import React from 'react';
import { GlobalHotKeys, configure } from 'react-hotkeys';

// by default, react-hotkeys will ignore hotkey events when input/select/textarea is focused.
// we want to listen for hotkeys even when the input/select/textarea is in focus
configure({
  ignoreTags: [],
});

export const ApplicationKeyMap = {
  ESC: 'esc',
  LEFT: 'left',
  RIGHT: 'right',
  GOTO_SETTINGS: 'alt+d',
  GO_BACK: 'alt+z',
  PAGINATION_NEXT: 'PageDown',
  PAGINATION_PREVIOUS: 'PageUp',
  SHOW_EXPORT_MODAL: 'alt+e',
  EXPORT_TO_PDF: 'alt+p',
  CREATE_NEW: 'alt+a',
};

export type ApplicationShortcut = keyof typeof ApplicationKeyMap;

export const DMSHotKey = (props: {
  shortcut: ApplicationShortcut;
  onPress: (keyEvent?: KeyboardEvent) => void;
  // For performance reason, by default, react-hotkeys takes the values of keyMap & handlers at mount time,
  // subsequence invalidation of the callbacks will not be taken into account.
  //
  // Most of the time it will be fine if your handler function doesn't depends on external values.
  //
  // However, when in need, set `allowChanges` to true so the handlers function will alaways be invalidated
  // in the subsequent renders.
  allowChanges?: boolean;
}) => {
  const { allowChanges, onPress } = props;

  return (
    <GlobalHotKeys
      keyMap={ApplicationKeyMap}
      handlers={{
        [props.shortcut]: (e) => {
          e?.preventDefault();
          onPress(e);
        },
      }}
      allowChanges={allowChanges}
    />
  );
};
