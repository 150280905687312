import truncate from 'lodash/truncate';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import cloneDeepWith from 'lodash/cloneDeepWith';
import { FormikErrors } from 'formik';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';

/**
 * Django Rest Framework Error
 *
 * The error responses returned by Django REST Framework (when it hasn't simply
 * crashed).
 *
 * On a GET, DELETE, or POST request that is not creating a new entity, the
 * response will typically include only a "detail" key, with a message about
 * the problem.
 *
 * On a PUT, PATCH, or POST request that *is* trying to create or update an
 * entity, the response may also include validation messages. These will be
 * structured as a key that names the field that failed validation, and a value
 * will be an array of strings with error messages. There may also be one
 * additional "non_field_errors" key (also an array of strings) for validation
 * messages that are not related to any one field. NOTE: If you send a nested
 * object in your request, the field errors will match the nesting.
 *
 * @example
 * // Misc failure
 * {
 *   detail: 'Incorrect login details'
 * }
 *
 * // Validation failure
 * {
 *   non_field_errors: [
 *     'start date must be before end date.'
 *   ],
 *   username: [
 *     'Username is already taken.',
 *     'Username must not be obscene.'
 *   ]
 *   profile: {
 *     name: [
 *       'This field may not be blank.'
 *     ]
 *   }
 * }
 */
export type DRFError<TModel extends {} = {}> = {
  detail?: string;
  non_field_errors?: string[];
} & DRFFieldErrors<TModel>;

// Recursive type definition to allow for nested DRF error fields.
type DRFFieldErrors<TModel extends {} = {}> = {
  [k in keyof TModel]?: TModel[k] extends object
    ? DRFFieldErrors<TModel[k]>
    : Array<null | string | Record<string, any>>;
};

export function isDRFError<TModel = any>(
  error: Error | DRFError<TModel> | any
): error is DRFError<TModel> {
  return typeof error === 'object' && !(error instanceof Error);
}

/**
 * Convenience type that represents the possible errors thrown by our BackendAPI
 * fetch methods. Because I keep having to write `DRFError | Error` over and
 * over...
 */
export type BackendApiError<TModel extends object = {}> =
  | DRFError<TModel>
  // Sometimes DRF gives us an array of errors instead of a dict of arrays.
  | string[]
  | Error;

/**
 * Takes an error that may be a standard JS Error object, a structured
 * Django Rest Framework error response, or a simple string error message,
 * and formats it into a structure usable for displaying error messages in
 * a Formik form.
 *
 * If your Formik form field names match up exactly with their corresponding
 * backend Django model fields, then you can simply take the "fieldErrors"
 * part of this function's return value and pass it to `formik.setErrors()`.
 *
 * The `otherErrors` portion of the return value will be an array of strings
 * with all other errors. The easiest way to display these is to put them in
 * `formik.setStatus()`, and use `.map()` to turn the array of strings into an
 * array of `AlertDanger` components.
 *
 * @param error
 * @param expectedFields Optional array of field names and/or lodash-style "paths"
 * used in your form. If supplied, only errors relating to these fields will be
 * placed in `fieldErrors`, and all other errors will be added to `otherErrors`,
 * flattened and prefixed with their field name as if sent through
 * `errorToString()`. This is not pretty, but it helps prevent unexpected errors
 * from being silently swallowed.
 */
export function formatErrorsForFormik<FormValues extends {} = {}>(
  error: unknown | string | BackendApiError<FormValues>,
  expectedFields?: string[]
): { fieldErrors: FormikErrors<FormValues>; otherErrors: string[] } {
  if (typeof error === 'string') {
    return {
      fieldErrors: {} as FormikErrors<FormValues>,
      otherErrors: [error],
    };
  } else if (error instanceof Error) {
    return {
      fieldErrors: {} as FormikErrors<FormValues>,
      otherErrors: [error.message],
    };
  } else if (
    Array.isArray(error) &&
    error.every((e) => typeof e === 'string')
  ) {
    return {
      fieldErrors: {} as FormikErrors<FormValues>,
      otherErrors: error,
    };
  } else if (isDRFError(error)) {
    const { detail, non_field_errors, ...reportedFieldErrors } =
      error as DRFError<any>;
    let otherErrors: string[] = [];

    if (detail) {
      otherErrors = otherErrors.concat(detail);
    }
    if (non_field_errors) {
      otherErrors = otherErrors.concat(non_field_errors);
    }

    if (expectedFields) {
      // Get all errors on "expected" fields
      const expectedFieldErrors = {};
      expectedFields.forEach((fieldPath) => {
        const thisFieldErr = lodashGet(reportedFieldErrors, fieldPath);
        if (thisFieldErr) {
          lodashSet(
            expectedFieldErrors,
            fieldPath,
            errorToString(thisFieldErr)
          );
        }
      });

      // Get all errors NOT on "expected" fields
      const unexpectedFieldErrors = omit(
        // _.omit() has a bug that sometimes mutates the object it's supposed
        // to copy from (and/or objects nested in it). So we need to run it
        // against a clone of the object to avoid mutating the original `errors`
        // param.
        //
        // See: https://github.com/lodash/lodash/issues/4007
        cloneDeep(reportedFieldErrors),
        expectedFields
      );

      otherErrors = otherErrors.concat(
        recursiveDrfErrorToArray(unexpectedFieldErrors)
      );
      return {
        fieldErrors: expectedFieldErrors,
        otherErrors,
      };
    } else {
      // DRF gives us arrays of error strings for each field. We want to
      // `.join()` those into single strings.
      const fieldErrors = cloneDeepWith(reportedFieldErrors, (value) => {
        if (
          Array.isArray(value) &&
          (value.length === 0 || typeof value[0] === 'string')
        ) {
          return value.join(';\n');
        } else {
          // `undefined` tells `cloneDeepWith()` to continue recursing on this field
          return undefined;
        }
      });

      return {
        fieldErrors,
        otherErrors,
      };
    }
  } else {
    // If they passed in something else that we can't specifically handle,
    // generate an error message as best we can. Include the error's type
    // to make it clearer what's going on.
    return {
      fieldErrors: {},
      otherErrors: [
        `[type ${typeof error}, error "${truncate(String(error))}"]`,
      ],
    };
  }
}

/**
 * Convert an Error or DRFError into one simple string. Useful for cases
 * where you are fetching, and should display an error if something goes
 * wrong, but you don't have a form to try to match things up to.
 *
 * This should normally not be used with PUT/POST/PATCH requests that may
 * include field-level validation errors. For those, use `showErrorsInFormik()`
 * instead, which can line up the field-level errors with their corresponding
 * form field.
 *
 * If the error passed to this function contains multiple error messages, they'll
 * be concatenated together with a ";\n" as separator.
 *
 * If the error contains field-level errors, they'll be prefaced with the name
 * of the field. This is not pretty, but it is at least informational.
 *
 * @example
 * // INPUT:
 * {
 *   detail: 'This is a detail-level error',
 *   non_field_errors: ['This is a non-field error'],
 *   username: ['This field must be unique'],
 *   profile: {
 *     name: ['This field is required']
 *   }
 * }
 *
 * // RESULT:
 * `This is a detail-level error;
 * This is a non-field error;
 * "username": This field must be unique;
 * "profile.name": This field is required`
 * @param error
 */
export function errorToString(error: unknown): string | '' {
  if (typeof error === 'string') {
    return error;
  } else if (!error) {
    return '';
  } else if (error instanceof Error) {
    return error.message;
  } else if (Array.isArray(error)) {
    return error.join(';\n');
  } else if (isDRFError(error)) {
    const { detail, non_field_errors, ...fieldErrors } = error as DRFError<any>;
    let allErrors: string[] = [];

    if (detail) {
      allErrors = allErrors.concat(detail);
    }
    if (non_field_errors) {
      allErrors = allErrors.concat(non_field_errors);
    }
    allErrors = allErrors.concat(recursiveDrfErrorToArray(fieldErrors));

    return allErrors.join(';\n');
  } else {
    // If they passed in something else that we can't specifically handle,
    // generate an error message as best we can. Include the error's type
    // to make it clearer what's going on.
    return `[type ${typeof error}, error "${truncate(String(error))}"]`;
  }
}

/**
 * Flattens a (potentially nested) DRF field errors object into a flat array of
 * strings, labelled with which field DRF assigned the field to.
 *
 * @param errorsObj
 * @param parentFieldName
 */
function recursiveDrfErrorToArray(
  errorsObj: Record<string, any> | any[],
  parentFieldName: string = ''
): string[] {
  return Object.entries<string | number, unknown>(errorsObj).reduce(
    (acc: string[], [fieldName, fieldValue]) => {
      const myFullPath = parentFieldName
        ? `${parentFieldName}.${fieldName}`
        : `${fieldName}`;

      if (fieldValue && typeof fieldValue === 'object') {
        if (
          Array.isArray(fieldValue) &&
          fieldValue.every((e) => typeof e === 'string')
        ) {
          // String array; add each entry to the output
          return acc.concat(
            fieldValue.map((errorString) => `"${myFullPath}": ${errorString}`)
          );
        } else {
          // Any other object; recurse into it
          return acc.concat(
            recursiveDrfErrorToArray(fieldValue as any, myFullPath)
          );
        }
      } else if (fieldValue === null || fieldValue === undefined) {
        // Empty entry; don't add an error to the list
        return acc;
      } else {
        // Non-string, non-array value. Display it as an error message.
        return acc.concat(`"${myFullPath}": ${fieldValue}`);
      }
    },
    []
  );
}

/**
 * A helper function to automatically generate good "expectedFields" arrays
 * for complicated forms.
 *
 * Takes an object of form values, returns an array of lodash-style paths
 * representing all the likely form fields in the form.
 *
 * Basically it's:
 *  - Fields with a scalar value
 *  - Fields with a value that's an array of scalars
 *
 * This COULD be wrong in some situations, but it's the best we can do without
 * directly hooking into Formik's context, which is hard to do during form
 * submission.
 *
 * @param formValues
 * @param parentPath
 */
export function getExpectedFields(
  formValues: Record<string, any> | any[],
  parentPath = ''
): string[] {
  return Object.entries<string | number, unknown>(formValues).reduce(
    (acc: string[], [key, val]) => {
      const myPath = parentPath ? `${parentPath}.${key}` : `${key}`;
      if (val && typeof val === 'object') {
        if (
          Array.isArray(val) &&
          !val.some(
            (valItem: unknown) => valItem && typeof valItem === 'object'
          )
        ) {
          // it's an array of scalars; add it to list of form fields
          return acc.concat(myPath);
        } else {
          // it's an object or it's an array of objects; recurse
          return acc.concat(getExpectedFields(val as any, myPath));
        }
      } else {
        // it's a scalar; add it to list of form fields
        return acc.concat(myPath);
      }
    },
    []
  );
}
