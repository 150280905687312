import React from 'react';
import classNames from 'classnames';
import { Route } from 'react-router';
import {
  RCPWithQueryParams,
  parseStringArrayFromQueryParam,
  setOneQueryParam,
} from '../../../../util/routing';
import { IconType, Icon } from '../../../base/icon/icon';
import { ReportColumnRenderInfo, getColBackendField } from '../report-types';

/**
 * A component to render table header cells for columns that can be sortable,
 * using the reporting endpoints API.
 *
 * Use this as a drop-in replacement for a base <th>; all props of <th>
 * can be provided to it and will be passed through to the underlying <th>
 * element.
 *
 * You pass it the "columns" section of your report info from the backend,
 * and a column name, and it will configure the column appropriately for you.
 *
 * The current ordering selection is tracked through the URL, so this should
 * be used with a Redux-aware screen container which uses `mapStateToProps()`
 * or `componentDidUpdate()` to notice when the URL's "ordering" param changes,
 * and pass that change through to the backend to request new data. See
 * https://www.django-rest-framework.org/api-guide/filtering/#orderingfilter for
 * details on the query param.
 */

type OwnProps<
  TReportItem extends {} = any,
  TField extends StringKeyOf<TReportItem> = StringKeyOf<TReportItem>
> = Merge<
  React.ThHTMLAttributes<HTMLTableHeaderCellElement>,
  {
    column: ReportColumnRenderInfo<TReportItem, TField>;
    defaultSorting: string;
  }
>;

type Props<
  TReportItem extends {} = any,
  TField extends StringKeyOf<TReportItem> = StringKeyOf<TReportItem>
> = OwnProps<TReportItem, TField> & RCPWithQueryParams<{ ordering?: string }>;

class InnerReportTableTh<TReportItem> extends React.PureComponent<
  Props<TReportItem>
> {
  makeAscending = () =>
    // ?ordering=columnname for ascending
    setOneQueryParam(this.props, 'ordering', this.props.column.frontend.name);

  makeDescending = () =>
    // ?ordering=-columnname (with a minus "-") for descending
    setOneQueryParam(
      this.props,
      'ordering',
      `-${this.props.column.frontend.name}`
    );

  render() {
    const {
      history,
      location,
      match,
      staticContext,
      children,
      className,
      column: backend,
      defaultSorting,
      ...otherProps
    } = this.props;

    const { isSortable, isActive, isDescending } = this.calculateStatus();

    let sortingProps: React.ThHTMLAttributes<HTMLTableHeaderCellElement> = {
      scope: 'col',
      role: 'columnheader',
      className,
    };
    let icon: React.ReactNode = null;

    if (isSortable) {
      let iconType: IconType;
      sortingProps = {
        ...sortingProps,
        className: classNames('table-header-sortable', className),
        tabIndex: 0,
      };
      if (isActive) {
        if (isDescending) {
          sortingProps = {
            ...sortingProps,
            onClick: this.makeAscending,
            'aria-sort': 'descending',
          };
          iconType = 'icon-sort-descend';
        } else {
          sortingProps = {
            ...sortingProps,
            onClick: this.makeDescending,
            'aria-sort': 'ascending',
          };
          iconType = 'icon-sort-ascend';
        }
      } else {
        sortingProps = {
          ...sortingProps,
          onClick: this.makeAscending,
          'aria-sort': 'none',
        };
        iconType = 'icon-sort';
      }
      icon = <Icon type={iconType} />;
    }
    return (
      <th {...sortingProps} {...otherProps}>
        <div>
          <span>{children}</span>
          {icon}
        </div>
      </th>
    );
  }

  calculateStatus = () => {
    const colInfo = this.props.column;
    if (!colInfo) {
      return { isSortable: false, isActive: false, isDescending: false };
    }
    const isSortable = colInfo.backend && colInfo.backend.is_sortable;
    if (!isSortable) {
      return { isSortable: false, isActive: false, isDescending: false };
    }
    const fieldName = colInfo.frontend.name;

    // Find out the "ordering" columns in the URL. Or if the URL has no
    // "ordering" param yet, use the backend report info's "default_sorting"
    // param to indicate which ones are active by default.
    const orderingColsInUrl = parseStringArrayFromQueryParam(
      this.props,
      'ordering',
      false
    );
    if (!orderingColsInUrl) {
      // No ordering columns in URL! Go by whether or not this column
      // is the default sorting.
      const backendFieldName = getColBackendField(colInfo.frontend);
      if (this.props.defaultSorting === backendFieldName) {
        return {
          isSortable: true,
          isActive: true,
          isDescending: false,
        };
      } else if (this.props.defaultSorting === `-${backendFieldName}`) {
        return { isSortable: true, isActive: true, isDescending: true };
      } else {
        return { isSortable: true, isActive: false, isDescending: false };
      }
    } else {
      // Find whether this column is in the "ordering" columns of the URL
      const myStatusInUrl = orderingColsInUrl.find(
        (f) => f === fieldName || f === `-${fieldName}`
      );
      const isActive = Boolean(isSortable && myStatusInUrl);
      const isDescending = Boolean(
        isSortable && myStatusInUrl && myStatusInUrl[0] === '-'
      );
      return { isSortable, isActive, isDescending };
    }
  };
}

export class ReportTableTh<TReportItem> extends React.PureComponent<
  OwnProps<TReportItem>
> {
  render() {
    return (
      // This looks a little bit clunkier than directly using withRouter(),
      // but it lets us ensure that the output inherits the generic type
      // parameters.
      <Route
        render={(routeProps) => (
          <InnerReportTableTh {...routeProps} {...this.props} />
        )}
      />
    );
  }
}
