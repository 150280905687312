import React from 'react';
import { Trans, t } from '@lingui/macro';
import Button from '../button/button';
import classNames from 'classnames';
import './accordion.scss';

interface AccordionItem {
  id: string;
  title: React.ReactNode;
  content: React.ReactNode;
}

interface Props {
  expand: boolean;
  item: AccordionItem;
  onToggle?: (isExpanded: boolean) => void; // when specified, component become controlled
  wrapperClassName?: string;
}

interface State {
  expanded: boolean;
}

export default class Accordion extends React.Component<Props, State> {
  state = {
    expanded: false,
  };

  public componentDidMount() {
    const { expand } = this.props;

    if (expand) {
      this.setState({
        expanded: true,
      });
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (
      typeof this.props.onToggle === 'function' &&
      this.props.expand !== prevProps.expand
    ) {
      this.setState({
        expanded: this.props.expand,
      });
    }
  }

  render() {
    const { item } = this.props;
    const { expanded } = this.state;
    const toggleClassNames = classNames('accordion-toggle', {
      'd-print-none': !expanded,
    });

    const collapseButton = (
      <Button
        className="accordion-collapse-btn"
        iconType="icon-arrow-double-up"
        title={t`Collapse`}
        iconOnly
      >
        <Trans>Collapse</Trans>
      </Button>
    );

    const expandButton = (
      <Button
        className="accordion-expand-btn"
        iconType="icon-arrow-double-down"
        title={t`Expand`}
        iconOnly
      >
        <Trans>Expand</Trans>
      </Button>
    );

    return (
      <div
        className={classNames(
          'accordion-container',
          this.props.wrapperClassName
        )}
      >
        <div
          onClick={this.toggle}
          className={toggleClassNames}
          id={item.id}
          data-toggle="item"
          data-test="accordion-toggle"
          role="button"
          aria-controls={`${item.id}-content`}
          aria-expanded={expanded}
        >
          {item.title}
          {expanded ? collapseButton : expandButton}
        </div>
        <div
          className={classNames('accordion-content', {
            'accordion-content-collapsed': !expanded,
          })}
          id={`${item.id}-content`}
        >
          {item.content}
        </div>
      </div>
    );
  }

  private toggle = (evt: React.MouseEvent<HTMLElement>) => {
    if (evt) {
      evt.preventDefault();
    }

    if (this.props.onToggle) {
      this.props.onToggle(!this.state.expanded);
    } else {
      this.setState({
        expanded: !this.state.expanded,
      });
    }
  };
}
