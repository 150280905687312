import React, { useCallback, useMemo } from 'react';
import { Trans, t } from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import ActionBlock from 'components/base/actionblock/actionblock';
import { AlertDanger } from 'components/base/alert/alert';
import Button, {
  ButtonPrimary,
  ButtonDanger,
} from 'components/base/button/button';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import ModalContent from 'components/base/modal/modalcontent';
import PanelContent from 'components/base/panel/panelcontent';
import {
  ErrorMessage,
  Form,
  Formik,
  FormikHelpers,
  FormikErrors,
} from 'formik';
import Loading from 'components/base/loading/loading';
import { LinkIfHas } from 'components/logic/link-if-has-permission/LinkIfHas';
import { Model } from 'util/backendapi/models/api.interfaces';
import { ReportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import { getUserDisplayName } from 'util/user';

interface FormValues {
  roles: number[];
  groupId?: number;
  userGroupId?: number;
  userId?: number;
}

interface Props {
  isLoading: boolean;
  groupUsers: Model.UserAreaGroupDecorated[] | null;
  allUsers: Model.User[] | null;
  errorMessage: string | null;
  groupName: string;
  onSubmitAddPerson: (
    values: FormValues,
    formik: FormikHelpers<FormValues>
  ) => void;
  onAddPersonClick: () => void;
  onEditPersonClick: (userGroupId: number | null) => void;
  addPersonFormOpen: boolean;
  editPersonFormOpen: boolean;
  roles: Model.Role[] | null;
  groupId: number;
  editingUserGroupId: number | null;
  onRemovePersonClick: (
    groupId: number,
    userGroupId: number,
    userId: number,
    hideModal: () => void
  ) => Promise<void>;
}

function validateForm(values: FormValues): FormikErrors<FormValues> {
  let errors: FormikErrors<FormValues> = {};

  // This same validation method is used for "edit person in group" and
  // "add person to group". But "edit person in group" has no "userId"
  // menu, so we shouldn't try to validate it.
  if ('userId' in values && !values.userId) {
    errors.userId = (<Trans>Person is required.</Trans>) as any;
  }

  if (!values.roles || values.roles.length === 0) {
    errors.roles = (<Trans>At least one role is required.</Trans>) as any;
  }

  return errors;
}

export function MaintainGroupUsersPanelView(props: Props) {
  const roleOptions = useMemo(
    () =>
      props.roles?.map((role) => {
        return {
          label: role.name,
          value: role.id,
        };
      }) ?? [],
    [props.roles]
  );

  const userGroups = useMemo(() => {
    return sortBy(
      props.groupUsers,
      (ug) => ug.user.profile.name,
      (ug) => ug.user.username
    );
  }, [props.groupUsers]);

  const renderRemovePersonConfirmation = useCallback(
    ({ id, user }: Model.UserAreaGroupDecorated, hideModal: () => void) => {
      return (
        <ModalContent header={<Trans>Confirmation</Trans>}>
          <p>
            <Trans>
              Are you sure you want to remove{' '}
              <strong>{getUserDisplayName(user)}</strong> from{' '}
              <strong>{props.groupName}</strong>? This action is not reversible.
            </Trans>
          </p>
          <ActionBlock className="text-right">
            <ButtonHideModal>
              <Trans>Cancel</Trans>
            </ButtonHideModal>
            <ButtonDanger
              onClick={() =>
                props.onRemovePersonClick.call(
                  null,
                  props.groupId,
                  id,
                  user.id,
                  hideModal
                )
              }
            >
              <Trans>Yes, remove</Trans>
            </ButtonDanger>
          </ActionBlock>
        </ModalContent>
      );
    },
    [props.groupId, props.groupName, props.onRemovePersonClick]
  );

  const renderEditPersonForm = useCallback(() => {
    const userGroupBeingEdited = userGroups?.find((userGroup) => {
      return userGroup.id === props.editingUserGroupId;
    });

    const existingRoles =
      userGroupBeingEdited?.roles.map((role) => role.id) ?? [];

    return (
      <Formik<FormValues>
        initialValues={{
          roles: existingRoles,
          groupId: props.groupId,
          userGroupId: userGroupBeingEdited?.id,
        }}
        onSubmit={props.onSubmitAddPerson}
        validate={validateForm}
      >
        {(formik) => (
          <div className="panel-form-wrapper">
            <div className="panel-form-body">
              <Form>
                <fieldset>
                  <legend>
                    <Trans>Change a person's role in the group</Trans>
                  </legend>
                  <div className="form-group">
                    <label htmlFor="maintgroupuseradd-user">
                      <Trans>Person</Trans>
                    </label>
                    <span>
                      {userGroupBeingEdited &&
                        getUserDisplayName(userGroupBeingEdited?.user)}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="maintgroupuseradd-roles">
                      <Trans>Roles</Trans>
                    </label>
                    <SimpleSelectField
                      name="roles"
                      id="maintgroupuseradd-roles"
                      options={roleOptions}
                      placeholder={<Trans>Select one or more roles</Trans>}
                      isMulti
                    />
                    <ErrorMessage name="roles" component={ErrorNotice} />
                  </div>
                </fieldset>
                <ActionBlock>
                  <Button
                    onClick={() => props.onEditPersonClick.call(null, null)}
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <ButtonPrimary
                    id="maintgroupusersadd-add"
                    type="submit"
                    disabled={formik.isSubmitting}
                    iconType="icon-save"
                  >
                    Save
                  </ButtonPrimary>
                </ActionBlock>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    );
  }, [
    props.editingUserGroupId,
    props.groupId,
    props.onEditPersonClick,
    props.onSubmitAddPerson,
    roleOptions,
    userGroups,
  ]);

  const allUserOptions = useMemo(
    () =>
      sortBy(props.allUsers?.map(ReportFilterMenu.USER_MENU), (o) => o.label) ??
      [],
    [props.allUsers]
  );

  const renderAddPersonForm = useCallback(() => {
    const usersNotInGroup = allUserOptions.filter(
      (u) => !userGroups?.some((groupUser) => groupUser.user.id === u.value)
    );
    return (
      <Formik<FormValues>
        initialValues={{
          userId: 0,
          roles: [],
          groupId: props.groupId,
        }}
        onSubmit={props.onSubmitAddPerson}
        validate={validateForm}
      >
        {(formik) => (
          <div className="panel-form-wrapper">
            <div className="panel-form-body">
              <Form>
                <fieldset>
                  <legend>
                    <Trans>Add a person to the group</Trans>
                  </legend>
                  <div className="form-group">
                    <label htmlFor="maintgroupuseradd-user">
                      <Trans>Person</Trans>
                    </label>
                    <SimpleSelectField
                      name="userId"
                      id="maintgroupusersadd-user"
                      options={usersNotInGroup}
                      placeholder={<Trans>Select a person</Trans>}
                    />
                    <ErrorMessage name="userId" component={ErrorNotice} />
                  </div>
                  <div className="form-group">
                    <label htmlFor="maintgroupuseradd-roles">
                      <Trans>Roles</Trans>
                    </label>
                    <SimpleSelectField
                      name="roles"
                      id="maintgroupuseradd-roles"
                      options={roleOptions}
                      placeholder={<Trans>Select one or more roles</Trans>}
                      isMulti
                    />
                    <ErrorMessage name="roles" component={ErrorNotice} />
                  </div>
                </fieldset>
                <ActionBlock>
                  <Button onClick={props.onAddPersonClick}>
                    <Trans>Cancel</Trans>
                  </Button>
                  <ButtonPrimary
                    id="maintgroupusersadd-add"
                    type="submit"
                    disabled={formik.isSubmitting}
                    iconType="icon-save"
                  >
                    <Trans>Add person</Trans>
                  </ButtonPrimary>
                </ActionBlock>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    );
  }, [
    allUserOptions,
    props.groupId,
    userGroups,
    props.onAddPersonClick,
    props.onSubmitAddPerson,
    roleOptions,
  ]);

  let innerContent = <Loading />;
  let addPersonButton = (
    <ActionBlock>
      <Button
        onClick={props.onAddPersonClick}
        iconType="icon-plus"
        className="btn-tab"
      >
        <Trans>Add a person</Trans>
      </Button>
    </ActionBlock>
  );

  if (!props.isLoading && userGroups && userGroups.length > 0) {
    innerContent = (
      <>
        {props.addPersonFormOpen
          ? renderAddPersonForm()
          : props.editPersonFormOpen
          ? renderEditPersonForm()
          : addPersonButton}
        <ul className="panel-member-list">
          {userGroups.map((userGroup) => {
            if (
              props.editPersonFormOpen &&
              userGroup.id === props.editingUserGroupId
            ) {
              return '';
            }

            return (
              <li key={userGroup.user.id} className="columns-fluid">
                <div>
                  <LinkIfHas
                    permission="can_view_other_user_profiles"
                    to={`/user/${userGroup.user.id}`}
                  >
                    <strong>{getUserDisplayName(userGroup.user)}</strong>
                  </LinkIfHas>
                  <br />
                  {userGroup.roles.map((role) => {
                    return (
                      <div key={role.id} className="text-small">
                        {role.name}
                      </div>
                    );
                  })}
                </div>
                <div className="action-icons text-right">
                  <Button
                    iconOnly={true}
                    iconType="icon-edit"
                    name="edituser"
                    className="btn-link-panel"
                    title={t`Edit`}
                    onClick={(e) => props.onEditPersonClick(userGroup.id)}
                  >
                    <Trans>Edit</Trans>
                  </Button>

                  <ButtonShowModal
                    name="remove-user"
                    iconOnly={true}
                    iconType="icon-circle-minus"
                    className="btn-link-panel"
                    title={t`Remove member`}
                    modalContent={({ hideModal }) =>
                      renderRemovePersonConfirmation(userGroup, hideModal)
                    }
                    modalProps={{
                      className: 'modal modal-danger',
                      overlayClassName: 'modal-centred',
                    }}
                  >
                    <Trans>Remove member</Trans>
                  </ButtonShowModal>
                </div>
              </li>
            );
          })}
        </ul>
      </>
    );
  } else if (!props.isLoading && props.errorMessage) {
    innerContent = (
      <AlertDanger>
        <Trans>An error has occurred.</Trans>
      </AlertDanger>
    );
  } else if (!props.isLoading && userGroups && userGroups.length === 0) {
    innerContent = (
      <>
        {props.addPersonFormOpen ? renderAddPersonForm() : addPersonButton}
        <p>
          <Trans>No users have been added to this group.</Trans>
        </p>
      </>
    );
  }

  return (
    <PanelContent header={<Trans>{props.groupName} group members</Trans>}>
      {innerContent}
    </PanelContent>
  );
}
