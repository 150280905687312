import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { getApi, postApi, patchApi } from '../util/backendapi/fetch';
import { Model } from '../util/backendapi/models/api.interfaces';
import sortBy from 'lodash/sortBy';
import { StandardThunk } from '../main/store';
import { AnyAction } from 'redux';

export enum ActionTypes {
  FETCH_ROUTE_MARCH_LIST_START = 'dms/routemarch/FETCH_ROUTE_MARCH_LIST_START',
  FETCH_ROUTE_MARCH_LIST_ERROR = 'dms/routemarch/FETCH_ROUTE_MARCH_LIST_ERROR',
  FETCH_ROUTE_MARCH_LIST_RESPONSE = 'dms/routemarch/FETCH_ROUTE_MARCH_LIST_RESPONSE',
  CREATE_ROUTE_MARCH_ERROR = 'dms/routemarch/CREATE_ROUTE_MARCH_ERROR',
  CREATE_ROUTE_MARCH_RESPONSE = 'dms/routemarch/CREATE_ROUTE_MARCH_RESPONSE',
  UPDATE_ROUTE_MARCH_START = 'dms/routemarch/UPDATE_ROUTE_MARCH_START',
  UPDATE_ROUTE_MARCH_RESPONSE = 'dms/routemarch/UPDATE_ROUTE_MARCH_RESPONSE',
  UPDATE_ROUTE_MARCH_ERROR = 'dms/routemarch/UPDATE_ROUTE_MARCH_ERROR',
}

export function fetchRouteMarchListStart() {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_LIST_START as ActionTypes.FETCH_ROUTE_MARCH_LIST_START,
  };
}

export function fetchRouteMarchListError(errorMessage: string) {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_LIST_ERROR as ActionTypes.FETCH_ROUTE_MARCH_LIST_ERROR,
    errorMessage,
  };
}

export function fetchRouteMarchListResponse(routeMarches: Model.RouteMarch[]) {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_LIST_RESPONSE as ActionTypes.FETCH_ROUTE_MARCH_LIST_RESPONSE,
    payload: routeMarches,
  };
}

export function createRouteMarchResponse(routeMarch: Model.RouteMarch) {
  return {
    type: ActionTypes.CREATE_ROUTE_MARCH_RESPONSE as ActionTypes.CREATE_ROUTE_MARCH_RESPONSE,
    payload: routeMarch,
  };
}

export function createRouteMarchError(errorMessage: string) {
  return {
    type: ActionTypes.CREATE_ROUTE_MARCH_ERROR as ActionTypes.CREATE_ROUTE_MARCH_ERROR,
    errorMessage: errorMessage,
  };
}

export function updateRouteMarchStart(routeMarchId: number) {
  return {
    type: ActionTypes.UPDATE_ROUTE_MARCH_START as ActionTypes.UPDATE_ROUTE_MARCH_START,
    payload: { routeMarchId },
  };
}

export function updateRouteMarchResponse(routeMarch: Model.RouteMarch) {
  return {
    type: ActionTypes.UPDATE_ROUTE_MARCH_RESPONSE as ActionTypes.UPDATE_ROUTE_MARCH_RESPONSE,
    payload: { routeMarch },
  };
}

export function updateRouteMarchError(errorMessage: string) {
  return {
    type: ActionTypes.UPDATE_ROUTE_MARCH_ERROR as ActionTypes.UPDATE_ROUTE_MARCH_ERROR,
    errorMessage: errorMessage,
  };
}

export type RouteMarchAction =
  | ReturnType<typeof fetchRouteMarchListStart>
  | ReturnType<typeof fetchRouteMarchListError>
  | ReturnType<typeof fetchRouteMarchListResponse>
  | ReturnType<typeof createRouteMarchResponse>
  | ReturnType<typeof createRouteMarchError>
  | ReturnType<typeof updateRouteMarchStart>
  | ReturnType<typeof updateRouteMarchResponse>
  | ReturnType<typeof updateRouteMarchError>;

export interface RouteMarchTableState {
  loading: boolean;
  errorMessage: string | null;
  routeMarches: Model.RouteMarch[] | null;
  updatingRouteMarchId: number | null;
}

// TODO: For now (with no route march detail page) it makes sense to just
// put the route march table's state at the top level of this state slice.
// But once we have multiple route march screens, we should move it to a
// "table" section, and the detail view into a "detail" section, or something.
export function routeMarchInitialState(): RouteMarchTableState {
  return {
    loading: true,
    errorMessage: null,
    routeMarches: null,
    updatingRouteMarchId: null,
  };
}

export function routeMarchReducer(
  state: RouteMarchTableState = routeMarchInitialState(),
  action: RouteMarchAction
): RouteMarchTableState {
  switch (action.type) {
    case ActionTypes.FETCH_ROUTE_MARCH_LIST_START:
      return {
        ...state,
        loading: true,
        errorMessage: null,
        routeMarches: null,
      };
    case ActionTypes.FETCH_ROUTE_MARCH_LIST_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
        routeMarches: null,
      };
    case ActionTypes.FETCH_ROUTE_MARCH_LIST_RESPONSE:
      return {
        ...state,
        errorMessage: null,
        loading: false,
        routeMarches: action.payload,
      };
    case ActionTypes.CREATE_ROUTE_MARCH_RESPONSE:
      return {
        ...state,
        routeMarches:
          state.routeMarches !== null
            ? state.routeMarches.concat([action.payload])
            : state.routeMarches,
      };
    case ActionTypes.CREATE_ROUTE_MARCH_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ActionTypes.UPDATE_ROUTE_MARCH_START:
      return {
        ...state,
        updatingRouteMarchId: action.payload.routeMarchId,
      };
    case ActionTypes.UPDATE_ROUTE_MARCH_RESPONSE: {
      let routeMarches = state.routeMarches;
      if (state.routeMarches !== null) {
        routeMarches = [...state.routeMarches];
        const updatedIndex = state.routeMarches.findIndex(
          (routeMarch) => routeMarch.id === action.payload.routeMarch.id
        );
        routeMarches[updatedIndex] = action.payload.routeMarch;
      }

      return {
        ...state,
        routeMarches,
      };
    }
    case ActionTypes.UPDATE_ROUTE_MARCH_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}

export function fetchRouteMarchList(): StandardThunk<AnyAction> {
  return async function (dispatch) {
    dispatch(fetchRouteMarchListStart());
    try {
      const routeMarches = sortBy(
        await getApi('/route-marches/'),
        (r: Model.RouteMarch) => r.code
      );

      return dispatch(fetchRouteMarchListResponse(routeMarches));
    } catch (e) {
      return dispatch(fetchRouteMarchListError(e.message));
    }
  };
}

export function createOrUpdateRouteMarch(
  routeMarch: Optional<Model.RouteMarch, 'id'>
): ThunkAction<any, any, any, any> {
  return async function (dispatch: ThunkDispatch<any, any, any>) {
    // Update
    if (routeMarch.id) {
      const { id: routeMarchId, ...routeMarchValues } = routeMarch;
      dispatch(updateRouteMarchStart(routeMarchId));
      try {
        const updatedRouteMarch = await patchApi(
          `/route-marches/${routeMarchId}/`,
          routeMarchValues
        );
        return dispatch(updateRouteMarchResponse(updatedRouteMarch));
      } catch (e) {
        dispatch(updateRouteMarchError(e.message));
        throw e;
      }
    }
    // Create
    else {
      try {
        const routeMarchResponse = await postApi('/route-marches/', routeMarch);
        return dispatch(createRouteMarchResponse(routeMarchResponse));
      } catch (e) {
        dispatch(createRouteMarchError(e.message));
        throw e;
      }
    }
  };
}
