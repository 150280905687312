import React, { useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import {
  fetchStoredPlot,
  updateStoredPlot,
  createStoredPlot,
  createStoredSpatialPlot,
  updateStoredSpatialPlot,
  makeStoredPlotKey,
  unmountStoredPlotDetails,
  createStoredSurveyLevellingPlot,
  updateStoredSurveyLevellingPlot,
} from 'ducks/stored-plot/detail';
import {
  selectAllInOrderedArray,
  EntityTypes,
  fetchEntityList,
} from 'ducks/entities';
import {
  StoredPlotEditView,
  StoredPlotEditViewProps,
} from './StoredPlotEditView';
import { StandardDispatch } from 'main/store';
import {
  useParams,
  useHistory,
  useLocation,
  matchPath,
} from 'react-router-dom';
import { PlotType } from 'util/backendapi/types/Enum';
import { NewStoredPlotDefaults } from './stored-plot-edit-utils';
import { FormikHelpers } from 'formik';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields, isDRFError } from 'util/backendapi/error';
import { StoredPlotSaveAsValues } from './StoredPlotSaveAsModal';
import { getLastBackUrl } from 'components/base/link/DMSLink';

export function showStoredPlotErrorsInFormik<T extends StoredPlotSaveAsValues>(
  formik: FormikHelpers<T>,
  error: any,
  values: T
) {
  // if saving a copy of the stored plot, translate errors to do with the save_as fields
  if (values.save_as) {
    if (isDRFError(error)) {
      ['name', 'area', 'description'].forEach((field) => {
        if (error[field]) {
          error[`save_as_${field}`] = error[field];
          delete error[field];
        }
      });
    }
  }
  showErrorsInFormik(formik, error, getExpectedFields(values));
}

export const StoredPlotEditScreen = () => {
  const routeParams = useParams<{ plotName?: string; plotType?: PlotType }>();
  const { plotName } = routeParams;
  const location = useLocation<{ name?: string }>();
  const history = useHistory();

  // If React Router is not providing a plot name, it means we're creating
  // a new plot, rather than editing an existing noe.
  const isNewPlot = !plotName;

  const backUrl = new URLSearchParams(location.search).get('backUrl');

  const dispatch: StandardDispatch = useDispatch();
  const areaOptions = useSelector((state: FullState) =>
    selectAllInOrderedArray(state, EntityTypes.AREA).map((area) => ({
      value: area.id,
      label: `${area.code} - ${area.name}`,
      timeZone: area.time_zone.name,
    }))
  );

  const { storedPlot, isLoading, errorMessage } = useShallowEqualSelector(
    (state: FullState) => {
      if (!plotName) {
        // New plot; no need to fetch any stored plot data
        return {
          storedPlot: null,
          errorMessage: '',
          isLoading: false,
        };
      } else {
        const plotState = state.storedPlot.detail[makeStoredPlotKey(plotName)];
        return {
          storedPlot: plotState?.record ?? null,
          errorMessage: plotState?.errorMessage ?? '',
          isLoading: plotState?.loading ?? false,
        };
      }
    }
  );

  const newPlotDefaults: NewStoredPlotDefaults | null = useMemo(
    () =>
      isNewPlot
        ? {
            name: location.state?.name ?? '',
            plot_type: routeParams.plotType as PlotType,
          }
        : null,
    [isNewPlot, location, routeParams.plotType]
  );

  useEffect(() => {
    if (plotName && storedPlot?.name !== plotName) {
      dispatch(fetchStoredPlot(plotName));
    }
    return () => {
      if (storedPlot) {
        dispatch(unmountStoredPlotDetails());
      }
    };
  }, [dispatch, plotName, storedPlot]);

  useEffect(() => {
    dispatch(fetchEntityList(EntityTypes.AREA));
  }, [dispatch]);

  const storedPlotId = storedPlot ? storedPlot.id : null;
  const handleSubmit: StoredPlotEditViewProps['onSubmit'] = useCallback(
    async (originalValues, saveAs) => {
      const createPlot = isNewPlot || saveAs;

      let values = { ...originalValues };
      if (saveAs) {
        values = {
          ...values,
          name: saveAs.name,
          description: saveAs.description,
          area: saveAs.area,
        };
      }

      if (
        values.plot_type === PlotType.SPATIAL_PLAN ||
        values.plot_type === PlotType.SPATIAL_CROSS_SECTION ||
        values.plot_type === PlotType.SPATIAL_WANDER
      ) {
        if (createPlot) {
          if (saveAs) {
            // When doing a save-as, and they haven't uploaded a new file, use the
            // `_uri` fields to indicate that you want to re-use the existing file
            if (values.base_layer_image && !values.base_layer_image_file) {
              values.base_layer_image_uri = `base_layers/${values.base_layer_image}`;
              values.base_layer_image = '';
            }
            if (
              values.additional_layer_image &&
              !values.additional_layer_image_file
            ) {
              values.additional_layer_image_uri = `additional_layers/${values.additional_layer_image}`;
              values.additional_layer_image = '';
            }
          }
          await dispatch(createStoredSpatialPlot(values));
        } else {
          await dispatch(updateStoredSpatialPlot(storedPlotId!, values));
        }
      } else if (values.plot_type === PlotType.SURVEY_LEVELLING) {
        if (createPlot) {
          if (saveAs) {
            if (values.background_image && !values.background_image_file) {
              values.background_image_uri = `base_layers/${values.background_image}`;
              values.background_image = '';
            }
          }
          await dispatch(createStoredSurveyLevellingPlot(values));
        } else {
          await dispatch(
            updateStoredSurveyLevellingPlot(storedPlotId!, values)
          );
        }
      } else {
        if (createPlot) {
          await dispatch(createStoredPlot(values));
        } else {
          await dispatch(updateStoredPlot(storedPlotId!, values));
        }
      }

      /**
       * Usually, this screen is accessed from the StoredPlot list page, we want to forward the backURL
       * to the next screen if user press Save. (the plot page also have a back button, and it should go back to the list)
       *
       * However, this screen can be access from the plot page itself, that's when it get clunky
       *
       * Example scenario:
       *
       * 1. Start with the StoredPlot reports: /stored-plots?plot_type=time_series
       *
       * 2. View a stored plot: /stored-plots/abcd?backUrl=%2Fstored-plots%3Fplot_type%3Dtime_series
       *    BackButton: back to the list (with filter)
       *
       * 3. Edit the plot: /stored-plots/abcd/edit?backUrl=%2Fstored-plots%2Fabcd%3FbackUrl%3D%252Fstored-plots%253Fplot_type%253Dtime_series
       *
       *    - When Cancel, go back to:
       *      /stored-plots/abcd?backUrl=%2Fstored-plots%3Fplot_type%3Dtime_series
       *      (this is good, click back again will show us the filtered list)
       *
       *    - When Save, we'll grab the last backUrl and use it as the backUrl for the next screen, so we end up with:
       *      /stored-plots/abcdz?backUrl=%2Fstored-plots%3Fplot_type%3Dtime_series
       */
      const lastBackUrl = getLastBackUrl(location);
      let qs = '';
      if (lastBackUrl) {
        const isBackToPlot = Boolean(
          matchPath(lastBackUrl, {
            path: '/stored-plots/:code',
            exact: true,
          })
        );
        if (!isBackToPlot) {
          qs = `?backUrl=${encodeURIComponent(lastBackUrl)}`;
        }
      }
      history.push(`/stored-plots/${values.name}${qs}`);
    },
    [dispatch, history, isNewPlot, storedPlotId, location]
  );
  const handleCancel: StoredPlotEditViewProps['onCancel'] = useCallback(() => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.push(`/stored-plots/`);
    }
  }, [history, backUrl]);

  return (
    <StoredPlotEditView
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      storedPlot={storedPlot}
      isLoading={isLoading}
      isNewPlot={isNewPlot}
      newPlotDefaults={newPlotDefaults}
      errorMessage={errorMessage}
      areaOptions={areaOptions}
    />
  );
};
