import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const commentListDuck = makeReportingDuck(
  'comments',
  (filters) => getPaginated('/reports/comments/', filters),
  () => getApi('/reports/comments/info/')
);

export type CommentListAction = ReportingDuckActions<typeof commentListDuck>;

export const readingCommentListDuck = makeReportingDuck(
  'readingComments',
  (filters) => getPaginated('/reports/reading-comments/', filters),
  () => getApi('/reports/reading-comments/info/')
);

export type ReadingCommentListAction = ReportingDuckActions<
  typeof readingCommentListDuck
>;
