import React, { useState, useEffect, useRef, ReactNode } from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { saveAs } from 'file-saver';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { getApi } from 'util/backendapi/fetch';
import { useIsMounted } from 'util/hooks';
import { useHideModal } from 'components/base/modal/use-hide-modal';
import { AlertWarning } from 'components/base/alert/alert';

interface Props {
  taskId: string;
  fileTypeLabel: string;
}
export function QueuedExportModal(props: Props) {
  const { taskId, fileTypeLabel } = props;

  const isMounted = useIsMounted();
  const [pollCount, setPollCount] = useState(0);
  const [taskStatus, setTaskStatus] = useState<Enum.Task_STATUS>();
  const [media, setMedia] = useState<Model.Media>();
  const timerRef = useRef<NodeJS.Timeout>();

  const isTaskInProgress =
    !taskStatus || Enum.Task_STATUS_IN_PROGRESS.includes(taskStatus);

  useEffect(() => {
    if (isTaskInProgress) {
      // For the first 30 seconds, check status every 1 second.
      // After that, check status every 10 seconds.
      const nextPollDelay = pollCount < 30 ? 1000 : 10 * 1000;
      timerRef.current = setTimeout(async () => {
        try {
          const status = await getApi(`/tasks/${taskId}/`);
          if (!isMounted()) {
            return;
          }
          if (status.status === Enum.Task_STATUS.SUCCESS) {
            if (status.media) {
              if (!isMounted()) {
                return;
              }
              setMedia(status.media);
            }
          }
          setTaskStatus(status.status);
          setPollCount((pollCount) => pollCount + 1);
        } catch (e) {
          if (!isMounted()) {
            return;
          }
          setTaskStatus(Enum.Task_STATUS.FAILURE);
          setPollCount((pollCount) => pollCount + 1);
        }
      }, nextPollDelay);
    }
  }, [isMounted, isTaskInProgress, pollCount, taskId]);

  // If there's a scheduled polling when the component unmounts, cancel it.
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  if (isTaskInProgress) {
    return <ExportInProgressModalView fileTypeLabel={fileTypeLabel} />;
  } else if (taskStatus === Enum.Task_STATUS.SUCCESS && media) {
    return (
      <ExportCompletedModalView media={media} fileTypeLabel={fileTypeLabel} />
    );
  } else {
    return <ExportErrorModalView fileTypeLabel={fileTypeLabel} />;
  }
}

interface InProgressViewProps {
  fileTypeLabel: ReactNode;
}
export function ExportInProgressModalView(props: InProgressViewProps) {
  const { fileTypeLabel } = props;
  return (
    <ModalContent
      header={<Trans>Your {fileTypeLabel} is being generated</Trans>}
    >
      {/* NOTE: Using an <a> tag instead of a <Link> to ensure the page reloads
            if the user is already on the My Exports report screen when they click
            this link.
        */}
      <Trans>
        <p>
          Exporting a large {fileTypeLabel} can take a couple of minutes so the
          export is happening in the background.
        </p>
        <p>
          You can close this message and your {fileTypeLabel} will be available
          in <a href="/my-exports">My Exports</a> when it's complete.
        </p>
        <p>
          Alternately you can wait here and this message will update with a
          download button when your {fileTypeLabel} is available.
        </p>
      </Trans>
      <ActionBlock>
        <ButtonHideModal>
          <Trans>Close</Trans>
        </ButtonHideModal>
      </ActionBlock>
    </ModalContent>
  );
}

interface CompletedViewProps {
  media: Model.Media;
  fileTypeLabel: ReactNode;
}
export function ExportCompletedModalView(props: CompletedViewProps) {
  const { media, fileTypeLabel } = props;
  const hideModal = useHideModal();
  return (
    <ModalContent
      header={<Trans>Your {fileTypeLabel} has finished exporting.</Trans>}
    >
      <p>
        {/* NOTE: Using an <a> tag instead of a <Link> to ensure the page reloads
            if the user is already on the media report screen when they click
            this link.
        */}
        <Trans>
          Your {fileTypeLabel} is available in{' '}
          <a href="/my-exports">My Exports</a>, or you can save it now by
          selecting the Download {fileTypeLabel} button below.
        </Trans>
      </p>
      <ActionBlock>
        <ButtonHideModal>
          <Trans>Close</Trans>
        </ButtonHideModal>
        <ButtonPrimary
          onClick={() => {
            saveAs(media.file, media.file_name);
            hideModal();
          }}
        >
          <Trans>Download {fileTypeLabel}</Trans>
        </ButtonPrimary>
      </ActionBlock>
    </ModalContent>
  );
}

interface ErrorViewProps {
  fileTypeLabel: ReactNode;
}
export function ExportErrorModalView(props: ErrorViewProps) {
  const { fileTypeLabel } = props;
  return (
    <ModalContent
      header={<Trans>Your {fileTypeLabel} export has failed.</Trans>}
    >
      <AlertWarning>
        <Trans>
          An error occurred while trying to produce your {fileTypeLabel}. Please
          close this message and try exporting the {fileTypeLabel} again. If it
          continues to fail, please contact support.
        </Trans>
      </AlertWarning>
      <ActionBlock>
        <ButtonHideModal>
          <Trans>Close</Trans>
        </ButtonHideModal>
      </ActionBlock>
    </ModalContent>
  );
}
