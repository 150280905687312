import { PolymorphicStoredPlotWithArea } from 'ducks/stored-plot/detail';
import { StandardThunk } from 'main/store';
import { Enum } from 'util/backendapi/models/api.interfaces';
import {
  fetchSurveyLevellingPlotData,
  selectSurveyLevellingPlotData,
  unmountSurveyLevellingPlot,
} from './survey-levelling';
import {
  fetchSpatialPlotData,
  fetchWanderPlotData,
  selectSpatialPlotData,
  unmountSpatialPlot,
} from './spatial';
import { buildStoredPlotKey } from 'components/plots/timeseriesplot.types';
import {
  resolvePlotSettingsFromStoredPlot,
  fetchReadingsFromResolvedSettings,
  unmountPlotPage,
} from './scatter-time-series';
import { computePlotDates } from 'screens/quickplot/quickplotselectors';
import {
  fetchCumulativeStoredPlotData,
  unmountCumulativePlot,
} from './cumulative';

/**
 * Initiates the fetching of the readings data for a stored plot. In order
 * to safely use this for pre-fetching the next plot in a plot set, it will
 * avoid re-fetching if it looks like the data has already been fetched.
 * @param storedPlot
 */
export function fetchStoredPlotReadings(
  storedPlot: PolymorphicStoredPlotWithArea
): StandardThunk {
  return async (dispatch, getState) => {
    switch (storedPlot.plot_type) {
      case Enum.PlotType.SURVEY_LEVELLING:
        if (selectSurveyLevellingPlotData(getState(), storedPlot.id)) {
          return;
        }
        return dispatch(fetchSurveyLevellingPlotData(storedPlot));
      case Enum.PlotType.SPATIAL_CROSS_SECTION:
      case Enum.PlotType.SPATIAL_PLAN:
        if (selectSpatialPlotData(getState(), storedPlot.id)) {
          return;
        }
        return dispatch(fetchSpatialPlotData(storedPlot.id));
      case Enum.PlotType.SPATIAL_WANDER:
        if (selectSpatialPlotData(getState(), storedPlot.id)) {
          return;
        }
        return dispatch(fetchWanderPlotData(storedPlot));
      case Enum.PlotType.CUMULATIVE:
        return dispatch(fetchCumulativeStoredPlotData(storedPlot));
      case Enum.PlotType.TIME_SERIES:
      case Enum.PlotType.SCATTER: {
        // Nothing to fetch if the stored plot has no plot items!
        if (storedPlot.items.length === 0) {
          return;
        }

        // Check to see if we've already started resolving for this stored
        // plot's plot items, by looking to see if the first plot item is
        // present in the Redux store.
        if (
          getState().plot.scatterTimeSeries.plots[
            buildStoredPlotKey(storedPlot.id, storedPlot.items[0]?.id)
          ]
        ) {
          return;
        }

        const resolvedSettingsForAllPlotItems = await dispatch(
          resolvePlotSettingsFromStoredPlot(storedPlot)
        );
        return Promise.all(
          resolvedSettingsForAllPlotItems.map(
            ({ plotKey, payload: resolvedSettings }) => {
              const {
                minDatetime,
                maxDatetime,
                paddedMinDatetime,
                paddedMaxDatetime,
              } = computePlotDates(resolvedSettings);
              return dispatch(
                fetchReadingsFromResolvedSettings(
                  plotKey,
                  resolvedSettings,
                  minDatetime,
                  maxDatetime,
                  paddedMinDatetime,
                  paddedMaxDatetime,
                  false
                )
              );
            }
          )
        );
      }
    }
  };
}

export function unmountStoredPlotReadings(
  plotType: Enum.PlotType
): StandardThunk {
  return async (dispatch) => {
    switch (plotType) {
      case Enum.PlotType.SURVEY_LEVELLING:
        return dispatch(unmountSurveyLevellingPlot());
      case Enum.PlotType.SPATIAL_CROSS_SECTION:
      case Enum.PlotType.SPATIAL_PLAN:
      case Enum.PlotType.SPATIAL_WANDER:
        return dispatch(unmountSpatialPlot());
      case Enum.PlotType.CUMULATIVE:
        return dispatch(unmountCumulativePlot());
      case Enum.PlotType.TIME_SERIES:
      case Enum.PlotType.SCATTER:
        return dispatch(unmountPlotPage());
    }
  };
}
