import React, { useState } from 'react';
import { t } from '@lingui/macro';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Icon } from 'components/base/icon/icon';
import { DMSLink } from 'components/base/link/DMSLink';
import { DMSHotKey } from 'main/DMSHotKey';

interface AreaMarkerProps {
  area: Model.AreaCoordinates & { wgs84_coordinates: Model.LatLong };
  clusterer: Clusterer;
  isOpen?: boolean;
}

/**
 * A map marker for a single area on the group screen
 *
 * @param props
 */
export function AreaMarker(props: AreaMarkerProps) {
  const [marker, setMarker] = useState<google.maps.Marker>();
  const [isOpen, setIsOpen] = useState(props.isOpen);
  const { area, clusterer } = props;

  return (
    <>
      <Marker
        position={area.wgs84_coordinates}
        onLoad={setMarker}
        onClick={() => setIsOpen(!isOpen)}
        clusterer={clusterer}
        title={`${area.code} - ${area.name}`}
      />
      {isOpen && marker && (
        <InfoWindow anchor={marker} onCloseClick={() => setIsOpen(false)}>
          <div className="plot-popup-map">
            <div className="popup-header">
              <DMSHotKey shortcut="ESC" onPress={() => setIsOpen(false)} />
              <h3>
                {area.code} - {area.name}
              </h3>
              <DMSLink to={`/maps/areas/${area.code}`}>
                <Icon type="icon-observation-point" title={t`View`} />
              </DMSLink>
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
}
