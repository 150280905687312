import React, { useMemo } from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  DEFAULT_HIDE,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { Trans, t } from '@lingui/macro';
import { DMSLink } from 'components/base/link/DMSLink';
import { formatDatetimeForDisplay } from 'util/dates';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { FormulaConstantDecorated } from 'util/backendapi/types/Model';
import { LinkIfHas } from 'components/logic/link-if-has-permission/LinkIfHas';
import { reportFilterAreas } from 'components/modules/report/filter/fields/reportFilterAreas';
import {
  reportFilterDatetime,
  NO_TIMEZONE,
} from 'components/modules/report/filter/fields/FilterDate';
import { RouteComponentProps } from 'react-router';
import { useReportState, ReportStateProps } from 'hooks/use-report-state';
import { observationPointFormulaConstantDuck } from 'ducks/observation-point-formula-constant/list';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { deleteApi } from 'util/backendapi/fetch';

export const opfcvColumns = (reportProps?: {
  refreshList?: () => void;
}): ReportColumn<Model.ReportsObservationPointFormulaConstantValue>[] => [
  {
    label: <Trans>Observation point</Trans>,
    name: 'observation-point',
    backendFieldName: 'observation_point_formula__observation_point__code',
    visibility: DEFAULT_SHOW,
    accessor: ({
      observation_point_formula__observation_point__code: code,
    }) => (
      <DMSLink
        to={{
          pathname: `/observation-point/${code}`,
          hash: 'card-formula-header',
        }}
      >
        {code}
      </DMSLink>
    ),
  },
  {
    label: <Trans>Formula</Trans>,
    name: 'formula',
    backendFieldName: 'formula_constant__formula__code',
    additionalFields: ['formula_constant__formula__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      formula_constant__formula__code: code,
      formula_constant__formula__name: name,
    }) => `${code} - ${name}`,
  },
  {
    label: <Trans>Constant</Trans>,
    name: 'formula-constant',
    backendFieldName: 'formula_constant__description',
    visibility: DEFAULT_SHOW,
    accessor: ({ formula_constant__description: description }) => description,
  },
  {
    // Readings with a reading datetime later than this will have this constant
    // applied to them.
    label: <Trans>Start date</Trans>,
    name: 'start-date',
    backendFieldName: 'start_datetime',
    additionalFields: [
      'observation_point_formula__observation_point__site__area__time_zone__name',
    ],
    hideFieldsFromCSV: [
      'observation_point_formula__observation_point__site__area__time_zone__name',
    ],
    visibility: DEFAULT_SHOW,
    accessor: ({
      start_datetime,
      observation_point_formula__observation_point__site__area__time_zone__name:
        timeZone,
      // Displayed in dam time because it's compared to reading datetimes
    }) => formatDatetimeForDisplay(start_datetime, timeZone),
  },
  {
    label: <Trans>Value</Trans>,
    name: 'value',
    backendFieldName: 'value',
    visibility: DEFAULT_SHOW,
    accessor: ({ value }) => value,
  },
  {
    label: <Trans>Changed by</Trans>,
    name: 'changed-by',
    backendFieldName: 'history__actor__profile__name',
    additionalFields: ['history__actor__username', 'history__actor'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      history__actor__profile__name: name,
      history__actor__username: username,
      history__actor: id,
    }) =>
      id && (
        <LinkIfHas
          permission="can_view_other_user_profiles"
          to={`/user/${id}`}
        >{`${name} (${username})`}</LinkIfHas>
      ),
  },
  {
    // The date this record was created/edited in the system.
    label: <Trans>Changed date and time</Trans>,
    name: 'changed-datetime',
    backendFieldName: 'history__timestamp',
    visibility: DEFAULT_SHOW,
    accessor: ({
      history__timestamp,
      // Displayed in user time because this date is *not* compared to reading datetimes
    }) => history__timestamp && formatDatetimeForDisplay(history__timestamp),
  },
  {
    label: <Trans>Area</Trans>,
    name: 'area',
    backendFieldName:
      'observation_point_formula__observation_point__site__area__code',
    additionalFields: [
      'observation_point_formula__observation_point__site__area__name',
    ],
    visibility: DEFAULT_HIDE,
    accessor: ({
      observation_point_formula__observation_point__site__area__code: code,
      observation_point_formula__observation_point__site__area__name: name,
    }) => `${code} - ${name}`,
  },
  {
    ...ACTION_COLUMN,
    additionalFields: [
      'formula_constant__description',
      'value',
      'observation_point_formula__observation_point__code',
      'formula_constant__formula__code',
      'id',
    ],
    accessor: ({
      formula_constant__description,
      value,
      observation_point_formula__observation_point__code,
      formula_constant__formula__code,
      id,
    }) => (
      <>
        <HasPermission check="can_delete_observation_point_formulas">
          <ButtonShowConfirmation
            destructive={true}
            iconOnly={true}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            title={t`Delete`}
            name={`delete-observation-point-formula-instance-${id}`}
            content={
              <Trans>
                Are you sure you want to delete the{' '}
                <strong>{formula_constant__description}</strong> constant with
                value <strong>{value}</strong> from the{' '}
                <strong>
                  {observation_point_formula__observation_point__code}
                </strong>{' '}
                observation point? This action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async () => {
              await deleteApi(
                `/observation-point-formula-constant-values/${id}/`
              );
              reportProps?.refreshList?.();
            }}
          />
        </HasPermission>
      </>
    ),
  },
];

export const opfcvFilters: ReportFilter[] = [
  reportFilterObservationPoints('observation_point_formula__observation_point'),
  reportFilterMenu(
    'formula_constant',
    <Trans>Constant</Trans>,
    {
      isMulti: true,
      valueType: 'number',
    },
    (opt: FormulaConstantDecorated) => ({
      value: opt.id,
      label: `${opt.formula.code} - ${opt.description}`,
    })
  ),
  reportFilterMenu(
    'formula_constant__formula',
    <Trans>Formula</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All formulas</Trans>, autoFocus: true }
  ),
  reportFilterMenu(
    'observation_point_formula__observation_point__instrument_type',
    <Trans>Instrument type</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All instrument types</Trans> }
  ),
  reportFilterUsers('history__actor', <Trans>Changed by</Trans>, {
    placeholder: <Trans>All DMS users</Trans>,
  }),
  reportFilterAreas('observation_point_formula__observation_point__site__area'),
  reportFilterDatetime(
    'start_datetime',
    <Trans>Start date</Trans>,
    // per-row dam time
    NO_TIMEZONE,
    [
      // no "is_blank" option, because this is not a nullable field
      'is',
      'is_after',
      'is_before',
      'is_between',
      'is_within_last',
      'is_within_next',
    ]
  ),
  reportFilterDatetime(
    'history__timestamp',
    <Trans>Changed date</Trans>,
    // user time
    null,
    [
      // no "is_blank" option, because this is not a nullable field
      'is',
      'is_after',
      'is_before',
      'is_between',
      'is_within_last',
      'is_within_next',
    ]
  ),
];

export function ObservationPointFormulaConstantListScreen(
  props: RouteComponentProps
) {
  const reportState = useReportState(
    props,
    opfcvColumns(),
    opfcvFilters,
    observationPointFormulaConstantDuck,
    (state) => state.obsPointFormulaConstant
  );

  return <ObservationPointFormulaConstantListView reportProps={reportState} />;
}

export function ObservationPointFormulaConstantListView(props: {
  reportProps: ReportStateProps<Model.ReportsObservationPointFormulaConstantValue>;
}) {
  const { reportProps } = props;

  const columns = useMemo(
    () => opfcvColumns({ refreshList: props.reportProps.refreshList }),
    [props.reportProps.refreshList]
  );

  return (
    <PageStandard
      name="observation-point-formula-constant-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Formula constant report</Trans>
        ) : (
          <Trans>Formula Constants</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <ReportFiltersBlock
          filtersFrontend={opfcvFilters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
          filtersToShow={[
            'observation_point_formula__observation_point',
            'formula_constant',
            'formula_constant__formula',
            'history__actor',
          ]}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={opfcvFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Formula constant report`}
            reportUrl={'/reports/observation-point-formula-constants/'}
            columns={columns}
            filters={opfcvFilters}
          />
          <AdvancedFiltersModalButton
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={opfcvFilters}
            sections={[
              {
                name: 'main_section',
                filters: [
                  'observation_point_formula__observation_point__site__area',
                  'observation_point_formula__observation_point',
                  'formula_constant',
                  'formula_constant__formula',
                  'observation_point_formula__observation_point__instrument_type',
                ],
              },
              {
                name: 'start_datetime_section',
                filters: ['start_datetime'],
              },
              {
                name: 'author_section',
                filters: ['history__actor', 'history__timestamp'],
              },
            ]}
          />
        </ActionBlock>

        <ReportTable<Model.ReportsObservationPointFormulaConstantValue>
          columns={columns}
          filters={opfcvFilters}
          msgNoMatches={
            <Trans>No formula constants match the selected filters.</Trans>
          }
          {...reportProps}
        />
      </div>
    </PageStandard>
  );
}
