import React from 'react';
import { Trans, Plural } from '@lingui/macro';
import { AlertDanger } from 'components/base/alert/alert';
import { Model } from 'util/backendapi/models/api.interfaces';
import Loading from 'components/base/loading/loading';

export type ConfirmReprocessingRequestProps = {
  isLoading: boolean;
  readingsCountData: Model.ReadingProcessingRequestCount[];
  error: string | null;
};

export const ConfirmReprocessingRequestView = (
  props: ConfirmReprocessingRequestProps
) => {
  if (props.isLoading) {
    return <Loading />;
  }

  if (props.error) {
    return (
      <AlertDanger>
        <Trans>{props.error}</Trans>
      </AlertDanger>
    );
  }

  if (!props.readingsCountData) {
    return null;
  }

  const numberObsPoints = props.readingsCountData.length;
  const numberReadings = props.readingsCountData.reduce(
    (acc, item) => acc + parseInt(item.count),
    0
  );

  return (
    <>
      <Trans>Are you sure you want to confirm this reprocessing request?</Trans>{' '}
      <Plural
        value={numberReadings}
        one={
          <Plural
            value={numberObsPoints}
            one={
              <span>
                Approximately <strong>1 reading</strong> from{' '}
                <strong>1 observation point</strong> will be reprocessed. This
                action is not reversible.
              </span>
            }
            other={
              <span>
                Approximately <strong>1 reading</strong> from{' '}
                <strong>{numberObsPoints} observation points</strong> will be
                reprocessed. This action is not reversible.
              </span>
            }
          />
        }
        other={
          <Plural
            value={numberObsPoints}
            one={
              <span>
                Approximately <strong>{numberReadings} readings</strong> from{' '}
                <strong>1 observation point</strong> will be reprocessed. This
                action is not reversible.
              </span>
            }
            other={
              <span>
                Approximately <strong>{numberReadings} readings</strong> from{' '}
                <strong>{numberObsPoints} observation points</strong> will be
                reprocessed. This action is not reversible.
              </span>
            }
          />
        }
      />
    </>
  );
};
