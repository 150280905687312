import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { SectionProps } from '../../maintobspoint.types';
import {
  ObsPointAliasSectionProps,
  ObsPointAliasSectionView,
} from './ObsPointAliasSectionView';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import { selectHasPermission } from 'util/user';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { StandardDispatch } from 'main/store';
import {
  fetchObsPointDetailAliases,
  updateObsPointAliases,
  deleteObsPointAlias,
} from 'ducks/obsPoint/detail/alias';

type Props = {
  observationPointCode: string;
  observationPointId: number | null;
} & Pick<
  SectionProps,
  | 'startEditing'
  | 'stopEditing'
  | 'isEditing'
  | 'isDisabled'
  | 'isLoading'
  | 'isSubmitting'
>;

type StateProps = Pick<
  ObsPointAliasSectionProps,
  'hasEditAliasPermission' | 'observationPointAliases' | 'isLoading'
> & {
  loadedObsPoint: number | null;
  errorMessage: string | null;
};

export const ObsPointAliasSection: React.FunctionComponent<Props> = function (
  props
) {
  const state = useShallowEqualSelector(
    (state: FullState): StateProps => ({
      isLoading: state.obsPoint.detail.alias.isLoading || props.isLoading,
      hasEditAliasPermission: selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_observation_point_aliases
      ),
      observationPointAliases: state.obsPoint.detail.alias.aliases,
      errorMessage: state.obsPoint.detail.alias.error,
      loadedObsPoint: state.obsPoint.detail.alias.loadedObsPoint,
    })
  );

  const dispatch = useDispatch<StandardDispatch>();

  useEffect(() => {
    // Load the aliases as soon as we know the observation point's id
    if (
      props.observationPointId &&
      props.observationPointId !== state.loadedObsPoint
    ) {
      dispatch(fetchObsPointDetailAliases(props.observationPointId));
    }
  }, [dispatch, props.observationPointId, state.loadedObsPoint]);

  const handleSubmit = useCallback(
    (
      aliases: Array<{ id?: number; reading_position: number; alias: string }>
    ) =>
      dispatch(
        updateObsPointAliases(
          props.observationPointId!,
          aliases.map((a) => ({
            ...a,
            observation_point: props.observationPointId!,
          }))
        )
      ),
    [dispatch, props.observationPointId]
  );

  const handleDelete = useCallback(
    (aliasId: number) =>
      dispatch(deleteObsPointAlias(props.observationPointId!, aliasId)),
    [dispatch, props.observationPointId]
  );

  return (
    <ObsPointAliasSectionView
      {...props}
      {...state}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  );
};
