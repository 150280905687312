import { Model } from 'util/backendapi/models/api.interfaces';
import { DRFError, errorToString } from 'util/backendapi/error';
import { StandardThunk } from 'main/store';
import { getApi, patchApi, postApi } from 'util/backendapi/fetch';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { CivilFeature_STATUS } from 'util/backendapi/types/Enum';

export const ActionTypes = {
  FETCH_AREA_START: 'dms/area/detail/FETCH_AREA_START',
  FETCH_AREA_RESPONSE: 'dms/area/detail/FETCH_AREA_RESPONSE',
  FETCH_AREA_ERROR: 'dms/area/detail/FETCH_AREA_ERROR',
  CLEAR_AREA_FORM: 'dms/area/detail/CLEAR_AREA_FORM',
  UPDATE_AREA_START: 'dms/area/detail/UPDATE_AREA_START',
  UPDATE_AREA_RESPONSE: 'dms/area/detail/UPDATE_AREA_RESPONSE',
  UPDATE_AREA_ERROR: 'dms/area/detail/UPDATE_AREA_ERROR',
  CREATE_AREA_START: 'dms/area/detail/CREATE_AREA_START',
  CREATE_AREA_RESPONSE: 'dms/area/detail/CREATE_AREA_RESPONSE',
  CREATE_AREA_ERROR: 'dms/area/detail/CREATE_AREA_ERROR',
} as const;

export const ActionCreators = {
  FETCH_AREA_START: (areaCode: string) => ({
    type: ActionTypes.FETCH_AREA_START,
    areaCode,
  }),
  FETCH_AREA_RESPONSE: (
    area: Model.AreaDecorated,
    civilFeatures: Model.CivilFeature[]
  ) => ({
    type: ActionTypes.FETCH_AREA_RESPONSE,
    area,
    civilFeatures,
  }),
  FETCH_AREA_ERROR: (areaCode: string, error: DRFError | Error) => ({
    type: ActionTypes.FETCH_AREA_ERROR,
    areaCode,
    error: true,
    payload: errorToString(error),
  }),
  CLEAR_AREA_FORM: () => ({
    type: ActionTypes.CLEAR_AREA_FORM,
  }),
  UPDATE_AREA_START: (areaId: number) => ({
    type: ActionTypes.UPDATE_AREA_START,
    areaId,
  }),
  UPDATE_AREA_RESPONSE: (
    area: Model.AreaDecorated,
    civilFeatures: Model.CivilFeature[]
  ) => ({
    type: ActionTypes.UPDATE_AREA_RESPONSE,
    area,
    civilFeatures,
  }),
  UPDATE_AREA_ERROR: (areaId: number) => ({
    type: ActionTypes.UPDATE_AREA_ERROR,
    areaId,
  }),
  CREATE_AREA_START: () => ({
    type: ActionTypes.CREATE_AREA_START,
  }),
  CREATE_AREA_RESPONSE: (area: Model.AreaDecorated) => ({
    type: ActionTypes.CREATE_AREA_RESPONSE,
    payload: area,
  }),
  CREATE_AREA_ERROR: () => ({
    type: ActionTypes.CREATE_AREA_ERROR,
  }),
} as const;

export type AreaDetailAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export interface AreaDetailState {
  loading: boolean;
  errorMessage: string | null;
  areaCode: null | string;
  area: null | Model.AreaDecorated;
  civilFeatures: Model.CivilFeature[];
  updatingAreaId: null | number;
}

export function initialAreaDetailState(): AreaDetailState {
  return {
    loading: false,
    errorMessage: null,
    areaCode: null,
    area: null,
    updatingAreaId: null,
    civilFeatures: [],
  };
}

export function areaDetailReducer(
  state = initialAreaDetailState(),
  action: AreaDetailAction
): AreaDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_AREA_START:
      return {
        ...initialAreaDetailState(),
        loading: true,
        areaCode: action.areaCode,
      };
    case ActionTypes.FETCH_AREA_RESPONSE:
      if (state.areaCode !== action.area.code) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          area: action.area ?? null,
          civilFeatures: action.civilFeatures,
        };
      }
    case ActionTypes.FETCH_AREA_ERROR:
      if (state.areaCode !== action.areaCode) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      }
    case ActionTypes.CLEAR_AREA_FORM:
      return initialAreaDetailState();

    case ActionTypes.UPDATE_AREA_START:
      return {
        ...state,
        updatingAreaId: action.areaId,
      };
    case ActionTypes.UPDATE_AREA_RESPONSE:
      if (state.updatingAreaId !== action.area.id) {
        return state;
      } else {
        return {
          ...state,
          area: action.area,
          civilFeatures: action.civilFeatures,
          updatingAreaId: null,
        };
      }
    default:
      return state;
  }
}

export function fetchArea(areaCode: string): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_AREA_START(areaCode));
    try {
      const [[area], civilFeatures] = await Promise.all([
        getApi(`/areas/`, { code: areaCode }),
        fetchAreaCodeActiveCivilFeatures(areaCode),
      ]);

      if (!area) {
        throw new Error(i18n._(t`Area not found.`));
      }

      dispatch(ActionCreators.FETCH_AREA_RESPONSE(area, civilFeatures));
    } catch (e) {
      dispatch(ActionCreators.FETCH_AREA_ERROR(areaCode, e));
    }
  };
}

export function fetchAreaCodeActiveCivilFeatures(areaCode: string) {
  return getApi('/civil-features/', {
    area__code: areaCode,
    status: CivilFeature_STATUS.active,
  });
}

export function fetchAreaIdActiveCivilFeatures(areaId: number) {
  return getApi('/civil-features/', {
    area: areaId,
    status: CivilFeature_STATUS.active,
  });
}

export function updateArea(
  areaId: number,
  payload: Model.Area_PATCH
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.UPDATE_AREA_START(areaId));
    try {
      const area = await patchApi(`/areas/${areaId}/`, payload);
      const civilFeatures = await fetchAreaIdActiveCivilFeatures(areaId);

      dispatch(ActionCreators.UPDATE_AREA_RESPONSE(area, civilFeatures));
      return area;
    } catch (e) {
      dispatch(ActionCreators.UPDATE_AREA_ERROR(areaId));
      throw e;
    }
  };
}

export function createArea(payload: ForPostOrPut<Model.Area>): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.CREATE_AREA_START());
    try {
      const area = await postApi(`/areas/`, payload);
      dispatch(ActionCreators.CREATE_AREA_RESPONSE(area));
      return area;
    } catch (e) {
      dispatch(ActionCreators.CREATE_AREA_ERROR());
      throw e;
    }
  };
}
