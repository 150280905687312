import React, { memo } from 'react';
import { useGetApi } from 'hooks/use-get-api';
import { DMSLink } from 'components/base/link/DMSLink';
import { Icon } from 'components/base/icon/icon';
import { t, Trans } from '@lingui/macro';
import Button from 'components/base/button/button';
import {
  SpatialWanderPlotObservationPoint,
  StoredSpatialWanderPlotSurveyPoint,
} from 'util/backendapi/types/Model';
import { formatDatetimeForDisplay } from 'util/dates';
import { getObservationPointItemUrlCode } from 'components/plots/timeseriesplotselectors';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import ErrorNotice from 'components/base/form/errornotice/errornotice';

export interface WanderLineRawReading {
  movement: string;
  errorSize: string;
  errorBearing: string;
}
export interface WanderLinePlotPoint {
  x: number;
  y: number;
  surveyDatetime: string;
  errorSemiMajor: string;
  errorSemiMinor: string;
  errorBearing: string;
  eastingReading: WanderLineRawReading;
  northingReading: WanderLineRawReading;
  surveyPoint: StoredSpatialWanderPlotSurveyPoint;
  coordsOpId: number;
}

interface Props {
  point: WanderLinePlotPoint;
  onClose: () => void;
  obsPoints: SpatialWanderPlotObservationPoint[];
  timeZone: string;
}

export const WanderPlotSitePopup = memo((props: Props) => {
  const {
    point: {
      surveyPoint,
      coordsOpId,
      surveyDatetime,
      northingReading,
      eastingReading,
    },
    onClose,
    obsPoints,
    timeZone,
  } = props;
  const [{ data: site, error: siteError }] = useGetApi(
    `/sites/${surveyPoint.site}/`
  );
  const [{ data: coordsObsPoint, error: coordsError }] = useGetApi(
    `/observation-points/${coordsOpId}/`,
    {
      fields: ['wgs84_coordinates'],
    }
  );

  return (
    <div className="plot-popup-spatial">
      <div className="popup-header">
        <h3>{site?.code ?? surveyPoint.label}</h3>
        {formatDatetimeForDisplay(surveyDatetime, timeZone)}
        {site && (
          <HasPermission check="can_view_site_report">
            &nbsp;
            <DMSLink to={`/sites/${site.code}`}>
              <Icon type="icon-view" title={t`View`} />
            </DMSLink>
          </HasPermission>
        )}
        <Button
          iconOnly
          onClick={onClose}
          shortcut="ESC"
          iconType="icon-cross"
          className="close"
        >
          <Trans>Close</Trans>
        </Button>
      </div>
      {siteError && <ErrorNotice>{siteError}</ErrorNotice>}
      {coordsError && <ErrorNotice>{coordsError}</ErrorNotice>}
      <div className="popup-content">
        <p>
          <strong>
            <Trans>Latitude:</Trans>
          </strong>{' '}
          {coordsObsPoint?.wgs84_coordinates?.lat ?? '...'}
          <br />
          <strong>
            <Trans>Longitude:</Trans>
          </strong>{' '}
          {coordsObsPoint?.wgs84_coordinates?.lng ?? '...'}
        </p>
        {[
          {
            obsPointId: surveyPoint.northing_observation_point,
            reading: northingReading,
          },
          {
            obsPointId: surveyPoint.easting_observation_point,
            reading: eastingReading,
          },
        ].map(({ obsPointId, reading }) => {
          const obsPoint = obsPoints.find((op) => op.id === obsPointId);
          if (!obsPoint) {
            return null;
          }
          return (
            <div key={obsPoint.id}>
              <h4>{obsPoint.code}</h4>
              <p>
                Movement: {reading.movement}
                <br />
                Std Error: {reading.errorSize}
                <br />
                Bearing: {reading.errorBearing}
              </p>
              <div className="action-icons">
                <HasPermission check="can_view_observation_points">
                  <DMSLink to={`/observation-point/${obsPoint.code}`}>
                    <Icon type="icon-view" title={t`View`} />
                  </DMSLink>{' '}
                </HasPermission>
                <HasPermission check="can_view_quick_plots">
                  <DMSLink
                    to={`/quickplot/${[1, 2, 3]
                      .map((i) =>
                        getObservationPointItemUrlCode(obsPoint.code, i)
                      )
                      .join(',')}`}
                  >
                    <Icon type="icon-plot" title={t`Quick plot`} />
                  </DMSLink>{' '}
                </HasPermission>
                <HasPermission check="can_view_quick_lists">
                  <DMSLink to={`/quicklist/${obsPoint.code}`}>
                    <Icon type="icon-list" title={t`Quick list`} />
                  </DMSLink>
                </HasPermission>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
});
