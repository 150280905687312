import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { PolymorphicComment } from 'util/backendapi/types/Model';
import { patchApi } from 'util/backendapi/fetch';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import { FormikHelpers, FormikErrors, Formik, Form, Field } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { ButtonPrimary } from 'components/base/button/button';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { useGetApi } from 'hooks/use-get-api';
import Loading from 'components/base/loading/loading';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import {
  Comment_TYPE,
  Comment_RESOURCE_TYPE,
} from 'util/backendapi/types/Enum';
import { DatetimeField } from 'components/base/form/datefield/datefield';

type FormValues = Pick<
  PolymorphicComment,
  'content' | 'comment_type' | 'created_datetime' | 'resourcetype'
>;

interface Props {
  commentId: number;
  timeZone: string | null;
  onAfterSubmit: () => void;
}

export function EditCommentModal({
  commentId,
  onAfterSubmit,
  timeZone,
}: Props) {
  const isMounted = useIsMounted();
  const validate = useCallback(
    (values: FormValues): FormikErrors<FormValues> => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.comment_type) {
        errors.comment_type = (<Trans>Comment type is required.</Trans>) as any;
      }
      if (!values.created_datetime) {
        errors.created_datetime = (
          <Trans>Comment date and time are required.</Trans>
        ) as any;
      }

      return errors;
    },
    []
  );

  const [commentDetail] = useGetApi(`/comments/${commentId}/`);

  const commentTypeOptions: SimpleSelectOption<Comment_TYPE>[] = React.useMemo(
    () =>
      menuItemsFromEnum(
        'Comment_TYPE',

        Object.values(Comment_TYPE)
      ),
    []
  );

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      try {
        // NOTE: the backend requires you to supply resourcetype
        // even though we do not let you change it here
        const patchValues =
          values.resourcetype === Comment_RESOURCE_TYPE.readingGap
            ? { ...values, at_datetime: values.created_datetime }
            : values;
        await patchApi(`/comments/${commentId}/`, patchValues);
        onAfterSubmit();
      } catch (err) {
        if (isMounted()) {
          formik.setSubmitting(false);
          showErrorsInFormik(formik, err, getExpectedFields(values));
        }
      }
    },
    [commentId, onAfterSubmit, isMounted]
  );

  const initialValues = useMemo((): FormValues | null => {
    if (!commentDetail.data) {
      return null;
    }

    return {
      content: commentDetail.data.content,
      comment_type: commentDetail.data.comment_type,
      created_datetime: commentDetail.data.created_datetime,
      resourcetype: commentDetail.data.resourcetype,
    };
  }, [commentDetail.data]);

  return (
    <ModalContent header={<Trans>Edit comment</Trans>}>
      {commentDetail.isLoading ? <Loading /> : null}
      {initialValues ? (
        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {(formik) => (
            <Form>
              <FormSection>
                <FormItem label={<Trans>Data type</Trans>}>
                  <TransEnum
                    enum="Comment_RESOURCE_TYPE"
                    value={initialValues.resourcetype}
                  />
                </FormItem>
                <FormItem
                  label={<Trans>Comment type</Trans>}
                  fieldId="content-type"
                >
                  <SimpleSelectField
                    name="comment_type"
                    id="content-type"
                    options={commentTypeOptions}
                    placeholder={<Trans>Select type of comment</Trans>}
                  />
                  <FieldError name="comment_type" />
                </FormItem>
                <FormItem
                  label={
                    commentDetail.data?.resourcetype ===
                    Comment_RESOURCE_TYPE.readingGap ? (
                      <Trans>Gap date and time</Trans>
                    ) : (
                      <Trans>Comment date and time</Trans>
                    )
                  }
                  fieldId="created-datetime"
                >
                  <DatetimeField
                    name="created_datetime"
                    id="created-datetime"
                    timeZone={
                      // Gap comment times are shown in "dam time"
                      commentDetail.data?.resourcetype ===
                      Comment_RESOURCE_TYPE.readingGap
                        ? timeZone ?? undefined
                        : undefined
                    }
                  />
                  <FieldError name="created_datetime" />
                </FormItem>
                <FormItem
                  label={<Trans>Comment</Trans>}
                  fieldId="content-textarea"
                >
                  <Field
                    component="textarea"
                    name="content"
                    id="content-textarea"
                  />
                  <FieldError name="content" />
                </FormItem>
              </FormSection>
              {formik.status}
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="edit-comment-submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      ) : null}
    </ModalContent>
  );
}
