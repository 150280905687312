import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { AlertInfo } from 'components/base/alert/alert';
import { ButtonPrimary } from 'components/base/button/button';
import {
  DateField,
  DateFieldValue,
} from 'components/base/form/datefield/datefield';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { FormItem, FormSection } from 'components/base/form/FormItem';
import Loading from 'components/base/loading/loading';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import ModalContent from 'components/base/modal/modalcontent';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportPanelModalButton } from 'components/modules/exportpanel/exportpanel';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import {
  ALWAYS_SHOW,
  ReportColumn,
} from 'components/modules/report/report-types';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { ErrorMessage, Form, Formik, FormikErrors } from 'formik';
import React from 'react';
import { Enum, Filter, Model } from 'util/backendapi/models/api.interfaces';
import { PaginationMeta } from 'util/backendapi/pagination';
import {
  convertDatetimeToDate,
  convertDateToDatetime,
  formatDateForDisplay,
  formatDatetimeForDisplay,
  getCurrentDatetime,
  MID_DAY,
} from 'util/dates';
import { detectExportMode } from 'util/export';
import { BackButton } from 'components/base/back-button/BackButton';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';

export interface StoredListReportProps<
  TList extends Model.StoredList = Model.PolymorphicStoredList,
  TReading extends Model.StoredListReading = Model.PolymorphicStoredListReading
> {
  loading: boolean;
  errorMessage: string | null;
  storedList: TList | null;
  timeZone: string | null;
  storedListItems: Model.StoredListItemDecorated<TList>[];
  pagination: PaginationMeta | null;
  generatedDatetime: string | null;
  readings: TReading[];
  filters: Filter.StoredListsReadings;
  onSubmitSettings: (values: Partial<Filter.StoredListsReadings>) => void;
}

const storedListTypeNames: { [K in Enum.StoredList_TYPE]: JSX.Element } = {
  [Enum.StoredList_TYPE.latest_reading]: <Trans>Latest Readings</Trans>,
  [Enum.StoredList_TYPE.nearest_reading]: <Trans>Nearest Readings</Trans>,
  [Enum.StoredList_TYPE.selected_list]: <Trans>Selected List</Trans>,
  [Enum.StoredList_TYPE.summary_report]: (
    <Trans>Observation Point Summary Report</Trans>
  ),
};

export function StoredListReportView(props: StoredListReportProps) {
  let columns: ReportColumn[] = [];
  let description: React.ReactNode = null;
  let actionButtons: React.ReactNode = null;
  if (props.storedList) {
    // eslint-disable-next-line default-case
    switch (props.storedList.type) {
      case Enum.StoredList_TYPE.selected_list:
        columns = selectedListColumns(props as SelectedListProps);
        break;
      case Enum.StoredList_TYPE.latest_reading:
        columns = latestReadingColumns(props as LatestReadingProps);
        break;
      case Enum.StoredList_TYPE.nearest_reading:
        columns = nearestReadingColumns(props as NearestReadingProps);
        description = (
          <Trans>
            Nearest readings to:{' '}
            {props.timeZone && props.storedList.target_datetime ? (
              formatDatetimeForDisplay(
                props.storedList.target_datetime,
                props.timeZone
              )
            ) : (
              <Trans>...</Trans>
            )}
          </Trans>
        );
        break;
      case Enum.StoredList_TYPE.summary_report: {
        let previousDate =
          props.timeZone && props.filters.previous_datetime ? (
            formatDateForDisplay(
              convertDatetimeToDate(
                props.filters.previous_datetime,
                props.timeZone
              )
            )
          ) : (
            <Trans>...</Trans>
          );
        let currentDate =
          props.timeZone && props.filters.current_datetime ? (
            formatDateForDisplay(
              convertDatetimeToDate(
                props.filters.current_datetime,
                props.timeZone
              )
            )
          ) : (
            <Trans>...</Trans>
          );
        description = (
          <Trans>
            For current date: {currentDate} and previous date: {previousDate}.
          </Trans>
        );
        columns = summaryReportColumns(props as SummaryReportProps);
        actionButtons = summaryReportActionButtons(props as SummaryReportProps);
        break;
      }
    }
  }

  let renderedTable: React.ReactNode;
  if (!props.storedList) {
    renderedTable = <Loading />;
  } else {
    // Summary Report stored list requires some query params. When the page
    // loads we request the data without any params (because we only know
    // the stored list's ID, not its type), which causes the backend to
    // return a 4xx error "Missing parameters" if it's a summary report.
    // But that's not the user's fault, so instead in that scenario we'll
    // show them a nicer message.
    if (
      props.storedList.type === Enum.StoredList_TYPE.summary_report &&
      !(props.filters.current_datetime && props.filters.previous_datetime)
    ) {
      renderedTable = (
        <AlertInfo>
          <Trans>Please select a date range</Trans>
        </AlertInfo>
      );
    } else {
      renderedTable = (
        <ReportTable
          errorMessage={props.errorMessage}
          isLoading={props.loading}
          msgNoMatches={<Trans>No readings for this list</Trans>}
          pagination={props.pagination}
          records={props.readings}
          columns={columns}
        />
      );
    }
  }
  return (
    <PageStandard
      name="storedListReport"
      header={props.storedList && storedListTypeNames[props.storedList.type]}
      subHeader={props.storedList && props.storedList.name}
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton defaultBackUrl="/stored-lists" />
        <div className="page-content-header columns-fluid">
          {description && (
            <div>
              <p>{description}</p>
            </div>
          )}
          {!detectExportMode() && (
            <ActionBlock className="text-right">
              {actionButtons}
              <ButtonPrint id="storedListReport-print" />
              <HasPermission check="can_export_lists">
                <ExportPanelModalButton
                  canExportPdf={true}
                  canExportCsv={true}
                  taskQueueFormats={[ExportFormats.PDF]}
                />
              </HasPermission>
            </ActionBlock>
          )}
        </div>
      </div>
      {renderedTable}
    </PageStandard>
  );
}

type SelectedListProps = StoredListReportProps<
  Model.SelectedListStoredList,
  Model.SelectedListStoredListReading
>;

/**
 * Generate the table for a "Selected List" stored list.
 *
 * @param props
 */
function selectedListColumns(props: SelectedListProps) {
  let columns: ReportColumn<Model.SelectedListStoredListReading>[] = [
    {
      label: <Trans>Date and time</Trans>,
      name: 'reading_datetime',
      tdClassName: 'text-no-wrap',
      visibility: ALWAYS_SHOW,
      accessor: (reading) =>
        props.timeZone &&
        formatDatetimeForDisplay(reading.datetime, props.timeZone),
    },
  ];
  if (props.storedListItems.length === 0) {
    columns.push({
      name: 'loading-report-metadata',
      visibility: ALWAYS_SHOW,
      label: <Trans>...</Trans>,
      accessor: () => null,
    });
  } else {
    columns.push(
      ...props.storedListItems.map(
        (
          stored_list_item,
          idx
        ): ReportColumn<Model.SelectedListStoredListReading> => {
          const instrument_type_item =
            stored_list_item.observation_point.instrument_type.items.find(
              (item) => item.item_number === stored_list_item.item_number
            );
          return {
            name: `op-${stored_list_item.observation_point.code}`,
            visibility: ALWAYS_SHOW,
            label: (
              <>
                {stored_list_item.observation_point.code}
                <span className="table-header-secondary">
                  {instrument_type_item ? (
                    instrument_type_item.description
                  ) : (
                    <Trans>
                      [MISSING INSTRUMENT TYPE ITEM $
                      {stored_list_item.item_number}
                    </Trans>
                  )}
                </span>
              </>
            ),
            accessor: (reading) => reading.values[idx],
          };
        }
      )
    );
  }

  return columns;
}

type NearestReadingProps = StoredListReportProps<
  Model.NearestReadingStoredList,
  Model.NearestReadingStoredListReading
>;

/**
 * Generate the table for a "Nearest Reading" stored list.
 *
 * @param props
 */
function nearestReadingColumns(props: NearestReadingProps) {
  return sharedLatestNearestReadingColumns(
    props,
    Enum.StoredList_TYPE.nearest_reading
  );
}

type LatestReadingProps = StoredListReportProps<
  Model.LatestReadingStoredList,
  Model.LatestReadingStoredListReading
>;

/**
 * Generate the table for a "Latest Reading" stored list.
 *
 * @param props
 */
function latestReadingColumns(props: LatestReadingProps) {
  return sharedLatestNearestReadingColumns(
    props,
    Enum.StoredList_TYPE.latest_reading
  );
}

/**
 * Shared definition for nearest reading & latest reading stored lists,
 * because they're nearly identical.
 *
 * @param props
 * @param type
 */
function sharedLatestNearestReadingColumns(
  props: LatestReadingProps | NearestReadingProps,
  type:
    | Enum.StoredList_TYPE.latest_reading
    | Enum.StoredList_TYPE.nearest_reading
) {
  const columns: ReportColumn<
    Model.LatestReadingStoredListReading | Model.NearestReadingStoredListReading
  >[] = [
    {
      label: <Trans>Observation point</Trans>,
      name: 'observation-point',
      accessor: (reading) => reading.observation_point.code,
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Item description</Trans>,
      name: 'item-description',
      accessor: (reading) => reading.instrument_type_item.description,
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Instrument type</Trans>,
      name: 'instrument-type',
      accessor: (reading) => {
        return reading.instrument_type.name;
      },
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Date and time</Trans>,
      name: 'reading_datetime',
      tdClassName: 'text-no-wrap',
      accessor: (reading) =>
        props.timeZone &&
        formatDatetimeForDisplay(reading.datetime, props.timeZone),
      visibility: ALWAYS_SHOW,
    },
    {
      label:
        type === Enum.StoredList_TYPE.latest_reading ? (
          <Trans>Latest reading</Trans>
        ) : (
          <Trans>Nearest reading</Trans>
        ),
      name: 'reading_value',
      accessor: (reading) => reading.value,
      visibility: ALWAYS_SHOW,
    },
  ];
  if (props.storedList && props.storedList.list_comments) {
    columns.push({
      label: <Trans>Comment</Trans>,
      name: 'comments',
      tdClassName: 'text-with-linebreaks',
      accessor: (reading) => reading.comment.content,
      visibility: ALWAYS_SHOW,
    });
  }
  return columns;
}

type SummaryReportProps = StoredListReportProps<
  Model.SummaryReportStoredList,
  Model.SummaryReportStoredListReading
>;

/**
 * Generate the table for an "Observation point summary report" stored list
 */
function summaryReportColumns(
  props: SummaryReportProps
): ReportColumn<Model.SummaryReportStoredListReading>[] {
  return [
    {
      label: <Trans>Observation point</Trans>,
      name: 'observation-point',
      accessor: (reading) => reading.observation_point.code,
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Item description</Trans>,
      name: 'item-number',
      accessor: (reading) => reading.instrument_type_item.description,
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Instrument type</Trans>,
      name: 'item-description',
      accessor: (reading) => reading.instrument_type.name,
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Previous reading date and time</Trans>,
      tdClassName: 'text-no-wrap',
      name: 'previous_datetime',
      accessor: (reading) =>
        props.timeZone &&
        formatDatetimeForDisplay(reading.previous_datetime, props.timeZone),
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Previous value</Trans>,
      name: 'previous_value',
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Current reading date and time</Trans>,
      name: 'current_datetime',
      tdClassName: 'text-no-wrap',
      accessor: (reading) =>
        props.timeZone &&
        formatDatetimeForDisplay(reading.current_datetime, props.timeZone),
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Current value</Trans>,
      name: 'current_value',
      visibility: ALWAYS_SHOW,
    },
    {
      label: <Trans>Change</Trans>,
      name: 'change',
      visibility: ALWAYS_SHOW,
    },
  ];
}

type SummaryReportSettingsFormValues = {
  previous_datetime: DateFieldValue;
  current_datetime: DateFieldValue;
};

/**
 * Generate the "settings" button and modal, for an Observation Point
 * Summary Report stored list.
 * @param props
 */
function summaryReportActionButtons(props: SummaryReportProps) {
  const timeZone = props.timeZone && props.timeZone;
  return (
    <ButtonShowModal
      name="summary-report-stored-list-settings"
      iconType="icon-cog"
      autoShow={
        !(props.filters.previous_datetime && props.filters.current_datetime)
      }
      modalContent={({ hideModal }) => (
        <ModalContent header={<Trans>Stored list settings</Trans>}>
          <Formik<SummaryReportSettingsFormValues>
            initialValues={{
              previous_datetime: convertDatetimeToDate(
                props.filters.previous_datetime,
                timeZone
              ),
              current_datetime: convertDatetimeToDate(
                props.filters.current_datetime,
                timeZone
              ),
            }}
            onSubmit={(values: SummaryReportSettingsFormValues) => {
              props.onSubmitSettings({
                previous_datetime: values.previous_datetime
                  ? convertDateToDatetime(
                      values.previous_datetime,
                      timeZone,
                      MID_DAY
                    )
                  : undefined,
                current_datetime: values.current_datetime
                  ? convertDateToDatetime(
                      values.current_datetime,
                      timeZone,
                      MID_DAY
                    )
                  : undefined,
              });
              hideModal();
            }}
            validate={(
              values: SummaryReportSettingsFormValues
            ): FormikErrors<SummaryReportSettingsFormValues> => {
              const now = getCurrentDatetime();
              const errors: FormikErrors<SummaryReportSettingsFormValues> = {};

              if (values.previous_datetime === '') {
                errors.previous_datetime = (
                  <Trans>Previous date is required</Trans>
                ) as any;
              } else if (values.previous_datetime > now) {
                errors.previous_datetime = (
                  <Trans>Previous date must be in the past</Trans>
                ) as any;
              }

              if (values.current_datetime === '') {
                errors.current_datetime = (
                  <Trans>Current date is required</Trans>
                ) as any;
              } else if (values.current_datetime > now) {
                errors.current_datetime = (
                  <Trans>Current date must be in the past</Trans>
                ) as any;
              }

              if (
                !errors.previous_datetime &&
                !errors.current_datetime &&
                values.previous_datetime > values.current_datetime
              ) {
                errors.current_datetime = (
                  <Trans>
                    Previous date can not be later than current date
                  </Trans>
                ) as any;
              }
              return errors;
            }}
          >
            {() => (
              <Form>
                <FormSection label={<Trans>Time period</Trans>}>
                  <FormItem
                    label={<Trans>Previous date</Trans>}
                    fieldId="summary-report-previous_datetime"
                    className="form-group-panel-inline"
                  >
                    <DateField
                      id="summary-report-previous_datetime"
                      name="previous_datetime"
                      autoFocus
                    />
                  </FormItem>
                  <FormItem
                    label={<Trans>Current date</Trans>}
                    fieldId="summary-report-current_datetime"
                    className="form-group-panel-inline"
                  >
                    <DateField
                      id="summary-report-current_datetime"
                      name="current_datetime"
                    />
                  </FormItem>
                  <ErrorMessage
                    name="previous_datetime"
                    component={ErrorNotice}
                  />
                  <ErrorMessage
                    name="current_datetime"
                    component={ErrorNotice}
                  />
                </FormSection>
                <ActionBlock>
                  <ButtonHideModal id="summary-report-stored-list-cancel" />
                  <ButtonPrimary
                    id="summary-report-stored-list-submit"
                    type="submit"
                    iconType="icon-update"
                  >
                    <Trans>Apply settings</Trans>
                  </ButtonPrimary>
                </ActionBlock>
              </Form>
            )}
          </Formik>
        </ModalContent>
      )}
    >
      <Trans>Settings</Trans>
    </ButtonShowModal>
  );
}
