import React, { useState, useEffect, useCallback } from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import { fetchPaginationMetadata, postApi } from 'util/backendapi/fetch';
import { FetcherState } from 'hooks/use-get-api';
import {
  PaginationResponseData,
  PAGINATION_COUNT_LIMIT as __PAGINATION_COUNT_LIMIT,
} from 'util/backendapi/pagination';
import { errorToString } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import Loading from 'components/base/loading/loading';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { AlertWarning } from 'components/base/alert/alert';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { recalculateAdjustedReadings } from 'ducks/checkreadings';

// WORKAROUND: A bug in the `<Trans>` lingui macro causes an exception
// if we insert the constant `PAGINATION_COUNT_LIMIT` directly inside
// the `<Trans>`. Copying it to a local variable resolves the problem.
// @see https://github.com/lingui/js-lingui/issues/514
const PAGINATION_COUNT_LIMIT = __PAGINATION_COUNT_LIMIT;

interface Props {
  readingsBatch: Model.ListReadingsBatch;
}

export function CopyBatchModal(props: Props) {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const history = useHistory();
  const { readingsBatch } = props;

  const [newBatchStatus, setNewBatchStatus] = useState<
    null | 'copying' | 'calculating'
  >(null);

  const [fetcherState, setFetcherState] = useState<
    FetcherState<PaginationResponseData>
  >({
    data: null,
    isError: false,
    isLoading: false,
  });

  useEffect(() => {
    (async () => {
      setFetcherState({ data: null, isError: false, isLoading: true });
      try {
        const metadata = await fetchPaginationMetadata('/readings/', {
          superseded: true,
          readings_batch: readingsBatch.id,
        });
        if (isMounted()) {
          setFetcherState({ data: metadata, isError: false, isLoading: false });
        }
      } catch (e) {
        if (isMounted()) {
          setFetcherState({
            data: null,
            isError: true,
            isLoading: false,
            error: errorToString(e),
          });
        }
      }
    })();
  }, [isMounted, readingsBatch.id]);

  const handleSubmit = useCallback(async () => {
    try {
      setNewBatchStatus('copying');
      const response = await postApi(
        `/readings-batches/${readingsBatch.id}/copy/`
      );

      if (isMounted()) {
        setNewBatchStatus('calculating');
      }
      await dispatch(recalculateAdjustedReadings(response.id));

      if (isMounted()) {
        history.push(`/check-readings/${response.batch_number}`);
      }
    } catch (e) {
      if (isMounted()) {
        setNewBatchStatus(null);
        throw e;
      }
    }
  }, [dispatch, history, isMounted, readingsBatch.id]);

  return (
    <CopyBatchModalView
      loading={fetcherState.isLoading}
      error={fetcherState.error}
      numReadingsInBatch={readingsBatch.readings_count}
      numReadingsSuperseded={fetcherState.data?.total ?? null}
      onSubmit={handleSubmit}
      newBatchStatus={newBatchStatus}
    />
  );
}

interface ViewProps {
  numReadingsInBatch: number;
  numReadingsSuperseded: number | null;
  loading: boolean;
  error?: string;
  onSubmit: () => Promise<any>;
  newBatchStatus: 'copying' | 'calculating' | null;
}

export function CopyBatchModalView(props: ViewProps) {
  const {
    loading,
    error,
    onSubmit,
    numReadingsInBatch,
    numReadingsSuperseded,
    newBatchStatus,
  } = props;

  return (
    <ModalContent header={<Trans>Copy batch</Trans>}>
      {loading ? (
        <Loading>
          <Trans>Checking for current effective readings</Trans>
        </Loading>
      ) : error ? (
        <AlertWarning additionalInformation={error}>
          <Trans>Error checking for current effective readings</Trans>
        </AlertWarning>
      ) : (
        <Formik
          // Well... we wound up not needing *any* form fields for this!
          // But it's still sorta handy to handle it through Formik, for the
          // sake of handling form submission and error messages.
          initialValues={{}}
          onSubmit={async (_values, formik) => {
            try {
              await onSubmit();
            } catch (e) {
              showErrorsInFormik(formik, e, []);
              formik.setSubmitting(false);
            }
          }}
        >
          {(formik) => (
            <Form>
              {newBatchStatus === 'copying' ? (
                <Loading
                  additionalInformation={<Trans>Copying batch...</Trans>}
                />
              ) : newBatchStatus === 'calculating' ? (
                <Loading
                  additionalInformation={
                    <Trans>Calculating adjusted readings...</Trans>
                  }
                />
              ) : (
                <>
                  <p>
                    <Trans>
                      A new unprocessed batch will be created with copies of the
                      readings in this batch.
                    </Trans>
                  </p>
                  {numReadingsSuperseded !== 0 && (
                    <>
                      <h3>
                        <Trans>Warning</Trans>
                      </h3>
                      <p>
                        <Trans>
                          <strong>
                            {numReadingsSuperseded ?? (
                              <Trans>Over {PAGINATION_COUNT_LIMIT}</Trans>
                            )}
                          </strong>{' '}
                          out of <strong>{numReadingsInBatch}</strong> readings
                          in this batch are not the current effective readings.
                        </Trans>
                      </p>
                    </>
                  )}
                </>
              )}
              {formik.status}
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="copy-batch-submit"
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Copy</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      )}
    </ModalContent>
  );
}
