import orderBy from 'lodash/orderBy';
import { Model } from 'util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions } from 'main/store';
import { getApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  FETCH_DATA_LOGGER_CHANNEL_START:
    'dms/obsPoint/detail/dataLoggerChannel/FETCH_DATA_LOGGER_CHANNEL_START',
  FETCH_DATA_LOGGER_CHANNEL_ERROR:
    'dms/obsPoint/detail/dataLoggerChannel/FETCH_DATA_LOGGER_CHANNEL_ERROR',
  FETCH_DATA_LOGGER_CHANNEL_RESPONSE:
    'dms/obsPoint/detail/dataLoggerChannel/FETCH_DATA_LOGGER_CHANNEL_RESPONSE',
} as const;

export const ActionCreators = {
  FETCH_DATA_LOGGER_CHANNEL_START: (obsPointCode: string) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_CHANNEL_START,
    meta: {
      obsPointCode,
    },
  }),
  FETCH_DATA_LOGGER_CHANNEL_ERROR: (
    obsPointCode: string,
    errorMessage: string
  ) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_CHANNEL_ERROR,
    error: true,
    payload: errorMessage,
    meta: {
      obsPointCode,
    },
  }),
  FETCH_DATA_LOGGER_CHANNEL_RESPONSE: (
    obsPointCode: string,
    dataLoggerChannel: Model.DataLoggerChannelDecorated[] | null
  ) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_CHANNEL_RESPONSE,
    payload: dataLoggerChannel,
    meta: { obsPointCode },
  }),
};

type Actions = DuckActions<typeof ActionTypes, typeof ActionCreators>;
export type ObsPointDetailDataLoggerChannelAction = Actions;

export interface ObsPointDetailDataLoggerChannelState {
  forObsPoint: string | null;
  dataLoggerChannels: Model.DataLoggerChannelDecorated[] | null;
  errorMessage: string | null;
  isLoading: boolean;
}

function initialState(): ObsPointDetailDataLoggerChannelState {
  return {
    forObsPoint: null,
    dataLoggerChannels: null,
    errorMessage: null,
    isLoading: false,
  };
}

export function obsPointDetailDataLoggerChannelReducer(
  state = initialState(),
  action: Actions
): ObsPointDetailDataLoggerChannelState {
  switch (action.type) {
    case ActionTypes.FETCH_DATA_LOGGER_CHANNEL_START:
      return {
        forObsPoint: action.meta.obsPointCode,
        dataLoggerChannels: null,
        errorMessage: null,
        isLoading: true,
      };
    case ActionTypes.FETCH_DATA_LOGGER_CHANNEL_ERROR:
      if (action.meta.obsPointCode === state.forObsPoint) {
        return {
          ...state,
          dataLoggerChannels: null,
          errorMessage: action.payload,
          isLoading: false,
        };
      } else return state;
    case ActionTypes.FETCH_DATA_LOGGER_CHANNEL_RESPONSE:
      if (action.meta.obsPointCode === state.forObsPoint) {
        return {
          ...state,
          dataLoggerChannels: action.payload,
          errorMessage: null,
          isLoading: false,
        };
      } else return state;
    default:
      return state;
  }
}

/**
 * Subroutine of fetchObservationPoint, to fetch info about the data logger.
 *
 * @param {object} observationPoint
 * @returns {object}
 */
export function fetchDataLoggerChannel(
  observationPoint: Pick<Model.ObservationPoint, 'id' | 'code'>
): StandardThunk {
  return async function (dispatch) {
    dispatch(
      ActionCreators.FETCH_DATA_LOGGER_CHANNEL_START(observationPoint.code)
    );
    try {
      const dataLoggerChannels = orderBy(
        await getApi('/data-logger-channels/', {
          observation_point: observationPoint.id,
        }),
        (dlc) => dlc.start_datetime,
        'desc'
      );

      return dispatch(
        ActionCreators.FETCH_DATA_LOGGER_CHANNEL_RESPONSE(
          observationPoint.code,
          dataLoggerChannels
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_DATA_LOGGER_CHANNEL_ERROR(
          observationPoint.code,
          errorToString(e)
        )
      );
    }
  };
}
