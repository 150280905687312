import { getApi, getPaginated } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { Filter } from 'util/backendapi/models/api.interfaces';

export const inspectionBatchDuck = makeReportingDuck(
  'inspectionBatch',
  (filters: Filter.ReportsInspectionBatches) =>
    getPaginated('/reports/inspection-batches/', filters),
  () => getApi('/reports/inspection-batches/info/')
);

export type ReadingsBatchListActions = ReportingDuckActions<
  typeof inspectionBatchDuck
>;
