import React from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import {
  userListFilters,
  userListColumns,
  UserListView,
  UserListViewProps,
} from './UserListView';
import { useReportState } from 'hooks/use-report-state';
import { userListDuck } from 'ducks/user/list';
import { useGetApi } from 'hooks/use-get-api';

type OwnProps = RCPWithQueryParams<Filter.ReportsUsers>;

export const UserListScreen = (ownProps: OwnProps) => {
  const [{ data: clients }] = useGetApi('/clients/');

  const props: UserListViewProps = {
    reportProps: useReportState(
      ownProps,
      userListColumns,
      userListFilters(),
      userListDuck,
      (state) => state.userList
    ),
    clients,
  };

  return <UserListView {...props} />;
};
