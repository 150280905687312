import React from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form, FormikErrors, Field, FormikHelpers } from 'formik';
import { useHistory, useLocation } from 'react-router';
import { PlotType } from 'util/backendapi/types/Enum';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { useDispatch } from 'react-redux';
import { createStoredPlot } from 'ducks/stored-plot/detail';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { enhanceWithBackUrl } from 'components/base/link/DMSLink';

interface FormValues {
  name: string;
  plot_type: PlotType;
}

export function CreateStoredPlotModal(_props: {}) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const handleSubmit = React.useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      try {
        // Send a POST body with just plot_type & name just
        // to get the backend validation for name (uniqueness check).
        // This way we can provide quicker feedback to user
        await dispatch(
          createStoredPlot({
            plot_type: values.plot_type,
            name: values.name,
          } as any)
        );
      } catch (e) {
        if (e['name']) {
          showErrorsInFormik(
            formik,
            {
              name: e['name'],
            },
            ['name']
          );
          formik.setSubmitting(false);
          return;
        }
      }

      history.push(
        // Pass the selected plot type as a route prop, because it should be
        // unchanging, and it's required.
        enhanceWithBackUrl(
          `/stored-plots-new/${encodeURIComponent(values.plot_type)}`,
          location
        ),
        {
          // Pass the plot name as invisible "location state", because the user
          // is able to change that via the form, so it would be confusing
          // to have it in the URL.
          name: values.name,
        }
      );
    },
    [history, dispatch, location]
  );
  return <CreateStoredPlotModalView onSubmit={handleSubmit} />;
}

interface ViewProps {
  onSubmit: (values: FormValues, formik: FormikHelpers<FormValues>) => any;
}

function CreateStoredPlotModalView(props: ViewProps) {
  const validate = React.useCallback(
    (values: FormValues): FormikErrors<FormValues> => {
      const errors: FormikErrors<FormValues> = {};
      if (values.name === '') {
        errors.name = (<Trans>Name is required.</Trans>) as any;
      }
      if (!values.plot_type) {
        errors.plot_type = (<Trans>Plot type is required.</Trans>) as any;
      }
      return errors;
    },
    []
  );

  const plotTypeOptions: SimpleSelectOption<PlotType>[] = React.useMemo(
    () =>
      menuItemsFromEnum('PlotType', [
        PlotType.SURVEY_LEVELLING,
        PlotType.SPATIAL_CROSS_SECTION,
        PlotType.SPATIAL_PLAN,
        PlotType.SPATIAL_WANDER,
      ]),
    []
  );

  return (
    <ModalContent header={<Trans>Create a stored plot</Trans>}>
      <Formik<FormValues>
        // TODO: When time series plots are enabled here, they should probably(?)
        // be the default value.
        initialValues={{ name: '', plot_type: PlotType.SURVEY_LEVELLING }}
        onSubmit={props.onSubmit}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <Form>
            <FormSection>
              <FormItem
                label={<Trans>Name</Trans>}
                fieldId="create-stored-plot-name"
              >
                <Field
                  type="text"
                  name="name"
                  id="create-stored-plot-name"
                  autoFocus={true}
                />
                <FieldError name="name" />
              </FormItem>
              <FormItem
                label={<Trans>Type</Trans>}
                fieldId="create-stored-plot-type"
              >
                <SimpleSelectField
                  name="plot_type"
                  id="create-stored-plot-type"
                  options={plotTypeOptions}
                />
                <FieldError name="plot_type" />
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                data-testid="create-stored-plot-submit"
                type="submit"
                disabled={isSubmitting}
                iconType="icon-arrow-right"
              >
                <Trans>Continue</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}

CreateStoredPlotModal.WrappedComponent = CreateStoredPlotModalView;
