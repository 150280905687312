import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Alert } from '../alert/alert';
import { Trans } from '@lingui/macro';
import './loading.scss';

export default class Loading extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    isLoading: PropTypes.bool,
    className: PropTypes.string,
  };

  render() {
    const { className, isLoading, ...otherProps } = this.props;
    return (
      <Alert className={classNames('loading', className)} {...otherProps}>
        <Trans>Loading</Trans>
      </Alert>
    );
  }
}
