import React from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import PanelContent from 'components/base/panel/panelcontent';
import { Trans } from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { Formik, Form, ErrorMessage, FormikHelpers } from 'formik';
import { FormItem } from 'components/base/form/FormItem';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import Loading from 'components/base/loading/loading';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { components, MultiValueGenericProps } from 'react-select';
import { AlertInfo } from 'components/base/alert/alert';
import { getExpectedFields } from 'util/backendapi/error';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';

export interface ClientAreasFormValues {
  areas: number[];
}

export interface ClientAreasPanelProps {
  client: Pick<
    Model.ReportsClient,
    'id' | 'full_name' | 'name' | 'users' | 'comment_count'
  >;
  clientAreas: Model.AreaDecorated[];
  areasWithNoClient: Model.AreaDecorated[];
  canEdit: boolean;
  isLoading: boolean;
  isEditing: boolean;
  onEdit: () => void;
  onCancel: () => void;
  onSubmit: (values: ClientAreasFormValues) => void;
}

interface ClientAreaMenuItem {
  label: string;
  value: number;
  shortLabel: string;
}

const SelectedAreaWithHovertext = (
  props: MultiValueGenericProps<ClientAreaMenuItem, true>
) => (
  <div title={props.data.label}>
    <components.MultiValueLabel {...props}>
      {props.data.shortLabel}
    </components.MultiValueLabel>
  </div>
);

export function ClientAreasPanelView(props: ClientAreasPanelProps) {
  // Memoize the areas menu.
  const areaMenuItems = React.useMemo(
    function (): ClientAreaMenuItem[] {
      return sortBy(
        props.clientAreas.concat(...props.areasWithNoClient).map((area) => ({
          label: `${area.code} - ${area.name}`,
          value: area.id,
          shortLabel: area.code,
        })),
        'label'
      );
    },
    [props.clientAreas, props.areasWithNoClient]
  );

  // Memoize this component's "handleSubmit" callback method (which wraps
  // the "onSubmit" parent callback with some error-handling UI code.)
  const handleSubmit = React.useCallback(
    async function (
      values: ClientAreasFormValues,
      formik: FormikHelpers<ClientAreasFormValues>
    ) {
      try {
        // Gotta call props.onSubmit this way to satisfy ESLint that `props`
        // is not a dependency of this function. Because technically, calling
        // `props.onSubmit()` passes `props` to `onSubmit` as the value of
        // `this`.
        await props.onSubmit.call(null, values);
      } catch (e) {
        showErrorsInFormik(formik, e, getExpectedFields(values));
      } finally {
        formik.setSubmitting(false);
      }
    },
    [props.onSubmit]
  );

  // Memoize the form's initial values object.
  const initialValues: ClientAreasFormValues = React.useMemo(
    () => ({ areas: props.clientAreas.map((area) => area.id) }),
    [props.clientAreas]
  );

  const header = <Trans>{props.client.name} areas</Trans>;
  if (props.isLoading) {
    // Loading
    return (
      <PanelContent header={header}>
        <Loading />
      </PanelContent>
    );
  } else if (props.canEdit && props.isEditing) {
    // Edit form
    return (
      <PanelContent header={header}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {(formik) => (
            <Form>
              {formik.status}
              <FormItem
                label={<Trans>Areas</Trans>}
                fieldId="client-areas-menu"
              >
                <SimpleSelectField<number, true>
                  name="areas"
                  id="client-areas-menu"
                  options={areaMenuItems}
                  isMulti={true}
                  isDisabled={formik.isSubmitting}
                  components={{
                    MultiValueLabel: SelectedAreaWithHovertext as (
                      props: MultiValueGenericProps<
                        SimpleSelectOption<number>,
                        true
                      >
                    ) => JSX.Element,
                  }}
                />
                <ErrorMessage component={ErrorNotice} name="areas" />
              </FormItem>
              <ActionBlock>
                <Button onClick={props.onCancel} disabled={formik.isSubmitting}>
                  <Trans>Cancel</Trans>
                </Button>
                <ButtonPrimary
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      </PanelContent>
    );
  } else {
    // Display list
    return (
      <PanelContent header={header}>
        {props.canEdit && (
          <ActionBlock>
            <Button onClick={props.onEdit} iconType="icon-update">
              <Trans>Change areas</Trans>
            </Button>
          </ActionBlock>
        )}
        {props.clientAreas.length === 0 ? (
          <AlertInfo>
            <Trans>This client has no areas</Trans>
          </AlertInfo>
        ) : (
          <ul className="area-list">
            {props.clientAreas.map((area) => (
              <li key={area.id}>
                <strong>{area.code}</strong> <span>{area.name}</span>
              </li>
            ))}
          </ul>
        )}
      </PanelContent>
    );
  }
}
