/**
 * Detect whether the application is being rendered on the backend servers
 * for purposes of export (e.g. PDF) generation. This can then be used to
 * do things like disable pagination functionality.
 */
export function detectExportMode(): boolean {
  // This variable is set in `src/server/launch.js`
  // @ts-ignore
  return Boolean(global.isSSR);
}

/**
 * Detect whether the application is being run in the CLI
 */
export function detectCliMode(): boolean {
  // @ts-ignore
  return Boolean(global.isSSR) || process.env.NODE_ENV === 'test';
}
