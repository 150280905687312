import { Model } from 'util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions } from 'main/store';
import { postApi, getApi, patchApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { isTruthy } from 'util/validation';

export const ActionTypes = {
  FETCH_PLOT_SET_START: 'dms/plotSet/detail/FETCH_PLOT_SET_START',
  FETCH_PLOT_SET_RESPONSE: 'dms/plotSet/detail/FETCH_PLOT_SET_RESPONSE',
  FETCH_PLOT_SET_ERROR: 'dms/plotSet/detail/FETCH_PLOT_SET_ERROR',
  UPDATE_STORED_PLOTS: 'dms/plotSet/detail/UPDATE_STORED_PLOTS',
  UNMOUNT: 'dms/plotSet/detail/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_PLOT_SET_START(plotSetName: string) {
    return {
      type: ActionTypes.FETCH_PLOT_SET_START,
      plotSetName,
    };
  },
  FETCH_PLOT_SET_RESPONSE(
    plotSet: Model.PlotSet,
    storedPlots: Model.ReportsStoredPlot[]
  ) {
    return {
      type: ActionTypes.FETCH_PLOT_SET_RESPONSE,
      payload: {
        plotSet,
        storedPlots,
      },
    };
  },
  FETCH_PLOT_SET_ERROR(plotSetName: string, errorMessage: string) {
    return {
      type: ActionTypes.FETCH_PLOT_SET_ERROR,
      plotSetName,
      errorMessage,
    };
  },
  UPDATE_STORED_PLOTS(
    plotSet: Model.PlotSet,
    storedPlots: Model.ReportsStoredPlot[]
  ) {
    return {
      type: ActionTypes.UPDATE_STORED_PLOTS,
      payload: {
        plotSet,
        storedPlots,
      },
    };
  },
  UNMOUNT() {
    return {
      type: ActionTypes.UNMOUNT,
    };
  },
};

export type PlotSetDetailActions = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface PlotSetDetailState {
  loading: boolean;
  isUpdatingStoredPlots: boolean;
  errorMessage: string;
  plotSetName: string;
  record: null | Model.PlotSet;
  storedPlots: Model.ReportsStoredPlot[];
}

export function initialPlotSetDetailState(): PlotSetDetailState {
  return {
    loading: false,
    isUpdatingStoredPlots: false,
    errorMessage: '',
    plotSetName: '',
    record: null,
    storedPlots: [],
  };
}

export function plotSetDetailReducer(
  state = initialPlotSetDetailState(),
  action: PlotSetDetailActions
): PlotSetDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_PLOT_SET_START:
      return {
        ...state,
        loading: true,
        isUpdatingStoredPlots: false,
        errorMessage: '',
        record: null,
        plotSetName: action.plotSetName,
      };
    case ActionTypes.FETCH_PLOT_SET_RESPONSE:
      if (state.plotSetName === action.payload.plotSet.name) {
        return {
          ...state,
          loading: false,
          isUpdatingStoredPlots: false,
          record: action.payload.plotSet,
          storedPlots: action.payload.storedPlots,
        };
      } else {
        return state;
      }
    case ActionTypes.FETCH_PLOT_SET_ERROR:
      if (state.plotSetName === action.plotSetName) {
        return {
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
        };
      } else {
        return state;
      }
    case ActionTypes.UPDATE_STORED_PLOTS:
      if (state.plotSetName === action.payload.plotSet.name) {
        return {
          ...state,
          isUpdatingStoredPlots: true,
          storedPlots: action.payload.storedPlots,
        };
      } else {
        return state;
      }
    case ActionTypes.UNMOUNT:
      return initialPlotSetDetailState();
    default:
      return state;
  }
}

export const unmountPlotSetDetail = ActionCreators.UNMOUNT;

export function fetchPlotSet(plotSetName: string): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_PLOT_SET_START(plotSetName));
    try {
      const plotSet = (
        await getApi('/plot-sets/', {
          name: plotSetName,
        })
      )[0];

      const storedPlots = await getApi('/reports/stored-plots/', {
        id__in: plotSet.stored_plots,
        columns: [
          'id',
          'name',
          'description',
          'area__name',
          'area__code',
          'plot_type',
        ],
      });

      const orderedStoredPlots = plotSet.stored_plots
        .map((sId) => storedPlots.find((sp) => sp.id === sId))
        .filter(isTruthy);

      dispatch(
        ActionCreators.FETCH_PLOT_SET_RESPONSE(plotSet, orderedStoredPlots)
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_PLOT_SET_ERROR(plotSetName, errorToString(e))
      );
    }
  };
}

export function createPlotSet(
  values: Model.PlotSet_POST
): StandardThunk<Model.PlotSet> {
  return async () => {
    return postApi('/plot-sets/', values);
  };
}

function updatePlotSet(
  origPlotSet: Model.PlotSet,
  values: Model.PlotSet_PATCH
): StandardThunk {
  return async (dispatch) => {
    try {
      const plotSet = await patchApi(`/plot-sets/${origPlotSet.id}/`, values);
      const storedPlots = await getApi('/reports/stored-plots/', {
        id__in: plotSet.stored_plots,
        columns: [
          'id',
          'name',
          'description',
          'area__name',
          'area__code',
          'plot_type',
        ],
      });

      const orderedStoredPlots = plotSet.stored_plots
        .map((sId) => storedPlots.find((sp) => sp.id === sId))
        .filter(isTruthy);

      dispatch(
        ActionCreators.FETCH_PLOT_SET_RESPONSE(plotSet, orderedStoredPlots)
      );
      return plotSet;
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_PLOT_SET_ERROR(origPlotSet.name, errorToString(e))
      );
    }
  };
}

export function updateStoredPlotsPosition(
  plotSet: Model.PlotSet,
  fromIdx: number,
  toIdx: number
): StandardThunk {
  return async (dispatch, getState) => {
    const state = getState();
    const storedPlots = state.plotSet.detail.storedPlots;

    const newStoredPlots = [...storedPlots];

    const idToMove = newStoredPlots.splice(fromIdx, 1);
    newStoredPlots.splice(toIdx, 0, ...idToMove);

    // update the stored plot positions directly in the stored to achieve instant re-ordering effect
    // after the update request, fresh stored plots will be refresh with the corret order
    dispatch(ActionCreators.UPDATE_STORED_PLOTS(plotSet, newStoredPlots));

    dispatch(
      updatePlotSet(plotSet, {
        stored_plots: newStoredPlots.map((s) => s.id),
      })
    );
  };
}

export function removeStoredPlot(
  plotSet: Model.PlotSet,
  fromIdx: number
): StandardThunk {
  return async function (dispatch, getState) {
    const state = getState();
    const storedPlots = state.plotSet.detail.storedPlots;
    const newStoredPlots = [...storedPlots];
    newStoredPlots.splice(fromIdx, 1);

    dispatch(ActionCreators.UPDATE_STORED_PLOTS(plotSet, newStoredPlots));

    dispatch(
      updatePlotSet(plotSet, {
        stored_plots: newStoredPlots.map((s) => s.id),
      })
    );
  };
}

export function addStoredPlots(
  plotSet: Model.PlotSet,
  newStoredPlotIds: number[]
): StandardThunk {
  return async function (dispatch, getState) {
    const state = getState();
    const storedPlots = state.plotSet.detail.storedPlots;

    // Don't actually update the rows in the table yet, because we need to fetch
    // data for that. But do fire off this thunk, in order to set the "updating"
    // flag.
    dispatch(ActionCreators.UPDATE_STORED_PLOTS(plotSet, storedPlots));

    dispatch(
      updatePlotSet(plotSet, {
        stored_plots: plotSet.stored_plots.concat(newStoredPlotIds),
      })
    );
  };
}
