import React, { useCallback } from 'react';
import {
  ReportColumn,
  ReportFilter,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { TransEnum } from 'components/base/i18n/TransEnum';
import {
  convertDateToDatetime,
  formatDatetimeForDisplay,
  formatDatetimeForBackendUrl,
  START_OF_DAY,
  END_OF_DAY,
} from 'util/dates';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { RouteComponentProps } from 'react-router-dom';
import { Icon } from 'components/base/icon/icon';
import { alarmParameterDuck } from 'ducks/alarm-parameter/list';
import { useReportState, ReportStateProps } from 'hooks/use-report-state';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import {
  reportFilterDatetime,
  NO_TIMEZONE,
} from 'components/modules/report/filter/fields/FilterDate';
import { reportFilterSites } from 'components/modules/report/filter/fields/reportFilterSites';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import { reportFilterActiveAt } from 'components/modules/report/filter/fields/reportFilterActiveAt';
import { parseQueryParamFromRouterProps } from 'util/routing';
import { DMSLink } from 'components/base/link/DMSLink';
import { AlarmParametersBulkCreateModal } from './AlarmParameterBulkCreateModal';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';

export function AlarmParameterListScreen(props: RouteComponentProps) {
  const columns = alarmParameterColumns({
    refreshList: EMPTY_FUNC,
    deleteAlarmParameter: EMPTY_FUNC,
  });
  const reportState = useReportState(
    props,
    columns,
    alarmParameterFilters,
    alarmParameterDuck,
    (state) => state.alarmParameters.list
  );

  const deleteAlarmParameter = useCallback(async (id: number) => {
    return deleteApi(`/alarm-parameters/${id}/`);
  }, []);

  return (
    <AlarmParameterListView
      reportProps={reportState}
      deleteAlarmParameter={deleteAlarmParameter}
    />
  );
}

export const alarmParameterFilters: ReportFilter[] = [
  reportFilterObservationPoints('observation_point', {
    placeholder: <Trans>Find observation point</Trans>,
  }),
  reportFilterSites('observation_point__site', {
    placeholder: <Trans>All sites</Trans>,
  }),
  reportFilterMenu(
    'observation_point__site__area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterMenu(
    'observation_point__instrument_type',
    <Trans>Instrument type</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All instrument types</Trans> }
  ),
  reportFilterMenu(
    'level',
    <Trans>Alarm level</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmParameter_LEVEL'),
    {
      placeholder: <Trans>All alarm levels</Trans>,
      options: Object.values(Enum.AlarmParameter_LEVEL),
    }
  ),
  reportFilterMenu(
    'comparison_type',
    <Trans>Comparision type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmParameter_COMPARISON_TYPE'),
    {
      placeholder: <Trans>All comparision types</Trans>,
      options: Object.values(Enum.AlarmParameter_COMPARISON_TYPE),
    }
  ),
  reportFilterMenu(
    'type',
    <Trans>Min/max</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmParameter_TYPE'),
    {
      placeholder: <Trans>Both min and max</Trans>,
      options: Object.values(Enum.AlarmParameter_TYPE),
    }
  ),
  reportFilterDatetime(
    'start_datetime',
    <Trans>Start date</Trans>,
    NO_TIMEZONE
  ),
  reportFilterDatetime('end_datetime', <Trans>End date</Trans>, NO_TIMEZONE),
  reportFilterMenu(
    'status',
    <Trans>Status</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmParameter_STATUS'),
    {
      placeholder: <Trans>Both enabled and disabled</Trans>,
      options: Object.values(Enum.AlarmParameter_STATUS),
    }
  ),
  {
    ...reportFilterActiveAt(
      'active_at_datetime',
      <Trans>Date active</Trans>,
      NO_TIMEZONE
    ),
    // this is a special filter, when "Today" checkbox is checked,
    // we will send to the backend the param `active_now = true`
    //
    // and this is a Dam time filter, so we'll use `formatDatetimeForBackendUrl`
    // to format the date instead of `formatDatetimeForStorage`
    getBackendFilterFromUrl: (routeProps) => {
      const nowQueryParam = parseQueryParamFromRouterProps(routeProps, 'now');
      const now = nowQueryParam === '' ? true : nowQueryParam === 'true';

      if (now) {
        return {
          active_now: true,
        };
      }

      const date = parseQueryParamFromRouterProps(
        routeProps,
        'active_at_datetime'
      );
      const type = parseQueryParamFromRouterProps(
        routeProps,
        `active_at_datetime_type`,
        'is'
      );

      if (date) {
        const typeFieldName =
          type === 'is_before'
            ? 'start_datetime__lt'
            : type === 'is_after'
            ? 'start_datetime__gte'
            : 'active_at_datetime';

        const timeOfDay = type === 'is_after' ? START_OF_DAY : END_OF_DAY;

        const isoDatetime = formatDatetimeForBackendUrl(
          convertDateToDatetime(date, null, timeOfDay)
        );

        return {
          [typeFieldName]: isoDatetime,
        };
      }
      return null;
    },
  },
];

export const alarmParameterColumns: (props: {
  deleteAlarmParameter: (id: number) => Promise<void>;
  refreshList: () => void;
}) => ReportColumn<Model.ReportsAlarmParameter>[] = ({
  deleteAlarmParameter,
  refreshList,
}) => [
  {
    label: <Trans>Observation point</Trans>,
    name: 'observation_point__code',
    additionalFields: ['item_number'],
    visibility: DEFAULT_SHOW,
    accessor: ({ observation_point__code, item_number }) => (
      <DMSLink
        to={`/alarm-parameters/${observation_point__code}-${item_number}`}
      >
        {observation_point__code}
      </DMSLink>
    ),
  },
  {
    label: <Trans>Instrument type</Trans>,
    name: 'observation_point__instrument_type__code',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Site</Trans>,
    name: 'observation_point__site__code',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Area</Trans>,
    name: 'observation_point__site__area__code',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Comparision obs pt</Trans>,
    name: 'relative_observation_point__code',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Comparision factor</Trans>,
    name: 'relative_comparison_factor',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Comparision time difference</Trans>,
    name: 'relative_comparison_datetime_tolerance',
    visibility: DEFAULT_HIDE,
    accessor: ({ relative_comparison_datetime_tolerance }) => (
      <IntervalDisplay value={relative_comparison_datetime_tolerance} />
    ),
  },
  {
    label: <Trans>Comparision obs pt item num</Trans>,
    name: 'relative_item_number',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Time interval</Trans>,
    name: 'time-interval',
    backendFieldName: 'time_interval',
    visibility: DEFAULT_HIDE,
    accessor: ({ time_interval }) => <IntervalDisplay value={time_interval} />,
  },
  {
    label: <Trans>Direction of change</Trans>,
    name: 'direction-of-change',
    backendFieldName: 'direction_of_change',
    visibility: DEFAULT_HIDE,
    accessor: ({ direction_of_change }) =>
      direction_of_change && (
        <TransEnum
          enum="AlarmParameter_DIRECTION_OF_CHANGE"
          value={direction_of_change}
        />
      ),
  },
  {
    label: <Trans>Item number</Trans>,
    name: 'item_number',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Item description</Trans>,
    name: 'item_description',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Comparision type</Trans>,
    name: 'comparison_type',
    visibility: DEFAULT_SHOW,
    accessor: ({ comparison_type }) => (
      <TransEnum
        enum={'AlarmParameter_COMPARISON_TYPE'}
        value={comparison_type}
      />
    ),
  },
  {
    label: <Trans>Alarm level</Trans>,
    name: 'level',
    visibility: DEFAULT_SHOW,
    accessor: ({ level }) => (
      <TransEnum enum={'AlarmParameter_LEVEL'} value={level} />
    ),
  },
  {
    label: <Trans>Min/Max</Trans>,
    name: 'type',
    visibility: DEFAULT_SHOW,
    accessor: ({ type }) => (
      <TransEnum enum={'AlarmParameter_TYPE'} value={type} />
    ),
  },
  {
    label: <Trans>Parameter</Trans>,
    name: 'threshold',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Start</Trans>,
    name: 'start_datetime',
    additionalFields: ['observation_point__site__area__time_zone__name'],
    hideFieldsFromCSV: ['observation_point__site__area__time_zone__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      start_datetime,
      observation_point__site__area__time_zone__name: tz,
    }) => formatDatetimeForDisplay(start_datetime, tz),
  },
  {
    label: <Trans>End</Trans>,
    name: 'end_datetime',
    additionalFields: ['observation_point__site__area__time_zone__name'],
    hideFieldsFromCSV: ['observation_point__site__area__time_zone__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      end_datetime,
      observation_point__site__area__time_zone__name: tz,
    }) => end_datetime && formatDatetimeForDisplay(end_datetime, tz),
  },
  {
    label: <Trans>Status</Trans>,
    name: 'status',
    backendFieldName: 'status',
    visibility: DEFAULT_HIDE,
    accessor: ({ status }) => (
      <TransEnum enum="AlarmParameter_STATUS" value={status} />
    ),
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id', 'observation_point__code', 'item_number'],
    accessor: ({ id, observation_point__code, item_number }) => (
      <>
        <DMSLink
          to={`/alarm-parameters/${observation_point__code}-${item_number}`}
        >
          <Icon type="icon-view" title={t`View`} />
        </DMSLink>
        <HasPermission check="can_delete_alarm_parameters">
          <ButtonShowConfirmation
            name="delete-alarm-parameter"
            title={t`Delete`}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            content={
              <Trans>
                Are you sure you want to delete this alarm parameter? This
                action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async function () {
              await deleteAlarmParameter(id);
              refreshList();
            }}
          />
        </HasPermission>
      </>
    ),
  },
];

export interface AlarmParameterListViewProps {
  reportProps: ReportStateProps<Model.ReportsAlarmParameter>;
  deleteAlarmParameter: (id: number) => Promise<any>;
}
export const AlarmParameterListView: React.FunctionComponent<AlarmParameterListViewProps> =
  function (props) {
    const columns = alarmParameterColumns({
      refreshList: props.reportProps.refreshList,
      deleteAlarmParameter: props.deleteAlarmParameter,
    });
    const filters = alarmParameterFilters;
    const { reportProps } = props;
    return (
      <PageStandard
        name="alarm-parameter-report"
        header={<Trans>Alarm parameters</Trans>}
      >
        <HasPermission check="can_bulk_create_alarm_parameters">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="bulk-create-alarm-parameters"
              modalContent={({ hideModal }) => (
                <AlarmParametersBulkCreateModal
                  onAfterSubmit={() => {
                    hideModal();
                  }}
                />
              )}
              iconType="icon-plus"
              title={t`Bulk create alarm parameters`}
            >
              <Trans>Bulk create alarm parameters</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>
        <ReportFiltersBlock
          filtersBackend={reportProps.reportInfo?.filters}
          filtersFrontend={filters}
          filtersToShow={[
            'observation_point',
            'observation_point__site__area',
            'level',
            'active_at_datetime',
          ]}
          isExportMode={reportProps.isExportMode}
        />
        <div className="filtered-table-wrapper">
          <ActionBlock className="text-right">
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
            />
            <ButtonPrint />
            <ExportReportButton
              fileNameBase={t`Alarm reports`}
              reportUrl="/reports/alarm-parameters/"
              columns={columns}
              filters={filters}
            />
            <AdvancedFiltersModalButton
              filtersBackend={reportProps.reportInfo?.filters}
              filtersFrontend={filters}
              sections={[
                {
                  name: 'general_section',
                  filters: [
                    'observation_point__site__area',
                    'observation_point__site',
                    'observation_point',
                    'observation_point__instrument_type',
                  ],
                },
                {
                  name: 'level_section',
                  filters: ['level', 'comparison_type', 'type'],
                },
                {
                  name: 'date_section',
                  filters: ['start_datetime', 'end_datetime', 'status'],
                },
              ]}
            />
          </ActionBlock>
          <ReportTable<Model.ReportsAlarmParameter>
            {...reportProps}
            columns={columns}
            filters={filters}
          />
        </div>
      </PageStandard>
    );
  };
