import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { StandardDispatch } from 'main/store';
import CommentsPanelView, {
  CommentsPanelResourceTypes,
} from './CommentsPanelView';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { fetchCommentsList, addComment } from 'ducks/comments/panel/list';
import { ActionCreators } from 'ducks/comments/panel/panel';
import { NewCommentFormValues } from './new-comment-form/NewCommentFormView';
import { useShallowEqualSelector, useIsMounted } from 'util/hooks';
import { useEffect } from 'react';
import { ReadingGapComment_POST } from 'util/backendapi/types/Model';

export type CommentsPanelProps = {
  /**
   * The unusual structure here is because the type of
   * the metadata is different for each type of comment panel. The obvious
   * approach would be to make this a templated type, but then TypeScript is
   * unable to do proper type detection in the case-switch statement.
   *
   * So instead we abuse mapped types: https://www.typescriptlang.org/docs/handbook/advanced-types.html#mapped-types
   *
   * The syntax below effectively creates a union of every variant of the props
   * for each key of `MetadataByCommentType`. And TypeScript *is* able to use
   * that to do proper type detection in a case-switch statement.
   */
  [K in CommentsPanelResourceTypes]: {
    description?: React.ReactNode;
    type: K;
    metadata: MetadataByCommentType[K];
    onCommentAdded?: (comment: Model.PolymorphicComment) => void;
    onPanelMount?: () => void;
    onPanelUnmount?: () => void;
    commentReportParams?: string;
  };
}[CommentsPanelResourceTypes];

/**
 * Type definitions for the metadata that must be passed to the `<CommentsPanel>`
 * component for each type of panel.
 */
interface MetadataByCommentType {
  [Enum.Comment_RESOURCE_TYPE.alarmParameter]: {
    alarm_parameter: number;
    area: number;
    observation_point: number;
    site: number;
  };
  [Enum.Comment_RESOURCE_TYPE.alarmReport]: {
    alarm_report: number;
    area: number;
    observation_point: number;
    site: number;
  };
  [Enum.Comment_RESOURCE_TYPE.area]: {
    area: number;
  };
  [Enum.Comment_RESOURCE_TYPE.client]: {
    client: number;
  };
  [Enum.Comment_RESOURCE_TYPE.media]: {
    media: number;
  };
  [Enum.Comment_RESOURCE_TYPE.obsPoint]: {
    area: number;
    site: number;
    observation_point: number;
    timeZone: string;
  };
  [Enum.Comment_RESOURCE_TYPE.reading]: {
    area: number;
    site: number;
    observation_point: number;
    reading: number;
    comment_type__in?: Enum.Comment_TYPE[];
  };
  [Enum.Comment_RESOURCE_TYPE.routeMarch]: {
    route_march: number;
  };
  [Enum.Comment_RESOURCE_TYPE.site]: {
    area: number;
    site: number;
  };
}

export function CommentsPanel(props: CommentsPanelProps) {
  const dispatch = useDispatch() as StandardDispatch;
  const isMounted = useIsMounted();

  const stateProps = useShallowEqualSelector((state: FullState) => ({
    refreshFlag: state.comments.panel.refreshFlag,
    isLoading: state.comments.panelList.isLoading,
    comments: state.comments.panelList.items,
    error: state.comments.panelList.error,
    permissions: state.user.permissions,
  }));

  useEffect(() => {
    dispatch(ActionCreators.mountCommentPanel(props.type));
  }, [dispatch, props.type]);

  useEffect(() => {
    const columns: Array<StringKeyOf<Model.ReportsComment>> = [
      'id',
      'comment_type',
      'commenter__name',
      'content',
      'resourcetype',
      'created_datetime',
    ];

    switch (props.type) {
      case Enum.Comment_RESOURCE_TYPE.alarmParameter:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [Enum.Comment_RESOURCE_TYPE.alarmParameter],
              alarm_parameter__in: [props.metadata.alarm_parameter],
              columns,
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.alarmReport:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [Enum.Comment_RESOURCE_TYPE.alarmReport],
              alarm_report__in: [props.metadata.alarm_report],
              columns,
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.area:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [Enum.Comment_RESOURCE_TYPE.area],
              area__in: [props.metadata.area],
              columns,
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.client:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [Enum.Comment_RESOURCE_TYPE.client],
              client__in: [props.metadata.client],
              columns,
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.media:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [Enum.Comment_RESOURCE_TYPE.media],
              media__in: [props.metadata.media],
              columns,
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.obsPoint:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [
                Enum.Comment_RESOURCE_TYPE.obsPoint,
                Enum.Comment_RESOURCE_TYPE.readingGap,
              ],
              observation_point__in: [props.metadata.observation_point],
              columns: [...columns, 'at_datetime'],
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.reading:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: props.metadata.comment_type__in || [
                Enum.Comment_TYPE.analysis,
              ],
              resourcetype__in: [
                Enum.Comment_RESOURCE_TYPE.reading,
                Enum.Comment_RESOURCE_TYPE.readingInspector,
              ],
              reading__in: [props.metadata.reading],
              columns,
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.routeMarch:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [Enum.Comment_RESOURCE_TYPE.routeMarch],
              route_march__in: [props.metadata.route_march],
              columns,
            },
            props.type
          )
        );
        break;
      case Enum.Comment_RESOURCE_TYPE.site:
        dispatch(
          fetchCommentsList(
            {
              comment_type__in: [Enum.Comment_TYPE.analysis],
              resourcetype__in: [Enum.Comment_RESOURCE_TYPE.site],
              site__in: [props.metadata.site],
              columns,
            },
            props.type
          )
        );
        break;
    }
  }, [
    dispatch,
    // @ts-ignore
    props.metadata.alarm_parameter,
    // @ts-ignore
    props.metadata.alarm_report,
    // @ts-ignore
    props.metadata.area,
    // @ts-ignore
    props.metadata.client,
    // @ts-ignore
    props.metadata.comment_type__in,
    // @ts-ignore
    props.metadata.media,
    // @ts-ignore
    props.metadata.observation_point,
    // @ts-ignore
    props.metadata.reading,
    // @ts-ignore
    props.metadata.route_march,
    // @ts-ignore
    props.metadata.site,
    props.type,
    stateProps.refreshFlag,
  ]);

  const handleAddComment = useCallback(
    async (values: NewCommentFormValues) => {
      let comment: Model.PolymorphicComment_POST | null = null;

      switch (values.resourcetype) {
        case Enum.Comment_RESOURCE_TYPE.alarmParameter:
          if (props.type === Enum.Comment_RESOURCE_TYPE.alarmParameter) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.alarmParameter,
              alarm_parameter: props.metadata.alarm_parameter,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.alarmReport:
          if (props.type === Enum.Comment_RESOURCE_TYPE.alarmReport) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.alarmReport,
              alarm_report: props.metadata.alarm_report,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.area:
          // area comment can be added from:
          // - maintain area page
          // - maintain observation point page
          // - maintain site page
          // - routeMarch obsPoint page
          if (
            props.type === Enum.Comment_RESOURCE_TYPE.area ||
            props.type === Enum.Comment_RESOURCE_TYPE.obsPoint ||
            props.type === Enum.Comment_RESOURCE_TYPE.site ||
            props.type === Enum.Comment_RESOURCE_TYPE.alarmReport ||
            props.type === Enum.Comment_RESOURCE_TYPE.alarmParameter ||
            props.type === Enum.Comment_RESOURCE_TYPE.reading
          ) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.area,
              area: props.metadata.area,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.client:
          if (props.type === Enum.Comment_RESOURCE_TYPE.client) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.client,
              client: props.metadata.client,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.media:
          if (props.type === Enum.Comment_RESOURCE_TYPE.media) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.media,
              media: props.metadata.media,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.obsPoint:
          if (
            props.type === Enum.Comment_RESOURCE_TYPE.obsPoint ||
            props.type === Enum.Comment_RESOURCE_TYPE.alarmReport ||
            props.type === Enum.Comment_RESOURCE_TYPE.alarmParameter ||
            props.type === Enum.Comment_RESOURCE_TYPE.reading
          ) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.obsPoint,
              observation_point: props.metadata.observation_point,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.reading:
          if (props.type === Enum.Comment_RESOURCE_TYPE.reading) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.reading,
              reading: props.metadata.reading,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.readingGap:
          if (props.type === Enum.Comment_RESOURCE_TYPE.obsPoint) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.readingGap,
              observation_point: props.metadata.observation_point,
              at_datetime: values.created_datetime,
            } as ReadingGapComment_POST;
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.routeMarch:
          if (props.type === Enum.Comment_RESOURCE_TYPE.routeMarch) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.routeMarch,
              route_march: props.metadata.route_march,
            };
          }
          break;

        case Enum.Comment_RESOURCE_TYPE.site:
          if (
            props.type === Enum.Comment_RESOURCE_TYPE.site ||
            props.type === Enum.Comment_RESOURCE_TYPE.obsPoint ||
            props.type === Enum.Comment_RESOURCE_TYPE.alarmReport ||
            props.type === Enum.Comment_RESOURCE_TYPE.alarmParameter ||
            props.type === Enum.Comment_RESOURCE_TYPE.reading
          ) {
            comment = {
              ...values,
              resourcetype: Enum.Comment_RESOURCE_TYPE.site,
              site: props.metadata.site,
            };
          }
          break;
      }

      if (!comment) {
        throw new Error('Comment type not supported yet!');
      }

      const newComment = await dispatch(addComment(comment));
      if (isMounted()) {
        dispatch(ActionCreators.refreshCommentPanel(props.type));
      }

      return newComment;
    },
    [
      dispatch,
      isMounted,
      // @ts-ignore
      props.metadata.alarm_parameter,
      // @ts-ignore
      props.metadata.alarm_report,
      // @ts-ignore
      props.metadata.area,
      // @ts-ignore
      props.metadata.client,
      // @ts-ignore
      props.metadata.media,
      // @ts-ignore
      props.metadata.observation_point,
      // @ts-ignore
      props.metadata.reading,
      // @ts-ignore
      props.metadata.route_march,
      // @ts-ignore
      props.metadata.site,
      props.type,
    ]
  );
  return (
    <CommentsPanelView
      {...props}
      {...stateProps}
      timeZone={
        props.type === Enum.Comment_RESOURCE_TYPE.obsPoint
          ? props.metadata.timeZone
          : undefined
      }
      addComment={handleAddComment}
    />
  );
}
