import React from 'react';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { useSelector } from 'react-redux';
import { FullState } from 'main/reducers';
import { selectHasPermission } from 'util/user';

interface Props {
  check:
    | keyof typeof Enum.User_PERMISSION
    | Array<keyof typeof Enum.User_PERMISSION>;
  children?: React.ReactNode;
  noPermissionMsg?: React.ReactNode;
}

/**
 * A React component for conditionally showing/hiding content based on the
 * user's current permissions.
 *
 * Only displays its children if the user has the specified permission.
 *
 * @example
 * <HasPermission
 *   check="can_delete_clients"
 *   // Optional "permission denied" message
 *   noPermissionMsg={
 *     <div>You don't have permission to delete this client!</div>
 *   }
 * >
 *   <Button>Delete this client</Button>
 * </HasPermission>
 */
export const HasPermission: React.FunctionComponent<Props> = function (
  props: Props
) {
  const hasPermission = useSelector((state: FullState): boolean =>
    Array.isArray(props.check)
      ? props.check.every((check) =>
          selectHasPermission(state, Enum.User_PERMISSION[check])
        )
      : selectHasPermission(state, Enum.User_PERMISSION[props.check])
  );
  if (hasPermission) {
    return <>{props.children}</>;
  } else if (props.noPermissionMsg) {
    return <>{props.noPermissionMsg}</>;
  } else {
    return null;
  }
};
HasPermission.defaultProps = {
  children: null,
  noPermissionMsg: null,
};
