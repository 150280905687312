import React, { useCallback, useMemo } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import {
  StoredPlotListView,
  storedPlotListColumns,
  storedPlotListFilters,
} from './StoredPlotListView';
import { storedPlotListDuck } from 'ducks/stored-plot/list';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';

type OwnProps = RCPWithQueryParams<Filter.ReportsSites>;

export const StoredPlotListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return storedPlotListColumns({
      refreshList: EMPTY_FUNC,
      deleteStoredPlot: EMPTY_FUNC,
    });
  }, []);

  const deleteStoredPlot = useCallback(async (id: number) => {
    return deleteApi(`/stored-plots/${id}/`);
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      storedPlotListFilters,
      storedPlotListDuck,
      (state) => state.storedPlot.list
    ),
    deleteStoredPlot,
  };

  return <StoredPlotListView {...props} />;
};
