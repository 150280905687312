import React from 'react';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import {
  SimpleSelectOption,
  SimpleSelectProps,
} from 'components/base/form/simpleselect/simpleselect';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { ReportFilter, FilterRenderingInfo } from '../../report-types';
import {
  parseNumberArrayFromQueryParam,
  parseStringArrayFromQueryParam,
  parseQueryParamFromRouterProps,
  parseNumberQueryParamFromRouterProps,
} from 'util/routing';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import { TranslatedEnumNames, TranslatedEnumVals } from 'util/i18n-utils';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { isTruthy } from 'util/validation';

/**
 * Configure a menu filter control for a report API table.
 *
 * @param name
 * @param label
 * @param settings configure if this menu is multi select and the value type
 * @param extraProps Additional props to pass through to the underlying SimpleSelect
 */
export function reportFilterMenu(
  name: string,
  label: React.ReactNode,
  settings: {
    isMulti: boolean;
    valueType: 'string' | 'number';
  },
  makeMenuOption:
    | null
    | ((option: any, idx: number) => SimpleSelectOption<any>),
  extraProps: Partial<ReportFilterMenuProps> = {}
): ReportFilter {
  function _parseFromFrontendUrl(
    routeProps: RouteChildrenProps | RouteComponentProps
  ) {
    if (settings.isMulti) {
      if (settings.valueType === 'string') {
        return parseStringArrayFromQueryParam(routeProps, name, null);
      } else {
        return parseNumberArrayFromQueryParam(routeProps, name, null);
      }
    } else {
      if (settings.valueType === 'string') {
        return parseQueryParamFromRouterProps(routeProps, name, null);
      } else {
        return parseNumberQueryParamFromRouterProps(routeProps, name, null);
      }
    }
  }

  return {
    name,
    label,
    render(filterInfo?: Model.ReportFilterInfo) {
      return (
        <ReportFilterMenu
          name={name}
          filterInfo={filterInfo}
          isMulti={settings.isMulti}
          makeMenuOption={makeMenuOption}
          {...(extraProps as any)}
        />
      );
    },
    renderSSR(info: FilterRenderingInfo) {
      const value: any = info.value;
      const staticOptions = extraProps?.options ?? null;
      const backendOptions = info.backend?.options ?? null;

      const selectedOptions = (staticOptions || backendOptions || [])
        .map((opt, idx) => {
          // the opt with label is the option provided by the frontend
          // we just return it as-is
          if (opt.label) {
            return opt;
          }
          return makeMenuOption!(opt, idx);
        })
        .filter((opt) =>
          settings.isMulti && Array.isArray(value)
            ? value.indexOf(opt.value) > -1
            : value === opt.value
        );

      return selectedOptions.map((opt, idx) => (
        <React.Fragment key={idx}>
          <span key={idx}>{opt.label}</span>
          {idx !== selectedOptions.length - 1 ? ', ' : ''}
        </React.Fragment>
      ));
    },
    getFormValFromUrlParam(routeProps) {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      if (valueFromUrl === null) {
        return settings.isMulti ? [] : '';
      } else {
        return valueFromUrl;
      }
    },
    getUrlParamFromFormVal(formValues) {
      const val = formValues[name];
      if (val === '' || val === undefined) {
        return { [name]: null };
      } else {
        return { [name]: val };
      }
    },
    getBackendFilterFromUrl(routeProps) {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      if (valueFromUrl === null) {
        return null;
      }

      const filterName = settings.isMulti
        ? `${name}__${Enum.ReportFilterInfo_EXPRESSION.in}`
        : name;
      return { [filterName]: valueFromUrl };
    },
  };
}

export type ReportFilterMenuProps = Merge<
  SimpleSelectProps<any, any>,
  {
    name: string;
    filterInfo?: Model.ReportFilterInfo;
    makeMenuOption:
      | null
      | ((option: any, idx: number) => SimpleSelectOption<any>);
    options?: Array<any>;
  }
>;

/**
 * A Formik component for a menu described by a report API.
 *
 * @param props
 */
export class ReportFilterMenu extends React.Component<ReportFilterMenuProps> {
  static PASS_MENU_THROUGH = null;

  static ENUM_MENU =
    <TEnumName extends TranslatedEnumNames>(pEnum: TEnumName) =>
    (option: TranslatedEnumVals<TEnumName>) => ({
      value: option,
      label: <TransEnum enum={pEnum} value={option} />,
    });

  static CODE_AND_NAME_MENU = (option: {
    id: number;
    code: string;
    name: string;
  }): SimpleSelectOption<number> => ({
    value: option.id,
    label: `${option.code} - ${option.name}`,
  });

  static STRING_OPTION_LIST_MENU = (
    option: string
  ): SimpleSelectOption<string> => ({
    value: option,
    label: option,
  });

  static USER_MENU = (user: Model.SimpleUser): SimpleSelectOption<number> => ({
    value: user.id,
    label: [user.profile?.name, `(${user.username})`]
      .filter(isTruthy)
      .join(' '),
  });

  selectOptions = createSelector(
    (props: ReportFilterMenuProps) => props.filterInfo,
    (props: ReportFilterMenuProps) => props.makeMenuOption,
    (props: ReportFilterMenuProps) => props.options,
    function (filterInfo, makeMenuOption, staticOptions) {
      let items = staticOptions || (filterInfo && filterInfo.options);
      if (!items) {
        return false;
      }

      if (!makeMenuOption) {
        return items;
      }

      return items.map(makeMenuOption!);
    }
  );

  render() {
    const { filterInfo, makeMenuOption, options, ...otherProps } = this.props;
    return (
      <SimpleSelectField
        isClearable={false}
        isLoading={!this.selectOptions(this.props)}
        options={this.selectOptions(this.props) || []}
        {...otherProps}
      />
    );
  }
}
