import React from 'react';
import lodashSet from 'lodash/set';
import { Trans } from '@lingui/macro';
import { FormCard } from 'components/base/card/card';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { StoredScatterPlotWithArea } from 'ducks/stored-plot/detail';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import ActionBlock from 'components/base/actionblock/actionblock';
import './StoredPlotEdit.scss';
import { StoredPlotItemSection } from './sections/StoredPlotItemSection';
import { StoredPlotLayoutSection } from './sections/StoredPlotLayoutSection';
import { getObsPointItemIdent } from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import {
  makeStoredPlotNameSectionInitialValues,
  validateStoredPlotNameSection,
  StoredPlotNameSection,
} from './sections/StoredPlotNameSection';
import {
  StoredPlotTimePeriodSection,
  StoredPlotTimePeriodFormValues,
  makeStoredPlotTimePeriodInitialValues,
  validateStoredPlotTimePeriod,
  makeStoredPlotTimePeriodBackendValues,
} from './StoredPlotTimePeriodSection';
import { StoredPlotSaveCancelButtons } from './StoredPlotSaveCancelButtons';
import {
  StoredPlotItemFormValues,
  validatePlotSection,
  NewStoredPlotDefaults,
  makeEmptyStoredPlotItem,
  getAnnotationLabelPositionFormValue,
  getAnnotationLabelPositionValue,
} from './stored-plot-edit-utils';
import {
  validateStoredPlotSaveAsValues,
  makeStoredPlotSaveAsInitialValues,
  StoredPlotSaveAsValues,
  StoredPlotSaveAsSettings,
  getStoredPlotSaveAsSettings,
} from './StoredPlotSaveAsModal';
import { showStoredPlotErrorsInFormik } from './StoredPlotEdit';
import { StoredPlotAnnotation_LINE_STYLE } from 'util/backendapi/types/Enum';
import {
  StoredPlotNumericAnnotation,
  StoredPlotAnnotation,
} from 'util/backendapi/types/Model';

export type StoredScatterPlotFormValues = StoredPlotSaveAsValues &
  Merge<
    Model.StoredScatterPlot_POST,
    StoredPlotTimePeriodFormValues & {
      items: StoredPlotItemFormValues[];
    }
  >;

function makeInitialValues(
  storedPlot: StoredScatterPlotWithArea | null,
  newPlotDefaults: NewStoredPlotDefaults | null
): StoredScatterPlotFormValues {
  if (storedPlot) {
    return {
      ...makeStoredPlotNameSectionInitialValues(storedPlot),
      ...makeStoredPlotTimePeriodInitialValues(storedPlot),
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.SCATTER,
      plots_per_page: storedPlot.plots_per_page,
      items: storedPlot.items.map((plot) => {
        return {
          ...plot,
          reading_series: plot.reading_series.map((serie) => ({
            ...serie,
            opItem: getObsPointItemIdent(
              serie.observation_point,
              serie.item_number
            ),
            randomKey: String(Math.random()),
          })),
          axes: plot.axes.map((axis) => ({
            ...axis,
            mode:
              axis.minimum !== null || axis.maximum !== null
                ? 'custom'
                : 'auto',
            minimum: axis.minimum === null ? '' : axis.minimum,
            maximum: axis.maximum === null ? '' : axis.maximum,
          })),
          show_highlight_periods: plot.highlight_periods.length > 0,
          highlight_periods: plot.highlight_periods.map(
            ({ start_datetime, end_datetime }) => ({
              start_datetime,
              end_datetime: end_datetime ?? '',
            })
          ),
          annotations: plot.annotations.map((a) => ({
            ...(a as StoredPlotNumericAnnotation),
            position: getAnnotationLabelPositionFormValue(a),
            // These fields aren't yet available for scatter plots, so just give
            // them empty values.
            line_style: null,
            stub_position: 3,
            datetime_value: '',
            start_numeric: '',
            end_numeric: '',
            start_datetime: '',
            end_datetime: '',
          })),
        };
      }),
    };
  } else {
    return {
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.SCATTER,
      name: newPlotDefaults?.name ?? '',
      plots_per_page: 1,
      items: [makeEmptyStoredPlotItem(Enum.PlotType.SCATTER)],
      area: 0,
      start_datetime: '',
      end_datetime: '',
      duration: '',
    };
  }
}

function validateForm(
  values: StoredScatterPlotFormValues
): FormikErrors<StoredScatterPlotFormValues> {
  let errors: FormikErrors<StoredScatterPlotFormValues> = {
    ...validateStoredPlotNameSection(values),
    ...validateStoredPlotTimePeriod(values),
    ...validateStoredPlotSaveAsValues(values),
  };

  if (!values.plots_per_page) {
    errors.plots_per_page = (
      <Trans>Plots per page must be at least 1.</Trans>
    ) as any;
  }

  values.items.forEach((plotSection, plotIdx) => {
    const plotSectionErrors = validatePlotSection(
      values.plot_type,
      plotSection
    );
    if (Object.keys(plotSectionErrors).length > 0) {
      lodashSet(errors, `items[${plotIdx}]`, plotSectionErrors);
    }
  });

  return errors;
}

export interface StoredScatterPlotFormProps {
  storedPlot: StoredScatterPlotWithArea | null;
  newPlotDefaults: NewStoredPlotDefaults | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  onSubmit: (
    values: Model.StoredScatterPlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<any>;
  onCancel: () => void;
}

export const StoredScatterPlotForm = (props: StoredScatterPlotFormProps) => {
  const { storedPlot, areaOptions, newPlotDefaults } = props;

  const handleSubmit = React.useCallback(
    async function (
      values: StoredScatterPlotFormValues,
      formik: FormikHelpers<StoredScatterPlotFormValues>
    ) {
      const valuesForBackend: Model.StoredScatterPlot_POST = {
        ...values,
        ...makeStoredPlotTimePeriodBackendValues(values, areaOptions),
        items: values.items.map((plot) => ({
          ...plot,
          reading_series: plot.reading_series.map(
            ({ opItem, ...serie }) => serie
          ),
          axes: plot.axes.map((axis) => ({
            ...axis,
            minimum:
              axis.mode === 'auto' || axis.minimum === ''
                ? null
                : String(axis.minimum),
            maximum:
              axis.mode === 'auto' || axis.maximum === ''
                ? null
                : String(axis.maximum),
          })),
          highlight_periods: plot.highlight_periods.map(
            ({ start_datetime, end_datetime }) => ({
              start_datetime,
              end_datetime: end_datetime || null,
            })
          ),
          annotations: plot.annotations.map((rawAnnotation) => {
            // Remove the fields we don't want for a numeric annotation
            const { start_numeric, start_datetime, end_datetime, ...a } =
              rawAnnotation;

            // Assigning to a typed local valiable to get stricter type-checking
            const finalAnnotation: StoredPlotAnnotation = {
              ...a,
              annotation_type: Enum.StoredPlotAnnotationType.NUMERIC,
              // Special handling to map Y axis field values to appropriate X axis values
              position: getAnnotationLabelPositionValue(a),
              start_datetime: null,
              end_datetime: null,
              line_style: StoredPlotAnnotation_LINE_STYLE.FULL,
              stub_position: null,
            };
            return finalAnnotation;
          }),
        })),
      };
      try {
        await props.onSubmit.call(
          null,
          valuesForBackend,
          getStoredPlotSaveAsSettings(values)
        );
      } catch (e) {
        formik.setSubmitting(false);
        showStoredPlotErrorsInFormik(formik, e, values);
      }
    },
    [areaOptions, props.onSubmit]
  );

  return (
    <Formik<StoredScatterPlotFormValues>
      validate={validateForm}
      initialValues={makeInitialValues(storedPlot, newPlotDefaults)}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const timeZone = formik.values.area
          ? areaOptions.find((a) => a.value === formik.values.area)?.timeZone
          : undefined;

        return (
          <Form>
            {formik.status}
            <FormCard
              name={'general'}
              header={<Trans>General</Trans>}
              className="stored-plot-general-card"
              subHeader={
                <StoredPlotSaveCancelButtons {...props} formik={formik} />
              }
            >
              <StoredPlotNameSection
                areaOptions={areaOptions}
                plotType={Enum.PlotType.SCATTER}
              />
              <StoredPlotTimePeriodSection />
              <StoredPlotLayoutSection formik={formik} />
            </FormCard>

            {formik.values.items.map((plot, plotIdx) => (
              <StoredPlotItemSection
                key={plotIdx}
                plotType={formik.values.plot_type}
                plot={plot}
                plotIdx={plotIdx}
                formik={formik}
                timeZone={timeZone}
              />
            ))}

            <ActionBlock className="text-right">
              <StoredPlotSaveCancelButtons {...props} formik={formik} />
            </ActionBlock>
          </Form>
        );
      }}
    </Formik>
  );
};
