import React, { useMemo, useCallback } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';
import { clientListDuck } from 'ducks/client/list';
import {
  ClientListView,
  clientListFilters,
  clientListColumns,
} from './ClientListView';

type OwnProps = RCPWithQueryParams<Filter.ReportsClients>;

export const ClientListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return clientListColumns({
      refreshList: EMPTY_FUNC,
      deleteClient: EMPTY_FUNC,
    });
  }, []);

  const reportProps = useReportState(
    ownProps,
    columns,
    clientListFilters,
    clientListDuck,
    (state) => state.client.list
  );

  const deleteClient = useCallback(
    async (id: number) => {
      await deleteApi(`/clients/${id}/`);
      reportProps.refreshList();
    },
    [reportProps]
  );

  const props = {
    reportProps,
    deleteClient,
  };

  return <ClientListView {...props} />;
};
