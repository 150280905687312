import React from 'react';
import {
  OnSearchFunc,
  LoadDefaultsFunc,
} from 'components/base/form/asyncsimpleselect/asyncsimpleselect';
import { reportFilterMenu, ReportFilterMenu } from './FilterMenu';
import { ReportFilter } from '../../report-types';
import {
  AsyncSimpleSelectField,
  AsyncSimpleSelectFieldProps,
} from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';

/**
 * Configure an async menu filter, for use in a Report API table.
 *
 * @param name
 * @param label
 * @param onSearch The onSearch callback for the async menu
 * @param loadDefaults The loadDefaults callback for the async menu
 * @param type Whether the filter is "in" or "exact"
 * @param extraProps Other props to pass through to the AsyncSimpleSelect component.
 */
export function reportFilterAsyncMenu(
  name: string,
  label: React.ReactNode,
  settings: {
    isMulti: boolean;
    valueType: 'string' | 'number';
    onSearch: OnSearchFunc<any>;
    loadDefaults: LoadDefaultsFunc<any, any>;
  },
  extraProps: Partial<AsyncSimpleSelectFieldProps<any, any, any>> = {}
): ReportFilter {
  const shared = reportFilterMenu(
    name,
    label,
    settings,
    ReportFilterMenu.PASS_MENU_THROUGH,
    // we can use `any` here because we just don't care, extraProps will be used only in render
    // and we override render method bellow
    extraProps as any
  );

  const { isMulti, onSearch, loadDefaults } = settings;

  return {
    ...shared,
    settings,
    render() {
      return (
        <AsyncSimpleSelectField
          name={name}
          isMulti={isMulti}
          onSearch={onSearch}
          loadDefaults={loadDefaults}
          isClearable={false}
          {...extraProps}
        />
      );
    },
  };
}
