import React, { RefObject, useState } from 'react';
import Loading from 'components/base/loading/loading';
import {
  PolymorphicStoredPlotWithArea,
  StoredTimeSeriesPlotWithArea,
  StoredScatterPlotWithArea,
} from 'ducks/stored-plot/detail';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { Trans } from '@lingui/macro';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { StoredTimeSeriesPlotItem } from './StoredTimeSeriesPlotItem';
import { StoredScatterPlotItem } from './StoredScatterPlotItem';
import { COLORS_POOL, MARKERS_POOL, LABELS_POOL } from './timeseriesplot';
import { SurveyLevellingPlot } from './SurveyLevellingPlot';
import useResizeObserver from 'hooks/use-resize-observer';
import { getSlotsPercentageFromTimeSeriesLayout } from './StoredPlot-utils';
import { SpatialPlanPlot } from 'screens/storedplot/spatial-plot/SpatialPlanPlot';
import { createPortal } from 'react-dom';
import { StoredPlotZoomResetButton } from 'ducks/StoredPlotZoomResetButton';
import { SpatialCrossSectionPlot } from 'screens/storedplot/spatial-plot/SpatialCrossSectionPlot';
import { SpatialWanderPlot } from 'screens/storedplot/spatial-plot/SpatialWanderPlot';
import { useStoredPlotMargin } from 'hooks/use-stored-plot-margin';
import { PlotSettingsAxis, ScatterPlotAxisSide } from './timeseriesplot.types';
import Button from 'components/base/button/button';
import { convertHighlightAreaToAxes } from './scatterplotselectors';
import { CumulativeStoredPlot } from './CumulativeStoredPlot';

export function getColorLabelPoolForStoredPlot(
  storedPlot: PolymorphicStoredPlotWithArea | Model.PolymorphicStoredPlot | null
) {
  const colorsPool: Record<number, string[]> = {};
  const labelsPool: Record<number, string> = {};
  const markersPool: Record<number, string[]> = {};

  if (storedPlot && 'items' in storedPlot) {
    // calculate colorsPool & labelsPool
    let count = 0;
    storedPlot.items.forEach((item) => {
      const start = count;
      count += item.reading_series.length;

      colorsPool[item.id] = item.reading_series.map(
        (_, idx) => COLORS_POOL[(idx + start) % COLORS_POOL.length]
      );
      labelsPool[item.id] = item.reading_series
        .map((_, idx) => LABELS_POOL[(idx + start) % LABELS_POOL.length])
        .join('');
    });

    // calculate markersPool
    // Note: not every series has show_plot_markers turned on
    let markerSeriesCount = 0;
    storedPlot.items.forEach((item) => {
      const start = markerSeriesCount;
      const seriesWithMarkers = item.reading_series.filter(
        (serie) => serie.show_plot_markers
      );

      markerSeriesCount += seriesWithMarkers.length;

      markersPool[item.id] = item.reading_series.map(() => '');
      seriesWithMarkers.forEach((series, idx) => {
        markersPool[item.id][item.reading_series.indexOf(series)] =
          MARKERS_POOL[(idx + start) % MARKERS_POOL.length];
      });
    });
  }

  return { colorsPool, markersPool, labelsPool };
}

interface Props {
  errorMessage: string | null;
  isLoading: boolean;
  storedPlot: null | PolymorphicStoredPlotWithArea;
  buttonsPortal: RefObject<HTMLElement>;
}
/**
 * Component for rendering a single stored plot.
 * Re-used for the "View stored plot" screen and the "Plot set" screen
 * @param props
 */
export function StoredPlot(props: Props) {
  const { errorMessage, isLoading, storedPlot } = props;

  if (errorMessage) {
    return (
      <ErrorNotice>
        <Trans>Failed to fetch data for stored plot</Trans>
      </ErrorNotice>
    );
  } else if (isLoading || !storedPlot) {
    return <Loading />;
  } else {
    switch (storedPlot.plot_type) {
      case Enum.PlotType.SURVEY_LEVELLING:
        return <SurveyLevellingPlot storedPlot={storedPlot} />;
      case Enum.PlotType.TIME_SERIES:
        return (
          <TimeSeriesLayout
            storedPlot={storedPlot}
            buttonsPortal={props.buttonsPortal}
          />
        );
      case Enum.PlotType.SCATTER:
        return (
          <ScatterLayout
            storedPlot={storedPlot}
            buttonsPortal={props.buttonsPortal}
          />
        );
      case Enum.PlotType.SPATIAL_PLAN:
        return (
          <SpatialPlanPlot
            storedPlot={storedPlot}
            buttonsPortal={props.buttonsPortal}
          />
        );
      case Enum.PlotType.SPATIAL_CROSS_SECTION:
        return (
          <SpatialCrossSectionPlot
            storedPlot={storedPlot}
            buttonsPortal={props.buttonsPortal}
          />
        );
      case Enum.PlotType.SPATIAL_WANDER:
        return (
          <SpatialWanderPlot
            storedPlot={storedPlot}
            buttonsPortal={props.buttonsPortal}
          />
        );
      case Enum.PlotType.CUMULATIVE:
        return <CumulativeStoredPlot storedPlot={storedPlot} />;
      default:
        return (
          <ErrorNotice>
            <Trans>Unsupported plot type</Trans>
          </ErrorNotice>
        );
    }
  }
}

/**
 *  Relying on flexbox CSS model, this is the overall approach that we took
 *  to minimize the mesuarement that we have to do in JavaScript.
 *
 *  div.page-multi-plot-timeseries[flex=1, overflow=hidden] (`useResizeObserver` to get the height)
 *    div{height=slot1Height}
 *      div.plot-item[display=flex]
 *         h2
 *         p
 *         div.plot-area[flex=1] (`useResizeObserver` to get the plotHeight)
 *           TimeSeriesPlot{height=plotHeight}
 *         div.legend
 *
 *    div{height=slot2Height}
 *      ...
 *
 *    div{height=slot3Height}
 *      ...
 *
 */
const TimeSeriesLayout = ({
  storedPlot,
  buttonsPortal,
}: {
  storedPlot: StoredTimeSeriesPlotWithArea;
  buttonsPortal?: RefObject<HTMLElement>;
}) => {
  const [ref, , plotAreaHeight] = useResizeObserver<HTMLDivElement>();
  const { colorsPool, markersPool, labelsPool } =
    getColorLabelPoolForStoredPlot(storedPlot);
  const slots = getSlotsPercentageFromTimeSeriesLayout(storedPlot.layout);
  const margin = useStoredPlotMargin(storedPlot);

  return (
    <>
      {buttonsPortal?.current &&
        createPortal(
          <StoredPlotZoomResetButton storedPlot={storedPlot} />,
          buttonsPortal.current
        )}
      <div ref={ref} className="page-multi-plot page-multi-plot-timeseries">
        {slots.map((slotPercentage, idx) => {
          const slotHeight = slotPercentage * plotAreaHeight;
          const item = storedPlot.items[idx];

          return (
            <div style={{ height: slotHeight }} key={item.id}>
              <StoredTimeSeriesPlotItem
                storedPlotId={storedPlot.id}
                plotItem={item}
                colorsPool={colorsPool[item.id]}
                labelsPool={labelsPool[item.id]}
                markersPool={markersPool[item.id]}
                margin={margin}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const ScatterLayout = ({
  storedPlot,
  buttonsPortal,
}: {
  storedPlot: StoredScatterPlotWithArea;
  buttonsPortal?: RefObject<HTMLElement>;
}) => {
  // A dictionary (keyed by plot item id) to keep track of the separate zoom
  // level for each scatter plot item.
  // (Since we don't down-sample scatter plots, we don't need to re-fetch any
  // data when you zoom, so there's no need to use Redux.)
  const [zoom, setZoom] = useState<
    Record<number, PlotSettingsAxis<ScatterPlotAxisSide>[]>
  >({});
  return (
    <>
      {Object.keys(zoom).length > 0 &&
        buttonsPortal?.current &&
        createPortal(
          <Button onClick={() => setZoom({})}>
            <Trans>Reset</Trans>
          </Button>,
          buttonsPortal.current
        )}
      <div className="page-multi-plot page-multi-plot-scatter">
        {storedPlot.items.map((item) => (
          <StoredScatterPlotItem
            storedPlotId={storedPlot.id}
            plotItem={item}
            key={item.id}
            zoom={zoom[item.id]}
            onZoom={(area) => {
              const axes = convertHighlightAreaToAxes(area);
              if (!axes) {
                return;
              }
              setZoom((zoom) => ({
                ...zoom,
                [item.id]: axes,
              }));
            }}
          />
        ))}
      </div>
    </>
  );
};
