import get from 'lodash/get';
import setWith from 'lodash/setWith';
import clone from 'lodash/clone';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

interface ListItem {
  position: number;
}

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const EMPTY_FUNC: () => any = () => {};

/**
 * Reorder a list of items that have position attributes, following the algorithm
 * used by `ObservationPointOrderManager` on the back end.
 *
 * @param list The array of items, before re-ordering. It's assumed that the
 * positions are in order, and are unique, but there may be gaps in the sequence
 * (e.g.: 1, 2, 5, 10, 11, 12, ...)
 * @param fromPosition The `position` the moved item had before its move.
 * @param itemWithNewPosition The full item, with its new position.
 */
export function reorderList<T extends ListItem = ListItem>(
  list: T[],
  fromPosition: number,
  itemWithNewPosition: T
): T[] {
  const newPosition = itemWithNewPosition.position;

  return sortBy(
    list
      // Remove the item from its old position in the list
      .filter((item) => item.position !== fromPosition)
      // Shift some items positions' to make room for the item at its new position
      .map(
        newPosition > fromPosition
          ? (item) =>
              // The item is moving "down" the list. Shift items between the old
              // position and the new position up by 1 to make room.
              item.position >= fromPosition && item.position <= newPosition
                ? { ...item, position: item.position - 1 }
                : item
          : (item) =>
              // The item is moving "up" the list. Shift items between the new
              // position and the old position down by 1 to make room.
              item.position >= newPosition && item.position <= fromPosition
                ? { ...item, position: item.position + 1 }
                : item
      )
      // Insert the item with its new position
      .concat(itemWithNewPosition),
    // Use lodash sortBy to get the item into the right index. (We could do this
    // more efficiently using `Array.prototype.splice()` but that would be less
    // readable.)
    (item) => item.position
  );
}

/**
 * Like lodash's `set()` method, but without mutating the original objects.
 * Instead it creates a clone of any nested object that needs to be updated.
 *
 * Idea taken from: https://github.com/lodash/lodash/issues/1696#issuecomment-328335502
 * @param obj
 * @param path
 * @param value
 */
export function setWithoutMutating<T extends object>(
  obj: T,
  path: string | number | Array<string | number>,
  value: any
): T {
  // If it already matches, no need to update anything.
  if (get(obj, path, !value) === value) {
    return obj;
  } else {
    return setWith(clone(obj), path, value, clone);
  }
}

/**
 * A more typesafe version of lodash's "get()" function. You pass it an object,
 * then a "getter" function to retrieve a value from that object. If it succeeds,
 * you get the value returned by the getter. If not, you get `notFoundValue`
 * (which defaults to `null`).
 *
 * This is probably slower than lodash's get() at runtime, so you should
 * avoid using it in a large loop. But in that case you should probably also
 * avoid using lodash get() as well, and just use a chain of `&&`
 *
 * @param obj
 * @param getter
 * @param notFoundValue
 *
 * @example
 *
 * // As an example, say you want to get the nested "bar" property from an
 * // object like this.
 * type NestedObj = {
 *   foo?: { bar: string }[];
 * };
 * const myObj: NestedObj = { foo: [{ bar: 'baz' }] };
 *
 * // The old-fashioned way, using `&&` operators. This is safe and very
 * // performant, but it's repetitive and hard to read or write.
 * const barFromES5 =
 *   (myObj && myObj.foo && myObj.foo[0] && myObj.foo[0].bar) || -1;
 *
 * // With lodash/get, you provide a string with the "path" to the attribute
 * // you want. Much more readable, but TS cannot typecheck this or infer the return type.
 * const barFromLodash = lodashGet(myObj, 'foo[0].bar', -1);
 *
 * // With getSafely(), instead of a string, you provide a getter function,
 * // which TS can infer from and type-check. (You may still need to use the
 * // TS "ignore nulls" operator `!`)
 * const barFromGetSafely = getSafely(() => myObj.foo![0].bar, -1);
 */
export function getSafely<T, NFV>(getter: () => T, notFoundValue: NFV): T | NFV;
export function getSafely<T>(getter: () => T): T | null;
export function getSafely(getter: () => any, notFoundValue = null) {
  try {
    return getter();
  } catch (e) {
    return notFoundValue;
  }
}

/**
 * Lodash (and d3) both have `range()` functions that generate a sequence of
 * integers. However, the "end" parameter is exclusive, which can be a little
 * confusing when you're trying to generate a sequence of IDs, i.e. to make
 * 1 through 9, you have to do range(1, 10);
 *
 * This function is just a wrapper around lodash range, that uses the "end"
 * parameter inclusively, so that `rangeInclusive(1, 9)` generates integers
 * 1 through 9.
 *
 * @example
 *
 * console.log(range(1, 5).join(', '));
 * // "1, 2, 3, 4"
 *
 * console.log(rangeInclusive(1, 5).join(', '));
 * // "1, 2, 3, 4, 5"
 *
 * @param from
 * @param to
 * @param step
 */
export function rangeInclusive(
  from: number,
  to: number,
  step?: number
): number[] {
  return range(from, to + 1, step);
}

/**
 * A lightweight seedable RNG for generating random-looking test data.
 * This is mostly useful to provide visually appealing sample plotting data in
 * storybook. JS's native "Math.random()" is not as good for this purpose
 * because it can't be seeded, so it will produce a completely different plot
 * each time the page is reloaded.
 *
 * This function uses the Lehmer RNG algorithm, which is much too weak to be a
 * cryptographically useful RNG, but works fine for generating a sequence of
 * "random-looking" numbers.
 *
 * @see https://en.wikipedia.org/wiki/Lehmer_random_number_generator
 * @example
 *
 * const rand = seedPseudoRand();
 *
 * // Generate a random integer between 0 and 99.
 * const n = rand() % 100;
 *
 * @export
 * @param {number} [seed=1] A seed for the RNG. Must be a positive integer
 * greater than 0. (Each RNG seeded with the same integer, will return the same sequence of
 * random integers.)
 * @returns {function():number} A function that will return a "random" integer
 * between 0 and 0x7fffffff each time that it is called.
 */
export function seedPseudoRand(seed = 1) {
  return function pseudoRand() {
    seed = (seed * 48271) % PSEUDO_RAND_MAX;
    return seed;
  };
}
export const PSEUDO_RAND_MAX = 0x7fffffff;

/* Returns a function that generates pseudo random numbers between 0.0 and 1.0 */

export function seedPseudoRandNormalizedFloat(seed: number) {
  const rand = seedPseudoRand(seed);
  return function pseudoRandFloat() {
    const f = (rand() % 10001) / 10000;
    return f;
  };
}

/**
 * Checks whether two arrays contain the same elements (not necessarily in
 * the same order)
 *
 * @param a
 * @param b
 */
export function hasSameEntries<T>(a: T[], b: T[]): boolean {
  if (a.length !== b.length) {
    return false;
  }
  return isEqual(sortBy(a), sortBy(b));
}

/**
 * A helper function for enforcing TypeScript exhaustiveness checks.
 *
 * @see https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html#union-exhaustiveness-checking
 */
export function assertNever(x: never, message: string): never {
  throw new Error(message);
}

/**
 * Take a number of bytes and format it into a human-readable format in the way
 * DSI wants it done: As megabytes, rounded (upward) to 1 decimal place.
 *
 * @param bytes
 */
export function printAsMegabytes(bytes: string | number): string {
  return `${Math.ceil((Number(bytes) / 1000000) * 10) / 10} MB`;
}
