import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';
import lodashMerge from 'lodash/merge';

import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrimary } from 'components/base/button/button';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ModalContent from 'components/base/modal/modalcontent';
import { DateField } from 'components/base/form/datefield/datefield';
import {
  FormSection,
  FormItem,
  FormMultiItem,
} from 'components/base/form/FormItem';
import { Formik, Form, FormikErrors, FieldArray, Field } from 'formik';
import { isNotNull } from 'util/validation';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import Loading from 'components/base/loading/loading';
import { RadioField } from 'components/base/form/radio-field/RadioField';
import { ReadingsDateRangeFilter } from 'components/modules/daterangefilter/readingsdaterangefilter';
import {
  LeftRightToggleField,
  showHideToggleOptions,
} from 'components/base/form/toggle-field/ToggleField';
import { convertDatetimeToDate, parseIntervalFromString } from 'util/dates';
import { getTimeZoneFromPlotMetadata } from 'components/plots/timeseriesplotselectors';
import './QuickPlotSettings.scss';
import { getSafely } from 'util/misc';
import { QuickPlotSettings } from 'components/plots/timeseriesplot.types';
import {
  ObsPointItemMenu,
  getObsPointItemIdent,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import {
  validateDuration,
  validateCustomScale,
} from 'components/plots/validation';
import { QuickPlotSettingsFormValue } from 'components/plots/settingsform.types';

export interface QuickPlotSettingsViewProps {
  isLoading: boolean;
  onSubmit: (values: QuickPlotSettingsFormValue) => void;
  resolvedSettings: QuickPlotSettings | null;
}

type Props = QuickPlotSettingsViewProps;

function validateForm(values: QuickPlotSettingsFormValue) {
  let errors: FormikErrors<QuickPlotSettingsFormValue> = {};

  if (values.opItems.length === 0) {
    errors.opItems = (
      <Trans>Please select an observation point.</Trans>
    ) as any;
  }

  const durationErrors = validateDuration(
    values,
    'numberOfMonths',
    'startDate',
    'endDate'
  );
  if (Object.keys(durationErrors).length > 0) {
    lodashMerge(errors, durationErrors);
  }

  values.yAxisScales.forEach((scale, idx) => {
    const scaleErrors = validateCustomScale(scale, `yAxisScales[${idx}]`);
    if (Object.keys(scaleErrors).length > 0) {
      lodashMerge(errors, scaleErrors);
    }
  });

  return errors;
}

export function getInitialValues(
  resolved: QuickPlotSettings | null
): QuickPlotSettingsFormValue {
  if (!resolved) {
    return {
      opItems: [],
      opItemDetails: [],
      yAxisSides: [],
      yAxisScales: [],
      startDate: '',
      endDate: '',
      numberOfMonths: '',
      showAnalysisComments: false,
      showInspectorComments: false,
      showMedia: false,
    };
  }
  const timeZone = getTimeZoneFromPlotMetadata(resolved);
  const yAxisSides = resolved.reading_series.map((serie, idx) => ({
    opItem: getObsPointItemIdent(serie.observation_point, serie.item_number),
    side: getSafely(() => resolved.reading_series[idx].axis_side, 'left') as
      | 'left'
      | 'right',
  }));
  return {
    yAxisSides,
    opItems: resolved.reading_series.map((serie) =>
      getObsPointItemIdent(serie.observation_point, serie.item_number)
    ),
    opItemDetails: [],
    startDate: convertDatetimeToDate(resolved.start_datetime, timeZone),
    endDate: convertDatetimeToDate(resolved.end_datetime, timeZone),
    numberOfMonths:
      parseIntervalFromString(resolved.duration, 'months').intervalNumber || '',
    // Only provide a scale control for a side that actually has an obs point
    // currently assigned to it.
    yAxisScales: ['left' as const, 'right' as const]
      .filter((side) => yAxisSides.some((yas) => yas.side === side))
      .map((side) => {
        const yAxis = resolved.axes.find((ya) => ya.side === side);
        if (yAxis) {
          return {
            side,
            mode:
              yAxis.minimum === null || yAxis.maximum === null
                ? 'auto'
                : 'custom',
            minimum: yAxis.minimum === null ? '' : yAxis.minimum,
            maximum: yAxis.maximum === null ? '' : yAxis.maximum,
          };
        } else {
          return {
            side,
            mode: 'auto',
            minimum: '',
            maximum: '',
          };
        }
      }),
    showAnalysisComments: resolved.showAnalysisComments,
    showInspectorComments: resolved.showInspectorComments,
    showMedia: resolved.showMediaAll,
  };
}

export const QuickPlotSettingsView: React.FunctionComponent<Props> = function (
  props
) {
  return (
    <ModalContent header={<Trans>Plot settings</Trans>}>
      {props.isLoading ? (
        <Loading />
      ) : (
        <Formik<QuickPlotSettingsFormValue>
          initialValues={getInitialValues(props.resolvedSettings)}
          onSubmit={props.onSubmit}
          validate={validateForm}
        >
          {(formik) => (
            <Form>
              <FormSection label={<Trans>Observation points</Trans>}>
                <ObsPointItemMenu
                  id="list-plot-settings-observation-points"
                  autoFocus={true}
                  name="opItems"
                  detailsName="opItemDetails"
                  className={classNames({
                    'clear-disallowed': formik.values.opItems.length === 1,
                  })}
                  isMulti
                />
                <FieldError name="opItems" />
                <div
                  id="quickplotsettings-y-axis"
                  className="quickplotsettings-y-axis"
                >
                  <FieldArray name="yAxisSides">
                    {(arrayHelpers) => (
                      <>
                        <FormChangeEffect<QuickPlotSettingsFormValue>
                          onChange={() => {
                            // Ensure that there is one formik form value
                            // for the "y axis side" control, for each
                            // selected observation point.
                            // To keep things sane, the order of this array
                            // doesn't matter; we'll render the controls going
                            // by the order of values.opItems
                            if (
                              formik.values.yAxisSides.length ===
                                formik.values.opItems.length &&
                              formik.values.yAxisSides.every((yas) =>
                                formik.values.opItems.includes(yas.opItem)
                              )
                            ) {
                              return;
                            }

                            const sides = formik.values.yAxisSides.map(
                              (yas) => yas.opItem
                            );

                            // Remove form controls for de-selected obs points
                            const removed = difference(
                              sides,
                              formik.values.opItems
                            );
                            if (removed.length) {
                              // We can only safely remove one item at a time
                              // from this list, because the removal is based
                              // on array index, and the array indexes may
                              // change after the first removal.
                              arrayHelpers.remove(sides.indexOf(removed[0]));
                            } else {
                              // Add form controls for newly selected obs points
                              const added = difference(
                                formik.values.opItems,
                                sides
                              );
                              added.forEach((opItem) =>
                                arrayHelpers.push({
                                  opItem,
                                  side: 'left',
                                })
                              );
                            }
                          }}
                        />
                        {formik.values.opItems.map((opItem, opItemIdx) => {
                          // The `yAxisSides` array is not necessarily sorted
                          // in the same order as the observation point selections,
                          // because it's hard to use Formik's array helpers
                          // to do that.
                          // So we'll find the index of the existing yAxisSides
                          // entry for this opItem (if there is one)
                          const valueIdx = formik.values.yAxisSides.findIndex(
                            (ya) => ya.opItem === opItem
                          );
                          if (valueIdx === -1) {
                            return null;
                          } else {
                            return (
                              <LeftRightToggleField
                                key={opItem}
                                label={getSafely(
                                  () =>
                                    formik.values.opItemDetails[opItemIdx].label
                                )}
                                name={`yAxisSides[${valueIdx}].side`}
                                inline
                                className="radio-fieldset-condensed"
                              />
                            );
                          }
                        })}
                      </>
                    )}
                  </FieldArray>
                </div>
              </FormSection>

              {/* effect to add/remove "X/Y Scale" section based on Obs Point selection */}
              <FormChangeEffect<QuickPlotSettingsFormValue>
                onChange={({ values: prevValues }) => {
                  // TODO: this is copy-pasted into StoredPlotEditView
                  const prevSides = uniq(
                    sortBy(prevValues.yAxisSides.map(({ side }) => side))
                  );
                  const sides = uniq(
                    sortBy(formik.values.yAxisSides.map(({ side }) => side))
                  );

                  if (!isEqual(prevSides, sides)) {
                    const removals = difference(prevSides, sides);
                    const additions = difference(sides, prevSides);

                    const scaleConfigs = sortBy(
                      [
                        // Remove the form values for no-longer-needed scales.
                        ...formik.values.yAxisScales.filter(
                          (scale) =>
                            !removals.includes(scale.side as 'left' | 'right')
                        ),
                        // Add form values for newly needed scales.
                        ...additions.map((side) => ({
                          minimum: '' as const,
                          maximum: '' as const,
                          mode: 'auto' as const,
                          side,
                        })),
                      ],
                      'side'
                    );

                    formik.setFieldValue('yAxisScales', scaleConfigs);
                  }
                }}
              />
              {formik.values.yAxisScales.map((yAxis, idx) => {
                const sectionLabel =
                  yAxis.side === 'left' ? (
                    <Trans>Y axis - left scale</Trans>
                  ) : (
                    <Trans>Y axis - right scale</Trans>
                  );

                return (
                  <FormSection
                    key={idx}
                    label={sectionLabel}
                    id={`quickplotsettings-${yAxis.side}-scale`}
                    data-testid="quickplotsettings-yaxis-scale"
                    className="radio-fieldset-toggle"
                  >
                    <RadioField
                      key={idx}
                      name={`yAxisScales[${idx}].mode`}
                      className="form-group"
                      options={[
                        {
                          label: <Trans>Auto</Trans>,
                          value: 'auto',
                        },
                        {
                          label: <Trans>Custom</Trans>,
                          value: 'custom',
                        },
                      ]}
                    />
                    {yAxis.mode === 'custom' ? (
                      <div className="columns-even">
                        <FormItem
                          label={<Trans>Min</Trans>}
                          fieldId={`quickplotsettings-yAxisScales[${idx}]-minimum`}
                        >
                          <Field
                            name={`yAxisScales[${idx}].minimum`}
                            id={`quickplotsettings-yAxisScales[${idx}]-minimum`}
                            type="number"
                            data-testid="min"
                          />
                          <FieldError name={`yAxisScales[${idx}].minimum`} />
                        </FormItem>
                        <FormItem
                          label={<Trans>Max</Trans>}
                          fieldId={`quickplotsettings-yAxisScales[${idx}]-maximum`}
                        >
                          <Field
                            name={`yAxisScales[${idx}].maximum`}
                            id={`quickplotsettings-yAxisScales[${idx}]-maximum`}
                            type="number"
                            data-testid="max"
                          />
                          <FieldError name={`yAxisScales[${idx}].maximum`} />
                        </FormItem>
                      </div>
                    ) : null}
                  </FormSection>
                );
              })}

              {/* effect to clear the value when switch from Custom --> Auto */}
              <FormChangeEffect<QuickPlotSettingsFormValue>
                onChange={({ values: prevValues }) => {
                  if (
                    formik.values.yAxisScales.length &&
                    formik.values.yAxisScales.length ===
                      prevValues.yAxisScales.length
                  ) {
                    formik.values.yAxisScales.forEach((scale, idx) => {
                      if (
                        scale.side === prevValues.yAxisScales[idx].side &&
                        scale.mode === 'auto' &&
                        prevValues.yAxisScales[idx].mode === 'custom'
                      ) {
                        formik.setFieldValue(`yAxisScales[${idx}].minimum`, '');
                        formik.setFieldTouched(
                          `yAxisScales[${idx}].minimum`,
                          false
                        );
                        formik.setFieldValue(`yAxisScales[${idx}].maximum`, '');
                        formik.setFieldTouched(
                          `yAxisScales[${idx}].maximum`,
                          false
                        );
                      }
                    });
                  }
                }}
              />
              <FormSection label={<Trans>X Axis scale</Trans>}>
                <ReadingsDateRangeFilter
                  timeZone={
                    props.resolvedSettings && props.resolvedSettings.storedPlot
                      ? props.resolvedSettings.storedPlot.area.time_zone.name
                      : undefined
                  }
                  observationPointIds={formik.values.opItemDetails
                    .map(
                      (opt) => opt.observationPoint && opt.observationPoint.id
                    )
                    .filter(isNotNull)}
                  render={({ startProps, endProps }) => {
                    return (
                      <>
                        <div className="form-group form-group-panel-inline">
                          <label htmlFor="quickplotsettings-startdate">
                            <Trans>Start date</Trans>
                          </label>
                          <DateField
                            id="quickplotsettings-startdate"
                            name="startDate"
                            disabled={startProps.disabled}
                            placeholder={startProps.placeholder}
                          />
                        </div>
                        <div className="form-group form-group-panel-inline">
                          <label htmlFor="quickplotsettings-enddate">
                            <Trans>End date</Trans>
                          </label>
                          <DateField
                            id="quickplotsettings-enddate"
                            name="endDate"
                            disabled={endProps.disabled}
                            placeholder={endProps.placeholder}
                          />
                        </div>
                        <FieldError name="startDate" />
                        <FieldError name="endDate" />
                      </>
                    );
                  }}
                />
                <FormItem
                  label={<Trans>Number of months</Trans>}
                  fieldId="quickplotsettings-numberOfMonths"
                >
                  <Field
                    name="numberOfMonths"
                    id="quickplotsettings-numberOfMonths"
                    type="number"
                    min="1"
                  />
                  <FieldError name="numberOfMonths" />
                </FormItem>
              </FormSection>
              <FormSection label={<Trans>Display options</Trans>}>
                <FormMultiItem
                  className="radio-fieldset-toggle radio-fieldset-inline"
                  label={<Trans>Analysis comments</Trans>}
                >
                  <RadioField
                    className="form-group"
                    name="showAnalysisComments"
                    options={showHideToggleOptions}
                  />
                </FormMultiItem>
                <FormMultiItem
                  className="radio-fieldset-toggle radio-fieldset-inline"
                  label={<Trans>Inspector comments</Trans>}
                >
                  <RadioField
                    className="form-group"
                    name="showInspectorComments"
                    options={showHideToggleOptions}
                  />
                </FormMultiItem>
                <FormMultiItem
                  className="radio-fieldset-toggle radio-fieldset-inline"
                  label={<Trans>Associated media</Trans>}
                >
                  <RadioField
                    className="form-group"
                    name="showMedia"
                    options={showHideToggleOptions}
                  />
                </FormMultiItem>
              </FormSection>
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  id="quickplotsettings-apply"
                  type="submit"
                  iconType="icon-update"
                >
                  <Trans>Apply settings</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      )}
    </ModalContent>
  );
};
