import { selectOneById, EntityTypes, withInsertEntities } from './entities';
import { fetchJsonEndpoint, withAuthHeaders } from '../util/backendapi/fetch';
import { t } from '@lingui/macro';

/**
 * Defining the ActionTypes enum as a class, because IDEs are better at
 * figuring out the autocomplete options if it's a class rather than an
 * object literal.
 */
export class ActionTypes {
  static UNMOUNT_FILE_EDITOR = 'dms/fileeditor/UNMOUNT_FILE_EDITOR';
  static FETCH_FILE_CONTENT = 'dms/fileeditor/FETCH_FILE_CONTENT';
  static FETCH_FILE_CONTENT_RESPONSE =
    'dms/fileeditor/FETCH_FILE_CONTENT_RESPONSE';
  static FETCH_FILE_CONTENT_ERROR = 'dms/fileeditor/FETCH_FILE_CONTENT_ERROR';
  static FETCH_FILE_PARSER_MESSAGES =
    'dms/fileeditor/FETCH_FILE_PARSER_MESSAGES';
  static FETCH_FILE_PARSER_MESSAGES_RESPONSE =
    'dms/fileeditor/FETCH_FILE_PARSER_MESSAGES_RESPONSE';
  static FETCH_FILE_PARSER_MESSAGES_ERROR =
    'dms/fileeditor/FETCH_FILE_PARSER_MESSAGES_ERROR';
  static UPLOAD_CHANGES = 'dms/fileeditor/EDIT_READINGS_FILE';
  static UPLOAD_CHANGES_RESPONSE = 'dms/fileeditor/EDIT_READINGS_FILE_RESPONSE';
  static UPLOAD_CHANGES_ERROR = 'dms/fileeditor/EDIT_READINGS_FILE_ERROR';
}

const ACT = ActionTypes;

/**
 * A function that returns the initial state for the fileEditorReducer.
 * (This is a function rather than a plain object, in order to avoid accidentally
 * doing a shallow copy that retains a pointer to the same "parserMessages" field.)
 */
function makeFileEditorInitialState() {
  return {
    error: false,
    finished: false,
    fileContent: null,
    parserMessages: null,
  };
}

export default function fileEditorReducer(
  state = makeFileEditorInitialState(),
  action
) {
  switch (action.type) {
    case ACT.FETCH_FILE_PARSER_MESSAGES_RESPONSE:
      return { ...state, parserMessages: action.payload };
    case ACT.FETCH_FILE_CONTENT_RESPONSE:
      return { ...state, fileContent: action.payload.text };
    case ACT.UPLOAD_CHANGES_RESPONSE:
      return { ...state, finished: true };
    case ACT.UNMOUNT_FILE_EDITOR:
      return makeFileEditorInitialState();
    default:
      return state;
  }
}

/**
 * Simple action creator for the "unmount file editor" action.
 *
 * @export
 * @returns {object}
 */
export function unmountFileEditor() {
  return { type: ACT.UNMOUNT_FILE_EDITOR };
}

/**
 *
 *
 * @export
 * @param {number} id
 * @param {number} reasonForChange
 * @param {string} fileName
 * @param {string} fileContent
 * @returns A thunk
 */
export function editReadingsFile(id, reasonForChange, fileContent) {
  return async function (dispatch, getState, { i18n }) {
    dispatch({ type: ACT.UPLOAD_CHANGES });
    try {
      const fileData = selectOneById(getState(), EntityTypes.READINGS_FILE, id);
      if (!fileData) {
        throw new Error(i18n._(t`Cannot find data for file ${id}`));
      }

      // TODO: The file upload endpoint is currently ignoring the names
      // of newly uploaded files
      const fileName = fileData.file_name || 'filename.missing';

      // Make a new File object and FormData, because that's the easiest way
      // to construct a multi-part formdata request like we want.
      // TODO: MS Edge doesn't support the File() constructor. As a workaround,
      // we can use a Blob object instead. In the longer term, it would probably
      // be better to do the multi-part formdata encoding ourselves, or change
      // the backend API so it'll accept the edited file content in another format
      const fileObj = new Blob([fileContent]);
      const formData = new FormData();
      formData.append('content', fileObj, fileName);
      formData.append('update_reason', reasonForChange);

      // Upload the file
      const readingsFile = await fetchJsonEndpoint(
        `/readings-files/${id}/`,
        withAuthHeaders({
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
          },
          body: formData,
        })
      );

      dispatch(
        withInsertEntities(
          { type: ACT.UPLOAD_CHANGES_RESPONSE },
          EntityTypes.READINGS_FILE,
          [readingsFile],
          false
        )
      );
    } catch (e) {
      return dispatch({
        type: ACT.UPLOAD_CHANGES_ERROR,
        error: true,
        payload: e.message,
      });
    }
  };
}

/**
 * Get the content of a particular file, from its "contentUrl" field (provided
 * to us by the files endpoint.)
 * @param {string} contentUrl
 */
export function fetchFileContent(contentUrl) {
  return async function (dispatch) {
    dispatch({ type: ACT.FETCH_FILE_CONTENT });
    try {
      const response = await fetch(contentUrl);
      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }
      const text = await response.text();
      return dispatch({
        type: ACT.FETCH_FILE_CONTENT_RESPONSE,
        payload: {
          text,
        },
      });
    } catch (e) {
      return dispatch({
        type: ACT.FETCH_FILE_CONTENT_ERROR,
        error: true,
        payload: e.message,
      });
    }
  };
}

export function fetchFileParserMessages(id) {
  return async function (dispatch) {
    dispatch({ type: ACT.FETCH_FILE_PARSER_MESSAGES });
    try {
      const parserMessages = await fetchJsonEndpoint(
        `/readings-files/${id}/parse-messages/`
      );
      return dispatch({
        type: ACT.FETCH_FILE_PARSER_MESSAGES_RESPONSE,
        payload: parserMessages,
      });
    } catch (e) {
      return dispatch({
        type: ACT.FETCH_FILE_PARSER_MESSAGES_ERROR,
        error: true,
        payload: e.message,
      });
    }
  };
}
