import React from 'react';

export interface DateRangeFilterProps {
  /**
   * "Suggested" aka "Default" values to fill in to the fields and/or display
   * as placeholders. e.g., the first and last reading dates for the selected
   * observation points. The field *may* update automatically when these change.
   */
  suggestedStartDate?: string;
  suggestedEndDate?: string;

  render: (props: {
    isLoading: boolean;
    startProps: {
      placeholder?: string;
      disabled?: boolean;
    };
    endProps: {
      placeholder?: string;
      disabled?: boolean;
    };
  }) => JSX.Element | null;
  /**
   * Whether the parent component is currently fetching new "suggested" values.
   */
  isLoading: boolean;
  isDisabled?: boolean;
}

/**
 * A "logical" component with the sole purpose of passing down some suggested
 * values of startDate & endDate to children via render prop.
 *
 * It should be used together with a Container Component whose job is to fetch the
 * suggested values either remotely or from Redux. (eg. ReadingsDateRangeFilter)
 *
 * @export
 * @class DateRangeFilter
 * @extends {React.Component}
 */
export class DateRangeFilter extends React.Component<DateRangeFilterProps> {
  /**
   * Rendering! This is mostly a "logical" component, so all it does is call
   * its render prop, passing in some of its computed values.
   */
  render() {
    const renderProps = {
      isLoading: this.props.isLoading,
      startProps: {
        // The placeholder should default to "undefined" if it's empty, because
        // that will tell a DateTimeInput component to print "DD/MM/YYYY"
        placeholder: this.props.isDisabled
          ? undefined
          : this.props.isLoading
          ? '...'
          : this.props.suggestedStartDate || undefined,
        disabled: this.props.isDisabled,
      },
      endProps: {
        placeholder: this.props.isDisabled
          ? undefined
          : this.props.isLoading
          ? '...'
          : this.props.suggestedEndDate || undefined,
        disabled: this.props.isDisabled,
      },
    };

    return this.props.render(renderProps);
  }
}
