import {
  LabelSeries as BaseLabelSeries,
  CustomSVGSeries as BaseCustomSVGSeries,
} from 'react-vis';
import ScaleUtils from 'react-vis/dist/utils/scales-utils';

// HACK: To bypass incorrect proptypes on some of React-Vis's components,
// (Specifically, typing 'x' as 'string | number', when 'Date' is also allowed)
// we use our own subclasses that don't have any PropTypes.
export class LabelSeries<T> extends BaseLabelSeries<T> {
  // @ts-ignore
  static defaultProps = BaseLabelSeries.defaultProps;
  // @ts-ignore
  static displayName = 'LabelSeries';
  // @ts-ignore
  static propTypes = {};
}
export class CustomSVGSeries<T> extends BaseCustomSVGSeries<T> {
  // @ts-ignore
  static defaultProps = BaseCustomSVGSeries.defaultProps;
  // @ts-ignore
  static displayName = 'CustomSVGSeries';
  // @ts-ignore
  static propTypes = {};
}

/**
 * Replicates the logic used by React-Vis inside its components,
 * to get the D3 scales for the X axis and Y axis for a particular
 * component.
 *
 * Due to the weird way D3 works, this must be called from a direct
 * child of an XYPlot (or something that receives all the props
 * from a direct child), and all the props must be passed to this
 * function.
 *
 * NOTE: Because it needs to use an unspecified subset of the props,
 * `useMemo()` cannot be used, and therefore there's no reason to make
 * this function into a hook.
 *
 * @param props
 */
export function getReactVisScales<X = Date, Y = number>(props: any) {
  return {
    scaleX: ScaleUtils.getAttributeFunctor(props, 'x') as (x: X) => number,
    scaleY: ScaleUtils.getAttributeFunctor(props, 'y') as (y: Y) => number,
  };
}
