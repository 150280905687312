import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import {
  fetchInstrumentType,
  updateInstrumentType,
  createInstrumentType,
} from '../../../ducks/instrument-type/detail';
import { Model, Enum } from '../../../util/backendapi/models/api.interfaces';
import {
  InstrumentTypeDetailView,
  InstrumentTypeDetailViewProps,
  EDIT_SECTION,
} from './InstrumentTypeDetailView';
import { FullState } from 'main/reducers';
import { StandardDispatch } from 'main/store';
import {
  RCPWithQueryParams,
  setOneQueryParam,
  parseQueryParamFromRouterProps,
  currentQueryString,
} from 'util/routing';
import { selectHasPermission } from 'util/user';
import { RouteChildrenProps } from 'react-router';

type OwnProps = RCPWithQueryParams<
  { edit?: 'main' | 'items' },
  { instrumentTypeCode: string }
> & {
  createNew?: boolean;
};

type StateProps = Pick<
  InstrumentTypeDetailViewProps,
  'instrumentType' | 'hasEditPermission' | 'mode' | 'error' | 'loading'
>;

export const InstrumentTypeDetail: React.FunctionComponent<OwnProps> =
  function (props) {
    const code = props.match.params.instrumentTypeCode;

    const stateProps = useSelector<FullState, StateProps>((state) => {
      const hasEditPermission = selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_instrument_types
      );

      let mode: StateProps['mode'];
      if (props.createNew) {
        mode = 'NEW';
      } else if (
        hasEditPermission &&
        Boolean(parseQueryParamFromRouterProps(props, 'edit', false))
      ) {
        mode = 'EDIT';
      } else {
        mode = 'VIEW';
      }

      return {
        instrumentType:
          mode === 'NEW' ? null : state.instrumentType.detail.instrumentType,
        error: state.instrumentType.detail.error,
        loading: state.instrumentType.detail.loading,
        hasEditPermission,
        mode,
        edit: parseQueryParamFromRouterProps(props, 'edit', false),
      };
    }, shallowEqual);

    const dispatch = useDispatch<StandardDispatch>();

    const routeProps = React.useMemo<RouteChildrenProps>(
      () => ({
        history: props.history,
        location: props.location,
        match: props.match,
      }),
      [props.history, props.location, props.match]
    );

    // Fetch data on mount.
    React.useEffect(() => {
      dispatch(fetchInstrumentType(code));
    }, [dispatch, code]);

    // Set up callback functions needed by the view.
    const handleCancelEditing = React.useCallback(() => {
      if (stateProps.mode === 'NEW') {
        routeProps.history.push('/instrument-types/');
      } else {
        setOneQueryParam(routeProps, 'edit', null);
      }
    }, [stateProps.mode, routeProps]);

    const handleStartEditing = React.useCallback(
      (section: EDIT_SECTION) => setOneQueryParam(routeProps, 'edit', section),
      [routeProps]
    );

    const onSubmit = React.useCallback(
      (values: ForPostOrPut<Model.InstrumentType>, id: number | null) => {
        if (stateProps.mode === 'NEW') {
          return dispatch(createInstrumentType(values));
        } else {
          return dispatch(updateInstrumentType(id!, values));
        }
      },
      [dispatch, stateProps.mode]
    );

    const onAfterSubmit = React.useCallback(
      (values: Model.InstrumentType) => {
        // if the code is updated, we need to change the current URL to new updated code
        // otherwise user will get an empty page when refresh
        const qs = currentQueryString(routeProps.location, {
          exclude: ['edit'],
        });
        routeProps.history.replace(`/instrument-types/${values.code}${qs}`);
      },
      [routeProps]
    );
    return (
      <InstrumentTypeDetailView
        key={code}
        {...props}
        {...stateProps}
        onCancel={handleCancelEditing}
        onEdit={handleStartEditing}
        onSubmit={onSubmit}
        onAfterSubmit={onAfterSubmit}
      />
    );
  };
