import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './actionblock.scss';

export default function ActionBlock(props) {
  const { children, className, ...otherProps } = props;
  return (
    <div className={classNames('action-block', className)} {...otherProps}>
      {children}
    </div>
  );
}
ActionBlock.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
