import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { postApiFormData } from 'util/backendapi/fetch';
import { downloadFile } from 'util/backendapi/download';
import { useIsMounted } from 'util/hooks';
import { FormikHelpers, FormikErrors, Formik, Form } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { FileField } from 'components/base/form/file-field/FileField';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { useHistory } from 'react-router';
import { AlarmParameter } from 'util/backendapi/types/Model';
import {
  bulkCreateError,
  clearBulkCreateErrors,
} from 'ducks/bulk-create/errors';
import { useDispatch } from 'react-redux';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import uniq from 'lodash/uniq';

type FormValues = {
  file_name: string;
  attached_file: File | null;
};

interface Props {
  onAfterSubmit: () => void;
}

export function AlarmParametersBulkCreateModal({ onAfterSubmit }: Props) {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const history = useHistory();

  const validate = useCallback(
    (values: FormValues): FormikErrors<FormValues> => {
      const errors: FormikErrors<FormValues> = {};
      if (!values.file_name) {
        errors.file_name = (<Trans>A file is required.</Trans>) as any;
      }
      return errors;
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      const formData = new FormData();

      if (values.file_name && values.attached_file) {
        formData.append('file', values.attached_file, values.file_name);
      }

      try {
        const result = await postApiFormData(
          '/alarm-parameters/bulk-create/',
          formData
        );

        const observationPointIds = uniq(
          result.map(
            (alarmParameter: AlarmParameter) => alarmParameter.observation_point
          )
        ).join(',');

        dispatch(clearBulkCreateErrors());
        history.push(
          `/alarm-parameter-report/?now=false&observation_point=${observationPointIds}`
        );
        onAfterSubmit();
      } catch (err) {
        if (isMounted()) {
          formik.setSubmitting(false);
          if (err.content) {
            await dispatch(bulkCreateError(err));
            history.push(`/alarm-parameter-report/bulk-create/`);
          } else if (err instanceof Error) {
            formik.setFieldError(
              'file_name',
              'The upload was not successful. Please try again.'
            );
          } else {
            showErrorsInFormik(
              formik,
              err,
              getExpectedFields(values),
              ErrorNotice
            );
          }
        }
      }
    },
    [onAfterSubmit, isMounted, dispatch, history]
  );

  const initialValues: FormValues | null = useMemo(() => {
    return {
      attached_file: null,
      file_name: '',
    };
  }, []);

  return (
    <ModalContent header={<Trans>Bulk create alarm parameters</Trans>}>
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formik) => (
          <Form>
            <div
              id="bulk-create-alarm-parameters-modal"
              className="bulk-create-modal"
            >
              {formik.status}
              <FormSection>
                <Button
                  onClick={() =>
                    downloadFile('/alarm-parameters/bulk-create-template/')
                  }
                >
                  <Trans>Download alarm parameters CSV template</Trans>
                </Button>
              </FormSection>
              <FormSection>
                <Trans>
                  Please note:
                  <ul>
                    <li>
                      The observation points(s) must be created in the DMS
                      before uploading the completed alarm parameter CSV.
                    </li>
                    <li>
                      Dates and times must be entered in the following format:
                      dd/mm/yyyy hh:mm
                    </li>
                    <li>
                      All dates and times will be saved in the area's timezone.
                    </li>
                    <li>
                      The details in the CSV will be validated and any errors
                      will be displayed on screen. If no errors are found all of
                      the alarm parameters will be created.
                    </li>
                  </ul>
                </Trans>
              </FormSection>
              <FormSection>
                <FormItem
                  label={<Trans>Upload complete alarm parameters CSV</Trans>}
                >
                  <div className="file-input-wrapper">
                    <FileField
                      name="file_name"
                      fileFieldName="attached_file"
                      fileExtensions={['.csv']}
                    />
                  </div>
                  <FieldError name="file_name" />
                </FormItem>
              </FormSection>
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="bulk-create-alarm-parameters-submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-upload"
                >
                  <Trans>Upload</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </div>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
