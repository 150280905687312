import React from 'react';
import { RadioFieldProps, RadioField } from '../radio-field/RadioField';
import { yesNoToggleOptions } from '../toggle-field/ToggleField';

/**
 * A Formik field for "yes/no" options. Returns boolean `true` for yes,
 * boolean `false` for no.
 *
 * TODO: Replace instances of this with YesNoToggleField
 *
 * @example See `yesnoradiofield.stories.js`
 * @param {string} name name of Formik form field
 * @deprecated Use YesNoToggleField instead, unless you need weird special styling.
 */
export const YesNoRadioField = (props: Omit<RadioFieldProps, 'options'>) => (
  <RadioField {...props} options={yesNoToggleOptions} />
);
