import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import { formatDatetimeForDisplay } from 'util/dates';
import { Model } from 'util/backendapi/models/api.interfaces';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import {
  ExportPanel,
  GetExportUrlFunc,
} from 'components/modules/exportpanel/exportpanel';
import { PaginationMeta } from 'util/backendapi/pagination';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { GeneratedOnMessage } from 'components/base/generated-on-message/GeneratedOnMessage';
import { QuickListSettings } from './settings/QuickListSettings';
import {
  ReportColumn,
  ALWAYS_SHOW,
} from 'components/modules/report/report-types';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { QuickListReadingsMode } from './settings/QuickListSettingsView';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';
import { DMSLink } from 'components/base/link/DMSLink';

function isGap(
  item: Model.Reading | Model.ReadingGapComment
): item is Model.ReadingGapComment {
  return 'comment_type' in item;
}

function getQuickListViewColumns(
  observationPoint: Model.ObservationPointDecorated | null,
  numRawReadingEntries?: number,
  readingsMode: QuickListReadingsMode = 'adjusted'
) {
  if (!observationPoint || numRawReadingEntries === undefined) {
    return [];
  }
  const columns: ReportColumn<Model.Reading | Model.ReadingGapComment>[] = [
    {
      label: <Trans>Date and time</Trans>,
      name: 'reading_datetime',
      tdClassName: 'text-no-wrap',
      visibility: ALWAYS_SHOW,
      accessor: (reading) =>
        formatDatetimeForDisplay(
          isGap(reading) ? reading.at_datetime : reading.reading_datetime,
          observationPoint.time_zone.name
        ),
    },
  ];
  // Because of the variable number of columns, we can't easily hook into the
  // normal report framework "columns" URL param to indicate which columns to
  // display.
  if (readingsMode === 'raw' || readingsMode === 'both') {
    // Show raw readings
    for (let i = 0; i < numRawReadingEntries; i++) {
      columns.push({
        label: <Trans>Raw reading {i + 1}</Trans>,
        name: `raw_reading_value_${i + 1}`,
        tdClassName: 'text-no-wrap',
        visibility: ALWAYS_SHOW,
        accessor: (reading) =>
          isGap(reading) ? (
            <Trans>Data gap</Trans>
          ) : (
            reading.raw_reading_entries?.find((e) => e.position === i + 1)
              ?.value
          ),
      });
    }
  }
  if (readingsMode === 'adjusted' || readingsMode === 'both') {
    // Show adjusted readings.
    observationPoint.instrument_type.items.forEach(
      (item: Model.InstrumentTypeItem) => {
        columns.push({
          label: <Trans>{item.description}</Trans>,
          name: `reading_value_${item.item_number}`,
          tdClassName: 'text-no-wrap',
          visibility: ALWAYS_SHOW,
          // adjusted reading entries on the readings endpoint are ordered by their item_number
          accessor: (reading) =>
            isGap(reading) ? (
              <Trans>Data gap</Trans>
            ) : (
              reading.adjusted_reading_entries?.[item.item_number - 1]?.value
            ),
        });
      }
    );
  }
  if (readingsMode === 'raw' || readingsMode === 'both') {
    // Show batch number.
    columns.push({
      label: <Trans>Batch</Trans>,
      name: 'batch_number',
      tdClassName: 'text-no-wrap',
      visibility: ALWAYS_SHOW,
      accessor: (reading) =>
        isGap(reading) ? null : (
          <DMSLink
            to={`/check-readings/${reading.readings_batch.batch_number}`}
          >
            {reading.readings_batch.batch_number}
          </DMSLink>
        ),
    });
  }

  columns.push({
    label: <Trans>Inspector comment</Trans>,
    tdClassName: '',
    name: 'inspector_comment',
    visibility: ALWAYS_SHOW,
    accessor: (reading) => (
      <div className="text-with-linebreaks">
        {isGap(reading) ? reading.content : reading.inspector_comment?.content}
      </div>
    ),
  });
  return columns;
}

export interface QuickListViewProps {
  isLoading: boolean;
  errorMessage: string | null;
  getExportUrl: GetExportUrlFunc | null;
  observationPoint: Model.ObservationPointDecorated | null;
  observationPointCode: string | null;
  readings: Model.Reading[] | null;
  gaps: Model.ReadingGapComment[] | null;
  readingsMode: QuickListReadingsMode;
  pagination: PaginationMeta | null;
  generatedOnDatetime: string | null;
}
export function QuickListView(props: QuickListViewProps) {
  const numRawReadingEntries = useMemo(
    () =>
      props.readings?.reduce(
        (maxEntries, reading) =>
          Math.max(reading.raw_reading_entries.length, maxEntries),
        0
      ),
    [props.readings]
  );
  const columns = React.useMemo(
    () =>
      getQuickListViewColumns(
        props.observationPoint,
        numRawReadingEntries,
        props.readingsMode
      ),
    [numRawReadingEntries, props.observationPoint, props.readingsMode]
  );

  const isObservationPointSelected = Boolean(props.observationPointCode);

  const derivedErrorMessage = isObservationPointSelected ? (
    props.errorMessage
  ) : (
    <Trans>No observation point selected.</Trans>
  );

  const readingsAndGaps: (Model.Reading | Model.ReadingGapComment)[] =
    useMemo(() => {
      if (!props.readings || props.readings.length === 0) {
        return [];
      }
      if (!props.gaps || props.gaps.length === 0) {
        return props.readings;
      }
      const firstReadingDatetime = props.readings?.[0].reading_datetime;
      const lastReadingDatetime =
        props.readings?.[props.readings.length - 1].reading_datetime;
      const applicableGaps = props.gaps.filter(
        (g) =>
          g.at_datetime >= firstReadingDatetime &&
          g.at_datetime < lastReadingDatetime
      );
      if (applicableGaps.length === 0) {
        return props.readings;
      } else {
        return sortBy([...props.readings, ...applicableGaps], (item) =>
          isGap(item) ? item.at_datetime : item.reading_datetime
        );
      }
    }, [props.gaps, props.readings]);

  return (
    <PageStandard
      name="quicklist"
      header={<Trans>Quick List</Trans>}
      subHeader={props.observationPointCode}
    >
      <div className="page-content-header columns-fluid">
        {isObservationPointSelected && (
          <GeneratedOnMessage
            when={props.generatedOnDatetime}
            showInExportMode={false}
          />
        )}
        <ActionBlock className="text-right">
          {props.getExportUrl && (
            <HasPermission check="can_export_lists">
              <ButtonShowModal
                name="export"
                // Don't activate the export button while data is still being loaded.
                disabled={
                  !isObservationPointSelected ||
                  props.isLoading ||
                  !props.observationPoint ||
                  !props.readings
                }
                modalContent={() => (
                  <ExportPanel
                    title={<Trans>Export list</Trans>}
                    description={
                      <Trans>
                        The list will be exported with the same settings as
                        displayed on screen.
                      </Trans>
                    }
                    canExportPdf={true}
                    canExportCsv={true}
                    taskQueueFormats={[ExportFormats.PDF, ExportFormats.CSV]}
                    getExportUrl={props.getExportUrl!}
                  />
                )}
                iconType="icon-export"
              >
                <Trans>Export</Trans>
              </ButtonShowModal>
            </HasPermission>
          )}
          <ButtonPrint id="quicklist-print" />
          <ButtonShowModal
            name="settings"
            iconType="icon-cog"
            autoShow={!isObservationPointSelected}
            key={String(isObservationPointSelected)}
            shortcut="GOTO_SETTINGS"
            modalContent={({ hideModal }) => (
              <QuickListSettings hideModal={hideModal} />
            )}
          >
            <Trans>Settings</Trans>
          </ButtonShowModal>
        </ActionBlock>
      </div>

      <ReportTable
        columns={columns}
        records={readingsAndGaps}
        pagination={props.pagination}
        isLoading={props.isLoading}
        errorMessage={derivedErrorMessage}
      />
    </PageStandard>
  );
}
