import moment from 'moment-timezone';
import { extent } from 'd3-array';
import { createSelector, createStructuredSelector } from 'reselect';
import { formatDatetimeForStorage, getCurrentDatetime } from 'util/dates';
import { isNotNull, validateNumber } from 'util/validation';
import { RouteComponentProps } from 'react-router';
import {
  parseStringParamFromRouterProps,
  parseQueryParamFromRouterProps,
  parseNumberQueryParamFromRouterProps,
} from 'util/routing';
import {
  getTimeZoneFromPlotMetadata,
  splitObservationPointItemUrlCode,
} from 'components/plots/timeseriesplotselectors';
import {
  PlotSettingsAxis,
  PlotReadingsSeries,
  QuickPlotAxisSide,
  ObservationPointItemUrlCode,
  PlotSettings,
  buildReadingSeriesKey,
} from 'components/plots/timeseriesplot.types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';

type RS = Pick<
  PlotSettings,
  | 'storedPlot'
  | 'observationPoints'
  | 'duration'
  | 'start_datetime'
  | 'end_datetime'
> | null;
export const computePlotDates = createSelector(
  (resolvedSettings: RS = null) => resolvedSettings,
  (resolvedSettings: RS = null) =>
    getTimeZoneFromPlotMetadata(resolvedSettings) || undefined,
  function (
    settings,
    timeZone
  ): {
    minDatetime: string | null;
    maxDatetime: string | null;
    paddedMinDatetime: string | null;
    paddedMaxDatetime: string | null;
  } {
    if (!settings) {
      return {
        minDatetime: null,
        maxDatetime: null,
        paddedMinDatetime: null,
        paddedMaxDatetime: null,
      };
    }

    let calculatedMinDatetime: string | null = null;
    let calculatedMaxDatetime: string | null = null;
    let paddedMinDatetime: string | null = null;
    let paddedMaxDatetime: string | null = null;
    if (settings.duration) {
      const interval = moment.duration(settings.duration);
      if (settings.start_datetime) {
        // 1. Number of months & start date
        // Show X months after start date
        calculatedMinDatetime = settings.start_datetime;
        calculatedMaxDatetime = formatDatetimeForStorage(
          moment
            .tz(settings.start_datetime, timeZone!)
            .endOf('day')
            .add(interval)
        );
      } else if (settings.end_datetime) {
        // 2. Number of months & end date
        // Show X months before end date
        calculatedMinDatetime = formatDatetimeForStorage(
          moment
            .tz(settings.end_datetime, timeZone!)
            .startOf('day')
            .subtract(interval)
        );
        calculatedMaxDatetime = settings.end_datetime;
      } else {
        // 3. Number of months ONLY
        // Show X months before today's date
        const now = getCurrentDatetime();
        calculatedMinDatetime = formatDatetimeForStorage(
          moment.tz(now, timeZone!).startOf('day').subtract(interval)
        );
        calculatedMaxDatetime = formatDatetimeForStorage(
          moment.tz(now, timeZone!).endOf('day')
        );
      }

      // Apply padding to 'number of months' period
      const baseStartDatetime = new Date(
        settings.start_datetime || calculatedMinDatetime
      );
      const baseEndDatetime = new Date(
        settings.end_datetime || calculatedMaxDatetime
      );

      const paddingSize = calculatePlotDatePaddingSize(
        baseStartDatetime.valueOf(),
        baseEndDatetime.valueOf()
      );

      paddedMinDatetime =
        settings.start_datetime ||
        formatDatetimeForStorage(
          new Date(baseStartDatetime.valueOf() - paddingSize)
        );

      paddedMaxDatetime =
        settings.end_datetime ||
        formatDatetimeForStorage(
          new Date(baseEndDatetime.valueOf() + paddingSize)
        );
    } else {
      // 4. Start date and end date
      // Show from start date to end date.
      if (settings.start_datetime && settings.end_datetime) {
        calculatedMinDatetime = settings.start_datetime;
        calculatedMaxDatetime = settings.end_datetime;
      } else {
        // 5. Start date ONLY
        //  Show from start date, to latest reading available
        // 6. End date ONLY
        //  Show from earliest reading available, to end date
        // 7. NO start date, NO end date, NO "number of months"
        //  Show from earliest reading to latest reading
        //
        // The quickest way to get those earliest/latest reading datetimes,
        // is from the cached "earliest_reading"/"latest_reading" fields on
        // Model.ObservationPoint. (This is faster than waiting for the plotting
        // endpoint's response, so it lets us draw the plot at the right scale
        // earlier.)
        const [earliestReadingDatetime, latestReadingDatetime] = extent(
          settings.observationPoints.flatMap((op) =>
            [
              op.earliest_reading && op.earliest_reading.reading_datetime,
              op.latest_reading && op.latest_reading.reading_datetime,
            ].filter(isNotNull)
          ),
          (reading_datetime) => new Date(reading_datetime)
        );

        const baseStartDatetime: Date | undefined = settings.start_datetime
          ? new Date(settings.start_datetime)
          : earliestReadingDatetime;

        const baseEndDatetime = settings.end_datetime
          ? new Date(settings.end_datetime)
          : latestReadingDatetime;

        if (!baseStartDatetime || !baseEndDatetime) {
          calculatedMinDatetime = null;
          calculatedMaxDatetime = null;
        } else {
          calculatedMinDatetime = formatDatetimeForStorage(baseStartDatetime);
          calculatedMaxDatetime = formatDatetimeForStorage(baseEndDatetime);

          // When we use an earliest / latest reading as a start or end of the
          // plot, we add a percentage of the plot width, as padding.
          const paddingSize = calculatePlotDatePaddingSize(
            baseStartDatetime.valueOf(),
            baseEndDatetime.valueOf()
          );

          paddedMinDatetime =
            settings.start_datetime ||
            formatDatetimeForStorage(
              new Date(baseStartDatetime.valueOf() - paddingSize)
            );

          paddedMaxDatetime =
            settings.end_datetime ||
            formatDatetimeForStorage(
              new Date(baseEndDatetime.valueOf() + paddingSize)
            );
        }
      }
    }

    // calculated padding
    if (calculatedMinDatetime === null || calculatedMaxDatetime === null) {
      return {
        maxDatetime: null,
        minDatetime: null,
        paddedMaxDatetime: null,
        paddedMinDatetime: null,
      };
    }

    return {
      minDatetime: calculatedMinDatetime,
      maxDatetime: calculatedMaxDatetime,
      paddedMaxDatetime: paddedMaxDatetime || calculatedMaxDatetime,
      paddedMinDatetime: paddedMinDatetime || calculatedMinDatetime,
    };
  }
);

export function calculatePlotDatePaddingSize(
  minDatetime: number | undefined,
  maxDatetime: number | undefined
) {
  if (!minDatetime || !maxDatetime) {
    return 0;
  }

  return ((maxDatetime - minDatetime) * 0.05) / 2;
}

type PlotAxisLetter = 'L' | 'R' | 'S' | 'B';
export function getUrlSide(side: null | Enum.PlotAxisSide): PlotAxisLetter {
  switch (side) {
    case 'right':
      return 'R';
    case 'right2':
      return 'S';
    case 'bottom':
      return 'B';
    default:
      return 'L';
  }
}

export function getPlotSide(
  sideLetter: null | PlotAxisLetter
): Enum.PlotAxisSide {
  switch (sideLetter) {
    case 'R':
      return 'right';
    case 'S':
      return 'right2';
    case 'B':
      return 'bottom';
    default:
      return 'left';
  }
}

/**
 * We use `~` & `_` as separators because they are safe for friendly URL
 *  * @see https://stackoverflow.com/questions/695438/safe-characters-for-friendly-url
 *
 * @example
 * "L~-100~100_R~0~500"
 */
export function encodeScalesConfigForUrl(
  axes: Array<Model.StoredPlotItemAxis | null>
): string | null {
  return (
    axes
      .filter(isNotNull)
      .map(
        (scale) => `${getUrlSide(scale.side)}~${scale.minimum}~${scale.maximum}`
      )
      .join('_') || null
  );
}

export function parseScalesConfigForPlot(
  scalesConfigString: string
): PlotSettingsAxis[] {
  return scalesConfigString
    .split('_')
    .map((config) => {
      const [side, min, max] = config.split('~');

      if (
        (side !== 'L' && side !== 'R' && side !== 'S' && side !== 'B') ||
        !validateNumber(min) ||
        !validateNumber(max) ||
        Number(min) >= Number(max)
      ) {
        return null;
      }

      return {
        side: getPlotSide(side),
        minimum: min,
        maximum: max,
      };
    })
    .filter(isNotNull);
}

/**
 * Take the "last settings" that a quickplot should reset to if the reset
 * button is pressed, and serialize them into a string for the URL.
 *
 * The format is the same as for the scales config, except it's preceeded
 * by the startDatetime, endDatetime, and/or numberOfMonths separated by "_".
 * If any of these are null, they're represented by an empty string ''.
 *
 * @param startDatetime
 * @param endDatetime
 * @param axes
 */
export function encodeZoomBaseForUrl({
  startDatetime,
  endDatetime,
  numberOfMonths,
  axes,
}: {
  startDatetime: string | null;
  endDatetime: string | null;
  numberOfMonths: number | null;
  axes: Array<Model.StoredPlotItemAxis | null>;
}): string {
  const axesString = encodeScalesConfigForUrl(axes);
  return `${startDatetime ?? ''}_${endDatetime ?? ''}_${numberOfMonths || ''}_${
    axesString ?? ''
  }`;
}

/**
 * Deserialize the "last settings" from a quickplot URL. These are the settings
 * the plot should revert to when the "reset" button is pressed after zooming.
 * @param resetStateString
 */
export function parseZoomBaseForPlot(resetStateString: string): null | {
  startDatetime: string | null;
  endDatetime: string | null;
  numberOfMonths: number | null;
  axes: PlotSettingsAxis<QuickPlotAxisSide>[];
} {
  if (!resetStateString) {
    return null;
  }
  const parts = resetStateString.match(/^([^_]*)_([^_]*)_([^_]*)_(.*)$/);
  if (parts === null || parts.length !== 5) {
    return null;
  }

  const [, startDatetime, endDatetime, numberOfMonths, axesString] = parts;
  return {
    startDatetime: startDatetime === '' ? null : startDatetime,
    endDatetime: endDatetime === '' ? null : endDatetime,
    numberOfMonths: numberOfMonths === '' ? null : Number(numberOfMonths),
    axes:
      axesString === ''
        ? []
        : (parseScalesConfigForPlot(
            axesString
          ) as PlotSettingsAxis<QuickPlotAxisSide>[]),
  };
}

/**
 * TODO: Might make sense to change the return type of this so it matches
 * up better with TimeSeriesPlotSettings
 */
export const getQuickPlotSettingsFromUrl = createStructuredSelector<
  RouteComponentProps,
  {
    pairs: ObservationPointItemUrlCode[] | null;
    startDatetime: string | null;
    endDatetime: string | null;
    yAxisSides: string;
    axes: PlotSettingsAxis<QuickPlotAxisSide>[];
    showAnalysisComments: boolean;
    showInspectorComments: boolean;
    showMedia: boolean;
    numberOfMonths: number | null;
    zoomedFrom: null | {
      startDatetime: string | null;
      endDatetime: string | null;
      numberOfMonths: number | null;
      axes: PlotSettingsAxis<QuickPlotAxisSide>[];
    };
  }
>({
  pairs: createSelector(
    (props: RouteComponentProps) =>
      parseStringParamFromRouterProps(props, 'obsPointCode', ''),
    function (codesStr) {
      if (!codesStr) {
        return null;
      }

      const pairs = codesStr
        .split(',')
        .map(splitObservationPointItemUrlCode)
        .filter(isNotNull);
      if (pairs.length) {
        return pairs;
      } else {
        return null;
      }
    }
  ),
  startDatetime: (props) =>
    parseQueryParamFromRouterProps(props, 'startDatetime', null),
  endDatetime: (props) =>
    parseQueryParamFromRouterProps(props, 'endDatetime', null),
  yAxisSides: (props) => parseQueryParamFromRouterProps(props, 'yAxis', ''),
  axes: createSelector(
    (props: RouteComponentProps) =>
      parseQueryParamFromRouterProps(props, 'axes', ''),
    function (axes) {
      return parseScalesConfigForPlot(
        axes
      ) as PlotSettingsAxis<QuickPlotAxisSide>[];
    }
  ),
  numberOfMonths: (props) =>
    parseNumberQueryParamFromRouterProps(props, 'numberOfMonths', null),
  showAnalysisComments: (props) =>
    Boolean(
      parseNumberQueryParamFromRouterProps(props, 'showAnalysisComments', 0)
    ),
  showInspectorComments: (props) =>
    Boolean(
      parseNumberQueryParamFromRouterProps(props, 'showInspectorComments', 0)
    ),
  showMedia: (props) =>
    Boolean(parseNumberQueryParamFromRouterProps(props, 'showMedia', 0)),
  zoomedFrom: (props) =>
    parseZoomBaseForPlot(parseQueryParamFromRouterProps(props, 'zoomedFrom')),
});

export function extractPairsFromReadingsSeries(
  series: PlotReadingsSeries[]
): ObservationPointItemUrlCode[] {
  return series.map((s) => ({
    observationPointCode: s.observationPoint.code,
    itemNumber: s.settings.item_number,
  }));
}

export function selectResolvedSettings(state: FullState, plotKey: string) {
  const plotConf = state.plot.scatterTimeSeries.plots[plotKey];

  const resolvedSettings: PlotSettings | null = plotConf?.resolved ?? null;

  const readingsData =
    resolvedSettings?.reading_series?.map(
      (rs) =>
        state.plot.scatterTimeSeries.readingsSeries[
          buildReadingSeriesKey(plotKey, rs.observation_point, rs.item_number)
        ]
    ) ?? [];

  // Calculate the actual start and end date of data to plot (based on
  // start date, end date, and/or "number of months" settings, or based on
  // current stored plot zoom)
  const zoomParams = plotConf?.zoom ?? null;
  const { minDatetime, maxDatetime, paddedMinDatetime, paddedMaxDatetime } =
    zoomParams
      ? {
          ...zoomParams,
          paddedMinDatetime: zoomParams.minDatetime,
          paddedMaxDatetime: zoomParams.maxDatetime,
        }
      : computePlotDates(resolvedSettings);

  return {
    resolvedSettings,
    zoomParams,
    readingsData,
    minDatetime,
    maxDatetime,
    paddedMinDatetime,
    paddedMaxDatetime,
    errorMessage: plotConf?.errorMessage ?? '',
  };
}
