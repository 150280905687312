import 'main/polyfills';
import 'main/sentry.tsx';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactModal from 'react-modal';
import './index.scss';
import App from 'main/App';

const appElement =
  document.getElementById('root') || document.createElement('div');

// See http://reactcommunity.org/react-modal/accessibility/#app-element
ReactModal.setAppElement(appElement);

ReactDOM.render(<App />, appElement);

// TODO: CRA's cacheing service worker is incompatible with our /admin/
// and /api/ paths. We'd need to eject and reconfigure it.
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();
