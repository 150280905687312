import React from 'react';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi } from 'util/backendapi/fetch';
import {
  makeIdCodeMenuOptions,
  SingleMenuProps,
  MultiMenuProps,
} from './utils';

export function SiteSingleMenu(props: SingleMenuProps) {
  const { name, ...otherProps } = props;
  return (
    <AsyncSimpleSelectField<number, false>
      name={name}
      {...otherProps}
      isMulti={false}
      onSearch={async (searchText: string) =>
        makeIdCodeMenuOptions(
          await getApi('/sites-list/', {
            code__icontains: searchText,
          })
        )
      }
      loadDefaults={async (value: number) =>
        makeIdCodeMenuOptions(
          await getApi('/sites-list/', {
            id__in: [value],
          })
        )
      }
    />
  );
}

export function SiteMultiMenu(props: MultiMenuProps) {
  const { name, ...otherProps } = props;
  return (
    <AsyncSimpleSelectField<number, true>
      name={name}
      {...otherProps}
      isMulti
      onSearch={async (searchText: string) =>
        makeIdCodeMenuOptions(
          await getApi('/sites-list/', {
            code__icontains: searchText,
          })
        )
      }
      loadDefaults={async (values: number[]) =>
        makeIdCodeMenuOptions(
          await getApi('/sites-list/', {
            id__in: values,
          })
        )
      }
    />
  );
}
