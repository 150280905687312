import React, { useState, useEffect, CSSProperties, useContext } from 'react';
import ReactDOM from 'react-dom';
import { PageStandardContext } from 'components/modules/pagestandard/pagestandard';
import './Popup.scss';
import useResizeObserver from 'hooks/use-resize-observer';
import { getReactVisScales } from '../react-vis-hacks';

interface Props {
  children: React.ReactNode;
  style?: CSSProperties;
  point: any;
  // These and additional props automagically passed through from React-Vis
  marginTop?: number;
  marginLeft?: number;
}

export const Popup: React.FunctionComponent<Props> = (props) => {
  const { point, marginTop, marginLeft } = props;

  const [plotLocationDiv, setPlotLocationDiv] = useState<null | HTMLDivElement>(
    null
  );

  const [left, setLeft] = useState<number>(-1000);
  const [top, setTop] = useState<number>(-1000);

  // Listen for resize events on the plot container. This lets us ensure that
  // the popup's document-level absolute positioning can be updated to match
  // its data point's position in the plot.
  const [plotRef, plotAreaWidth] = useResizeObserver();
  useEffect(() => {
    if (plotLocationDiv) {
      plotRef(plotLocationDiv.parentElement);
    }
  }, [plotLocationDiv, plotRef]);

  // Also listen for resize events on the popup's content, in case it initially
  // displays in a "Loading" state and then expands once additional data loads.
  const [setPopupContentRef, popupWidth, popupHeight] = useResizeObserver();

  const { popupRef: popupPortalRef } = useContext(PageStandardContext);

  // Calculate the (pixel) location of the target data point, within the
  // plot.
  const { scaleX, scaleY } = getReactVisScales(props);
  const pointX = scaleX(point) + marginLeft!;
  const pointY = scaleY(point) + marginTop!;

  // Calculate how to "position: absolute" the popup on the page so it'll be
  // aligned near its data point.
  useEffect(() => {
    if (!popupWidth || !popupHeight) {
      setLeft(-1000);
      setTop(-1000);
      return;
    }

    const plotBounds = plotLocationDiv?.parentElement?.getBoundingClientRect();
    if (!plotBounds) {
      setLeft(-1000);
      setTop(-1000);
      return;
    }

    // Calculate where to position the popup (within the plot) so that it's
    // inside the plot (if possible), and near but not covering the data point.
    let popupLeftInPlot = pointX - popupWidth / 2;
    if (popupLeftInPlot + popupWidth > plotAreaWidth) {
      popupLeftInPlot = plotAreaWidth - popupWidth;
    } else {
      popupLeftInPlot = Math.max(popupLeftInPlot, 0);
    }

    // we don't want the popup to cover the data point it's targeting
    let popupTopInPlot = pointY - popupHeight - 20;
    if (popupTopInPlot < 0) {
      popupTopInPlot = pointY + 10;
    }

    // The `popupTop` and `popupLeft` positions are relative to the plot container.
    // However, since the plot height can be small due to the dynamic sizing,
    // the absolute positioned popup can be partly hidden due to `overflow: hidden`
    //
    // The solution is to use React.Portal to tether the popup content into a
    // different container that matches the size of the whole document, and
    // use absolute positioning to get it located over the plot.
    //
    // This document-level absolute positioning can be calculated by adding
    // the document-level position of the plot container to `popupTop` and
    // `popupLeft`
    const { top: plotTopInViewport, left: plotLeftInViewport } = plotBounds;

    setLeft(popupLeftInPlot + plotLeftInViewport + window.pageXOffset);
    setTop(popupTopInPlot + plotTopInViewport + window.pageYOffset);
  }, [
    plotLocationDiv,
    plotAreaWidth,
    popupWidth,
    popupHeight,
    pointX,
    pointY,
    // Re-calculate when this ref object changes, because that's a signal
    // PageStandardContext uses to indicate that the nav bar collapse/expand
    // CSS transition is finished.
    popupPortalRef,
  ]);

  return (
    <div ref={setPlotLocationDiv}>
      {popupPortalRef?.current &&
        ReactDOM.createPortal(
          <div
            className="plot-popup-wrapper"
            ref={setPopupContentRef}
            style={{
              ...(props.style || {}),
              top: `${top}px`,
              left: `${left}px`,
            }}
          >
            {props.children}
          </div>,
          popupPortalRef.current
        )}
    </div>
  );
};
