import React, { useMemo } from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportFilter,
} from 'components/modules/report/report-types';
import { Model, Filter } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { DMSLink } from 'components/base/link/DMSLink';
import {
  formatDatetimeForDisplay,
  START_OF_DAY,
  END_OF_DAY,
  formatDatetimeForBackendUrl,
  convertDateToDatetime,
} from 'util/dates';
import { Icon } from 'components/base/icon/icon';
import { useReportState, ReportStateProps } from 'hooks/use-report-state';
import { RouteComponentProps } from 'react-router';
import { observationPointFormulaDuck } from 'ducks/observation-point-formula/list';
import { FullState } from 'main/reducers';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { reportFilterActiveAt } from 'components/modules/report/filter/fields/reportFilterActiveAt';
import {
  NO_TIMEZONE,
  reportFilterDatetime,
  DateFilterType,
} from 'components/modules/report/filter/fields/FilterDate';
import { parseQueryParamFromRouterProps } from 'util/routing';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { reportFilterSites } from 'components/modules/report/filter/fields/reportFilterSites';
import { LinkIfHas } from 'components/logic/link-if-has-permission/LinkIfHas';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { deleteApi } from 'util/backendapi/fetch';

export const opfColumns = (reportProps?: {
  refreshList?: () => void;
}): ReportColumn<Model.ReportsObservationPointFormula>[] => [
  {
    label: <Trans>Observation point</Trans>,
    name: 'observation-point',
    backendFieldName: 'observation_point__code',
    visibility: DEFAULT_SHOW,
    accessor: ({ observation_point__code: code }) => (
      <DMSLink
        to={{
          pathname: `/observation-point/${code}`,
          hash: 'card-formula-header',
        }}
      >
        {code}
      </DMSLink>
    ),
  },
  {
    label: <Trans>Formula</Trans>,
    name: 'formula',
    backendFieldName: 'formula__code',
    additionalFields: ['formula__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({ formula__code: code, formula__name: name }) =>
      `${code} - ${name}`,
  },
  {
    label: <Trans>Area</Trans>,
    name: 'area',
    backendFieldName: 'observation_point__site__area__code',
    additionalFields: ['observation_point__site__area__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      observation_point__site__area__code: code,
      observation_point__site__area__name: name,
    }) => `${code} - ${name}`,
  },
  {
    label: <Trans>Instrument type</Trans>,
    name: 'instrument_type',
    backendFieldName: 'observation_point__instrument_type__code',
    additionalFields: ['observation_point__instrument_type__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      observation_point__instrument_type__code: code,
      observation_point__instrument_type__name: name,
    }) => `${code} - ${name}`,
  },
  {
    label: <Trans>Start date and time</Trans>,
    name: 'start_datetime',
    backendFieldName: 'start_datetime',
    additionalFields: ['observation_point__site__area__time_zone__name'],
    hideFieldsFromCSV: ['observation_point__site__area__time_zone__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      start_datetime,
      observation_point__site__area__time_zone__name: timeZone,
    }) => formatDatetimeForDisplay(start_datetime, timeZone),
  },
  {
    label: <Trans>Changed by</Trans>,
    name: 'changed-by',
    backendFieldName: 'history__actor__profile__name',
    additionalFields: ['history__actor__username', 'history__actor'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      history__actor__profile__name: name,
      history__actor__username: username,
      history__actor: id,
    }) =>
      id && (
        <LinkIfHas
          permission="can_view_other_user_profiles"
          to={`/user/${id}`}
        >{`${name} (${username})`}</LinkIfHas>
      ),
  },
  {
    // The date this record was created/edited in the system.
    label: <Trans>Changed date and time</Trans>,
    name: 'changed-datetime',
    backendFieldName: 'history__timestamp',
    visibility: DEFAULT_SHOW,
    accessor: ({
      history__timestamp,
      // Displayed in user time because this date is *not* compared to reading datetimes
    }) => history__timestamp && formatDatetimeForDisplay(history__timestamp),
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['observation_point__code', 'formula__code', 'id'],
    accessor: ({ observation_point__code, formula__code, id }) => (
      <>
        <DMSLink
          to={{
            pathname: `/observation-point/${observation_point__code}`,
            hash: 'card-formula-header',
          }}
        >
          <Icon type="icon-view" title={t`View`} />
        </DMSLink>
        <HasPermission check="can_delete_observation_point_formulas">
          <ButtonShowConfirmation
            destructive={true}
            iconOnly={true}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            title={t`Delete`}
            name={`delete-observation-point-formula-instance-${id}`}
            content={
              <Trans>
                Are you sure you want to delete the{' '}
                <strong>{formula__code}</strong> formula from the{' '}
                <strong>{observation_point__code}</strong> observation point?
                This action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async () => {
              await deleteApi(`/observation-point-formulas/${id}/`);
              reportProps?.refreshList?.();
            }}
          />
        </HasPermission>
      </>
    ),
  },
];

export const opfFilters: ReportFilter[] = [
  reportFilterMenu(
    'formula',
    <Trans>Formula</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All formulas</Trans>, autoFocus: true }
  ),
  reportFilterObservationPoints('observation_point'),
  reportFilterMenu(
    'observation_point__site__area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
    }
  ),
  {
    ...reportFilterActiveAt(
      'active_at_datetime',
      <Trans>Date active</Trans>,
      NO_TIMEZONE
    ),
    // this is a special filter, when "Today" checkbox is checked,
    // we will send to the backend the param `active_now = true`
    //
    // and this is a Dam time filter, so we'll use `formatDatetimeForBackendUrl`
    // to format the date instead of `formatDatetimeForStorage`
    getBackendFilterFromUrl: (routeProps) => {
      const nowQueryParam = parseQueryParamFromRouterProps(routeProps, 'now');
      const now = nowQueryParam === '' ? true : nowQueryParam === 'true';

      if (now) {
        return {
          active_now: true,
        };
      }

      const date = parseQueryParamFromRouterProps(
        routeProps,
        'active_at_datetime'
      );
      const type = parseQueryParamFromRouterProps(
        routeProps,
        `active_at_datetime_type`,
        'is'
      ) as DateFilterType;

      if (date) {
        const typeFieldName: keyof Filter.ObservationPointFormulas =
          type === 'is_before'
            ? 'start_datetime__lte'
            : type === 'is_after'
            ? 'start_datetime__gte'
            : 'active_at_datetime';

        const timeOfDay = type === 'is_after' ? START_OF_DAY : END_OF_DAY;

        const isoDatetime = formatDatetimeForBackendUrl(
          convertDateToDatetime(date, null, timeOfDay)
        );

        return {
          [typeFieldName]: isoDatetime,
        };
      }
      return null;
    },
  },
  reportFilterSites('observation_point__site'),
  reportFilterMenu(
    'observation_point__instrument_type',
    <Trans>Instrument type</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All instrument types</Trans> }
  ),
  reportFilterDatetime(
    'start_datetime',
    <Trans>Start date</Trans>,
    // Dam time for each row
    NO_TIMEZONE,
    [
      // "is_blank" not supported, because start datetime is a non-null field
      'is',
      'is_after',
      'is_before',
      'is_between',
      'is_within_last',
      'is_within_next',
    ]
  ),
  reportFilterUsers('history__actor', <Trans>Changed by</Trans>, {
    placeholder: <Trans>All DMS users</Trans>,
  }),
  reportFilterDatetime(
    'history__timestamp',
    <Trans>Changed date</Trans>,
    // user time
    null,
    [
      // no "is_blank" option, because this is not a nullable field
      'is',
      'is_after',
      'is_before',
      'is_between',
      'is_within_last',
      'is_within_next',
    ]
  ),
];

export function ObservationPointFormulaListScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    opfColumns(),
    opfFilters,
    observationPointFormulaDuck,
    (state: FullState) => state.obsPointFormula
  );
  return <ObservationPointFormulaListView reportProps={reportState} />;
}

export function ObservationPointFormulaListView(props: {
  reportProps: ReportStateProps<Model.ReportsObservationPointFormula>;
}) {
  const { reportProps } = props;
  const columns = useMemo(
    () => opfColumns({ refreshList: props.reportProps.refreshList }),
    [props.reportProps.refreshList]
  );
  return (
    <PageStandard
      name="observation-point-formula-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Formula assignment report</Trans>
        ) : (
          <Trans>Formula Assignments</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <ReportFiltersBlock
          filtersFrontend={opfFilters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
          filtersToShow={[
            'formula',
            'observation_point',
            'observation_point__site__area',
            'active_at_datetime',
          ]}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={opfFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Formula assignment report`}
            reportUrl="/reports/observation-point-formulas/"
            columns={columns}
            filters={opfFilters}
          />
          <AdvancedFiltersModalButton
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={opfFilters}
            sections={[
              {
                name: 'menu_section',
                filters: [
                  'observation_point__site__area',
                  'observation_point__site',
                  'observation_point',
                  'observation_point__instrument_type',
                  'formula',
                ],
              },
              {
                name: 'dates_section',
                filters: ['start_datetime'],
              },
              {
                name: 'author_section',
                filters: ['history__actor', 'history__timestamp'],
              },
            ]}
          />
        </ActionBlock>

        <ReportTable<Model.ReportsObservationPointFormula>
          columns={columns}
          filters={opfFilters}
          {...reportProps}
        />
      </div>
    </PageStandard>
  );
}
