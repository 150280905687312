import { Enum, Model } from './backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import { isTruthy } from './validation';

/**
 * The logic to decide how to display a user's name.
 *
 * Preferred name if they have it, otherwise fallback to username.
 *
 * @param user
 */
export function getUserDisplayName(user: Model.SimpleUser) {
  return [user.profile.name, `(${user.username})`].filter(isTruthy).join(' ');
}

/**
 * A Redux selector to determine whether the logged-in user has the specified
 * permission.
 *
 * @param state
 * @param permission
 */
export function selectHasPermission(
  state: FullState,
  permission: Enum.User_PERMISSION
) {
  return state.user.permissions.includes(permission);
}
