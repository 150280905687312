import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { getPaginated, getApi } from 'util/backendapi/fetch';

export const retirementRequestDuck = makeReportingDuck(
  'retirementRequest',
  (filters) => getPaginated('/reports/reading-retirement-requests/', filters),
  () => getApi('/reports/reading-retirement-requests/info/')
);

export type RetirementRequestAction = ReportingDuckActions<
  typeof retirementRequestDuck
>;
