import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import {
  ReportFilterMenu,
  reportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { getApi, deleteApi } from 'util/backendapi/fetch';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { RouteComponentProps } from 'react-router';
import { checksheetInstanceListDuck } from 'ducks/checksheet-instance/list';
import { FullState } from 'main/reducers';
import { useReportState, ReportStateProps } from 'hooks/use-report-state';
import { formatDatetimeForDisplay } from 'util/dates';
import { ChecksheetInstance_STATUS } from 'util/backendapi/types/Enum';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { Icon } from 'components/base/icon/icon';
import { DMSLink } from 'components/base/link/DMSLink';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { getUserDisplayName } from 'util/user';

export const checksheetInstanceListColumns: (extraProps?: {
  refreshList: () => void;
}) => ReportColumn<Model.ReportsChecksheetInstance>[] = (extraProps) => [
  {
    label: <Trans>Name</Trans>,
    name: 'name',
    backendFieldName: 'template__name',
    additionalFields: ['id'],
    visibility: DEFAULT_SHOW,
    accessor: ({ template__name, id }) => (
      <HasPermission
        check="can_view_checksheet_instances"
        noPermissionMsg={template__name}
      >
        <DMSLink to={`/checksheet-instances/${id}`}>{template__name}</DMSLink>
      </HasPermission>
    ),
  },
  {
    label: <Trans>Area</Trans>,
    name: 'area',
    backendFieldName: 'template__areas__code',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Status</Trans>,
    name: 'status',
    backendFieldName: 'status',
    visibility: DEFAULT_SHOW,
    accessor: ({ status }) => (
      <TransEnum enum="ChecksheetInstance_STATUS" value={status} />
    ),
  },
  {
    label: <Trans>Date</Trans>,
    name: 'created_datetime',
    backendFieldName: 'created_datetime',
    visibility: DEFAULT_SHOW,
    accessor: ({ created_datetime }) =>
      formatDatetimeForDisplay(created_datetime),
  },
  {
    label: <Trans>Started</Trans>,
    name: 'created_by',
    backendFieldName: 'created_by',
    visibility: DEFAULT_SHOW,
    accessor: ({ created_by }) => getUserDisplayName(created_by),
  },
  {
    label: <Trans>Analyst</Trans>,
    name: 'analyst',
    backendFieldName: 'analyst',
    visibility: DEFAULT_SHOW,
    accessor: ({ analyst }) => analyst && getUserDisplayName(analyst),
  },
  {
    label: <Trans>Reviewer</Trans>,
    name: 'reviewed_by',
    backendFieldName: 'reviewed_by',
    visibility: DEFAULT_SHOW,
    accessor: ({ reviewed_by }) =>
      reviewed_by && getUserDisplayName(reviewed_by),
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id', 'template__name', 'created_datetime'],
    accessor: ({ id, template__name, created_datetime }) => (
      <>
        <HasPermission check="can_view_checksheet_instances">
          <DMSLink to={`/checksheet-instances/${id}`}>
            <Icon type="icon-view" title={t`View`} />
          </DMSLink>
        </HasPermission>
        <HasPermission check="can_delete_checksheet_instances">
          <ButtonShowConfirmation
            destructive={true}
            iconOnly={true}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            title={t`Delete`}
            name={`delete-checksheet-instance-${id}`}
            content={
              <Trans>
                Are you sure you want to delete the instance of the{' '}
                {template__name} checksheet started on{' '}
                {formatDatetimeForDisplay(created_datetime)}? This action is not
                reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async () => {
              await deleteApi(`/checksheet-instances/${id}/`);
              extraProps?.refreshList();
            }}
          />
        </HasPermission>
      </>
    ),
  },
];

function _makeNameMenuItem(
  checksheetTemplate: Model.ReportsChecksheetTemplate
): SimpleSelectOption<number> {
  return {
    value: checksheetTemplate.id,
    label: checksheetTemplate.name,
  };
}

function _makeAreaCodeMenuItem(area: any): SimpleSelectOption<string> {
  return {
    value: area.code,
    label: `${area.code} - ${area.name}`,
  };
}

export const checksheetInstanceListFilters: ReportFilter[] = [
  reportFilterAsyncMenu(
    'template',
    <Trans>Name</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (searchText) =>
        getApi('/reports/checksheet-templates/', {
          name__icontains: searchText,
          columns: ['id', 'name'],
        }).then((checksheetTemplates) =>
          checksheetTemplates.map(_makeNameMenuItem)
        ),
      loadDefaults: (template_id: number) =>
        getApi('/reports/checksheet-templates/', {
          id__in: [template_id],
          columns: ['id', 'name'],
        }).then((checksheetTemplates) =>
          checksheetTemplates.map(_makeNameMenuItem)
        ),
    },
    {
      placeholder: <Trans>All checksheet instances</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterMenu(
    'template__areas__code',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'string' },
    _makeAreaCodeMenuItem,
    { placeholder: <Trans>All areas</Trans> }
  ),
  reportFilterMenu(
    'status',
    <Trans>Status</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('ChecksheetInstance_STATUS'),
    {
      placeholder: <Trans>All statuses</Trans>,
      options: Object.values(ChecksheetInstance_STATUS),
    }
  ),
];

export function ChecksheetInstanceListScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    checksheetInstanceListColumns(),
    checksheetInstanceListFilters,
    checksheetInstanceListDuck,
    (state: FullState) => state.checksheetInstance.list
  );

  return <ChecksheetInstanceListView reportProps={reportState} />;
}

export function ChecksheetInstanceListView(props: {
  reportProps: ReportStateProps<Model.ReportsChecksheetInstance>;
}) {
  const { reportProps } = props;
  const columns = checksheetInstanceListColumns(reportProps);
  const filters = checksheetInstanceListFilters;
  return (
    <PageStandard
      name="checksheet-instances-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Checksheet instances report</Trans>
        ) : (
          <Trans>Maintain Checksheet Instances</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <ReportFiltersBlock
          filtersFrontend={filters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Checksheet instances report`}
            reportUrl="/reports/checksheet-instances/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>

        <ReportTable<Model.ReportsChecksheetInstance>
          columns={columns}
          filters={filters}
          {...reportProps}
        />
      </div>
    </PageStandard>
  );
}
