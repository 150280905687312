import React from 'react';
import ModalContent from '../../components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import {
  Formik,
  Field,
  FormikProps,
  FormikHelpers,
  Form,
  ErrorMessage,
} from 'formik';
import ErrorNotice from '../../components/base/form/errornotice/errornotice';
import { SimpleSelectField } from '../../components/base/form/simpleselect/simpleselectfield';
import ActionBlock from '../../components/base/actionblock/actionblock';
import ButtonHideModal from '../../components/base/modal/buttonhidemodal';
import { ButtonPrimary } from '../../components/base/button/button';
import { AreaOption } from './routemarchpanel';
import { RouteComponentProps } from 'react-router';
import { Model, Enum } from '../../util/backendapi/models/api.interfaces';
import { AlertDanger } from '../../components/base/alert/alert';
import {
  validateIsFilled,
  validateIsNotGreaterThanMaxLength,
} from '../../util/validation';
import { RouteMarchTableState } from '../../ducks/routemarch';
import { IntervalField } from '../../components/base/form/interval/intervalfield';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { UppercaseTextField } from 'components/base/form/uppercase-text-field/UppercaseTextField';
import { getExpectedFields } from 'util/backendapi/error';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import { useGetApi } from 'hooks/use-get-api';
import Loading from 'components/base/loading/loading';

export interface FormikValues {
  code: string;
  type: Enum.RouteMarch_TYPE;
  areas: number[];
  description: string;
  menu_label: string;
  id: number;
  reading_frequency: string | null;
  reading_frequency_tolerance: string | null;
  status: Enum.RouteMarch_STATUS;
  stored_spatial_plan_plot: number | null;
}

export type StateProps = RouteMarchTableState & {
  areaOptions: null | AreaOption[];
};

export type DispatchProps = {
  onSubmit: (values: FormikValues) => void;
  onAfterSubmit: (routeMarchObservationPointCode: string) => void;
};

export type OwnProps = RouteComponentProps<any> & {
  routeMarch: Model.RouteMarch | null;
  hideModal: () => void;
};

export type Props = StateProps & DispatchProps & OwnProps;

export function RouteMarchPanelView(props: Props) {
  const validate = (values: FormikValues) => {
    const requiredFields = ['code', 'description', 'menu_label'];
    let errors: any = {};
    Object.entries(values).forEach((field: any) => {
      const fieldName = field[0];
      if (
        requiredFields.indexOf(fieldName) !== -1 &&
        !validateIsFilled(values, fieldName, '')
      ) {
        errors[fieldName] = <Trans>{fieldName} is required</Trans>;
      }
    });

    if (values.areas.length === 0) {
      errors.areas = <Trans>Area is required</Trans>;
    }

    if (!validateIsNotGreaterThanMaxLength(values, 'menu_label', 12)) {
      errors.menu_label = (
        <Trans>Menu label must be 12 characters or less.</Trans>
      );
    }

    if (values.reading_frequency && !values.reading_frequency_tolerance) {
      errors.reading_frequency_tolerance = (
        <Trans>
          Frequency tolerance is required, when reading frequency has been
          supplied.
        </Trans>
      ) as any as string;
    }

    if (values.reading_frequency_tolerance && !values.reading_frequency) {
      errors.reading_frequency = (
        <Trans>
          Reading frequency is required, when frequency tolerance has been
          supplied.
        </Trans>
      ) as any as string;
    }

    return errors;
  };

  const submit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    actions.setSubmitting(true);
    try {
      await props.onSubmit(values);
      actions.setSubmitting(false);
      props.hideModal();
      if (!values.id) {
        props.onAfterSubmit(values.code);
      }
    } catch (errors) {
      actions.setSubmitting(false);
      showErrorsInFormik(actions, errors, getExpectedFields(values));
    }
  };

  const headerTitle = props.routeMarch ? (
    <Trans>Edit route march</Trans>
  ) : (
    <Trans>Create a route march</Trans>
  );
  const initialValue = props.routeMarch
    ? props.routeMarch
    : {
        code: '',
        type: Enum.RouteMarch_TYPE.general,
        areas: [],
        description: '',
        menu_label: '',
        id: 0,
        reading_frequency: null,
        reading_frequency_tolerance: null,
        status: Enum.RouteMarch_STATUS.active,
        stored_spatial_plan_plot: null,
      };

  const areaOptions = props.areaOptions || [];

  const [spatialPlanPlots] = useGetApi('/spatial-stored-plots/', {
    plot_type__in: [Enum.PlotType.SPATIAL_PLAN],
    fields: ['id', 'name'],
  });
  const spatialPlanOptions =
    spatialPlanPlots?.data?.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? [];

  const typeOptions = menuItemsFromEnum(
    'RouteMarch_TYPE',
    Object.values(Enum.RouteMarch_TYPE)
  );

  const statusOptions = menuItemsFromEnum(
    'RouteMarch_STATUS',
    Object.values(Enum.RouteMarch_STATUS)
  );

  if (spatialPlanPlots.isLoading) {
    return <Loading />;
  }

  return (
    <ModalContent header={headerTitle}>
      <Formik
        initialValues={initialValue}
        onSubmit={submit}
        validate={validate}
      >
        {(formik: FormikProps<FormikValues>) => (
          <Form>
            {formik.status ? <AlertDanger>{formik.status}</AlertDanger> : null}
            <fieldset>
              <div className="form-group">
                <label htmlFor="routemarch-code">
                  <Trans>Route march</Trans>
                </label>
                <UppercaseTextField
                  name="code"
                  id="routemarch-code"
                  autoFocus={true}
                />
                <ErrorMessage name="code" component={ErrorNotice} />
              </div>
              <div className="form-group">
                <label htmlFor="routemarch-areas">
                  <Trans>Area</Trans>
                </label>
                <SimpleSelectField
                  name="areas"
                  id="routemarch-areas"
                  placeholder=" Select an area..."
                  isMulti={true}
                  options={areaOptions}
                />
                <ErrorMessage name="areas" component={ErrorNotice} />
              </div>
              <div className="form-group">
                <label htmlFor="routemarch-description">
                  <Trans>Description</Trans>
                </label>
                <Field
                  name="description"
                  type="text"
                  id="routemarch-description"
                />
                <ErrorMessage name="description" component={ErrorNotice} />
              </div>
              <div className="form-group">
                <label htmlFor="routemarch-menu-label">
                  <Trans>Menu label</Trans>
                </label>
                <Field
                  name="menu_label"
                  type="text"
                  id="routemarch-menu-label"
                />
                <ErrorMessage name="menu_label" component={ErrorNotice} />
              </div>
              <div className="form-group">
                <label htmlFor="routemarch-stored-spatial-plan-plot">
                  <Trans>Spatial plan (for mobile app map)</Trans>
                </label>
                <SimpleSelectField
                  name="stored_spatial_plan_plot"
                  id="routemarch-stored-spatial-plan-plot"
                  placeholder=" Select a spatial plan..."
                  isMulti={false}
                  isClearable={true}
                  options={spatialPlanOptions}
                />
                <ErrorMessage
                  name="stored_spatial_plan_plot"
                  component={ErrorNotice}
                />
              </div>
              <div
                className="form-group form-group-interval-select"
                id="readingFrequency-input"
              >
                <label htmlFor="routemarch-reading-frequency">
                  <Trans>Reading frequency</Trans>
                </label>
                <div className="form-group-content">
                  <IntervalField name="reading_frequency" />
                  <ErrorMessage
                    name="reading_frequency"
                    component={ErrorNotice}
                  />
                </div>
              </div>
              <div
                className="form-group form-group-interval-select"
                id="readingFrequencyTolerance-input"
              >
                <label htmlFor="routemarch-reading-frequency-tolerance">
                  <Trans>Frequency tolerance</Trans>
                </label>
                <div className="form-group-content">
                  <IntervalField name="reading_frequency_tolerance" />
                  <ErrorMessage
                    name="reading_frequency_tolerance"
                    component={ErrorNotice}
                  />
                </div>
              </div>
              <div
                className="form-group form-group-status-select"
                id="routeMarchType-input"
              >
                <label htmlFor="routemarch-type">
                  <Trans>Type</Trans>
                </label>
                <div className="form-group-content">
                  <SimpleSelectField name="type" options={typeOptions} />
                  <ErrorMessage name="type" component={ErrorNotice} />
                </div>
              </div>
              <div
                className="form-group form-group-status-select"
                id="status-input"
              >
                <label htmlFor="routemarch-status">
                  <Trans>Status</Trans>
                </label>
                <div className="form-group-content">
                  <SimpleSelectField name="status" options={statusOptions} />
                  <ErrorMessage name="status" component={ErrorNotice} />
                </div>
              </div>
            </fieldset>
            <ActionBlock>
              <ButtonHideModal />
              {props.routeMarch ? (
                <ButtonPrimary
                  id="routemarch-change"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  id="routemarch-create"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Create</Trans>
                </ButtonPrimary>
              )}
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
