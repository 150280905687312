import { getApi } from 'util/backendapi/fetch';
import { Filter, Model } from 'util/backendapi/models/api.interfaces';
import { ThunkDispatch } from 'redux-thunk';
import { errorToString } from 'util/backendapi/error';
import { DuckActions } from 'main/store';

export const ActionTypes = {
  FETCH_BATCHES_START: 'dms/dashboard/FETCH_BATCHES_START',
  FETCH_BATCHES_RESPONSE: 'dms/dashboard/FETCH_BATCHES_RESPONSE',
  FETCH_BATCHES_ERROR: 'dms/dashboard/FETCH_BATCHES_ERROR',
} as const;

export const ActionCreators = {
  FETCH_BATCHES_START: function () {
    return {
      type: ActionTypes.FETCH_BATCHES_START,
    };
  },
  FETCH_BATCHES_RESPONSE: function (data: Model.ListReadingsBatch[]) {
    return {
      type: ActionTypes.FETCH_BATCHES_RESPONSE,
      data: data,
    };
  },
  FETCH_BATCHES_ERROR: function (errorMessage: string) {
    return {
      type: ActionTypes.FETCH_BATCHES_ERROR,
      payload: errorMessage,
    };
  },
};

export type ListBatchesAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface State {
  isLoading: boolean;
  errorMessage: string;
  batches: null | Model.ListReadingsBatch[];
}

export const initialState = () => ({
  isLoading: false,
  errorMessage: '',
  batches: null,
});

export function dashboardReducer(
  state: State = initialState(),
  actions: ListBatchesAction
): State {
  switch (actions.type) {
    case ActionTypes.FETCH_BATCHES_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_BATCHES_RESPONSE:
      return {
        ...state,
        isLoading: false,
        batches: actions.data,
      };
    case ActionTypes.FETCH_BATCHES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: state.errorMessage,
      };
    default:
      return state;
  }
}

export function fetchBatches(filters: Filter.ReadingsBatch) {
  return async function (dispatch: ThunkDispatch<any, any, any>) {
    dispatch(ActionCreators.FETCH_BATCHES_START());

    try {
      let batches = await getApi('/readings-batches/', {
        ...filters,
        ordering: ['-created'],
      });
      dispatch(ActionCreators.FETCH_BATCHES_RESPONSE(batches));
    } catch (e) {
      return dispatch(ActionCreators.FETCH_BATCHES_ERROR(errorToString(e)));
    }
  };
}
