import React from 'react';
import { FastField } from 'formik';
import { Trans } from '@lingui/macro';
import { validateNumber } from 'util/validation';

/**
 * IntegerField: a Formik field component for an input that should only accept
 * a string representing an integer (or the empty string)
 *
 * NOTE: Although this field is for getting numeric input from the user, it
 * works on STRING values not NUMBER values (this greatly simplifies the
 * implementation). Forms that use `IntegerField` need to cast the incoming
 * values to a string e.g. when setting up Formik's initialValues, and if
 * needed, convert from a string in the form's onSubmit.
 */

export type IntegerFieldValue = string;

type Props = Merge<
  React.InputHTMLAttributes<HTMLInputElement>,
  {
    name: string;
    min?: number | null;
  }
>;
export function IntegerField(props: Props) {
  const { min, name, ...otherProps } = props;
  return (
    <FastField
      name={name}
      validate={(value: string) => {
        // A value of '' indicates the field is empty, which is fine.
        if (value === '') {
          return;
        }

        if (!validateNumber(value) || !Number.isInteger(Number(value))) {
          return <Trans>Must be an integer number.</Trans>;
        }

        if (typeof props.min === 'number' && Number(value) < props.min) {
          return (
            <Trans>Must be an integer number greater than {props.min}.</Trans>
          );
        }
      }}
      // Using a text input instead of an HTML5 number input, in order to have
      // greater control over validation timing and messages.
      type="text"
      {...otherProps}
    />
  );
}
IntegerField.defaultProps = {
  min: 1,
};
