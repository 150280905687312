import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';
import orderBy from 'lodash/orderBy';
import { Model, Enum } from '../../util/backendapi/models/api.interfaces';
import { StoredPlotNumericAnnotation } from 'util/backendapi/types/Model';
import {
  TSPlotReading,
  PlotSettingsAxis,
  ScatterPlotAxisSide,
} from './timeseriesplot.types';
import { StoredPlotAnnotationLabelPosition } from 'util/backendapi/types/Enum';
import { HighlightArea } from 'react-vis';

interface NonNullReadingsPlotItem {
  reading_id: number;
  reading_datetime: number;
  value: number;
}

export type DataPoint = {
  x: number;
  y: number;
  time: number;
  xId: number | null;
  yId: number | null;
};

/**
 * NOTE: matchReadingsByTime expect the input data to be sorted by reading_datetime in ascending order
 */
export function matchReadingsByTime(
  allXRaw: TSPlotReading[] | null,
  allYRaw: TSPlotReading[] | null,
  interpolate: boolean
): DataPoint[] {
  const allX =
    allXRaw &&
    allXRaw
      // Rename each plot reading's "x" and "y" back to "value" and "reading_datetime"
      // to make things less confusing (because in a scatter plot, these do not
      // wind up corresponding to the X and Y axes)
      .map((r) => ({
        value: r.y,
        // Cast the datetime from a Date to a number, to make comparisons easier
        reading_datetime: r.x.valueOf(),
        reading_id: r.reading_id,
      }))
      // Filter out null-value readings.
      .filter((r): r is NonNullReadingsPlotItem => r.value !== null);
  const allY =
    allYRaw &&
    allYRaw
      .map((r) => ({
        value: r.y,
        reading_datetime: r.x.valueOf(),
        reading_id: r.reading_id,
      }))
      .filter((r): r is NonNullReadingsPlotItem => r.value !== null);

  if (!(allX && allX.length && allY && allY.length)) {
    return [];
  }

  // Create d3 scales, to calculate interpolated values for times that
  // are present in one series but absent in the other.
  // TODO: This generates a "piecewise" scale, which is actually a collection
  // of individual scales between each data point, and it does a binary search
  // to find which one to use. Since we are moving through ordered data, and
  // sometimes the data will actually match up, we could probably do this
  // more efficiently by only generating each interpolator as we need it.

  // xInterpolator is a function that lets us know what the value
  // of the x series would have been at a particular time (assuming it
  // moved in a straight line from one data point to the next)
  const xInterpolator = scaleLinear()
    .domain(allX.map((r) => r.reading_datetime))
    .range(allX.map((r) => r.value));
  // yInterpolator, vice-versa
  const yInterpolator = scaleLinear()
    .domain(allY.map((r) => r.reading_datetime))
    .range(allY.map((r) => r.value));

  // We can only plot readings during the same time period. So filter
  // out any that are before or after the first or last date of the other series.
  const [firstXTime, lastXTime] = extent(xInterpolator.domain()) as [
    number,
    number
  ];
  const [firstYTime, lastYTime] = extent(yInterpolator.domain()) as [
    number,
    number
  ];
  const xSeries = allX.filter(
    ({ reading_datetime }) =>
      reading_datetime >= firstYTime && reading_datetime <= lastYTime
  );
  const ySeries = allY.filter(
    ({ reading_datetime }) =>
      reading_datetime >= firstXTime && reading_datetime <= lastXTime
  );

  let xLength = xSeries.length;
  let yLength = ySeries.length;
  let xIdx = 0;
  let yIdx = 0;
  let nextX = xLength > 0 ? xSeries[0] : null;
  let nextY = yLength > 0 ? ySeries[0] : null;
  const values: DataPoint[] = [];

  // Turn the x series and y series of data into a set of plottable points,
  // using interpolation to "fill in" when one series has a reading at
  // a particular time, but the other series has no matching reading at that
  // time.
  while (nextX || nextY) {
    if (nextX && nextY && nextX.reading_datetime === nextY.reading_datetime) {
      // X and Y both have a reading at the same time!
      // Draw a point using both of their values, and advance both iterators.
      values.push({
        xId: nextX.reading_id,
        yId: nextY.reading_id,
        x: nextX.value,
        y: nextY.value,
        time: nextX.reading_datetime,
      });
      xIdx = xIdx + 1;
      yIdx = yIdx + 1;
    } else if (
      nextY &&
      (!nextX || nextX.reading_datetime > nextY.reading_datetime)
    ) {
      if (interpolate) {
        // Y has a reading at this time, but X does not.
        // Draw a point at Y's value and X's interpolated value, and advance
        // the Y cursor only.
        values.push({
          xId: null,
          yId: nextY.reading_id,
          x: xInterpolator(nextY.reading_datetime),
          y: nextY.value,
          time: nextY.reading_datetime,
        });
      }
      yIdx = yIdx + 1;
    } else if (
      nextX &&
      (!nextY || nextY.reading_datetime > nextX.reading_datetime)
    ) {
      if (interpolate) {
        // X has a reading at this time, but Y does not.
        // Draw a point at X's value and Y's interpolated value, and advance
        // the X cursor only.
        values.push({
          xId: nextX.reading_id,
          yId: null,
          x: nextX.value,
          y: yInterpolator(nextX.reading_datetime),
          time: nextX.reading_datetime,
        });
      }
      xIdx = xIdx + 1;
    } else {
      throw new Error(
        `Unexpected state during scatter plot interpolation: (${xIdx}:${xLength}, ${yIdx}:${yLength})`
      );
    }

    nextX = xIdx < xLength ? xSeries[xIdx] : null;
    nextY = yIdx < yLength ? ySeries[yIdx] : null;
  }
  return values;
}

export function calculateDomainsWithPadding(data: DataPoint[]) {
  if (!data.length) {
    return {
      xDomain: undefined,
      yDomain: undefined,
    };
  }
  return {
    xDomain: _applyPaddingToDomain('x', data),
    yDomain: _applyPaddingToDomain('y', data),
  };
}

export interface ScatterDomainProps {
  xDomain?: [number, number];
  yDomain?: [number, number];
}

/**
 * Mostly identical with timeseriesplotselectors.calculateAnnotationPlotLines,
 * with a little differences regarding Vertical Annotation:
 *
 * - On TimeSeries Plot, X axis is with Date scale,
 * - On Scatter Plot, X axis is with Numeric scale,
 */
export function calculateScatterPlotAnnotations(
  domain: ScatterDomainProps,
  annotations: Model.StoredPlotAnnotation[] | undefined
) {
  if (
    !domain ||
    !domain.xDomain ||
    !domain.yDomain ||
    !annotations ||
    !annotations.length
  ) {
    return null;
  }

  const [xMin, xMax] = domain.xDomain;
  const [yMin, yMax] = domain.yDomain;

  const yAnnotations = annotations.filter(
    (annot) => annot.axis === Enum.StoredPlotAnnotationAxis.Y
  ) as StoredPlotNumericAnnotation[];
  const xAnnotations = annotations.filter(
    (annot) => annot.axis === Enum.StoredPlotAnnotationAxis.X
  ) as StoredPlotNumericAnnotation[];

  const sortedHorizontalAnnotations = orderBy(
    yAnnotations,
    (item) => Number(item.numeric_value),
    'desc'
  )
    .map((annot) => ({
      ...annot,
      numeric_value: Number(annot.numeric_value),
    }))
    .filter(
      (annot) => !(annot.numeric_value < yMin || annot.numeric_value > yMax)
    );

  const sortedVerticalAnnotations = orderBy(
    xAnnotations,
    (item) => Number(item.numeric_value),
    'desc'
  )
    .map((annot) => ({
      ...annot,
      numeric_value: Number(annot.numeric_value),
    }))
    .filter(
      (annot) => !(annot.numeric_value < xMin || annot.numeric_value > xMax)
    );

  const horizontalLines = sortedHorizontalAnnotations.flatMap((annot) => [
    { x: xMin, y: annot.numeric_value },
    { x: xMax, y: annot.numeric_value },
    { x: null, y: null },
  ]);

  const horizontalLabels = sortedHorizontalAnnotations
    .filter((annot) => annot.label)
    .map((annot) => ({
      x: xMin,
      y: annot.numeric_value,
      label: annot.label,
      position:
        annot.position === ''
          ? StoredPlotAnnotationLabelPosition.ABOVE
          : annot.position,
    }));

  const verticalLines = sortedVerticalAnnotations.flatMap((annot) => [
    { x: annot.numeric_value, y: yMin },
    { x: annot.numeric_value, y: yMax },
    { x: null, y: null },
  ]);

  const verticalLabels = sortedVerticalAnnotations
    .filter((annot) => annot.label)
    .map((annot) => ({
      x: annot.numeric_value,
      y: yMin,
      label: annot.label,
      position:
        annot.position === ''
          ? StoredPlotAnnotationLabelPosition.LEFT
          : annot.position,
    }));

  return {
    horizontalLines,
    horizontalLabels,
    verticalLines,
    verticalLabels,
  };
}

export function calculateAlarmThresholdLine(
  xDomain: [number, number],
  comparisionFactor: number,
  threshold: number
): { x: number; y: number }[] {
  return [
    {
      x: xDomain[0],
      y: xDomain[0] * comparisionFactor + threshold,
    },
    {
      x: xDomain[1],
      y: xDomain[1] * comparisionFactor + threshold,
    },
  ];
}

/**
 * Module-internal function to add the 5% padding to the plot (by setting its
 * D3 "domain" to be 5% greater on each side than the actual data domain)
 *
 * TODO: This logic is somewhat replicated in timeseriesplotselectors.js
 * @param axis
 * @param data
 */
function _applyPaddingToDomain(
  axis: 'x' | 'y',
  data: DataPoint[]
): [number, number] {
  const [minValue, maxValue] = extent(data, (d) => d[axis]) as [number, number];
  const padding = PADDING_FACTOR * (maxValue - minValue);
  return [minValue - padding, maxValue + padding];
}

const PADDING_FACTOR = 0.05;

/**
 * Convert from the "area" format returned by a react-vis highlight component,
 * into the format we use for scatter plot axis config
 *
 * @param area
 */
export function convertHighlightAreaToAxes(
  area: HighlightArea | null
): PlotSettingsAxis<ScatterPlotAxisSide>[] | null {
  if (!area) {
    return null;
  }
  return [
    {
      minimum: `${area.left}`,
      maximum: `${area.right}`,
      side: 'bottom',
    },
    {
      minimum: `${area.bottom}`,
      maximum: `${area.top}`,
      side: 'left',
    },
  ];
}
