import React, { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import ActionBlock from 'components/base/actionblock/actionblock';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { FormikHelpers, FormikErrors, Field, FieldArray } from 'formik';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { defaultMemoize } from 'reselect';
import { validateCode } from 'util/validation';
import { UppercaseTextField } from 'components/base/form/uppercase-text-field/UppercaseTextField';
import { getExpectedFields } from 'util/backendapi/error';
import { BackButton } from 'components/base/back-button/BackButton';
import Button from 'components/base/button/button';
import './AreaDetailView.scss';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { CivilFeature_STATUS } from 'util/backendapi/types/Enum';

export type AreaFormValues = Pick<Model.Area, 'code' | 'name' | 'time_zone'>;

export interface CivilFeaturesFormValues {
  civil_feature_name: string;
  civil_features: {
    id: number;
    name: string;
    status: CivilFeature_STATUS;
    key: string;
  }[];
  editing_civil_feature_key: string;
}

type ClientFilterOptions =
  Model.ReportInfo<Model.ReportsArea>['filters'][0]['options'];

export interface AreaDetailProps {
  area: null | Model.AreaDecorated;
  civilFeatures: Model.CivilFeature[];
  isLoading: boolean;
  isEditing: boolean;
  fetchErrorMessage: string | null;
  timeZones: Model.TimeZone[];
  clients: ClientFilterOptions;
  hasCreateAreaPermission: boolean;
  hasCommentsButton: boolean;
  handleSubmit: (
    values: AreaFormValues,
    formik: FormikHelpers<AreaFormValues>
  ) => any;
  handleCivilFeaturesSubmit: (
    values: CivilFeaturesFormValues,
    formik: FormikHelpers<CivilFeaturesFormValues>
  ) => any;
  onRetireCivilFeature: (id: number) => Promise<void>;
  onStartEditing: () => any;
  onStopEditing: () => any;
}

const selectTimeZoneOptions = defaultMemoize((timeZones: Model.TimeZone[]) => {
  return timeZones.map((tz) => ({
    value: tz.id,
    label: tz.name,
  }));
});

const selectClientOptions = defaultMemoize((clients: ClientFilterOptions) => {
  return (clients || []).map((client) => ({
    value: client.id,
    label: client.full_name,
  }));
});

const validateAreaForm = (values: AreaFormValues) => {
  let errors: FormikErrors<Model.Area> = {};

  if (!values.code) {
    errors.code = (<Trans>Area is required.</Trans>) as any;
  } else {
    if (!validateCode(values.code)) {
      errors.code = (
        <Trans>Area must only contain letters, numbers, and hyphens.</Trans>
      ) as any;
    }
  }

  if (!values.name) {
    errors.name = (<Trans>Name is required.</Trans>) as any;
  }

  return errors;
};

const validateCivilFeaturesForm = (values: CivilFeaturesFormValues) => {
  let errors: FormikErrors<CivilFeaturesFormValues> = {};
  return errors;
};

export function AreaDetailView(props: AreaDetailProps) {
  const pageProps = {
    name: 'maintain-area',
    header: <Trans>Area</Trans>,
  };

  const [isEditingCivilFeatures, setEditingCivilFeatures] =
    useState<boolean>(false);

  let { isEditing, area, civilFeatures } = props;

  isEditing = isEditing || !area;

  const initialAreaValues = useMemo(
    () =>
      area
        ? {
            code: area.code,
            name: area.name,
            time_zone: area.time_zone.id,
            client: area.client ? area.client.id : null,
          }
        : {
            code: '',
            name: '',
            time_zone: 1, // default to Pacific/Auckland
            client: null,
          },
    [area]
  );

  const initialCivilFeatureValues: CivilFeaturesFormValues = useMemo(
    () => ({
      civil_feature_name: '',
      editing_civil_feature_key: '',
      civil_features: civilFeatures.map(({ id, name, status }) => ({
        id,
        name,
        status,
        key: uniqueId(),
      })),
    }),
    [civilFeatures]
  );

  if (props.fetchErrorMessage) {
    return (
      <PageStandard {...pageProps}>
        <AlertDanger>{props.fetchErrorMessage}</AlertDanger>
      </PageStandard>
    );
  }

  if (props.isLoading) {
    return (
      <PageStandard {...pageProps}>
        <Loading />
      </PageStandard>
    );
  }

  return (
    <PageStandard
      name="maintain-area"
      header={<Trans>Area</Trans>}
      subHeader={area ? <>{area.code}</> : null}
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton defaultBackUrl="/area" />
        <div className="page-content-header">
          <ActionBlock className="text-right">
            {props.hasCommentsButton && area && (
              <div className="text-right">
                <ButtonShowCommentsPanel
                  type={Enum.Comment_RESOURCE_TYPE.area}
                  metadata={{
                    area: area.id,
                  }}
                  commentReportParams={`resourcetype=${Enum.Comment_RESOURCE_TYPE.area}&area=${area.id}`}
                />
              </div>
            )}
          </ActionBlock>
        </div>
      </div>
      <EditableCard<AreaFormValues>
        validateOnBlur={false}
        hasEditPermission={props.hasCreateAreaPermission}
        name="general-card"
        header={!area ? <Trans>New area</Trans> : <Trans>General</Trans>}
        startEditing={() => props.onStartEditing()}
        stopEditing={() => props.onStopEditing()}
        isEditMode={isEditing}
        initialValues={initialAreaValues}
        onSubmit={async (values, formik) => {
          try {
            await props.handleSubmit(values, formik);
          } catch (error) {
            showErrorsInFormik(formik, error, getExpectedFields(values));
          }
        }}
        validate={validateAreaForm}
        render={({ CardSectionComponent }) => (
          <CardSectionComponent
            name="general-section"
            header={<Trans>Name</Trans>}
            fields={[
              {
                name: 'code',
                label: <Trans>Area</Trans>,
                content: isEditing ? (
                  <>
                    <UppercaseTextField name="code" autoFocus={true} />
                    <FieldError name="code" />
                  </>
                ) : (
                  props.area && props.area.code
                ),
              },
              {
                name: 'name',
                label: <Trans>Name</Trans>,
                content: isEditing ? (
                  <>
                    <Field type="text" name="name" />
                    <FieldError name="name" />
                  </>
                ) : (
                  props.area && props.area.name
                ),
              },
              {
                name: 'timezone',
                label: <Trans>Timezone</Trans>,
                content: isEditing ? (
                  <>
                    <SimpleSelectField
                      name="time_zone"
                      options={selectTimeZoneOptions(props.timeZones)}
                    />
                    <FieldError name="time_zone" />
                  </>
                ) : (
                  props.area && props.area.time_zone.name
                ),
              },
              {
                name: 'client',
                label: <Trans>Client</Trans>,
                content: isEditing ? (
                  <>
                    <SimpleSelectField
                      name="client"
                      options={selectClientOptions(props.clients)}
                      placeholder={<Trans>Please select a client</Trans>}
                    />
                    <FieldError name="client" />
                  </>
                ) : (
                  props.area && props.area.client && props.area.client.full_name
                ),
              },
            ]}
          />
        )}
      />
      {area ? (
        <EditableCard<CivilFeaturesFormValues>
          validateOnBlur={false}
          hasEditPermission={props.hasCreateAreaPermission}
          name="civil-features-card"
          header={<Trans>Civil features</Trans>}
          startEditing={() => setEditingCivilFeatures(true)}
          stopEditing={() => setEditingCivilFeatures(false)}
          shouldDisable={isEditing}
          isEditMode={isEditingCivilFeatures}
          initialValues={initialCivilFeatureValues}
          onSubmit={async (values, formik) => {
            try {
              await props.handleCivilFeaturesSubmit(values, formik);
              setEditingCivilFeatures(false);
            } catch (error) {
              showErrorsInFormik(formik, error, getExpectedFields(values));
            }
          }}
          validate={validateCivilFeaturesForm}
          render={({ formik, CardSectionComponent }) => {
            return (
              <FieldArray name="civil_features">
                {(formikArrayHelpers) => {
                  return (
                    <CardSectionComponent
                      name="civil-features-section"
                      className="civil-features-section"
                      header={<Trans>Civil features</Trans>}
                      fields={[
                        {
                          name: 'civil-features-table',
                          content: (
                            <>
                              <DragDropContext
                                onBeforeDragStart={(initial) => {
                                  if (
                                    formik.values.editing_civil_feature_key !==
                                    ''
                                  ) {
                                    formik.setFieldValue(
                                      'editing_civil_feature_key',
                                      ''
                                    );
                                  }
                                }}
                                onDragEnd={(result: DropResult) => {
                                  if (
                                    result.destination &&
                                    result.source.index !==
                                      result.destination.index
                                  ) {
                                    formikArrayHelpers.move(
                                      result.source.index,
                                      result.destination.index
                                    );
                                  }
                                }}
                              >
                                <table>
                                  <Droppable droppableId="civic-feature-droppable">
                                    {(provided) => (
                                      <tbody
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                      >
                                        {formik.values.civil_features.map(
                                          (cf, idx) => (
                                            <Draggable
                                              key={cf.key}
                                              draggableId={cf.key}
                                              index={idx}
                                              isDragDisabled={
                                                !isEditingCivilFeatures
                                              }
                                            >
                                              {(provided, snapshot) => (
                                                <tr
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  ref={provided.innerRef}
                                                  className={classNames({
                                                    draggable:
                                                      isEditingCivilFeatures,
                                                    dragging:
                                                      snapshot.isDragging,
                                                  })}
                                                >
                                                  <td className="civil-feature-edit-item">
                                                    {!isEditingCivilFeatures ||
                                                    snapshot.isDragging ? (
                                                      cf.name
                                                    ) : formik.values
                                                        .editing_civil_feature_key ===
                                                      cf.key ? (
                                                      <Field
                                                        name={`civil_features.${idx}.name`}
                                                      />
                                                    ) : (
                                                      <>
                                                        {cf.name}
                                                        {cf.id > 0 ? (
                                                          <ButtonShowConfirmation
                                                            className="civil-feature-retire-button"
                                                            name={`civil-feature-${idx}-retire-button`}
                                                            onConfirm={async () => {
                                                              await props.onRetireCivilFeature(
                                                                cf.id
                                                              );
                                                              formikArrayHelpers.remove(
                                                                idx
                                                              );
                                                            }}
                                                            okBtnText={
                                                              <Trans>
                                                                Yes, retire
                                                              </Trans>
                                                            }
                                                            content={
                                                              <Trans>
                                                                Are you sure you
                                                                want to retire
                                                                the{' '}
                                                                <strong>
                                                                  {cf.name}
                                                                </strong>{' '}
                                                                civil feature?
                                                                Once retired the
                                                                civil feature
                                                                will not be
                                                                available to
                                                                assign to any
                                                                Observation
                                                                Points. This
                                                                action is not
                                                                reversible.
                                                              </Trans>
                                                            }
                                                          >
                                                            <Trans>
                                                              Retire
                                                            </Trans>
                                                          </ButtonShowConfirmation>
                                                        ) : null}
                                                        <Button
                                                          className="civil-feature-edit-button"
                                                          name={`civil_features.${idx}.isEditing`}
                                                          onClick={() =>
                                                            formik.setFieldValue(
                                                              'editing_civil_feature_key',
                                                              cf.key
                                                            )
                                                          }
                                                        >
                                                          <Trans>Edit</Trans>
                                                        </Button>
                                                      </>
                                                    )}
                                                  </td>
                                                </tr>
                                              )}
                                            </Draggable>
                                          )
                                        )}
                                        {provided.placeholder}
                                      </tbody>
                                    )}
                                  </Droppable>
                                </table>
                              </DragDropContext>
                              {isEditingCivilFeatures ? (
                                <div
                                  key="civil-feature-add"
                                  className="card-row"
                                >
                                  <div className="civil-feature-add-item">
                                    <Field name="civil_feature_name" />

                                    <Button
                                      className="civil-feature-add-button"
                                      iconType="icon-plus"
                                      onClick={() => {
                                        if (
                                          formik.values.civil_feature_name !==
                                          ''
                                        ) {
                                          formikArrayHelpers.push({
                                            id: 0,
                                            name: formik.values
                                              .civil_feature_name,
                                            status: CivilFeature_STATUS.active,
                                            key: uniqueId(),
                                          });
                                          formik.setFieldValue(
                                            'civil_feature_name',
                                            ''
                                          );
                                        }
                                      }}
                                    >
                                      <Trans>Add</Trans>
                                    </Button>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          ),
                        },
                      ]}
                    >
                      {!isEditingCivilFeatures &&
                        !props.isLoading &&
                        formik.values.civil_features.length === 0 && (
                          <p className="non-editable-value">
                            <Trans>
                              There are no civil features for this area
                            </Trans>
                          </p>
                        )}
                      {formik.status}
                    </CardSectionComponent>
                  );
                }}
              </FieldArray>
            );
          }}
        />
      ) : null}
    </PageStandard>
  );
}
