import React from 'react';
import {
  ReportColumn,
  ACTION_COLUMN,
  ReportFilter,
  ReportViewProps,
  DEFAULT_SHOW,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { getApi } from 'util/backendapi/fetch';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import ButtonShowModal, {
  ModalContentProps,
} from 'components/base/modal/buttonshowmodal';
import ModalContent from 'components/base/modal/modalcontent';
import { Formik, Form } from 'formik';
import { FormSection } from 'components/base/form/FormItem';
import { ToggleField } from 'components/base/form/toggle-field/ToggleField';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { useIsMounted } from 'util/hooks';
import { updateUserFileGate, fileGateDuck } from 'ducks/file-gate';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useReportState } from 'hooks/use-report-state';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { StandardDispatch } from 'main/store';
import { HasPermission } from 'components/logic/has-permission/HasPermission';

export const fileGatesColumns: ReportColumn<Model.ReportsFileGate>[] = [
  {
    label: <Trans>Name</Trans>,
    name: 'name',
    backendFieldName: 'profile__name',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Username</Trans>,
    name: 'username',
    backendFieldName: 'username',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Client</Trans>,
    name: 'client',
    backendFieldName: 'client_full_names',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Upload gate</Trans>,
    name: 'upload_gate',
    backendFieldName: 'is_file_uploader',
    visibility: DEFAULT_SHOW,
    accessor: (row) =>
      row.is_file_uploader ? <Trans>Open</Trans> : <Trans>Closed</Trans>,
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id', 'profile__name', 'is_file_uploader', 'username'],
    accessor: (
      row: Pick<
        Model.ReportsFileGate,
        'id' | 'profile__name' | 'is_file_uploader' | 'username'
      >
    ) => (
      <HasPermission check="can_assign_file_uploader_role">
        <ButtonShowModal
          iconOnly
          iconType="icon-edit"
          title={t`Edit`}
          className="btn-link-panel"
          name={`edit-upload-gate-${row.username}`}
          modalContent={(modalProps) => (
            <ToggleFileGateModal row={row} {...modalProps} />
          )}
        />
      </HasPermission>
    ),
  },
];

function _userToMenuItem(
  user: Model.ReportsFileGate
): SimpleSelectOption<number> {
  return {
    value: user.id,
    label: user.profile__name,
  };
}

export const fileGatesFilters: ReportFilter[] = [
  reportFilterAsyncMenu(
    'id',
    <Trans>Person</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (searchText) =>
        getApi('/reports/file-gates/', {
          profile__name__icontains: searchText,
          columns: ['id', 'profile__name'],
        }).then((users) => users.map(_userToMenuItem)),
      loadDefaults: (ids: number[]) =>
        getApi('/reports/file-gates/', {
          id__in: ids,
          columns: ['id', 'profile__name'],
        }).then((users) => users.map(_userToMenuItem)),
    },
    {
      autoFocus: true,
      placeholder: <Trans>All DMS users</Trans>,
    }
  ),
  reportFilterMenu(
    'client',
    <Trans>Client</Trans>,
    { isMulti: true, valueType: 'number' },
    (item: Pick<Model.Client, 'id' | 'full_name'>) => ({
      value: item.id,
      label: item.full_name,
    }),
    {
      placeholder: <Trans>All DMS clients</Trans>,
    }
  ),
  reportFilterMenu(
    'is_file_uploader',
    <Trans>Upload gate</Trans>,
    { isMulti: false, valueType: 'string' },
    ReportFilterMenu.PASS_MENU_THROUGH,
    {
      options: [
        // Note: because ReactSelect doesn't like `null` values, the trinary
        // logic here is best handled by just using strings. "true" and "false"
        // are acceptable to DRF, and '' will make `prepareApiQueryParams()`
        // remove the param.
        {
          value: '',
          label: <Trans>All statuses</Trans>,
        },
        {
          value: 'true',
          label: <Trans>Open</Trans>,
        },
        {
          value: 'false',
          label: <Trans>Closed</Trans>,
        },
      ],
    }
  ),
];

export function FileGatesScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    fileGatesColumns,
    fileGatesFilters,
    fileGateDuck,
    (state) => state.fileGate
  );
  return <FileGatesView reportProps={reportState} />;
}

interface FileGatesViewProps {
  reportProps: ReportViewProps<Model.ReportsFileGate>;
}

export function FileGatesView(props: FileGatesViewProps) {
  const columns = fileGatesColumns;
  const filters = fileGatesFilters;
  const { reportProps } = props;
  return (
    <PageStandard
      name="file-gates-report"
      header={<Trans>File Upload Gate</Trans>}
    >
      <div className="page-content-header-filters-actions">
        <ReportFiltersBlock
          filtersFrontend={filters}
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="text-right">
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ButtonPrint />
          <ExportReportButton
            fileNameBase={t`File Upload Gate report`}
            reportUrl="/reports/file-gates/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>
        <ReportTable<Model.ReportsFileGate>
          {...reportProps}
          columns={columns}
          filters={filters}
        />
      </div>
    </PageStandard>
  );
}

interface ToggleFileGateModalProps extends ModalContentProps {
  row: Pick<Model.ReportsFileGate, 'id' | 'is_file_uploader' | 'profile__name'>;
}
function ToggleFileGateModal(props: ToggleFileGateModalProps) {
  const dispatch: StandardDispatch = useDispatch();
  const isMounted = useIsMounted();
  const { row } = props;
  return (
    <ModalContent header={<Trans>Open or close upload gate</Trans>}>
      <p>
        <Trans>
          When the upload gate is open, <strong>{row.profile__name}</strong>{' '}
          will be able to upload data files from their dashboard or from the
          mobile app. When the gate is closed their ability to upload is
          disabled.
        </Trans>
      </p>
      <Formik
        initialValues={{
          is_upload_gate_enabled: row.is_file_uploader,
        }}
        onSubmit={async (values, formik) => {
          if (values.is_upload_gate_enabled === row.is_file_uploader) {
            props.hideModal();
          }

          try {
            await dispatch(
              updateUserFileGate(row.id, values.is_upload_gate_enabled)
            );
            if (!isMounted()) {
              return;
            }
            dispatch(fileGateDuck.refreshReportList());
            props.hideModal();
          } catch (e) {
            showErrorsInFormik(formik, e, []);
            formik.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <FormSection label={<Trans>Upload gate</Trans>}>
              <ToggleField
                name="is_upload_gate_enabled"
                options={[
                  {
                    label: <Trans>Closed</Trans>,
                    value: false,
                  },
                  {
                    label: <Trans>Open</Trans>,
                    value: true,
                  },
                ]}
              />
            </FormSection>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                type="submit"
                iconType="icon-save"
                disabled={
                  formik.isSubmitting ||
                  formik.values.is_upload_gate_enabled === row.is_file_uploader
                }
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
