import React from 'react';
import Select, {
  GroupBase,
  OnChangeValue,
  Props as ReactSelectProps,
} from 'react-select';
import classNames from 'classnames';
import { createSelector } from 'reselect';
import { isTruthy } from 'util/validation';

// A constant to use as a default option list (in order to avoid breaking
// memoization by declaring a new empty array literal on each run through)
const EMPTY_ARRAY: [] = [];

export interface SimpleSelectOption<E = string> {
  value: E;
  label: any;
}

export type SimpleSelectProps<E, IsMulti extends boolean = false> = Merge<
  ReactSelectProps<
    SimpleSelectOption<E>,
    IsMulti,
    GroupBase<SimpleSelectOption<E>>
  >,
  {
    name: string;
    placeholder?: React.ReactNode;
    isMulti?: IsMulti;
    value?: (IsMulti extends true ? E[] : E) | null | undefined;
    options: SimpleSelectOption<E>[];
    onChange: (selection: IsMulti extends true ? E[] : E | null) => void;
  }
>;

/**
 * A custom select component that bind simple value (string | string[]) as selected value(s).
 *
 * The main way it differs from a standard ReactSelect <Select> component, is
 * that it accepts and returns only the "value" field of the option objects
 * as its value, rather than returning the entire option object.
 *
 * That is, if you had these options:
 *
 * [
 *   {value: 'FIRST_VALUE', label: 'option 1'},
 *   {value: 'SECOND_VALUE', label: 'option 2'},
 * ]
 *
 * ... the default behavior of ReactSelect is to receive and return a value
 * like this: {value: 'FIRST_VALUE', label: 'option 1'}
 *
 * ... while this component uses ReactSelect hooks to receive and return a
 * value like this: 'FIRST_VALUE'
 *
 * If you want something different from that, you're probably better off not
 * using this component, and just using Select directly instead.
 *
 * @class SimpleSelect
 * @extends {React.Component}
 */
export default class SimpleSelect<
  EachOptionValue = string,
  IsMulti extends boolean = false
> extends React.PureComponent<SimpleSelectProps<EachOptionValue, IsMulti>> {
  /**
   * Given one or more values, find the options objects that have those values.
   * (This is important because React-Select expects to receive full option
   * objects for its own `props.value`, and it uses `===` comparisons on them.)
   */
  findSelectedOption = createSelector(
    (props: SimpleSelectProps<EachOptionValue, IsMulti>) => props.isMulti,
    (props: SimpleSelectProps<EachOptionValue, IsMulti>) => props.options,
    (props: SimpleSelectProps<EachOptionValue, IsMulti>) => props.value,
    function (
      isMulti,
      options,
      value
    ): OnChangeValue<SimpleSelectOption<EachOptionValue>, IsMulti> {
      if (!options) {
        return (isMulti ? [] : null) as OnChangeValue<
          SimpleSelectOption<EachOptionValue>,
          IsMulti
        >;
      }
      // if values is array, it indicates this is a multi-select
      if (Array.isArray(value) && isMulti) {
        return value
          .map((v) => options.find((opt) => v === opt.value))
          .filter(isTruthy) as ReadonlyArray<
          SimpleSelectOption<EachOptionValue>
        > as OnChangeValue<SimpleSelectOption<EachOptionValue>, IsMulti>;
      } else {
        return options.find((opt) => opt.value === value) as OnChangeValue<
          SimpleSelectOption<EachOptionValue>,
          IsMulti
        >;
      }
    }
  );

  handleChange = (
    selection: OnChangeValue<SimpleSelectOption<EachOptionValue>, IsMulti>
  ) => {
    const { onChange } = this.props;

    if (this.props.isMulti) {
      if (Array.isArray(selection)) {
        onChange(
          (selection as SimpleSelectOption<EachOptionValue>[]).map(
            (item) => item.value
          ) as any
        );
      } else {
        onChange([] as any);
      }
    } else {
      if (selection) {
        onChange(
          (selection as SimpleSelectOption<EachOptionValue>).value as any
        );
      } else {
        // We used to pass `undefined` when there was nothing selected,
        // but that causes Formik to remove the field from its values, which
        // in turn causes error messages not to be displayed. So we now pass
        // `null` instead.
        onChange(null as any);
      }
    }
  };

  render() {
    const { options, className, onChange, value, placeholder, ...otherProps } =
      this.props;
    return (
      <Select<SimpleSelectOption<EachOptionValue>, IsMulti>
        options={
          options || (EMPTY_ARRAY as SimpleSelectOption<EachOptionValue>[])
        }
        value={this.findSelectedOption(this.props)}
        onChange={this.handleChange}
        className={classNames('react-select', className)}
        classNamePrefix="react-select"
        // React-Select type definitions type placeholder as `string`, but it
        // actually accepts any React Node.
        placeholder={placeholder as string}
        {...otherProps}
      />
    );
  }
}
