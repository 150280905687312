import React from 'react';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t, Plural } from '@lingui/macro';
import { useReportState, ReportStateProps } from 'hooks/use-report-state';
import { RouteComponentProps } from 'react-router';
import {
  cancelProcessingRequest,
  confirmProcessingRequest,
  readingProcessingRequestDuck,
} from 'ducks/readings/processingRequest/list';
import { FullState } from 'main/reducers';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';

import {
  ReprocessingRequestColumnProps,
  reprocessingRequestsColumns,
  reprocessingRequestsFilters,
} from './table.config';
import { selectHasPermission } from 'util/user';
import { useSelector } from 'react-redux';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { CreateReprocessingRequestModal } from './CreateReprocessingRequestModal';

export const ReprocessingRequestListScreen = (props: RouteComponentProps) => {
  const reportState = useReportState(
    props,
    reprocessingRequestsColumns(),
    reprocessingRequestsFilters,
    readingProcessingRequestDuck,
    (state: FullState) => state.reprocessingRequest.list
  );

  const hasCancelRequestPermission = useSelector((state: FullState) =>
    selectHasPermission(
      state,
      Enum.User_PERMISSION.can_cancel_reading_reprocessing_requests
    )
  );
  const hasExecuteRequestPermission = useSelector((state: FullState) =>
    selectHasPermission(
      state,
      Enum.User_PERMISSION.can_execute_reading_reprocessing_requests
    )
  );

  const columnProps: ReprocessingRequestColumnProps = {
    onCancelRequest: cancelProcessingRequest,
    onConfirmRequest: confirmProcessingRequest,
    hasCancelRequestPermission: hasCancelRequestPermission,
    hasExecuteRequestPermission: hasExecuteRequestPermission,
    refreshList: reportState.refreshList,
  };

  return (
    <ReprocessingRequestListView
      reportProps={reportState}
      columnProps={columnProps}
    />
  );
};

export interface ReprocessingRequestListViewProps {
  reportProps: ReportStateProps<Model.ReportsReadingProcessingRequest>;
  columnProps?: ReprocessingRequestColumnProps;
}

export function ReprocessingRequestListView(
  props: ReprocessingRequestListViewProps
) {
  const { reportProps, columnProps } = props;

  const columns = reprocessingRequestsColumns(columnProps);

  return (
    <PageStandard
      name="reprocessing-requests-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Reprocessing Requests report</Trans>
        ) : (
          <Trans>Reprocessing Requests</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_reading_reprocessing_requests">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="request-reprocessing-button"
              data-testid="request-reprocessing-button"
              shortcut="CREATE_NEW"
              modalContent={(modalProps) => (
                <CreateReprocessingRequestModal
                  {...modalProps}
                  onAfterSubmit={reportProps.refreshList}
                />
              )}
            >
              <Trans>Create reprocessing request</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>
        <ReportFiltersBlock
          filtersFrontend={reprocessingRequestsFilters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={reprocessingRequestsFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Reprocessing requests report`}
            reportUrl="/reports/reading-processing-requests/"
            columns={columns}
            filters={reprocessingRequestsFilters}
          />
        </ActionBlock>

        <ReportTable<Model.ReportsReadingProcessingRequest>
          columns={columns}
          filters={reprocessingRequestsFilters}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 processing request"
              other="Filtered to # processing requests"
            />
          )}
          msgNoMatches={
            <Trans>No processing requests match the selected filters.</Trans>
          }
          {...reportProps}
        />
      </div>
    </PageStandard>
  );
}
