import classNames from 'classnames';
import Button, { ButtonProps } from '../button/button';
import {
  PageStandardContext,
  PageStandardContextState,
} from '../../modules/pagestandard/pagestandard';
import React, { useEffect, useRef, useCallback } from 'react';
import ReactModal from 'react-modal';

export interface ModalContentProps {
  modalName: ButtonShowModalProps['name'];
  hideModal: PageStandardContextState['hideModal'];
  pageName: PageStandardContextState['pageName'];
}

export type ButtonShowModalProps = ButtonProps & {
  name: string;
  onClick?: () => Promise<boolean>;
  modalContent: (modalContentProps: ModalContentProps) => React.ReactNode;
  modalProps?: Partial<ReactModal.Props>;
  autoShow?: boolean;
  context: PageStandardContextState;
};

function ButtonShowModalInner(props: ButtonShowModalProps) {
  const {
    name,
    children,
    className,
    modalContent,
    onClick,
    iconType,
    modalProps,
    autoShow,
    context,
    ...otherProps
  } = props;
  const thisComponent = useRef({ id: Date.now() });

  const contextRef = useRef(context);
  contextRef.current = context;

  useEffect(() => {
    const thisModal = thisComponent.current;

    if (autoShow) {
      contextRef.current.showModal(thisModal as any, name);
    }

    return () => {
      if ((contextRef.current.activeModal as any) === thisModal) {
        contextRef.current.hideModal();
      }
    };
  }, [autoShow, name]);

  const handleClick: React.MouseEventHandler = useCallback(
    async (e) => {
      e.preventDefault();
      if (onClick) {
        // Optionally call a function on click before showing the modal
        // If the function returns false the modal is not shown
        const ok = await onClick();
        if (!ok) return;
      }
      contextRef.current.showModal(thisComponent.current as any, name);
    },
    [name, onClick]
  );

  const isThisModalVisible =
    (context.activeModal as any) === thisComponent.current;

  const isAnyModalVisible = Boolean(context.activeModal);

  return (
    <React.Fragment>
      <Button
        id={`${context.pageName}-modal-button-${name}`}
        iconType={iconType}
        onClick={handleClick}
        className={classNames(className, {
          'btn-modal-active': isThisModalVisible,
        })}
        disabled={isAnyModalVisible}
        {...otherProps}
      >
        {children}
      </Button>
      <ReactModal
        isOpen={isThisModalVisible}
        onRequestClose={context.hideModal}
        className={defaultModalClassName}
        {...modalProps}
      >
        {props.modalContent({
          hideModal: context.hideModal,
          pageName: context.pageName,
          modalName: name,
        })}
      </ReactModal>
    </React.Fragment>
  );
}

const ButtonShowModal: React.FunctionComponent<
  Omit<ButtonShowModalProps, 'context'>
> = function ButtonShowModal(props) {
  return (
    <PageStandardContext.Consumer>
      {(context) => <ButtonShowModalInner {...props} context={context} />}
    </PageStandardContext.Consumer>
  );
};
export default ButtonShowModal;

export const defaultModalClassName = 'modal panel';
