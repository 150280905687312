import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FileUploaderView } from './fileuploaderview';
import { FullState } from 'main/reducers';
import { StandardDispatch } from 'main/store';
import { useIsMounted } from 'util/hooks';
import {
  userChangeUploaderFileSelection,
  uploadReadingsFiles,
  unmountUploader,
} from 'ducks/file-uploader';

/**
 * An interface component that selects a file for upload, and uploads it to the
 * backend.
 */
export default function FileUploader(_props: {}) {
  const { isUploading, errorMessage } = useSelector(
    (state: FullState) => state.fileUploads
  );
  const [viewKey, setViewKey] = React.useState(1);
  const dispatch: StandardDispatch = useDispatch();
  const isMounted = useIsMounted();

  const handleFileSelection = React.useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(userChangeUploaderFileSelection());

      if (e.target.files && e.target.files.length > 0) {
        await dispatch(uploadReadingsFiles(e.target.files));

        // After a successful upload, clear the upload button's selection by
        // changing the key prop we send to the view component (which causes
        // a new one to render)
        if (isMounted()) {
          setViewKey((prevKey) => prevKey + 1);
        }
      }
    },
    [dispatch, isMounted]
  );

  React.useEffect(
    () => () => {
      dispatch(unmountUploader());
    },
    [dispatch]
  );

  return (
    <FileUploaderView
      key={viewKey}
      isUploading={isUploading}
      errorMessage={errorMessage}
      handleFileSelection={handleFileSelection}
    />
  );
}
