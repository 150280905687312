import { TRANSLATED_ENUMS } from './backendapi/types/Enum';

// Some type aliases to make things below more readable.
type TranslatedEnums = typeof TRANSLATED_ENUMS;
export type TranslatedEnumNames = keyof TranslatedEnums;
// The allowed values for a given enum name.
export type TranslatedEnumVals<EnumName extends TranslatedEnumNames> =
  ArrayElements<TranslatedEnums[EnumName]>;

/**
 * A related helper function for getting translations of backend enums, into
 * a string.
 *
 * NOTE: Most of the time, it's easier to use the `<TransEnum>` component instead!
 *
 * @example
 * <I18n>
 *   {(props) => (
 *     <img
 *       src="kitten.jpg"
 *       // Just pass the result of `transEnum()` directly to `i18n._()`.
 *       alt={props.i18n._(
 *         transEnum('AdjustedReadingEntry_STATUS', row.status)
 *       )}
 *     />
 *   )}
 * </I18n>
 * @param pEnum
 * @param value
 * @returns A lang string ID suitable to passed directly as the only param
 * of `i18n._()`
 */
export function transEnum<EnumName extends TranslatedEnumNames>(
  pEnum: EnumName,
  value: TranslatedEnumVals<EnumName>
): string {
  return `${pEnum}.${value}`;
}
