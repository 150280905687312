import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { PerformanceIndicator_POST } from 'util/backendapi/types/Model';
import { patchApi, postApi } from 'util/backendapi/fetch';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import { FormikHelpers, FormikErrors, Formik, Form, Field } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { ButtonPrimary } from 'components/base/button/button';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { useGetApi } from 'hooks/use-get-api';
import Loading from 'components/base/loading/loading';

type FormValues = PerformanceIndicator_POST & { id: number };

interface Props {
  performanceIndicatorId?: number;
  onAfterSubmit: () => void;
}

export function EditPerformanceIndicatorModal({
  performanceIndicatorId,
  onAfterSubmit,
}: Props) {
  const isMounted = useIsMounted();

  const validate = useCallback(
    (values: FormValues): FormikErrors<FormValues> => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.name) {
        errors.name = (<Trans>Name is required.</Trans>) as any;
      }

      return errors;
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      try {
        if (performanceIndicatorId) {
          await patchApi(
            `/performance-indicators/${performanceIndicatorId}/`,
            values
          );
        } else {
          await postApi(`/performance-indicators/`, values);
        }
        onAfterSubmit();
      } catch (err) {
        if (isMounted()) {
          formik.setSubmitting(false);
          showErrorsInFormik(
            formik,
            err,
            getExpectedFields(values),
            ErrorNotice
          );
        }
      }
    },
    [performanceIndicatorId, onAfterSubmit, isMounted]
  );

  const [performanceIndicatorDetail] = useGetApi(
    performanceIndicatorId
      ? `/performance-indicators/${performanceIndicatorId}/`
      : null
  );

  const initialValues: FormValues | null = useMemo((): FormValues | null => {
    if (performanceIndicatorId && !performanceIndicatorDetail.data) {
      return null;
    }

    if (performanceIndicatorId && performanceIndicatorDetail.data) {
      return {
        id: performanceIndicatorId,
        name: performanceIndicatorDetail.data.name,
      };
    }

    return {
      id: 0,
      name: '',
    };
  }, [performanceIndicatorId, performanceIndicatorDetail.data]);

  return (
    <ModalContent
      header={
        performanceIndicatorId ? (
          <Trans>Edit performance indicator</Trans>
        ) : (
          <Trans>Create performance indicator</Trans>
        )
      }
    >
      {performanceIndicatorDetail.isLoading ? <Loading /> : null}
      {initialValues ? (
        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {(formik) => (
            <Form>
              <FormSection>
                <FormItem
                  label={<Trans>Name</Trans>}
                  fieldId="performance-indicator-name"
                >
                  <Field name="name" id="performance-indicator-name" />
                  <FieldError name="name" />
                </FormItem>
              </FormSection>
              {formik.status}
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="performance-indicator-submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-save"
                >
                  {performanceIndicatorId ? (
                    <Trans>Save</Trans>
                  ) : (
                    <Trans>Create</Trans>
                  )}
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      ) : null}
    </ModalContent>
  );
}
