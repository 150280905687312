import React from 'react';
import { FastField } from 'formik';
import { validateNumber } from 'util/validation';
import { Trans } from '@lingui/macro';

type Props = Merge<
  React.InputHTMLAttributes<HTMLInputElement>,
  {
    name: string;
    required?: boolean;
    decimalPlaces?: number;
  }
>;

export function NumberField(props: Props) {
  const { name, decimalPlaces, required, ...otherProps } = props;
  return (
    <FastField
      type="text"
      name={name}
      maxLength={25}
      validate={(value: string) => {
        if (value === '') {
          if (required) {
            return <Trans>This field is required.</Trans>;
          }
          return;
        }
        if (!validateNumber(value) || value.match(/[eE]/)) {
          return <Trans>Must be a number.</Trans>;
        }
        if (decimalPlaces) {
          const matchResult = value.match(/\.(\d+)$/);
          if (matchResult && matchResult[1].length > decimalPlaces) {
            return <Trans>Up to {decimalPlaces} decimal places allowed.</Trans>;
          }
        }
      }}
      {...otherProps}
    />
  );
}
