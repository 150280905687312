import { SiteList } from 'util/backendapi/models/sitelist';

export const ActionTypes = {
  FETCH_SITE_MENU_OPTIONS_START: 'dms/sitesmenu/FETCH_SITE_MENU_OPTIONS_START',
  FETCH_SITE_MENU_OPTIONS_RESPONSE:
    'dms/sitesmenu/FETCH_SITE_MENU_OPTIONS_RESPONSE',
  FETCH_SITE_MENU_OPTIONS_ERROR: 'dms/sitesmenu/FETCH_SITE_MENU_OPTIONS_ERROR',
};

export function sitesMenuInitialState() {
  return {
    errorMessage: '',
    isLoading: false,
    menuOptions: [],
    sitesForAreaCode: null,
  };
}

export function sitesMenuReducer(state = sitesMenuInitialState(), action) {
  switch (action.type) {
    case ActionTypes.FETCH_SITE_MENU_OPTIONS_START:
      if (state.sitesForAreaCode === action.payload.areaCode) {
        return state;
      }
      return {
        menuOptions: [],
        isLoading: true,
        errorMessage: '',
        sitesForAreaCode: action.payload,
      };
    case ActionTypes.FETCH_SITE_MENU_OPTIONS_RESPONSE:
      if (state.sitesForAreaCode !== action.payload.areaCode) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        menuOptions: action.payload.menuOptions,
      };
    case ActionTypes.FETCH_SITE_MENU_OPTIONS_ERROR:
      if (state.sitesForAreaCode !== action.payload.areaCode) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload.errorMessage,
      };
    default:
      return state;
  }
}

export function fetchSiteMenuOptions(areaCode) {
  return async function (dispatch) {
    try {
      dispatch(fetchSiteMenuOptions.startAction(areaCode));

      // If they provided a blank or empty area code, clear the menu.
      if (!areaCode) {
        return dispatch(fetchSiteMenuOptions.responseAction(areaCode, []));
      }

      const sitesJson = await SiteList.fetchAll({ areaCode: areaCode });
      const sites = sitesJson.data.map((i) => {
        const site = SiteList.fromEndpointFormat(i);
        return {
          id: site.id,
          code: site.code,
          areaId: site.areaId,
        };
      });
      return dispatch(fetchSiteMenuOptions.responseAction(areaCode, sites));
    } catch (e) {
      return dispatch(fetchSiteMenuOptions.errorAction(areaCode, e.message));
    }
  };
}
fetchSiteMenuOptions.startAction = function (areaCode) {
  return {
    type: ActionTypes.FETCH_SITE_MENU_OPTIONS_START,
    payload: areaCode,
  };
};
fetchSiteMenuOptions.errorAction = function (areaCode, errorMessage) {
  return {
    type: ActionTypes.FETCH_SITE_MENU_OPTIONS_ERROR,
    error: true,
    payload: {
      areaCode,
      errorMessage,
    },
  };
};
fetchSiteMenuOptions.responseAction = function (areaCode, menuOptions) {
  return {
    type: ActionTypes.FETCH_SITE_MENU_OPTIONS_RESPONSE,
    payload: {
      areaCode,
      menuOptions,
    },
  };
};
