import React, { useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { Field, FormikHelpers, FormikErrors } from 'formik';
import { useIsMounted } from 'util/hooks';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';

import './ReadingCommentExclusions.scss';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { BackButton } from 'components/base/back-button/BackButton';
import Loading from 'components/base/loading/loading';
import { useGetApi } from 'hooks/use-get-api';
import { postApi } from 'util/backendapi/fetch';
import { ReadingCommentExclusionPayload } from 'util/backendapi/types/Model';
import { useSelector } from 'react-redux';
import { FullState } from 'main/reducers';
import { selectHasPermission } from 'util/user';
import { User_PERMISSION } from 'util/backendapi/types/Enum';
import { AlertWarning } from 'components/base/alert/alert';

interface MyFormValues {
  exclusions: string;
}

/**
 * Convert the exclusions from a single string in a textarea, into an array of
 * non-empty strings.
 *
 * @param exclusions
 */
function exclusionsTextareaToArray(exclusions: string): string[] {
  return exclusions
    .split('\n')
    .map((value) => value.trim())
    .filter(Boolean);
}

export const ReadingCommentExclusionsScreen = () => {
  const canEdit = useSelector((state: FullState) =>
    selectHasPermission(
      state,
      User_PERMISSION.can_edit_reading_comments_report_exclusions
    )
  );
  const [{ data, isLoading, isError, error }, , , reloadData] = useGetApi(
    '/reports/reading-comments-report-exclusion/'
  );

  const isMounted = useIsMounted();
  const handleSubmit = useCallback(
    async (values: MyFormValues) => {
      const payload = exclusionsTextareaToArray(values.exclusions).map(
        (value) => ({
          comment_content: value,
        })
      );

      await postApi(
        '/reports/reading-comments-report-exclusion/update-exclusions/',
        payload
      );

      if (!isMounted()) {
        return;
      }

      reloadData();
    },
    [isMounted, reloadData]
  );

  return (
    <ReadingCommentExclusionsView
      exclusions={data}
      isLoading={isLoading}
      isError={isError}
      error={error}
      onSubmit={handleSubmit}
      canEdit={canEdit}
    />
  );
};

/**
 * Validate the exclusions form
 *
 * @param values
 */
function validate(values: MyFormValues) {
  const errors: FormikErrors<MyFormValues> = {};
  // check for duplicates
  const exclusions = exclusionsTextareaToArray(values.exclusions);
  if (new Set(exclusions).size < exclusions.length) {
    errors.exclusions = (
      <Trans>
        A unique phrase is listed more than once in the list. Please enter each
        phrase only once.
      </Trans>
    ) as any;
  }
  return errors;
}

interface ReadingCommentExclusionsProps {
  exclusions: ReadingCommentExclusionPayload[] | null;
  isLoading: boolean;
  isError: boolean;
  error: string;
  onSubmit: any;
  canEdit: boolean;
}

export function ReadingCommentExclusionsView(
  props: ReadingCommentExclusionsProps
) {
  const isMounted = useIsMounted();
  const [isEditing, setIsEditing] = React.useState(false);

  const handleSubmit = React.useCallback(
    async (values: MyFormValues, formik: FormikHelpers<MyFormValues>) => {
      try {
        await props.onSubmit(values);
        if (isMounted()) {
          setIsEditing(false);
        }
      } catch (e) {
        showErrorsInFormik(formik, e, getExpectedFields(values));
      }
    },
    [isMounted, props]
  );

  let exclusionsString = '';
  if (props.exclusions) {
    props.exclusions.forEach((exclusion) => {
      exclusionsString += exclusion.comment_content + '\n';
    });
  }

  return (
    <PageStandard
      name="manage-reading-comment-report-exclusions"
      header={<Trans>Reading comment exclusions</Trans>}
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton defaultBackUrl="/reading-comments" />
        {props.isLoading ? (
          <Loading />
        ) : props.isError ? (
          <AlertWarning>{props.error}</AlertWarning>
        ) : (
          <EditableCard<MyFormValues>
            name="overview"
            header={<Trans>Exclusions</Trans>}
            hasEditPermission={props.canEdit}
            isEditMode={isEditing && props.canEdit}
            startEditing={() => setIsEditing(true)}
            stopEditing={() => setIsEditing(false)}
            initialValues={{
              exclusions: exclusionsString,
            }}
            onSubmit={handleSubmit}
            validate={validate}
            validateOnBlur={false}
            validateOnChange={false}
            render={({ formik, CardSectionComponent }) => (
              <>
                {formik.status}
                <CardSectionComponent
                  name="manage-reading-comment-report-exclusions-field"
                  header={<Trans>Reading comment exclusions</Trans>}
                  fields={[
                    {
                      name: 'exclusions',
                      label: <Trans>Exclusions</Trans>,
                      content: isEditing ? (
                        <>
                          <Field component="textarea" name="exclusions" />
                          <FieldError name="exclusions" />
                        </>
                      ) : (
                        <p className="text-with-linebreaks">
                          {exclusionsString}
                        </p>
                      ),
                    },
                  ]}
                />
              </>
            )}
          />
        )}
      </div>
    </PageStandard>
  );
}
