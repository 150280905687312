import { makeReportingDuck, ReportingDuckActions } from './make-reporting-duck';
import {
  getPaginated,
  getApi,
  postApi,
  deleteApi,
} from 'util/backendapi/fetch';
import { StandardThunk } from 'main/store';

export const fileGateDuck = makeReportingDuck(
  'fileGates',
  (filters) => getPaginated('/reports/file-gates/', filters),
  () => getApi('/reports/file-gates/info/')
);

export type FileGateAction = ReportingDuckActions<typeof fileGateDuck>;

export function updateUserFileGate(
  user: number,
  newSetting: boolean
): StandardThunk {
  return async function () {
    const url = `/users/${user}/file-uploader/` as const;
    // You send a POST request to enable the file gate,
    // or a DELETE request to disable it.
    if (newSetting === true) {
      return await postApi(url);
    } else {
      return await deleteApi(url);
    }
  };
}
