import React from 'react';
import { Trans } from '@lingui/macro';
import lodashSet from 'lodash/set';
import { Model } from 'util/backendapi/models/api.interfaces';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { AlertDanger } from 'components/base/alert/alert';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { Link } from 'react-router-dom';
import { Icon, IconType } from 'components/base/icon/icon';
import { Field, FieldArray, FormikErrors, FormikProps } from 'formik';
import { useIsMounted } from 'util/hooks';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import Loading from 'components/base/loading/loading';
import ActionBlock from 'components/base/actionblock/actionblock';
import { validateCode } from 'util/validation';
import { CardSectionRow } from 'components/base/card/card';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { InstrumentTypeItem } from 'util/backendapi/types/Model';
import Button from 'components/base/button/button';
import { IntegerField } from 'components/base/form/integer-field/IntegerField';
import { UppercaseTextField } from 'components/base/form/uppercase-text-field/UppercaseTextField';
import { getExpectedFields } from 'util/backendapi/error';
import { BackButton } from 'components/base/back-button/BackButton';
import { READINGS_LEGEND_STYLED_ICONS } from 'screens/storedplot/spatial-plot/ReadingsLegend';
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';
import './FontIconPicker.scss';

type MODE = 'VIEW' | 'EDIT' | 'NEW';
export type EDIT_SECTION = 'main' | 'items';

export interface InstrumentTypeDetailViewProps {
  instrumentType: Model.InstrumentType | null;
  hasEditPermission: boolean;
  mode: MODE;
  error: string;
  loading: boolean;
  onCancel: () => void;
  onEdit: (section: EDIT_SECTION) => void;
  onSubmit: (values: any, id: number | null) => Promise<Model.InstrumentType>;
  onAfterSubmit: (values: Model.InstrumentType) => void;
  edit?: string;
}

type InstrumentTypeFormValues = Omit<
  Model.InstrumentType,
  'default_y_axis_range' | 'items' | 'id'
>;
interface SingleInstrumentTypeItem {
  item_number: string;
  description: string;
}
interface InstrumentTypeItemsFormValues {
  items: SingleInstrumentTypeItem[];
}

type Props = InstrumentTypeDetailViewProps;

const validateMainSection = (values: InstrumentTypeFormValues) => {
  let errors: any = {};

  if (!values.code) {
    errors.code = <Trans>Instrument type is required.</Trans>;
  } else if (!validateCode(values.code)) {
    errors.code = (
      <Trans>
        Instrument type must only contain letters, numbers, and hyphens.
      </Trans>
    );
  }

  if (!values.name) {
    errors.name = <Trans>Name is required.</Trans>;
  }

  if (values.readings_legend_symbol && !values.readings_legend_title) {
    errors.readings_legend_title = <Trans>Title is required.</Trans>;
  }

  if (values.readings_legend_title && !values.readings_legend_symbol) {
    errors.readings_legend_symbol = <Trans>Symbol is required.</Trans>;
  }

  return errors;
};

const validateInstrumentTypeItemsSection = (
  values: InstrumentTypeItemsFormValues
) => {
  const errors: FormikErrors<InstrumentTypeItemsFormValues> = {};
  values.items.forEach((item, idx) => {
    if (!item.item_number) {
      lodashSet(
        errors,
        ['items', idx, 'item_number'],
        <Trans>Item number is required</Trans>
      );
    } else if (
      idx > 0 &&
      values.items
        .slice(0, idx)
        .some((otherItem) => otherItem.item_number === item.item_number)
    ) {
      lodashSet(
        errors,
        ['items', idx, 'item_number'],
        <Trans>Item number must be unique for the instrument type</Trans>
      );
    }
    if (!item.description) {
      lodashSet(
        errors,
        ['items', idx, 'description'],
        <Trans>Description is required</Trans>
      );
    }
  });
  return errors;
};

const renderField = (
  instrumentType: Model.InstrumentType | null,
  label: React.ReactNode,
  field: keyof Model.InstrumentType,
  isEditing: boolean,
  isSubmitting: boolean,
  extraProps: React.InputHTMLAttributes<HTMLInputElement> = {}
) => {
  return {
    name: field,
    label,
    content: isEditing ? (
      <>
        <Field
          type="text"
          name={field}
          disabled={isSubmitting}
          {...extraProps}
        />
        <FieldError name={field} />
      </>
    ) : (
      instrumentType && instrumentType[field]
    ),
  };
};

const renderReadingsLegendIcon = (styledIcon: string | null) => {
  if (!styledIcon) return <></>;

  const [icon, style] = styledIcon.split(':');

  return (
    <span style={{ color: style }}>
      <Icon className="font-picker-icon" type={icon as IconType} />
    </span>
  );
};

const renderIconPickerField = (
  formik: FormikProps<InstrumentTypeFormValues>,
  instrumentType: Model.InstrumentType | null,
  label: React.ReactNode,
  field: keyof InstrumentTypeFormValues,
  isEditing: boolean,
  isSubmitting: boolean,
  extraProps: React.InputHTMLAttributes<HTMLInputElement> = {}
) => {
  return {
    name: field,
    label,
    content: isEditing ? (
      <>
        <FontIconPicker
          icons={READINGS_LEGEND_STYLED_ICONS}
          name={field}
          closeOnSelect={true}
          noSelectedPlaceholder="Select a symbol"
          value={formik.values[field]}
          onChange={(icon) => formik.setFieldValue(field, icon)}
          renderFunc={(icon) => renderReadingsLegendIcon(icon)}
        />
        <FieldError name={field} />
      </>
    ) : (
      instrumentType && renderReadingsLegendIcon(instrumentType[field])
    ),
  };
};

const defaultInitialValues: InstrumentTypeFormValues = {
  code: '',
  name: '',
  default_plot_title: '',
  default_scatter_plot_title: '',
  default_scatter_plot_x_title: '',
  default_scatter_plot_y_title: '',
  default_vertical_plot_title: '',
  cumulative_plot_y_axis_title: '',
  standard_units: '',
  readings_legend_title: '',
  readings_legend_symbol: '',
};

export const InstrumentTypeDetailView: React.FunctionComponent<Props> = (
  props
) => {
  const {
    instrumentType,
    error,
    loading,
    onCancel,
    onEdit,
    onSubmit,
    onAfterSubmit,
  } = props;
  const isEditingMain =
    (props.mode === 'EDIT' && props.edit === 'main') || props.mode === 'NEW';
  const isEditingItems = props.edit === 'items' && props.mode === 'EDIT';
  const isMounted = useIsMounted();

  const initialMainValues: InstrumentTypeFormValues =
    props.mode === 'NEW' ? defaultInitialValues : props.instrumentType!;

  const initialItemsValues: InstrumentTypeItemsFormValues =
    props.mode === 'NEW' ||
    props.instrumentType == null ||
    props.instrumentType.items.length === 0
      ? { items: [{ item_number: '', description: '' }] }
      : {
          items: props.instrumentType.items.map((item) => ({
            item_number: `${item.item_number}`,
            description: item.description,
          })),
        };

  return (
    <PageStandard
      name="maintInstrumentType"
      header={<Trans>Instrument Type</Trans>}
      subHeader={
        instrumentType && `${instrumentType.code} - ${instrumentType.name}`
      }
    >
      {loading && <Loading />}

      {error && <AlertDanger>{error}</AlertDanger>}
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton defaultBackUrl="/instrument-types" />
        {instrumentType && (
          <div className="page-content-header">
            <ActionBlock>
              <Link
                to={`/observation-point/?instrument_type__in=${instrumentType.id}`}
                className="btn"
              >
                <span>
                  <Trans>List observation points</Trans>
                </span>
                <Icon type="icon-observation-point" />
              </Link>
            </ActionBlock>
          </div>
        )}
      </div>

      {!error && !loading && (instrumentType || props.mode === 'NEW') && (
        <>
          <EditableCard<InstrumentTypeFormValues>
            name="details"
            header={
              props.mode === 'NEW' ? (
                <Trans>New instrument type</Trans>
              ) : (
                <Trans>All instrument type details</Trans>
              )
            }
            hasEditPermission={props.hasEditPermission}
            isEditMode={isEditingMain}
            startEditing={() => {
              onEdit('main');
            }}
            stopEditing={onCancel}
            onSubmit={async (values, formik) => {
              try {
                const updated = await onSubmit(
                  values,
                  instrumentType && instrumentType.id
                );
                onAfterSubmit(updated);
                if (isMounted()) {
                  formik.setSubmitting(false);
                }
              } catch (errors) {
                if (isMounted()) {
                  formik.setSubmitting(false);
                  showErrorsInFormik(formik, errors, getExpectedFields(values));
                }
              }
            }}
            initialValues={initialMainValues}
            validate={validateMainSection}
            render={({ formik, CardSectionComponent }) => (
              <>
                {formik.status && <ErrorNotice>{formik.status}</ErrorNotice>}
                <CardSectionComponent
                  name="generalSection"
                  header={<Trans>General</Trans>}
                  fields={[
                    {
                      name: 'code',
                      label: <Trans>Instrument type</Trans>,
                      content: isEditingMain ? (
                        <>
                          <UppercaseTextField
                            name="code"
                            disabled={formik.isSubmitting}
                            autoFocus={true}
                          />
                          <FieldError name="code" />
                        </>
                      ) : (
                        instrumentType && instrumentType.code
                      ),
                    },
                    renderField(
                      instrumentType,
                      <Trans>Name</Trans>,
                      'name',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                    renderField(
                      instrumentType,
                      <Trans>Standard units</Trans>,
                      'standard_units',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                    renderField(
                      instrumentType,
                      <Trans>Plot title</Trans>,
                      'default_plot_title',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                  ]}
                />

                <CardSectionComponent
                  name="timeSeriesPlot"
                  header={<Trans>Time series plots</Trans>}
                  fields={[
                    renderField(
                      instrumentType,
                      <Trans>Y axis title</Trans>,
                      'default_vertical_plot_title',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                  ]}
                />

                <CardSectionComponent
                  name="scatterPlot"
                  header={<Trans>Scatter plots</Trans>}
                  fields={[
                    renderField(
                      instrumentType,
                      <Trans>Title</Trans>,
                      'default_scatter_plot_title',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                    renderField(
                      instrumentType,
                      <Trans>X axis title</Trans>,
                      'default_scatter_plot_x_title',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                    renderField(
                      instrumentType,
                      <Trans>Y axis title</Trans>,
                      'default_scatter_plot_y_title',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                  ]}
                />

                <CardSectionComponent
                  name="cumulativePlot"
                  header={<Trans>Cumulative plots</Trans>}
                  fields={[
                    renderField(
                      instrumentType,
                      <Trans>Y axis title</Trans>,
                      'cumulative_plot_y_axis_title',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                  ]}
                />

                <CardSectionComponent
                  name="spatialPlansAndWanderPlots"
                  header={<Trans>Spatial plans and wander plots</Trans>}
                  fields={[
                    renderField(
                      instrumentType,
                      <Trans>Legend title</Trans>,
                      'readings_legend_title',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                    renderIconPickerField(
                      formik,
                      instrumentType,
                      <Trans>Legend symbol</Trans>,
                      'readings_legend_symbol',
                      isEditingMain,
                      formik.isSubmitting
                    ),
                  ]}
                />
              </>
            )}
          />
          <EditableCard<InstrumentTypeItemsFormValues>
            enableReinitialize={!isEditingItems}
            header={<Trans>Instrument type items</Trans>}
            name="instrument-type-items"
            hasEditPermission={props.hasEditPermission}
            isEditMode={isEditingItems}
            startEditing={() => {
              onEdit('items');
            }}
            stopEditing={onCancel}
            validate={validateInstrumentTypeItemsSection}
            onSubmit={async (values, formik) => {
              try {
                const updated = await onSubmit(
                  values,
                  instrumentType && instrumentType.id
                );
                onAfterSubmit(updated);
                if (isMounted()) {
                  formik.setSubmitting(false);
                }
              } catch (errors) {
                if (isMounted()) {
                  formik.setSubmitting(false);
                  showErrorsInFormik(formik, errors, getExpectedFields(values));
                }
              }
            }}
            initialValues={initialItemsValues}
            render={({ formik, CardSectionComponent }) => (
              <FieldArray name="items">
                {(formikArrayHelpers) => {
                  let cardFields: CardSectionRow[] = [];
                  if (!isEditingItems) {
                    if (
                      props.instrumentType &&
                      props.instrumentType!.items.length > 0
                    ) {
                      cardFields = formik.values.items.map((itemVal, idx) => ({
                        name: `instrument-type-item-view-${idx}`,
                        label: <Trans>Item number {itemVal.item_number}</Trans>,
                        content: itemVal.description,
                      }));
                    }
                  } else {
                    cardFields = formik.values.items.map((itemVal, idx) => {
                      const rowName = `instrument-type-item-${idx}`;
                      let deleteButton;

                      if (idx > instrumentType!.items.length - 1) {
                        deleteButton = (
                          <Button
                            name={`${rowName}-delete`}
                            onClick={() => formikArrayHelpers.remove(idx)}
                          >
                            <Trans>Delete</Trans>
                          </Button>
                        );
                      } else {
                        deleteButton = (
                          <ButtonShowConfirmation
                            name={`${rowName}-delete`}
                            onConfirm={async () => {
                              const itemsToKeep: InstrumentTypeItem[] =
                                instrumentType!.items.filter((item, idx2) => {
                                  if (idx2 !== idx) {
                                    return item;
                                  }
                                  return false;
                                });
                              await onSubmit(
                                { items: itemsToKeep } as any,
                                instrumentType!.id
                              );
                              formikArrayHelpers.remove(idx);
                            }}
                            okBtnText={<Trans>Yes, delete</Trans>}
                            content={
                              <Trans>
                                Are you sure you want to delete the item{' '}
                                {itemVal.item_number} description? This action
                                is not reversible.
                              </Trans>
                            }
                          >
                            <Trans>Delete</Trans>
                          </ButtonShowConfirmation>
                        );
                      }

                      return {
                        name: rowName,
                        columns: [
                          {
                            name: rowName + '-item-number',
                            label: <Trans>Item number</Trans>,
                            content: (
                              <>
                                <IntegerField
                                  name={`items[${idx}].item_number`}
                                />
                                <FieldError
                                  name={`items[${idx}].item_number`}
                                />
                              </>
                            ),
                          },
                          {
                            name: rowName + '-description',
                            label: <Trans>Description</Trans>,
                            content: (
                              <>
                                <Field
                                  name={`items[${idx}].description`}
                                  maxLength="15"
                                />
                                <FieldError
                                  name={`items[${idx}].description`}
                                />
                                {deleteButton}
                              </>
                            ),
                          },
                        ],
                      };
                    });
                    cardFields.push(
                      <div key="items-add" className="card-row">
                        <div className="form-group">
                          <Button
                            name="item-add"
                            iconType="icon-plus"
                            onClick={() =>
                              formikArrayHelpers.push({
                                item_number: '',
                                description: '',
                              })
                            }
                          >
                            <Trans>Add item</Trans>
                          </Button>
                        </div>
                      </div>
                    );
                  }
                  return (
                    <CardSectionComponent
                      name="items"
                      header={<Trans>Items</Trans>}
                      fields={cardFields}
                    >
                      {!isEditingItems &&
                        !loading &&
                        formik.values.items.length === 0 && (
                          <p className="non-editable-value">
                            <Trans>
                              There are no items for this instrument type
                            </Trans>
                          </p>
                        )}
                      {formik.status}
                    </CardSectionComponent>
                  );
                }}
              </FieldArray>
            )}
          />
        </>
      )}
    </PageStandard>
  );
};
