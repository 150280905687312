import React from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import PanelContent from 'components/base/panel/panelcontent';
import { Trans } from '@lingui/macro';
import { formatDatetimeForDisplayPretty } from 'util/dates';

interface Props {
  readingsBatch: Model.ListReadingsBatch;
  readingsFile: null | Model.ReadingsFileDecorated;
}

export function BatchSummary({ readingsBatch, readingsFile }: Props) {
  return (
    <PanelContent header={<Trans>Batch info</Trans>}>
      <dl className="dl-inline">
        <dt>
          <Trans>Batch:</Trans>
        </dt>
        <dd>{readingsBatch.batch_number}</dd>
        <dt>
          <Trans>Records:</Trans>
        </dt>
        <dd>{readingsBatch.readings_count}</dd>
        <dt>
          <Trans>Batch comments:</Trans>
        </dt>
        <dd>
          <div className="text-with-linebreaks">
            {readingsBatch.inspector_comment?.content}
          </div>
        </dd>
        <dt>
          <Trans>Rain last 4 days:</Trans>
        </dt>
        <dd>{readingsBatch.rainfall}</dd>
        <dt>
          <Trans>Observed by:</Trans>
        </dt>
        <dd>{readingsBatch.observer_name}</dd>
        <dt>
          <Trans>Input file:</Trans>
        </dt>
        <dd>
          {readingsFile ? (
            <Trans>
              {readingsFile.file_name} produced on{' '}
              {formatDatetimeForDisplayPretty(
                readingsFile.datetime_generated,
                readingsFile.time_zone?.name
              )}
            </Trans>
          ) : (
            <Trans>N/A</Trans>
          )}
        </dd>
      </dl>
    </PanelContent>
  );
}
