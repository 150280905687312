import React from 'react';
import { Trans, Plural, NumberFormat } from '@lingui/macro';
import lodashGet from 'lodash/get';
import range from 'lodash/range';
import classNames from 'classnames';
import {
  PaginationMeta,
  PAGINATION_COUNT_LIMIT,
} from 'util/backendapi/pagination';
import { AlertDanger } from 'components/base/alert/alert';
import Button from 'components/base/button/button';
import { ListPager } from 'components/base/listpager/listpager';
import { ReportColumnsMenu } from './ReportColumnsMenu';
import Loading from 'components/base/loading/loading';
import { detectExportMode } from 'util/export';
import { Model } from 'util/backendapi/models/api.interfaces';
import {
  parseStringArrayFromQueryParam,
  RCPWithQueryParams,
  setQueryParams,
} from 'util/routing';
import { Route } from 'react-router';
import { createSelector } from 'reselect';
import { ReportTableTh } from './ReportTableTh';
import {
  ReportColumn,
  ReportColumnRenderInfo,
  ReportFilter,
  ALWAYS_SHOW,
  getColBackendField,
  DEFAULT_SHOW,
} from '../report-types';
import ActionBlock from 'components/base/actionblock/actionblock';
import './ReportTable.scss';

export interface ReportTableProps<TReportItem extends {} = any> {
  records?: TReportItem[] | null;
  renderTable?: (renderProps: {
    alternateTableBody: false | React.ReactNode;
    activeColumns: ReportColumnRenderInfo<TReportItem>[];
  }) => React.ReactNode;
  errorMessage?: React.ReactNode;
  isLoading?: boolean;
  pagination: PaginationMeta | null;
  // filters
  hasActiveFilters?: boolean;
  msgFilterCount?: (count: number) => React.ReactNode;
  onClearFilters?: () => void;
  msgNoMatches?: React.ReactNode;
  msgTooManyMatches?: (count: number) => React.ReactNode;
  // columns
  columns?: ReportColumn<TReportItem>[];
  filters?: ReportFilter[];
  reportInfo?: Model.ReportInfo<TReportItem> | null;
  // Optional callback function to customize a table row's props
  getTrProps?: (
    row: TReportItem,
    rowIdx: number
  ) => Partial<React.HTMLAttributes<HTMLTableRowElement>>;
  defaultSortColumn?: string;
  onBulkSelect?: (selectedRows: number[]) => void;
}

interface ReportTableInnerProps<TReportItem>
  extends ReportTableProps<TReportItem>,
    RCPWithQueryParams<{ columns: string[] }> {
  isExportMode?: boolean;
}

interface ReportTableState {
  bulkSelectedRows: Set<number>;
}

/**
 *
 * @param props.errorMessage An error message to display in place of the tabel body
 * @param props.hasActiveFilters Indicates whether the results are currently being
 * filtered.
 * @param props.isLoading Indicates whether the results are currently being loaded
 * @param props.msgFilterCount A pluralizable language string (typically a
 * <Plural> tag) for a message that says how many records the results have been
 * filtered to.
 * @param props.msgNoMatches A language string to display when there are no
 * matching records to display.
 * @param props.onClearFilters A callback function to clear the filters.
 * @param props.pagination The current pagination state.
 * @param props.render A render prop to display the table. It is passed a single
 * parameter, "alternateTableBody". If this is false, it indicates that the
 * table should display the retrieved records. If this is not false, it will
 * contain some JSX elements that should be displayed in place of the table
 * body.
 */
class ReportTableInner<TReportItem extends {} = any> extends React.Component<
  ReportTableInnerProps<TReportItem>,
  ReportTableState
> {
  state: ReportTableState = {
    bulkSelectedRows: new Set(),
  };

  componentDidUpdate(prevProps: ReportTableInnerProps<TReportItem>) {
    if (this.state.bulkSelectedRows.size === 0) {
      return;
    }
    if (
      prevProps.pagination?.requested !== this.props.pagination?.requested ||
      prevProps.records !== this.props.records ||
      (!prevProps.isLoading && this.props.isLoading) ||
      (prevProps.onBulkSelect && !this.props.onBulkSelect)
    ) {
      // Clear the bulk selected rows when the page or filters change
      this.setState({ bulkSelectedRows: new Set() }, () =>
        this.props.onBulkSelect?.([])
      );
    }
  }

  getDisplayableColumns = createSelector(
    (props: ReportTableInnerProps<TReportItem>) => props.reportInfo,
    (props: ReportTableInnerProps<TReportItem>) => props.columns,
    function (
      reportInfo,
      columns
    ): Omit<ReportColumnRenderInfo<TReportItem>, 'derivedAccessor'>[] {
      if (!columns) {
        return [];
      }

      if (!reportInfo) {
        // If we haven't fetched the report API metadata yet (or if there is
        // no report API metadata)
        return columns.map((column) => ({
          name: column.name,
          frontend: column,
          backend: null,
        }));
      }

      return columns
        .map((column) => {
          // Find the backend info for each report API column.
          // (if a column has no "reportApiFieldName", check if its name matches)
          const backendName = getColBackendField<TReportItem>(column);
          const backend =
            reportInfo.columns.find(
              (backend) => backend.field_name === backendName
            ) || null;
          return {
            name: column.name,
            frontend: column,
            backend,
          };
        })
        .filter(
          ({ frontend, backend }) =>
            // Filter out:
            // -- columns mapped to a backend field, but backend field metadata
            // could not be found
            !(getColBackendField<TReportItem>(frontend) && !backend)
        );
    }
  );

  getSelectableColumns = createSelector(
    this.getDisplayableColumns,
    function (columns) {
      return columns.filter(
        ({ frontend }) => frontend.visibility !== ALWAYS_SHOW
      );
    }
  );

  getSelectedColumnNames = createSelector(
    (props: ReportTableInnerProps<TReportItem>) => props.location,
    this.getSelectableColumns,
    function (location, selectableColumns): string[] {
      if (selectableColumns.length === 0) {
        return [];
      }

      const columnsInUrl = parseStringArrayFromQueryParam(
        { location },
        'columns',
        null
      );

      if (columnsInUrl === null) {
        // No 'columns' param in the URL. Return the default columns.
        return selectableColumns
          .filter(({ frontend }) => frontend.visibility === DEFAULT_SHOW)
          .map((col) => col.name);
      } else {
        return columnsInUrl.filter((colname) =>
          // Filter out column names that are not configured on the frontend.
          selectableColumns.some(
            (col) =>
              col.name === colname && col.frontend.visibility !== ALWAYS_SHOW
          )
        ) as StringKeyOf<TReportItem>[];
      }
    }
  );

  getActiveColumns = createSelector(
    this.getDisplayableColumns,
    this.getSelectedColumnNames,
    function (
      columns,
      activeColumnNames
    ): ReportColumnRenderInfo<TReportItem>[] {
      // These are the columns that are currently displayed.
      const activeColumns = columns.filter(
        (col) =>
          col.frontend.visibility === ALWAYS_SHOW ||
          activeColumnNames.includes(col.name)
      );

      // We'll also do some calculations here that would otherwise need to
      // be repeated on rendering each row.
      return activeColumns.map((column) => {
        const { frontend, backend } = column;
        let derivedAccessor: (
          row: TReportItem,
          rowIdx: number
        ) => React.ReactNode;
        if (frontend.accessor) {
          derivedAccessor = frontend.accessor;
        } else {
          if (backend) {
            derivedAccessor = (row) => lodashGet(row, backend.field_name);
          } else {
            const backendFieldName = getColBackendField(frontend);
            if (backendFieldName) {
              derivedAccessor = (row) => lodashGet(row, backendFieldName);
            } else {
              derivedAccessor = () => <>&nbsp;</>;
            }
          }
        }
        return {
          ...column,
          derivedAccessor,
        };
      });
    }
  );

  render() {
    const routeProps = {
      history: this.props.history,
      location: this.props.location,
      match: this.props.match,
    };

    // See whether we need to display a placeholder rather than the table
    // body.
    let alternateTableBody: false | React.ReactNode = false;

    if (this.props.errorMessage) {
      alternateTableBody = <AlertDanger>{this.props.errorMessage}</AlertDanger>;
    } else if (this.props.isLoading) {
      alternateTableBody = <Loading />;
    } else if (
      this.props.pagination?.received?.total === 0 ||
      (detectExportMode() && this.props.records?.length === 0)
    ) {
      alternateTableBody = this.props.msgNoMatches ? (
        this.props.msgNoMatches
      ) : (
        <Trans>No matches</Trans>
      );
    }
    if (alternateTableBody) {
      alternateTableBody = (
        <tbody>
          <tr>
            <td className="td-no-results" colSpan={999}>
              {alternateTableBody}
            </td>
          </tr>
        </tbody>
      );
    }

    let hasActiveFilters = this.props.hasActiveFilters;
    let onClearFilters = this.props.onClearFilters;
    if (this.props.filters) {
      const filters = this.props.filters.map((frontend) => {
        const backend =
          this.props.reportInfo &&
          this.props.reportInfo.filters.find(
            (backend) => backend.name === frontend.name
          );

        const hasValues = frontend.getBackendFilterFromUrl(routeProps) !== null;
        return {
          frontend,
          backend,
          hasValues,
        };
      });

      hasActiveFilters = filters.some((f) => f.hasValues);
      onClearFilters = () => {
        setQueryParams(
          routeProps,
          filters.reduce(
            (acc, filter) =>
              filter.frontend
                ? {
                    ...acc,
                    ...filter.frontend.getUrlParamFromFormVal({}),
                  }
                : acc,
            {}
          )
        );
      };
    }

    const shouldShowFilterCount = Boolean(
      hasActiveFilters && !this.props.errorMessage
    );
    const filterMsg = (() => {
      if (!shouldShowFilterCount) {
        return null;
      }

      if (this.props.pagination?.received?.total === null) {
        return this.props.msgTooManyMatches!(PAGINATION_COUNT_LIMIT);
      }

      if (typeof this.props.msgFilterCount === 'function') {
        return this.props.msgFilterCount(
          this.props.pagination?.received?.total ?? 0
        );
      }

      return this.props.msgFilterCount;
    })();
    const shouldShowColumnSelector = Boolean(
      this.getSelectableColumns(this.props).length > 0
    );
    const activeColumns = this.getActiveColumns(this.props);
    const getTrProps = this.props.getTrProps
      ? this.props.getTrProps
      : () => ({});

    let tableContent = null;
    if (this.props.records && this.props.records.length > 0) {
      tableContent = (
        <tbody>
          {this.props.records.map((record, rowIdx) => (
            <tr key={rowIdx} {...getTrProps(record, rowIdx)}>
              {this.props.onBulkSelect && (
                <td>
                  <input
                    type="checkbox"
                    disabled={
                      this.props.isLoading || !this.props.records?.length
                    }
                    checked={this.state.bulkSelectedRows.has(rowIdx)}
                    onChange={(e) => {
                      let newSelection = new Set<number>(
                        this.state.bulkSelectedRows
                      );
                      if ((e.target as HTMLInputElement).checked) {
                        newSelection.add(rowIdx);
                      } else {
                        newSelection.delete(rowIdx);
                      }
                      this.setState({ bulkSelectedRows: newSelection }, () =>
                        this.props.onBulkSelect?.(Array.from(newSelection))
                      );
                    }}
                  />
                </td>
              )}
              {activeColumns.map((column) => (
                <td
                  key={column.name}
                  className={classNames(
                    column.frontend.className,
                    column.frontend.tdClassName
                  )}
                >
                  {column.derivedAccessor(record, rowIdx)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      );
    } else if (
      this.props.pagination?.received?.total === 0 ||
      !this.props.pagination ||
      !this.props.pagination.received ||
      this.props.errorMessage
    ) {
      tableContent = alternateTableBody;
    }

    return (
      <>
        {this.props.isExportMode ? null : (
          <>
            {shouldShowFilterCount && (
              <ActionBlock className="filtered-table-result-count">
                {<p>{filterMsg}</p>}
                <Button onClick={onClearFilters}>
                  <Trans>Clear filters</Trans>
                </Button>
              </ActionBlock>
            )}
            <ActionBlock className="columns-fluid">
              {shouldShowColumnSelector && (
                <ReportColumnsMenu
                  activeColumns={this.getSelectedColumnNames(this.props)}
                  columns={this.getSelectableColumns(this.props)}
                  {...routeProps}
                />
              )}
              <ListPager pagination={this.props.pagination} />
            </ActionBlock>
          </>
        )}
        <div
          className="filtered-table-container"
          data-is-loading={this.props.isLoading ? 'true' : 'false'}
          data-is-error={this.props.errorMessage ? 'true' : 'false'}
        >
          {this.props.renderTable ? (
            // Legacy or "escape hatch" API, if they provide a "render()"
            // function, we call that and let it take care of rendering the
            // table.
            this.props.renderTable({ alternateTableBody, activeColumns })
          ) : (
            // Otherwise, we draw the table ourselves.
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    {this.props.onBulkSelect && (
                      <th>
                        <input
                          type="checkbox"
                          disabled={
                            this.props.isLoading || !this.props.records?.length
                          }
                          checked={Boolean(
                            this.props.records?.length &&
                              this.state.bulkSelectedRows.size ===
                                this.props.records.length
                          )}
                          onChange={(e) => {
                            let newSelection: Set<number>;
                            if ((e.target as HTMLInputElement).checked) {
                              newSelection = new Set(
                                range(0, this.props.records?.length)
                              );
                            } else {
                              newSelection = new Set();
                            }
                            this.setState(
                              { bulkSelectedRows: newSelection },
                              () =>
                                this.props.onBulkSelect?.(
                                  Array.from(this.state.bulkSelectedRows)
                                )
                            );
                          }}
                        />
                      </th>
                    )}
                    {activeColumns.map((column) => (
                      <ReportTableTh
                        id={`table-column-${column.name}`}
                        key={column.name}
                        column={column}
                        defaultSorting={
                          this.props.defaultSortColumn ||
                          (this.props.reportInfo
                            ? this.props.reportInfo.default_sorting[0]
                            : '')
                        }
                        className={column.frontend.className}
                      >
                        {column.frontend.label}
                      </ReportTableTh>
                    ))}
                  </tr>
                </thead>
                {tableContent}
              </table>
            </div>
          )}
        </div>
        <ListPager pagination={this.props.pagination} />
      </>
    );
  }
}

/**
 * Wrap `ReportTableInner` in a parent component that provides the
 * "isExportMode" value (to allow for testing with different values of
 * "isExportMode")
 *
 * @param props
 */
export function ReportTable<TReportItem>(props: ReportTableProps<TReportItem>) {
  return (
    <Route
      render={(routeProps) => (
        <ReportTableInner
          isExportMode={detectExportMode()}
          {...routeProps}
          {...props}
        />
      )}
    />
  );
}
ReportTable.defaultProps = {
  msgFilterCount: (count: number) => (
    <Plural
      value={count}
      one="Filtered to 1 record"
      other="Filtered to # records"
    />
  ),
  msgNoMatches: <Trans>No records match the selected filters.</Trans>,
  msgTooManyMatches: (count: number) => (
    <Trans>
      Filtered to <NumberFormat value={count} />+ records
    </Trans>
  ),
};
ReportTable.WrappedComponent = ReportTableInner;
