import React from 'react';
import { NewDataLoggerView } from './newdataloggerview';
import { Trans } from '@lingui/macro';
import { connect, ResolveThunks } from 'react-redux';
import { AlertSuccess } from 'components/base/alert/alert';
import { createDataLogger } from 'ducks/data-logger/detail';
import {
  EntityTypes,
  fetchEntityList,
  selectAllInOrderedArray,
} from 'ducks/entities';
import { RouteComponentProps } from 'react-router';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import {
  DataLoggerFormValues,
  formatDataLoggerForBackend,
} from '../dataloggerformbody';
import { FormikHelpers } from 'formik';
import { DRFError, getExpectedFields } from 'util/backendapi/error';
import { showErrorsInFormik } from 'util/backendapi/error-formik';

type OwnProps = RouteComponentProps & {
  previousPath: string;
};
type StateProps = {
  areas: Array<Model.AreaDecorated>;
  isCreating: boolean;
  errorsCreating: null | string | DRFError<Model.DataLogger>;
  dataLogger: null | Model.DataLoggerDecorated;
  nullReadingObsPoint: null | Model.ObservationPointDecorated;
};
const mapDispatchToProps = {
  createDataLogger,
  fetchEntityList,
};

type DispatchProps = typeof mapDispatchToProps;
type MyProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class InnerNewDataLoggerScreen extends React.Component<MyProps> {
  _isMounted: boolean = false;

  static mapStateToProps(state: FullState): StateProps {
    return {
      areas: selectAllInOrderedArray(state, EntityTypes.AREA),
      isCreating: state.dataLogger.detail.isCreating,
      errorsCreating: state.dataLogger.detail.errorsCreating,
      dataLogger: state.dataLogger.detail.dataLogger,
      nullReadingObsPoint: state.dataLogger.detail.nullReadingObsPoint,
    };
  }

  createNewLogger = async (
    values: DataLoggerFormValues,
    formik: FormikHelpers<DataLoggerFormValues>
  ) => {
    await this.props.createDataLogger(formatDataLoggerForBackend(values));

    if (!this._isMounted) {
      return;
    }
    formik.setSubmitting(false);

    if (this.props.errorsCreating) {
      showErrorsInFormik(
        formik,
        this.props.errorsCreating,
        getExpectedFields(values)
      );
    } else {
      formik.setStatus(
        <AlertSuccess>
          <Trans>Data logger successfully created.</Trans>
        </AlertSuccess>
      );
    }
  };

  handleCancel = (evt: React.SyntheticEvent) => {
    evt.preventDefault();

    if (this.props.previousPath !== '/data-loggers/new') {
      this.props.history.goBack();
    } else {
      this.props.history.push('/dashboard');
    }
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.fetchEntityList(EntityTypes.AREA);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <NewDataLoggerView
        areas={this.props.areas}
        createNewLogger={this.createNewLogger}
        handleCancel={this.handleCancel}
      />
    );
  }
}

export const NewDataLoggerScreen = connect(
  InnerNewDataLoggerScreen.mapStateToProps,
  mapDispatchToProps
)(InnerNewDataLoggerScreen);
