import React from 'react';
import {
  DateField,
  DateFieldValue,
} from 'components/base/form/datefield/datefield';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import {
  convertDateToDatetime,
  formatDateForDisplay,
  formatDatetimeForBackendUrl,
} from 'util/dates';
import { parseQueryParamFromRouterProps } from 'util/routing';
import { FilterRenderingInfo, ReportFilter } from '../../report-types';
import { NO_TIMEZONE } from './FilterDate';

export function reportFilterDateNoMenu(
  frontendName: string,
  backendFieldName: string,
  label: React.ReactNode,
  timeZone: string | typeof NO_TIMEZONE | null,
  timeOfDay: string
): ReportFilter {
  // Apply no timezone conversion to the user's input, because the backend will
  // use it for a multi-timezone filter (called `DamTimeFilter` on the backend)
  if (timeZone === NO_TIMEZONE) {
    // ... to avoid any timezone conversion, we pretend the datetime is already in UTC.
    timeZone = 'Etc/UTC';
  }

  return {
    label,
    name: frontendName,
    render: () => (
      <>
        <DateField name={frontendName} />
        <FieldError name={frontendName} />
      </>
    ),
    renderSSR: (info: FilterRenderingInfo) =>
      info.hasValues && formatDateForDisplay(info.value),
    getBackendFilterFromUrl: (routeProps) => {
      const value = parseQueryParamFromRouterProps(
        routeProps,
        frontendName,
        null
      );
      if (value) {
        return {
          [backendFieldName]: formatDatetimeForBackendUrl(
            convertDateToDatetime(value, timeZone, timeOfDay)
          ),
        };
      } else {
        return null;
      }
    },
    getFormValFromUrlParam: (routeProps) =>
      parseQueryParamFromRouterProps(routeProps, frontendName, ''),
    getUrlParamFromFormVal: (formValues) => {
      const value: DateFieldValue = formValues[frontendName] || null;
      return {
        [frontendName]: value,
      };
    },
  };
}
