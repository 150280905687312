import React, { useEffect } from 'react';
import {
  DataLoggerChannelsView,
  dataLoggerChannelsColumns,
  dataLoggerChannelFilters,
} from './DataLoggerChannelsView';
import { useReportState } from 'hooks/use-report-state';
import {
  RCPWithQueryParams,
  parseNumberQueryParamFromRouterProps,
  setQueryParams,
} from 'util/routing';
import { dataLoggerChannelsDuck } from 'ducks/data-logger/channels';
import { useSelector, useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { fetchDataLogger } from 'ducks/data-logger/detail';
import { usePrevious } from 'util/hooks';

interface Props
  extends RCPWithQueryParams<{
    data_logger?: number;
    channel_number__in?: number[];
    observation_point__in?: number[];
    active_at_datetime?: string;
    now?: boolean;
  }> {}
export function DataLoggerChannelsScreen(props: Props) {
  // We need to get a copy of the selected data logger, because we
  // need it to determine the options in the "channel numbers" menu.
  const loggerNumber = parseNumberQueryParamFromRouterProps(
    props,
    'data_logger'
  );

  const storedDataLogger = useSelector(
    (state: FullState) => state.dataLogger.detail.dataLogger
  );
  const isLoadingDataLogger = useSelector(
    (state: FullState) => state.dataLogger.detail.loading
  );

  // Only use the data logger from the store if it matches the selected id
  const dataLogger =
    loggerNumber &&
    storedDataLogger &&
    loggerNumber === storedDataLogger.logger_number
      ? storedDataLogger
      : null;

  // Fetch the data logger if we don't already have it.
  const dispatch = useDispatch();

  const previousLoggerNumber = usePrevious(loggerNumber);

  useEffect(() => {
    // Because we only populate `dataLogger` when it matches `dataLoggerId`,
    // this should trigger a re-fetch only when dataLoggerId changes
    if (loggerNumber && !dataLogger) {
      dispatch(fetchDataLogger(loggerNumber));
    }

    if (
      previousLoggerNumber &&
      loggerNumber &&
      loggerNumber !== previousLoggerNumber
    ) {
      setQueryParams(props, {
        channel_number: null,
        observation_point: null,
        now: null,
        active_at_datetime: null,
      });
    }
  }, [dataLogger, loggerNumber, previousLoggerNumber, dispatch, props]);

  const columns = dataLoggerChannelsColumns();
  const filters = dataLogger ? dataLoggerChannelFilters(dataLogger) : [];
  const reportProps = useReportState(
    props,
    columns,
    filters,
    dataLoggerChannelsDuck,
    (state) => state.dataLogger.channels
  );
  return (
    <DataLoggerChannelsView
      reportProps={{
        ...reportProps,
        isLoading: reportProps.isLoading || isLoadingDataLogger,
      }}
      dataLogger={dataLogger}
    />
  );
}
