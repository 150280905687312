import React from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import {
  ReportColumn,
  DEFAULT_SHOW,
  DEFAULT_HIDE,
  ACTION_COLUMN,
  ReportFilter,
} from 'components/modules/report/report-types';
import {
  formatDatetimeForDisplay,
  THE_BEGINNING_OF_TIME,
  START_OF_DAY,
  END_OF_DAY,
  convertDateToDatetime,
  formatDatetimeForBackendUrl,
  formatDatetimeForStorage,
} from 'util/dates';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { rangeInclusive } from 'util/misc';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  parseNumberQueryParamFromRouterProps,
  parseQueryParamFromRouterProps,
} from 'util/routing';
import { ReportStateProps } from 'hooks/use-report-state';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { EditChannelAllocationModal } from './EditChannelAllocationModal';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import Button from 'components/base/button/button';
import './DataLoggerChannelsView.scss';
import { useSelector } from 'react-redux';
import { selectHasPermission } from 'util/user';
import { FullState } from 'main/reducers';
import { reportFilterActiveAt } from 'components/modules/report/filter/fields/reportFilterActiveAt';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';

export const dataLoggerChannelsColumns: (extraProps?: {
  canEditDataLoggerAllocation: boolean;
}) => ReportColumn<Model.ReportsDataLoggerChannel>[] = (extraProps) => [
  {
    label: <Trans>Data logger</Trans>,
    name: 'data_logger__logger_number',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Channel</Trans>,
    name: 'channel_number',
    visibility: DEFAULT_SHOW,
    accessor: ({ channel_number }) =>
      extraProps?.canEditDataLoggerAllocation ? (
        <Button
          key={`channel-btn-link-${channel_number}`}
          className="btn-link-panel btn-link-text"
          onClick={() => {
            const channelButtonId = `data-logger-channel-allocation-modal-button-edit-channel-${channel_number}`;
            const editChannelButton = document.getElementById(channelButtonId);
            if (editChannelButton) {
              editChannelButton.click();
            }
          }}
        >
          {channel_number}
        </Button>
      ) : (
        channel_number
      ),
  },
  {
    label: <Trans>Observation point</Trans>,
    name: 'observation_point__code',
    visibility: DEFAULT_SHOW,
    accessor: (row) =>
      row.observation_point__code ? (
        <a
          href={`/observation-point/${row.observation_point__code}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {row.observation_point__code}
        </a>
      ) : (
        <i>
          <Trans>not allocated</Trans>
        </i>
      ),
  },
  {
    label: <Trans>Formula</Trans>,
    name: 'observation_point_formula__formula__code',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Instrument type</Trans>,
    name: 'observation_point__instrument_type__code',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Channel start date and time</Trans>,
    name: 'start_datetime',
    visibility: DEFAULT_SHOW,
    additionalFields: ['data_logger__site__area__time_zone__name'],
    hideFieldsFromCSV: ['data_logger__site__area__time_zone__name'],
    accessor: (row) =>
      row.start_datetime &&
      row.data_logger__site__area__time_zone__name &&
      row.start_datetime !== THE_BEGINNING_OF_TIME &&
      formatDatetimeForDisplay(
        row.start_datetime,
        row.data_logger__site__area__time_zone__name
      ),
  },
  {
    label: <Trans>C1</Trans>,
    name: 'c1',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C2</Trans>,
    name: 'c2',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C3</Trans>,
    name: 'c3',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C4</Trans>,
    name: 'c4',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C5</Trans>,
    name: 'c5',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C6</Trans>,
    name: 'c6',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C7</Trans>,
    name: 'c7',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C8</Trans>,
    name: 'c8',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C9</Trans>,
    name: 'c9',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>C10</Trans>,
    name: 'c10',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Temperature comp. channel</Trans>,
    name: 'temperature_compensation_channel',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Temperature comp. obs pt</Trans>,
    name: 'temperature_compensation_obs_point',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Pressure comp. channel</Trans>,
    name: 'pressure_compensation_channel',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Pressure comp. obs pt</Trans>,
    name: 'pressure_compensation_obs_point',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Serial number</Trans>,
    name: 'serial_number',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Status</Trans>,
    name: 'status',
    visibility: DEFAULT_SHOW,
    accessor: (row) => (row.status ? row.status.charAt(0).toUpperCase() : ''),
  },
  {
    label: <Trans>Change date and time</Trans>,
    name: 'change_datetime',
    visibility: DEFAULT_HIDE,
    additionalFields: ['data_logger__site__area__time_zone__name'],
    hideFieldsFromCSV: ['data_logger__site__area__time_zone__name'],
    accessor: (row) =>
      !row.change_datetime
        ? 'N/A'
        : row.change_datetime !== THE_BEGINNING_OF_TIME &&
          row.data_logger__site__area__time_zone__name &&
          formatDatetimeForDisplay(
            row.change_datetime,
            row.data_logger__site__area__time_zone__name
          ),
  },
  {
    ...ACTION_COLUMN,
    additionalFields: [
      'id',
      'channel_number',
      'data_logger',
      'data_logger__site__area__time_zone__name',
      'observation_point',
      'start_datetime',
      'serial_number',
    ],
    accessor: (
      row: Pick<
        Model.ReportsDataLoggerChannel,
        | 'id'
        | 'channel_number'
        | 'data_logger'
        | 'data_logger__site__area__time_zone__name'
        | 'observation_point'
        | 'start_datetime'
        | 'status'
        | 'serial_number'
      >
    ) => (
      <HasPermission check="can_create_data_loggers">
        <ButtonShowModal
          iconOnly
          iconType="icon-edit"
          title={t`Edit`}
          className="btn-link-panel"
          name={`edit-channel-${row.channel_number}`}
          modalContent={(modalProps) => (
            <EditChannelAllocationModal {...modalProps} row={row} />
          )}
        />
      </HasPermission>
    ),
  },
];

export function dataLoggerChannelFilters(
  dataLogger: null | Model.DataLoggerDecorated
): ReportFilter[] {
  return [
    // NOTE: This filter uses the options list from the "data_logger" (id) filter
    // on the backend, but it actually needs to filter by logger NUMBER not ID.
    // It does this via a custom `getBackendFilterFromUrl()` method.
    {
      ...reportFilterMenu(
        'data_logger',
        <Trans>Data logger</Trans>,
        { isMulti: false, valueType: 'number' },
        (option: Model.DataLogger) => ({
          value: option.logger_number,
          label: option.logger_number,
        }),
        {
          autoFocus: true,
        }
      ),
      getBackendFilterFromUrl(routeProps) {
        const val = parseNumberQueryParamFromRouterProps(
          routeProps,
          'data_logger',
          null
        );
        if (val === null) {
          return null;
        } else {
          return {
            data_logger__logger_number: val,
          };
        }
      },
    },
    {
      ...reportFilterMenu(
        'channel_number',
        <Trans>Channel</Trans>,
        { isMulti: true, valueType: 'number' },
        ReportFilterMenu.PASS_MENU_THROUGH,
        {
          isDisabled: !dataLogger,
          options: dataLogger
            ? rangeInclusive(1, dataLogger.number_of_channels).map((i) => ({
                value: i,
                label: i,
              }))
            : [],
        }
      ),
      renderSSR: (info) => {
        return info.value.join(',');
      },
    },
    reportFilterObservationPoints('observation_point', {
      isDisabled: !dataLogger,
    }),
    {
      ...reportFilterActiveAt(
        'active_at_datetime',
        <Trans>Date</Trans>,
        dataLogger ? dataLogger.site.area.time_zone.name : ''
      ),
      getBackendFilterFromUrl: (routeProps, isForExportUrl) => {
        const nowQueryParam = parseQueryParamFromRouterProps(routeProps, 'now');
        const now = nowQueryParam === '' ? true : nowQueryParam === 'true';

        if (now) {
          const backendFieldName = 'active_at_datetime';

          if (isForExportUrl) {
            return {
              active_at_datetime: new Date().toISOString(),
            };
          }

          // Return a fixed version rather than a dynamic version of "now"
          // otherwise, we'll enter an endless render cycle
          // it'll be convert to actual "now" value in the duck

          return {
            [backendFieldName]: 'now',
          };
        }

        const date = parseQueryParamFromRouterProps(
          routeProps,
          'active_at_datetime'
        );
        const type = parseQueryParamFromRouterProps(
          routeProps,
          `active_at_datetime_type`,
          'is'
        );

        if (date) {
          const typeFieldName =
            type === 'is_before'
              ? 'start_datetime_before'
              : type === 'is_after'
              ? 'start_datetime_after'
              : 'active_at_datetime';

          const timeOfDay = type === 'is_after' ? START_OF_DAY : END_OF_DAY;

          const datetime = convertDateToDatetime(
            date,
            dataLogger?.site.area.time_zone.name,
            timeOfDay
          );

          // active_at_datetime requires a different format
          // than the before and after filters
          const isoDatetime =
            typeFieldName === 'active_at_datetime'
              ? formatDatetimeForStorage(datetime)
              : formatDatetimeForBackendUrl(datetime);

          return {
            [typeFieldName]: isoDatetime,
          };
        }

        return null;
      },
    },
    reportFilterMenu(
      'observation_point_formula__formula',
      <Trans>Formula</Trans>,
      { isMulti: true, valueType: 'number' },
      ReportFilterMenu.CODE_AND_NAME_MENU,
      { placeholder: <Trans>All formulas</Trans> }
    ),
    reportFilterMenu(
      'observation_point__instrument_type',
      <Trans>Instrument type</Trans>,
      { isMulti: true, valueType: 'number' },
      ReportFilterMenu.CODE_AND_NAME_MENU,
      { placeholder: <Trans>All instrument types</Trans> }
    ),
    reportFilterMenu(
      'status',
      <Trans>Status</Trans>,
      { isMulti: true, valueType: 'string' },
      ReportFilterMenu.ENUM_MENU('DataLoggerChannel_STATUS'),
      {
        placeholder: <Trans>All statuses</Trans>,
        options: Object.values(Enum.DataLoggerChannel_STATUS),
      }
    ),
  ];
}

export interface DataLoggerChannelsViewProps {
  reportProps: ReportStateProps<Model.ReportsDataLoggerChannel>;
  dataLogger: Model.DataLoggerDecorated | null;
}

export function DataLoggerChannelsView(props: DataLoggerChannelsViewProps) {
  const { reportProps } = props;

  const canEditDataLoggerAllocation = useSelector((state: FullState): boolean =>
    selectHasPermission(state, Enum.User_PERMISSION.can_create_data_loggers)
  );

  const columns = dataLoggerChannelsColumns({ canEditDataLoggerAllocation });
  const filters = dataLoggerChannelFilters(props.dataLogger);

  return (
    <PageStandard
      name="data-logger-channel-allocation"
      header={<Trans>Logger Calibration</Trans>}
    >
      <div className="page-content-header-filters-actions">
        <ReportFiltersBlock
          filtersFrontend={filters}
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          isExportMode={reportProps.isExportMode}
          filtersToShow={[
            'data_logger',
            'channel_number',
            'observation_point',
            'active_at_datetime',
          ]}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Logger calibration report`}
            reportUrl="/reports/data-logger-channels/"
            pdfExtraParams={{
              dataLogger: props.dataLogger,
            }}
            columns={columns}
            filters={filters}
          />
          <AdvancedFiltersModalButton
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={filters}
            sections={[
              {
                name: 'menu_section',
                filters: [
                  'observation_point_formula__formula',
                  'observation_point__instrument_type',
                  'status',
                ],
              },
            ]}
          />
        </ActionBlock>
        <ReportTable
          columns={columns}
          filters={filters}
          reportInfo={reportProps.reportInfo}
          errorMessage={
            !reportProps.isLoading && !props.dataLogger ? (
              <Trans>Please select a data logger</Trans>
            ) : (
              reportProps.errorMessage
            )
          }
          isLoading={reportProps.isLoading}
          records={reportProps.records}
          pagination={reportProps.pagination}
        />
      </div>
    </PageStandard>
  );
}
