import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import ModalContent from 'components/base/modal/modalcontent';
import { Form, Formik } from 'formik';
import { getExpectedFields } from 'util/backendapi/error';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { ButtonPrimary } from 'components/base/button/button';
import { PerformanceIndicatorResponseDecorated } from 'util/backendapi/types/Model';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import './EditPerformanceIndicatorsModal.scss';
import { PerformanceIndicator_RESPONSE } from 'util/backendapi/types/Enum';
import { FormItem, FormSection } from 'components/base/form/FormItem';
import {
  RadioField,
  RadioFieldOption,
} from 'components/base/form/radio-field/RadioField';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { postApi } from 'util/backendapi/fetch';

interface ModalProps {
  readingId: number;
  observationPointCode: string;
  performanceIndicatorResponses: PerformanceIndicatorResponseDecorated[];
  onAfterSubmit: () => void;
  hideModal: () => void;
}

type ButtonProps = Omit<ModalProps, 'hideModal'>;

export function EditPerformanceIndicatorResponsesButton(props: ButtonProps) {
  return (
    <ButtonShowModal
      name="editPerformanceIndicatorResponsesModal"
      iconOnly
      className="btn-link-panel performance-indicator-responses-edit-button"
      iconType="icon-edit"
      modalContent={(modalProps) => (
        <EditPerformanceIndicatorResponsesModal {...modalProps} {...props} />
      )}
    >
      <Trans>Edit performance indicator responses</Trans>
    </ButtonShowModal>
  );
}

type FormValues = {
  performance_indicator_responses: {
    performance_indicator: number;
    performance_indicator_name: string;
    response: PerformanceIndicator_RESPONSE | '';
  }[];
};

const performanceIndicatorResponseOptions: RadioFieldOption<PerformanceIndicator_RESPONSE>[] =
  [
    {
      label: (
        <TransEnum
          enum="PerformanceIndicator_RESPONSE"
          value={PerformanceIndicator_RESPONSE.NOT_OBSERVED}
        />
      ),
      value: PerformanceIndicator_RESPONSE.NOT_OBSERVED,
    },
    {
      label: (
        <TransEnum
          enum="PerformanceIndicator_RESPONSE"
          value={PerformanceIndicator_RESPONSE.OBSERVED}
        />
      ),
      value: PerformanceIndicator_RESPONSE.OBSERVED,
    },
    {
      label: <Trans>Issue</Trans>,
      value: PerformanceIndicator_RESPONSE.ISSUE,
    },
  ];

function EditPerformanceIndicatorResponsesModal(props: ModalProps) {
  const {
    readingId,
    observationPointCode,
    performanceIndicatorResponses,
    hideModal,
    onAfterSubmit,
  } = props;

  const initialValues = useMemo(() => {
    return {
      performance_indicator_responses: performanceIndicatorResponses.map(
        (p) => ({
          performance_indicator: p.performance_indicator.id,
          performance_indicator_name: p.performance_indicator.name,
          response: p.response,
        })
      ),
    };
  }, [performanceIndicatorResponses]);

  return (
    <ModalContent
      className="edit-performance-indicator-responses-modal"
      header={
        <>
          <Trans>Performance indicators:</Trans> {observationPointCode}
        </>
      }
    >
      <Formik<FormValues>
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          try {
            await postApi(
              `/readings/${readingId}/performance-indicator-responses/`,
              {
                performance_indicator_responses:
                  values.performance_indicator_responses.map((p) => ({
                    performance_indicator: p.performance_indicator,
                    response: p.response,
                  })),
              }
            );
            onAfterSubmit();
            hideModal();
          } catch (e) {
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, getExpectedFields(initialValues));
          }
        }}
      >
        {(formik) => (
          <Form>
            {formik.status}
            {performanceIndicatorResponses.map((p, idx) => (
              <FormItem
                key={idx}
                label={p.performance_indicator.name}
                fieldId={`performance-indicator-response-label-${idx}`}
              >
                <FormSection className="radio-fieldset-toggle">
                  <RadioField
                    className="performance-indicator-response-option"
                    name={`performance_indicator_responses.${idx}.response`}
                    options={performanceIndicatorResponseOptions}
                  />
                </FormSection>
              </FormItem>
            ))}
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                data-testid="upload-media-submit"
                type="submit"
                disabled={formik.isSubmitting}
                iconType="icon-save"
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
