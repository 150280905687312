import React from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import {
  ReadingCommentsListView,
  readingCommentsListColumns,
  readingCommentsListFilters,
} from './ReadingCommentsListView';
import { readingCommentListDuck } from 'ducks/comments/list/list';

type OwnProps = RCPWithQueryParams<Filter.ReportsComments>;

export const ReadingCommentsListScreen = (ownProps: OwnProps) => {
  const reportProps = useReportState(
    ownProps,
    readingCommentsListColumns,
    readingCommentsListFilters,
    readingCommentListDuck,
    (state) => state.comments.readingComments
  );

  const props = {
    reportProps,
  };

  return <ReadingCommentsListView {...props} />;
};
