import { fetchJsonEndpoint } from '../fetch';

async function fetchAll(filter = {}) {
  let sites = {
    error: false,
  };

  let queryString = '';

  if (filter['areaCode']) {
    queryString = `?area__code=${encodeURIComponent(filter['areaCode'])}`;
  } else if (filter['siteCode']) {
    queryString = `?code=${encodeURIComponent(filter['siteCode'])}`;
  }

  try {
    sites.data = await fetchJsonEndpoint(`/sites-list/${queryString}`);
  } catch (e) {
    sites.error = true;
    sites.errorMessage = e;
  }
  return sites;
}

function fromEndpointFormat(site) {
  return {
    id: Number(site.id),
    code: String(site.code),
    areaId: Number(site.area),
  };
}

function generateMock(seed = 1, frontendFormat = true) {
  const mockItem = {
    id: seed,
    code: `SITE ${seed}`,
    name: `Site ${seed}`,
    area: seed,
  };
  if (frontendFormat) {
    return fromEndpointFormat(mockItem);
  } else {
    return mockItem;
  }
}

export const SiteList = {
  fetchAll,
  fromEndpointFormat,
  generateMock,
};
