import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './alert.scss';

const alertLevels = {
  DEFAULT: 'alert',
  SUCCESS: 'alert alert-success',
  DANGER: 'alert alert-danger',
  WARNING: 'alert alert-warning',
  INFO: 'alert alert-info',
};

/**
 * An "alert" displayed in a box at or near the top of a screen
 *
 * @export
 * @class Alert
 * @extends {React.Component}
 */
export class Alert extends React.Component {
  static propTypes = {
    testid: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    additionalInformation: PropTypes.node,
    level: PropTypes.oneOf(Object.values(alertLevels)),
  };

  static defaultProps = {
    level: alertLevels.DEFAULT,
  };

  static LEVELS = alertLevels;

  render() {
    return (
      <div
        data-testid={this.props.testid}
        className={classNames(this.props.level, this.props.className)}
        role="alert"
      >
        <p className="alert-header">{this.props.children}</p>
        {this.props.additionalInformation ? (
          <div className="alert-content">
            {this.props.additionalInformation}
          </div>
        ) : null}
      </div>
    );
  }
}

const alertSubclassProptypes = {
  children: PropTypes.node,
  additionalInformation: PropTypes.node,
  testid: PropTypes.string,
  className: PropTypes.string,
};

export function AlertSuccess(props) {
  return <Alert level={alertLevels.SUCCESS} {...props} />;
}
AlertSuccess.propTypes = alertSubclassProptypes;

export function AlertDanger(props) {
  return <Alert level={alertLevels.DANGER} {...props} />;
}
AlertDanger.propTypes = alertSubclassProptypes;

export function AlertWarning(props) {
  return <Alert level={alertLevels.WARNING} {...props} />;
}
AlertWarning.propTypes = alertSubclassProptypes;

export function AlertInfo(props) {
  return <Alert level={alertLevels.INFO} {...props} />;
}
AlertInfo.propTypes = alertSubclassProptypes;
