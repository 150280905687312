import React from 'react';
import { t, Trans, Plural } from '@lingui/macro';
import { Icon } from 'components/base/icon/icon';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Model } from 'util/backendapi/models/api.interfaces';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import CreateObsPointPanel from './createobspoint/createobspoint';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import ActionBlock from 'components/base/actionblock/actionblock';
import { formatDatetimeForDisplay, formatDateForDisplay } from 'util/dates';
import {
  ReportColumn,
  ReportFilter,
  DEFAULT_SHOW,
  DEFAULT_HIDE,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import {
  reportFilterDate,
  reportFilterDatetime,
  NO_TIMEZONE,
} from 'components/modules/report/filter/fields/FilterDate';
import { reportFilterBoolean } from 'components/modules/report/filter/fields/FilterBoolean';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { ReportStateProps } from 'hooks/use-report-state';
import { reportFilterSites } from 'components/modules/report/filter/fields/reportFilterSites';
import { DMSLink } from 'components/base/link/DMSLink';
import { ObsPointBulkCreateModal } from './ObsPointBulkCreateModal';

export const maintObsPointListColumns = (extraProps?: {
  refreshList: () => void;
  deleteObsPoint: (obsPointId: number) => Promise<void>;
}): ReportColumn<Model.ReportsObservationPoint>[] => {
  return [
    {
      label: <Trans>Observation point</Trans>,
      name: 'code',
      visibility: DEFAULT_SHOW,
      // we need this `setup_complete` additional field here because when exporting to PDF
      // the action column's additional fields are not requested
      additionalFields: ['setup_complete'],
      accessor: (row) => {
        const link = row.setup_complete
          ? `/observation-point/${row.code}`
          : `/observation-point-setup/${row.code}`;
        return <DMSLink to={link}>{row.code}</DMSLink>;
      },
    },
    {
      label: <Trans>Name</Trans>,
      name: 'name',
      visibility: DEFAULT_HIDE,
      backendFieldName: 'name',
      accessor: ({ name }) => name,
    },
    {
      label: <Trans>Classification</Trans>,
      name: 'classification',
      visibility: DEFAULT_HIDE,
      backendFieldName: 'classification__code',
      additionalFields: ['classification__name'],
      accessor: (row) =>
        row.classification__code
          ? `${row.classification__code} - ${row.classification__name}`
          : '',
    },
    {
      label: <Trans>Site</Trans>,
      name: 'site',
      visibility: DEFAULT_HIDE,
      backendFieldName: 'site__code',
    },
    {
      label: <Trans>Area</Trans>,
      name: 'area',
      visibility: DEFAULT_SHOW,
      backendFieldName: 'site__area__code',
      additionalFields: ['site__area__name'],
      accessor: (row) => `${row.site__area__code} - ${row.site__area__name}`,
    },
    {
      label: <Trans>Civil feature</Trans>,
      name: 'civil_feature',
      backendFieldName: 'civil_feature__name',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Instrument Type</Trans>,
      name: 'instrument_type',
      visibility: DEFAULT_SHOW,
      backendFieldName: 'instrument_type__code',
      additionalFields: ['instrument_type__name'],
      accessor: (row) =>
        `${row.instrument_type__code} - ${row.instrument_type__name}`,
    },
    {
      label: <Trans>Instrument reliability</Trans>,
      name: 'instrument_reliability',
      visibility: DEFAULT_HIDE,
      backendFieldName: 'reliability__code',
      additionalFields: ['reliability__name'],
      hideFieldsFromCSV: ['reliability__name'],
      accessor: (row) => {
        if (!row.reliability__code) return '';
        let reliability = `${row.reliability__code} - ${row.reliability__name}`;
        return reliability;
      },
    },
    {
      label: <Trans>Instrument reliability - reason for change</Trans>,
      name: 'reliability__change_reason',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Maintenance required</Trans>,
      name: 'maintenance_required',
      visibility: DEFAULT_HIDE,
      accessor: ({ maintenance_required }) =>
        maintenance_required === 'true' ? (
          <Trans>Yes</Trans>
        ) : (
          <Trans>No</Trans>
        ),
    },
    {
      label: <Trans>Reason maintenance is required</Trans>,
      name: 'maintenance_required_reason',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Monitored</Trans>,
      name: 'monitored',
      visibility: DEFAULT_HIDE,
      accessor: ({ monitored }) => (monitored ? 'Yes' : 'No'),
    },
    {
      label: <Trans>Cap RL</Trans>,
      name: 'cap_rl',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Port RL</Trans>,
      name: 'port_rl',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Install Depth</Trans>,
      name: 'install_depth',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Install date</Trans>,
      name: 'install_date',
      accessor: (row) => formatDateForDisplay(row.install_date || ''),
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Earliest Reading</Trans>,
      name: 'earliest_reading',
      backendFieldName: 'earliest_reading__reading_datetime',
      visibility: DEFAULT_SHOW,
      additionalFields: ['site__area__time_zone__name'],
      accessor: ({
        earliest_reading__reading_datetime: value,
        site__area__time_zone__name: timezone,
      }) => formatDatetimeForDisplay(value, timezone),
    },
    {
      label: <Trans>Latest Reading</Trans>,
      name: 'latest_reading',
      backendFieldName: 'latest_reading__reading_datetime',
      visibility: DEFAULT_SHOW,
      additionalFields: ['site__area__time_zone__name'],
      accessor: ({
        latest_reading__reading_datetime: value,
        site__area__time_zone__name: timezone,
      }) => formatDatetimeForDisplay(value, timezone),
    },
    {
      label: <Trans>Next Reading</Trans>,
      name: 'next_reading',
      backendFieldName: 'next_reading_datetime',
      visibility: DEFAULT_SHOW,
      additionalFields: ['site__area__time_zone__name'],
      accessor: ({
        next_reading_datetime: value,
        site__area__time_zone__name: timezone,
      }) => formatDatetimeForDisplay(value, timezone),
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['id', 'code', 'setup_complete'],
      accessor: function actionCell({ id, code, setup_complete }) {
        const deleteButton = (
          <HasPermission check="can_delete_observation_points">
            <ButtonShowConfirmation
              name="delete-obs-point"
              title={t`Delete`}
              iconType="icon-circle-minus"
              className="btn-link-panel"
              content={
                <Trans>
                  Are you sure you want to delete <strong>{code}</strong>? This
                  action is not reversible.
                </Trans>
              }
              okBtnText={<Trans>Yes, delete</Trans>}
              onConfirm={async function () {
                await extraProps!.deleteObsPoint(id);
                extraProps!.refreshList();
              }}
            />
          </HasPermission>
        );
        if (setup_complete) {
          return (
            <>
              <DMSLink to={`/alarm-parameters/${code}`}>
                <Icon type="icon-bell" title={t`Alarm Parameters`} />
              </DMSLink>
              <DMSLink to={`/observation-point/${code}`}>
                <Icon type="icon-view" title={t`View`} />
              </DMSLink>
              <DMSLink to={`/quickplot/${code}`}>
                <Icon type="icon-plot" title={t`Plot`} />
              </DMSLink>
              <DMSLink to={`/quicklist/${code}`}>
                <Icon type="icon-list" title={t`List`} />
              </DMSLink>
              {deleteButton}
            </>
          );
        } else {
          return (
            <>
              <DMSLink to={`/observation-point-setup/${code}`}>
                <Icon type="icon-resume" title={t`Resume`} />
              </DMSLink>
              {deleteButton}
            </>
          );
        }
      },
    },
  ];
};

export const maintObsPointListFilters = (): ReportFilter[] => [
  reportFilterObservationPoints('id', {
    placeholder: <Trans>Find observation point</Trans>,
    autoFocus: true,
  }),
  reportFilterMenu(
    'classification',
    <Trans>Classification</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All classifications</Trans> }
  ),
  reportFilterSites('site', { placeholder: <Trans>All sites</Trans> }),
  reportFilterMenu(
    'site__area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All areas</Trans> }
  ),
  reportFilterMenu(
    'instrument_type',
    <Trans>Instrument type</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All instrument types</Trans> }
  ),
  reportFilterMenu(
    'reliability',
    <Trans>Instrument reliability</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All reliabilities</Trans> }
  ),
  reportFilterBoolean(
    'maintenance_required',
    <Trans>Maintenance required</Trans>
  ),
  reportFilterBoolean('monitored', <Trans>Monitored</Trans>),
  reportFilterBoolean(
    'has_alarm_parameters',
    <Trans>Has alarm parameters</Trans>
  ),
  reportFilterDate('install_date', <Trans>Install date</Trans>),
  reportFilterDatetime(
    'earliest_reading__reading_datetime',
    <Trans>First reading</Trans>,
    // Filtered using "dam time" timezone for each row, calculated by the back end
    NO_TIMEZONE
  ),
  reportFilterDatetime(
    'latest_reading__reading_datetime',
    <Trans>Latest reading</Trans>,
    // Filtered using "dam time" timezone for each row, calculated by the back end
    NO_TIMEZONE
  ),
];

export interface ObsPointListViewProps {
  reportProps: ReportStateProps<Model.ReportsObservationPoint>;
  deleteObsPoint: (id: number) => Promise<any>;
}

export const ObsPointListView: React.FunctionComponent<
  ObsPointListViewProps
> = (props) => {
  const filters = maintObsPointListFilters();
  const { reportProps } = props;
  return (
    <PageStandard
      name="maintObsPointLanding"
      header={
        reportProps.isExportMode ? (
          <Trans>Observation point report</Trans>
        ) : (
          <Trans>Maintain Observation Points</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <ActionBlock className="text-right">
          <HasPermission check="can_bulk_create_observation_points">
            <ButtonShowModal
              name="bulk-create-observation-points"
              modalContent={({ hideModal }) => (
                <ObsPointBulkCreateModal
                  onAfterSubmit={() => {
                    hideModal();
                  }}
                />
              )}
              iconType="icon-plus"
              title={t`Bulk create observation points`}
            >
              <Trans>Bulk create observation points</Trans>
            </ButtonShowModal>
          </HasPermission>
          <HasPermission check="can_create_observation_points">
            <ButtonShowModal
              name="create-observation-point"
              iconType="icon-plus"
              shortcut="CREATE_NEW"
              modalContent={({ hideModal }: any) => (
                <CreateObsPointPanel hideModal={hideModal} />
              )}
            >
              <Trans>Create observation point</Trans>
            </ButtonShowModal>
          </HasPermission>
        </ActionBlock>
        <ReportFiltersBlock
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          filtersFrontend={filters}
          filtersToShow={['id', 'site__area', 'instrument_type']}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <SaveReportModalButtons
            columnsFrontend={maintObsPointListColumns()}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Observation point report`}
            reportUrl="/reports/observation-points/"
            columns={maintObsPointListColumns()}
            filters={filters}
          />
          <AdvancedFiltersModalButton
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={filters}
            sections={[
              {
                name: 'menu_section',
                filters: [
                  'id',
                  'classification',
                  'site',
                  'site__area',
                  'instrument_type',
                  'reliability',
                  'maintenance_required',
                  'monitored',
                  'has_alarm_parameters',
                ],
              },
              {
                name: 'dates_section',
                filters: [
                  'install_date',
                  'earliest_reading__reading_datetime',
                  'latest_reading__reading_datetime',
                ],
              },
            ]}
          />
        </ActionBlock>
        <ReportTable
          columns={maintObsPointListColumns({
            refreshList: reportProps.refreshList,
            deleteObsPoint: props.deleteObsPoint,
          })}
          filters={filters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 observation point"
              other="Filtered to # observation points"
            />
          )}
          msgNoMatches={
            <Trans>No observation points match the selected filters.</Trans>
          }
          getTrProps={(row) => ({
            className: row.setup_complete
              ? undefined
              : 'table-row-warning table-row-icon',
          })}
        />
      </div>
    </PageStandard>
  );
};
