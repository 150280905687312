import React from 'react';
import { FormCardSection } from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { DateField } from 'components/base/form/datefield/datefield';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import {
  IntegerField,
  IntegerFieldValue,
} from 'components/base/form/integer-field/IntegerField';
import {
  StoredTimeSeriesPlotWithArea,
  StoredScatterPlotWithArea,
} from 'ducks/stored-plot/detail';
import {
  convertDatetimeToDate,
  parseIntervalFromString,
  formatIntervalForComputer,
  convertDateToDatetime,
  START_OF_DAY,
  END_OF_DAY,
} from 'util/dates';
import { FormikErrors } from 'formik';
import { validateDuration } from 'components/plots/validation';
import { Model } from 'util/backendapi/models/api.interfaces';

export interface StoredPlotTimePeriodFormValues {
  start_datetime: string;
  end_datetime: string;
  duration: IntegerFieldValue;
}

export function makeStoredPlotTimePeriodInitialValues(
  storedPlot: StoredTimeSeriesPlotWithArea | StoredScatterPlotWithArea
): StoredPlotTimePeriodFormValues {
  return {
    // HACK: start_datetime is actually a date (without time) on frontend
    // Keeping the name the same so backend errors will display properly.
    start_datetime: convertDatetimeToDate(
      storedPlot.start_datetime,
      storedPlot.area.time_zone.name
    ),
    // HACK: start_datetime is actually a date (without time) on frontend
    // Keeping the name the same so backend errors will display properly.
    end_datetime: convertDatetimeToDate(
      storedPlot.end_datetime,
      storedPlot.area.time_zone.name
    ),
    duration:
      storedPlot.duration === null
        ? ''
        : String(
            parseIntervalFromString(storedPlot.duration, 'months')
              .intervalNumber
          ),
  };
}

export function makeStoredPlotTimePeriodBackendValues(
  values: StoredPlotTimePeriodFormValues & { area: number },
  areaOptions: { value: number; label: string; timeZone: string }[]
): Pick<
  Model.StoredTimeSeriesPlot_POST,
  'duration' | 'start_datetime' | 'end_datetime'
> {
  const area = areaOptions.find((a) => a.value === values.area);
  const timeZone = area ? area.timeZone : undefined;

  return {
    duration:
      values.duration === ''
        ? null
        : formatIntervalForComputer(Number(values.duration), 'months'),
    // HACK: start_datetime is actually a date (without time) on frontend
    // Keeping the name the same so backend errors will display properly.
    start_datetime:
      convertDateToDatetime(values.start_datetime, timeZone, START_OF_DAY) ||
      null,
    // HACK: start_datetime is actually a date (without time) on frontend
    // Keeping the name the same so backend errors will display properly.
    end_datetime:
      convertDateToDatetime(values.end_datetime, timeZone, END_OF_DAY) || null,
  };
}

export function validateStoredPlotTimePeriod(
  values: StoredPlotTimePeriodFormValues
): FormikErrors<StoredPlotTimePeriodFormValues> {
  return validateDuration(values, 'duration', 'start_datetime', 'end_datetime');
}

export function StoredPlotTimePeriodSection(props: {}) {
  return (
    <FormCardSection
      name="timeperiod"
      header={<Trans>Time period</Trans>}
      fields={[
        {
          // HACK: The "start_datetime" field is actually displayed
          // as a date WITHOUT TIME. (Keeping the name so that
          // backend errors will line up properly.)
          name: 'start_datetime',
          label: <Trans>Start date</Trans>,
          content: (
            <>
              <DateField name="start_datetime" />
              <FieldError name="start_datetime" />
            </>
          ),
        },
        {
          // HACK: The "end_datetime" field is actually displayed
          // as a date WITHOUT TIME. (Keeping the name so that
          // backend errors will line up properly.)
          name: 'end_datetime',
          label: <Trans>End date</Trans>,
          content: (
            <>
              <DateField name="end_datetime" />
              <FieldError name="end_datetime" />
            </>
          ),
        },
        {
          name: 'duration',
          label: <Trans>Number of months</Trans>,
          content: (
            <>
              <IntegerField name="duration" />
              <FieldError name="duration" />
            </>
          ),
        },
      ]}
    />
  );
}
