import React from 'react';
import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';
import { Title } from 'react-head';
import NavMenu from './navmenu/navmenu';
import { PageNavHeader } from './PageNavHeader';

import './pagestandard.scss';

export interface PageStandardViewProps {
  name: string;
  // Page content
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  // Page layout header
  userPreferredName: string;
  userGroupsCount: number;
  handleClickLogout: (e: React.SyntheticEvent) => void;
  // Nav menu
  isNavMenuCollapsed: boolean;
  // For the info panel portal
  passPanelRef: (panelRef: React.RefObject<HTMLDivElement>) => void;
  // For the plot popup
  passPopupRef: (panelRef: React.RefObject<HTMLDivElement>) => void;
  forceUpdatePopupRef: () => void;
}

export default class PageStandardView extends React.Component<PageStandardViewProps> {
  static defaultProps = {
    passPanelRef: function () {},
    passPopupRef: function () {},
    forceUpdatePopupRef: function () {},
  };

  panelRef: React.RefObject<HTMLDivElement>;
  popupRef: React.RefObject<HTMLDivElement>;

  constructor(props: PageStandardViewProps) {
    super(props);
    this.panelRef = React.createRef();
    this.popupRef = React.createRef();
  }

  render() {
    const pageId = `screen-${this.props.name}`;
    const headerId = `screen-${this.props.name}-header`;
    const mainId = `screen-${this.props.name}-main`;

    return (
      <div className="page page-standard" id="page-layout">
        <Transition
          in={this.props.isNavMenuCollapsed}
          timeout={100}
          onEntered={this.props.forceUpdatePopupRef}
          onExited={this.props.forceUpdatePopupRef}
        >
          {(status) => {
            let navTransitionClassName;
            switch (status) {
              case 'entering':
                navTransitionClassName = 'nav-collapsing';
                break;
              case 'entered':
                navTransitionClassName = 'nav-collapsed';
                break;
              case 'exiting':
                navTransitionClassName = 'nav-expanding';
                break;
              case 'exited':
                navTransitionClassName = 'nav-expanded';
                break;
              default:
                navTransitionClassName = null;
            }
            return (
              <>
                {/* The nav menu on the left side of the screen*/}
                <NavMenu navTransitionClassName={navTransitionClassName} />

                {/* Everything besides the nav menu */}
                <div
                  className={classNames(
                    'main-content-block',
                    navTransitionClassName
                  )}
                  id={pageId}
                >
                  <header id={headerId} className="page-header">
                    {/** The nav icons at the top-right of the screen */}
                    <PageNavHeader {...this.props} />

                    {/** The document title, shown in browser history and window/tab
                     * title bar. (This doesn't actually render anything right
                     * here, it just communicates with a React context.)
                     */}
                    <Title>
                      <Trans>
                        DMS | {this.props.header}
                        {this.props.subHeader && (
                          <>
                            {` | `}
                            {this.props.subHeader}
                          </>
                        )}
                      </Trans>
                    </Title>

                    {/** The page title displayed in the page itself */}
                    <h1>
                      {this.props.header}
                      {this.props.subHeader && (
                        <>
                          <span> : </span>
                          <span className="text-light">
                            {this.props.subHeader}
                          </span>
                        </>
                      )}
                    </h1>
                  </header>
                  <div className="main-content" ref={this.panelRef}>
                    <main role="main" id={mainId}>
                      {this.props.children}
                    </main>
                    {/**
                     * info panels get appended here, via a React Portal
                     * anchored to the div with "this.panelRef" as its ref.
                     * @see PanelContent
                     */}
                  </div>
                  {/**
                   * Plots popups get appended here for fixed positioning
                   */}
                  <div id="plot-popup-root" ref={this.popupRef}></div>
                </div>
              </>
            );
          }}
        </Transition>
      </div>
    );
  }

  componentDidMount() {
    this.props.passPanelRef(this.panelRef);
    this.props.passPopupRef(this.popupRef);
  }
}
