import { getApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { StandardThunk, DuckActions } from 'main/store';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import {
  ChecksheetInstance_STATUS,
  ReadingsBatch_STATUS,
} from 'util/backendapi/types/Enum';
import { t } from '@lingui/macro';
import { selectHasPermission } from 'util/user';

export const ActionTypes = {
  FETCH_BATCH_INFO_START: 'dms/closeout/FETCH_BATCH_INFO_START',
  FETCH_BATCH_INFO_RESPONSE: 'dms/closeout/FETCH_BATCH_INFO_RESPONSE',
  FETCH_BATCH_INFO_ERROR: 'dms/closeout/FETCH_BATCH_INFO_ERROR',
  UNMOUNT: 'dms/closeout/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_BATCH_INFO_START: (batchNumber: number) => ({
    type: ActionTypes.FETCH_BATCH_INFO_START,
    batchNumber,
  }),
  FETCH_BATCH_INFO_RESPONSE: (
    readingsBatch: null | Model.ListReadingsBatch,
    readingsFile: null | Model.ReadingsFileDecorated,
    alarmsCount: number,
    checksheetInstanceId: number | null
  ) => ({
    type: ActionTypes.FETCH_BATCH_INFO_RESPONSE,
    readingsFile,
    readingsBatch,
    alarmsCount,
    checksheetInstanceId,
  }),
  FETCH_BATCH_INFO_ERROR: (errorMessage: string) => ({
    type: ActionTypes.FETCH_BATCH_INFO_ERROR,
    error: true,
    payload: errorMessage,
  }),

  UNMOUNT: () => ({ type: ActionTypes.UNMOUNT }),
} as const;

export const unmountCloseoutScreen = ActionCreators.UNMOUNT;

export type CloseoutAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface CloseoutState {
  batchNumber: number | null;
  errorMessage: string | null;
  isLoading: boolean;
  alarmsCount: number | null;
  checksheetInstanceId: number | null;
  readingsFile: Model.ReadingsFileDecorated | null;
  readingsBatch: Model.ListReadingsBatch | null;
}

function closeoutInitialState(): CloseoutState {
  return {
    batchNumber: null,
    errorMessage: null,
    isLoading: false,
    alarmsCount: null,
    checksheetInstanceId: null,
    readingsFile: null,
    readingsBatch: null,
  };
}

export default function closeoutReducer(
  state = closeoutInitialState(),
  action: CloseoutAction
): CloseoutState {
  switch (action.type) {
    case ActionTypes.FETCH_BATCH_INFO_START:
      return {
        ...closeoutInitialState(),
        batchNumber: action.batchNumber,
        isLoading: true,
      };
    case ActionTypes.FETCH_BATCH_INFO_RESPONSE:
      return {
        ...state,
        isLoading: false,
        alarmsCount: action.alarmsCount,
        readingsFile: action.readingsFile,
        readingsBatch: action.readingsBatch,
        checksheetInstanceId: action.checksheetInstanceId,
      };
    case ActionTypes.FETCH_BATCH_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case ActionTypes.UNMOUNT:
      return closeoutInitialState();
    default:
      return state;
  }
}

/**
 * Get a fresh copy of the batch's file (reflecting its new closed-out state),
 * and get a count of the alarm reports generated.
 *
 * @param batchId
 */
export function fetchBatchCloseoutInfo(
  batchNumber: number,
  polling: boolean
): StandardThunk {
  return async function (dispatch, getState, { i18n }) {
    try {
      if (!polling) {
        dispatch(ActionCreators.FETCH_BATCH_INFO_START(batchNumber));
      }

      const [readingsBatch] = await getApi('/readings-batches/', {
        batch_number__in: [batchNumber],
      });

      if (readingsBatch) {
        let alarmsCount = 0;
        let checksheetInstanceId = null;
        let readingsFile = null;

        if (readingsBatch.status === ReadingsBatch_STATUS.qa_complete) {
          // Fetch additional batch info once alarm checking has finished

          if (readingsBatch.file_id) {
            readingsFile = await getApi(
              `/readings-files/${readingsBatch.file_id}/`
            );
          }

          const alarmReports = await getApi('/alarm-reports/', {
            readings_batch__batch_number: batchNumber,
          });

          alarmsCount = alarmReports.length;

          if (
            selectHasPermission(
              getState(),
              Enum.User_PERMISSION.can_view_checksheet_instance_report
            )
          ) {
            checksheetInstanceId =
              (
                await getApi('/reports/checksheet-instances/', {
                  columns: ['id'],
                  data_sources__batches__id__in: [readingsBatch.id],
                  status__in: [ChecksheetInstance_STATUS.in_progress],
                  ordering: ['-created_datetime'],
                  limit: 1,
                })
              )[0]?.id ?? null;
          }
        }

        dispatch(
          ActionCreators.FETCH_BATCH_INFO_RESPONSE(
            readingsBatch,
            readingsFile,
            alarmsCount,
            checksheetInstanceId
          )
        );
      } else {
        dispatch(
          ActionCreators.FETCH_BATCH_INFO_ERROR(
            i18n._(t`Readings batch not found`)
          )
        );
      }
    } catch (e) {
      dispatch(ActionCreators.FETCH_BATCH_INFO_ERROR(errorToString(e)));
    }
  };
}
