import React, { useEffect, useCallback } from 'react';
import classNames from 'classnames';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import { StandardDispatch } from 'main/store';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import { RCPWithQueryParams } from 'util/routing';
import {
  fetchPlotSet,
  updateStoredPlotsPosition,
  removeStoredPlot,
} from 'ducks/plotset/detail';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { AddStoredPlotModal } from './AddStoredPlotModal';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { BackButton } from 'components/base/back-button/BackButton';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { selectHasPermission } from 'util/user';

type OwnProps = RCPWithQueryParams<{}, { plotSetName: string }>;

export const PlotSetDetailScreen = (props: OwnProps) => {
  const dispatch: StandardDispatch = useDispatch();
  const {
    plotSet,
    storedPlots,
    isLoading,
    isUpdatingStoredPlots,
    errorMessage,
    canCreatePlotSets,
  } = useShallowEqualSelector((state: FullState) => {
    return {
      plotSet: state.plotSet.detail.record,
      storedPlots: state.plotSet.detail.storedPlots,
      errorMessage: state.plotSet.detail.errorMessage,
      isLoading: state.plotSet.detail.loading,
      isUpdatingStoredPlots: state.plotSet.detail.isUpdatingStoredPlots,
      canCreatePlotSets: selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_plot_sets
      ),
    };
  });

  useEffect(() => {
    dispatch(fetchPlotSet(props.match.params.plotSetName));
  }, [dispatch, props.match]);

  const handleItemMoved = useCallback(
    (plotSet: Model.PlotSet, fromIdx: number, toIdx: number) => {
      dispatch(updateStoredPlotsPosition(plotSet, fromIdx, toIdx));
    },
    [dispatch]
  );

  const handleItemRemoved = useCallback(
    async (plotSet: Model.PlotSet, removalIdx: number) => {
      dispatch(removeStoredPlot(plotSet, removalIdx));
    },
    [dispatch]
  );

  return (
    <PlotSetDetailView
      isLoading={isLoading}
      isUpdatingStoredPlots={isUpdatingStoredPlots}
      canCreatePlotSets={canCreatePlotSets}
      errorMessage={errorMessage}
      plotSet={plotSet}
      storedPlots={storedPlots}
      onItemMoved={handleItemMoved}
      onItemRemoved={handleItemRemoved}
    />
  );
};

export interface PlotSetDetailViewProps {
  isLoading: boolean;
  isUpdatingStoredPlots: boolean;
  canCreatePlotSets: boolean;
  errorMessage: string;
  plotSet: null | Model.PlotSet;
  storedPlots: Model.ReportsStoredPlot[];
  onItemMoved: (plotSet: Model.PlotSet, fromIdx: number, toIdx: number) => void;
  onItemRemoved: (plotSet: Model.PlotSet, removalIdx: number) => Promise<any>;
}

export const PlotSetDetailView = ({
  plotSet,
  storedPlots,
  isLoading,
  errorMessage,
  onItemMoved,
  onItemRemoved,
  isUpdatingStoredPlots,
  canCreatePlotSets,
}: PlotSetDetailViewProps) => {
  const onDragEnd = useCallback(
    (result: DropResult, provided: ResponderProvided) => {
      if (
        result.destination &&
        result.source.index !== result.destination.index
      ) {
        onItemMoved(plotSet!, result.source.index, result.destination.index);
      }
    },
    [plotSet, onItemMoved]
  );
  return (
    <PageStandard
      name="plot-set-detail"
      header={<Trans>Maintain Plot Set</Trans>}
      subHeader={plotSet ? <>{plotSet.name}</> : null}
    >
      {isLoading ? (
        <Loading />
      ) : errorMessage ? (
        <AlertWarning>{errorMessage}</AlertWarning>
      ) : !plotSet ? (
        <AlertWarning>
          <Trans>Plot set not found</Trans>
        </AlertWarning>
      ) : (
        <>
          <div className="page-content-header-with-back-button-wrapper">
            <BackButton defaultBackUrl="/plot-sets" />
            <div className="page-content-header">
              <ActionBlock className="text-right">
                <HasPermission check="can_create_plot_sets">
                  <ButtonShowModal
                    name="add-stored-plot"
                    iconType="icon-plus"
                    modalContent={({ hideModal }) => (
                      <AddStoredPlotModal
                        plotSet={plotSet}
                        onAfterSubmit={hideModal}
                      />
                    )}
                    shortcut={'CREATE_NEW'}
                    autoFocus={true}
                  >
                    <Trans>Add stored plots</Trans>
                  </ButtonShowModal>
                </HasPermission>
              </ActionBlock>
            </div>
          </div>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="table-responsive">
              <table data-is-loading={isUpdatingStoredPlots}>
                <thead>
                  <tr>
                    <th></th>
                    <th>Stored plot</th>
                    <th>Area</th>
                    <th>Description</th>
                    <th>Plot type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                {storedPlots.length ? (
                  <Droppable droppableId="stored-plot-list">
                    {(droppableProvided) => (
                      <tbody
                        ref={droppableProvided.innerRef}
                        {...droppableProvided.droppableProps}
                      >
                        {storedPlots.map((storedPlot, index) => (
                          <Draggable
                            key={String(storedPlot.id)}
                            draggableId={String(storedPlot.id)}
                            index={index}
                            isDragDisabled={
                              isUpdatingStoredPlots || !canCreatePlotSets
                            }
                          >
                            {(draggableProvided, snapshot) => {
                              return (
                                <tr
                                  key={String(storedPlot.id)}
                                  {...draggableProvided.draggableProps}
                                  {...draggableProvided.dragHandleProps}
                                  ref={draggableProvided.innerRef}
                                  className={classNames({
                                    draggable: canCreatePlotSets,
                                    dragging: snapshot.isDragging,
                                  })}
                                >
                                  <td />
                                  <td>{storedPlot.name}</td>
                                  <td>
                                    {storedPlot.area__code} -{' '}
                                    {storedPlot.area__name}
                                  </td>
                                  <td>{storedPlot.description}</td>
                                  <td>
                                    <TransEnum
                                      enum="PlotType"
                                      value={storedPlot.plot_type}
                                    />
                                  </td>
                                  <td>
                                    <HasPermission check="can_create_plot_sets">
                                      <ButtonShowConfirmation
                                        name="remove-stored-plot"
                                        title={t`Remove stored plot`}
                                        iconType="icon-circle-minus"
                                        className="btn-link-panel"
                                        content={
                                          <Trans>
                                            Are you sure you want to remove{' '}
                                            <strong>{storedPlot.name}</strong>{' '}
                                            from this plot set? This action is
                                            not reversible
                                          </Trans>
                                        }
                                        okBtnText={<Trans>Yes, remove</Trans>}
                                        onConfirm={async () =>
                                          await onItemRemoved(plotSet, index)
                                        }
                                      />
                                    </HasPermission>
                                  </td>
                                </tr>
                              );
                            }}
                          </Draggable>
                        ))}

                        {droppableProvided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                ) : (
                  <tbody>
                    <tr>
                      <td />
                      <td colSpan={999}>
                        <Trans>
                          No stored plots have been added to this plot set.
                        </Trans>
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </DragDropContext>
        </>
      )}
    </PageStandard>
  );
};
