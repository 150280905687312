import { EntityTypes, withInsertEntities } from './entities';
import { fetchJsonEndpoint, getApi } from '../util/backendapi/fetch';
import { Filter } from '../util/backendapi/models/api.interfaces';
import { StandardThunk } from 'main/store';

export class ActionTypes {
  static FETCH_BACKENDAPI_STATUS = 'dms/actions/FETCH_BACKENDAPI_STATUS';
  static FETCH_BACKENDAPI_STATUS_RESPONSE =
    'dms/actions/FETCH_BACKENDAPI_STATUS_RESPONSE';
  static FETCH_BACKENDAPI_STATUS_ERROR =
    'dms/actions/FETCH_BACKENDAPI_STATUS_ERROR';
  static FETCH_READINGS_FILES_START = 'dms/actions/FETCH_READINGS_FILES_START';
  static FETCH_READINGS_FILES_RESPONSE =
    'dms/actions/FETCH_READINGS_FILES_RESPONSE';
  static FETCH_READINGS_FILES_ERROR = 'dms/actions/FETCH_READINGS_FILES_ERROR';
  static FETCH_READINGS_FILE_START = 'dms/actions/FETCH_READINGS_FILE_START';
  static FETCH_READINGS_FILE_RESPONSE =
    'dms/actions/FETCH_READINGS_FILE_RESPONSE';
  static FETCH_READINGS_FILE_ERROR = 'dms/actions/FETCH_READINGS_FILE_ERROR';
}

/**
 * Redux action creator functions, implemented before we decided to organize
 * these into "ducks" under the /redux directory.
 * TODO: move these into their own redux ducks.
 */

/**
 * Contact the API that displays the backend's up/down status
 *
 * @export
 * @returns {function} A redux-thunk action
 */
export function fetchBackendBasicStatus(): StandardThunk {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.FETCH_BACKENDAPI_STATUS,
    });

    try {
      const json = await fetchJsonEndpoint(`/monitoring/up/`, false);

      return dispatch({
        type: ActionTypes.FETCH_BACKENDAPI_STATUS_RESPONSE,
        status: json.status,
      });
    } catch (e) {
      return dispatch({
        type: ActionTypes.FETCH_BACKENDAPI_STATUS_ERROR,
        status: 'down',
      });
    }
  };
}

export function fetchReadingsFiles(
  params: Filter.ReadingsFiles
): StandardThunk {
  return async function (dispatch) {
    dispatch({ type: ActionTypes.FETCH_READINGS_FILES_START });

    try {
      const readingsFiles = await getApi('/readings-files/', params);

      dispatch(
        withInsertEntities(
          { type: ActionTypes.FETCH_READINGS_FILES_RESPONSE },
          EntityTypes.READINGS_FILE,
          readingsFiles,
          true
        )
      );

      return readingsFiles;
    } catch (e) {
      dispatch({
        type: ActionTypes.FETCH_READINGS_FILES_ERROR,
        error: true,
        payload: e.message,
      });
      return [];
    }
  };
}

export function fetchReadingsFile(id: number): StandardThunk {
  return async function (dispatch) {
    dispatch({ type: ActionTypes.FETCH_READINGS_FILE_START });

    try {
      const readingsFiles = await getApi(`/readings-files/${id}/`);

      dispatch(
        withInsertEntities(
          { type: ActionTypes.FETCH_READINGS_FILE_RESPONSE },
          EntityTypes.READINGS_FILE,
          [readingsFiles],
          false
        )
      );

      return readingsFiles;
    } catch (e) {
      dispatch({
        type: ActionTypes.FETCH_READINGS_FILE_ERROR,
        error: true,
        payload: e.message,
      });
      return [];
    }
  };
}
