import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FullState } from 'main/reducers';
import { useEffect, useMemo } from 'react';
import {
  ReadingSeriesState,
  fetchReadingsFromResolvedSettings,
  selectAlarmsForPlotting,
} from 'ducks/plot/scatter-time-series';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { selectLoggedInUserName } from 'ducks/login';
import {
  PlotReadingsSeries,
  TimeSeriesPlotSettings,
  ScatterPlotSettings,
  ScatterPlotAxisSide,
  YAxisSide,
} from 'components/plots/timeseriesplot.types';
import { selectResolvedSettings } from 'screens/quickplot/quickplotselectors';
import { isTruthy } from 'util/validation';

/**
 * Plot should only be in loading state when all of the calls to get
 * the readings still pending. (for the case of plotting multiple observation points)
 *
 * For Timeseries plot: if at least one of them is resolved, then we could already render the plot
 * with data for that observation point.
 *
 * For Scatter plot: we need all of them to resolved
 */
function calculateIsLoading(
  plotType: Enum.PlotType,
  errorMessage: string | undefined,
  readingsData: (ReadingSeriesState | undefined)[] | undefined
): boolean {
  if (errorMessage) {
    return false;
  } else if (!readingsData) {
    return true;
  }

  if (plotType === Enum.PlotType.SCATTER) {
    // Scatter plot CANNOT display unless EVERY series is done loading
    return !readingsData.every((s) => s?.readings);
  } else {
    // Time series plot CANNOT display unless ONE series is done loading
    return !readingsData.some((s) => s?.readings);
  }
}

type PlotKey = 'quickPlot' | string;

interface PlotTypes {
  [Enum.PlotType.SCATTER]: {
    settings: ScatterPlotSettings;
    axis: ScatterPlotAxisSide;
  };
  [Enum.PlotType.TIME_SERIES]: {
    settings: TimeSeriesPlotSettings;
    axis: YAxisSide;
  };
}

/**
 * A hook to retrieve all neccessary states needed for rendering a single time series
 * or scatter plot.
 *
 * @param `plotKey` - is used to lookup the settings/metadata for each plot within the Redux stored
 *         For QuickPlot, the settings is stored under `quickPlot`
 *         For StoredPlot, the plotKey is currently formatted as `StoredPlot:${storedPlotId}:${storedPlotItemId}` (by makeStoredPlotKey())
 */
export function usePlotState<
  T extends Enum.PlotType.SCATTER | Enum.PlotType.TIME_SERIES
>(plotKey: PlotKey = 'quickPlot', plotType: T) {
  const dispatch = useDispatch();

  const userString = useSelector(selectLoggedInUserName);

  const {
    resolvedSettings,
    zoomParams,
    errorMessage,
    minDatetime,
    maxDatetime,
    paddedMinDatetime,
    paddedMaxDatetime,
    readingsData,
  } = useSelector(
    (state: FullState) => selectResolvedSettings(state, plotKey),
    // Custom comparator to check for changes. Check for shallow equality on
    // all the fields except readingsData. Check for shallow equality on each
    // entry within readingsData
    (
      { readingsData: prevReadingsData, ...prev },
      { readingsData: nextReadingsData, ...next }
    ) => {
      return (
        shallowEqual(prevReadingsData, nextReadingsData) &&
        shallowEqual(prev, next)
      );
    }
  );

  // Fetch data to plot
  useEffect(() => {
    // If we haven't resolved the plot settings & metadata yet, can't fetch
    // the plotting data.
    if (!plotKey || !resolvedSettings) {
      return;
    }

    // Allow distinguishing between the default plot settings and the zoomed in
    const isZoomed = !!zoomParams;

    // We only have to pass the plotKey. The thunk will get the resolved settings
    // out of state itself.
    dispatch(
      fetchReadingsFromResolvedSettings(
        plotKey,
        resolvedSettings,
        minDatetime,
        maxDatetime,
        paddedMinDatetime,
        paddedMaxDatetime,
        isZoomed
      )
    );
  }, [
    dispatch,
    plotKey,
    plotType,
    paddedMaxDatetime,
    paddedMinDatetime,
    resolvedSettings,
    minDatetime,
    maxDatetime,
    zoomParams,
  ]);

  // Match together readings data and resolved settings, into the data structure
  // <TimeSeriesPlot> needs for each readings series.
  const readingsSeries: PlotReadingsSeries<PlotTypes[T]['axis']>[] = useMemo(
    () =>
      resolvedSettings?.reading_series
        .map((rs, idx) => {
          const observationPoint = resolvedSettings.observationPoints.find(
            (op) => op.id === rs.observation_point
          );

          if (!observationPoint) {
            return undefined;
          }

          return {
            settings: rs,
            observationPoint,
            // Default values for "loading" and "readings", in case there is no
            // entry for this series yet in Redux.
            loading: true,
            readings: null,
            alarmParameters: null,
            ...(readingsData[idx] as ReadingSeriesState | undefined),
          };
        })
        .filter(isTruthy) ?? ([] as PlotReadingsSeries[]),
    [readingsData, resolvedSettings]
  );

  // Note: alarm parameters are only be shown if there is only one
  // reading series for the stored plot item
  const alarmParamSeries = useMemo(
    () =>
      resolvedSettings &&
      resolvedSettings.reading_series.length === 1 &&
      resolvedSettings.reading_series[0].show_alarm_parameters &&
      resolvedSettings.reading_series[0].alarm_parameters
        ? selectAlarmsForPlotting(
            resolvedSettings?.reading_series[0].alarm_parameters
          )
        : undefined,
    [resolvedSettings]
  );

  return {
    minDatetime,
    maxDatetime,
    paddedMinDatetime,
    paddedMaxDatetime,
    yAxes: (zoomParams?.yAxes ?? resolvedSettings?.axes) as
      | PlotTypes[T]['settings']['axes']
      | undefined,
    readingsSeries,
    alarmParamSeries,
    resolvedSettings: resolvedSettings as PlotTypes[T]['settings'] | null,
    errorMessage,
    isLoading: calculateIsLoading(plotType, errorMessage, readingsData),
    userString,
  };
}
