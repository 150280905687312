import { Dispatch } from 'redux';
import { Model } from '../../util/backendapi/models/api.interfaces';
import { postApi, getApi, patchApi } from 'util/backendapi/fetch';
import { StandardThunk } from 'main/store';
import { BackendApiError, errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  CREATE_INSTRUMENT_TYPE_START:
    'dms/alarm-reports/CREATE_INSTRUMENT_TYPE_START',
  CREATE_INSTRUMENT_TYPE_RESPONSE:
    'dms/alarm-reports/CREATE_INSTRUMENT_TYPE_RESPONSE',
  CREATE_INSTRUMENT_TYPE_ERROR:
    'dms/alarm-reports/CREATE_INSTRUMENT_TYPE_ERROR',

  FETCH_INSTRUMENT_TYPE_START: 'dms/alarm-reports/FETCH_INSTRUMENT_TYPE_START',
  FETCH_INSTRUMENT_TYPE_RESPONSE:
    'dms/alarm-reports/FETCH_INSTRUMENT_TYPE_RESPONSE',
  FETCH_INSTRUMENT_TYPE_ERROR: 'dms/alarm-reports/FETCH_INSTRUMENT_TYPE_ERROR',

  UPDATE_INSTRUMENT_TYPE_START:
    'dms/alarm-reports/UPDATE_INSTRUMENT_TYPE_START',
  UPDATE_INSTRUMENT_TYPE_RESPONSE:
    'dms/alarm-reports/UPDATE_INSTRUMENT_TYPE_RESPONSE',
  UPDATE_INSTRUMENT_TYPE_ERROR:
    'dms/alarm-reports/UPDATE_INSTRUMENT_TYPE_ERROR',
} as const;

export const ActionCreators = {
  CREATE_INSTRUMENT_TYPE_START() {
    return {
      type: ActionTypes.CREATE_INSTRUMENT_TYPE_START,
    };
  },
  CREATE_INSTRUMENT_TYPE_RESPONSE(instrumentType: Model.InstrumentType) {
    return {
      type: ActionTypes.CREATE_INSTRUMENT_TYPE_RESPONSE,
      payload: {
        instrumentType,
      },
    };
  },
  CREATE_INSTRUMENT_TYPE_ERROR() {
    return {
      type: ActionTypes.CREATE_INSTRUMENT_TYPE_ERROR,
    };
  },

  FETCH_INSTRUMENT_TYPE_DETAIL_START(code: string) {
    return {
      type: ActionTypes.FETCH_INSTRUMENT_TYPE_START,
      payload: { code },
    };
  },
  FETCH_INSTRUMENT_TYPE_DETAIL_RESPONSE(
    code: string,
    instrumentType: Model.InstrumentType
  ) {
    return {
      type: ActionTypes.FETCH_INSTRUMENT_TYPE_RESPONSE,
      payload: { code, instrumentType },
    };
  },
  FETCH_INSTRUMENT_TYPE_DETAIL_ERROR(code: string, e: BackendApiError) {
    return {
      type: ActionTypes.FETCH_INSTRUMENT_TYPE_ERROR,
      payload: errorToString(e),
    };
  },

  UPDATE_INSTRUMENT_TYPE_START() {
    return {
      type: ActionTypes.UPDATE_INSTRUMENT_TYPE_START,
    };
  },
  UPDATE_INSTRUMENT_TYPE_RESPONSE(instrumentType: Model.InstrumentType) {
    return {
      type: ActionTypes.UPDATE_INSTRUMENT_TYPE_RESPONSE,
      payload: {
        instrumentType,
      },
    };
  },
  UPDATE_INSTRUMENT_TYPE_ERROR() {
    return {
      type: ActionTypes.UPDATE_INSTRUMENT_TYPE_ERROR,
    };
  },
} as const;

export type InstrumentTypeDetailAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export interface InstrumentTypeDetailState {
  readonly instrumentType: null | Model.InstrumentType;
  readonly loading: boolean;
  readonly error: string;
}

export const initialState: InstrumentTypeDetailState = {
  instrumentType: null,
  loading: true,
  error: '',
};

export function instrumentTypeDetailReducer(
  state: InstrumentTypeDetailState = initialState,
  action: InstrumentTypeDetailAction
): InstrumentTypeDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_INSTRUMENT_TYPE_START:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ActionTypes.FETCH_INSTRUMENT_TYPE_RESPONSE:
      return {
        ...state,
        loading: false,
        instrumentType: action.payload.instrumentType,
      };
    case ActionTypes.FETCH_INSTRUMENT_TYPE_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case ActionTypes.UPDATE_INSTRUMENT_TYPE_RESPONSE:
    case ActionTypes.CREATE_INSTRUMENT_TYPE_RESPONSE:
      return {
        ...state,
        instrumentType: action.payload.instrumentType,
      };

    default:
      return state;
  }
}

export function fetchInstrumentType(code: string): StandardThunk {
  return async function (dispatch: Dispatch) {
    dispatch(ActionCreators.FETCH_INSTRUMENT_TYPE_DETAIL_START(code));
    try {
      const instrumentTypes = await getApi('/instrument-types/', { code });
      dispatch(
        ActionCreators.FETCH_INSTRUMENT_TYPE_DETAIL_RESPONSE(
          code,
          instrumentTypes[0]
        )
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_INSTRUMENT_TYPE_DETAIL_ERROR(code, e)
      );
    }
  };
}

export function updateInstrumentType(
  id: number,
  instrumentType: ForPatch<Model.InstrumentType>
): StandardThunk<Model.InstrumentType> {
  return async function (dispatch: Dispatch) {
    dispatch(ActionCreators.UPDATE_INSTRUMENT_TYPE_START());
    try {
      const updatedInstrumentType = await patchApi(
        `/instrument-types/${id}/`,
        instrumentType
      );
      dispatch(
        ActionCreators.UPDATE_INSTRUMENT_TYPE_RESPONSE(updatedInstrumentType)
      );
      return updatedInstrumentType;
    } catch (error) {
      dispatch(ActionCreators.UPDATE_INSTRUMENT_TYPE_ERROR());
      throw error;
    }
  };
}

export function createInstrumentType(
  instrumentType: ForPostOrPut<Model.InstrumentType>
): StandardThunk<Model.InstrumentType> {
  return async function (dispatch: Dispatch) {
    dispatch(ActionCreators.CREATE_INSTRUMENT_TYPE_START());
    try {
      const newInstrumentType = await postApi(
        '/instrument-types/',
        instrumentType
      );
      dispatch(
        ActionCreators.CREATE_INSTRUMENT_TYPE_RESPONSE(newInstrumentType)
      );
      return newInstrumentType;
    } catch (error) {
      dispatch(ActionCreators.CREATE_INSTRUMENT_TYPE_ERROR());
      throw error;
    }
  };
}
