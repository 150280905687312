import React, { useEffect, useCallback, useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import {
  CheckReadingsView,
  CheckReadingErrorsFilterSetting,
} from './CheckReadingsView';
import { useShallowEqualSelector, useIsMounted } from 'util/hooks';
import { FullState } from 'main/reducers';
import { selectAll, EntityTypes, fetchEntityList } from 'ducks/entities';
import { Pagination } from 'util/backendapi/pagination';
import { parseQueryParamFromRouterProps, setQueryParams } from 'util/routing';
import { useDispatch } from 'react-redux';
import {
  fetchBatchReadings,
  previewBatchAlarms,
  closeOutBatch,
  recalculateAdjustedReadings,
  fetchBatchData,
  unmountCheckReadingsScreen,
  recalculateAdjustedReadingsResponse,
} from 'ducks/checkreadings';
import { createWebsocket } from 'util/websocket';

const TASK_WEBSOCKET_RECONNECT_DELAY_MS = 5000;

export function CheckReadings() {
  const { batchNumber } = useParams<{ batchNumber: string }>();
  const location = useLocation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const [taskWebsocket, setTaskWebsocket] = useState<WebSocket | null>(null);
  const [taskWebsocketDisconnected, setTaskWebsocketDisconnected] =
    useState<boolean>(false);
  const {
    isLoading,
    isRecalculatingReadings,
    errorMessage,
    readings,
    readingsBatch,
    readingsFile,
    checksheetInstanceId,
    allFormulaOutputs,
    alarmsCountByReadingId,
    pagination,
    taskId,
  } = useShallowEqualSelector((state: FullState) => {
    return {
      isLoading:
        state.checkReadings.isLoading || state.checkReadings.isLoadingBatchData,
      isRecalculatingReadings: state.checkReadings.isRecalculatingReadings,
      errorMessage: state.checkReadings.errorMessage,
      readings: state.checkReadings.readings,
      readingsBatch: state.checkReadings.readingsBatch,
      readingsFile: state.checkReadings.readingsFile,
      checksheetInstanceId: state.checkReadings.checksheetInstanceId,
      allFormulaOutputs: selectAll(state, EntityTypes.FORMULA_OUTPUT),
      alarmsCountByReadingId: state.checkReadings.alarmsCountByReadingId,
      pagination: Pagination.fromRequestedReceived(
        Pagination.parseFromRouterProps({ location }),
        state.checkReadings.pagination
      ),
      taskId: state.checkReadings.taskId,
    };
  });

  const { offset, limit } = pagination.requested;
  const readingErrorsFilter = parseQueryParamFromRouterProps(
    { location },
    'reading_errors',
    null
  ) as null | CheckReadingErrorsFilterSetting;

  const dispatch = useDispatch();

  const fetchReadings = useCallback(() => {
    if (!readingsBatch) {
      return;
    }

    let reading_error__isnull: boolean | undefined;

    switch (readingErrorsFilter) {
      case 'with_error':
        reading_error__isnull = false;
        break;
      case 'without_error':
        reading_error__isnull = true;
        break;
      default:
        reading_error__isnull = undefined;
    }

    dispatch(
      fetchBatchReadings({
        readings_batch: readingsBatch.id,
        reading_error__isnull,
        offset,
        limit,
        fields: [
          'id',
          'observation_point',
          'readings_batch',
          'reading_datetime',
          'raw_reading_entries',
          'time_zone',
          'analysis_comments_count',
          'area',
          'media',
        ],
      })
    );
  }, [dispatch, readingsBatch, readingErrorsFilter, offset, limit]);

  const fetchAlarmsPreview = useCallback(() => {
    if (!readingsBatch || readings.length === 0) {
      return;
    }
    const readingIds = readings.map((r) => r.id);
    dispatch(previewBatchAlarms(readingsBatch.id, readingIds));
  }, [dispatch, readingsBatch, readings]);

  const handleConfirmReadings = useCallback(async () => {
    if (!readingsBatch) {
      return;
    }
    try {
      // Wait to see if the closeout is successful before proceeding to the
      // next screen. (The thunk will throw if the closeout fails.)
      await dispatch(closeOutBatch(readingsBatch.id));

      if (isMounted()) {
        history.push(`/closeout/${readingsBatch.batch_number}`);
      }
    } catch (e) {
      // Redux will take care of displaying the error, so we don't actually need
      // to do anything in this error catch block.
    }
  }, [dispatch, readingsBatch, isMounted, history]);

  const handleRecalculate = useCallback(async () => {
    if (!readingsBatch) {
      return;
    }

    await dispatch(recalculateAdjustedReadings(readingsBatch.id));

    if (taskWebsocket) {
      setTaskWebsocket(null);
    }
  }, [dispatch, setTaskWebsocket, taskWebsocket, readingsBatch]);

  const handleFilterChange = useCallback(
    (selectedFilter) => {
      setQueryParams(
        { location, history },
        { reading_errors: selectedFilter || null }
      );
    },
    [location, history]
  );

  const createTaskWebsocket = useCallback(
    (taskId: string) => {
      if (!isMounted()) return;

      const websocket = createWebsocket(`/ws/tasks/${taskId}/`);

      if (taskWebsocket) {
        taskWebsocket.close();
      }

      let messagesReceived = 0;

      websocket.onmessage = ({ data }) => {
        messagesReceived++;

        dispatch(recalculateAdjustedReadingsResponse(data));

        fetchAlarmsPreview();
        fetchReadings();

        websocket.close();
      };

      websocket.onclose = (_e) => {
        if (messagesReceived === 0) {
          // If we didn't received the task result we want to distinguish this state so
          // we can retry after a delay
          setTaskWebsocketDisconnected(true);
        } else {
          setTaskWebsocketDisconnected(false);
        }
        setTaskWebsocket(null);
      };

      setTaskWebsocket(websocket);
    },
    [
      taskWebsocket,
      isMounted,
      setTaskWebsocketDisconnected,
      setTaskWebsocket,
      fetchAlarmsPreview,
      fetchReadings,
      dispatch,
    ]
  );

  // Fetch the batch identified in the URL
  useEffect(() => {
    if (batchNumber) {
      dispatch(fetchBatchData(parseInt(batchNumber)));
    }
    // Clear all data for this screen when it unmounts
    return () => {
      dispatch(unmountCheckReadingsScreen());
    };
  }, [dispatch, batchNumber]);

  // fetch all readings of the batch
  useEffect(() => {
    fetchReadings();
  }, [fetchReadings]);

  // fetch alarm preview
  useEffect(() => {
    fetchAlarmsPreview();
  }, [fetchAlarmsPreview]);

  useEffect(() => {
    if (isRecalculatingReadings && taskId && !taskWebsocket) {
      if (taskWebsocketDisconnected) {
        // If the websocket disconnected for some reason, reconnect after a delay
        setTimeout(
          () => createTaskWebsocket(taskId),
          TASK_WEBSOCKET_RECONNECT_DELAY_MS
        );
      } else {
        // When we receive the taskId, create a websocket that is notified when the task has completed
        createTaskWebsocket(taskId);
      }
    }
  }, [
    taskId,
    isRecalculatingReadings,
    taskWebsocket,
    taskWebsocketDisconnected,
    createTaskWebsocket,
  ]);

  useEffect(() => {
    dispatch(fetchEntityList(EntityTypes.FORMULA_OUTPUT));
  }, [dispatch]);

  return (
    <CheckReadingsView
      key={batchNumber}
      isLoading={isLoading}
      isRecalculatingReadings={isRecalculatingReadings}
      errorMessage={errorMessage}
      onConfirmAndCreateAlarms={handleConfirmReadings}
      checksheetInstanceId={checksheetInstanceId}
      readingsBatch={readingsBatch}
      readingsFile={readingsFile}
      readings={readings}
      allFormulaOutputs={allFormulaOutputs}
      alarmsCountByReadingId={alarmsCountByReadingId}
      pagination={pagination}
      readingErrorsFilter={readingErrorsFilter}
      onRecalculateAdjustedReadings={handleRecalculate}
      onReadingErrorsFilterChanged={handleFilterChange}
      refreshList={() => {
        fetchReadings();
        fetchAlarmsPreview();
      }}
      refreshBatchData={() => {
        if (batchNumber) {
          dispatch(fetchBatchData(parseInt(batchNumber)));
        }
      }}
    />
  );
}
