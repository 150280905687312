import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from 'util/hooks';
import { FormikHelpers } from 'formik';
import { FullState } from 'main/reducers';
import {
  ChangeStatusModalView,
  ChangeStatusFormValues,
} from './changestatusmodalview';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { getExpectedFields } from 'util/backendapi/error';
import { fetchCommentsList } from 'ducks/comments/panel/list';
import { patchApi } from 'util/backendapi/fetch';
import { fetchAlarmReport } from 'ducks/alarm-report/detail';
import { makeStatusChangeComment } from './change-status-util';
import { withI18n, withI18nProps } from '@lingui/react';

export type WorkflowOption = {
  value: Enum.AlarmReport_STATUS;
  label: React.ReactNode;
  nextSteps: Enum.AlarmReport_STATUS[];
  permission?: Enum.User_PERMISSION;
};

type OwnProps = {
  alarmReport: Model.AlarmReportDecorated;
  hideModal: Function;
};

export const alarmReportWorkflowSteps: WorkflowOption[] = [
  {
    value: Enum.AlarmReport_STATUS.unacknowledged,
    label: (
      <TransEnum
        enum="AlarmReport_STATUS"
        value={Enum.AlarmReport_STATUS.unacknowledged}
      />
    ),
    nextSteps: [Enum.AlarmReport_STATUS.acknowledged],
  },
  {
    value: Enum.AlarmReport_STATUS.acknowledged,
    label: (
      <TransEnum
        enum="AlarmReport_STATUS"
        value={Enum.AlarmReport_STATUS.acknowledged}
      />
    ),
    nextSteps: [
      Enum.AlarmReport_STATUS.data_check,
      Enum.AlarmReport_STATUS.cancelled,
    ],
    permission: Enum.User_PERMISSION.can_acknowledge_alarm_report,
  },
  {
    value: Enum.AlarmReport_STATUS.data_check,
    label: (
      <TransEnum
        enum="AlarmReport_STATUS"
        value={Enum.AlarmReport_STATUS.data_check}
      />
    ),
    nextSteps: [Enum.AlarmReport_STATUS.cancelled],
    permission:
      Enum.User_PERMISSION.can_initiate_data_check_alarm_report_status,
  },
  {
    value: Enum.AlarmReport_STATUS.cancelled,
    label: (
      <TransEnum
        enum="AlarmReport_STATUS"
        value={Enum.AlarmReport_STATUS.cancelled}
      />
    ),
    nextSteps: [],
    permission: Enum.User_PERMISSION.can_cancel_alarm_report,
  },
];

export function getWorkflowStatusOptions(
  stepName: Enum.AlarmReport_STATUS,
  permissions: Enum.User_PERMISSION[]
): WorkflowOption[] {
  const currentStepOption = alarmReportWorkflowSteps.find(
    (option) => option.value === stepName
  );
  return alarmReportWorkflowSteps.filter((option) => {
    const hasPermission = option.permission
      ? permissions.includes(option.permission)
      : true;
    const isNextStep = currentStepOption!.nextSteps.includes(option.value);
    return hasPermission && isNextStep;
  });
}

function InnerChangeStatusModal(props: OwnProps & withI18nProps) {
  const { alarmReport, hideModal, i18n } = props;
  const dispatch = useDispatch();

  const { comments, isLoadingComments, nextStepsOptions } =
    useShallowEqualSelector((state: FullState) => ({
      nextStepsOptions: getWorkflowStatusOptions(
        alarmReport.status,
        state.user.permissions
      ),
      comments: state.comments.panelList.items,
      isLoadingComments: state.comments.panelList.isLoading,
    }));

  // fetch existing comments
  useEffect(() => {
    const columns: Array<StringKeyOf<Model.ReportsComment>> = [
      'id',
      'comment_type',
      'commenter__name',
      'content',
      'resourcetype',
      'created_datetime',
    ];
    dispatch(
      fetchCommentsList(
        {
          comment_type__in: [Enum.Comment_TYPE.analysis],
          resourcetype__in: [Enum.Comment_RESOURCE_TYPE.alarmReport],
          alarm_report__in: [alarmReport.id],
          columns,
        },
        Enum.Comment_RESOURCE_TYPE.alarmReport
      )
    );
  }, [alarmReport, dispatch]);

  const onSubmit = useCallback(
    async (
      values: ChangeStatusFormValues,
      formik: FormikHelpers<ChangeStatusFormValues>
    ) => {
      try {
        await patchApi(`/alarm-reports/${alarmReport.id}/`, {
          ...values,
          comment: makeStatusChangeComment(
            i18n,
            values.status!,
            values.comment
          ),
        } as Model.AlarmReport_PATCH);
        dispatch(fetchAlarmReport(alarmReport.id));
        hideModal();
      } catch (e) {
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(values));
        return;
      }
    },
    [alarmReport.id, dispatch, hideModal, i18n]
  );

  return (
    <ChangeStatusModalView
      initialCategory={alarmReport.category}
      comments={comments}
      isLoadingComments={isLoadingComments}
      nextStepsOptions={nextStepsOptions}
      onSubmit={onSubmit}
    />
  );
}

export const ChangeStatusModal = withI18n()(InnerChangeStatusModal);
