import { Model } from 'util/backendapi/models/api.interfaces';
import { DuckActions, StandardThunk } from 'main/store';
import { errorToString } from 'util/backendapi/error';
import { getApi } from 'util/backendapi/fetch';
import { StoredCumulativePlotWithArea } from 'ducks/stored-plot/detail';
import {
  CumulativePlotReadings,
  StoredCumulativePlot,
} from 'util/backendapi/types/Model';
import { FullState } from 'main/reducers';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { sortBy } from 'lodash';

/***
 * Duck for rendering a cumulative plot
 */
export const ActionTypes = {
  FETCH_PLOTTING_DATA_START:
    'dms/storedPlot/cumulativePlot/FETCH_PLOTTING_DATA_START',
  FETCH_PLOTTING_DATA_RESPONSE:
    'dms/storedPlot/cumulativePlot/FETCH_PLOTTING_DATA_RESPONSE',
  FETCH_PLOTTING_DATA_ERROR:
    'dms/storedPlot/cumulativePlot/FETCH_PLOTTING_DATA_ERROR',
  UNMOUNT: 'dms/storedPlot/cumulativePlot/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_PLOTTING_DATA_START: (storedPlotId: number) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_START,
    storedPlotId,
  }),
  FETCH_PLOTTING_DATA_RESPONSE: (
    storedPlotId: number,
    observationPoints: Model.ObservationPointDecorated[],
    observationPointItems: StoredCumulativePlot['observation_point_items'],
    timePeriods: StoredCumulativePlot['time_periods'],
    plotReadings: CumulativePlotReadings
  ) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_RESPONSE,
    storedPlotId,
    payload: {
      observationPoints,
      observationPointItems,
      timePeriods,
      plotReadings,
    },
  }),
  FETCH_PLOTTING_DATA_ERROR: (storedPlotId: number, errorMessage: string) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_ERROR,
    storedPlotId,
    error: true,
    payload: errorMessage,
  }),
  UNMOUNT: () => ({ type: ActionTypes.UNMOUNT }),
};

export type CumulativePlotAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface SingleCumulativePlotState {
  storedPlotId: number | null;
  isLoading: boolean;
  errorMessage: string | null;
  observationPoints: Model.ObservationPointDecorated[] | null;
  observationPointItems: StoredCumulativePlot['observation_point_items'] | null;
  timePeriods: StoredCumulativePlot['time_periods'] | null;
  plotReadings: CumulativePlotReadings | null;
}

type CumulativePlotState = {
  [K in number]?: SingleCumulativePlotState;
};

function initialState(): CumulativePlotState {
  return {};
}

export function cumulativePlotReducer(
  state = initialState(),
  action: CumulativePlotAction
): CumulativePlotState {
  switch (action.type) {
    case ActionTypes.FETCH_PLOTTING_DATA_START:
      return {
        ...state,
        [action.storedPlotId]: {
          storedPlotId: action.storedPlotId,
          isLoading: true,
          errorMessage: null,
          observationPoints: null,
          observationPointItems: null,
          timePeriods: null,
          plotReadings: null,
        },
      };
    case ActionTypes.FETCH_PLOTTING_DATA_RESPONSE: {
      const plotState = state[action.storedPlotId];
      if (plotState) {
        return {
          ...state,
          [action.storedPlotId]: {
            ...plotState,
            isLoading: false,
            errorMessage: null,
            observationPoints: action.payload.observationPoints,
            observationPointItems: action.payload.observationPointItems,
            timePeriods: action.payload.timePeriods,
            plotReadings: action.payload.plotReadings,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.FETCH_PLOTTING_DATA_ERROR: {
      const plotState = state[action.storedPlotId];
      if (plotState) {
        return {
          ...state,
          [action.storedPlotId]: {
            ...plotState,
            isLoading: false,
            errorMessage: action.payload,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.UNMOUNT:
      return initialState();
    default:
      return state;
  }
}

export function selectCumulativePlotData(
  state: FullState,
  storedPlotId: number
) {
  return state.plot.cumulative[storedPlotId];
}

export const unmountCumulativePlot = ActionCreators.UNMOUNT;

function yearsToYearRanges(years: number[]) {
  let yearsRemaining = sortBy(years);

  let yearRanges = [];
  while (yearsRemaining.length > 0) {
    const startYear = yearsRemaining.shift()!;

    let yearRange = {
      start_year: startYear,
      end_year: startYear,
    };

    // If the next year directly follows this year, extend the range
    while (
      yearsRemaining.length > 0 &&
      yearsRemaining[0] === yearRange.end_year + 1
    ) {
      yearRange.end_year = yearsRemaining.shift()!;
    }

    yearRanges.push(yearRange);
  }

  return yearRanges;
}

async function fetchCumulativePlotReadingsForTimePeriods(
  observationPointId: number,
  itemNumber: number,
  timePeriods: number[]
) {
  let plotReadings: CumulativePlotReadings = {
    observation_point: observationPointId,
    item_number: itemNumber,
    readings: {},
  };

  const yearRanges = yearsToYearRanges(timePeriods);

  for (let i = 0; i < yearRanges.length; i++) {
    const yearRange = yearRanges[i];
    const response = await getApi('/readings/cumulative-plot/', {
      observation_point: observationPointId,
      item_number: itemNumber,
      start_year: yearRange.start_year,
      end_year: yearRange.end_year,
    });

    const years = Object.keys(response.readings);
    // NOTE: Using a forEach here somehow causes a module build error 'Couldn't find a Program'
    for (let j = 0; j < years.length; j++) {
      const year = years[j];
      if (response.readings[year]) {
        plotReadings.readings[year] = response.readings[year];
      }
    }
  }

  return plotReadings;
}

export function fetchCumulativePlotData(
  observationPointCode: string,
  itemNumber: number,
  timePeriods: number[]
): StandardThunk {
  return async function (dispatch) {
    // Store the 'quick' cumulative plot in the same structure as the stored plots
    const quickCumulativePlotId = 0;

    dispatch(ActionCreators.FETCH_PLOTTING_DATA_START(quickCumulativePlotId));

    try {
      const observationPoints = await getApi('/observation-points/', {
        code: observationPointCode,
      });

      if (observationPoints.length === 0) {
        throw new Error(i18n._(t`Observation point not found.`));
      }

      // NOTE: cumulative plot currently only supports one observation point
      const observationPoint = observationPoints[0];

      const plotReadings: CumulativePlotReadings =
        await fetchCumulativePlotReadingsForTimePeriods(
          observationPoint.id,
          itemNumber,
          timePeriods
        );

      const observationPointItems = [
        {
          observation_point: observationPoint.id,
          item_number: itemNumber,
        },
      ];

      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_RESPONSE(
          quickCumulativePlotId,
          observationPoints,
          observationPointItems,
          timePeriods,
          plotReadings
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_ERROR(
          quickCumulativePlotId,
          errorToString(e)
        )
      );
    }
  };
}

export function fetchCumulativeStoredPlotData(
  storedPlot: StoredCumulativePlotWithArea
): StandardThunk {
  return async function (dispatch) {
    const storedPlotId = storedPlot.id;
    dispatch(ActionCreators.FETCH_PLOTTING_DATA_START(storedPlotId));
    try {
      if (storedPlot.observation_point_items.length === 0) {
        throw new Error(i18n._(t`No observation points.`));
      }

      // NOTE: cumulative plot currently only supports one observation point
      const observationPointId =
        storedPlot.observation_point_items[0].observation_point;
      const itemNumber = storedPlot.observation_point_items[0].item_number;

      const observationPoints = await getApi('/observation-points/', {
        id__in: [observationPointId],
      });

      let plotReadings: CumulativePlotReadings =
        await fetchCumulativePlotReadingsForTimePeriods(
          observationPointId,
          itemNumber,
          storedPlot.time_periods
        );

      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_RESPONSE(
          storedPlotId,
          observationPoints,
          storedPlot.observation_point_items,
          storedPlot.time_periods,
          plotReadings
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_ERROR(storedPlotId, errorToString(e))
      );
    }
  };
}
