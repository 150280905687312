import React from 'react';
import Button, { ButtonProps } from '../button/button';
import { Trans } from '@lingui/macro';

function handlePrint(event: React.MouseEvent) {
  event.preventDefault();
  window.print();
}

export const ButtonPrint: React.FunctionComponent<ButtonProps> = function (
  props
) {
  return (
    <Button
      iconType="icon-print"
      onClick={handlePrint}
      type="button"
      {...props}
    >
      <Trans>Print</Trans>
    </Button>
  );
};
