import React from 'react';
import { Trans } from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import {
  StoredSpatialWanderPlotWithArea,
  StoredSpatialPlanPlotWithArea,
} from 'ducks/stored-plot/detail';
import { InstrumentType } from 'util/backendapi/types/Model';

type ReadingsLegendEntry = {
  title: string;
  symbol: string;
  style: React.CSSProperties;
};

interface ReadingsLegendProps {
  storedPlot: StoredSpatialWanderPlotWithArea | StoredSpatialPlanPlotWithArea;
  legendEntries: ReadingsLegendEntry[];
  innerWidth?: number;
  innerHeight?: number;
}

const READINGS_LEGEND_WIDTH_MM = 57.5;

const READINGS_LEGEND_TITLE_FONT_SIZE = 3.5;
const READINGS_LEGEND_SYMBOL_FONT_SIZE = 4;
const READINGS_LEGEND_LABEL_FONT_SIZE = 2.5;

const READINGS_LEGEND_TOP_MARGIN_MM = 12;
const READINGS_LEGEND_ROW_HEIGHT_MM = 5;

const READINGS_LEGEND_SYMBOL_MARGIN_X_MM = 1;
const READINGS_LEGEND_LABEL_MARGIN_X_MM = 6;

export const READINGS_LEGEND_ICONS: Record<string, string> = {
  'icon-marker-observation-well': '\ue957',
  'icon-marker-piezometer-tip': '\ue958',
  'icon-marker-water-level-baseline': '\ue95a',
  'icon-marker-dam-levelling': '\ue954',
  'icon-marker-deformation': '\ue955',
  'icon-marker-deformation-redundant': '\ue956',
  'icon-marker-water-level': '\ue959',
  'icon-marker-x': '\ue95c',
  'icon-marker-asterisk': '\ue95d',
  'icon-marker-plus': '\ue95e',
  'icon-marker-rhombus-solid': '\ue95f',
  'icon-marker-rhombus': '\ue960',
  'icon-marker-square-solid': '\ue961',
  'icon-marker-square': '\ue962',
  'icon-marker-diamond-solid': '\ue963',
  'icon-marker-diamond': '\ue964',
  'icon-marker-circle-solid': '\ue965',
  'icon-marker-circle': '\ue966',
  'icon-marker-triangle-solid': '\ue967',
  'icon-marker-triangle': '\ue968',
};

export const READINGS_LEGEND_COLORS = [
  'yellow',
  'orange',
  'red',
  'springgreen',
  'cyan',
  'blue',
  'purple',
  'hotpink',
  'white',
  'lightgray',
];

const generateReadingsLegendIconList = (): string[] => {
  let icons: string[] = [];

  Object.keys(READINGS_LEGEND_ICONS).forEach((icon) =>
    READINGS_LEGEND_COLORS.forEach((color) => {
      icons.push(`${icon}:${color}`);
    })
  );

  return icons;
};

export const READINGS_LEGEND_STYLED_ICONS = generateReadingsLegendIconList();

export function parseReadingsLegendSymbol(
  readingsLegendSymbol: string | undefined,
  defaultSymbol: string,
  defaultColor: string
) {
  let [iconName, color] = readingsLegendSymbol?.split(':') ?? [];

  if (!color) color = defaultColor;

  const symbol = READINGS_LEGEND_ICONS[iconName] ?? defaultSymbol;
  const style: React.CSSProperties = { fill: color };

  return {
    symbol,
    style,
  };
}

export function createReadingsLegendEntries(
  instrumentTypes: InstrumentType[],
  defaultSymbol: string,
  defaultColor: string,
  additionalEntries?: ReadingsLegendEntry[]
) {
  // Add entries for instrument types that define a readings legend symbol and title
  let readingsLegendEntries = sortBy(
    instrumentTypes
      .filter((it) => it.readings_legend_symbol && it.readings_legend_title)
      .map((it) => {
        const { symbol, style } = parseReadingsLegendSymbol(
          it.readings_legend_symbol,
          defaultSymbol,
          defaultColor
        );

        return { title: it.readings_legend_title, symbol, style };
      }),
    'title'
  );

  // Add a default 'Instrument' entry if any lack a symbol or title
  if (
    instrumentTypes.some(
      (it) => !it.readings_legend_symbol || !it.readings_legend_title
    )
  ) {
    readingsLegendEntries.push({
      title: 'Instrument',
      symbol: defaultSymbol,
      style: { fill: defaultColor },
    });
  }

  // Add additional entries specific to the plot
  if (additionalEntries) {
    readingsLegendEntries = readingsLegendEntries.concat(additionalEntries);
  }

  return uniqBy(readingsLegendEntries, (item) => item.title);
}

export function ReadingsLegend(props: ReadingsLegendProps) {
  const { storedPlot, legendEntries, innerWidth, innerHeight } = props;

  if (!storedPlot.readings_legend_x || !storedPlot.readings_legend_y)
    return null;

  const minX = +storedPlot.readings_legend_x;
  const minY = +storedPlot.paperspace_height - +storedPlot.readings_legend_y;

  const readingsLegendHeightMM =
    11 + legendEntries.length * READINGS_LEGEND_ROW_HEIGHT_MM;

  return (
    <svg
      className="readings-legend"
      x={0}
      y={0}
      width={innerWidth}
      height={innerHeight}
      // Nested SVG with a "viewBox" that matches the paperspace size, allows us
      // to position everything using paperspace coordinates
      viewBox={`0 0 ${storedPlot.paperspace_width} ${storedPlot.paperspace_height}`}
    >
      {/* The border box around the readings legend */}
      <rect
        x={minX}
        y={minY}
        width={READINGS_LEGEND_WIDTH_MM}
        height={readingsLegendHeightMM}
      />

      {/* Readings legend title */}
      <text
        className="readings-legend-title"
        x={minX}
        y={minY}
        fontSize={READINGS_LEGEND_TITLE_FONT_SIZE}
        textAnchor="start"
        dominantBaseline="auto"
        dx="0.5em"
        dy="1.5em"
      >
        <Trans>Readings Legend</Trans>
      </text>
      {legendEntries.map((entry, i) => (
        <text
          className="readings-legend-symbol"
          key={i}
          x={minX + READINGS_LEGEND_SYMBOL_MARGIN_X_MM}
          y={
            minY +
            READINGS_LEGEND_TOP_MARGIN_MM +
            i * READINGS_LEGEND_ROW_HEIGHT_MM
          }
          fontSize={READINGS_LEGEND_SYMBOL_FONT_SIZE}
          textAnchor="start"
          dominantBaseline="auto"
          style={entry.style}
        >
          {entry.symbol}
        </text>
      ))}
      {legendEntries.map((entry, i) => (
        <text
          className="readings-legend-label"
          key={i}
          x={minX + READINGS_LEGEND_LABEL_MARGIN_X_MM}
          y={
            minY +
            READINGS_LEGEND_TOP_MARGIN_MM +
            i * READINGS_LEGEND_ROW_HEIGHT_MM
          }
          fontSize={READINGS_LEGEND_LABEL_FONT_SIZE}
          textAnchor="start"
          dominantBaseline="auto"
        >
          {entry.title}
        </text>
      ))}
    </svg>
  );
}
