import React, { useMemo } from 'react';
import { Trans, Plural, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportFilter,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { formatDatetimeForDisplay } from 'util/dates';
import ActionBlock from 'components/base/actionblock/actionblock';
import { TransEnum } from 'components/base/i18n/TransEnum';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { reportFilterShowHideMenu } from 'components/modules/report/filter/fields/FilterShowHideMenu';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { ReportStateProps } from 'hooks/use-report-state';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { getApi } from 'util/backendapi/fetch';
import { reportFilterDatetime } from 'components/modules/report/filter/fields/FilterDate';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { EditCommentModal } from './EditCommentModal';
import { reportFilterNumber } from 'components/modules/report/filter/fields/FilterNumber';
import {
  CommentReportAutoColumnFilter as AUTO_COLUMN_FILTER,
  CommentReportAutoColumn as AUTO_COLUMN,
  useCommentReportAutoColumns,
} from './use-comment-report-auto-columns';
import NewCommentForm, {
  canCreateComments,
  NewCommentFormValues,
} from 'components/modules/comments-panel/new-comment-form/NewCommentFormView';
import ButtonShowPanel from 'components/base/panel/buttonshowpanel';
import PanelContent from 'components/base/panel/panelcontent';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';

export interface CommentsListViewProps {
  reportProps: ReportStateProps<Model.ReportsComment>;
  createComment: (values: NewCommentFormValues, target?: number) => void;
  deleteComment: (id: number) => Promise<any>;
  currentUsername: string | null;
  permissions: Enum.User_PERMISSION[];
}

const NEW_COMMENT_TYPES = [
  Enum.Comment_RESOURCE_TYPE.area,
  Enum.Comment_RESOURCE_TYPE.site,
  Enum.Comment_RESOURCE_TYPE.obsPoint,
];

export function CommentsListView(props: CommentsListViewProps) {
  const { reportProps, permissions, createComment } = props;

  const columns = useMemo(() => {
    const canEditAllComments = permissions.includes(
      Enum.User_PERMISSION.can_edit_all_comments
    );
    const canEditOwnComments = permissions.includes(
      Enum.User_PERMISSION.can_edit_own_comments
    );
    return commentsListColumns({
      deleteComment: props.deleteComment,
      refreshList: reportProps.refreshList,
      currentUsername: props.currentUsername,
      canEditAllComments,
      canEditOwnComments,
    });
  }, [
    permissions,
    props.currentUsername,
    props.deleteComment,
    reportProps.refreshList,
  ]);

  // Change the displayed columns when certain filters are enabled.
  const updateColumnsOnFilterChange = useCommentReportAutoColumns(columns);

  return (
    <PageStandard name="listcomments" header={<Trans>Maintain Comments</Trans>}>
      <div className="page-content-header-filters-actions">
        {canCreateComments(NEW_COMMENT_TYPES, permissions) && (
          <ActionBlock className="text-right">
            <ButtonShowPanel
              name="new-comment-button"
              iconType="icon-plus"
              panelContent={({ hidePanel }) => (
                <PanelContent header={<Trans>Add comment</Trans>}>
                  <NewCommentForm
                    permissions={permissions}
                    timeZone={undefined}
                    type={NEW_COMMENT_TYPES}
                    onCancel={hidePanel}
                    onSubmit={createComment}
                  />
                </PanelContent>
              )}
            >
              <Trans>New comment</Trans>
            </ButtonShowPanel>
          </ActionBlock>
        )}
        <ReportFiltersBlock
          filtersFrontend={commentsListFilters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
          filtersToShow={[
            'observation_point',
            'resourcetype',
            'comment_type',
            'area',
            'batch_comments',
          ]}
          onFiltersChange={updateColumnsOnFilterChange}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={commentsListFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Comments report`}
            reportUrl="/reports/comments/"
            columns={columns}
            filters={commentsListFilters}
          />
          <AdvancedFiltersModalButton
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={commentsListFilters}
            onFiltersChange={updateColumnsOnFilterChange}
            sections={[
              {
                name: 'types',
                filters: ['resourcetype', 'comment_type', 'commenter'],
              },
              {
                name: 'subjects',
                filters: [
                  'observation_point',
                  'site',
                  'area',
                  'alarm_report__alarm_number__in',
                  'readings_batch__batch_number',
                  'route_march',
                ],
              },
              { name: 'date', filters: ['created_datetime', 'batch_comments'] },
            ]}
          />
        </ActionBlock>
        <ReportTable
          columns={columns}
          filters={commentsListFilters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 comment"
              other="Filtered to # comments"
            />
          )}
          msgNoMatches={<Trans>No comments match the selected filters.</Trans>}
        />
      </div>
    </PageStandard>
  );
}

export const commentsListFilters: ReportFilter[] = [
  reportFilterObservationPoints(AUTO_COLUMN_FILTER.observationPoint, {
    autoFocus: true,
  }),
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All areas</Trans> }
  ),
  reportFilterNumber(
    AUTO_COLUMN_FILTER.alarmNumber,
    <Trans>Alarm number</Trans>,
    {
      placeholder: t`All alarm numbers`,
    }
  ),
  reportFilterMenu(
    'resourcetype',
    <Trans>Data type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('Comment_RESOURCE_TYPE'),
    {
      placeholder: <Trans>All data types</Trans>,
    }
  ),
  reportFilterMenu(
    'comment_type',
    <Trans>Comment type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('Comment_TYPE'),
    {
      placeholder: <Trans>All comment types</Trans>,
    }
  ),
  reportFilterUsers('commenter', <Trans>Commenter</Trans>),
  reportFilterDatetime('created_datetime', <Trans>Comment date</Trans>),
  reportFilterAsyncMenu(
    AUTO_COLUMN_FILTER.batch,
    <Trans>Batch number</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (inputValue: string) =>
        getApi('/reports/readings-batches/', {
          batch_number__startswith: inputValue,
          ordering: ['-batch_number'],
          columns: ['batch_number'],
        }).then((batches) =>
          batches.map(({ batch_number }) => ({
            value: batch_number,
            label: batch_number,
          }))
        ),
      loadDefaults: (initialValue: number[]) =>
        getApi('/reports/readings-batches/', {
          batch_number__in: initialValue,
          columns: ['batch_number'],
        }).then((batches) =>
          batches.map(({ batch_number }) => ({
            value: batch_number,
            label: batch_number,
          }))
        ),
    },
    {
      placeholder: <Trans>All batch numbers</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterAsyncMenu(
    'route_march',
    <Trans>Route march</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (inputValue: string) =>
        getApi('/route-marches/', {
          code__icontains: inputValue,
        }).then((rms) =>
          rms.map(({ code, id }) => ({
            value: id,
            label: code,
          }))
        ),
      loadDefaults: (inputValue: string) =>
        getApi('/route-marches/', {
          id__in: inputValue,
        }).then((rms) =>
          rms.map(({ code, id }) => ({
            value: id,
            label: code,
          }))
        ),
    },
    {
      placeholder: <Trans>All route marches</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterAsyncMenu(
    AUTO_COLUMN_FILTER.site,
    <Trans>Site</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (inputValue: string) =>
        getApi('/sites/', {
          code__icontains: inputValue,
        }).then((rms) =>
          rms.map(({ code, id }) => ({
            value: id,
            label: code,
          }))
        ),
      loadDefaults: (inputValue: number[]) =>
        getApi('/sites/', {
          id__in: inputValue,
        }).then((rms) =>
          rms.map(({ code, id }) => ({
            value: id,
            label: code,
          }))
        ),
    },
    {
      placeholder: <Trans>All sites</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterShowHideMenu('batch_comments', <Trans>Batch comments</Trans>),
];

export function commentsListColumns({
  deleteComment,
  refreshList,
  currentUsername,
  canEditAllComments,
  canEditOwnComments,
}: {
  deleteComment?: (id: number) => Promise<any>;
  refreshList: () => void;
  currentUsername: string | null;
  canEditAllComments: boolean;
  canEditOwnComments: boolean;
}): ReportColumn<Model.ReportsComment>[] {
  return [
    {
      label: <Trans>Data type</Trans>,
      name: 'resourcetype',
      visibility: DEFAULT_SHOW,
      accessor: (row) => (
        <TransEnum enum="Comment_RESOURCE_TYPE" value={row.resourcetype} />
      ),
    },
    {
      label: <Trans>Comment type</Trans>,
      name: 'comment_type',
      visibility: DEFAULT_SHOW,
      accessor: (row) => (
        <TransEnum enum="Comment_TYPE" value={row.comment_type} />
      ),
    },
    {
      label: <Trans>Date and time</Trans>,
      name: 'created_datetime',
      visibility: DEFAULT_SHOW,
      accessor: ({ created_datetime: value }) =>
        formatDatetimeForDisplay(value), // datetime is relative to user
    },
    {
      label: <Trans>Gap date and time</Trans>,
      name: 'gap',
      backendFieldName: 'at_datetime',
      additionalFields: ['time_zone_name'],
      visibility: DEFAULT_HIDE,
      accessor: ({ at_datetime, time_zone_name: timeZone }) =>
        at_datetime && formatDatetimeForDisplay(at_datetime, timeZone),
    },
    {
      label: <Trans>Commenter</Trans>,
      name: 'commenter__name',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Area</Trans>,
      name: 'area_code',
      visibility: DEFAULT_SHOW,
      additionalFields: ['area_name'],
      accessor: ({ area_code, area_name }) =>
        area_code ? `${area_code} - ${area_name}` : '',
    },
    {
      label: <Trans>Site</Trans>,
      name: AUTO_COLUMN.site,
      backendFieldName: 'site_code',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Observation points</Trans>,
      name: AUTO_COLUMN.observationPoint,
      backendFieldName: 'observation_points',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Batch number</Trans>,
      name: AUTO_COLUMN.batch,
      backendFieldName: 'batch_number',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Alarm number</Trans>,
      name: AUTO_COLUMN.alarmNumber,
      backendFieldName: 'alarm_report__alarm_number',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Comment</Trans>,
      name: 'content',
      backendFieldName: 'content',
      visibility: DEFAULT_SHOW,
      tdClassName: 'text-with-linebreaks',
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['id', 'commenter__username', 'time_zone_name'],
      accessor: ({ id, commenter__username, time_zone_name: timeZone }) => {
        return (
          <>
            {canEditAllComments ||
            (canEditOwnComments &&
              currentUsername !== null &&
              currentUsername === commenter__username) ? (
              <ButtonShowModal
                name="edit-comment"
                iconType="icon-edit"
                className="btn-link-panel"
                iconOnly={true}
                title={t`Edit`}
                modalContent={({ hideModal }: any) => (
                  <EditCommentModal
                    commentId={id}
                    timeZone={timeZone}
                    onAfterSubmit={() => {
                      hideModal();
                      refreshList();
                    }}
                  />
                )}
              >
                <Trans>Edit</Trans>
              </ButtonShowModal>
            ) : null}
            <HasPermission check="can_delete_comments">
              <ButtonShowConfirmation
                name="delete-comment"
                title={t`Delete`}
                iconType="icon-circle-minus"
                className="btn-link-panel"
                content={
                  <Trans>
                    Are you sure you want to delete this comment? This action is
                    not reversible.
                  </Trans>
                }
                okBtnText={<Trans>Yes, delete</Trans>}
                onConfirm={async function () {
                  await deleteComment!(id!);
                }}
              />
            </HasPermission>
          </>
        );
      },
    },
  ];
}
export default CommentsListView;
