import React from 'react';
import classNames from 'classnames';
// Note: The icon CSS styles are held in "base" because they may be used for
// styling other elements via CSS classes directly.
import '../../../basestyles/_icons.scss';
import { I18n } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';

export const ICONS = [
  'icon-download',
  'icon-marker-x',
  'icon-marker-asterisk',
  'icon-marker-plus',
  'icon-marker-rhombus-solid',
  'icon-marker-rhombus',
  'icon-marker-square-solid',
  'icon-marker-square',
  'icon-marker-diamond-solid',
  'icon-marker-diamond',
  'icon-marker-circle-solid',
  'icon-marker-circle',
  'icon-marker-triangle-solid',
  'icon-marker-triangle',
  'icon-clipboard-arrow-left',
  'icon-marker-water-level-baseline',
  'icon-marker-dam-levelling',
  'icon-marker-deformation',
  'icon-marker-deformation-redundant',
  'icon-marker-observation-well',
  'icon-marker-piezometer-tip',
  'icon-marker-water-level',
  'icon-menu',
  'icon-cross-hair',
  'icon-offline',
  'icon-online',
  'icon-envelope',
  'icon-at',
  'icon-pause',
  'icon-camera',
  'icon-not-a-number',
  'icon-phone',
  'icon-tick-outline',
  'icon-clipboard-tick',
  'icon-exchange',
  'icon-magnifing-glass-plus',
  'icon-mute',
  'icon-formula',
  'icon-multimedia',
  'icon-parameter',
  'icon-route-march',
  'icon-sliders',
  'icon-sort',
  'icon-sort-ascend',
  'icon-sort-descend',
  'icon-resume',
  'icon-switch',
  'icon-house',
  'icon-readings',
  'icon-observation-point',
  'icon-view',
  'icon-arrow-double-down',
  'icon-arrow-double-up',
  'icon-reorder',
  'icon-past',
  'icon-csv',
  'icon-minus-solid',
  'icon-plus',
  'icon-star',
  'icon-tick',
  'icon-upload',
  'icon-area',
  'icon-members',
  'icon-data-loggers',
  'icon-spanner',
  'icon-pdf',
  'icon-png',
  'icon-format',
  'icon-filter',
  'icon-arrow-rounded-left',
  'icon-play',
  'icon-cross',
  'icon-audit-log',
  'icon-comment',
  'icon-site',
  'icon-new-reading',
  'icon-arrow-double-left',
  'icon-arrow-double-right',
  'icon-arrow-down',
  'icon-arrow-left',
  'icon-arrow-right',
  'icon-arrow-up',
  'icon-bell',
  'icon-bell-solid',
  'icon-calendar',
  'icon-circle',
  'icon-circle-info',
  'icon-circle-minus',
  'icon-circle-plus',
  'icon-circle-tick',
  'icon-cog',
  'icon-detach',
  'icon-edit',
  'icon-exclamation-solid',
  'icon-export',
  'icon-info-solid',
  'icon-list',
  'icon-lock',
  'icon-log-in',
  'icon-log-out',
  'icon-plot',
  'icon-print',
  'icon-processing',
  'icon-save',
  'icon-tick-solid',
  'icon-update',
  'icon-user',
  'icon-audio-file',
] as const;

export type IconType = typeof ICONS[keyof typeof ICONS];

export type IconProps = Merge<
  React.HTMLAttributes<HTMLSpanElement>,
  {
    type: IconType;
    title?: string | MessageDescriptor;
    className?: string;
  }
>;

/**
 * Renders a single icon.
 *
 * This component is based on the React "Single Element Pattern", so all
 * additional props passed to it will be passed through to the underlying
 * HTML element that it renders.
 *
 * see: https://github.com/diegohaz/singel
 *
 * @export
 * @class Icon
 * @extends {React.PureComponent}
 */
export class Icon extends React.PureComponent<IconProps> {
  render() {
    const { className, type, title, ...otherProps } = this.props;

    const iconWithoutTitle = (
      <span
        {...otherProps}
        className={classNames(type as string, className)}
        role="presentation"
      />
    );
    if (!title) {
      return iconWithoutTitle;
    } else if (typeof title === 'string') {
      return this.renderWithTitle(iconWithoutTitle, title);
    } else {
      return (
        <I18n>
          {({ i18n }) => {
            let translatedTitle = i18n._(title as any);
            return this.renderWithTitle(iconWithoutTitle, translatedTitle);
          }}
        </I18n>
      );
    }
  }

  renderWithTitle(icon: JSX.Element, title: string) {
    const iconWithTitle = React.cloneElement(icon, {
      title,
    });
    return (
      <>
        {iconWithTitle}
        <span className="visuallyhidden">{title}</span>
      </>
    );
  }
}
