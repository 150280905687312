import React from 'react';
import { Trans } from '@lingui/macro';
import { AlertDanger } from 'components/base/alert/alert';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import './fileuploader.scss';

interface FileUploaderViewProps {
  isUploading: boolean;
  errorMessage: null | string;
  handleFileSelection: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const FileUploaderView: React.FunctionComponent<FileUploaderViewProps> =
  function (props) {
    const header = (
      <h3>
        <Trans>Upload readings</Trans>
      </h3>
    );

    return (
      <HasPermission
        check="can_upload_raw_readings"
        noPermissionMsg={
          <div className="file-upload">
            {header}
            <div data-testid="uploads-disabled-message">
              <Trans>
                <p className="file-upload-message">
                  Uploads are currently disabled.
                </p>
                <p>Please call DSI on 04 382 7590 to upload readings.</p>
              </Trans>
            </div>
          </div>
        }
      >
        <div className="file-upload file-upload-has-permission">
          {header}
          {props.errorMessage && (
            <AlertDanger>{props.errorMessage}</AlertDanger>
          )}
          <input
            data-testid="button_upload-readings-file"
            name="uploadButton"
            type="file"
            onChange={props.handleFileSelection}
            disabled={props.isUploading}
            multiple={true}
          />
        </div>
      </HasPermission>
    );
  };
