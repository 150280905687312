import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import {
  AreaDetailView,
  AreaDetailProps,
  AreaFormValues,
  CivilFeaturesFormValues,
} from './AreaDetailView';
import {
  fetchArea,
  updateArea,
  ActionCreators,
  createArea,
} from 'ducks/area/detail';
import { areaListDuck } from 'ducks/area/list';
import {
  parseStringParamFromRouterProps,
  RCPWithQueryParams,
  parseNumberQueryParamFromRouterProps,
  setQueryParams,
  currentQueryString,
} from 'util/routing';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import { FormikHelpers } from 'formik';
import {
  fetchEntityList,
  EntityTypes,
  selectAllInOrderedArray,
} from 'ducks/entities';
import { patchApi } from 'util/backendapi/fetch';
import { CivilFeature_STATUS } from 'util/backendapi/types/Enum';

class _AreaDetail extends React.Component<Props> {
  private _isMounted: boolean;

  constructor(props: Props) {
    super(props);
    this._isMounted = false;
  }

  static mapStateToProps(state: FullState, ownProps: RouterProps): StateProps {
    const clientFilter = state.area.list.reportInfo
      ? state.area.list.reportInfo.filters.find(
          (filter) => filter.name === 'client'
        )
      : null;
    const clients = clientFilter ? clientFilter.options : null;
    return {
      area: state.area.detail.area,
      civilFeatures: state.area.detail.civilFeatures,
      isLoading: state.area.detail.loading,
      isEditing: Boolean(
        parseNumberQueryParamFromRouterProps(ownProps, 'edit')
      ),
      fetchErrorMessage: state.area.detail.errorMessage,
      hasCommentsButton: state.user.permissions.includes(
        Enum.User_PERMISSION.can_view_area_report
      ),
      hasCreateAreaPermission: state.user.permissions.includes(
        Enum.User_PERMISSION.can_create_areas
      ),
      timeZones: selectAllInOrderedArray(state, EntityTypes.TIMEZONE),
      clients,
    };
  }

  static mapDispatchToProps = {
    fetchArea,
    fetchAreaListInfo: areaListDuck.fetchReportInfo,
    fetchEntityList,
    updateArea,
    createArea,
    unmountMaintainAreaScreen: ActionCreators.CLEAR_AREA_FORM,
  };

  handleSubmit = async (
    values: AreaFormValues,
    formik: FormikHelpers<AreaFormValues>
  ) => {
    const areaCode = parseStringParamFromRouterProps(this.props, 'areaCode');
    const shouldChangeUrl = values.code !== areaCode;

    if (this.props.area) {
      await this.props.updateArea(this.props.area.id, values);
    } else {
      await this.props.createArea(values);
    }

    if (!this._isMounted) return false;

    if (shouldChangeUrl) {
      const qs = currentQueryString(this.props.location, { exclude: ['edit'] });
      this.props.history.replace(
        `/area/${encodeURIComponent(values.code)}${qs}`
      );
    } else {
      setQueryParams(this.props, { edit: null });
    }

    return true;
  };

  handleCivilFeaturesSubmit = async (
    values: CivilFeaturesFormValues,
    formik: FormikHelpers<CivilFeaturesFormValues>
  ) => {
    const areaId = this.props.area!.id;
    await this.props.updateArea(areaId, {
      civil_features: values.civil_features.map(({ id, name, status }) =>
        id > 0
          ? {
              id,
              name,
              status,
              area: areaId,
            }
          : { name, area: areaId, status }
      ),
    });
  };

  retireCivilFeature = async (id: number) => {
    await patchApi(`/civil-features/${id}/`, {
      status: CivilFeature_STATUS.retired,
    });
  };

  handleStartEditing = () => {
    setQueryParams(this.props, { edit: 1 });
  };

  handleStopEditing = () => {
    if (this.props.area) {
      setQueryParams(this.props, { edit: null });
    } else {
      this.props.history.push(`/area/`);
    }
  };

  render() {
    return (
      <AreaDetailView
        isLoading={this.props.isLoading}
        isEditing={this.props.isEditing}
        fetchErrorMessage={this.props.fetchErrorMessage}
        handleSubmit={this.handleSubmit}
        handleCivilFeaturesSubmit={this.handleCivilFeaturesSubmit}
        onRetireCivilFeature={this.retireCivilFeature}
        area={this.props.area ? this.props.area : null}
        civilFeatures={this.props.civilFeatures}
        timeZones={this.props.timeZones}
        clients={this.props.clients}
        hasCreateAreaPermission={this.props.hasCreateAreaPermission}
        hasCommentsButton={this.props.hasCommentsButton}
        onStartEditing={this.handleStartEditing}
        onStopEditing={this.handleStopEditing}
      />
    );
  }

  componentDidMount() {
    const areaCode = parseStringParamFromRouterProps(this.props, 'areaCode');
    if (areaCode && areaCode !== 'new') {
      this.props.fetchArea(areaCode);
    }

    // there are two ways of fetching list the of clients for the maintain area screen
    // * using /api/clients
    // * using /reports/areas/info to extract the option from client filters
    //
    // The first endpoint is mainly used for DMS admin to add/remove client, with
    // a different set of permissions (can-create-client, can-edit-client etc.).
    //
    // We need to go with the second option, because the list of clients will be
    // filtered for the area group current user is associated with.
    //
    // Just one thing to keep in mind is that user will need `can-view-areas-report` for
    // the report info endpoint to work
    //
    if (!this.props.clients) {
      this.props.fetchAreaListInfo();
    }
    this._isMounted = true;

    this.props.fetchEntityList(EntityTypes.TIMEZONE);
  }

  componentDidUpdate(prevProps: Props) {
    const prevAreaCode = parseStringParamFromRouterProps(prevProps, 'areaCode');
    const areaCode = parseStringParamFromRouterProps(this.props, 'areaCode');
    if (areaCode !== prevAreaCode) {
      this.props.fetchArea(areaCode);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.unmountMaintainAreaScreen();
  }
}

type StateProps = Omit<
  AreaDetailProps,
  | 'handleSubmit'
  | 'handleCivilFeaturesSubmit'
  | 'onRetireCivilFeature'
  | 'onStartEditing'
  | 'onStopEditing'
>;
type DispatchProps = ResolveThunks<typeof _AreaDetail.mapDispatchToProps>;
type RouterProps = RCPWithQueryParams<{ edit: string }, { areaCode: string }>;
type Props = StateProps & DispatchProps & RouterProps;

export const AreaDetail = connect<
  StateProps,
  DispatchProps,
  RouterProps,
  FullState
>(
  _AreaDetail.mapStateToProps,
  _AreaDetail.mapDispatchToProps as any
)(_AreaDetail);
