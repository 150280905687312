import moment from 'moment-timezone';
import { Pagination, PaginationResponseData } from './pagination';
import { Endpoint } from './models/api.interfaces';
import { DRFError } from './error';
import { AUTH_GLOBALS } from './auth-globals';

export interface PaginatedResponse<T> {
  pagination: null | PaginationResponseData;
  data: T[];
}

/**
 * Helper functions for contacting our backend REST api.
 */

const BACKENDAPI = (function () {
  if (process.env.NODE_ENV === 'test') {
    // During unit testing, use a completely fake backend base URL.
    // TODO: provide a way to specify the test backend URL, from within the
    // test file?
    return 'https://example.com';
  } else if (process.env.REACT_APP_BACKEND_BASE_URL) {
    return process.env.REACT_APP_BACKEND_BASE_URL;
  } else {
    return '/api';
  }
})();

/**
 * Helper function to consistently prepend our backend API's base URL to and
 * endpoint path.
 *
 * NOTE: This is applied automatically by fetchJsonEndpoint(), so you only
 * need to call it if you're doing fetch() directly.
 *
 * @export
 * @param {string} endpoint Must start with "/". The function doesn't actually
 * enforce this, but will print an error message about it, in the dev & test
 * environments.
 * @returns {string}
 */
export function backendUrl(endpoint: string) {
  // In Dev, provide some helpful error messages for when the endpoint
  // is not specified correctly. Following the philosophy of Prop-Types,
  // it won't actually correct the problem, just inform the developer about it.
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    if (endpoint.startsWith(BACKENDAPI)) {
      // eslint-disable-next-line no-console
      console.trace(
        `WARNING: endpoint URL passed to backendUrl() must be relative to "${BACKENDAPI}": "${endpoint}"`
      );
    } else if (endpoint.charAt(0) !== '/') {
      // Check whether the endpoint URL starts with a "/"
      // eslint-disable-next-line no-console
      console.trace(
        `WARNING: endpoint URL passed to backendUrl() must start with "/": "${endpoint}"`
      );
    } else {
      let endOfPath = '';
      const startOfQuery = endpoint.indexOf('?');
      // -1 means there is no query section
      // 0 means the query starts at the beginning of the string
      // So we can just check "startOfQuery > 0"
      if (startOfQuery > 0) {
        endOfPath = endpoint.charAt(startOfQuery - 1);
      } else if (endpoint.length > 0) {
        endOfPath = endpoint.charAt(endpoint.length - 1);
      }

      if (endOfPath !== '/') {
        // eslint-disable-next-line no-console
        console.trace(
          // If the URL does not end with "/", Django gives a 3xx redirect response,
          // which causes IE11 to error out.
          // TODO: The plotting endpoints don't follow this pattern. But maybe they should?
          // That is to say, perhaps we should be more consistent in making sure
          // that frontend URLs end in "/"?
          `WARNING: endpoint URL's path component must end with "/": "${endpoint}"`
        );
      }
    }
  }

  return `${BACKENDAPI}${endpoint}`;
}

/**
 * I keep having to write this same code over and over. So I'm extracting it
 * out into a helper function.
 *
 * @export
 * @param {string} endpoint The relative path of the endpoint, starting with "/"
 * e.g. "/readings-files/"
 * @param {object} [options={}]
 * @param {boolean} [withAuth=true]
 * @param {function} [handleErrorResponse] async callback for handling the error response in
 * a specialised fashion if required.
 * @returns
 */
export async function fetchJsonEndpoint(
  endpoint: string,
  options: any = {},
  withAuth: boolean = true,
  handleErrorResponse?: (response: any) => void
) {
  let derivedOptions = {
    method: 'GET',
    ...options,
    headers: {
      Accept: 'application/json',
      ...options.headers,
    },
  };
  if (withAuth) {
    derivedOptions = withAuthHeaders(derivedOptions);
  }
  const response = await fetch(backendUrl(endpoint), derivedOptions);

  if (!response || !response.ok) {
    if (handleErrorResponse) {
      return await handleErrorResponse(response);
    }
    // TODO: translate this?
    throw new Error(await parseResponseError(response));
  }

  if (options.responseHandler) {
    options.responseHandler(response);
  }

  // HTTP status 204 - Success, no response body
  if (response.status === 204) {
    return null;
  }

  return await response.json();
}

async function parseResponseError(response: any) {
  let errorMessage = 'Error!';
  try {
    const errorJson = await response.json();
    if (errorJson.detail) {
      errorMessage = errorJson.detail;
    } else if (errorJson.non_field_errors) {
      errorMessage = errorJson.non_field_errors;
    } else {
      throw new Error();
    }
  } catch (e) {
    errorMessage = `${response.status} ${response.statusText}`;
  }
  return errorMessage;
}

/**
 * Fetch from a JSON endpoint supporting limit/offset pagination.
 *
 * @export
 * @param {string} endpoint The relative path of the backend endpoint.
 * @returns {{data: object, pagination: object|null}} Returns an object containing the JSON parsed from the backend as well as a `Pagination` object with the current page index and number of accessible pages.
 *
 */

export async function fetchPaginatedJsonEndpoint<TResponse = any>(
  endpoint: string
): Promise<{
  pagination: PaginationResponseData | null;
  data: TResponse;
}> {
  const options = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  };

  const response = await fetch(backendUrl(endpoint), withAuthHeaders(options));

  if (!response || !response.ok) {
    throw new Error(await parseResponseError(response));
  }

  const pagination = Pagination.fromBackendResponse(response);
  const data = await response.json();

  return { pagination, data };
}

/**
 * A utility function to add the necessary headers that we need to add to
 * every authenticated endpoint of our backend.
 *
 * TODO: It's not really necessary to export this function, since all HTTP
 * requests should be done in actions, and all actions are in this file.
 * But I have to export it in order to unit test it.
 *
 * @export
 * @param {Object} [reqOptions={}] A "fetch(url, options)" options object.
 * @returns {Object} A shallow-cloned copy of reqOptions, with the headers added.
 */
export function withAuthHeaders(reqOptions: any = {}) {
  if (!AUTH_GLOBALS.dmsAccessToken && !AUTH_GLOBALS.activeAreaGroupId) {
    return reqOptions;
  }

  const newHeaders = reqOptions.headers ? { ...reqOptions.headers } : {};
  if (AUTH_GLOBALS.dmsAccessToken) {
    newHeaders.Authorization = `Bearer ${AUTH_GLOBALS.dmsAccessToken}`;
  }
  if (AUTH_GLOBALS.activeAreaGroupId) {
    // Area-Group is a custom HTTP header used by our backend API to identify
    // which group (of the ones the user belongs to) should be used for access
    // control.
    newHeaders['Area-Group'] = AUTH_GLOBALS.activeAreaGroupId;
  }

  // Add user's timezone to request, in case the backend needs to render
  // datetimes in user time (generally only needed for CSV exports)
  newHeaders['User-Time-Zone'] =
    process.env.NODE_ENV === 'test'
      ? // When running unit tests, hard-code the timezone to Pacific/Auckland
        // to avoid problems with test runners having different locales.
        'Pacific/Auckland'
      : moment.tz.guess(true);

  return {
    ...reqOptions,
    headers: newHeaders,
  };
}

/**
 * The types of data we can process in `prepareApiQueryParams`
 */
type ApiQueryParamValue =
  | string
  | string[]
  | number
  | number[]
  | boolean
  | boolean[]
  | undefined
  | null;
export type ApiQueryParams = {
  [K in string]?: ApiQueryParamValue;
};

/**
 * Combines the base backend endpoint URL, and an optional object of query params,
 * into a final absolute URL to the endpoint.
 *
 * @param url
 * @param params
 */
function prepareGetUrl(url: string, params?: ApiQueryParams): string {
  const queryString = prepareApiQueryParams(params).toString();

  if (queryString) {
    url = url + `?${queryString}`;
  }

  return url;
}

/**
 * Converts one of our query param objects into a `URLSearchParams` instance.
 * It includes handling for some common patterns we use in the query params:
 *
 * - If a param's value is `null`, `undefined`, or `''`, it omits that param
 *
 * - If a param's value is an array, it turns that into a comma-separated list
 * (this is a Django Rest Framework thing)
 *
 * - Otherwise, it just casts the value to a string. (This includes casting
 * booleans to the strings "true" and "false", which is compatible with Django
 * Rest Framework.)
 *
 * @see https://www.django-rest-framework.org/api-guide/filtering/
 */
export function prepareApiQueryParams(
  params: ApiQueryParams | undefined
): URLSearchParams {
  const query = new URLSearchParams();
  if (params) {
    Object.entries(params).forEach(([paramName, paramValue]) => {
      if (Array.isArray(paramValue)) {
        // Array - turn into comma-separated list
        const arrayValues = (paramValue as unknown[]).filter(
          _isValidQueryParam
        );
        if (arrayValues.length > 0) {
          query.set(paramName, arrayValues.join(','));
        }
      } else if (_isValidQueryParam(paramValue)) {
        query.set(paramName, String(paramValue));
      }
    });
  }
  return query;
}

function _isValidQueryParam(value: unknown): boolean {
  return value !== undefined && value !== null && value !== '';
}

/**
 * If the "error" response from the server contains a parseable JSON response,
 * then we parse and throw that response.
 *
 * If not, we throw a normal JS Error object, with a message created by combining
 * the response's status code and status text.
 *
 * In usage, this means that your try/catch block may need to check
 * `(e instanceof Error)` to distinguish between these cases.
 * @param response
 */
async function parseAndThrowError<TRequestBody = any>(
  response: any
): Promise<void> {
  let errors: DRFError<TRequestBody>;
  try {
    errors = await response.json();
  } catch (e) {
    throw new Error(
      response && (response.status || response.statusText)
        ? `${response.status} ${response.statusText}`
        : String(response)
    );
  }

  if (errors) {
    throw errors;
  }
}

export async function getApi<T extends keyof Endpoint.GET>(
  url: T,
  params?: Endpoint.GET[T]['params'],
  extraOptions?: {
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<Endpoint.GET[T]['result']> {
  const options = withAuthHeaders({
    method: 'GET',
    headers: {
      Accept: 'application/json',
      ...(extraOptions && extraOptions.headers ? extraOptions.headers : null),
    },
  });

  const urlWithQueryParams = prepareGetUrl(url, params as ApiQueryParams);
  const getFullUrl =
    extraOptions && extraOptions.backendUrl
      ? extraOptions.backendUrl
      : backendUrl;

  const response = await fetch(getFullUrl(urlWithQueryParams), options);

  if (!response || !response.ok) {
    await parseAndThrowError(response);
  }

  return response.status === 204 ? null : response.json();
}

export async function getPaginated<T extends keyof Endpoint.GET>(
  url: T,
  params?: Endpoint.GET[T]['params'],
  extraOptions?: {
    method?: string;
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<
  PaginatedResponse<
    Endpoint.GET[T]['result'] extends Array<infer E>
      ? E
      : Endpoint.GET[T]['result']
  >
> {
  const method = extraOptions?.method ?? 'GET';
  const options = withAuthHeaders({
    method,
    headers: {
      Accept: 'application/json',
      ...extraOptions?.headers,
    },
  });

  const urlWithQueryParams = prepareGetUrl(url, params as ApiQueryParams);
  const getFullUrl = extraOptions?.backendUrl ?? backendUrl;

  const response = await fetch(getFullUrl(urlWithQueryParams), options);

  if (!response || !response.ok) {
    await parseAndThrowError(response);
  }

  const data =
    response.status === 204 || method === 'HEAD' ? null : await response.json();

  return {
    pagination: Pagination.fromBackendResponse(response),
    data,
  };
}

/**
 * Retrieve just the pagination metadata headers, using a HEAD request
 *
 * @param url
 * @param params
 * @param extraOptions
 * @returns
 */
export async function fetchPaginationMetadata<T extends keyof Endpoint.GET>(
  url: T,
  params?: Endpoint.GET[T]['params'],
  extraOptions?: {
    method?: string;
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<PaginationResponseData> {
  const response = await getPaginated(
    url,
    { ...params, limit: 1, offset: 0 },
    { ...extraOptions, method: 'HEAD' }
  );
  if (!response.pagination) {
    throw new Error(`Endpoint ${url} did not return pagination metadata`);
  }
  return response.pagination;
}

export async function postApiFormData<T extends keyof Endpoint.POST>(
  url: T,
  formData: FormData,
  extraOptions?: {
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<Endpoint.POST[T]['result']> {
  const headers = {
    Accept: 'application/json',
    ...(extraOptions?.headers ?? null),
  };

  const options = withAuthHeaders({
    method: 'POST',
    headers,
    body: formData,
  });

  const getFullUrl = extraOptions?.backendUrl ?? backendUrl;
  const response = await fetch(getFullUrl(url), options);

  if (!response || !response.ok) {
    await parseAndThrowError(response);
  }

  return response.status === 204 ? null : response.json();
}

export async function patchApiFormData<T extends keyof Endpoint.PATCH>(
  url: T,
  formData: FormData,
  extraOptions?: {
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<Endpoint.PATCH[T]['result']> {
  const headers = {
    Accept: 'application/json',
    ...(extraOptions?.headers ?? null),
  };

  const options = withAuthHeaders({
    method: 'PATCH',
    headers,
    body: formData,
  });

  const getFullUrl = extraOptions?.backendUrl ?? backendUrl;
  const response = await fetch(getFullUrl(url), options);

  if (!response || !response.ok) {
    await parseAndThrowError(response);
  }

  return response.status === 204 ? null : response.json();
}

export async function postApi<T extends keyof Endpoint.POST>(
  url: T,
  params?: Endpoint.POST[T]['params'],
  extraOptions?: {
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<Endpoint.POST[T]['result']> {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(extraOptions && extraOptions.headers ? extraOptions.headers : null),
  };

  const options = withAuthHeaders({
    method: 'POST',
    headers,
    body:
      headers['Content-Type'] === 'application/json'
        ? JSON.stringify(params)
        : params,
  });

  const getFullUrl =
    extraOptions && extraOptions.backendUrl
      ? extraOptions.backendUrl
      : backendUrl;

  const response = await fetch(getFullUrl(url), options);

  if (!response || !response.ok) {
    await parseAndThrowError(response);
  }

  return response.status === 204 ? null : response.json();
}

export async function patchApi<T extends keyof Endpoint.PATCH>(
  url: T,
  params: Endpoint.PATCH[T]['params'],
  extraOptions?: {
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<Endpoint.PATCH[T]['result']> {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(extraOptions && extraOptions.headers ? extraOptions.headers : null),
  };

  const options = withAuthHeaders({
    method: 'PATCH',
    headers,
    body:
      headers['Content-Type'] === 'application/json'
        ? JSON.stringify(params)
        : params,
  });

  const getFullUrl =
    extraOptions && extraOptions.backendUrl
      ? extraOptions.backendUrl
      : backendUrl;
  const response = await fetch(getFullUrl(url), options);

  if (!response || !response.ok) {
    await parseAndThrowError(response);
  }

  return response.status === 204 ? null : response.json();
}

export async function deleteApi<T extends keyof Endpoint.DELETE>(
  url: T,
  params?: Endpoint.DELETE[T]['params'],
  extraOptions?: {
    headers?: any;
    backendUrl?: (url: string) => string;
  }
): Promise<Endpoint.DELETE[T]['result']> {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(extraOptions && extraOptions.headers ? extraOptions.headers : null),
  };

  const options = withAuthHeaders({
    method: 'DELETE',
    headers,
    body:
      headers['Content-Type'] === 'application/json'
        ? JSON.stringify(params)
        : params,
  });

  const getFullUrl =
    extraOptions && extraOptions.backendUrl
      ? extraOptions.backendUrl
      : backendUrl;
  const response = await fetch(getFullUrl(url), options);

  if (!response || !response.ok) {
    await parseAndThrowError(response);
  }

  return response.status === 204 ? null : response.json();
}
