import React from 'react';
import {
  ClientAreasPanelProps,
  ClientAreasPanelView,
} from './ClientAreasPanelView';
import { FullState } from 'main/reducers';
import { fetchClientAreas, updateClientAreas } from 'ducks/client/areas';
import { ResolveThunks, connect } from 'react-redux';
import { selectHasPermission } from 'util/user';
import { Enum } from 'util/backendapi/models/api.interfaces';

interface OwnProps {
  client: ClientAreasPanelProps['client'];
}
type StateProps = Pick<
  ClientAreasPanelProps,
  'canEdit' | 'clientAreas' | 'areasWithNoClient' | 'isLoading'
> & {
  key: number;
};

function mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
  return {
    // Specify ClientID as component's key to make sure it unmounts when you
    // switch to a modal for a different client.
    canEdit: selectHasPermission(
      state,
      // Client areas are actually a property of the area, not the client,
      // so it's dependent on area permissions.
      Enum.User_PERMISSION.can_create_areas
    ),
    key: ownProps.client.id,
    isLoading: state.client.areas.loading,
    clientAreas: state.client.areas.clientAreas || [],
    areasWithNoClient: state.client.areas.areasWithNoClient || [],
  };
}

const mapDispatchToProps = {
  fetchClientAreas,
  updateClientAreas,
};

type DispatchProps = typeof mapDispatchToProps;

interface OwnState {
  isEditing: boolean;
}

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;
class ClientAreasPanelInner extends React.Component<Props, OwnState> {
  _isMounted = false;

  state: OwnState = {
    isEditing: false,
  };

  render() {
    return (
      <ClientAreasPanelView
        {...this.props}
        isEditing={this.state.isEditing}
        onEdit={() => this.setState({ isEditing: true })}
        onCancel={() => this.setState({ isEditing: false })}
        onSubmit={this.handleSubmit}
      />
    );
  }

  handleSubmit: ClientAreasPanelProps['onSubmit'] = async (values) => {
    await this.props.updateClientAreas(
      this.props.client.id,
      this.props.clientAreas.map((area) => area.id),
      values.areas
    );
    // After submitting the updates, refresh the panel's content.
    if (!this._isMounted) {
      return;
    }

    this.props.fetchClientAreas(this.props.client.id);
    this.setState({ isEditing: false });
  };

  componentDidMount() {
    this.props.fetchClientAreas(this.props.client.id);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export const ClientAreasPanel = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  mapStateToProps,
  mapDispatchToProps
)(ClientAreasPanelInner);
