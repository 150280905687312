import React from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import {
  ReportFilter,
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportViewProps,
} from 'components/modules/report/report-types';
import { reportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import { Trans, Plural, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { formatDatetimeForDisplay } from 'util/dates';

export const AuditLogListFilters = (): ReportFilter[] => [
  reportFilterMenu(
    'content_type__model',
    <Trans>Resource</Trans>,
    { isMulti: true, valueType: 'string' },
    (option: { code: string; name: string }): SimpleSelectOption<string> => {
      return {
        value: option.code,
        label: option.name,
      };
    },
    { placeholder: <Trans>All resources</Trans> }
  ),
  reportFilterMenu(
    'action',
    <Trans>Action</Trans>,
    { isMulti: true, valueType: 'number' },
    (option: { [key: number]: string }, idx): SimpleSelectOption<number> => {
      return {
        value: idx,
        label: option[idx],
      };
    },
    { placeholder: <Trans>All actions</Trans> }
  ),
  reportFilterMenu(
    'actor',
    <Trans>Person</Trans>,
    { isMulti: true, valueType: 'number' },
    (option: { id: number; username: string }): SimpleSelectOption<number> => {
      return {
        value: option.id,
        label: option.username,
      };
    },
    { placeholder: <Trans>All DMS users</Trans> }
  ),
];

export const AuditLogListColumns: ReportColumn<Model.ReportsAuditLog>[] = [
  {
    label: <Trans>Date and time</Trans>,
    name: 'timestamp',
    visibility: DEFAULT_SHOW,
    accessor: (row) => formatDatetimeForDisplay(row.timestamp),
  },
  {
    label: <Trans>Area</Trans>,
    name: 'area',
    backendFieldName: 'additional_data__area',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Resource</Trans>,
    name: 'resource',
    backendFieldName: 'content_type__model',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Identifier</Trans>,
    name: 'object_reference',
    backendFieldName: 'additional_data__object_reference',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Action</Trans>,
    name: 'action',
    backendFieldName: 'action',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Person</Trans>,
    name: 'person',
    backendFieldName: 'actor__username',
    visibility: DEFAULT_SHOW,
  },
  {
    ...ACTION_COLUMN,
    accessor: (_row) => <></>,
  },
];

export interface AuditLogListViewProps {
  reportProps: ReportViewProps<Model.ReportsAuditLog>;
}

export const AuditLogListView = (props: AuditLogListViewProps) => {
  const { reportProps } = props;
  const columns = AuditLogListColumns;
  const filters = AuditLogListFilters();

  return (
    <PageStandard
      name="audit-log-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Audit Log report</Trans>
        ) : (
          <Trans>Maintain Audit Log</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <ReportFiltersBlock
          filtersFrontend={filters}
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          isExportMode={reportProps.isExportMode}
        />
      </div>

      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Audit Log report`}
            reportUrl="/reports/audit-log/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>

        <ReportTable
          columns={columns}
          errorMessage={reportProps.errorMessage}
          filters={filters}
          isLoading={reportProps.isLoading}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 audit log"
              other="Filtered to # audit logs"
            />
          )}
          msgNoMatches={
            <Trans>No audit logs match the selected filters.</Trans>
          }
          pagination={reportProps.pagination}
          records={reportProps.records}
          reportInfo={reportProps.reportInfo}
        />
      </div>
    </PageStandard>
  );
};
