import React, { useState, useEffect } from 'react';
import Button from 'components/base/button/button';
import { formatDatetimeForDisplay } from 'util/dates';
import { useGetApi } from 'hooks/use-get-api';
import { TSPlotMouseoverPoint } from '../timeseriesplot.types';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { Icon } from 'components/base/icon/icon';
import { isTruthy } from 'util/validation';
import { useIsMounted } from 'util/hooks';
import { getPaginated } from 'util/backendapi/fetch';

interface Props {
  point: TSPlotMouseoverPoint;
  timeZone?: string | null;
  onClose: () => void;
}

export const ReadingInfo = React.memo((props: Props) => {
  const { point, onClose, timeZone: plotTimeZone } = props;
  const isMounted = useIsMounted();

  const showAnalysisComments =
    point.showAnalysisComments ||
    // NOTE: If the plot is not set to draw any comment indicators, reading info
    // will display info about all (analysis and inspector) comments.
    (!point.showAnalysisComments && !point.showInspectorComments);
  const showInspectorComments =
    point.showInspectorComments ||
    (!point.showAnalysisComments && !point.showInspectorComments);

  // Find out the total number of analysis and/or inspector comments for the reading.
  const [commentCount, setCommentCount] = useState(0);
  useEffect(() => {
    // TODO: Using pagination to get the count of records. It may be more
    // performant to add a feature on the back end that requests only the
    // count and not the records, because a pagination request causes Django
    // to run two SQL queries (one to get the count, a second to get the
    // current "page" of records)
    getPaginated('/reports/comments/', {
      limit: 1,
      offset: 0,
      columns: ['id'],
      reading__in: [point.reading_id],
      comment_type__in: [
        showAnalysisComments && Enum.Comment_TYPE.analysis,
        showInspectorComments && Enum.Comment_TYPE.inspector,
      ].filter(isTruthy),
      resourcetype__in: [
        Enum.Comment_RESOURCE_TYPE.reading,
        Enum.Comment_RESOURCE_TYPE.readingInspector,
      ],
    }).then((result) => {
      if (isMounted()) {
        setCommentCount(result.pagination?.total ?? 0);
      }
    });
  }, [
    isMounted,
    point.reading_id,
    showAnalysisComments,
    showInspectorComments,
  ]);

  const [readingDetail] = useGetApi(`/readings/${point.reading_id}/`);
  const mediaCount = readingDetail.data?.associated_media_count ?? 0;

  // NOTE: this relies on raw_reading_entries and adjusted_reading_entries
  // being returned by the backend in item_number order
  const readingEntryIndex = point.itemNumber - 1;

  return (
    <div className="plot-popup-timeseries">
      <div className="plot-popup-timeseries-info">
        <p>
          <strong>{point.obsPointCode}</strong>
        </p>
        <Button
          iconOnly
          onClick={onClose}
          iconType="icon-cross"
          shortcut="ESC"
          className="close"
        >
          <Trans>Close</Trans>
        </Button>
        {readingDetail.isLoading || !readingDetail.data ? (
          <div>...</div>
        ) : (
          <>
            <p>
              {formatDatetimeForDisplay(
                readingDetail.data.reading_datetime,
                plotTimeZone
              )}
              <br />
              <Trans>RV:</Trans>{' '}
              {readingDetail.data.raw_reading_entries[readingEntryIndex]?.value}
              <br />
              <strong>
                {
                  readingDetail.data.adjusted_reading_entries[readingEntryIndex]
                    ?.value
                }
                {point.unit}
              </strong>
            </p>
          </>
        )}
      </div>
      <div
        className="plot-popup-timeseries-action"
        style={{ backgroundColor: point.seriesColor }}
      >
        {readingDetail.data ? (
          <>
            {mediaCount ? (
              <HasPermission check="can_view_media_report">
                <Link
                  to={`/media/?reading=${readingDetail.data!.id}`}
                  className="btn"
                >
                  <Icon type="icon-multimedia" title={t`Media`} />
                  <span className="button-badge">{mediaCount}</span>
                </Link>
              </HasPermission>
            ) : null}
            <HasPermission check="can_view_comment_report">
              <ButtonShowCommentsPanel
                type={Enum.Comment_RESOURCE_TYPE.reading}
                description={
                  <h4 className="panel-subheader">
                    <span>{point.obsPointCode}</span>{' '}
                    <span>
                      {formatDatetimeForDisplay(
                        readingDetail.data.reading_datetime,
                        plotTimeZone
                      )}
                    </span>
                  </h4>
                }
                onCommentAdded={(comment) => {
                  if (
                    ((showAnalysisComments &&
                      comment.comment_type === Enum.Comment_TYPE.analysis) ||
                      (showInspectorComments &&
                        comment.comment_type ===
                          Enum.Comment_TYPE.inspector)) &&
                    (comment.resourcetype ===
                      Enum.Comment_RESOURCE_TYPE.reading ||
                      comment.resourcetype ===
                        Enum.Comment_RESOURCE_TYPE.readingInspector) &&
                    comment.reading === readingDetail.data!.id
                  ) {
                    setCommentCount((count) => count + 1);
                  }
                }}
                metadata={{
                  reading: readingDetail.data.id,
                  observation_point: readingDetail.data.observation_point.id,
                  site: readingDetail.data.observation_point.site,
                  area: readingDetail.data.area,
                  comment_type__in: [
                    showAnalysisComments && Enum.Comment_TYPE.analysis,
                    showInspectorComments && Enum.Comment_TYPE.inspector,
                  ].filter(isTruthy),
                }}
                buttonProps={{
                  iconOnly: true,
                  className: 'btn-link-panel',
                  badge: commentCount,
                }}
              />
            </HasPermission>
          </>
        ) : null}
      </div>
    </div>
  );
});
