import {
  parseStringArrayFromQueryParam,
  QueryParams,
  mergeQueryParams,
} from 'util/routing';
import {
  ReportColumn,
  DEFAULT_SHOW,
} from 'components/modules/report/report-types';
import { useRouteComponentProps } from 'util/hooks';
import sortBy from 'lodash/sortBy';
import deepEquals from 'lodash/isEqual';
import union from 'lodash/union';
import difference from 'lodash/difference';
import { useCallback } from 'react';
import { Enum } from 'util/backendapi/models/api.interfaces';

/**
 * The columns that get shown/hidden when certain filters are enabled on the
 * comments report. The values here correspond to the "frontend names" for
 * these columns. Defining them as constants here, to help ensure that the
 * names will match up correctly in the column definitions and in the hook.
 */
export enum CommentReportAutoColumn {
  site = 'site',
  observationPoint = 'observation_points',
  batch = 'batch_number',
  alarmNumber = 'alarm_number',
}
const COLUMNS = CommentReportAutoColumn;

/**
 * The filters that trigger automatic column showing/hiding. The values here
 * corresponding to the "frontend names" for these filters. Defining them
 * as constants here to make sure the names match up in the filter definitions
 * and in the hook.
 */
export enum CommentReportAutoColumnFilter {
  site = 'site',
  observationPoint = 'observation_point',
  batch = 'readings_batch__batch_number',
  alarmNumber = 'alarm_report__alarm_number__in',
}
const FILTERS = CommentReportAutoColumnFilter;

type AutoColumnResourceTypes =
  | Enum.Comment_RESOURCE_TYPE.alarmReport
  | Enum.Comment_RESOURCE_TYPE.batch
  | Enum.Comment_RESOURCE_TYPE.obsPoint
  | Enum.Comment_RESOURCE_TYPE.site;

const resourceToColumnMappings: {
  [K in AutoColumnResourceTypes]: CommentReportAutoColumn[];
} = {
  [Enum.Comment_RESOURCE_TYPE.site]: [COLUMNS.site],
  [Enum.Comment_RESOURCE_TYPE.obsPoint]: [COLUMNS.observationPoint],
  [Enum.Comment_RESOURCE_TYPE.batch]: [COLUMNS.batch],
  [Enum.Comment_RESOURCE_TYPE.alarmReport]: [
    COLUMNS.alarmNumber,
    COLUMNS.observationPoint,
  ],
};
const RESOURCE_TYPES = Object.keys(resourceToColumnMappings);

/**
 * A list of columns to display when the associated filter is enabled.
 * (All other auto-columns should be hidden when that filter is enabled.)
 */
const filterToColumnMappings: {
  [K in CommentReportAutoColumnFilter]: CommentReportAutoColumn[];
} = {
  [FILTERS.site]: resourceToColumnMappings[Enum.Comment_RESOURCE_TYPE.site],
  [FILTERS.observationPoint]:
    resourceToColumnMappings[Enum.Comment_RESOURCE_TYPE.obsPoint],
  [FILTERS.batch]: resourceToColumnMappings[Enum.Comment_RESOURCE_TYPE.batch],
  [FILTERS.alarmNumber]:
    resourceToColumnMappings[Enum.Comment_RESOURCE_TYPE.alarmReport],
};

/**
 * Logic for automatically showing and hiding certain columns when some of
 * the filters are changed on the comments report.
 *
 * This is not really re-usable on any other screens, but it seemed like
 * a decent idea to split it into a separate file for readability purposes.
 *
 * @param columns
 * @param filters
 */
export function useCommentReportAutoColumns(columns: ReportColumn[]) {
  const routeProps = useRouteComponentProps();

  // Adjust the displayed columns when certain filters are switched on
  const handleFiltersChange = useCallback(
    (newParams: QueryParams): QueryParams => {
      // TODO: Replicating some logic from `<ReportColumnsMenu>`
      const currentColumns =
        parseStringArrayFromQueryParam(routeProps, 'columns', null) ??
        columns.filter((c) => c.visibility === DEFAULT_SHOW).map((c) => c.name);

      const currentSearch = routeProps.location.search;
      const oldParams = new URLSearchParams(currentSearch);
      const updatedParams = mergeQueryParams(currentSearch, newParams);

      let newColumns = currentColumns;

      // Check to see if the "resource type" filter has changed.
      if (
        updatedParams.has('resourcetype') &&
        updatedParams.get('resourcetype') !== oldParams.get('resourcetype')
      ) {
        // Find out if they've added/removed any of the resource types we're
        // interested in.
        const newResourceTypes =
          updatedParams
            .get('resourcetype')
            ?.split(',')
            .filter((r: any): r is AutoColumnResourceTypes =>
              RESOURCE_TYPES.includes(r)
            )
            .sort() ?? [];
        const oldResourceTypes =
          oldParams
            .get('resourcetype')
            ?.split(',')
            .filter((r: any): r is AutoColumnResourceTypes =>
              RESOURCE_TYPES.includes(r)
            )
            .sort() ?? [];
        if (!deepEquals(newResourceTypes, oldResourceTypes)) {
          const columnsToAdd = newResourceTypes.flatMap(
            (resourceType) => resourceToColumnMappings[resourceType]
          );

          newColumns = getNewColumnsList(columns, currentColumns, columnsToAdd);
        }
      }

      // Check to see if one of the filters has been added.
      // (using `.some()` to exit early if we find one of the filters has changed)
      Object.entries(filterToColumnMappings).some(
        ([filterName, columnsToAdd]) => {
          if (updatedParams.has(filterName) && !oldParams.has(filterName)) {
            // This filter has been enabled. Show its related columns, hide
            // the others.
            newColumns = getNewColumnsList(
              columns,
              currentColumns,
              columnsToAdd
            );
            return true;
          }
          return false;
        }
      );

      if (!deepEquals(newColumns, currentColumns)) {
        return {
          ...newParams,
          columns: newColumns,
        };
      } else {
        return newParams;
      }
    },
    [columns, routeProps]
  );

  return handleFiltersChange;
}

function getNewColumnsList(
  columnDefinitions: ReportColumn[],
  currentColumns: string[],
  columnsToAdd: CommentReportAutoColumn[]
) {
  const columnsToHide: string[] = difference(
    Object.values(COLUMNS),
    columnsToAdd
  );

  return sortBy(
    union(difference(currentColumns, columnsToHide), columnsToAdd),
    // TODO: Replicating some logic from `<ReportColumnsMenu>`
    (colName) =>
      columnDefinitions.findIndex((colDef) => colDef.name === colName)
  );
}
