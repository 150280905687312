import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from 'react';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { errorToString } from 'util/backendapi/error';
import { getPaginated } from 'util/backendapi/fetch';
import {
  getColBackendField,
  ReportColumn,
} from 'components/modules/report/report-types';
import { isTruthy } from 'util/validation';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import { Trans, Plural, t } from '@lingui/macro';
import Accordion from 'components/base/accordion/accordion';
import { useSelector, useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { setDashletCollapsed } from 'ducks/ui';
import { useIsMounted } from 'util/hooks';
import { checksheetInstanceListColumns } from 'screens/checksheet-instance/list/ChecksheetInstanceListView';
import { Link } from 'react-router-dom';
import { I18n } from '@lingui/react';

const POLLING_INTERVAL_MS = 75 * 1000;

interface ChecksheetsDashletProps {
  dashletType: 'checksheetsToReview' | 'checksheetsInProgress';
  descriptionSuffix: string;
  checksheetStatuses: Enum.ChecksheetInstance_STATUS[];
  reportColumns: string[];
}

export function ChecksheetsDashlet(props: ChecksheetsDashletProps) {
  const { dashletType, descriptionSuffix, checksheetStatuses, reportColumns } =
    props;

  const columnDefinitions = useMemo(
    () =>
      checksheetInstanceListColumns().filter((c) =>
        reportColumns.includes(c.name)
      ),
    [reportColumns]
  );

  const isMounted = useIsMounted();
  const isCollapsed = useSelector(
    (state: FullState) => state.ui.isDashletCollapsed[dashletType]
  );
  const dispatch = useDispatch();
  const handleToggle = useCallback(
    (isExpanded: boolean) =>
      dispatch(setDashletCollapsed(dashletType, !isExpanded)),
    [dispatch, dashletType]
  );

  const [pollingState, setPollingState] = useState<PollingState>({
    isLoading: false,
  });

  const timerRef = useRef<NodeJS.Timer>();

  // A function to fetch the data and then schedule the next fetch.
  const fetchData = useCallback(async () => {
    timerRef.current = undefined;

    setPollingState((currentPollingState) => ({
      ...currentPollingState,
      isLoading: true,
    }));

    try {
      const backendColumns = columnDefinitions.flatMap((c) =>
        [getColBackendField(c), ...(c.additionalFields ?? [])].filter(isTruthy)
      );

      const { data: checksheetInstanceReports, pagination } =
        await getPaginated('/reports/checksheet-instances/', {
          status__in: checksheetStatuses,
          columns: backendColumns,
          ordering: ['-created_datetime'],
          limit: 50,
          offset: 0,
        });
      if (!isMounted()) {
        return;
      }

      setPollingState(() => ({
        isLoading: false,
        checksheetInstanceReports,
        totalChecksheetInstanceReports: pagination!.total,
      }));
      timerRef.current = setTimeout(fetchData, POLLING_INTERVAL_MS);
    } catch (e) {
      if (!isMounted()) {
        return;
      }

      setPollingState({
        isLoading: false,
        error: errorToString(e),
      });
    }
  }, [isMounted, checksheetStatuses, columnDefinitions]);

  useEffect(() => {
    // Do an immediate poll when the component first loads.
    fetchData();

    // Cancel any remaining scheduled poll, when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [fetchData]);

  return (
    <ChecksheetsDashletView
      descriptionSuffix={descriptionSuffix}
      columnDefinitions={columnDefinitions}
      checksheetStatuses={checksheetStatuses}
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      {...pollingState}
    />
  );
}

interface PollingState {
  isLoading: boolean;
  error?: string;
  checksheetInstanceReports?: Model.ReportsChecksheetInstance[];
  totalChecksheetInstanceReports?: number | null;
}

interface ViewProps extends PollingState {
  isCollapsed: boolean;
  descriptionSuffix: string;
  checksheetStatuses: Enum.ChecksheetInstance_STATUS[];
  columnDefinitions: ReportColumn<Model.ReportsChecksheetInstance>[];
  onToggle: (isExpanded: boolean) => void;
}

export function ChecksheetsDashletView(props: ViewProps) {
  const {
    isCollapsed,
    error,
    checksheetInstanceReports,
    totalChecksheetInstanceReports,
    descriptionSuffix,
    checksheetStatuses,
    columnDefinitions,
    onToggle,
  } = props;

  const heading =
    error || !checksheetInstanceReports ? (
      <Trans>Checksheets {descriptionSuffix}</Trans>
    ) : totalChecksheetInstanceReports === null ||
      totalChecksheetInstanceReports! > 50 ? (
      <Trans>More than 50 checksheets {descriptionSuffix}</Trans>
    ) : (
      <Plural
        value={totalChecksheetInstanceReports || 0}
        one={`1 checksheet ${descriptionSuffix}`}
        other={`# checksheets ${descriptionSuffix}`}
      />
    );

  return (
    <Accordion
      data-testid="checksheets-dashlet"
      onToggle={onToggle}
      expand={!isCollapsed}
      wrapperClassName="accordion-secondary"
      item={{
        id: 'checksheets-dashlet',
        title: (
          <>
            {heading}
            <span className="accordion-title-additional-content">
              <Link
                to={`/checksheet-instances/?status=${encodeURIComponent(
                  checksheetStatuses.join(',')
                )}`}
                className="btn"
              >
                <span>
                  <Trans>View report</Trans>
                </span>
              </Link>
            </span>
          </>
        ),
        content: (
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  {columnDefinitions.map((col) => (
                    <th key={col.name} scope="col">
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {error ? (
                  <tr>
                    <td className="td-no-results" colSpan={999}>
                      <AlertDanger>{error}</AlertDanger>
                    </td>
                  </tr>
                ) : !checksheetInstanceReports ? (
                  <tr>
                    <td className="td-no-results" colSpan={999}>
                      <Loading />
                    </td>
                  </tr>
                ) : checksheetInstanceReports.length === 0 ? (
                  <tr>
                    <td className="td-no-results" colSpan={999}>
                      <Trans>No checksheets {descriptionSuffix}</Trans>
                    </td>
                  </tr>
                ) : (
                  checksheetInstanceReports.map((row) => (
                    <tr key={row.id}>
                      {columnDefinitions.map((col, idx) => (
                        <td key={col.name}>
                          {col.accessor?.(row, idx) ??
                            row[
                              getColBackendField(
                                col
                              ) as keyof Model.ReportsChecksheetInstance
                            ]}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        ),
      }}
    />
  );
}

export function ChecksheetsToReviewDashlet() {
  return (
    <I18n>
      {({ i18n }) => (
        <ChecksheetsDashlet
          dashletType="checksheetsToReview"
          descriptionSuffix={i18n._(t`to review`)}
          reportColumns={[
            'name',
            'area',
            'status',
            'created_datetime',
            'analyst',
          ]}
          checksheetStatuses={[
            Enum.ChecksheetInstance_STATUS.ready_for_review,
            Enum.ChecksheetInstance_STATUS.under_followup,
          ]}
        />
      )}
    </I18n>
  );
}

export function ChecksheetsInProgressDashlet() {
  return (
    <I18n>
      {({ i18n }) => (
        <ChecksheetsDashlet
          dashletType="checksheetsInProgress"
          descriptionSuffix={i18n._(t`in progress`)}
          reportColumns={[
            'name',
            'area',
            'status',
            'created_datetime',
            'created_by',
          ]}
          checksheetStatuses={[Enum.ChecksheetInstance_STATUS.in_progress]}
        />
      )}
    </I18n>
  );
}
