import { Model } from 'util/backendapi/models/api.interfaces';
import { getPaginated, getApi, postApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { StandardThunk } from 'main/store';

export const dataLoggerChannelsDuck = makeReportingDuck(
  'dataLoggerChannels',
  (filters) => {
    if (!filters.data_logger__logger_number) {
      return Promise.resolve({
        pagination: {
          total: 0,
          start: 0,
          end: 0,
        },
        data: [],
      });
    }

    if (filters.active_at_datetime === 'now') {
      filters.active_at_datetime = new Date().toISOString();
    }
    return getPaginated('/reports/data-logger-channels/', filters);
  },
  () => getApi('/reports/data-logger-channels/info/')
);

export type DataLoggerChannelsActions = ReportingDuckActions<
  typeof dataLoggerChannelsDuck
>;

export function saveDataLoggerChannel(
  dlc: ForPostOrPut<Model.DataLoggerChannel>
): StandardThunk {
  return async function () {
    await postApi('/data-logger-channels/', dlc);
  };
}
