import { StandardThunk, DuckActions } from 'main/store';
import {
  SpatialPlotObservationPoint,
  SpatialWanderPlotObservationPoint,
  SimpleReading,
  StoredSpatialWanderPlot,
} from 'util/backendapi/types/Model';
import { getApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { StoredSpatialWanderPlotWithArea } from 'ducks/stored-plot/detail';
import { FullState } from 'main/reducers';

export const ActionTypes = {
  FETCH_PLOTTING_DATA_START: 'dms/storedPlot/spatial/FETCH_PLOTTING_DATA_START',
  FETCH_PLOTTING_DATA_RESPONSE:
    'dms/storedPlot/spatial/FETCH_PLOTTING_DATA_RESPONSE',
  FETCH_PLOTTING_DATA_ERROR: 'dms/storedPlot/spatial/FETCH_PLOTTING_DATA_ERROR',
  UNMOUNT: 'dms/storedPlot/spatial/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_PLOTTING_DATA_START: (storedPlotId: number) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_START,
    storedPlotId,
  }),
  FETCH_PLOTTING_DATA_RESPONSE: (
    storedPlotId: number,
    observationPoints:
      | SpatialPlotObservationPoint[]
      | SpatialWanderPlotObservationPoint[],
    readings: SimpleReading[] | null = null
  ) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_RESPONSE,
    storedPlotId,
    payload: {
      observationPoints,
      readings,
    },
  }),
  FETCH_PLOTTING_DATA_ERROR: (storedPlotId: number, errorMessage: string) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_ERROR,
    storedPlotId,
    error: true,
    payload: errorMessage,
  }),
  UNMOUNT: () => ({ type: ActionTypes.UNMOUNT }),
};

export interface SingleSpatialPlotState {
  storedPlotId: number | null;
  isLoading: boolean;
  errorMessage: string | null;
  observationPoints:
    | SpatialPlotObservationPoint[]
    | SpatialWanderPlotObservationPoint[]
    | null;
  readings: SimpleReading[] | null;
}

type SpatialPlotState = {
  [K in number]?: SingleSpatialPlotState;
};

function spatialPlotInitialState(): SpatialPlotState {
  return {};
}

export type SpatialPlotAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export function spatialPlotReducer(
  state = spatialPlotInitialState() as SpatialPlotState,
  action: SpatialPlotAction
): SpatialPlotState {
  switch (action.type) {
    case ActionTypes.FETCH_PLOTTING_DATA_START:
      return {
        ...state,
        [action.storedPlotId]: {
          storedPlotId: action.storedPlotId,
          isLoading: true,
          errorMessage: null,
          observationPoints: null,
          readings: null,
        },
      };
    case ActionTypes.FETCH_PLOTTING_DATA_RESPONSE: {
      const plotState = state[action.storedPlotId];
      if (plotState) {
        return {
          ...state,
          [action.storedPlotId]: {
            ...plotState,
            isLoading: false,
            errorMessage: null,
            observationPoints: action.payload.observationPoints,
            readings: action.payload.readings,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.FETCH_PLOTTING_DATA_ERROR: {
      const plotState = state[action.storedPlotId];
      if (plotState) {
        return {
          ...state,
          [action.storedPlotId]: {
            ...plotState,
            isLoading: false,
            errorMessage: action.payload,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.UNMOUNT:
      return spatialPlotInitialState();
    default:
      return state;
  }
}

export function selectSpatialPlotData(state: FullState, storedPlotId: number) {
  return state.plot.spatial[storedPlotId];
}

export const unmountSpatialPlot = ActionCreators.UNMOUNT;

export function fetchSpatialPlotData(storedPlotId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_PLOTTING_DATA_START(storedPlotId));
    try {
      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_RESPONSE(
          storedPlotId,
          await getApi(`/spatial-stored-plots/${storedPlotId}/plot/`)
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_ERROR(storedPlotId, errorToString(e))
      );
    }
  };
}

export function fetchWanderPlotData(
  storedPlot: StoredSpatialWanderPlot | StoredSpatialWanderPlotWithArea
): StandardThunk {
  return async function (dispatch) {
    const storedPlotId = storedPlot.id;
    dispatch(ActionCreators.FETCH_PLOTTING_DATA_START(storedPlotId));
    try {
      const [observationPoints, readings] = await Promise.all([
        getApi(`/spatial-stored-plots/${storedPlotId}/plot/`),
        getApi('/readings/simple/', {
          effective: true,
          reading_datetime__in: storedPlot.time_periods,
          observation_point__in: storedPlot.survey_points.flatMap(
            ({ northing_observation_point, easting_observation_point }) => [
              northing_observation_point,
              easting_observation_point,
            ]
          ),
        }),
      ]);
      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_RESPONSE(
          storedPlotId,
          observationPoints,
          readings
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_ERROR(storedPlotId, errorToString(e))
      );
    }
  };
}
