import React from 'react';
import { Trans } from '@lingui/macro';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { formatDatetimeForDisplay } from 'util/dates';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { AlertDanger } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import { Card, CardSection } from 'components/base/card/card';
import Button from 'components/base/button/button';
import ActionBlock from 'components/base/actionblock/actionblock';
import { isNotNull } from 'util/validation';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { ChangeStatusModal } from './changestatus-modal/changestatusmodal';
import { Icon } from 'components/base/icon/icon';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { BackButton } from 'components/base/back-button/BackButton';
import { ChangeAlarmReportCategoryModal } from './change-category-modal/ChangeAlarmReportCategoryModal';
import { DMSLink } from 'components/base/link/DMSLink';

export interface AlarmReportDetailViewStateProps {
  alarmReport: Model.AlarmReportDecorated | null;
  error: string;
  loading: boolean;
  canChangeStatus: boolean;
  inspectorComment: Model.ReadingInspectorComment | null;
  hasCommentsButton: boolean;
}
export interface AlarmReportDetailViewDispatchProps {
  onViewPlot: (obsCode: string) => void;
  onViewPreviousAlarm: (obsId: number, obsCode: string) => void;
}

type Props = AlarmReportDetailViewStateProps &
  AlarmReportDetailViewDispatchProps;

const AlarmReportDetailView: React.SFC<Props> = ({
  alarmReport,
  onViewPlot,
  onViewPreviousAlarm,
  error,
  loading,
  canChangeStatus,
  inspectorComment,
  hasCommentsButton,
}) => {
  const alarmReportReadingItem = alarmReport?.instrument_type.items.find(
    (item) => item.item_number === alarmReport.item_number
  );

  return (
    <PageStandard name="alarmreport" header={<Trans>Alarm Reports</Trans>}>
      {loading && <Loading />}

      {error && <AlertDanger>{error}</AlertDanger>}

      {!error && !loading && alarmReport && (
        <>
          <div className="page-content-header-with-back-button-wrapper">
            <BackButton defaultBackUrl="/alarm-reports" />
            <div className="page-content-header">
              <ActionBlock className="columns-fluid">
                <div>
                  {canChangeStatus && (
                    <>
                      <ButtonShowModal
                        name="change-status-panel"
                        iconType="icon-update"
                        modalContent={({ hideModal }) => (
                          <ChangeStatusModal
                            alarmReport={alarmReport}
                            hideModal={hideModal}
                          />
                        )}
                        modalProps={{
                          shouldCloseOnOverlayClick: false,
                        }}
                      >
                        <Trans>Change status</Trans>
                      </ButtonShowModal>
                      <ButtonShowModal
                        name="change-category-modal"
                        iconType="icon-edit"
                        modalContent={(modalProps) => (
                          <ChangeAlarmReportCategoryModal
                            {...modalProps}
                            alarmReport={alarmReport}
                          />
                        )}
                      >
                        <Trans>Change category</Trans>
                      </ButtonShowModal>
                    </>
                  )}
                  {alarmReport.alarm_parameter.comparison_type ===
                    Enum.AlarmParameter_COMPARISON_TYPE.absolute && (
                    <Button
                      iconType="icon-plot"
                      onClick={() =>
                        onViewPlot(alarmReport.observation_point.code)
                      }
                    >
                      <Trans>View plot</Trans>
                    </Button>
                  )}
                  <Button
                    iconType="icon-past"
                    onClick={() =>
                      onViewPreviousAlarm(
                        alarmReport.observation_point.id,
                        alarmReport.observation_point.code
                      )
                    }
                  >
                    <Trans>Previous alarms</Trans>
                  </Button>
                  <DMSLink
                    className="btn media-link"
                    to={`/media/?reading=${alarmReport.reading.id}`}
                  >
                    <span>
                      <Trans>View media</Trans>
                    </span>
                    <Icon type="icon-multimedia" />
                  </DMSLink>
                </div>
                {hasCommentsButton ? (
                  <div className="text-right">
                    <ButtonShowCommentsPanel
                      type={Enum.Comment_RESOURCE_TYPE.alarmReport}
                      metadata={{
                        area: alarmReport.area.id,
                        site: alarmReport.site.id,
                        observation_point: alarmReport.observation_point.id,
                        alarm_report: alarmReport.id,
                      }}
                      commentReportParams={`resourcetype=${Enum.Comment_RESOURCE_TYPE.alarmReport}&alarm_report__alarm_number__in=${alarmReport.alarm_number}`}
                    />
                  </div>
                ) : null}
              </ActionBlock>
            </div>
          </div>
          <Card
            name="readings"
            header={
              <div>
                <span>{alarmReport.observation_point.code}</span>
                <span> {alarmReport.alarm_parameter.level}</span>
                <span> {alarmReport.alarm_parameter.type}</span>
                <span>
                  {' - '}
                  {formatDatetimeForDisplay(
                    alarmReport.created_datetime,
                    alarmReport.time_zone.name
                  )}
                </span>
              </div>
            }
          >
            <CardSection
              name="instrumentSection"
              header={<Trans>Instruments</Trans>}
              fields={[
                {
                  name: 'observationPoint',
                  label: <Trans>Observation point</Trans>,
                  content: alarmReport.observation_point.code,
                },
                {
                  name: 'alarmReportReadingItemNumber',
                  label: <Trans>Reading item number</Trans>,
                  content: alarmReportReadingItem?.item_number,
                },
                {
                  name: 'alarmReportReadingItemDescription',
                  label: <Trans>Reading item description</Trans>,
                  content: alarmReportReadingItem?.description,
                },
                {
                  name: 'instrumentType',
                  label: <Trans>Instrument type</Trans>,
                  content: (
                    <span>
                      {alarmReport.instrument_type.code} -{' '}
                      {alarmReport.instrument_type.name}
                    </span>
                  ),
                },
                {
                  name: 'area',
                  label: <Trans>Area</Trans>,
                  content: <span>{alarmReport.area.name}</span>,
                },
              ]}
            />

            <CardSection
              name="alarmSection"
              header={<Trans>Alarm</Trans>}
              fields={[
                {
                  name: 'status',
                  label: <Trans>Status</Trans>,
                  content: (
                    <span>
                      <TransEnum
                        enum="AlarmReport_STATUS"
                        value={alarmReport.status}
                      />
                    </span>
                  ),
                },
                {
                  name: 'alarmDateAndTime',
                  label: <Trans>Alarm date and time</Trans>,
                  content: (
                    <span>
                      {formatDatetimeForDisplay(
                        alarmReport.created_datetime,
                        alarmReport.time_zone.name
                      )}
                    </span>
                  ),
                },
                {
                  name: 'comparisonType',
                  label: <Trans>Comparison type</Trans>,
                  content: (
                    <span>
                      <TransEnum
                        enum="AlarmParameter_COMPARISON_TYPE"
                        value={alarmReport.alarm_parameter.comparison_type}
                      />
                    </span>
                  ),
                },
                {
                  name: 'level',
                  label: <Trans>Level</Trans>,
                  content: (
                    <span>
                      <TransEnum
                        enum="AlarmParameter_LEVEL"
                        value={alarmReport.alarm_parameter.level}
                      />
                    </span>
                  ),
                },
                {
                  name: 'parameterExceeded',
                  label: <Trans>Parameter exceeded</Trans>,
                  content: (
                    <span>
                      <TransEnum
                        enum="AlarmParameter_TYPE"
                        value={alarmReport.alarm_parameter.type}
                      />
                    </span>
                  ),
                },
                alarmReport.alarm_parameter.comparison_type !==
                Enum.AlarmParameter_COMPARISON_TYPE.relative
                  ? {
                      name: 'mimMaxParameter',
                      label:
                        alarmReport.alarm_parameter.type ===
                        Enum.AlarmParameter_TYPE.maximum ? (
                          <Trans>Maximum parameter</Trans>
                        ) : (
                          <Trans>Minimum parameter</Trans>
                        ),
                      content: (
                        <span>
                          {Number(alarmReport.alarm_parameter.threshold)}
                        </span>
                      ),
                    }
                  : null,
                {
                  name: 'alarmNumber',
                  label: <Trans>Alarm number</Trans>,
                  content: <span>{alarmReport.alarm_number}</span>,
                },
                {
                  name: 'alarmCategory',
                  label: <Trans>Category</Trans>,
                  content: alarmReport.category && (
                    <span>
                      <TransEnum
                        enum="AlarmReport_CATEGORY"
                        value={alarmReport.category}
                      />
                    </span>
                  ),
                },
              ].filter(isNotNull)}
            />

            <CardSection
              name="readingSection"
              header={<Trans>Reading</Trans>}
              fields={[
                {
                  name: 'adjustedReading',
                  label: <Trans>Adjusted reading</Trans>,
                  content: (
                    <span>{alarmReport.adjusted_reading_entry.value}</span>
                  ),
                },
                ...alarmReport.formula_inputs.map((fi, idx) => {
                  // for each formula input, we need to find the raw_readings value
                  // corresponding to the formula input position
                  // TODO: Does this work correctly for summation inputs?
                  const rawReading =
                    alarmReport.raw_readings.find(
                      (rawReading) => rawReading.position === fi.position
                    ) || null;

                  return {
                    name: `rawReading${idx}`,
                    label: <Trans>Raw reading ({fi.description})</Trans>,
                    content: <span>{rawReading ? rawReading.value : ''}</span>,
                  };
                }),
                {
                  name: 'rawReadingDatetime',
                  label: <Trans>Raw reading date and time</Trans>,
                  content: (
                    <span>
                      {formatDatetimeForDisplay(
                        alarmReport.reading.reading_datetime,
                        alarmReport.time_zone.name
                      )}
                    </span>
                  ),
                },
                {
                  name: 'readingComment',
                  label: <Trans>Reading comment</Trans>,
                  content: (
                    <span className="text-with-linebreaks">
                      {inspectorComment ? inspectorComment.content : null}
                    </span>
                  ),
                },
                {
                  name: 'batchNumber',
                  label: <Trans>Batch number</Trans>,
                  content: (
                    <DMSLink
                      to={`/check-readings/${alarmReport.readings_batch.batch_number}`}
                    >
                      {alarmReport.readings_batch.batch_number}
                    </DMSLink>
                  ),
                },
              ]}
            />

            {alarmReport.alarm_parameter.comparison_type ===
              Enum.AlarmParameter_COMPARISON_TYPE.relative && (
              <CardSection
                name="relativeComparisonSection"
                header={<Trans>Relative comparison</Trans>}
                fields={[
                  {
                    name: 'relObservationPoint',
                    label: <Trans>Observation point</Trans>,
                    content: (
                      <span>
                        {
                          alarmReport.alarm_parameter
                            .relative_observation_point!.code
                        }
                      </span>
                    ),
                  },
                  {
                    name: 'relFactor',
                    label: <Trans>Factor</Trans>,
                    content: (
                      <span>
                        {Number(
                          alarmReport.alarm_parameter.relative_comparison_factor
                        )}
                      </span>
                    ),
                  },
                  {
                    name: 'relTolerance',
                    label: <Trans>Tolerance</Trans>,
                    content: (
                      <span>
                        <IntervalDisplay
                          value={
                            alarmReport.alarm_parameter
                              .relative_comparison_datetime_tolerance
                          }
                        />
                      </span>
                    ),
                  },
                ]}
              />
            )}
          </Card>
        </>
      )}
    </PageStandard>
  );
};

export default AlarmReportDetailView;
