import React from 'react';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import {
  BulkCreateErrorTable,
  BulkCreateErrorFilter,
} from 'components/modules/bulk-create/BulkCreateErrorTable';
import { Formik } from 'formik';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button from 'components/base/button/button';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import {
  FilterControl,
  FilterBlock,
} from 'components/modules/report/filter/FilterBlock';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { AlertInfo } from 'components/base/alert/alert';
import { InfoModal } from 'components/base/modal/InfoModal';
import { BulkCreateErrorDetail } from 'util/backendapi/types/Model';

export type ObsPointBulkCreateErrorsViewProps = {
  errorDetail: BulkCreateErrorDetail | null;
  handleCancel: (e: React.SyntheticEvent) => void;
};

type ObsPointBulkCreateFormValues = {
  errorFilter: BulkCreateErrorFilter;
};

const obsPointErrorFilterOptions: SimpleSelectOption<string>[] = [
  {
    label: <Trans>All observation points</Trans>,
    value: '',
  },
  {
    label: <Trans>Observation points with errors</Trans>,
    value: 'with_errors',
  },
  {
    label: <Trans>Observation points without errors</Trans>,
    value: 'without_errors',
  },
];

export const ObsPointBulkCreateErrorsView = (
  props: ObsPointBulkCreateErrorsViewProps
) => {
  const { errorDetail } = props;

  return (
    <PageStandard
      name="bulk-create-observation-points"
      header={<Trans>Bulk Create Observation Points</Trans>}
    >
      {errorDetail ? (
        <>
          <InfoModal
            name="bulk-csv-error-info"
            header={
              <Trans>
                There is an error with one or more observation points in your
                CSV.
              </Trans>
            }
            content={
              <Trans>
                When you close this message you can review the error(s) and then
                cancel the import. Please correct the errors in the CSV and try
                again.
              </Trans>
            }
          />
          <Formik<ObsPointBulkCreateFormValues>
            initialValues={{
              errorFilter: '',
            }}
            onSubmit={() => {}}
          >
            {(formik) => {
              const buttons = (
                <ActionBlock className="text-right">
                  <Button onClick={props.handleCancel}>
                    <Trans>Cancel</Trans>
                  </Button>
                </ActionBlock>
              );

              return (
                <>
                  <div className="page-content-header-filters-actions">
                    {buttons}
                    <FilterBlock>
                      <FilterControl
                        label={<Trans>Errors</Trans>}
                        hasValues={true}
                      >
                        <SimpleSelectField
                          name="errorFilter"
                          isClearable={false}
                          options={obsPointErrorFilterOptions}
                        />
                      </FilterControl>
                    </FilterBlock>
                  </div>
                  <BulkCreateErrorTable
                    errorFilter={formik.values.errorFilter}
                    errorDetail={errorDetail}
                  />
                  {buttons}
                </>
              );
            }}
          </Formik>
        </>
      ) : (
        <AlertInfo testid="bulk-create-no-errors">
          <Trans>No errors to display.</Trans>
        </AlertInfo>
      )}
    </PageStandard>
  );
};
