import { getApi, getPaginated } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { Filter } from 'util/backendapi/models/api.interfaces';

export const alarmReportDuck = makeReportingDuck(
  'alarmReport',
  (filters: Filter.ReportsAlarmReports) =>
    getPaginated('/reports/alarm-reports/', filters),
  () => getApi('/reports/alarm-reports/info/')
);

export type AlarmReportListActions = ReportingDuckActions<
  typeof alarmReportDuck
>;

export const alarmCommentsReportDuck = makeReportingDuck(
  'alarmReportComments',
  (filters: Filter.ReportsAlarmComments) =>
    getPaginated('/reports/alarm-comments/', filters),
  () => getApi('/reports/alarm-comments/info/')
);

export type AlarmCommentsReportActions = ReportingDuckActions<
  typeof alarmCommentsReportDuck
>;
