import { postApi, patchApi } from 'util/backendapi/fetch';
import { Model } from 'util/backendapi/models/api.interfaces';
import { StandardThunk } from 'main/store';

export function createSavedReport(
  report: Model.SavedReport_POST
): StandardThunk {
  return async function () {
    return await postApi('/saved-reports/', report);
  };
}

export function updateSavedReport(
  savedReportId: number,
  report: Model.SavedReport_PATCH
): StandardThunk {
  return async function () {
    return patchApi(`/saved-reports/${savedReportId}/`, report);
  };
}
