import React from 'react';
import range from 'lodash/range';
import {
  StoredScatterTimeSeriesFormValues,
  makeEmptyStoredPlotItem,
} from '../stored-plot-edit-utils';
import { PlotType } from 'util/backendapi/types/Enum';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { Trans } from '@lingui/macro';
import { FormikProps } from 'formik';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { defaultMemoize } from 'reselect';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import { FormCardSection } from 'components/base/card/card';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';

const timeSeriesLayoutOptions = menuItemsFromEnum(
  'StoredPlot_LAYOUT',
  Object.values(Enum.StoredPlot_LAYOUT)
);

const selectTimeSeriesLayoutOptions = defaultMemoize((plotsPerPage: number) => {
  switch (plotsPerPage) {
    case 1:
      return timeSeriesLayoutOptions.filter(
        (opt) => Enum.StoredPlot_LAYOUT.L100 === opt.value
      );

    case 2:
      return timeSeriesLayoutOptions.filter(
        (opt) =>
          Enum.StoredPlot_LAYOUT.L50_50 === opt.value ||
          Enum.StoredPlot_LAYOUT.L60_40 === opt.value ||
          Enum.StoredPlot_LAYOUT.L75_25 === opt.value ||
          Enum.StoredPlot_LAYOUT.L80_20 === opt.value
      );

    case 3:
      return timeSeriesLayoutOptions.filter(
        (opt) =>
          Enum.StoredPlot_LAYOUT.L33_33_33 === opt.value ||
          Enum.StoredPlot_LAYOUT.L50_25_25 === opt.value ||
          Enum.StoredPlot_LAYOUT.L60_20_20 === opt.value
      );

    case 4:
      return timeSeriesLayoutOptions.filter(
        (opt) =>
          Enum.StoredPlot_LAYOUT.L25_25_25_25 === opt.value ||
          Enum.StoredPlot_LAYOUT.L40_20_20_20 === opt.value
      );

    default:
      return [];
  }
});

const getDefaultTimeSeriesLayout = defaultMemoize((plotsPerPage: number) => {
  const layoutOptions = selectTimeSeriesLayoutOptions(plotsPerPage);
  return layoutOptions[0] ? layoutOptions[0].value : null;
});

export function StoredPlotLayoutSection<
  T extends StoredScatterTimeSeriesFormValues
>(props: { formik: FormikProps<T> }) {
  const { formik } = props;
  const plotType = formik.values.plot_type;

  let maxPlotsPerPage = 1;
  let plotsPerPageOptions: SimpleSelectOption<number>[] = [];

  if (plotType === PlotType.TIME_SERIES) {
    maxPlotsPerPage = 4;
  } else if (plotType === PlotType.SCATTER) {
    maxPlotsPerPage = 8;
  }

  if (maxPlotsPerPage <= 1) return null;

  for (let i = 1; i <= maxPlotsPerPage; i++) {
    plotsPerPageOptions.push({ label: String(i), value: i });
  }

  let layoutFields = [
    {
      name: 'plots_per_page',
      label: <Trans>Plots per page</Trans>,
      content: (
        <>
          <SimpleSelectField
            name="plots_per_page"
            options={plotsPerPageOptions}
          />
          <FieldError name="plots_per_page" />
        </>
      ),
    },
  ];

  if (plotType === PlotType.TIME_SERIES) {
    layoutFields.push({
      name: 'layout',
      label: <Trans>Layout</Trans>,
      content: (
        <>
          <SimpleSelectField
            name="layout"
            options={selectTimeSeriesLayoutOptions(
              formik.values.plots_per_page
            )}
          />
          <FieldError name="layout" />
        </>
      ),
    });
  }

  return (
    <>
      <FormCardSection
        name="layout"
        header={<Trans>Layout</Trans>}
        fields={layoutFields}
      />

      {/* effect to update the layout dropdown when number of plots changed */}
      {/* aslo add/remove the plot items based on `plots_per_page`  */}
      <FormChangeEffect<StoredScatterTimeSeriesFormValues>
        onChange={({ values: prevValues }) => {
          if (prevValues.plots_per_page !== formik.values.plots_per_page) {
            if (plotType === PlotType.TIME_SERIES) {
              formik.setFieldValue(
                'layout',
                getDefaultTimeSeriesLayout(formik.values.plots_per_page)
              );
            }

            const itemCounts = formik.values.items.length;

            if (formik.values.plots_per_page > itemCounts) {
              const addThisMany = formik.values.plots_per_page - itemCounts;
              formik.setFieldValue(
                'items',
                formik.values.items.concat(
                  range(addThisMany).map(() =>
                    makeEmptyStoredPlotItem(formik.values.plot_type)
                  )
                )
              );
            } else if (formik.values.plots_per_page < itemCounts) {
              formik.setFieldValue(
                'items',
                formik.values.items.slice(0, formik.values.plots_per_page)
              );
            }
          }
        }}
      />
    </>
  );
}
