import sortBy from 'lodash/sortBy';
import groupBy from 'lodash/groupBy';
import { Model } from 'util/backendapi/models/api.interfaces';
import { DuckActions, StandardThunk } from 'main/store';
import { errorToString } from 'util/backendapi/error';
import { getApi } from 'util/backendapi/fetch';
import { StoredSurveyLevellingPlotWithArea } from 'ducks/stored-plot/detail';
import {
  SimpleReading,
  StoredSurveyLevellingPlotSurveyPoint,
} from 'util/backendapi/types/Model';
import { FullState } from 'main/reducers';

/***
 * Duck for rendering a survey levelling plot
 */
export const ActionTypes = {
  FETCH_PLOTTING_DATA_START:
    'dms/storedPlot/surveyLevellingPlot/FETCH_PLOTTING_DATA_START',
  FETCH_PLOTTING_DATA_RESPONSE:
    'dms/storedPlot/surveyLevellingPlot/FETCH_PLOTTING_DATA_RESPONSE',
  FETCH_PLOTTING_DATA_ERROR:
    'dms/storedPlot/surveyLevellingPlot/FETCH_PLOTTING_DATA_ERROR',
  UNMOUNT: 'dms/storedPlot/surveyLevellingPlot/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_PLOTTING_DATA_START: (storedPlotId: number) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_START,
    storedPlotId,
  }),
  FETCH_PLOTTING_DATA_RESPONSE: (
    storedPlotId: number,
    observationPoints: Model.ObservationPointDecorated[],
    surveySeries: SurveyLevellingSerie[]
  ) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_RESPONSE,
    storedPlotId,
    payload: {
      observationPoints,
      surveySeries,
    },
  }),
  FETCH_PLOTTING_DATA_ERROR: (storedPlotId: number, errorMessage: string) => ({
    type: ActionTypes.FETCH_PLOTTING_DATA_ERROR,
    storedPlotId,
    error: true,
    payload: errorMessage,
  }),
  UNMOUNT: () => ({ type: ActionTypes.UNMOUNT }),
};

export type SurveyLevellingPlotAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface SurveyLevellingReading {
  x: number;
  y: number | null;
  yVariance: number | null;
  reading: SimpleReading | null;
  surveyPoint: StoredSurveyLevellingPlotSurveyPoint;
}

export interface SurveyLevellingSerie {
  surveyDatetime: string;
  showPlotMarkers: boolean;
  readings: SurveyLevellingReading[];
}

export interface SingleSurveyLevellingPlotState {
  storedPlotId: number | null;
  isLoading: boolean;
  errorMessage: string | null;
  observationPoints: Model.ObservationPointDecorated[] | null;
  surveySeries: SurveyLevellingSerie[] | null;
}

type SurveyLevellingPlotState = {
  [K in number]?: SingleSurveyLevellingPlotState;
};

function initialState(): SurveyLevellingPlotState {
  return {};
}

export function surveyLevellingPlotReducer(
  state = initialState(),
  action: SurveyLevellingPlotAction
): SurveyLevellingPlotState {
  switch (action.type) {
    case ActionTypes.FETCH_PLOTTING_DATA_START:
      return {
        ...state,
        [action.storedPlotId]: {
          storedPlotId: action.storedPlotId,
          isLoading: true,
          errorMessage: null,
          observationPoints: null,
          surveySeries: null,
        },
      };
    case ActionTypes.FETCH_PLOTTING_DATA_RESPONSE: {
      const plotState = state[action.storedPlotId];
      if (plotState) {
        return {
          ...state,
          [action.storedPlotId]: {
            ...plotState,
            isLoading: false,
            errorMessage: null,
            observationPoints: action.payload.observationPoints,
            surveySeries: action.payload.surveySeries,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.FETCH_PLOTTING_DATA_ERROR: {
      const plotState = state[action.storedPlotId];
      if (plotState) {
        return {
          ...state,
          [action.storedPlotId]: {
            ...plotState,
            isLoading: false,
            errorMessage: action.payload,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.UNMOUNT:
      return initialState();
    default:
      return state;
  }
}

export function selectSurveyLevellingPlotData(
  state: FullState,
  storedPlotId: number
) {
  return state.plot.surveyLevelling[storedPlotId];
}

export const unmountSurveyLevellingPlot = ActionCreators.UNMOUNT;

/**
 * A helper function to group and sort the readings together for plotting on
 * a survey levelling plot.
 *
 * The output is an array of `SurveyLevellingSerie`s, each representing one
 * data line on the final plot. Each line represents the readings for all
 * the observation points, at a particular survey datetime.
 *
 * The output includes a `null` in place of the reading, for any obs point
 * that doesn't have any readings at one of the survey datetimes.
 *
 * NOTE: This function assumes that the back end has provided the stored plot's
 * survey points ordered by `distance`.
 *
 * @param storedPlot
 * @param allReadings
 */
export function sortReadingsIntoSurveyLevellingSeries(
  storedPlot: StoredSurveyLevellingPlotWithArea,
  allReadings: SimpleReading[]
): SurveyLevellingSerie[] {
  const readingsByDatetime = groupBy(allReadings, (r) => r.reading_datetime);

  return sortBy(
    storedPlot.time_periods,
    (timePeriod) => timePeriod.survey_datetime
  ).map(({ survey_datetime, show_plot_markers }) => {
    const measuredReadings = readingsByDatetime[survey_datetime] ?? [];
    const readings = storedPlot.survey_points.map(
      (surveyPoint): SurveyLevellingReading => {
        const reading =
          measuredReadings.find(
            (r) => r.observation_point === surveyPoint.observation_point
          ) ?? null;
        const readingValue =
          reading?.adjusted_reading_entries[0]?.value ?? null;
        const confidenceLevel =
          reading?.adjusted_reading_entries[1]?.value ?? null;
        return {
          reading,
          surveyPoint,
          x: Number(surveyPoint.distance),
          y: readingValue === null ? null : +readingValue,
          // React-Vis draws the error bar to the exact length of `yVariance`.
          // The confidence levels from the back end are meant to be +/-, which
          // means the line's total length should be double that.
          yVariance: confidenceLevel === null ? null : +confidenceLevel * 2,
        };
      }
    );
    return {
      surveyDatetime: survey_datetime,
      showPlotMarkers: show_plot_markers,
      readings,
    };
  });
}

export function fetchSurveyLevellingPlotData(
  storedPlot: StoredSurveyLevellingPlotWithArea
): StandardThunk {
  return async function (dispatch) {
    const storedPlotId = storedPlot.id;
    dispatch(ActionCreators.FETCH_PLOTTING_DATA_START(storedPlotId));
    try {
      const obsPointIds = storedPlot.survey_points.map(
        (sp) => sp.observation_point
      );

      const [observationPoints, allReadings] = await Promise.all([
        obsPointIds.length
          ? await getApi('/observation-points/', { id__in: obsPointIds })
          : [],
        obsPointIds.length && storedPlot.time_periods.length
          ? await getApi('/readings/simple/', {
              effective: true,
              observation_point__in: obsPointIds,
              reading_datetime__in: storedPlot.time_periods.map(
                ({ survey_datetime }) => survey_datetime
              ),
            })
          : [],
      ]);

      const surveySeries = sortReadingsIntoSurveyLevellingSeries(
        storedPlot,
        allReadings
      );

      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_RESPONSE(
          storedPlotId,
          observationPoints,
          surveySeries
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_PLOTTING_DATA_ERROR(storedPlotId, errorToString(e))
      );
    }
  };
}
