import React, { useCallback, useEffect } from 'react';
import { ObsPointBulkCreateErrorsView } from './ObsPointBulkCreateErrorsView';
import { useHistory } from 'react-router';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import { useDispatch } from 'react-redux';
import { clearBulkCreateErrors } from 'ducks/bulk-create/errors';

export const ObsPointBulkCreateErrorsScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const state = useShallowEqualSelector((state: FullState) => {
    return {
      errorDetail: state.bulkCreateErrors.errorDetail,
    };
  });

  const handleCancel = useCallback(() => {
    history.push('/observation-point/');
  }, [history]);

  useEffect(() => {
    return () => {
      dispatch(clearBulkCreateErrors());
    };
  }, [dispatch]);

  return (
    <ObsPointBulkCreateErrorsView
      errorDetail={state.errorDetail}
      handleCancel={handleCancel}
    />
  );
};
