import React, { useMemo, useState, RefObject } from 'react';
import { XYPlot, LineSeries } from 'react-vis';
import { LabelSeries, CustomSVGSeries } from 'components/plots/react-vis-hacks';
import { Trans } from '@lingui/macro';
import groupBy from 'lodash/groupBy';
import range from 'lodash/range';
import sortBy from 'lodash/sortBy';
import pick from 'lodash/pick';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import {
  InstrumentType,
  SimpleReading,
  SpatialWanderPlotObservationPoint,
  StoredSpatialWanderPlotSurveyPoint,
} from 'util/backendapi/types/Model';
import {
  calculatePlanViewOrigin,
  calculateSpatialPlotDomains,
  ICON_MARKER_DEFORMATION,
} from './spatial-plot-utils';
import { useSpatialPlotResizeObserver } from './useSpatialPlotResizeObserver';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import { StoredSpatialWanderPlotWithArea } from 'ducks/stored-plot/detail';
import { createPortal } from 'react-dom';
import { Toggle } from 'components/base/form/toggle/Toggle';
import { showHideToggleOptions } from 'components/base/form/toggle-field/ToggleField';
import { isNotNull } from 'util/validation';
import { formatDateForDisplay, convertDatetimeToDate } from 'util/dates';
import { StoredSpatialWanderPlot_ERROR_ELLIPSE } from 'util/backendapi/types/Enum';

import './spatial-plot.scss';
import { selectSpatialPlotData } from 'ducks/plot/spatial';
import { useSpatialPlotHover } from './useSpatialPlotHover';
import CrosshairWithReticle from 'components/plots/crosshairwithreticle';
import { Popup } from 'components/plots/popups/Popup';
import {
  WanderPlotSitePopup,
  WanderLinePlotPoint,
} from './WanderPlotSitePopup';
import { detectExportMode } from 'util/export';
import {
  createReadingsLegendEntries,
  parseReadingsLegendSymbol,
  ReadingsLegend,
} from './ReadingsLegend';

// Because we draw the icons using fonts, they're actuall LabelSeries. But
// providing an IconSeries alias hopefully makes the code a little easier to read.
const IconSeries = LabelSeries;

const NO_PLOT_MARGIN = { top: 0, bottom: 0, left: 0, right: 0 };

const WANDER_PLOT_DEFAULT_MARKER_SYMBOL = ICON_MARKER_DEFORMATION;
const WANDER_PLOT_DEFAULT_MARKER_COLOR = 'red';

interface SitePlotData {
  surveyPoint: StoredSpatialWanderPlotSurveyPoint;
  // The x,y, plotting location for this survey marker / site
  x: number;
  y: number;
  // A list of x,y points to draw the site's "wander line"
  wanderPoints: WanderLinePlotPoint[];
  lastWander: WanderLinePlotPoint[];
}

export function SpatialWanderPlot(props: {
  storedPlot: StoredSpatialWanderPlotWithArea;
  buttonsPortal?: RefObject<HTMLElement>;
}) {
  const { storedPlot } = props;

  const plotState = useShallowEqualSelector((state: FullState) =>
    selectSpatialPlotData(state, storedPlot.id)
  );

  if (plotState?.isLoading) {
    return <Loading />;
  } else if (plotState?.errorMessage) {
    return <AlertWarning>{plotState?.errorMessage}</AlertWarning>;
  } else if (
    !storedPlot ||
    !plotState?.observationPoints ||
    !plotState?.readings
  ) {
    return null;
  }

  return (
    <SpatialWanderPlotView
      obsPoints={
        plotState.observationPoints as SpatialWanderPlotObservationPoint[]
      }
      readings={plotState.readings}
      storedPlot={storedPlot}
      buttonsPortal={props.buttonsPortal}
    />
  );
}

interface SpatialWanderPlotViewProps {
  storedPlot: StoredSpatialWanderPlotWithArea;
  obsPoints: SpatialWanderPlotObservationPoint[];
  readings: SimpleReading[];
  buttonsPortal?: RefObject<HTMLElement>;
  initialHeight?: number;
  dataTablePos?: {
    top: string;
    left: string;
  };
}

export function SpatialWanderPlotView(props: SpatialWanderPlotViewProps) {
  const baseObsPoint = props.obsPoints.find(
    (op) => op.id === props.storedPlot.base_observation_point
  );
  if (!baseObsPoint) {
    return (
      <AlertWarning>
        <Trans>Error: Could not load base observation point.</Trans>
      </AlertWarning>
    );
  }
  if (
    baseObsPoint.nztm_easting === null ||
    baseObsPoint.nztm_northing === null
  ) {
    return (
      <AlertWarning>
        <Trans>
          Error: Base observation {baseObsPoint.code} does not have geographic
          location data.
        </Trans>
      </AlertWarning>
    );
  }

  return (
    <Inner
      {...props}
      baseObsPoint={baseObsPoint as InnerProps['baseObsPoint']}
    />
  );
}

type InnerProps = SpatialWanderPlotViewProps & {
  baseObsPoint: SpatialWanderPlotObservationPoint & {
    nztm_easting: string;
    nztm_northing: string;
  };
};

function Inner(props: InnerProps) {
  const {
    storedPlot,
    obsPoints,
    baseObsPoint,
    readings,
    buttonsPortal,
    initialHeight,
    dataTablePos,
  } = props;

  const origin = calculatePlanViewOrigin(storedPlot, baseObsPoint);
  const [xDomain, yDomain] = calculateSpatialPlotDomains(storedPlot, origin);

  const sitesForPlotting = useMemo(
    () =>
      calculateSitesForWanderPlot(
        storedPlot,
        obsPoints,
        readings,
        xDomain,
        yDomain
      ),
    [storedPlot, obsPoints, readings, xDomain, yDomain]
  );

  const { ref, widthPx, heightPx } = useSpatialPlotResizeObserver(
    storedPlot,
    NO_PLOT_MARGIN,
    initialHeight
  );

  const errorEllipses = useMemo(
    () => calculateErrorEllipses(storedPlot, sitesForPlotting, heightPx),
    [heightPx, sitesForPlotting, storedPlot]
  );

  const [showDataTable, setShowDataTable] = useState<boolean>(
    storedPlot.show_data_table
  );

  const hover = useSpatialPlotHover<WanderLinePlotPoint>();

  // A combined series of the wander points for every site. We need them all
  // in one series, to do the mouseover.
  const hoverPoints = useMemo(() => {
    return sitesForPlotting.flatMap((s) =>
      s.wanderPoints.concat(s.lastWander[1])
    );
  }, [sitesForPlotting]);

  return (
    <>
      {buttonsPortal?.current &&
        createPortal(
          <Toggle
            inline={true}
            className="radio-toggle-data-table"
            options={showHideToggleOptions}
            name="show-data-table-radio"
            label={<Trans>Data table:</Trans>}
            defaultValue={showDataTable}
            onChange={setShowDataTable}
          />,
          buttonsPortal.current
        )}
      <div className="non-cropping-plot-wrapper">
        <div className="plot-area" ref={ref}>
          <XYPlot
            height={heightPx}
            width={widthPx}
            xDomain={xDomain}
            yDomain={yDomain}
            margin={NO_PLOT_MARGIN}
          >
            <ManualSVGContainer {...props} />
            {/* An icon or each deformation survey marker site */}
            <IconSeries
              data={sitesForPlotting}
              labelAnchorX="middle"
              labelAnchorY="central"
              className="wander-deformation-marker-icon"
            />
            {/* A label for each deformation survey marker site */}
            <LabelSeries
              data={sitesForPlotting.filter((p) => p.surveyPoint.show_label)}
              getLabel={(p: SitePlotData) => p.surveyPoint.label}
              className="wander-deformation-marker-label"
            />
            {/* The "wander" lines for each deformation survey marker */}
            {sitesForPlotting.map(
              ({ surveyPoint: site, wanderPoints, lastWander }) => [
                // The black part of the wander line
                <LineSeries
                  key={`${site.site}-wander`}
                  data={wanderPoints}
                  className="wander-line"
                  // Allow color to be controlled by CSS
                  color=""
                />,
                // The red part of the wander line (representing the latest survey)
                <LineSeries
                  key={`${site.site}-last`}
                  data={lastWander}
                  className="wander-line latest-survey"
                  // Allow color to be controlled by CSS
                  color=""
                />,
              ]
            )}
            {/* Error ellipses */}
            <CustomSVGSeries
              data={errorEllipses}
              className="wander-error-ellipse"
              customComponent={(p: ArrayElements<typeof errorEllipses>) => (
                <g transform={`rotate(${p.errorBearing})`}>
                  <ellipse
                    className={p.className}
                    cx="0"
                    cy="0"
                    rx={p.errorSemiMinor}
                    ry={p.errorSemiMajor}
                  />
                </g>
              )}
            />
            {/* An invisible data series for handling the mouseover functionality */}
            {!detectExportMode() && (
              <LineSeries<WanderLinePlotPoint>
                animation={false}
                data={hoverPoints}
                className="plot-readings-invisible-line"
                strokeWidth={0}
                onNearestXY={hover.isHoverable ? hover.onNearestXY : undefined}
              />
            )}
            {hover.hoverPoint && (
              <CrosshairWithReticle
                showVertical={false}
                showHorizontal={false}
                values={[hover.hoverPoint]}
                onReticleClick={(point, position) => {
                  hover.setSelectedPoint({
                    ...point,
                    position,
                  });
                  hover.setIsHoverable(false);
                }}
              />
            )}
            {hover.selectedPoint && (
              <Popup point={hover.selectedPoint}>
                <WanderPlotSitePopup
                  timeZone={storedPlot.area.time_zone.name}
                  onClose={hover.onClosePopup}
                  point={hover.selectedPoint}
                  obsPoints={obsPoints}
                />
              </Popup>
            )}
          </XYPlot>
        </div>
        {showDataTable && (
          <div
            className="data-table-wrapper-wander"
            style={{
              position: 'absolute',
              top: `${dataTablePos?.top ?? `20px`}`,
              left: `${dataTablePos?.left ?? `20px`}`,
            }}
          >
            <WanderPlotDataTable
              storedPlot={storedPlot}
              sitesForPlotting={sitesForPlotting}
            />
          </div>
        )}
      </div>
    </>
  );
}

function calculateSitesForWanderPlot(
  storedPlot: StoredSpatialWanderPlotWithArea,
  obsPoints: SpatialWanderPlotObservationPoint[],
  readings: SimpleReading[],
  xDomain: [number, number],
  yDomain: [number, number]
): SitePlotData[] {
  const obsPointsBySite = groupBy(
    sortBy(obsPoints, (op) => op.code),
    (op) => op.site
  );

  let instrumentTypes: Record<string, InstrumentType> = {};
  storedPlot.instrument_types.forEach((it) => {
    instrumentTypes[it.code] = it;
  });

  let obsPointInstrumentType: Record<number, InstrumentType> = {};
  obsPoints.forEach((obs) => {
    const instrumentType = instrumentTypes[obs.instrument_type__code];
    obsPointInstrumentType[obs.id] = instrumentType;
  });

  const sites = storedPlot.survey_points
    .map((surveyPoint) => {
      const myObsPoints = obsPointsBySite[surveyPoint.site];
      const coordsOp = myObsPoints?.find(
        (op) =>
          op.nztm_easting !== null &&
          op.nztm_northing !== null &&
          +op.nztm_easting >= xDomain[0] &&
          +op.nztm_easting <= xDomain[1] &&
          +op.nztm_northing >= yDomain[0] &&
          +op.nztm_northing <= yDomain[1]
      );
      if (!coordsOp) {
        return null;
      } else {
        return {
          surveyPoint,
          coordsOpId: coordsOp.id,
          x: coordsOp.nztm_easting!,
          y: coordsOp.nztm_northing!,
        };
      }
    })
    .filter(isNotNull);

  // We can assume the readings are already sorted chronologically?
  const readingsByOP = groupBy(
    readings
      .map((r) => ({
        observationPoint: r.observation_point,
        readingDatetime: r.reading_datetime,
        movement: r.adjusted_reading_entries[0]?.value,
        errorSize: r.adjusted_reading_entries[1]?.value,
        errorBearing: r.adjusted_reading_entries[2]?.value,
      }))
      .filter(
        (r): r is typeof r & { movement: string } =>
          typeof r.movement === 'string'
      ),
    (r) => r.observationPoint
  );

  // Since our plot's domain is in nztm map grid meters, the easiest way to
  // get React-Vis to plot everything correctly is to scale the coordinates
  // from: survey mm -> paperspace mm -> map grid meters
  const scaleFactor =
    ((1 / +storedPlot.wander_scale) * +storedPlot.scale) / 1000;

  return sites.map((s) => {
    const baseX = s.x;
    const baseY = s.y;
    const wanderPoints: WanderLinePlotPoint[] = [];

    const xReadings = readingsByOP[s.surveyPoint.easting_observation_point];
    const yReadings = readingsByOP[s.surveyPoint.northing_observation_point];

    const northingInstrumentType =
      obsPointInstrumentType[s.surveyPoint.northing_observation_point];

    const { symbol, style } = parseReadingsLegendSymbol(
      northingInstrumentType?.readings_legend_symbol,
      WANDER_PLOT_DEFAULT_MARKER_SYMBOL,
      WANDER_PLOT_DEFAULT_MARKER_COLOR
    );

    if (!xReadings || !yReadings) {
      return {
        surveyPoint: s.surveyPoint,
        x: baseX,
        y: baseY,
        label: symbol,
        style: style,
        wanderPoints: [],
        lastWander: [],
      };
    }

    for (let i = 0; i < storedPlot.time_periods.length; i++) {
      const time = storedPlot.time_periods[i];
      const x = xReadings.find((r) => r.readingDatetime === time);
      const y = yReadings.find((r) => r.readingDatetime === time);
      if (!x || !y) {
        continue;
      }

      wanderPoints.push({
        surveyDatetime: time,
        x: baseX + Number(x.movement) * scaleFactor,
        y: baseY + Number(y.movement) * scaleFactor,
        errorSemiMajor: y.errorSize,
        errorSemiMinor: x.errorSize,
        errorBearing: y.errorBearing,
        eastingReading: pick(x, 'movement', 'errorSize', 'errorBearing'),
        northingReading: pick(y, 'movement', 'errorSize', 'errorBearing'),
        coordsOpId: s.coordsOpId,
        surveyPoint: s.surveyPoint,
      });
    }

    return {
      surveyPoint: s.surveyPoint,
      x: wanderPoints[0]?.x ?? baseX,
      y: wanderPoints[0]?.y ?? baseY,
      label: symbol,
      style: style,
      wanderPoints: wanderPoints.slice(0, -1),
      lastWander: wanderPoints.slice(-2),
    };
  });
}

function calculateErrorEllipses(
  storedPlot: StoredSpatialWanderPlotWithArea,
  sitesForPlotting: SitePlotData[],
  heightPx: number
) {
  const wanderScale = +storedPlot.wander_scale;
  const imageHeightMM = +storedPlot.paperspace_height;

  // We need to specify the ellipses' size in pixels. So we scale by:
  // wander MM -> paperspace MM -> plot PX
  const scaleFactor = (1 / wanderScale) * (heightPx / imageHeightMM);
  return sitesForPlotting.flatMap((site) => {
    const latestSurvey = site.lastWander[site.lastWander.length - 1];
    const previousSurvey = site.lastWander[0];
    const initialSurvey = site.wanderPoints[0];
    return [
      storedPlot.error_ellipses ===
      StoredSpatialWanderPlot_ERROR_ELLIPSE.INITIAL_AND_LATEST
        ? { ...initialSurvey, className: 'initial-survey' }
        : { ...previousSurvey, className: 'previous-survey' },
      { ...latestSurvey, className: 'latest-survey' },
    ]
      .filter(
        (p) => p && p.errorSemiMajor && p.errorSemiMinor && p.errorBearing
      )
      .map((p) => ({
        ...p,
        errorSemiMajor: Number(p.errorSemiMajor) * scaleFactor,
        errorSemiMinor: Number(p.errorSemiMinor) * scaleFactor,
      }));
  });
}

/**
 * A container function to render low-level SVG elements that can't be handled
 * very gracefully by a React-Vis series. (Although it would be possible to
 * do it hackily with a CustomSVGSeries that has a single data point at the
 * upper-left of the plot.)
 *
 * @param props
 */
function ManualSVGContainer(
  props: SpatialWanderPlotViewProps & {
    // React-Vis will automagically pass these props in via child prop
    // rewriting.
    innerWidth?: number;
    innerHeight?: number;
  }
) {
  const { storedPlot, innerWidth, innerHeight } = props;

  const readingsLegendEntries = useMemo(
    () =>
      createReadingsLegendEntries(
        storedPlot.instrument_types,
        WANDER_PLOT_DEFAULT_MARKER_SYMBOL,
        WANDER_PLOT_DEFAULT_MARKER_COLOR
      ),
    [storedPlot.instrument_types]
  );

  return (
    <>
      {/* The plot's background images using normal <img /> tag */}
      {/* The SVG <image href /> tag will not render in SSR */}
      {/* Need to wrap inside SVG otherwise it wont render correctly in SSR */}
      <svg x={0} y={0} width={innerWidth} height={innerHeight}>
        <foreignObject x="0" y="0" width="100%" height="100%">
          <img src={storedPlot.base_layer_image} width="100%" alt="" />
        </foreignObject>
      </svg>
      {storedPlot.additional_layer_image && (
        <svg x={0} y={0} width={innerWidth} height={innerHeight}>
          <foreignObject x="0" y="0" width="100%" height="100%">
            <img src={storedPlot.additional_layer_image!} width="100%" alt="" />
          </foreignObject>
        </svg>
      )}
      <MovementVectorScale {...props} />
      <ReadingsLegend {...props} legendEntries={readingsLegendEntries} />
    </>
  );
}
// React-Vis requires this attribute to be present.
ManualSVGContainer.requiresSVG = true;

// Height of the rectangle around the movement scale, in paperspace mm
// (Copied from the size of the image scale legend in eDamDoctor)
const MOVEMENT_LEGEND_VERT_MM = 3.625;
// Width/length of the rectangle around the movement scale, in paperspace mm
// (Copied from the size of the image scale legend in eDamDoctor)
const MOVEMENT_LEGEND_HORIZ_MM = 56.125;
// Min padding between last scale "tick" and the border rectangle, in paperspace mm
const MOVEMENT_LEGEND_PADDING_MM = 1.5;
// Height of the legend scale ticks, in paperspace mm.
const MOVEMENT_LEGEND_TICK_HEIGHT_MM = MOVEMENT_LEGEND_VERT_MM / 3;
// Number of scale mm between ticks
const MOVEMENT_LEGEND_TICK_INTERVAL = 5;
// Number of steps between labeled ticks
const MOVEMENT_LEGEND_LABEL_INTERVAL = 2;

function MovementVectorScale(
  props: SpatialWanderPlotViewProps & {
    innerWidth?: number;
    innerHeight?: number;
  }
) {
  const { storedPlot, innerWidth, innerHeight } = props;

  const minX = +storedPlot.movement_legend_paperspace_x;
  const minY =
    +storedPlot.paperspace_height - +storedPlot.movement_legend_paperspace_y;

  // How many paperspace mm is one wander scale mm
  const oneScaleMM = 1 / +storedPlot.wander_scale;
  // How many ticks can we fit into the wander scale legend box
  const numTicks = Math.floor(
    (MOVEMENT_LEGEND_HORIZ_MM - MOVEMENT_LEGEND_PADDING_MM) /
      (oneScaleMM * MOVEMENT_LEGEND_TICK_INTERVAL)
  );

  const tickLabelFontSize =
    0.8 * (MOVEMENT_LEGEND_VERT_MM - MOVEMENT_LEGEND_TICK_HEIGHT_MM);

  // The "tick" lines, drawn as a single SVG <path>.
  // M<x> <y> = move to absolute coordinates
  // m<dx> <dy> = move to relative position
  // v<dy>     = draw a vertical line from current position
  // See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#Path_commands
  const ticksPath =
    `M${minX + oneScaleMM} ${minY} ` +
    // First draw four ticks spaced one scale mm apart
    (
      `v${MOVEMENT_LEGEND_TICK_HEIGHT_MM} ` +
      `m${oneScaleMM} ${-1 * MOVEMENT_LEGEND_TICK_HEIGHT_MM} `
    ).repeat(4) +
    // Then draw the regular ticks, spaced "tick interval" scale mm apart.
    (
      `v${MOVEMENT_LEGEND_TICK_HEIGHT_MM} ` +
      `m${oneScaleMM * MOVEMENT_LEGEND_TICK_INTERVAL} ${
        -1 * MOVEMENT_LEGEND_TICK_HEIGHT_MM
      }`
    ).repeat(numTicks);

  return (
    <svg
      className="movement-vector-scale"
      x={0}
      y={0}
      width={innerWidth}
      height={innerHeight}
      // Nested SVG with a "viewBox" that matches the paperspace size, allows us
      // to position everything using paperspace coordinates
      viewBox={`0 0 ${storedPlot.paperspace_width} ${storedPlot.paperspace_height}`}
    >
      {/* The border box around the scale legend. */}
      <rect
        x={minX}
        y={minY}
        width={MOVEMENT_LEGEND_HORIZ_MM}
        height={MOVEMENT_LEGEND_VERT_MM}
      />
      {/* The ticks on the scale legend. */}
      <path d={ticksPath} />
      {/* The labels on some of the ticks */}
      {range(0, numTicks, MOVEMENT_LEGEND_LABEL_INTERVAL).map((tickIdx) => (
        <text
          key={tickIdx}
          x={minX + oneScaleMM * MOVEMENT_LEGEND_TICK_INTERVAL * (tickIdx + 1)}
          y={minY + MOVEMENT_LEGEND_VERT_MM}
          fontSize={tickLabelFontSize}
          textAnchor="middle"
          // TODO: "text-after-edge" doesn't work in WebKit. Workround is to use "auto"
          // and shift the labels up by a fraction of an "em"
          dominantBaseline="auto"
          dy="-0.3em"
        >
          {tickIdx * MOVEMENT_LEGEND_TICK_INTERVAL}
        </text>
      ))}
      {/* Static text labels around the scale legend. */}
      <text
        className="movement-vector-scale-label"
        x={minX}
        y={minY}
        fontSize={tickLabelFontSize * 1.2}
        textAnchor="start"
        // TODO: "text-after-edge" doesn't work in WebKit. Workround is to use "auto"
        // and shift the labels up by a fraction of an "em"
        dominantBaseline="auto"
        dy="-0.5em"
      >
        <Trans>Movement vector scale</Trans>
      </text>
      <text
        className="movement-vector-scale-units-label"
        x={minX + MOVEMENT_LEGEND_HORIZ_MM}
        dx="1"
        y={minY + MOVEMENT_LEGEND_VERT_MM}
        fontSize={tickLabelFontSize}
        textAnchor="start"
        // TODO: "text-after-edge" doesn't work in WebKit. Workround is to use "auto"
        // and shift the labels up by a fraction of an "em"
        dominantBaseline="auto"
        dy="-0.3em"
      >
        <Trans>mm</Trans>
      </text>
    </svg>
  );
}

function WanderPlotDataTable(props: {
  storedPlot: StoredSpatialWanderPlotWithArea;
  sitesForPlotting: SitePlotData[];
}) {
  const { storedPlot, sitesForPlotting } = props;
  const showLatestAndInitial =
    storedPlot.error_ellipses ===
    StoredSpatialWanderPlot_ERROR_ELLIPSE.INITIAL_AND_LATEST;
  return (
    <div className="table-responsive data-table data-table-wander">
      <table>
        <caption>
          <Trans>Deformation sites</Trans>
        </caption>
        <thead>
          <tr>
            <th>
              <Trans>Site</Trans>
            </th>
            <th>
              <Trans>Latest survey</Trans>
            </th>
            <th>
              {showLatestAndInitial ? (
                <Trans>Initial survey</Trans>
              ) : (
                <Trans>Previous survey</Trans>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {storedPlot.survey_points
            .filter((s) => s.show_in_data_table)
            .map(({ site, label }) => {
              const sitePlotData = sitesForPlotting.find(
                (wl) => wl.surveyPoint.site === site
              );

              // lastWander only has 2 elements in it, so lastWander[1] will be the latest survey,
              // and lastWander[0] will be the "previous" survey
              const latestSurvey = sitePlotData?.lastWander[1]?.surveyDatetime;

              const otherSurvey = showLatestAndInitial
                ? // initial survey
                  sitePlotData?.wanderPoints[0]?.surveyDatetime
                : // previous survey
                  sitePlotData?.lastWander[0]?.surveyDatetime;

              return (
                <tr key={site}>
                  <td>{label}</td>
                  <td>
                    {latestSurvey
                      ? formatDateForDisplay(
                          convertDatetimeToDate(
                            latestSurvey,
                            storedPlot.area.time_zone.name
                          )
                        )
                      : '-'}
                  </td>
                  <td>
                    {otherSurvey
                      ? formatDateForDisplay(
                          convertDatetimeToDate(
                            otherSurvey,
                            storedPlot.area.time_zone.name
                          )
                        )
                      : '-'}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
