/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback } from 'react';
import first from 'lodash/first';
import last from 'lodash/last';
import union from 'lodash/union';
import { Pagination, PaginationMeta } from 'util/backendapi/pagination';
import './pagination.scss';
import { useLocation, useHistory } from 'react-router';
import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import { I18n } from '@lingui/react';
import { rangeInclusive } from 'util/misc';
import { DMSHotKey } from 'main/DMSHotKey';

/**
 * Show links to this many pages (centered around the current page)
 */
const PAGE_RANGE_DISPLAYED = 5;
/**
 * Show links to this many pages from the start and this many pages from the end.
 */
const MARGIN_PAGES_DISPLAYED = 2;

interface Props {
  pagination: null | PaginationMeta;
  onPageChanged?: (offsetIndex: number) => void;
}

/**
 * A pager component, based on the `react-paginate` library, except that it
 * supports infinite pagination.
 */
export const ListPager = React.memo(function ListPager(props: Props) {
  const location = useLocation();
  const history = useHistory();

  const { pagination, onPageChanged } = props;
  const handlePageChanged = useCallback(
    (selected: number) => {
      if (pagination) {
        const newOffset = selected * pagination.requested.limit;
        if (typeof onPageChanged === 'function') {
          return onPageChanged(newOffset);
        }

        // if props.onPageChanged is not specified
        // we'll go ahead and append offset & limit parameters to the URL
        const params = new URLSearchParams(location.search);

        params.set('limit', `${pagination.requested.limit}`);
        params.set('offset', `${newOffset}`);

        history.push({
          ...location,
          search: String(params),
        });
      }
    },
    [history, location, onPageChanged, pagination]
  );

  const pageCount = pagination && Pagination.pageCount(pagination);

  const currentPageIndex = pagination
    ? Pagination.currentPageIndex(pagination)
    : 0;
  const currentPageNumber = currentPageIndex + 1;
  const buttonsToShow = pickButtonsToShow(pageCount, currentPageNumber);

  const onPrevious = useCallback(() => {
    if (currentPageNumber <= 1) {
      return;
    }

    handlePageChanged(currentPageIndex - 1);
  }, [currentPageNumber, currentPageIndex, handlePageChanged]);

  const onNext = useCallback(() => {
    if (pageCount !== null && currentPageNumber >= pageCount) {
      return;
    }

    handlePageChanged(currentPageIndex + 1);
  }, [currentPageNumber, currentPageIndex, pageCount, handlePageChanged]);

  // Don't display the paginator if...
  if (
    // Pagination data not yet received from backend
    !pagination ||
    !pagination.received ||
    // Only 0 or 1 page of results
    pageCount === 0 ||
    (pageCount === 1 &&
      // Edge case: If page count is 1 and received.start is null, it means
      // there is one page of data but they requested an out-of-range page.
      // In that case, do not hide the paginator, so the user can use it to
      // click back to a valid page.
      pagination.received.start !== null)
  ) {
    return null;
  }

  return (
    <div className="text-right">
      <DMSHotKey allowChanges shortcut="PAGINATION_NEXT" onPress={onNext} />

      <DMSHotKey
        allowChanges
        shortcut="PAGINATION_PREVIOUS"
        onPress={onPrevious}
      />

      <ul className="pagination">
        <PrevNextButton
          direction="prev"
          isDisabled={currentPageNumber <= 1}
          onClick={onPrevious}
        >
          <Trans>Previous</Trans>
        </PrevNextButton>
        {buttonsToShow.map((b) =>
          b === '<' ? (
            <Ellipsis
              key={b}
              onClick={() =>
                handlePageChanged(currentPageIndex - PAGE_RANGE_DISPLAYED)
              }
            />
          ) : b === '>' ? (
            <Ellipsis
              key={b}
              onClick={() =>
                handlePageChanged(currentPageIndex + PAGE_RANGE_DISPLAYED)
              }
            />
          ) : (
            <PageButton
              key={b}
              pageNumber={b}
              isSelected={currentPageNumber === b}
              goToPageIndex={handlePageChanged}
            />
          )
        )}
        <PrevNextButton
          direction="next"
          isDisabled={pageCount !== null && currentPageNumber >= pageCount}
          onClick={onNext}
        >
          <Trans>Next</Trans>
        </PrevNextButton>
      </ul>
    </div>
  );
});

function PrevNextButton(props: {
  isDisabled: boolean;
  onClick: () => void;
  children: JSX.Element;
  direction: 'prev' | 'next';
}) {
  const { direction, isDisabled, onClick } = props;
  return (
    <li
      className={classNames(`btn-${direction}-page`, {
        disabled: isDisabled,
      })}
    >
      <a
        tabIndex={0}
        role="button"
        aria-disabled={isDisabled}
        onClick={isDisabled ? undefined : onClick}
      >
        {props.children}
      </a>
    </li>
  );
}

function Ellipsis(props: { onClick: () => void }) {
  return (
    <li className="break">
      <a role="button" tabIndex={0} onClick={props.onClick}>
        ...
      </a>
    </li>
  );
}

function PageButton(props: {
  pageNumber: number;
  isSelected: boolean;
  goToPageIndex: (idx: number) => void;
}) {
  const { pageNumber, isSelected, goToPageIndex: pageToIdx } = props;
  const handleClick = useCallback(
    () => pageToIdx(pageNumber - 1),
    [pageNumber, pageToIdx]
  );
  return (
    <I18n>
      {({ i18n }) => (
        <li
          className={classNames('btn-numbered-page', {
            active: isSelected,
          })}
        >
          <a
            role="button"
            tabIndex={0}
            aria-current="page"
            aria-label={
              isSelected
                ? i18n._(t`Page ${pageNumber} is your current page`)
                : i18n._(t`Page ${pageNumber}`)
            }
            aria-disabled={isSelected}
            onClick={isSelected ? undefined : handleClick}
          >
            {pageNumber}
          </a>
        </li>
      )}
    </I18n>
  );
}

/**
 * The algorithm decides which page numbers and ellipses to display. It's based
 * on the behavior of the react-paginate library, and the interaction of its
 * "marginPagesDisplayed" and "pageRangeDisplayed" props (which we've replaced
 * here with constants at the top of this file):
 *
 * 1. MARGIN_PAGES_DISPLAYED: Show links to this many pages at start and end
 *     of list. If MARGIN_PAGES_DISPLAYED = 2, and pageCount is 10 pages, you'd
 *     always start the pager with links to (1, 2) and end it with links to
 *     (9, 10). If pageCount is unknown, we don't/can't show the links to the
 *     last X pages.
 * 2. PAGE_RANGE_DISPLAYED: Show links to this many pages in the middle of the
 *     list, centered around the current page. If the current page is 11, and
 *     PAGE_RANGE_DISPLAYED is 5, you should see links to (9, 10, 11, 12, 13).
 * 3. If there is a gap between the MARGIN_PAGES_DISPLAYED pages and the
 *     PAGE_RANGE_DISPLAYED pages, we put an ellipsis "..." there.
 *     Such as: 1, 2, ..., 7, 8, 9, 10, 11, ... 15, 16. If pageCount is unknown,
 *     we end the list with an ellipsis: 1, 2, ..., 7, 8, 9, 10, 11, ...
 * 4. If the block of numbers from PAGE_RANGE_DISPLAYED would overlap the numbers
 *     from MARGIN_PAGES_DISPLAYED, we shift it out so that it doesn't overlap.
 *     So if MARGIN_PAGES_DISPLAYED is 2 (showing "1, 2"), and current page is 4,
 *     and PAGE_RANGE_DISPLAYED is 5 (showing "2, 3, 4, 5, 6"), we
 *     would shift PAGE_RANGE_DISPLAYED to show (3, 4, 5, 6, 7) instead,
 *     giving us (1, 2, 3, 4, 5, 6, 7) at the start of the pager.
 *
 * Rule #4 may seem weird, but it helps keep the number of buttons on-screen
 * fairly stable so the UI doesn't jump so much, by ensuring you always show
 * PAGE_RANGE_DISPLAYED + (MARGIN_PAGES_DISPLAYED * 2) number buttons (if there
 * are enough pages for that).
 *
 * Here's how it would look without rule #4 if you paged forward from page 1:
 *
 *  *1*, 2, 3, ..., 19, 20
 *  1, *2*, 3, 4, ..., 19, 20
 *  1, 2, *3*, 4, 5, ..., 19, 20
 *  1, 2, 3, *4*, 5, 6, ..., 19, 20
 *  1, 2, 3, 4, *5*, 6, 7, ..., 19, 20
 *  1, 2, ..., 4, 5, *6*, 7, 8, ..., 19, 20
 *  1, 2, ..., 5, 6, *7*, 8, 9, ..., 19, 20
 *
 * Here's how it looks with rule #4:
 *
 *  *1*, 2, 3, 4, 5, 6, 7, ..., 19, 20
 *  1, *2*, 3, 4, 5, 6, 7, ..., 19, 20
 *  1, 2, *3*, 4, 5, 6, 7, ..., 19, 20
 *  1, 2, 3, *4*, 5, 6, 7, ..., 19, 20
 *  1, 2, 3, 4, *5*, 6, 7, ..., 19, 20
 *  1, 2, ..., 4, 5, *6*, 7, 8, ..., 19, 20
 *  1, 2, ..., 5, 6, *7*, 8, 9, ..., 19, 20
 *
 * @see https://github.com/AdeleD/react-paginate/blob/master/react_components/PaginationBoxView.js#L229
 * @param pageCount
 * @param currentPageNumber
 * @return an array of page numbers, and/or the strings '<' and '>' to indicate
 * "prev 5" ellipsis and "next 5" ellipsis
 */
function pickButtonsToShow(
  pageCount: number | null,
  currentPageNumber: number
): (number | '<' | '>')[] {
  if (
    currentPageNumber < 1 ||
    (pageCount !== null && currentPageNumber > pageCount)
  ) {
    // Special case: If they've requested a page that's out of range, just show
    // a link to page 1.
    return [1];
  }

  // First block of pages from MARGIN_PAGES_DISPLAYED
  const firstXPages = rangeInclusive(1, MARGIN_PAGES_DISPLAYED);

  // Second block of pages from MARGIN_PAGES_DISPLAYED
  const lastXPages =
    pageCount === null
      ? []
      : rangeInclusive(pageCount - (MARGIN_PAGES_DISPLAYED - 1), pageCount);

  // block of pages from PAGE_RANGE_DISPLAYED
  let pageRangeStart = currentPageNumber - Math.floor(PAGE_RANGE_DISPLAYED / 2);
  let pageRangeEnd = pageRangeStart + PAGE_RANGE_DISPLAYED - 1;
  if (pageRangeStart <= last(firstXPages)!) {
    // Don't overlap firstXPages
    pageRangeStart = last(firstXPages)! + 1;
    pageRangeEnd = pageRangeStart + PAGE_RANGE_DISPLAYED - 1;
  } else if (pageCount !== null && pageRangeEnd >= first(lastXPages)!) {
    // Don't overlap lastXPages
    pageRangeEnd = first(lastXPages)! - 1;
    pageRangeStart = pageRangeEnd - PAGE_RANGE_DISPLAYED + 1;
  }
  const pageRangeDisplayed = rangeInclusive(pageRangeStart, pageRangeEnd);

  // Using union() to avoid duplicates
  return union<number | '<' | '>'>(
    firstXPages,
    first(pageRangeDisplayed)! > last(firstXPages)! + 1 ? ['<'] : [],
    pageRangeDisplayed,
    pageCount === null || last(pageRangeDisplayed)! < first(lastXPages)! - 1
      ? ['>']
      : [],
    lastXPages
  ).filter(
    (n) =>
      typeof n === 'string' ||
      (n >= 1 && (pageCount === null || n <= pageCount))
  );
}
