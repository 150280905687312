import React, { useEffect, useState } from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { FetcherState } from 'hooks/use-get-api';
import { errorToString, getExpectedFields } from 'util/backendapi/error';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import { getApi, postApi, patchApi } from 'util/backendapi/fetch';
import {
  ReportsChecksheetInstance,
  ChecksheetInstance,
} from 'util/backendapi/types/Model';
import {
  ChecksheetInstance_STATUS,
  ChecksheetTemplate_STATUS,
} from 'util/backendapi/types/Enum';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { formatDatetimeForDisplay } from 'util/dates';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getUserDisplayName } from 'util/user';

interface Props extends ModalContentProps {
  readingsBatch: Model.ListReadingsBatch;
  onAfterSave: () => void;
}

type FormValues = {
  checksheet_template_id: number | null;
  checksheet_instance_id: number | null;
};

async function fetchMenuOptions(readingsBatch: Model.ListReadingsBatch) {
  let checksheetTemplateOptions = null;
  if (readingsBatch.route_march) {
    const routeMarchChecksheetTemplates = await getApi(
      '/checksheet-templates/',
      {
        route_marches: readingsBatch.route_march!.id,
        status__in: [ChecksheetTemplate_STATUS.active],
      }
    );
    checksheetTemplateOptions = routeMarchChecksheetTemplates.map(
      (tmpl): SimpleSelectOption<number> => ({
        value: tmpl.id,
        label: `${tmpl.name}`,
      })
    );
  }

  const allowOtherBatchesChecksheetInstances = await getApi(
    '/reports/checksheet-instances/',
    {
      template__allow_other_batches: true,
      status__in: [ChecksheetInstance_STATUS.in_progress],
      columns: ['template__name', 'id'],
    }
  );

  const checksheetInstanceOptions = allowOtherBatchesChecksheetInstances.map(
    (instance): SimpleSelectOption<number> => ({
      value: instance.id,
      label: `${instance.template__name}`,
    })
  );

  return {
    checksheetTemplateOptions,
    checksheetInstanceOptions,
  };
}

export function AssignToChecksheetInstanceModal(props: Props) {
  const { readingsBatch } = props;

  const initialValues: FormValues = {
    checksheet_template_id: null,
    checksheet_instance_id: null,
  };

  const header = <Trans>Assign to checksheet</Trans>;

  const [currentChecksheetInstance, setCurrentChecksheetInstance] =
    useState<ReportsChecksheetInstance | null>(null);

  const fetchCurrentChecksheetInstance = async (
    checksheet_template_id: number | null
  ): Promise<ReportsChecksheetInstance | null> => {
    if (checksheet_template_id === null) {
      return null;
    }
    const response = await getApi('/reports/checksheet-instances/', {
      columns: ['created_datetime', 'created_by', 'id'],
      template: checksheet_template_id!,
      status__in: [ChecksheetInstance_STATUS.in_progress],
      ordering: ['-created_datetime'],
      limit: 1,
    });
    return response[0] ?? null;
  };

  const validate = (values: FormValues) => {
    const errors: any = {};

    if (!values.checksheet_template_id && !values.checksheet_instance_id) {
      errors.checksheet_template_id = <Trans>Checksheet is required.</Trans>;
    }

    if (values.checksheet_template_id && values.checksheet_instance_id) {
      errors.checksheet_instance_id = (
        <Trans>
          Cannot assign the same batch to multiple checksheets. Select either a
          checksheet under Checksheet or Other Batches, not both.
        </Trans>
      );
    }

    return errors;
  };

  const [menuOptions, setMenuOptions] = useState<
    FetcherState<{
      checksheetTemplateOptions: SimpleSelectOption<number>[] | null;
      checksheetInstanceOptions: SimpleSelectOption<number>[];
    }>
  >({
    isError: false,
    isLoading: true,
    data: null,
  });
  useEffect(() => {
    setMenuOptions({
      isError: false,
      isLoading: true,
      data: null,
    });

    fetchMenuOptions(readingsBatch)
      .then((data) =>
        setMenuOptions({
          isError: false,
          isLoading: false,
          data,
        })
      )
      .catch((e) =>
        setMenuOptions({
          isError: true,
          isLoading: false,
          error: errorToString(e),
          data: null,
        })
      );
  }, [readingsBatch]);

  if (menuOptions.isLoading || !menuOptions.data) {
    return (
      <ModalContent header={header}>
        <Loading />
      </ModalContent>
    );
  }
  if (menuOptions.isError) {
    return (
      <ModalContent header={header}>
        <AlertWarning>{menuOptions.error}</AlertWarning>
      </ModalContent>
    );
  }

  const { checksheetTemplateOptions, checksheetInstanceOptions } =
    menuOptions.data;

  return (
    <ModalContent header={header}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, formik) => {
          try {
            if (values.checksheet_instance_id) {
              await postApi('/checksheet-data-sources/', {
                batches: [readingsBatch.id],
                instance: values.checksheet_instance_id,
              });
            } else {
              const currentChecksheetInstance =
                await fetchCurrentChecksheetInstance(
                  values.checksheet_template_id
                );
              // If there is already an in progress checksheet instance of the selected template
              if (currentChecksheetInstance) {
                const checksheetInstance: ChecksheetInstance = await getApi(
                  `/checksheet-instances/${currentChecksheetInstance.id}/`
                );

                // Find the data source associated with the batches route march
                // and add the batch to it (unless it already has it)
                const routeMarchdataSource =
                  checksheetInstance.data_sources.find(
                    (dataSource) =>
                      dataSource.route_march?.id ===
                      readingsBatch.route_march!.id
                  );
                if (routeMarchdataSource) {
                  let batchIds = routeMarchdataSource.batches.map(
                    (batch) => batch.id
                  );

                  if (!batchIds.some((id) => id === readingsBatch.id)) {
                    batchIds.push(readingsBatch.id);

                    await patchApi(
                      `/checksheet-data-sources/${routeMarchdataSource.id}/`,
                      {
                        batches: batchIds,
                        instance: checksheetInstance.id,
                      }
                    );
                  }
                }
              } else {
                // Otherwise create a new instance
                await postApi('/checksheet-instances/', {
                  template: values.checksheet_template_id!,
                  batch: readingsBatch.id,
                });
              }
            }
          } catch (e) {
            showErrorsInFormik(formik, e, getExpectedFields(values));
            formik.setSubmitting(false);
            return;
          }

          props.hideModal();
          props.onAfterSave();
        }}
      >
        {(formik) => {
          return (
            <Form>
              {formik.status}

              <FormSection>
                <FormItem label={<Trans>Batch</Trans>}>
                  <span>{readingsBatch.batch_number}</span>
                </FormItem>
                {readingsBatch.route_march ? (
                  <FormItem label={<Trans>Route march</Trans>}>
                    <span>{readingsBatch.route_march!.code}</span>
                  </FormItem>
                ) : null}
                <FormItem label={<Trans>Checksheet</Trans>}>
                  {checksheetTemplateOptions &&
                  checksheetTemplateOptions.length > 0 ? (
                    <>
                      <SimpleSelectField<number>
                        placeholder={<Trans>Select a checksheet</Trans>}
                        name="checksheet_template_id"
                        isMulti={false}
                        options={checksheetTemplateOptions}
                      />
                      <FieldError name="checksheet_template_id" />
                    </>
                  ) : (
                    <Trans>This route march is not found on a checksheet</Trans>
                  )}
                  {formik.values.checksheet_template_id &&
                  checksheetTemplateOptions &&
                  checksheetTemplateOptions.length > 0 ? (
                    <FormItem label={<Trans>Instance</Trans>}>
                      <span>
                        {currentChecksheetInstance ? (
                          <Trans>
                            Started:{' '}
                            {formatDatetimeForDisplay(
                              currentChecksheetInstance.created_datetime
                            )}{' '}
                            by{' '}
                            {getUserDisplayName(
                              currentChecksheetInstance.created_by
                            )}
                          </Trans>
                        ) : (
                          <Trans>A new instance will be created</Trans>
                        )}
                      </span>
                    </FormItem>
                  ) : null}
                </FormItem>
                {checksheetInstanceOptions.length > 0 ? (
                  <FormItem label={<Trans>Other batches</Trans>}>
                    <SimpleSelectField<number>
                      placeholder={<Trans>Select a checksheet instance</Trans>}
                      name="checksheet_instance_id"
                      isMulti={false}
                      options={checksheetInstanceOptions}
                    />
                    <FieldError name="checksheet_instance_id" />
                  </FormItem>
                ) : null}
              </FormSection>
              <FormChangeEffect<FormValues>
                onChange={({ values: prevValues }) => {
                  if (
                    formik.values.checksheet_template_id !==
                    prevValues.checksheet_template_id
                  ) {
                    fetchCurrentChecksheetInstance(
                      formik.values.checksheet_template_id
                    ).then((instance) =>
                      setCurrentChecksheetInstance(instance)
                    );
                  }
                }}
              />
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          );
        }}
      </Formik>
    </ModalContent>
  );
}
