import React from 'react';
import { FormCardSection } from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { FileField } from 'components/base/form/file-field/FileField';
import { FieldError } from 'components/base/form/errornotice/errornotice';

export function StoredSpatialLayersSection() {
  return (
    <FormCardSection
      name="layers"
      header={<Trans>Layers</Trans>}
      fields={[
        {
          name: 'base_layer_image',
          label: <Trans>Base image</Trans>,
          className: 'card-row-file-upload',
          content: (
            <>
              <FileField
                name="base_layer_image"
                fileExtensions={['.jpg', '.png', '.svg']}
              />

              <FieldError name="base_layer_image" />
            </>
          ),
        },
        {
          name: 'additional_layer_image',
          label: <Trans>Additional image</Trans>,
          className: 'card-row-file-upload',
          content: (
            <>
              <FileField
                name="additional_layer_image"
                fileExtensions={['.svg']}
              />
              <FieldError name="additional_layer_image" />
            </>
          ),
        },
      ]}
    />
  );
}
