import { getApi, getPaginated, patchApi, postApi } from 'util/backendapi/fetch';
import { Enum } from 'util/backendapi/models/api.interfaces';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export async function cancelProcessingRequest(id: number) {
  await patchApi(`/reading-processing-requests/${id}/`, {
    status: Enum.ReadingProcessingRequest_STATUS.cancelled,
  });
}

export async function confirmProcessingRequest(id: number) {
  await postApi(`/reading-processing-requests/${id}/process/`);
}

export const readingProcessingRequestDuck = makeReportingDuck(
  'readingProcessingRequest',
  (filters) => getPaginated('/reports/reading-processing-requests/', filters),
  () => getApi('/reports/reading-processing-requests/info/')
);

export type ReadingProcessingRequestAction = ReportingDuckActions<
  typeof readingProcessingRequestDuck
>;
