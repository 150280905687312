import { Model } from 'util/backendapi/models/api.interfaces';
import { getApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { DuckActions, StandardThunk } from 'main/store';

export const ActionTypes = {
  FETCH_STATIC_DATA_REPORT_START:
    'dms/static-data-report/FETCH_STATIC_DATA_REPORT_START',
  FETCH_STATIC_DATA_REPORT_RESPONSE:
    'dms/static-data-report/FETCH_STATIC_DATA_REPORT_RESPONSE',
  FETCH_STATIC_DATA_REPORT_ERROR:
    'dms/static-data-report/FETCH_STATIC_DATA_REPORT_ERROR',
} as const;

export const ActionCreators = {
  FETCH_STATIC_DATA_REPORT_START: () => ({
    type: ActionTypes.FETCH_STATIC_DATA_REPORT_START,
  }),
  FETCH_STATIC_DATA_REPORT_RESPONSE: (
    reportData: Model.StaticDataReportInstance[]
  ) => ({
    type: ActionTypes.FETCH_STATIC_DATA_REPORT_RESPONSE,
    payload: reportData,
  }),
  FETCH_STATIC_DATA_REPORT_ERROR: (errorMessage: string) => ({
    type: ActionTypes.FETCH_STATIC_DATA_REPORT_ERROR,
    error: true,
    payload: errorMessage,
  }),
};
export type StaticDataReportAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface StaticDataReportState {
  result: Model.StaticDataReportInstance[] | null;
  isLoading: boolean;
  errorMessage: string | null;
}

export function staticDataReportInitialState(): StaticDataReportState {
  return {
    result: null,
    isLoading: false,
    errorMessage: null,
  };
}

export function staticDataReportReducer(
  state = staticDataReportInitialState(),
  action: StaticDataReportAction
): StaticDataReportState {
  switch (action.type) {
    case ActionTypes.FETCH_STATIC_DATA_REPORT_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: null,
      };
    case ActionTypes.FETCH_STATIC_DATA_REPORT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        result: action.payload,
      };
    case ActionTypes.FETCH_STATIC_DATA_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export function fetchStaticDataReport(
  observationPointId: number,
  start_datetime: string | null,
  end_datetime: string
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_STATIC_DATA_REPORT_START());

    try {
      const response = await getApi('/reports/static-data/', {
        observation_point_ids: `${observationPointId}`,
        start_datetime: start_datetime ?? '',
        end_datetime: end_datetime,
      });
      return dispatch(
        ActionCreators.FETCH_STATIC_DATA_REPORT_RESPONSE(response)
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_STATIC_DATA_REPORT_ERROR(errorToString(e))
      );
    }
  };
}
