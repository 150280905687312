import { connect } from 'react-redux';
import { FullState } from 'main/reducers';
import { StandardDispatch } from 'main/store';
import {
  ConfirmReprocessingRequestProps,
  ConfirmReprocessingRequestView,
} from './ConfirmReprocessingRequestView';
import withInit, { InitProps } from 'components/hoc/withinit';
import { fetchReadingsCount } from 'ducks/readings/processingRequest/detail';

interface OwnProps {
  requestId: number;
}

type StateProps = ConfirmReprocessingRequestProps;

const mapStateToProps = (state: FullState): StateProps => ({
  readingsCountData: state.reprocessingRequest.detail.readingsCountData,
  isLoading: state.reprocessingRequest.detail.isLoading,
  error: state.reprocessingRequest.detail.error,
});

const mapDispatchToProps = (
  dispatch: StandardDispatch,
  ownProps: OwnProps
) => ({
  onInit: () => dispatch(fetchReadingsCount(ownProps.requestId)),
});

export const ConfirmReprocessingRequest = connect<
  StateProps,
  InitProps,
  OwnProps,
  FullState
>(
  mapStateToProps,
  mapDispatchToProps
)(withInit(ConfirmReprocessingRequestView));
