import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BackendStatusView from './backendstatusview';
import { fetchBackendBasicStatus } from '../../ducks/actions';

class _BackendStatusScreen extends Component {
  static propTypes = {
    // From mapStateToProps
    isFetching: PropTypes.bool.isRequired,
    status: PropTypes.string,
    // From (default) mapDispatchToProps
    dispatch: PropTypes.func.isRequired,
  };

  static mapStateToProps(store) {
    return {
      isFetching: store.backendBasicStatus.isFetching,
      status: store.backendBasicStatus.status,
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchBackendBasicStatus());
  }

  render() {
    return (
      <BackendStatusView
        isFetching={this.props.isFetching}
        status={this.props.status}
      />
    );
  }
}

const BackendStatusScreen = connect(_BackendStatusScreen.mapStateToProps)(
  _BackendStatusScreen
);
export default BackendStatusScreen;
