import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { getPaginated, getApi } from 'util/backendapi/fetch';

export const observationPointFormulaDuck = makeReportingDuck(
  'observationPointFormula',
  (filters) => getPaginated('/reports/observation-point-formulas/', filters),
  () => getApi('/reports/observation-point-formulas/info/')
);

export type ObsPointFormulaAction = ReportingDuckActions<
  typeof observationPointFormulaDuck
>;
