import React from 'react';
import { withRouter } from 'react-router-dom';

import { convertDateToDatetime, END_OF_DAY, START_OF_DAY } from 'util/dates';
import { RCPWithQueryParams } from 'util/routing';
import { FullState } from 'main/reducers';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import { QuickPlotSettingsView } from './QuickPlotSettingsView';
import { useShallowEqualSelector } from 'util/hooks';
import { getUrlSide, encodeScalesConfigForUrl } from '../quickplotselectors';
import {
  timeSeriesMetadataToUrl,
  getTimeZoneFromPlotSettingsForm,
  getObservationPointItemUrlCode,
} from 'components/plots/timeseriesplotselectors';
import { StoredTimeSeriesPlotWithArea } from 'ducks/stored-plot/detail';
import { splitObsPointItemIdent } from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { QuickPlotSettingsFormValue } from 'components/plots/settingsform.types';
import { QuickPlotSettings } from 'components/plots/timeseriesplot.types';

interface OwnProps {
  hideModal: ModalContentProps['hideModal'];
}

interface MyQueryParams {
  endDatetime?: string;
  numberOfMonths?: string;
  showAnalysisComments?: string;
  showInspectorComments?: string;
  showMedia?: string;
  startDatetime?: string;
  storedPlot?: string;
  axes?: string;
  yAxis?: string;
}

type RouterProps = RCPWithQueryParams<MyQueryParams, { obsPointCode: string }>;

type Props = OwnProps & RouterProps;

const formValuesToUrl = (
  values: QuickPlotSettingsFormValue,
  storedPlot: StoredTimeSeriesPlotWithArea | null
) => {
  const { opItems, startDate, endDate, yAxisSides, yAxisScales } = values;
  if (opItems.length === 0) {
    return '';
  }
  const identifierString = values.opItemDetails
    .map(
      (opt) =>
        opt.observationPoint &&
        getObservationPointItemUrlCode(
          opt.observationPoint.code,
          splitObsPointItemIdent(opt.value)!.item_number
        )
    )
    .join(',');

  const params = new URLSearchParams();
  params.set(
    'yAxis',
    opItems
      .map((opItemVal) => {
        const yas = yAxisSides.find((y) => y.opItem === opItemVal);
        return getUrlSide(yas ? yas.side : null);
      })
      .join('')
  );

  // We use `~` & `_` as separators because they are safe for friendly URL
  // @see https://stackoverflow.com/questions/695438/safe-characters-for-friendly-url
  // eg. L~-100~100_R~0~500
  const axes = encodeScalesConfigForUrl(
    yAxisScales.filter((scale) => scale.mode !== 'auto')
  );
  if (axes) {
    params.set('axes', axes);
  }

  if (values.numberOfMonths) {
    params.set('numberOfMonths', String(values.numberOfMonths));
  }

  if (startDate || endDate) {
    const timeZone = getTimeZoneFromPlotSettingsForm(values, storedPlot);

    if (startDate) {
      // This should get us a timestamp of midnight, on the selected start date,
      // in the timezone of the selected observation point.
      params.set(
        'startDatetime',
        convertDateToDatetime(startDate, timeZone, START_OF_DAY)
      );
    }
    if (endDate) {
      // This should get us a timestamp of 11:59:59pm, on the selected end date,
      // in the timezone of the selected observation point.
      params.set(
        'endDatetime',
        convertDateToDatetime(endDate, timeZone, END_OF_DAY)
      );
    }
  }

  if (values.showAnalysisComments) {
    params.set('showAnalysisComments', '1');
  }

  if (values.showInspectorComments) {
    params.set('showInspectorComments', '1');
  }

  if (values.showMedia) {
    params.set('showMedia', '1');
  }

  if (storedPlot) {
    params.set('storedPlot', storedPlot.name);
  }

  return `${identifierString}?${params.toString()}`;
};

export const InnerQuickPlotSettingsModal: React.FunctionComponent<Props> =
  function (props) {
    const state = useShallowEqualSelector(function (state: FullState) {
      return {
        resolvedSettings: state.plot.scatterTimeSeries.plots.quickPlot
          .resolved as QuickPlotSettings,
        isLoading: state.plot.scatterTimeSeries.plots.quickPlot.isLoading,
      };
    });

    /**
     * When the user submits the settings form, we add the new settings to the URL,
     * which in turn will cause the plot to update.
     */
    const handleSubmit = React.useCallback(
      (values: QuickPlotSettingsFormValue) => {
        if (!state) {
          return;
        }
        const urlFromSubmitted = formValuesToUrl(
          values,
          state.resolvedSettings && state.resolvedSettings.storedPlot
        );

        const { path, search } = timeSeriesMetadataToUrl(
          state.resolvedSettings
        );
        const urlFromResolvedSettings = `${path}?${search.toString()}`;

        if (urlFromSubmitted !== urlFromResolvedSettings) {
          props.history.push(`/quickplot/${urlFromSubmitted}`);
        }

        props.hideModal.call(null);
      },
      [props.hideModal, props.history, state]
    );

    return <QuickPlotSettingsView {...state} onSubmit={handleSubmit} />;
  };

export const QuickPlotSettingsModal = withRouter(
  InnerQuickPlotSettingsModal
) as React.ComponentClass<OwnProps> & {
  WrappedComponent: typeof InnerQuickPlotSettingsModal;
};
QuickPlotSettingsModal.WrappedComponent = InnerQuickPlotSettingsModal;
