import React from 'react';
import { TRANSLATED_ENUMS } from 'util/backendapi/types/Enum';
import { Trans } from '@lingui/react';

// Some type aliases to make things below more readable.
type TranslatedEnums = typeof TRANSLATED_ENUMS;
type TranslatedEnumNames = keyof TranslatedEnums;
// The allowed values for a given enum name.
type TranslatedEnumVals<EnumName extends TranslatedEnumNames> = ArrayElements<
  TranslatedEnums[EnumName]
>;

/**
 * A helper function for rendering translations mapped to backend enums.
 * It basically just calls <Trans> (the non-macro version) with the id set
 * to a concatenation of the enum name and the enum value.
 *
 * It assumes that the translations for the enum have already been manually
 * written out, either in a PO file added to our lang catalog at build time,
 * or via calls to the "t" macro.
 *
 * Currently all of the enum translations are stored in `Model.ts` using the
 * "t" macro, next to the declaration of their related enum.
 *
 * @example
 * // The translation declarations, if done in JS should look like this.
 * // This has no run-time effect; the only thing it does is define a lang
 * // string ID and default translation, which are picked up by the Lingui CLI
 * // "extract" job when it scans the source code.
 *
 * // t('ENUM_NAME.VALUE_FROM_BACKEND')`Translation`;
 * t('AdjustedReadingEntry_STATUS.unconfirmed')`Unconfirmed`;
 * t('AdjustedReadingEntry_STATUS.confirmed')`Confirmed`;
 *
 * // And then to use TransEnum, you do this (with some variable where the
 * // backend enum value is stored, as the "status").
 * <TransEnum enum='AdjustedReadingEntry_STATUS' value={row.status} />
 *
 * // This is exactly equivalent to doing this:
 * <Trans id={`AdjustedReadingEntry_STATUS.{row.status}`} />
 */
export function TransEnum<EnumName extends TranslatedEnumNames>(props: {
  enum: EnumName;
  value: TranslatedEnumVals<EnumName> | undefined | null | '';
}) {
  if (props.value === undefined || props.value === null || props.value === '') {
    return <></>;
  }
  return <Trans id={`${props.enum}.${props.value}`} />;
}
