import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import {
  MaintainDataLoggerView,
  MaintainDataLoggerViewProps,
} from './maintdataloggerview';
import {
  updateDataLogger,
  unmountDataLogger,
  fetchDataLogger,
  DataLoggerDetailState,
} from 'ducks/data-logger/detail';
import {
  parseNumberParamFromRouterProps,
  currentQueryString,
} from 'util/routing';
import {
  EntityTypes,
  fetchEntityList,
  selectAllInOrderedArray,
} from 'ducks/entities';
import { AlertSuccess } from 'components/base/alert/alert';
import { Trans } from '@lingui/macro';
import { RouteComponentProps } from 'react-router';
import { FullState } from 'main/reducers';
import { FormikHelpers } from 'formik';
import {
  DataLoggerFormValues,
  formatDataLoggerForBackend,
} from '../dataloggerformbody';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { selectHasPermission } from 'util/user';
import { Enum } from 'util/backendapi/models/api.interfaces';

type OwnProps = RouteComponentProps<{ dataLoggerNumber: string }>;

type StateProps = Pick<
  MaintainDataLoggerViewProps,
  | 'dataLogger'
  | 'areas'
  | 'error'
  | 'loading'
  | 'hasEditPermission'
  | 'nullReadingObsPoint'
  | 'missedDownloadObsPoint'
> &
  Pick<
    DataLoggerDetailState,
    'dataLogger' | 'dataLoggerNumber' | 'isUpdating' | 'errorsUpdating'
  >;

const mapDispatchToProps = {
  updateDataLogger,
  unmountDataLogger,
  fetchDataLogger,
  fetchEntityList,
};

type DispatchProps = typeof mapDispatchToProps;
type MyProps = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class InnerMaintainDataLoggerScreen extends React.Component<MyProps> {
  _isMounted = false;

  static defaultProps: Partial<MyProps> = {
    dataLoggerNumber: null,
    dataLogger: null,
    areas: [],
    loading: true,
    error: '',
    updateDataLogger: (() => {}) as any,
    fetchDataLogger: (() => {}) as any,
    fetchEntityList: (() => {}) as any,
  };

  static mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
    let dataLoggerNumber;

    if (state.dataLogger.detail.dataLoggerNumber) {
      dataLoggerNumber = state.dataLogger.detail.dataLoggerNumber;
    } else {
      dataLoggerNumber = parseNumberParamFromRouterProps(
        ownProps,
        'dataLoggerNumber'
      );
    }

    return {
      hasEditPermission: selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_data_loggers
      ),
      dataLoggerNumber: dataLoggerNumber,
      loading: state.dataLogger.detail.loading,
      error: state.dataLogger.detail.errorLoading,
      isUpdating: state.dataLogger.detail.isUpdating,
      errorsUpdating: state.dataLogger.detail.errorsUpdating,
      areas: selectAllInOrderedArray(state, EntityTypes.AREA),
      dataLogger: state.dataLogger.detail.dataLogger,
      nullReadingObsPoint: state.dataLogger.detail.nullReadingObsPoint,
      missedDownloadObsPoint: state.dataLogger.detail.missedDownloadObsPoint,
    };
  }

  updateDataLogger = async (
    values: DataLoggerFormValues,
    actions: FormikHelpers<DataLoggerFormValues>
  ) => {
    let urlChange = values.logger_number !== this.props.dataLoggerNumber;

    await this.props.updateDataLogger(
      this.props.dataLogger!.id,
      formatDataLoggerForBackend(values)
    );

    if (!this._isMounted) return false;
    actions.setSubmitting(false);

    if (this.props.errorsUpdating) {
      showErrorsInFormik(
        actions,
        this.props.errorsUpdating,
        getExpectedFields(values)
      );
      return false;
    } else if (!this.props.isUpdating && this.props.dataLogger) {
      if (urlChange) {
        const qs = currentQueryString(this.props.location);
        this.props.history.replace(
          `/data-loggers/${encodeURIComponent(
            String(values.logger_number)
          )}${qs}`
        );
        return true;
      }

      actions.setStatus(
        <AlertSuccess>
          <Trans>Data logger successfully updated.</Trans>
        </AlertSuccess>
      );
    }

    return true;
  };

  render() {
    return (
      <MaintainDataLoggerView
        hasEditPermission={this.props.hasEditPermission}
        loading={this.props.loading}
        error={this.props.error}
        dataLogger={this.props.dataLogger}
        nullReadingObsPoint={this.props.nullReadingObsPoint}
        missedDownloadObsPoint={this.props.missedDownloadObsPoint}
        areas={this.props.areas}
        updateDataLogger={this.updateDataLogger}
      />
    );
  }

  componentDidMount() {
    this._isMounted = true;
    if (
      this.props.dataLoggerNumber &&
      (!this.props.dataLogger ||
        this.props.dataLoggerNumber !== this.props.dataLogger.logger_number)
    ) {
      this.props.fetchDataLogger(this.props.dataLoggerNumber);
    }
    this.props.fetchEntityList(EntityTypes.AREA);
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.unmountDataLogger();
  }
}

export const MaintainDataLoggerScreen = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  InnerMaintainDataLoggerScreen.mapStateToProps,
  mapDispatchToProps
)(InnerMaintainDataLoggerScreen);
