import React, { Component } from 'react';
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { createPath, LocationDescriptorObject } from 'history';
import { selectIsLoggedIn } from '../../../ducks/login';
import { FullState } from '../../../main/reducers';
import { Enum } from '../../../util/backendapi/models/api.interfaces';
import { AlertDanger } from '../../base/alert/alert';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { DMSLink } from 'components/base/link/DMSLink';

export type PrivateRouteProps<
  TComponent extends React.ComponentType<any>,
  P = TComponent extends React.ComponentType<infer I> ? I : any
> = Merge<
  Omit<RouteProps, 'render'>,
  {
    component: TComponent;
    permission: null | Enum.User_PERMISSION;
  }
> &
  Partial<P>;
type StateProps = {
  isLoggedIn: boolean;
  userPermissions: Enum.User_PERMISSION[];
};

type Props<TComponent extends React.ComponentType<any>> =
  PrivateRouteProps<TComponent> & StateProps & RouteComponentProps;

/**
 * A higher-order component for routes that should only be accessible
 * to logged-in users.
 *
 * As in https://reacttraining.com/react-router/web/example/auth-workflow
 */
class InnerPrivateRoute<
  TComponent extends React.ComponentType<any>
> extends Component<Props<TComponent>> {
  /**
   * How to populate (some of) our props with values from the Redux store
   * @param {import('../../../main/reducers').FullState} state
   */
  static mapStateToProps(state: FullState): StateProps {
    return {
      isLoggedIn: selectIsLoggedIn(state),
      userPermissions: state.user.permissions,
    };
  }

  /**
   * Render function
   */
  render() {
    const {
      component: PrivateComponent,
      userPermissions,
      isLoggedIn,
      path,
      permission,
      ...otherProps
    } = this.props;
    if (this.props.isLoggedIn) {
      if (
        !this.props.permission ||
        this.props.userPermissions.includes(this.props.permission!)
      ) {
        // User is logged in, and has the required permission (if any)
        return <PrivateComponent {...(otherProps as any)} />;
      } else {
        // User is logged in, but lacks the required permission.
        // Show "permission denied" message.
        return (
          <PageStandard
            name="permissiondenied"
            header={<Trans>Permission denied.</Trans>}
          >
            <AlertDanger>
              <Trans>You do not have permission to perform this action.</Trans>
            </AlertDanger>
            <DMSLink to="/">
              <Trans>Back to dashboard</Trans>
            </DMSLink>
          </PageStandard>
        );
      }
    } else {
      // User is not logged in. Send to login page.
      const sendTo: LocationDescriptorObject = {
        pathname: '/login',
      };
      // Add the location to return to after a succesful login. (Unless they're
      // just trying to reach "/dashboard", since that's the default returnto location.)
      if (
        otherProps.location &&
        otherProps.location.pathname !== '/dashboard'
      ) {
        sendTo.search = `?returnto=${encodeURIComponent(
          createPath(otherProps.location!)
        )}`;
      }
      return <Redirect push to={sendTo} />;
    }
  }
}

// This is a little tricky. We need to use react-redux's "connect()" function,
// so the private route can see whether the user is logged in and what their
// permissions are. But, this can block nested routing-aware components from
// receiving route updates .
// See: https://reacttraining.com/react-router/web/guides/dealing-with-update-blocking
//
// We could use withRouter(), but it turns out that creates an inefficient
// extra match-all <Route> around our component, which would need a second
// Route to match the desired path.
// See: https://github.com/ReactTraining/react-router/pull/5552#issuecomment-331502281
//
// So instead, we make a (non-exported) connected component inside the module.
// Then we export *another* component that just wraps the connected component
// with a Route that matches the path we want.
const PrivateRouteWithRedux = connect(InnerPrivateRoute.mapStateToProps)(
  InnerPrivateRoute
);

export default function PrivateRoute<T extends React.ComponentType<any>>(
  props: PrivateRouteProps<T>
) {
  // We don't actually need to extract "path" from the other props here, but
  // I find it's a helpful reminder of how this thing actually works!
  const { component, path, ...otherProps } = props;
  return (
    <Route
      path={path}
      {...otherProps}
      render={(routeProps) => (
        <PrivateRouteWithRedux {...props} {...routeProps} />
      )}
    />
  );
}
