import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { errorToString } from 'util/backendapi/error';
import { getPaginated } from 'util/backendapi/fetch';
import { alarmReportColumns } from 'screens/alarmreports/AlarmReportListScreen';
import { getColBackendField } from 'components/modules/report/report-types';
import { isTruthy } from 'util/validation';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import { Trans, Plural } from '@lingui/macro';
import Accordion from 'components/base/accordion/accordion';
import { useSelector, useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { setDashletCollapsed } from 'ducks/ui';
import { useIsMounted } from 'util/hooks';
import { Link } from 'react-router-dom';
import { AlarmReport_STATUS } from 'util/backendapi/types/Enum';

const POLLING_INTERVAL_MS = 70 * 1000;

const columnDefinitions = alarmReportColumns.filter((c) =>
  [
    // Observation point
    'observation-point-code',
    // Alarm report date and time
    'created-datetime',
    // Alarm status
    'status',
    // Comparison type
    'comparison-type',
    // Alarm level
    'level',
    // Min/max
    'type',
  ].includes(c.name)
);
const backendColumns = columnDefinitions.flatMap((c) =>
  [getColBackendField(c), ...(c.additionalFields ?? [])].filter(isTruthy)
);
const uncancelledStatuses = Object.values(Enum.AlarmReport_STATUS).filter(
  (s) => s !== Enum.AlarmReport_STATUS.cancelled
);

export function UncancelledAlarmsDashlet() {
  const isMounted = useIsMounted();
  const isCollapsed = useSelector(
    (state: FullState) => state.ui.isDashletCollapsed.uncancelledAlarms
  );
  const dispatch = useDispatch();
  const handleToggle = useCallback(
    (isExpanded: boolean) =>
      dispatch(setDashletCollapsed('uncancelledAlarms', !isExpanded)),
    [dispatch]
  );

  const [pollingState, setPollingState] = useState<PollingState>({
    isLoading: false,
  });
  const timerRef = useRef<NodeJS.Timer>();

  // A function to fetch the data and then schedule the next fetch.
  const fetchData = useCallback(async () => {
    timerRef.current = undefined;

    setPollingState((currentPollingState) => ({
      ...currentPollingState,
      isLoading: true,
    }));

    try {
      const { data: alarmReports, pagination } = await getPaginated(
        '/reports/alarm-reports/',
        {
          status__in: uncancelledStatuses,
          columns: ['id', ...backendColumns],
          ordering: ['-created_datetime'],
          limit: 50,
          offset: 0,
        }
      );
      if (!isMounted()) {
        return;
      }

      setPollingState(() => ({
        isLoading: false,
        alarmReports,
        totalAlarmReports: pagination!.total,
      }));
      timerRef.current = setTimeout(fetchData, POLLING_INTERVAL_MS);
    } catch (e) {
      if (!isMounted()) {
        return;
      }

      setPollingState({
        isLoading: false,
        error: errorToString(e),
      });
    }
  }, [isMounted]);

  useEffect(() => {
    // Do an immediate poll when the component first loads.
    fetchData();

    // Cancel any remaining scheduled poll, when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [fetchData]);

  return (
    <UncancelledAlarmsDashletView
      onToggle={handleToggle}
      isCollapsed={isCollapsed}
      {...pollingState}
    />
  );
}

interface PollingState {
  isLoading: boolean;
  error?: string;
  alarmReports?: Model.ReportsAlarmReport[];
  totalAlarmReports?: number | null;
}

interface ViewProps extends PollingState {
  isCollapsed: boolean;
  onToggle: (isExpanded: boolean) => void;
}

export function UncancelledAlarmsDashletView(props: ViewProps) {
  const { isCollapsed, error, alarmReports, totalAlarmReports, onToggle } =
    props;

  return (
    <Accordion
      data-testid="uncancelled-alarms-dashlet"
      onToggle={onToggle}
      expand={!isCollapsed}
      wrapperClassName="accordion-secondary"
      item={{
        id: 'uncancelled-alarms-dashlet',
        title: (
          <>
            {error || !alarmReports ? (
              <Trans>Uncancelled alarms</Trans>
            ) : totalAlarmReports === null || totalAlarmReports! > 50 ? (
              <Trans>More than 50 uncancelled alarms</Trans>
            ) : (
              <Plural
                value={Number(totalAlarmReports)}
                one="1 uncancelled alarm"
                other="# uncancelled alarms"
              />
            )}
            <span className="accordion-title-additional-content">
              <Link
                to={`/alarm-reports/?status=${encodeURIComponent(
                  [
                    AlarmReport_STATUS.acknowledged,
                    AlarmReport_STATUS.unacknowledged,
                    AlarmReport_STATUS.data_check,
                  ].join(',')
                )}`}
                className="btn"
              >
                <span>
                  <Trans>View report</Trans>
                </span>
              </Link>
            </span>
          </>
        ),
        content: (
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  {columnDefinitions.map((col) => (
                    <th key={col.name} scope="col">
                      {col.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {error ? (
                  <tr>
                    <td className="td-no-results" colSpan={999}>
                      <AlertDanger>{error}</AlertDanger>
                    </td>
                  </tr>
                ) : !alarmReports ? (
                  <tr>
                    <td className="td-no-results" colSpan={999}>
                      <Loading />
                    </td>
                  </tr>
                ) : alarmReports.length === 0 ? (
                  <tr>
                    <td className="td-no-results" colSpan={999}>
                      <Trans>No uncancelled alarms</Trans>
                    </td>
                  </tr>
                ) : (
                  alarmReports.map((row) => (
                    <tr key={row.id}>
                      {columnDefinitions.map((col, idx) => (
                        <td key={col.name}>
                          {col.accessor?.(row, idx) ??
                            row[
                              getColBackendField(
                                col
                              ) as keyof Model.ReportsAlarmReport
                            ]}
                        </td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        ),
      }}
    />
  );
}
