import React, { useState, useCallback } from 'react';
import ReactModal from 'react-modal';
import ModalContent from '../modal/modalcontent';
import { Trans } from '@lingui/macro';
import ActionBlock from '../actionblock/actionblock';
import Button, { ButtonDanger } from '../button/button';

interface Props {
  onResolve: ((result: boolean) => void) | null;
  content: () => React.ReactNode;
  cancelBtnText?: React.ReactNode;
  okBtnText?: React.ReactNode;
}

/**
 * A hook for showing a confirmation modal when submitting a Formik form.
 * To be used in conjunction with `<FormConfirmation>`.
 *
 * @returns an array with two items:
 *
 * 1. `askForConfirmation()`: an async function that will resolve with a boolean
 *     when the user either confirms or cancels the confirmation.
 * 2. `onResolveConfirmation`: a callback function, or a `null`, to be passed to `<FormConfirmation>`.
 *
 * @example
 * ```tsx
 * const [askForConfirmation, onResolveConfirmation] = useFormConfirmation();
 * return <Formik
 *   onSubmit={async (values, formik) => {
 *
 *     // 1. call and await `askForConfirmation()` in the submission function
 *     const confirmResult = await askForConfirmation();
 *
 *     if (!confirmResult) {
 *       formik.setSubmitting(false);
 *       return;
 *     } else {
 *       doSomeStuff(values); // etc
 *     }
 * }}>
 *   {() => <Form>
 *     <FormConfirmation
 * 
 *       // 2. pass `onResolveConfirmation` to `<FormConfirmation>`
 *       onResolve={onResolveConfirmation}

 *       content={() => <Trans>Are you sure you want to do this?</Trans>}
 *     />
 *   </Form>}
 * </Formik>
 * 
 */
export function useFormConfirmation(): [
  () => Promise<boolean>,
  ((result: boolean) => void) | null
] {
  const [onResolveConfirmation, setResolveFn] = useState<
    ((result: boolean) => void) | null
  >(null);

  const askForConfirmation = useCallback(async () => {
    const result = await new Promise<boolean>(function (resolve) {
      setResolveFn(() => resolve);
    });

    setResolveFn(null);
    return result;
  }, []);
  return [askForConfirmation, onResolveConfirmation];
}

/**
 * A component to show a confirmation modal when submitting a Formik form.
 * To be used in conjunction with the `useFormConfirmation()` hook.
 *
 * @see useFormConfirmation()
 */
export function FormConfirmation(props: Props) {
  const { onResolve, content, okBtnText, cancelBtnText } = props;
  if (!onResolve) {
    return null;
  }

  return (
    <ReactModal
      isOpen={true}
      onRequestClose={() => onResolve(false)}
      className="modal modal-danger"
      overlayClassName="modal-centred"
    >
      <ModalContent
        header={<Trans>Confirmation</Trans>}
        onClose={() => onResolve(false)}
      >
        <div>{content()}</div>
        <ActionBlock className="text-right">
          <Button onClick={() => onResolve(false)}>
            {cancelBtnText || <Trans>Cancel</Trans>}
          </Button>
          <ButtonDanger onClick={() => onResolve(true)}>
            {okBtnText || <Trans>Yes</Trans>}
          </ButtonDanger>
        </ActionBlock>
      </ModalContent>
    </ReactModal>
  );
}
