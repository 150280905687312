import React from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import {
  ReportFilter,
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { Trans, Plural, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { Icon } from 'components/base/icon/icon';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { EditPlotSetModal } from './EditPlotSetModal';
import { ReportStateProps } from 'hooks/use-report-state';
import { DMSLink } from 'components/base/link/DMSLink';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';

export const PlotSetListFilters = (): ReportFilter[] => [
  reportFilterMenu(
    'id',
    <Trans>Plot set</Trans>,
    { isMulti: true, valueType: 'number' },
    (option: { id: number; name: string }): SimpleSelectOption<number> => {
      return {
        value: option.id,
        label: option.name,
      };
    },
    { placeholder: <Trans>All plot sets</Trans> }
  ),
  reportFilterMenu(
    'areas',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All areas</Trans> }
  ),
  reportFilterMenu(
    'pre_cached',
    <Trans>Cached</Trans>,
    { isMulti: false, valueType: 'string' },
    ReportFilterMenu.PASS_MENU_THROUGH,
    {
      options: [
        {
          label: <Trans>All statuses</Trans>,
          value: '',
        },
        {
          label: <Trans>Yes</Trans>,
          value: 'true',
        },
        {
          label: <Trans>No</Trans>,
          value: 'false',
        },
      ],
    }
  ),
];

export const PlotSetListColumns: (props: {
  deletePlotSet: (id: number) => Promise<void>;
  refreshList: () => void;
}) => ReportColumn<Model.ReportsPlotSet>[] = ({
  deletePlotSet,
  refreshList,
}) => [
  {
    label: <Trans>Name</Trans>,
    name: 'name',
    visibility: DEFAULT_SHOW,
    accessor: ({ name }) => (
      <DMSLink to={`/plot-sets/${name}/plot`}>{name}</DMSLink>
    ),
  },
  {
    label: <Trans>Description</Trans>,
    name: 'description',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Area</Trans>,
    name: 'area',
    backendFieldName: 'areas__code',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Number of stored plots</Trans>,
    name: 'stored_plots_count',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Cached</Trans>,
    name: 'pre_cached',
    visibility: DEFAULT_SHOW,
    accessor: ({ pre_cached }) =>
      pre_cached ? <Trans>Yes</Trans> : <Trans>No</Trans>,
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['name', 'id'],
    accessor: ({ name, id }) => (
      <>
        <DMSLink to={`/plot-sets/${name}/plot`}>
          <Icon type="icon-view" title={t`View`} />
        </DMSLink>
        <HasPermission check="can_create_plot_sets">
          <DMSLink to={`/plot-sets/${name}`}>
            <Icon type="icon-cog" title={t`Configure`} />
          </DMSLink>
          <ButtonShowModal
            className="btn-link-panel"
            name="edit-plot-set"
            iconType="icon-edit"
            iconOnly={true}
            title={t`Edit`}
            modalContent={({ hideModal }) => (
              <EditPlotSetModal
                plotSetId={id}
                onAfterSubmit={() => {
                  hideModal();
                  refreshList();
                }}
              />
            )}
          >
            <Trans>Edit</Trans>
          </ButtonShowModal>
        </HasPermission>
        <HasPermission check="can_delete_plot_sets">
          <ButtonShowConfirmation
            name="delete-plotset"
            title={t`Delete`}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            content={
              <Trans>
                Are you sure you want to delete the <strong>{name}</strong> plot
                set? The associated stored plots will not be deleted. This
                action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async function () {
              await deletePlotSet(id);
              refreshList();
            }}
          />
        </HasPermission>
      </>
    ),
  },
];

export interface PlotSetListViewProps {
  reportProps: ReportStateProps<Model.ReportsPlotSet>;
  deletePlotSet: (id: number) => Promise<any>;
}

export const PlotSetListView = (props: PlotSetListViewProps) => {
  const { reportProps } = props;

  const columns = PlotSetListColumns({
    refreshList: reportProps.refreshList,
    deletePlotSet: props.deletePlotSet,
  });
  const filters = PlotSetListFilters();

  return (
    <PageStandard
      name="plot-set-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Plot set report</Trans>
        ) : (
          <Trans>Maintain Plot Set</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_plot_sets">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="create-plot-set"
              iconType="icon-plus"
              shortcut="CREATE_NEW"
              modalContent={({ hideModal }) => (
                <EditPlotSetModal onAfterSubmit={hideModal} />
              )}
            >
              <Trans>Create plot set</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>

        <ReportFiltersBlock
          filtersFrontend={filters}
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          isExportMode={reportProps.isExportMode}
        />
      </div>

      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Plot Set report`}
            reportUrl="/reports/plot-sets/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>

        <ReportTable
          columns={columns}
          errorMessage={reportProps.errorMessage}
          filters={filters}
          isLoading={reportProps.isLoading}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 plot set"
              other="Filtered to # plot sets"
            />
          )}
          msgNoMatches={<Trans>No plot sets match the selected filters.</Trans>}
          pagination={reportProps.pagination}
          records={reportProps.records}
          reportInfo={reportProps.reportInfo}
        />
      </div>
    </PageStandard>
  );
};
