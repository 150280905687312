import {
  getPaginated,
  postApi,
  patchApi,
  getApi,
  deleteApi,
} from '../util/backendapi/fetch';
import {
  ActionCreators as CommentsListActionCreators,
  ActionTypes as CommentsListActionTypes,
} from './comments/panel/list';
import { ThunkDispatch } from 'redux-thunk';
import { PaginationResponseData } from '../util/backendapi/pagination';
import { Enum, Model, Filter } from '../util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions, SyncThunk } from '../main/store';
import { errorToString } from 'util/backendapi/error';
import { ChecksheetInstance_STATUS } from 'util/backendapi/types/Enum';
import { selectHasPermission } from 'util/user';

export const ActionTypes = {
  FETCH_BATCH_DATA_START: 'dms/checkreadings/FETCH_BATCH_DATA_START',
  FETCH_BATCH_DATA_RESPONSE: 'dms/checkreadings/FETCH_BATCH_DATA_RESPONSE',
  FETCH_BATCH_DATA_ERROR: 'dms/checkreadings/FETCH_BATCH_DATA_ERROR',
  FETCH_READINGS_START: 'dms/checkreadings/FETCH_READINGS_START',
  FETCH_READINGS_RESPONSE: 'dms/checkreadings/FETCH_READINGS_RESPONSE',
  FETCH_READINGS_ERROR: 'dms/checkreadings/FETCH_READINGS_ERROR',
  FETCH_ALARMS_COUNT_START: 'dms/checkreadings/FETCH_ALARMS_COUNT_START',
  FETCH_ALARMS_COUNT_RESPONSE: 'dms/checkreadings/FETCH_ALARMS_COUNT_RESPONSE',
  FETCH_ALARMS_COUNT_ERROR: 'dms/checkreadings/FETCH_ALARMS_COUNT_ERROR',
  GENERATE_ALARM_REPORTS_START:
    'dms/checkreadings/GENERATE_ALARM_REPORTS_START',
  GENERATE_ALARM_REPORTS_RESPONSE:
    'dms/checkreadings/GENERATE_ALARM_REPORTS_RESPONSE',
  GENERATE_ALARM_REPORTS_ERROR:
    'dms/checkreadings/GENERATE_ALARM_REPORTS_ERROR',
  RECALCULATE_ADJUSTED_READINGS_START:
    'dms/checkreadings/RECALCULATE_ADJUSTED_READINGS_START',
  RECALCULATE_ADJUSTED_READINGS_TASK_SCHEDULED:
    'dms/checkreadings/RECALCULATE_ADJUSTED_READINGS_TASK_SCHEDULED',
  RECALCULATE_ADJUSTED_READINGS_RESPONSE:
    'dms/checkreadings/RECALCULATE_ADJUSTED_READINGS_RESPONSE',
  RECALCULATE_ADJUSTED_READINGS_ERROR:
    'dms/checkreadings/RECALCULATE_ADJUSTED_READINGS_ERROR',
  CLOSE_OUT_BATCH_START: 'dms/checkreadings/CLOSE_OUT_BATCH_START',
  CLOSE_OUT_BATCH_RESPONSE: 'dms/checkreadings/CLOSE_OUT_BATCH_RESPONSE',
  CLOSE_OUT_BATCH_ERROR: 'dms/checkreadings/CLOSE_OUT_BATCH_ERROR',
  UNMOUNT: 'dms/checkreadings/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_BATCH_DATA_START: function () {
    return {
      type: ActionTypes.FETCH_BATCH_DATA_START,
    };
  },
  FETCH_BATCH_DATA_RESPONSE: function (
    readingsBatch: null | Model.ListReadingsBatch,
    readingsFile: null | Model.ReadingsFileDecorated,
    checksheetInstanceId: null | number
  ) {
    return {
      type: ActionTypes.FETCH_BATCH_DATA_RESPONSE,
      readingsBatch,
      readingsFile,
      checksheetInstanceId,
    };
  },
  FETCH_BATCH_DATA_ERROR: function (errorMessage: string) {
    return {
      type: ActionTypes.FETCH_BATCH_DATA_ERROR,
      payload: errorMessage,
    };
  },

  FETCH_READINGS_START: function () {
    return {
      type: ActionTypes.FETCH_READINGS_START,
    };
  },
  FETCH_READINGS_RESPONSE: function (
    data: Model.Reading[],
    pagination: null | PaginationResponseData
  ) {
    return {
      data,
      pagination,
      type: ActionTypes.FETCH_READINGS_RESPONSE,
    };
  },
  FETCH_READINGS_ERROR: function (errorMessage: string) {
    return {
      type: ActionTypes.FETCH_READINGS_ERROR,
      payload: errorMessage,
      error: true,
    };
  },

  FETCH_ALARMS_COUNT_START: () => ({
    type: ActionTypes.FETCH_ALARMS_COUNT_START,
  }),
  FETCH_ALARMS_COUNT_RESPONSE: (alarms: AlarmCountByReadingId) => ({
    type: ActionTypes.FETCH_ALARMS_COUNT_RESPONSE,
    payload: alarms,
  }),
  FETCH_ALARMS_COUNT_ERROR: (errorMessage: string) => ({
    type: ActionTypes.FETCH_ALARMS_COUNT_ERROR,
    payload: errorMessage,
    error: true,
  }),

  GENERATE_ALARM_REPORTS_START: function () {
    return { type: ActionTypes.GENERATE_ALARM_REPORTS_START };
  },
  GENERATE_ALARM_REPORTS_RESPONSE: function () {
    return { type: ActionTypes.GENERATE_ALARM_REPORTS_RESPONSE };
  },
  GENERATE_ALARM_REPORTS_ERROR: function (message: string) {
    return {
      type: ActionTypes.GENERATE_ALARM_REPORTS_ERROR,
      error: true,
      payload: message,
    };
  },

  CLOSE_OUT_BATCH_START: () => ({
    type: ActionTypes.CLOSE_OUT_BATCH_START,
  }),
  CLOSE_OUT_BATCH_RESPONSE: () => ({
    type: ActionTypes.CLOSE_OUT_BATCH_RESPONSE,
  }),
  CLOSE_OUT_BATCH_ERROR: (errorMessage: string) => ({
    type: ActionTypes.CLOSE_OUT_BATCH_ERROR,
    error: true,
    payload: errorMessage,
  }),

  RECALCULATE_ADJUSTED_READINGS_START() {
    return {
      type: ActionTypes.RECALCULATE_ADJUSTED_READINGS_START,
    };
  },
  RECALCULATE_ADJUSTED_READINGS_TASK_SCHEDULED(task: Model.Task) {
    return {
      type: ActionTypes.RECALCULATE_ADJUSTED_READINGS_TASK_SCHEDULED,
      payload: task.id,
    };
  },
  RECALCULATE_ADJUSTED_READINGS_RESPONSE() {
    return {
      type: ActionTypes.RECALCULATE_ADJUSTED_READINGS_RESPONSE,
    };
  },
  RECALCULATE_ADJUSTED_READINGS_ERROR(message: string) {
    return {
      type: ActionTypes.RECALCULATE_ADJUSTED_READINGS_ERROR,
      error: true,
      payload: message,
    };
  },
  UNMOUNT() {
    return {
      type: ActionTypes.UNMOUNT,
    };
  },
} as const;

export type CheckReadingsAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export type AlarmCountByReadingId = { [key: string]: { count: number } };

export interface State {
  errorMessage: string;
  pagination: null | PaginationResponseData;
  isLoading: boolean;
  isRecalculatingReadings: boolean;
  readings: Model.Reading[];
  isLoadingBatchData: boolean;
  readingsFile: null | Model.ReadingsFileDecorated;
  readingsBatch: null | Model.ListReadingsBatch;
  checksheetInstanceId: null | number;
  alarmsCountByReadingId: AlarmCountByReadingId | null;
  taskId: string | null;
}

export const checkReadingsInitialState = () => ({
  isLoading: false,
  isRecalculatingReadings: false,
  errorMessage: '',
  pagination: null,
  readings: [],
  readingsFile: null,
  readingsBatch: null,
  checksheetInstanceId: null,
  isLoadingBatchData: false,
  alarmsCountByReadingId: null,
  taskId: null,
});

export function checkReadingsReducer(
  state: State = checkReadingsInitialState(),
  action:
    | CheckReadingsAction
    | ReturnType<typeof CommentsListActionCreators['addCommentResponse']>
): State {
  switch (action.type) {
    case ActionTypes.FETCH_BATCH_DATA_START:
      return {
        ...state,
        isLoadingBatchData: true,
        readingsBatch: null,
        readingsFile: null,
        checksheetInstanceId: null,
        taskId: null,
        readings: [],
      };
    case ActionTypes.FETCH_BATCH_DATA_RESPONSE:
      return {
        ...state,
        isLoadingBatchData: false,
        readingsBatch: action.readingsBatch,
        readingsFile: action.readingsFile,
        checksheetInstanceId: action.checksheetInstanceId,
      };
    case ActionTypes.FETCH_BATCH_DATA_ERROR:
      return {
        ...state,
        isLoadingBatchData: false,
        errorMessage: action.payload,
      };

    case ActionTypes.FETCH_READINGS_START:
      return {
        ...state,
        isLoading: true,
        readings: [],
      };
    case ActionTypes.FETCH_READINGS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        pagination: action.pagination,
        readings: action.data,
      };
    // increase comment count when an analysis comment is added with type = ReadingComment
    case CommentsListActionTypes.ADD_COMMENT_RESPONSE:
      if (
        action.payload.resourcetype === Enum.Comment_RESOURCE_TYPE.reading &&
        action.payload.comment_type === Enum.Comment_TYPE.analysis
      ) {
        const idxForUpdateCommentCount = state.readings.findIndex(
          (reading) =>
            reading.id === (action.payload as Model.ReadingComment).reading
        );

        if (idxForUpdateCommentCount > -1) {
          return {
            ...state,
            readings: state.readings.map((reading, idx) =>
              idx === idxForUpdateCommentCount
                ? {
                    ...reading,
                    analysis_comments_count:
                      reading.analysis_comments_count + 1,
                  }
                : reading
            ),
          };
        } else {
          return state;
        }
      } else {
        return state;
      }
    case ActionTypes.FETCH_READINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };

    case ActionTypes.FETCH_ALARMS_COUNT_START:
      return {
        ...state,
        alarmsCountByReadingId: null,
      };
    case ActionTypes.FETCH_ALARMS_COUNT_RESPONSE:
      return {
        ...state,
        alarmsCountByReadingId: action.payload,
      };
    case ActionTypes.FETCH_ALARMS_COUNT_ERROR:
      return {
        ...state,
        alarmsCountByReadingId: null,
        errorMessage: action.payload,
      };

    case ActionTypes.RECALCULATE_ADJUSTED_READINGS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.RECALCULATE_ADJUSTED_READINGS_TASK_SCHEDULED:
      return {
        ...state,
        taskId: action.payload,
        isRecalculatingReadings: true,
      };
    case ActionTypes.RECALCULATE_ADJUSTED_READINGS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        isRecalculatingReadings: false,
        taskId: null,
      };
    case ActionTypes.RECALCULATE_ADJUSTED_READINGS_ERROR:
      return {
        ...state,
        isLoading: false,
        isRecalculatingReadings: false,
        taskId: null,
        errorMessage: action.payload,
      };

    case ActionTypes.CLOSE_OUT_BATCH_START:
      return {
        ...state,
        isLoading: true,
        errorMessage: '',
      };
    case ActionTypes.CLOSE_OUT_BATCH_RESPONSE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CLOSE_OUT_BATCH_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case ActionTypes.UNMOUNT:
      return checkReadingsInitialState();
    default:
      return state;
  }
}

let nonceFetchBatchReadings = 0;
export function fetchBatchReadings(params: Filter.Readings) {
  return async function (dispatch: ThunkDispatch<any, any, any>) {
    nonceFetchBatchReadings++;
    const thisNonce = nonceFetchBatchReadings;
    dispatch(ActionCreators.FETCH_READINGS_START());

    try {
      const readingsResponse = await getPaginated('/readings/', params);
      if (thisNonce !== nonceFetchBatchReadings) {
        return;
      }
      const rawReadings = readingsResponse.data;
      const pagination = readingsResponse.pagination;
      dispatch(ActionCreators.FETCH_READINGS_RESPONSE(rawReadings, pagination));
    } catch (e) {
      return dispatch(ActionCreators.FETCH_READINGS_ERROR(errorToString(e)));
    }
  };
}

let nonceFetchBatchData = 0;
export function fetchBatchData(batchNumber: number): StandardThunk {
  return async function (dispatch, getState) {
    nonceFetchBatchData++;
    const thisNonce = nonceFetchBatchData;
    dispatch(ActionCreators.FETCH_BATCH_DATA_START());

    try {
      const [readingsBatch] = await getApi('/readings-batches/', {
        batch_number__in: [batchNumber],
      });
      if (thisNonce !== nonceFetchBatchData) {
        return;
      }

      let readingsFile: null | Model.ReadingsFileDecorated = null;
      let checksheetInstanceId = null;
      if (readingsBatch) {
        if (readingsBatch.file_id) {
          readingsFile = await getApi(
            `/readings-files/${readingsBatch.file_id}/`
          );
          if (thisNonce !== nonceFetchBatchData) {
            return;
          }
        }

        if (
          selectHasPermission(
            getState(),
            Enum.User_PERMISSION.can_view_checksheet_instance_report
          )
        ) {
          checksheetInstanceId =
            (
              await getApi('/reports/checksheet-instances/', {
                columns: ['id'],
                data_sources__batches__id__in: [readingsBatch.id],
                status__in: [ChecksheetInstance_STATUS.in_progress],
                ordering: ['-created_datetime'],
                limit: 1,
              })
            )[0]?.id ?? null;
        }

        if (thisNonce !== nonceFetchBatchData) {
          return;
        }

        [readingsFile] = await Promise.all([
          readingsBatch.file_id
            ? getApi(`/readings-files/${readingsBatch.file_id}/`)
            : null,
        ]);
        if (thisNonce !== nonceFetchBatchData) {
          return;
        }
      }

      dispatch(
        ActionCreators.FETCH_BATCH_DATA_RESPONSE(
          readingsBatch,
          readingsFile,
          checksheetInstanceId
        )
      );
    } catch (e) {
      return dispatch(ActionCreators.FETCH_BATCH_DATA_ERROR(errorToString(e)));
    }
  };
}

let noncePreviewBatchAlarms = 0;
/**
 * Dynamically check whether the readings in a batch violate any of their alarm
 * parameters. This gives you an idea of whether or not they would generate
 * alarm reports once the batch is confirmed.
 *
 * TODO: This is a long-running query, so it would be good to add some
 * logic here to cancel/ignore a previously-requested "check alarms" request
 * if it's still running when another "check alarms" is requested.
 *
 * @param batchId
 */
export function previewBatchAlarms(
  batchId: number,
  readingIds: number[]
): StandardThunk {
  return async function (dispatch) {
    noncePreviewBatchAlarms++;
    const thisNonce = noncePreviewBatchAlarms;

    try {
      dispatch(ActionCreators.FETCH_ALARMS_COUNT_START());
      const adjustedEntryAlarms = await getApi(
        `/readings-batches/${batchId}/check-alarms/`,
        { reading_ids: readingIds }
      );
      if (thisNonce !== noncePreviewBatchAlarms) {
        return;
      }

      // The endpoint gives us an array with one item for each adjusted reading
      // entry. We need to group that into one item for each reading.
      const alarmsCountByReadingId: AlarmCountByReadingId = {};

      adjustedEntryAlarms.forEach((entryAlarms) => {
        const readingId = entryAlarms.reading_id;
        const alarmCount = entryAlarms.alarms.filter((a) => a.triggered).length;
        if (!alarmsCountByReadingId[readingId]) {
          alarmsCountByReadingId[readingId] = {
            count: alarmCount,
          };
        } else {
          alarmsCountByReadingId[readingId].count += alarmCount;
        }
      });

      dispatch(
        ActionCreators.FETCH_ALARMS_COUNT_RESPONSE(alarmsCountByReadingId)
      );
    } catch (e) {
      return dispatch(ActionCreators.FETCH_READINGS_ERROR(errorToString(e)));
    }
  };
}

let nonceRecalculateAdjustedReadings = 0;
export function recalculateAdjustedReadings(batchId: number) {
  return async function (dispatch: ThunkDispatch<any, any, any>) {
    nonceRecalculateAdjustedReadings++;
    const thisNonce = nonceRecalculateAdjustedReadings;
    dispatch(ActionCreators.RECALCULATE_ADJUSTED_READINGS_START());

    try {
      const task = await postApi(
        `/readings-batches/${batchId}/calculate-adjusted-readings/`
      );
      if (thisNonce !== nonceRecalculateAdjustedReadings) {
        return;
      }
      dispatch(
        ActionCreators.RECALCULATE_ADJUSTED_READINGS_TASK_SCHEDULED(task)
      );
    } catch (e) {
      dispatch(
        ActionCreators.RECALCULATE_ADJUSTED_READINGS_ERROR(errorToString(e))
      );
    }
  };
}
export function recalculateAdjustedReadingsResponse(response: string) {
  return function (dispatch: ThunkDispatch<any, any, any>) {
    let error = '';

    try {
      const data = JSON.parse(response);
      const success = data.success || false;
      if (!success) {
        error = 'Recalculate adjusted readings task failed.';
      }
    } catch (e) {
      error = errorToString(e);
    }

    if (!error) {
      dispatch(ActionCreators.RECALCULATE_ADJUSTED_READINGS_RESPONSE());
    } else {
      dispatch(ActionCreators.RECALCULATE_ADJUSTED_READINGS_ERROR(error));
    }
  };
}

function updateReadingInspectorComment(
  comment: Partial<Model.ReadingInspectorComment> | null
): Promise<any> {
  if (comment === null) {
    return Promise.resolve(null);
  }
  if (comment.id) {
    if (!comment.content) {
      return deleteApi(`/comments/${comment.id}/`);
    } else {
      return patchApi(`/comments/${comment.id}/`, {
        ...comment,
        resourcetype: Enum.Comment_RESOURCE_TYPE.readingInspector,
      });
    }
  } else {
    return postApi('/comments/', {
      ...comment,
      resourcetype: Enum.Comment_RESOURCE_TYPE.readingInspector,
    } as Model.ReadingInspectorComment_POST);
  }
}

let nonceUpdateReading = 0;
export function updateReading(
  readingId: number,
  reading: Model.SimpleReading_POST,
  comment: Partial<Model.ReadingInspectorComment> | null
): StandardThunk {
  return async function () {
    nonceUpdateReading++;
    const thisNonce = nonceUpdateReading;
    await Promise.all([
      patchApi(`/readings/${readingId}/`, reading),
      updateReadingInspectorComment(comment),
    ]);
    if (thisNonce !== nonceUpdateReading) {
      return;
    }
    await postApi('/readings/calculate-adjusted-readings/', {
      ids: [readingId],
    });
    if (thisNonce !== nonceUpdateReading) {
      return;
    }
  };
}

/**
 * "Close out" a batch, and generate applicable alarm reports.
 */

export function closeOutBatch(batchId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.CLOSE_OUT_BATCH_START());

    try {
      await postApi(`/readings-batches/${batchId}/confirm/`);
      dispatch(ActionCreators.CLOSE_OUT_BATCH_RESPONSE());
    } catch (e) {
      dispatch(ActionCreators.CLOSE_OUT_BATCH_ERROR(errorToString(e)));
      throw e;
    }
  };
}

export function unmountCheckReadingsScreen(): SyncThunk {
  return function (dispatch) {
    // Cancel any in-progress thunks
    nonceFetchBatchData++;
    nonceFetchBatchReadings++;
    noncePreviewBatchAlarms++;
    nonceRecalculateAdjustedReadings++;
    nonceUpdateReading++;
    dispatch(ActionCreators.UNMOUNT());
  };
}
