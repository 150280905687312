import React from 'react';
import {
  ReportColumn,
  ReportFilter,
  DEFAULT_SHOW,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { formatDatetimeForDisplay } from 'util/dates';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { RouteComponentProps } from 'react-router-dom';
import { inspectionBatchDuck } from 'ducks/inspection-batch/list';
import { ReportStateProps, useReportState } from 'hooks/use-report-state';
import { reportFilterDatetime } from 'components/modules/report/filter/fields/FilterDate';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { EMPTY_FUNC } from 'util/misc';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';
import { DMSLink } from 'components/base/link/DMSLink';
import { Icon } from 'components/base/icon/icon';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { removeBatch } from 'ducks/readings-batch/list';

export const inspectionBatchFilters: ReportFilter[] = [
  reportFilterDatetime('created', <Trans>Created date</Trans>, null),
  reportFilterMenu(
    'route_march__code',
    <Trans>Route march</Trans>,
    {
      isMulti: true,
      valueType: 'string',
    },
    (rm: { id: number; code: string }) => ({
      value: rm.code,
      label: rm.code,
    }),
    {
      placeholder: <Trans>All route marches</Trans>,
    }
  ),
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    {
      isMulti: true,
      valueType: 'number',
    },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
    }
  ),
  reportFilterUsers('created_by', <Trans>Uploaded by</Trans>),
];

export function inspectionBatchColumns({
  refreshList,
}: {
  refreshList: () => void;
}): ReportColumn<Model.ReportsInspectionBatch>[] {
  return [
    {
      label: <Trans>Route march</Trans>,
      name: 'route-march-code',
      backendFieldName: 'route_march__code',
      visibility: DEFAULT_SHOW,
      accessor: ({ route_march__code }) => route_march__code,
    },
    {
      label: <Trans>Area</Trans>,
      name: 'area',
      backendFieldName:
        'observation_points__observation_point__site__area__code',
      visibility: DEFAULT_SHOW,
      accessor: ({
        observation_points__observation_point__site__area__code: areas,
      }) => areas && <Trans>{areas.join(', ')}</Trans>,
    },
    {
      label: <Trans>Created date</Trans>,
      name: 'created-date',
      backendFieldName: 'created',
      visibility: DEFAULT_SHOW,
      accessor: ({ created }) => formatDatetimeForDisplay(created),
    },
    {
      label: <Trans>Uploaded by</Trans>,
      name: 'uploaded-by',
      backendFieldName: 'created_by',
      visibility: DEFAULT_SHOW,
      accessor: ({ created_by }) => created_by,
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['id', 'route_march__code', 'created'],
      accessor: ({ id, route_march__code, created }) => (
        <>
          <DMSLink to={`/inspection-report/?readings_batch=${id}`}>
            <Icon type="icon-view" title={t`View inspection report`} />
          </DMSLink>
          <HasPermission check="can_delete_inspection_report_batch">
            <ButtonShowConfirmation
              name="delete-inspection-report-batch"
              title={t`Delete inspection report`}
              iconType="icon-circle-minus"
              className="btn-link-panel"
              onConfirm={async () => {
                await removeBatch(id);
                refreshList();
              }}
              content={
                <Trans>
                  Are you sure you want to delete the{' '}
                  <strong>{route_march__code}</strong> inspection report for{' '}
                  {formatDatetimeForDisplay(created)}. It will be permanently
                  removed from the system. This action is not reversible.
                </Trans>
              }
            />
          </HasPermission>
        </>
      ),
    },
  ];
}

export function InspectionBatchListScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    inspectionBatchColumns({ refreshList: EMPTY_FUNC }),
    inspectionBatchFilters,
    inspectionBatchDuck,
    (state) => state.inspectionBatch.list
  );
  return <InspectionBatchListView reportProps={reportState} />;
}

interface InspectionBatchListViewProps {
  reportProps: ReportStateProps<Model.ReportsInspectionBatch>;
}
export const InspectionBatchListView: React.FunctionComponent<InspectionBatchListViewProps> =
  function (props) {
    const { reportProps } = props;
    const columns = inspectionBatchColumns({
      refreshList: reportProps.refreshList,
    });
    const filters = inspectionBatchFilters;
    return (
      <PageStandard
        name="inspection-batches-report"
        header={<Trans>Inspection reports</Trans>}
      >
        <div className="page-content-header-filters-actions">
          <ReportFiltersBlock
            filtersToShow={['route_march__code', 'area', 'created_by']}
            filtersFrontend={filters}
            filtersBackend={
              reportProps.reportInfo && reportProps.reportInfo.filters
            }
            isExportMode={reportProps.isExportMode}
          />
        </div>
        <div className="filtered-table-wrapper">
          <ActionBlock className="text-right">
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
            />
            <ButtonPrint />
            <ExportReportButton
              fileNameBase={t`Inspection reports`}
              reportUrl="/reports/inspection-batches/"
              columns={columns}
              filters={filters}
            />
            <AdvancedFiltersModalButton
              filtersBackend={reportProps.reportInfo?.filters}
              filtersFrontend={filters}
              sections={[
                {
                  name: 'data_source_section',
                  filters: ['route_march__code', 'area', 'created_by'],
                },
                {
                  name: 'date_section',
                  filters: ['created'],
                },
              ]}
            />
          </ActionBlock>
          <ReportTable<Model.ReportsInspectionBatch>
            {...reportProps}
            columns={columns}
            filters={filters}
          />
        </div>
      </PageStandard>
    );
  };
