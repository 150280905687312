import React from 'react';
import { Trans } from '@lingui/macro';
import range from 'lodash/range';
import {
  StoredSpatialCrossSectionPlotWithArea,
  StoredSpatialPlanPlotWithArea,
} from 'ducks/stored-plot/detail';

interface ScaleRuleProps {
  storedPlot:
    | StoredSpatialCrossSectionPlotWithArea
    | StoredSpatialPlanPlotWithArea;
  innerWidth?: number;
  innerHeight?: number;
}

// Height of the rectangle around the movement scale in paperspace mm
const SCALE_RULE_LEGEND_HEIGHT_MM = 3.625;

// Width between the first and last labelled tick, in paperspace mm
const SCALE_RULE_LEGEND_EXTENT_WIDTH_MM = 50;

// Padding before the first / after the last tick in paperspace mm
const SCALE_RULE_LEGEND_PADDING_MM = 5;

// Total width of the scale rule including padding in paperspace mm
const SCALE_RULE_LEGEND_WIDTH_MM =
  SCALE_RULE_LEGEND_EXTENT_WIDTH_MM + SCALE_RULE_LEGEND_PADDING_MM * 2;

// Height of the legend scale ticks in paperspace mm
const SCALE_RULE_LEGEND_TICK_HEIGHT_MM = SCALE_RULE_LEGEND_HEIGHT_MM / 3;

// Number of labelled ticks
const SCALE_RULE_LABELLED_TICK_COUNT = 6;

// Width between the labelled ticks in paperspace mm
const SCALE_RULE_LABELLED_TICK_INTERVAL_MM =
  SCALE_RULE_LEGEND_EXTENT_WIDTH_MM / (SCALE_RULE_LABELLED_TICK_COUNT - 1);

// Width between the extent ticks in paperspace mm
const SCALE_RULE_LEGEND_TICK_INTERVAL_MM =
  SCALE_RULE_LABELLED_TICK_INTERVAL_MM * 0.5;

export function ScaleRule(props: ScaleRuleProps) {
  const { storedPlot, innerWidth, innerHeight } = props;

  if (!storedPlot.scale_rule_x || !storedPlot.scale_rule_y) return null;

  const minX = +storedPlot.scale_rule_x;
  const minY = +storedPlot.paperspace_height - +storedPlot.scale_rule_y;

  // Width the area between the first and last tick represents in m
  const extentWidthM =
    (storedPlot.scale * SCALE_RULE_LEGEND_EXTENT_WIDTH_MM) / 1000;

  const labelledTickGapWidthM =
    extentWidthM / (SCALE_RULE_LABELLED_TICK_COUNT - 1);

  // How many ticks can we fit into the wander scale legend box

  const tickLabelFontSize =
    0.8 * (SCALE_RULE_LEGEND_HEIGHT_MM - SCALE_RULE_LEGEND_TICK_HEIGHT_MM);

  const numTicks = SCALE_RULE_LABELLED_TICK_COUNT * 2 - 1;

  // The "tick" lines, drawn as a single SVG <path>.
  // M<x> <y> = move to absolute coordinates
  // m<dx> <dy> = move to relative position
  // v<dy>     = draw a vertical line from current position
  // See https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#Path_commands

  // Four ticks spaced 1 mm apart
  const initialTicksPath =
    `M${minX + 1} ${minY} ` +
    (
      `v${SCALE_RULE_LEGEND_TICK_HEIGHT_MM} ` +
      `m${1} ${-1 * SCALE_RULE_LEGEND_TICK_HEIGHT_MM} `
    ).repeat(4);

  // Then draw the regular ticks, spaced SCALE_RULE_LEGEND_TICK_INTERVAL_MM apart
  const extentTicksPath =
    `M${minX + SCALE_RULE_LEGEND_PADDING_MM} ${minY} ` +
    (
      `v${SCALE_RULE_LEGEND_TICK_HEIGHT_MM} ` +
      `m${SCALE_RULE_LEGEND_TICK_INTERVAL_MM} ${
        -1 * SCALE_RULE_LEGEND_TICK_HEIGHT_MM
      }`
    ).repeat(numTicks);

  return (
    <svg
      className="scale-rule"
      x={0}
      y={0}
      width={innerWidth}
      height={innerHeight}
      // Nested SVG with a "viewBox" that matches the paperspace size, allows us
      // to position everything using paperspace coordinates
      viewBox={`0 0 ${storedPlot.paperspace_width} ${storedPlot.paperspace_height}`}
    >
      {/* Scale legend ratio */}
      <text
        className="scale-rule-label"
        x={minX}
        dx="-1.5"
        y={minY + SCALE_RULE_LEGEND_HEIGHT_MM}
        fontSize={tickLabelFontSize}
        textAnchor="end"
        // NOTE: "text-after-edge" doesn't work in WebKit. Workaround is to use "auto"
        // and shift the labels up by a fraction of an "em"
        dominantBaseline="auto"
        dy="-0.3em"
      >
        1:{Math.round(storedPlot.scale)}
      </text>
      {/* The border box around the scale rule */}
      <rect
        x={minX}
        y={minY}
        width={SCALE_RULE_LEGEND_WIDTH_MM}
        height={SCALE_RULE_LEGEND_HEIGHT_MM}
      />

      {/* The 4 ticks before the first 0 labelled tick. */}
      <path d={initialTicksPath} />

      {/* The ticks over the main extent of the scale rule. */}
      <path d={extentTicksPath} />

      {/* The labels on some of the ticks */}
      {range(0, SCALE_RULE_LABELLED_TICK_COUNT).map((tickIdx) => (
        <text
          key={tickIdx}
          x={
            minX +
            SCALE_RULE_LEGEND_PADDING_MM +
            SCALE_RULE_LABELLED_TICK_INTERVAL_MM * tickIdx
          }
          y={minY + SCALE_RULE_LEGEND_HEIGHT_MM}
          fontSize={tickLabelFontSize}
          textAnchor="middle"
          // NOTE: "text-after-edge" doesn't work in WebKit. Workaround is to use "auto"
          // and shift the labels up by a fraction of an "em"
          dominantBaseline="auto"
          dy="-0.3em"
        >
          {Number((tickIdx * labelledTickGapWidthM).toFixed(1))}
        </text>
      ))}
      <text
        className="scale-rule-label"
        x={minX + SCALE_RULE_LEGEND_WIDTH_MM}
        dx="1.5"
        y={minY + SCALE_RULE_LEGEND_HEIGHT_MM}
        fontSize={tickLabelFontSize}
        textAnchor="start"
        // NOTE: "text-after-edge" doesn't work in WebKit. Workaround is to use "auto"
        // and shift the labels up by a fraction of an "em"
        dominantBaseline="auto"
        dy="-0.3em"
      >
        <Trans>m</Trans>
      </text>
    </svg>
  );
}
