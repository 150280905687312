import React from 'react';
import { Trans } from '@lingui/macro';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { Field, FormikErrors, FormikHelpers } from 'formik';
import { UserProfileState } from 'ducks/userprofile';
import { Model } from 'util/backendapi/models/api.interfaces';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { PasswordChangeModal } from './PasswordChangeModal';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { sortBy } from 'lodash';

interface MyFormValues {
  email: string;
  profile: {
    name: string;
    preferred_name: string;
    mobile: string;
    default_user_area_group: number;
  };
}

function validateForm(values: MyFormValues): FormikErrors<MyFormValues> {
  let errors: FormikErrors<MyFormValues> = {};

  if (!values.email) {
    errors.email = (<Trans>Email is required.</Trans>) as any;
  }
  if (!values.profile.mobile) {
    lodashSet(
      errors,
      ['profile', 'mobile'],
      <Trans>Mobile is required.</Trans>
    );
  }
  if (!values.profile.name) {
    lodashSet(errors, ['profile', 'name'], <Trans>Name is required.</Trans>);
  }
  if (!values.profile.preferred_name) {
    lodashSet(
      errors,
      ['profile', 'preferred_name'],
      <Trans>Preferred name is required.</Trans>
    );
  }

  return errors;
}

export interface UserProfileViewProps extends UserProfileState {
  isCurrentUser: boolean;
  onSubmit: (user: Model.CurrentUser_PATCH) => Promise<any>;
}

export function UserProfileView(props: UserProfileViewProps) {
  const isMounted = useIsMounted();
  const [isEditing, setIsEditing] = React.useState(false);

  const handleSubmit = React.useCallback(
    async (values: MyFormValues, formik: FormikHelpers<MyFormValues>) => {
      try {
        const submitValues = {
          ...values,
          profile: {
            ...values.profile,
            default_user_area_group:
              values.profile.default_user_area_group || null,
          },
        };

        await props.onSubmit(submitValues);
        if (isMounted()) {
          setIsEditing(false);
        }
      } catch (e) {
        showErrorsInFormik(formik, e, getExpectedFields(values));
      }
    },
    [isMounted, props]
  );

  const renderField = React.useCallback(
    (label: React.ReactElement, name: string) => {
      return {
        name,
        label,
        content: isEditing ? (
          <>
            <Field type="text" name={name} />
            <FieldError name={name} />
          </>
        ) : (
          lodashGet(props.user, name)
        ),
      };
    },
    [isEditing, props.user]
  );

  const defaultUserAreaGroupField = React.useCallback(
    (label: React.ReactElement, name: string) => {
      const defaultOption: SimpleSelectOption<number> = {
        value: 0,
        label: <Trans>None</Trans>,
      };
      const userAreaGroupOptions = [defaultOption].concat(
        sortBy(
          props.user?.user_area_groups.map((uag) => ({
            label: uag.area_group.name,
            value: uag.id,
          })) ?? [],
          'label'
        )
      );

      const defaultUserAreaGroupValue =
        props.user?.profile.default_user_area_group ?? 0;
      const defaultUserAreaGroupName = userAreaGroupOptions.find(
        (option) => option.value === defaultUserAreaGroupValue
      )?.label;

      return {
        name,
        label,
        content: isEditing ? (
          <>
            <SimpleSelectField name={name} options={userAreaGroupOptions} />
            <FieldError name={name} />
          </>
        ) : (
          defaultUserAreaGroupName
        ),
      };
    },
    [isEditing, props.user]
  );

  const pageProps = {
    name: 'user-profile',
    header: <Trans>User Profile</Trans>,
  };
  if (props.errorMessage) {
    return (
      <PageStandard {...pageProps}>
        <AlertDanger>{props.errorMessage}</AlertDanger>
      </PageStandard>
    );
  }

  const { user } = props;

  if (props.isLoading || !user) {
    return (
      <PageStandard {...pageProps}>
        <Loading />
      </PageStandard>
    );
  }

  const userGroups = user.user_area_groups;

  return (
    <PageStandard
      {...pageProps}
      subHeader={user.profile && user.profile.name}
      key={`userprofile-${user.username}`}
    >
      <EditableCard<MyFormValues>
        name="overview"
        hasEditPermission={props.isCurrentUser}
        header={user.profile.preferred_name}
        isEditMode={isEditing}
        startEditing={() => setIsEditing(true)}
        stopEditing={() => setIsEditing(false)}
        initialValues={{
          email: user.email,
          profile: {
            ...user.profile,
            default_user_area_group: user.profile.default_user_area_group || 0,
          },
        }}
        onSubmit={handleSubmit}
        validate={validateForm}
        render={({ formik, CardSectionComponent }) => (
          <>
            {formik.status}
            <CardSectionComponent
              name="details"
              header={<Trans>Details</Trans>}
              fields={[
                renderField(<Trans>Name</Trans>, 'profile.name'),
                renderField(
                  <Trans>Preferred name</Trans>,
                  'profile.preferred_name'
                ),
                {
                  label: <Trans>Username</Trans>,
                  name: 'username',
                  content: (
                    <p className="non-editable-value">{user.username}</p>
                  ),
                },
                renderField(<Trans>Email</Trans>, 'email'),
                renderField(<Trans>Mobile</Trans>, 'profile.mobile'),
              ]}
            />
            <CardSectionComponent
              name="defaults"
              header={<Trans>Defaults</Trans>}
              fields={[
                defaultUserAreaGroupField(
                  <Trans>Default group</Trans>,
                  'profile.default_user_area_group'
                ),
              ]}
            />
            {props.isCurrentUser && (
              <CardSectionComponent
                name="password"
                header={<Trans>Password</Trans>}
              >
                <ButtonShowModal
                  name="change-password"
                  modalContent={(modalProps) => (
                    <PasswordChangeModal {...modalProps} />
                  )}
                >
                  <Trans>Change password</Trans>
                </ButtonShowModal>
              </CardSectionComponent>
            )}
            <CardSectionComponent
              name="user-groups"
              header={<Trans>Groups</Trans>}
              fields={userGroups.map((userGroup) => ({
                name: `usergroup-${userGroup.area_group.id}`,
                label: userGroup.area_group.name,
                content: userGroup.roles.map((role) => (
                  <p key={`role-${role.id}`} className="non-editable-value">
                    {role.name}
                  </p>
                )),
              }))}
            />
          </>
        )}
      />
    </PageStandard>
  );
}
