import { useEffect, useState, useCallback } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Copy from https://github.com/ZeeCoder/use-resize-observer
 *
 * With some improvements:
 * - Added TypeScript typings
 * - Use [callback ref](https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node)
 *   method rather than `useRef`, because we want to make sure that
 *   a conditionally render ref can still trigger the observer.
 */
export default function useResizeObserver<T extends HTMLElement>({
  defaultWidth = 1,
  defaultHeight = 1,
} = {}): [(node: null | T) => void, number, number, T | null] {
  const [element, setElement] = useState<T | null>(null);

  const ref = useCallback((node: null | T) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  const [width, changeWidth] = useState(defaultWidth);
  const [height, changeHeight] = useState(defaultHeight);

  useEffect(() => {
    if (!element) {
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (!Array.isArray(entries)) {
        return;
      }

      // Since we only observe the one element, we don't need to loop over the
      // array
      if (!entries.length) {
        return;
      }

      const entry = entries[0];

      changeWidth(entry.contentRect.width);
      changeHeight(entry.contentRect.height);
    });

    resizeObserver.observe(element);

    return () => resizeObserver.unobserve(element);
  }, [element]);

  return [ref, width, height, element];
}
