import React, { useMemo } from 'react';
import { DataPoint } from '../scatterplotselectors';
import Button from 'components/base/button/button';
import { Trans } from '@lingui/macro';
import { ScatterPlotReadingSeries } from '../ScatterPlot';
import { useGetApi } from 'hooks/use-get-api';
import { formatDatetimeForDisplay } from 'util/dates';
import { errorToString } from 'util/backendapi/error';

type Serie = Pick<ScatterPlotReadingSeries, 'observationPoint' | 'settings'>;

interface Props {
  point: DataPoint;
  x: Serie;
  y: Serie;
  onClose: () => void;
}

export function ScatterPlotReadingInfo(props: Props) {
  const { onClose, point, x, y } = props;

  return (
    <div className="plot-popup-timeseries">
      <div className="plot-popup-timeseries-info">
        &nbsp;
        <Button
          iconOnly
          onClick={onClose}
          iconType="icon-cross"
          shortcut="ESC"
          className="close"
        >
          <Trans>Close</Trans>
        </Button>
        {point.yId ? (
          <NonInterpolatedReading
            serie={y}
            readingId={point.yId}
            timestamp={point.time}
          />
        ) : (
          <InterpolatedReading serie={y} interpolatedValue={point.y} />
        )}
        {point.xId ? (
          <NonInterpolatedReading
            serie={x}
            readingId={point.xId}
            timestamp={point.time}
          />
        ) : (
          <InterpolatedReading serie={x} interpolatedValue={point.x} />
        )}
      </div>
    </div>
  );
}

export function NonInterpolatedReading(props: {
  serie: Serie;
  timestamp: number;
  readingId: number;
}) {
  const {
    serie: {
      observationPoint,
      settings: { item_number },
    },
    readingId,
    timestamp,
  } = props;
  const datetime = new Date(timestamp).toISOString();
  const timeZone = observationPoint.time_zone.name;
  const units = observationPoint.instrument_type.standard_units;

  const [{ data: reading, isLoading, isError, error }] = useGetApi(
    `/readings/${readingId}/`
  );

  const { rawValue, adjustedValue } = useMemo(() => {
    if (!reading) {
      return { rawValue: '...', adjustedValue: '...' };
    }
    return {
      rawValue: reading.raw_reading_entries[item_number - 1]?.value ?? (
        <Trans>[missing]</Trans>
      ),
      adjustedValue: reading.adjusted_reading_entries[item_number - 1]
        ?.value ?? <Trans>[missing]</Trans>,
    };
  }, [reading, item_number]);

  return (
    <p>
      <strong>{observationPoint.code}</strong>{' '}
      {formatDatetimeForDisplay(datetime, timeZone)}
      <br />
      {isLoading ? (
        '...'
      ) : isError ? (
        <Trans>Error: {errorToString(error)}</Trans>
      ) : (
        <>
          <strong>
            {adjustedValue}
            {units}{' '}
          </strong>
          (<Trans>RV:</Trans> {rawValue})
        </>
      )}
    </p>
  );
}

export function InterpolatedReading(props: {
  serie: Serie;
  interpolatedValue: number;
}) {
  const {
    serie: { observationPoint },
    interpolatedValue,
  } = props;

  const units = observationPoint.instrument_type.standard_units;

  return (
    <p>
      <strong>{observationPoint.code}</strong>{' '}
      <em>
        <Trans>interpolated</Trans>
      </em>
      <br />
      <strong>
        {interpolatedValue.toFixed(2)}
        {units}{' '}
        <em>
          <Trans>estimated</Trans>
        </em>
      </strong>
    </p>
  );
}
