import { getCurrentDatetime, formatDatetimeForStorage } from './dates';

export function isCurrentAlarm(alarm: {
  start_datetime: string;
  end_datetime: null | string;
}) {
  const now = getCurrentDatetime();
  return (
    formatDatetimeForStorage(alarm.start_datetime) <= now &&
    (!alarm.end_datetime || formatDatetimeForStorage(alarm.end_datetime) > now)
  );
}

export function isFutureAlarm(alarm: {
  start_datetime: string;
  end_datetime: null | string;
}) {
  const now = getCurrentDatetime();
  return formatDatetimeForStorage(alarm.start_datetime) > now;
}
