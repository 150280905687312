import React from 'react';
import * as Sentry from '@sentry/browser';
import { errorToString } from 'util/backendapi/error';

interface State {
  errorDetail: string | null;
  componentStack: string | null;
}

export class ErrorBoundary extends React.Component<{}, State> {
  state = {
    errorDetail: null,
    componentStack: null,
  };

  static getDerivedStateFromError(error: any) {
    const errorDetail =
      error instanceof Error
        ? `Error message: "${error.message}"\n\nStack trace:\n${error.stack}`
        : errorToString(error);
    return { errorDetail };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (process.env.NODE_ENV === 'production') {
      // We only need to explicitly log these things to the console when we're in
      // production mode, because in other modes React will log them itself.

      // eslint-disable-next-line no-console
      console.error('DMS Front-end error', error);
      // eslint-disable-next-line no-console
      console.error(errorInfo.componentStack);

      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo as { componentStack: string });
        Sentry.captureException(error);
      });
    }

    this.setState({
      componentStack: errorInfo.componentStack,
    });
  }

  render() {
    if (this.state.errorDetail) {
      return (
        <div style={{ margin: '20px' }}>
          <h1>Front-end rendering error</h1>
          <main>
            <div>
              <details>
                <summary>Details</summary>
                <pre>
                  {this.state.errorDetail}
                  {this.state.componentStack &&
                    `\n\nComponent stack:\n
${this.state.componentStack}`}
                </pre>
              </details>
            </div>
          </main>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
