import React, { useEffect } from 'react';
import { Trans } from '@lingui/macro';
import { Field, FormikErrors, FormikProps } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { ButtonPrimary } from 'components/base/button/button';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';

export interface StoredPlotSaveAsValues {
  save_as: boolean;
  save_as_name: string;
  save_as_description: string;
  save_as_area: number;
}

export interface StoredPlotSaveAsSettings {
  name: string;
  description: string;
  area: number;
}

interface Props<T extends StoredPlotSaveAsValues & { area: number }> {
  formik: FormikProps<T>;
  hideModal: () => void;
  areaOptions: { value: number; label: string; timeZone: string }[];
}

export function makeStoredPlotSaveAsInitialValues(): StoredPlotSaveAsValues {
  return {
    save_as: false,
    save_as_name: '',
    save_as_description: '',
    save_as_area: 0,
  };
}

export function getStoredPlotSaveAsSettings(
  values: StoredPlotSaveAsValues
): StoredPlotSaveAsSettings | null {
  if (!values.save_as) return null;
  return {
    name: values.save_as_name,
    description: values.save_as_description,
    area: values.save_as_area,
  };
}

export function validateStoredPlotSaveAsValues(
  values: StoredPlotSaveAsValues
): FormikErrors<StoredPlotSaveAsValues> {
  let errors: FormikErrors<StoredPlotSaveAsValues> = {};

  if (values.save_as) {
    if (values.save_as_name === '') {
      errors.save_as_name = (<Trans>Name is required.</Trans>) as any;
    }

    if (!values.save_as_area) {
      errors.save_as_area = (<Trans>Area is required.</Trans>) as any;
    }
  }

  return errors;
}

export function StoredPlotSaveAsModal<
  T extends StoredPlotSaveAsValues & { area: number }
>({ formik, areaOptions, hideModal }: Props<T>) {
  useEffect(() => {
    // on mount
    formik.setFieldValue('save_as_area', formik.values.area, false);
    formik.setFieldValue('save_as', true, false);

    return () => {
      // on unmount
      formik.setFieldValue('save_as', false, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ModalContent header={<Trans>Save plot as...</Trans>}>
      <FormSection label={<Trans>Stored plot</Trans>}>
        <FormItem label={<Trans>Name</Trans>}>
          <Field type="text" name="save_as_name" autoFocus />
          <FieldError name="save_as_name" />
        </FormItem>
        <FormItem label={<Trans>Description</Trans>}>
          <Field name="save_as_description" component="textarea" />
          <FieldError name="save_as_description" />
        </FormItem>
        <FormItem label={<Trans>Area</Trans>}>
          <SimpleSelectField
            name="save_as_area"
            isLoading={!areaOptions.length}
            options={areaOptions}
          />
          <FieldError name="save_as_area" />
        </FormItem>
      </FormSection>
      <ActionBlock>
        <ButtonHideModal />
        <ButtonPrimary
          data-testid="stored-plot-save-as-submit"
          type="submit"
          disabled={formik.isSubmitting}
          iconType="icon-save"
          onClick={() => {
            formik.submitForm();
          }}
        >
          <Trans>Save</Trans>
        </ButtonPrimary>
      </ActionBlock>
    </ModalContent>
  );
}
