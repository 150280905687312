import React from 'react';
import lodashSet from 'lodash/set';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { StoredSpatialPlanPlotWithArea } from 'ducks/stored-plot/detail';
import {
  makeStoredPlotNameSectionInitialValues,
  StoredPlotNameSection,
  validateStoredPlotNameSection,
} from './sections/StoredPlotNameSection';
import { Formik, Form, FormikErrors, FormikProps } from 'formik';
import { FormCard, FormCardSection } from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { StoredPlotSaveCancelButtons } from './StoredPlotSaveCancelButtons';
import ActionBlock from 'components/base/actionblock/actionblock';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { NewStoredPlotDefaults } from './stored-plot-edit-utils';
import { UnitOfMeasurementField } from 'components/base/form/unitofmeasurement-field/UnitOfMeasurementField';
import { parseFilenameFromUrl } from 'util/backendapi/file';
import { StoredSpatialPlotObservationPoint } from 'util/backendapi/types/Model';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { isNotNull } from 'util/validation';
import {
  StoredSpatialPlotFormValues,
  StoredSpatialPlotObservationPointListSection,
} from './sections/StoredSpatialPlotObservationPointListSection';
import {
  ToggleField,
  ShowHideToggleField,
  YesNoToggleField,
} from 'components/base/form/toggle-field/ToggleField';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import { formatDatetimeForBackendapi } from 'util/dates';
import { StoredSpatialLayersSection } from './sections/StoredSpatialLayersSection';
import { StoredSpatialDimensionsSection } from './sections/StoredSpatialDimensionsSection';
import {
  makeStoredPlotSaveAsInitialValues,
  validateStoredPlotSaveAsValues,
  StoredPlotSaveAsValues,
  StoredPlotSaveAsSettings,
  getStoredPlotSaveAsSettings,
} from './StoredPlotSaveAsModal';
import { showStoredPlotErrorsInFormik } from './StoredPlotEdit';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';

type StoredSpatialPlotObservationFormValues = Merge<
  StoredSpatialPlotObservationPoint,
  {
    selectedOptions?: SimpleSelectOption<number>[];
    use_specific_datetime: boolean;
  }
>;

export type StoredSpatialPlanPlotFormValues = StoredPlotSaveAsValues &
  Merge<
    Model.StoredSpatialPlanPlot_POST,
    {
      scale: string;
      observation_points: StoredSpatialPlotObservationFormValues[];
      use_specific_datetime: boolean;
      show_lake_level: boolean;
      show_tailwater_level: boolean;
      show_scale_rule: boolean;
      show_readings_legend: boolean;
    }
  >;

export interface StoredSpatialPlanPlotFormProps {
  storedPlot: StoredSpatialPlanPlotWithArea | null;
  newPlotDefaults: NewStoredPlotDefaults | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  onSubmit: (
    values: Model.StoredSpatialPlanPlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<Model.StoredSpatialPlanPlot>;
  onCancel: () => void;
}

function makeInitialValues(
  storedPlot: StoredSpatialPlanPlotWithArea | null,
  newPlotDefaults: NewStoredPlotDefaults | null
): StoredSpatialPlanPlotFormValues {
  if (!storedPlot) {
    // Create an empty one
    // Not all fields have values initially
    return {
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.SPATIAL_PLAN,
      name: newPlotDefaults?.name ?? '',
      area: 0,
      observation_points: [
        {
          observation_point: 0,
          label: '',
          use_specific_datetime: false,
          reading_datetime: null,
          show_label: true,
          show_in_data_table: true,
          vertical_offset: '0',
          horizontal_offset: '0',
        },
      ],
      scale: '',
      base_layer_image: '',
      additional_layer_image: '',
      base_layer_image_file: null,
      additional_layer_image_file: null,
      paperspace_width: '',
      paperspace_height: '',
      base_observation_point: null,
      base_paperspace_x: '',
      base_paperspace_y: '',
      use_specific_datetime: false,
      default_reading_datetime: null,
      show_data_table: true,
      data_table_style: Enum.StoredSpatialPlanPlot_DATA_TABLE_STYLE.STANDARD,
      data_table_paperspace_x: '',
      data_table_paperspace_y: '',
      show_lake_level: true,
      lake_level_paperspace_x: '',
      lake_level_paperspace_y: '',
      lake_level_observation_point: null,
      show_tailwater_level: true,
      tailwater_level_paperspace_x: '',
      tailwater_level_paperspace_y: '',
      tailwater_level_observation_point: null,
      show_scale_rule: false,
      show_readings_legend: false,
      disperse_observation_point_labels: false,
    };
  }

  const storedPlotValues: StoredSpatialPlanPlotFormValues = {
    ...makeStoredPlotNameSectionInitialValues(storedPlot),
    ...makeStoredPlotSaveAsInitialValues(),
    plot_type: Enum.PlotType.SPATIAL_PLAN,
    observation_points: storedPlot.observation_points.map((op) => ({
      ...op,
      use_specific_datetime: Boolean(op.reading_datetime),
      vertical_offset: op.vertical_offset,
      horizontal_offset: op.horizontal_offset,
    })),
    base_layer_image: parseFilenameFromUrl(storedPlot.base_layer_image),
    additional_layer_image: parseFilenameFromUrl(
      storedPlot.additional_layer_image ?? ''
    ),
    base_layer_image_file: null,
    additional_layer_image_file: null,
    scale: String(storedPlot.scale),
    paperspace_width: storedPlot.paperspace_width,
    paperspace_height: storedPlot.paperspace_height,
    base_observation_point: storedPlot.base_observation_point,
    base_paperspace_x: storedPlot.base_paperspace_x ?? '',
    base_paperspace_y: storedPlot.base_paperspace_y ?? '',
    use_specific_datetime: Boolean(storedPlot.default_reading_datetime),
    default_reading_datetime: storedPlot.default_reading_datetime,
    show_data_table: storedPlot.show_data_table,
    data_table_style: storedPlot.data_table_style,
    data_table_paperspace_x: storedPlot.data_table_paperspace_x,
    data_table_paperspace_y: storedPlot.data_table_paperspace_y,
    show_lake_level: Boolean(storedPlot.lake_level_observation_point),
    lake_level_paperspace_x: storedPlot.lake_level_paperspace_x ?? '',
    lake_level_paperspace_y: storedPlot.lake_level_paperspace_y ?? '',
    lake_level_observation_point: storedPlot.lake_level_observation_point,
    show_tailwater_level: Boolean(storedPlot.tailwater_level_observation_point),
    tailwater_level_paperspace_x: storedPlot.tailwater_level_paperspace_x ?? '',
    tailwater_level_paperspace_y: storedPlot.tailwater_level_paperspace_y ?? '',
    tailwater_level_observation_point:
      storedPlot.tailwater_level_observation_point,
    show_scale_rule: Boolean(
      storedPlot.scale_rule_x || storedPlot.scale_rule_y
    ),
    show_readings_legend: Boolean(
      storedPlot.readings_legend_x || storedPlot.readings_legend_y
    ),
    scale_rule_x: storedPlot.scale_rule_x ?? '',
    scale_rule_y: storedPlot.scale_rule_y ?? '',
    readings_legend_x: storedPlot.readings_legend_x ?? '',
    readings_legend_y: storedPlot.readings_legend_y ?? '',
    disperse_observation_point_labels:
      storedPlot.disperse_observation_point_labels,
  };

  return storedPlotValues;
}

function validateForm(
  values: StoredSpatialPlanPlotFormValues
): FormikErrors<StoredSpatialPlanPlotFormValues> {
  let errors: FormikErrors<StoredSpatialPlanPlotFormValues> = {
    ...validateStoredPlotNameSection(values),
    ...validateStoredPlotSaveAsValues(values),
  };

  if (values.use_specific_datetime) {
    if (!values.default_reading_datetime) {
      errors.default_reading_datetime = (
        <Trans>Default reading date required.</Trans>
      ) as any;
    }
  }

  if (!values.base_layer_image) {
    errors.base_layer_image = (<Trans>Base image is required.</Trans>) as any;
  }

  let validBaseObsPoint = false;
  let validLakeLevelObsPoint = false;
  let validTailwaterLevelObsPoint = false;

  values.observation_points.forEach(function (observation_point, idx) {
    if (observation_point.observation_point === values.base_observation_point) {
      validBaseObsPoint = true;
    }
    if (
      observation_point.observation_point ===
      values.lake_level_observation_point
    ) {
      validLakeLevelObsPoint = true;
    }
    if (
      observation_point.observation_point ===
      values.tailwater_level_observation_point
    ) {
      validTailwaterLevelObsPoint = true;
    }
    if (!observation_point.observation_point) {
      lodashSet(
        errors,
        ['observation_points', idx, 'observation_point'],
        <Trans>Observation point is required.</Trans>
      );
    }
    if (observation_point.label === '') {
      lodashSet(
        errors,
        ['observation_points', idx, 'label'],
        <Trans>Label is required.</Trans>
      );
    }
    if (observation_point.use_specific_datetime) {
      if (!observation_point.reading_datetime) {
        lodashSet(
          errors,
          ['observation_points', idx, 'reading_datetime'],
          <Trans>Reading date is required.</Trans>
        );
      }
    }
  });

  if (values.base_observation_point && !validBaseObsPoint) {
    errors.base_observation_point = (
      <Trans>Invalid base observation point selected.</Trans>
    ) as any;
  }

  if (values.show_lake_level) {
    if (!values.lake_level_observation_point) {
      errors.lake_level_observation_point = (
        <Trans>Lake level observation point is required.</Trans>
      ) as any;
    } else if (!validLakeLevelObsPoint) {
      errors.lake_level_observation_point = (
        <Trans>Invalid lake level observation point selected.</Trans>
      ) as any;
    }

    if (!values.lake_level_paperspace_x) {
      errors.lake_level_paperspace_x = (
        <Trans>Lake level X axis paperspace location is required.</Trans>
      ) as any;
    }
    if (!values.lake_level_paperspace_y) {
      errors.lake_level_paperspace_y = (
        <Trans>Lake level Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  if (values.show_tailwater_level) {
    if (!values.tailwater_level_observation_point) {
      errors.tailwater_level_observation_point = (
        <Trans>Tail water level observation point is required.</Trans>
      ) as any;
    } else if (!validTailwaterLevelObsPoint) {
      errors.tailwater_level_observation_point = (
        <Trans>Invalid tail water level observation point selected.</Trans>
      ) as any;
    }

    if (!values.tailwater_level_paperspace_x) {
      errors.tailwater_level_paperspace_x = (
        <Trans>Tail water level X axis paperspace location is required.</Trans>
      ) as any;
    }
    if (!values.tailwater_level_paperspace_y) {
      errors.tailwater_level_paperspace_y = (
        <Trans>Tail water level Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  if (values.show_scale_rule) {
    if (!values.scale_rule_x) {
      errors.scale_rule_x = (
        <Trans>Scale rule X axis paperspace location is required.</Trans>
      ) as any;
    }
    if (!values.scale_rule_y) {
      errors.scale_rule_y = (
        <Trans>Scale rule Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  if (values.show_readings_legend) {
    if (!values.readings_legend_x) {
      errors.readings_legend_x = (
        <Trans>Readings legend X axis paperspace location is required.</Trans>
      ) as any;
    }
    if (!values.readings_legend_y) {
      errors.readings_legend_y = (
        <Trans>Readings legend Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  if (values.show_data_table) {
    if (!values.data_table_paperspace_x) {
      errors.data_table_paperspace_x = (
        <Trans>Data table X axis paperspace location is required.</Trans>
      ) as any;
    }

    if (!values.data_table_paperspace_y) {
      errors.data_table_paperspace_y = (
        <Trans>Data table Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  const requiredFieldLabels = {
    scale: <Trans>Scale</Trans>,
    paperspace_width: <Trans>Paperspace width (X axis)</Trans>,
    paperspace_height: <Trans>Paperspace height (Y axis)</Trans>,
  } as const;

  Object.entries(requiredFieldLabels).forEach(function ([fieldName, label]) {
    if (!values[fieldName]) {
      errors[fieldName] = (<Trans>{label} is required.</Trans>) as any;
    }
  });

  const requiredFieldsWithObservationPoints = {
    base_observation_point: <Trans>Base observation point</Trans>,
    base_paperspace_x: <Trans>Base obs pt X axis paperspace location</Trans>,
    base_paperspace_y: <Trans>Base obs pt Y axis paperspace location</Trans>,
  };

  if (values.observation_points.length > 0) {
    Object.entries(requiredFieldsWithObservationPoints).forEach(function ([
      fieldName,
      label,
    ]) {
      if (!values[fieldName]) {
        errors[fieldName] = (<Trans>{label} is required.</Trans>) as any;
      }
    });
  }

  return errors;
}

export function StoredSpatialPlanPlotForm(
  props: StoredSpatialPlanPlotFormProps
) {
  const { storedPlot, areaOptions, newPlotDefaults } = props;

  const initialValues = makeInitialValues(storedPlot, newPlotDefaults);

  return (
    <Formik<StoredSpatialPlanPlotFormValues>
      validate={validateForm}
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        const valuesForBackend: Model.StoredSpatialPlanPlot_POST = {
          ...(values as StoredSpatialPlanPlotFormValues),
          scale: +values.scale,
          default_reading_datetime: values.use_specific_datetime
            ? formatDatetimeForBackendapi(values.default_reading_datetime!)
            : null,
          observation_points: values.observation_points!.map(
            ({
              selectedOptions,
              use_specific_datetime,
              reading_datetime,
              ...rest
            }) => ({
              ...rest,
              reading_datetime: use_specific_datetime
                ? formatDatetimeForBackendapi(reading_datetime!)
                : null,
            })
          ),
          base_observation_point: values.base_observation_point
            ? values.base_observation_point
            : null,
          base_paperspace_x: values.base_paperspace_x
            ? values.base_paperspace_x
            : null,
          base_paperspace_y: values.base_paperspace_y
            ? values.base_paperspace_y
            : null,
          data_table_paperspace_x: values.show_data_table
            ? values.data_table_paperspace_x
            : null,
          data_table_paperspace_y: values.show_data_table
            ? values.data_table_paperspace_y
            : null,
          lake_level_observation_point: values.show_lake_level
            ? values.lake_level_observation_point
            : null,
          lake_level_paperspace_x: values.show_lake_level
            ? values.lake_level_paperspace_x
            : null,
          lake_level_paperspace_y: values.show_lake_level
            ? values.lake_level_paperspace_y
            : null,
          tailwater_level_observation_point: values.show_tailwater_level
            ? values.tailwater_level_observation_point
            : null,
          tailwater_level_paperspace_x: values.show_tailwater_level
            ? values.tailwater_level_paperspace_x
            : null,
          tailwater_level_paperspace_y: values.show_tailwater_level
            ? values.tailwater_level_paperspace_y
            : null,
          scale_rule_x: values.show_scale_rule ? values.scale_rule_x : null,
          scale_rule_y: values.show_scale_rule ? values.scale_rule_y : null,
          readings_legend_x: values.show_readings_legend
            ? values.readings_legend_x
            : null,
          readings_legend_y: values.show_readings_legend
            ? values.readings_legend_y
            : null,
        };
        try {
          await props.onSubmit(
            valuesForBackend,
            getStoredPlotSaveAsSettings(values)
          );
        } catch (e) {
          formik.setSubmitting(false);
          showStoredPlotErrorsInFormik(formik, e, values);
        }
      }}
    >
      {(formik) => {
        const area = areaOptions.find((a) => a.value === formik.values.area);
        const timeZone = area?.timeZone;

        return (
          <Form>
            {formik.status}
            <FormCard
              name="general"
              header={<Trans>General</Trans>}
              subHeader={
                <StoredPlotSaveCancelButtons {...props} formik={formik} />
              }
              className="stored-plot-plot-card"
            >
              <StoredPlotNameSection
                areaOptions={areaOptions}
                plotType={Enum.PlotType.SPATIAL_PLAN}
              />
              <StoredSpatialLayersSection />
              <FormCardSection
                name="reading-dates"
                header={<Trans>Reading dates</Trans>}
                fields={[
                  {
                    name: 'use_specific_datetime',
                    label: <Trans>Default date</Trans>,
                    content: (
                      <ToggleField
                        name="use_specific_datetime"
                        options={[
                          {
                            label: <Trans>Latest reading</Trans>,
                            value: false,
                          },
                          {
                            label: <Trans>Nearest before...</Trans>,
                            value: true,
                          },
                        ]}
                      />
                    ),
                  },
                  formik.values.use_specific_datetime
                    ? {
                        name: 'default_reading_datetime',
                        label: <Trans>Default reading date</Trans>,
                        content: (
                          <>
                            <DatetimeField
                              name="default_reading_datetime"
                              timeZone={timeZone}
                            />
                            <FieldError name="default_reading_datetime" />
                          </>
                        ),
                      }
                    : null,
                ].filter(isNotNull)}
              />
              <StoredSpatialPlotObservationPointListSection
                formik={formik as FormikProps<StoredSpatialPlotFormValues>}
                timeZone={timeZone}
                requireObservationPoint={false}
              />
              <StoredSpatialDimensionsSection />
              <FormChangeEffect<StoredSpatialPlanPlotFormValues>
                onChange={({ values: prevValues }) => {
                  // Because the base observation point field is disabled
                  // when there are no observation points we need
                  // to clear them in that case
                  if (
                    prevValues.observation_points.length > 0 &&
                    formik.values.observation_points.length === 0 &&
                    formik.values.base_observation_point
                  ) {
                    formik.setFieldValue('base_observation_point', null);
                  }
                }}
              />
              <FormCardSection
                name="reference-point"
                header={<Trans>Reference point</Trans>}
                fields={[
                  {
                    name: 'base_observation_point',
                    label: <Trans>Base observation point</Trans>,
                    content: (
                      <>
                        <SimpleSelectField<number>
                          name="base_observation_point"
                          isMulti={false}
                          isClearable={true}
                          isDisabled={
                            !formik.values.observation_points[0]
                              ?.observation_point
                          }
                          options={formik.values.observation_points
                            .map(({ selectedOptions }) =>
                              selectedOptions?.[0]
                                ? {
                                    label: selectedOptions[0].label,
                                    value: selectedOptions[0].value,
                                  }
                                : null
                            )
                            .filter(isNotNull)}
                        />
                        <FieldError name="base_observation_point" />
                      </>
                    ),
                  },
                  {
                    name: 'base_x_axis_paperspace_location',
                    label: (
                      <Trans>Base obs pt X axis paperspace location</Trans>
                    ),
                    content: (
                      <>
                        <UnitOfMeasurementField
                          name="base_paperspace_x"
                          unit="mm"
                        />
                        <FieldError name="base_paperspace_x" />
                      </>
                    ),
                  },
                  {
                    name: 'base_paperspace_y',
                    label: (
                      <Trans>Base obs pt Y axis paperspace location</Trans>
                    ),
                    content: (
                      <>
                        <UnitOfMeasurementField
                          name="base_paperspace_y"
                          unit="mm"
                        />
                        <FieldError name="base_paperspace_y" />
                      </>
                    ),
                  },
                ]}
              />
              <FormCardSection
                name="display-options"
                header={<Trans>Display options</Trans>}
                fields={[
                  {
                    name: 'show_data_table',
                    label: <Trans>Data table</Trans>,
                    content: (
                      <>
                        <ShowHideToggleField name="show_data_table" />
                        <FieldError name="show_data_table" />
                        {!formik.values.show_data_table && (
                          <>
                            <FieldError name="data_table_paperspace_x" />
                            <FieldError name="data_table_paperspace_y" />
                          </>
                        )}
                      </>
                    ),
                  },
                  formik.values.show_data_table
                    ? {
                        name: 'data_table_style',
                        label: <Trans>Data table style</Trans>,
                        content: (
                          <>
                            <SimpleSelectField
                              name="data_table_style"
                              isMulti={false}
                              options={menuItemsFromEnum(
                                'StoredSpatialPlanPlot_DATA_TABLE_STYLE'
                              )}
                            />
                            <FieldError name="data_table_style" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_data_table
                    ? {
                        name: 'data_table_paperspace_x',
                        label: (
                          <Trans>Data table X axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="data_table_paperspace_x"
                              unit="mm"
                            />
                            <FieldError name="data_table_paperspace_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_data_table
                    ? {
                        name: 'data_table_paperspace_y',
                        label: (
                          <Trans>Data table Y axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="data_table_paperspace_y"
                              unit="mm"
                            />
                            <FieldError name="data_table_paperspace_y" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'show_lake_level',
                    label: <Trans>Lake level</Trans>,
                    content: <ShowHideToggleField name="show_lake_level" />,
                  },
                  formik.values.show_lake_level
                    ? {
                        name: 'lake_level_observation_point',
                        label: <Trans>Lake level observation point</Trans>,
                        content: (
                          <>
                            <SimpleSelectField<number>
                              name="lake_level_observation_point"
                              isMulti={false}
                              isDisabled={
                                !formik.values.observation_points[0]
                                  ?.observation_point
                              }
                              options={formik.values.observation_points
                                .map(({ selectedOptions }) =>
                                  selectedOptions?.[0]
                                    ? {
                                        label: selectedOptions[0].label,
                                        value: selectedOptions[0].value,
                                      }
                                    : null
                                )
                                .filter(isNotNull)}
                            />
                            <FieldError name="lake_level_observation_point" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_lake_level
                    ? {
                        name: 'lake_level_paperspace_x',
                        label: (
                          <Trans>Lake level X axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="lake_level_paperspace_x"
                              unit="mm"
                            />
                            <FieldError name="lake_level_paperspace_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_lake_level
                    ? {
                        name: 'lake_level_paperspace_y',
                        label: (
                          <Trans>Lake level Y axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="lake_level_paperspace_y"
                              unit="mm"
                            />
                            <FieldError name="lake_level_paperspace_y" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'show_tailwater_level',
                    label: <Trans>Tailwater level</Trans>,
                    content: (
                      <ShowHideToggleField name="show_tailwater_level" />
                    ),
                  },
                  formik.values.show_tailwater_level
                    ? {
                        name: 'tailwater_level_observation_point',
                        label: <Trans>Tailwater level observation point</Trans>,
                        content: (
                          <>
                            <SimpleSelectField<number>
                              name="tailwater_level_observation_point"
                              isMulti={false}
                              isDisabled={
                                !formik.values.observation_points[0]
                                  ?.observation_point
                              }
                              options={formik.values.observation_points
                                .map(({ selectedOptions }) =>
                                  selectedOptions?.[0]
                                    ? {
                                        label: selectedOptions[0].label,
                                        value: selectedOptions[0].value,
                                      }
                                    : null
                                )
                                .filter(isNotNull)}
                            />
                            <FieldError name="tailwater_level_observation_point" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_tailwater_level
                    ? {
                        name: 'tailwater_level_paperspace_x',
                        label: (
                          <Trans>
                            Tailwater level X axis paperspace location
                          </Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="tailwater_level_paperspace_x"
                              unit="mm"
                            />
                            <FieldError name="tailwater_level_paperspace_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_tailwater_level
                    ? {
                        name: 'tailwater_level_paperspace_y',
                        label: (
                          <Trans>
                            Tailwater level Y axis paperspace location
                          </Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="tailwater_level_paperspace_y"
                              unit="mm"
                            />
                            <FieldError name="tailwater_level_paperspace_y" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'show_scale_rule',
                    label: <Trans>Scale rule</Trans>,
                    content: <ShowHideToggleField name="show_scale_rule" />,
                  },
                  formik.values.show_scale_rule
                    ? {
                        name: 'scale_rule_x',
                        label: (
                          <Trans>Scale rule X axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="scale_rule_x"
                              unit="mm"
                            />
                            <FieldError name="scale_rule_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_scale_rule
                    ? {
                        name: 'scale_rule_y',
                        label: (
                          <Trans>Scale rule Y axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="scale_rule_y"
                              unit="mm"
                            />
                            <FieldError name="scale_rule_y" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'show_readings_legend',
                    label: <Trans>Readings legend</Trans>,
                    content: (
                      <ShowHideToggleField name="show_readings_legend" />
                    ),
                  },
                  formik.values.show_readings_legend
                    ? {
                        name: 'readings_legend_x',
                        label: (
                          <Trans>
                            Readings legend X axis paperspace location
                          </Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="readings_legend_x"
                              unit="mm"
                            />
                            <FieldError name="readings_legend_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_readings_legend
                    ? {
                        name: 'readings_legend_y',
                        label: (
                          <Trans>
                            Readings legend Y axis paperspace location
                          </Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="readings_legend_y"
                              unit="mm"
                            />
                            <FieldError name="readings_legend_y" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'disperse_observation_point_labels',
                    label: <Trans>Disperse observation point labels</Trans>,
                    content: (
                      <YesNoToggleField name="disperse_observation_point_labels" />
                    ),
                  },
                ].filter(isNotNull)}
              />
            </FormCard>
            <ActionBlock className="text-right">
              <StoredPlotSaveCancelButtons {...props} formik={formik} />
            </ActionBlock>
          </Form>
        );
      }}
    </Formik>
  );
}
