import { Model } from 'util/backendapi/models/api.interfaces';
import { DRFError, errorToString } from 'util/backendapi/error';
import { StandardThunk } from 'main/store';
import { getApi, patchApi, postApi } from 'util/backendapi/fetch';

export const ActionTypes = {
  FETCH_CLIENT_START: 'dms/client/detail/FETCH_CLIENT_START',
  FETCH_CLIENT_RESPONSE: 'dms/client/detail/FETCH_CLIENT_RESPONSE',
  FETCH_CLIENT_ERROR: 'dms/client/detail/FETCH_CLIENT_ERROR',
  CLEAR_CLIENT_FORM: 'dms/client/detail/CLEAR_CLIENT_FORM',
} as const;

export const ActionCreators = {
  fetchClientStart: (clientId: number) => ({
    type: ActionTypes.FETCH_CLIENT_START,
    clientId,
  }),
  fetchClientResponse: (client: Model.Client) => ({
    type: ActionTypes.FETCH_CLIENT_RESPONSE,
    payload: client,
  }),
  fetchClientError: (clientId: number, error: DRFError | Error) => ({
    type: ActionTypes.FETCH_CLIENT_ERROR,
    clientId,
    error: true,
    payload: errorToString(error),
  }),
  clearClientForm: () => ({
    type: ActionTypes.CLEAR_CLIENT_FORM,
  }),
} as const;

export type ClientDetailActions = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export interface ClientDetailState {
  loading: boolean;
  errorMessage: string | null;
  clientId: null | number;
  client: null | Model.Client;
}

export function initalClientDetailState(): ClientDetailState {
  return {
    loading: false,
    errorMessage: null,
    clientId: null,
    client: null,
  };
}

export function clientDetailReducer(
  state = initalClientDetailState(),
  action: ClientDetailActions
): ClientDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENT_START:
      return {
        ...initalClientDetailState(),
        loading: true,
        clientId: action.clientId,
      };
    case ActionTypes.FETCH_CLIENT_RESPONSE:
      if (state.clientId !== action.payload.id) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          client: action.payload,
        };
      }
    case ActionTypes.FETCH_CLIENT_ERROR:
      if (state.clientId !== action.clientId) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          errorMessage: action.payload,
        };
      }
    case ActionTypes.CLEAR_CLIENT_FORM:
      return initalClientDetailState();
    default:
      return state;
  }
}

export function fetchClient(clientId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.fetchClientStart(clientId));
    try {
      const client = await getApi(`/clients/${clientId}/`);
      dispatch(ActionCreators.fetchClientResponse(client));
    } catch (e) {
      dispatch(ActionCreators.fetchClientError(clientId, e));
    }
  };
}

export function updateClient(
  clientId: number,
  payload: ForPatch<Model.Client>
): StandardThunk {
  return async function (dispatch, getState, { i18n }) {
    return await patchApi(`/clients/${clientId}/`, payload);
  };
}

export function createClient(
  payload: ForPostOrPut<Model.Client>
): StandardThunk {
  return async function (dispatch, getState, { i18n }) {
    return await postApi(`/clients/`, payload);
  };
}
