import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const savedReportsListDuck = makeReportingDuck(
  'savedReports',
  (filters) => getPaginated('/reports/saved-reports/', filters),
  () => getApi('/reports/saved-reports/info/')
);

export type SavedReportsListAction = ReportingDuckActions<
  typeof savedReportsListDuck
>;
