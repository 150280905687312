import React from 'react';
import { RCPWithQueryParams } from 'util/routing';
import {
  AuditLogListView,
  AuditLogListColumns,
  AuditLogListFilters,
} from './AuditLogListView';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import { auditLogsListDuck } from 'ducks/auditLog/list';
import { FullState } from 'main/reducers';

type OwnProps = RCPWithQueryParams<Filter.ReportsAuditLog>;

export const AuditLogListScreen = (ownProps: OwnProps) => {
  const props = useReportState(
    ownProps,
    AuditLogListColumns,
    AuditLogListFilters(),
    auditLogsListDuck,
    (state: FullState) => state.auditLog.list
  );

  return <AuditLogListView reportProps={props} />;
};
