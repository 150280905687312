import React from 'react';
import {
  ReportColumn,
  ReportFilter,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { Trans, NumberFormat, t } from '@lingui/macro';
import { TransEnum } from 'components/base/i18n/TransEnum';
import {
  formatDatetimeForDisplay,
  formatDatetimeForBackendUrl,
} from 'util/dates';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { getApi } from 'util/backendapi/fetch';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { RouteComponentProps } from 'react-router-dom';
import { Icon } from 'components/base/icon/icon';
import { readingsBatchDuck } from 'ducks/readings-batch/list';
import { ReportStateProps, useReportState } from 'hooks/use-report-state';
import { reportFilterDatetime } from 'components/modules/report/filter/fields/FilterDate';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { DMSLink } from 'components/base/link/DMSLink';
import { DeleteReadingsBatchButton } from 'screens/dashboard/batcheslist/batcheslistview';
import { EMPTY_FUNC } from 'util/misc';
import uniq from 'lodash/uniq';
import { DownloadBatchButton } from 'components/modules/DownloadBatchButton';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';

export const readingsBatchFilters: ReportFilter[] = [
  reportFilterAsyncMenu(
    'batch_number',
    <Trans>Batch number</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (inputValue: string) =>
        getApi('/reports/readings-batches/', {
          batch_number__startswith: inputValue,
          ordering: ['-batch_number'],
          limit: 10,
          columns: ['batch_number'],
        }).then((batches) =>
          batches.map(({ batch_number }) => ({
            value: batch_number,
            label: batch_number,
          }))
        ),
      loadDefaults: (initialValue: number[]) =>
        getApi('/reports/readings-batches/', {
          batch_number__in: initialValue,
          columns: ['batch_number'],
        }).then((batches) =>
          batches.map(({ batch_number }) => ({
            value: batch_number,
            label: batch_number,
          }))
        ),
    },
    {
      placeholder: <Trans>All batches</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterMenu(
    'status',
    <Trans>Batch status</Trans>,
    {
      isMulti: true,
      valueType: 'string',
    },
    ReportFilterMenu.ENUM_MENU('ReadingsBatch_STATUS'),
    {
      placeholder: <Trans>All statuses</Trans>,
      options: Object.values(Enum.ReadingsBatch_STATUS),
    }
  ),
  reportFilterDatetime('created', <Trans>Created date</Trans>, null),
  reportFilterUsers('created_by', <Trans>Uploaded by</Trans>),
  reportFilterMenu(
    'data_logger',
    <Trans>Data logger number</Trans>,
    {
      isMulti: true,
      valueType: 'number',
    },
    (dl: { id: number; logger_number: number }) => ({
      value: dl.id,
      label: dl.logger_number,
    }),
    {
      placeholder: <Trans>All batches</Trans>,
    }
  ),
  reportFilterMenu(
    'reading__reading_error__isnull',
    <Trans>Batch errors</Trans>,
    { isMulti: false, valueType: 'string' },
    ReportFilterMenu.PASS_MENU_THROUGH,
    {
      placeholder: <Trans>All batches</Trans>,
      // NOTE: Because this is an "isnull" filter, TRUE means there are NO ERRORS
      options: [
        {
          label: <Trans>Yes</Trans>,
          value: 'false',
        },
        {
          label: <Trans>No</Trans>,
          value: 'true',
        },
      ],
    }
  ),
  reportFilterAsyncMenu(
    'name',
    <Trans>Batch name</Trans>,
    {
      isMulti: true,
      valueType: 'string',
      onSearch: (inputValue: string) =>
        getApi('/reports/readings-batches/', {
          name__icontains: inputValue,
          ordering: ['-name'],
          limit: 10,
          columns: ['name'],
          // To reduce performance hit, only check records since the start of 2020.
          created__gte: formatDatetimeForBackendUrl('2020-01-01T00:00:00Z'),
        }).then((batches) =>
          uniq(batches.map(({ name }) => name)).map((name) => ({
            value: name,
            label: name,
          }))
        ),
      loadDefaults: (initialValue: string[]) =>
        // Don't actually need to do a request here, because we only need the names,
        // and since we used those as values, we already have them.
        Promise.resolve(
          initialValue.map((name) => ({
            value: name,
            label: name,
          }))
        ),
    },
    {
      placeholder: <Trans>All batches</Trans>,
    }
  ),
  reportFilterMenu(
    'readings_file__is_data_logger',
    <Trans>Data logger</Trans>,
    { isMulti: false, valueType: 'string' },
    ReportFilterMenu.PASS_MENU_THROUGH,
    {
      placeholder: <Trans>All batches</Trans>,
      options: [
        {
          label: <Trans>Yes</Trans>,
          value: 'true',
        },
        {
          label: <Trans>No</Trans>,
          value: 'false',
        },
      ],
    }
  ),
  reportFilterMenu(
    'route_march__code',
    <Trans>Route march</Trans>,
    {
      isMulti: true,
      valueType: 'string',
    },
    (rm: { id: number; code: string }) => ({
      value: rm.code,
      label: rm.code,
    }),
    {
      placeholder: <Trans>All batches</Trans>,
    }
  ),
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    {
      isMulti: true,
      valueType: 'number',
    },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
    }
  ),
];

export function readingsBatchColumns({
  refreshList,
}: {
  refreshList: () => void;
}): ReportColumn<Model.ReportsReadingsBatch>[] {
  return [
    {
      label: <Trans>Batch number</Trans>,
      name: 'batch-number',
      backendFieldName: 'batch_number',
      visibility: DEFAULT_SHOW,
      accessor: (row) => {
        return (
          <HasPermission
            check="can_view_readings"
            noPermissionMsg={row.batch_number}
          >
            <DMSLink to={`/check-readings/${row.batch_number}`}>
              {row.batch_number}
            </DMSLink>
          </HasPermission>
        );
      },
    },
    {
      label: <Trans>Status</Trans>,
      name: 'status',
      backendFieldName: 'status',
      visibility: DEFAULT_SHOW,
      accessor: ({ status }) => (
        <TransEnum enum={'ReadingsBatch_STATUS'} value={status} />
      ),
    },
    {
      label: <Trans>Batch name</Trans>,
      name: 'batch-name',
      backendFieldName: 'name',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Upload date</Trans>,
      name: 'upload-date',
      backendFieldName: 'readings_file__upload_datetime',
      visibility: DEFAULT_HIDE,
      accessor: ({ readings_file__upload_datetime: upload_datetime }) =>
        upload_datetime && formatDatetimeForDisplay(upload_datetime),
    },
    {
      label: <Trans>Created date</Trans>,
      name: 'created-date',
      backendFieldName: 'created',
      visibility: DEFAULT_SHOW,
      accessor: ({ created }) => formatDatetimeForDisplay(created),
    },
    {
      label: <Trans>Created by</Trans>,
      name: 'created-by',
      backendFieldName: 'created_by',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Area</Trans>,
      name: 'area',
      backendFieldName:
        'observation_points__observation_point__site__area__code',
      visibility: DEFAULT_HIDE,
      accessor: ({
        observation_points__observation_point__site__area__code: areas,
      }) => areas && <Trans>{areas.join(', ')}</Trans>,
    },
    {
      label: <Trans>Uploaded by</Trans>,
      name: 'uploaded-by',
      backendFieldName: 'readings_file__user__username',
      additionalFields: ['readings_file__user__profile__name'],
      visibility: DEFAULT_HIDE,
      accessor: ({
        readings_file__user__username: username,
        readings_file__user__profile__name: fullname,
      }) =>
        username && (
          <Trans>
            {fullname} ({username})
          </Trans>
        ),
    },
    {
      label: <Trans>Data logger</Trans>,
      name: 'data-logger',
      backendFieldName: 'readings_file__is_data_logger',
      visibility: DEFAULT_SHOW,
      accessor: ({ readings_file__is_data_logger: is_data_logger }) => {
        switch (is_data_logger) {
          case true:
            return <Trans>Yes</Trans>;
          case false:
            return <Trans>No</Trans>;
          case null:
          default:
            return null;
        }
      },
    },
    {
      label: <Trans>Readings</Trans>,
      name: 'readings',
      backendFieldName: 'readings_file__readings__count',
      visibility: DEFAULT_SHOW,
      accessor: ({ readings_file__readings__count: readings_count }) => (
        <NumberFormat value={readings_count} />
      ),
    },
    {
      label: <Trans>Errors</Trans>,
      name: 'errors',
      backendFieldName: 'readings_file__readings__reading_errors__exists',
      visibility: DEFAULT_SHOW,
      accessor: ({
        readings_file__readings__reading_errors__exists: hasErrors,
      }) => (hasErrors ? <Trans>Yes</Trans> : <Trans>No</Trans>),
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['id', 'status', 'batch_number', 'readings_file'],
      accessor: (batch) => (
        <>
          {batch.status !== Enum.ReadingsBatch_STATUS.unprocessed &&
          batch.status !== Enum.ReadingsBatch_STATUS.qa_complete ? (
            <HasPermission check="can_view_readings_batch_report">
              <DMSLink to={`/closeout/${batch.batch_number}`}>
                <Icon type="icon-bell" title={t`View alarm check`} />
              </DMSLink>
            </HasPermission>
          ) : null}
          <HasPermission check="can_view_readings">
            <DMSLink to={`/check-readings/${batch.batch_number}`}>
              <Icon type="icon-view" title={t`View batch`} />
            </DMSLink>
            <DownloadBatchButton
              fileId={batch.readings_file}
              batchId={batch.id}
            />
          </HasPermission>
          <DeleteReadingsBatchButton batch={batch} onSubmit={refreshList} />
        </>
      ),
    },
  ];
}

export function ReadingsBatchListScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    readingsBatchColumns({ refreshList: EMPTY_FUNC }),
    readingsBatchFilters,
    readingsBatchDuck,
    (state) => state.readingsBatch.list
  );
  return <ReadingsBatchListView reportProps={reportState} />;
}

interface ReadingsBatchListViewProps {
  reportProps: ReportStateProps<Model.ReportsReadingsBatch>;
}
export const ReadingsBatchListView: React.FunctionComponent<ReadingsBatchListViewProps> =
  function (props) {
    const { reportProps } = props;
    const columns = readingsBatchColumns({
      refreshList: reportProps.refreshList,
    });
    const filters = readingsBatchFilters;
    return (
      <PageStandard
        name="readings-batches-report"
        header={
          reportProps.isExportMode ? (
            <Trans>Batches report</Trans>
          ) : (
            <Trans>Maintain Batches</Trans>
          )
        }
      >
        <div className="page-content-header-filters-actions">
          <ReportFiltersBlock
            filtersToShow={[
              'batch_number',
              'name',
              'readings_file__is_data_logger',
              'reading__reading_error__isnull',
            ]}
            filtersFrontend={filters}
            filtersBackend={
              reportProps.reportInfo && reportProps.reportInfo.filters
            }
            isExportMode={reportProps.isExportMode}
          />
        </div>
        <div className="filtered-table-wrapper">
          <ActionBlock className="text-right">
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
            />
            <ButtonPrint />
            <ExportReportButton
              fileNameBase={t`Batches report`}
              reportUrl="/reports/readings-batches/"
              columns={columns}
              filters={filters}
            />
            <AdvancedFiltersModalButton
              filtersBackend={reportProps.reportInfo?.filters}
              filtersFrontend={filters}
              sections={[
                {
                  name: 'batch_section',
                  filters: [
                    'batch_number',
                    'name',
                    'status',
                    'reading__reading_error__isnull',
                    'created_by',
                  ],
                },
                {
                  name: 'data_source_section',
                  filters: [
                    'readings_file__is_data_logger',
                    'data_logger',
                    'route_march__code',
                    'area',
                  ],
                },
                {
                  name: 'date_section',
                  filters: ['created'],
                },
              ]}
            />
          </ActionBlock>
          <ReportTable<Model.ReportsReadingsBatch>
            {...reportProps}
            columns={columns}
            filters={filters}
          />
        </div>
      </PageStandard>
    );
  };
