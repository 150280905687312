import { connect } from 'react-redux';
import withInit from '../../components/hoc/withinit';
import {
  StateProps,
  RouteMarchListView,
  RouteMarchListProps,
  DispatchProps,
} from './routemarchlistview';
import { ThunkDispatch } from 'redux-thunk';
import {
  fetchRouteMarchList,
  RouteMarchTableState,
} from '../../ducks/routemarch';
import {
  Pagination,
  PaginationQueryParams,
} from '../../util/backendapi/pagination';
import {
  setQueryParams,
  parseQueryParamFromRouterProps,
  setOneQueryParam,
  RCPWithQueryParams,
} from '../../util/routing';
import { fetchEntityList, EntityTypes, selectAll } from '../../ducks/entities';
import { Enum } from 'util/backendapi/models/api.interfaces';

// The URL query parameters directly consumed by this screen.
type ScreenQueryParams = {
  code?: any;
  area?: any;
} & PaginationQueryParams;
type ScreenOwnProps = RCPWithQueryParams<ScreenQueryParams>;

export function mapStateToProps(
  state: any,
  ownProps: ScreenOwnProps
): StateProps {
  const myState = state.routeMarch as RouteMarchTableState;

  // Since there aren't very many route marches, we just retrieve the full
  // list and do pagination and filtering on the client side.
  const { limit, offset } = Pagination.parseFromRouterProps(ownProps);
  const selectedAreaIds = parseQueryParamFromRouterProps(ownProps, 'area', '')
    .split(',')
    .filter((s) => s !== '');
  const selectedRouteMarchCodes = parseQueryParamFromRouterProps(
    ownProps,
    'code',
    ''
  )
    .split(',')
    .filter((s) => s !== '');

  const selectedTypesQueryParam = parseQueryParamFromRouterProps(
    ownProps,
    'type',
    null
  );

  // default to 'general' if type isn't defined
  const defaultType = Enum.RouteMarch_TYPE.general;

  const selectedTypes =
    selectedTypesQueryParam === null
      ? [defaultType]
      : selectedTypesQueryParam.split(',').filter((s) => s !== '');

  const selectedStatusesQueryParam = parseQueryParamFromRouterProps(
    ownProps,
    'status',
    null
  );
  // default to 'active' if status isn't defined
  const defaultStatus = Enum.RouteMarch_STATUS.active;

  const selectedStatuses =
    selectedStatusesQueryParam === null
      ? [defaultStatus]
      : selectedStatusesQueryParam.split(',').filter((s) => s !== '');

  let filteredRouteMarches = myState.routeMarches;
  let total = 0;
  if (filteredRouteMarches) {
    const hasCodeFilter = Boolean(selectedRouteMarchCodes.length);
    const hasAreaFilter = Boolean(selectedAreaIds.length);
    const hasStatusFilter = Boolean(selectedStatuses.length);
    const hasTypeFilter = Boolean(selectedTypes.length);
    if (hasCodeFilter || hasAreaFilter || hasStatusFilter || hasTypeFilter) {
      const areaIdsAsNumbers = selectedAreaIds.map((areaId) => Number(areaId));
      filteredRouteMarches = filteredRouteMarches.filter(
        (r) =>
          (!hasCodeFilter || selectedRouteMarchCodes.includes(r.code)) &&
          (!hasAreaFilter ||
            areaIdsAsNumbers.some((areaId) => r.areas.includes(areaId))) &&
          (!hasTypeFilter || selectedTypes.includes(r.type)) &&
          (!hasStatusFilter || selectedStatuses.includes(r.status))
      );
    }
    total = filteredRouteMarches.length;
    filteredRouteMarches = filteredRouteMarches.slice(offset, offset + limit);
  }

  let r = {
    ...myState,
    routeMarches: myState.routeMarches,
    allAreas: selectAll(state, EntityTypes.AREA),
    pagination: Pagination.fromOffsetTotalLimit(offset, total, limit),
    filteredRouteMarches,
    selectedAreaIds,
    selectedRouteMarchCodes,
    selectedTypes,
    selectedStatuses,
  };
  return r;
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<any, any, any>,
  ownProps: ScreenOwnProps
): DispatchProps {
  return {
    onInit: function () {
      dispatch(fetchEntityList(EntityTypes.AREA));
      dispatch(fetchRouteMarchList());
    },
    onRouteMarchCodeFilterChanged: (newCodes?: string[]) =>
      setOneQueryParam(ownProps, 'code', newCodes),
    onAreaFilterChanged: (newAreaIds?: string[]) =>
      setOneQueryParam(ownProps, 'area', newAreaIds),
    onTypeFilterChanged: (newTypes?: string[]) =>
      // Still include 'type' when empty to match all types instead of just the default 'general' type
      setOneQueryParam(
        ownProps,
        'type',
        newTypes?.length !== 0 ? newTypes : ['']
      ),
    onStatusFilterChanged: (newStatuses?: string[]) =>
      // Still include 'status' when empty to match all statuses instead of just the default 'active' status
      setOneQueryParam(
        ownProps,
        'status',
        newStatuses?.length !== 0 ? newStatuses : ['']
      ),
    onClearFilters: () =>
      setQueryParams(ownProps, {
        code: null,
        area: null,
      }),
  };
}

const RouteMarchListScreen = connect<StateProps, DispatchProps, ScreenOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withInit<RouteMarchListProps>(RouteMarchListView));
export default RouteMarchListScreen;
