import React from 'react';
import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { createSelector } from 'reselect';
import { connect as redux_connect } from 'react-redux';
import { connect as formik_connect } from 'formik';
import { fetchSiteMenuOptions } from 'ducks/sitesmenu';

/**
 * A Formik component that displays a menu to select a site, filtered by an area
 * code.
 *
 * It takes a prop that tells it which area is selected, and it takes care
 * of fetching the appropriate sites for itself, as well as updating its
 * underlying Formik value as needed.
 *
 * @export
 * @class SitesMenuInner
 * @extends {React.Component}
 */
export class SitesMenuInner extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    initialSiteCode: PropTypes.string,
    selectedAreaCode: PropTypes.string,
    /**
     * From mapStateToProps
     */
    isLoading: PropTypes.bool,
    sitesForAreaCode: PropTypes.string,
    menuOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.number,
      })
    ),
    errorMessage: PropTypes.string,
    /**
     * From mapDispatchToProps
     */
    fetchSiteMenuOptions: PropTypes.func,
    /**
     * From formik
     */
    formik: PropTypes.object,
  };

  static mapStateToProps(state, ownProps) {
    return {
      errorMessage: state.sitesMenu.errorMessage,
      isLoading: state.sitesMenu.isLoading,
      sitesForAreaCode: state.sitesMenu.sitesForAreaCode,
      menuOptions:
        state.sitesMenu.sitesForAreaCode === ownProps.selectedAreaCode
          ? makeSiteOptions(state)
          : [],
    };
  }

  static mapDispatchToProps = {
    fetchSiteMenuOptions,
  };

  constructor(props) {
    super(props);

    // Special handling if the component needs to load its menu content at
    // the outset.
    this.state = {
      isInitialLoading:
        props.selectedAreaCode !== props.sitesForAreaCode ||
        (props.selectedAreaCode === props.sitesForAreaCode && props.isLoading),
    };
  }

  render() {
    const {
      name,
      selectedAreaCode,
      isLoading,
      initialSiteCode,
      sitesForAreaCode,
      menuOptions,
      fetchSiteMenuOptions,
      formik,
      ...otherProps
    } = this.props;

    let placeholderProps = {};
    if (this.props.errorMessage) {
      placeholderProps = {
        isDisabled: true,
        placeholder: <Trans>Error: {this.props.errorMessage}</Trans>,
      };
    } else if (!this.props.selectedAreaCode) {
      placeholderProps = {
        isDisabled: true,
      };
    } else if (this.state.isInitialLoading) {
      placeholderProps = {
        isDisabled: true,
        placeholder: this.props.initialSiteCode,
      };
    } else if (this.props.isLoading) {
      placeholderProps = {
        isDisabled: true,
        placeholder: <Trans>Loading...</Trans>,
      };
    }

    return (
      <SimpleSelectField
        {...otherProps}
        name={this.props.name}
        isLoading={this.state.isInitialLoading || this.props.isLoading}
        options={this.props.menuOptions}
        isMulti={false}
        {...placeholderProps}
      />
    );
  }

  componentDidMount() {
    if (this.props.selectedAreaCode !== this.props.sitesForAreaCode) {
      this.props.fetchSiteMenuOptions(this.props.selectedAreaCode);
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Detect whether the initial load has finished. This will happen if
    // we were in "initial loading" state, but now the redux data says
    // we're done loading, and the area we've loaded matches the selected
    // area.
    if (
      state.isInitialLoading &&
      props.selectedAreaCode === props.sitesForAreaCode &&
      !props.isLoading
    ) {
      return {
        isInitialLoading: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    // They've changed the area selection
    if (prevProps.selectedAreaCode !== this.props.selectedAreaCode) {
      // Clear our menu selection
      if (this.props.formik.values[this.props.name] !== '') {
        this.props.formik.setFieldValue(this.props.name, '');
      }

      if (this.props.selectedAreaCode !== this.props.sitesForAreaCode) {
        this.props.fetchSiteMenuOptions(this.props.selectedAreaCode);
      }

      // Clear the initial loading flag, if it's still set.
      if (this.state.isInitialLoading) {
        this.setState({ isInitialLoading: false });
      }
    }
  }
}

export const SitesMenuAutoLoading = redux_connect(
  SitesMenuInner.mapStateToProps,
  SitesMenuInner.mapDispatchToProps
)(formik_connect(SitesMenuInner));

/**
 * A memoized selector function to assemble the site data into the form
 * needed for the menu.
 */
const makeSiteOptions = createSelector(
  (state) => state.sitesMenu.menuOptions,
  (state) => state.sitesMenu.isLoading,
  (state) => state.sitesMenu.errorMessage,
  function (siteOptions, isLoading, errorMessage) {
    if (isLoading || errorMessage) {
      return [];
    }

    const fullSiteOptions = [
      {
        value: undefined,
        label: <Trans>Please select a site</Trans>,
      },
      ...siteOptions.map((site) => ({
        value: site.id,
        label: site.code,
      })),
    ];

    return fullSiteOptions;
  }
);
