import React from 'react';
import fromPairs from 'lodash/fromPairs';
import ModalContent from '../../../base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Route } from 'react-router';
import { setQueryParams, QueryParams } from 'util/routing';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { ReportFilter } from '../report-types';
import ButtonShowModal, {
  defaultModalClassName,
  ModalContentProps,
  ButtonShowModalProps,
} from 'components/base/modal/buttonshowmodal';
import { getDisplayableFilters } from '../report-utils';
import './AdvancedFiltersModal.scss';
import { assign } from 'lodash';

export interface ReportAdvancedFiltersSection {
  name: string;
  filters: string[];
}

interface Props {
  filtersBackend?: Model.ReportFilterInfo[];
  filtersFrontend: ReportFilter[];
  sections: ReportAdvancedFiltersSection[];
  onFiltersChange?: (newQueryParams: QueryParams) => QueryParams;
}

/**
 * A smart component for displaying the "advanced filters" modal for a Report API
 * table.
 *
 * It does these things:
 * - Renders a form, in a ModalContent body, with the requested filter controls
 * - Automatically populates the filters' default values to match any filter
 * params in the frontend URL
 * - If the form is submitted, updates the frontend URL to match the values
 * selected in the form
 * - If the form is cancelled, does not change the frontend URL at all.
 *
 * @param props.filtersFrontend Frontend information about the filters defined
 * for this screen. (This can include more filters than the ones we'll actually
 * display, because this list is generally shared with a ReportFilterBlock
 * instance and others.)
 * @param props.filtersBackend Metadata about the filters from the backend
 * reports API. (Note this is not sufficient data to actually render the filters,
 * which is why we need additional "filtersFrontend" configuration.)
 * @param props.sections A list of filters to display, divided up into sections.
 * (Each section is rendered as a FormCardSection.)
 */
export const AdvancedFiltersModalContent: React.FunctionComponent<
  Props & ModalContentProps
> = function (props) {
  const filtersToShow = props.sections.flatMap((section) => section.filters);
  return (
    <ModalContent header={<Trans>Advanced filters</Trans>}>
      <Route
        render={(routeProps) => {
          const filtersToRender = getDisplayableFilters(
            routeProps,
            filtersToShow,
            props.filtersFrontend,
            props.filtersBackend
          );
          const initialValues = fromPairs(
            filtersToRender.map(({ filterName, value }) => [
              filterName,
              value ?? '',
            ])
          );

          return (
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              onSubmit={function (values) {
                // On submitting the form, update all the filters in the URL.
                let queryParams: QueryParams = assign(
                  {},
                  ...filtersToRender.map((f) =>
                    f.frontend?.getUrlParamFromFormVal(values)
                  )
                );
                if (props.onFiltersChange) {
                  queryParams = props.onFiltersChange(queryParams);
                }
                setQueryParams(routeProps, queryParams);
                props.hideModal();
              }}
            >
              {() => (
                <Form>
                  <div id="report-filter-modal" className="report-filter-modal">
                    {props.sections.map((section) => (
                      // section
                      <fieldset key={section.name}>
                        {section.filters.map((filterName) => {
                          const filter = filtersToRender.find(
                            (f) => f.filterName === filterName
                          );
                          const label =
                            filter && filter.frontend
                              ? filter.frontend.label
                              : filterName;
                          const content =
                            filter && filter.frontend
                              ? filter.frontend.render(filter.backend)
                              : `[${filterName}] not defined on frontend`;
                          // field
                          return (
                            <div className="form-group" key={filterName}>
                              <label
                                htmlFor={filterName}
                                id={`report-filter-modal-${filterName}`}
                              >
                                {label}
                              </label>
                              <div className="form-group-content">
                                {content}
                              </div>
                            </div>
                          );
                        })}
                      </fieldset>
                    ))}
                  </div>
                  <ActionBlock>
                    <ButtonHideModal />
                    <ButtonPrimary type="submit" iconType="icon-update">
                      <Trans>Apply filters</Trans>
                    </ButtonPrimary>
                  </ActionBlock>
                </Form>
              )}
            </Formik>
          );
        }}
      />
    </ModalContent>
  );
};

/**
 * A button to display the Advanced Filters modal, with standard label and
 * passing through of modelContentProps.
 *
 * @param props
 */
export const AdvancedFiltersModalButton: React.FunctionComponent<
  Props & Partial<ButtonShowModalProps>
> = (props) => {
  const {
    filtersBackend,
    filtersFrontend,
    sections,
    onFiltersChange,
    ...buttonProps
  } = props;

  return (
    <ButtonShowModal
      name="advanced-filters-show-button"
      iconType={'icon-filter'}
      modalContent={(modalContentProps) => (
        <AdvancedFiltersModalContent
          {...modalContentProps}
          {...{
            filtersBackend,
            filtersFrontend,
            sections,
            onFiltersChange,
          }}
        />
      )}
      modalProps={{ className: defaultModalClassName + ' panel-large' }}
      {...buttonProps}
    >
      <Trans>Advanced filters</Trans>
    </ButtonShowModal>
  );
};
