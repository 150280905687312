import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Formik, Form, Field, FormikErrors } from 'formik';
import ModalContent from 'components/base/modal/modalcontent';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import {
  fetchEntityList,
  EntityTypes,
  selectAllInOrderedArray,
} from 'ducks/entities';
import { FullState } from 'main/reducers';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { useShallowEqualSelector, useIsMounted } from 'util/hooks';
import { createPlotSet } from 'ducks/plotset/detail';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { getExpectedFields } from 'util/backendapi/error';
import { patchApi } from 'util/backendapi/fetch';
import { useGetApi } from 'hooks/use-get-api';
import Loading from 'components/base/loading/loading';
import { enhanceWithBackUrl } from 'components/base/link/DMSLink';
import { YesNoToggleField } from 'components/base/form/toggle-field/ToggleField';

export const EditPlotSetModal = withRouter(
  (
    props: RouteComponentProps & {
      onAfterSubmit: () => void;
      plotSetId?: number;
    }
  ) => {
    const isMounted = useIsMounted();
    const dispatch = useDispatch();

    const { areaOptions } = useShallowEqualSelector((state: FullState) => ({
      areaOptions: selectAllInOrderedArray(state, EntityTypes.AREA).map(
        (area) => ({
          value: area.id,
          label: `${area.code} - ${area.name}`,
        })
      ),
    }));

    useEffect(() => {
      dispatch(fetchEntityList(EntityTypes.AREA));
    }, [dispatch]);

    const onSubmit = useCallback(
      async (values: SavePlotFormValues) => {
        if (props.plotSetId) {
          await patchApi(`/plot-sets/${props.plotSetId}/`, {
            name: values.name,
            description: values.description,
            areas: values.areas,
            pre_cached: values.pre_cached,
          });
        } else {
          const plotSet = await dispatch(
            createPlotSet({
              name: values.name,
              description: values.description,
              stored_plots: [],
              areas: values.areas,
              pre_cached: values.pre_cached,
            })
          );
          props.history.push(
            enhanceWithBackUrl(`/plot-sets/${plotSet.name}`, props.location)
          );
        }
        if (isMounted()) {
          props.onAfterSubmit();
        }
      },
      [dispatch, isMounted, props]
    );

    return (
      <EditPlotSetModalView
        plotSetId={props.plotSetId}
        areaOptions={areaOptions}
        onSubmit={onSubmit}
      />
    );
  }
);

export interface SavePlotFormValues {
  name: string;
  description: string;
  areas: number[];
  pre_cached: boolean;
}

const validate = (values: SavePlotFormValues) => {
  const errors: FormikErrors<SavePlotFormValues> = {};
  if (!values.name) {
    errors.name = (<Trans>Name is required.</Trans>) as any;
  }
  if (!values.areas.length) {
    errors.areas = (<Trans>Area is required.</Trans>) as any;
  }

  return errors;
};

interface ViewProps {
  areaOptions: SimpleSelectOption<number>[];
  plotSetId: number | undefined;
  onSubmit: (values: SavePlotFormValues) => Promise<any>;
}

export const EditPlotSetModalView = ({
  onSubmit,
  areaOptions,
  plotSetId,
}: ViewProps) => {
  const [plotSetDetail] = useGetApi(
    plotSetId ? `/plot-sets/${plotSetId}/` : null
  );

  const initialValues: SavePlotFormValues =
    plotSetId && plotSetDetail.data
      ? {
          name: plotSetDetail.data.name,
          description: plotSetDetail.data.description ?? '',
          areas: plotSetDetail.data.areas.map((a) => a.id),
          pre_cached: plotSetDetail.data.pre_cached,
        }
      : {
          name: '',
          description: '',
          areas: [],
          pre_cached: false,
        };

  return (
    <ModalContent
      header={
        plotSetId ? (
          <Trans>Edit plot set</Trans>
        ) : (
          <Trans>Create a plot set</Trans>
        )
      }
    >
      {plotSetId && !plotSetDetail.data ? (
        <Loading />
      ) : (
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={async (values, formik) => {
            try {
              await onSubmit(values);
            } catch (e) {
              showErrorsInFormik(
                formik,
                e,
                getExpectedFields(values),
                ErrorNotice
              );
            }
          }}
        >
          {(formik) => (
            <Form>
              <FormSection>
                <FormItem
                  label={<Trans>Plot set name</Trans>}
                  fieldId="edit-plot-set-name"
                >
                  <Field id="edit-plot-set-name" name="name" type="text" />
                  <FieldError name="name" />
                </FormItem>
                <FormItem
                  label={<Trans>Description</Trans>}
                  fieldId="edit-plot-set-description"
                >
                  <Field
                    id="edit-plot-set-description"
                    name="description"
                    component="textarea"
                  />
                  <FieldError name="description" />
                </FormItem>
                <FormItem label={<Trans>Area</Trans>}>
                  <SimpleSelectField
                    id="edit-plot-set-area"
                    name="areas"
                    isLoading={!areaOptions.length}
                    options={areaOptions}
                    isMulti={true}
                  />
                  <FieldError name="areas" />
                </FormItem>
                <FormItem label={<Trans>Cached</Trans>}>
                  <YesNoToggleField
                    id="edit-plot-set-pre-cached"
                    name="pre_cached"
                  />
                </FormItem>
              </FormSection>
              <ActionBlock>
                <ButtonHideModal id="edit-plot-set-cancel" />
                <ButtonPrimary
                  id="edit-plot-set-submit"
                  type="submit"
                  iconType="icon-save"
                >
                  {plotSetId ? <Trans>Save</Trans> : <Trans>Create</Trans>}
                </ButtonPrimary>
              </ActionBlock>
              {formik.status}
            </Form>
          )}
        </Formik>
      )}
    </ModalContent>
  );
};
