import React, { useMemo, useCallback } from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, FormikErrors, Form } from 'formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi, postApi } from 'util/backendapi/fetch';
import { ButtonPrimary } from 'components/base/button/button';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from 'components/modules/async-menu/ObsPointMenu';
import { ReadingProcessingRequest_REASON } from 'util/backendapi/types/Enum';

type obsPointOption = SimpleSelectOption<number> & {
  timeZone: string;
  area: number;
};

type FormValues = Omit<
  Merge<
    Model.ReadingProcessingRequest_POST,
    {
      observationPointDetails: obsPointOption[];
    }
  >,
  'area' | 'reason'
>;

function validate(values: FormValues): FormikErrors<FormValues> {
  const errors: FormikErrors<FormValues> = {};
  if (values.observation_points.length === 0) {
    errors.observation_points = (
      <Trans>Observation point is required.</Trans>
    ) as any;
  }
  if (!values.from_datetime) {
    errors.from_datetime = (
      <Trans>From date and time is required</Trans>
    ) as any;
  }
  if (!values.to_datetime) {
    errors.to_datetime = (<Trans>To date and time is required</Trans>) as any;
  }
  if (
    values.from_datetime &&
    values.to_datetime &&
    values.from_datetime > values.to_datetime
  ) {
    errors.from_datetime = (
      <Trans>From date and time cannot be later than To date and time</Trans>
    ) as any;
  }

  return errors;
}

interface Props {
  hideModal: () => void;
  onAfterSubmit: () => void;
}

export function CreateReprocessingRequestModal(props: Props) {
  const isMounted = useIsMounted();
  const { hideModal, onAfterSubmit } = props;

  const initialValues: FormValues = useMemo(
    () => ({
      observation_points: [],
      observationPointDetails: [],
      from_datetime: '',
      to_datetime: '',
    }),
    []
  );
  const onSearchObsPoints = useCallback(async (searchText: string) => {
    const obsPoints = await getApi('/observation-points/', {
      code__icontains: searchText,
      fields: ['id', 'code', 'time_zone', 'area'],
      limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
    });
    return obsPoints.map((op) => ({
      value: op.id,
      label: op.code,
      timeZone: op.time_zone.name,
      area: op.area.id,
    }));
  }, []);
  const loadDefaultsObsPoints = useCallback(async (values: number[]) => {
    const obsPoints = await getApi('/observation-points/', {
      id__in: values,
      fields: ['id', 'code', 'time_zone', 'area'],
    });
    return obsPoints.map((op) => ({
      value: op.id,
      label: op.code,
      timeZone: op.time_zone.name,
      area: op.area.id,
    }));
  }, []);

  return (
    <ModalContent header={<Trans>Create reprocessing request</Trans>}>
      <Formik<FormValues>
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, formik) => {
          try {
            await postApi('/reading-processing-requests/', {
              area: values.observationPointDetails[0].area,
              observation_points: values.observation_points,
              from_datetime: values.from_datetime,
              to_datetime: values.to_datetime,
              reason: ReadingProcessingRequest_REASON.manually_requested,
            });
            if (!isMounted()) {
              return;
            }
            onAfterSubmit();
            hideModal();
          } catch (e) {
            if (!isMounted()) {
              return;
            }
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, getExpectedFields(initialValues));
          }
        }}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <FormSection>
              <FormItem
                label={<Trans>Observation points</Trans>}
                fieldId="observation-points"
              >
                <AsyncSimpleSelectField<number, true, obsPointOption>
                  name="observation_points"
                  detailsName="observationPointDetails"
                  isMulti
                  placeholder={<Trans>Select an observation point...</Trans>}
                  autoFocus={true}
                  onSearch={onSearchObsPoints}
                  loadDefaults={loadDefaultsObsPoints}
                />
                <FieldError name="observation_points" />
              </FormItem>
              <FormItem label={<Trans>From date</Trans>} fieldId="from-date">
                <DatetimeField
                  name="from_datetime"
                  id="from-date"
                  timeZone={formik.values.observationPointDetails[0]?.timeZone}
                  requireAllFields={true}
                />
                <FieldError name="from_datetime" />
              </FormItem>
              <FormItem label={<Trans>To date</Trans>} fieldId="to-date">
                <DatetimeField
                  name="to_datetime"
                  id="to-date"
                  timeZone={formik.values.observationPointDetails[0]?.timeZone}
                  requireAllFields={true}
                />
                <FieldError name="to_datetime" />
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                type="submit"
                data-testid="reprocessing-request-submit-btn"
                disabled={formik.isSubmitting}
              >
                <Trans>Create</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
