import React from 'react';
import { Field, FieldProps } from 'formik';
import {
  parseIntervalFromString,
  formatIntervalForComputer,
} from '../../../../util/dates';
import { I18n } from '@lingui/react';
import { transEnum } from 'util/i18n-utils';
import { validateNumber } from 'util/validation';
import { IntervalUnit, INTERVAL_UNITS } from 'util/backendapi/types/Enum';

export type IntervalFieldValue = string | null;

type OwnProps = {
  name: string;
  id?: string;
  disabled?: boolean;
  defaultUnit?: IntervalUnit;
};

type Props = OwnProps & FieldProps;

interface State {
  intervalNumber: string;
  intervalUnit: IntervalUnit;
}

const menuOptions: {
  value: IntervalUnit;
  msgId: string;
}[] = INTERVAL_UNITS.map((intervalUnit) => ({
  value: intervalUnit,
  msgId: transEnum('interval.type', intervalUnit),
}));

const DEFAULT_UNIT: IntervalUnit = 'days';

/**
 * A custom component designed to be used with Formik's <Input> component.
 *
 * This is made to be used as a <Field> within <Formik>. It takes and returns
 * a form value of type null | string, where the string is an ISO duration string.
 *
 * @example See `intervalfield.stories.js`
 * @export
 * @class IntervalField
 * @extends {React.Component}
 */
class IntervalFieldInner extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    const { defaultUnit } = this.props;
    const { intervalNumber, intervalUnit } = parseIntervalFromString(
      this.props.field.value as IntervalFieldValue
    );

    this.state = {
      intervalNumber: intervalNumber === null ? '' : intervalNumber.toString(),
      intervalUnit: intervalUnit || defaultUnit || DEFAULT_UNIT,
    };
  }

  handleIntervalNumberChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    this.setState(
      { intervalNumber: event.target.value },
      this._updateFormikValues
    );
  };

  handleIntervalUnitChange: React.ChangeEventHandler<HTMLSelectElement> = (
    event
  ) => {
    this.setState(
      { intervalUnit: (event.target.value as IntervalUnit) || DEFAULT_UNIT },
      this._updateFormikValues
    );
  };

  handleBlur = () => {
    this.props.form.setFieldTouched(this.props.field.name);
  };

  _updateFormikValues = () => {
    const { intervalNumber, intervalUnit } = this.state;

    const newValue: IntervalFieldValue =
      validateNumber(intervalNumber) && Number(intervalNumber) >= 0
        ? formatIntervalForComputer(Number(intervalNumber), intervalUnit)
        : null;
    this.props.form.setFieldValue(this.props.field.name, newValue);
    this.props.form.setFieldTouched(this.props.field.name, true);
  };

  render() {
    const { field } = this.props;
    return (
      <>
        <input
          id={`${this.props.id || field.name}-interval-number`}
          type="text"
          name={`${field.name}-number`}
          onChange={this.handleIntervalNumberChange}
          onBlur={this.handleBlur}
          value={this.state.intervalNumber}
          disabled={Boolean(this.props.disabled)}
        />
        <I18n>
          {({ i18n }) => (
            <select
              id={`${this.props.id || field.name}-interval-unit`}
              name={`${field.name}-unit`}
              onChange={this.handleIntervalUnitChange}
              value={this.state.intervalUnit || ''}
              disabled={Boolean(this.props.disabled)}
            >
              {menuOptions.map(({ value, msgId }) => (
                <option key={value} value={value}>
                  {i18n._(msgId)}
                </option>
              ))}
            </select>
          )}
        </I18n>
      </>
    );
  }
}

export function IntervalField(props: OwnProps) {
  return <Field component={IntervalFieldInner} {...props} />;
}
IntervalField.WrappedComponent = IntervalFieldInner;
