import { useRef, useEffect, useCallback, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import {
  RouteComponentProps,
  useLocation,
  useHistory,
  useRouteMatch,
  RouteProps,
} from 'react-router';

/**
 * A React Hook that provides a callback "isMounted" function to check whether
 * your component is still mounted.
 *
 * This is useful for situations where you have an asynchronous callback function
 * that should be "cancelled" if the component unmounts before it's done.
 *
 * For example, an async function that submits a request to update a record,
 * and then sends a request to refresh the data in a table. If it takes several
 * seconds to update the record, and the user clicks away to another screen
 * in the meantime, then there is no need to follow it up by refreshing the
 * table.
 *
 * @example
 * export function Foo(props) {
 *   const isMounted = useIsMounted();
 *   return <button onClick={async() => {
 *
 *     // This might take a while
 *     await doSomethingSlow();
 *
 *     // After the `await` finishes, the component may have unmounted due
 *     // to the user clicking away to another screen, closing a modal, etc.
 *     if (!isMounted()) {
 *       // If this component is no longer mounted, don't carry out any further
 *       // steps.
 *       return;
 *     }
 *
 *     doSomethingAsFollowUp();
 *   }}>Do a thing</button>;
 * }
 */
export function useIsMounted(): () => boolean {
  const isMounted = useRef<boolean>(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const callBack = useCallback(() => isMounted.current, [isMounted]);

  return callBack;
}

export function useShallowEqualSelector<TState, TSelected>(
  selector: (state: TState) => TSelected
) {
  return useSelector(selector, shallowEqual);
}

/**
 * A custom hook that uses the useRef hook internally for storing the previous value
 * @see https://usehooks.com/usePrevious/
 */
export function usePrevious<T>(value: T) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

/**
 * Simulate React Router's old-fashioned RouteComponentProps, using hooks.
 *
 * @param path
 */
export function useRouteComponentProps(
  path: string | string[] | RouteProps = '/'
): RouteComponentProps {
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch(path);
  return useMemo(
    () => ({ location, history, match: match! }),
    [history, location, match]
  );
}
