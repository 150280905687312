import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import PageStandard from '../../components/modules/pagestandard/pagestandard';

class BackendStatusView extends Component {
  render() {
    return (
      <PageStandard
        name="backendstatus_screen"
        header={<Trans>DMS status dashboard</Trans>}
      >
        <div id="backend_basic_endpoint_status">
          <Trans>Backend Basic endpoint</Trans>
          <div>
            {this.props.isFetching ? (
              <Trans>loading...</Trans>
            ) : (
              this.props.status
            )}
          </div>
        </div>
      </PageStandard>
    );
  }
}

BackendStatusView.propTypes = {
  isFetching: PropTypes.bool,
  status: PropTypes.string,
};

export default BackendStatusView;
