import React from 'react';
import Button from 'components/base/button/button';
import { StoredTimeSeriesPlotWithArea } from './stored-plot/detail';
import { useDispatch, useSelector } from 'react-redux';
import { setStoredPlotZoom } from './plot/scatter-time-series';
import { Trans } from '@lingui/macro';
import { FullState } from 'main/reducers';
import { buildStoredPlotKey } from 'components/plots/timeseriesplot.types';

export function StoredPlotZoomResetButton(props: {
  storedPlot: StoredTimeSeriesPlotWithArea;
}) {
  const { storedPlot } = props;

  const dispatch = useDispatch();

  const isZoomed = useSelector((state: FullState) =>
    storedPlot.items.some((storedPlotItem) =>
      Boolean(
        storedPlot &&
          state.plot.scatterTimeSeries.plots[
            buildStoredPlotKey(storedPlot.id, storedPlotItem.id)
          ]?.zoom
      )
    )
  );

  if (!isZoomed || !storedPlot) {
    return null;
  }

  return (
    <Button
      onClick={() =>
        storedPlot.items.forEach((storedPlotItem) =>
          dispatch(setStoredPlotZoom(storedPlot.id, storedPlotItem.id, null))
        )
      }
    >
      <Trans>Reset</Trans>
    </Button>
  );
}
