import React from 'react';
import { formatDatetimeForDisplay } from 'util/dates';
import { Trans } from '@lingui/macro';
import { Field, FieldProps } from 'formik';
import { ReportFilter } from '../../report-types';
import { parseQueryParamFromRouterProps } from 'util/routing';
import { NO_TIMEZONE, ReportDateFilterField } from './FilterDate';
import './reportFilterActiveAt.scss';

export function reportFilterActiveAt(
  filterName: string,
  label: React.ReactNode,
  timeZone?: string | typeof NO_TIMEZONE
): ReportFilter {
  // Apply no timezone conversion to the user's input, because the backend will
  // use it for a multi-timezone filter (called `DamTimeFilter` on the backend)
  if (timeZone === NO_TIMEZONE) {
    // ... to avoid any timezone conversion, we pretend the datetime is already in UTC.
    timeZone = 'Etc/UTC';
  }

  return {
    name: filterName,
    label,
    render: () => (
      <Field name={filterName}>
        {({
          field,
          form,
        }: FieldProps<{ range: string; type: string; now: boolean }>) => (
          <>
            <div className="check-group-wrapper">
              <div className="check-group">
                <input
                  type="checkbox"
                  id="data-logger-filter-date-today"
                  name="data-logger-filter-date-today"
                  checked={field.value.now}
                  onChange={() => {
                    form.setFieldValue(field.name, {
                      type: field.value.type,
                      range: field.value.range,
                      now: !field.value.now,
                    });
                  }}
                />
                <label htmlFor="data-logger-filter-date-today">Today</label>
              </div>
            </div>
            <div
              className={
                field.value.now
                  ? 'disabled-date-filter-wrapper'
                  : 'date-filter-wrapper'
              }
            >
              <ReportDateFilterField
                name={filterName}
                types={['is', 'is_before', 'is_after']}
                handleFocus={() => {
                  // Clicking the select drop down or the date field
                  // should automatically un-tick 'Today'
                  if (field.value.now) {
                    form.setFieldValue(field.name, {
                      type: field.value.type,
                      range: field.value.range,
                      now: false,
                    });
                  }
                }}
              />
            </div>
          </>
        )}
      </Field>
    ),
    renderSSR: (info) => {
      if (info.value.now) {
        return <Trans>Today</Trans>;
      }
      return formatDatetimeForDisplay(info.value.range, timeZone);
    },
    getFormValFromUrlParam: (routeProps) => {
      const nowQueryParam = parseQueryParamFromRouterProps(routeProps, 'now');
      const now = nowQueryParam === '' ? true : nowQueryParam === 'true';

      const date = parseQueryParamFromRouterProps(routeProps, filterName);
      const type = parseQueryParamFromRouterProps(
        routeProps,
        `${filterName}_type`
      );

      return {
        now,
        type: type || 'is',
        range: date,
      };
    },
    getUrlParamFromFormVal: (values) => {
      const fieldValue = values[filterName];

      if (fieldValue) {
        const typeFieldName = `${filterName}_type`;
        return {
          now: fieldValue.now || 'false',
          [typeFieldName]: fieldValue.type,
          [filterName]: fieldValue.range,
        };
      }

      return { now: true, [filterName]: '' };
    },
    getBackendFilterFromUrl: (routeProps, isForExportUrl) => {
      // NOTE: getBackendFilterFromUrl() formatting is currently report specific
      // due to slightly different backend requirements on different reports
      // such as different backend field names and datetime formats.
      return null;
    },
  };
}
