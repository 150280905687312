import React from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Field, FormikErrors } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { patchApi } from 'util/backendapi/fetch';

interface FormValues {
  password: string;
  confirmPassword: string;
}

export function PasswordChangeModal(props: { hideModal: () => void }) {
  return (
    <ModalContent header={<Trans>Change your password</Trans>}>
      <Trans>
        <p>
          Your new password must be at least 12 characters long.{' '}
          <em>
            <strong>Tip</strong>: Use a sentence as your password.
          </em>
        </p>
        <p>
          If you use the DMS Data Collection App, changing this password will
          also update the password you use to log into the app.
        </p>
      </Trans>
      <Formik<FormValues>
        initialValues={{ password: '', confirmPassword: '' }}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};
          if (values.password.length < 12) {
            errors.password = (
              <Trans>Password must be at least 12 characters long.</Trans>
            ) as any;
          } else if (values.password !== values.confirmPassword) {
            errors.confirmPassword = (
              <Trans>Passwords do not match.</Trans>
            ) as any;
          }
          return errors;
        }}
        onSubmit={async (values, formik) => {
          try {
            await patchApi('/users/current/', { password: values.password });
            props.hideModal();
          } catch (e) {
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, ['password', 'confirmPassword']);
          }
        }}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {(formik) => (
          <form
            onSubmit={(e) => {
              // Prevent this form submission from bubbling up and triggering
              // submission of the surrounding user profile form.
              e?.stopPropagation?.();
              formik.handleSubmit(e);
            }}
            onReset={formik.handleReset}
          >
            {formik.status}
            <FormSection>
              <FormItem
                label={<Trans>New password</Trans>}
                fieldId="newPassword"
              >
                <Field
                  id="newPassword"
                  name="password"
                  type="password"
                  autoComplete="new-password"
                />
                <FieldError name="password" />
              </FormItem>
              <FormItem
                label={<Trans>Confirm new password</Trans>}
                fieldId="confirmPassword"
              >
                <Field
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  autoComplete="new-password"
                />
                <FieldError name="confirmPassword" />
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                data-testid="changepassword-submit"
                type="submit"
                iconType="icon-save"
              >
                <Trans>Change password</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </form>
        )}
      </Formik>
    </ModalContent>
  );
}
