import React from 'react';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { Formik, Form, Field, FormikErrors, FormikHelpers } from 'formik';
import {
  FormSection,
  FormItem,
  FormMultiItem,
} from 'components/base/form/FormItem';
import { Trans } from '@lingui/macro';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import {
  DateField,
  DateFieldValue,
} from 'components/base/form/datefield/datefield';
import { YesNoRadioField } from 'components/base/form/yesnoradio/yesnoradiofield';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrimary } from 'components/base/button/button';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import {
  convertDateToDatetime,
  convertDatetimeToDate,
  formatIntervalForComputer,
  parseIntervalFromString,
  MID_DAY,
  START_OF_DAY,
  END_OF_DAY,
} from 'util/dates';
import { TransEnum } from 'components/base/i18n/TransEnum';
import ModalContent from 'components/base/modal/modalcontent';
import { useIsMounted } from 'util/hooks';
import { getExpectedFields } from 'util/backendapi/error';

// We'll combine all the fields from all the stored list types, in our form.
type MyFormValues = Merge<
  Omit<Model.NearestReadingStoredList, 'type'> &
    Omit<Model.LatestReadingStoredList, 'type'> &
    Omit<Model.SelectedListStoredList, 'type'> &
    Omit<Model.SummaryReportStoredList, 'type'>,
  {
    id?: any;
    type: Enum.StoredList_TYPE;
    tolerance: number | '';
    target_datetime: DateFieldValue;
  }
>;

interface Props {
  storedList: Model.PolymorphicStoredList | null;
  areaMenuItems: Array<
    SimpleSelectOption<number> & { timeZone: Model.TimeZone }
  >;
  onSubmit: (value: Model.PolymorphicStoredList_POST) => Promise<any>;
  hideModal: ModalContentProps['hideModal'];
}

const defaultValues: Readonly<MyFormValues> = {
  area: 0,
  description: '',
  end_datetime: null,
  list_comments: false,
  name: '',
  number_of_readings: 0,
  one_per_day: false,
  start_datetime: null,
  target_datetime: '',
  tolerance: '',
  type: '' as any,
};

export const StoredListEditModal: React.FunctionComponent<Props> = function (
  props
) {
  const isMounted = useIsMounted();

  const initialValues: MyFormValues =
    React.useMemo<MyFormValues>((): MyFormValues => {
      let values: MyFormValues = defaultValues;
      if (props.storedList) {
        const { id, ...storedListWithoutID } = props.storedList;
        switch (storedListWithoutID.type) {
          case Enum.StoredList_TYPE.nearest_reading: {
            const initialArea = props.areaMenuItems.find(
              (area) =>
                area.value === (props.storedList as Model.StoredList).area
            );
            const { tolerance, target_datetime } = storedListWithoutID;
            values = {
              ...defaultValues,
              ...storedListWithoutID,
              // Convert tolerance from an interval to an integer
              tolerance: Math.round(
                parseIntervalFromString(tolerance, 'days').intervalNumber || 0
              ),
              // Convert target datetime to a date (in area timezone)
              target_datetime: convertDatetimeToDate(
                target_datetime,
                initialArea ? initialArea.timeZone.name : null
              ),
            };
            break;
          }
          case Enum.StoredList_TYPE.selected_list: {
            const initialArea = props.areaMenuItems.find(
              (area) =>
                area.value === (props.storedList as Model.StoredList).area
            );
            const { start_datetime, end_datetime } = storedListWithoutID;
            values = {
              ...defaultValues,
              ...storedListWithoutID,
              // Convert datetimes to dates (in area timezone)
              start_datetime: start_datetime
                ? convertDatetimeToDate(
                    start_datetime,
                    initialArea?.timeZone.name ?? null
                  )
                : '',
              end_datetime: end_datetime
                ? convertDatetimeToDate(
                    end_datetime,
                    initialArea?.timeZone.name ?? null
                  )
                : '',
            };
            break;
          }
          default:
            values = {
              ...defaultValues,
              ...storedListWithoutID,
            };
            break;
        }
      }
      return values;
    }, [props.storedList, props.areaMenuItems]);

  const storedListTypeMenuItems: SimpleSelectOption<string>[] = React.useMemo(
    () =>
      menuItemsFromEnum('StoredList_TYPE', Object.values(Enum.StoredList_TYPE)),
    []
  );

  const handleSubmit = React.useCallback(
    async function (
      values: MyFormValues,
      formikActions: FormikHelpers<MyFormValues>
    ) {
      const shared: Merge<Model.StoredList, { id?: never; type: any }> = {
        name: values.name,
        area: values.area,
        description: values.description,
        type: values.type,
      };
      let newValue: Model.PolymorphicStoredList_POST;
      switch (values.type) {
        case Enum.StoredList_TYPE.latest_reading: {
          const latestReadingValue: ForPostOrPut<Model.LatestReadingStoredList> =
            {
              ...shared,
              // TODO: A future story will expose this in the create/edit form.
              // For now we just need to provide a default value when creating,
              // or echo back the current value when editing.
              list_comments: values.list_comments,
            };
          newValue = latestReadingValue;
          break;
        }
        case Enum.StoredList_TYPE.nearest_reading: {
          const area = props.areaMenuItems.find(
            (area) => area.value === values.area
          );
          const nearestReadingValue: ForPostOrPut<Model.NearestReadingStoredList> =
            {
              ...shared,
              // Convert tolerance to an interval
              tolerance: formatIntervalForComputer(+values.tolerance, 'days')!,
              // Expand target datetime from date to full datetime,
              // at noon, in timezone of selected area
              target_datetime: convertDateToDatetime(
                values.target_datetime || '',
                area ? area.timeZone.name : null,
                MID_DAY
              ),
              list_comments: values.list_comments,
            };
          newValue = nearestReadingValue;
          break;
        }
        case Enum.StoredList_TYPE.selected_list: {
          const timeZone =
            props.areaMenuItems.find((area) => area.value === values.area)
              ?.timeZone.name ?? null;
          const selectedListValue: ForPostOrPut<Model.SelectedListStoredList> =
            {
              ...shared,
              start_datetime: values.start_datetime
                ? convertDateToDatetime(
                    values.start_datetime,
                    timeZone,
                    START_OF_DAY
                  )
                : null,
              end_datetime: values.end_datetime
                ? convertDateToDatetime(
                    values.end_datetime,
                    timeZone,
                    END_OF_DAY
                  )
                : null,
              number_of_readings: values.number_of_readings,
              one_per_day: values.one_per_day,
            };
          newValue = selectedListValue;
          break;
        }
        case Enum.StoredList_TYPE.summary_report: {
          const summaryReportValue: ForPostOrPut<Model.SummaryReportStoredList> =
            shared;
          newValue = summaryReportValue;
          break;
        }
        default:
          throw new Error('Invalid stored list type');
      }
      try {
        await props.onSubmit.call(null, newValue);
        // Success! close the modal.
        if (!isMounted()) {
          return;
        }
        props.hideModal.call(null);
      } catch (e) {
        if (!isMounted()) {
          return;
        }
        formikActions.setSubmitting(false);
        showErrorsInFormik(formikActions, e, getExpectedFields(values));
      }
    },
    [isMounted, props.areaMenuItems, props.hideModal, props.onSubmit]
  );

  return (
    <ModalContent
      header={
        props.storedList ? (
          <Trans>Edit stored list</Trans>
        ) : (
          <Trans>Create stored list</Trans>
        )
      }
    >
      <Formik<MyFormValues>
        initialValues={initialValues}
        validate={handleValidate}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <FormSection>
              <FormItem
                label={<Trans>List name</Trans>}
                fieldId="edit-stored-list-name"
              >
                <Field
                  id="edit-stored-list-name"
                  name="name"
                  type="text"
                  required={true}
                  autoComplete={'on'}
                  spellCheck={false}
                  disabled={formik.isSubmitting}
                />
                <FieldError name="name" />
              </FormItem>
              <FormItem
                label={<Trans>Description</Trans>}
                fieldId="edit-stored-list-description"
              >
                <Field
                  id="edit-stored-list-description"
                  name="description"
                  type="text"
                  autoComplete="on"
                  spellCheck={true}
                  disabled={formik.isSubmitting}
                />
                <FieldError name="description" />
              </FormItem>
              <FormItem
                label={<Trans>Area</Trans>}
                fieldId="edit-stored-list-area"
              >
                <SimpleSelectField
                  name="area"
                  id="edit-stored-list-area"
                  placeholder={<Trans>Select an area</Trans>}
                  options={props.areaMenuItems}
                  isDisabled={formik.isSubmitting}
                />
                <FieldError name="area" />
              </FormItem>
              <FormItem
                label={<Trans>List type</Trans>}
                fieldId="edit-stored-list-type"
              >
                {props.storedList ? (
                  <strong>
                    <TransEnum
                      enum="StoredList_TYPE"
                      value={props.storedList.type}
                    />
                  </strong>
                ) : (
                  <>
                    <SimpleSelectField
                      name="type"
                      id="edit-stored-list-type"
                      placeholder={<Trans>Select a stored list type</Trans>}
                      options={storedListTypeMenuItems}
                      isDisabled={formik.isSubmitting}
                    />
                    <FieldError name="type" />
                  </>
                )}
              </FormItem>
              {formik.values.type === Enum.StoredList_TYPE.nearest_reading && (
                <>
                  <FormItem
                    label={<Trans>Nearest reading to</Trans>}
                    fieldId="edit-stored-list-target-datetime"
                  >
                    <DateField
                      id="edit-stored-list-target-datetime"
                      name="target_datetime"
                      disabled={formik.isSubmitting}
                    />
                    <FieldError name="target_datetime" />
                  </FormItem>
                  <FormItem
                    label={<Trans>Tolerance (days)</Trans>}
                    fieldId="edit-stored-list-tolerance"
                  >
                    <Field
                      id="edit-stored-list-tolerance"
                      name="tolerance"
                      type="number"
                      required={true}
                      disabled={formik.isSubmitting}
                    />
                    <FieldError name="tolerance" />
                  </FormItem>
                </>
              )}
              {formik.values.type === Enum.StoredList_TYPE.selected_list && (
                <>
                  <FormItem
                    label={<Trans>Start date</Trans>}
                    fieldId="edit-stored-list-start-datetime"
                  >
                    <DateField
                      id="edit-stored-list-start-datetime"
                      name="start_datetime"
                      disabled={formik.isSubmitting}
                    />
                    <FieldError name="start_datetime" />
                  </FormItem>
                  <FormItem
                    label={<Trans>End date</Trans>}
                    fieldId="edit-stored-list-end-datetime"
                  >
                    <DateField
                      id="edit-stored-list-end-datetime"
                      name="end_datetime"
                      disabled={formik.isSubmitting}
                    />
                    <FieldError name="end_datetime" />
                  </FormItem>
                </>
              )}
            </FormSection>
            {[
              Enum.StoredList_TYPE.latest_reading,
              Enum.StoredList_TYPE.nearest_reading,
            ].includes(formik.values.type) && (
              <>
                <FormMultiItem
                  label={<Trans>Include reading comments</Trans>}
                  id="edit-stored-list-include-comments"
                  className="radio-fieldset-toggle"
                >
                  <YesNoRadioField
                    name="list_comments"
                    disabled={formik.isSubmitting}
                  />
                  <FieldError name="list_comments" />
                </FormMultiItem>
              </>
            )}
            <ActionBlock>
              <ButtonHideModal id="edit-stored-list-cancel" />
              <ButtonPrimary
                id="edit-stored-list-submit"
                type="submit"
                iconType="icon-save"
              >
                {props.storedList ? <Trans>Save</Trans> : <Trans>Create</Trans>}
              </ButtonPrimary>
            </ActionBlock>
            {formik.status}
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

function handleValidate(values: MyFormValues): FormikErrors<MyFormValues> {
  const errors: FormikErrors<MyFormValues> = {};
  if (values.name === '') {
    errors.name = (<Trans>List name is required</Trans>) as any;
  }
  if (!values.type) {
    errors.type = (<Trans>List type is required</Trans>) as any;
  }
  if (!values.area) {
    errors.area = (<Trans>Area is required</Trans>) as any;
  }
  if (values.type === Enum.StoredList_TYPE.nearest_reading) {
    if (values.target_datetime === '') {
      errors.target_datetime = (
        <Trans>"Nearest reading to" is required</Trans>
      ) as any;
    }
    if (values.tolerance === '') {
      errors.tolerance = (<Trans>Tolerance (days) is required</Trans>) as any;
    } else if (Number.isNaN(+values.tolerance)) {
      errors.tolerance = (
        <Trans>Tolerance (days) must be an integer number</Trans>
      ) as any;
    }
    if (values.list_comments !== false && values.list_comments !== true) {
      errors.list_comments = (
        <Trans>"Include reading comments" is required</Trans>
      ) as any;
    }
  }
  if (values.type === Enum.StoredList_TYPE.selected_list) {
    if (
      values.start_datetime &&
      values.end_datetime &&
      values.start_datetime > values.end_datetime
    ) {
      errors.start_datetime = (
        <Trans>Start date can not be later than end date</Trans>
      ) as any;
    }
  }
  return errors;
}
