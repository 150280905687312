import { DuckActions, StandardThunk } from 'main/store';
import { BulkCreateErrorDetail } from 'util/backendapi/types/Model';

export interface BulkCreateState {
  errorDetail: BulkCreateErrorDetail | null;
}

export const ActionTypes = {
  BULK_CREATE_ERROR: 'dms/bulk-create/BULK_CREATE_ERROR',
  BULK_CREATE_CLEAR: 'dms/bulk-create/BULK_CREATE_CLEAR',
} as const;

const ActionCreators = {
  BULK_CREATE_ERROR: (errorDetail: BulkCreateErrorDetail) => {
    return {
      type: ActionTypes.BULK_CREATE_ERROR,
      errorDetail,
    };
  },
  BULK_CREATE_CLEAR: () => ({ type: ActionTypes.BULK_CREATE_CLEAR }),
};

export type BulkCreateAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

function bulkCreateErrorsInitialState(): BulkCreateState {
  return {
    errorDetail: null,
  };
}

export function bulkCreateErrorsReducer(
  state = bulkCreateErrorsInitialState(),
  action: BulkCreateAction
): BulkCreateState {
  switch (action.type) {
    case ActionTypes.BULK_CREATE_ERROR:
      return {
        ...state,
        errorDetail: action.errorDetail,
      };
    case ActionTypes.BULK_CREATE_CLEAR:
      return bulkCreateErrorsInitialState();
    default:
      return state;
  }
}

export function bulkCreateError(
  errorDetail: BulkCreateErrorDetail
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.BULK_CREATE_ERROR(errorDetail));
  };
}

export const clearBulkCreateErrors = ActionCreators.BULK_CREATE_CLEAR;
