import React, { useMemo, useCallback, useState, useEffect } from 'react';
import throttle from 'lodash/throttle';
import {
  XYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  XAxis,
  YAxis,
  LineSeries,
  MarkSeries,
  Borders,
  HighlightArea,
} from 'react-vis';
import {
  matchReadingsByTime,
  calculateDomainsWithPadding,
  calculateScatterPlotAnnotations,
  DataPoint,
  calculateAlarmThresholdLine,
} from './scatterplotselectors';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { AlertInfo, AlertWarning } from 'components/base/alert/alert';
import { Trans } from '@lingui/macro';
import {
  ScatterPlotSettings,
  PlotSettingsReadingSeries,
  TSPlotReading,
} from './timeseriesplot.types';
import useResizeObserver from 'hooks/use-resize-observer';
import { SeriesLegendText } from './SeriesLegendText';
import {
  getColorForIdx,
  getMarkerForIdx,
  GRID_LINES_STYLE,
} from './timeseriesplot';
import { CustomSVGSeries } from './react-vis-hacks';
import { StoredPlotAnnotationLabelPosition } from 'util/backendapi/types/Enum';
import { SquareHighlight } from './SquareHighlight';
import { detectExportMode } from 'util/export';
import CrosshairWithReticle from './crosshairwithreticle';
import { Popup } from './popups/Popup';
import { ScatterPlotReadingInfo } from './popups/ScatterPlotReadingInfo';
import orderBy from 'lodash/orderBy';
import { TransEnum } from 'components/base/i18n/TransEnum';
import sortBy from 'lodash/sortBy';
import { LabelSeries } from 'components/plots/react-vis-hacks';
import { convertDatetimeToDate, formatDateForDisplay } from 'util/dates';

const axisStyle = {
  line: {
    stroke: '#cfcfcf',
  },
};
const tickSize = {
  tickSizeOuter: 0,
  tickSizeInner: 5,
};

const CROSSHAIR_ICON = '\uE952';
const DEFAULT_SERIES_COLOR = getColorForIdx(0);
const MARK_SERIES_DOT_SIZE = 2;
const getColorForHighlightPeriod = (idx: number) => getColorForIdx(idx + 1);

export interface ScatterPlotReadingSeries {
  observationPoint: Model.ObservationPointDecorated;
  readings: TSPlotReading[];
  settings: PlotSettingsReadingSeries;
}

export interface ScatterPlotProps {
  showMarkConnections: boolean;
  showPlotMarks: boolean;
  interpolate: boolean;
  highlightPeriods: Model.StoredPlotItemHighlightPeriod[];
  timeZone: string | null;
  annotations?: Model.StoredPlotAnnotation[];
  x: ScatterPlotReadingSeries | null;
  y: ScatterPlotReadingSeries | null;
  xObservationPointCode: string;
  yObservationPointCode: string;
  relativeAlarmParameters?: Model.ReportsAlarmParameter[];
  axes: ScatterPlotSettings['axes'];
  initialWidth?: number;
  isSSR?: boolean;
  onZoom?: (area: HighlightArea | null) => void;
}

export const ScatterPlot = (props: ScatterPlotProps) => {
  const {
    interpolate,
    showMarkConnections,
    showPlotMarks,
    x,
    y,
    xObservationPointCode,
    yObservationPointCode,
    axes,
    initialWidth,
    isSSR,
    highlightPeriods,
    relativeAlarmParameters,
    timeZone,
  } = props;

  const [isBrushing, setIsBrushing] = useState<boolean>(false);
  const [isHoverable, setIsHoverable] = useState(true);
  const [hoverPoint, setHoverPoint] = useState<null | DataPoint>(null);
  const [selectedPoint, setSelectedPoint] = useState<null | DataPoint>(null);

  const handleNearestXY = useMemo(
    // Throttle the crosshair to update no more than 12 times per second.
    // This doesn't make much of a difference when there are few data points,
    // but it helps prevent the browser from freezing when there are thousands.
    // TODO: Patch React-Vis to memoize more of its "onXY" calculations?
    // Currently it generates a new d3 voronoi on each call to the onmouseover.
    () =>
      throttle((hoverPoint: DataPoint | null) => {
        setHoverPoint(hoverPoint);
      }, 1000 / 12),
    []
  );
  const handleMouseLeave = useCallback(() => {
    if (!selectedPoint) {
      handleNearestXY(null);
    }
  }, [handleNearestXY, selectedPoint]);

  useEffect(
    () => () => {
      handleNearestXY.cancel();
    },
    [handleNearestXY]
  );

  const handleClosePopup = useCallback(() => {
    setSelectedPoint(null);
    setTimeout(() => {
      setIsHoverable(true);
    }, 100);
  }, []);

  const data = useMemo(
    () =>
      matchReadingsByTime(
        x ? x.readings : null,
        y ? y.readings : null,
        interpolate
      ),
    [interpolate, x, y]
  );

  const {
    highlightPeriodsData,
    highlightPeriodsMarkers,
    highlightPeriodsLegend,
    defaultPlotMarkers,
  } = useMemo(() => {
    const sortedHighlightPeriods = sortBy(
      highlightPeriods || [],
      'start_datetime'
    );

    const highlightTimePeriods = sortedHighlightPeriods.map(
      ({ start_datetime, end_datetime }, idx) => {
        const start_time = new Date(start_datetime).valueOf();

        let end_time = start_time;

        if (end_datetime) {
          end_time = new Date(end_datetime).valueOf();
          // If end_datetime is null use the start_datetime of the next highlight period if there is one
        } else if (idx < sortedHighlightPeriods.length - 1) {
          end_time = new Date(
            sortedHighlightPeriods[idx + 1].start_datetime
          ).valueOf();
          // Otherwise use the end of the time of the data
        } else if (data.length > 0) {
          end_time = data[data.length - 1].time;
        }

        return { start_time, end_time };
      }
    );

    const highlightPeriodsLegend = highlightTimePeriods.map((period, idx) => ({
      start_date: formatDateForDisplay(
        convertDatetimeToDate(new Date(period.start_time), timeZone)
      ),
      end_date: formatDateForDisplay(
        convertDatetimeToDate(new Date(period.end_time), timeZone)
      ),
    }));

    // identify points in highlight periods
    const highlightPeriodsData = highlightTimePeriods.map(
      ({ start_time, end_time }) =>
        data.filter((item) => item.time >= start_time && item.time < end_time)
    );

    const highlightPeriodsMarkers = highlightPeriodsData.map((period, idx) => {
      const label = getMarkerForIdx(idx);
      return period.map(({ x, y }) => {
        return { x, y, label };
      });
    });

    // Any points not in any highlight periods should use the default marker
    const defaultPlotMarkers = data.filter(
      (item) =>
        !highlightTimePeriods.some(
          ({ start_time, end_time }) =>
            item.time >= start_time && item.time < end_time
        )
    );

    return {
      highlightPeriodsData,
      highlightPeriodsMarkers,
      highlightPeriodsLegend,
      defaultPlotMarkers,
    };
  }, [highlightPeriods, timeZone, data]);

  const latestDataPoint = [data[data.length - 1]];

  const { xDomain, yDomain } = useMemo(() => {
    let { xDomain, yDomain } = calculateDomainsWithPadding(data);

    const customXDomain = axes.find((axis) => axis.side === 'bottom');
    const customYDomain = axes.find((axis) => axis.side === 'left');

    if (customXDomain && customXDomain.minimum && customXDomain.maximum) {
      xDomain = [
        parseFloat(customXDomain.minimum),
        parseFloat(customXDomain.maximum),
      ];
    }

    if (customYDomain && customYDomain.minimum && customYDomain.maximum) {
      yDomain = [
        parseFloat(customYDomain.minimum),
        parseFloat(customYDomain.maximum),
      ];
    }

    return { xDomain, yDomain };
  }, [axes, data]);

  const annotations = useMemo(
    () =>
      calculateScatterPlotAnnotations({ xDomain, yDomain }, props.annotations),
    [xDomain, yDomain, props.annotations]
  );

  const getNull = useCallback((d: any) => d.y !== null, []);

  const [ref, width] = useResizeObserver<HTMLDivElement>({
    defaultWidth: initialWidth || 500,
    defaultHeight: initialWidth || 500,
  });

  const handleBrushStart = useCallback(() => {
    setIsBrushing(true);
    setHoverPoint(null);
  }, []);
  const handleBrushEnd = useCallback(
    (area: HighlightArea | null) => {
      setIsBrushing(false);
      const onZoom = props.onZoom;
      if (onZoom) {
        onZoom(area);
      }
    },
    [props.onZoom]
  );

  const {
    minimumAlarmThresholdLevel,
    minimumAlarmThresholdLine,
    maximumAlarmThresholdLevel,
    maximumAlarmThresholdLine,
  } = useMemo(() => {
    if (!xDomain || !relativeAlarmParameters) {
      return {
        minimumAlarmThresholdLevel: null,
        minimumAlarmThresholdLine: null,
        maximumAlarmThresholdLevel: null,
        maximumAlarmThresholdLine: null,
      };
    }

    // Sort alarm parameters in descending order so if there are multiple 'current' parameters of the same type
    // we display the latest
    const sortedAlarmParameters = orderBy(
      relativeAlarmParameters,
      [(param) => param.start_datetime],
      ['desc']
    );

    const minimumAlarmParameter = sortedAlarmParameters.find(
      (param) => param.type === Enum.AlarmParameter_TYPE.minimum
    );
    const maximumAlarmParameter = sortedAlarmParameters.find(
      (param) => param.type === Enum.AlarmParameter_TYPE.maximum
    );

    const minimumAlarmThresholdLine = minimumAlarmParameter
      ? calculateAlarmThresholdLine(
          xDomain,
          Number(minimumAlarmParameter.relative_comparison_factor),
          Number(minimumAlarmParameter.threshold)
        )
      : null;
    const maximumAlarmThresholdLine = maximumAlarmParameter
      ? calculateAlarmThresholdLine(
          xDomain,
          Number(maximumAlarmParameter.relative_comparison_factor),
          Number(maximumAlarmParameter.threshold)
        )
      : null;

    return {
      minimumAlarmThresholdLevel: minimumAlarmParameter?.level,
      minimumAlarmThresholdLine,
      maximumAlarmThresholdLevel: maximumAlarmParameter?.level,
      maximumAlarmThresholdLine,
    };
  }, [xDomain, relativeAlarmParameters]);

  if (!xObservationPointCode || !yObservationPointCode) {
    return null;
  }

  // TODO: The RM61381 acceptance criteria don't specify how to get the titles,
  // and the "scatter plot x" and "scatter plot y" fields currently seem to
  // be mostly empty. So for now, fall back to using "vertical plot title".
  //
  const xAxisTitle = x
    ? `${xObservationPointCode} ${
        x.observationPoint.instrument_type.default_scatter_plot_x_title ||
        x.observationPoint.instrument_type.default_vertical_plot_title
      }`
    : '';
  const yAxisTitle = y
    ? `${yObservationPointCode} ${
        y.observationPoint.instrument_type.default_scatter_plot_y_title ||
        y.observationPoint.instrument_type.default_vertical_plot_title
      }`
    : '';

  const plotHeight = width - 10;

  return (
    <>
      <div
        className="plot-area plot-area-scatter"
        ref={ref}
        onMouseLeave={handleMouseLeave}
      >
        {interpolate && !isSSR && (
          <AlertInfo className="alert-condensed">
            <Trans>Note, interpolation is enabled for this plot</Trans>
          </AlertInfo>
        )}
        {!data.length ? (
          <AlertWarning className="alert-condensed">
            <Trans>No data</Trans>
          </AlertWarning>
        ) : (
          <XYPlot
            data={data}
            xDomain={xDomain}
            yDomain={yDomain}
            // The height and width are for the entire SVG, including the ticks
            // and labels. We only need the actual plotting area to be square.
            // So, because the Y axis's tick labels need more padding then the
            // X axis's tick labels, the actual SVG is made slightly non-square.
            height={plotHeight}
            width={width}
            // Note the extra 10 pixels on the "width" prop is taken up with an
            // extra 10 pixels of "margin"
            // TODO: Sniff the size of the rendered tick labels, as we do in
            // timeseriesplot
            margin={{ left: 60, bottom: 50 }}
          >
            <HorizontalGridLines style={GRID_LINES_STYLE} />
            <VerticalGridLines style={GRID_LINES_STYLE} />
            {!showMarkConnections ? null : (
              <LineSeries
                animation={false}
                className="plot-readings-line"
                style={{ stroke: DEFAULT_SERIES_COLOR }}
                data={data}
              />
            )}
            {/* Line series for highlight periods */}
            {!showMarkConnections
              ? null
              : highlightPeriodsData.map((pData, idx) => (
                  <LineSeries
                    key={idx}
                    animation={false}
                    className="plot-readings-line plot-readings-line-highlighted"
                    style={{ stroke: getColorForHighlightPeriod(idx) }}
                    data={pData}
                  />
                ))}
            {!showPlotMarks ? null : (
              <MarkSeries
                className="plot-readings-points"
                animation={false}
                color={DEFAULT_SERIES_COLOR}
                data={defaultPlotMarkers}
                size={MARK_SERIES_DOT_SIZE}
              />
            )}
            {/* Mark series for highlight periods */}
            {!showPlotMarks
              ? null
              : highlightPeriodsMarkers.map((markers, idx) => (
                  <LabelSeries
                    data={markers}
                    key={idx}
                    labelAnchorX="middle"
                    labelAnchorY="central"
                    className="scatter-plot-highlight-marker"
                    style={{ stroke: getColorForHighlightPeriod(idx) }}
                  />
                ))}
            {/* Icon to make it easy to spot the latest reading */}
            <CustomSVGSeries<{ x: number; y: number }>
              data={latestDataPoint}
              customComponent={() => (
                <>
                  <text
                    className="scatter-plot-latest-reading-icon"
                    x={0}
                    y={0}
                    textAnchor="middle"
                    dominantBaseline="central"
                  >
                    {CROSSHAIR_ICON}
                  </text>
                </>
              )}
            />
            {/* Relative alarm thresholds */}
            {minimumAlarmThresholdLine && (
              <LineSeries
                key="alarm-threshold-minimum-line"
                className="plot-alarm-threshold-line"
                data={minimumAlarmThresholdLine}
                color="orange"
                getNull={getNull}
              />
            )}
            {maximumAlarmThresholdLine && (
              <LineSeries
                key="alarm-threshold-maximum-line"
                className="plot-alarm-threshold-line"
                data={maximumAlarmThresholdLine}
                color="red"
                getNull={getNull}
              />
            )}
            {/* Annotations */}
            {annotations && [
              <LineSeries
                key="annotation-lines-horizontal"
                className="plot-annotation-line"
                data={annotations.horizontalLines}
                color="auto"
                getNull={getNull}
              />,
              <LineSeries
                key="annotation-lines-vertical"
                className="plot-annotation-line"
                data={annotations.verticalLines}
                color="auto"
                getNull={getNull}
              />,
              <CustomSVGSeries<{
                x: number;
                y: number;
                label: string;
                position: string;
              }>
                key={`annotation-labels-horizontal`}
                data={annotations.horizontalLabels}
                customComponent={(annot) => (
                  <text
                    className="plot-label-inside annotation-label"
                    x="1"
                    y="0"
                    dominantBaseline={
                      annot.position === StoredPlotAnnotationLabelPosition.ABOVE
                        ? 'auto'
                        : 'hanging'
                    }
                    dy={
                      annot.position === StoredPlotAnnotationLabelPosition.ABOVE
                        ? '-0.3em'
                        : '0.3em'
                    }
                    textAnchor="start"
                  >
                    {annot.label}
                  </text>
                )}
              />,
              <CustomSVGSeries<{
                x: number;
                y: number;
                label: string;
                position: string;
              }>
                key={`annotation-labels-vertical`}
                data={annotations.verticalLabels}
                customComponent={(annot) => (
                  <text
                    className="plot-label-inside annotation-label"
                    x="-1"
                    y="0"
                    dx=".5em"
                    dy={
                      annot.position === StoredPlotAnnotationLabelPosition.LEFT
                        ? '-.5em'
                        : '0.5em'
                    }
                    dominantBaseline={
                      annot.position === StoredPlotAnnotationLabelPosition.LEFT
                        ? 'auto'
                        : 'hanging'
                    }
                    textAnchor="start"
                    transform="rotate(-90)"
                  >
                    {annot.label}
                  </text>
                )}
              />,
            ]}
            <Borders style={{ all: { fill: '#fff', stroke: 0 } }} />
            {/* The label for the bottom of the graph. (Placed in a separate element
          so that we can position it below the graph. This should be done with CSS,
          but IE11 doesn't support CSS transforms on SVG elements.) */}
            <XAxis
              title={xAxisTitle}
              top={width - 10}
              orientation="bottom"
              position="middle"
              hideLine
              hideTicks
            />
            {/* The label for the left edge of the plot (positioned via a separate
          element rather than CSS, as an IE11 workaround.) */}
            <YAxis
              title={yAxisTitle}
              // Line up the label flush with the left side of the SVG.
              // (It should be positioned correctly due to the left-margin we
              // put on the whole plot.)
              left={0}
              marginLeft={0}
              orientation="left"
              position="middle"
              hideLine
              hideTicks
            />
            <XAxis style={axisStyle} {...tickSize} className="xAxis" />
            <YAxis style={axisStyle} {...tickSize} className="yAxis" />
            {/*
             * The following axes acted as border for the plot
             */}
            <XAxis
              orientation="top"
              className="xAxis"
              hideTicks
              style={axisStyle}
            />
            <YAxis orientation="right" hideTicks style={axisStyle} />
            {/* Mouseover handler, to find the nearest point to the mouse, out
              of all the data series. */}
            {!isBrushing &&
              isHoverable &&
              !detectExportMode() &&
              data.length > 0 && (
                <LineSeries<DataPoint>
                  animation={false}
                  data={data}
                  className="plot-readings-invisible-line"
                  strokeWidth={0}
                  getNull={getNull}
                  onNearestXY={handleNearestXY}
                />
              )}
            {/* Vertical "hover" line */}
            {!isBrushing && hoverPoint && (
              <CrosshairWithReticle
                values={[hoverPoint]}
                showHorizontal={true}
                showVertical={true}
                onReticleClick={(point, position) => {
                  setSelectedPoint({
                    ...point,
                    position,
                  });
                  setIsHoverable(false);
                }}
              />
            )}
            {/* Data point detail popup */}
            {selectedPoint && x && y && (
              <Popup point={selectedPoint}>
                <ScatterPlotReadingInfo
                  point={selectedPoint}
                  x={x}
                  y={y}
                  onClose={handleClosePopup}
                />
              </Popup>
            )}
            {/* Drag-and-drop zoom handler */}
            {!selectedPoint && props.onZoom && (
              <SquareHighlight
                onBrushStart={handleBrushStart}
                onBrushEnd={handleBrushEnd}
                className={'plot-zoom'}
              />
            )}
          </XYPlot>
        )}
      </div>
      {x && y ? (
        <div className="plot-legend">
          {isSSR ? (
            <>
              <div>
                <SeriesLegendText minimal series={y} />
              </div>
              <div>
                <SeriesLegendText minimal series={x} />
              </div>
            </>
          ) : (
            <>
              <p>
                <SeriesLegendText series={y} />{' '}
                <strong>
                  <Trans>VS</Trans>
                </strong>{' '}
                <SeriesLegendText series={x} />
              </p>
              {maximumAlarmThresholdLevel && (
                <p>
                  <span className="scatter-plot-maximum-legend"></span>
                  <TransEnum
                    enum="AlarmParameter_LEVEL"
                    value={maximumAlarmThresholdLevel}
                  />{' '}
                  <Trans>maximum threshold</Trans>
                </p>
              )}
              {minimumAlarmThresholdLevel && (
                <p>
                  <span className="scatter-plot-minimum-legend"></span>
                  <TransEnum
                    enum="AlarmParameter_LEVEL"
                    value={minimumAlarmThresholdLevel}
                  />{' '}
                  <Trans>minimum threshold</Trans>
                </p>
              )}
            </>
          )}
        </div>
      ) : null}
      {highlightPeriodsLegend.length ? (
        // NOTE: On the PDF export the highlight period is displayed to the right of the scatter plot as a vertical list
        // where as in the browser it is displayed below the plot and wraps horizontally
        <div
          className={
            isSSR
              ? 'scatter-plot-highlight-legend-ssr'
              : 'scatter-plot-highlight-legend'
          }
          style={
            isSSR ? { left: width, top: -(plotHeight + 2 * 18) } : undefined
          }
        >
          <ol>
            {highlightPeriodsLegend.map((period, idx) => (
              <li key={idx}>
                <span
                  className="highlight-legend-marker"
                  style={{ color: getColorForIdx(idx + 1) }}
                >
                  {getMarkerForIdx(idx)}
                </span>{' '}
                <span>
                  {period.start_date} - {period.end_date}
                </span>
              </li>
            ))}
          </ol>
        </div>
      ) : null}
    </>
  );
};
