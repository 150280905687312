import React from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form, Field } from 'formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';

interface Props extends ModalContentProps {
  checksheetInstance: Model.ChecksheetInstance;
  featureIdx: number;
  onSave: (review_comment: string) => void;
}

type FormValues = {
  review_comment: string;
};

export function ReviewerCommentModal(props: Props) {
  const { checksheetInstance, featureIdx } = props;

  const feature = checksheetInstance.features[featureIdx];

  const initialValues: FormValues = {
    review_comment: '',
  };

  const header = <Trans>Reviewer comment</Trans>;

  return (
    <ModalContent header={header}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          props.onSave(values.review_comment);
          props.hideModal();
        }}
      >
        {(formik) => {
          return (
            <Form>
              {formik.status}

              <FormSection>
                <FormItem label={<Trans>Feature name</Trans>}>
                  <span>{feature.name}</span>
                </FormItem>
                <FormItem label={<Trans>Analyst comment</Trans>}>
                  <span>{feature.comment}</span>
                </FormItem>
                <FormItem label={<Trans>Reviewer comment</Trans>}>
                  <Field
                    id="edit-reviewer-comment"
                    name="review_comment"
                    component="textarea"
                  />
                  <FieldError name="review_comment" />
                </FormItem>
              </FormSection>
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          );
        }}
      </Formik>
    </ModalContent>
  );
}
