import React, { useCallback, useMemo } from 'react';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form, FormikHelpers, FormikErrors } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { patchApi } from 'util/backendapi/fetch';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { useDispatch } from 'react-redux';
import { fetchAlarmReport } from 'ducks/alarm-report/detail';
import { useIsMounted } from 'util/hooks';

interface Props {
  hideModal: () => void;
  alarmReport: Model.AlarmReportDecorated;
}

export function ChangeAlarmReportCategoryModal(props: Props) {
  const { alarmReport } = props;
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await patchApi(`/alarm-reports/${alarmReport.id}/`, {
        category: values.category!,
      });
      if (!isMounted()) {
        return;
      }
      // Don't need to "await" for this.
      dispatch(fetchAlarmReport(alarmReport.id));
    },
    [alarmReport.id, dispatch, isMounted]
  );
  return (
    <ChangeAlarmReportCategoryModalView {...props} onSubmit={handleSubmit} />
  );
}

interface FormValues {
  category: Enum.AlarmReport_CATEGORY | null;
}
interface ViewProps extends Props {
  onSubmit: (values: FormValues) => Promise<any>;
}
function validate(values: FormValues): FormikErrors<FormValues> {
  const errors: FormikErrors<FormValues> = {};
  if (!values.category) {
    errors.category = (<Trans>Category is required.</Trans>) as any;
  }
  return errors;
}
export function ChangeAlarmReportCategoryModalView(props: ViewProps) {
  const { alarmReport, onSubmit, hideModal } = props;
  const isMounted = useIsMounted();

  const initialValues: FormValues = useMemo(
    () => ({
      category: alarmReport.category,
    }),
    [alarmReport.category]
  );

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      try {
        await onSubmit(values);
        if (!isMounted()) {
          return;
        }
        hideModal();
      } catch (e) {
        if (!isMounted()) {
          return;
        }
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, Object.keys(initialValues));
      }
    },
    [hideModal, initialValues, isMounted, onSubmit]
  );

  return (
    <ModalContent header={<Trans>Change category</Trans>}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <FormSection label={<Trans>Alarm report category</Trans>}>
              <FormItem
                label={<Trans>Category</Trans>}
                fieldId="changecategory-category"
              >
                <SimpleSelectField
                  id="changecategory-category"
                  name="category"
                  placeholder={<Trans>Select a category</Trans>}
                  options={menuItemsFromEnum(
                    'AlarmReport_CATEGORY',
                    Object.values(Enum.AlarmReport_CATEGORY)
                  )}
                  autoFocus={true}
                />
                <FieldError name="category" />
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                iconType="icon-save"
                disabled={formik.isSubmitting}
                type="submit"
                data-testid="changecategory-submit"
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
