import React from 'react';
import {
  RouteMarchScheduleView,
  RouteMarchScheduleColumns,
  RouteMarchScheduleFilters,
} from './RouteMarchScheduleView';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import { routeMarchScheduleDuck } from 'ducks/routemarchschedule';
import { useReportState } from 'hooks/use-report-state';

type OwnProps = RCPWithQueryParams<Filter.ReportsRouteMarchSchedule>;

export const RouteMarchScheduleScreen = (ownProps: OwnProps) => {
  const props = useReportState(
    ownProps,
    RouteMarchScheduleColumns,
    RouteMarchScheduleFilters(),
    routeMarchScheduleDuck,
    (state: FullState) => state.routeMarchSchedule
  );

  return <RouteMarchScheduleView reportProps={props} />;
};
