import React from 'react';
import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import lodashSet from 'lodash/set';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { Trans } from '@lingui/macro';
import { FormCard } from 'components/base/card/card';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { StoredTimeSeriesPlotWithArea } from 'ducks/stored-plot/detail';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import ActionBlock from 'components/base/actionblock/actionblock';
import './StoredPlotEdit.scss';
import { StoredPlotItemSection } from './sections/StoredPlotItemSection';
import { StoredPlotLayoutSection } from './sections/StoredPlotLayoutSection';
import { getObsPointItemIdent } from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import {
  makeStoredPlotNameSectionInitialValues,
  validateStoredPlotNameSection,
  StoredPlotNameSection,
} from './sections/StoredPlotNameSection';
import {
  StoredPlotTimePeriodSection,
  makeStoredPlotTimePeriodInitialValues,
  validateStoredPlotTimePeriod,
  StoredPlotTimePeriodFormValues,
  makeStoredPlotTimePeriodBackendValues,
} from './StoredPlotTimePeriodSection';
import { StoredPlotSaveCancelButtons } from './StoredPlotSaveCancelButtons';
import {
  StoredPlotItemFormValues,
  validatePlotSection,
  NewStoredPlotDefaults,
  makeEmptyStoredPlotItem,
  annotationFormValues,
  formatAnnotationFormValuesForSubmit,
} from './stored-plot-edit-utils';
import {
  StoredPlotSaveAsValues,
  makeStoredPlotSaveAsInitialValues,
  validateStoredPlotSaveAsValues,
  getStoredPlotSaveAsSettings,
  StoredPlotSaveAsSettings,
} from './StoredPlotSaveAsModal';
import { showStoredPlotErrorsInFormik } from './StoredPlotEdit';

export type StoredTimeSeriesPlotFormValues = Merge<
  Model.StoredTimeSeriesPlot_POST,
  StoredPlotSaveAsValues &
    StoredPlotTimePeriodFormValues & {
      items: StoredPlotItemFormValues[];
    }
>;

function makeInitialValues(
  storedPlot: StoredTimeSeriesPlotWithArea | null,
  newPlotDefaults: NewStoredPlotDefaults | null
): StoredTimeSeriesPlotFormValues {
  if (storedPlot) {
    return {
      ...makeStoredPlotNameSectionInitialValues(storedPlot),
      ...makeStoredPlotTimePeriodInitialValues(storedPlot),
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.TIME_SERIES,
      plots_per_page: storedPlot.plots_per_page,
      layout: storedPlot.layout,
      items: storedPlot.items.map((plot) => {
        return {
          ...plot,
          reading_series: plot.reading_series.map((serie) => ({
            ...serie,
            opItem: getObsPointItemIdent(
              serie.observation_point,
              serie.item_number
            ),
            randomKey: String(Math.random()),
          })),
          axes: plot.axes.map((yAxis) => ({
            ...yAxis,
            mode:
              yAxis.minimum !== null || yAxis.maximum !== null
                ? 'custom'
                : 'auto',
            minimum: yAxis.minimum === null ? '' : yAxis.minimum,
            maximum: yAxis.maximum === null ? '' : yAxis.maximum,
          })),
          // NOTE: time series plot doesn't use highlight_periods
          highlight_periods: [],
          annotations: annotationFormValues(plot.annotations),
          show_highlight_periods: false,
        };
      }),
    };
  } else {
    return {
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.TIME_SERIES,
      name: newPlotDefaults?.name ?? '',
      area: 0,
      items: [makeEmptyStoredPlotItem(Enum.PlotType.TIME_SERIES)],
      layout: Enum.StoredPlot_LAYOUT.L100,
      plots_per_page: 1,
      start_datetime: '',
      end_datetime: '',
      duration: '',
    };
  }
}

function validateForm(
  values: StoredTimeSeriesPlotFormValues
): FormikErrors<StoredTimeSeriesPlotFormValues> {
  let errors: FormikErrors<StoredTimeSeriesPlotFormValues> = {
    ...validateStoredPlotNameSection(values),
    ...validateStoredPlotTimePeriod(values),
    ...validateStoredPlotSaveAsValues(values),
  };

  if (!values.layout) {
    errors.layout = (<Trans>Layout is required.</Trans>) as any;
  }
  if (!values.plots_per_page) {
    errors.plots_per_page = (
      <Trans>Plots per page must be at least 1.</Trans>
    ) as any;
  }

  values.items.forEach((plotSection, plotIdx) => {
    const plotSectionErrors = validatePlotSection(
      values.plot_type,
      plotSection
    );
    if (Object.keys(plotSectionErrors).length > 0) {
      lodashSet(errors, `items[${plotIdx}]`, plotSectionErrors);
    }
  });
  return errors;
}

export interface StoredTimeSeriesPlotFormProps {
  storedPlot: StoredTimeSeriesPlotWithArea | null;
  newPlotDefaults: NewStoredPlotDefaults | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  onSubmit: (
    values: Model.StoredTimeSeriesPlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<any>;
  onCancel: () => void;
}

export const StoredTimeSeriesPlotForm = (
  props: StoredTimeSeriesPlotFormProps
) => {
  const { storedPlot, areaOptions, newPlotDefaults } = props;

  const handleSubmit = React.useCallback(
    async function (
      values: StoredTimeSeriesPlotFormValues,
      formik: FormikHelpers<StoredTimeSeriesPlotFormValues>
    ) {
      const valuesForBackend: Model.StoredTimeSeriesPlot_POST = {
        ...values,
        ...makeStoredPlotTimePeriodBackendValues(values, areaOptions),
        items: values.items.map((plot) => ({
          ...plot,
          reading_series: plot.reading_series.map(({ opItem, ...serie }) => {
            // Can only show alarm parameters if the stored plot item
            // has a single observation point / reading series
            if (plot.reading_series.length !== 1) {
              serie.show_alarm_parameters = false;
            }
            delete serie.selectedOptions;
            return serie;
          }),
          axes: plot.axes.map((ya) => ({
            ...ya,
            minimum:
              ya.mode === 'auto' || ya.minimum === ''
                ? null
                : String(ya.minimum),
            maximum:
              ya.mode === 'auto' || ya.maximum === ''
                ? null
                : String(ya.maximum),
          })),
          annotations: formatAnnotationFormValuesForSubmit(
            values.plot_type,
            plot.annotations
          ),
        })),
      };
      try {
        await props.onSubmit.call(
          null,
          valuesForBackend,
          getStoredPlotSaveAsSettings(values)
        );
      } catch (e) {
        formik.setSubmitting(false);
        showStoredPlotErrorsInFormik(formik, e, values);
      }
    },
    [areaOptions, props.onSubmit]
  );

  return (
    <Formik<StoredTimeSeriesPlotFormValues>
      validate={validateForm}
      initialValues={makeInitialValues(storedPlot, newPlotDefaults)}
      onSubmit={handleSubmit}
    >
      {(formik) => {
        const timeZone = formik.values.area
          ? areaOptions.find((a) => a.value === formik.values.area)?.timeZone
          : undefined;
        return (
          <Form>
            {formik.status}
            <FormCard
              name={'general'}
              header={<Trans>General</Trans>}
              className="stored-plot-general-card"
              subHeader={
                <StoredPlotSaveCancelButtons {...props} formik={formik} />
              }
            >
              <StoredPlotNameSection
                areaOptions={areaOptions}
                plotType={Enum.PlotType.TIME_SERIES}
              />
              <StoredPlotTimePeriodSection />
              <StoredPlotLayoutSection formik={formik} />

              {/* effect to make sure that the `plot.axes` config is in sync with the selected sides */}
              <FormChangeEffect<StoredTimeSeriesPlotFormValues>
                onChange={({ values: prevValues }) => {
                  // TODO: this is a copy-paste from QuickPlotSettingsView
                  // Unfortunately some of the field names are different, which
                  // would make it non-trivial to dedupe.
                  formik.values.items.forEach((plot, plotIdx) => {
                    const prevPlot = prevValues.items[plotIdx];
                    if (!prevPlot) {
                      return;
                    }

                    const prevSides = uniq(
                      sortBy(prevPlot.reading_series.map((s) => s.axis_side))
                    );
                    const sides = uniq(
                      sortBy(plot.reading_series.map((s) => s.axis_side))
                    );

                    if (!isEqual(prevSides, sides)) {
                      const removals = difference(prevSides, sides);
                      const additions = difference(sides, prevSides);

                      const scaleConfigs = sortBy(
                        [
                          // Remove the form values for no-longer-needed scales.
                          ...plot.axes.filter(
                            (yAxis) => !removals.includes(yAxis.side)
                          ),
                          // Add form values for newly needed scales.
                          ...additions.map((side) => ({
                            side,
                            minimum: '',
                            maximum: '',
                            mode: 'auto',
                          })),
                        ],
                        'side'
                      );

                      formik.setFieldValue(
                        `items.${plotIdx}.axes`,
                        sortBy(scaleConfigs, 'side')
                      );
                    }
                  });
                }}
              />
            </FormCard>

            {formik.values.items.map((plot, plotIdx) => (
              <StoredPlotItemSection
                key={plotIdx}
                plotType={formik.values.plot_type}
                plot={plot}
                plotIdx={plotIdx}
                formik={formik}
                timeZone={timeZone}
              />
            ))}

            <ActionBlock className="text-right">
              <StoredPlotSaveCancelButtons {...props} formik={formik} />
            </ActionBlock>
          </Form>
        );
      }}
    </Formik>
  );
};
