import throttle from 'lodash/throttle';
import { useCallback, useMemo, useState } from 'react';

export function useSpatialPlotHover<T>() {
  const [hoverPoint, setHoverPoint] = useState<null | T>(null);
  const [isHoverable, setIsHoverable] = useState(true);
  const [selectedPoint, setSelectedPoint] = useState<null | T>(null);

  const onClosePopup = useCallback(() => {
    setSelectedPoint(null);
    setTimeout(() => {
      setIsHoverable(true);
    }, 100);
  }, []);

  const onNearestXY = useMemo(
    // Throttle the crosshair to update no more than 12 times per second.
    // This doesn't make much of a difference when there are few data points,
    // but it helps prevent the browser from freezing when there are thousands.
    // TODO: Patch React-Vis to memoize more of its "onXY" calculations?
    // Currently it generates a new d3 voronoi on each call to the onmouseover.
    () =>
      throttle((hoverPoint: T | null) => {
        setHoverPoint(hoverPoint);
      }, 1000 / 12),
    []
  );

  return {
    hoverPoint,
    isHoverable,
    setIsHoverable,
    selectedPoint,
    setSelectedPoint,
    onClosePopup,
    onNearestXY,
  };
}
