import { getApi, postApi, patchApi, deleteApi } from '../util/backendapi/fetch';
import { Model } from '../util/backendapi/models/api.interfaces';
import { t } from '@lingui/macro';
import { StandardThunk } from 'main/store';
import { reorderList, hasSameEntries } from '../util/misc';
import { errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  FETCH_ROUTE_MARCH_OBSERVATION_POINTS_START:
    'dms/routemarchobspoints/FETCH_ROUTE_MATCH_OBSERVATION_POINTS_START',
  FETCH_ROUTE_MARCH_OBSERVATION_POINTS_RESPONSE:
    'dms/routemarchobspoints/FETCH_ROUTE_MATCH_OBSERVATION_POINTS_RESPONSE',
  FETCH_ROUTE_MARCH_OBSERVATION_POINTS_ERROR:
    'dms/routemarchobspoints/FETCH_ROUTE_MATCH_OBSERVATION_POINTS_ERROR',
  ADD_ROUTE_MARCH_OBSERVATION_POINT_START:
    'dms/routemarchobspoints/ADD_ROUTE_MATCH_OBSERVATION_POINT_START',
  ADD_ROUTE_MARCH_OBSERVATION_POINT_RESPONSE:
    'dms/routemarchobspoints/ADD_ROUTE_MATCH_OBSERVATION_POINT_RESPONSE',
  ADD_ROUTE_MARCH_OBSERVATION_POINT_ERROR:
    'dms/routemarchobspoints/ADD_ROUTE_MATCH_OBSERVATION_POINT_ERROR',
  FETCH_OBSERVATION_POINTS_START:
    'dms/routemarchobspoints/FETCH_OBSERVATION_POINTS_START',
  FETCH_OBSERVATION_POINTS_RESPONSE:
    'dms/routemarchobspoints/FETCH_OBSERVATION_POINTS_RESPONSE',
  FETCH_OBSERVATION_POINTS_ERROR:
    'dms/routemarchobspoints/FETCH_OBSERVATION_POINTS_ERROR',
  FETCH_ROUTE_MARCH_START: 'dms/routemarchobspoints/FETCH_ROUTE_MARCH_START',
  FETCH_ROUTE_MARCH_RESPONSE:
    'dms/routemarchobspoints/FETCH_ROUTE_MARCH_RESPONSE',
  FETCH_ROUTE_MARCH_ERROR: 'dms/routemarchobspoints/FETCH_ROUTE_MARCH_ERROR',
  UPDATE_ROUTE_MARCH_POSITION_START:
    'dms/routemarchobspoints/UPDATE_ROUTE_MARCH_POSITION_START',
  UPDATE_ROUTE_MARCH_POSITION_RESPONSE:
    'dms/routemarchobspoints/UPDATE_ROUTE_MARCH_POSITION_RESPONSE',
  UPDATE_ROUTE_MARCH_POSITION_ERROR:
    'dms/routemarchobspoints/UPDATE_ROUTE_MARCH_POSITION_ERROR',
  REMOVE_ROUTE_MARCH_POSITION_START:
    'dms/routemarchobspoints/REMOVE_ROUTE_MARCH_POSITION_START',
  REMOVE_ROUTE_MARCH_POSITION_RESPONSE:
    'dms/routemarchobspoints/REMOVE_ROUTE_MARCH_POSITION_RESPONSE',
  REMOVE_ROUTE_MARCH_POSITION_ERROR:
    'dms/routemarchobspoints/REMOVE_ROUTE_MARCH_POSITION_ERROR',
} as const;

export function fetchRouteMarchObservationPointsStart(routeMarchCode: string) {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_OBSERVATION_POINTS_START,
    payload: { routeMarchCode },
  };
}

export function fetchRouteMarchObservationPointsError(errorMessage: string) {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_OBSERVATION_POINTS_ERROR,
    errorMessage: errorMessage,
  };
}

export function fetchRouteMarchObservationPointsResponse(
  routeMarchObservationPoints: Model.RouteMarchObservationPointDecorated[]
) {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_OBSERVATION_POINTS_RESPONSE,
    payload: { routeMarchObservationPoints },
  };
}

export function addRouteMarchObservationPointStart() {
  return {
    type: ActionTypes.ADD_ROUTE_MARCH_OBSERVATION_POINT_START,
  };
}

export function addRouteMarchObservationPointResponse(
  newRouteMarchObservationPoint: Model.RouteMarchObservationPointDecorated
) {
  return {
    type: ActionTypes.ADD_ROUTE_MARCH_OBSERVATION_POINT_RESPONSE,
    payload: newRouteMarchObservationPoint,
  };
}

export function addRouteMarchObservationPointError(errorMessage: string) {
  return {
    type: ActionTypes.ADD_ROUTE_MARCH_OBSERVATION_POINT_ERROR,
    errorMessage: errorMessage,
  };
}

export function fetchObservationPointsStart(areaIds: number[]) {
  return {
    type: ActionTypes.FETCH_OBSERVATION_POINTS_START,
    payload: { areaIds },
  };
}

export function fetchObservationPointsResponse(
  areaIds: number[],
  observationPoints: Pick<Model.ObservationPointDecorated, 'id' | 'code'>[]
) {
  return {
    type: ActionTypes.FETCH_OBSERVATION_POINTS_RESPONSE,
    payload: {
      areaIds,
      observationPoints,
    },
  };
}

export function fetchObservationPointsError(errorMessage: string) {
  return {
    type: ActionTypes.FETCH_OBSERVATION_POINTS_ERROR,
    errorMessage: errorMessage,
  };
}

export function fetchRouteMarchStart() {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_START,
  };
}

export function fetchRouteMarchResponse(routeMarch: Model.RouteMarch) {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_RESPONSE,
    payload: routeMarch,
  };
}

export function fetchRouteMarchError(errorMessage: string) {
  return {
    type: ActionTypes.FETCH_ROUTE_MARCH_ERROR,
    errorMessage: errorMessage,
  };
}

export function updateRouteMarchObservationPointPositionStart(
  existingPosition: number,
  routeMarchObservationPoint: Model.RouteMarchObservationPointDecorated
) {
  return {
    type: ActionTypes.UPDATE_ROUTE_MARCH_POSITION_START,
    payload: { routeMarchObservationPoint, existingPosition },
  };
}

export function updateRouteMarchObservationPointPositionResponse() {
  return {
    type: ActionTypes.UPDATE_ROUTE_MARCH_POSITION_RESPONSE,
  };
}

export function updateRouteMarchObservationPointPositionError(
  errorMessage: string
) {
  return {
    type: ActionTypes.UPDATE_ROUTE_MARCH_POSITION_ERROR,
    errorMessage: errorMessage,
  };
}

export function removeRouteMarchObservationPointStart(
  routeMarchObservationPointId: number
) {
  return {
    type: ActionTypes.REMOVE_ROUTE_MARCH_POSITION_START,
    payload: { routeMarchObservationPointId },
  };
}

export function removeRouteMarchObservationPointResponse() {
  return {
    type: ActionTypes.REMOVE_ROUTE_MARCH_POSITION_RESPONSE,
  };
}

export function removeRouteMarchObservationPointError(errorMessage: string) {
  return {
    type: ActionTypes.REMOVE_ROUTE_MARCH_POSITION_ERROR,
    errorMessage: errorMessage,
  };
}

export type RouteMarchObservationPointAction =
  | ReturnType<typeof fetchRouteMarchObservationPointsStart>
  | ReturnType<typeof fetchRouteMarchObservationPointsResponse>
  | ReturnType<typeof fetchRouteMarchObservationPointsError>
  | ReturnType<typeof addRouteMarchObservationPointStart>
  | ReturnType<typeof addRouteMarchObservationPointResponse>
  | ReturnType<typeof addRouteMarchObservationPointError>
  | ReturnType<typeof fetchObservationPointsStart>
  | ReturnType<typeof fetchObservationPointsResponse>
  | ReturnType<typeof fetchObservationPointsError>
  | ReturnType<typeof fetchRouteMarchStart>
  | ReturnType<typeof fetchRouteMarchResponse>
  | ReturnType<typeof fetchRouteMarchError>
  | ReturnType<typeof updateRouteMarchObservationPointPositionStart>
  | ReturnType<typeof updateRouteMarchObservationPointPositionResponse>
  | ReturnType<typeof updateRouteMarchObservationPointPositionError>
  | ReturnType<typeof removeRouteMarchObservationPointStart>
  | ReturnType<typeof removeRouteMarchObservationPointResponse>
  | ReturnType<typeof removeRouteMarchObservationPointError>;

export interface RouteMarchObservationPointListState {
  loading: boolean;
  errorMessage: string | null;
  routeMarchObservationPoints: Model.RouteMarchObservationPointDecorated[];
  routeMarchCode: string | null;
  updatingRouteMarchObservationpoints: boolean;
  observationPoints: {
    areaIds: number[];
    loading: boolean;
    errorMessage: string | null;
    observationPoints: Pick<Model.ObservationPointDecorated, 'id' | 'code'>[];
  };
  routeMarch: {
    loading: boolean;
    errorMessage: string | null;
    routeMarchData: null | Model.RouteMarch;
  };
}

export interface RouteMarchObservationPointPanelState {
  loading: boolean;
  errorMessage: string | null;
  observationPoints: Pick<Model.ObservationPointDecorated, 'id' | 'code'>[];
}

export function routeMarchObservationPointInitialState(): RouteMarchObservationPointListState {
  return {
    loading: true,
    errorMessage: null,
    routeMarchObservationPoints: [],
    routeMarchCode: null,
    updatingRouteMarchObservationpoints: false,
    observationPoints: {
      areaIds: [],
      loading: false,
      errorMessage: null,
      observationPoints: [],
    },
    routeMarch: {
      loading: false,
      errorMessage: null,
      routeMarchData: null,
    },
  };
}

export function routeMarchObservationPointReducer(
  state: RouteMarchObservationPointListState = routeMarchObservationPointInitialState(),
  action: RouteMarchObservationPointAction
): RouteMarchObservationPointListState {
  switch (action.type) {
    case ActionTypes.FETCH_ROUTE_MARCH_OBSERVATION_POINTS_START:
      return {
        ...state,
        loading: true,
        errorMessage: null,
        routeMarchObservationPoints: [],
        routeMarchCode: action.payload.routeMarchCode,
      };
    case ActionTypes.FETCH_ROUTE_MARCH_OBSERVATION_POINTS_RESPONSE:
      return {
        ...state,
        loading: false,
        errorMessage: null,
        routeMarchObservationPoints: action.payload.routeMarchObservationPoints,
      };
    case ActionTypes.FETCH_ROUTE_MARCH_OBSERVATION_POINTS_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
        routeMarchObservationPoints: [],
      };
    case ActionTypes.FETCH_ROUTE_MARCH_START:
      return {
        ...state,
        routeMarch: {
          loading: true,
          errorMessage: null,
          routeMarchData: null,
        },
      };
    case ActionTypes.FETCH_ROUTE_MARCH_RESPONSE:
      return {
        ...state,
        routeMarch: {
          loading: false,
          errorMessage: null,
          routeMarchData: action.payload,
        },
      };
    case ActionTypes.FETCH_ROUTE_MARCH_ERROR:
      return {
        ...state,
        routeMarch: {
          loading: false,
          errorMessage: action.errorMessage,
          routeMarchData: null,
        },
      };
    case ActionTypes.FETCH_OBSERVATION_POINTS_START:
      return {
        ...state,
        observationPoints: {
          ...state.observationPoints,
          areaIds: action.payload.areaIds,
          loading: true,
          observationPoints: [],
          errorMessage: null,
        },
      };
    case ActionTypes.FETCH_OBSERVATION_POINTS_RESPONSE:
      // This check prevents potential simulatenous fetch bugs by ignoring
      // any response from an area that does not match that currently in the store.
      if (
        hasSameEntries(state.observationPoints.areaIds, action.payload.areaIds)
      ) {
        return {
          ...state,
          observationPoints: {
            ...state.observationPoints,
            loading: false,
            observationPoints: action.payload.observationPoints,
          },
        };
      }
      return {
        ...state,
      };

    case ActionTypes.FETCH_OBSERVATION_POINTS_ERROR:
      return {
        ...state,
        observationPoints: {
          ...state.observationPoints,
          loading: false,
          errorMessage: action.errorMessage,
          observationPoints: [],
        },
      };
    case ActionTypes.ADD_ROUTE_MARCH_OBSERVATION_POINT_START:
      return {
        ...state,
        updatingRouteMarchObservationpoints: true,
      };
    case ActionTypes.ADD_ROUTE_MARCH_OBSERVATION_POINT_RESPONSE:
      return {
        ...state,
        updatingRouteMarchObservationpoints: false,
        // We always add new ones to the end of the list
        routeMarchObservationPoints: state.routeMarchObservationPoints.concat([
          action.payload,
        ]),
      };
    case ActionTypes.ADD_ROUTE_MARCH_OBSERVATION_POINT_ERROR:
      return {
        ...state,
        updatingRouteMarchObservationpoints: false,
        errorMessage: action.errorMessage,
      };
    case ActionTypes.UPDATE_ROUTE_MARCH_POSITION_START:
      return {
        ...state,
        updatingRouteMarchObservationpoints: true,
        routeMarchObservationPoints: reorderList(
          state.routeMarchObservationPoints,
          action.payload.existingPosition,
          action.payload.routeMarchObservationPoint
        ),
      };
    case ActionTypes.UPDATE_ROUTE_MARCH_POSITION_RESPONSE:
      return {
        ...state,
        updatingRouteMarchObservationpoints: false,
      };
    case ActionTypes.UPDATE_ROUTE_MARCH_POSITION_ERROR:
      return {
        ...state,
        updatingRouteMarchObservationpoints: false,
        errorMessage: action.errorMessage,
      };
    case ActionTypes.REMOVE_ROUTE_MARCH_POSITION_START:
      return {
        ...state,
        // When you remove an OP from a route march, the back end does not
        // re-number the other OPs.
        routeMarchObservationPoints: state.routeMarchObservationPoints.filter(
          (rmob) => rmob.id !== action.payload.routeMarchObservationPointId
        ),
        updatingRouteMarchObservationpoints: true,
      };
    case ActionTypes.REMOVE_ROUTE_MARCH_POSITION_RESPONSE:
      return {
        ...state,
        updatingRouteMarchObservationpoints: false,
      };
    case ActionTypes.REMOVE_ROUTE_MARCH_POSITION_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    default:
      return state;
  }
}

export function fetchRouteMarchObservationPoints(
  routeMarchCode: string
): StandardThunk {
  return async function (dispatch) {
    dispatch(fetchRouteMarchObservationPointsStart(routeMarchCode));
    try {
      const routeMarchObservationPoints = await getApi(
        '/route-march-observation-points/',
        { route_march__code: routeMarchCode }
      );

      return dispatch(
        fetchRouteMarchObservationPointsResponse(routeMarchObservationPoints)
      );
    } catch (e) {
      return dispatch(fetchRouteMarchObservationPointsError(e.message));
    }
  };
}

export function fetchObservationPoints(areaIds: number[]): StandardThunk {
  return async function (dispatch) {
    dispatch(fetchObservationPointsStart(areaIds));
    try {
      const observationPoints = await getApi('/observation-points/', {
        site__area__in: areaIds,
        fields: ['id', 'code'],
      });
      dispatch(fetchObservationPointsResponse(areaIds, observationPoints));
    } catch (e) {
      dispatch(fetchObservationPointsError(e.error));
    }
  };
}

export function addRouteMarchObservationPoint(
  routeMarchId: number,
  routeMarchObservationPointPosition: number,
  observationPointId: number
): StandardThunk {
  return async function (dispatch) {
    try {
      const newRouteMarchObservationPoint = await postApi(
        `/route-march-observation-points/`,
        {
          route_march: routeMarchId,
          position: routeMarchObservationPointPosition,
          observation_point: observationPointId,
        }
      );
      return dispatch(
        addRouteMarchObservationPointResponse(newRouteMarchObservationPoint)
      );
    } catch (e) {
      return dispatch(addRouteMarchObservationPointError(errorToString(e)));
    }
  };
}

export function fetchRouteMarch(routeMarchCode: string): StandardThunk {
  return async function (dispatch) {
    dispatch(fetchRouteMarchStart());
    try {
      const routeMarchResponse = await getApi('/route-marches/', {
        code: routeMarchCode,
      });
      dispatch(fetchRouteMarchResponse(routeMarchResponse[0]));
    } catch (e) {
      dispatch(fetchRouteMarchError(e.error));
    }
  };
}

export function updateRouteMarchObservationPointPosition(
  routeMarchObservationPoint: Model.RouteMarchObservationPointDecorated,
  newRouteMarchPosition: number
): StandardThunk {
  return async function (dispatch, _getState, { i18n }) {
    dispatch(
      updateRouteMarchObservationPointPositionStart(
        routeMarchObservationPoint.position,
        { ...routeMarchObservationPoint, position: newRouteMarchPosition }
      )
    );
    try {
      await patchApi(
        `/route-march-observation-points/${routeMarchObservationPoint.id}/`,
        {
          position: newRouteMarchPosition,
        }
      );
      dispatch(updateRouteMarchObservationPointPositionResponse());
    } catch (e) {
      if (!e.error) {
        dispatch(
          updateRouteMarchObservationPointPositionError(
            i18n._(t`Error updating positions`)
          )
        );
      } else {
        dispatch(updateRouteMarchObservationPointPositionError(e.error));
      }
    }
  };
}

export function removeRouteMarchObservationPoint(
  routeMarchObservationPointId: number
): StandardThunk {
  return async function (dispatch) {
    dispatch(
      removeRouteMarchObservationPointStart(routeMarchObservationPointId)
    );
    try {
      await deleteApi(
        `/route-march-observation-points/${routeMarchObservationPointId}/`,
        {}
      );
      return dispatch(removeRouteMarchObservationPointResponse());
    } catch (e) {
      return dispatch(removeRouteMarchObservationPointError(e.errorMessage));
    }
  };
}
