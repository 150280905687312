import React from 'react';
import { DateInputFieldProps, DateInputField } from './DateInputField';

// Our Datetime & Date fields return a string | false value
// - When the user has entered a valid date/datetime, it gives
//   an ISO UTC datetime or date string
// - When the user has typed in an invalid date string, it gives `false`
// - When the input is empty, it gives the empty string ''
export type DateFieldValue = string | false;
export type DatetimeFieldValue = string | false;

/**
 * Formik field for a date (without time)
 */
export type DateFieldProps = Omit<
  DateInputFieldProps,
  'timeZone' | 'isDateOnly'
>;
export function DateField(props: DateFieldProps) {
  return <DateInputField isDateOnly={true} {...props} />;
}

/**
 * Formik field for a datetime
 */
export type DatetimeFieldProps = Omit<DateInputFieldProps, 'isDateOnly'>;
export function DatetimeField(props: DatetimeFieldProps) {
  return <DateInputField isDateOnly={false} {...props} />;
}
