import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { FormikHelpers } from 'formik';
import CreateObsPointPanelView from './createobspointview';
import {
  createObservationPoint,
  openSectionForEditing,
} from 'ducks/obsPoint/detail/main';
import { FullState } from 'main/reducers';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormValues } from './createobspointview';

import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { Model } from 'util/backendapi/models/api.interfaces';
import { createStructuredSelector } from 'reselect';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';

interface OwnProps {
  hideModal: () => void;
}

interface StateProps {
  areaOptions: { value: number; label: string; code: string }[];
  instrumentTypeOptions: SimpleSelectOption<number>[];
}

interface DispatchProps {
  createObservationPoint: typeof createObservationPoint;
  openSectionForEditing: typeof openSectionForEditing;
}

type RouterProps = RouteComponentProps<{}>;
export type Props = OwnProps &
  StateProps &
  RouterProps &
  ResolveThunks<DispatchProps>;

function makeFilterOptions(
  filterInfo:
    | Model.ReportFilterInfo<Model.Area | Model.InstrumentType>
    | undefined
) {
  if (!filterInfo || !filterInfo.options) {
    return [];
  }
  const items = filterInfo.options;
  return items.map((item) => {
    return {
      value: item.id as number,
      label: `${item.code} - ${item.name}`,
      // TODO: refactor siteSelectAutoLoading to take area ID, and then
      // we won't need this code anymore. (It's currently used by the
      // "create observation point" panel.)
      code: item.code,
    };
  });
}

export const selectObsPointLandingFilterOptions = createStructuredSelector<
  FullState,
  {
    area: { value: number; label: string; code: string }[];
    instrumentType: { value: number; label: string }[];
  }
>({
  area: (state: FullState) =>
    makeFilterOptions(
      state.obsPoint.list.reportInfo?.filters.find(
        (f) => f.name === 'site__area'
      ) as Model.ReportFilterInfo<Model.Area> | undefined
    ),
  instrumentType: (state: FullState) =>
    makeFilterOptions(
      state.obsPoint.list.reportInfo?.filters.find(
        (f) => f.name === 'instrument_type'
      ) as Model.ReportFilterInfo<Model.InstrumentType> | undefined
    ),
});

function mapStateToProps(state: FullState): StateProps {
  return {
    areaOptions: selectObsPointLandingFilterOptions(state).area,
    instrumentTypeOptions:
      selectObsPointLandingFilterOptions(state).instrumentType,
  };
}

const mapDispatchToProps: DispatchProps = {
  createObservationPoint,
  openSectionForEditing,
};

class CreateObsPointPanel extends React.Component<Props> {
  handleSubmit = async (
    values: FormValues,
    formik: FormikHelpers<FormValues>
  ) => {
    try {
      await this.props.createObservationPoint(values);

      this.props.openSectionForEditing('general');
      this.props.history.push(`/observation-point-setup/${values.code}`);
    } catch (e) {
      formik.setSubmitting(false);
      showErrorsInFormik(formik, e, getExpectedFields(values));
    }
  };

  render() {
    return (
      <CreateObsPointPanelView
        areaOptions={this.props.areaOptions}
        instrumentTypeOptions={this.props.instrumentTypeOptions}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps, FullState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateObsPointPanel));
