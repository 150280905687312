import React, { useRef, useEffect, useContext } from 'react';
import ActionBlock from '../actionblock/actionblock';
import ModalContent from './modalcontent';
import Button from '../button/button';
import { Trans } from '@lingui/macro';
import ReactModal from 'react-modal';
import { PageStandardContext } from 'components/modules/pagestandard/pagestandard';

interface Props {
  name: string;
  header: React.ReactNode;
  content: React.ReactNode;
}

export const InfoModal = (props: Props) => {
  const { name, header, content } = props;

  const context = useContext(PageStandardContext);

  const thisComponent = useRef({});

  const contextRef = useRef(context);
  contextRef.current = context;

  const isThisModalVisible = context.activeModal === thisComponent.current;

  useEffect(() => {
    const thisModal = thisComponent.current;

    // Open modal initially
    contextRef.current.showModal(thisModal as any, name);

    return () => {
      if ((contextRef.current.activeModal as any) === thisModal) {
        contextRef.current.hideModal();
      }
    };
  }, [name]);

  return (
    <ReactModal
      isOpen={isThisModalVisible}
      onRequestClose={context.hideModal}
      className={'modal'}
      overlayClassName={'modal-centred'}
    >
      <ModalContent header={header}>
        {content}
        <ActionBlock className="text-right">
          <Button onClick={context.hideModal}>
            <Trans>Close</Trans>
          </Button>
        </ActionBlock>
      </ModalContent>
    </ReactModal>
  );
};
