import React, { useCallback } from 'react';
import {
  StoredPlotItemFormValues,
  StoredPlotItemSerieFormValues,
  StoredPlotItemAxisFormValues,
  StoredScatterTimeSeriesFormValues,
} from '../stored-plot-edit-utils';
import { FormikProps, FieldArrayRenderProps, getIn } from 'formik';
import { FormCard, FormCardSection } from 'components/base/card/card';
import { Trans, t } from '@lingui/macro';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import {
  ShowHideToggleField,
  showHideToggleOptions,
} from 'components/base/form/toggle-field/ToggleField';
import { getCustomScaleFieldsForAxis } from './get-custom-scale-fields';
import {
  splitObsPointItemIdent,
  ObsPointItemMenuOption,
  ObsPointItemMenu,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';

/**
 * Config for one reading series in one scatter plot
 *
 * @param props
 */
export function StoredScatterPlotSeriesSection<
  T extends StoredScatterTimeSeriesFormValues
>(props: {
  plot: StoredPlotItemFormValues;
  serie: StoredPlotItemSerieFormValues;
  axis: StoredPlotItemAxisFormValues;
  seriesFieldPrefix: string;
  axisFieldPrefix: string;
  seriesIdx: number;
  formik: FormikProps<T>;
}) {
  const { plot, serie, axis, seriesIdx, seriesFieldPrefix, axisFieldPrefix } =
    props;

  /**
   * Exclude series already selected in this graph
   */
  const filterObservationPointOptions = useCallback<
    (option: ObsPointItemMenuOption) => boolean
  >(
    (opo) =>
      !plot.reading_series.some(
        (otherSerie) => otherSerie !== serie && otherSerie.opItem === opo.value
      ),
    [plot.reading_series, serie]
  );

  return (
    <FormCard
      name={`series-${props.seriesIdx}`}
      header={
        seriesIdx === 0 ? (
          <Trans>Y axis observation point</Trans>
        ) : (
          <Trans>X axis observation point</Trans>
        )
      }
      iconType="icon-observation-point"
      className="card-observation-point-item"
      data-testid="stored-plot-item-series"
      subHeader={
        <>
          <ObsPointItemMenu
            name={`${seriesFieldPrefix}.opItem`}
            isMulti={false}
            filterOption={filterObservationPointOptions}
          />
          <FieldError name={`${seriesFieldPrefix}.opItem`} />
          <FieldError name={`${seriesFieldPrefix}.observation_point`} />
          <FieldError name={`${seriesFieldPrefix}.item_number`} />
          <FormChangeEffect<StoredScatterTimeSeriesFormValues>
            // This menu actually needs to change two things at once:
            // the observation point id and the instrument type item number.
            // We'll do that by having the menu use one joined "opItem"
            // property, and when that changes, we update the observation point
            // and item number fields.
            onChange={() => {
              const pair = splitObsPointItemIdent(serie.opItem);
              const { observation_point, item_number } = pair || {
                observation_point: 0,
                item_number: 0,
              };

              if (serie.observation_point !== observation_point) {
                props.formik.setFieldValue(
                  `${seriesFieldPrefix}.observation_point`,
                  observation_point
                );
              }
              if (serie.item_number !== item_number) {
                props.formik.setFieldValue(
                  `${seriesFieldPrefix}.item_number`,
                  item_number
                );
              }
            }}
          />
        </>
      }
    >
      <FormCardSection
        header={seriesIdx === 0 ? <Trans>Y Axis</Trans> : <Trans>X Axis</Trans>}
        name={`${seriesFieldPrefix}-axis-scale`}
        fields={getCustomScaleFieldsForAxis(
          axis,
          axisFieldPrefix,
          <Trans>Scale</Trans>
        )}
      />

      <FormCardSection
        header={<Trans>Legend</Trans>}
        name={`${seriesFieldPrefix}-legend`}
        fields={[
          {
            label: <Trans>Cap RL</Trans>,
            name: `${seriesFieldPrefix}.show_legend_cap_rl`,
            content: (
              <>
                <ShowHideToggleField
                  name={`${seriesFieldPrefix}.show_legend_cap_rl`}
                />
                <FieldError name={`${seriesFieldPrefix}.show_legend_cap_rl`} />
              </>
            ),
          },
          {
            label: <Trans>Port RL</Trans>,
            name: `${seriesFieldPrefix}.show_legend_port_rl`,
            content: (
              <>
                <ShowHideToggleField
                  name={`${seriesFieldPrefix}.show_legend_port_rl`}
                />
                <FieldError name={`${seriesFieldPrefix}.show_legend_port_rl`} />
              </>
            ),
          },
        ]}
      />
    </FormCard>
  );
}

/**
 * Config for one reading series in one time-series plot
 *
 * @param props
 */
export function StoredTimeSeriesPlotSeriesSection<
  T extends StoredScatterTimeSeriesFormValues
>(props: {
  plot: StoredPlotItemFormValues;
  serie: StoredPlotItemSerieFormValues;
  seriesFieldPrefix: string;
  seriesIdx: number;
  formik: FormikProps<T>;
  readingSeriesArrayHelpers: FieldArrayRenderProps;
}) {
  const {
    plot,
    serie,
    seriesIdx,
    seriesFieldPrefix,
    readingSeriesArrayHelpers,
  } = props;

  /**
   * Exclude series already selected in this graph
   */
  const filterObservationPointOptions = React.useCallback<
    (option: ObsPointItemMenuOption) => boolean
  >(
    (opo) =>
      !plot.reading_series.some(
        (otherSerie) => otherSerie !== serie && otherSerie.opItem === opo.value
      ),
    [plot.reading_series, serie]
  );

  const detailsName = `${seriesFieldPrefix}.selectedOptions`;
  const selectedOptions = getIn(props.formik.values, detailsName);
  const selectedObsPoint = selectedOptions?.[0];

  return (
    <Draggable
      key={serie.randomKey}
      draggableId={serie.randomKey}
      index={seriesIdx}
      isDragDisabled={plot.reading_series.length === 1}
    >
      {(provided, snapshot) => (
        <tr
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={classNames({
            draggable: plot.reading_series.length > 1,
            dragging: snapshot.isDragging,
          })}
        >
          <td>
            {/*Observation point*/}
            <ObsPointItemMenu
              menuPortalTarget={document.body}
              menuPlacement="top"
              name={`${seriesFieldPrefix}.opItem`}
              isMulti={false}
              detailsName={detailsName}
              filterOption={filterObservationPointOptions}
            />
            <FieldError name={`${seriesFieldPrefix}.opItem`} />
            <FieldError name={`${seriesFieldPrefix}.observation_point`} />
            <FieldError name={`${seriesFieldPrefix}.item_number`} />
            <FormChangeEffect<StoredScatterTimeSeriesFormValues>
              // This menu actually needs to change two things at once:
              // the observation point id and the instrument type item number.
              // We'll do that by having the menu use one joined "opItem"
              // property, and when that changes, we update the observation point
              // and item number fields.
              onChange={() => {
                const pair = splitObsPointItemIdent(serie.opItem);
                const { observation_point, item_number } = pair || {
                  observation_point: 0,
                  item_number: 0,
                };

                if (serie.observation_point !== observation_point) {
                  props.formik.setFieldValue(
                    `${seriesFieldPrefix}.observation_point`,
                    observation_point
                  );
                }
                if (serie.item_number !== item_number) {
                  props.formik.setFieldValue(
                    `${seriesFieldPrefix}.item_number`,
                    item_number
                  );
                }
              }}
            />
          </td>
          <td>
            {/*Y axis*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.axis_side`}
              options={[
                {
                  label: <Trans>Left</Trans>,
                  value: 'left',
                },
                {
                  label: <Trans>Right 1</Trans>,
                  value: 'right',
                },
                {
                  label: <Trans>Right 2</Trans>,
                  value: 'right2',
                },
              ]}
            />
            <FieldError name={`${seriesFieldPrefix}.axis_side`} />
          </td>
          <td>
            {/*Plot markers*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_plot_markers`}
              options={showHideToggleOptions}
            />
            <FieldError name={`${seriesFieldPrefix}.show_plot_markers`} />
          </td>
          <td>
            {/*Port RL*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_plot_port_rl`}
              options={showHideToggleOptions}
            />
            <FieldError name={`${seriesFieldPrefix}.show_plot_port_rl`} />
          </td>
          <td>
            {/*Analysis comments*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_analysis_comment_indicators`}
              options={showHideToggleOptions}
            />
            <FieldError
              name={`${seriesFieldPrefix}.show_analysis_comment_indicators`}
            />
          </td>
          <td>
            {/*Inspector comments*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_inspector_comment_indicators`}
              options={showHideToggleOptions}
            />
            <FieldError
              name={`${seriesFieldPrefix}.show_inspector_comment_indicators`}
            />
          </td>
          <td>
            {/*Associated media*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_media_indicators`}
              options={showHideToggleOptions}
            />
            <FieldError name={`${seriesFieldPrefix}.show_media_indicators`} />
          </td>
          <td>
            {/*Cap RL legend*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_legend_cap_rl`}
              options={showHideToggleOptions}
            />
            <FieldError name={`${seriesFieldPrefix}.show_legend_cap_rl`} />
          </td>
          <td>
            {/*Port RL legend*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_legend_port_rl`}
              options={showHideToggleOptions}
            />
            <FieldError name={`${seriesFieldPrefix}.show_legend_port_rl`} />
          </td>
          <td>
            {/*Confidence level legend*/}
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${seriesFieldPrefix}.show_confidence_level`}
              options={showHideToggleOptions}
            />
            <FieldError name={`${seriesFieldPrefix}.show_confidence_level`} />
          </td>
          <td className="action-icons">
            {/*Actions*/}
            {plot.reading_series.length > 1 && (
              <ButtonShowConfirmation
                iconOnly={true}
                iconType="icon-circle-minus"
                className="btn-link-panel"
                title={t`Delete`}
                name={`${seriesFieldPrefix}-delete`}
                onConfirm={async () => {
                  readingSeriesArrayHelpers.remove(seriesIdx);
                }}
                content={
                  selectedObsPoint ? (
                    <Trans>
                      Are you sure you want to delete{' '}
                      <strong>{selectedObsPoint?.label}</strong> from this plot?
                      This action is not reversible.
                    </Trans>
                  ) : (
                    <Trans>
                      Are you sure you want to delete this item from this plot?
                      This action is not reversible.
                    </Trans>
                  )
                }
                okBtnText={<Trans>Yes, delete</Trans>}
              />
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
}
