import React, { useContext, useRef, useEffect, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import { StoredSpatialWanderPlotWithArea } from 'ducks/stored-plot/detail';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { parseFilenameFromUrl } from 'util/backendapi/file';
import { useState, useCallback } from 'react';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { EMPTY_ARRAY, EMPTY_FUNC } from 'util/misc';
import { getApi } from 'util/backendapi/fetch';
import { NewStoredPlotDefaults } from '../stored-plot-edit-utils';
import { StoredSpatialWanderPlotFormValues } from '../StoredSpatialWanderPlotForm';
import { makeStoredPlotNameSectionInitialValues } from '../sections/StoredPlotNameSection';
import { makeStoredPlotSaveAsInitialValues } from '../StoredPlotSaveAsModal';

function makeInitialValues(
  storedPlot: StoredSpatialWanderPlotWithArea | null,
  newPlotDefaults: NewStoredPlotDefaults | null
): StoredSpatialWanderPlotFormValues {
  if (!storedPlot) {
    return {
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.SPATIAL_WANDER,
      name: newPlotDefaults?.name ?? '',
      area: 0,
      survey_points: [
        {
          site: 0,
          label: '',
          northing_observation_point: 0,
          easting_observation_point: 0,
          show_label: true,
          show_in_data_table: true,
        },
      ],
      error_ellipses:
        Enum.StoredSpatialWanderPlot_ERROR_ELLIPSE.INITIAL_AND_LATEST,
      wander_scale: '',
      time_periods: [],
      scale: '',
      base_layer_image: '',
      additional_layer_image: '',
      base_layer_image_file: null,
      additional_layer_image_file: null,
      paperspace_width: '',
      paperspace_height: '',
      base_observation_point: 0,
      base_paperspace_x: '',
      base_paperspace_y: '',
      show_data_table: true,
      data_table_paperspace_x: '',
      data_table_paperspace_y: '',
      movement_legend_paperspace_x: '',
      movement_legend_paperspace_y: '',
      show_readings_legend: false,
    };
  }

  const storedPlotValues: StoredSpatialWanderPlotFormValues = {
    ...makeStoredPlotNameSectionInitialValues(storedPlot),
    ...makeStoredPlotSaveAsInitialValues(),
    plot_type: Enum.PlotType.SPATIAL_WANDER,
    survey_points: storedPlot.survey_points,
    error_ellipses: storedPlot.error_ellipses,
    wander_scale: String(storedPlot.wander_scale),
    time_periods: storedPlot.time_periods,
    base_layer_image: parseFilenameFromUrl(storedPlot.base_layer_image),
    additional_layer_image: parseFilenameFromUrl(
      storedPlot.additional_layer_image ?? ''
    ),
    base_layer_image_file: null,
    additional_layer_image_file: null,
    scale: String(storedPlot.scale),
    movement_legend_paperspace_x: storedPlot.movement_legend_paperspace_x,
    movement_legend_paperspace_y: storedPlot.movement_legend_paperspace_y,
    paperspace_width: storedPlot.paperspace_width,
    paperspace_height: storedPlot.paperspace_height,
    base_observation_point: storedPlot.base_observation_point,
    base_paperspace_x: storedPlot.base_paperspace_x,
    base_paperspace_y: storedPlot.base_paperspace_y,
    show_data_table: storedPlot.show_data_table,
    data_table_paperspace_x: storedPlot.data_table_paperspace_x,
    data_table_paperspace_y: storedPlot.data_table_paperspace_y,
    show_readings_legend: Boolean(
      storedPlot.readings_legend_x || storedPlot.readings_legend_y
    ),
    readings_legend_x: storedPlot.readings_legend_x,
    readings_legend_y: storedPlot.readings_legend_y,
  };

  return storedPlotValues;
}

export function useWanderForm(
  storedPlot: null | StoredSpatialWanderPlotWithArea,
  newPlotDefaults: NewStoredPlotDefaults | null
) {
  const initialValues = useMemo(() => {
    return makeInitialValues(storedPlot, newPlotDefaults);
  }, [storedPlot, newPlotDefaults]);

  const [allObsPoints, setAllObsPoints] = useState<
    SimpleSelectOption<number>[]
  >([]);

  const $obsPointsBySite = useRef<Record<number, SimpleSelectOption<number>[]>>(
    {}
  );

  const loadObservationPoints = useCallback(async (siteId: number) => {
    try {
      const res = await getApi('/observation-points/', {
        site__in: [siteId],
      });

      const options = res.map((item) => ({
        value: item.id,
        label: item.code,
      }));

      $obsPointsBySite.current[siteId] = options;

      setAllObsPoints((obsPoints) =>
        uniqBy([...obsPoints, ...options], (item) => item.value)
      );
      return options;
    } catch (_e) {
      return [];
    }
  }, []);

  const getSiteObsPoints = useCallback((siteId: number) => {
    return $obsPointsBySite.current[siteId] || [];
  }, []);

  // load inital obs point for all selected sites
  useEffect(() => {
    for (const sp of initialValues.survey_points) {
      if (sp.site) {
        loadObservationPoints(sp.site);
      }
    }
  }, [initialValues, loadObservationPoints]);

  return {
    initialValues,
    allObsPoints,
    loadObservationPoints,
    getSiteObsPoints,
  };
}

export const WanderPlotFormContext = React.createContext<
  ReturnType<typeof useWanderForm>
>({
  initialValues: makeInitialValues(null, null),
  allObsPoints: EMPTY_ARRAY,
  loadObservationPoints: EMPTY_FUNC,
  getSiteObsPoints: EMPTY_FUNC,
});

export const useWanderPlotFormContext = () => {
  return useContext(WanderPlotFormContext);
};
