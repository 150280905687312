import React, { useState, useEffect, useCallback } from 'react';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { t } from '@lingui/macro';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { useGetApi } from 'hooks/use-get-api';
import { Icon } from 'components/base/icon/icon';
import { formatDatetimeForDisplay } from 'util/dates';
import { getObservationPointItemUrlCode } from 'components/plots/timeseriesplotselectors';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import { errorToString } from 'util/backendapi/error';
import { isTruthy } from 'util/validation';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import { DMSLink } from 'components/base/link/DMSLink';
import { DMSHotKey } from 'main/DMSHotKey';

// We only need to fetch these fields for each observation point.
export type MapMarkerObservationPoint = Pick<
  Model.ObservationPointDecorated,
  | 'id'
  | 'code'
  | 'instrument_type'
  | 'latest_reading'
  | 'name'
  | 'time_zone'
  | 'wgs84_coordinates'
>;

interface ObservationPointMarkerProps {
  // The obs points sent to a Marker should already have been filter to be only
  // the ones that definitely have wgs84_coordinates.
  observationPoint: MapMarkerObservationPoint & {
    wgs84_coordinates: Model.LatLong;
  };
  clusterer: Clusterer;
  spiderfier?: OverlappingMarkerSpiderfier;
}

export function ObservationPointMarker(props: ObservationPointMarkerProps) {
  const { observationPoint, clusterer, spiderfier } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleInfoWindow = useCallback(
    () => setIsOpen((isOpen) => !isOpen),
    []
  );
  const closeInfoWindow = useCallback(() => setIsOpen(false), []);

  const [{ data: latestReading, error, isLoading, isError }, setUrl] =
    useGetApi(
      // Pass an empty string as the initial URL, to indicate that we don't fetch
      // anything until the InfoWindow is opened.
      null
    );

  useEffect(() => {
    // When the info window opens, initiate a fetch of the reading
    if (isOpen && observationPoint.latest_reading) {
      // This operation is idempotent.
      setUrl(`/readings/${observationPoint.latest_reading.id}/`);
    }
  }, [isOpen, observationPoint.latest_reading, setUrl]);

  return (
    <ObservationPointMarkerView
      {...{
        toggleInfoWindow,
        closeInfoWindow,
        isOpen,
        observationPoint,
        clusterer,
        latestReading,
        error,
        isLoading,
        isError,
        spiderfier,
      }}
    />
  );
}

interface ViewProps {
  toggleInfoWindow: () => void;
  closeInfoWindow: () => void;
  isOpen: boolean;
  observationPoint: ObservationPointMarkerProps['observationPoint'];
  clusterer: Clusterer;
  latestReading: Model.Reading | null;
  isLoading: boolean;
  isError: boolean;
  error: any;
  spiderfier?: OverlappingMarkerSpiderfier;
}

function ObservationPointMarkerView(props: ViewProps) {
  const {
    observationPoint,
    toggleInfoWindow,
    closeInfoWindow,
    isOpen,
    clusterer,
    latestReading,
    isLoading,
    isError,
    error,
    spiderfier,
  } = props;
  const [marker, setMarker] = useState<google.maps.Marker>();
  const title = [observationPoint.code, observationPoint.name]
    .filter(isTruthy)
    .join(' - ');

  useEffect(() => {
    if (marker && spiderfier) {
      // Manually registering the marker with the clusterer, instead of just
      // using the `clusterer` prop on the `<Marker>` component.
      //
      // We need to do it this way to ensure it's registered with the
      // spiderfier first, and the clusterer second. (Doing it with clusterer
      // first and spiderfier second sometimes causes clustered markers to
      // become re-displayed in addition to their cluster!)
      spiderfier.addMarker(marker, undefined as any);
      clusterer.addMarker(marker, true);
    }
  }, [clusterer, marker, spiderfier]);

  return (
    <>
      <Marker
        position={observationPoint.wgs84_coordinates}
        onLoad={(marker) => {
          setMarker(marker);
          // Spiderfier hijacks the normal marker 'click' handler, and replaces
          // it with a 'spider_click' handler.
          marker.addListener('spider_click', toggleInfoWindow);
        }}
        title={title}
        label={observationPoint.instrument_type.code.slice(0, 2)}
      />
      {isOpen && marker && (
        <InfoWindow anchor={marker} onCloseClick={closeInfoWindow}>
          <div className="plot-popup-map">
            <div className="popup-header">
              <h3>{title}</h3>
              <DMSLink to={`/observation-point/${observationPoint.code}`}>
                <Icon type="icon-view" title={t`View`} />
              </DMSLink>
              <DMSHotKey shortcut="ESC" onPress={closeInfoWindow} />
            </div>
            <div className="popup-content">
              {observationPoint.instrument_type.items.map((item, itemIdx) => {
                const readingDatetime = latestReading?.reading_datetime;
                const readingValue =
                  latestReading?.adjusted_reading_entries[itemIdx]?.value;

                return (
                  <div key={item.item_number}>
                    <h4>{item.description}</h4>
                    <p>
                      <strong>{readingValue ?? '-'}</strong>{' '}
                      <span>
                        {readingDatetime
                          ? formatDatetimeForDisplay(
                              readingDatetime,
                              observationPoint.time_zone.name
                            )
                          : '-'}
                      </span>
                    </p>
                    <div className="action-icons">
                      <DMSLink
                        to={`/quickplot/${getObservationPointItemUrlCode(
                          observationPoint.code,
                          item.item_number
                        )}`}
                      >
                        <Icon type="icon-plot" title={t`Quick plot`} />
                      </DMSLink>{' '}
                      <DMSLink to={`/quicklist/${observationPoint.code}`}>
                        <Icon type="icon-list" title={t`Quick list`} />
                      </DMSLink>
                    </div>
                  </div>
                );
              })}
              {isLoading && <Loading />}
              {isError && <AlertDanger>{errorToString(error)}</AlertDanger>}
            </div>
          </div>
        </InfoWindow>
      )}
    </>
  );
}

ObservationPointMarker.WrappedComponent = ObservationPointMarkerView;
