import { rangeInclusive, seedPseudoRand } from 'util/misc';
import {
  formatDatetimeForBackendapi,
  makeMockDatetime,
  makeMockIntervalString,
} from '../../dates';
import { pickFromEnum } from '../../validation';
import { BulkCreateErrorDetail } from '../types/Model';
import { Model, Enum } from './api.interfaces';
import { mockObservationPoint } from './observationpoint';
import lodashSet from 'lodash/set';
import lodashGet from 'lodash/get';

export function mockAlarmParameter(
  seed: number,
  comparison_type = pickFromEnum(Enum.AlarmParameter_COMPARISON_TYPE, seed)
): Model.AlarmParameter {
  const isAbsolute =
    comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.absolute;
  const isRelative =
    comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.relative;
  const isVelocity =
    comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.velocity;

  const level = isAbsolute
    ? pickFromEnum(Enum.AlarmParameter_LEVEL, seed)
    : [Enum.AlarmParameter_LEVEL.alert, Enum.AlarmParameter_LEVEL.design_check][
        seed % 2
      ];

  return {
    id: seed,
    level,
    type: pickFromEnum(Enum.AlarmParameter_TYPE, seed),
    comparison_type: comparison_type,
    threshold: (seed * 10.3).toFixed(1),
    start_datetime: formatDatetimeForBackendapi(makeMockDatetime(seed)),
    end_datetime: formatDatetimeForBackendapi(
      makeMockDatetime(seed, '2011-01-01T01:01:01Z')
    ),
    item_number: 1,
    item_description: `item description ${seed * 11}`,
    last_changed: formatDatetimeForBackendapi(makeMockDatetime(seed)),
    status: pickFromEnum(Enum.AlarmParameter_STATUS, seed),
    relative_comparison_factor: isRelative ? String(seed * 100) : null,
    relative_comparison_datetime_tolerance: isRelative
      ? String(seed * 101)
      : null,
    relative_item_number: isRelative ? seed * 103 : null,
    relative_observation_point: isRelative ? 1 : null,
    observation_point: 1,
    direction_of_change: isVelocity
      ? pickFromEnum(Enum.AlarmParameter_DIRECTION_OF_CHANGE, seed)
      : null,
    time_interval: isVelocity ? makeMockIntervalString(seed) : null,
  };
}

export function mockAlarmParameterDecorated(
  seed: number
): Model.AlarmParameterDecorated {
  const alarmParam = mockAlarmParameter(seed);
  const isRelative =
    alarmParam.comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.relative;
  return {
    ...alarmParam,
    relative_item_number: isRelative ? seed * 102 : null,
    relative_observation_point:
      isRelative && alarmParam.relative_observation_point
        ? mockObservationPoint(alarmParam.relative_observation_point)
        : null,
  };
}

/**
 * A helper function to sort alarm thresholds by level (for use with
 * Array.prototype.sort())
 *
 * @param {*} a
 * @param {*} b
 * @returns
 */
export function compareAlarmParameterLevels(
  a: Enum.AlarmParameter_LEVEL,
  b: Enum.AlarmParameter_LEVEL
) {
  return LEVELS_SIZE_ORDER.indexOf(a) - LEVELS_SIZE_ORDER.indexOf(b);
}

/**
 * Alarm Levels:
 * An "alarm" is a setting that will notify the engineers if an observation
 * point's readings are beyond a certain range. Each alarm type can be configured
 * with different range values for each of three different "levels", which
 * represent differing levels of concern. Confusingly, the three alarm levels
 * can be ordered in two different ways; in order of alarm severity, and in order
 * of distance from the readings' ideal value.
 *
 * In order of distance (going outward to inward):
 * 1. Data check: The largest value. If the reading is off by this large amount,
 * then it usually means there was a data entry error.
 * 2. Alert check: Less than a data check. If the reading is off by this much,
 * it suggests there may be an actual problem.
 * 3. Design check: Less than alert check. This alarm level is used to help
 * determine the usual range of readings data, to help configure the alert check.
 *
 * In order of severity (least severe to most):
 * 1. Data check
 * 2. Design check: Requires a data check to be configured first
 * 3. Alert check: Requires a design check to be configured first
 *
 * An alarm must have each of the less-severe levels configured, before it
 * can have the more-severe ones.
 *
 * There can be a "min" and "max" for each of the alarm levels:
 * - Max data check
 * - Max alert check
 * - Max design check
 * - (Ideal Readings!)
 * - Min design check
 * - Min alert check
 * - Min data check
 */
export const LEVELS_SIZE_ORDER: Array<Enum.AlarmParameter_LEVEL> = [
  Enum.AlarmParameter_LEVEL.design_check,
  Enum.AlarmParameter_LEVEL.alert,
  Enum.AlarmParameter_LEVEL.data_check,
];

export const LEVELS_SEVERITY_ORDER: Array<Enum.AlarmParameter_LEVEL> = [
  Enum.AlarmParameter_LEVEL.data_check,
  Enum.AlarmParameter_LEVEL.design_check,
  Enum.AlarmParameter_LEVEL.alert,
];

export function mockAlarmParameterReport(
  seed: number
): Model.ReportsAlarmParameter {
  const alarm = mockAlarmParameterDecorated(seed);
  return {
    id: alarm.id,
    level: alarm.level,
    type: alarm.type,
    threshold: alarm.threshold,
    start_datetime: alarm.start_datetime,
    end_datetime: alarm.end_datetime,
    comparison_type: alarm.comparison_type,
    comment_count: 0,
    relative_comparison_factor: alarm.relative_comparison_factor,
    relative_comparison_datetime_tolerance:
      alarm.relative_comparison_datetime_tolerance,
    status: alarm.status,
    last_changed: alarm.last_changed,
    observation_point__code: `OBS_PT_${seed * 1000}`,
    item_number: alarm.item_number,
    item_description: `item description ${alarm.item_number}`,
    relative_observation_point__code:
      alarm.comparison_type === 'relative'
        ? `REL_OBSCODE_${seed * 1000 + 1}`
        : null,
    relative_item_number: alarm.relative_item_number,
    observation_point__site: seed * 10,
    observation_point__id: seed * 1000,
    observation_point__instrument_type__code: 'PZ',
    observation_point__site__area: seed * 100,
    observation_point__site__area__time_zone__name: 'Pacific/Auckland',
    observation_point__site__area__code: `AREA_${seed * 100}`,
    observation_point__site__code: `SITE_${seed * 10}`,
    direction_of_change: alarm.direction_of_change,
    time_interval: alarm.time_interval,
  };
}

export function mockAlarmParameterBulkCreateErrorDetail(
  seed: number,
  alarmParameterCount: number
): BulkCreateErrorDetail {
  const alarmParameters = rangeInclusive(1, alarmParameterCount).map(() =>
    mockAlarmParameterDecorated(seed)
  );

  const headers = {
    observation_point: 'Obs pt',
    item_number: 'Reading item',
    comparison_type: 'Comparison type',
    level: 'Alarm level',
    type: 'Max/min',
    threshold: 'Alarm parameter',
    start_datetime: 'Start',
    end_datetime: 'End',
    relative_observation_point: 'Comparison obs pt',
    relative_item_number: 'Comparison reading item',
    relative_comparison_factor: 'Comparison factor',
    relative_comparison_datetime_tolerance: 'Comparison tolerance',
    direction_of_change: 'Direction of change',
    time_interval: 'Time interval',
  };

  const fields: Array<keyof Model.AlarmParameter> = Object.keys(headers);

  const contents = alarmParameters.map((alarmParameter, i): typeof headers => {
    const observationPoint = mockObservationPoint(seed + i);

    return {
      observation_point: observationPoint.code,
      item_number: String(alarmParameter.item_number),
      comparison_type: alarmParameter.comparison_type,
      level: alarmParameter.level,
      type: alarmParameter.type,
      threshold: alarmParameter.threshold,
      start_datetime: alarmParameter.start_datetime ?? '',
      end_datetime: alarmParameter.end_datetime ?? '',
      relative_observation_point:
        alarmParameter.relative_observation_point?.code ?? '',
      relative_item_number: String(alarmParameter.relative_item_number ?? ''),
      relative_comparison_factor:
        alarmParameter.relative_comparison_factor ?? '',
      relative_comparison_datetime_tolerance:
        alarmParameter.relative_comparison_datetime_tolerance
          ? `${i + 1} hours`
          : '',
      direction_of_change: alarmParameter.direction_of_change ?? '',
      time_interval: alarmParameter.time_interval ?? '',
    };
  });

  let prng = seedPseudoRand(seed);

  const errors = contents.map((_content, rowIndex) => {
    if (rowIndex % 3 === 0) {
      let errors = {};
      fields.forEach((fieldName) => {
        if (prng() % 7 === 0) {
          const header = lodashGet(headers, fieldName);
          lodashSet(errors, fieldName, [`${header} error message`]);
        }
      });
      return errors;
    }
    return {};
  });

  const errorDetail: BulkCreateErrorDetail = {
    fields: fields,
    headers: headers,
    content: contents,
    errors: errors,
  };

  return errorDetail;
}
