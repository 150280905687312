import { StoredTimeSeriesPlotWithArea } from 'ducks/stored-plot/detail';
import { StoredPlot_LAYOUT } from 'util/backendapi/types/Enum';

export function getSlotsPercentageFromTimeSeriesLayout(
  layout: StoredTimeSeriesPlotWithArea['layout']
): number[] {
  let slots: number[] = [];
  switch (layout) {
    case StoredPlot_LAYOUT.L100:
      slots = [1];
      break;
    case StoredPlot_LAYOUT.L50_50:
      slots = [0.5, 0.5];
      break;
    case StoredPlot_LAYOUT.L60_40:
      slots = [0.6, 0.4];
      break;
    case StoredPlot_LAYOUT.L75_25:
      slots = [0.75, 0.25];
      break;
    case StoredPlot_LAYOUT.L80_20:
      slots = [0.8, 0.2];
      break;

    case StoredPlot_LAYOUT.L33_33_33:
      slots = [1 / 3, 1 / 3, 1 / 3];
      break;
    case StoredPlot_LAYOUT.L50_25_25:
      slots = [0.5, 0.25, 0.25];
      break;
    case StoredPlot_LAYOUT.L60_20_20:
      slots = [0.6, 0.2, 0.2];
      break;

    case StoredPlot_LAYOUT.L25_25_25_25:
      slots = [0.25, 0.25, 0.25, 0.25];
      break;
    case StoredPlot_LAYOUT.L40_20_20_20:
      slots = [0.4, 0.2, 0.2, 0.2];
      break;

    default:
      throw new Error('Unsupported layout!');
  }

  return slots;
}
