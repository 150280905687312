import { DuckActions, StandardThunk } from 'main/store';
import { postApiFormData } from 'util/backendapi/fetch';
import { Model } from 'util/backendapi/models/api.interfaces';
import { t } from '@lingui/macro';
import { errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  UPLOAD_READINGS_FILES_START: 'dms/file-uploader/UPLOAD_READINGS_FILES_START',
  UPLOAD_READINGS_FILES_RESPONSE:
    'dms/file-uploader/UPLOAD_READINGS_FILES_RESPONSE',
  UPLOAD_READINGS_FILES_ERROR: 'dms/file-uploader/UPLOAD_READINGS_FILES_ERROR',
  UNMOUNT_UPLOADER: 'dms/file-uploader/UNMOUNT_UPLOADER',
  USERCHANGE_UPLOADER_FILE_SELECTION:
    'dms/file-uploader/USERCHANGE_UPLOADER_FILE_SELECTION',
} as const;

export const ActionCreators = {
  UPLOAD_READINGS_FILES_START: () => ({
    type: ActionTypes.UPLOAD_READINGS_FILES_START,
  }),
  UPLOAD_READINGS_FILES_RESPONSE: () => ({
    type: ActionTypes.UPLOAD_READINGS_FILES_RESPONSE,
  }),
  UPLOAD_READINGS_FILES_ERROR: (errorMessage: string) => ({
    type: ActionTypes.UPLOAD_READINGS_FILES_ERROR,
    error: true,
    payload: errorMessage,
  }),
  UNMOUNT_UPLOADER: () => ({ type: ActionTypes.UNMOUNT_UPLOADER }),
  USERCHANGE_UPLOADER_FILE_SELECTION: () => ({
    type: ActionTypes.USERCHANGE_UPLOADER_FILE_SELECTION,
  }),
};

export const unmountUploader = ActionCreators.UNMOUNT_UPLOADER;
export const userChangeUploaderFileSelection =
  ActionCreators.USERCHANGE_UPLOADER_FILE_SELECTION;

export type FileUploaderAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface FileUploaderState {
  isUploading: boolean;
  errorMessage: string | null;
}

const fileUploadsInitialState: FileUploaderState = {
  isUploading: false,
  errorMessage: null,
};

export function fileUploadsReducer(
  state = { ...fileUploadsInitialState },
  action: FileUploaderAction
): FileUploaderState {
  switch (action.type) {
    case ActionTypes.UPLOAD_READINGS_FILES_START:
      return {
        isUploading: true,
        errorMessage: null,
      };
    case ActionTypes.UPLOAD_READINGS_FILES_RESPONSE: {
      return {
        isUploading: false,
        errorMessage: null,
      };
    }
    case ActionTypes.UPLOAD_READINGS_FILES_ERROR:
      return {
        isUploading: false,
        errorMessage: action.payload,
      };
    case ActionTypes.UNMOUNT_UPLOADER:
    case ActionTypes.USERCHANGE_UPLOADER_FILE_SELECTION:
      return {
        isUploading: false,
        errorMessage: null,
      };
    default:
      return state;
  }
}

/**
 * Uploads a file to the backend.
 *
 * @export
 * @param {File} file This is expected to be the selected file from an <input
 * type="file"> element's native FileList. See: https://developer.mozilla.org/en-US/docs/Web/API/File
 * @returns {function} A redux-thunk action
 */
export function uploadReadingsFiles(fileList: FileList): StandardThunk {
  return async function (dispatch, _getState, { i18n }) {
    dispatch(ActionCreators.UPLOAD_READINGS_FILES_START());

    const filesUploaded: Model.ReadingsFileDecorated[] = [];

    // Using a for-loop instead `.forEach()` so we can do the uploads one at
    // a time, instead of in parallel.
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      try {
        // Send the request using a FormData object in order to simulate the
        // the submission of an old-school form containing a file input.
        // The advantage of doing it this is way, is that it lets us avoid reading
        // the file into a JS variable; instead, the browser itself will take care
        // of reading the file and serializing it into a request body.
        const formData = new FormData();
        formData.append('content', file);

        // Upload the file
        const readingsFile = await postApiFormData(
          '/readings-files/',
          formData
        );
        filesUploaded.push(readingsFile);
      } catch (e) {
        const filename = file.name;
        return dispatch(
          ActionCreators.UPLOAD_READINGS_FILES_ERROR(
            i18n._(t`Error uploading "${filename}": ${errorToString(e)}`)
          )
        );
      }

      dispatch(ActionCreators.UPLOAD_READINGS_FILES_RESPONSE());
    }
  };
}
