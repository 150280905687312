import React from 'react';
import { Trans, t } from '@lingui/macro';
import { AlertDanger } from 'components/base/alert/alert';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import ButtonShowPanel from 'components/base/panel/buttonshowpanel';
import PanelContent from 'components/base/panel/panelcontent';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import sortBy from 'lodash/sortBy';
import Loading from 'components/base/loading/loading';
import MaintainGroupModal from './maintgroupmodal';
import { MaintainGroupUsersPanel } from './maintgroupuserspanel';
import { Model } from 'util/backendapi/models/api.interfaces';
import ActionBlock from 'components/base/actionblock/actionblock';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';

const GroupPanelContent = (props: {
  areas: Model.AreaGroupDecorated['areas'];
  groupName: string;
}) => (
  <PanelContent header={<Trans>Areas in the {props.groupName} group</Trans>}>
    <ul className="area-list">
      {sortBy(props.areas, 'code').map((area) => {
        return (
          <li key={area.id}>
            <strong>{area.code}</strong> <span>{area.name}</span>
          </li>
        );
      })}
    </ul>
  </PanelContent>
);

export interface Props {
  isLoading: boolean;
  error: boolean;
  groups: Model.AreaGroupDecorated[];
  errorMessage: string;
  onDeleteGroup: (groupId: number) => Promise<any>;
}

const MaintainGroupScreenView = (props: Props) => {
  let content = <Loading />;

  if (props.error) {
    content = (
      <AlertDanger>
        <Trans>An error has occurred.</Trans>
      </AlertDanger>
    );
  }

  if (!props.isLoading && !props.error && props.groups.length > 0) {
    const groups = sortBy(props.groups, (group) => {
      return group.name;
    });

    content = (
      <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th scope="col">
                <Trans>Group name</Trans>
              </th>
              <th scope="col" className="action-icons">
                <Trans>Actions</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {groups.map((group) => {
              return (
                <tr key={group.id}>
                  <th scope="row">
                    <strong>{group.name}</strong>
                  </th>
                  <td className="action-icons">
                    <ButtonShowPanel
                      name={group.name}
                      iconOnly={true}
                      iconType="icon-area"
                      title={t`Areas`}
                      className="btn-link-panel"
                      panelContent={() => {
                        return (
                          <GroupPanelContent
                            areas={group.areas}
                            groupName={group.name}
                          />
                        );
                      }}
                    >
                      <Trans>Areas</Trans>
                    </ButtonShowPanel>
                    <ButtonShowPanel
                      name={`listGroupUsers-${group.id}`}
                      iconOnly={true}
                      iconType="icon-members"
                      title={t`Members`}
                      className="btn-link-panel"
                      panelContent={() => {
                        return (
                          // @ts-ignore
                          <MaintainGroupUsersPanel
                            groupName={group.name}
                            groupId={group.id}
                          />
                        );
                      }}
                    >
                      <Trans>Members</Trans>
                    </ButtonShowPanel>
                    <ButtonShowModal
                      name="editGroup"
                      iconOnly={true}
                      iconType="icon-edit"
                      title={t`Edit`}
                      className="btn-link-panel"
                      modalContent={({ hideModal }: any) => (
                        <MaintainGroupModal
                          hideModal={hideModal}
                          group={group}
                        />
                      )}
                    >
                      <Trans>Edit</Trans>
                    </ButtonShowModal>
                    <HasPermission check="can_delete_groups">
                      <ButtonShowConfirmation
                        name="delete-group"
                        iconType="icon-circle-minus"
                        title={t`Delete`}
                        className="btn-link-panel"
                        content={
                          <Trans>
                            Are you sure you want to delete{' '}
                            <strong>{group.name}</strong>? This action is not
                            reversible.
                          </Trans>
                        }
                        onConfirm={() => props.onDeleteGroup(group.id)}
                      />
                    </HasPermission>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  if (
    !props.isLoading &&
    !props.error &&
    props.groups &&
    props.groups.length === 0
  ) {
    content = (
      <p>
        <Trans>No groups.</Trans>
      </p>
    );
  }

  return (
    <PageStandard
      name="maintain-groups"
      header={<Trans>Maintain Groups</Trans>}
    >
      <HasPermission check="can_create_groups">
        <ActionBlock className="text-right">
          <ButtonShowModal
            name="createGroup"
            iconType="icon-plus"
            shortcut="CREATE_NEW"
            modalContent={({ hideModal }: any) => (
              <MaintainGroupModal
                hideModal={hideModal}
                group={{ name: '', areas: [] }}
              />
            )}
          >
            <Trans>Create a group</Trans>
          </ButtonShowModal>
        </ActionBlock>
      </HasPermission>
      {content}
    </PageStandard>
  );
};

export default MaintainGroupScreenView;
