import React from 'react';
import { FormikProps, FieldArrayRenderProps, getIn, Field } from 'formik';
import { Trans, t } from '@lingui/macro';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { ObsPointMenu } from 'components/modules/async-menu/ObsPointMenu';
import { StoredSpatialPlotFormValues } from './StoredSpatialPlotObservationPointListSection';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import { yesNoToggleOptions } from 'components/base/form/toggle-field/ToggleField';
import { NumberField } from 'components/base/form/number-field/NumberField';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

/**
 * Config for one spatial plot observation point
 *
 * @param props
 */
export const StoredSpatialPlotObservationPointSection = (props: {
  obsPointIdx: number;
  obsPointFieldPrefix: string;
  formik: FormikProps<StoredSpatialPlotFormValues>;
  obsPointArrayHelpers: FieldArrayRenderProps;
  requireObservationPoint: boolean;
  timeZone: string | undefined;
  draggable: boolean;
}) => {
  const {
    formik,
    timeZone,
    obsPointIdx,
    obsPointFieldPrefix,
    obsPointArrayHelpers,
    requireObservationPoint,
    draggable,
  } = props;

  const detailsName = `${obsPointFieldPrefix}.selectedOptions`;
  const selectedOptions = getIn(props.formik.values, detailsName);
  const selectedObsPoint = selectedOptions?.[0];

  return (
    <Draggable
      draggableId={`draggable-${obsPointIdx}`}
      index={obsPointIdx}
      isDragDisabled={!draggable}
    >
      {(provided, snapshot) => (
        <tr
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={classNames({
            draggable: draggable,
            dragging: snapshot.isDragging,
          })}
        >
          <td>
            <ObsPointMenu
              name={`${obsPointFieldPrefix}.observation_point`}
              isMulti={false}
              detailsName={`${obsPointFieldPrefix}.selectedOptions`}
            />
            <FieldError name={`${obsPointFieldPrefix}.observation_point`} />
          </td>
          <td>
            <Field
              className="label-input"
              type="text"
              maxLength={50}
              name={`${obsPointFieldPrefix}.label`}
            />
            <FieldError name={`${obsPointFieldPrefix}.label`} />
          </td>
          <td>
            <SimpleSelectField
              className="spatial-plot-table-select-input"
              menuPortalTarget={document.body}
              name={`${obsPointFieldPrefix}.show_label`}
              options={yesNoToggleOptions}
            />
            <FieldError name={`${obsPointFieldPrefix}.show_label`} />
          </td>
          <td>
            <SimpleSelectField
              className="spatial-plot-table-select-input"
              menuPortalTarget={document.body}
              name={`${obsPointFieldPrefix}.show_in_data_table`}
              options={yesNoToggleOptions}
            />
            <FieldError name={`${obsPointFieldPrefix}.show_in_data_table`} />
          </td>
          <td>
            <SimpleSelectField
              menuPortalTarget={document.body}
              name={`${obsPointFieldPrefix}.use_specific_datetime`}
              options={[
                {
                  label: <Trans>Default</Trans>,
                  value: false,
                },
                {
                  label: <Trans>Specific date</Trans>,
                  value: true,
                },
              ]}
            />
            <FieldError name={`${obsPointFieldPrefix}.use_specific_datetime`} />
          </td>
          <td className="reading-date-input">
            <DatetimeField
              name={`${obsPointFieldPrefix}.reading_datetime`}
              timeZone={timeZone}
              disabled={
                !formik.values.observation_points[obsPointIdx]
                  .use_specific_datetime
              }
            />
            <FieldError name={`${obsPointFieldPrefix}.reading_datetime`} />
          </td>
          <td>
            <NumberField
              className="number-input"
              required={true}
              decimalPlaces={2}
              name={`${obsPointFieldPrefix}.vertical_offset`}
            />
            <FieldError name={`${obsPointFieldPrefix}.vertical_offset`} />
          </td>
          <td>
            <NumberField
              className="number-input"
              required={true}
              decimalPlaces={2}
              name={`${obsPointFieldPrefix}.horizontal_offset`}
            />
            <FieldError name={`${obsPointFieldPrefix}.horizontal_offset`} />
          </td>
          <td className="action-icons">
            {/*Actions*/}
            {((formik.values.observation_points.length > 2 &&
              requireObservationPoint) ||
              (formik.values.observation_points.length > 1 &&
                !requireObservationPoint)) && (
              <ButtonShowConfirmation
                iconOnly={true}
                iconType="icon-circle-minus"
                className="btn-link-panel"
                title={t`Delete`}
                name={`${obsPointFieldPrefix}-delete`}
                onConfirm={async () => {
                  obsPointArrayHelpers.remove(obsPointIdx);
                }}
                content={
                  selectedObsPoint ? (
                    <Trans>
                      Are you sure you want to delete{' '}
                      <strong>{selectedObsPoint?.label}</strong> from this plot?
                      This action is not reversible.
                    </Trans>
                  ) : (
                    <Trans>
                      Are you sure you want to delete this item from this plot?
                      This action is not reversible.
                    </Trans>
                  )
                }
                okBtnText={<Trans>Yes, delete</Trans>}
              />
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
};
