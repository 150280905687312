import React from 'react';
import { Trans, Plural } from '@lingui/macro';
import {
  AlertInfo,
  AlertWarning,
  AlertDanger,
} from 'components/base/alert/alert';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import ButtonShowPanel from 'components/base/panel/buttonshowpanel';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import ActionBlock from 'components/base/actionblock/actionblock';
import Loading from 'components/base/loading/loading';
import { BatchSummary } from 'components/modules/batchsummarybox/BatchSummary';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { AssignToChecksheetInstanceModal } from 'screens/checkreadings/AssignToChecksheetInstanceModal';
import { Link } from 'react-router-dom';
import { Icon } from 'components/base/icon/icon';

export interface CloseoutViewProps {
  alarmsCount: number | null;
  errorMessage: string | null;
  readingsBatch: Model.ListReadingsBatch | null;
  readingsFile: Model.ReadingsFileDecorated | null;
  isLoading: boolean;
  checksheetInstanceId: number | null;
  onRefresh: () => void;
}

export function CloseoutView(props: CloseoutViewProps) {
  const { checksheetInstanceId, readingsBatch, onRefresh } = props;

  const batchNumber = props.readingsBatch?.batch_number;
  const pageProps = {
    name: 'closeout',
    header: <Trans>Closeout summary</Trans>,
    subHeader: batchNumber,
  };

  if (props.isLoading) {
    return (
      <PageStandard {...pageProps}>
        <Loading />
      </PageStandard>
    );
  }

  if (props.errorMessage) {
    return (
      <PageStandard {...pageProps}>
        <AlertWarning>{props.errorMessage}</AlertWarning>
      </PageStandard>
    );
  }

  if (!readingsBatch) {
    return (
      <PageStandard {...pageProps}>
        <AlertWarning>
          <Trans>Readings batch not found.</Trans>
        </AlertWarning>
      </PageStandard>
    );
  }

  const isMakingReadingsEffective =
    readingsBatch.status === Enum.ReadingsBatch_STATUS.confirmation_queued ||
    readingsBatch.status ===
      Enum.ReadingsBatch_STATUS.making_readings_effective;

  const isAlarmCheckInProgress =
    readingsBatch.status === Enum.ReadingsBatch_STATUS.alarm_checking;

  const isBatchConfirmed =
    readingsBatch.status === Enum.ReadingsBatch_STATUS.qa_complete;

  return (
    <PageStandard {...pageProps}>
      {isBatchConfirmed ? (
        <ActionBlock className="text-right">
          {checksheetInstanceId && (
            <HasPermission check="can_view_checksheet_instances">
              <Link
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                to={`/checksheet-instances/${checksheetInstanceId}`}
              >
                <span>
                  <Trans>View checksheet</Trans>
                </span>
                <Icon type="icon-view" />
              </Link>
            </HasPermission>
          )}
          <HasPermission check="can_create_checksheet_instances">
            <ButtonShowModal
              name="assignToChecksheetInstance"
              iconType="icon-clipboard-arrow-left"
              modalContent={(modalProps) => (
                <AssignToChecksheetInstanceModal
                  readingsBatch={readingsBatch}
                  onAfterSave={onRefresh}
                  {...modalProps}
                />
              )}
            >
              <Trans>Assign to checksheet</Trans>
            </ButtonShowModal>
          </HasPermission>
          <ButtonShowPanel
            name="batchSummary"
            panelContent={() => (
              <BatchSummary
                readingsBatch={readingsBatch}
                readingsFile={props.readingsFile}
              />
            )}
          >
            <Trans>Batch info</Trans>
          </ButtonShowPanel>
        </ActionBlock>
      ) : null}
      {props.errorMessage && <AlertDanger>{props.errorMessage}</AlertDanger>}
      {isMakingReadingsEffective ? (
        <AlertInfo>
          <Trans>Making readings effective</Trans>
        </AlertInfo>
      ) : isAlarmCheckInProgress ? (
        <AlertInfo>
          <Trans>Alarm checking in progress</Trans>
        </AlertInfo>
      ) : isBatchConfirmed ? (
        <AlertInfo>
          <Trans>Readings for batch {batchNumber} confirmed.</Trans>
        </AlertInfo>
      ) : (
        <AlertWarning>
          <Trans>Readings for batch {batchNumber} NOT confirmed</Trans>
        </AlertWarning>
      )}
      {!isBatchConfirmed ? null : props.alarmsCount === 0 ? (
        <AlertInfo>
          <Trans>No alarms were raised.</Trans>
        </AlertInfo>
      ) : (
        <AlertWarning>
          <Plural
            value={props.alarmsCount!}
            one="1 alarm was raised."
            other="# alarms were raised."
          />
        </AlertWarning>
      )}
    </PageStandard>
  );
}
