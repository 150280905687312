import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { StandardDispatch } from 'main/store';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector, useIsMounted } from 'util/hooks';
import { FullState } from 'main/reducers';
import {
  RCPWithQueryParams,
  parseNumberQueryParamFromRouterProps,
  setOneQueryParam,
} from 'util/routing';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { fetchPlotSet, unmountPlotSetDetail } from 'ducks/plotset/detail';
import { PlotSetView } from './PlotSetView';
import { selectLoggedInUserName } from 'ducks/login';
import { DMSHotKey } from 'main/DMSHotKey';
import { useStoredPlotState } from 'hooks/use-stored-plot-state';
import {
  fetchStoredPlotReadings,
  unmountStoredPlotReadings,
} from 'ducks/plot/stored-plot';
import { uniq } from 'lodash';
import { unmountStoredPlotDetails } from 'ducks/stored-plot/detail';
import { postApi } from 'util/backendapi/fetch';
import { Trans } from '@lingui/macro';
import { ExportFormValues } from './PlotSetView';
import { convertDateToDatetime, formatDatetimeForBackendUrl } from 'util/dates';

type OwnProps = RCPWithQueryParams<{ idx: string }, { plotSetName: string }>;

export interface ExportState {
  exportTaskId: string | null;
  errorMessage: JSX.Element | null;
}

export const PlotSetScreen = (props: OwnProps) => {
  const plotNumber = parseNumberQueryParamFromRouterProps(props, 'idx');
  const plotSetName = props.match.params.plotSetName;
  const isMounted = useIsMounted();

  const { plotSet, storedPlots, isLoading, errorMessage, userString } =
    useShallowEqualSelector((state: FullState) => {
      return {
        plotSet: state.plotSet.detail.record,
        storedPlots: state.plotSet.detail.storedPlots,
        errorMessage: state.plotSet.detail.errorMessage,
        isLoading: state.plotSet.detail.loading,
        userString: selectLoggedInUserName(state),
      };
    });

  const dispatch: StandardDispatch = useDispatch();
  useEffect(() => {
    if (plotSet?.name !== plotSetName) {
      dispatch(fetchPlotSet(plotSetName));
    }
    return () => {
      if (plotSet && storedPlots) {
        const plotTypes = uniq(storedPlots.map((sp) => sp.plot_type));
        plotTypes.forEach((t) => dispatch(unmountStoredPlotReadings(t)));
        dispatch(unmountStoredPlotDetails());
        dispatch(unmountPlotSetDetail());
      }
    };
  }, [dispatch, plotSet, plotSetName, storedPlots]);

  const storedPlotOptions: SimpleSelectOption[] = useMemo(() => {
    if (storedPlots && storedPlots.length) {
      return storedPlots.map((sp) => ({
        value: sp.name,
        label: sp.name,
      }));
    }

    return [];
  }, [storedPlots]);

  // The "idx" in the URL is one-based because it's user-facing (sorta).
  // We need to convert it into a valid zero-based index for internal JS use.
  const plotIdx = useMemo(() => {
    if (plotNumber && plotNumber > 0) {
      return Math.min(storedPlots.length - 1, plotNumber - 1);
    }

    return 0;
  }, [plotNumber, storedPlots]);

  // Fetch and/or select data about the current plot being displayed.
  const storedPlotName = storedPlots?.[plotIdx]?.name;
  const {
    record: storedPlot,
    loading: isLoadingStoredPlot,
    errorMessage: storedPlotErrorMessage,
  } = useStoredPlotState(storedPlotName);
  // A flag to indicate all the plot data has been loaded, so we can start
  // pre-fetching the next page's data
  const [isPlotDataLoaded, setIsPlotDataLoaded] = useState(false);
  useEffect(() => {
    if (storedPlot) {
      dispatch(fetchStoredPlotReadings(storedPlot)).then(
        () => isMounted() && setIsPlotDataLoaded(true)
      );
    }
  }, [dispatch, isMounted, storedPlot]);

  // Pre-fetch data for the next stored plot in the plot set.
  const nextStoredPlotName: string | null =
    storedPlots?.[plotIdx + 1]?.name ?? null;
  const { record: nextStoredPlot } = useStoredPlotState(nextStoredPlotName);
  useEffect(() => {
    // NOTE: Don't start pre-fetching data until the data for the current screen
    // has been loaded.
    if (nextStoredPlot && isPlotDataLoaded) {
      dispatch(fetchStoredPlotReadings(nextStoredPlot));
    }
  }, [dispatch, isPlotDataLoaded, nextStoredPlot]);

  const onPrevious = useCallback(() => {
    let nextIdx = plotIdx - 1;
    if (nextIdx < 0) {
      return;
    }
    setOneQueryParam(props, 'idx', nextIdx + 1);
  }, [plotIdx, props]);

  const onNext = useCallback(() => {
    let nextIdx = plotIdx + 1;
    if (nextIdx > storedPlotOptions.length - 1) {
      return;
    }
    setOneQueryParam(props, 'idx', nextIdx + 1);
  }, [storedPlotOptions, plotIdx, props]);

  const onSelectStorePlot = useCallback(
    (val: string | null) => {
      const idx = storedPlotOptions.findIndex((opt) => opt.value === val);
      setOneQueryParam(props, 'idx', idx + 1);
    },
    [storedPlotOptions, props]
  );

  const initialExportState = {
    exportTaskId: null,
    errorMessage: null,
  };
  const [exportState, setExportState] =
    useState<ExportState>(initialExportState);

  const resetExportState = () => {
    setExportState(initialExportState);
  };

  const handleExportSubmit = async (values: ExportFormValues) => {
    const path = `/tasks/export/pdf/plot-sets/${plotSet?.id}/`;
    let exportURL = path;

    if (values.endDate) {
      let queryString = new URLSearchParams();
      queryString.append(
        'end_datetime',
        formatDatetimeForBackendUrl(convertDateToDatetime(values.endDate, null))
      );
      exportURL = `${path}?${queryString.toString()}`;
    }

    try {
      const response = await postApi(exportURL as '/tasks/export/:exportURL/');
      setExportState({ ...exportState, exportTaskId: response.id });
    } catch (e) {
      setExportState({
        ...exportState,
        errorMessage: <Trans>Error: {e.message}</Trans>,
      });
    }
  };

  return (
    <>
      <DMSHotKey allowChanges shortcut="LEFT" onPress={onPrevious} />
      <DMSHotKey allowChanges shortcut="RIGHT" onPress={onNext} />

      <PlotSetView
        isLoading={isLoading || isLoadingStoredPlot}
        errorMessage={errorMessage || storedPlotErrorMessage}
        plotSet={plotSet}
        storedPlot={storedPlot}
        currentIndex={plotIdx}
        onNext={onNext}
        onPrevious={onPrevious}
        userString={userString}
        storedPlotOptions={storedPlotOptions}
        onSelectStorePlot={onSelectStorePlot}
        onExportSubmit={handleExportSubmit}
        exportState={exportState}
        resetExportState={resetExportState}
      />
    </>
  );
};
