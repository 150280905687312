import React, { useState } from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import dsiLogo from 'assets/images/dsi-logo.svg';
import { postApi } from 'util/backendapi/fetch';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { FormItem } from 'components/base/form/FormItem';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { ButtonPrimary, ButtonSecondary } from 'components/base/button/button';
import { Link } from 'react-router-dom';
import '../login/loginview.scss';
import './changepassword.scss';
import { showErrorsInFormik } from 'util/backendapi/error-formik';

interface ChangePasswordViewProps {
  userId: number;
  token: string;
  validToken: boolean | null;
  handleCancel: () => void;
}
interface ChangePasswordFormValues {
  password: string;
  confirmPassword: string;
}

export const ChangePasswordView: React.FunctionComponent<
  ChangePasswordViewProps
> = (props) => {
  const [passwordChanged, setPasswordChanged] = useState<boolean>(false);

  return (
    <div className="page forgot-password-page">
      <I18n>
        {({ i18n }) => (
          <div className="forgot-password-page-main">
            <h1>
              <Trans>Enter new password</Trans>
            </h1>
            <div className="forgot-password-form-wrapper">
              <div className="forgot-password-form">
                {props.validToken && !passwordChanged ? (
                  <>
                    <Trans>
                      <p>
                        Your new password must be at least 12 characters long.{' '}
                        <em>
                          <strong>Tip</strong>: Use a sentence as your password.
                        </em>
                      </p>
                      <p>
                        If you use the DMS Data Collection App, changing this
                        password will also update the password you use to log
                        into the app.
                      </p>
                    </Trans>
                    <Formik
                      initialValues={{ password: '', confirmPassword: '' }}
                      onSubmit={async (values, formik) => {
                        formik.setSubmitting(true);
                        try {
                          await postApi(
                            `/users/password-reset/${props.userId}/${props.token}/`,
                            { password: values.password }
                          );
                          setPasswordChanged(true);
                        } catch (e) {
                          showErrorsInFormik(formik, e, ['password']);
                          formik.setSubmitting(false);
                        }
                      }}
                      validate={(values: ChangePasswordFormValues) => {
                        const errors: FormikErrors<ChangePasswordFormValues> =
                          {};
                        if (values.password.length < 12) {
                          errors.password = (
                            <Trans>
                              Password must be at least 12 characters long.
                            </Trans>
                          ) as any;
                        } else if (values.password !== values.confirmPassword) {
                          errors.confirmPassword = (
                            <Trans>Passwords do not match.</Trans>
                          ) as any;
                        }
                        return errors;
                      }}
                    >
                      {(formik) => (
                        <Form>
                          {formik.status}
                          <FormItem
                            id="password"
                            label={<Trans>New password</Trans>}
                          >
                            <Field
                              name="password"
                              required={true}
                              type="password"
                              autoComplete="new-password"
                            />
                            <FieldError name="password" />
                          </FormItem>
                          <FormItem
                            id="confirmPassword"
                            label={<Trans>Confirm password</Trans>}
                          >
                            <Field
                              name="confirmPassword"
                              required={true}
                              type="password"
                              autoComplete="new-password"
                            />
                            <FieldError name="confirmPassword" />
                          </FormItem>
                          <ButtonPrimary type="submit">
                            <Trans>Change password</Trans>
                          </ButtonPrimary>
                          <ButtonSecondary onClick={props.handleCancel}>
                            <Trans>Cancel</Trans>
                          </ButtonSecondary>
                        </Form>
                      )}
                    </Formik>
                  </>
                ) : passwordChanged ? (
                  <Trans>
                    Password successfully changed,{' '}
                    <Link to="/login">login</Link>.
                  </Trans>
                ) : (
                  <ErrorNotice>
                    <Trans>Invalid token.</Trans>
                  </ErrorNotice>
                )}
              </div>
            </div>
            <footer>
              <div className="powered-by">
                <span>
                  <Trans>Powered by</Trans>
                </span>
                <img src={dsiLogo} alt={i18n._(t`Dam Safety Intelligence`)} />
              </div>
            </footer>
          </div>
        )}
      </I18n>
    </div>
  );
};
