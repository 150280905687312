import { PaginationResponseData } from 'util/backendapi/pagination';
import { StandardThunk, DuckActions } from 'main/store';
import { getPaginated, PaginatedResponse } from 'util/backendapi/fetch';
import { Model } from 'util/backendapi/models/api.interfaces';
import { errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  FETCH_DATA_LOGGER_LIST_START:
    'dms/datalogger/list/FETCH_DATA_LOGGER_LIST_START',
  FETCH_DATA_LOGGER_LIST_RESPONSE:
    'dms/datalogger/list/FETCH_DATA_LOGGER_LIST_RESPONSE',
  FETCH_DATA_LOGGER_LIST_ERROR:
    'dms/datalogger/list/FETCH_DATA_LOGGER_LIST_ERROR',
  UNMOUNT_DATA_LOGGER_LIST: 'dms/datalogger/list/UNMOUNT_DATA_LOGGER_LIST',
} as const;

export const ActionCreators = {
  FETCH_DATA_LOGGER_LIST_START: () => ({
    type: ActionTypes.FETCH_DATA_LOGGER_LIST_START,
  }),
  FETCH_DATA_LOGGER_LIST_RESPONSE: (
    response: PaginatedResponse<Model.DataLoggerDecorated>
  ) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_LIST_RESPONSE,
    payload: response,
  }),
  FETCH_DATA_LOGGER_LIST_ERROR: (errorMessage: string) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_LIST_ERROR,
    payload: errorMessage,
  }),
  UNMOUNT_DATA_LOGGER_LIST: () => ({
    type: ActionTypes.UNMOUNT_DATA_LOGGER_LIST,
  }),
};

export type DataLoggerListAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface DataLoggerListState {
  dataLoggers: null | Model.DataLoggerDecorated[];
  error: null | string;
  loading: boolean;
  pagination: null | PaginationResponseData;
}

function initialState(): DataLoggerListState {
  return {
    dataLoggers: null,
    error: null,
    loading: false,
    pagination: null,
  };
}

export function dataLoggerListReducer(
  state = initialState(),
  action: DataLoggerListAction
): DataLoggerListState {
  switch (action.type) {
    case ActionTypes.UNMOUNT_DATA_LOGGER_LIST:
      return initialState();
    case ActionTypes.FETCH_DATA_LOGGER_LIST_START:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.FETCH_DATA_LOGGER_LIST_RESPONSE:
      return {
        ...state,
        loading: false,
        dataLoggers: action.payload.data,
        pagination: action.payload.pagination,
      };
    case ActionTypes.FETCH_DATA_LOGGER_LIST_ERROR:
      return {
        ...state,
        loading: false,
        dataLoggers: null,
        pagination: null,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const unmountDataLoggerList = ActionCreators.UNMOUNT_DATA_LOGGER_LIST;

export function fetchDataLoggerList(
  limit: number,
  offset: number
): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.FETCH_DATA_LOGGER_LIST_START());
    try {
      const response = await getPaginated('/data-loggers/', {
        limit,
        offset,
      });
      return dispatch(ActionCreators.FETCH_DATA_LOGGER_LIST_RESPONSE(response));
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_DATA_LOGGER_LIST_ERROR(errorToString(e))
      );
    }
  };
}
