import { Model } from 'util/backendapi/models/api.interfaces';
import { PaginatedResponse } from 'util/backendapi/fetch';
import { PaginationResponseData } from 'util/backendapi/pagination';
import { StandardThunk } from 'main/store';
import { errorToString } from 'util/backendapi/error';
import { AnyAction } from 'redux';
import { ReportsStandardFilters } from 'util/backendapi/types/Filter';

const ReportListActionTypes = {
  FETCH_REPORT_LIST_START: 'dms/reports/FETCH_REPORT_LIST_START',
  FETCH_REPORT_LIST_RESPONSE: 'dms/reports/FETCH_REPORT_LIST_RESPONSE',
  FETCH_REPORT_LIST_ERROR: 'dms/reports/FETCH_REPORT_LIST_ERROR',
  FETCH_REPORT_INFO_START: 'dms/reports/FETCH_REPORT_INFO_START',
  FETCH_REPORT_INFO_RESPONSE: 'dms/reports/FETCH_REPORT_INFO_RESPONSE',
  FETCH_REPORT_INFO_ERROR: 'dms/reports/FETCH_REPORT_INFO_ERROR',
  MODIFY_REPORT_LIST_ROW: 'dms/reports/MODIFY_REPORT_LIST_ROW',
  REFRESH_REPORT_LIST: 'dms/reports/REFRESH_REPORT_LIST',
  UNMOUNT_REPORT_SCREEN: 'dms/reports/UNMOUNT_REPORT_SCREEN',
} as const;

export interface ReportListState<T> {
  loading: boolean;
  loadedFilters: string | null;
  generatedOnDatetime: string | null;
  error: string | null;
  items: T[] | null;
  paginationResponse: PaginationResponseData | null;
  reportInfo: Model.ReportInfo<T> | null;
  reportInfoLoading: boolean;
  reportInfoError: string | null;
}

function initialListState<T>(): ReportListState<T> {
  return {
    loading: false,
    loadedFilters: null,
    generatedOnDatetime: null,
    error: null,
    items: [],
    paginationResponse: null,
    reportInfo: null,
    reportInfoLoading: false,
    reportInfoError: null,
  };
}

// vvv Try to keep these in alphabetical order
type ReportingDuckType =
  | 'alarmParameter'
  | 'alarmReport'
  | 'alarmReportComments'
  | 'alarmNotification'
  | 'areas'
  | 'auditLog'
  | 'checksheetTemplate'
  | 'clients'
  | 'comments'
  | 'readingComments'
  | 'dataLoggerChannels'
  | 'fileGates'
  | 'instrumentTypes'
  | 'media'
  | 'myExports'
  | 'observationPoints'
  | 'observationPointFormula'
  | 'observationPointFormulaConstant'
  | 'plotSet'
  | 'readingProcessingRequest'
  | 'readingsBatch'
  | 'inspectionReading'
  | 'inspectionBatch'
  | 'retirementRequest'
  | 'routeMarchSchedule'
  | 'savedReports'
  | 'sites'
  | 'storedPlots'
  | 'users';
// ^^^ Try to keep these in alphabetical order

type ReportingDuckActionCreators<S extends ReportingDuckType> = {
  [K in keyof typeof ReportListActionTypes]: (...params: any) => {
    type: typeof ReportListActionTypes[K];
    screen: S;
  };
};

export interface ReportingDuck<
  S extends ReportingDuckType,
  T,
  F extends ReportsStandardFilters
> {
  actions: ReportingDuckActionCreators<S>;
  reducer: (state: any, action: any) => ReportListState<T>;
  fetchReportList: (filters: F) => StandardThunk;
  fetchReportInfo: () => StandardThunk;
  modifyReportRow: (id: number, partialRow: Partial<T>) => StandardThunk;
  unmount: () => AnyAction;
  refreshReportList: () => AnyAction;
}

/**
 * Helper to auto-generate the boilerplate Redux stuff for bog-standard
 * reports endpoint screens.
 *
 * @param screen Unique string used to identify each duck.
 * @param fetchListFunc Thunk action creator for fetching data from the report endpoint
 * @param fetchReportInfoFunc Thunk action creator for fetching metadata from the
 * report info endpoint
 *
 * @typeParam S is meta type for the duck's name (the `screen` parameter)
 * @typeParam T is meta type for Models returned by report listing endpoint
 * @typeParam F is meta type for Filter used for report listing endpoint
 *
 * @example
 *
 * export const sitesListDuck = makeReportingDuck(
 *   'sites',
 *   (filters) => getPaginated('/reports/sites/', filters),
 *   () => getApi('/reports/sites/info/')
 * );
 *
 * // Also export a type for the duck's actions
 * export type SiteListAction = ReportingDuckActions<
 *   typeof sitesListDuck
 * >;
 */
export function makeReportingDuck<
  S extends ReportingDuckType,
  T = any,
  F extends ReportsStandardFilters = any
>(
  screen: S,
  fetchListFunc: (filters: F) => Promise<PaginatedResponse<T>>,
  fetchReportInfoFunc: () => Promise<Model.ReportInfo<T>>
): ReportingDuck<S, T, F> {
  const actions = {
    REFRESH_REPORT_LIST() {
      return {
        type: ReportListActionTypes.REFRESH_REPORT_LIST,
        screen,
      };
    },
    FETCH_REPORT_LIST_START(filters: string) {
      return {
        type: ReportListActionTypes.FETCH_REPORT_LIST_START,
        screen,
        filters,
      };
    },
    FETCH_REPORT_LIST_RESPONSE(filters: string, payload: PaginatedResponse<T>) {
      return {
        type: ReportListActionTypes.FETCH_REPORT_LIST_RESPONSE,
        screen,
        filters,
        payload,
      };
    },
    MODIFY_REPORT_LIST_ROW(id: number, partialRow: Partial<T>) {
      return {
        type: ReportListActionTypes.MODIFY_REPORT_LIST_ROW,
        screen,
        id,
        partialRow,
      };
    },
    FETCH_REPORT_LIST_ERROR(filters: string, error: string) {
      return {
        type: ReportListActionTypes.FETCH_REPORT_LIST_ERROR,
        screen,
        filters,
        error: true,
        payload: error,
      };
    },
    FETCH_REPORT_INFO_START() {
      return {
        type: ReportListActionTypes.FETCH_REPORT_INFO_START,
        screen,
      };
    },
    FETCH_REPORT_INFO_RESPONSE(reportInfo: Model.ReportInfo<T>) {
      return {
        type: ReportListActionTypes.FETCH_REPORT_INFO_RESPONSE,
        screen,
        payload: reportInfo,
      };
    },
    FETCH_REPORT_INFO_ERROR(error: string) {
      return {
        type: ReportListActionTypes.FETCH_REPORT_INFO_ERROR,
        screen,
        error: true,
        payload: error,
      };
    },
    UNMOUNT_REPORT_SCREEN() {
      return {
        type: ReportListActionTypes.UNMOUNT_REPORT_SCREEN,
        screen,
      };
    },
  };

  const reducer = (
    state: ReportListState<T> = initialListState<T>(),
    action: ReturnType<typeof actions[keyof typeof actions]>
  ): ReportListState<T> => {
    if (action.screen !== screen) {
      return state;
    }

    switch (action.type) {
      case ReportListActionTypes.REFRESH_REPORT_LIST:
        return {
          ...state,
          loading: false,
          error: null,
          loadedFilters: null,
        };
      case ReportListActionTypes.FETCH_REPORT_LIST_START:
        return {
          ...state,
          loading: true,
          loadedFilters: action.filters,
          error: null,
        };
      case ReportListActionTypes.FETCH_REPORT_LIST_RESPONSE:
        if (state.loadedFilters !== action.filters) {
          return state;
        } else {
          return {
            ...state,
            loading: false,
            error: null,
            items: action.payload.data,
            paginationResponse: action.payload.pagination,
          };
        }
      case ReportListActionTypes.MODIFY_REPORT_LIST_ROW: {
        let updatedItems = [...state.items!];
        let itemIdx = updatedItems.findIndex(
          (item: any) => item.id === action.id
        );
        if (itemIdx !== -1) {
          updatedItems[itemIdx] = {
            ...updatedItems[itemIdx],
            ...action.partialRow,
          };
        }
        return {
          ...state,
          items: updatedItems,
        };
      }
      case ReportListActionTypes.FETCH_REPORT_LIST_ERROR:
        if (state.loadedFilters !== action.filters) {
          return state;
        } else {
          return {
            ...state,
            loading: false,
            error: action.payload,
            items: [],
            paginationResponse: null,
          };
        }
      case ReportListActionTypes.FETCH_REPORT_INFO_START:
        return {
          ...state,
          reportInfoLoading: true,
          reportInfoError: null,
        };
      case ReportListActionTypes.FETCH_REPORT_INFO_RESPONSE:
        return {
          ...state,
          reportInfoLoading: false,
          reportInfoError: null,
          reportInfo: action.payload,
        };
      case ReportListActionTypes.FETCH_REPORT_INFO_ERROR:
        return {
          ...state,
          reportInfoLoading: false,
          reportInfoError: action.payload,
          reportInfo: null,
        };
      case ReportListActionTypes.UNMOUNT_REPORT_SCREEN:
        return initialListState();
      default:
        return state;
    }
  };

  function fetchReportList(filters: F): StandardThunk {
    return async function (dispatch) {
      const loadedFilters = JSON.stringify(filters);
      dispatch(actions.FETCH_REPORT_LIST_START(loadedFilters));
      try {
        const response = await fetchListFunc(filters);
        dispatch(actions.FETCH_REPORT_LIST_RESPONSE(loadedFilters, response));
      } catch (e) {
        dispatch(
          actions.FETCH_REPORT_LIST_ERROR(loadedFilters, errorToString(e))
        );
      }
    };
  }

  function modifyReportRow(id: number, partialRow: Partial<T>): StandardThunk {
    return async function (dispatch) {
      dispatch(actions.MODIFY_REPORT_LIST_ROW(id, partialRow));
    };
  }

  function fetchReportInfo(): StandardThunk {
    return async function (dispatch) {
      dispatch(actions.FETCH_REPORT_INFO_START());
      try {
        const response = await fetchReportInfoFunc();
        dispatch(actions.FETCH_REPORT_INFO_RESPONSE(response));
      } catch (e) {
        dispatch(actions.FETCH_REPORT_INFO_ERROR(errorToString(e)));
      }
    };
  }

  return {
    reducer,
    actions,
    fetchReportList,
    fetchReportInfo,
    modifyReportRow,
    unmount: actions.UNMOUNT_REPORT_SCREEN,
    refreshReportList: actions.REFRESH_REPORT_LIST,
  };
}
export type ReportingDuckActions<D extends ReportingDuck<any, any, any>> =
  ReturnType<D['actions'][keyof typeof ReportListActionTypes]>;
