import React, { useMemo } from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import {
  Formik,
  FormikHelpers,
  Form,
  ErrorMessage,
  FormikErrors,
} from 'formik';
import ActionBlock from 'components/base/actionblock/actionblock';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { addObservationPoint } from 'ducks/storedList/detail';
import {
  ObsPointItemMenu,
  getObsPointItemIdent,
  splitObsPointItemIdent,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { useDispatch } from 'react-redux';
import { Model } from 'util/backendapi/models/api.interfaces';

export interface FormikValues {
  obsPoint: string;
}

export interface Option {
  value: string;
  label: string;
}

export type Props = {
  hideModal: () => void;
  storedListId: number;
  storedListItems: Model.StoredListItemDecorated[];
};

const validate = (values: FormikValues): FormikErrors<FormikValues> => {
  let errors: FormikErrors<FormikValues> = {};

  if (!values.obsPoint) {
    errors.obsPoint = (
      <Trans>Observation point is required</Trans>
    ) as any as string;
  }
  return errors;
};

export const AddObsPointPanelView = (props: Props) => {
  const dispatch = useDispatch();

  const currentListItems: string[] = useMemo(
    () =>
      props.storedListItems.map((i) =>
        getObsPointItemIdent(i.observation_point.id, i.item_number)
      ),
    [props.storedListItems]
  );

  const submit = React.useCallback(
    async (values: FormikValues, formik: FormikHelpers<FormikValues>) => {
      try {
        const obsPointItem = splitObsPointItemIdent(values.obsPoint)!;
        const storedListItem = {
          stored_list: props.storedListId,
          observation_point: obsPointItem.observation_point,
          position: currentListItems.length + 1,
          item_number: obsPointItem.item_number,
        };
        await dispatch(addObservationPoint(storedListItem));
        props.hideModal.call(null);
      } catch (errors) {
        formik.setSubmitting(false);
        showErrorsInFormik(formik, errors, ['obsPoint']);
      }
    },
    [currentListItems.length, dispatch, props.hideModal, props.storedListId]
  );

  return (
    <ModalContent header={<Trans>Add an observation point</Trans>}>
      <Formik
        initialValues={{ obsPoint: '' }}
        onSubmit={submit}
        validate={validate}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <fieldset>
              <div className="form-group">
                <label htmlFor="addobspointpanel-obsPoint">
                  <Trans>Observation point</Trans>
                </label>
                <ObsPointItemMenu
                  name="obsPoint"
                  id="add-obspoint"
                  autoFocus={true}
                  isMulti={false}
                  placeholder={<Trans>Select an observation point...</Trans>}
                  filterOption={(item) =>
                    !currentListItems.includes(item.value)
                  }
                />
                <ErrorMessage name="obsPoint" component={ErrorNotice} />
              </div>
            </fieldset>

            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                id="createobspointpanel-submit"
                type="submit"
                iconType="icon-save"
              >
                <Trans>Add</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
