import React from 'react';
import classNames from 'classnames';
import { Model, Enum } from '../../../util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import PageStandard from '../../../components/modules/pagestandard/pagestandard';
import Loading from '../../../components/base/loading/loading';
import { AlertDanger, AlertInfo } from '../../../components/base/alert/alert';
import { RouteMarchObservationPointListState } from '../../../ducks/routemarchobspoints';
import RouteMarchAddObservationPointPanel from './addobspointpanel';
import ButtonShowModal, {
  ModalContentProps,
} from '../../../components/base/modal/buttonshowmodal';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ModalContent from '../../../components/base/modal/modalcontent';
import ActionBlock from '../../../components/base/actionblock/actionblock';
import ButtonHideModal from '../../../components/base/modal/buttonhidemodal';
import { ButtonDanger } from '../../../components/base/button/button';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { EditObsPointRouteMarchInfoModal } from './EditObsPointRouteMarchInfoModal';
import { BackButton } from 'components/base/back-button/BackButton';
import { CopyRouteMarchPanel } from './CopyRouteMarchPanel';
import { postApi } from 'util/backendapi/fetch';
import { RouteComponentProps } from 'react-router';

export interface RouteMarchListObservationPointsViewProps
  extends RouteMarchObservationPointListState {
  routeMarchCode: string;
  hasCreateCommentPermission: boolean;
  hasCreateRouteMarchesPermission: boolean;
  history: RouteComponentProps['history'];
  refreshList: () => void;
  onRemoveObsPointClick: (
    routeMarchObsPointId: number,
    hideModal: () => void
  ) => void;
}

export class RouteMarchListObservationPointsView extends React.Component<RouteMarchListObservationPointsViewProps> {
  removeObsPointConfirmation = (
    routeMarchObsPointId: number,
    obsPointCode: string,
    hideModal: () => void
  ) => {
    return (
      <ModalContent header={<Trans>Confirmation</Trans>}>
        <p>
          <Trans>
            Are you sure you want to remove <strong>{obsPointCode}</strong> from
            this route march? This action is not reversible.
          </Trans>
        </p>
        <ActionBlock className="text-right">
          <ButtonHideModal>
            <Trans>Cancel</Trans>
          </ButtonHideModal>
          <ButtonDanger
            onClick={() =>
              this.props.onRemoveObsPointClick(routeMarchObsPointId, hideModal)
            }
          >
            <Trans>Yes, remove</Trans>
          </ButtonDanger>
        </ActionBlock>
      </ModalContent>
    );
  };

  render() {
    const { refreshList } = this.props;

    const pageProps = {
      name: 'listroutemarchobservationpoints',
      header: <Trans>Maintain Route March</Trans>,
      subHeader: this.props.routeMarchCode ? (
        <span>{this.props.routeMarchCode}</span>
      ) : null,
    };
    let addRouteMarchObsPointButton = null;

    if (
      !this.props.routeMarch.loading &&
      this.props.routeMarch.routeMarchData
    ) {
      addRouteMarchObsPointButton = (
        <div className="page-content-header-with-back-button-wrapper">
          <BackButton defaultBackUrl="/route-march" />
          <div className="page-content-header">
            <ActionBlock className="columns-fluid">
              <div className="text-right">
                {this.props.hasCreateCommentPermission &&
                this.props.routeMarch.routeMarchData ? (
                  <>
                    <ButtonShowCommentsPanel
                      type={Enum.Comment_RESOURCE_TYPE.routeMarch}
                      metadata={{
                        route_march: this.props.routeMarch.routeMarchData.id,
                      }}
                      commentReportParams={`resourcetype=${Enum.Comment_RESOURCE_TYPE.routeMarch}&route_march=${this.props.routeMarch.routeMarchData.id}`}
                    />
                    <ButtonShowModal
                      name="copyRouteMarch"
                      title={t`Copy route march`}
                      modalContent={({ hideModal }: any) => (
                        <CopyRouteMarchPanel
                          hideModal={hideModal}
                          onSubmit={async (values) => {
                            const newRouteMarch = await postApi(
                              `/route-marches/${
                                this.props.routeMarch.routeMarchData!.id
                              }/copy/`,
                              values
                            );
                            hideModal();
                            this.props.history.push(
                              `/route-march-observation-points/${newRouteMarch.code}`
                            );
                          }}
                        />
                      )}
                    >
                      <Trans>Copy route march</Trans>
                    </ButtonShowModal>
                  </>
                ) : null}
                {this.props.hasCreateRouteMarchesPermission ? (
                  <ButtonShowModal
                    shortcut={'CREATE_NEW'}
                    autoFocus={true}
                    name="addObservationPoint"
                    iconType="icon-plus"
                    title={t`Add observation point`}
                    modalContent={({ hideModal }: any) => (
                      <RouteMarchAddObservationPointPanel
                        routeMarch={
                          this.props.routeMarch
                            .routeMarchData as Model.RouteMarch
                        }
                        routeMarchObservationPoints={
                          this.props.routeMarchObservationPoints
                        }
                        hideModal={hideModal}
                      />
                    )}
                  >
                    <Trans>Add observation point</Trans>
                  </ButtonShowModal>
                ) : null}
              </div>
            </ActionBlock>
          </div>
        </div>
      );
    }

    let pageContent = <Loading />;

    if (
      !this.props.loading &&
      this.props.routeMarchObservationPoints.length === 0
    ) {
      pageContent = (
        <>
          {addRouteMarchObsPointButton}
          <AlertInfo>
            <Trans>No results.</Trans>
          </AlertInfo>
        </>
      );
    }

    if (
      !this.props.loading &&
      this.props.routeMarchObservationPoints.length > 0
    ) {
      pageContent = (
        <>
          {addRouteMarchObsPointButton}
          <div className="table-responsive">
            <table
              id="route-march-observation-points-table"
              data-is-loading={this.props.updatingRouteMarchObservationpoints}
            >
              <thead>
                <tr>
                  <th scope="col">
                    <Trans>Observation point</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Civil feature</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Instrument type</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Device alarm value</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Route march instruction</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Emergency route march instruction</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Actions</Trans>
                  </th>
                </tr>
              </thead>
              <Droppable droppableId="routeMarchObservationPoints-list">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {this.props.routeMarchObservationPoints.map(
                      (routeMarchObservationPoint, index) => {
                        const instrumentType: Model.InstrumentType =
                          routeMarchObservationPoint.observation_point
                            .instrument_type;

                        return (
                          <Draggable
                            key={String(routeMarchObservationPoint.id)}
                            draggableId={String(routeMarchObservationPoint.id)}
                            index={index}
                            isDragDisabled={
                              !this.props.hasCreateRouteMarchesPermission ||
                              this.props.updatingRouteMarchObservationpoints
                            }
                          >
                            {(provided, snapshot) => {
                              return (
                                <tr
                                  key={String(routeMarchObservationPoint.id)}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  className={classNames({
                                    draggable:
                                      this.props
                                        .hasCreateRouteMarchesPermission,
                                    dragging: snapshot.isDragging,
                                  })}
                                >
                                  <td>
                                    {
                                      routeMarchObservationPoint
                                        .observation_point.code
                                    }
                                  </td>
                                  <td>
                                    {
                                      routeMarchObservationPoint.civil_feature
                                        ?.name
                                    }
                                  </td>
                                  <td>{`${instrumentType.code} - ${instrumentType.name}`}</td>
                                  <td>
                                    {
                                      routeMarchObservationPoint
                                        .observation_point
                                        .device_alarm_threshold
                                    }
                                  </td>
                                  <td>
                                    {
                                      routeMarchObservationPoint
                                        .observation_point
                                        .route_march_instruction
                                    }
                                  </td>
                                  <td>
                                    {
                                      routeMarchObservationPoint
                                        .observation_point
                                        .emergency_route_march_instruction
                                    }
                                  </td>
                                  <td>
                                    {this.props
                                      .hasCreateRouteMarchesPermission ? (
                                      <>
                                        <ButtonShowModal
                                          name={`edit-rmop-${routeMarchObservationPoint.id}`}
                                          iconOnly={true}
                                          iconType="icon-edit"
                                          className="btn-link-panel"
                                          title={t`Edit route march observation point`}
                                          modalContent={({
                                            hideModal,
                                          }: ModalContentProps) => (
                                            <EditObsPointRouteMarchInfoModal
                                              observationPointId={
                                                routeMarchObservationPoint
                                                  .observation_point.id
                                              }
                                              onAfterSubmit={() => {
                                                hideModal();
                                                refreshList();
                                              }}
                                            />
                                          )}
                                        >
                                          <Trans>Edit</Trans>
                                        </ButtonShowModal>
                                        <ButtonShowModal
                                          name={`remove-rmop-${routeMarchObservationPoint.id}`}
                                          iconOnly={true}
                                          iconType="icon-circle-minus"
                                          className="btn-link-panel"
                                          title={t`Remove route march observation point`}
                                          modalContent={({
                                            hideModal,
                                          }: ModalContentProps) =>
                                            this.removeObsPointConfirmation(
                                              routeMarchObservationPoint.id,
                                              routeMarchObservationPoint
                                                .observation_point.code,
                                              hideModal
                                            )
                                          }
                                          modalProps={{
                                            className: 'modal modal-danger',
                                            overlayClassName: 'modal-centred',
                                          }}
                                        >
                                          <Trans>Remove</Trans>
                                        </ButtonShowModal>
                                      </>
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            }}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </div>
        </>
      );
    }

    if (this.props.errorMessage) {
      pageContent = (
        <AlertDanger>
          {this.props.errorMessage || this.props.routeMarch.errorMessage}
        </AlertDanger>
      );
    }

    return <PageStandard {...pageProps}>{pageContent}</PageStandard>;
  }
}
