import React, { useMemo } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import {
  PerformanceIndicatorListView,
  performanceIndicatorListColumns,
  PerformanceIndicatorListFilters,
} from './PerformanceIndicatorListView';
import { performanceIndicatorListDuck } from 'ducks/performance-indicator/list';
import { EMPTY_FUNC } from 'util/misc';
type OwnProps = RCPWithQueryParams<Filter.ReportsSites>;

export const PerformanceIndicatorListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return performanceIndicatorListColumns({
      refreshList: EMPTY_FUNC,
    });
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      PerformanceIndicatorListFilters,
      performanceIndicatorListDuck,
      (state) => state.performanceIndicator.list
    ),
  };

  return <PerformanceIndicatorListView {...props} />;
};
