import React from 'react';
import { withRouter } from 'react-router-dom';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import {
  RCPWithQueryParams,
  parseStringParamFromRouterProps,
  parseNumberQueryParamFromRouterProps,
  parseQueryParamFromRouterProps,
} from 'util/routing';
import {
  QuickListSettingsFormValue,
  QuickListSettingsView,
  QuickListReadingsMode,
} from './QuickListSettingsView';
import { getApi } from 'util/backendapi/fetch';
import {
  convertDatetimeToDate,
  convertDateToDatetime,
  START_OF_DAY,
  END_OF_DAY,
} from 'util/dates';
import { getSafely } from 'util/misc';

interface OwnProps {
  hideModal: ModalContentProps['hideModal'];
}

interface MyQueryParams {
  obsPointCode?: string;
  startDatetime?: string;
  endDatetime?: string;
  numberOfReadings?: number;
  readingsMode?: QuickListReadingsMode;
}

type RouterProps = RCPWithQueryParams<MyQueryParams>;

const InnerQuickListSettings: React.FunctionComponent<OwnProps & RouterProps> =
  function (props) {
    const observationPointCode = parseStringParamFromRouterProps(
      props,
      'obsPointCode'
    );
    const startDatetime = parseQueryParamFromRouterProps(
      props,
      'startDatetime',
      ''
    );
    const endDatetime = parseQueryParamFromRouterProps(
      props,
      'endDatetime',
      ''
    );
    const numberOfReadings = parseNumberQueryParamFromRouterProps(
      props,
      'numberOfReadings',
      ''
    );
    const readingsMode = parseQueryParamFromRouterProps(
      props,
      'readingsMode',
      'adjusted'
    ) as QuickListReadingsMode;

    const [timeZone, setTimeZone] = React.useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    React.useEffect(() => {
      if (observationPointCode) {
        setIsLoading(true);
        getApi('/observation-points/', {
          code: observationPointCode,
          fields: ['time_zone'],
        }).then((obsPoints) => {
          if (obsPoints.length) {
            setTimeZone(obsPoints[0].time_zone.name);
            setIsLoading(false);
          }
        });
      } else {
        setIsLoading(false);
      }
    }, [observationPointCode]);

    const initialValues: QuickListSettingsFormValue = React.useMemo(
      () => ({
        observationPoint: observationPointCode,
        observationPointDetails: [],
        startDate:
          timeZone && startDatetime
            ? convertDatetimeToDate(startDatetime, timeZone)
            : '',
        endDate:
          timeZone && endDatetime
            ? convertDatetimeToDate(endDatetime, timeZone)
            : '',
        numberOfReadings,
        readingsMode,
      }),
      [
        endDatetime,
        numberOfReadings,
        observationPointCode,
        startDatetime,
        timeZone,
        readingsMode,
      ]
    );

    /**
     * When the user submits the settings form, we add the new settings to the URL,
     * which in turn will cause the plot to update.
     */
    const handleSubmit = React.useCallback(
      (values: QuickListSettingsFormValue) => {
        const {
          observationPoint,
          numberOfReadings,
          startDate,
          endDate,
          observationPointDetails,
          readingsMode,
        } = values;

        const params = new URLSearchParams();
        const timeZone = getSafely(() => observationPointDetails[0].timeZone);

        if (numberOfReadings) {
          params.set('numberOfReadings', String(numberOfReadings));
        } else if (startDate || endDate) {
          if (startDate) {
            // This should get us a timestamp of midnight, on the selected start date,
            // in the timezone of the selected observation point.
            params.set(
              'startDatetime',
              convertDateToDatetime(startDate, timeZone, START_OF_DAY)
            );
          }
          if (endDate) {
            // This should get us a timestamp of 11:59:59pm, on the selected end date,
            // in the timezone of the selected observation point.
            params.set(
              'endDatetime',
              convertDateToDatetime(endDate, timeZone, END_OF_DAY)
            );
          }
        }
        if (readingsMode === 'raw' || readingsMode === 'both') {
          params.set('readingsMode', readingsMode);
        }

        let queryString = params.toString();
        if (queryString) {
          queryString = '?' + queryString;
        }
        props.history.push(`/quicklist/${observationPoint}${queryString}`);
        props.hideModal.call(null);
      },
      [props.hideModal, props.history]
    );

    return (
      <QuickListSettingsView
        initialValues={initialValues}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    );
  };

export const QuickListSettings = withRouter(
  InnerQuickListSettings
) as React.ComponentClass<OwnProps> & {
  WrappedComponent: typeof InnerQuickListSettings;
};
QuickListSettings.WrappedComponent = InnerQuickListSettings;
