import React from 'react';
import { Trans } from '@lingui/macro';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import mapValues from 'lodash/mapValues';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import { AlertDanger, AlertInfo } from 'components/base/alert/alert';
import ActionBlock from 'components/base/actionblock/actionblock';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { YesNoRadioField } from 'components/base/form/yesnoradio/yesnoradiofield';
import {
  Field,
  ErrorMessage,
  FormikErrors,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import {
  formatDatetimeForDisplay,
  getCurrentDatetime,
} from '../../../util/dates';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import Button from 'components/base/button/button';
import { validateCode, validateNumber, isNotNull } from 'util/validation';
import { UppercaseTextField } from 'components/base/form/uppercase-text-field/UppercaseTextField';
import { getExpectedFields } from 'util/backendapi/error';
import { BackButton } from 'components/base/back-button/BackButton';
import { DMSLink } from 'components/base/link/DMSLink';

export interface SiteDetailViewProps {
  site: Model.SiteDecorated | null;
  areaOptions: { value: number; label: React.ReactNode }[] | null;
  isLoading: boolean;
  isSubmitting: boolean;
  fetchErrorMessage: string | null;
  isEditing: boolean;
  onStartEditing: () => void;
  onStopEditing: () => void;
  onSubmit: (
    values: SiteDetailFormValues,
    actions: FormikHelpers<SiteDetailFormValues>
  ) => Promise<boolean>;
  hasEditPermission: boolean;
}

interface State {
  canChange: {
    cap_rl: boolean;
    ground_rl: boolean;
    asbuilt_angle: boolean;
  };
}

export type SiteDetailFormValues = Model.Site_POST & {
  'site_time_dependent_fields.asbuilt_angle.[0].value'?: string;
  'site_time_dependent_fields.asbuilt_angle.[0].start_datetime'?: string;
  'site_time_dependent_fields.cap_rl.[0].value'?: string;
  'site_time_dependent_fields.cap_rl.[0].start_datetime'?: string;
  'site_time_dependent_fields.ground_rl.[0].value'?: string;
  'site_time_dependent_fields.ground_rl.[0].start_datetime'?: string;
};

type FieldName = keyof SiteDetailFormValues;

const defaultInitialValues: SiteDetailFormValues = {
  area: 0,
  asbuilt: false,
  asbuilt_azimuth: '',
  asbuilt_length: '',
  code: '',
  location: '',
  site_time_dependent_fields: {
    asbuilt_angle: [{ value: '', start_datetime: '' }],
    cap_rl: [{ value: '', start_datetime: '' }],
    ground_rl: [{ value: '', start_datetime: '' }],
  },
};

const getTimeDependentFieldValuePath = (
  field: keyof State['canChange']
): FieldName => `site_time_dependent_fields.${field}.[0].value` as any;

const getTimeDependentFieldDatetimePath = (
  field: keyof State['canChange']
): FieldName => `site_time_dependent_fields.${field}.[0].start_datetime` as any;

export class SiteDetailView extends React.Component<
  SiteDetailViewProps,
  State
> {
  constructor(props: SiteDetailViewProps) {
    super(props);
    this.state = {
      canChange: {
        cap_rl: false,
        ground_rl: false,
        asbuilt_angle: false,
      },
    };
  }

  fieldLabels: { [key in FieldName]?: any } = {
    code: <Trans>Site</Trans>,
    area: <Trans>Area</Trans>,
    location: <Trans>Location</Trans>,
    asbuilt: <Trans>As built documentation</Trans>,
    asbuilt_azimuth: <Trans>As built azimuth</Trans>,
    asbuilt_length: <Trans>As built length</Trans>,
    'site_time_dependent_fields.asbuilt_angle.[0].value': (
      <Trans>As built angle</Trans>
    ),
    'site_time_dependent_fields.asbuilt_angle.[0].start_datetime': (
      <Trans>As built angle start date time</Trans>
    ),
    'site_time_dependent_fields.cap_rl.[0].value': <Trans>Cap RL</Trans>,
    'site_time_dependent_fields.cap_rl.[0].start_datetime': (
      <Trans>Cap RL start date time</Trans>
    ),
    'site_time_dependent_fields.ground_rl.[0].value': <Trans>Ground RL</Trans>,
    'site_time_dependent_fields.ground_rl.[0].start_datetime': (
      <Trans>Ground RL start date time</Trans>
    ),
  };

  validateTimeDependentField = (
    errors: any,
    field: keyof State['canChange'],
    values: SiteDetailFormValues
  ) => {
    if (this.state.canChange[field]) {
      const valuePath = getTimeDependentFieldValuePath(field);
      const datetimePath = getTimeDependentFieldDatetimePath(field);
      const value = lodashGet(values, valuePath, '');
      const startDatetime = lodashGet(values, datetimePath, '');

      if (startDatetime && (!value || !validateNumber(value as string))) {
        lodashSet(
          errors,
          valuePath,
          <Trans>A valid number is required.</Trans>
        );
      }

      if (value && !startDatetime) {
        lodashSet(
          errors,
          datetimePath,
          <Trans>Start datetime is required.</Trans>
        );
      }
    }

    return errors;
  };

  validateForm = (values: SiteDetailFormValues) => {
    let errors: FormikErrors<SiteDetailFormValues> = {};

    if (!values.code) {
      errors.code = (<Trans>Site is required.</Trans>) as any;
    } else if (!validateCode(values.code)) {
      errors.code = (
        <Trans>Site must only contain letters, numbers, and hyphens.</Trans>
      ) as any;
    }

    if (!values.area) {
      errors.area = (<Trans>Area is required.</Trans>) as any;
    }

    if (values.asbuilt_azimuth && !validateNumber(values.asbuilt_azimuth)) {
      errors.asbuilt_azimuth = (
        <Trans>A valid number is required.</Trans>
      ) as any;
    }

    if (values.asbuilt_length && !validateNumber(values.asbuilt_length)) {
      errors.asbuilt_length = (
        <Trans>A valid number is required.</Trans>
      ) as any;
    }

    errors = this.validateTimeDependentField(errors, 'cap_rl', values);
    errors = this.validateTimeDependentField(errors, 'ground_rl', values);
    errors = this.validateTimeDependentField(errors, 'asbuilt_angle', values);

    return errors;
  };

  render() {
    const { site } = this.props;

    const pageProps = {
      name: 'maintain-site',
      header: <Trans>Site</Trans>,
    };
    if (this.props.fetchErrorMessage) {
      return (
        <PageStandard {...pageProps}>
          <AlertDanger>{this.props.fetchErrorMessage}</AlertDanger>
        </PageStandard>
      );
    }

    if (this.props.isLoading || this.props.areaOptions === null) {
      return (
        <PageStandard {...pageProps}>
          <Loading />
        </PageStandard>
      );
    }

    const isEditing = Boolean(this.props.isEditing || !site);

    const initialValues: SiteDetailFormValues = site
      ? {
          area: site.area.id,
          asbuilt: site.asbuilt,
          asbuilt_azimuth: site.asbuilt_azimuth || '',
          asbuilt_length: site.asbuilt_length || '',
          code: site.code,
          location: site.location,
          // nested field needs to have some initial values
          // otherswise, React will complain about uncontrolled becomes controlled component
          site_time_dependent_fields: mapValues(
            defaultInitialValues.site_time_dependent_fields,
            (_, key: keyof State['canChange']) => {
              const values = site.site_time_dependent_fields[key];
              return (values || []).length
                ? values
                : [{ value: '', start_datetime: '' }];
            }
          ),
        }
      : defaultInitialValues;

    return (
      <PageStandard
        name="maintain-site"
        header={<Trans>Site</Trans>}
        subHeader={site ? <>{site.code}</> : null}
      >
        <div className="page-content-header-with-back-button-wrapper">
          <BackButton defaultBackUrl="/sites" />
          {site ? (
            <HasPermission check="can_create_site_comments">
              <div className="page-content-header">
                <ActionBlock className="text-right">
                  <ButtonShowCommentsPanel
                    type={Enum.Comment_RESOURCE_TYPE.site}
                    metadata={{
                      site: site.id,
                      area: site.area.id,
                    }}
                    commentReportParams={`resourcetype=${Enum.Comment_RESOURCE_TYPE.site}&site=${site.id}`}
                  />
                </ActionBlock>
              </div>
            </HasPermission>
          ) : null}
        </div>
        <EditableCard<SiteDetailFormValues>
          validateOnBlur={false}
          hasEditPermission={this.props.hasEditPermission}
          name="area-form"
          header={
            !site ? (
              <Trans>New site</Trans>
            ) : isEditing ? (
              <Trans>Edit site details</Trans>
            ) : (
              <Trans>All site details</Trans>
            )
          }
          startEditing={() => this.props.onStartEditing()}
          stopEditing={() => this.props.onStopEditing()}
          isEditMode={isEditing}
          initialValues={initialValues}
          onSubmit={async (values, formik) => {
            // clean up site_time_dependent_fields values if nothing is filled in
            values.site_time_dependent_fields = mapValues(
              values.site_time_dependent_fields,
              (values) =>
                lodashGet(values, '[0].value') &&
                lodashGet(values, '[0].start_datetime')
                  ? values
                  : []
            );

            if (!values.asbuilt_length) {
              values.asbuilt_length = null;
            }

            if (!values.asbuilt_azimuth) {
              values.asbuilt_azimuth = null;
            }

            try {
              await this.props.onSubmit(values, formik);
            } catch (error) {
              showErrorsInFormik(formik, error, getExpectedFields(values));
            }
          }}
          validate={this.validateForm}
          render={({ formik, CardSectionComponent }) => (
            <>
              <CardSectionComponent
                name="name"
                header={<Trans>Name</Trans>}
                fields={[
                  {
                    name: 'code',
                    label: this.fieldLabels['code'],
                    content: isEditing ? (
                      <>
                        <UppercaseTextField
                          name="code"
                          disabled={this.shouldDisable()}
                          autoFocus={true}
                        />
                        <FieldError name="code" />
                      </>
                    ) : (
                      this.props.site && this.props.site.code
                    ),
                  },
                  this.areaSelect('area', isEditing),
                  this.field('location', isEditing),
                ]}
              />

              <CardSectionComponent
                name="reduced-levels"
                header={<Trans>Reduced levels</Trans>}
                fields={[
                  <AlertInfo
                    className="alert-condensed"
                    key="cap-rl-instructions"
                    additionalInformation={
                      this.props.site ? (
                        <DMSLink
                          to={`/observation-point?site=${this.props.site.id}`}
                        >
                          List Observation Points.
                        </DMSLink>
                      ) : null
                    }
                  >
                    <Trans>
                      When the Cap RL is changed the Install Depth of the
                      observation points related to this site must also be
                      changed by the same amount to keep the Port RL from
                      changing.
                    </Trans>
                  </AlertInfo>,
                  this.timeDependentField('cap_rl', 'm', formik, isEditing),
                  this.timeDependentField('ground_rl', 'm', formik, isEditing),
                ]}
              />

              <CardSectionComponent
                name="as-built"
                header={<Trans>As built</Trans>}
                fields={[
                  this.booleanField('asbuilt', formik, isEditing),
                  this.unitOfMeasurementField(
                    'asbuilt_azimuth',
                    'deg',
                    formik,
                    isEditing
                  ),
                  this.timeDependentField(
                    'asbuilt_angle',
                    'deg',
                    formik,
                    isEditing
                  ),
                  this.unitOfMeasurementField(
                    'asbuilt_length',
                    'm',
                    formik,
                    isEditing
                  ),
                ]}
              />
            </>
          )}
        />
      </PageStandard>
    );
  }

  areaSelect = (prop: keyof SiteDetailFormValues, isEditing: boolean) => {
    let content;

    if (isEditing) {
      content = this.props.areaOptions ? (
        <React.Fragment>
          <SimpleSelectField
            name={prop}
            id="maintsite-areas"
            placeholder="Please select an area"
            options={this.props.areaOptions}
          />
          <ErrorMessage name={prop} component={ErrorNotice} />
        </React.Fragment>
      ) : (
        <Loading />
      );
    } else {
      content =
        this.props.site && this.props.site.area
          ? this.props.site.area.name
          : '';
    }

    return { name: prop, label: this.fieldLabels[prop], content };
  };

  field = (prop: 'code' | 'location', isEditing: boolean) => {
    return {
      name: prop,
      label: this.fieldLabels[prop],
      content: isEditing ? (
        <React.Fragment>
          <Field type="text" name={prop} disabled={this.shouldDisable()} />
          <ErrorMessage name={prop} component={ErrorNotice} />
        </React.Fragment>
      ) : (
        this.props.site && this.props.site[prop]
      ),
    };
  };

  booleanField = (
    prop: keyof SiteDetailFormValues,
    formik: FormikProps<SiteDetailFormValues>,
    isEditing: boolean
  ) => {
    let content;

    if (isEditing) {
      content = (
        <fieldset className="radio-fieldset-toggle">
          <YesNoRadioField name={prop} disabled={this.shouldDisable()} />
        </fieldset>
      );
    } else {
      content = formik.values[prop] ? <Trans>Yes</Trans> : <Trans>No</Trans>;
    }

    return { name: prop, label: this.fieldLabels[prop], content };
  };

  toggleFieldState = (prop: keyof State['canChange']) => {
    var canChange = { ...this.state.canChange };
    canChange[prop] = true;
    this.setState({ canChange: canChange });
  };

  shouldDisable = (field?: FieldName | keyof State['canChange']) => {
    return (
      this.props.isSubmitting ||
      (field &&
        this.state.canChange[field as keyof State['canChange']] === false)
    );
  };

  unitOfMeasurementField = (
    field: FieldName,
    unit: React.ReactNode,
    formik: FormikProps<SiteDetailFormValues>,
    isEditing: boolean,
    disabled?: boolean
  ) => {
    let content;

    if (isEditing) {
      content = (
        <div className="input-unit-wrapper">
          <Field
            type="text"
            name={field}
            id={`maintsite-${field}`}
            disabled={disabled || this.shouldDisable(field)}
            className="text-input-with-unit"
          />
          <span className="input-unit">{unit}</span>
          <ErrorMessage component={ErrorNotice} name={field} />
        </div>
      );
    } else {
      const value = lodashGet(formik.values, field, '');
      content = value !== '' ? `${value} ${unit}` : '';
    }

    return { name: field, label: this.fieldLabels[field], content };
  };

  timeDependentField = (
    prop: keyof State['canChange'],
    unit: React.ReactNode,
    formik: FormikProps<SiteDetailFormValues>,
    isEditing: boolean
  ) => {
    const disabled = this.shouldDisable(prop);
    const valueField = this.unitOfMeasurementField(
      `site_time_dependent_fields.${prop}.[0].value` as any,
      unit,
      formik,
      isEditing,
      disabled
    );
    const startDateFieldName =
      `site_time_dependent_fields.${prop}.[0].start_datetime` as keyof SiteDetailFormValues;
    const currentValue = lodashGet(
      formik.values,
      startDateFieldName,
      undefined
    ) as string | undefined;

    return {
      name: prop,
      columns: [
        // First column
        {
          name: `${valueField.name}-label`,
          label: this.fieldLabels[valueField.name],
          content: valueField.content,
        },
        // Second column (start date)
        {
          name: startDateFieldName,
          label: <Trans>Start date</Trans>,
          content: isEditing ? (
            <React.Fragment>
              <DatetimeField
                name={startDateFieldName}
                id={`maintsite-${prop}-startdatetime`}
                disabled={disabled}
                timeZone={
                  this.props.site
                    ? this.props.site.area.time_zone.name
                    : undefined
                }
              />
              <ErrorMessage component={ErrorNotice} name={startDateFieldName} />
              {!this.state.canChange[prop] && (
                <Button
                  onClick={() => {
                    const currentDateTime = getCurrentDatetime();

                    if (!lodashGet(formik.values, valueField.name)) {
                      formik.setFieldValue(valueField.name, '');
                    }

                    formik.setFieldValue(
                      startDateFieldName,
                      currentDateTime,
                      false
                    );

                    this.toggleFieldState(prop);
                  }}
                >
                  <Trans>Edit</Trans>
                </Button>
              )}
            </React.Fragment>
          ) : (
            currentValue && (
              <span>
                {formatDatetimeForDisplay(
                  currentValue,
                  this.props.site?.area.time_zone.name
                )}
              </span>
            )
          ),
        },
        isEditing
          ? null
          : {
              name: `${prop}-view-history-link`,
              content: this.props.site && (
                <DMSLink
                  to={`/sites/${this.props.site.code}/time-dependent-fields/${prop}`}
                >
                  <Trans>View history</Trans>
                </DMSLink>
              ),
            },
      ].filter(isNotNull),
    };
  };
}
