import React from 'react';
import { Trans } from '@lingui/macro';
import { Formik, FormikErrors, Form } from 'formik';
import isEqual from 'lodash/isEqual';
import ModalContent from 'components/base/modal/modalcontent';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { useDispatch } from 'react-redux';
import {
  saveDataLoggerChannel,
  dataLoggerChannelsDuck,
} from 'ducks/data-logger/channels';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { FormItem } from 'components/base/form/FormItem';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi } from 'util/backendapi/fetch';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import {
  DatetimeField,
  DatetimeFieldValue,
} from 'components/base/form/datefield/datefield';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import {
  OnSearchFunc,
  LoadDefaultsFunc,
} from 'components/base/form/asyncsimpleselect/asyncsimpleselect';
import { THE_BEGINNING_OF_TIME } from 'util/dates';
import { getExpectedFields } from 'util/backendapi/error';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from 'components/modules/async-menu/ObsPointMenu';

export interface EditChannelAllocationModalProps extends ModalContentProps {
  row: Pick<
    Model.ReportsDataLoggerChannel,
    // NOTE: If you add more fields here, make sure to add them to the
    // DataLoggerChannelsColumns "action" column's "additional fields"
    | 'id'
    | 'channel_number'
    | 'data_logger'
    | 'data_logger__site__area__time_zone__name'
    | 'observation_point'
    | 'start_datetime'
    | 'status'
  >;
}

interface FormValues {
  observation_point: number | null;
  start_datetime: DatetimeFieldValue;
  status: Enum.DataLoggerChannel_STATUS;
}

const onSearchObsPoints: OnSearchFunc<number> = async function (
  inputValue: string
) {
  const obsPoints = await getApi('/observation-points/', {
    code__icontains: inputValue,
    fields: ['id', 'code'],
    limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
  });
  return obsPoints.map(({ id, code }) => ({ value: id, label: code }));
};

const loadDefaultObsPoint: LoadDefaultsFunc<number, false> = async function (
  initialValue: number
) {
  if (initialValue) {
    const op = await getApi(`/observation-points/${initialValue}/`, {
      fields: ['id', 'code'],
    });
    return [{ value: op.id, label: op.code }];
  } else {
    return [];
  }
};

export function EditChannelAllocationModal(
  props: EditChannelAllocationModalProps
) {
  const dispatch = useDispatch();

  const { row, hideModal } = props;

  const isDummyRecord =
    !row.observation_point && row.start_datetime === THE_BEGINNING_OF_TIME;

  const initialValues: FormValues = {
    observation_point: row.observation_point,
    start_datetime: isDummyRecord ? '' : row.start_datetime,
    status: row.status,
  };
  return (
    <ModalContent header={<Trans>Edit channel allocation</Trans>}>
      <Formik<FormValues>
        initialValues={initialValues}
        enableReinitialize
        onSubmit={async (values, formik) => {
          try {
            // If they didn't change anything, then don't actually
            // submit anything. The backend for this always does a
            // POST and never a PATCH, so sending unchanged values
            // back will cause a validation failure due to uniqueness
            // constraints.
            if (!isEqual(values, initialValues)) {
              await dispatch(
                saveDataLoggerChannel({
                  channel_number: row.channel_number,
                  data_logger: row.data_logger,
                  observation_point: values.observation_point || null,
                  start_datetime: values.start_datetime || '',
                  status: values.status,
                })
              );
              dispatch(dataLoggerChannelsDuck.refreshReportList());
            }
            hideModal();
          } catch (e) {
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, getExpectedFields(values));
          }
        }}
        validate={(values) => {
          const errors: FormikErrors<typeof initialValues> = {};
          if (!values.start_datetime) {
            errors.start_datetime = (
              <Trans>This field is required</Trans>
            ) as any;
          }
          return errors;
        }}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <FormItem label={<Trans>Channel</Trans>}>
              <p className="non-editable-value">{row.channel_number}</p>
            </FormItem>
            <FormItem label={<Trans>Status</Trans>} fieldId="changestatus">
              <SimpleSelectField
                id="changestatus-status"
                name="status"
                placeholder={<Trans>Select a status</Trans>}
                options={menuItemsFromEnum(
                  'DataLoggerChannel_STATUS',
                  Object.values(Enum.DataLoggerChannel_STATUS)
                )}
                autoFocus={true}
              />
              <FieldError name="status" />
            </FormItem>
            <FormItem label={<Trans>Observation Point</Trans>}>
              <AsyncSimpleSelectField<number, false>
                name="observation_point"
                isMulti={false}
                // Clear the menu to indicate "not allocated"
                isClearable={true}
                placeholder={<Trans>Not allocated</Trans>}
                onSearch={onSearchObsPoints}
                loadDefaults={loadDefaultObsPoint}
              />
              <FieldError name="observation_point" />
            </FormItem>
            <FormItem label={<Trans>Start date and time</Trans>}>
              <DatetimeField
                name="start_datetime"
                timeZone={row.data_logger__site__area__time_zone__name}
              />
              <FieldError name="start_datetime" />
            </FormItem>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                type="submit"
                iconType="icon-save"
                disabled={formik.isSubmitting}
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
