import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

export interface FilterMenuProps {
  hasValues: boolean;
  label: React.ReactNode;
  className?: any;
}
/**
 * A simple display component to add styling to the filter controls (typically
 * menus) on a filtered table screen.
 *
 * @example
 *
 * const filterMenu = <FilterMenu><SimpleSelect /></FilterMenu>;
 *
 * @param props.hasValues Indicates whether or not the screen is currently
 * @param props.label The human-readable label for the filter control
 * filtered by this filter.
 */
export const FilterControl: React.FunctionComponent<FilterMenuProps> =
  function ({
    hasValues: isActive,
    label,
    children,
    className,
    ...otherProps
  }) {
    const combinedClassName = classNames(
      'form-group',
      { 'filter-has-value': isActive },
      className
    );
    return (
      <div className={combinedClassName} {...otherProps}>
        <label>{label}</label>
        {children}
      </div>
    );
  };

export interface FilterBlockProps {
  className?: any;
}
/**
 * A simple component to add styling to the section of the page where the filter
 * controls are, on a filtered table screen.
 * @example
 *
 * const filterBlock = (
 * <FilterBlock>
 *     <FilterMenu><SimpleSelect /></FilterMenu>
 *     <FilterMenu><SimpleSelect /><FilterMenu>
 * </FilterBlock>
 * );
 */
export const FilterBlock: React.FunctionComponent<FilterBlockProps> =
  function ({ children, className, ...otherProps }) {
    return (
      <fieldset
        className={classNames('filter-block', className)}
        {...otherProps}
      >
        <legend>
          <Trans>Filters</Trans>
        </legend>
        {/* use div as chrome doesn't allow flex on a fieldset */}
        <div className="columns-even">{children}</div>
      </fieldset>
    );
  };
