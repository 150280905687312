import React from 'react';
import { Icon } from 'components/base/icon/icon';
import { formatDatetimeForDisplay } from 'util/dates';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';

import './comment.scss';

export const Comment = (
  props: Model.ReportsComment & { timeZone?: string }
) => (
  <>
    <div className="comment-header">
      <p className="comment-info">
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.obsPoint && (
          <Icon
            type="icon-observation-point"
            title={t`Observation point comment`}
          />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.area && (
          <Icon type="icon-area" title={t`Area comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.site && (
          <Icon type="icon-site" title={t`Site comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.routeMarch && (
          <Icon type="icon-route-march" title={t`Route march comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.alarmReport && (
          <Icon type="icon-bell" title={t`Alarm report comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.alarmParameter && (
          <Icon type="icon-parameter" title={t`Alarm parameter comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.reading && (
          <Icon type="icon-sliders" title={t`Reading comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.readingInspector && (
          <Icon type="icon-route-march" title={t`Inspector comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.client && (
          <Icon type="icon-user" title={t`Client comment`} />
        )}
        {props.resourcetype === Enum.Comment_RESOURCE_TYPE.media && (
          <Icon type="icon-multimedia" title={t`Media comment`} />
        )}
        <span className="comment-date">
          {formatDatetimeForDisplay(props.created_datetime)}
        </span>
      </p>
      <p className="comment-author">
        <span className="visuallyhidden">
          <Trans>Comment by </Trans>
        </span>
        {props.commenter__name}
      </p>
    </div>
    <p className="comment-text text-with-linebreaks">
      {props.resourcetype === Enum.Comment_RESOURCE_TYPE.readingGap && (
        <>
          <span className="comment-gap">
            <strong>
              <Trans>Data gap</Trans>
              {`: `}
            </strong>
            {formatDatetimeForDisplay(props.at_datetime, props.timeZone)}
          </span>{' '}
          {'\n\n'}
        </>
      )}
      {props.content}
    </p>
  </>
);
