import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import ButtonShowPanel from 'components/base/panel/buttonshowpanel';
import { ReadingsCountPanel } from './readings-count-panel/ReadingsCountPanel';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { ConfirmReprocessingRequest } from './confirmation/ConfirmReprocessingRequest';

type Props = {
  row: Pick<Model.ReadingProcessingRequestDecorated, 'id' | 'status'>;
  onCancelRequest: (requestId: number) => Promise<void>;
  onConfirmRequest: (requestId: number) => Promise<void>;
  refreshList: () => void;
  hasCancelRequestPermission: boolean;
  hasExecuteRequestPermission: boolean;
};

export const ReprocessingRequestActionsCell = (props: Props) => {
  if (
    props.row.status === Enum.ReadingProcessingRequest_STATUS.queued ||
    props.row.status === Enum.ReadingProcessingRequest_STATUS.processing
  ) {
    return null;
  }

  return (
    <>
      <ButtonShowPanel
        iconType="icon-view"
        iconOnly={true}
        className="btn-link-panel"
        name="show-readings-count-panel"
        title={t`View observation points`}
        panelContent={() => <ReadingsCountPanel requestId={props.row.id} />}
      />

      {props.hasExecuteRequestPermission &&
        props.row.status === Enum.ReadingProcessingRequest_STATUS.new && (
          <ButtonShowConfirmation
            iconType="icon-play"
            className="btn-link-panel"
            name={`confirm-processing-request-${props.row.id}`}
            title={t`Confirm processing request`}
            okBtnText={<Trans>Yes, reprocess</Trans>}
            content={<ConfirmReprocessingRequest requestId={props.row.id} />}
            onConfirm={async () => {
              await props.onConfirmRequest(props.row.id);
              props.refreshList();
            }}
          />
        )}

      {props.hasCancelRequestPermission &&
        props.row.status === Enum.ReadingProcessingRequest_STATUS.new && (
          <ButtonShowConfirmation
            iconType="icon-circle-minus"
            className="btn-link-panel"
            name={`cancel-processing-request-${props.row.id}`}
            title={t`Cancel processing request`}
            content={
              <p>
                <Trans>
                  Are you sure you want to cancel this reprocessing request?
                  This action is not reversible.
                </Trans>
              </p>
            }
            onConfirm={async () => {
              await props.onCancelRequest(props.row.id);
              props.refreshList();
            }}
          />
        )}
    </>
  );
};
