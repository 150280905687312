import React from 'react';
import { Trans } from '@lingui/macro';
import Button, { ButtonProps } from 'components/base/button/button';
import { useHideModal } from './use-hide-modal';

type ButtonHideModalProps = ButtonProps;

export default function ButtonHideModal(props: ButtonHideModalProps) {
  const { children, ...otherProps } = props;
  const hideModal = useHideModal();

  return (
    <Button onClick={hideModal} {...otherProps}>
      {children ? children : <Trans>Cancel</Trans>}
    </Button>
  );
}
