import React, { useCallback, useEffect, useState } from 'react';
import { LoginView } from './loginview';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { doLogin, selectIsLoggedIn, unmountLoginPage } from '../../ducks/login';
import { FullState } from 'main/reducers';
import { postApi } from 'util/backendapi/fetch';

export const LoginScreen: React.FunctionComponent = function () {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(unmountLoginPage());
    };
  }, [dispatch]);

  const submitLogin = (username: string, password: string) => {
    dispatch(doLogin(username, password));
  };

  const location = useLocation();
  let redirectOnSuccess = '/';
  // Determine where we should redirect to after the user successfully logs in.
  const searchParams = new URLSearchParams(location.search);
  if (searchParams.has('returnto')) {
    redirectOnSuccess = searchParams.get('returnto')!;
  }
  const { statusMsg, isLoggedIn } = useSelector((state: FullState) => ({
    statusMsg: state.loginPage.statusMsg,
    isLoggedIn: selectIsLoggedIn(state),
  }));
  const [showForgotPassword, setShowForgotPasswordState] = useState(false);

  const handlePasswordReset = useCallback(async (email_address: string) => {
    await postApi('/users/password-reset/', { email_address: email_address });
    setShowForgotPasswordState(false);
  }, []);

  return (
    <LoginView
      statusMsg={statusMsg}
      isLoggedIn={isLoggedIn}
      redirectOnSuccess={redirectOnSuccess}
      showForgotPassword={showForgotPassword}
      setShowForgotPasswordState={setShowForgotPasswordState}
      submitLogin={submitLogin}
      handlePasswordReset={handlePasswordReset}
    />
  );
};
