import { connect } from 'react-redux';
import withInit, { InitProps } from 'components/hoc/withinit';
import { FullState } from 'main/reducers';
import {
  fetchClient,
  ActionCreators,
  createClient,
  updateClient,
} from 'ducks/client/detail';
import {
  ClientEditFormView,
  ClientEditFormViewProps,
  ClientEditFormValues,
} from './ClientEditFormView';
import { StandardDispatch } from 'main/store';
import {
  fetchEntityList,
  EntityTypes,
  selectAllInOrderedArray,
} from 'ducks/entities';

type StateProps = Omit<
  ClientEditFormViewProps,
  'onSubmit' | 'onCancel' | 'onUnmount'
>;
type DispatchProps = Pick<
  ClientEditFormViewProps,
  'onSubmit' | 'onCancel' | 'onUnmount'
>;

interface OwnProps {
  clientId: null | number;
  hideModal: () => any;
  onAfterSave: () => any;
}

export const mapStateToProps = (
  state: FullState,
  ownProps: OwnProps
): StateProps => {
  return {
    ...state.client.detail,
    clientId: ownProps.clientId,
    allUsers: selectAllInOrderedArray(state, EntityTypes.USER),
  };
};

export const mapDispatchToProps = (
  dispatch: StandardDispatch,
  ownProps: OwnProps
) => ({
  onInit: (props: StateProps & DispatchProps) => {
    dispatch(fetchEntityList(EntityTypes.USER));

    if (ownProps.clientId) {
      dispatch(fetchClient(ownProps.clientId));
    }
  },
  onSubmit: async (id: null | number, values: ClientEditFormValues) => {
    if (!id) {
      await dispatch(createClient({ ...values, users: [] }));
    } else {
      await dispatch(updateClient(id, values));
    }
    ownProps.hideModal();
    ownProps.onAfterSave();
  },
  onCancel: () => {
    ownProps.hideModal();
  },
  onUnmount: () => {
    dispatch(ActionCreators.clearClientForm());
  },
});

export default connect<
  StateProps,
  DispatchProps & InitProps<StateProps & DispatchProps>,
  OwnProps,
  FullState
>(
  mapStateToProps,
  mapDispatchToProps
)(withInit(ClientEditFormView));
