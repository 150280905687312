import React from 'react';
import { FormCardSection } from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { IntegerField } from 'components/base/form/integer-field/IntegerField';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { UnitOfMeasurementField } from 'components/base/form/unitofmeasurement-field/UnitOfMeasurementField';

export function StoredSpatialDimensionsSection() {
  return (
    <FormCardSection
      name="dimensions"
      header={<Trans>Dimensions</Trans>}
      fields={[
        {
          name: 'scale',
          label: <Trans>Scale</Trans>,
          content: (
            <>
              <IntegerField name="scale" />
              <FieldError name="scale" />
            </>
          ),
        },
        {
          name: 'paperspace_width',
          label: <Trans>Paperspace width (X axis)</Trans>,
          content: (
            <>
              <UnitOfMeasurementField name="paperspace_width" unit="mm" />
              <FieldError name="paperspace_width" />
            </>
          ),
        },
        {
          name: 'paperspace_height',
          label: <Trans>Paperspace height (Y axis)</Trans>,
          content: (
            <>
              <UnitOfMeasurementField name="paperspace_height" unit="mm" />
              <FieldError name="paperspace_height" />
            </>
          ),
        },
      ]}
    />
  );
}
