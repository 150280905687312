import React from 'react';
import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { FormikProps } from 'formik';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import ButtonShowModal, {
  ModalContentProps,
} from 'components/base/modal/buttonshowmodal';
import {
  StoredPlotSaveAsModal,
  StoredPlotSaveAsValues,
} from './StoredPlotSaveAsModal';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { NewStoredPlotDefaults } from './stored-plot-edit-utils';

export function StoredPlotSaveCancelButtons<
  T extends StoredPlotSaveAsValues & { area: number }
>(props: {
  onCancel: () => void;
  formik: FormikProps<T>;
  newPlotDefaults: NewStoredPlotDefaults | null;
  disableSaveAs?: boolean;
  areaOptions: { value: number; label: string; timeZone: string }[];
}) {
  const { formik, disableSaveAs, newPlotDefaults, areaOptions } = props;

  return (
    <ActionBlock>
      <Button
        data-testid={`stored-plot-edit-cancel-button`}
        onClick={props.onCancel}
      >
        <Trans>Cancel</Trans>
      </Button>
      {newPlotDefaults ? (
        <ButtonPrimary
          data-testid={`stored-plot-edit-submit-button`}
          type="submit"
          iconType="icon-save"
        >
          <Trans>Save</Trans>
        </ButtonPrimary>
      ) : (
        <>
          <ButtonShowConfirmation
            name={`stored-plot-edit-submit-confirm-button`}
            data-testid={`stored-plot-edit-submit-confirm-button`}
            iconType="icon-save"
            disabled={formik.isSubmitting}
            destructive={false}
            onConfirm={async () => {
              if (!formik) return;
              formik.submitForm();
            }}
            content={
              <Trans>
                Are you sure you want to save changes to this stored plot? This
                will overwrite the previous settings. This action is not
                reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, save</Trans>}
          >
            <Trans>Save</Trans>
          </ButtonShowConfirmation>
          {!disableSaveAs ? (
            <ButtonShowModal
              className="btn-primary"
              name={`stored-plot-edit-save-as-button`}
              data-testid={`stored-plot-edit-save-as-button`}
              disabled={formik.isSubmitting}
              iconType="icon-save"
              modalContent={({ hideModal }: ModalContentProps) => (
                <StoredPlotSaveAsModal
                  formik={formik}
                  areaOptions={areaOptions}
                  hideModal={hideModal}
                />
              )}
            >
              <Trans>Save as...</Trans>
            </ButtonShowModal>
          ) : null}
        </>
      )}
    </ActionBlock>
  );
}
