import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfileView } from 'screens/userprofile/userprofileview';
import {
  fetchUserProfile,
  updateCurrentUserProfile,
  unmountUserProfileScreen,
} from 'ducks/userprofile';
import { FullState } from 'main/reducers';
import { Model } from 'util/backendapi/models/api.interfaces';
import { parseNumberParamFromRouterProps } from 'util/routing';
import { RouteComponentProps } from 'react-router';

interface Props extends RouteComponentProps<{ userId?: string }> {}

export function UserProfileScreen(props: Props) {
  const dispatch = useDispatch();

  const userProfileState = useSelector((state: FullState) => state.userProfile);
  const loggedInAs = useSelector((state: FullState) => state.user.loggedInAs);

  // Display the user whose ID is in the URL. Or, if there is no ID in the URL,
  // display the current user.
  const userId = parseNumberParamFromRouterProps(props, 'userId', loggedInAs);

  const handleSubmit = React.useCallback(
    async (values: Model.CurrentUser_PATCH) =>
      userProfileState.user &&
      userProfileState.user.id === loggedInAs &&
      dispatch(updateCurrentUserProfile(values)),
    [dispatch, userProfileState.user, loggedInAs]
  );

  React.useEffect(() => {
    return () => {
      dispatch(unmountUserProfileScreen());
    };
    // Fire the unmount action whenever the user to view changes
  }, [dispatch, userId]);

  React.useEffect(() => {
    if (userId && userId !== userProfileState.userId) {
      dispatch(fetchUserProfile(userId));
    }
  }, [dispatch, userId, userProfileState.userId]);

  return (
    <UserProfileView
      {...userProfileState}
      isCurrentUser={Boolean(
        userProfileState.user && userProfileState.user.id === loggedInAs
      )}
      onSubmit={handleSubmit}
    />
  );
}
