import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { refreshCurrentUserData } from 'ducks/login';
import { FullState } from 'main/reducers';
import Loading from '../../base/loading/loading';
import { IdleTimeoutManager } from './IdleTimeoutManager';
import { TokenSyncManager } from './TokenSyncManager';
import { TokenRefreshManager } from './TokenRefreshManager';
import { useShallowEqualSelector } from 'util/hooks';
import { GlobalActionCreators } from 'ducks/global';

import { BroadcastChannel } from 'broadcast-channel';

const LOGIN_CHECK_REQUEST = 'loginCheckRequest';
const LOGIN_CHECK_RESPONSE = 'loginCheckResponse';

// How long to wait for the broadcast channel to respond before assuming
// there are no other tabs logged into DMS
const LOGIN_CHECK_TIMEOUT_MS = 2000;

const LOGIN_CHECK_SESSION_STATE = 'loginSessionState';

/* Browsers may keep the sessionState when the user reopens their browser
   if the user has enabled the restore previous tabs option. To distinguish
   this case, when the page is unloaded we set LOGIN_SESSION_UNLOAD_TIME to the
   current time. If the page is loaded again after more than LOGIN_SESSION_RELOAD_TIMEOUT_MS
   we will consider LOGIN_CHECK_SESSION_STATE expired and delete it so the user has to login.
*/
const LOGIN_SESSION_UNLOAD_TIME = 'loginSessionUnloadTime';
const LOGIN_SESSION_RELOAD_TIMEOUT_MS = 30000;

window.addEventListener('beforeunload', function (event) {
  if (sessionStorage[LOGIN_CHECK_SESSION_STATE]) {
    // console.log('Setting LOGIN_SESSION_UNLOAD_TIME')
    sessionStorage[LOGIN_SESSION_UNLOAD_TIME] = new Date().toISOString();
  }
});

function getHasLoggedInSessionState(): boolean | null {
  const unloadTimeISOString = sessionStorage[LOGIN_SESSION_UNLOAD_TIME];
  if (unloadTimeISOString) {
    const unloadTimeMilliseconds = new Date(unloadTimeISOString).valueOf();
    if (unloadTimeMilliseconds) {
      const elapsedTimeMilliseconds =
        new Date().valueOf() - unloadTimeMilliseconds;
      // console.log('Time since page unloaded:', elapsedTimeMilliseconds);
      if (elapsedTimeMilliseconds > LOGIN_SESSION_RELOAD_TIMEOUT_MS) {
        // console.log('Login session state expired. Removing.')
        delete sessionStorage[LOGIN_CHECK_SESSION_STATE];
      }
      delete sessionStorage[LOGIN_SESSION_UNLOAD_TIME];
    }
  }

  if (sessionStorage[LOGIN_CHECK_SESSION_STATE]) {
    return Boolean(sessionStorage[LOGIN_CHECK_SESSION_STATE]);
  }

  return null;
}

/**
 * A component for handling the authentication session. Much of its logic is
 * split into multiple sub-components: IdleTimeoutManager, TokenRefreshManager,
 * and TokenSyncManager.
 *
 * Unfortunately the whole of session management is spread out across a few
 * different parts of the code. In general, the Redux `state.auth` should be the
 * "source of truth" for auth:
 *
 * 1. Components that need to CHANGE the current auth state should do so by
 * dispatching the appropriate Redux actions (mostly in `login.ts`).
 *     1. SessionManager - log out if tokens expired, fetch "current" user & permissions
 *     2. IdleTimeoutManager - log out user if idle
 *     3. TokenRefreshManager - while logged in, refresh the tokens
 *     4. TokenSyncManager - update tokens in Redux when another tab has changed them
 *     5. Login screen
 *     6. Logout button
 *     7. "Switch group" button
 * 2. Components that need to RESPOND TO the auth state should read from
 * Redux's `state.auth` (for tokens) and `state.user` (for user ID and permissions)
 *     1. SessionManager, TokenRefreshManager, TokenSyncManager
 *     2. PrivateRoute - redirect if you're not logged in or lack permissions
 *     3. HasPermission
 * 3. Redux middleware should be used to propagate changes in auth state to other
 * places
 *     1. AUTH_GLOBALS (global variables used in our fetch API)
 *     2. LocalStorage (via react-localstorage-simple) for persistence between
 *     page loads, and propagating auth updates to other tabs
 *         * Changes in LocalStorage trigger StorageEvents in other open tabs,
 *           which are caught by that tab's TokenRefreshManager and then update
 *           that tab's Redux store.
 *
 * @param props
 */

export function SessionManager(props: { children: React.ReactElement }) {
  const dispatch = useDispatch();

  const [hasLoggedIn, setHasLoggedIn] = useState<boolean | null>(
    getHasLoggedInSessionState()
  );

  const broadcastChannel = useMemo(() => {
    // console.log('Setup broadcast channel');
    const channel = new BroadcastChannel('dms');
    return channel;
  }, []);

  const {
    loggedInAs,
    loading: loadingUserData,
    hasPerformedLogin,
    accessToken,
    refreshToken,
    tokenExpiry,
  } = useShallowEqualSelector((state: FullState) => {
    const tokenExpiryEpoch =
      state.auth.tokenExpiryTime === null
        ? null
        : new Date(state.auth.tokenExpiryTime).valueOf();

    return {
      loggedInAs: state.user.loggedInAs,
      loading: state.user.loading,
      hasPerformedLogin: state.user.hasPerformedLogin,
      accessToken: state.auth.accessToken,
      refreshToken: state.auth.refreshToken,
      tokenExpiry:
        tokenExpiryEpoch === null || Number.isNaN(tokenExpiryEpoch)
          ? null
          : tokenExpiryEpoch,
    };
  });

  const loggedInSomewhere = hasPerformedLogin || hasLoggedIn;

  const timerRef = useRef<NodeJS.Timer>();

  broadcastChannel.onmessage = useCallback(
    (message) => {
      // console.log('Received message', message);
      if (message === LOGIN_CHECK_RESPONSE && !hasLoggedIn) {
        // If we receive a login check response and we're not logged in, set hasLoggedIn to true
        // console.log('Received LOGIN_CHECK_RESPONSE. Setting hasLoggedIn=true');
        setHasLoggedIn(true);
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      }
      if (message === LOGIN_CHECK_REQUEST && loggedInSomewhere) {
        // If we receive a login check request message and we're logged in, reply loggedIn
        // console.log('Received LOGIN_CHECK_REQUEST. Sending LOGIN_CHECK_RESPONSE');
        broadcastChannel.postMessage(LOGIN_CHECK_RESPONSE);
      }
    },
    [broadcastChannel, hasLoggedIn, loggedInSomewhere, setHasLoggedIn]
  );

  useEffect(
    () => {
      // If we have an access token need to check if the user has logged in during
      // this browser session.

      if (accessToken && !loggedInSomewhere) {
        // console.log('has not logged in yet, ask other tabs');
        broadcastChannel.postMessage(LOGIN_CHECK_REQUEST);

        timerRef.current = setTimeout(() => {
          // console.log('No logged in confirmation received. logging out.');
          setHasLoggedIn(false);
        }, LOGIN_CHECK_TIMEOUT_MS);

        // If there is no accessToken go straight to hasLoggedIn=false
      } else if (!accessToken && hasLoggedIn === null) {
        // console.log('No access token. logging out. Setting hasLoggedIn=false');
        setHasLoggedIn(false);
      }
    },
    // NOTE: This only done once this when page is first loaded, so no dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (loggedInSomewhere && !sessionStorage[LOGIN_CHECK_SESSION_STATE]) {
      // console.log('Logged in. Setting LOGIN_CHECK_SESSION_STATE = true');
      sessionStorage[LOGIN_CHECK_SESSION_STATE] = true;
    }
  }, [loggedInSomewhere]);

  useEffect(() => {
    if (hasLoggedIn === false) {
      // Log out if no tabs logged in
      // console.log('Not logged in. Logging out by clearing state.');
      dispatch(GlobalActionCreators.CLEAR_STATE());
    }
  }, [hasLoggedIn, dispatch]);

  useEffect(() => {
    if (
      (accessToken || refreshToken || tokenExpiry) &&
      !(accessToken && refreshToken && tokenExpiry)
    ) {
      // Incomplete `state.auth`; clear the Redux state and start over fresh.
      dispatch(GlobalActionCreators.CLEAR_STATE());
    } else if (tokenExpiry !== null && tokenExpiry < Date.now()) {
      // Expired refresh token; log the user out.
      dispatch(GlobalActionCreators.CLEAR_STATE());
    }
  }, [accessToken, dispatch, refreshToken, tokenExpiry]);

  const isAuthenticated = Boolean(accessToken);

  useEffect(() => {
    /**
     * Request user info if you have auth tokens, but no "current user" data.
     *
     * This should happen when:
     * 1. You have just successfully passed the login screen
     * 2. You have just reloaded the page and had auth tokens in localStorage
     *    and the user performed a login from the login screen during this browser session
     *
     * NOTE: state.auth is persisted to localStorage, but state.user is not
     */

    if (
      isAuthenticated &&
      loggedInSomewhere &&
      !loggedInAs &&
      !loadingUserData
    ) {
      dispatch(refreshCurrentUserData());
    }
  }, [
    loggedInSomewhere,
    loggedInAs,
    dispatch,
    isAuthenticated,
    loadingUserData,
  ]);

  return (
    <>
      <TokenSyncManager />
      <TokenRefreshManager
        jitter={true}
        dispatch={dispatch}
        tokenExpiry={tokenExpiry}
      />
      {loadingUserData || hasLoggedIn === null ? (
        <Loading />
      ) : (
        <>
          {props.children}
          {/* Only start the idle timer if you're logged in. */}
          {isAuthenticated && <IdleTimeoutManager />}
        </>
      )}
    </>
  );
}
