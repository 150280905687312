import lodashGet from 'lodash/get';
import { t } from '@lingui/macro';
import { AnyAction } from 'redux';
import { StandardThunk } from '../main/store';
import { getApi } from '../util/backendapi/fetch';
import { Model } from '../util/backendapi/models/api.interfaces';
import { TimeZone } from 'util/backendapi/types/Model';

/***
 * A redux module ("duck") for handling normalized data, which is typically
 * called "entities" in the Redux ecosystem.
 * @see https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
 */

/**
 * Our entity types!
 */
export enum EntityTypes {
  AREA = 'AREA',
  AREA_ALL = 'AREA_ALL',
  DATA_LOGGER = 'DATA_LOGGER',
  FORMULA = 'FORMULA',
  FORMULA_OUTPUT = 'FORMULA_OUTPUT',
  INSTRUMENT_TYPE = 'INSTRUMENT_TYPE',
  OBSERVATION_POINT_CLASSIFICATION = 'OBSERVATION_POINT_CLASSIFICATION',
  OBSERVATION_POINT_GRID_REFERENCE = 'OBSERVATION_POINT_GRID_REFERENCE',
  OBSERVATION_POINT_RELIABILITY = 'OBSERVATION_POINT_RELIABILITY',
  OBSERVATION_POINT_TUBING_TYPE = 'OBSERVATION_POINT_TUBING_TYPE',
  READING = 'READING',
  READINGS_FILE = 'READINGS_FILE',
  TIMEZONE = 'TIMEZONE',
  USER = 'USER',
}

/**
 * A dictionary to indicate what model type we store for each of these
 * entities. Some of them are "any" because they're storing the old "flat"
 * data types, and we haven't written up typescript types for those yet.
 */
export interface EntityModels {
  AREA: Model.AreaDecorated;
  AREA_ALL: Model.Area;
  DATA_LOGGER: Model.DataLoggerDecorated;
  FORMULA: Model.FormulaDecorated;
  FORMULA_OUTPUT: Model.FormulaOutputDecorated;
  INSTRUMENT_TYPE: Model.InstrumentType;
  OBSERVATION_POINT_CLASSIFICATION: Model.ObservationPointClassification;
  OBSERVATION_POINT_GRID_REFERENCE: Model.ObservationPointGridReference;
  OBSERVATION_POINT_RELIABILITY: Model.ObservationPointReliability;
  OBSERVATION_POINT_TUBING_TYPE: Model.ObservationPointTubingType;
  READING: Model.Reading;
  READINGS_FILE: Model.ReadingsFileDecorated;
  TIMEZONE: TimeZone;
  USER: Model.User;
}

/**
 * The shape of data we store for each type of entity.
 * @see https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
 */
type EntityListData<T extends { id: number }> = {
  byId: { [id: number]: T };
  allIds: number[];
};

/**
 * The detailed type for the data you'll get from the entity store, for a given
 * type.
 *
 * @example
 *
 * const allAreas: EntityList<EntityTypes.AREA> = // etc
 *
 */
export type EntityList<T extends EntityTypes> = EntityListData<EntityModels[T]>;

/**
 * Functions to "fetch all" for different entity types.
 */
export const EntityListEndpoints: {
  [E in EntityTypes]?: () => Promise<Array<EntityModels[E]>>;
} = {
  AREA: () => getApi('/areas/'),
  AREA_ALL: () => getApi('/all-areas/'),
  DATA_LOGGER: () => getApi('/data-loggers/'),
  FORMULA: () => getApi('/formulas/'),
  FORMULA_OUTPUT: () => getApi('/formula-outputs/'),
  INSTRUMENT_TYPE: () => getApi('/instrument-types/'),
  OBSERVATION_POINT_CLASSIFICATION: () =>
    getApi('/observation-point-classifications/'),
  OBSERVATION_POINT_GRID_REFERENCE: () =>
    getApi('/observation-point-grid-references/'),
  OBSERVATION_POINT_RELIABILITY: () =>
    getApi('/observation-point-reliabilities/'),
  OBSERVATION_POINT_TUBING_TYPE: () =>
    getApi('/observation-point-tubing-types/'),
  TIMEZONE: () => getApi('/time-zones/'),
  USER: () => getApi('/users/', { is_active: true }),
};

export enum ActionTypes {
  FETCH_ALL_OF_TYPE = 'dms/entities/FETCH_ALL_OF_TYPE',
  FETCH_ALL_OF_TYPE_RESPONSE = 'dms/entities/FETCH_ALL_OF_TYPE_RESPONSE',
  FETCH_ALL_OF_TYPE_ERROR = 'dms/entities/FETCH_ALL_OF_TYPE_ERROR',
  FETCH_SINGLE_OF_TYPE = 'dms/entities/FETCH_SINGLE_OF_TYPE',
  FETCH_SINGLE_OF_TYPE_RESPONSE = 'dms/entities/FETCH_SINGLE_OF_TYPE_RESPONSE',
  FETCH_SINGLE_OF_TYPE_ERROR = 'dms/entities/FETCH_SINGLE_OF_TYPE_ERROR',
}

export enum SubActionTypes {
  REPLACE = 'dms/entities/REPLACE',
  ADD = 'dms/entities/ADD',
}

const ACT = SubActionTypes;

type EntitySubAction = {
  entityType: EntityTypes;
  actionType: SubActionTypes;
  payload: any[];
};

export type ActionWithEntities<Action = AnyAction> = Action & {
  meta?: {
    entities?: EntitySubAction[];
  };
};

/**
 * An action decorator, to add an "entities" property to another action,
 * which contains information about entities to add to the store.
 *
 * This "replace" function will replace all entities of the specified type.
 *
 * @export
 * @param {object} mainAction a redux action
 * @param {string} entityType
 * @param {object[]} [entities=[]]
 * @param {boolean} [replaceAll=true] If false, the entities in this list
 * will be merged into the existing list of entities of this type. If true,
 * the existing list will be deleted, and this new list will replace it.
 * @return {object} The redux action, mutated to include this "sub-action"
 */
export function withInsertEntities<A extends AnyAction, E extends EntityTypes>(
  mainAction: A,
  entityType: E,
  entities: Array<EntityModels[E]> = [],
  replaceAll: boolean = true
): A & { meta: A['meta'] & { entities: EntitySubAction[] } } {
  let singleSubAction: EntitySubAction = {
    entityType,
    actionType: replaceAll ? ACT.REPLACE : ACT.ADD,
    payload: entities,
  };
  let entitiesSubActionList: EntitySubAction[];
  if (mainAction.meta && mainAction.meta.entities) {
    entitiesSubActionList = [...mainAction.meta.entities, singleSubAction];
  } else {
    entitiesSubActionList = [singleSubAction];
  }

  return {
    ...mainAction,
    meta: {
      ...mainAction.meta,
      entities: entitiesSubActionList,
    },
  };
}

export type EntitiesState = { [E in EntityTypes]: EntityList<E> };

/**
 * Store shape for normalized data.
 * Each entity type is an object with two fields:
 *  byId: {} an object with ID for key, entity for value. For easy indexed
 * lookup of a specific entity.
 *  allIds: [] an array holding only the IDs, in "order", whatever the order is.
 */
const entitiesInitialState: EntitiesState = Object.values(EntityTypes).reduce(
  function (acc, name): EntitiesState {
    return {
      ...acc,
      [name]: {
        byId: {},
        allIds: [],
      },
    };
  },
  {} as EntitiesState
);

/**
 * Our reducer. It looks for a list of "entities" sub-actions under
 * action.meta.entities, and if present, evaluates each sub-action to insert
 * the attached entities into the right part of the store.
 *
 * @export
 * @param {*} [curState=entitiesInitialState]
 * @param {*} action
 */
export default function entitiesReducer(
  curState = entitiesInitialState,
  action: ActionWithEntities
): EntitiesState {
  if (!action.meta || !action.meta.entities) {
    return curState;
  }

  // I could do this iteration a little bit more elegantly with
  // action.meta.entities.reduce(...), but I think that would be harder for
  // non-fulltime-JS devs to make sense of.
  let newState = curState;
  for (let i = 0; i < action.meta.entities.length; i++) {
    const subAction = action.meta.entities[i];

    switch (subAction.actionType) {
      case ACT.REPLACE:
        newState = {
          ...newState,
          [subAction.entityType]: arrayToEntityList(subAction.payload),
        };
        break;
      case ACT.ADD: {
        const current = newState[subAction.entityType];
        const newIds = subAction.payload.map((entity) => entity.id);
        newState = {
          ...newState,
          [subAction.entityType]: {
            byId: {
              ...current.byId,
              ...arrayToByIdObj(subAction.payload),
            },
            allIds: [
              // Put newly created entities at the front of the list.
              ...newIds.filter((id) => !current.allIds.includes(id)),
              ...current.allIds,
            ],
          },
        };
        break;
      }
      // Since this is a for-loop mutating newState, we don't actually
      // need to do anything in the default case...
      default:
    }
  }

  return newState;
}

/**
 * A helper function to turn an array of entities into a "byId" object,
 * where each value is an entity, and each key is the entity's id.
 * @param {array} a
 * @return {object}
 */
function arrayToByIdObj<T extends { id: number } = any>(
  a: T[]
): Record<number, T> {
  return a.reduce(function (accumulator, entity) {
    return {
      ...accumulator,
      [entity.id]: entity,
    };
  }, {});
}

export function arrayToEntityList<T extends { id: number } = any>(
  a: T[]
): EntityListData<T> {
  return {
    byId: arrayToByIdObj(a),
    allIds: a.map((item) => item.id),
  };
}

//////////// Selectors! /////////////

const emptySelection = { byId: {}, allIds: [] };

/**
 * Get all the entities of a particular type.
 * @export
 * @param {object} state
 * @param {string} entityType
 * @return {object} with "byId" and "allIds" fields
 */
export function selectAll<E extends EntityTypes = any>(
  state: { entities: EntitiesState },
  entityType: E
): EntityList<E> {
  return (state.entities[entityType] as EntityList<E>) || emptySelection;
}

/**
 * Get an ordered array of all the entities of a particular type
 * @export
 * @param {object} state
 * @param {string} entityType
 * @return {object[]} An array of entity objects
 */
export function selectAllInOrderedArray<E extends EntityTypes = any>(
  state: { entities: EntitiesState },
  entityType: E
): Array<EntityModels[E]> {
  const { byId, allIds } = selectAll(state, entityType);
  return allIds.map((id) => byId[id]);
}

/**
 * Get entities with specified values in specified fields.
 *
 * @export
 * @param {object} state
 * @param {string} entityType
 * @param {object} matching - {key,value} object to match, with key can be in
 * lodash's get style for filtering by nested field eg. `a[0].b.c`
 * @return {object[]} An array of entity objects
 */
export function selectMatching<E extends EntityTypes = any>(
  state: { entities: EntitiesState },
  entityType: E,
  matching: Record<string, any>
): Array<EntityModels[E]> {
  // TODO optimize this. Currently I'm getting all of them, and then doing
  // array.filter to remove the ones that don't match, which takes O(n) time.
  // If we need to do this a lot, with a large number of records, then we may
  // want to employ some kind of indexing scheme to speed things up.
  return selectAllInOrderedArray(state, entityType).filter((item) =>
    // Compare each field in "matching" to the field of the same name
    Object.entries(matching).every(function ([matchKey, matchValue]) {
      return lodashGet(item, matchKey) === matchValue;
    })
  );
}

/**
 * Get the entity instance with the specified id
 * @export
 * @param state
 * @param entityType
 * @param id
 * @return One entity object (or undefined)
 */
export function selectOneById<E extends EntityTypes = any>(
  state: { entities: EntitiesState },
  entityType: E,
  id: number
): EntityModels[E] | null {
  return selectAll(state, entityType).byId[id] || null;
}

/**
 * A generic method for fetching all the entities of a particular type.
 *
 * TODO: Put a flag in the store to indicate whether a particular entity type
 * has already been fetch-all'ed, so we can skip that step. The tricky thing
 * about doing that right, is that if another request tries to fetch the same
 * thing *while* a fetch is on-going, you'd ideally want it to be able to wait
 * for that first promise to come back.
 *
 * @export
 * @param {*} entityType
 * @returns {object} On success, returns all the entities of the selected type,
 * from the redux store. On error, returns the dispatched error action object.
 */
export function fetchEntityList(entityType: EntityTypes): StandardThunk<any> {
  return async function (dispatch, getState, { i18n }) {
    dispatch(fetchEntityList.actionStart(entityType));

    let fetchAllFunc: (() => Promise<any[]>) | undefined = undefined;
    let Model: any = null;
    fetchAllFunc = EntityListEndpoints[entityType];
    if (!fetchAllFunc) {
      return dispatch(
        fetchEntityList.actionError(
          entityType,
          i18n._(
            t`Entity type "${entityType}" does not yet support entities.fetchEntityList`
          )
        )
      );
    }

    try {
      let resultFromJson = (await fetchAllFunc()) as
        | { error: true; errorMessage: string }
        | { data: any[] }
        | any[];
      // Some of the models catch their own errors and turn them into properties
      // of the result like this. Others just throw the error directly.
      // TODO: regularize that...
      if ('error' in resultFromJson && resultFromJson.error) {
        throw new Error(resultFromJson.errorMessage);
      } else if ('data' in resultFromJson) {
        resultFromJson = resultFromJson.data;
      }

      // We're trying to get to the point that we can completely get rid of `Model.fromEndpointFormat`
      // and use directly what comming back from the backend response. This will help to reduce the mental
      // overheads of converting back and forth the response as well as the error. For now, the situation
      // that we have to live with is some Models still support `fromEndpointFormat`, and the others not.
      const result: any =
        Model && typeof Model.fromEndpointFormat === 'function'
          ? (resultFromJson as []).map((item) => Model.fromEndpointFormat(item))
          : resultFromJson;
      dispatch(fetchEntityList.actionResponse(entityType, result));
      // Special case: Instead of returning the final dispatched action, it
      // returns the "selectAll" for the fetched Entity type.
      return selectAll(getState(), entityType);
    } catch (e) {
      return dispatch(fetchEntityList.actionError(entityType, e.message));
    }
  };
}
fetchEntityList.actionStart = function (entityType: EntityTypes) {
  return {
    type: ActionTypes.FETCH_ALL_OF_TYPE,
    meta: { entityType },
  };
};
fetchEntityList.actionResponse = function (
  entityType: EntityTypes,
  entities: any[]
) {
  return withInsertEntities(
    {
      type: ActionTypes.FETCH_ALL_OF_TYPE_RESPONSE as ActionTypes.FETCH_ALL_OF_TYPE_RESPONSE,
      payload: entityType,
    },
    entityType,
    entities
  );
};
fetchEntityList.actionError = function (
  entityType: EntityTypes,
  errorMessage: string
) {
  return {
    type: ActionTypes.FETCH_ALL_OF_TYPE_ERROR,
    payload: errorMessage,
    error: true,
    meta: {
      entityType,
    },
  };
};
