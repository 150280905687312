import React, { useMemo, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useGetApi } from 'hooks/use-get-api';
import { Model } from 'util/backendapi/models/api.interfaces';
import { errorToString } from 'util/backendapi/error';
import { isNotNull } from 'util/validation';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import {
  GOOGLE_MAPS_API_KEY,
  hasLatLong,
  GOOGLE_MAP_OPTIONS,
  MAP_CLUSTERER_OPTIONS,
} from '../map-utils';
import {
  useLoadScript,
  GoogleMap,
  MarkerClusterer,
} from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import useResizeObserver from 'hooks/use-resize-observer';
import { AlertDanger, AlertInfo } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import {
  ObservationPointMarker,
  MapMarkerObservationPoint,
} from './ObservationPointMarker';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { AreaMapSettingsModal } from './AreaMapSettingsModal';
import { DMSLink } from 'components/base/link/DMSLink';

interface Props extends RouteComponentProps<{ areaCode: string }> {}

export function AreaMapScreen(props: Props) {
  const areaCode = props.match.params.areaCode;

  const [areaFetch, , setAreaParams] = useGetApi('/areas/', {
    code: areaCode,
  });

  useEffect(() => setAreaParams({ code: areaCode }), [areaCode, setAreaParams]);

  const area = areaFetch.data?.[0] ?? null;

  const [observationPointsFetch, setUrl, setParams] = useGetApi(
    '' as '/observation-points/'
  );

  const observationPoints: MapMarkerObservationPoint[] | null =
    observationPointsFetch.data;

  useEffect(() => {
    if (area) {
      setUrl('/observation-points/');
      setParams({
        site__area__in: [area.id],
        fields: [
          'id',
          'code',
          'instrument_type',
          'latest_reading',
          'name',
          'time_zone',
          'wgs84_coordinates',
        ],
      });
    }
  }, [area, setParams, setUrl]);

  return (
    <AreaMapView
      key={areaCode}
      areaCode={areaCode}
      area={area}
      observationPoints={observationPoints}
      isLoading={areaFetch.isLoading || observationPointsFetch.isLoading}
      isError={areaFetch.isError || observationPointsFetch.isError}
      error={[
        areaFetch.isError ? errorToString(areaFetch.error) : null,
        observationPointsFetch.isError
          ? errorToString(observationPointsFetch.error)
          : null,
      ]
        .filter(isNotNull)
        .join('; ')}
    />
  );
}

interface ViewProps {
  areaCode: string;
  area: Model.AreaDecorated | null;
  observationPoints: MapMarkerObservationPoint[] | null;
  isLoading: boolean;
  isError: boolean;
  error: any;
}

export function AreaMapView(props: ViewProps) {
  const observationPoints = useMemo(
    () =>
      props.observationPoints ? props.observationPoints.filter(hasLatLong) : [],
    [props.observationPoints]
  );

  const googleMapsScript = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const [ref, width, height] = useResizeObserver<HTMLDivElement>({
    defaultWidth: 800,
    defaultHeight: 650,
  });

  const bounds = useMemo(() => {
    if (!googleMapsScript.isLoaded) {
      return null;
    }
    const bounds = new google.maps.LatLngBounds();
    (observationPoints || []).forEach((op) =>
      bounds.extend(op.wgs84_coordinates)
    );
    return bounds;
  }, [observationPoints, googleMapsScript.isLoaded]);

  const hasError = Boolean(props.isError || googleMapsScript.loadError);
  const error = props.error || googleMapsScript.isLoaded;
  const [spiderfier, setSpiderfier] = useState<OverlappingMarkerSpiderfier>();
  const [clusterer, setClusterer] = useState<Clusterer>();

  // Query the maximum zoom level available at this location, and make sure
  // we stop clustering before get to that level. (Otherwise, we'll display
  // clusters that can't be un-clustered!)
  useEffect(() => {
    if (googleMapsScript.isLoaded && clusterer && bounds && !bounds.isEmpty()) {
      const maxZoomService = new google.maps.MaxZoomService();
      maxZoomService.getMaxZoomAtLatLng(bounds.getCenter(), (response) => {
        if (response.status === 'OK' && response.zoom) {
          clusterer.setMaxZoom(response.zoom - 1);
        }
      });
    }
  }, [bounds, clusterer, googleMapsScript.isLoaded]);

  return (
    <PageStandard
      name="area-map-screen"
      header={<Trans>Area</Trans>}
      subHeader={props.area?.name}
    >
      <div className="map-page-wrapper">
        <div className="page-content-header columns-fluid">
          <div>
            <DMSLink to="/maps" className="back-link">
              <Trans>Back to groups</Trans>
            </DMSLink>
          </div>
          <ActionBlock className="text-right">
            <ButtonShowModal
              disabled={!props.area}
              iconType="icon-cog"
              name="area-map-settings-button"
              modalContent={(modalProps) => (
                <AreaMapSettingsModal
                  areaCode={props.areaCode}
                  {...modalProps}
                />
              )}
            >
              <Trans>Settings</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </div>
        <div ref={ref} className="map-wrapper">
          {hasError ? (
            <AlertDanger>{errorToString(error)}</AlertDanger>
          ) : props.isLoading || !googleMapsScript.isLoaded || !bounds ? (
            <Loading />
          ) : !props.area ? (
            <AlertInfo>
              <Trans>Could not find area.</Trans>
            </AlertInfo>
          ) : observationPoints.length === 0 ? (
            <AlertInfo>
              <Trans>No observation points found to display on map.</Trans>
            </AlertInfo>
          ) : (
            <GoogleMap
              id="area-map"
              mapContainerStyle={{ height, width }}
              options={GOOGLE_MAP_OPTIONS}
              onLoad={(map) => {
                map.fitBounds(bounds);

                const spiderfier = new OverlappingMarkerSpiderfier(map, {
                  markersWontHide: true,
                  markersWontMove: true,
                  basicFormatEvents: true,
                  keepSpiderfied: true,
                  circleFootSeparation: 32,
                  circleSpiralSwitchover: 16,
                });
                setSpiderfier(spiderfier);
              }}
            >
              <MarkerClusterer
                options={MAP_CLUSTERER_OPTIONS}
                onLoad={setClusterer}
              >
                {(clusterer) =>
                  observationPoints.map((op) => (
                    <ObservationPointMarker
                      key={op.id}
                      observationPoint={op}
                      clusterer={clusterer}
                      spiderfier={spiderfier}
                    />
                  ))
                }
              </MarkerClusterer>
            </GoogleMap>
          )}
        </div>
      </div>
    </PageStandard>
  );
}

AreaMapScreen.WrappedComponent = AreaMapView;
