import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { DataReviewReportView } from './DataReviewReportView';
import { fetchDataReviewReport } from 'ducks/dataReview';
import { RouteComponentProps } from 'react-router';
import {
  setOneQueryParam,
  parseNumberArrayFromQueryParam,
  setQueryParams,
  parseQueryParamFromRouterProps,
  parseNumberQueryParamFromRouterProps,
} from 'util/routing';
import { getApi } from 'util/backendapi/fetch';
import { usePrevious } from 'util/hooks';
import { isEqual } from 'lodash';
import { GetExportUrlFunc } from 'components/modules/exportpanel/exportpanel';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';

export type OwnProps = RouteComponentProps;

export const DataReviewReportScreen: React.FunctionComponent<OwnProps> = (
  props
) => {
  const { isLoading, errorMessage, result } = useSelector(
    (state: FullState) => state.dataReviewReport,
    shallowEqual
  );
  const dispatch = useDispatch();
  const allAreas = parseNumberArrayFromQueryParam(props, 'allAreas', []);
  const previousAllAreas = usePrevious(allAreas);
  const activeArea = parseNumberQueryParamFromRouterProps(props, 'activeArea');
  const startDatetime = parseQueryParamFromRouterProps(props, 'start_datetime');
  const endDatetime = parseQueryParamFromRouterProps(props, 'end_datetime');

  const initialAreaState = { options: [], errorMessage: null };
  const [areaData, setAreaOptions] = useState<{
    options: { value: number; label: string }[];
    errorMessage: string | null;
  }>(initialAreaState);

  useEffect(() => {
    (async () => {
      if (
        allAreas.length &&
        (!isEqual(previousAllAreas, allAreas) || !areaData.options.length)
      ) {
        try {
          const response = await getApi('/reports/areas/', {
            id__in: allAreas,
            columns: ['id', 'code', 'name'],
          });
          const options = response.map((area) => ({
            value: area.id,
            label: `${area.code} - ${area.name}`,
          }));
          setAreaOptions({ options: options, errorMessage: null });
        } catch (e) {
          setAreaOptions({ options: [], errorMessage: e.message });
        }
      }
    })();
  }, [allAreas, previousAllAreas, areaData]);

  useEffect(() => {
    (() => {
      if (activeArea && startDatetime && endDatetime) {
        dispatch(fetchDataReviewReport(activeArea, startDatetime, endDatetime));
      }
    })();
  }, [activeArea, startDatetime, endDatetime, dispatch]);

  const setActiveAreaQueryParam = (activeArea: number) => {
    setOneQueryParam(props, 'activeArea', activeArea);
  };

  const setReviewQueryParams = (
    allAreas: number[],
    activeArea: number,
    start_datetime: string,
    end_datetime: string
  ) => {
    // Clear any existing area options
    setAreaOptions(initialAreaState);
    setQueryParams(props, {
      allAreas: allAreas,
      activeArea: activeArea,
      start_datetime: start_datetime,
      end_datetime: end_datetime,
    });
  };

  const getExportUrl: GetExportUrlFunc = useCallback(
    (
      exportFormat,
      { queryParams },
      i18n,
      generatedDatetime,
      pageOrientation
    ) => {
      queryParams.append('area_ids', allAreas.join(','));
      queryParams.append('start_datetime', startDatetime);
      queryParams.append('end_datetime', endDatetime);
      if (exportFormat === ExportFormats.XLSX) {
        return {
          path: `/export/${exportFormat}/reports/data-review/`,
          queryParams: queryParams,
        };
      } else {
        queryParams.append('page_orientation', pageOrientation!);
        return {
          path: `/export/${exportFormat}/data-review-report/`,
          queryParams: queryParams,
        };
      }
    },
    [allAreas, startDatetime, endDatetime]
  );

  return (
    <DataReviewReportView
      isLoading={isLoading}
      errorMessage={errorMessage}
      allAreaOptions={areaData.options}
      areaOptionsErrorMessage={areaData.errorMessage}
      activeArea={activeArea}
      result={result}
      setActiveAreaQueryParam={setActiveAreaQueryParam}
      setReviewQueryParams={setReviewQueryParams}
      getExportUrl={getExportUrl}
      startDatetime={startDatetime}
      endDatetime={endDatetime}
    />
  );
};
