import React, { useCallback } from 'react';
import { useReportState } from 'hooks/use-report-state';
import { alarmNotificationListDuck } from 'ducks/alarmNotification/list';
import { FullState } from 'main/reducers';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { AlarmNotificationForm } from './AlarmNotificationForm';
import {
  ReportViewProps,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportColumn,
  ReportFilter,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { RouteComponentProps } from 'react-router-dom';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { useDispatch } from 'react-redux';
import {
  deleteAlarmNotification,
  updateAlarmNotification,
} from 'ducks/alarmNotification/detail';
import { useGetApi } from 'hooks/use-get-api';

const yesNoFromBoolean = (flag: boolean) =>
  flag ? <Trans>Yes</Trans> : <Trans>No</Trans>;

type EnabledDisabled = 'enabled' | 'disabled';

export const alarmNotificationsColumns = (
  props: {
    onDeleteAlarmNotification?: (id: number) => Promise<any>;
    onEnableDisableAlarmNotification?: (
      id: number,
      status: EnabledDisabled
    ) => Promise<any>;
  } = {}
): ReportColumn<Model.ReportsAlarmNotification>[] => [
  {
    label: <Trans>Person</Trans>,
    name: 'person',
    backendFieldName: 'user__profile__name',
    additionalFields: ['user__username'],
    visibility: DEFAULT_SHOW,
    accessor: ({ user__profile__name, user__username }) =>
      `${user__profile__name} (${user__username})`,
  },
  {
    label: <Trans>Area</Trans>,
    name: 'area',
    backendFieldName: 'areas__code',
    visibility: DEFAULT_SHOW,
    accessor: ({ areas__code }) => areas__code?.join(', '),
  },
  {
    label: <Trans>Data logger</Trans>,
    name: 'data_logger',
    backendFieldName: 'data_loggers__logger_number',
    visibility: DEFAULT_SHOW,
    accessor: ({ data_loggers__logger_number }) =>
      data_loggers__logger_number?.join(', '),
  },
  {
    label: <Trans>Observation point</Trans>,
    name: 'observation_point',
    backendFieldName: 'observation_points__code',
    visibility: DEFAULT_SHOW,
    accessor: ({ observation_points__code }) =>
      observation_points__code?.join(', '),
  },
  {
    label: <Trans>Design check</Trans>,
    name: 'include_design_check',
    visibility: DEFAULT_SHOW,
    accessor: ({ include_design_check }) =>
      yesNoFromBoolean(include_design_check),
  },
  {
    label: <Trans>Alert</Trans>,
    name: 'include_alert',
    visibility: DEFAULT_SHOW,
    accessor: ({ include_alert }) => yesNoFromBoolean(include_alert),
  },
  {
    label: <Trans>Data check</Trans>,
    name: 'include_data_check',
    visibility: DEFAULT_SHOW,
    accessor: ({ include_data_check }) => yesNoFromBoolean(include_data_check),
  },
  {
    label: <Trans>Email</Trans>,
    name: 'send_email',
    visibility: DEFAULT_SHOW,
    accessor: ({ send_email }) => yesNoFromBoolean(send_email),
  },
  {
    label: <Trans>SMS</Trans>,
    name: 'send_sms',
    visibility: DEFAULT_SHOW,
    accessor: ({ send_sms }) => yesNoFromBoolean(send_sms),
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id', 'status'],
    accessor: ({ id, status }) => (
      <>
        <HasPermission check="can_create_alarm_notifications">
          <ButtonShowModal
            iconOnly
            iconType="icon-edit"
            title={t`Edit alarm notification`}
            className="btn-link-panel"
            name={`edit-alarm-notification-${id}`}
            modalContent={(modalProps) => (
              <AlarmNotificationForm
                alarmNotificationId={id}
                hideModal={modalProps.hideModal}
              />
            )}
          />
        </HasPermission>
        <HasPermission check="can_disable_and_enable_alarm_notifications">
          {status === 'enabled' ? (
            <ButtonShowConfirmation
              name={`disable-alarm-notification-${id}`}
              title={t`Temporarily disable alarm notification`}
              iconType="icon-mute"
              className="btn-link-panel"
              onConfirm={async () =>
                props.onEnableDisableAlarmNotification &&
                props.onEnableDisableAlarmNotification(id, 'disabled')
              }
              okBtnText={<Trans>Yes, disable notification</Trans>}
              content={
                <Trans>
                  Are you sure you want to temporarily disable this alarm
                  notification? No Email or SMS notifications will be sent for
                  matching alarm reports while it is disabled.
                </Trans>
              }
            />
          ) : (
            <ButtonShowConfirmation
              name={`enable-alarm-notification-${id}`}
              title={t`Enable alarm notification`}
              iconType="icon-mute"
              className="btn-link-panel"
              onConfirm={async () =>
                props.onEnableDisableAlarmNotification &&
                props.onEnableDisableAlarmNotification(id, 'enabled')
              }
              okBtnText={<Trans>Yes, enable notification</Trans>}
              content={
                <Trans>
                  Are you sure you want to enable this alarm notification?
                </Trans>
              }
            />
          )}
        </HasPermission>
        <HasPermission check="can_delete_alarm_notifications">
          <ButtonShowConfirmation
            name={`delete-alarm-notification-${id}`}
            title={t`Delete alarm notification`}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            onConfirm={async () =>
              props.onDeleteAlarmNotification &&
              props.onDeleteAlarmNotification(id)
            }
            content={
              <Trans>
                Are you sure you want to delete this alarm notification? This
                action is not reversible.
              </Trans>
            }
          />
        </HasPermission>
      </>
    ),
  },
];

export const alarmNotificationsFilters = (
  props: {
    dataLoggers?: Model.DataLoggerDecorated[] | null;
  } = {}
): ReportFilter[] => [
  reportFilterMenu(
    'user',
    <Trans>Person</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.USER_MENU,
    {
      autoFocus: true,
      placeholder: <Trans>All people</Trans>,
    }
  ),
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
    }
  ),
  reportFilterObservationPoints('observation_point', {
    placeholder: <Trans>All observation points</Trans>,
  }),
  reportFilterMenu(
    'data_logger',
    <Trans>Data logger</Trans>,
    { isMulti: true, valueType: 'number' },
    (dataLogger: Model.DataLoggerDecorated) => ({
      value: dataLogger.id,
      label: dataLogger.logger_number,
    }),
    {
      placeholder: <Trans>All data loggers</Trans>,
      options: props.dataLoggers ?? [],
      isLoading: !props.dataLoggers,
    }
  ),
];

export interface AlarmNotificationListViewProps {
  reportProps: ReportViewProps<Model.ReportsAlarmNotification>;
  onDeleteAlarmNotification: (id: number) => Promise<any>;
  onEnableDisableAlarmNotification: (
    id: number,
    status: EnabledDisabled
  ) => Promise<any>;
  dataLoggers?: Model.DataLoggerDecorated[] | null;
}

export const AlarmNotificationListView = (
  props: AlarmNotificationListViewProps
) => {
  const {
    reportProps,
    dataLoggers,
    onDeleteAlarmNotification,
    onEnableDisableAlarmNotification,
  } = props;
  const columns = alarmNotificationsColumns({
    onDeleteAlarmNotification,
    onEnableDisableAlarmNotification,
  });
  const filters = alarmNotificationsFilters({ dataLoggers });

  return (
    <PageStandard
      name="alarm-notifications-report"
      header={<Trans>Maintain Alarm Notifications</Trans>}
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_alarm_notifications">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="create-alarm-notification"
              iconType="icon-plus"
              shortcut="CREATE_NEW"
              modalContent={({ hideModal }) => (
                <AlarmNotificationForm hideModal={hideModal} />
              )}
            >
              <Trans>Create alarm notification</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>
        <ReportFiltersBlock
          filtersFrontend={filters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="text-right">
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ButtonPrint />
          <ExportReportButton
            fileNameBase={t`Alarm Notifications report`}
            reportUrl="/reports/alarm-notifications/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>
        <ReportTable<Model.ReportsAlarmNotification>
          {...reportProps}
          getTrProps={(row) => ({
            className: row.status === 'enabled' ? undefined : 'table-row-mute',
          })}
          columns={columns}
          filters={filters}
        />
      </div>
    </PageStandard>
  );
};

export const AlarmNotificationsScreen = (props: RouteComponentProps) => {
  const dispatch = useDispatch();
  const handleDeleteAlarmNotification = useCallback(
    async (id: number) => {
      await dispatch(deleteAlarmNotification(id));
      dispatch(alarmNotificationListDuck.refreshReportList());
    },
    [dispatch]
  );

  const handleEnableDisableAlarmNotification = useCallback(
    async (id: number, status: EnabledDisabled) => {
      await dispatch(updateAlarmNotification(id, { status: status }));
      dispatch(alarmNotificationListDuck.refreshReportList());
    },
    [dispatch]
  );

  const [{ data: dataLoggers }] = useGetApi('/data-loggers/');

  const reportState = useReportState(
    props,
    alarmNotificationsColumns(),
    alarmNotificationsFilters(),
    alarmNotificationListDuck,
    (state: FullState) => state.alarmNotification.list
  );

  return (
    <AlarmNotificationListView
      onDeleteAlarmNotification={handleDeleteAlarmNotification}
      onEnableDisableAlarmNotification={handleEnableDisableAlarmNotification}
      reportProps={reportState}
      dataLoggers={dataLoggers}
    />
  );
};
