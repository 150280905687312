import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { Trans, Plural, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { formatDatetimeForDisplay } from 'util/dates';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import {
  getApi,
  prepareApiQueryParams,
  deleteApi,
} from 'util/backendapi/fetch';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { Icon } from 'components/base/icon/icon';
import { isTruthy } from 'util/validation';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { ReportStateProps } from 'hooks/use-report-state';
import { DMSLink } from 'components/base/link/DMSLink';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';

const reportURI: Record<Enum.ReportInfo_REPORT, string> = {
  area_report: '/area/',
  alarm_report: '/alarm-reports/',
  alarm_comments: '/alarm-comments-report/',
  alarm_notification: '/alarm-notifications/',
  alarm_parameter: '/alarm-parameter-report/',
  audit_log_report: '/audit-log/',
  checksheet_instance_report: '/checksheet-instances/',
  checksheet_template_report: '/checksheet-templates/',
  client_report: '/clients/',
  comment_report: '/comments/',
  data_logger_channel_report: '/data-logger-channels/',
  file_gate_report: '/file-gates/',
  inspection_batch: '/inspection-reports/',
  instrument_type_report: '/instrument-types/',
  media_report: '/media/',
  my_exports_report: '/my-exports/',
  observation_point_report: '/observation-point/',
  observation_point_formula_report: '/formula-assignment-report/',
  observation_point_formula_constant_report: '/formula-constants/',
  plot_set: '/plot-sets/',
  reading_comments: '/reading-comments/',
  readings_batch: '/readings-batches/',
  reading_processing_request: '/reading-processing-requests/',
  reading_retirement_request: '/reading-retirement-requests/',
  route_march_schedule_report: '/route-march-schedule/',
  saved_report_report: '/saved-reports/',
  site_report: '/sites/',
  stored_plot: '/stored-plots/',
  user: '/users/',
};

function _reportToMenuItem(
  report: Model.ReportsSavedReport
): SimpleSelectOption<number> {
  return {
    value: report.id,
    label: report.name,
  };
}

export const SavedReportsFilters = (): ReportFilter[] => [
  reportFilterAsyncMenu(
    'id',
    <Trans>Report name</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (searchText: string) =>
        getApi('/reports/saved-reports/', {
          columns: ['id', 'name'],
          name__icontains: searchText,
        }).then((reports) => reports.map(_reportToMenuItem)),
      loadDefaults: (initialValues: number[]) =>
        getApi('/reports/saved-reports/', {
          columns: ['id', 'name'],
          id__in: initialValues,
        }).then((reports) => reports.map(_reportToMenuItem)),
    },
    {
      autoFocus: true,
      placeholder: <Trans>All saved reports</Trans>,
    }
  ),
  reportFilterMenu(
    'report',
    <Trans>Type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('ReportInfo_REPORT'),
    {
      placeholder: <Trans>All report types</Trans>,
    }
  ),
];

const makeReportUrl = function (
  row: Pick<
    Model.ReportsSavedReport,
    'id' | 'filters' | 'columns' | 'ordering' | 'report'
  >
): string | null {
  const baseUrl = reportURI[row.report];
  if (!baseUrl) {
    return null;
  }
  const args = prepareApiQueryParams({
    ...row.filters,
    saved_report_id: row.id,
    columns: row.columns,
    ordering: row.ordering,
  });
  const reportUrl = [baseUrl, args.toString()].filter(isTruthy).join('?');
  return reportUrl;
};

export const SavedReportsColumns = (extraProps?: {
  refreshList: () => void;
}): ReportColumn<Model.ReportsSavedReport>[] => [
  {
    label: <Trans>Report name</Trans>,
    name: 'name',
    additionalFields: ['id'],
    visibility: DEFAULT_SHOW,
    accessor: (row) => {
      const reportUrl = makeReportUrl(row);
      if (!reportUrl) {
        return row.name;
      }
      return <DMSLink to={reportUrl}>{row.name}</DMSLink>;
    },
  },
  {
    label: <Trans>Type</Trans>,
    name: 'report',
    visibility: DEFAULT_SHOW,
    accessor: ({ report: value }) => (
      <TransEnum enum="ReportInfo_REPORT" value={value} />
    ),
  },
  {
    label: <Trans>Saved on</Trans>,
    name: 'datetime',
    visibility: DEFAULT_SHOW,
    accessor: ({ created_datetime: value }) => formatDatetimeForDisplay(value),
  },
  {
    label: <Trans>Saved by</Trans>,
    name: 'user__profile__name',
    visibility: DEFAULT_SHOW,
  },
  {
    ...ACTION_COLUMN,
    additionalFields: [
      'id',
      'filters',
      'columns',
      'ordering',
      'report',
      'name',
    ],
    accessor: function actionCell({
      id,
      filters,
      columns,
      ordering,
      report,
      name,
    }) {
      const reportUrl = makeReportUrl({
        id,
        filters,
        columns,
        ordering,
        report,
      });
      return (
        <>
          {reportUrl && (
            <DMSLink to={reportUrl}>
              <Icon type="icon-view" title={t`View`} />
            </DMSLink>
          )}
          <ButtonShowConfirmation
            name={`delete-saved-report-${id}`}
            title={t`Delete`}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            destructive={true}
            content={
              <Trans>
                Are you sure you want to delete the <strong>{name}</strong>{' '}
                report? This action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async function () {
              await deleteApi(`/saved-reports/${id}/`);
              extraProps?.refreshList();
            }}
          />
        </>
      );
    },
  },
];

export interface SavedReportsViewProps {
  reportProps: ReportStateProps<Model.ReportsSavedReport>;
}

export const SavedReportsView: React.FunctionComponent<SavedReportsViewProps> =
  function (props) {
    const { reportProps } = props;
    const columns = SavedReportsColumns(props.reportProps);
    const filters = SavedReportsFilters();
    return (
      <PageStandard name="saved-reports" header={<Trans>Saved Reports</Trans>}>
        <div className="page-content-header-filters-actions">
          <ReportFiltersBlock
            filtersFrontend={filters}
            filtersBackend={
              reportProps.reportInfo && reportProps.reportInfo.filters
            }
            isExportMode={reportProps.isExportMode}
          />
        </div>

        <div className="filtered-table-wrapper">
          <ActionBlock className="filtered-table-options">
            <ButtonPrint />
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
              // Refresh the table after saving a report, so that the new report
              // can show up in the table!
              onAfterSave={reportProps.refreshList}
            />
            <ExportReportButton
              fileNameBase={t`Saved reports`}
              reportUrl="/reports/saved-reports/"
              columns={columns}
              filters={filters}
            />
          </ActionBlock>

          <ReportTable
            columns={columns}
            errorMessage={reportProps.errorMessage}
            filters={filters}
            isLoading={reportProps.isLoading}
            msgFilterCount={(count) => (
              <Plural
                value={count}
                one="Filtered to 1 saved report"
                other="Filtered to # saved reports"
              />
            )}
            msgNoMatches={
              <Trans>No saved reports match the selected filters.</Trans>
            }
            pagination={reportProps.pagination}
            records={reportProps.records}
            reportInfo={reportProps.reportInfo}
          />
        </div>
      </PageStandard>
    );
  };
