import React from 'react';
import { Trans } from '@lingui/macro';
import { Formik, Form, FormikHelpers } from 'formik';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { FormCard, FormCardSection } from 'components/base/card/card';
import ActionBlock from 'components/base/actionblock/actionblock';
import {
  DataLoggerFormValues,
  DataLoggerFormBody,
  validateDataLoggerForm,
} from '../dataloggerformbody';
import { Model } from 'util/backendapi/models/api.interfaces';

interface NewDataLoggerViewProps {
  areas: Array<Model.AreaDecorated>;
  createNewLogger: (
    values: DataLoggerFormValues,
    actions: FormikHelpers<DataLoggerFormValues>
  ) => void;
  handleCancel: (e: React.SyntheticEvent) => void;
}

export class NewDataLoggerView extends React.Component<NewDataLoggerViewProps> {
  render() {
    const pageTitle = <Trans>Create a New Data Logger</Trans>;
    if (this.props.areas.length === 0) {
      return (
        <PageStandard name="newdatalogger" header={pageTitle}>
          <Loading />
        </PageStandard>
      );
    }

    return (
      <PageStandard name="newdatalogger" header={pageTitle}>
        <Formik<DataLoggerFormValues>
          validateOnBlur={false}
          initialValues={{
            logger_number: '',
            area: '',
            site: '',
            model: '',
            install_date: '',
            install_by: '',
            install_comments: '',
            null_reading_observation_point: '',
            missed_download_observation_point: '',
            maintain_observation_point_latest_readings: false,
            reading_frequency: null,
            reading_frequency_tolerance: null,
            download_frequency: null,
            download_frequency_tolerance: null,
            number_of_channels: '16',
            data_logger_time_dependent_fields: {
              daylight_saving_handling: [
                {
                  isEditing: false,
                  value: null,
                  start_datetime: '',
                },
              ],
            },
          }}
          onSubmit={(values, actions) => {
            this.props.createNewLogger(values, actions);
          }}
          validate={validateDataLoggerForm}
        >
          {(formik) => {
            const buttons = (
              <ActionBlock>
                <Button
                  data-testid="create-data-logger-cancel"
                  onClick={this.props.handleCancel}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <ButtonPrimary
                  data-testid="create-data-logger-submit"
                  iconType="icon-circle-plus"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Create data logger</Trans>
                </ButtonPrimary>
              </ActionBlock>
            );
            return (
              <Form>
                <FormCard
                  name="general"
                  header={<Trans>New data logger</Trans>}
                  subHeader={buttons}
                >
                  <DataLoggerFormBody
                    formik={formik}
                    CardSectionComponent={FormCardSection}
                    isEditing={true}
                    dataLogger={null}
                    nullReadingObsPoint={null}
                    missedDownloadObsPoint={null}
                    areas={this.props.areas}
                  />
                  <FormCardSection name="card-actions-bottom">
                    {buttons}
                  </FormCardSection>
                </FormCard>
              </Form>
            );
          }}
        </Formik>
      </PageStandard>
    );
  }
}
