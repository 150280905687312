import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import {
  ExportPanel,
  GetExportUrlFunc,
} from 'components/modules/exportpanel/exportpanel';
import React from 'react';
import { AlertDanger, AlertInfo } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { formatDatetimeForDisplay } from 'util/dates';
import {
  TimeSeriesPlot,
  TimeSeriesPlotProps,
} from 'components/plots/timeseriesplot';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { SavePlotModal } from './SavePlotModal';
import { QuickPlotSettingsModal } from './settings/QuickPlotSettingsModal';
import Button from 'components/base/button/button';
import {
  ObservationPointItemUrlCode,
  TSPlotOnZoomFn,
} from 'components/plots/timeseriesplot.types';
import { isTruthy } from 'util/validation';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { HasPermission } from 'components/logic/has-permission/HasPermission';

export interface QuickPlotViewProps {
  minDatetime: string | null;
  maxDatetime: string | null;
  paddedMinDatetime: string | null;
  paddedMaxDatetime: string | null;
  timeZone?: string | null;
  readingsSeries: TimeSeriesPlotProps['readingsSeries'];
  yAxes: TimeSeriesPlotProps['yAxes'];
  userString: string;
  loading: boolean;
  errorMessage: string;
  getExportUrl: GetExportUrlFunc;
  onResetZoom?: () => void;
  onZoom?: TSPlotOnZoomFn;
  seriesPairs: ObservationPointItemUrlCode[] | null;
}
type Props = QuickPlotViewProps;
interface State {
  width: null | number;
}
class QuickPlotView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      width: null,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeydown);
  }

  // Redirect Ctrl + P to our print() method for greater control
  handleKeydown = (e: KeyboardEvent) => {
    if (e.ctrlKey && e.key === 'p') {
      e.preventDefault();
      e.stopPropagation();
      this.print(e);
    }
  };

  // we want the quickplot page to print in landscape mode by default, but keep
  // portrait mode as default for other pages.
  //
  // CSS `@page: landscape` rule is a global rule, and we can't conditinally apply
  // it to certain body class. Therefore here we have to inject the CSS rule
  // here and remove it after print is done
  //
  // @return: a function to remove the injected style
  //
  appendLandscapePrintStyle() {
    const css = '@page { size: landscape; }';
    const printStyle = document.createElement('style');
    const head = document.head || document.getElementsByTagName('head')[0];

    printStyle.type = 'text/css';
    printStyle.media = 'print';

    // @ts-ignore
    if (printStyle.styleSheet) {
      // @ts-ignore
      printStyle.styleSheet.cssText = css;
    } else {
      printStyle.appendChild(document.createTextNode(css));
    }

    head.appendChild(printStyle);

    return () => {
      head.removeChild(printStyle);
    };
  }

  // The plot rendered on a wide screen will not be resize correctly when printing,
  // thus result in the plot being cut off in print preview.
  // Here we have to manually set the size of the plot before printing to make it fit
  // on the A4·and set it back to null again on `afterprint`
  print = (event: Event | React.SyntheticEvent) => {
    event.preventDefault();
    this.setState({ width: 940 }, () => {
      const removeStyle = this.appendLandscapePrintStyle();
      setTimeout(() => {
        window.print();
        this.setState({ width: null });
        removeStyle();
      }, 500);
    });
  };

  render() {
    const { seriesPairs, readingsSeries, loading, errorMessage } = this.props;

    const obsPointCodesForDisplay = seriesPairs
      ? seriesPairs
          .map((pair) => pair.observationPointCode)
          .filter(isTruthy)
          .join(', ')
      : '';

    const pageProps = {
      name: 'quickplot',
      header: <Trans>Quick Plot</Trans>,
      subHeader: obsPointCodesForDisplay,
    };

    const plotHeaderActions = (
      <ActionBlock className="text-right">
        {this.props.onResetZoom && (
          <Button onClick={this.props.onResetZoom}>
            <Trans>Reset</Trans>
          </Button>
        )}
        <HasPermission check="can_create_stored_plots">
          <ButtonShowModal
            name="save-plot"
            iconType="icon-save"
            modalContent={() => (
              <SavePlotModal plotType={Enum.PlotType.TIME_SERIES} />
            )}
          >
            <Trans>Save</Trans>
          </ButtonShowModal>
        </HasPermission>
        <ButtonShowModal
          name="settings"
          autoShow={!seriesPairs}
          // HACK: If you're looking at a quickplot and then click on
          // Plots -> Quick Plot in the nav menu, the QuickPlotView does
          // not remount, so the panel does not auto-open. Setting a key on it
          // helps ensure it will re-mount and open in this situation.
          key={String(!seriesPairs)}
          iconType="icon-cog"
          shortcut="GOTO_SETTINGS"
          modalContent={({ hideModal }) => (
            <QuickPlotSettingsModal hideModal={hideModal} />
          )}
        >
          <Trans>Settings</Trans>
        </ButtonShowModal>
        <ButtonPrint id="quickplot-print" onClick={this.print} />
        {seriesPairs &&
        seriesPairs.length &&
        readingsSeries &&
        readingsSeries.length &&
        readingsSeries.some((serie) => serie.readings?.length) ? (
          <ButtonShowModal
            name="export"
            modalContent={() => (
              <ExportPanel
                title={<Trans>Export plot</Trans>}
                description={
                  <Trans>
                    The plot will be exported with the same settings as
                    displayed on screen.
                  </Trans>
                }
                canExportPdf={true}
                getExportUrl={this.props.getExportUrl}
              />
            )}
            iconType="icon-export"
          >
            <Trans>Export</Trans>
          </ButtonShowModal>
        ) : null}
      </ActionBlock>
    );

    if (!seriesPairs) {
      return (
        <PageStandard {...pageProps}>
          <div className="page-content-header columns-fluid">
            {plotHeaderActions}
          </div>
          <AlertInfo testid="plot-no-selection">
            <Trans>No observation points selected.</Trans>
          </AlertInfo>
        </PageStandard>
      );
    }

    if (errorMessage) {
      return (
        <PageStandard {...pageProps}>
          <AlertDanger testid="plot-error">{errorMessage}</AlertDanger>
        </PageStandard>
      );
    }

    if (loading) {
      return (
        <PageStandard {...pageProps}>
          <div className="page-content-header columns-fluid">
            {plotHeaderActions}
          </div>
          <Loading />
        </PageStandard>
      );
    }

    const displayedDateRange = this.props.minDatetime &&
      this.props.maxDatetime &&
      this.props.timeZone && (
        <div className="plot-page-header-info">
          <h2 data-testid="plot-date-range">
            {formatDatetimeForDisplay(
              this.props.minDatetime,
              this.props.timeZone
            )}{' '}
            -{' '}
            {formatDatetimeForDisplay(
              this.props.maxDatetime,
              this.props.timeZone
            )}
          </h2>
        </div>
      );
    if (
      readingsSeries.length &&
      readingsSeries.every((serie) => !serie.loading && !serie.readings?.length)
    ) {
      return (
        <PageStandard {...pageProps}>
          <div className="page-content-header columns-fluid">
            {displayedDateRange}
            {plotHeaderActions}
          </div>
          <AlertDanger>
            <Trans>
              No adjusted readings available for the requested observation
              points during the requested time period.
            </Trans>
          </AlertDanger>
        </PageStandard>
      );
    }

    return (
      <PageStandard {...pageProps}>
        <div className="page-content-header columns-fluid">
          {displayedDateRange}
          {plotHeaderActions}
        </div>

        <TimeSeriesPlot
          paddedMinDatetime={this.props.paddedMinDatetime}
          paddedMaxDatetime={this.props.paddedMaxDatetime}
          timeZone={this.props.timeZone}
          readingsSeries={this.props.readingsSeries}
          yAxes={this.props.yAxes}
          width={this.state.width}
          onZoom={this.props.onZoom}
        />
      </PageStandard>
    );
  }
}

export default QuickPlotView;
