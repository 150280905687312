import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportFilter,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, Plural, t } from '@lingui/macro';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { Icon } from 'components/base/icon/icon';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ReportStateProps } from 'hooks/use-report-state';
import { DMSLink } from 'components/base/link/DMSLink';

export const InstrumentTypeListFilters: ReportFilter[] = [
  reportFilterMenu(
    'id',
    <Trans>Instrument type</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      autoFocus: true,
      placeholder: <Trans>Find instrument type</Trans>,
    }
  ),
];

export const InstrumentTypeListColumns: ReportColumn<Model.ReportsInstrumentType>[] =
  [
    {
      label: <Trans>Instrument type</Trans>,
      name: 'code',
      visibility: DEFAULT_SHOW,
      accessor: ({ code }) => (
        <DMSLink to={`/instrument-types/${code}`}>{code}</DMSLink>
      ),
    },
    {
      label: <Trans>Name</Trans>,
      name: 'name',
      visibility: DEFAULT_SHOW,
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['code'],
      accessor: ({ code }) => (
        <>
          <DMSLink to={`/instrument-types/${code}`}>
            <Icon type="icon-view" title={t`View`} />
          </DMSLink>
          <HasPermission check="can_create_instrument_types">
            <DMSLink to={`/instrument-types/${code}?edit=main`}>
              <Icon type="icon-edit" title={t`Edit`} />
            </DMSLink>
          </HasPermission>
        </>
      ),
    },
  ];

export interface InstrumentTypeListViewProps {
  reportProps: ReportStateProps<Model.ReportsInstrumentType>;
}

export const InstrumentTypeListView: React.FunctionComponent<InstrumentTypeListViewProps> =
  function (props) {
    const { reportProps } = props;
    const columns = InstrumentTypeListColumns;
    const filters = InstrumentTypeListFilters;
    return (
      <PageStandard
        name="instrumentTypes-list"
        header={
          reportProps.isExportMode ? (
            <Trans>Instrument type report</Trans>
          ) : (
            <Trans>Maintain Instrument Types</Trans>
          )
        }
      >
        <div className="page-content-header-filters-actions">
          <HasPermission check="can_create_instrument_types">
            <ActionBlock className="text-right">
              <DMSLink
                to={`/instrument-types/new`}
                className="btn"
                shortcut="CREATE_NEW"
              >
                <span>
                  <Trans>Create instrument type</Trans>
                </span>
                <Icon type="icon-plus" />
              </DMSLink>
            </ActionBlock>
          </HasPermission>

          <ReportFiltersBlock
            filtersFrontend={filters}
            filtersBackend={
              reportProps.reportInfo && reportProps.reportInfo.filters
            }
            isExportMode={reportProps.isExportMode}
          />
        </div>
        <div className="filtered-table-wrapper">
          <ActionBlock className="filtered-table-options">
            <ButtonPrint />
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
            />
            <ExportReportButton
              fileNameBase={t`Instrument types report`}
              reportUrl="/reports/instrument-types/"
              columns={columns}
              filters={filters}
            />
          </ActionBlock>

          <ReportTable
            columns={columns}
            errorMessage={reportProps.errorMessage}
            filters={filters}
            isLoading={reportProps.isLoading}
            msgFilterCount={(count) => (
              <Plural
                value={count}
                one="Filtered to 1 instrument type"
                other="Filtered to # instrument types"
              />
            )}
            msgNoMatches={
              <Trans>No instrument types match the selected filters.</Trans>
            }
            pagination={reportProps.pagination}
            records={reportProps.records}
            reportInfo={reportProps.reportInfo}
          />
        </div>
      </PageStandard>
    );
  };
