import React from 'react';
import { AsyncSimpleSelectFieldProps } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { ReportFilter } from '../../report-types';
import { reportFilterAsyncMenu } from './FilterAsyncMenu';
import { Trans } from '@lingui/macro';
import { getApi } from 'util/backendapi/fetch';
import { Model } from 'util/backendapi/models/api.interfaces';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { getUserDisplayName } from 'util/user';

function userToMenuItem(user: Model.User): SimpleSelectOption<number> {
  return {
    value: user.id,
    label: getUserDisplayName(user),
  };
}

export function reportFilterUsers(
  name: string,
  label: React.ReactNode,
  extraProps: Partial<AsyncSimpleSelectFieldProps<any, any, any>> = {}
): ReportFilter {
  return reportFilterAsyncMenu(
    name,
    label,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (searchText) =>
        getApi('/users/', {
          profile__name__icontains: searchText,
        }).then((users) => users.map(userToMenuItem)),
      loadDefaults: (ids: number[]) =>
        getApi('/users/', {
          id__in: ids,
        }).then((users) => users.map(userToMenuItem)),
    },
    {
      placeholder: <Trans>All users</Trans>,
      ...extraProps,
    }
  );
}
