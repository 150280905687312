import { RouteMarchObservationPointPanelState } from '../../../ducks/routemarchobspoints';
import { Form, Formik, FormikProps, FormikHelpers } from 'formik';
import { SimpleSelectField } from '../../../components/base/form/simpleselect/simpleselectfield';
import ActionBlock from '../../../components/base/actionblock/actionblock';
import { ButtonPrimary } from '../../../components/base/button/button';
import Loading from '../../../components/base/loading/loading';
import { Model } from '../../../util/backendapi/models/api.interfaces';
import { AlertDanger, AlertInfo } from '../../../components/base/alert/alert';
import React from 'react';
import { Trans } from '@lingui/macro';
import ModalContent from '../../../components/base/modal/modalcontent';
import ButtonHideModal from '../../../components/base/modal/buttonhidemodal';

export interface ReduxStateProps extends RouteMarchObservationPointPanelState {
  routeMarch: Model.RouteMarch;
  routeMarchObservationPoints: Model.RouteMarchObservationPointDecorated[];
}

export type Props = ReduxStateProps & {
  onSubmit: (values: FormikValues, actions: FormikHelpers<FormikValues>) => any;
  onValidate: (values: FormikValues) => any;
};

export interface FormikValues {
  observationPoint: number;
}

export class RouteMarchAddObservationPointPanelView extends React.Component<Props> {
  render() {
    let modalContent = <Loading />;

    if (!this.props.loading && this.props.observationPoints.length === 0) {
      modalContent = (
        <AlertInfo>
          <Trans>No observation points available.</Trans>
        </AlertInfo>
      );
    }

    if (!this.props.loading && this.props.errorMessage) {
      modalContent = <AlertDanger>{this.props.errorMessage}</AlertDanger>;
    }

    if (!this.props.loading && this.props.observationPoints.length) {
      const observationPoints: Pick<
        Model.ObservationPointDecorated,
        'id' | 'code'
      >[] = this.props.observationPoints.filter((obsPoint) => {
        const alreadyAdded = this.props.routeMarchObservationPoints.find(
          (routeMarchObsPoint) =>
            routeMarchObsPoint.observation_point.id === obsPoint.id
        );

        return !alreadyAdded;
      });

      const observationPointOptions = observationPoints.map((obsPoint) => {
        return {
          value: obsPoint.id,
          label: obsPoint.code,
        };
      });

      modalContent = (
        <Formik
          initialValues={{ observationPoint: 0 }}
          onSubmit={this.props.onSubmit}
          validate={this.props.onValidate}
        >
          {(formik: FormikProps<FormikValues>) => (
            <Form>
              <legend>Observation Point</legend>
              <SimpleSelectField
                name="observationPoint"
                id="routemarchobservationpoint-obspoints"
                options={observationPointOptions}
                placeholder={<Trans>Select an observation point</Trans>}
                autoFocus={true}
              />
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  id="routemarchobservationpoint-add"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-plus"
                >
                  Add
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      );
    }

    return (
      <ModalContent header={<Trans>Add an observation point</Trans>}>
        {modalContent}
      </ModalContent>
    );
  }
}
