import React, { useEffect, useState } from 'react';
import { useParams, Redirect } from 'react-router';
import { getApi, postApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans } from '@lingui/macro';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import { Enum } from 'util/backendapi/models/api.interfaces';

/**
 * A "redirect" screen, for when the user clicks on the "start/resume checksheet"
 * button on the checksheet templates list screen.
 *
 * It checks to see if the template has an instance in progress.
 * If so, it redirects to that in-progress instance.
 * If not, it creates a new instance and redirects to that.
 */
export function ChecksheetTemplateStartInstanceScreen() {
  const { checksheetTemplateId } = useParams<{
    checksheetTemplateId: string;
  }>();

  const [instanceId, setInstanceId] = useState<number>();
  const [error, setInstanceError] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async function () {
      setIsLoading(true);
      setInstanceError(undefined);
      setInstanceId(undefined);

      try {
        // Check if there's one that can be resumed.
        const inProgressInstance = await getApi('/checksheet-instances/', {
          template: +checksheetTemplateId,
          status__in: [Enum.ChecksheetInstance_STATUS.in_progress],
        });
        if (inProgressInstance.length) {
          setInstanceId(inProgressInstance[0].id);
        } else {
          const createdInstance = await postApi('/checksheet-instances/', {
            template: +checksheetTemplateId,
          });
          setInstanceId(createdInstance.id);
        }
      } catch (e) {
        setInstanceError(errorToString(e));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [checksheetTemplateId]);

  return (
    <PageStandard
      name="start-checksheet"
      header={<Trans>Start checksheet</Trans>}
    >
      {isLoading ? (
        <Loading />
      ) : error ? (
        <AlertWarning>{error}</AlertWarning>
      ) : (
        <Redirect to={`/checksheet-instances/${instanceId}`} push={false} />
      )}
    </PageStandard>
  );
}
