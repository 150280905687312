import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const clientListDuck = makeReportingDuck(
  'clients',
  (filters) => getPaginated('/reports/clients/', filters),
  () => getApi('/reports/clients/info/')
);

export type ClientListAction = ReportingDuckActions<typeof clientListDuck>;
