import { connect } from 'react-redux';
import { FullState } from '../../../main/reducers';
import withInit, { InitProps } from '../../../components/hoc/withinit';
import {
  fetchStoredListItemList,
  fetchStoredList,
  updateObsPointPosition,
  removeObsPoint,
} from 'ducks/storedList/detail';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import { StateProps, StoredListItemView } from './listitemsview';
import { DropResult } from 'react-beautiful-dnd';
import { Enum } from 'util/backendapi/models/api.interfaces';

type OwnProps = RouteComponentProps<{ storedListId: string }>;

const mapStateToProps = (state: FullState): StateProps => {
  return {
    ...state.storedList.detail,
    hasAddPermission: state.user.permissions.includes(
      Enum.User_PERMISSION.can_create_stored_lists
    ),
    hasDeletePermission: state.user.permissions.includes(
      Enum.User_PERMISSION.can_delete_stored_list_items
    ),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>,
  ownProps: OwnProps
) => ({
  onInit: () => {
    const storedListId = ownProps.match.params.storedListId;
    dispatch(fetchStoredList(+storedListId));
    dispatch(fetchStoredListItemList(+storedListId));
  },
  onDragEnd: (result: DropResult) => {
    if (
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      dispatch(
        updateObsPointPosition(
          +result.draggableId,
          result.source.index + 1,
          result.destination.index + 1
        )
      );
    }
  },
  onRemoveObsPoint: (id: number, hideModal: any) => {
    dispatch(removeObsPoint(id));
    hideModal();
  },
});

export const StoredListItemScreen = withRouter(
  connect<StateProps, InitProps, OwnProps, FullState>(
    mapStateToProps,
    mapDispatchToProps
  )(withInit(StoredListItemView))
);
