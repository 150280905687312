import { ScaleLinear } from 'd3-scale';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { LabelSeriesPoint } from 'react-vis';
import {
  StoredTimeSeriesPlotWithArea,
  StoredScatterPlotWithArea,
} from 'ducks/stored-plot/detail';
import { SimpleReading } from 'util/backendapi/types/Model';

// The hard-coded number of "buckets" we downsample time series plots to.
// TODO: scale this with the actual rendered size of the plot.
export const TIMESERIES_BUCKETS = 1920;

export type QuickPlotAxisSide = 'left' | 'right';
export const QUICKPLOT_AXIS_SIDES: QuickPlotAxisSide[] = ['left', 'right'];

export type ScatterPlotAxisSide = 'left' | 'bottom';
export const SCATTERPLOT_AXIS_SIDES: ScatterPlotAxisSide[] = ['left', 'bottom'];

export type YAxisSide = 'left' | 'right' | 'right2';
export const Y_AXIS_SIDES: YAxisSide[] = ['left', 'right', 'right2'];

/**
 * The unique identifying information for a time series plot, that we onced
 * into the URL. (Specifically, the obs point code and item number)
 */
export interface ObservationPointItemUrlCode {
  observationPointCode: string;
  itemNumber: number;
}

export interface PlotSettingsAxis<
  A extends Enum.PlotAxisSide = Enum.PlotAxisSide
> extends Model.StoredPlotItemAxis {
  side: A;
}

export interface PlotSettingsReadingSeries<
  A extends Enum.PlotAxisSide = Enum.PlotAxisSide
> extends Optional<Model.StoredPlotItemReadingsSeries, 'id'> {
  axis_side: A;
}

/**
 * Plot configuration and metadata that affects the display of the plot screen.
 */
export interface PlotSettings {
  plotType: Enum.PlotType;
  start_datetime: Model.BaseStoredScatterTimeSeriesPlot['start_datetime'];
  end_datetime: Model.BaseStoredScatterTimeSeriesPlot['end_datetime'];
  duration: Model.BaseStoredScatterTimeSeriesPlot['duration'];
  interpolate: Model.StoredPlotItem['interpolate'];
  show_plot_markers: Model.StoredPlotItem['show_plot_markers'];
  show_mark_connections: Model.StoredPlotItem['show_mark_connections'];
  highlight_periods: Model.StoredPlotItem['highlight_periods'];
  reading_series: Optional<PlotSettingsReadingSeries, 'id'>[];
  axes: PlotSettingsAxis[];
  // Used for quickplot, to record plot settings. (For stored plots, these
  // are series settings rather than plot settings.)
  showAnalysisComments: boolean;
  showInspectorComments: boolean;
  showMediaAll: boolean;
  // Fetched data
  observationPoints: Model.ObservationPointDecorated[];
  storedPlot: null | StoredTimeSeriesPlotWithArea | StoredScatterPlotWithArea;
}

export interface TimeSeriesPlotSettings<A extends Enum.PlotAxisSide = YAxisSide>
  extends PlotSettings {
  plotType: Enum.PlotType.TIME_SERIES;
  reading_series: PlotSettingsReadingSeries<A>[];
  axes: PlotSettingsAxis<A>[];
  // Fetched data
  storedPlot: null | StoredTimeSeriesPlotWithArea;
}

export type QuickPlotSettings = TimeSeriesPlotSettings<QuickPlotAxisSide>;

export interface ScatterPlotSettings extends PlotSettings {
  plotType: Enum.PlotType.SCATTER;
  reading_series: PlotSettingsReadingSeries<ScatterPlotAxisSide>[];
  axes: PlotSettingsAxis<ScatterPlotAxisSide>[];
  // Fetched data
  storedPlot: null | StoredScatterPlotWithArea;
  relativeAlarmParameters?: Model.ReportsAlarmParameter[];
}

export type PolymorphicPlotSettings =
  | TimeSeriesPlotSettings
  | ScatterPlotSettings;

export interface PlotReadingsSeries<
  A extends Enum.PlotAxisSide = Enum.PlotAxisSide
> {
  loading: boolean;
  errorMessage?: string;
  readings: TSPlotReading[] | null;
  observationPoint: Model.ObservationPointDecorated;
  settings: PlotSettingsReadingSeries<A>;
}

/**
 * The data we have about the downsampled "buckets" in a plot. Basically
 * the bucket shape we get from the backend, but with the `readings` property
 * removed to prevent looped data structures (since we then nest a reference
 * to the bucket onto each reading.)
 */
export type TSPlotReadingsBucket = Omit<Model.ReadingsPlotBucket, 'readings'>;

/**
 * The data we need to render each time series of readings data
 */
export interface TSPlotReading {
  x: Date;
  y: number | null;
  reading_id: number;
  bucket?: TSPlotReadingsBucket | null;
  // A flag we place onto a reading to indicate that we can show a comment
  // indicator above this reading. (The reading itself may not actually have
  // a comment; only the max value reading in a bucket shows a comment indicator))
  hasAnalysisCommentIndicator?: boolean;
  hasInspectorCommentIndicator?: boolean;
  hasMediaIndicator?: boolean;
  // For displaying "error bars" / "confidence interval"
  yVariance?: number;
  confidence_interval_readings?: SimpleReading[];
}

/**
 * What we pass to a LabelSeries for each of the icons it should draw.
 */
export type IconPoint = Merge<LabelSeriesPoint, TSPlotReading>;

/**
 * The data we need to render alarm parameter thresholds as a time series.
 */
export interface TSPlotAlarmParamSeries {
  type: Enum.AlarmParameter_TYPE;
  level: Enum.AlarmParameter_LEVEL;
  thresholds: Array<{
    threshold: number;
    start_datetime: string;
    end_datetime: string | null;
  }>;
}
/**
 * The position and label of an alarm parameter
 */
export interface TSPlotAlarmParamLabel {
  x: Date;
  y: number;
  label: LabelSeriesPoint['label'];
  yOffset: LabelSeriesPoint['yOffset'];
  xOffset: LabelSeriesPoint['xOffset'];
}
/**
 * The corners of one of the alarm parameter boxes
 */
export interface TSPlotAlarmParamBox {
  x: Date;
  y: number;
  x0: Date;
  y0: number;
}
/**
 * The lines along the tops of the alarm parameter boxes
 */
export interface TSPlotAlarmParamOutlinePoint {
  x: Date;
  y: number | null;
}

/**
 * The nitty-gritty data for drawing all the plot elements for an alarm parameter
 * series.
 */
export interface TSPlotAlarmParamSeriesWithShapes
  extends TSPlotAlarmParamSeries {
  shadedAlarmAreas: TSPlotAlarmParamBox[];
  alarmThresholdLines: TSPlotAlarmParamOutlinePoint[];
  alarmTypeClassName: string;
  alarmLevelClassName: string;
}

/**
 * The data we need to render the combined series we use to track the nearest
 * data point to the mouse's location.
 */
export type TSPlotMouseoverPoint = Merge<
  TSPlotReading,
  {
    originalValue: number | null;
    obsPointCode: string;
    seriesColor: string;
    itemNumber: number;
    marker: string;
    unit: string;
    showInspectorComments: boolean;
    showAnalysisComments: boolean;
  }
>;

// An object that holds D3 scales for converting from one of our Y axis's
// domain to the "default" y axis domain for the plot.
export type TSPlotYAxisScales = {
  [K in YAxisSide]: ScaleLinear<number, number>;
};

export interface TSPlotZoomParams {
  minDatetime: string;
  maxDatetime: string;
  yAxes?: PlotSettingsAxis<YAxisSide>[];
}

export type TSPlotOnZoomFn = (zoomParams: TSPlotZoomParams) => void;

/**
 * Generates a unique key to identify a stored plot item, for storing and
 * retrieving its resolved settings in the Redux store.
 *
 * @param storedPlotId
 * @param storedPlotItemId
 */
export function buildStoredPlotKey(
  storedPlotId: number,
  storedPlotItemId: number
) {
  return `StoredPlot:${storedPlotId}:${storedPlotItemId}`;
}

/**
 * Generates a unique key to identify the Redux state for the data we load
 * for a single data series on the plot.
 *
 * @param plotKey
 * @param obsPointId
 * @param itemNumber
 */
export function buildReadingSeriesKey(
  plotKey: string,
  obsPointId: number,
  itemNumber: number
) {
  return [plotKey, obsPointId, itemNumber].join(':');
}
