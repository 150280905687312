import React from 'react';
import { useGetApi } from 'hooks/use-get-api';
import { DashboardComponent } from 'util/backendapi/types/Model';
import Accordion from 'components/base/accordion/accordion';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { AlertWarning } from 'components/base/alert/alert';
import { useSelector, useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { setCustomDashletCollapsed } from 'ducks/ui';
import { errorToString } from 'util/backendapi/error';

/**
 * A controller for all the user's custom bookmark dashlets
 */
export function BookmarksDashboardSection() {
  const [{ isError, error, data: dashlets }] = useGetApi(
    '/dashboard-components/'
  );
  const collapseState = useSelector(
    (state: FullState) => state.ui.isCustomDashletCollapsed
  );
  const dispatch = useDispatch();

  if (isError) {
    return <AlertWarning>{errorToString(error)}</AlertWarning>;
  } else if (dashlets) {
    return (
      <>
        {dashlets.map((dashlet) => (
          <BookmarksDashlet
            key={dashlet.id}
            dashlet={dashlet}
            isCollapsed={Boolean(collapseState[dashlet.id])}
            onToggle={(isExpanded) =>
              dispatch(setCustomDashletCollapsed(dashlet.id, !isExpanded))
            }
          />
        ))}
      </>
    );
  } else {
    return null;
  }
}

interface ViewProps {
  isCollapsed: boolean;
  onToggle: (isExpanded: boolean) => void;
  dashlet: DashboardComponent;
}

/**
 * The view component for a single custom component bookmarks dashlet
 * @param props
 */
export function BookmarksDashlet(props: ViewProps) {
  const { dashlet, isCollapsed, onToggle } = props;
  return (
    <Accordion
      data-testid={`bookmarks-dashlet-${dashlet.id}`}
      onToggle={onToggle}
      expand={!isCollapsed}
      wrapperClassName="accordion-secondary"
      item={{
        id: `bookmarks-dashlet-${dashlet.id}`,
        title: (
          <>
            {dashlet.title}
            {!isCollapsed && (
              // Prevent a click on the "edit" button from bubbling up to
              // trigger the accordion to toggle. (We can't do this directly
              // in the `<Link>` component's `onClick()` because that would break
              // React-Router)
              <span
                onClick={(e) => e.stopPropagation()}
                className="accordion-title-additional-content"
              >
                <Link
                  className="btn"
                  to={`/dashboard-components/${dashlet.id}`}
                >
                  <span>
                    <Trans>Edit</Trans>
                  </span>
                </Link>
              </span>
            )}
          </>
        ),
        content: (
          <ul>
            {dashlet.links.map((link, idx) => (
              <li key={idx}>
                <a rel="nofollow noopener noreferrer" href={link.url}>
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        ),
      }}
    ></Accordion>
  );
}
