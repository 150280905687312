import React, { useEffect, useState, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { SectionProps } from '../../maintobspoint.types';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { formatDatetimeForDisplay } from 'util/dates';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { CardSectionRow, CardSection } from 'components/base/card/card';
import { FullState } from 'main/reducers';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { fetchDataLoggerChannel } from 'ducks/obsPoint/detail/dataLoggerChannel';
import { DMSLink } from 'components/base/link/DMSLink';
import { getApi } from 'util/backendapi/fetch';
import { isNotNull } from 'util/validation';
import { uniq } from 'lodash';
import { useShallowEqualSelector } from 'util/hooks';
import { DataLoggerChannelHistory } from 'screens/obsPoint/detail/sections/data-logger-channel/DataLoggerChannelHistory';
import { EMPTY_OBJECT, EMPTY_FUNC } from 'util/misc';
import Loading from 'components/base/loading/loading';
import { TransEnum } from 'components/base/i18n/TransEnum';

type Props = SectionProps & { observationPointCode: string };

export function ObsPointDataLoggerSection(props: Props) {
  const { state, formulaState } = useShallowEqualSelector(
    (state: FullState) => {
      return {
        state: state.obsPoint.detail.dataLoggerChannel,
        formulaState: state.obsPoint.detail.formula,
      };
    }
  );

  const {
    latestDataLoggerChannel,
    dataLoggerChannelHistory,
    isLoadingState,
    noActiveDataLogger,
  } = useMemo(() => {
    if (
      state.isLoading ||
      !props.observationPoint ||
      state.forObsPoint !== props.observationPointCode ||
      !state.dataLoggerChannels
    ) {
      return {
        isLoadingState: true,
        latestDataLoggerChannel: null,
        dataLoggerChannelHistory: null,
        noActiveDataLogger: false,
      };
    }

    const latestDataLoggerChannel = state.dataLoggerChannels[0];
    if (
      !(
        latestDataLoggerChannel &&
        latestDataLoggerChannel.status !== Enum.DataLoggerChannel_STATUS.retired
      )
    ) {
      return {
        isLoadingState: false,
        latestDataLoggerChannel: latestDataLoggerChannel,
        dataLoggerChannelHistory: state.dataLoggerChannels,
        noActiveDataLogger: true,
      };
    }
    return {
      isLoadingState: false,
      latestDataLoggerChannel: state.dataLoggerChannels[0],
      dataLoggerChannelHistory: state.dataLoggerChannels,
      noActiveDataLogger: false,
    };
  }, [
    props.observationPoint,
    props.observationPointCode,
    state.dataLoggerChannels,
    state.forObsPoint,
    state.isLoading,
  ]);

  const dispatch = useDispatch();

  const [relatedDataLoggerChannel, setRelatedDataLoggerChannel] =
    useState<Model.DataLoggerChannelDecorated | null>(null);

  const [relatedObservationPointFormula, setRelatedObservationPointFormula] =
    useState<Model.ObservationPointFormulaDecorated | null>(null);

  const [isLoadingRelated, setIsLoadingRelated] = useState(true);

  useEffect(() => {
    if (props.observationPoint) {
      dispatch(fetchDataLoggerChannel(props.observationPoint));
      setIsLoadingRelated(true);
    }
  }, [dispatch, props.observationPoint]);

  // Check if an observation point that is an input to the current observation point formula
  // has a data logger
  useEffect(() => {
    (async function () {
      let relatedDataLoggerChannel = null;
      let relatedObservationPointFormula = null;

      if (noActiveDataLogger && formulaState.formulaSnapshots?.length) {
        const currentFormulaSnapshot = formulaState.formulaSnapshots[0];
        const obsPointFormulaInputs = Object.values(
          currentFormulaSnapshot.observation_point_formula_inputs
        );

        const obsPointFormulaInputObsPointIds = uniq(
          obsPointFormulaInputs.flatMap((opfi) =>
            [opfi.compensation_observation_point].concat(
              opfi.dependency_observation_points.map(
                (dop) => dop.observation_point
              )
            )
          )
        ).filter(isNotNull);

        if (obsPointFormulaInputObsPointIds.length > 0) {
          const activeRelatedDataLoggerChannels = await getApi(
            '/data-logger-channels/',
            {
              observation_point__in: obsPointFormulaInputObsPointIds,
              active_at_datetime: new Date().toISOString(),
            }
          );

          if (activeRelatedDataLoggerChannels.length > 0) {
            relatedDataLoggerChannel = activeRelatedDataLoggerChannels[0];

            if (relatedDataLoggerChannel.observation_point) {
              // get observation point formula of the related observation point
              // with a data logger
              relatedObservationPointFormula =
                (
                  await getApi('/observation-point-formulas/', {
                    observation_point__in: [
                      relatedDataLoggerChannel.observation_point.id,
                    ],
                    active_now: true,
                  })
                )[0] ?? null;
            }
          }
        }
      }
      setIsLoadingRelated(false);
      setRelatedDataLoggerChannel(relatedDataLoggerChannel);
      setRelatedObservationPointFormula(relatedObservationPointFormula);
    })();
  }, [formulaState.formulaSnapshots, noActiveDataLogger]);

  if (props.isEditing) {
    let dataLoggerChannelsLink = '/data-logger-channels/';
    if (props.observationPoint && latestDataLoggerChannel) {
      dataLoggerChannelsLink += `?data_logger=${latestDataLoggerChannel.data_logger.logger_number}&observation_point=${props.observationPoint.id}`;
    }
    return <Redirect push to={dataLoggerChannelsLink} />;
  }

  return (
    <InnerObsPointDataLoggerSection
      {...props}
      isLoading={props.isLoading || isLoadingState || isLoadingRelated}
      latestDataLoggerChannel={latestDataLoggerChannel}
      dataLoggerChannelHistory={dataLoggerChannelHistory}
      relatedDataLoggerChannel={relatedDataLoggerChannel}
      relatedObservationPointFormula={relatedObservationPointFormula}
      noActiveDataLogger={noActiveDataLogger}
    />
  );
}

type InnerProps = Merge<
  Props,
  {
    latestDataLoggerChannel: Model.DataLoggerChannelDecorated | null;
    dataLoggerChannelHistory: Model.DataLoggerChannelDecorated[] | null;
    relatedDataLoggerChannel: Model.DataLoggerChannelDecorated | null;
    relatedObservationPointFormula: Model.ObservationPointFormulaDecorated | null;
    noActiveDataLogger: boolean;
  }
>;

function InnerObsPointDataLoggerSection(props: InnerProps) {
  const {
    latestDataLoggerChannel,
    relatedDataLoggerChannel,
    relatedObservationPointFormula,
    isLoading,
    noActiveDataLogger,
  } = props;
  const timeZone = props.observationPoint
    ? props.observationPoint.time_zone.name
    : undefined;

  // NOTE: this card isn't used in Edit mode. The Change button triggers
  // a redirect to the Data Logger Channel allocation screen
  return (
    <EditableCard
      name="dataLogger"
      header={<Trans>Data logger</Trans>}
      footer={
        <DataLoggerChannelHistory
          noActiveDataLogger={noActiveDataLogger}
          dataLoggerChannelHistory={props.dataLoggerChannelHistory}
          timeZone={timeZone}
        />
      }
      shouldDisable={false}
      hasEditPermission={props.hasEditPermission}
      isEditMode={false}
      startEditing={props.startEditing}
      stopEditing={props.stopEditing}
      initialValues={EMPTY_OBJECT}
      onSubmit={EMPTY_FUNC}
      render={() =>
        isLoading ? (
          <Loading />
        ) : (
          <DataLoggerChannelCard
            timeZone={timeZone}
            dataLoggerChannel={
              noActiveDataLogger
                ? relatedDataLoggerChannel
                : latestDataLoggerChannel
            }
            relatedObservationPointFormula={relatedObservationPointFormula}
          />
        )
      }
    />
  );
}

/**
 * The contents of the data logger card. Extracted into a separate component so
 * it can be re-used for each data logger in the history accordion.
 */
export function DataLoggerChannelCard(props: {
  timeZone: string | undefined;
  dataLoggerChannel: Model.DataLoggerChannelDecorated | null;
  relatedObservationPointFormula: Model.ObservationPointFormulaDecorated | null;
}) {
  const { dataLoggerChannel, relatedObservationPointFormula, timeZone } = props;
  const formFields: CardSectionRow[] = [
    {
      name: 'hasDataLogger',
      label: <Trans>Data logger</Trans>,
      content: dataLoggerChannel ? (
        relatedObservationPointFormula &&
        dataLoggerChannel.observation_point ? (
          <Trans>
            Yes (via{' '}
            <DMSLink
              to={`/observation-point/${dataLoggerChannel.observation_point.code}`}
            >
              {dataLoggerChannel.observation_point.code}
            </DMSLink>{' '}
            with {relatedObservationPointFormula.formula.code} formula)
          </Trans>
        ) : (
          <Trans>Yes</Trans>
        )
      ) : (
        <Trans>No</Trans>
      ),
    },
  ];

  if (dataLoggerChannel) {
    formFields.push(
      {
        name: 'data_logger',
        label: <Trans>Logger ID</Trans>,
        content: dataLoggerChannel.data_logger.logger_number,
      },
      {
        name: 'channel_number',
        label: <Trans>Channel</Trans>,
        content: dataLoggerChannel.channel_number,
      },
      {
        name: 'start_datetime',
        label: <Trans>Start date and time</Trans>,
        content: formatDatetimeForDisplay(
          dataLoggerChannel.start_datetime,
          timeZone
        ),
      },
      {
        name: 'status',
        label: <Trans>Status</Trans>,
        content: (
          <TransEnum
            enum="DataLoggerChannel_STATUS"
            value={dataLoggerChannel.status}
          />
        ),
      }
    );
  }
  return (
    <CardSection
      name="dataLogger"
      header={<Trans>Data logger</Trans>}
      fields={formFields}
    />
  );
}

ObsPointDataLoggerSection.WrappedComponent = InnerObsPointDataLoggerSection;
