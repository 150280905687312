import React from 'react';
import { AlertDanger } from 'components/base/alert/alert';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import { PaginationMeta } from 'util/backendapi/pagination';
import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { Icon } from 'components/base/icon/icon';
import { ListPager } from 'components/base/listpager/listpager';
import { Model } from 'util/backendapi/models/api.interfaces';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { DMSLink } from 'components/base/link/DMSLink';

interface Props {
  error: null | string;
  loading: boolean;
  pagination: null | PaginationMeta;
  dataLoggers: null | Model.DataLoggerDecorated[];
  onPageChanged: (pageIndex?: number) => void;
}

export function DataLoggersListView(props: Props) {
  const pageProps = {
    name: 'list-data-loggers',
    header: <Trans>Data Loggers</Trans>,
  };
  if (props.error) {
    return (
      <PageStandard {...pageProps}>
        <AlertDanger>{props.error}</AlertDanger>
      </PageStandard>
    );
  }
  if (props.loading) {
    return (
      <PageStandard {...pageProps}>
        <Loading />
      </PageStandard>
    );
  }
  if (!Array.isArray(props.dataLoggers) || props.dataLoggers.length === 0) {
    return (
      <PageStandard {...pageProps}>
        <Trans>No data</Trans>
      </PageStandard>
    );
  }

  return (
    <PageStandard {...pageProps}>
      <ListPager
        pagination={props.pagination}
        onPageChanged={props.onPageChanged}
      />
      <div className="table-responsive">
        <table id="data-loggers-table">
          <thead>
            <tr>
              <th scope="col">
                <Trans>Logger ID</Trans>
              </th>
              <th scope="col">
                <Trans>Area</Trans>
              </th>
              <th scope="col">
                <Trans>Site</Trans>
              </th>
              <th scope="col">
                <Trans>Model</Trans>
              </th>
              <th scope="col" className="action-icons">
                <Trans>Actions</Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {props.dataLoggers.map((dataLogger) => (
              <tr key={String(dataLogger.id)} data-logger-id={dataLogger.id}>
                <td>
                  <DMSLink to={`/data-loggers/${dataLogger.logger_number}`}>
                    {dataLogger.logger_number}
                  </DMSLink>
                </td>
                <td>{dataLogger.site.area.name}</td>
                <td>{dataLogger.site.code}</td>
                <td>{dataLogger.model}</td>
                <td className="action-icons">
                  <DMSLink to={`/data-loggers/${dataLogger.logger_number}`}>
                    <Icon type="icon-view" title={t`View logger`} />
                  </DMSLink>
                  <HasPermission check="can_view_data_logger_channel_report">
                    <Link
                      to={`/data-logger-channels/?data_logger=${dataLogger.logger_number}`}
                    >
                      <Icon
                        type="icon-exchange"
                        title={t`Channel allocation`}
                      />
                    </Link>
                  </HasPermission>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ListPager
        pagination={props.pagination}
        onPageChanged={props.onPageChanged}
      />
    </PageStandard>
  );
}
