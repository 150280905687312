import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Pagination } from 'util/backendapi/pagination';
import {
  fetchQuickListReadings,
  unmountQuickListPage,
  QuickListRequestDetails,
  fetchQuickListObsPoint,
} from 'ducks/quicklist';
import {
  parseStringParamFromRouterProps,
  parseNumberQueryParamFromRouterProps,
  parseQueryParamFromRouterProps,
} from 'util/routing';
import { QuickListView } from './QuickListView';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';
import { FullState } from 'main/reducers';
import { GetExportUrlFunc } from 'components/modules/exportpanel/exportpanel';
import { useShallowEqualSelector } from 'util/hooks';
import { formatDatetimeForBackendUrl } from 'util/dates';
import { QuickListReadingsMode } from './settings/QuickListSettingsView';

type Props = RouteComponentProps<{
  obsPointCode?: string;
  startDatetime?: string;
  endDatetime?: string;
  numberOfReadings?: string;
  readingsMode?: QuickListReadingsMode;
}>;

export function QuickListScreen(props: Props) {
  const dispatch = useDispatch();

  const observationPointCode = parseStringParamFromRouterProps(
    props,
    'obsPointCode',
    null
  );

  const numberOfReadings = parseNumberQueryParamFromRouterProps(
    props,
    'numberOfReadings',
    null
  );
  const startDatetime = parseQueryParamFromRouterProps(
    props,
    'startDatetime',
    null
  );
  const endDatetime = parseQueryParamFromRouterProps(
    props,
    'endDatetime',
    null
  );
  const readingsMode = parseQueryParamFromRouterProps(
    props,
    'readingsMode',
    'adjusted'
  ) as QuickListReadingsMode;
  const { offset, limit } = Pagination.parseFromRouterProps(props);

  const request: QuickListRequestDetails | null = React.useMemo(
    () =>
      observationPointCode
        ? {
            observationPointCode,
            numLatestReadings: numberOfReadings,
            startDatetime,
            endDatetime,
            offset,
            limit,
          }
        : null,
    [
      endDatetime,
      limit,
      numberOfReadings,
      observationPointCode,
      offset,
      startDatetime,
    ]
  );

  const state = useShallowEqualSelector((state: FullState) => {
    // Unlike quickplot, there are no plans to do a quicklist with readings
    // from multiple observation points. So if they provide multiple in the
    // URL (maybe by hand-modifying a quickplot URL), we only retrieve data
    // for the first one.

    return {
      pagination: Pagination.fromRequestedReceived(
        Pagination.parseFromRouterProps(props),
        state.quickList.pagination
      ),
      basePaginationOffset: state.quickList.basePaginationOffset,
      errorMessage:
        state.quickList.readingsErrorMessage ||
        state.quickList.obsPointErrorMessage,
      isLoading:
        state.quickList.isLoadingReadings || state.quickList.isLoadingObsPoint,
      observationPoint: state.quickList.observationPoint,
      readings: state.quickList.readings,
      generatedOnDatetime: state.quickList.generatedOnDatetime,
      gaps: state.quickList.gaps,
    };
  });

  const getExportUrl: GetExportUrlFunc = React.useCallback(
    (exportFormat, { queryParams }) => {
      if (!request || !state.pagination.received || !state.observationPoint) {
        throw new Error('Export not yet available');
      }
      // TODO: Update this when/if we make quick list use instrument item numbers.
      queryParams.set('observation_point', String(state.observationPoint.id));
      if (request.numLatestReadings) {
        // Although exports are not paginated as such, we use the pagination
        // params to get the right window of readings for the "number of latest
        // readings" option
        queryParams.set('offset', String(state.basePaginationOffset));
        queryParams.set('limit', String(request.numLatestReadings));
      }
      if (request.startDatetime) {
        queryParams.set(
          'reading_datetime_after',
          formatDatetimeForBackendUrl(request.startDatetime)
        );
      }
      if (request.endDatetime) {
        queryParams.set(
          'reading_datetime_before',
          formatDatetimeForBackendUrl(request.endDatetime)
        );
      }
      if (exportFormat === ExportFormats.CSV) {
        queryParams.set('effective', 'true');

        queryParams.set(
          'columns',
          readingsMode === 'both'
            ? 'raw_reading_entries,adjusted_reading_entries'
            : readingsMode === 'raw'
            ? 'raw_reading_entries'
            : 'adjusted_reading_entries'
        );
        return {
          path: '/export/csv/readings/',
          queryParams,
        };
      } else {
        return {
          path: `/export/pdf/quick-lists/`,
          queryParams,
        };
      }
    },
    [
      request,
      readingsMode,
      state.basePaginationOffset,
      state.observationPoint,
      state.pagination.received,
    ]
  );

  React.useEffect(
    () => () => {
      dispatch(unmountQuickListPage());
    },
    [dispatch]
  );

  React.useEffect(() => {
    if (observationPointCode) {
      dispatch(fetchQuickListObsPoint(observationPointCode));
    }
  }, [dispatch, observationPointCode]);

  React.useEffect(() => {
    if (request && state.observationPoint) {
      dispatch(fetchQuickListReadings(state.observationPoint.id, request));
    }
  }, [dispatch, request, state.observationPoint]);

  return (
    <QuickListView
      errorMessage={state.errorMessage}
      observationPoint={state.observationPoint}
      observationPointCode={request && request.observationPointCode}
      isLoading={state.isLoading}
      gaps={state.gaps}
      readings={state.readings}
      readingsMode={readingsMode}
      pagination={state.pagination}
      generatedOnDatetime={state.generatedOnDatetime}
      getExportUrl={getExportUrl}
    />
  );
}
