import React, { useCallback, useEffect, useState } from 'react';
import { i18nMark } from '@lingui/react';
import orderBy from 'lodash/orderBy';
import EditReadingView, { EditReadingFormValues } from './editreadingview';
import { useDispatch, useSelector } from 'react-redux';
import { updateReading } from '../../ducks/checkreadings';
import { Model, Enum } from '../../util/backendapi/models/api.interfaces';
import { FormikHelpers } from 'formik';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { useIsMounted } from 'util/hooks';
import { getApi } from 'util/backendapi/fetch';
import { getSafely } from 'util/misc';
import { selectLoggedInUser } from 'ducks/login';

/**
 * Hard-coded list of "Reasons for change" options.
 * TODO: Pull these dynamically from the backend!
 */
const reasonsForChange = [
  { value: '1', label: i18nMark('Formula code changed or corrected') },
  { value: '2', label: i18nMark('Formula constants changed or corrected') },
  { value: '3', label: i18nMark('Survey datum offset incorrect/not applied') },
  { value: '4', label: i18nMark('Raw reading incorrect') },
];

type OwnProps = {
  reading: Model.Reading;
  hideModal: () => void;
  onAfterSave: () => void;
};

const EditReading = (props: OwnProps) => {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();
  const loggedInUser = useSelector(selectLoggedInUser);

  const [numReadingPositions, setNumReadingPositions] = useState<number>(
    props.reading.raw_reading_entries.length
  );
  const [isFetchingReadingPositions, setIsFetchingReadingPositions] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      props.reading.raw_reading_entries.length > 0 &&
      props.reading.errors.length === 0
    ) {
      // If the reading has entries and there aren't any errors, assume it's the right number of
      // entries.
      return;
    }

    // If the reading does not have entries, we try to look up the formula
    // in effect at the time of the reading, to determine how many raw
    // reading entries should have been available.
    const id = props.reading.observation_point.id;
    const fetchFormulasSnapshot = async () => {
      setIsFetchingReadingPositions(true);
      try {
        const snapshots = await getApi(
          `/observation-points/${id}/formula-snapshots/`
        );
        const snapshotAppliedForReading = orderBy(
          snapshots,
          'start_datetime',
          'desc'
        ).find((item) => item.start_datetime <= props.reading.reading_datetime);

        if (!snapshotAppliedForReading) {
          return;
        }

        const formulaId =
          snapshotAppliedForReading.observation_point_formula.formula;
        const formula = await getApi(`/formulas/${formulaId}/`);

        const readingPositions = formula.formula_inputs.filter(
          (fi) =>
            // One for each "raw reading" input...
            fi.type === Enum.FormulaInput_TYPE.raw_reading ||
            // One for each chainable input that is set to "raw reading" mode
            (fi.type === Enum.FormulaInput_TYPE.chainable_reading &&
              getSafely(
                () =>
                  snapshotAppliedForReading.observation_point_formula_inputs[
                    fi.var_name
                  ].dependency_observation_points.length,
                0
              ) === 0)
        ).length;
        setNumReadingPositions(readingPositions);
      } finally {
        setIsFetchingReadingPositions(false);
      }
    };

    fetchFormulasSnapshot();
  }, [props.reading]);

  const handleSubmit = useCallback(
    async (
      values: EditReadingFormValues,
      formik: FormikHelpers<EditReadingFormValues>
    ) => {
      try {
        // Remove the reading comment from the reading object.
        // (Currently backend just ignores it, but it's better not to send un-used
        // fields.)
        const { reading_comment, ...reading } = values;

        const raw_reading_entries = reading.raw_reading_entries.map(
          (rawReadingEntry) => ({
            position: rawReadingEntry.position,
            value: rawReadingEntry.isNaN ? 'NaN' : rawReadingEntry.value,
          })
        );

        let comment: null | Partial<Model.ReadingInspectorComment> = null;
        if (reading_comment && props.reading.inspector_comment === null) {
          // No existing inspector comment; prepare a new, blank one.
          // Commenter should be the logged in user
          comment = {
            content: reading_comment,
            created_datetime: values.reading_datetime,
            reading: props.reading.id,
            comment_type: Enum.Comment_TYPE.inspector,
            commenter: loggedInUser?.username,
          };
        } else if (
          props.reading.inspector_comment !== null &&
          reading_comment !== props.reading.inspector_comment.content
        ) {
          // Existing inspector comment, just update it.
          comment = {
            id: props.reading.inspector_comment.id,
            content: reading_comment,
          };
        }
        await dispatch(
          updateReading(
            props.reading.id,
            {
              ...reading,
              raw_reading_entries: raw_reading_entries,
              reason_for_change: parseInt(values.reason_for_change),
            },
            comment
          )
        );
        props.onAfterSave();

        if (!isMounted()) return;
        props.hideModal();
      } catch (e) {
        if (!isMounted()) return;
        formik.setSubmitting(false);
        const rawReadingEntriesFields = values.raw_reading_entries.map(
          (_, idx) => `raw_reading_entries[${idx}].value`
        );
        showErrorsInFormik(formik, e, [
          'reading_datetime',
          'reason_for_change',
          'reading_comment',
          ...rawReadingEntriesFields,
        ]);
      }
    },
    [dispatch, isMounted, props, loggedInUser]
  );

  return (
    <EditReadingView
      loading={isFetchingReadingPositions}
      reading={props.reading}
      numReadingPositions={numReadingPositions}
      reasonForChangeOptions={reasonsForChange}
      onSubmit={handleSubmit}
      onCancel={props.hideModal}
    />
  );
};

export default EditReading;
