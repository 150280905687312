import React, { useMemo, useCallback } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';
import { siteListColumns, siteListFilters, SiteListView } from './SiteListView';
import { siteListDuck } from 'ducks/site/list';

type OwnProps = RCPWithQueryParams<Filter.ReportsSites>;

export const SiteListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return siteListColumns({
      refreshList: EMPTY_FUNC,
      deleteSite: EMPTY_FUNC,
    });
  }, []);

  const deleteSite = useCallback(async (id: number) => {
    return deleteApi(`/sites/${id}/`);
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      siteListFilters(),
      siteListDuck,
      (state) => state.site.list
    ),
    deleteSite,
  };

  return <SiteListView {...props} />;
};
