import React from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import { Trans } from '@lingui/macro';
import { PaginationMeta } from 'util/backendapi/pagination';

/**
 * The configuration for specifying a report filter control to display on
 * the frontend.
 *
 * It includes the rendering of the UI component for the filter, and several
 * callbacks that are used for turning the rendered filter's selected value into
 * a frontend URL, and mapping the frontend URL to backend API filter parameters.
 *
 * NOTE: Most of the time, rather than defining your report's filters as a
 * list of `ReportFilter` object literals, you'll want to call one of the
 * functions that will create the type of filter you need:
 * - `reportFilterMenu()`
 * - `reportFilterAsyncMenu()`
 * - `reportFilterDate()`
 * - `reportFilterDatetime()`
 */
export interface ReportFilter {
  name: string;
  label: React.ReactNode;
  render: (filterInfo?: Model.ReportFilterInfo) => React.ReactNode;
  renderSSR: (info: FilterRenderingInfo) => React.ReactNode;
  getFormValFromUrlParam: (
    routeProps: RouteChildrenProps | RouteComponentProps
  ) => any;
  getUrlParamFromFormVal: (formValues: any) => Record<string, any>;
  getBackendFilterFromUrl: (
    routeProps: RouteChildrenProps | RouteComponentProps,
    isForExportUrl?: boolean
  ) => Record<string, any> | null;
  settings?: any;
}

/**
 * The configuration for specifying each column to display in a `ReportTable`
 * on the frontend.
 *
 * This includes the rendering of the column, which backend fields to
 * request (if using a Reports API endpoint), etc.
 *
 * NOTE: There is *not* a strict 1-to-1 mapping between frontend table
 * "columns" and backend API "fields".
 */
export interface ReportColumn<TReportItem extends {} = any> {
  // The label to display in the column's header cell
  label: React.ReactNode;
  // A name for the column, used in SIT ID's and frontend URLs.
  // Also, if `backendFieldName` is undefined or true, `name` is used as
  // the name of the primary backend field for this column.
  name: string;
  // The "primary" backend field for this column. Used for:
  // - Requested when this column is to be rendered
  // - If backend reportInfo is provided at render time, it's checked for:
  //     - Whether this column can be displayed (`display` backend field)
  //     - Whether this column is sortable (`is_sortable` backend field)
  // - If this column is selected for sorting, we tell the backend to sort
  // by this field name
  //
  // If `backendFieldName` is not provided, `name` is assumed to be the name
  // of the primary backend field. If `backendFieldName` is `false`, the column
  // has *no* primary backend field (e.g. the "Actions" column).
  //
  // See also getColBackendField()
  backendFieldName?: StringKeyOf<TReportItem> | boolean;
  // When this field is to be rendered, also fetch these backend fields (in
  // addition to the primary backend field, if any)
  //
  // NOTE: The report API endpoints do not include *any* fields of data
  // in their responses except those specifically asked for. So *any* field
  // referenced in the column's accessor *must* be included as its primary
  // backend field, or in `additionalFields`
  additionalFields?: StringKeyOf<TReportItem>[];
  // Controls whether the column is selectable in the columns selection menu,
  // and whether it's selected by default.
  visibility: ReportColumn_CANSELECT;
  // A CSS class name passed through to all <td> and <th> cells in the column.
  className?: string;
  // A CSS class name passed through to <td> cells in the column.
  tdClassName?: string;
  // If `true`, exclude this column from PDF exports, and exclude its backend
  // fields (both "main" and "additional" fields) from CSV exports.
  hideFromExport?: boolean;
  // Array of individual backend fields to exclude from CSV export of this column.
  // This can include the "primary" backend field and/or any "additional" backend
  // fields.
  hideFieldsFromCSV?: StringKeyOf<TReportItem>[];
  // A callback function that renders this column's cell in each row of the
  // table.
  // - If not supplied, and the column has a primary backend field, the
  // primary backend field will be displayed instead.
  //
  // NOTE: If you're displaying a table for a report API endpoint, all fields
  // of `row` referenced in the accessor *must* be present in the column's
  // `additionalFields` or its primary backend field. Because the report API
  // endpoint *only* includes the backend fields specifically requested.
  accessor?: (row: TReportItem, rowIdx: number) => React.ReactNode;
}
export const DEFAULT_HIDE = 0 as 0;
export const DEFAULT_SHOW = 1 as 1;
export const ALWAYS_SHOW = 2 as 2;
export type ReportColumn_CANSELECT =
  | typeof DEFAULT_HIDE
  | typeof DEFAULT_SHOW
  | typeof ALWAYS_SHOW;

export const ACTION_COLUMN = {
  label: <Trans>Actions</Trans>,
  name: 'actions',
  backendFieldName: false,
  visibility: ALWAYS_SHOW,
  hideFromExport: true,
  className: 'action-icons',
} as const;

/**
 * Calculates the "main backend field" of a frontend column definition.
 *
 * - If `reportApiFieldName` is a string, use that.
 * - If `reportApiFieldName` is `true` or not provided, use the column's `name`
 * field
 * - Else return `false`, meaning the column is not mapped to a backend
 * field. (e.g. the "Actions" column)
 *
 * @param column
 */
export function getColBackendField<TReportItem extends {} = any>(
  column: ReportColumn<TReportItem>
): StringKeyOf<TReportItem> | false {
  if (typeof column.backendFieldName === 'string') {
    return column.backendFieldName;
  } else if (
    column.backendFieldName === undefined ||
    column.backendFieldName === true
  ) {
    return column.name as StringKeyOf<TReportItem>;
  } else {
    return false;
  }
}

/**
 * The combined frontend and backend information about a column configuration.
 */
export interface ReportColumnRenderInfo<
  TReportItem extends {} = any,
  TField extends StringKeyOf<TReportItem> = StringKeyOf<TReportItem>
> {
  name: string;
  frontend: ReportColumn<TReportItem>;
  backend: null | Model.ReportColumnInfo<TReportItem, TField>;
  derivedAccessor: (row: TReportItem, rowIdx: number) => React.ReactNode;
}

/**
 * The props required by a standard report view.
 *
 * @typeParam T The Model type returned by the endpoint, e.g. `Model.ReportsSite`
 */
export interface ReportViewProps<T> {
  records: T[];
  reportInfo: Model.ReportInfo<T> | null;
  pagination: PaginationMeta;
  isLoading: boolean;
  generatedOnDatetime: string | null;
  errorMessage: string | null;
  isExportMode: boolean;
  reportInfoError: string | null;
}

/**
 * Information about how a filter should be rendered, based on its value
 * (or lack thereof) in the URL. (Return value of `getDisplayableFilters()`)
 */
export interface FilterRenderingInfo {
  filterName: string;
  hasValues: boolean;
  value: any;
  frontend?: ReportFilter;
  backend?: Model.ReportFilterInfo;
}
