import * as Sentry from '@sentry/browser';

/**
 * Sentry initialization for BROWSER mode
 */
if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://12ebb0535bc8457b811ddce12918a3aa@sentry.catalyst.net.nz/186',
    release: process.env.REACT_APP_APPLICATION_VERSION,
    ignoreErrors: [/ResizeObserver loop/],
  });
}
