import { DuckActions, StandardThunk } from 'main/store';
import { errorToString } from 'util/backendapi/error';
import { getPaginated } from 'util/backendapi/fetch';

export const ActionTypes = {
  FETCH_SURVEY_DATETIMES_START:
    'dms/storedPlot/surveyLevellingDetail/FETCH_OBS_POINT_SURVEY_DATETIMES_START',
  FETCH_SURVEY_DATETIMES_ERROR:
    'dms/storedPlot/surveyLevellingDetail/FETCH_OBS_POINT_SURVEY_DATETIMES_ERROR',
  FETCH_SURVEY_DATETIMES_RESPONSE:
    'dms/storedPlot/surveyLevellingDetail/FETCH_OBS_POINT_SURVEY_DATETIMES_RESPONSE',
  UNMOUNT: 'dms/storedPlot/surveyLevellingDetail/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_SURVEY_DATETIMES_START(observationPointId: number) {
    return {
      type: ActionTypes.FETCH_SURVEY_DATETIMES_START,
      observationPointId,
    };
  },
  FETCH_SURVEY_DATETIMES_ERROR(
    observationPointId: number,
    errorMessage: string
  ) {
    return {
      type: ActionTypes.FETCH_SURVEY_DATETIMES_ERROR,
      observationPointId,
      error: true,
      payload: errorMessage,
    };
  },
  FETCH_SURVEY_DATETIMES_RESPONSE(
    observationPointId: number,
    readingDatetimes: string[],
    numReadings: number
  ) {
    return {
      type: ActionTypes.FETCH_SURVEY_DATETIMES_RESPONSE,
      observationPointId,
      payload: {
        readingDatetimes,
        numReadings,
      },
    };
  },
  UNMOUNT() {
    return {
      type: ActionTypes.UNMOUNT,
    };
  },
};

export type StoredPlotSurveyLevellingDetailAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

interface SingleObsPointState {
  isLoading?: boolean;
  errorMessage: string | null;
  readingDatetimes: string[];
  numReadings: number | null;
}

export type StoredPlotSurveyLevellingDetailState = {
  [OP: number]: SingleObsPointState;
};

function initialState(): StoredPlotSurveyLevellingDetailState {
  return {};
}

export function storedPlotSurveyLevellingDetailReducer(
  state = initialState(),
  action: StoredPlotSurveyLevellingDetailAction
): StoredPlotSurveyLevellingDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_SURVEY_DATETIMES_START: {
      const newState: SingleObsPointState =
        action.observationPointId in state
          ? {
              ...state[action.observationPointId],
              isLoading: true,
            }
          : {
              isLoading: true,
              errorMessage: null,
              readingDatetimes: [],
              numReadings: null,
            };
      return {
        ...state,
        [action.observationPointId]: newState,
      };
    }
    case ActionTypes.FETCH_SURVEY_DATETIMES_ERROR:
      if (action.observationPointId in state) {
        return {
          ...state,
          [action.observationPointId]: {
            ...state[action.observationPointId],
            isLoading: false,
            errorMessage: action.payload,
            readingDatetimes: [],
            numReadings: null,
          },
        };
      } else {
        return state;
      }
    case ActionTypes.FETCH_SURVEY_DATETIMES_RESPONSE:
      if (action.observationPointId in state) {
        return {
          ...state,
          [action.observationPointId]: {
            ...state[action.observationPointId],
            isLoading: false,
            errorMessage: null,
            readingDatetimes: action.payload.readingDatetimes,
            numReadings: action.payload.numReadings,
          },
        };
      } else {
        return state;
      }
    case ActionTypes.UNMOUNT:
      return initialState();
    default:
      return state;
  }
}

export const unmountStoredSurveyLevellingPlotForm = ActionCreators.UNMOUNT;

export function fetchObsPointSurveyDatetimes(
  observationPointId: number
): StandardThunk {
  return async function (dispatch, getState) {
    // Don't re-fetch readings for the same observation point
    if (observationPointId in getState().storedPlot.surveyLevellingDetail) {
      return;
    }

    dispatch(ActionCreators.FETCH_SURVEY_DATETIMES_START(observationPointId));

    try {
      const { data: readings, pagination } = await getPaginated(
        '/readings/simple/',
        {
          effective: true,
          observation_point: observationPointId,
          limit: 1000,
        }
      );

      dispatch(
        ActionCreators.FETCH_SURVEY_DATETIMES_RESPONSE(
          observationPointId,
          readings.map((r) => r.reading_datetime),
          pagination?.total ?? 0
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_SURVEY_DATETIMES_ERROR(
          observationPointId,
          errorToString(e)
        )
      );
    }
  };
}
