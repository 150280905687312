import React from 'react';
import { Field, FieldProps } from 'formik';
import isEqual from 'lodash/isEqual';
import AsyncSimpleSelect, {
  AsyncSimpleSelectProps,
  OnChangeFunc,
} from './asyncsimpleselect';
import { SimpleSelectOption } from '../simpleselect/simpleselect';

/**
 * A Formik Field wrapper around `AsyncSimpleSelect`
 */

export type AsyncSimpleSelectFieldProps<
  E = string,
  IsMulti extends boolean = false,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
> = {
  name: string;
  // detailsName: If provided, the full selected objects will be assigned to
  // the formik values under this field name. This is useful if you need to
  // get additional metadata besides just the "Value", from your async menu.
  detailsName?: string;
} & Optional<AsyncSimpleSelectProps<E, IsMulti, OPT>, 'onChange'>;

function InnerAsyncSimpleSelectField<
  E,
  IsMulti extends boolean = false,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
>(props: AsyncSimpleSelectFieldProps<E, IsMulti, OPT> & FieldProps) {
  const curDetails = props.detailsName && props.form.values[props.detailsName];
  const { setFieldValue } = props.form;
  const { onChange, name, ...otherProps } = props;
  const handleChange: OnChangeFunc<E, IsMulti, OPT> = React.useCallback(
    (value, details) => {
      if (props.detailsName && !isEqual(curDetails, details)) {
        // Update "details" form field. Do not update form "touched" state.
        setFieldValue(props.detailsName, details, false);
      }
      if (!isEqual(value, props.field.value)) {
        // Update main form field. DO update form "touched" state.
        setFieldValue(props.field.name, value, true);
      }
      if (onChange) {
        onChange(value, details);
      }
    },
    [
      curDetails,
      props.detailsName,
      props.field.name,
      props.field.value,
      onChange,
      setFieldValue,
    ]
  );

  return (
    <AsyncSimpleSelect
      value={props.field.value}
      name={props.field.name}
      onChange={handleChange as any}
      {...otherProps}
    />
  );
}

export function AsyncSimpleSelectField<
  E = string,
  IsMulti extends boolean = false,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
>(props: AsyncSimpleSelectFieldProps<E, IsMulti, OPT>) {
  return <Field component={InnerAsyncSimpleSelectField} {...props} />;
}
