import React, { useCallback, useMemo, useRef, MutableRefObject } from 'react';
import moment from 'moment';
import { Field, FieldProps } from 'formik';
import {
  DateInput,
  convertValueToState,
  DateInputProps,
  DateInputState,
  DateInputOnChangeFn,
} from './DateInput';
import { Trans } from '@lingui/macro';

export type DateInputFieldProps = Merge<
  Omit<DateInputProps, 'value' | 'onChange'>,
  {
    name: string;
    timeZone?: string;
    placeholder?: string;
    defaultValues?: string | Partial<DateInputState>;
    // If true, the component provides no value until every one of its child
    // text fields has something typed into it; and displays error messages
    // via field-level validation.
    requireAllFields?: boolean;
    // If provided, this Formik value slot will be populated with an object
    // holding the current text content of each of the child text fields.
    // Useful in combination with "requireAllFields" for determining if
    // the component is completely empty, or if it holds an invalid partial input.
    detailsName?: string;
  }
>;

/**
 *
 * A wrapper of DateInput component to make it smarter & work well with Formik.
 *
 * - Deal with timezone!
 * - Provide validation message when user manage to enter dumb data
 */
export function DateInputField(props: DateInputFieldProps) {
  const {
    placeholder,
    name,
    defaultValues,
    timeZone,
    requireAllFields,
    detailsName,
    ...dateInputProps
  } = props;

  const inputStateRef = useRef<DateInputState>();

  const validate = useCallback(
    (val: string) => {
      const rawInputs = inputStateRef.current;
      if (val && !dateInputProps.disabled) {
        const d = moment.utc(val);
        if (!d.isValid()) {
          return <Trans>Please enter a valid date.</Trans>;
        } else {
          const year = Number(rawInputs?.year);
          if (year < 1900 || year > 2100) {
            return <Trans>Year must be between [1900, 2100].</Trans>;
          }
        }
      }

      if (requireAllFields && rawInputs) {
        // Check the input elements from left to right
        if (!rawInputs.day) {
          return <Trans>Please enter a day of the month (DD)</Trans>;
        }
        if (!rawInputs.month) {
          return <Trans>Please enter a month (MM)</Trans>;
        }
        if (!rawInputs.year) {
          return <Trans>Please enter a year (YYYY)</Trans>;
        }
        if (!rawInputs.hour) {
          return <Trans>Please enter an hour (HH)</Trans>;
        }
        if (!rawInputs.minute) {
          return <Trans>Please enter minutes after the hour (mm)</Trans>;
        }
      }
    },
    [dateInputProps.disabled, requireAllFields]
  );

  const parsedPlaceholder = useMemo(() => {
    if (placeholder) {
      return convertValueToState(
        placeholder,
        Boolean(dateInputProps.isDateOnly)
      );
    }
  }, [placeholder, dateInputProps.isDateOnly]);

  const parsedDefaultValues = useMemo(() => {
    // When all fields are required, disable default values, so that the input
    // won't report any value until all fields are populated.
    if (requireAllFields) {
      return null;
    }

    if (typeof defaultValues !== 'string') {
      return defaultValues;
    }

    if (defaultValues) {
      return convertValueToState(
        defaultValues,
        Boolean(dateInputProps.isDateOnly)
      );
    }
  }, [requireAllFields, defaultValues, dateInputProps.isDateOnly]);

  return (
    <Field name={name} validate={validate}>
      {(formik: FieldProps) => {
        return (
          <DateInputFieldInner
            {...dateInputProps}
            formik={formik}
            timeZone={timeZone}
            defaultValues={parsedDefaultValues}
            placeholder={parsedPlaceholder}
            requireAllFields={requireAllFields}
            inputStateRef={inputStateRef}
            detailsName={detailsName}
          />
        );
      }}
    </Field>
  );
}

/**
 * A local inner component, mostly used so that we can memoize the "handle change"
 * function with some fields from the Formik context.
 *
 * @param props
 */
function DateInputFieldInner(
  props: Omit<DateInputProps, 'value' | 'onChange' | 'name'> & {
    formik: FieldProps;
    timeZone?: string;
    requireAllFields?: boolean;
    inputStateRef: MutableRefObject<DateInputState | undefined>;
    detailsName?: string;
  }
) {
  const {
    formik: {
      field: { name, value, onBlur },
      form: { setFieldValue },
    },
    timeZone,
    requireAllFields,
    inputStateRef,
    detailsName,
    ...dateInputProps
  } = props;

  const { isDateOnly } = dateInputProps;

  const handleDateChange: DateInputOnChangeFn = useCallback(
    (val: string, inputState) => {
      if (detailsName) {
        setFieldValue(detailsName, inputState, false);
      }
      setFieldValue(name, isDateOnly ? val : localTimeToUTC(val, timeZone));
      inputStateRef.current = inputState;
    },
    [detailsName, inputStateRef, isDateOnly, name, setFieldValue, timeZone]
  );

  return (
    <DateInput
      name={name}
      value={isDateOnly ? value : utcToLocalTime(value, timeZone)}
      onBlur={onBlur}
      onChange={handleDateChange}
      {...dateInputProps}
    />
  );
}

/**
 * Helper function to convert an absolute UTC timestamp into a no-tz datetime,
 * in the user's local timezone or in the specified timezone.
 *
 * @param value
 * @param timeZone
 */
function utcToLocalTime(value: string, timeZone?: string) {
  if (value) {
    const d = timeZone ? moment.utc(value).tz(timeZone) : moment(value);
    if (d.isValid()) {
      return d.format('YYYY-MM-DDTHH:mm:ss');
    }
  }

  return value;
}

/**
 * Helper function to convert a no-tz datetime into an absolute UTC timestamp.
 * If a timezone param is provided, it's assumed the no-tz datetime should be
 * treated as belonging to that timezone. Otherwise, the no-tz datetime is treated
 * as user's local timezone.
 *
 * @param value
 * @param timeZone
 */
function localTimeToUTC(value: string, timeZone?: string) {
  const d = timeZone
    ? moment.tz(value, 'YYYY-MM-DDTHH:mm:ss', timeZone)
    : moment(value, 'YYYY-MM-DDTHH:mm:ss');

  if (d.isValid()) {
    // we do not want the milliseconds
    return d.toISOString().split('.')[0] + 'Z';
  }

  return value;
}
