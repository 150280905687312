import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const userListDuck = makeReportingDuck(
  'users',
  (filters) => getPaginated('/reports/users/', filters),
  () => getApi('/reports/users/info/')
);

export type UserListAction = ReportingDuckActions<typeof userListDuck>;
