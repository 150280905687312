import React from 'react';
import { selectOneById, EntityTypes } from '../../ducks/entities';
import { connect, ResolveThunks } from 'react-redux';
import {
  fetchFileContent,
  editReadingsFile,
  unmountFileEditor,
  fetchFileParserMessages,
} from '../../ducks/fileeditor';
import { fetchReadingsFile } from '../../ducks/actions';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { parseNumberParamFromRouterProps } from '../../util/routing';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import { FileEditorView } from './fileeditorview';

// TODO: Get the backend api to turn these into an actual enum.
// Or just bite the bullet and actually fetch them from the
// `/update-types/:id/reasons/` endpoint.
export const fakeReasonForChangeOptions = Object.entries({
  1: 'Data entry error',
  2: 'NaN changed to zero (dry)',
  3: 'Data assigned to wrong observation point',
  4: 'Data units incorrect',
  5: 'Reading procedure incorrect',
}).map(([id, reason]) => ({ value: id, label: reason }));

type OwnProps = RouteComponentProps<{ fileId: string }>;
type StateProps = {
  fileId: number;
  finished: boolean;
  file: Model.ReadingsFileDecorated | null;
  fileContent: string | null;
  parserMessages: Model.ReadingsFileParseMessageDecorated[] | null;
  canEditFileFormat: boolean;
};

const mapDispatchToProps = {
  fetchReadingsFile,
  fetchFileContent,
  fetchFileParserMessages,
  editReadingsFile,
  unmountFileEditor,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

class InnerFileEditorScreen extends React.Component<Props> {
  static mapStateToProps(state: FullState, ownProps: OwnProps) {
    const { error, finished, fileContent, parserMessages } = state.fileEditor;
    const fileId = parseNumberParamFromRouterProps(ownProps, 'fileId', 0);
    const file = selectOneById(state, EntityTypes.READINGS_FILE, fileId);
    // Cannot edit TXT files because they're often too big.
    // TODO: Better to instead base this on the actual size of the
    // file, which can be found by doing a HEAD request.
    const canEditFileFormat = !(
      file && /\.txt$/.test(file.file_name.toLowerCase())
    );

    return {
      error,
      finished,
      fileContent,
      parserMessages,
      fileId,
      file,
      canEditFileFormat,
    };
  }

  render() {
    // TODO: Validate that the selected file is currently in the right status
    // to be editable. (file.uploadStatus === BatchStatusCodes.parse_error)
    // TODO: Validate that a file ID was actually provided!
    // TODO: Display any errors that happen while attempting to retrieve the
    // initial data, or submit it for saving.
    if (this.props.finished) {
      // After form is successfully submitted, go back to dashboard
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <FileEditorView
          isLoading={!this.props.file}
          handleSubmit={this.handleSubmit}
          reasonForChangeOptions={fakeReasonForChangeOptions}
          file={this.props.file}
          id={this.props.fileId}
          fileContent={this.props.fileContent}
          parserMessages={this.props.parserMessages}
          canEditFileFormat={this.props.canEditFileFormat}
        />
      );
    }
  }

  async componentDidMount() {
    // Nothing to do if there is no fileId (although our router setup should
    // prevent this component from even loading in that case)
    if (!this.props.fileId) {
      // TODO: log something, or print an error message?
      return;
    }

    if (!this.props.file) {
      await this.props.fetchReadingsFile(this.props.fileId);
    }
    if (this.props.fileId) {
      this.props.fetchFileParserMessages(this.props.fileId);
    }
    if (this.props.file && this.props.canEditFileFormat) {
      this.props.fetchFileContent(this.props.file.content);
    }
  }

  componentWillUnmount() {
    this.props.unmountFileEditor();
  }

  handleSubmit = (reasonForChange: number, fileContent: string) => {
    this.props.editReadingsFile(
      this.props.fileId,
      reasonForChange,
      fileContent
    );
  };
}

const FileEditorScreen = connect(
  InnerFileEditorScreen.mapStateToProps,
  mapDispatchToProps
)(InnerFileEditorScreen);
export default FileEditorScreen;
