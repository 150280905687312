import React from 'react';
import {
  SortableContext,
  rectSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { Icon } from 'components/base/icon/icon';
import { PreviewMediaButton } from 'screens/media/preview/PreviewMediaModal';
import { Trans } from '@lingui/macro';
import { Media_FILE_TYPE } from 'util/backendapi/types/Enum';
import {
  InspectionReportMedia,
  ReportsInspectionReading,
} from 'util/backendapi/types/Model';

export interface InspectionReportSortableMediaListProps {
  readingId: number;
  media: InspectionReportMedia[];
  mediaLabels: Record<number, string>;
  observationPointCode: string;
  timeZone: string;
  canEditMediaCaption: boolean;
  canEditInspectionReport: boolean;
  refreshList: () => void;
  modifyReportRow: (
    id: number,
    partialRow: Partial<ReportsInspectionReading>
  ) => void;
  onToggleExportMedia: (mediaId: number, exportMedia: boolean) => Promise<void>;
  onUpdateMediaOrder: (readingId: number, mediaIds: number[]) => Promise<void>;
}

interface InspectionReportSortableMediaProps {
  media: InspectionReportMedia;
  label: string;
  observationPointCode: string;
  timeZone: string;
  refreshList: () => void;
  onToggleExportMedia: () => Promise<void>;
  onAfterEditMediaCaption: (caption: string) => void;
  canEditMediaCaption: boolean;
  canEditInspectionReport: boolean;
}

function InspectionReportSortableMedia(
  props: InspectionReportSortableMediaProps
) {
  const {
    media,
    label,
    observationPointCode,
    timeZone,
    refreshList,
    onToggleExportMedia,
    onAfterEditMediaCaption,
    canEditMediaCaption,
    canEditInspectionReport,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isSorting,
  } = useSortable({ id: media.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="inspection-report-media"
    >
      <div className="inspection-report-media-label">{label}</div>
      <PreviewMediaButton
        key={`media-button-${media.id}`}
        media={media}
        observationPointCode={observationPointCode}
        timeZone={timeZone}
        onAfterSubmit={refreshList}
        canReplaceMedia={canEditMediaCaption}
        canEditMediaCaption={canEditMediaCaption}
        onAfterEditMediaCaption={onAfterEditMediaCaption}
      />
      {!isSorting ? (
        <>
          <div className="inspection-report-media-export">
            {media.file_type === Media_FILE_TYPE.IMAGE ? (
              <>
                <input
                  id={`export-media-checkbox-${media.id}`}
                  type="checkbox"
                  checked={media.export === false ? false : true}
                  disabled={!canEditInspectionReport}
                  onChange={onToggleExportMedia}
                />
                <label htmlFor={`export-media-checkbox-${media.id}`}>
                  <Trans>Export</Trans>
                </label>
              </>
            ) : null}
          </div>
          {media.description === '' ? (
            <Icon
              className="inspection-report-media-requires-caption-icon"
              type="icon-comment"
            >
              <span>
                <Trans>Requires caption</Trans>
              </span>
            </Icon>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export function InspectionReportSortableMediaList(
  props: InspectionReportSortableMediaListProps
) {
  const {
    readingId,
    media,
    mediaLabels,
    observationPointCode,
    timeZone,
    canEditMediaCaption,
    canEditInspectionReport,
    refreshList,
    modifyReportRow,
    onUpdateMediaOrder,
    onToggleExportMedia,
  } = props;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Drag activation distance
      activationConstraint: {
        distance: 5,
      },
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragEnd={async (event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
          const sourceIndex = media.findIndex((m) => m.id === active.id);
          const destIndex = media.findIndex((m) => m.id === over.id);

          let updatedMedia = [...media];
          const sourceMedia = updatedMedia[sourceIndex];
          updatedMedia.splice(sourceIndex, 1);
          updatedMedia.splice(destIndex, 0, sourceMedia);
          modifyReportRow(readingId, { media: updatedMedia });

          const mediaIds = updatedMedia.map((m) => m.id);
          try {
            await onUpdateMediaOrder(readingId, mediaIds);
          } catch (e) {
            // Revert order if exception
            modifyReportRow(readingId, { media: media });
          }
        }
      }}
    >
      <SortableContext items={media} strategy={rectSortingStrategy}>
        {media.map((m, idx) => (
          <InspectionReportSortableMedia
            media={m}
            label={mediaLabels?.[m.id] ?? ''}
            key={idx}
            observationPointCode={observationPointCode}
            timeZone={timeZone}
            refreshList={refreshList}
            canEditMediaCaption={canEditMediaCaption}
            canEditInspectionReport={canEditInspectionReport}
            onAfterEditMediaCaption={(content) => {
              let updatedMedia = [...media];
              updatedMedia[idx] = {
                ...updatedMedia[idx],
                description: content,
              };
              modifyReportRow(readingId, { media: updatedMedia });
            }}
            onToggleExportMedia={async () => {
              const exportMedia = m.export === false ? true : false;

              try {
                await onToggleExportMedia(m.id, exportMedia);
              } catch (e) {
                // If there's an exception don't update the checkbox
                return;
              }

              let updatedMedia = [...media];
              updatedMedia[idx] = {
                ...updatedMedia[idx],
                export: exportMedia,
              };
              modifyReportRow(readingId, {
                media: updatedMedia,
              });
            }}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}
