import React from 'react';
import { Formik, Form, FormikProps, FormikConfig, FormikValues } from 'formik';
import { Trans } from '@lingui/macro';
import ActionBlock from '../../actionblock/actionblock';
import Button, { ButtonPrimary, ButtonSecondary } from '../../button/button';
import { AlertDanger } from '../../alert/alert';
import {
  FormCardSection,
  CardSection,
  FormCard,
  Card,
  CardSectionProps,
} from '../../card/card';

/**
 * An abstract class that represents the "CardSectionComponent" that we pass
 * to the render function of EditableCard, which may be either a CardSection
 * or a FormCardSection.
 */
abstract class EditableCardSection extends React.Component<
  CardSectionProps<HTMLElement>
> {}
export type EditableCardSectionComponent = typeof EditableCardSection;

export type EditableCardProps<Values = FormikValues> = {
  // The header section of the card.
  header?: React.ReactNode;
  footer?: React.ReactNode;
  // A machine-readable name for the section. Used for computing SIT ids.
  name: string;
  // A function with the content of the form. It'll be passed a "props" argument
  // containing "formik" (the formik render props) and "CardSectionComponent",
  // which will be the component to use for the card sections.
  render: (props: {
    formik: FormikProps<Values>;
    CardSectionComponent: EditableCardSectionComponent;
  }) => React.ReactNode;
  hasEditPermission?: boolean;
  // Whether to disable its "change"/"save"/"cancel" buttons.
  shouldDisable?: boolean;
  // Whether to show the card in read mode or edit mode.
  isEditMode?: boolean;
  // Whether the component should be updated if initialValues changes.
  // Can be useful in some cases to disable this e.g. while editing
  enableReinitialize?: boolean;
  // An error message to show at the top of the form.
  errorMessage?: React.ReactNode;
  // The function to call to change the card into edit mode.
  startEditing: () => void;
  // The function to call to switch the card out of edit mode.
  stopEditing: () => void;
  saveButton?: (formik: FormikProps<Values>) => React.ReactNode;
  children?: never;
} & Omit<FormikConfig<Values>, 'render' | 'children'>;

/**
 * A component for the read/write forms displayed as "cards". It takes care of
 * two things:
 *
 * 1. Switching the display of the form between <Card>/<CardSection> and
 * <FormCard>/<FormCardSection> depending on whether it's in edit mode or not.
 * 2. Rendering the standard "Change" and "Cancel / Save" buttons at the top
 * of the form.
 *
 * NOTE that it is *stateless*. It relies on its parent component to manage
 * its state, through its boolean props.
 *
 * TODO: A standardized component that also takes care of more of the state?
 * But that would be less flexible, so it's probably not worth doing.
 *
 * @example See `editablecard.stories.js`
 * @export
 * @class EditableCard
 * @extends {React.Component}
 */
export default class EditableCard<
  Values = FormikValues
> extends React.PureComponent<EditableCardProps<Values>> {
  static defaultProps = {
    hasEditPermission: true,
  };

  renderButtons = (formik: FormikProps<Values>) => {
    if (!this.props.hasEditPermission) {
      return null;
    }

    if (this.props.isEditMode) {
      return (
        <ActionBlock>
          <Button
            data-testid={`cancel-edit-${this.props.name}`}
            onClick={() => {
              formik.handleReset();
              this.props.stopEditing();
            }}
            disabled={this.props.shouldDisable}
          >
            <Trans>Cancel</Trans>
          </Button>
          {this.props.saveButton ? (
            this.props.saveButton(formik)
          ) : (
            <ButtonPrimary
              data-testid={`submit-edit-${this.props.name}`}
              type="submit"
              disabled={this.props.shouldDisable}
              iconType="icon-save"
            >
              <Trans>Save</Trans>
            </ButtonPrimary>
          )}
        </ActionBlock>
      );
    } else {
      return (
        <ActionBlock>
          <ButtonSecondary
            data-testid={`edit-${this.props.name}`}
            onClick={this.props.startEditing}
            disabled={this.props.shouldDisable}
            iconType="icon-edit"
          >
            <Trans>Edit</Trans>
          </ButtonSecondary>
        </ActionBlock>
      );
    }
  };

  render() {
    const {
      shouldDisable,
      isEditMode,
      enableReinitialize,
      errorMessage,
      header,
      footer,
      name,
      render,
      startEditing,
      stopEditing,
      ...propsForFormik
    } = this.props;

    const CardComponent = isEditMode ? FormCard : Card;
    const CardSectionComponent: EditableCardSectionComponent = isEditMode
      ? FormCardSection
      : CardSection;

    return (
      <Formik<Values>
        enableReinitialize={enableReinitialize ?? true}
        {...propsForFormik}
        // Tell React to mount a completely different component for the form
        // in "display" mode and the form in "edit" mode, to help prevent
        // data from one leaking over into the other.
        key={String(isEditMode)}
      >
        {(formik) => (
          <Form>
            <CardComponent
              name={name}
              header={header}
              subHeader={this.renderButtons(formik)}
              footer={footer}
            >
              {this.props.errorMessage && (
                <AlertDanger>{this.props.errorMessage}</AlertDanger>
              )}
              {this.props.render({ formik, CardSectionComponent })}

              {/* we render the Cancel & Save button in edit mode at the bottom of the content */}
              {this.props.isEditMode ? (
                <CardSectionComponent name="card-actions-bottom">
                  {this.renderButtons(formik)}
                </CardSectionComponent>
              ) : null}
            </CardComponent>
          </Form>
        )}
      </Formik>
    );
  }
}
