import React from 'react';
import {
  ExportPanelModalButton,
  GetExportUrlFunc,
} from 'components/modules/exportpanel/exportpanel';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';
import { prepareApiQueryParams } from 'util/backendapi/fetch';
import { getReportApiBackendParams } from '../report-utils';
import { ReportColumn, ReportFilter } from '../report-types';
import { Endpoint, Filter } from 'util/backendapi/models/api.interfaces';
import { RCPWithQueryParams } from 'util/routing';
import { RouteChildrenProps, withRouter } from 'react-router';
import { MessageDescriptor } from '@lingui/core';
import { isNotNull } from 'util/validation';
import { ReportParamsTypes } from 'server/ssr.types';

/**
 * An export button for report screens using a Reports API endpoint.
 * (TODO: It may also work for endpoints that use the Report Table component
 * without a Reports API endpoint?)
 *
 * The main thing it provides, is the code to generate the `getExportUrl()`
 * function needed by the Export panel.
 */

interface Props {
  reportUrl: ReportParamsTypes['type'] & keyof Endpoint.GET;
  columns: ReportColumn[];
  filters: ReportFilter[];
  fileNameBase: MessageDescriptor;
  pdfExtraParams?: any;
}
function InnerExportReportButton(
  props: Props & RCPWithQueryParams<Filter.ReportsStandardFilters>
) {
  const routeProps = React.useMemo<RouteChildrenProps>(
    () => ({
      history: props.history,
      location: props.location,
      match: props.match,
    }),
    [props.history, props.location, props.match]
  );
  const { reportUrl, pdfExtraParams, columns, filters, fileNameBase } = props;
  const getExportUrl = React.useCallback<GetExportUrlFunc>(
    async (
      exportFormat,
      { queryParams, location },
      i18n,
      generatedDatetime,
      pageOrientation
    ) => {
      if (exportFormat === ExportFormats.CSV) {
        return {
          path: reportUrl,
          queryParams: prepareApiQueryParams({
            ...getReportApiBackendParams(
              routeProps,
              columns,
              filters,
              exportFormat
            ),
            limit: undefined,
            offset: undefined,
          }),
          // Reports API endpoint does not add the "Content-Disposition: attachment"
          // header, so we need to generate an appropriate filename here on the
          // client side.
          filename: `${i18n._(fileNameBase)} - ${generatedDatetime}.csv`,
        };
      } else if (exportFormat === ExportFormats.PDF) {
        // treat all the filters with `settings.loadDefaults` as async filters
        // we'll load all the selected options and send them to the backend
        const asyncFiltersOptions = (
          await Promise.all(
            filters
              .filter((f) => {
                return Boolean(f.settings?.loadDefaults);
              })
              .map((f) => {
                const values = f.getFormValFromUrlParam(routeProps);

                if (!values || (Array.isArray(values) && !values.length)) {
                  return Promise.resolve(null);
                }

                return f
                  .settings!.loadDefaults(values)
                  .then((options: any) => ({
                    name: f.name,
                    options,
                  }));
              })
          )
        ).filter(isNotNull);

        return {
          path: `/export/pdf${reportUrl}`,
          queryParams: prepareApiQueryParams({
            ...getReportApiBackendParams(
              routeProps,
              columns,
              filters,
              exportFormat
            ),
            passthrough: JSON.stringify({
              async_filter_options: asyncFiltersOptions,
              ...(pdfExtraParams ? { extra: pdfExtraParams } : null),
            }),
            url: [location.pathname, location.search]
              .filter((s) => Boolean(s))
              .join('/'),
            limit: undefined,
            offset: undefined,
            page_orientation: pageOrientation,
          }),
        };
      } else {
        return {
          path: `/render/${exportFormat}${location.pathname}`,
          queryParams,
        };
      }
    },
    [routeProps, reportUrl, pdfExtraParams, columns, filters, fileNameBase]
  );
  return (
    <ExportPanelModalButton
      canExportCsv
      canExportPdf
      getExportUrl={getExportUrl}
      taskQueueFormats={[ExportFormats.PDF]}
      canSelectPageOrientation={true}
    />
  );
}

export const ExportReportButton = withRouter(InnerExportReportButton);
