import React from 'react';
import { FastField, FieldProps } from 'formik';

type Props = Merge<
  React.InputHTMLAttributes<HTMLInputElement>,
  {
    name: string;
  }
>;

/**
 * Under the hood, this component doesn't actually transform the text the user
 * types, as they type. Doing that would require complex logic to prevent the
 * cursor from jumping around.
 *
 * Instead, it does this:
 *
 * 1. Applies a CSS "text-transform: uppercase" rule to make the input.value *look*
 *   uppercase.
 * 2. Keeps the formik value separate and parallel, and does force the Formik
 *   value to be uppercase. Does a case-insensitive comparison between the
 *   Formik value and the input.value, to detect actual Formik-driven changes
 *   in value.
 *
 * @see https://www.the-art-of-web.com/html/input-field-uppercase/
 */
function InnerUppercaseTextField(props: FieldProps<string>) {
  const { form, field, ...otherProps } = props;
  const [userInput, setUserInput] = React.useState(field.value);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newInput = e.target.value;
      if (newInput !== userInput) {
        setUserInput(newInput);
      }
      form.setFieldValue.call(null, field.name, newInput.toUpperCase());
      form.setFieldTouched.call(null, field.name, true);
    },
    [field.name, form.setFieldTouched, form.setFieldValue, userInput]
  );

  React.useEffect(() => {
    if (field.value.toUpperCase() !== userInput.toUpperCase()) {
      setUserInput(field.value.toUpperCase());
    }
  }, [field.value, userInput]);

  return (
    <input
      type="text"
      style={{ textTransform: 'uppercase' }}
      {...otherProps}
      {...field}
      value={userInput}
      onChange={handleChange}
    />
  );
}

/**
 * A form field for that forces any entered letters to be uppercase.
 */
export function UppercaseTextField(props: Props) {
  return <FastField component={InnerUppercaseTextField} {...props} />;
}
