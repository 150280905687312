import { ActionTypes } from 'ducks/actions';
import { alarmParameterDuck } from 'ducks/alarm-parameter/list';
import {
  alarmReportDuck,
  alarmCommentsReportDuck,
} from 'ducks/alarm-report/list';
import { alarmNotificationDetailReducer } from 'ducks/alarmNotification/detail';
import { alarmNotificationListDuck } from 'ducks/alarmNotification/list';
import { alarmParametersReducer } from 'ducks/alarmparameters';
import { alarmReportsReducer } from 'ducks/alarm-report/detail';
import { areaDetailReducer } from 'ducks/area/detail';
import { areaListDuck } from 'ducks/area/list';
import { auditLogsListDuck } from 'ducks/auditLog/list';
import { bulkCreateErrorsReducer } from 'ducks/bulk-create/errors';
import { checkReadingsReducer } from 'ducks/checkreadings';
import { checksheetInstanceEditReducer } from 'ducks/checksheet-instance/edit';
import { checksheetInstanceListDuck } from 'ducks/checksheet-instance/list';
import { checksheetTemplateListDuck } from 'ducks/checksheet-template/list';
import { clientAreasReducer } from 'ducks/client/areas';
import { clientDetailReducer } from 'ducks/client/detail';
import { clientListDuck } from 'ducks/client/list';
import closeoutReducer from 'ducks/closeout';
import { commentListDuck } from 'ducks/comments/list/list';
import { readingCommentListDuck } from 'ducks/comments/list/list';
import { commentsPanelListReducer } from 'ducks/comments/panel/list';
import { commentsPanelReducer } from 'ducks/comments/panel/panel';
import { dashboardReducer } from 'ducks/dashboard';
import { areaObsPointMenuReducer } from 'ducks/data-logger/area-obs-point-menu';
import { dataLoggerChannelsDuck } from 'ducks/data-logger/channels';
import { dataLoggerDetailReducer } from 'ducks/data-logger/detail';
import { dataLoggerListReducer } from 'ducks/data-logger/list';
import entitiesReducer from 'ducks/entities';
import { fileGateDuck } from 'ducks/file-gate';
import { fileUploadsReducer } from 'ducks/file-uploader';
import fileEditorReducer from 'ducks/fileeditor';
import { GlobalActionTypes } from 'ducks/global';
import { groupReducer } from 'ducks/group';
import { inspectionBatchDuck } from 'ducks/inspection-batch/list';
import { inspectionReadingDuck } from 'ducks/inspection-reading/list';
import { instrumentTypeDetailReducer } from 'ducks/instrument-type/detail';
import { instrumentTypeListDuck } from 'ducks/instrument-type/list';
import { authReducer, loginPageReducer, userReducer } from 'ducks/login';
import { mediaListDuck } from 'ducks/media/list';
import { myExportsListDuck } from 'ducks/my-exports/list';
import { obsPointDetailAliasReducer } from 'ducks/obsPoint/detail/alias';
import { obsPointDetailDataLoggerChannelReducer } from 'ducks/obsPoint/detail/dataLoggerChannel';
import { obsPointDetailFormulaReducer } from 'ducks/obsPoint/detail/formula';
import obsPointDetailReducer from 'ducks/obsPoint/detail/main';
import { obsPointListDuck } from 'ducks/obsPoint/list/list';
import { scatterTimeSeriesPlotReducer } from 'ducks/plot/scatter-time-series';
import { spatialPlotReducer } from 'ducks/plot/spatial';
import { cumulativePlotReducer } from 'ducks/plot/cumulative';
import { surveyLevellingPlotReducer } from 'ducks/plot/survey-levelling';
import { plotSetDetailReducer } from 'ducks/plotset/detail';
import { plotSetListDuck } from 'ducks/plotset/list';
import { quickListReducer } from 'ducks/quicklist';
import { readingsBatchDuck } from 'ducks/readings-batch/list';
import { ReprocessingRequestDetailReducer } from 'ducks/readings/processingRequest/detail';
import { readingsDateRangeFilterReducer } from 'ducks/readingsdaterangefilter';
import { routeMarchObservationPointReducer } from 'ducks/routemarchobspoints';
import { routeMarchScheduleDuck } from 'ducks/routemarchschedule';
import { savedReportsListDuck } from 'ducks/savedReport/list';
import siteDetailReducer from 'ducks/site/detail';
import { siteListDuck } from 'ducks/site/list';
import { sitesMenuReducer } from 'ducks/sitesmenu';
import { storedPlotDetailReducer } from 'ducks/stored-plot/detail';
import { storedPlotListDuck } from 'ducks/stored-plot/list';
import { storedPlotSurveyLevellingDetailReducer } from 'ducks/stored-plot/survey-levelling-detail';
import { storedListDetailReducer } from 'ducks/storedList/detail';
import { storedListListReducer } from 'ducks/storedList/list';
import uiReducer from 'ducks/ui';
import userProfileReducer from 'ducks/userprofile';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import { AnyAction, combineReducers, Reducer } from 'redux';
import { routeMarchReducer } from '../ducks/routemarch';
import {
  STATE_TO_PERSIST_ON_LOGOUT,
  STATE_TO_PERSIST_ON_REFRESH,
} from './store';
import { userListDuck } from 'ducks/user/list';
import { readingProcessingRequestDuck } from 'ducks/readings/processingRequest/list';
import { retirementRequestDuck } from 'ducks/readings/retirement-request/list';
import { observationPointFormulaDuck } from 'ducks/observation-point-formula/list';
import { dataReviewReportReducer } from 'ducks/dataReview';
import { staticDataReportReducer } from 'ducks/static-data-report';
import { observationPointFormulaConstantDuck } from 'ducks/observation-point-formula-constant/list';
import { performanceIndicatorListDuck } from 'ducks/performance-indicator/list';

// Redux reducers.
// TODO: refactor these (and their related actions) into Redux "ducks" under
// the src/redux directory.

const backendBasicInitialState = {
  isFetching: false,
  status: undefined,
};

export function backendStatusReducer(
  state = { ...backendBasicInitialState },
  action: AnyAction
) {
  switch (action.type) {
    case ActionTypes.FETCH_BACKENDAPI_STATUS:
      return { ...state, isFetching: true, status: undefined };
    case ActionTypes.FETCH_BACKENDAPI_STATUS_RESPONSE:
      return { ...state, isFetching: false, status: action.status };
    case ActionTypes.FETCH_BACKENDAPI_STATUS_ERROR:
      return { ...state, isFetching: false, status: action.status };
    default:
      return state;
  }
}

// Our root reducer
const slicedReducers = combineReducers({
  alarmNotification: combineReducers({
    list: alarmNotificationListDuck.reducer,
    detail: alarmNotificationDetailReducer,
  }),
  alarmParameters: combineReducers({
    list: alarmParameterDuck.reducer,
    detail: alarmParametersReducer,
  }),
  alarmReports: combineReducers({
    detail: alarmReportsReducer,
    list: alarmReportDuck.reducer,
    alarmCommentsReport: alarmCommentsReportDuck.reducer,
  }),
  area: combineReducers({
    list: areaListDuck.reducer,
    detail: areaDetailReducer,
  }),
  auditLog: combineReducers({
    list: auditLogsListDuck.reducer,
  }),
  auth: authReducer,
  backendBasicStatus: backendStatusReducer,
  bulkCreateErrors: bulkCreateErrorsReducer,
  checkReadings: checkReadingsReducer,
  checksheetInstance: combineReducers({
    edit: checksheetInstanceEditReducer,
    list: checksheetInstanceListDuck.reducer,
  }),
  checksheetTemplate: combineReducers({
    list: checksheetTemplateListDuck.reducer,
  }),
  client: combineReducers({
    areas: clientAreasReducer,
    list: clientListDuck.reducer,
    detail: clientDetailReducer,
  }),
  closeout: closeoutReducer,
  comments: combineReducers({
    list: commentListDuck.reducer,
    panel: commentsPanelReducer,
    panelList: commentsPanelListReducer,
    readingComments: readingCommentListDuck.reducer,
  }),
  dashboard: dashboardReducer,
  dataLogger: combineReducers({
    channels: dataLoggerChannelsDuck.reducer,
    detail: dataLoggerDetailReducer,
    list: dataLoggerListReducer,
    areaObsPointMenu: areaObsPointMenuReducer,
  }),
  dataReviewReport: dataReviewReportReducer,
  staticDataReport: staticDataReportReducer,
  entities: entitiesReducer,
  fileEditor: fileEditorReducer,
  fileGate: fileGateDuck.reducer,
  fileUploads: fileUploadsReducer,
  groups: groupReducer,
  instrumentType: combineReducers({
    detail: instrumentTypeDetailReducer,
    list: instrumentTypeListDuck.reducer,
  }),
  loginPage: loginPageReducer,
  obsPoint: combineReducers({
    detail: combineReducers({
      main: obsPointDetailReducer,
      alias: obsPointDetailAliasReducer,
      dataLoggerChannel: obsPointDetailDataLoggerChannelReducer,
      formula: obsPointDetailFormulaReducer,
    }),
    list: obsPointListDuck.reducer,
  }),
  obsPointFormula: observationPointFormulaDuck.reducer,
  obsPointFormulaConstant: observationPointFormulaConstantDuck.reducer,
  performanceIndicator: combineReducers({
    list: performanceIndicatorListDuck.reducer,
  }),
  media: combineReducers({
    list: mediaListDuck.reducer,
  }),
  myExports: combineReducers({
    list: myExportsListDuck.reducer,
  }),
  plot: combineReducers({
    scatterTimeSeries: scatterTimeSeriesPlotReducer,
    surveyLevelling: surveyLevellingPlotReducer,
    spatial: spatialPlotReducer,
    cumulative: cumulativePlotReducer,
  }),
  plotSet: combineReducers({
    list: plotSetListDuck.reducer,
    detail: plotSetDetailReducer,
  }),
  quickList: quickListReducer,
  routeMarch: routeMarchReducer,
  routeMarchObservationPoint: routeMarchObservationPointReducer,
  routeMarchSchedule: routeMarchScheduleDuck.reducer,
  readingsDateRangeFilter: readingsDateRangeFilterReducer,
  readingsBatch: combineReducers({
    list: readingsBatchDuck.reducer,
  }),
  inspectionBatch: combineReducers({
    list: inspectionBatchDuck.reducer,
  }),
  inspectionReading: combineReducers({
    list: inspectionReadingDuck.reducer,
  }),
  reprocessingRequest: combineReducers({
    detail: ReprocessingRequestDetailReducer,
    list: readingProcessingRequestDuck.reducer,
  }),
  retirementRequest: retirementRequestDuck.reducer,
  savedReports: savedReportsListDuck.reducer,
  site: combineReducers({
    detail: siteDetailReducer,
    list: siteListDuck.reducer,
  }),
  sitesMenu: sitesMenuReducer,
  storedList: combineReducers({
    detail: storedListDetailReducer,
    list: storedListListReducer,
  }),
  storedPlot: combineReducers({
    list: storedPlotListDuck.reducer,
    detail: storedPlotDetailReducer,
    surveyLevellingDetail: storedPlotSurveyLevellingDetailReducer,
  }),
  ui: uiReducer,
  user: userReducer,
  userList: userListDuck.reducer,
  userProfile: userProfileReducer,
});

// A special extra reducer, to wipe all redux state when the user logs out.
export const rootReducer: Reducer<FullState, AnyAction> = function (
  state: FullState | undefined,
  action: AnyAction
) {
  switch (action.type) {
    case GlobalActionTypes.CLEAR_STATE:
      // When the user logs out, wipe all cached data and sensitive data
      if (state === undefined) {
        return slicedReducers(undefined, action);
      } else {
        const newState = slicedReducers(undefined, action);
        STATE_TO_PERSIST_ON_LOGOUT.forEach((key) =>
          lodashSet(newState, key, lodashGet(state, key))
        );
        return newState;
      }
    case GlobalActionTypes.CLEAR_CACHED_DATA:
      // When the user changes groups, clear all the cached data (as if they'd
      // reloaded the page.)
      if (state === undefined) {
        return slicedReducers(state, action);
      } else {
        const newState = slicedReducers(undefined, action);
        STATE_TO_PERSIST_ON_REFRESH.forEach((key) =>
          lodashSet(newState, key, lodashGet(state, key))
        );
        return newState;
      }
    default:
      return slicedReducers(state, action);
  }
};

/**
 * Helper function to return the initial Redux state.
 */
export function getDefaultInitialState() {
  return rootReducer(undefined, { type: 'no such action' });
}

// TODO: It would be better to define this as by combining the interfaces
// that represent the state managed by each of our reducers. But we can't
// do that until we TSify every reducer. So for now, using the returntype
// of the combined reducers is good enough.
export type FullState = Exclude<ReturnType<typeof slicedReducers>, undefined>;
