import React from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import ActionBlock from 'components/base/actionblock/actionblock';
import { Icon } from 'components/base/icon/icon';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import ObsPointGeneralSection from './sections/general';
import ObsPointInstallationSection from './sections/installation';
import { ObsPointCurrentFormulaSection } from './sections/currentformula/currentformula';
import { ObsPointDataLoggerSection } from './sections/data-logger-channel/dataloggerchannel';
import ObsPointReadingsSection from './sections/readings';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { ObsPointPerformanceIndicatorSection } from './sections/performance-indicator/PerformanceIndicatorSection';
import { ObsPointAliasSection } from './sections/alias/ObsPointAliasSection';
import { ObsPointFormSection } from 'ducks/obsPoint/detail/main';
import { ObsPointViewProps, SectionProps } from './maintobspoint.types';
import { BackButton } from 'components/base/back-button/BackButton';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { CreateReadingModal } from './CreateReadingModal';
import { DMSLink } from 'components/base/link/DMSLink';
import { fetchAreaCodeActiveCivilFeatures } from 'ducks/area/detail';

export default class MaintainObservationPointView extends React.Component<ObsPointViewProps> {
  getPropsForSection = (sectionName: ObsPointFormSection): SectionProps => {
    return {
      isDisabled: Boolean(
        this.props.editingSection && this.props.editingSection !== sectionName
      ),
      isEditing: this.props.editingSection === sectionName,
      isLoading: this.props.isLoading,
      isSubmitting:
        this.props.editingSection === sectionName &&
        this.props.editingSectionIsSubmitting,
      startEditing: () => this.props.startEditing(sectionName),
      stopEditing: () => this.props.stopEditing(sectionName),
      onSubmit: (values) => this.props.onSubmit(sectionName, values),
      observationPoint: this.props.observationPoint,
      hasEditPermission: this.props.hasCreateObservationPointPermission,
    };
  };

  render() {
    return (
      <PageStandard
        name="maintain-observation-point"
        header={<Trans>Observation Point</Trans>}
        subHeader={this.props.observationPointCode}
        key={`maintobspoint-${this.props.observationPointCode}`}
      >
        {this.props.isLoading && <Loading />}
        {this.props.errorMessage && (
          <AlertDanger>{this.props.errorMessage}</AlertDanger>
        )}
        <div className="page-content-header-with-back-button-wrapper">
          <BackButton defaultBackUrl="/observation-point" />
          <div className="page-content-header">
            <ActionBlock className="columns-fluid">
              <div>
                <Link
                  to={`/quickplot/${this.props.observationPointCode}`}
                  className="btn"
                >
                  <span>
                    <Trans>View plot</Trans>
                  </span>
                  <Icon type="icon-plot" />
                </Link>
                <Link
                  to={`/quicklist/${this.props.observationPointCode}`}
                  className="btn"
                >
                  <span>
                    <Trans>View list</Trans>
                  </span>
                  <Icon type="icon-list" />
                </Link>
                {this.props.observationPoint && (
                  <Link
                    to={`/media/?observation_point=${this.props.observationPoint.id}`}
                    className="btn"
                  >
                    <span>
                      <Trans>View media</Trans>
                    </span>
                    <Icon type="icon-multimedia" />
                  </Link>
                )}
                <DMSLink
                  to={`/alarm-parameters/${this.props.observationPointCode}`}
                  className="btn"
                >
                  <span>
                    <Trans>View alarm parameters</Trans>
                  </span>
                  <Icon type="icon-bell" />
                </DMSLink>
              </div>
              {this.props.observationPoint &&
                (this.props.hasCreateReadingPermission ||
                  this.props.hasViewCommentPermission) && (
                  <div className="text-right">
                    {this.props.hasCreateReadingPermission && (
                      <ButtonShowModal
                        name="create-reading"
                        modalContent={() => (
                          <CreateReadingModal
                            observationPoint={this.props.observationPoint!}
                          />
                        )}
                        iconType="icon-plus"
                      >
                        <Trans>Manual reading</Trans>
                      </ButtonShowModal>
                    )}
                    {this.props.hasViewCommentPermission && (
                      <ButtonShowCommentsPanel
                        type={Enum.Comment_RESOURCE_TYPE.obsPoint}
                        metadata={{
                          area: this.props.observationPoint.area.id,
                          site: this.props.observationPoint.site.id,
                          observation_point: this.props.observationPoint.id,
                          timeZone: this.props.observationPoint.time_zone.name,
                        }}
                        commentReportParams={`resourcetype=${Enum.Comment_RESOURCE_TYPE.obsPoint}&observation_point=${this.props.observationPoint.id}`}
                      />
                    )}
                  </div>
                )}
            </ActionBlock>
          </div>
        </div>

        <ObsPointGeneralSection
          {...this.getPropsForSection('general')}
          observationPointCode={this.props.observationPointCode}
          menuOptions={this.props.menuOptions}
          onFetchAreaCodeCivilFeatures={fetchAreaCodeActiveCivilFeatures}
        />
        <ObsPointPerformanceIndicatorSection
          {...this.getPropsForSection('performanceIndicators')}
        />
        <ObsPointAliasSection
          {...this.getPropsForSection('alias')}
          observationPointCode={this.props.observationPointCode}
          observationPointId={
            this.props.observationPoint && this.props.observationPoint.id
          }
        />
        <ObsPointDataLoggerSection
          {...this.getPropsForSection('dataLogger')}
          observationPointCode={this.props.observationPointCode}
        />
        <ObsPointCurrentFormulaSection
          {...this.getPropsForSection('formula')}
          formulaOptions={this.props.menuOptions.formula}
        />
        <ObsPointInstallationSection
          {...this.getPropsForSection('installation')}
          menuOptions={this.props.menuOptions}
        />
        <ObsPointReadingsSection {...this.getPropsForSection('readings')} />
      </PageStandard>
    );
  }
}
