import * as React from 'react';

export interface InitProps<P = any> {
  onInit: (props: P) => void;
  shouldReinit?: (prevProps: P, currentProps: P) => boolean;
}

/**
 * There are many times that we need to convert a Functional Component to React.Component just to
 * fire a fetch inside `componentDidMount` lifecycle method.
 *
 * This HOC is made for that purpose, to help you hold on to the goodness of Functional Component
 * for a little longer.
 *
 * Usually it is used in conjunction with redux's `connect` to provide
 * `onInit()` as a dispatch prop.
 *
 * @example
 *
 * import { FullState } from '../../main/reducers';
 * import { StandardDispatch } from '../main/store';
 *
 * // "OwnProps" are props passed to the wrapped component by its parent
 * interface OwnProps = ...;
 *
 * // "StateProps" come from mapStateToProps
 * interface StateProps = ...;
 *
 * // "DispatchProps" come from mapDispatchToProps (not counting the stuff needed
 * // by withInit)
 * interface DispatchProps = ...;
 *
 * // "Props" is all the props the wrapped component will receive from all
 * // these sources, plus withInit()
 * interface Props = OwnProps & StateProps & DispatchProps;
 *
 * function mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
 *   // ...
 * }
 *
 * type DispatchWithInitProps = DispatchProps & InitProps<Props>;
 *
 * function mapDispatchToProps(dispatch: StandardDispatch, ownProps: OwnProps): DispatchWithInitProps {
 *   return {
 *     onInit: (props: Props) => {
 *       // do your thing here
 *     },
 *     // ...
 *   }
 * }
 *
 * export const YourConnectedComponent = connect<
 *   StateProps,
 *   DispatchWithInitProps,
 *   OwnProps,
 *   FullState
 * >(
 *   mapStateToProps,
 *   mapDispatchToProps,
 * )(withInit(YourFunctionalComponent));
 */
function withInit<WrappedProps>(
  WrappedComponent: React.ComponentType<WrappedProps>
) {
  return class InitWrapper extends React.Component<
    WrappedProps & InitProps<WrappedProps>
  > {
    public componentDidMount() {
      this.props.onInit(this.props);
    }

    public componentDidUpdate(
      prevProps: WrappedProps & InitProps<WrappedProps>
    ) {
      if (
        typeof this.props.shouldReinit === 'function' &&
        this.props.shouldReinit(prevProps, this.props)
      ) {
        this.props.onInit(this.props);
      }
    }

    public render() {
      return React.createElement(WrappedComponent, this.props);
    }

    static WrappedComponent = WrappedComponent;
  };
}

export default withInit;
