import React, { useState, useEffect, useCallback } from 'react';
import {
  ReportColumn,
  ReportFilter,
  DEFAULT_SHOW,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { Trans, NumberFormat, t } from '@lingui/macro';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { formatDatetimeForDisplay } from 'util/dates';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { Icon } from 'components/base/icon/icon';
import { alarmReportDuck } from 'ducks/alarm-report/list';
import { useReportState, ReportStateProps } from 'hooks/use-report-state';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import {
  reportFilterDatetime,
  NO_TIMEZONE,
} from 'components/modules/report/filter/fields/FilterDate';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { DMSLink } from 'components/base/link/DMSLink';
import { reportFilterNumber } from 'components/modules/report/filter/fields/FilterNumber';
import Button from 'components/base/button/button';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { BulkChangeStatusModal } from './changestatus-modal/BulkChangeStatusModal';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { RCPWithQueryParams } from 'util/routing';
import { useSelector } from 'react-redux';
import { FullState } from 'main/reducers';
import { selectHasPermission } from 'util/user';

export const alarmReportFilters: ReportFilter[] = [
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterObservationPoints('observation_point', {
    placeholder: <Trans>Find observation point</Trans>,
  }),
  reportFilterMenu(
    'instrument_type',
    <Trans>Instrument type</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All instrument types</Trans>,
    }
  ),
  reportFilterNumber('alarm_number', <Trans>Alarm number</Trans>, {
    placeholder: t`All alarm numbers`,
  }),
  reportFilterMenu(
    'status',
    <Trans>Alarm status</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmReport_STATUS'),
    {
      placeholder: <Trans>All statuses</Trans>,
      options: Object.values(Enum.AlarmReport_STATUS),
    }
  ),
  reportFilterMenu(
    'category',
    <Trans>Category</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmReport_CATEGORY'),
    {
      placeholder: <Trans>All categories</Trans>,
      options: Object.values(Enum.AlarmReport_CATEGORY),
    }
  ),
  reportFilterNumber(
    'readings_batch__batch_number',
    <Trans>Batch number</Trans>,
    {
      placeholder: t`Enter batch number`,
    }
  ),
  reportFilterMenu(
    'alarm_parameter__comparison_type',
    <Trans>Comparison type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmParameter_COMPARISON_TYPE'),
    {
      placeholder: <Trans>All comparison types</Trans>,
      options: Object.values(Enum.AlarmParameter_COMPARISON_TYPE),
    }
  ),
  reportFilterMenu(
    'alarm_parameter__level',
    <Trans>Alarm level</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('AlarmParameter_LEVEL'),
    {
      placeholder: <Trans>All alarm levels</Trans>,
      options: Object.values(Enum.AlarmParameter_LEVEL),
    }
  ),
  reportFilterDatetime('created_datetime', <Trans>Alarm report date</Trans>),
  reportFilterDatetime(
    'adjusted_reading_entry__reading__reading_datetime',
    <Trans>Reading date</Trans>,
    // Filtered using "dam time" timezone for each row, calculated by the back end
    NO_TIMEZONE
  ),
];

export const alarmReportColumns: ReportColumn<Model.ReportsAlarmReport>[] = [
  {
    label: <Trans>Alarm number</Trans>,
    name: 'alarm-number',
    visibility: DEFAULT_SHOW,
    backendFieldName: 'alarm_number',
    additionalFields: ['id'],
    accessor: ({ id, alarm_number }) => (
      <DMSLink to={`/alarm-reports/${id}`}>{alarm_number}</DMSLink>
    ),
  },
  {
    label: <Trans>Category</Trans>,
    name: 'category',
    backendFieldName: 'category',
    visibility: DEFAULT_SHOW,
    accessor: ({ category }) =>
      category && <TransEnum enum="AlarmReport_CATEGORY" value={category} />,
  },
  {
    label: <Trans>Observation point</Trans>,
    name: 'observation-point-code',
    backendFieldName: 'observation_point__code',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Instrument type</Trans>,
    name: 'instrument-type-code',
    backendFieldName: 'instrument_type__code',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Alarm report date and time</Trans>,
    name: 'created-datetime',
    backendFieldName: 'created_datetime',
    additionalFields: ['area__time_zone__name'],
    hideFieldsFromCSV: ['area__time_zone__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({ created_datetime, area__time_zone__name: time_zone }) =>
      created_datetime && formatDatetimeForDisplay(created_datetime, time_zone),
  },
  {
    label: <Trans>Alarm status</Trans>,
    name: 'status',
    backendFieldName: 'status',
    visibility: DEFAULT_SHOW,
    accessor: ({ status }) => (
      <TransEnum enum={'AlarmReport_STATUS'} value={status} />
    ),
  },
  {
    label: <Trans>Comparison type</Trans>,
    name: 'comparison-type',
    backendFieldName: 'alarm_parameter__comparison_type',
    visibility: DEFAULT_SHOW,
    accessor: ({ alarm_parameter__comparison_type: comparison_type }) => (
      <TransEnum
        enum={'AlarmParameter_COMPARISON_TYPE'}
        value={comparison_type}
      />
    ),
  },
  {
    label: <Trans>Alarm level</Trans>,
    name: 'level',
    backendFieldName: 'alarm_parameter__level',
    visibility: DEFAULT_SHOW,
    accessor: ({ alarm_parameter__level: level }) => (
      <TransEnum enum={'AlarmParameter_LEVEL'} value={level} />
    ),
  },
  {
    label: <Trans>Min/max</Trans>,
    name: 'type',
    backendFieldName: 'alarm_parameter__type',
    visibility: DEFAULT_SHOW,
    accessor: ({ alarm_parameter__type: type }) => (
      <TransEnum enum={'AlarmParameter_TYPE'} value={type} />
    ),
  },
  {
    label: <Trans>Alarm parameter</Trans>,
    name: 'threshold',
    backendFieldName: 'alarm_parameter__threshold',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Adjusted reading</Trans>,
    name: 'adjusted-reading-value',
    backendFieldName: 'adjusted_reading_entry__value',
    visibility: DEFAULT_SHOW,
    accessor: ({ adjusted_reading_entry__value: reading_value }) => (
      <NumberFormat value={reading_value} />
    ),
  },
  {
    label: <Trans>Reading date and time</Trans>,
    name: 'reading-datetime',
    backendFieldName: 'adjusted_reading_entry__reading__reading_datetime',
    additionalFields: ['area__time_zone__name'],
    hideFieldsFromCSV: ['area__time_zone__name'],
    visibility: DEFAULT_SHOW,
    accessor: ({
      adjusted_reading_entry__reading__reading_datetime: reading_datetime,
      area__time_zone__name: time_zone,
    }) =>
      reading_datetime && formatDatetimeForDisplay(reading_datetime, time_zone),
  },

  {
    ...ACTION_COLUMN,
    additionalFields: [
      'id',
      'observation_point__code',
      // HACK: "category" column is required for the bulk select column.
      // Perhaps we should refactor the bulk select controls out of ReportTable
      // and make it so that a bulk selecting report just adds an additional
      // (frontend-only) column definition when bulk selecting is enabled!
      'category',
    ],
    accessor: ({ id, observation_point__code }) => (
      <>
        <DMSLink to={`/alarm-reports/${id}`}>
          <Icon type="icon-view" title={t`View alarm report`} />
        </DMSLink>
        <DMSLink to={`/alarm-parameters/${String(observation_point__code)}`}>
          <Icon type="icon-plot" title={t`Plot`} />
        </DMSLink>
        <DMSLink to={`/observation-point/${String(observation_point__code)}`}>
          <Icon type="icon-observation-point" title={t`Observation point`} />
        </DMSLink>
      </>
    ),
  },
];

export function AlarmReportListScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    alarmReportColumns,
    alarmReportFilters,
    alarmReportDuck,
    (state) => state.alarmReports.list
  );
  const location = useLocation();

  const selectedStatuses = alarmReportFilters
    .find((f) => f.name === 'status')
    ?.getFormValFromUrlParam({
      location,
    } as RCPWithQueryParams) as Enum.AlarmReport_STATUS[] | undefined;

  // If the user has set the "status" filter to exactly one value, get that
  // one value and pass it to the view so it can use it to determine whether
  // or not to show the "update status in bulk" controls
  const singleSelectedStatus =
    selectedStatuses?.length === 1 ? selectedStatuses[0] : null;

  return (
    <AlarmReportListView
      reportProps={reportState}
      singleSelectedStatus={singleSelectedStatus}
    />
  );
}

interface AlarmReportListViewProps {
  reportProps: ReportStateProps<Model.ReportsAlarmReport>;
  singleSelectedStatus: Enum.AlarmReport_STATUS | null;
}
export const AlarmReportListView: React.FunctionComponent<AlarmReportListViewProps> =
  function (props) {
    const { reportProps, singleSelectedStatus } = props;

    const hasCanViewUncancelledAlarmReportsPermission = useSelector(
      (state: FullState): boolean =>
        selectHasPermission(
          state,
          Enum.User_PERMISSION.can_view_uncancelled_alarm_reports
        )
    );

    // Whether or not the user has clicked the "Bulk update" button, which indicates
    // that we should display the bulk selection checkboxes.
    const [isBulkSelecting, setIsBulkSelecting] = useState<boolean>(false);
    const handleStartBulkSelecting = useCallback(
      () => setIsBulkSelecting(true),
      []
    );

    useEffect(() => {
      if (!singleSelectedStatus) {
        setIsBulkSelecting(false);
      }
    }, [singleSelectedStatus]);

    // Which rows are selected (if the user is currently bulk-selecting)
    const [bulkSelectedRows, setBulkSelectedRows] = useState<number[]>([]);

    const columns = alarmReportColumns;
    const filters = alarmReportFilters;
    let filtersToShow = [
      'area',
      'alarm_parameter__comparison_type',
      'alarm_parameter__level',
    ];
    let advancedAlarmFilters = [
      'alarm_number',
      'category',
      'alarm_parameter__comparison_type',
      'alarm_parameter__level',
    ];
    if (hasCanViewUncancelledAlarmReportsPermission) {
      filtersToShow = [
        'area',
        'status',
        'alarm_parameter__comparison_type',
        'alarm_parameter__level',
      ];
      advancedAlarmFilters = [
        'alarm_number',
        'status',
        'category',
        'alarm_parameter__comparison_type',
        'alarm_parameter__level',
      ];
    }

    return (
      <PageStandard
        name="alarm-reports-report"
        header={<Trans>Alarm reports</Trans>}
      >
        <div className="page-content-header-filters-actions">
          <ReportFiltersBlock
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={filters}
            filtersToShow={filtersToShow}
            isExportMode={reportProps.isExportMode}
          />
        </div>
        <div className="filtered-table-wrapper">
          <ActionBlock className="text-right">
            <HasPermission check="can_bulk_change_alarm_report_status">
              {singleSelectedStatus ? (
                isBulkSelecting ? (
                  <ButtonShowModal
                    iconType="icon-update"
                    name="bulk-change-status"
                    disabled={bulkSelectedRows.length === 0}
                    modalContent={(modalProps) => (
                      <BulkChangeStatusModal
                        {...modalProps}
                        currentStatus={singleSelectedStatus}
                        selectedAlarmReports={bulkSelectedRows.map(
                          (rowIdx) => reportProps.records[rowIdx]
                        )}
                        onAfterSubmit={reportProps.refreshList}
                      />
                    )}
                  >
                    <Trans>Bulk change status</Trans>
                  </ButtonShowModal>
                ) : (
                  <Button
                    iconType="icon-update"
                    onClick={handleStartBulkSelecting}
                    name="bulk-update"
                  >
                    <Trans>Bulk update</Trans>
                  </Button>
                )
              ) : null}
            </HasPermission>
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
            />
            <ButtonPrint />
            <ExportReportButton
              fileNameBase={t`Alarm reports`}
              reportUrl="/reports/alarm-reports/"
              columns={columns}
              filters={filters}
            />
            <AdvancedFiltersModalButton
              filtersBackend={reportProps.reportInfo?.filters}
              filtersFrontend={filters}
              sections={[
                {
                  name: 'type_section',
                  filters: [
                    'area',
                    'observation_point',
                    'instrument_type',
                    'readings_batch__batch_number',
                  ],
                },
                {
                  name: 'alarm_section',
                  filters: advancedAlarmFilters,
                },
                {
                  name: 'date_section',
                  filters: [
                    'created_datetime',
                    'adjusted_reading_entry__reading__reading_datetime',
                  ],
                },
              ]}
            />
          </ActionBlock>
          <ReportTable<Model.ReportsAlarmReport>
            {...reportProps}
            columns={columns}
            filters={filters}
            onBulkSelect={isBulkSelecting ? setBulkSelectedRows : undefined}
          />
        </div>
      </PageStandard>
    );
  };
