import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import MaintainGroupModalView, {
  AreaOption,
  GroupFormValues,
} from './maintgroupmodalview';
import {
  fetchEntityList,
  EntityTypes,
  selectAllInOrderedArray,
} from 'ducks/entities';
import { createGroup, updateGroup } from 'ducks/group';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import { FormikHelpers } from 'formik';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';

const selectAreaOptions = createSelector(
  (state: FullState) => selectAllInOrderedArray(state, EntityTypes.AREA_ALL),
  (itemList) => {
    return itemList
      ? itemList.map(({ id, code, name }) => ({
          value: id,
          label: `${code} - ${name}`,
          code,
        }))
      : null;
  }
);

interface OwnProps {
  hideModal: () => void;
  group: ForPostOrPut<Model.AreaGroupDecorated>;
}

interface StateProps {
  areaOptions: null | AreaOption[];
}

interface DispatchProps {
  fetchEntityList: (entityType: EntityTypes) => any;
  createGroup: (payload: ForPostOrPut<Model.AreaGroup>) => void;
  updateGroup: (id: number, payload: ForPatch<Model.AreaGroup>) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class _MaintainGroupModal extends React.Component<Props> {
  private _isMounted: boolean;

  constructor(props: Props) {
    super(props);
    this._isMounted = false;
  }

  static mapStateToProps(state: any) {
    return {
      areaOptions: selectAreaOptions(state),
    };
  }

  static mapDispatchToProps = {
    fetchEntityList,
    createGroup,
    updateGroup,
  };

  componentDidMount() {
    this.props.fetchEntityList(EntityTypes.AREA_ALL);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSubmit = async (
    values: GroupFormValues,
    formik: FormikHelpers<GroupFormValues>
  ) => {
    if (!values.id) {
      try {
        await this.props.createGroup(values);
        if (!this._isMounted) return;
        this.props.hideModal();
      } catch (e) {
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(values));
      }
    } else {
      try {
        await this.props.updateGroup(values.id, values);
        if (!this._isMounted) return;
        this.props.hideModal();
      } catch (e) {
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(values));
      }
    }
  };

  render() {
    return (
      <MaintainGroupModalView
        group={this.props.group}
        areaOptions={this.props.areaOptions || []}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const MaintainGroupModal = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  _MaintainGroupModal.mapStateToProps,
  _MaintainGroupModal.mapDispatchToProps
)(_MaintainGroupModal);
export default MaintainGroupModal;
