import React, { useMemo, useCallback } from 'react';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { Trans } from '@lingui/macro';
import mapValues from 'lodash/mapValues';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button, { ButtonPrimary } from 'components/base/button/button';
import {
  Field,
  ErrorMessage,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from 'formik';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { getCurrentDatetime } from 'util/dates';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import { defaultMemoize } from 'reselect';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import { Dictionary } from 'lodash';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { getExpectedFields } from 'util/backendapi/error';
import { CommentsPanelResourceTypes } from '../CommentsPanelView';
import { AreaSingleMenu } from 'components/modules/async-menu/AreaMenu';
import { SiteSingleMenu } from 'components/modules/async-menu/SiteMenu';
import { ObsPointMenu } from 'components/modules/async-menu/ObsPointMenu';
import { FormItem } from 'components/base/form/FormItem';
import { isNotNull } from 'util/validation';

export interface NewCommentFormValues {
  resourcetype: Enum.Comment_RESOURCE_TYPE;
  comment_type: Enum.Comment_TYPE;
  created_datetime: string;
  content: string;
}

interface FormValuesInternal extends NewCommentFormValues {
  area: number;
  site: number;
  observation_point: number;
}

type Props = {
  type: CommentsPanelResourceTypes | Enum.Comment_RESOURCE_TYPE[];
  permissions: Enum.User_PERMISSION[];
  onSubmit: (values: NewCommentFormValues, target?: number) => void;
  onCancel: () => void;
  timeZone: string | undefined;
};

const RESOURCE_TYPE_PERMISSIONS: {
  [K in StringKeyOf<
    typeof Enum.Comment_RESOURCE_TYPE
  >]: Enum.User_PERMISSION | null;
} = {
  area: Enum.User_PERMISSION.can_create_area_comments,
  alarmParameter: Enum.User_PERMISSION.can_create_alarm_parameter_comments,
  alarmReport: Enum.User_PERMISSION.can_create_alarm_report_comments,
  batch: null,
  reading: Enum.User_PERMISSION.can_create_reading_comments,
  readingGap: Enum.User_PERMISSION.can_create_observation_point_comments,
  readingInspector: Enum.User_PERMISSION.can_create_reading_comments,
  obsPoint: Enum.User_PERMISSION.can_create_observation_point_comments,
  site: Enum.User_PERMISSION.can_create_site_comments,
  routeMarch: Enum.User_PERMISSION.can_create_route_march_comments,
  client: Enum.User_PERMISSION.can_create_client_comments,
  media: Enum.User_PERMISSION.can_create_media_comments,
};

interface ResourceTypeOption {
  value: Enum.Comment_RESOURCE_TYPE;
  permission: Enum.User_PERMISSION;
  label: React.ReactNode;
}
const RESOURCE_TYPE_OPTIONS: {
  [K in StringKeyOf<typeof Enum.Comment_RESOURCE_TYPE>]: ResourceTypeOption;
} = mapValues(
  RESOURCE_TYPE_PERMISSIONS as Dictionary<Enum.User_PERMISSION>,
  (
    permission,
    resourceType: keyof typeof Enum.Comment_RESOURCE_TYPE
  ): ResourceTypeOption => ({
    value: Enum.Comment_RESOURCE_TYPE[resourceType],
    label: (
      <TransEnum
        enum="Comment_RESOURCE_TYPE"
        value={Enum.Comment_RESOURCE_TYPE[resourceType]}
      />
    ),
    permission,
  })
) as any;

const getResourceTypeOptions = defaultMemoize(
  (
    type: CommentsPanelResourceTypes | Enum.Comment_RESOURCE_TYPE[],
    permissions: Enum.User_PERMISSION[]
  ): SimpleSelectOption<Enum.Comment_RESOURCE_TYPE>[] => {
    // If the prop provided a list of types, just use those (if the user has
    // permission.)
    if (Array.isArray(type)) {
      return type
        .map((t) => {
          const opt = Object.values(RESOURCE_TYPE_OPTIONS).find(
            (opt) => opt.value === t
          );
          if (opt && permissions.includes(opt.permission)) {
            return opt;
          } else {
            return null;
          }
        })
        .filter(isNotNull);
    }

    // If the prop provided a single type, get that type and related ones
    switch (type) {
      case Enum.Comment_RESOURCE_TYPE.area:
        return [RESOURCE_TYPE_OPTIONS.area].filter((opt) =>
          permissions.includes(opt.permission)
        );

      case Enum.Comment_RESOURCE_TYPE.obsPoint:
        return [
          RESOURCE_TYPE_OPTIONS.area,
          RESOURCE_TYPE_OPTIONS.site,
          RESOURCE_TYPE_OPTIONS.obsPoint,
          RESOURCE_TYPE_OPTIONS.readingGap,
        ].filter((opt) => permissions.includes(opt.permission));

      case Enum.Comment_RESOURCE_TYPE.site:
        return [RESOURCE_TYPE_OPTIONS.area, RESOURCE_TYPE_OPTIONS.site].filter(
          (opt) => permissions.includes(opt.permission)
        );

      case Enum.Comment_RESOURCE_TYPE.routeMarch:
        return [RESOURCE_TYPE_OPTIONS.routeMarch].filter((opt) =>
          permissions.includes(opt.permission)
        );

      case Enum.Comment_RESOURCE_TYPE.alarmReport:
        return [
          RESOURCE_TYPE_OPTIONS.area,
          RESOURCE_TYPE_OPTIONS.site,
          RESOURCE_TYPE_OPTIONS.obsPoint,
          RESOURCE_TYPE_OPTIONS.alarmReport,
        ].filter((opt) => permissions.includes(opt.permission));

      case Enum.Comment_RESOURCE_TYPE.alarmParameter:
        return [
          RESOURCE_TYPE_OPTIONS.area,
          RESOURCE_TYPE_OPTIONS.site,
          RESOURCE_TYPE_OPTIONS.obsPoint,
          RESOURCE_TYPE_OPTIONS.alarmParameter,
        ].filter((opt) => permissions.includes(opt.permission));

      case Enum.Comment_RESOURCE_TYPE.reading:
        return [
          RESOURCE_TYPE_OPTIONS.area,
          RESOURCE_TYPE_OPTIONS.site,
          RESOURCE_TYPE_OPTIONS.obsPoint,
          RESOURCE_TYPE_OPTIONS.reading,
        ].filter((opt) => permissions.includes(opt.permission));

      case Enum.Comment_RESOURCE_TYPE.client:
        return [RESOURCE_TYPE_OPTIONS.client].filter((opt) =>
          permissions.includes(opt.permission)
        );

      case Enum.Comment_RESOURCE_TYPE.media:
        return [RESOURCE_TYPE_OPTIONS.media].filter((opt) =>
          permissions.includes(opt.permission)
        );

      default:
        return [];
    }
  }
);

export const canCreateComments = (
  type: CommentsPanelResourceTypes | Enum.Comment_RESOURCE_TYPE[],
  permissions: Enum.User_PERMISSION[]
) => getResourceTypeOptions(type, permissions).length > 0;

const commentTypeOptions = menuItemsFromEnum(
  'Comment_TYPE',
  Object.values(Enum.Comment_TYPE)
);

const getCommentTypeOptions = defaultMemoize(
  (type: Enum.Comment_RESOURCE_TYPE): SimpleSelectOption[] => {
    switch (type) {
      case Enum.Comment_RESOURCE_TYPE.area:
      case Enum.Comment_RESOURCE_TYPE.obsPoint:
      case Enum.Comment_RESOURCE_TYPE.site:
      case Enum.Comment_RESOURCE_TYPE.routeMarch:
      case Enum.Comment_RESOURCE_TYPE.alarmReport:
      case Enum.Comment_RESOURCE_TYPE.alarmParameter:
      case Enum.Comment_RESOURCE_TYPE.reading:
      case Enum.Comment_RESOURCE_TYPE.client:
      case Enum.Comment_RESOURCE_TYPE.media:
        return commentTypeOptions.filter((opt) =>
          [Enum.Comment_TYPE.analysis].includes(opt.value)
        );
      default:
        return [];
    }
  }
);

const NewCommentForm: React.FunctionComponent<Props> = ({
  type,
  permissions,
  onSubmit,
  onCancel,
  timeZone,
}) => {
  const isMultiTarget = Array.isArray(type);

  const dataTypeOptions = useMemo(
    () => getResourceTypeOptions(type, permissions),
    [permissions, type]
  );

  const initialValues: FormValuesInternal = useMemo(
    () => ({
      resourcetype: Array.isArray(type) ? (undefined as any) : type,
      comment_type: Enum.Comment_TYPE.analysis,
      created_datetime: getCurrentDatetime(),
      content: '',
      area: 0,
      site: 0,
      observation_point: 0,
    }),
    [type]
  );

  const validate = useCallback(
    (values: FormValuesInternal) => {
      let errors: FormikErrors<FormValuesInternal> = {};

      if (!values.resourcetype) {
        errors.resourcetype = (<Trans>Please select a Data Type</Trans>) as any;
      }

      if (!values.comment_type) {
        errors.comment_type = (
          <Trans>Please select a Comment Type</Trans>
        ) as any;
      }

      if (!values.created_datetime) {
        errors.created_datetime = (
          <Trans>Please enter comment date and time</Trans>
        ) as any;
      }

      if (!values.content) {
        errors.content = (<Trans>Please enter a comment</Trans>) as any;
      }

      if (isMultiTarget) {
        switch (values.resourcetype) {
          case Enum.Comment_RESOURCE_TYPE.area:
            if (!values.area) {
              errors.area = (<Trans>Please select an area</Trans>) as any;
            }
            break;
          case Enum.Comment_RESOURCE_TYPE.site:
            if (!values.site) {
              errors.site = (<Trans>Please select a site</Trans>) as any;
            }
            break;
          case Enum.Comment_RESOURCE_TYPE.obsPoint:
            if (!values.observation_point) {
              errors.observation_point = (
                <Trans>Please select an observation point</Trans>
              ) as any;
            }
        }
      }

      return errors;
    },
    [isMultiTarget]
  );

  const handleSubmit = React.useCallback(
    async (
      { area, site, observation_point, ...values }: FormValuesInternal,
      formik: FormikHelpers<FormValuesInternal>
    ) => {
      try {
        let target: number | undefined = undefined;
        if (isMultiTarget) {
          switch (values.resourcetype) {
            case Enum.Comment_RESOURCE_TYPE.area:
              target = area;
              break;
            case Enum.Comment_RESOURCE_TYPE.site:
              target = site;
              break;
            case Enum.Comment_RESOURCE_TYPE.obsPoint:
              target = observation_point;
              break;
          }
        }
        await onSubmit(values, target);
        onCancel();
      } catch (err) {
        formik.setSubmitting(false);
        showErrorsInFormik(formik, err, getExpectedFields(values), ErrorNotice);
      }
    },
    [isMultiTarget, onSubmit, onCancel]
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {({ values, isSubmitting, status }) => (
        <Form>
          <fieldset>
            <div className="form-group">
              <label htmlFor="add-comment-datatype">
                <Trans>Data type</Trans>
              </label>
              <SimpleSelectField
                name="resourcetype"
                id="add-comment-datatype"
                options={dataTypeOptions}
                isSearchable={false}
              />
              <ErrorMessage name="resourcetype" component={ErrorNotice} />
            </div>
            {isMultiTarget &&
              (values.resourcetype === Enum.Comment_RESOURCE_TYPE.area ? (
                <FormItem label={<Trans>Area</Trans>} key={'area'}>
                  <AreaSingleMenu name="area" />
                  <FieldError name="area" />
                </FormItem>
              ) : values.resourcetype === Enum.Comment_RESOURCE_TYPE.site ? (
                <FormItem label={<Trans>Site</Trans>} key={'site'}>
                  <SiteSingleMenu name="site" />
                  <FieldError name="site" />
                </FormItem>
              ) : values.resourcetype ===
                Enum.Comment_RESOURCE_TYPE.obsPoint ? (
                <FormItem
                  label={<Trans>Observation point</Trans>}
                  key={'obsPoint'}
                >
                  <ObsPointMenu name="observation_point" />
                  <FieldError name="observation_point" />
                </FormItem>
              ) : null)}
            <div className="form-group">
              <label htmlFor="add-comment-commenttype">
                <Trans>Comment type</Trans>
              </label>
              <SimpleSelectField
                name="comment_type"
                id="add-comment-commenttype"
                options={getCommentTypeOptions(values.resourcetype)}
                isSearchable={false}
              />
              <ErrorMessage name="comment_type" component={ErrorNotice} />
            </div>
            <div className="form-group">
              <label htmlFor="add-comment-datetime">
                {values.resourcetype ===
                Enum.Comment_RESOURCE_TYPE.readingGap ? (
                  <Trans>Gap date and time</Trans>
                ) : (
                  <Trans>Comment date and time</Trans>
                )}
              </label>
              <DatetimeField
                name="created_datetime"
                id="add-comment-datetime"
                timeZone={
                  // Gap comment datetimes need to be in "dam time"
                  values.resourcetype === Enum.Comment_RESOURCE_TYPE.readingGap
                    ? timeZone
                    : undefined
                }
              />
              <ErrorMessage name="created_datetime" component={ErrorNotice} />
            </div>
            <div className="form-group">
              <label htmlFor="add-comment-content">
                <Trans>Comment</Trans>
              </label>
              <Field
                name="content"
                component="textarea"
                id="add-comment-content"
              />
              <ErrorMessage name="content" component={ErrorNotice} />
            </div>
          </fieldset>
          {status}
          <ActionBlock>
            <Button id={`add-comment-form-cancel`} onClick={() => onCancel()}>
              <Trans>Cancel</Trans>
            </Button>
            <ButtonPrimary
              id="add-comment-form-submit"
              type="submit"
              disabled={isSubmitting}
              iconType="icon-save"
            >
              <Trans>Save comment</Trans>
            </ButtonPrimary>
          </ActionBlock>
        </Form>
      )}
    </Formik>
  );
};

export default NewCommentForm;
