import { Model } from 'util/backendapi/models/api.interfaces';
import { ClustererOptions } from '@react-google-maps/marker-clusterer';
import clusterIcon from 'assets/images/map-cluster-marker.png';

// TODO: Move the Google Maps API key into secrets storage, out of source code
export const GOOGLE_MAPS_API_KEY = 'AIzaSyB3Okqj6ERfrmWqicAcDMsXDcDIBI76bDo';

/**
 * Map "style" definitions, for disabling some of the labels on the map
 * Generated using the Google Maps API Styling Wizard
 *
 * @see https://mapstyle.withgoogle.com/
 */
const mapFeatures: google.maps.MapTypeStyle[] = [
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
];

/**
 * Default options for a Google Map
 */
export const GOOGLE_MAP_OPTIONS: google.maps.MapOptions = {
  // Display the distance scale
  scaleControl: true,

  // Sattelite view with labels
  mapTypeId: 'hybrid',

  // Doesn't resize correctly after returning from full screen
  fullscreenControl: false,

  // At this zoom level, NZ is scaled to about 1.6km/pixel, and the map of
  // the whole country is about 600x800px
  zoom: 6,

  styles: mapFeatures,
};

export const MAP_CLUSTERER_OPTIONS: ClustererOptions = {
  averageCenter: false,
  zoomOnClick: true,
  // 19 seems to be the limit for satellite view of rural areas in NZ.
  // So we want to say level 18 is the highest level we'll cluster at.
  //
  // In cases where clusters are likely, it's a good idea to follow up this up
  // by using the Google Maps Maximum Zoom Service to dynamically request the
  // actual max zoom level available in the displayed area.
  maxZoom: 18,
  styles: [
    {
      url: clusterIcon,
      height: 32,
      width: 32,
    },
  ],
};

export function hasLatLong<
  T extends { wgs84_coordinates: Model.LatLong | null }
>(o: T): o is T & { wgs84_coordinates: Model.LatLong } {
  return Boolean(o.wgs84_coordinates);
}
