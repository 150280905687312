import React from 'react';
import { Trans, t } from '@lingui/macro';
import { Icon } from 'components/base/icon/icon';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { SwitchGroupModal } from './switchgroupsmodal/switchgroupsmodal';
import Button from 'components/base/button/button';
import './PageNavHeader.scss';
import { DMSLink } from 'components/base/link/DMSLink';

interface Props {
  userPreferredName: string;
  userGroupsCount: number;
  handleClickLogout: (e: React.SyntheticEvent) => void;
}

/**
 * The nav icons at the top-right of the screen.
 * @param props
 */
export function PageNavHeader(props: Props) {
  const profileUrl = '/user';
  return (
    <div id="user-nav" className="user-nav">
      <ul>
        <li>
          <DMSLink to={profileUrl} className="btn profile-button">
            <span className="profile-button-text">
              <Trans>Hello, {props.userPreferredName}</Trans>
            </span>
            <Icon type="icon-user" title={t`User profile`} />
          </DMSLink>
        </li>
        {props.userGroupsCount > 1 && (
          <li>
            <ButtonShowModal
              name="switch-groups"
              iconOnly={true}
              iconType="icon-switch"
              title={t`Switch group`}
              modalContent={({ hideModal }) => (
                <SwitchGroupModal hideModal={hideModal} />
              )}
            >
              <Trans>Switch group</Trans>
            </ButtonShowModal>
          </li>
        )}
        <li>
          <Button
            id="log-out-button"
            className="log-out-button"
            iconType="icon-log-out"
            iconOnly={true}
            title={t`Log out`}
            onClick={props.handleClickLogout}
          >
            <Trans>Log out</Trans>
          </Button>
        </li>
      </ul>
    </div>
  );
}
