import React from 'react';
import { Props, SwitchGroupsModalView } from './switchgroupsmodalview';
import { FullState } from '../../../../main/reducers';
import { connect } from 'react-redux';
import { switchActiveAreaGroup } from '../../../../ducks/login';
import { withRouter, RouteComponentProps } from 'react-router-dom';

type OwnProps = {
  hideModal: () => void;
} & RouteComponentProps;
type StateProps = Pick<Props, 'activeAreaGroupId' | 'areaGroups'>;
type DispatchProps = {
  switchActiveAreaGroup: typeof switchActiveAreaGroup;
};
type MyProps = OwnProps & StateProps & DispatchProps;

class SwitchGroupModalInner extends React.Component<MyProps> {
  static mapStateToProps(state: FullState): StateProps {
    return {
      activeAreaGroupId: state.user.activeAreaGroupId as number,
      areaGroups: state.user.areaGroups,
    };
  }

  static mapDispatchToProps: DispatchProps = {
    switchActiveAreaGroup,
  };

  handleSubmit = async (areaGroupId: number) => {
    if (areaGroupId !== this.props.activeAreaGroupId) {
      // This will cause the current screen to unmount. The user will be
      // briefly redirected to the login screen while their information
      // is re-fetched, and then they'll return to the current URL.
      this.props.switchActiveAreaGroup(areaGroupId);
    } else {
      this.props.hideModal();
    }
  };

  render() {
    return (
      <SwitchGroupsModalView
        activeAreaGroupId={this.props.activeAreaGroupId}
        areaGroups={this.props.areaGroups}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

export const SwitchGroupModal = connect(
  SwitchGroupModalInner.mapStateToProps,
  SwitchGroupModalInner.mapDispatchToProps
)(withRouter(SwitchGroupModalInner));
