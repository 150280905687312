import fromPairs from 'lodash/fromPairs';
import { defaultMemoize } from 'reselect';
import { CurrentFormulaFormValues } from './currentformula';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { OpfiFormValue, OpfcvFormValue } from './currentformula.types';
import { formatIntervalForComputer } from 'util/dates';
import {
  getObsPointItemIdent,
  splitObsPointItemIdent,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { isNotNull } from 'util/validation';
import { isReferenceConstant } from 'util/backendapi/models/formulaconstant';

/**
 * Empty form values for an observation point formula input that has just
 * been added to the form but the user hasn't entered anything yet.
 *
 * @param formulaInput
 * @param defaultStartDatetime
 */
export function emptyOpfiFormValue(
  formulaInput: Model.FormulaInput,
  defaultStartDatetime: string
): OpfiFormValue {
  const base = {
    formula_input: formulaInput.id,
    isEditing: true,
    isChainMode: false,
    start_datetime: defaultStartDatetime,
    compensationFieldIds: null,
    compensation_observation_point: null,
    compensation_item_number: null,
    dependency_observation_points: [],
    tolerance: null,
  };
  switch (formulaInput.type) {
    case Enum.FormulaInput_TYPE.chainable_reading:
      return {
        ...base,
        dependency_observation_points: [],
      };
    case Enum.FormulaInput_TYPE.summation:
      return {
        ...base,
        dependency_observation_points: [],
        tolerance: formatIntervalForComputer(0, 'minutes'),
      };
    case Enum.FormulaInput_TYPE.comp_reading:
      return {
        ...base,
        tolerance: formatIntervalForComputer(0, 'minutes'),
      };
    default:
      return base;
  }
}

/**
 * Empty form values for an observatin point formula constant value that has
 * just been added to the form but the user hasn't entered anything yet.
 *
 * @param formulaConstant
 * @param defaultStartDatetime
 */
export function emptyOpfcvFormValue(
  formulaConstant: Model.FormulaConstant,
  defaultStartDatetime: string
): OpfcvFormValue {
  return {
    formula_constant: formulaConstant.id,
    isEditing: true,
    value:
      formulaConstant.default_value === null
        ? ''
        : formulaConstant.default_value,
    start_datetime: defaultStartDatetime,
  };
}

/**
 * Empty form values for a particular formula, for when the form has just
 * been switched to that formula, or the "edit formula" button has just been
 * clicked.
 *
 * @param formula
 * @param defaultStartDatetime
 */
export function emptyOpfFormValues(
  formula: Model.FormulaDecorated | undefined | null,
  defaultStartDatetime: string
): CurrentFormulaFormValues {
  if (!formula) {
    // No formula selected.
    return {
      isEditingFormula: true,
      formula: 0,
      start_datetime: defaultStartDatetime,
      observation_point_formula_inputs: {},
      observation_point_formula_constant_values: {},
    };
  }

  return {
    isEditingFormula: true,
    formula: formula.id,
    start_datetime: defaultStartDatetime as string,
    observation_point_formula_inputs: fromPairs(
      formula.formula_inputs
        // Raw reading formula inputs are not editable, so they don't need
        // a form value.
        .filter((fi) => fi.type !== Enum.FormulaInput_TYPE.raw_reading)
        .map((fi) => [
          fi.var_name,
          emptyOpfiFormValue(fi, defaultStartDatetime),
        ])
    ),
    observation_point_formula_constant_values: fromPairs(
      formula.formula_constants
        // Reference constants are not editable in this form, so they don't
        // need a form value.
        .filter((fc) => !isReferenceConstant(fc))
        .map((fc) => [
          fc.var_name,
          emptyOpfcvFormValue(fc, defaultStartDatetime),
        ])
    ),
  };
}

/**
 * Convert an observation point formula input object into the data types used
 * in our forms.
 *
 * @param fi
 * @param defaultStartDatetime
 * @param opfi
 */
export function opfiToFormValues(
  fi: Model.FormulaInput,
  defaultStartDatetime: string,
  opfi: Model.ObservationPointFormulaInput | undefined | null
): OpfiFormValue {
  if (!opfi) {
    return emptyOpfiFormValue(fi, defaultStartDatetime);
  } else {
    const base = {
      ...opfi,
      isEditing: false,
      isChainMode: false,
      compensationFieldIds: null,
      compensation_observation_point: null,
      compensation_item_number: null,
      dependency_observation_points: [],
      hasSummationTolerance: null,
      tolerance: null,
    };

    switch (fi.type) {
      case Enum.FormulaInput_TYPE.chainable_reading:
        return {
          ...base,
          isChainMode: opfi.dependency_observation_points.length > 0,
          dependency_observation_points: opfi.dependency_observation_points.map(
            (i) => getObsPointItemIdent(i.observation_point, i.item_number)
          ),
        };
      case Enum.FormulaInput_TYPE.comp_reading:
        return {
          ...base,
          tolerance: opfi.tolerance === null ? '' : opfi.tolerance,
          compensationFieldIds: getObsPointItemIdent(
            opfi.compensation_observation_point!,
            opfi.compensation_item_number!
          ),
        };
      case Enum.FormulaInput_TYPE.summation:
        return {
          ...base,
          tolerance: opfi.tolerance === null ? '' : opfi.tolerance,
          hasSummationTolerance: opfi.tolerance ? true : false,
          dependency_observation_points: opfi.dependency_observation_points.map(
            (i) => getObsPointItemIdent(i.observation_point, i.item_number)
          ),
        };
      default:
        return base;
    }
  }
}

export function formValuesToOpfiPartial(
  opfi: OpfiFormValue
): Omit<
  Model.ObservationPointFormulaInput,
  'id' | 'observation_point_formula'
> {
  const {
    isEditing,
    isChainMode,
    compensationFieldIds,
    start_datetime,
    dependency_observation_points,
    hasSummationTolerance,
    tolerance,
    ...others
  } = opfi;
  const compensationItem = splitObsPointItemIdent(compensationFieldIds);
  const compensation_observation_point =
    compensationItem?.observation_point ?? null;
  const compensation_item_number = compensationItem?.item_number ?? null;

  return {
    ...others,
    // reset tolerance to null if hasSummationTolerance is explicitly set to false (not just undefined)
    tolerance: hasSummationTolerance === false ? null : tolerance,
    compensation_observation_point,
    compensation_item_number,
    start_datetime: start_datetime as string,
    dependency_observation_points: dependency_observation_points
      .map(splitObsPointItemIdent)
      .filter(isNotNull),
  };
}

/**
 * Convert an observation point formula constant value object into the data types
 * used in our forms.
 *
 * @param fc
 * @param defaultStartDatetime
 * @param opfcv
 */
export function opfcvToFormValues(
  fc: Model.FormulaConstant,
  defaultStartDatetime: string,
  opfcv: Model.ObservationPointFormulaConstantValue | undefined | null
): OpfcvFormValue {
  if (!opfcv) {
    return emptyOpfcvFormValue(fc, defaultStartDatetime);
  } else {
    return {
      ...opfcv,
      isEditing: false,
    };
  }
}

export function formValuesToOpfcvPartial(
  opfcv: OpfcvFormValue
): Omit<
  Model.ObservationPointFormulaConstantValue,
  'id' | 'observation_point_formula'
> {
  const { isEditing, start_datetime, ...others } = opfcv;
  return {
    start_datetime: start_datetime as string,
    ...others,
  };
}

/**
 * Generate the "initialValues" for the "Current formula" form, based on
 * an observation point formula object and its corresponding formula.
 *
 * The formula is used to ensure that there are form values for every
 * editable formula input and constant.
 */
export const opfToFormValues = defaultMemoize(function (
  opf: Model.ObservationPointFormulaSnapshot | null,
  formulas: Model.FormulaDecorated[],
  defaultStartDatetime: string
): CurrentFormulaFormValues {
  const formula =
    opf && formulas.find((f) => f.id === opf.observation_point_formula.formula);

  if (!opf || !formula) {
    // If there is no selected formula, start with an empty form in edit mode
    return emptyOpfFormValues(formula, defaultStartDatetime);
  }

  return {
    formula: formula.id,
    isEditingFormula: false,
    start_datetime: opf.observation_point_formula.start_datetime,
    // Make form values for each of the formula's inputs
    observation_point_formula_inputs: fromPairs(
      formula.formula_inputs
        // Raw reading formula inputs are not editable, so they don't need
        // a form value.
        .filter((fi) => fi.type !== Enum.FormulaInput_TYPE.raw_reading)
        .map((fi) => [
          fi.var_name,
          opfiToFormValues(
            fi,
            defaultStartDatetime,
            opf.observation_point_formula_inputs[fi.var_name]
          ),
        ])
    ),
    // Make form values for each of the formula's editable constants
    observation_point_formula_constant_values: fromPairs(
      formula.formula_constants
        // Reference constants are not editable in this form, so they don't
        // need a form value.
        .filter((fc) => !isReferenceConstant(fc))
        .map((fc) => [
          fc.var_name,
          opfcvToFormValues(
            fc,
            defaultStartDatetime,
            opf.observation_point_formula_constant_values[fc.var_name]
          ),
        ])
    ),
  };
});
