import React from 'react';
import orderBy from 'lodash/orderBy';
import { Trans, t } from '@lingui/macro';
import PageStandard from '../../components/modules/pagestandard/pagestandard';
import { TimeSeriesPlot } from 'components/plots/timeseriesplot';
import {
  PlotReadingsSeries,
  YAxisSide,
} from 'components/plots/timeseriesplot.types';
import { formatDatetimeForDisplay } from '../../util/dates';
import { selectAlarmsForPlotting } from '../../ducks/plot/scatter-time-series';
import Loading from '../../components/base/loading/loading';
import { AlertDanger, AlertInfo } from '../../components/base/alert/alert';
import { Model, Enum } from '../../util/backendapi/models/api.interfaces';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import AlarmParametersSettingsView from 'components/modules/alarmparameterssettingsmodal/alarmparameterssettingsview';
import { AlarmParameterForm } from './edit/alarm-parameter-form';
import { isCurrentAlarm, isFutureAlarm } from 'util/alarmparameters';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { BackButton } from 'components/base/back-button/BackButton';
import { DMSLink } from 'components/base/link/DMSLink';
import { Icon } from 'components/base/icon/icon';
import {
  ScatterPlot,
  ScatterPlotReadingSeries,
} from 'components/plots/ScatterPlot';
import { RelativeAlarmScatterPlotData } from './relative-alarms-plot';
import './alarmparameters.scss';

export interface StateProps {
  obsPointCode: string;
  readings: PlotReadingsSeries<YAxisSide>[];
  alarms: null | Model.ReportsAlarmParameter[];
  instrumentTypeItem: null | Model.InstrumentTypeItem;
  isLoadingQuickPlot: boolean;
  isLoadingScatterPlots: boolean;
  isLoadingAlarms: boolean;
  errorMessage: string;
  paddedMinDatetime: string | null;
  paddedMaxDatetime: string | null;
  scatterPlotsData: RelativeAlarmScatterPlotData[];
}

export interface DispatchProps {
  refreshAlarmsList: (
    observationPointId: number,
    itemNumber: null | number
  ) => void;
  enableAlarm: (id: number) => Promise<any>;
  disableAlarm: (id: number) => Promise<any>;
}

function isDisabled(alarm: Model.ReportsAlarmParameter) {
  return alarm.status === Enum.AlarmParameter_STATUS.disabled;
}

const AlarmParametersView = React.memo(
  ({
    obsPointCode,
    readings,
    alarms,
    instrumentTypeItem,
    isLoadingQuickPlot,
    isLoadingScatterPlots,
    isLoadingAlarms,
    errorMessage,
    paddedMinDatetime,
    paddedMaxDatetime,
    refreshAlarmsList,
    enableAlarm,
    disableAlarm,
    scatterPlotsData,
  }: StateProps & DispatchProps) => {
    const commentPanelRef = React.useRef<HTMLDivElement>(null);

    const alarmsForPlotting = selectAlarmsForPlotting(alarms || []);
    const currentAlarms = (alarms || []).filter(isCurrentAlarm);
    const futureAlarms = (alarms || []).filter(isFutureAlarm);

    const currentAndFutureAlarms = orderBy(
      [...currentAlarms, ...futureAlarms],
      [(alarm) => +alarm.threshold],
      'desc'
    );

    const absoluteAlarmParameters = currentAndFutureAlarms.filter(
      ({ comparison_type }) =>
        comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.absolute
    );
    const relativeAlarmParameters = currentAndFutureAlarms.filter(
      ({ comparison_type }) =>
        comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.relative
    );
    const velocityAlarmParameters = currentAndFutureAlarms.filter(
      ({ comparison_type }) =>
        comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.velocity
    );

    const observationPoint = readings[0] ? readings[0].observationPoint : null;

    const observationPointId = observationPoint ? observationPoint.id : '';
    const observationPointCode = observationPoint ? observationPoint.code : '';
    const timeZone = observationPoint ? observationPoint.time_zone.name : '';

    const pageProps = {
      name: 'alarm-parameters',
      header: <Trans>Alarm Parameters</Trans>,
      subHeader: observationPointCode ? (
        <span>
          {observationPointCode} -{' '}
          {instrumentTypeItem && instrumentTypeItem.description}
        </span>
      ) : null,
    };

    return (
      <PageStandard {...pageProps}>
        <div className="page-content-header-with-back-button-wrapper">
          <BackButton defaultBackUrl="/alarm-parameters" />
          <div className="page-content-header">
            <ActionBlock className="text-right">
              <ButtonShowModal
                name="settings"
                autoShow={!obsPointCode}
                key={String(!obsPointCode)}
                iconType="icon-cog"
                title={t`Settings`}
                shortcut="GOTO_SETTINGS"
                modalContent={({ hideModal }) => (
                  <AlarmParametersSettingsView hideModal={hideModal} />
                )}
              >
                <Trans>Settings</Trans>
              </ButtonShowModal>
            </ActionBlock>
          </div>
        </div>
        {!obsPointCode ? (
          <AlertInfo testid="plot-no-selection">
            <Trans>No observation point selected.</Trans>
          </AlertInfo>
        ) : (
          <>
            {errorMessage && <AlertDanger>{errorMessage}</AlertDanger>}
            {!isLoadingQuickPlot && !errorMessage && (
              <TimeSeriesPlot
                paddedMinDatetime={paddedMinDatetime}
                paddedMaxDatetime={paddedMaxDatetime}
                yAxes={[]}
                timeZone={timeZone}
                readingsSeries={readings}
                alarmParamSeries={alarmsForPlotting}
              />
            )}
            {isLoadingScatterPlots ? (
              <Loading />
            ) : (
              !errorMessage && (
                <>
                  <br />
                  <div className="page-multi-plot page-multi-plot-scatter">
                    {scatterPlotsData.map((scatterPlotData, i) => (
                      <div
                        className="plot-item plot-item-scatter"
                        key={`scatter-plot-${i}`}
                      >
                        <ScatterPlot
                          axes={[]}
                          interpolate={true}
                          showMarkConnections={true}
                          showPlotMarks={true}
                          xObservationPointCode={
                            scatterPlotData.settings.observationPoints[1].code
                          }
                          yObservationPointCode={
                            scatterPlotData.settings.observationPoints[0].code
                          }
                          highlightPeriods={[]}
                          y={
                            scatterPlotData
                              .readingsSeries[0] as ScatterPlotReadingSeries
                          }
                          x={
                            scatterPlotData
                              .readingsSeries[1] as ScatterPlotReadingSeries
                          }
                          relativeAlarmParameters={
                            scatterPlotData.settings.relativeAlarmParameters!
                          }
                          timeZone={timeZone}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )
            )}
            {(isLoadingQuickPlot || isLoadingAlarms) && <Loading />}
            {!isLoadingQuickPlot &&
              !isLoadingAlarms &&
              !errorMessage &&
              instrumentTypeItem &&
              observationPointId && (
                <div className="panel-context-wrapper">
                  <div>
                    <h3 className="alarm-parameters-tables-heading">
                      <Trans>Current and future alarm parameters</Trans>
                    </h3>
                    <ActionBlock className="text-right">
                      <DMSLink
                        to={`/alarm-parameter-report/?observation_point=${observationPointId}&ordering=-threshold`}
                        className="btn"
                      >
                        <span>
                          <Trans>View full alarm parameter report</Trans>
                        </span>
                        <Icon type="icon-view" />
                      </DMSLink>
                      <HasPermission check="can_create_alarm_parameters">
                        <ButtonShowModal
                          name="create-alarm-parameter"
                          iconType="icon-plus"
                          modalContent={({ hideModal }) => {
                            return (
                              <AlarmParameterForm
                                alarmParamId={null}
                                observationPointId={observationPointId}
                                itemNumber={instrumentTypeItem.item_number}
                                timeZone={timeZone}
                                onAfterSave={() => {
                                  refreshAlarmsList(
                                    observationPointId,
                                    instrumentTypeItem
                                      ? instrumentTypeItem.item_number
                                      : null
                                  );
                                }}
                                hideModal={hideModal}
                              />
                            );
                          }}
                        >
                          <Trans>Create alarm parameter</Trans>
                        </ButtonShowModal>
                      </HasPermission>
                    </ActionBlock>
                    {currentAndFutureAlarms.length === 0 ? (
                      <AlertInfo>
                        <Trans>
                          There are no current or future alarm parameters for
                          this observation point.
                        </Trans>
                      </AlertInfo>
                    ) : null}
                    {absoluteAlarmParameters.length > 0 ? (
                      <AlarmParameterTable
                        showActions
                        tableCaption={
                          <Trans>Absolute value alarm parameters</Trans>
                        }
                        timeZone={timeZone}
                        comparisonType={
                          Enum.AlarmParameter_COMPARISON_TYPE.absolute
                        }
                        observationPointId={observationPointId}
                        alarmParameters={absoluteAlarmParameters}
                        itemNumber={instrumentTypeItem.item_number}
                        refreshAlarmsList={refreshAlarmsList}
                        commentPanelRef={commentPanelRef}
                        disableAlarm={disableAlarm}
                        enableAlarm={enableAlarm}
                      />
                    ) : null}
                    {relativeAlarmParameters.length > 0 ? (
                      <AlarmParameterTable
                        showActions
                        tableCaption={
                          <Trans>Relative value alarm parameters</Trans>
                        }
                        timeZone={timeZone}
                        comparisonType={
                          Enum.AlarmParameter_COMPARISON_TYPE.relative
                        }
                        observationPointId={observationPointId}
                        alarmParameters={relativeAlarmParameters}
                        itemNumber={instrumentTypeItem.item_number}
                        refreshAlarmsList={refreshAlarmsList}
                        commentPanelRef={commentPanelRef}
                        disableAlarm={disableAlarm}
                        enableAlarm={enableAlarm}
                      />
                    ) : null}
                    {velocityAlarmParameters.length > 0 ? (
                      <AlarmParameterTable
                        showActions
                        tableCaption={<Trans>Velocity alarm parameters</Trans>}
                        timeZone={timeZone}
                        comparisonType={
                          Enum.AlarmParameter_COMPARISON_TYPE.velocity
                        }
                        observationPointId={observationPointId}
                        alarmParameters={velocityAlarmParameters}
                        itemNumber={instrumentTypeItem.item_number}
                        refreshAlarmsList={refreshAlarmsList}
                        commentPanelRef={commentPanelRef}
                        disableAlarm={disableAlarm}
                        enableAlarm={enableAlarm}
                      />
                    ) : null}
                  </div>
                  <div className="panel-wrapper" ref={commentPanelRef}></div>
                </div>
              )}
          </>
        )}
      </PageStandard>
    );
  }
);

interface AlarmParameterTableProps {
  alarmParameters: Model.ReportsAlarmParameter[];
  comparisonType: Enum.AlarmParameter_COMPARISON_TYPE;
  showActions: boolean;
  tableCaption: React.ReactNode;
  timeZone: string;
  observationPointId: number;
  refreshAlarmsList: DispatchProps['refreshAlarmsList'];
  itemNumber: number;
  commentPanelRef?: React.RefObject<HTMLElement>;
  enableAlarm?: (id: number) => Promise<any>;
  disableAlarm?: (id: number) => Promise<any>;
}

const AlarmParameterTable = React.memo(
  ({
    comparisonType,
    alarmParameters,
    showActions,
    tableCaption,
    timeZone,
    observationPointId,
    itemNumber,
    refreshAlarmsList,
    commentPanelRef,
    enableAlarm,
    disableAlarm,
  }: AlarmParameterTableProps) => {
    return (
      <div className="table-responsive alarm-parameter-table">
        <table>
          <caption>{tableCaption}</caption>
          <thead>
            <tr>
              <th scope="col">
                <Trans>Alarm level</Trans>
              </th>
              <th scope="col">
                <Trans>Min/max</Trans>
              </th>
              {comparisonType ===
                Enum.AlarmParameter_COMPARISON_TYPE.velocity && (
                <th scope="col">
                  <Trans>Time interval</Trans>
                </th>
              )}
              <th scope="col">
                <Trans>Parameter</Trans>
              </th>
              {comparisonType ===
                Enum.AlarmParameter_COMPARISON_TYPE.velocity && (
                <th scope="col">
                  <Trans>Direction</Trans>
                </th>
              )}
              <th scope="col">
                <Trans>Start</Trans>
              </th>
              <th scope="col">
                <Trans>End</Trans>
              </th>
              <th scope="col">
                <Trans>Status</Trans>
              </th>
              {comparisonType ===
                Enum.AlarmParameter_COMPARISON_TYPE.relative && (
                <>
                  <th scope="col">
                    <Trans>Comparison obs pt</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Comparison obs pt item num</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Comparison factor</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Comparison time difference</Trans>
                  </th>
                </>
              )}
              {showActions && (
                <th scope="col" className="action-icons">
                  <Trans>Actions</Trans>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {alarmParameters.map((alarmParameter) => (
              <tr
                key={String(alarmParameter.id)}
                className={isDisabled(alarmParameter) ? 'table-row-mute' : ''}
              >
                <td>
                  <TransEnum
                    enum="AlarmParameter_LEVEL"
                    value={alarmParameter.level}
                  />
                </td>
                <td>
                  <TransEnum
                    enum="AlarmParameter_TYPE"
                    value={alarmParameter.type}
                  />
                </td>
                {comparisonType ===
                  Enum.AlarmParameter_COMPARISON_TYPE.velocity && (
                  <td>
                    <IntervalDisplay value={alarmParameter.time_interval} />
                  </td>
                )}
                <td>{Number(alarmParameter.threshold)}</td>
                {comparisonType ===
                  Enum.AlarmParameter_COMPARISON_TYPE.velocity && (
                  <td>
                    <TransEnum
                      enum="AlarmParameter_DIRECTION_OF_CHANGE"
                      value={alarmParameter.direction_of_change!}
                    />
                  </td>
                )}
                <td>
                  {formatDatetimeForDisplay(
                    alarmParameter.start_datetime,
                    timeZone
                  )}
                </td>
                <td>
                  {alarmParameter.end_datetime
                    ? formatDatetimeForDisplay(
                        alarmParameter.end_datetime,
                        timeZone
                      )
                    : null}
                </td>
                <td>
                  <TransEnum
                    enum="AlarmParameter_STATUS"
                    value={alarmParameter.status}
                  />
                </td>
                {comparisonType ===
                  Enum.AlarmParameter_COMPARISON_TYPE.relative && (
                  <>
                    <td>{alarmParameter.relative_observation_point__code}</td>
                    <td>{alarmParameter.relative_item_number}</td>
                    <td>{alarmParameter.relative_comparison_factor}</td>
                    <td>
                      <IntervalDisplay
                        value={
                          alarmParameter.relative_comparison_datetime_tolerance
                        }
                      />
                    </td>
                  </>
                )}
                {showActions && (
                  <td className="action-icons">
                    <HasPermission check="can_create_alarm_parameters">
                      <ButtonShowModal
                        className="btn-link-panel"
                        name="edit-alarm-parameter"
                        modalContent={({ hideModal }) => {
                          return (
                            <AlarmParameterForm
                              alarmParamId={alarmParameter.id}
                              observationPointId={observationPointId}
                              itemNumber={itemNumber}
                              timeZone={timeZone}
                              onAfterSave={() => {
                                refreshAlarmsList(
                                  observationPointId,
                                  itemNumber ? itemNumber : null
                                );
                              }}
                              hideModal={hideModal}
                            />
                          );
                        }}
                        iconType="icon-edit"
                        iconOnly={true}
                        title={t`Edit`}
                      >
                        <Trans>Edit</Trans>
                      </ButtonShowModal>
                    </HasPermission>

                    <HasPermission check="can_disable_and_enable_alarm_parameters">
                      <ButtonShowConfirmation
                        name={
                          isDisabled(alarmParameter)
                            ? 'enable-alarm-parameters'
                            : 'disable-alarm-parameters'
                        }
                        title={
                          isDisabled(alarmParameter)
                            ? t`Enable alarm parameter`
                            : t`Temporarily disable alarm parameter`
                        }
                        iconType="icon-mute"
                        className="btn-link-panel"
                        destructive={false}
                        content={
                          isDisabled(alarmParameter) ? (
                            <Trans>
                              Are you sure you want to enable the{' '}
                              <strong>
                                <TransEnum
                                  enum="AlarmParameter_COMPARISON_TYPE"
                                  value={alarmParameter.comparison_type}
                                />{' '}
                                <TransEnum
                                  enum="AlarmParameter_LEVEL"
                                  value={alarmParameter.level}
                                />{' '}
                                <TransEnum
                                  enum="AlarmParameter_TYPE"
                                  value={alarmParameter.type}
                                />{' '}
                              </strong>
                              alarm parameter? Alarm checking will resume and
                              alarm reports will be created if readings exceed
                              this alarm parameter.
                            </Trans>
                          ) : (
                            <Trans>
                              Are you sure you want to temporarily disable the{' '}
                              <strong>
                                <TransEnum
                                  enum="AlarmParameter_COMPARISON_TYPE"
                                  value={alarmParameter.comparison_type}
                                />{' '}
                                <TransEnum
                                  enum="AlarmParameter_LEVEL"
                                  value={alarmParameter.level}
                                />{' '}
                                <TransEnum
                                  enum="AlarmParameter_TYPE"
                                  value={alarmParameter.type}
                                />{' '}
                              </strong>
                              alarm parameter? No alarm reports will be created
                              if readings exceed this alarm parameter while it
                              is disabled.
                            </Trans>
                          )
                        }
                        okBtnText={
                          isDisabled(alarmParameter) ? (
                            <Trans>Yes, enable parameter</Trans>
                          ) : (
                            <Trans>Yes, disable parameter</Trans>
                          )
                        }
                        onConfirm={async function () {
                          if (isDisabled(alarmParameter)) {
                            if (enableAlarm) {
                              return await enableAlarm(alarmParameter.id);
                            }
                          } else {
                            if (disableAlarm) {
                              return await disableAlarm(alarmParameter.id);
                            }
                          }
                        }}
                      />
                    </HasPermission>

                    <HasPermission check="can_create_alarm_parameter_comments">
                      <>
                        <ButtonShowCommentsPanel
                          type={Enum.Comment_RESOURCE_TYPE.alarmParameter}
                          description={
                            <h4 className="panel-subheader">
                              <span>
                                <TransEnum
                                  enum="AlarmParameter_COMPARISON_TYPE"
                                  value={alarmParameter.comparison_type}
                                />
                              </span>{' '}
                              <span>
                                <TransEnum
                                  enum="AlarmParameter_LEVEL"
                                  value={alarmParameter.level}
                                />
                              </span>{' '}
                              <span>
                                <TransEnum
                                  enum="AlarmParameter_TYPE"
                                  value={alarmParameter.type}
                                />
                              </span>
                            </h4>
                          }
                          metadata={{
                            alarm_parameter: alarmParameter.id,
                            area: alarmParameter.observation_point__site__area,
                            site: alarmParameter.observation_point__site,
                            observation_point:
                              alarmParameter.observation_point__id,
                          }}
                          buttonProps={{
                            iconOnly: true,
                            className: 'btn-link-panel',
                            badge: alarmParameter.comment_count,
                          }}
                          panelRef={commentPanelRef}
                          commentReportParams={`observation_point=${observationPointId}&resourcetype=${Enum.Comment_RESOURCE_TYPE.alarmParameter}`}
                        />
                      </>
                    </HasPermission>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
);

export default AlarmParametersView;
