import { Enum } from 'util/backendapi/models/api.interfaces';

export const ActionTypes = {
  REFRESH_COMMENT_PANEL: 'dms/comments/panel/REFRESH_COMMENT_PANEL',
  MOUNT_COMMENT_PANEL: 'dms/comments/panel/MOUNT_COMMENT_PANEL',
} as const;

export const ActionCreators = {
  refreshCommentPanel: (commentType: Enum.Comment_RESOURCE_TYPE) => ({
    type: ActionTypes.REFRESH_COMMENT_PANEL,
    commentType,
  }),
  mountCommentPanel: (commentType: Enum.Comment_RESOURCE_TYPE) => ({
    type: ActionTypes.MOUNT_COMMENT_PANEL,
    commentType,
  }),
};

export type CommentsPanelAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export interface CommentsPanelState {
  refreshFlag: null | number;
  commentType: Enum.Comment_RESOURCE_TYPE | null;
}

export function commentsPanelInitialState(): CommentsPanelState {
  return {
    refreshFlag: null,
    commentType: null,
  };
}

export const commentsPanelReducer = function (
  state = commentsPanelInitialState(),
  action: CommentsPanelAction
): CommentsPanelState {
  switch (action.type) {
    case ActionTypes.REFRESH_COMMENT_PANEL:
      if (action.commentType === state.commentType) {
        return {
          ...state,
          refreshFlag: Date.now(),
          commentType: action.commentType,
        };
      }
      return state;

    case ActionTypes.MOUNT_COMMENT_PANEL:
      return {
        ...state,
        commentType: action.commentType,
      };

    default: {
      return state;
    }
  }
};
