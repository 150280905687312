import React from 'react';
import { Trans } from '@lingui/macro';
import { FormikErrors, FormikProps, FormikHelpers } from 'formik';
import lodash_set from 'lodash/set';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import orderBy from 'lodash/orderBy';

import EditableCard from 'components/base/form/editablecard/editablecard';
import { SectionProps } from '../../maintobspoint.types';
import { getCurrentDatetime, convertDateToDatetime } from 'util/dates';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { ObsPointFormulaCard } from './formbody';
import { FormulaHistory, FormulaHistoryProps } from './formulahistory';
import { OpfcvFormValue, OpfiFormValue } from './currentformula.types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { validateNumber } from 'util/validation';
import {
  opfToFormValues,
  formValuesToOpfiPartial,
  formValuesToOpfcvPartial,
  emptyOpfFormValues,
} from './currentformulaselectors';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeSelectedFormula,
  modifyObsPointFormulaDetails,
  expandFormulaHistory,
  collapseFormulaHistory,
  fetchFormulaHistory,
  ObsPointDetailFormulaState,
} from 'ducks/obsPoint/detail/formula';
import { FullState } from 'main/reducers';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { selectAllInOrderedArray, EntityTypes } from 'ducks/entities';
import { useIsMounted } from 'util/hooks';
import { Card, CardSection } from 'components/base/card/card';

import './currentformula.scss';
import { getExpectedFields } from 'util/backendapi/error';
import { fetchObservationPoint } from 'ducks/obsPoint/detail/main';
import { selectHasPermission } from 'util/user';

export type CurrentFormulaProps = Omit<SectionProps, 'onSubmit'> & {
  formulaOptions: SimpleSelectOption<number>[];
};

export interface CurrentFormulaFormValues {
  formula: number;
  isEditingFormula: boolean;
  start_datetime: string | false;
  observation_point_formula_inputs: { [var_name in string]: OpfiFormValue };
  observation_point_formula_constant_values: {
    [var_name in string]: OpfcvFormValue;
  };
}
type FormValues = CurrentFormulaFormValues;

export function ObsPointCurrentFormulaSection(props: CurrentFormulaProps) {
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const state = useSelector(
    (state: FullState) => state.obsPoint.detail.formula
  );

  React.useEffect(() => {
    if (
      props.observationPoint &&
      props.observationPoint !== state.historyForObservationPoint
    ) {
      dispatch(fetchFormulaHistory(props.observationPoint));
    }
  }, [dispatch, props.observationPoint, state.historyForObservationPoint]);

  const formulas = useSelector((state: FullState) =>
    selectAllInOrderedArray(state, EntityTypes.FORMULA)
  );

  // Display the latest observation point formula in the form (note: could be
  // in the future!)
  const opf = React.useMemo(() => {
    return orderBy(
      state.formulaSnapshots,
      (fs) => fs.start_datetime,
      'desc'
    )[0];
  }, [state.formulaSnapshots]);

  const handleSubmit = React.useCallback(
    async (values: CurrentFormulaFormValues) => {
      if (!props.observationPoint) {
        return;
      }
      if (values.isEditingFormula || !opf) {
        await dispatch(
          changeSelectedFormula(
            {
              formula: values.formula!,
              start_datetime: values.start_datetime as string,
              observation_point: props.observationPoint.id,
            },
            mapValues(
              values.observation_point_formula_inputs,
              formValuesToOpfiPartial
            ),
            mapValues(
              values.observation_point_formula_constant_values,
              formValuesToOpfcvPartial
            )
          )
        );
      } else {
        const editedOpfis = pickBy(
          values.observation_point_formula_inputs,
          (opfi) => opfi.isEditing
        ) as CurrentFormulaFormValues['observation_point_formula_inputs'];
        const editedOpfcvs = pickBy(
          values.observation_point_formula_constant_values,
          (opfcv) => opfcv.isEditing
        ) as CurrentFormulaFormValues['observation_point_formula_constant_values'];

        await dispatch(
          modifyObsPointFormulaDetails(
            mapValues(editedOpfis, (opfi) => ({
              ...formValuesToOpfiPartial(opfi),
              // Add the opfi ID
              observation_point_formula: opf.observation_point_formula.id,
            })),
            mapValues(editedOpfcvs, (opfcv) => ({
              ...formValuesToOpfcvPartial(opfcv),
              // Add the opfi ID
              observation_point_formula: opf.observation_point_formula.id,
            })),
            opf
          )
        );
      }
      if (isMounted() && props.observationPoint) {
        dispatch(fetchFormulaHistory(props.observationPoint));

        // in this section, we're not perform an update to the observatio point itself, but
        // the `setup_complete` flag can change after the formula is setup.
        //
        // If that is the case user will be redirected out of the `Observation Point Setup` screen.
        //
        // We need to refresh the obs point here, so the redirection can happen.
        // The redirection is handled in `maintobspointscreen.tsx`
        dispatch(fetchObservationPoint(props.observationPoint.code));
      }
    },
    [dispatch, isMounted, opf, props.observationPoint]
  );

  const handleToggleHistory = React.useCallback(
    (isExpanded) =>
      isExpanded
        ? dispatch(expandFormulaHistory())
        : dispatch(collapseFormulaHistory()),
    [dispatch]
  );

  const canViewFormulaAssignmentReport = useSelector((state: FullState) =>
    selectHasPermission(
      state,
      Enum.User_PERMISSION.can_view_observation_point_formula_report
    )
  );
  const canViewFormulaConstantReport = useSelector((state: FullState) =>
    selectHasPermission(
      state,
      Enum.User_PERMISSION.can_view_observation_point_formula_constant_report
    )
  );

  const canViewSiteReport = useSelector((state: FullState) =>
    selectHasPermission(state, Enum.User_PERMISSION.can_view_site_report)
  );

  return (
    <InnerObsPointFormulaSection
      {...props}
      formulas={formulas}
      formulaHistory={state}
      observationPointFormula={opf}
      onSubmit={handleSubmit}
      onToggleHistory={handleToggleHistory}
      canViewFormulaAssignmentReport={canViewFormulaAssignmentReport}
      canViewFormulaConstantReport={canViewFormulaConstantReport}
      canViewSiteReport={canViewSiteReport}
    />
  );
}

/**
 * A factory function to produce our form validation callback. (Extracted
 * out into a factory like this to accommodate legacy unit tests)
 * @param formulas
 */
export function validateCurrentFormulaSection(
  values: CurrentFormulaFormValues,
  formulas: Model.FormulaDecorated[]
): FormikErrors<CurrentFormulaFormValues> {
  let errors: FormikErrors<FormValues> = {};

  const formula = formulas.find((f) => f.id === values.formula);
  if (!formula) {
    errors.formula = (<Trans>Formula is required</Trans>) as any;
    return errors;
  }

  if (values.isEditingFormula && values.start_datetime === '') {
    errors.start_datetime = (<Trans>Start date is required</Trans>) as any;
  }

  formula.formula_inputs.forEach((fi) => {
    const opfi = values.observation_point_formula_inputs[fi.var_name];

    if (!opfi || !opfi.isEditing) {
      return;
    }
    const prefix = `observation_point_formula_inputs.${fi.var_name}`;
    if (opfi.start_datetime === '') {
      lodash_set(
        errors,
        `${prefix}.start_datetime`,
        <Trans>Start date is required</Trans>
      );
    }
    switch (fi.type) {
      case Enum.FormulaInput_TYPE.comp_reading:
        if (!opfi.compensationFieldIds) {
          lodash_set(
            errors,
            `${prefix}.compensationFieldIds`,
            <Trans>Compensation reading is required</Trans>
          );
        }
        break;
      case Enum.FormulaInput_TYPE.chainable_reading:
        if (
          opfi.isChainMode &&
          opfi.dependency_observation_points.length === 0
        ) {
          lodash_set(
            errors,
            `${prefix}.dependency_observation_points`,
            <Trans>
              When reading source is "Adjusted reading from another observation
              point", dependency observation point is required
            </Trans>
          );
        }
        break;
      case Enum.FormulaInput_TYPE.summation:
        if (opfi.dependency_observation_points.length === 0) {
          lodash_set(
            errors,
            `${prefix}.dependency_observation_points`,
            <Trans>This field is required</Trans>
          );
        }
        if (!opfi.tolerance) {
          lodash_set(
            errors,
            `${prefix}.tolerance`,
            <Trans>Reading time difference tolerance is required</Trans>
          );
        }
    }
  });

  formula.formula_constants.forEach((fc) => {
    const opfcv = values.observation_point_formula_constant_values[fc.var_name];
    if (!opfcv || !opfcv.isEditing) {
      return;
    }

    const prefix = `observation_point_formula_constant_values.${fc.var_name}`;

    if (opfcv.start_datetime === '') {
      lodash_set(
        errors,
        `${prefix}.start_datetime`,
        <Trans>Start date is required</Trans>
      );
    }

    if (opfcv.value === '') {
      lodash_set(
        errors,
        `${prefix}.value`,
        <Trans>Formula constant value is required</Trans>
      );
    } else if (!validateNumber(opfcv.value)) {
      lodash_set(
        errors,
        `${prefix}.value`,
        <Trans>Formula constant value must be a valid number</Trans>
      );
    }
  });
  return errors;
}

interface InnerProps extends CurrentFormulaProps {
  formulas: Model.FormulaDecorated[];
  formulaHistory: ObsPointDetailFormulaState;
  observationPointFormula: Model.ObservationPointFormulaSnapshot | null;
  onSubmit: (
    values: FormValues,
    formik: FormikHelpers<FormValues>
  ) => Promise<void>;
  onToggleHistory: FormulaHistoryProps['onToggle'];
  canViewFormulaAssignmentReport: boolean;
  canViewFormulaConstantReport: boolean;
  canViewSiteReport: boolean;
}

function InnerObsPointFormulaSection(props: InnerProps) {
  const opf = props.observationPointFormula;

  // Save the time the form started editing, so that we can use this to
  // auto-fill a default value of "now" into the startdate fields if the
  // user chooses to unlock them.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultStartDatetime = React.useMemo(() => {
    // New observation point; default to the install date
    if (
      !props.formulaHistory.formulaSnapshots?.length &&
      props.observationPoint
    ) {
      const installDate = props.observationPoint.install_date;
      const timeZone = props.observationPoint.time_zone.name;
      if (installDate && timeZone) {
        return convertDateToDatetime(installDate, timeZone);
      }
    }
    return getCurrentDatetime();
  }, [props.formulaHistory.formulaSnapshots, props.observationPoint]);

  const validateForm = React.useCallback(
    (values: CurrentFormulaFormValues) =>
      validateCurrentFormulaSection(values, props.formulas),
    [props.formulas]
  );

  /**
   * Reset the form when the user first clicks the "change" button for the
   * formula menu.
   *
   * @param {object} formik
   * @return {void}
   */
  const handleClickFormulaChangeButton = React.useCallback(
    (formik: FormikProps<CurrentFormulaFormValues>) => {
      if (formik.values.isEditingFormula) {
        // They've already clicked the "change" button for the formula. Do nothing.
        return;
      }

      const formula = props.formulas.find(
        (f) => f.id === formik.values.formula
      );
      const newValues = emptyOpfFormValues(formula, defaultStartDatetime);
      formik.resetForm({ values: newValues });
    },
    [defaultStartDatetime, props.formulas]
  );

  /**
   * Reset the form when the user changes the selection in the formula menu.
   * (Note this function acts as an "onchange" callback for the SimpleSelect
   * component.)
   *
   * @param {object} formik
   * @param {object} selection
   * @param {string} selection.value The ID of the selected formula.
   * @return {false} Returns false to tell SimpleSelect that we're taking care
   * of the onchange stuff ourselves.
   */
  const handleChangeFormulaMenu = React.useCallback(
    (
      formik: FormikProps<CurrentFormulaFormValues>,
      selection: number | null
    ) => {
      const formula = props.formulas.find((f) => f.id === selection);
      const newValues = emptyOpfFormValues(formula, defaultStartDatetime);
      formik.resetForm({ values: newValues });

      // This onchange handler replaces the default simpleselect one. So we
      // return false to tell simpleselect it can stop.
      return false;
    },
    [defaultStartDatetime, props.formulas]
  );

  /**
   * Handle a click on the "change" button for an observation point formula input
   * or observation point formula constant.
   * ("opfic" means OPFI or OPFC)
   */
  const handleClickOpficChangeButton = React.useCallback(
    (formik: FormikProps<CurrentFormulaFormValues>, formikPrefix: string) => {
      // When they click the change button, set this field to be
      // editable, and clear the startdate field
      formik.setFieldValue(`${formikPrefix}.isEditing`, true);
      formik.setFieldValue(`${formikPrefix}.start_datetime`, '');
      formik.setFieldTouched(`${formikPrefix}.start_datetime`, true);
    },
    []
  );

  // Check if this observation point's latest OPF is for the "OBS" (obsolete)
  // formula. (If so, the observation point formula should not be editable.)
  const obsoleteFormula =
    props.formulas &&
    props.formulas.find((f) => f.code === Enum.Formula_code_OBSOLETE);
  const isObsolete =
    opf &&
    obsoleteFormula &&
    opf.observation_point_formula.formula === obsoleteFormula.id;

  const shouldDisable =
    isObsolete ||
    props.isDisabled ||
    props.isLoading ||
    props.isSubmitting ||
    props.formulaHistory.isLoading;

  const initialValues: CurrentFormulaFormValues = opfToFormValues(
    opf,
    props.formulas,
    defaultStartDatetime
  );

  const handleSubmit = React.useCallback(
    (
      values: CurrentFormulaFormValues,
      formik: FormikHelpers<CurrentFormulaFormValues>
    ) =>
      props.onSubmit.call(null, values, formik).catch((e) => {
        formik.setSubmitting(false);
        showErrorsInFormik(formik, e, getExpectedFields(values));
      }),
    [props.onSubmit]
  );

  const isEditing = props.isEditing && !isObsolete;

  const cardHeadProps = {
    name: 'formula',
    header: <Trans>Current formula</Trans>,
    footer: (
      <FormulaHistory
        {...props.formulaHistory}
        onToggle={props.onToggleHistory}
        formulas={props.formulas}
      />
    ),
  };

  const cardBodyProps = {
    namePrefix: '',
    formulas: props.formulas,
    isEditing,
    isHistory: false,
    isLoading: props.isLoading || props.formulaHistory.isLoading,
    observationPoint: props.observationPoint,
    observationPointFormula: opf,
    siteDecorated: props.formulaHistory.siteDecorated,
    relatedObservationPoints: props.formulaHistory.relatedObservationPoints,
    canViewFormulaAssignmentReport: props.canViewFormulaAssignmentReport,
    canViewFormulaConstantReport: props.canViewFormulaConstantReport,
    canViewSiteReport: props.canViewSiteReport,
  };

  if (isObsolete) {
    // No edit button if its latest assignment is to the OBS formula
    return (
      <Card {...cardHeadProps}>
        <ObsPointFormulaCard
          CardSectionComponent={CardSection}
          {...cardBodyProps}
        />
      </Card>
    );
  } else {
    return (
      <EditableCard
        {...cardHeadProps}
        shouldDisable={shouldDisable}
        hasEditPermission={props.hasEditPermission}
        isEditMode={isEditing}
        enableReinitialize={!isEditing}
        startEditing={props.startEditing}
        stopEditing={props.stopEditing}
        onSubmit={handleSubmit}
        validate={validateForm}
        initialValues={initialValues}
        render={({ formik, CardSectionComponent }) => (
          <ObsPointFormulaCard
            {...cardBodyProps}
            formik={formik}
            CardSectionComponent={CardSectionComponent}
            shouldDisable={shouldDisable}
            handleChangeFormulaMenu={handleChangeFormulaMenu}
            handleClickFormulaChangeButton={handleClickFormulaChangeButton}
            handleClickOpficChangeButton={handleClickOpficChangeButton}
            formulaOptions={props.formulaOptions}
          />
        )}
      />
    );
  }
}

ObsPointCurrentFormulaSection.WrappedComponent = InnerObsPointFormulaSection;
