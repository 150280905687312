import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const myExportsListDuck = makeReportingDuck(
  'myExports',
  (filters) => getPaginated('/reports/my-exports/', filters),
  () => getApi('/reports/my-exports/info/')
);

export type MyExportsListAction = ReportingDuckActions<
  typeof myExportsListDuck
>;
