import React, { useCallback, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import { hasLatLong } from '../map-utils';
import { useGetApi } from 'hooks/use-get-api';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form, FormikErrors } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { useHistory } from 'react-router';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { AlertWarning } from 'components/base/alert/alert';
import { errorToString } from 'util/backendapi/error';

interface FormValues {
  areaCode: string;
}

interface Props extends ModalContentProps {
  areaCode: string;
}

export function AreaMapSettingsModal(props: Props) {
  const [{ data: areas, isLoading, isError, error }] =
    useGetApi('/area-coordinates/');

  const areaMenuOptions = useMemo(
    () =>
      sortBy(
        (areas ?? [])
          .filter(hasLatLong)
          .map((a) => ({ label: `${a.code} - ${a.name}`, value: a.code })),
        (a) => a.label
      ),
    [areas]
  );

  const initialValues: FormValues = useMemo(
    () => ({ areaCode: props.areaCode }),
    [props.areaCode]
  );

  const history = useHistory();
  const handleSubmit = useCallback(
    (formValues: FormValues) => {
      history.push(`/maps/areas/${encodeURIComponent(formValues.areaCode)}`);
      props.hideModal.call(null);
    },
    [history, props.hideModal]
  );

  return (
    <AreaMapSettingsModalView
      {...props}
      {...{ areaMenuOptions, isLoading, isError, error, initialValues }}
      onSubmit={handleSubmit}
    />
  );
}

interface ViewProps extends ModalContentProps {
  initialValues: FormValues;
  areaMenuOptions: SimpleSelectOption<string>[];
  onSubmit: (values: FormValues) => void;
  isLoading: boolean;
  isError: boolean;
  error: any;
}

function AreaMapSettingsModalView(props: ViewProps) {
  const validate = useCallback(
    (values: FormValues): FormikErrors<FormValues> => {
      const errors: FormikErrors<FormValues> = {};
      if (values.areaCode === '') {
        errors.areaCode = (<Trans>Area is required.</Trans>) as any;
      }
      return errors;
    },
    []
  );
  return (
    <ModalContent header={<Trans>Map settings</Trans>}>
      <Formik<FormValues>
        initialValues={props.initialValues}
        onSubmit={props.onSubmit}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <Form>
            {props.isError && (
              <AlertWarning>{errorToString(props.error)}</AlertWarning>
            )}
            <FormSection>
              <FormItem
                label={<Trans>Area</Trans>}
                fieldId="area-map-settings-area"
              >
                <SimpleSelectField
                  id="area-map-settings-area"
                  isDisabled={props.isLoading || props.isError}
                  name="areaCode"
                  options={props.areaMenuOptions}
                  isLoading={props.isLoading}
                />
                <FieldError name="areaCode" />
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                name="area-map-settings-submit"
                disabled={props.isLoading || props.isError || isSubmitting}
                iconType="icon-update"
                type="submit"
              >
                <Trans>Apply settings</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
