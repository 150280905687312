import React, { useMemo, useCallback } from 'react';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans, Plural } from '@lingui/macro';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { getWorkflowStatusOptions } from './changestatusmodal';
import { useSelector } from 'react-redux';
import { FullState } from 'main/reducers';
import { Form, Formik, FormikHelpers } from 'formik';
import {
  ChangeStatusForm,
  validateChangeStatusForm,
  ChangeStatusFormValues,
} from './changestatusmodalview';
import { patchApi } from 'util/backendapi/fetch';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { useIsMounted } from 'util/hooks';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { errorToString } from 'util/backendapi/error';
import { withI18nProps, withI18n } from '@lingui/react';
import { makeStatusChangeComment } from './change-status-util';

interface Props extends ModalContentProps {
  selectedAlarmReports: {
    id: number;
    category: Enum.AlarmReport_CATEGORY | null;
  }[];
  currentStatus: Enum.AlarmReport_STATUS;
  onAfterSubmit: () => void;
}

function InnerBulkChangeStatusModal(props: Props & withI18nProps) {
  const isMounted = useIsMounted();
  const {
    currentStatus,
    selectedAlarmReports,
    onAfterSubmit,
    hideModal,
    i18n,
  } = props;
  const userPermissions = useSelector(
    (state: FullState) => state.user.permissions
  );
  const handleSubmit = useCallback(
    async (
      values: ChangeStatusFormValues,
      formik: FormikHelpers<ChangeStatusFormValues>
    ) => {
      try {
        await patchApi('/alarm-reports/bulk/', {
          ids: selectedAlarmReports.map((a) => a.id),
          category: values.category!,
          status: values.status!,
          comment: makeStatusChangeComment(
            i18n,
            values.status!,
            values.comment
          ),
        });
      } catch (e) {
        formik.setSubmitting(false);
        // This endpoint may return an array of DRF errors. In which case,
        // just display them as strings at the top of the form.
        if (Array.isArray(e)) {
          formik.setStatus(e.map((error) => errorToString(error)).join(';\n'));
        } else {
          showErrorsInFormik(formik, e);
        }
        return;
      }
      if (isMounted()) {
        onAfterSubmit();
        hideModal();
      }
    },
    [isMounted, selectedAlarmReports, i18n, onAfterSubmit, hideModal]
  );

  // If all the selected alarm reports share the same category, pre-fill the
  // category menu with that. Otherwise, leave it blank.
  const initialCategory = useMemo(() => {
    const firstRecordCategory = selectedAlarmReports[0]?.category;
    if (selectedAlarmReports.every((a) => a.category === firstRecordCategory)) {
      return firstRecordCategory;
    } else {
      return null;
    }
  }, [selectedAlarmReports]);

  return (
    <BulkChangeStatusView
      initialStatus={currentStatus}
      initialCategory={initialCategory}
      selectionCount={selectedAlarmReports.length}
      onSubmit={handleSubmit}
      userPermissions={userPermissions}
    />
  );
}

export const BulkChangeStatusModal = withI18n()(InnerBulkChangeStatusModal);

interface ViewProps {
  initialStatus: Enum.AlarmReport_STATUS;
  initialCategory: Enum.AlarmReport_CATEGORY | null;
  selectionCount: number;
  onSubmit: (
    values: ChangeStatusFormValues,
    formik: FormikHelpers<ChangeStatusFormValues>
  ) => void;
  userPermissions: Enum.User_PERMISSION[];
}

export function BulkChangeStatusView(props: ViewProps) {
  const {
    initialStatus,
    initialCategory,
    selectionCount,
    onSubmit,
    userPermissions,
  } = props;
  const workflowOptions = getWorkflowStatusOptions(
    initialStatus,
    userPermissions
  );
  const initialValues: ChangeStatusFormValues = useMemo(
    () => ({
      status: undefined,
      category: initialCategory,
      comment: '',
    }),
    [initialCategory]
  );
  return (
    <ModalContent header={<Trans>Bulk change status</Trans>}>
      <p>
        <strong>
          <Plural
            value={selectionCount}
            one="1 Alarm report selected"
            other="# Alarm reports selected"
          />
        </strong>
      </p>
      <p>
        <Trans>
          Note, the same status and comment will be applied to all of the
          selected alarm reports.
        </Trans>
      </p>
      <Formik<ChangeStatusFormValues>
        validate={validateChangeStatusForm}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <ChangeStatusForm nextStepsOptions={workflowOptions} />
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                iconType="icon-save"
                disabled={formik.isSubmitting}
                type="submit"
                data-testid="bulk-change-status-modal-submit"
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
