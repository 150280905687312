import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { fetchAllGroups, deleteGroup } from '../../ducks/group';
import MaintainGroupScreenView from './maintgroupscreenview';
import { FullState } from 'main/reducers';
import { Model } from 'util/backendapi/models/api.interfaces';

interface StateProps {
  isLoading: boolean;
  error: boolean;
  groups: Model.AreaGroupDecorated[];
  errorMessage: string;
}
type DispatchProps = typeof mapDispatchToProps;
interface OwnProps {}
type Props = StateProps & OwnProps & ResolveThunks<DispatchProps>;

const mapDispatchToProps = {
  fetchAllGroups,
  deleteGroup,
};

class _MaintainGroupScreen extends React.Component<Props> {
  static defaultProps = {
    isLoading: false,
    error: false,
    errorMessage: '',
  };

  static mapStateToProps(state: FullState) {
    return {
      isLoading: state.groups.isLoading,
      error: state.groups.error,
      groups: state.groups.groups || [],
      errorMessage: state.groups.errorMessage,
    };
  }

  componentDidMount() {
    if (!this.props.isLoading) {
      this.props.fetchAllGroups();
    }
  }

  render() {
    return (
      <MaintainGroupScreenView
        isLoading={this.props.isLoading}
        error={this.props.error}
        groups={this.props.groups}
        errorMessage={this.props.errorMessage}
        onDeleteGroup={this.props.deleteGroup}
      />
    );
  }
}

const MaintainGroupScreen = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  _MaintainGroupScreen.mapStateToProps,
  mapDispatchToProps
)(_MaintainGroupScreen);
export default MaintainGroupScreen;
