import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from 'util/hooks';
import { Pagination } from 'util/backendapi/pagination';
import { RouteComponentProps } from 'react-router-dom';
import { errorToString } from 'util/backendapi/error';
import { detectExportMode } from 'util/export';
import { ReportListState, ReportingDuck } from 'ducks/make-reporting-duck';
import { FullState } from 'main/reducers';
import { useEffect, useMemo, useCallback } from 'react';
import { getReportApiBackendParams } from 'components/modules/report/report-utils';
import {
  ReportColumn,
  ReportFilter,
  ReportViewProps,
} from 'components/modules/report/report-types';
import { EMPTY_ARRAY } from 'util/misc';
import { Filter } from 'util/backendapi/models/api.interfaces';

/**
 * The object returned by `useReportState()`. Gives you pretty much everything
 * you'll need to plug in to `<ReportTable>` and friends.
 *
 * @typeParam T The Model type returned by the endpoint, e.g. `Model.ReportsSite`
 */
export interface ReportStateProps<T> extends ReportViewProps<T> {
  loadedFilters: string | null;
  refreshList: () => void;
  modifyReportRow?: (id: number, partialRow: Partial<T>) => void;
}

/**
 * Hook to handle all the boilerplate of getting filters from the URL, and
 * requesting data from the backend, for a bog-standard reports framework
 * screen.
 *
 * @typeParam T The Model type returned by the endpoint, e.g. `Model.ReportsSite`
 *
 * @param ownProps The screen's props (to get route props)
 * @param columns
 * @param filters
 * @param reportingDuck A duck created by `makeReportingDuck()`
 * @param reportListStateSelector Something like `(state) => state.myDuck`
 * @see `makeReportingDuck()`
 */
export function useReportState<T, F extends Filter.ReportsStandardFilters>(
  ownProps: RouteComponentProps,
  columns: ReportColumn<T>[],
  filters: ReportFilter[],
  reportingDuck: ReportingDuck<any, T, F>,
  reportListStateSelector: (state: FullState) => ReportListState<T>
): ReportStateProps<T> {
  const props = useShallowEqualSelector((state: FullState) => {
    const reportListState: ReportListState<T> = reportListStateSelector(state);
    return {
      records: reportListState.items || EMPTY_ARRAY,
      reportInfo: reportListState.reportInfo,
      pagination: Pagination.fromRequestedReceived(
        Pagination.parseFromRouterProps(ownProps),
        reportListState.paginationResponse
      ),
      isLoading:
        reportListState.loading ||
        reportListState.reportInfoLoading ||
        (!reportListState.reportInfo && !reportListState.reportInfoError),
      loadedFilters: reportListState.loadedFilters,
      generatedOnDatetime: reportListState.generatedOnDatetime,
      errorMessage: errorToString(reportListState.error),
      isExportMode: detectExportMode(),
      reportInfoError: reportListState.reportInfoError,
    };
  });

  const dispatch = useDispatch();
  const currentFilters = useMemo(() => {
    return getReportApiBackendParams<T, F>(ownProps, columns, filters);
  }, [ownProps, columns, filters]);

  useEffect(() => {
    dispatch(reportingDuck.fetchReportInfo());
    return () => {
      dispatch(reportingDuck.actions.UNMOUNT_REPORT_SCREEN());
    };
  }, [dispatch, reportingDuck]);

  useEffect(() => {
    if (props.loadedFilters !== JSON.stringify(currentFilters)) {
      dispatch(reportingDuck.fetchReportList(currentFilters));
    }
  }, [dispatch, props.loadedFilters, reportingDuck, currentFilters]);

  const refreshList = useCallback(() => {
    dispatch(reportingDuck.fetchReportInfo());
    dispatch(reportingDuck.fetchReportList(currentFilters));
  }, [dispatch, reportingDuck, currentFilters]);

  const modifyReportRow = useCallback(
    (rowIdx: number, partialRow: Partial<T>) => {
      dispatch(reportingDuck.modifyReportRow(rowIdx, partialRow));
    },
    [dispatch, reportingDuck]
  );

  return {
    ...props,
    refreshList,
    modifyReportRow,
  };
}
