import React from 'react';
import { Trans } from '@lingui/macro';
import min from 'lodash/minBy';
import max from 'lodash/maxBy';
import { defaultMemoize } from 'reselect';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrimary } from 'components/base/button/button';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { YesNoRadioField } from 'components/base/form/yesnoradio/yesnoradiofield';
import Loading from 'components/base/loading/loading';
import { Form, Formik, FormikProps, ErrorMessage, Field } from 'formik';
import { DateField } from 'components/base/form/datefield/datefield';
import { ReadingsDateRangeFilter } from 'components/modules/daterangefilter/readingsdaterangefilter';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { convertDatetimeToDate, parseIntervalFromString } from 'util/dates';
import { ReadingDatesByObsPointId } from 'ducks/readingsdaterangefilter';
import { ScatterPlotSettings } from 'components/plots/timeseriesplot.types';
import {
  LoadDefaultsFunc,
  OnSearchFunc,
} from 'components/base/form/asyncsimpleselect/asyncsimpleselect';
import { getTimeZoneFromPlotMetadata } from 'components/plots/timeseriesplotselectors';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { isTruthy } from 'util/validation';
import {
  getObsPointItemIdent,
  ObsPointItemMenuOption,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { IntegerField } from 'components/base/form/integer-field/IntegerField';
import {
  validateDuration,
  validateCustomScale,
} from 'components/plots/validation';
import { RadioField } from 'components/base/form/radio-field/RadioField';
import {
  ScaleConfigFormValue,
  ScatterPlotSettingsFormValue,
} from 'components/plots/settingsform.types';

export interface ScatterPlotSettingsViewProps {
  isLoading: boolean;
  onSubmit: (values: ScatterPlotSettingsFormValue) => void;
  resolvedSettings: ScatterPlotSettings | null;
  loadDefaultObsPointItems: LoadDefaultsFunc<string, false>;
  onSearchObsPointItems: OnSearchFunc<string, ObsPointItemMenuOption>;
}

// const excludeFromListSelector = defaultMemoize(
//   (list: PlotListObsPointMenuItem[], exclusionValue: string) =>
//     list.filter((item) => item.value !== exclusionValue)
// );

// For scatter plot, we need the `last start date` and `first end date`, of the observation points.
// (Because we can't start plotting until there is data present for both observation points.)
const firstAndLastReadingDateSelector = defaultMemoize(
  (
    observationPointIds: number[],
    timeZone: string | null,
    datetimesByObsPointId: ReadingDatesByObsPointId
  ) => {
    if (!observationPointIds || !observationPointIds.length) {
      return {
        firstReadingDate: null,
        lastReadingDate: null,
      };
    }

    const opReadingDatetimes = observationPointIds
      .map((id) => datetimesByObsPointId[id])
      .filter(isTruthy);

    const lastStartDate = max(
      opReadingDatetimes.map((op) => op.earliest_reading_datetime)
    );
    const firstEndDate = min(
      opReadingDatetimes.map((op) => op.latest_reading_datetime)
    );

    return {
      firstReadingDate: lastStartDate
        ? convertDatetimeToDate(lastStartDate, timeZone || undefined)
        : null,
      lastReadingDate: firstEndDate
        ? convertDatetimeToDate(firstEndDate, timeZone || undefined)
        : null,
    };
  }
);

const validate = (values: ScatterPlotSettingsFormValue) => {
  let errors: any = {};

  if (!values.selectionY) {
    errors.selectionY = <Trans>Please select an observation point</Trans>;
  }

  if (!values.selectionX) {
    errors.selectionX = <Trans>Please select an observation point</Trans>;
  }

  errors = {
    ...errors,
    ...validateDuration(values, 'numberOfMonths', 'startDate', 'endDate'),
    ...validateCustomScale(values.yAxisScale, `yAxisScale`),
    ...validateCustomScale(values.xAxisScale, `xAxisScale`),
  };

  return errors;
};

export function getScatterPlotSettingsFormInitialValues(
  resolved: ScatterPlotSettings | null
): ScatterPlotSettingsFormValue {
  if (!resolved) {
    return {
      selectionX: '',
      selectionXDetails: [],
      xAxisScale: {
        minimum: '',
        maximum: '',
        side: 'bottom',
        mode: 'auto',
      },
      selectionY: '',
      selectionYDetails: [],
      yAxisScale: {
        minimum: '',
        maximum: '',
        side: 'left',
        mode: 'auto',
      },
      startDate: '',
      endDate: '',
      numberOfMonths: '',
      interpolate: true,
      plotmarks: true,
      markconnections: true,
    };
  }
  const timeZone = getTimeZoneFromPlotMetadata(resolved);
  const xSeries = resolved.reading_series[1];
  const ySeries = resolved.reading_series[0];

  const yAxisScale = resolved.axes[0];
  const xAxisScale = resolved.axes[1];
  return {
    selectionX: getObsPointItemIdent(
      xSeries.observation_point,
      xSeries.item_number
    ),
    yAxisScale: yAxisScale
      ? {
          ...yAxisScale,
          minimum: yAxisScale.minimum === null ? '' : yAxisScale.minimum,
          maximum: yAxisScale.maximum === null ? '' : yAxisScale.maximum,
          mode: yAxisScale.minimum || yAxisScale.maximum ? 'custom' : 'auto',
        }
      : {
          side: 'left',
          minimum: '',
          maximum: '',
          mode: 'auto',
        },
    selectionXDetails: [],
    xAxisScale: xAxisScale
      ? {
          ...xAxisScale,
          minimum: xAxisScale.minimum === null ? '' : xAxisScale.minimum,
          maximum: xAxisScale.maximum === null ? '' : xAxisScale.maximum,
          mode: xAxisScale.minimum || xAxisScale.maximum ? 'custom' : 'auto',
        }
      : {
          side: 'bottom',
          minimum: '',
          maximum: '',
          mode: 'auto',
        },
    selectionY: getObsPointItemIdent(
      ySeries.observation_point,
      ySeries.item_number
    ),
    selectionYDetails: [],
    startDate: convertDatetimeToDate(resolved.start_datetime, timeZone),
    endDate: convertDatetimeToDate(resolved.end_datetime, timeZone),
    numberOfMonths:
      parseIntervalFromString(resolved.duration, 'months').intervalNumber || '',
    interpolate: Boolean(resolved.interpolate),
    plotmarks: Boolean(resolved.show_plot_markers),
    markconnections: Boolean(resolved.show_mark_connections),
  };
}

export const ScatterPlotSettingsView: React.FunctionComponent<
  ScatterPlotSettingsViewProps
> = ({
  resolvedSettings,
  isLoading,
  onSubmit,
  loadDefaultObsPointItems,
  onSearchObsPointItems,
}) => {
  const initialValues =
    getScatterPlotSettingsFormInitialValues(resolvedSettings);
  return (
    <ModalContent header={<Trans>Plot settings</Trans>}>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Formik
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
          >
            {(formikBag: FormikProps<ScatterPlotSettingsFormValue>) => (
              <Form>
                <FormSection label={<Trans>Y axis</Trans>}>
                  <AsyncSimpleSelectField<string>
                    autoFocus={true}
                    id="scatter-plot-settings-y"
                    name="selectionY"
                    detailsName="selectionYDetails"
                    loadDefaults={loadDefaultObsPointItems}
                    onSearch={onSearchObsPointItems}
                    isClearable={false}
                  />
                  <ErrorMessage name="selectionY" component={ErrorNotice} />
                </FormSection>
                <div id="scatter-plot-settings-yscale">
                  <CustomScaleField
                    label={<Trans>Y axis scale</Trans>}
                    axis={formikBag.values.yAxisScale}
                    axisFieldPrefix="yAxisScale"
                  />
                </div>
                <FormSection label={<Trans>X axis</Trans>}>
                  <AsyncSimpleSelectField<string>
                    id="scatter-plot-settings-x"
                    name="selectionX"
                    detailsName="selectionXDetails"
                    loadDefaults={loadDefaultObsPointItems}
                    onSearch={onSearchObsPointItems}
                    isClearable={false}
                  />
                  <ErrorMessage name="selectionX" component={ErrorNotice} />
                </FormSection>
                <div id="scatter-plot-settings-xscale">
                  <CustomScaleField
                    label={<Trans>X axis scale</Trans>}
                    axis={formikBag.values.xAxisScale}
                    axisFieldPrefix="xAxisScale"
                  />
                </div>
                <FormSection label={<Trans>Time period</Trans>}>
                  <ReadingsDateRangeFilter
                    timeZone={
                      resolvedSettings && resolvedSettings.storedPlot
                        ? resolvedSettings.storedPlot.area.time_zone.name
                        : undefined
                    }
                    // TODO
                    observationPointIds={[]}
                    firstAndLastReadingDateSelector={
                      firstAndLastReadingDateSelector
                    }
                    render={({ startProps, endProps }) => {
                      return (
                        <>
                          <div className="form-group form-group-panel-inline">
                            <label htmlFor="scatter-plot-settings-startdate">
                              <Trans>Start date</Trans>
                            </label>
                            <DateField
                              id="scatter-plot-settings-startdate"
                              name="startDate"
                              disabled={startProps.disabled}
                              placeholder={startProps.placeholder}
                            />
                          </div>
                          <div className="form-group form-group-panel-inline">
                            <label htmlFor="scatter-plot-settings-enddate">
                              <Trans>End date</Trans>
                            </label>
                            <DateField
                              id="scatter-plot-settings-enddate"
                              name="endDate"
                              disabled={endProps.disabled}
                              placeholder={endProps.placeholder}
                            />
                          </div>
                          <FieldError name="startDate" />
                          <FieldError name="endDate" />
                        </>
                      );
                    }}
                  />
                  <FormItem
                    label={<Trans>Number of months</Trans>}
                    fieldId="scatter-plot-settings-numberOfMonths"
                  >
                    <IntegerField
                      id="scatter-plot-settings-numberOfMonths"
                      name="numberOfMonths"
                      min={1}
                    />
                    <FieldError name="numberOfMonths" />
                  </FormItem>
                </FormSection>

                <FormSection
                  className="radio-fieldset-toggle"
                  label={<Trans>Interpolation</Trans>}
                >
                  <fieldset className="radio-fieldset-toggle radio-fieldset-inline">
                    <legend>
                      <Trans>Interpolate data</Trans>
                    </legend>
                    <YesNoRadioField name="interpolate" />
                  </fieldset>
                </FormSection>

                <FormSection label={<Trans>Plot marks</Trans>}>
                  <fieldset className="radio-fieldset-toggle radio-fieldset-inline">
                    <legend>
                      <Trans>Show plot markers</Trans>
                    </legend>
                    <YesNoRadioField name="plotmarks" />
                  </fieldset>

                  <fieldset className="radio-fieldset-toggle radio-fieldset-inline">
                    <legend>
                      <Trans>Show mark connections</Trans>
                    </legend>
                    <YesNoRadioField name="markconnections" />
                  </fieldset>
                </FormSection>

                <ActionBlock>
                  <ButtonHideModal />
                  <ButtonPrimary
                    type="submit"
                    iconType="icon-update"
                    disabled={formikBag.isSubmitting}
                  >
                    <Trans>Apply settings</Trans>
                  </ButtonPrimary>
                </ActionBlock>
              </Form>
            )}
          </Formik>
        </React.Fragment>
      )}
    </ModalContent>
  );
};

const CustomScaleField = ({
  label,
  axis,
  axisFieldPrefix,
}: {
  label: React.ReactNode;
  axis: ScaleConfigFormValue;
  axisFieldPrefix: string;
}) => {
  return (
    <FormSection label={label} className="radio-fieldset-toggle">
      <RadioField
        name={`${axisFieldPrefix}.mode`}
        className="form-group"
        options={[
          {
            label: <Trans>Auto</Trans>,
            value: 'auto',
          },
          {
            label: <Trans>Custom</Trans>,
            value: 'custom',
          },
        ]}
      />
      {axis.mode === 'custom' ? (
        <div className="columns-even">
          <FormItem
            label={<Trans>Min</Trans>}
            fieldId={`scatter-plot-settings-${axisFieldPrefix}-minimum`}
          >
            <Field
              name={`${axisFieldPrefix}.minimum`}
              id={`scatter-plot-settings-${axisFieldPrefix}-minimum`}
              type="number"
              data-testid="min"
            />
            <FieldError name={`${axisFieldPrefix}.minimum`} />
          </FormItem>
          <FormItem
            label={<Trans>Max</Trans>}
            fieldId={`scatter-plot-settings-${axisFieldPrefix}-maximum`}
          >
            <Field
              name={`${axisFieldPrefix}.maximum`}
              id={`scatter-plot-settings-${axisFieldPrefix}-maximum`}
              type="number"
              data-testid="max"
            />
            <FieldError name={`${axisFieldPrefix}.maximum`} />
          </FormItem>
        </div>
      ) : null}
    </FormSection>
  );
};
