import React from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { ToggleField } from 'components/base/form/toggle-field/ToggleField';
import { RadioFieldOption } from 'components/base/form/radio-field/RadioField';
import { Trans } from '@lingui/macro';
import { StoredSurveyLevellingPlotTimePeriod } from 'util/backendapi/types/Model';
import './SurveyTimePeriodsField.scss';

export interface SurveyTimePeriodOption {
  surveyDateOption: SimpleSelectOption<string>;
  showPlotMarkers: boolean;
}

export interface SurveyTimePeriodsProps {
  name: string;
  disabled?: boolean;
  className?: string;
  surveyTimePeriods: ReadonlyArray<SurveyTimePeriodOption>;
}

const InnerSurveyTimePeriodsField: React.FunctionComponent<
  FieldProps & SurveyTimePeriodsProps
> = (props) => {
  return (
    <div className={classNames('check-group-wrapper', props.className)}>
      {props.surveyTimePeriods.map((surveyTimePeriod, i) => (
        <SurveyTimePeriodField {...props} key={i} option={surveyTimePeriod} />
      ))}
    </div>
  );
};

export interface SurveyTimePeriodFieldProps {
  name: string;
  disabled?: boolean;
  className?: string;
  surveyTimePeriods: ReadonlyArray<SurveyTimePeriodOption>;
}

export const showMarkersToggleOptions: RadioFieldOption<boolean>[] = [
  { label: <Trans>Show markers</Trans>, value: true },
  { label: <Trans>Hide markers</Trans>, value: false },
];

const SurveyTimePeriodField: React.FunctionComponent<
  FieldProps &
    SurveyTimePeriodFieldProps & {
      option: SurveyTimePeriodOption;
    }
> = (props) => {
  const { field, form, option } = props;

  const surveyDatetime = option.surveyDateOption.value;

  const isSelected =
    Array.isArray(field.value) &&
    field.value.some(
      (time_period: StoredSurveyLevellingPlotTimePeriod) =>
        time_period.survey_datetime === surveyDatetime
    );

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newIsSelected = Boolean(e.target && e.target.checked);
      if (newIsSelected === isSelected) {
        return;
      }

      if (newIsSelected) {
        // Newly selected, add it to the array
        form.setFieldValue.call(null, field.name, [
          ...field.value,
          {
            survey_datetime: surveyDatetime,
            show_plot_markers: option.showPlotMarkers,
          } as StoredSurveyLevellingPlotTimePeriod,
        ]);
      } else {
        // Deselected, remove it from the array
        form.setFieldValue.call(
          null,
          field.name,
          field.value.filter(
            (time_period: StoredSurveyLevellingPlotTimePeriod) =>
              time_period.survey_datetime !== surveyDatetime
          )
        );
      }
    },
    [
      field.name,
      field.value,
      form.setFieldValue,
      isSelected,
      surveyDatetime,
      option,
    ]
  );

  const checkboxId = `checkbox-${field.name}-${option.surveyDateOption.label}`;

  const fieldIndex = isSelected
    ? field.value.findIndex(
        (time_period: StoredSurveyLevellingPlotTimePeriod) =>
          time_period.survey_datetime === surveyDatetime
      )
    : -1;

  return (
    <div className="check-group">
      <input
        type="checkbox"
        name={checkboxId}
        id={checkboxId}
        checked={isSelected}
        // NOTE: The "value" attribute here is not actually read back by our
        // code. Rather, the form value is set in the `onChange` callback.
        value={checkboxId}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <label htmlFor={checkboxId}>
        {option.surveyDateOption.label}
        {isSelected ? (
          <ToggleField
            name={`${field.name}[${fieldIndex}].show_plot_markers`}
            options={showMarkersToggleOptions}
          />
        ) : null}
      </label>
    </div>
  );
};

export const SurveyTimePeriodsField = (props: SurveyTimePeriodsProps) => (
  <Field
    className="survey-time-periods"
    component={InnerSurveyTimePeriodsField}
    {...props}
  />
);
