import React from 'react';
import { Trans } from '@lingui/macro';
import { Title } from 'react-head';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AlertDanger } from 'components/base/alert/alert';
import PrivateRoute from 'components/logic/privateroute/privateroute';
import AlarmParametersScreen from 'screens/alarmparameters/alarmparameters';
import { AlarmParameterListScreen } from 'screens/alarmparameters/AlarmParameterListScreen';
import AlarmReportDetailScreen from 'screens/alarmreports/alarmreportdetail';
import { DashboardScreen } from 'screens/dashboard/DashboardScreen';
import BackendStatusScreen from 'screens/backendstatus/backendstatus';
import { CloseoutScreen } from 'screens/closeout/Closeout';
import FileEditorScreen from 'screens/fileeditor/fileeditor';
import { DataLoggersListScreen } from 'screens/dataLogger/list/DataLoggersListScreen';
import { MaintainDataLoggerScreen } from 'screens/dataLogger/maintdatalogger/maintdataloggerscreen';
import MaintainGroupScreen from 'screens/maintgroup/maintgroupscreen';
import { InstrumentTypeDetail } from 'screens/instrument-type/detail/InstrumentTypeDetail';
import { ObsPointDetailScreen } from 'screens/obsPoint/detail/maintobspointscreen';
import { SiteDetailScreen } from 'screens/site/detail/SiteDetailScreen';
import { NewDataLoggerScreen } from 'screens/dataLogger/newdatalogger/newdatalogger';
import { QuickListScreen } from 'screens/quicklist/QuickListScreen';
import { QuickPlotScreen } from 'screens/quickplot/quickplot';
import RouteMarchListScreen from 'screens/routemarch/routemarchlistscreen';
import { RouteMarchScheduleScreen } from 'screens/routemarch/RouteMarchScheduleScreen';
import { CommentsListScreen } from 'screens/comments/CommentsListScreen';
import RouteMarchListObservationPointScreen from 'screens/routemarch/listobspoints/listobspointsscreen';
import { ReprocessingRequestListScreen } from 'screens/readings/reprocessing-requests/ReprocessingRequestScreen';
import { ScatterPlotScreen } from 'screens/scatterplot/ScatterPlotScreen';
import { UserProfileScreen } from 'screens/userprofile/userprofilescreen';
import { StoredListLandingScreen } from 'screens/storedlist/list/storedlistlandingscreen';
import { StoredListItemScreen } from 'screens/storedlist/detail/listitemsscreen';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { StoredListReportScreen } from 'screens/storedlist/report/storedlistreportscreen';
import { ClientListScreen } from 'screens/client/list/ClientListScreen';
import { AreaListScreen } from 'screens/maintarea/list/AreaListScreen';
import { AreaDetail } from 'screens/maintarea/detail/AreaDetail';
import { SiteListScreen } from 'screens/site/list/SiteListScreen';
import { InstrumentTypeListScreen } from 'screens/instrument-type/list/InstrumentTypeList';
import { StoredPlotListScreen } from 'screens/storedplot/list/StoredPlotList';
import { StoredPlotScreen } from 'screens/storedplot/plot/StoredPlotScreen';
import { StoredPlotEditScreen } from 'screens/storedplot/edit/StoredPlotEdit';
import { AuditLogListScreen } from 'screens/auditlog/list/AuditLogListScreen';
import { PlotSetListScreen } from 'screens/plotset/list/PlotSetList';
import { PlotSetDetailScreen } from 'screens/plotset/detail/PlotSetDetail';
import { PlotSetScreen } from 'screens/plotset/plot/PlotSet';
import { DataLoggerChannelsScreen } from 'screens/dataLogger/channels/DataLoggerChannelsScreen';
import { FileGatesScreen } from 'screens/file-gates/FileGatesScreen';
import { SavedReportsScreen } from 'screens/savedreports/SavedReportsScreen';
import { ReadingsBatchListScreen } from 'screens/readings/batches/ReadingsBatchListScreen';
import { InspectionBatchListScreen } from 'screens/inspection-report/InspectionBatchListScreen';
import { InspectionReadingListScreen } from 'screens/inspection-report/InspectionReadingListScreen';
import { AlarmReportListScreen } from 'screens/alarmreports/AlarmReportListScreen';
import { AlarmNotificationsScreen } from 'screens/alarmnotifications/AlarmNotificationsScreen';
import { GroupMapScreen } from 'screens/maps/group/GroupMapScreen';
import { AreaMapScreen } from 'screens/maps/area/AreaMapScreen';
import { CheckReadings } from 'screens/checkreadings/CheckReadings';
import { ChecksheetTemplateListScreen } from 'screens/checksheet-template/list/ChecksheetTemplateListScreen';
import { MediaListScreen } from 'screens/media/list/MediaList';
import { MyExportsListScreen } from 'screens/media/list/MyExportsList';
import { ChecksheetTemplateEditScreen } from 'screens/checksheet-template/edit/ChecksheetTemplateEditScreen';
import { ObsPointListScreen } from 'screens/obsPoint/list/ObsPointListScreen';
import { ChecksheetEditScreen } from 'screens/checksheet-instance/edit/ChecksheetEditScreen';
import { ChecksheetTemplateStartInstanceScreen } from 'screens/checksheet-template/list/ChecksheetTemplateStartInstanceScreen';
import { ChecksheetInstanceListScreen } from 'screens/checksheet-instance/list/ChecksheetInstanceListView';
import { EditBookmarksDashletScreen } from 'screens/dashboard/BookmarksDashlet/EditBookmarksDashletScreen';
import { DMSLink } from 'components/base/link/DMSLink';
import { DataReviewReportScreen } from 'screens/data-review/DataReviewReportScreen';
import { SiteBulkCreateErrorsScreen } from 'screens/site/bulk-create/SiteBulkCreateErrorsScreen';
import { UserListScreen } from 'screens/user/list/UserListScreen';
import { ObsPointBulkCreateErrorsScreen } from 'screens/obsPoint/bulk-create/ObsPointBulkCreateErrorsScreen';
import { UserEditScreen } from 'screens/user/edit/UserEditScreen';
import { RetirementRequestListScreen } from 'screens/readings/retirement-requests/RetirementRequestListScreen';
import { ChangePasswordScreen } from 'screens/change-password/changePasswordScreen';
import { LoginScreen } from 'screens/login/login';
import { AlarmParameterBulkCreateErrorsScreen } from 'screens/alarmparameters/bulk-create/AlarmParameterBulkCreateErrorsScreen';
import { ObservationPointFormulaListScreen } from 'screens/observation-point-formulas/ObservationPointFormulaListScreen';
import { AlarmCommentsReportScreen } from 'screens/alarm-comments-report/AlarmCommentsReportScreen';
import { ReadingCommentExclusionsScreen } from 'screens/reading-comment-exclusions/ReadingCommentExclusionsScreen';
import { ReadingCommentsListScreen } from 'screens/reading-comments/ReadingCommentsListScreen';
import { StaticDataReportScreen } from 'screens/static-data-report/StaticDataReportScreen';
import { SiteTimeDependentFieldsScreen } from 'screens/site/detail/SiteTimeDependentFieldsScreen';
import { ObsPointTimeDependentFieldsScreen } from 'screens/obsPoint/detail/ObsPointTimeDependentFieldsScreen';
import { ObservationPointFormulaConstantListScreen } from 'screens/observation-point-formula-constants/ObservationPointFormulaConstantListScreen';
import { DataLoggerTdfScreen } from 'screens/dataLogger/DataLoggerTimeDependentFieldsScreen';
import { PerformanceIndicatorListScreen } from 'screens/performance-indicator/list/PerformanceIndicatorListScreen';
import { CumulativePlotScreen } from 'screens/cumulativeplot/CumulativePlotScreen';

/* Try to keep the following list of routes in alphabetical order
 * (except when a subpage needs to come before a parent page, for routing precedence.)
 */
export const DMSRoutes: {
  [key: string]: {
    path: string;
    component: any;
    permission: null | Enum.User_PERMISSION;
  };
} = {
  DASHBOARD: {
    path: '/dashboard',
    component: DashboardScreen,
    // Discussed with Duncan, every user should have some kind information available inside the Dashboard.
    // And it's the default screen that we redirect user to after login.
    // We don't need a specific permission for this screen
    permission: null,
  },
  ALARM_COMMENTS_REPORT: {
    path: '/alarm-comments-report',
    component: AlarmCommentsReportScreen,
    permission: Enum.User_PERMISSION.can_view_alarm_comments_report,
  },
  ALARM_NOTIFICATION_LIST: {
    path: '/alarm-notifications/',
    component: AlarmNotificationsScreen,
    permission: Enum.User_PERMISSION.can_view_alarm_notifications,
  },
  ALARM_PARAMETER_DETAIL: {
    path: '/alarm-parameters/:obsPointCode',
    component: AlarmParametersScreen,
    permission: Enum.User_PERMISSION.can_view_alarm_parameter_report,
  },
  ALARM_PARAMETER_LANDING: {
    path: '/alarm-parameters',
    component: AlarmParametersScreen,
    permission: Enum.User_PERMISSION.can_view_alarm_parameter_report,
  },
  ALARM_PARAMETER_BULK_CREATE_ERRORS: {
    path: '/alarm-parameter-report/bulk-create/',
    component: AlarmParameterBulkCreateErrorsScreen,
    permission: Enum.User_PERMISSION.can_bulk_create_alarm_parameters,
  },
  ALARM_PARAMETER_REPORT: {
    path: '/alarm-parameter-report/',
    component: AlarmParameterListScreen,
    permission: Enum.User_PERMISSION.can_view_alarm_parameter_report,
  },
  ALARM_REPORT_DETAIL: {
    path: '/alarm-reports/:alarmReportId',
    component: AlarmReportDetailScreen,
    permission: Enum.User_PERMISSION.can_view_alarm_report_report,
  },
  ALARM_REPORT_LIST: {
    path: '/alarm-reports',
    component: AlarmReportListScreen,
    permission: Enum.User_PERMISSION.can_view_alarm_report_report,
  },
  AREA_DETAIL: {
    path: '/area/:areaCode',
    component: AreaDetail,
    permission: Enum.User_PERMISSION.can_view_area_report,
  },
  AREA_LIST: {
    path: '/area/',
    component: AreaListScreen,
    permission: Enum.User_PERMISSION.can_view_area_report,
  },
  AUDIT_LOG_LIST: {
    path: '/audit-log/',
    component: AuditLogListScreen,
    permission: Enum.User_PERMISSION.can_view_audit_log_report,
  },
  CHECK_READINGS: {
    path: '/check-readings/:batchNumber',
    component: CheckReadings,
    permission: Enum.User_PERMISSION.can_view_readings_batch_report,
  },
  CHECKSHEET_INSTANCE_EDIT: {
    path: '/checksheet-instances/:checksheetInstanceId',
    component: ChecksheetEditScreen,
    permission: Enum.User_PERMISSION.can_create_checksheet_instances,
  },
  CHECKSHEET_INSTANCE_LIST: {
    path: '/checksheet-instances/',
    component: ChecksheetInstanceListScreen,
    permission: Enum.User_PERMISSION.can_view_checksheet_instance_report,
  },
  CHECKSHEET_TEMPLATE_NEW: {
    path: '/checksheet-templates/new',
    component: ChecksheetTemplateEditScreen,
    permission: Enum.User_PERMISSION.can_create_checksheet_templates,
  },
  CHECKSHEET_TEMPLATE_START_INSTANCE: {
    path: '/checksheet-templates/:checksheetTemplateId/checksheet-instance',
    component: ChecksheetTemplateStartInstanceScreen,
    permission: Enum.User_PERMISSION.can_create_checksheet_instances,
  },
  CHECKSHEET_TEMPLATE_EDIT: {
    path: '/checksheet-templates/:checksheetTemplateId',
    component: ChecksheetTemplateEditScreen,
    permission: Enum.User_PERMISSION.can_create_checksheet_templates,
  },
  CHECKSHEET_TEMPLATE_LIST: {
    path: '/checksheet-templates/',
    component: ChecksheetTemplateListScreen,
    permission: Enum.User_PERMISSION.can_view_checksheet_template_report,
  },
  CLIENT_LIST: {
    path: '/clients/',
    component: ClientListScreen,
    permission: Enum.User_PERMISSION.can_view_client_report,
  },
  CLOSEOUT: {
    path: '/closeout/:batchNumber',
    component: CloseoutScreen,
    permission: Enum.User_PERMISSION.can_view_readings_batch_report,
  },
  COMMENT_LIST: {
    path: '/comments/',
    component: CommentsListScreen,
    permission: Enum.User_PERMISSION.can_view_comment_report,
  },
  CUMULATIVE_PLOT_DETAIL: {
    path: '/cumulativeplot/:op/:item/:years/',
    component: CumulativePlotScreen,
    permission: Enum.User_PERMISSION.can_view_quick_plots,
  },
  CUMULATIVE_PLOT_LANDING: {
    path: '/cumulativeplot/',
    component: CumulativePlotScreen,
    permission: Enum.User_PERMISSION.can_view_quick_plots,
  },

  DASHLET_NEW: {
    path: '/dashboard-components/new',
    component: EditBookmarksDashletScreen,
    permission: Enum.User_PERMISSION.can_create_dashboard_component,
  },
  DASHLET_EDIT: {
    path: '/dashboard-components/:dashletId',
    component: EditBookmarksDashletScreen,
    permission: Enum.User_PERMISSION.can_create_dashboard_component,
  },
  DATA_LOGGER_NEW: {
    path: '/data-loggers/new',
    component: NewDataLoggerScreen,
    permission: Enum.User_PERMISSION.can_create_data_loggers,
    // @ts-ignore
    previousPath: document.location.pathname,
  },
  DATA_LOGGER_TIME_DEPENDENT_FIELDS: {
    path: '/data-loggers/:dataLoggerNumber/time-dependent-fields/:fieldName/',
    component: DataLoggerTdfScreen,
    permission: Enum.User_PERMISSION.can_view_data_loggers,
  },
  DATA_LOGGER_EDIT: {
    path: '/data-loggers/:dataLoggerNumber',
    component: MaintainDataLoggerScreen,
    permission: Enum.User_PERMISSION.can_view_data_loggers,
  },
  DATA_LOGGER_LIST: {
    path: '/data-loggers',
    component: DataLoggersListScreen,
    permission: Enum.User_PERMISSION.can_view_data_loggers,
  },
  DATA_LOGGER_CHANNELS: {
    path: '/data-logger-channels',
    component: DataLoggerChannelsScreen,
    permission: Enum.User_PERMISSION.can_view_data_logger_channel_report,
  },
  DATA_REVIEW_REPORT: {
    path: '/data-review-report',
    component: DataReviewReportScreen,
    permission: Enum.User_PERMISSION.can_view_data_review_report,
  },
  STATIC_DATA_REPORT: {
    path: '/static-data-report',
    component: StaticDataReportScreen,
    permission: Enum.User_PERMISSION.can_view_static_data_report,
  },
  FILE_GATE: {
    path: '/file-gates/',
    component: FileGatesScreen,
    permission: Enum.User_PERMISSION.can_assign_file_uploader_role,
  },
  GROUP_LIST: {
    path: '/groups',
    component: MaintainGroupScreen,
    permission: Enum.User_PERMISSION.can_list_groups,
  },
  INSTRUMENT_TYPE_NEW: {
    path: '/instrument-types/new',
    component: InstrumentTypeDetail,
    // @ts-ignore
    createNew: true,
    permission: Enum.User_PERMISSION.can_create_instrument_types,
  },
  INSTRUMENT_TYPE_DETAIL: {
    path: '/instrument-types/:instrumentTypeCode',
    component: InstrumentTypeDetail,
    permission: Enum.User_PERMISSION.can_create_instrument_types,
  },
  INSTRUMENT_TYPE_LIST: {
    path: '/instrument-types',
    component: InstrumentTypeListScreen,
    permission: Enum.User_PERMISSION.can_view_instrument_type_report,
  },
  MAP_AREA: {
    path: '/maps/areas/:areaCode',
    component: AreaMapScreen,
    permission: Enum.User_PERMISSION.can_view_map,
  },
  MAP_GROUP: {
    path: '/maps',
    component: GroupMapScreen,
    permission: Enum.User_PERMISSION.can_view_map,
  },
  MEDIA_LIST: {
    path: '/media',
    component: MediaListScreen,
    permission: Enum.User_PERMISSION.can_view_media_report,
  },
  MY_EXPORTS_LIST: {
    path: '/my-exports',
    component: MyExportsListScreen,
    permission: Enum.User_PERMISSION.can_view_my_exports_report,
  },
  OBSERVATION_POINT_SETUP: {
    path: '/observation-point-setup/:obsPointCode',
    component: ObsPointDetailScreen,
    permission: Enum.User_PERMISSION.can_create_observation_points,
  },
  OBSERVATION_POINT_BULK_CREATE_ERRORS: {
    path: '/observation-point/bulk-create/',
    component: ObsPointBulkCreateErrorsScreen,
    permission: Enum.User_PERMISSION.can_bulk_create_observation_points,
  },
  OBSERVATION_POINT_TIME_DEPENDENT_FIELDS: {
    path: '/observation-point/:obsPointCode/time-dependent-fields/:fieldName/',
    component: ObsPointTimeDependentFieldsScreen,
    permission: Enum.User_PERMISSION.can_view_observation_points,
  },
  OBSERVATION_POINT_DETAIL: {
    path: '/observation-point/:obsPointCode',
    component: ObsPointDetailScreen,
    permission: Enum.User_PERMISSION.can_view_observation_points,
  },
  OBSERVATION_POINT_LIST: {
    path: '/observation-point',
    component: ObsPointListScreen,
    permission: Enum.User_PERMISSION.can_view_observation_points,
  },
  OBSERVATION_POINT_FORMULA_LIST: {
    path: '/formula-assignment-report',
    component: ObservationPointFormulaListScreen,
    permission: Enum.User_PERMISSION.can_view_observation_point_formula_report,
  },
  OBSERVATION_POINT_FORMULA_CONSTANT_LIST: {
    path: '/formula-constants/',
    component: ObservationPointFormulaConstantListScreen,
    permission:
      Enum.User_PERMISSION.can_view_observation_point_formula_constant_report,
  },
  PERFORMANCE_INDICATOR_LIST: {
    path: '/performance-indicators/',
    component: PerformanceIndicatorListScreen,
    permission: Enum.User_PERMISSION.can_maintain_performance_indicators,
  },
  PLOT_SET_VIEW: {
    path: '/plot-sets/:plotSetName/plot',
    component: PlotSetScreen,
    permission: Enum.User_PERMISSION.can_view_stored_plot_report,
  },
  PLOT_SET_DETAIL: {
    path: '/plot-sets/:plotSetName',
    component: PlotSetDetailScreen,
    permission: Enum.User_PERMISSION.can_view_stored_plot_report,
  },
  PLOT_SET_LIST: {
    path: '/plot-sets',
    component: PlotSetListScreen,
    permission: Enum.User_PERMISSION.can_view_stored_plot_report,
  },
  QUICK_LIST_DETAIL: {
    path: '/quicklist/:obsPointCode',
    component: QuickListScreen,
    permission: Enum.User_PERMISSION.can_view_quick_lists,
  },
  QUICK_LIST_LIST: {
    path: '/quicklist/',
    component: QuickListScreen,
    permission: Enum.User_PERMISSION.can_view_quick_lists,
  },
  QUICK_PLOT_DETAIL: {
    path: '/quickplot/:obsPointCode',
    component: QuickPlotScreen,
    permission: Enum.User_PERMISSION.can_view_quick_plots,
  },
  QUICK_PLOT_LANDING: {
    path: '/quickplot',
    component: QuickPlotScreen,
    permission: Enum.User_PERMISSION.can_view_quick_plots,
  },
  READING_COMMENT_EXCLUSIONS: {
    path: '/reading-comment-exclusions/',
    component: ReadingCommentExclusionsScreen,
    permission:
      Enum.User_PERMISSION.can_view_reading_comments_report_exclusions,
  },
  READING_COMMENTS_REPORT: {
    path: '/reading-comments/',
    component: ReadingCommentsListScreen,
    permission: Enum.User_PERMISSION.can_view_reading_comments_report,
  },
  READINGS_BATCH_LIST: {
    path: '/readings-batches',
    component: ReadingsBatchListScreen,
    permission: Enum.User_PERMISSION.can_view_readings_batch_report,
  },
  INSPECTION_BATCH_LIST: {
    path: '/inspection-reports',
    component: InspectionBatchListScreen,
    permission: Enum.User_PERMISSION.can_view_inspection_reports,
  },
  INSPECTION_READING_LIST: {
    path: '/inspection-report',
    component: InspectionReadingListScreen,
    permission: Enum.User_PERMISSION.can_view_inspection_reports,
  },
  READINGS_FILE_EDIT: {
    path: '/readings-file/:fileId/edit',
    component: FileEditorScreen,
    permission: Enum.User_PERMISSION.can_view_readings_batch_report,
  },
  READING_PROCESSING_REQUEST_LIST: {
    path: '/reading-processing-requests/',
    component: ReprocessingRequestListScreen,
    permission: Enum.User_PERMISSION.can_view_reading_reprocessing_requests,
  },
  READING_RETIREMENT_REQUEST_LIST: {
    path: '/reading-retirement-requests/',
    component: RetirementRequestListScreen,
    permission: Enum.User_PERMISSION.can_retire_readings,
  },
  ROUTE_MARCH_LIST: {
    path: '/route-march/',
    component: RouteMarchListScreen,
    permission: Enum.User_PERMISSION.can_view_route_marches,
  },
  ROUTE_MARCH_SCHEDULE: {
    path: '/route-march-schedule/',
    component: RouteMarchScheduleScreen,
    permission: Enum.User_PERMISSION.can_view_route_march_schedule_report,
  },
  ROUTE_MARCH_OBSERVATION_POINTS: {
    path: '/route-march-observation-points/:routeMarchCode',
    component: RouteMarchListObservationPointScreen,
    permission: Enum.User_PERMISSION.can_view_route_march_observation_points,
  },
  SCATTER_PLOT_DETAIL: {
    path: '/scatterplot/:op_y/:item_y/:op_x/:item_x/',
    component: ScatterPlotScreen,
    permission: Enum.User_PERMISSION.can_view_quick_plots,
  },
  SCATTER_PLOT_LANDING: {
    path: '/scatterplot/',
    component: ScatterPlotScreen,
    permission: Enum.User_PERMISSION.can_view_quick_plots,
  },
  SAVED_REPORT_LIST: {
    path: '/saved-reports/',
    component: SavedReportsScreen,
    permission: Enum.User_PERMISSION.can_save_reports,
  },
  SITE_BULK_CREATE_ERRORS: {
    path: '/sites/bulk-create/',
    component: SiteBulkCreateErrorsScreen,
    permission: Enum.User_PERMISSION.can_bulk_create_sites,
  },
  SITE_TIME_DEPENDENT_FIELDS: {
    path: '/sites/:siteCode/time-dependent-fields/:fieldName/',
    component: SiteTimeDependentFieldsScreen,
    permission: Enum.User_PERMISSION.can_view_site_report,
  },
  SITE_DETAIL: {
    path: '/sites/:siteCode/',
    component: SiteDetailScreen,
    permission: Enum.User_PERMISSION.can_view_site_report,
  },
  SITE_LIST: {
    path: '/sites/',
    component: SiteListScreen,
    permission: Enum.User_PERMISSION.can_view_site_report,
  },
  STATUS: {
    path: '/status',
    component: BackendStatusScreen,
    permission: null,
  },
  STORED_LIST_EDIT: {
    path: '/stored-lists/:storedListId/edit/',
    component: StoredListItemScreen,
    permission: Enum.User_PERMISSION.can_create_stored_lists,
  },
  STORED_LIST_DETAIL: {
    path: '/stored-lists/:storedListId/',
    component: StoredListReportScreen,
    permission: Enum.User_PERMISSION.can_view_stored_lists,
  },
  STORED_LIST_LIST: {
    path: '/stored-lists/',
    component: StoredListLandingScreen,
    permission: Enum.User_PERMISSION.can_view_stored_lists,
  },
  STORED_PLOT_NEW: {
    path: '/stored-plots-new/:plotType',
    component: StoredPlotEditScreen,
    permission: Enum.User_PERMISSION.can_create_stored_plots,
  },
  STORED_PLOT_EDIT: {
    path: '/stored-plots/:plotName/edit',
    component: StoredPlotEditScreen,
    permission: Enum.User_PERMISSION.can_create_stored_plots,
  },
  STORED_PLOT_DETAIL: {
    path: '/stored-plots/:plotName',
    component: StoredPlotScreen,
    permission: Enum.User_PERMISSION.can_view_stored_plot_report,
  },
  STORED_PLOT_LIST: {
    path: '/stored-plots',
    component: StoredPlotListScreen,
    permission: Enum.User_PERMISSION.can_view_stored_plot_report,
  },
  USER_DETAIL: {
    path: '/user/:userId',
    component: UserProfileScreen,
    permission: Enum.User_PERMISSION.can_view_other_user_profiles,
  },
  USER_PROFILE: {
    path: '/user',
    component: UserProfileScreen,
    permission: null,
  },
  USER_NEW: {
    path: '/users/new',
    component: UserEditScreen,
    permission: Enum.User_PERMISSION.can_create_users,
  },
  USER_EDIT: {
    path: '/users/:userId',
    component: UserEditScreen,
    permission: Enum.User_PERMISSION.can_create_users,
  },
  USER_LIST: {
    path: '/users',
    component: UserListScreen,
    permission: Enum.User_PERMISSION.can_view_user_report,
  },
} as const;

export const routes = (
  <Switch>
    <Route path="/login" component={LoginScreen} />
    <Route
      path="/users/password-reset/:userId/:token"
      component={ChangePasswordScreen}
    />
    <Route path="/" exact render={() => <Redirect to="/dashboard" />} />

    {Object.keys(DMSRoutes).map((key) => (
      <PrivateRoute {...DMSRoutes[key]} key={key} />
    ))}

    {/* SPECIAL PAGES. THESE MUST COME AFTER THE NORMAL ROUTES. */}
    {/* In development, the paths /api/ and /admin/ should be ways
     * to access the backend server. Our reverse proxy sends them
     * to the frontend server if the backend is down.
     * TODO: remove this in production
     */}
    <Route
      path="/(admin|api)"
      exact={true}
      strict={true}
      render={({ match }) => (
        <>
          <Title>
            <Trans>DMS | Frontend error</Trans>
          </Title>
          <div>
            <Trans>Please add a trailing slash to your URL:</Trans>
            <a href={`${match.url}/`}>{match.url}/</a>
          </div>
        </>
      )}
    />
    <Route
      path="/(admin|api)/"
      exact={true}
      strict={true}
      render={() => (
        <>
          <Title>
            <Trans>DMS | Frontend error</Trans>
          </Title>
          <AlertDanger>
            <Trans>Frontend error: DMS backend appears to be down.</Trans>
          </AlertDanger>
        </>
      )}
    />
    {/* Page not found
     *
     * See: https://reacttraining.com/react-router/web/example/no-match
     * "A <Switch> renders the first child <Route> that matches. A
     * <Route> with no path always matches."
     *
     * TODO: A better error page, with a properly specified
     * user story behind it.
     */}
    <Route
      render={() => (
        <>
          <Title>
            <Trans>DMS | Frontend error</Trans>
          </Title>
          <AlertDanger>
            <Trans>Frontend error: Page not found</Trans>
          </AlertDanger>
          <div>
            <DMSLink to="/">
              <Trans>Back to dashboard</Trans>
            </DMSLink>
          </div>
        </>
      )}
    />
  </Switch>
);
