import { Model } from 'util/backendapi/models/api.interfaces';
import { DRFError, isDRFError } from 'util/backendapi/error';
import { StandardThunk, DuckActions } from 'main/store';
import { postApi, getApi, patchApi } from 'util/backendapi/fetch';
import { t } from '@lingui/macro';

export const ActionTypes = {
  UNMOUNT_DATA_LOGGER: 'dms/datalogger/detail/UNMOUNT_DATA_LOGGER',
  CREATE_DATA_LOGGER_START: 'dms/datalogger/detail/CREATE_DATA_LOGGER_START',
  CREATE_DATA_LOGGER_RESPONSE:
    'dms/datalogger/detail/CREATE_DATA_LOGGER_RESPONSE',
  CREATE_DATA_LOGGER_ERROR: 'dms/datalogger/detail/CREATE_DATA_LOGGER_ERROR',
  FETCH_DATA_LOGGER_START: 'dms/datalogger/detail/FETCH_DATA_LOGGER_START',
  FETCH_DATA_LOGGER_RESPONSE:
    'dms/datalogger/detail/FETCH_DATA_LOGGER_RESPONSE',
  FETCH_DATA_LOGGER_ERROR: 'dms/datalogger/detail/FETCH_DATA_LOGGER_ERROR',
  UPDATE_DATA_LOGGER_START: 'dms/datalogger/detail/UPDATE_DATA_LOGGER_START',
  UPDATE_DATA_LOGGER_RESPONSE:
    'dms/datalogger/detail/UPDATE_DATA_LOGGER_RESPONSE',
  UPDATE_DATA_LOGGER_ERROR: 'dms/datalogger/detail/UPDATE_DATA_LOGGER_ERROR',
} as const;

export const ActionCreators = {
  UNMOUNT_DATA_LOGGER: () => ({ type: ActionTypes.UNMOUNT_DATA_LOGGER }),
  CREATE_DATA_LOGGER_START: () => ({
    type: ActionTypes.CREATE_DATA_LOGGER_START,
  }),
  CREATE_DATA_LOGGER_RESPONSE: (
    dataLogger: Model.DataLoggerDecorated,
    nullReadingObsPoint: Model.ObservationPointDecorated | null,
    missedDownloadObsPoint: Model.ObservationPointDecorated | null
  ) => ({
    type: ActionTypes.CREATE_DATA_LOGGER_RESPONSE,
    payload: {
      dataLogger,
      nullReadingObsPoint,
      missedDownloadObsPoint,
    },
  }),
  CREATE_DATA_LOGGER_ERROR: (errors: string | DRFError<Model.DataLogger>) => ({
    type: ActionTypes.CREATE_DATA_LOGGER_ERROR,
    payload: errors,
  }),
  UPDATE_DATA_LOGGER_START: () => ({
    type: ActionTypes.UPDATE_DATA_LOGGER_START,
  }),
  UPDATE_DATA_LOGGER_ERROR: (errors: string | DRFError<Model.DataLogger>) => ({
    type: ActionTypes.UPDATE_DATA_LOGGER_ERROR,
    errors,
  }),
  UPDATE_DATA_LOGGER_RESPONSE: (
    dataLogger: Model.DataLoggerDecorated,
    nullReadingObsPoint: null | Model.ObservationPointDecorated,
    missedDownloadObsPoint: null | Model.ObservationPointDecorated
  ) => ({
    type: ActionTypes.UPDATE_DATA_LOGGER_RESPONSE,
    payload: {
      dataLogger,
      nullReadingObsPoint,
      missedDownloadObsPoint,
    },
  }),
  FETCH_DATA_LOGGER_START: (logger_number: number) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_START,
    logger_number,
  }),
  FETCH_DATA_LOGGER_RESPONSE: (
    logger_number: number,
    dataLogger: Model.DataLoggerDecorated,
    nullReadingObsPoint: Model.ObservationPointDecorated | null,
    missedDownloadObsPoint: Model.ObservationPointDecorated | null
  ) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_RESPONSE,
    logger_number,
    payload: {
      dataLogger,
      nullReadingObsPoint,
      missedDownloadObsPoint,
    },
  }),
  FETCH_DATA_LOGGER_ERROR: (logger_number: number, errorMessage: string) => ({
    type: ActionTypes.FETCH_DATA_LOGGER_ERROR,
    logger_number,
    payload: errorMessage,
  }),
};

export type DataLoggerDetailAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface DataLoggerDetailState {
  loading: boolean;
  errorLoading: null | string;
  isCreating: boolean;
  errorsCreating: null | string | DRFError<Model.DataLogger>;
  isUpdating: boolean;
  errorsUpdating: null | string | DRFError<Model.DataLogger>;
  dataLogger: null | Model.DataLoggerDecorated;
  dataLoggerNumber: null | number;
  nullReadingObsPoint: null | Model.ObservationPointDecorated;
  missedDownloadObsPoint: null | Model.ObservationPointDecorated;
}

function initialState(): DataLoggerDetailState {
  return {
    loading: false,
    errorLoading: null,
    isCreating: false,
    errorsCreating: null,
    isUpdating: false,
    errorsUpdating: null,
    dataLogger: null,
    dataLoggerNumber: null,
    nullReadingObsPoint: null,
    missedDownloadObsPoint: null,
  };
}

export function dataLoggerDetailReducer(
  state = initialState(),
  action: DataLoggerDetailAction
): DataLoggerDetailState {
  switch (action.type) {
    case ActionTypes.UNMOUNT_DATA_LOGGER:
      return initialState();

    case ActionTypes.FETCH_DATA_LOGGER_START: {
      return {
        ...state,
        dataLoggerNumber: action.logger_number,
        loading: true,
      };
    }
    case ActionTypes.FETCH_DATA_LOGGER_RESPONSE: {
      if (action.logger_number !== state.dataLoggerNumber) {
        return state;
      } else {
        return {
          ...state,
          dataLogger: action.payload.dataLogger,
          nullReadingObsPoint: action.payload.nullReadingObsPoint,
          missedDownloadObsPoint: action.payload.missedDownloadObsPoint,
          loading: false,
        };
      }
    }
    case ActionTypes.FETCH_DATA_LOGGER_ERROR: {
      if (action.logger_number !== state.dataLoggerNumber) {
        return state;
      } else {
        return {
          ...state,
          errorLoading: action.payload,
          loading: false,
        };
      }
    }

    case ActionTypes.CREATE_DATA_LOGGER_START:
      return {
        ...state,
        isCreating: true,
      };
    case ActionTypes.CREATE_DATA_LOGGER_RESPONSE:
      return {
        ...state,
        isCreating: false,
        dataLogger: action.payload.dataLogger,
        dataLoggerNumber: action.payload.dataLogger.logger_number,
        nullReadingObsPoint: action.payload.nullReadingObsPoint,
        missedDownloadObsPoint: action.payload.missedDownloadObsPoint,
        errorsCreating: null,
      };
    case ActionTypes.CREATE_DATA_LOGGER_ERROR:
      return {
        ...state,
        isCreating: false,
        errorsCreating: action.payload,
      };

    case ActionTypes.UPDATE_DATA_LOGGER_START:
      return {
        ...state,
        isUpdating: true,
      };
    case ActionTypes.UPDATE_DATA_LOGGER_RESPONSE:
      return {
        ...state,
        isUpdating: false,
        dataLogger: action.payload.dataLogger,
        dataLoggerNumber: action.payload.dataLogger.logger_number,
        nullReadingObsPoint: action.payload.nullReadingObsPoint,
        missedDownloadObsPoint: action.payload.missedDownloadObsPoint,
        errorsUpdating: null,
      };
    case ActionTypes.UPDATE_DATA_LOGGER_ERROR:
      return {
        ...state,
        isUpdating: false,
        errorsUpdating: action.errors,
      };

    default:
      return state;
  }
}

export const unmountDataLogger = ActionCreators.UNMOUNT_DATA_LOGGER;

/**
 * Fetch the details about a data logger, using either its logger number
 * or its ID.
 *
 * @param logger_number
 */
export function fetchDataLogger(logger_number: number): StandardThunk {
  return async (dispatch, _getState, { i18n }) => {
    dispatch(ActionCreators.FETCH_DATA_LOGGER_START(logger_number));
    try {
      const dataLoggers = await getApi('/data-loggers/', { logger_number });
      const dataLogger = dataLoggers[0];

      if (!dataLogger) {
        throw Error(i18n._(t`Data logger not found.`));
      }

      let nullReadingObsPoint: null | Model.ObservationPointDecorated;
      let missedDownloadObsPoint: null | Model.ObservationPointDecorated;
      if (dataLogger.null_reading_observation_point) {
        nullReadingObsPoint = await getApi(
          `/observation-points/${dataLogger.null_reading_observation_point}/`
        );
      } else {
        nullReadingObsPoint = null;
      }
      if (dataLogger.missed_download_observation_point) {
        missedDownloadObsPoint = await getApi(
          `/observation-points/${dataLogger.missed_download_observation_point}/`
        );
      } else {
        missedDownloadObsPoint = null;
      }
      return dispatch(
        ActionCreators.FETCH_DATA_LOGGER_RESPONSE(
          logger_number,
          dataLogger,
          nullReadingObsPoint,
          missedDownloadObsPoint
        )
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_DATA_LOGGER_ERROR(logger_number, e.message)
      );
    }
  };
}

export function createDataLogger(
  dataLogger: Model.DataLogger_POST
): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.CREATE_DATA_LOGGER_START());

    try {
      const newDataLogger = await postApi('/data-loggers/', dataLogger);

      let nullReadingObsPoint: null | Model.ObservationPointDecorated;
      let missedDownloadObsPoint: null | Model.ObservationPointDecorated;
      if (newDataLogger.null_reading_observation_point) {
        nullReadingObsPoint = await getApi(
          `/observation-points/${newDataLogger.null_reading_observation_point}/`
        );
      } else {
        nullReadingObsPoint = null;
      }
      if (newDataLogger.missed_download_observation_point) {
        missedDownloadObsPoint = await getApi(
          `/observation-points/${newDataLogger.missed_download_observation_point}/`
        );
      } else {
        missedDownloadObsPoint = null;
      }

      dispatch(
        ActionCreators.CREATE_DATA_LOGGER_RESPONSE(
          newDataLogger,
          nullReadingObsPoint,
          missedDownloadObsPoint
        )
      );
    } catch (e) {
      if (isDRFError<Model.DataLogger>(e)) {
        dispatch(ActionCreators.CREATE_DATA_LOGGER_ERROR(e));
      } else {
        dispatch(ActionCreators.CREATE_DATA_LOGGER_ERROR(e.message));
      }
    }
  };
}

export function updateDataLogger(
  id: number,
  dataLogger: ForPatch<Model.DataLogger>
): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.UPDATE_DATA_LOGGER_START());

    try {
      const newDataLogger = await patchApi(`/data-loggers/${id}/`, dataLogger);

      let nullReadingObsPoint: null | Model.ObservationPointDecorated;
      let missedDownloadObsPoint: null | Model.ObservationPointDecorated;
      if (newDataLogger.null_reading_observation_point) {
        nullReadingObsPoint = await getApi(
          `/observation-points/${newDataLogger.null_reading_observation_point}/`
        );
      } else {
        nullReadingObsPoint = null;
      }
      if (newDataLogger.missed_download_observation_point) {
        missedDownloadObsPoint = await getApi(
          `/observation-points/${newDataLogger.missed_download_observation_point}/`
        );
      } else {
        missedDownloadObsPoint = null;
      }

      return dispatch(
        ActionCreators.UPDATE_DATA_LOGGER_RESPONSE(
          newDataLogger,
          nullReadingObsPoint,
          missedDownloadObsPoint
        )
      );
    } catch (e) {
      let errMsg: string | DRFError<Model.DataLogger>;
      if (isDRFError<Model.DataLogger>(e)) {
        errMsg = e;
      } else {
        errMsg = e.message;
      }
      return dispatch(ActionCreators.UPDATE_DATA_LOGGER_ERROR(errMsg));
    }
  };
}
