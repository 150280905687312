import React from 'react';
import sortBy from 'lodash/sortBy';
import {
  AsyncSimpleSelectFieldProps,
  AsyncSimpleSelectField,
} from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import {
  LoadDefaultsFunc,
  OnSearchFunc,
} from 'components/base/form/asyncsimpleselect/asyncsimpleselect';
import { isNotNull } from 'util/validation';
import { getApi } from 'util/backendapi/fetch';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { Model } from 'util/backendapi/models/api.interfaces';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from '../async-menu/ObsPointMenu';

// To boost performance, we only ask the backend for these fields of each
// observation point.
const OBS_POINT_ITEM_FIELDS = [
  'id' as const,
  'code' as const,
  'instrument_type' as const,
  'time_zone' as const,
];
export type ObsPointItem = Pick<
  Model.ObservationPointDecorated,
  ArrayElements<typeof OBS_POINT_ITEM_FIELDS>
>;

/**
 * Menu item object, for an "observation points" reading series menu.
 * Decorated with the observation point object (if any)
 */
export interface ObsPointItemMenuOption extends SimpleSelectOption<string> {
  observationPoint: ObsPointItem | null;
}

/**
 * Makes a unique identifier for an observation point & instrument item, using
 * the observation point id and the instrument item number.
 *
 * NOTE: This is similar to the format we use in URLs, but with the obs point
 * ID rather than code.
 *
 * @param observation_point
 * @param item_number
 */
export function getObsPointItemIdent(
  observation_point: number,
  item_number: number
) {
  return `${observation_point}_${item_number}`;
}

/**
 * Split the identifier for an observation point & instrument item, into
 * the IDs.
 *
 * @param identifierStr
 */
export function splitObsPointItemIdent(
  identifierStr: string | null
): Model.ObservationPointItem | null {
  if (!identifierStr) {
    return null;
  }
  const parts = identifierStr.split('_');
  if (parts.length !== 2) {
    return null;
  }
  return { observation_point: +parts[0], item_number: +parts[1] };
}

/**
 * Make a menu item for one of an observation point's instrument items
 *
 * @param observationPoint
 * @param item_number
 */
export function makeObsPointItemMenuOption(
  observationPoints: ObsPointItem[],
  serie: Model.ObservationPointItem
): ObsPointItemMenuOption {
  const observationPoint = observationPoints.find(
    (op) => op.id === serie.observation_point
  );

  if (!observationPoint) {
    // No observation point with this code; could be the code was edited,
    // or the user lacks permissions to view that obs point, or it was a
    // typo. Just display a placeholder for it.
    return {
      value: getObsPointItemIdent(serie.observation_point, serie.item_number),
      label: `[OP ${serie.observation_point}] - [ITEM ${serie.item_number}]`,
      observationPoint: null,
    };
  }

  const instrumentItem = observationPoint.instrument_type.items.find(
    (i) => i.item_number === serie.item_number
  );

  if (!instrumentItem) {
    // If the obs point item number is invalid (perhaps because the obs point's
    // instrument has been changed), print a placeholder for it instead.
    return {
      value: getObsPointItemIdent(serie.observation_point, serie.item_number),
      label: `${observationPoint.code} - [ITEM ${serie.item_number}]`,
      observationPoint,
    };
  }

  return {
    value: getObsPointItemIdent(observationPoint.id, serie.item_number),
    label: `${observationPoint.code} - ${instrumentItem.description}`,
    observationPoint,
  };
}

type Props<IsMulti extends boolean> = Omit<
  AsyncSimpleSelectFieldProps<string, IsMulti, ObsPointItemMenuOption>,
  'loadDefaults' | 'onSearch' | 'filterOption'
> & {
  isMulti: IsMulti;
  filterOption?: (item: ObsPointItemMenuOption) => boolean;
};

export function ObsPointItemMenu<IsMulti extends boolean>(
  props: Props<IsMulti>
) {
  /**
   * Function to fetch the initial menu selections in the "observation points"
   * menu, based on the obs point item strings (from the URL)
   *
   * @param initialValues
   */
  const loadDefaultObsPointItems: LoadDefaultsFunc<
    string,
    IsMulti,
    ObsPointItemMenuOption
  > = React.useCallback(
    async (pInitialValues) => {
      const initialValues = (
        props.isMulti ? pInitialValues : [pInitialValues]
      ) as string[];

      const pairs = initialValues.map(splitObsPointItemIdent).filter(isNotNull);
      if (!pairs.length) {
        return [];
      }
      const obsPoints: ObsPointItem[] = await getApi('/observation-points/', {
        id__in: pairs.map((p) => p.observation_point),
        fields: OBS_POINT_ITEM_FIELDS,
      });

      // Make the return array by looping over the pairs, so we
      // can keep the correct order.
      return pairs.map((pair) => {
        return makeObsPointItemMenuOption(obsPoints, pair);
      });
    },
    [props.isMulti]
  );

  const { filterOption, ...otherProps } = props;

  /**
   * Function to asynchronously search for observation points matching the
   * user's input, in the observation points menu.
   * @param inputString
   */
  const onSearchObsPointItems: OnSearchFunc<string, ObsPointItemMenuOption> =
    React.useCallback(
      async (inputString) => {
        const obsPoints: ObsPointItem[] = sortBy(
          await getApi('/observation-points/', {
            code__icontains: inputString,
            fields: OBS_POINT_ITEM_FIELDS,
            limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
          }),
          (op) => op.code
        );
        const result = obsPoints.flatMap((op) =>
          sortBy(op.instrument_type.items, (item) => item.item_number).map(
            ({ item_number }) =>
              makeObsPointItemMenuOption(obsPoints, {
                observation_point: op.id,
                item_number,
              })
          )
        );
        if (filterOption) {
          return result.filter(filterOption);
        } else {
          return result;
        }
      },
      [filterOption]
    );

  return (
    <AsyncSimpleSelectField<string, IsMulti, ObsPointItemMenuOption>
      loadDefaults={loadDefaultObsPointItems}
      onSearch={onSearchObsPointItems}
      isClearable={false}
      {...otherProps}
    />
  );
}
