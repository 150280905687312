import { StandardThunk } from 'main/store';
import { getApi } from 'util/backendapi/fetch';
import { Model } from 'util/backendapi/models/api.interfaces';

export const ActionTypes = {
  CLEAR_OBS_DATE_RANGE_CACHE:
    'dms/firstlastdatesfilter/CLEAR_OBS_DATE_RANGE_CACHE',
  FETCH_OBS_DATE_RANGE:
    'dms/firstlastdatesfilter/FETCH_OBS_START_AND_END_DATE_START',
  FETCH_OBS_DATE_RANGE_RESPONSE:
    'dms/firstlastdatesfilter/FETCH_OBS_START_AND_END_DATE_RESPONSE',
  FETCH_OBS_DATE_RANGE_ERROR:
    'dms/firstlastdatesfilter/FETCH_OBS_START_AND_END_DATE_ERROR',
} as const;

export const ActionCreators = {
  CLEAR_OBS_DATE_RANGE_CACHE: function () {
    return {
      type: ActionTypes.CLEAR_OBS_DATE_RANGE_CACHE,
    };
  },
  FETCH_OBS_DATE_RANGE: function (
    allRequestedIds: number[],
    idsToFetch: number[]
  ) {
    return {
      type: ActionTypes.FETCH_OBS_DATE_RANGE,
      payload: {
        requestedIds: allRequestedIds,
        fetchingIds: idsToFetch,
      },
    };
  },
  FETCH_OBS_DATE_RANGE_RESPONSE: function (
    firstLastReadingsDatetimeByObs: ReadingDatesByObsPointId
  ) {
    return {
      type: ActionTypes.FETCH_OBS_DATE_RANGE_RESPONSE,
      payload: {
        firstLastReadingsDatetimeByObs,
      },
    };
  },
  FETCH_OBS_DATE_RANGE_ERROR: function (
    errorMessage: string,
    fetchingIds: number[]
  ) {
    return {
      type: ActionTypes.FETCH_OBS_DATE_RANGE_ERROR,
      error: true,
      payload: {
        message: errorMessage,
        fetchingIds,
      },
    };
  },
};

export type ReadingsDateRangeAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export type ReadingDatesByObsPointId = Record<
  number,
  {
    earliest_reading_datetime: string | null;
    latest_reading_datetime: string | null;
    timeZone: string;
  }
>;

export interface ReadingsDateRangeState {
  fetchingStartAndEndDatetimeFor: null | number[];
  errorMessage: string;
  firstLastReadingsDatetimeByObs: ReadingDatesByObsPointId;
}

export function readingsDateRangeInitialState(): ReadingsDateRangeState {
  return {
    fetchingStartAndEndDatetimeFor: null,
    firstLastReadingsDatetimeByObs: {},
    errorMessage: '',
  };
}

export function readingsDateRangeFilterReducer(
  state: ReadingsDateRangeState = readingsDateRangeInitialState(),
  action: ReadingsDateRangeAction
) {
  switch (action.type) {
    case ActionTypes.CLEAR_OBS_DATE_RANGE_CACHE:
      return readingsDateRangeInitialState();
    case ActionTypes.FETCH_OBS_DATE_RANGE: {
      // Add the items we're fetching to the list of current fetches.
      const newItems = action.payload.requestedIds.filter(
        (opId) =>
          state.fetchingStartAndEndDatetimeFor &&
          state.fetchingStartAndEndDatetimeFor.includes(opId)
      );
      if (newItems.length) {
        return {
          ...state,
          fetchingStartAndEndDatetimeFor: [
            ...(state.fetchingStartAndEndDatetimeFor || []),
            ...newItems,
          ],
          errorMessage: '',
        };
      } else {
        return {
          ...state,
          errorMessage: '',
        };
      }
    }
    case ActionTypes.FETCH_OBS_DATE_RANGE_RESPONSE: {
      const newFetchingIds = state.fetchingStartAndEndDatetimeFor
        ? state.fetchingStartAndEndDatetimeFor.filter(
            (opId) => !(opId in action.payload.firstLastReadingsDatetimeByObs)
          )
        : [];

      return {
        ...state,
        // Add the newly received data to the cache.
        firstLastReadingsDatetimeByObs: {
          ...state.firstLastReadingsDatetimeByObs,
          ...action.payload.firstLastReadingsDatetimeByObs,
        },
        // Remove it from the list of things we're currently fetching.
        fetchingStartAndEndDatetimeFor:
          newFetchingIds.length > 0 ? newFetchingIds : null,
      };
    }
    case ActionTypes.FETCH_OBS_DATE_RANGE_ERROR: {
      const newFetchingIds =
        state.fetchingStartAndEndDatetimeFor &&
        action.payload.fetchingIds.length > 0
          ? state.fetchingStartAndEndDatetimeFor.filter(
              (id) => !action.payload.fetchingIds.includes(id)
            )
          : [];
      return {
        ...state,
        errorMessage: action.payload.message,
        fetchingStartAndEndDatetimeFor:
          newFetchingIds.length > 0 ? newFetchingIds : null,
      };
    }
    default:
      return state;
  }
}

export const clearObsPointDateRangeCache =
  ActionCreators.CLEAR_OBS_DATE_RANGE_CACHE;

/**
 * Fetch the first and last readings datetimes for multiple observation points.
 *
 * We skip the fetch for observation points that we already know the firstReadingDate & lastReadingDate
 * (base on the data that we cache in the redux store)
 *
 * @export
 * @param {number[]} observationPointIds
 * @returns
 */
export function fetchObsPntDateRange(
  observationPointIds: number[]
): StandardThunk {
  return async function (dispatch, getState) {
    const alreadyFetching =
      getState().readingsDateRangeFilter.fetchingStartAndEndDatetimeFor;
    const obsToFetch = observationPointIds.filter(
      (obsPointId) =>
        !(
          obsPointId in
          getState().readingsDateRangeFilter.firstLastReadingsDatetimeByObs
        ) && !(alreadyFetching && alreadyFetching.includes(obsPointId))
    );

    if (!obsToFetch.length) {
      return;
    }

    dispatch(
      ActionCreators.FETCH_OBS_DATE_RANGE(observationPointIds, obsToFetch)
    );

    try {
      // Get the first/last readings dates for the needed observation points.
      const readingsData: Pick<
        Model.ObservationPointDecorated,
        'id' | 'earliest_reading' | 'latest_reading' | 'time_zone'
      >[] = await getApi('/observation-points/', {
        id__in: obsToFetch,
        fields: ['id', 'earliest_reading', 'latest_reading', 'time_zone'],
      });

      const newFirstLastDatesByObs = readingsData.reduce(
        (acc: ReadingDatesByObsPointId, obsPoint): ReadingDatesByObsPointId => {
          if (obsPoint.earliest_reading && obsPoint.latest_reading) {
            return {
              ...acc,
              [obsPoint.id]: {
                earliest_reading_datetime:
                  obsPoint.earliest_reading.reading_datetime,
                latest_reading_datetime:
                  obsPoint.latest_reading.reading_datetime,
                timeZone: obsPoint.time_zone.name,
              },
            };
          } else {
            return acc;
          }
        },
        getState().readingsDateRangeFilter.firstLastReadingsDatetimeByObs
      );

      dispatch(
        ActionCreators.FETCH_OBS_DATE_RANGE_RESPONSE(newFirstLastDatesByObs)
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_OBS_DATE_RANGE_ERROR(e.message, obsToFetch)
      );
    }
  };
}
