import React, { useMemo, useCallback } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import {
  MediaListView,
  mediaListColumns,
  mediaListFilters,
} from './MediaListView';
import { useReportState } from 'hooks/use-report-state';
import { mediaListDuck } from 'ducks/media/list';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';

type OwnProps = RCPWithQueryParams<Filter.ReportsPlotSets>;

export const MediaListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return mediaListColumns({
      refreshList: EMPTY_FUNC,
      deleteMedia: EMPTY_FUNC,
    });
  }, []);

  const deleteMedia = useCallback(async (id: number) => {
    return deleteApi(`/media/${id}/`);
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      mediaListFilters,
      mediaListDuck,
      (state) => state.media.list
    ),
    deleteMedia,
  };

  return <MediaListView {...props} />;
};
