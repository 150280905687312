import React from 'react';
import PageStandard from '../../../components/modules/pagestandard/pagestandard';
import { Trans, Plural, t } from '@lingui/macro';
import { ReportTable } from '../../../components/modules/report/table/ReportTable';
import SimpleSelect, {
  SimpleSelectOption,
} from '../../../components/base/form/simpleselect/simpleselect';
import AsyncSimpleSelect, {
  LoadDefaultsFunc,
  OnSearchFunc,
} from '../../../components/base/form/asyncsimpleselect/asyncsimpleselect';
import { EntityList, EntityTypes } from '../../../ducks/entities';
import { PaginationMeta } from '../../../util/backendapi/pagination';
import { Icon } from '../../../components/base/icon/icon';
import {
  FilterBlock,
  FilterControl,
} from 'components/modules/report/filter/FilterBlock';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import ActionBlock from 'components/base/actionblock/actionblock';
import { StoredListEditModal } from './add-edit-modal';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { DMSLink } from 'components/base/link/DMSLink';

export interface StoredListLandingViewProps {
  allAreas: EntityList<EntityTypes.AREA>;
  pagination: PaginationMeta | null;
  filtersInUrl: {
    area__in: number[];
    id__in: number[];
    type__in: string[];
    limit: number;
    offset: number;
  };
  filteredItems: Model.PolymorphicStoredList[];
  loading: boolean;
  errorMessage: string | null;
  onFilterChanged: (
    filterName: 'area__in' | 'id__in' | 'type__in',
    newValues: string[] | number[] | undefined | null
  ) => void;
  onClearFilters: () => void;
  loadDefaultListNames: LoadDefaultsFunc<number, true>;
  onSearchForListName: OnSearchFunc<number>;
  onSubmitCreate: (
    storedList: Model.PolymorphicStoredList_POST
  ) => Promise<any>;
  onSubmitUpdate: (
    id: number,
    storedList: Model.PolymorphicStoredList_PATCH
  ) => Promise<any>;
}

/**
 * The menu options for "list type". These are a hard-coded enum
 */
const listTypeOptions: SimpleSelectOption<string>[] = menuItemsFromEnum(
  'StoredList_TYPE',
  Object.values(Enum.StoredList_TYPE)
);

export class StoredListLandingView extends React.Component<StoredListLandingViewProps> {
  render() {
    const areaMenuItems: Array<
      SimpleSelectOption<number> & {
        timeZone: Model.TimeZone;
      }
    > = this.props.allAreas.allIds.map((i) => {
      const area = this.props.allAreas.byId[i];
      return {
        value: area.id,
        label: `${area.code} - ${area.name}`,
        timeZone: area.time_zone,
      };
    });
    return (
      <PageStandard
        name="stored-lists-table"
        header={<Trans>Maintain Stored Lists</Trans>}
      >
        <HasPermission check="can_create_stored_lists">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="create-stored-list"
              iconType="icon-plus"
              shortcut="CREATE_NEW"
              modalContent={({ hideModal }) => (
                <StoredListEditModal
                  areaMenuItems={areaMenuItems}
                  storedList={null}
                  onSubmit={this.props.onSubmitCreate}
                  hideModal={hideModal}
                />
              )}
            >
              <Trans>Create stored list</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>
        <FilterBlock>
          <FilterControl
            label={<Trans>Stored list name</Trans>}
            hasValues={this.props.filtersInUrl.id__in.length > 0}
          >
            <AsyncSimpleSelect<number, true>
              name="storedlist-landing-id__in"
              autoFocus={true}
              isMulti={true}
              isClearable={false}
              value={this.props.filtersInUrl.id__in}
              loadDefaults={this.props.loadDefaultListNames}
              onChange={(value) => this.props.onFilterChanged('id__in', value)}
              onSearch={this.props.onSearchForListName}
            />
          </FilterControl>
          <FilterControl
            label={<Trans>Area</Trans>}
            hasValues={this.props.filtersInUrl.area__in.length > 0}
          >
            <SimpleSelect<number, true>
              name="storedlist-landing-area__in"
              isClearable={false}
              isMulti={true}
              options={areaMenuItems}
              value={this.props.filtersInUrl.area__in}
              onChange={(value) =>
                this.props.onFilterChanged('area__in', value)
              }
            />
          </FilterControl>
          <FilterControl
            label={<Trans>Stored list types</Trans>}
            hasValues={this.props.filtersInUrl.type__in.length > 0}
          >
            <SimpleSelect<string, true>
              name="storedlist-landing-type__in"
              isClearable={false}
              isMulti={true}
              options={listTypeOptions}
              value={this.props.filtersInUrl.type__in}
              onChange={(value) =>
                this.props.onFilterChanged('type__in', value)
              }
            />
          </FilterControl>
        </FilterBlock>
        <div className="filtered-table-wrapper">
          <ReportTable
            errorMessage={this.props.errorMessage}
            hasActiveFilters={
              this.props.filtersInUrl.area__in.length +
                this.props.filtersInUrl.id__in.length +
                this.props.filtersInUrl.type__in.length >
              0
            }
            isLoading={this.props.loading}
            msgFilterCount={(count) => (
              <Plural
                value={count}
                one="Filtered to 1 stored list"
                other="Filtered to # stored lists"
              />
            )}
            msgNoMatches={
              <Trans>No stored lists match the selected filters.</Trans>
            }
            onClearFilters={this.props.onClearFilters}
            pagination={this.props.pagination}
            renderTable={({ alternateTableBody }) => (
              <div className="table-responsive">
                <table id="Stored-lists-table">
                  <thead>
                    <tr>
                      <th>
                        <Trans>List name</Trans>
                      </th>
                      <th>
                        <Trans>Area</Trans>
                      </th>
                      <th>
                        <Trans>List type</Trans>
                      </th>
                      <th>
                        <Trans>Description</Trans>
                      </th>
                      <th className="action-icons">
                        <Trans>Actions</Trans>
                      </th>
                    </tr>
                  </thead>
                  {alternateTableBody && <>{alternateTableBody}</>}
                  {!alternateTableBody && (
                    <tbody>
                      {this.props.filteredItems.map((storedList) => {
                        const area = this.props.allAreas.byId[storedList.area];
                        return (
                          <tr key={storedList.id}>
                            <td>
                              <DMSLink to={`/stored-lists/${storedList.id}`}>
                                {storedList.name}
                              </DMSLink>
                            </td>
                            <td>
                              {area
                                ? `${area.code} - ${area.name}`
                                : `[${storedList.area}]`}
                            </td>
                            <td>
                              <TransEnum
                                enum="StoredList_TYPE"
                                value={storedList.type}
                              />
                            </td>
                            <td>{storedList.description}</td>
                            <td className="action-icons">
                              <DMSLink to={`/stored-lists/${storedList.id}`}>
                                <Icon
                                  type="icon-list"
                                  title={t`View stored list`}
                                />
                              </DMSLink>
                              <HasPermission check="can_create_stored_lists">
                                <DMSLink
                                  to={`/stored-lists/${storedList.id}/edit`}
                                >
                                  <Icon
                                    type="icon-cog"
                                    title={t`Maintain stored list`}
                                  />
                                </DMSLink>
                                <ButtonShowModal
                                  name="edit-stored-list"
                                  title={t`Edit`}
                                  iconType="icon-edit"
                                  iconOnly={true}
                                  className="btn-link-panel"
                                  modalContent={({ hideModal }) => (
                                    <StoredListEditModal
                                      areaMenuItems={areaMenuItems}
                                      storedList={storedList}
                                      onSubmit={(value) =>
                                        this.props.onSubmitUpdate(
                                          storedList.id,
                                          value
                                        )
                                      }
                                      hideModal={hideModal}
                                    />
                                  )}
                                >
                                  <Trans>Edit</Trans>
                                </ButtonShowModal>
                              </HasPermission>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                </table>
              </div>
            )}
          />
        </div>
      </PageStandard>
    );
  }
}
