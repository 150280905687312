import { saveAs } from 'file-saver';
import { backendUrl, withAuthHeaders } from './fetch';

export async function downloadFile(endpoint: string) {
  const downloadURL = backendUrl(endpoint);
  const response = await fetch(downloadURL, withAuthHeaders());
  if (response && response.ok) {
    if (response.headers && response.headers.get('Content-Disposition')) {
      const saveAsFileName = response
        .headers!.get('Content-Disposition')!
        .split('"')[1];
      saveAs(await response.blob(), saveAsFileName);
    }
  }
}
