import React from 'react';
import lodashSet from 'lodash/set';
import difference from 'lodash/difference';
import sortBy from 'lodash/sortBy';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { StoredSpatialCrossSectionPlotWithArea } from 'ducks/stored-plot/detail';
import {
  makeStoredPlotNameSectionInitialValues,
  StoredPlotNameSection,
  validateStoredPlotNameSection,
} from './sections/StoredPlotNameSection';
import {
  Formik,
  Form,
  FormikErrors,
  Field,
  FieldArray,
  FormikProps,
  FieldArrayRenderProps,
} from 'formik';
import {
  FormCard,
  FormCardSection,
  CardSectionField,
  CardSectionRow,
} from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { StoredPlotSaveCancelButtons } from './StoredPlotSaveCancelButtons';
import ActionBlock from 'components/base/actionblock/actionblock';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { NewStoredPlotDefaults } from './stored-plot-edit-utils';
import { UnitOfMeasurementField } from 'components/base/form/unitofmeasurement-field/UnitOfMeasurementField';
import { parseFilenameFromUrl } from 'util/backendapi/file';
import { StoredSpatialPlotObservationPoint } from 'util/backendapi/types/Model';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { isNotNull, validateNumber } from 'util/validation';
import {
  StoredSpatialPlotObservationPointListSection,
  StoredSpatialPlotFormValues,
} from './sections/StoredSpatialPlotObservationPointListSection';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import {
  ToggleField,
  ShowHideToggleField,
} from 'components/base/form/toggle-field/ToggleField';
import { formatDatetimeForBackendapi } from 'util/dates';
import { StoredSpatialLayersSection } from './sections/StoredSpatialLayersSection';
import { StoredSpatialDimensionsSection } from './sections/StoredSpatialDimensionsSection';
import {
  StoredPlotSaveAsValues,
  makeStoredPlotSaveAsInitialValues,
  validateStoredPlotSaveAsValues,
  StoredPlotSaveAsSettings,
  getStoredPlotSaveAsSettings,
} from './StoredPlotSaveAsModal';
import { showStoredPlotErrorsInFormik } from './StoredPlotEdit';
import Button from 'components/base/button/button';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';

type StoredSpatialPlotObservationFormValues = Merge<
  StoredSpatialPlotObservationPoint,
  {
    selectedOptions?: SimpleSelectOption<number>[];
    use_specific_datetime: boolean;
  }
>;

export type StoredSpatialCrossSectionPlotFormValues = StoredPlotSaveAsValues &
  Merge<
    Omit<Model.StoredSpatialCrossSectionPlot_POST, 'bearing_wgs84_coordinates'>,
    {
      scale: string;
      observation_points: StoredSpatialPlotObservationFormValues[];
      bearing_longitude: string;
      bearing_latitude: string;
      use_specific_datetime: boolean;
      show_lake_level: boolean;
      show_tailwater_level: boolean;
      show_scale_rule: boolean;
    }
  >;

export interface StoredSpatialCrossSectionPlotFormProps {
  storedPlot: StoredSpatialCrossSectionPlotWithArea | null;
  newPlotDefaults: NewStoredPlotDefaults | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  onSubmit: (
    values: Model.StoredSpatialCrossSectionPlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<Model.StoredSpatialCrossSectionPlot>;
  onCancel: () => void;
}

function makeInitialValues(
  storedPlot: StoredSpatialCrossSectionPlotWithArea | null,
  newPlotDefaults: NewStoredPlotDefaults | null
): StoredSpatialCrossSectionPlotFormValues {
  if (!storedPlot) {
    // Create an empty one
    // Not all fields have values initially
    return {
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.SPATIAL_CROSS_SECTION,
      name: newPlotDefaults?.name ?? '',
      title: '',
      description: '',
      area: 0,
      observation_points: [
        {
          observation_point: 0,
          label: '',
          use_specific_datetime: false,
          reading_datetime: null,
          show_label: true,
          show_in_data_table: true,
          vertical_offset: '0',
          horizontal_offset: '0',
        },
      ],
      scale: '',
      base_layer_image: '',
      additional_layer_image: '',
      base_layer_image_file: null,
      additional_layer_image_file: null,
      paperspace_width: '',
      paperspace_height: '',
      base_observation_point: 0,
      base_paperspace_x: '',
      base_paperspace_y: '',
      cross_section_bearing: 'observation_point',
      bearing_observation_point: null,
      bearing_latitude: '',
      bearing_longitude: '',
      use_specific_datetime: false,
      default_reading_datetime: null,
      show_data_table: true,
      data_table_paperspace_x: '',
      data_table_paperspace_y: '',
      show_lake_level: true,
      lake_level_line_start_x: '',
      lake_level_line_end_x: '',
      lake_level_observation_point: null,
      show_tailwater_level: true,
      tailwater_level_line_start_x: '',
      tailwater_level_line_end_x: '',
      tailwater_level_observation_point: null,
      show_scale_rule: false,
      show_connection_lines: false,
      connection_groups: [],
      y_axis_scale_min: '',
      y_axis_scale_max: '',
    };
  }

  const storedPlotValues: StoredSpatialCrossSectionPlotFormValues = {
    ...makeStoredPlotNameSectionInitialValues(storedPlot),
    ...makeStoredPlotSaveAsInitialValues(),
    plot_type: Enum.PlotType.SPATIAL_CROSS_SECTION,
    observation_points: storedPlot.observation_points.map((op) => ({
      ...op,
      use_specific_datetime: Boolean(op.reading_datetime),
      vertical_offset: op.vertical_offset,
      horizontal_offset: op.horizontal_offset,
    })),
    base_layer_image: parseFilenameFromUrl(storedPlot.base_layer_image),
    additional_layer_image: parseFilenameFromUrl(
      storedPlot.additional_layer_image ?? ''
    ),
    base_layer_image_file: null,
    additional_layer_image_file: null,
    scale: String(storedPlot.scale),
    paperspace_width: storedPlot.paperspace_width,
    paperspace_height: storedPlot.paperspace_height,
    base_observation_point: storedPlot.base_observation_point,
    base_paperspace_x: storedPlot.base_paperspace_x,
    base_paperspace_y: storedPlot.base_paperspace_y,
    cross_section_bearing: storedPlot.cross_section_bearing,
    bearing_observation_point: storedPlot.bearing_observation_point,
    bearing_latitude: String(storedPlot.bearing_wgs84_coordinates?.lat ?? ''),
    bearing_longitude: String(storedPlot.bearing_wgs84_coordinates?.lng ?? ''),
    use_specific_datetime: Boolean(storedPlot.default_reading_datetime),
    default_reading_datetime: storedPlot.default_reading_datetime,
    show_data_table: storedPlot.show_data_table,
    data_table_paperspace_x: storedPlot.data_table_paperspace_x,
    data_table_paperspace_y: storedPlot.data_table_paperspace_y,
    show_lake_level: Boolean(storedPlot.lake_level_observation_point),
    lake_level_line_start_x: storedPlot.lake_level_line_start_x ?? '',
    lake_level_line_end_x: storedPlot.lake_level_line_end_x ?? '',
    lake_level_observation_point: storedPlot.lake_level_observation_point,
    show_tailwater_level: Boolean(storedPlot.tailwater_level_observation_point),
    tailwater_level_line_start_x: storedPlot.tailwater_level_line_start_x ?? '',
    tailwater_level_line_end_x: storedPlot.tailwater_level_line_end_x ?? '',
    tailwater_level_observation_point:
      storedPlot.tailwater_level_observation_point,
    show_scale_rule: Boolean(
      storedPlot.scale_rule_x || storedPlot.scale_rule_y
    ),
    scale_rule_x: storedPlot.scale_rule_x ?? '',
    scale_rule_y: storedPlot.scale_rule_y ?? '',
    show_connection_lines: storedPlot.show_connection_lines,
    connection_groups: storedPlot.connection_groups,
    y_axis_scale_min: storedPlot.y_axis_scale_min ?? '',
    y_axis_scale_max: storedPlot.y_axis_scale_max ?? '',
  };

  return storedPlotValues;
}

function validateForm(
  values: StoredSpatialCrossSectionPlotFormValues
): FormikErrors<StoredSpatialCrossSectionPlotFormValues> {
  let errors: FormikErrors<StoredSpatialCrossSectionPlotFormValues> = {
    ...validateStoredPlotNameSection(values),
    ...validateStoredPlotSaveAsValues(values),
  };

  if (values.use_specific_datetime) {
    if (!values.default_reading_datetime) {
      errors.default_reading_datetime = (
        <Trans>Default reading date required.</Trans>
      ) as any;
    }
  }

  if (!values.base_layer_image) {
    errors.base_layer_image = (<Trans>Base image is required.</Trans>) as any;
  }

  let valid_base_observation_point = false;
  let valid_bearing_observation_point = false;
  let valid_lake_level_observation_point = false;
  let valid_tailwater_level_observation_point = false;
  values.observation_points.forEach(function (observation_point, idx) {
    if (observation_point.observation_point === values.base_observation_point) {
      valid_base_observation_point = true;
    }
    if (
      observation_point.observation_point === values.bearing_observation_point
    ) {
      valid_bearing_observation_point = true;
    }
    if (
      observation_point.observation_point ===
      values.lake_level_observation_point
    ) {
      valid_lake_level_observation_point = true;
    }
    if (
      observation_point.observation_point ===
      values.tailwater_level_observation_point
    ) {
      valid_tailwater_level_observation_point = true;
    }
    if (!observation_point.observation_point) {
      lodashSet(
        errors,
        ['observation_points', idx, 'observation_point'],
        <Trans>Observation point is required.</Trans>
      );
    }
    if (observation_point.label === '') {
      lodashSet(
        errors,
        ['observation_points', idx, 'label'],
        <Trans>Label is required.</Trans>
      );
    }
    if (observation_point.use_specific_datetime) {
      if (!observation_point.reading_datetime) {
        lodashSet(
          errors,
          ['observation_points', idx, 'reading_datetime'],
          <Trans>Reading date is required.</Trans>
        );
      }
    }
  });

  if (values.base_observation_point && !valid_base_observation_point) {
    errors.base_observation_point = (
      <Trans>Invalid base observation point selected.</Trans>
    ) as any;
  }

  if (values.show_lake_level) {
    if (!values.lake_level_observation_point) {
      errors.lake_level_observation_point = (
        <Trans>Lake level observation point is required.</Trans>
      ) as any;
    } else if (!valid_lake_level_observation_point) {
      errors.lake_level_observation_point = (
        <Trans>Invalid lake level observation point selected.</Trans>
      ) as any;
    }

    if (!values.lake_level_line_start_x) {
      errors.lake_level_line_start_x = (
        <Trans>Lake level line start X axis is required.</Trans>
      ) as any;
    }
    if (!values.lake_level_line_end_x) {
      errors.lake_level_line_end_x = (
        <Trans>Lake level line end X axis is required.</Trans>
      ) as any;
    }
  }

  if (values.show_tailwater_level) {
    if (!values.tailwater_level_observation_point) {
      errors.tailwater_level_observation_point = (
        <Trans>Tail water level observation point is required.</Trans>
      ) as any;
    } else if (!valid_tailwater_level_observation_point) {
      errors.tailwater_level_observation_point = (
        <Trans>Invalid tail water level observation point selected.</Trans>
      ) as any;
    }

    if (!values.tailwater_level_line_start_x) {
      errors.tailwater_level_line_start_x = (
        <Trans>Tailwater level line start X axis is required.</Trans>
      ) as any;
    }
    if (!values.tailwater_level_line_end_x) {
      errors.tailwater_level_line_end_x = (
        <Trans>Tailwater level line end X axis is required.</Trans>
      ) as any;
    }
  }

  if (values.show_scale_rule) {
    if (!values.scale_rule_x) {
      errors.scale_rule_x = (
        <Trans>Scale rule X axis paperspace location is required.</Trans>
      ) as any;
    }
    if (!values.scale_rule_y) {
      errors.scale_rule_y = (
        <Trans>Scale rule Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  if (values.cross_section_bearing === 'observation_point') {
    if (!values.bearing_observation_point) {
      errors.bearing_observation_point = (
        <Trans>Bearing observation point is required.</Trans>
      ) as any;
    } else if (!valid_bearing_observation_point) {
      errors.bearing_observation_point = (
        <Trans>Invalid bearing observation point selected.</Trans>
      ) as any;
    }
  } else if (values.cross_section_bearing === 'wgs84_coordinates') {
    if (!values.bearing_latitude) {
      errors.bearing_latitude = (
        <Trans>Bearing latitude is required.</Trans>
      ) as any;
    } else if (!validateNumber(values.bearing_latitude)) {
      errors.bearing_latitude = (
        <Trans>Invalid bearing latitude value.</Trans>
      ) as any;
    }

    if (!values.bearing_longitude) {
      errors.bearing_longitude = (
        <Trans>Bearing longitude is required.</Trans>
      ) as any;
    } else if (!validateNumber(values.bearing_longitude)) {
      errors.bearing_longitude = (
        <Trans>Invalid bearing longitude value.</Trans>
      ) as any;
    }
  }

  if (values.show_data_table) {
    if (!values.data_table_paperspace_x) {
      errors.data_table_paperspace_x = (
        <Trans>Data table X axis paperspace location is required.</Trans>
      ) as any;
    }

    if (!values.data_table_paperspace_y) {
      errors.data_table_paperspace_y = (
        <Trans>Data table Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  if (values.show_connection_lines) {
    values.connection_groups.forEach((group, idx) => {
      if (group.observation_points.length < 2) {
        lodashSet(
          errors,
          ['connection_groups', idx],
          <Trans>
            Each group must contain at least two observation points.
          </Trans>
        );
      }
    });
  }

  const isYAxisMinSupplied = Boolean(values.y_axis_scale_min);
  const isYAxisMinValid =
    isYAxisMinSupplied && validateNumber(values.y_axis_scale_min);
  const isYAxisMaxSupplied = Boolean(values.y_axis_scale_max);
  const isYAxisMaxValid =
    isYAxisMaxSupplied && validateNumber(values.y_axis_scale_max);
  if (
    isYAxisMinValid &&
    isYAxisMaxValid &&
    +values.y_axis_scale_min! >= +values.y_axis_scale_max!
  ) {
    errors.y_axis_scale_min = (<Trans>Min must be less than Max</Trans>) as any;
  } else {
    if (
      (isYAxisMinSupplied && !isYAxisMinValid) ||
      (isYAxisMaxSupplied && !isYAxisMinSupplied)
    ) {
      errors.y_axis_scale_min = (
        <Trans>
          Minimum and maximum must either be both a number or both empty.
        </Trans>
      ) as any;
    }
    if (
      (isYAxisMaxSupplied && !isYAxisMaxValid) ||
      (isYAxisMinSupplied && !isYAxisMaxSupplied)
    ) {
      errors.y_axis_scale_max = (
        <Trans>
          Minimum and maximum must either be both a number or both empty.
        </Trans>
      ) as any;
    }
  }

  const requiredFieldLabels = {
    scale: <Trans>Scale</Trans>,
    paperspace_width: <Trans>Paperspace width (X axis)</Trans>,
    paperspace_height: <Trans>Paperspace height (Y axis)</Trans>,
    base_observation_point: <Trans>Base observation point</Trans>,
    base_paperspace_x: <Trans>Base obs pt X axis paperspace location</Trans>,
    base_paperspace_y: <Trans>Base obs pt Y axis paperspace location</Trans>,
    cross_section_bearing: <Trans>Cross section bearing</Trans>,
  } as const;

  Object.entries(requiredFieldLabels).forEach(function ([fieldName, label]) {
    if (!values[fieldName]) {
      errors[fieldName] = (<Trans>{label} is required.</Trans>) as any;
    }
  });

  return errors;
}

export function StoredSpatialCrossSectionPlotForm(
  props: StoredSpatialCrossSectionPlotFormProps
) {
  const { storedPlot, areaOptions, newPlotDefaults } = props;

  const initialValues = makeInitialValues(storedPlot, newPlotDefaults);

  return (
    <Formik<StoredSpatialCrossSectionPlotFormValues>
      validate={validateForm}
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        const valuesForBackend: Model.StoredSpatialCrossSectionPlot_POST = {
          ...(values as StoredSpatialCrossSectionPlotFormValues),
          scale: +values.scale,
          default_reading_datetime: values.use_specific_datetime
            ? formatDatetimeForBackendapi(values.default_reading_datetime!)
            : null,
          observation_points: values.observation_points!.map(
            ({
              selectedOptions,
              use_specific_datetime,
              reading_datetime,
              ...rest
            }) => ({
              ...rest,
              reading_datetime: use_specific_datetime
                ? formatDatetimeForBackendapi(reading_datetime!)
                : null,
            })
          ),
          bearing_observation_point:
            values.cross_section_bearing === 'observation_point'
              ? values.bearing_observation_point
              : null,
          bearing_wgs84_coordinates:
            values.cross_section_bearing === 'wgs84_coordinates'
              ? {
                  lat: Number(values.bearing_latitude),
                  lng: Number(values.bearing_longitude),
                }
              : null,
          data_table_paperspace_x: values.show_data_table
            ? values.data_table_paperspace_x
            : null,
          data_table_paperspace_y: values.show_data_table
            ? values.data_table_paperspace_y
            : null,
          lake_level_observation_point: values.show_lake_level
            ? values.lake_level_observation_point
            : null,
          lake_level_line_start_x: values.show_lake_level
            ? values.lake_level_line_start_x
            : null,
          lake_level_line_end_x: values.show_lake_level
            ? values.lake_level_line_end_x
            : null,
          tailwater_level_observation_point: values.show_tailwater_level
            ? values.tailwater_level_observation_point
            : null,
          tailwater_level_line_start_x: values.show_tailwater_level
            ? values.tailwater_level_line_start_x
            : null,
          tailwater_level_line_end_x: values.show_tailwater_level
            ? values.tailwater_level_line_end_x
            : null,
          scale_rule_x: values.show_scale_rule ? values.scale_rule_x : null,
          scale_rule_y: values.show_scale_rule ? values.scale_rule_y : null,
          connection_groups: values.show_connection_lines
            ? values.connection_groups
            : [],
          y_axis_scale_min: values.y_axis_scale_min || null,
          y_axis_scale_max: values.y_axis_scale_max || null,
        };
        try {
          await props.onSubmit(
            valuesForBackend,
            getStoredPlotSaveAsSettings(values)
          );
        } catch (e) {
          formik.setSubmitting(false);
          showStoredPlotErrorsInFormik(formik, e, values);
        }
      }}
    >
      {(formik) => {
        const area = areaOptions.find((a) => a.value === formik.values.area);
        const timeZone = area?.timeZone;

        return (
          <Form>
            {formik.status}
            <FormCard
              name="general"
              header={<Trans>General</Trans>}
              subHeader={
                <StoredPlotSaveCancelButtons {...props} formik={formik} />
              }
            >
              <StoredPlotNameSection
                areaOptions={areaOptions}
                plotType={Enum.PlotType.SPATIAL_CROSS_SECTION}
              />
              <StoredSpatialLayersSection />
              <FormCardSection
                name="reading-dates"
                header={<Trans>Reading dates</Trans>}
                fields={[
                  {
                    name: 'use_specific_datetime',
                    label: <Trans>Default date</Trans>,
                    content: (
                      <ToggleField
                        name="use_specific_datetime"
                        options={[
                          {
                            label: <Trans>Latest reading</Trans>,
                            value: false,
                          },
                          {
                            label: <Trans>Nearest before...</Trans>,
                            value: true,
                          },
                        ]}
                      />
                    ),
                  },
                  formik.values.use_specific_datetime
                    ? {
                        name: 'default_reading_datetime',
                        label: <Trans>Default reading date</Trans>,
                        content: (
                          <>
                            <DatetimeField
                              name="default_reading_datetime"
                              timeZone={timeZone}
                            />
                            <FieldError name="default_reading_datetime" />
                          </>
                        ),
                      }
                    : null,
                ].filter(isNotNull)}
              />
              <StoredSpatialPlotObservationPointListSection
                formik={formik as FormikProps<StoredSpatialPlotFormValues>}
                timeZone={timeZone}
                requireObservationPoint={true}
              />
              <StoredSpatialDimensionsSection />
              <FormCardSection
                name="reference-point"
                header={<Trans>Reference point</Trans>}
                fields={[
                  {
                    name: 'base_observation_point',
                    label: <Trans>Base observation point</Trans>,
                    content: (
                      <>
                        <SimpleSelectField<number>
                          name="base_observation_point"
                          isMulti={false}
                          isDisabled={
                            !formik.values.observation_points[0]
                              ?.observation_point
                          }
                          options={formik.values.observation_points
                            .map(({ selectedOptions }) =>
                              selectedOptions?.[0]
                                ? {
                                    label: selectedOptions[0].label,
                                    value: selectedOptions[0].value,
                                  }
                                : null
                            )
                            .filter(isNotNull)}
                        />
                        <FieldError name="base_observation_point" />
                      </>
                    ),
                  },
                  {
                    name: 'base_x_axis_paperspace_location',
                    label: (
                      <Trans>Base obs pt X axis paperspace location</Trans>
                    ),
                    content: (
                      <>
                        <UnitOfMeasurementField
                          name="base_paperspace_x"
                          unit="mm"
                        />
                        <FieldError name="base_paperspace_x" />
                      </>
                    ),
                  },
                  {
                    name: 'base_paperspace_y',
                    label: (
                      <Trans>Base obs pt Y axis paperspace location</Trans>
                    ),
                    content: (
                      <>
                        <UnitOfMeasurementField
                          name="base_paperspace_y"
                          unit="mm"
                        />
                        <FieldError name="base_paperspace_y" />
                      </>
                    ),
                  },
                  {
                    name: 'cross_section_bearing',
                    label: <Trans>Cross-section bearing</Trans>,
                    content: (
                      <>
                        <SimpleSelectField<string>
                          name="cross_section_bearing"
                          isMulti={false}
                          options={[
                            {
                              label: 'Observation point',
                              value: 'observation_point',
                            },
                            {
                              label: 'Enter Latitude/Longitude',
                              value: 'wgs84_coordinates',
                            },
                          ]}
                        />
                        <FieldError name="cross_section_bearing" />
                      </>
                    ),
                  },
                  formik.values.cross_section_bearing === 'observation_point'
                    ? {
                        name: 'bearing_observation_point',
                        label: <Trans>Bearings observation point</Trans>,
                        content: (
                          <>
                            <SimpleSelectField<number>
                              name="bearing_observation_point"
                              isMulti={false}
                              maxMenuHeight={100} // prevent from extending below bottom of page
                              options={formik.values.observation_points
                                .map(({ selectedOptions }) =>
                                  selectedOptions?.[0]
                                    ? {
                                        label: selectedOptions[0].label,
                                        value: selectedOptions[0].value,
                                      }
                                    : null
                                )
                                .filter(isNotNull)}
                            />
                            <FieldError name="bearing_observation_point" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.cross_section_bearing === 'wgs84_coordinates'
                    ? {
                        name: 'bearing_latitude',
                        label: <Trans>Bearing latitude</Trans>,
                        content: (
                          <>
                            <Field name="bearing_latitude" />
                            <FieldError name="bearing_latitude" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.cross_section_bearing === 'wgs84_coordinates'
                    ? {
                        name: 'bearing_longitude',
                        label: <Trans>Bearing longitude</Trans>,
                        content: (
                          <>
                            <Field name="bearing_longitude" />
                            <FieldError name="bearing_longitude" />
                          </>
                        ),
                      }
                    : null,
                ].filter(isNotNull)}
              />
              <FieldArray name="connection_groups">
                {(arrayHelpers) => (
                  <FormCardSection
                    name="connections"
                    header={<Trans>Adjacent piezo connections</Trans>}
                    fields={[
                      {
                        name: 'show_connection_lines',
                        label: <Trans>Connection lines</Trans>,
                        content: (
                          <>
                            <ShowHideToggleField name="show_connection_lines" />
                            <FieldError name="show_connection_lines" />
                            <ConnectionGroupsChangeEffect formik={formik} />
                          </>
                        ),
                      },
                      ...makeConnectionGroupsSubsection(formik, arrayHelpers),
                    ]}
                  />
                )}
              </FieldArray>
              <FormCardSection
                name="display-options"
                header={<Trans>Display options</Trans>}
                fields={[
                  {
                    name: 'show_data_table',
                    label: <Trans>Data table</Trans>,
                    content: (
                      <>
                        <ShowHideToggleField name="show_data_table" />
                        <FieldError name="show_data_table" />
                        {!formik.values.show_data_table && (
                          <>
                            <FieldError name="data_table_paperspace_x" />
                            <FieldError name="data_table_paperspace_y" />
                          </>
                        )}
                      </>
                    ),
                  },
                  formik.values.show_data_table
                    ? {
                        name: 'data_table_paperspace_x',
                        label: (
                          <Trans>Data table X axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="data_table_paperspace_x"
                              unit="mm"
                            />
                            <FieldError name="data_table_paperspace_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_data_table
                    ? {
                        name: 'data_table_paperspace_y',
                        label: (
                          <Trans>Data table Y axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="data_table_paperspace_y"
                              unit="mm"
                            />
                            <FieldError name="data_table_paperspace_y" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'show_lake_level',
                    label: <Trans>Lake level</Trans>,
                    content: <ShowHideToggleField name="show_lake_level" />,
                  },
                  formik.values.show_lake_level
                    ? {
                        name: 'lake_level_observation_point',
                        label: <Trans>Lake level observation point</Trans>,
                        content: (
                          <>
                            <SimpleSelectField<number>
                              name="lake_level_observation_point"
                              isMulti={false}
                              isDisabled={
                                !formik.values.observation_points[0]
                                  ?.observation_point
                              }
                              options={formik.values.observation_points
                                .map(({ selectedOptions }) =>
                                  selectedOptions?.[0]
                                    ? {
                                        label: selectedOptions[0].label,
                                        value: selectedOptions[0].value,
                                      }
                                    : null
                                )
                                .filter(isNotNull)}
                            />
                            <FieldError name="lake_level_observation_point" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_lake_level
                    ? {
                        name: 'lake_level_line_start_x',
                        label: <Trans>Lake level line start X axis</Trans>,
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="lake_level_line_start_x"
                              unit="mm"
                            />
                            <FieldError name="lake_level_line_start_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_lake_level
                    ? {
                        name: 'lake_level_line_end_x',
                        label: <Trans>Lake level line end X axis</Trans>,
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="lake_level_line_end_x"
                              unit="mm"
                            />
                            <FieldError name="lake_level_line_end_x" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'show_tailwater_level',
                    label: <Trans>Tail Water level</Trans>,
                    content: (
                      <ShowHideToggleField name="show_tailwater_level" />
                    ),
                  },
                  formik.values.show_tailwater_level
                    ? {
                        name: 'tailwater_level_observation_point',
                        label: (
                          <Trans>Tail water level observation point</Trans>
                        ),
                        content: (
                          <>
                            <SimpleSelectField<number>
                              name="tailwater_level_observation_point"
                              isMulti={false}
                              isDisabled={
                                !formik.values.observation_points[0]
                                  ?.observation_point
                              }
                              options={formik.values.observation_points
                                .map(({ selectedOptions }) =>
                                  selectedOptions?.[0]
                                    ? {
                                        label: selectedOptions[0].label,
                                        value: selectedOptions[0].value,
                                      }
                                    : null
                                )
                                .filter(isNotNull)}
                            />
                            <FieldError name="tailwater_level_observation_point" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_tailwater_level
                    ? {
                        name: 'tailwater_level_line_start_x',
                        label: (
                          <Trans>Tail water level line start X axis</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="tailwater_level_line_start_x"
                              unit="mm"
                            />
                            <FieldError name="tailwater_level_line_start_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_tailwater_level
                    ? {
                        name: 'tailwater_level_line_end_x',
                        label: <Trans>Tail water level line end X axis</Trans>,
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="tailwater_level_line_end_x"
                              unit="mm"
                            />
                            <FieldError name="tailwater_level_line_end_x" />
                          </>
                        ),
                      }
                    : null,
                  {
                    name: 'show_scale_rule',
                    label: <Trans>Scale rule</Trans>,
                    content: <ShowHideToggleField name="show_scale_rule" />,
                  },
                  formik.values.show_scale_rule
                    ? {
                        name: 'scale_rule_x',
                        label: (
                          <Trans>Scale rule X axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="scale_rule_x"
                              unit="mm"
                            />
                            <FieldError name="scale_rule_x" />
                          </>
                        ),
                      }
                    : null,
                  formik.values.show_scale_rule
                    ? {
                        name: 'scale_rule_y',
                        label: (
                          <Trans>Scale rule Y axis paperspace location</Trans>
                        ),
                        content: (
                          <>
                            <UnitOfMeasurementField
                              name="scale_rule_y"
                              unit="mm"
                            />
                            <FieldError name="scale_rule_y" />
                          </>
                        ),
                      }
                    : null,
                ].filter(isNotNull)}
              />
              <FormCardSection
                name="y-axis-scale"
                header={<Trans>Y-Axis Scale</Trans>}
                fields={[
                  {
                    name: 'y-axis-scale',
                    columns: [
                      {
                        name: 'y-axis-min',
                        label: <Trans>Min</Trans>,
                        content: (
                          <>
                            <Field name="y_axis_scale_min" />
                            <FieldError name="y_axis_scale_min" />
                          </>
                        ),
                      },
                      {
                        name: 'y-axis-max',
                        label: <Trans>Max</Trans>,
                        content: (
                          <>
                            <Field name="y_axis_scale_max"></Field>
                            <FieldError name="y_axis_scale_max" />
                          </>
                        ),
                      },
                    ],
                  },
                ]}
              />
            </FormCard>
            <ActionBlock className="text-right">
              <StoredPlotSaveCancelButtons {...props} formik={formik} />
            </ActionBlock>
          </Form>
        );
      }}
    </Formik>
  );
}

function ConnectionGroupsChangeEffect(props: {
  formik: FormikProps<StoredSpatialCrossSectionPlotFormValues>;
}) {
  const { formik } = props;
  return (
    <FormChangeEffect<StoredSpatialCrossSectionPlotFormValues>
      onChange={(prevProps) => {
        if (!formik.values.show_connection_lines) {
          // They've just disabled the connection groups. Clear their form setting.
          if (prevProps.values.show_connection_lines) {
            formik.setFieldValue('connection_groups', []);
          }

          // Not showing connection lines; no need to check for removed obs points
          return;
        }

        // Check whether any obs points have been removed from the plot, and
        // remove them from any connection groups they're in.
        const curObsPoints = props.formik.values.observation_points;
        const prevObsPoints = prevProps.values.observation_points;
        if (curObsPoints === prevObsPoints) {
          return;
        }
        const curObsPointIDs = curObsPoints.map((op) => op.observation_point);
        const prevObsPointIDs = prevObsPoints.map((op) => op.observation_point);
        const removedObsPointIDs = difference(prevObsPointIDs, curObsPointIDs);
        if (removedObsPointIDs.length === 0) {
          return;
        }

        // There are some obs points that have been removed. Clear them out
        // of any connection groups they're in.
        formik.values.connection_groups.forEach((group, idx) => {
          const updatedGroup = {
            observation_points: group.observation_points.filter(
              (id) => !removedObsPointIDs.includes(id)
            ),
          };
          if (
            updatedGroup.observation_points.length !==
            group.observation_points.length
          ) {
            formik.setFieldValue(`connection_groups[${idx}]`, updatedGroup);
          }
        });
      }}
    />
  );
}

/**
 * Generates the array of card section rows, one for each connection group.
 * And the "add a group" button.
 */
const makeConnectionGroupsSubsection = function (
  formik: FormikProps<StoredSpatialCrossSectionPlotFormValues>,
  arrayHelpers: FieldArrayRenderProps
): CardSectionRow[] {
  if (!formik.values.show_connection_lines) {
    return [];
  }

  // The button to add another connection group
  const addGroupButton: CardSectionField = {
    name: 'add-connection-group',
    content: (
      <Button
        data-testid="connection-group--add-button"
        iconType="icon-plus"
        onClick={() => arrayHelpers.push({ observation_points: [] })}
      >
        <Trans>Add a group</Trans>
      </Button>
    ),
  };

  const obsPointOptions = sortBy(
    formik.values.observation_points.flatMap(
      (opDetails) => opDetails.selectedOptions ?? []
    ),
    (opt) => opt.label
  );

  return [
    // Make one card row for each connection group.
    ...formik.values.connection_groups.map((_connectionGroup, idx) => {
      return {
        name: `connection-group-${idx}-observation-points`,
        label: <Trans>Group {idx + 1}</Trans>,
        content: (
          <>
            <SimpleSelectField
              name={`connection_groups[${idx}].observation_points`}
              options={obsPointOptions}
              isMulti={true}
            />
            <FieldError name={`connection_groups[${idx}]`} />
            {formik.values.connection_groups.length > 1 && (
              <Button onClick={() => arrayHelpers.remove(idx)}>
                <Trans>Delete</Trans>
              </Button>
            )}
          </>
        ),
      };
    }),
    addGroupButton,
  ];
};
