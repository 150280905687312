import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { RCPWithQueryParams, setQueryParams } from 'util/routing';
import {
  resolveSettingsFromQuickplot,
  unmountPlotPage,
} from 'ducks/plot/scatter-time-series';
import QuickPlotView from './quickplotview';
import { usePlotState } from 'hooks/use-plot-state';
import {
  getQuickPlotSettingsFromUrl,
  encodeScalesConfigForUrl,
  encodeZoomBaseForUrl,
} from './quickplotselectors';
import { getTimeZoneFromPlotMetadata } from 'components/plots/timeseriesplotselectors';
import { PlotType } from 'util/backendapi/types/Enum';
import {
  QuickPlotSettings,
  TIMESERIES_BUCKETS,
  TSPlotOnZoomFn,
} from 'components/plots/timeseriesplot.types';
import { GetExportUrlFunc } from 'components/modules/exportpanel/exportpanel';

/**
 * These are the parameters we send to the backend (as a JSON blob) when
 * requesting a PDF export.
 */
interface QuickPlotExportParams extends QuickPlotSettings {
  buckets: number;
  // Don't send any fetched data to the backend
  storedPlot: null;
  observationPoints: [];
}

type OwnProps = RCPWithQueryParams;

export const QuickPlotScreen: React.FunctionComponent<OwnProps> = function (
  props
) {
  const dispatch = useDispatch();

  const settingsFromUrl = getQuickPlotSettingsFromUrl(props);

  // Update the "resolved settings" in the Redux store, if the settings in
  // the URL have changed.
  useEffect(() => {
    dispatch(
      resolveSettingsFromQuickplot(
        settingsFromUrl.pairs,
        settingsFromUrl.startDatetime,
        settingsFromUrl.endDatetime,
        settingsFromUrl.yAxisSides,
        settingsFromUrl.axes,
        settingsFromUrl.numberOfMonths,
        settingsFromUrl.showAnalysisComments,
        settingsFromUrl.showInspectorComments,
        settingsFromUrl.showMedia
      )
    );
  }, [dispatch, settingsFromUrl]);

  const handleZoom = React.useCallback<TSPlotOnZoomFn>(
    (zoomParams) =>
      setQueryParams(
        { history: props.history, location: props.location },
        {
          startDatetime: zoomParams.minDatetime,
          endDatetime: zoomParams.maxDatetime,
          axes: zoomParams.yAxes && encodeScalesConfigForUrl(zoomParams.yAxes),
          // HACK: We originally wrote this system to have no difference between
          // plot parameters that came from zooming in, and plot parameters that
          // came from the settings modal. This was later changed so that the
          // "Reset zoom" button only applies to plot parameters from zooming in.
          // To make that work, we added an additional URL param called
          // "zoomedFrom", which indicates that the plot has been zoomed, and
          // records the settings it should return to if the reset button is
          // pressed.
          zoomedFrom: encodeZoomBaseForUrl(
            // If we're already zoomed, don't update the "zoomedFrom".
            settingsFromUrl.zoomedFrom ?? settingsFromUrl
          ),
        },
        ['numberOfMonths']
      ),
    [props.history, props.location, settingsFromUrl]
  );

  const {
    minDatetime,
    maxDatetime,
    paddedMinDatetime,
    paddedMaxDatetime,
    errorMessage,
    isLoading,
    readingsSeries,
    resolvedSettings,
    userString,
  } = usePlotState('quickPlot', PlotType.TIME_SERIES);
  // Clear plot data when unmounting.
  useEffect(
    () => () => {
      dispatch(unmountPlotPage());
    },
    [dispatch]
  );

  const handleResetZoom = React.useCallback(() => {
    const zoomedFrom = settingsFromUrl.zoomedFrom;
    if (!zoomedFrom) {
      // The reset button shouldn't be displayed if there's no "zoomed from"
      // setting. So this code branch shouldn't be necessary. But in case
      // something screws up and we do get here with zoomedFrom being null...
      return;
    }
    const { axes, ...others } = zoomedFrom;
    setQueryParams(
      { history: props.history, location: props.location },
      {
        ...others,
        axes: encodeScalesConfigForUrl(axes),
        zoomedFrom: null,
      },
      null
    );
  }, [props.history, props.location, settingsFromUrl.zoomedFrom]);

  const isZoomed = Boolean(settingsFromUrl.zoomedFrom);

  const quickPlotSettings = resolvedSettings as QuickPlotSettings;
  const getExportUrl: GetExportUrlFunc = React.useCallback(() => {
    const exportSettings: QuickPlotExportParams = {
      ...quickPlotSettings!,
      buckets: TIMESERIES_BUCKETS,
      // Don't send fetched data to the backend
      storedPlot: null,
      observationPoints: [],
    };
    return {
      path: '/export/pdf/quick-plots/',
      queryParams: new URLSearchParams({
        settings: JSON.stringify(exportSettings),
      }),
      fileName: 'quickplot.pdf',
    };
  }, [quickPlotSettings]);

  return (
    <QuickPlotView
      minDatetime={minDatetime}
      maxDatetime={maxDatetime}
      paddedMinDatetime={paddedMinDatetime}
      paddedMaxDatetime={paddedMaxDatetime}
      timeZone={getTimeZoneFromPlotMetadata(resolvedSettings)}
      yAxes={quickPlotSettings ? quickPlotSettings.axes : []}
      loading={isLoading}
      errorMessage={errorMessage}
      seriesPairs={settingsFromUrl.pairs}
      readingsSeries={readingsSeries}
      userString={userString}
      onResetZoom={isZoomed ? handleResetZoom : undefined}
      onZoom={handleZoom}
      getExportUrl={getExportUrl}
    />
  );
};
