import React from 'react';
import { Link } from 'react-router-dom';
import { Trans, t, Plural } from '@lingui/macro';
import { formatDatetimeForDisplay } from 'util/dates';
import { Icon } from 'components/base/icon/icon';
import { TransEnum } from 'components/base/i18n/TransEnum';
import './batcheslist.scss';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { removeBatch } from 'ducks/readings-batch/list';
import Accordion from 'components/base/accordion/accordion';
import { DMSLink } from 'components/base/link/DMSLink';
import Loading from 'components/base/loading/loading';
import { DownloadBatchButton } from 'components/modules/DownloadBatchButton';

function canEditFile(batch: Model.ListReadingsBatch) {
  return batch.file_status === Enum.ReadingsFile_STATUS.parse_error;
}

function canCheckReadings(batch: Model.ListReadingsBatch) {
  return (
    batch.status === Enum.ReadingsBatch_STATUS.unprocessed &&
    (!batch.file_id || batch.file_status === Enum.ReadingsFile_STATUS.processed)
  );
}

function removeBatchMessage(
  batch_number: number,
  readings_count: number | undefined
) {
  if (readings_count) {
    return (
      <Trans>
        Are you sure you want to delete batch {batch_number}. {readings_count}{' '}
        readings will not be processed and will be permanently removed from the
        system. This action is not reversible.
      </Trans>
    );
  }
  return (
    <Trans>
      Are you sure you want to delete batch {batch_number}. These readings will
      not be processed and will be permanently removed from the system. This
      action is not reversible.
    </Trans>
  );
}

export function DeleteReadingsBatchButton(props: {
  batch: Optional<
    Pick<
      Model.ListReadingsBatch,
      'id' | 'batch_number' | 'status' | 'readings_count'
    >,
    'readings_count'
  >;
  onSubmit?: () => void;
}) {
  const { batch, onSubmit } = props;
  if (batch.status !== Enum.ReadingsBatch_STATUS.unprocessed) return null;

  return (
    <HasPermission check="can_delete_readings_batch">
      <ButtonShowConfirmation
        name="delete-batch"
        title={t`Delete batch`}
        iconType="icon-circle-minus"
        className="btn-link-panel"
        onConfirm={async () => {
          await removeBatch(batch.id);
          if (onSubmit) onSubmit();
        }}
        content={removeBatchMessage(batch.batch_number, batch.readings_count)}
      />
    </HasPermission>
  );
}

export interface BatchesListViewProps {
  batches: Model.ListReadingsBatch[] | null;
  isExpanded: boolean;
  batchesForUser: boolean;
  onToggle: (expanded: boolean) => void;
}

export function BatchesListView(props: BatchesListViewProps) {
  const batches = props.batches ?? [];
  const { batchesForUser } = props;

  const initializing = !props.batches;

  let title: React.ReactElement;

  if (initializing) {
    title = batchesForUser ? (
      <Trans>Loading uploaded batches</Trans>
    ) : (
      <Trans>Loading batches awaiting processing</Trans>
    );
  } else {
    title = batchesForUser ? (
      <Plural
        value={batches.length}
        one="1 uploaded batch"
        other="# uploaded batches"
      />
    ) : (
      <Plural
        value={batches.length}
        one="1 batch awaiting processing"
        other="# batches awaiting processing"
      />
    );
  }

  return (
    <Accordion
      expand={props.isExpanded}
      onToggle={props.onToggle}
      wrapperClassName="accordion-secondary"
      item={{
        id: 'batches-awaiting-processing-accordion',
        title,
        content: (
          <div className="table-responsive">
            <table
              className="FilesListTable"
              data-testid="table_files-awaiting-processing"
            >
              <thead>
                <tr>
                  <th scope="col">
                    <Trans>Batch name</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Route march</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Batch</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Uploaded</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Uploaded by</Trans>
                  </th>
                  {!batchesForUser && (
                    <>
                      <th scope="col" className="text-right">
                        <Trans>Records</Trans>
                      </th>
                      <th scope="col">
                        <Trans>Status</Trans>
                      </th>
                      <th scope="col" className="action-icons">
                        <Trans>Actions</Trans>
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              {batches.length > 0 ? (
                <tbody>
                  {batches.map((batch) => (
                    <tr
                      key={`fileAwaitingProcessing${String(batch.id)}`}
                      data-file-id={batch.file_id ? batch.file_id : null}
                      data-batch-id={batch.id}
                      data-batch-name={batch.name}
                      className={
                        batchesForUser
                          ? ''
                          : batch.file_status
                          ? `status-${batch.file_status}`
                          : `status-${Enum.ReadingsFile_STATUS.processed}`
                      }
                    >
                      <th scope="row">{batch.name}</th>
                      <td>
                        {batch.route_march ? batch.route_march.code : null}
                      </td>
                      <td>{batch.batch_number}</td>
                      <td>{formatDatetimeForDisplay(batch.created)}</td>
                      <td>
                        {batch.file_uploader_username
                          ? batch.file_uploader_username
                          : batch.observer_name}
                      </td>
                      {!batchesForUser && (
                        <>
                          <td className="text-right">
                            {batch.readings_count !== 0
                              ? batch.readings_count
                              : '-'}
                          </td>
                          <td className="table-cell-status">
                            {batch.file_status ? (
                              <TransEnum
                                enum="ReadingsFile_STATUS"
                                value={batch.file_status}
                              />
                            ) : (
                              // If the batch has no file, then unprocessed batches shoudl appear in the same 'validated' status
                              <TransEnum
                                enum="ReadingsFile_STATUS"
                                value={Enum.ReadingsFile_STATUS.processed}
                              />
                            )}
                          </td>
                          <td className="action-icons">
                            {canEditFile(batch) ? (
                              <Link
                                to={`/readings-file/${String(
                                  batch.file_id
                                )}/edit`}
                              >
                                <Icon type="icon-edit" title={t`Edit`} />
                              </Link>
                            ) : null}
                            {canCheckReadings(batch) ? (
                              <DMSLink
                                to={`/check-readings/${String(
                                  batch.batch_number
                                )}`}
                              >
                                <Icon
                                  type="icon-clipboard-tick"
                                  title={t`Check`}
                                />
                              </DMSLink>
                            ) : null}
                            <HasPermission check="can_view_readings">
                              <DownloadBatchButton
                                fileId={batch.file_id}
                                batchId={batch.id}
                              />
                            </HasPermission>
                            <DeleteReadingsBatchButton batch={batch} />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td className="td-no-results" colSpan={999}>
                      {initializing ? (
                        <Loading />
                      ) : batchesForUser ? (
                        <Trans>No batches uploaded today.</Trans>
                      ) : (
                        <Trans>No files awaiting processing</Trans>
                      )}
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        ),
      }}
    />
  );
}
