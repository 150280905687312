import React from 'react';
import { SpatialPlotObservationPoint } from 'util/backendapi/types/Model';
import {
  StoredSpatialCrossSectionPlotWithArea,
  StoredSpatialPlanPlotWithArea,
} from 'ducks/stored-plot/detail';
import { Trans } from '@lingui/macro';
import { formatDateForDisplay, convertDatetimeToDate } from 'util/dates';

/**
 * The table of data about the observation points in the map.
 */
export function SpatialPlotDataTable(props: {
  storedPlot:
    | StoredSpatialPlanPlotWithArea
    | StoredSpatialCrossSectionPlotWithArea;
  obsPoints: SpatialPlotObservationPoint[];
}) {
  const { storedPlot, obsPoints } = props;
  return (
    <div className="table-responsive data-table">
      <table>
        <caption>
          <Trans>Current data used</Trans>
        </caption>
        <thead>
          <tr>
            <th>
              <Trans>Label</Trans>
            </th>
            <th>
              <Trans>Date</Trans>
            </th>
            <th>
              <Trans>Value (m)</Trans>
            </th>
            <th>
              <Trans>Port RL (m)</Trans>
            </th>
            <th>
              <Trans>Cap RL (m)</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {storedPlot.observation_points
            .filter((sop) => sop.show_in_data_table)
            .map((sop) => {
              const op = obsPoints.find(
                (op) => op.id === sop.observation_point
              );
              return (
                <tr key={sop.observation_point}>
                  <td>{sop.label}</td>
                  <td>
                    {op?.reading?.reading_datetime
                      ? formatDateForDisplay(
                          convertDatetimeToDate(
                            op.reading.reading_datetime,
                            storedPlot.area.time_zone.name
                          )
                        )
                      : '-'}
                  </td>
                  <td>
                    {(op?.instrument_type__code === 'PZTT'
                      ? // RM90348: For instrument type PZTT, only print the first reading entry.
                        op.reading?.adjusted_reading_entries.slice(0, 1)
                      : op?.reading?.adjusted_reading_entries
                    )
                      ?.map((adj) => adj.value)
                      .join(' ') ?? '-'}
                  </td>
                  <td>{op?.port_rl ?? '-'}</td>
                  <td>{op?.cap_rl ?? '-'}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export function SpatialPlotDeformationSurveyDataTable(props: {
  storedPlot: StoredSpatialPlanPlotWithArea;
  obsPoints: SpatialPlotObservationPoint[];
}) {
  const { storedPlot, obsPoints } = props;
  return (
    <div className="table-responsive data-table">
      <table>
        <caption>
          <Trans>Current data used</Trans>
        </caption>
        <thead>
          <tr>
            <th>
              <Trans>Label</Trans>
            </th>
            <th>
              <Trans>Date</Trans>
            </th>
            <th>
              <Trans>Movement</Trans>
            </th>
            <th>
              <Trans>Std Error</Trans>
            </th>
            <th>
              <Trans>Bearing</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {storedPlot.observation_points
            .filter((sop) => sop.show_in_data_table)
            .map((sop) => {
              const op = obsPoints.find(
                (op) => op.id === sop.observation_point
              );
              return (
                <tr key={sop.observation_point}>
                  <td>{sop.label}</td>
                  <td>
                    {op?.reading?.reading_datetime
                      ? formatDateForDisplay(
                          convertDatetimeToDate(
                            op.reading.reading_datetime,
                            storedPlot.area.time_zone.name
                          )
                        )
                      : '-'}
                  </td>
                  <td>{op?.reading?.adjusted_reading_entries[0]?.value}</td>
                  <td>{op?.reading?.adjusted_reading_entries[1]?.value}</td>
                  <td>{op?.reading?.adjusted_reading_entries[2]?.value}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
