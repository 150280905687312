import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const routeMarchScheduleDuck = makeReportingDuck(
  'routeMarchSchedule',
  (filters) => getPaginated('/reports/route-march-schedule/', filters),
  () => getApi('/reports/route-march-schedule/info/')
);

export type RouteMarchScheduleAction = ReportingDuckActions<
  typeof routeMarchScheduleDuck
>;
