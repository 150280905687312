import React from 'react';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import { isEmpty } from 'lodash';

type ErrorNoticeProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
>;

export default class ErrorNotice extends React.Component<ErrorNoticeProps> {
  render() {
    const { children, className, ...otherProps } = this.props;
    if (children && !isEmpty(children)) {
      return (
        <p
          className={classNames('error', this.props.className)}
          {...otherProps}
        >
          {this.props.children}
        </p>
      );
    } else {
      return null;
    }
  }
}

/**
 * A convenience function for the very frequent pattern of using Formik's
 * <ErrorMessage> component with our <ErrorNotice> element as its `component`
 * prop.
 *
 * @param props
 */
export const FieldError: React.FunctionComponent<{ name: string }> = function (
  props
) {
  return <ErrorMessage name={props.name} component={ErrorNotice} />;
};
