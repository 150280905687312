import React from 'react';
import { Trans } from '@lingui/macro';
import PanelContent from 'components/base/panel/panelcontent';
import { AlertDanger, AlertSuccess } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import Button from 'components/base/button/button';
import ActionBlock from 'components/base/actionblock/actionblock';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { Comment } from './comment/comment';
import NewCommentForm, {
  canCreateComments,
  NewCommentFormValues,
} from './new-comment-form/NewCommentFormView';
import { Icon } from 'components/base/icon/icon';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './CommentsPanel.scss';

/**
 * The comment types which can be used as the target type in a comments panel.
 *
 * NOTE: Some target types will actually display multiple comment types
 * together (For example, resource type "obsPoint" shows observation point
 * comments *and* reading gap comments).
 *
 * Also, most target types will let you *create* many other types of comments
 * besides the ones displayed.
 */
export type CommentsPanelResourceTypes =
  | Enum.Comment_RESOURCE_TYPE.alarmParameter
  | Enum.Comment_RESOURCE_TYPE.alarmReport
  | Enum.Comment_RESOURCE_TYPE.area
  | Enum.Comment_RESOURCE_TYPE.client
  | Enum.Comment_RESOURCE_TYPE.media
  | Enum.Comment_RESOURCE_TYPE.obsPoint
  | Enum.Comment_RESOURCE_TYPE.reading
  | Enum.Comment_RESOURCE_TYPE.routeMarch
  | Enum.Comment_RESOURCE_TYPE.site;

export interface CommentsPanelViewProps {
  isLoading: boolean;
  type: CommentsPanelResourceTypes;
  description?: React.ReactNode;
  permissions: Enum.User_PERMISSION[];
  comments: Model.ReportsComment[];
  error: string | null;
  refreshFlag: number | null;
  addComment: (
    values: NewCommentFormValues
  ) => Promise<Model.PolymorphicComment>;
  onCommentAdded?: (values: Model.PolymorphicComment) => void;
  onPanelMount?: () => void;
  onPanelUnmount?: () => void;
  timeZone: string | undefined;
  commentReportParams?: string;
}

type State = {
  activeTab: string | null;
  successMessage: React.ReactNode;
};

const getCommentData = (type: Enum.Comment_RESOURCE_TYPE) => {
  switch (type) {
    case Enum.Comment_RESOURCE_TYPE.area:
      return {
        prefix: 'area',
        noCommentMessage: <Trans>There are no comments for this area.</Trans>,
        successMessage: <Trans>Area comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.obsPoint:
      return {
        prefix: 'observation-point',
        noCommentMessage: (
          <Trans>There are no comments for this observation point.</Trans>
        ),
        successMessage: <Trans>Observation point comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.site:
      return {
        prefix: 'site',
        noCommentMessage: <Trans>There are no comments for this site.</Trans>,
        successMessage: <Trans>Site comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.routeMarch:
      return {
        prefix: 'route-march',
        noCommentMessage: (
          <Trans>There are no comments for this route march.</Trans>
        ),
        successMessage: <Trans>Route march comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.alarmReport:
      return {
        prefix: 'alarm-report',
        noCommentMessage: (
          <Trans>There are no comments for this alarm report.</Trans>
        ),
        successMessage: <Trans>Alarm report comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.alarmParameter:
      return {
        prefix: 'alarm-parameter',
        noCommentMessage: (
          <Trans>There are no comments for this alarm parameter.</Trans>
        ),
        successMessage: <Trans>Alarm parameter comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.reading:
      return {
        prefix: 'alarm-parameter',
        noCommentMessage: (
          <Trans>There are no comments for this reading.</Trans>
        ),
        successMessage: <Trans>Reading comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.readingGap:
      return {
        prefix: 'reading-gap',
        noCommentMessage: (
          <Trans>
            There are no reading gap comments for this observation point.
          </Trans>
        ),
        successMessage: <Trans>Reading gap saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.client:
      return {
        prefix: 'alarm-parameter',
        noCommentMessage: <Trans>There are no comments for this client.</Trans>,
        successMessage: <Trans>Client comment saved!</Trans>,
      };
    case Enum.Comment_RESOURCE_TYPE.media:
      return {
        prefix: 'media',
        noCommentMessage: (
          <Trans>There are no comments for this media item.</Trans>
        ),
        successMessage: <Trans>Media comment saved!</Trans>,
      };
    default:
      return;
  }
};

class CommentsPanelView extends React.Component<CommentsPanelViewProps, State> {
  private _isMounted: boolean = false;
  constructor(props: CommentsPanelViewProps) {
    super(props);

    this.state = {
      activeTab: null,
      successMessage: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.onPanelMount) {
      this.props.onPanelMount();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.props.onPanelUnmount) {
      this.props.onPanelUnmount();
    }
  }

  handleAddComment = async (values: NewCommentFormValues) => {
    const newComment = await this.props.addComment(values);
    if (!this._isMounted) {
      return;
    }

    if (this.props.onCommentAdded) {
      this.props.onCommentAdded(newComment);
    }

    if (values.resourcetype !== this.props.type) {
      const commentsData = getCommentData(values.resourcetype);
      if (commentsData) {
        this.setState(
          {
            successMessage: commentsData.successMessage,
          },
          () => {
            setTimeout(() => {
              if (
                this._isMounted &&
                this.state.successMessage === commentsData.successMessage
              ) {
                this.setState({ successMessage: '' });
              }
            }, 3000);
          }
        );
      }
    }
  };

  render() {
    const { isLoading, description, comments, error, type } = this.props;
    const { activeTab } = this.state;
    const commentsData = getCommentData(type);

    if (!commentsData) {
      // check to assure typescript that model will have required properties (modelName, prefix)
      // it appears panel is not configured
      return null;
    }

    const showNewCommentButton = canCreateComments(
      this.props.type,
      this.props.permissions
    );

    return (
      <PanelContent header={<Trans>Comments</Trans>}>
        {description}
        {!activeTab ? (
          <ActionBlock className="comment-panel-action-block">
            {showNewCommentButton ? (
              <Button
                id={`comments-panel-new-comment-button`}
                iconType="icon-plus"
                className="btn-tab"
                onClick={() => this.setState({ activeTab: 'new-comment' })}
              >
                <Trans>New comment</Trans>
              </Button>
            ) : null}
            {this.props.commentReportParams ? (
              <Link
                to={`/comments/?${this.props.commentReportParams}`}
                target="_blank"
                className="btn comment-report-link"
              >
                <span>
                  <Trans>Comment report</Trans>
                </span>
              </Link>
            ) : null}
          </ActionBlock>
        ) : (
          <div className="tabs-wrapper">
            <nav className="tab-head">
              <div
                id={`comments-panel-tab-new-comment`}
                className={classNames('btn btn-tab', {
                  active: activeTab === 'new-comment',
                })}
                role="link"
                onClick={() => this.setState({ activeTab: 'new-comment' })}
              >
                <span>
                  <Trans>New comment</Trans>
                </span>
                <Icon type="icon-plus" role="presentation" />
              </div>
            </nav>
            <div className="tab-body">
              {activeTab === 'new-comment' && (
                <NewCommentForm
                  type={this.props.type}
                  permissions={this.props.permissions}
                  onSubmit={this.handleAddComment}
                  onCancel={() => this.setState({ activeTab: null })}
                  timeZone={this.props.timeZone}
                />
              )}
            </div>
          </div>
        )}

        {this.state.successMessage && (
          <AlertSuccess>{this.state.successMessage}</AlertSuccess>
        )}

        {isLoading && <Loading />}

        {!isLoading && error ? (
          <AlertDanger>
            <Trans>{error}</Trans>
          </AlertDanger>
        ) : null}
        {!isLoading && !comments.length && (
          <p className="comment-no-results">{commentsData.noCommentMessage}</p>
        )}

        {!isLoading && comments.length > 0 ? (
          <ol className="comment-list">
            {comments.map((comment) => (
              <li
                id={`${commentsData.prefix}-comment-${comment.id}`}
                key={`${commentsData.prefix}-comment-${comment.id}`}
              >
                <Comment {...comment} timeZone={this.props.timeZone} />
              </li>
            ))}
          </ol>
        ) : null}
      </PanelContent>
    );
  }
}

export default CommentsPanelView;
