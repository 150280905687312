import React from 'react';
import { MessageDescriptor } from '@lingui/core';
import { reportFilterText } from './FilterText';
import { validateNumber } from 'util/validation';
import { Trans } from '@lingui/macro';

export function reportFilterNumber(
  name: string,
  label: React.ReactNode,
  extraProps?: {
    placeholder?: MessageDescriptor;
  }
) {
  return reportFilterText(name, label, {
    ...extraProps,
    validate: (val: string) => {
      if (val && !validateNumber(val)) {
        return <Trans>Please enter a valid number</Trans>;
      }

      return null;
    },
  });
}
