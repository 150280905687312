import React from 'react';
import { ReportFilter } from '../../report-types';
import { reportFilterAsyncMenu } from './FilterAsyncMenu';
import { Trans } from '@lingui/macro';
import { getApi } from 'util/backendapi/fetch';
import { AsyncSimpleSelectFieldProps } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from 'components/modules/async-menu/ObsPointMenu';

export function makeObsPointsMenuOptions(
  options: { id: number; code: string }[]
) {
  return options.map((opt) => ({
    value: opt.id,
    label: opt.code,
  }));
}

/**
 * Generates a report filter for an async "observation points" menu.
 * @param name
 */
export function reportFilterObservationPoints(
  name: string,
  extraProps: Partial<AsyncSimpleSelectFieldProps<any, any, any>> = {}
): ReportFilter {
  return reportFilterAsyncMenu(
    name,
    <Trans>Observation point</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: async (searchText: string) =>
        makeObsPointsMenuOptions(
          await getApi('/observation-points/', {
            code__icontains: searchText,
            fields: ['id', 'code'],
            limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
          })
        ),
      loadDefaults: async (value: number[]) =>
        makeObsPointsMenuOptions(
          await getApi('/observation-points/', {
            id__in: value,
            fields: ['id', 'code'],
          })
        ),
    },
    {
      placeholder: <Trans>All observation points</Trans>,
      ...extraProps,
    }
  );
}
