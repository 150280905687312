import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CloseoutView } from './CloseoutView';
import { unmountCloseoutScreen, fetchBatchCloseoutInfo } from 'ducks/closeout';
import { FullState } from 'main/reducers';
import { useParams } from 'react-router-dom';
import { Enum } from 'util/backendapi/models/api.interfaces';

export function CloseoutScreen() {
  const CLOSEOUT_BATCH_STATUS_POLL_INTERVAL_MS = 5000;

  const dispatch = useDispatch();
  const { batchNumber } = useParams<{ batchNumber: string }>();
  const state = useSelector((state: FullState) => state.closeout);

  const refresh = useCallback(() => {
    if (batchNumber) {
      dispatch(fetchBatchCloseoutInfo(parseInt(batchNumber), false));
    }
  }, [dispatch, batchNumber]);

  useEffect(() => {
    return () => {
      dispatch(unmountCloseoutScreen());
    };
  }, [batchNumber, dispatch]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const timerRef = useRef<NodeJS.Timer>();

  const poll = useCallback(() => {
    if (batchNumber) {
      dispatch(fetchBatchCloseoutInfo(parseInt(batchNumber), true));
    }
  }, [dispatch, batchNumber]);

  useEffect(() => {
    if (
      state.readingsBatch &&
      state.readingsBatch.status !== Enum.ReadingsBatch_STATUS.unprocessed &&
      state.readingsBatch.status !== Enum.ReadingsBatch_STATUS.qa_complete
    ) {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      timerRef.current = setTimeout(
        poll,
        CLOSEOUT_BATCH_STATUS_POLL_INTERVAL_MS
      );
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [state, poll]);

  return <CloseoutView {...state} onRefresh={refresh} />;
}
