import React, { useEffect, useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { FullState } from 'main/reducers';
import { useShallowEqualSelector } from 'util/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchChecksheetInstance,
  ChecksheetInstanceEditState,
  updateChecksheetInstanceSimplified,
} from 'ducks/checksheet-instance/edit';
import { Trans, t } from '@lingui/macro';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { Link } from 'react-router-dom';
import ActionBlock from 'components/base/actionblock/actionblock';
import { Icon } from 'components/base/icon/icon';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { Formik, Field, FormikErrors, FormikProps } from 'formik';
import { YNToggleField } from 'components/base/form/toggle-field/ToggleField';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { AssignBatchesModal } from './AssignBatchesModal';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import sortBy from 'lodash/sortBy';
import { StandardDispatch } from 'main/store';
import { FormChangeEffectDebounced } from 'components/base/form/formchangeeffect/FormChangeEffectDebounced';
import Button, { ButtonPrimary } from 'components/base/button/button';
import lodashSet from 'lodash/set';
import { ChecksheetInstance_STATUS } from 'util/backendapi/types/Enum';
import { ReviewerCommentModal } from './ReviewerCommentModal';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import {
  ExportPanel,
  GetExportUrlFunc,
} from 'components/modules/exportpanel/exportpanel';
import './ChecksheetEditScreen.scss';
import { ChecksheetDataSourceTable } from '../detail/ChecksheetDataSourceTable';
import { ChecksheetFeatureObservationPoints } from '../detail/ChecksheetFeatureObservationPoints';
import {
  ChecksheetFeature_PATCH,
  ChecksheetInstance,
} from 'util/backendapi/types/Model';
import { BackButton } from 'components/base/back-button/BackButton';
import ModalContent from 'components/base/modal/modalcontent';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { selectHasPermission } from 'util/user';

interface ChecksheetEditFormValues
  extends Model.SimpleChecksheetInstance_PATCH {
  features?: Merge<
    ChecksheetFeature_PATCH,
    { editing_comment?: boolean; editing_review_comment?: boolean }
  >[];
}

interface ChecksheetEditViewProps extends ChecksheetInstanceEditState {
  onSave: (data: ChecksheetEditFormValues) => Promise<any>;
  getExportUrl?: GetExportUrlFunc;
}

let submitStatus: ChecksheetInstance_STATUS | null = null;

export function useSortedDataSources(
  checksheetInstance: ChecksheetInstance | undefined
) {
  return useMemo(() => {
    if (!checksheetInstance) {
      return [];
    }
    return sortBy(
      checksheetInstance.data_sources,
      // Put route marches at the top of the table, data loggers below
      (ds) => (ds.route_march ? 1 : 2),
      // Sort route marches by code, data loggers by number
      (ds) => ds.route_march?.code ?? ds.data_logger?.logger_number
    );
  }, [checksheetInstance]);
}

export function ChecksheetEditScreen() {
  const { checksheetInstanceId } = useParams<{
    checksheetInstanceId: string;
  }>();

  const dispatch = useDispatch<StandardDispatch>();
  useEffect(() => {
    submitStatus = null;
    dispatch(fetchChecksheetInstance(+checksheetInstanceId));
  }, [checksheetInstanceId, dispatch]);

  const state = useShallowEqualSelector(
    (state: FullState) => state.checksheetInstance.edit
  );

  const handleSave = useCallback(
    (data: ChecksheetEditFormValues) =>
      dispatch(updateChecksheetInstanceSimplified(+checksheetInstanceId, data)),
    [checksheetInstanceId, dispatch]
  );

  const getExportUrl: GetExportUrlFunc = useCallback(() => {
    return {
      path: `/export/pdf/checksheet-instances/${checksheetInstanceId}/`,
      queryParams: new URLSearchParams(),
    };
  }, [checksheetInstanceId]);

  return (
    <ChecksheetEditView
      {...state}
      onSave={handleSave}
      getExportUrl={getExportUrl}
    />
  );
}

function reviewButtons(
  formik: FormikProps<ChecksheetEditFormValues>,
  location: string,
  dataSources: Model.ChecksheetDataSource[]
) {
  if (
    formik.values.status === ChecksheetInstance_STATUS.ready_for_review ||
    formik.values.status === ChecksheetInstance_STATUS.under_followup ||
    formik.values.status === ChecksheetInstance_STATUS.reviewed
  ) {
    return (
      <>
        <HasPermission check="can_review_checksheet_instances">
          <Button
            id={`mark-as-under-followup-${location}`}
            disabled={formik.isSubmitting}
            onClick={() => {
              // Enter non-automatic submission state
              submitStatus = ChecksheetInstance_STATUS.under_followup;
              formik.submitForm();
            }}
          >
            <Trans>Mark as 'Under follow-up'</Trans>
          </Button>
          <ButtonPrimary
            id={`mark-as-reviewed-${location}`}
            disabled={formik.isSubmitting}
            iconType="icon-tick"
            onClick={() => {
              // Enter non-automatic submission state
              submitStatus = ChecksheetInstance_STATUS.reviewed;
              formik.submitForm();
            }}
          >
            <Trans>Mark as 'Reviewed'</Trans>
          </ButtonPrimary>
        </HasPermission>
      </>
    );
  } else {
    // If any of the batches aren't QA complete yet, this button should show
    // a modal with a warning message, instead of actually letting you submit.
    if (
      !dataSources.every(({ batches }) =>
        batches.every((b) => b.status === Enum.ReadingsBatch_STATUS.qa_complete)
      )
    ) {
      return (
        <ButtonShowModal
          name={`mark-as-ready-for-review-${location}`}
          data-testid={`mark-as-ready-for-review-${location}`}
          disabled={formik.isSubmitting}
          iconType="icon-tick"
          modalProps={{
            overlayClassName: 'modal-centred',
            className: 'modal',
          }}
          modalContent={() => (
            <ModalContent>
              <div>
                <Trans>
                  This checksheet cannot be marked as 'Ready for review' because
                  an associated batch has not been fully processed. Please check
                  that all batches are 'QA complete' and then try again.
                </Trans>
              </div>
              <ActionBlock className="text-right">
                <ButtonHideModal>
                  <Trans>Close</Trans>
                </ButtonHideModal>
              </ActionBlock>
            </ModalContent>
          )}
        >
          <Trans>Mark as 'Ready for review'</Trans>
        </ButtonShowModal>
      );
    } else {
      return (
        <ButtonPrimary
          data-testid={`mark-as-ready-for-review-${location}`}
          disabled={formik.isSubmitting}
          iconType="icon-tick"
          onClick={() => {
            // Enter non-automatic submission state
            submitStatus = ChecksheetInstance_STATUS.ready_for_review;
            formik.submitForm();
          }}
        >
          <Trans>Mark as 'Ready for review'</Trans>
        </ButtonPrimary>
      );
    }
  }
}

export function ChecksheetEditView(props: ChecksheetEditViewProps) {
  const {
    isLoading,
    errorMessage,
    data,
    checksheetInstanceId,
    onSave,
    getExportUrl,
  } = props;

  const history = useHistory();

  const validate = useCallback(
    (
      values: ChecksheetEditFormValues
    ): FormikErrors<ChecksheetEditFormValues> => {
      const errors: FormikErrors<ChecksheetEditFormValues> = {};
      if (submitStatus === ChecksheetInstance_STATUS.ready_for_review) {
        if (values.features) {
          values.features.forEach((featureValues, i) => {
            // validate comment fields
            if (featureValues.normal === null || !featureValues.normal) {
              if (!featureValues.comment) {
                lodashSet(
                  errors,
                  `features[${i}].comment`,
                  'A comment is required'
                );
              }
            }
          });
        }
      }

      return errors;
    },
    []
  );

  const dataSources = useSortedDataSources(data?.checksheetInstance);

  const { canReviewCheckSheetInstances, currentUserId } = useSelector(
    (state: FullState) => ({
      canReviewCheckSheetInstances: selectHasPermission(
        state,
        Enum.User_PERMISSION.can_review_checksheet_instances
      ),
      currentUserId: state.user.loggedInAs,
    })
  );

  if (!data || !checksheetInstanceId || isLoading || errorMessage) {
    return (
      <PageStandard
        name="edit-checksheet-instance"
        header={<Trans>Checksheet</Trans>}
      >
        {errorMessage ? (
          <AlertWarning>{errorMessage}</AlertWarning>
        ) : (
          <Loading />
        )}
      </PageStandard>
    );
  }

  // Can't destructure the `data` prop until after we have checked that the
  // data is in fact present.
  const { checksheetInstance, checksheetTemplate, plotSet } = data;
  const { areas } = checksheetTemplate;
  const joinedAreaNames = areas.map(({ name }) => name).join(', ');

  return (
    <Formik<ChecksheetEditFormValues>
      initialValues={{
        features: checksheetInstance.features.map((feature) => ({
          id: feature.id,
          normal: feature.normal,
          comment: feature.comment,
          review_comment: feature.review_comment,
        })),
        status: checksheetInstance.status,
      }}
      validate={validate}
      onSubmit={async (values, formik) => {
        let checksheetInstanceUpdate = { ...values };

        if (submitStatus) {
          // Change status in the update
          checksheetInstanceUpdate.status = submitStatus;
        }

        try {
          await onSave(checksheetInstanceUpdate);

          if (submitStatus) {
            if (
              [
                ChecksheetInstance_STATUS.under_followup,
                ChecksheetInstance_STATUS.ready_for_review,
                ChecksheetInstance_STATUS.reviewed,
              ].indexOf(submitStatus) !== -1
            ) {
              history.push('/checksheet-instances/');
            } else {
              // Change form status if successful and return to normal automatic submission state
              formik.setFieldValue('status', submitStatus, false);

              // eslint-disable-next-line require-atomic-updates
              submitStatus = null;
            }
          }
        } catch (e) {
          showErrorsInFormik(formik, e, getExpectedFields(values));
        } finally {
          formik.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <PageStandard
          name="edit-checksheet-instance"
          header={
            <>
              {joinedAreaNames} - {checksheetTemplate.name} (
              <TransEnum
                enum="ChecksheetTemplate_FREQUENCY"
                value={checksheetTemplate.frequency}
              />
              )
            </>
          }
        >
          {/* Auto-save when the user makes a change */}
          {!submitStatus && (
            <FormChangeEffectDebounced
              onChange={formik.submitForm}
              delay={1000}
            />
          )}
          <div className="page-content-header-with-back-button-wrapper">
            <BackButton defaultBackUrl="/checksheet-instances" />
            <div className="page-content-header columns-fluid">
              {formik.isSubmitting && (
                <p className="saving-indicator">
                  <Icon type="icon-processing" />
                  <Trans>Saving</Trans>
                </p>
              )}
              <ActionBlock className="text-right">
                <ButtonShowModal
                  name="export"
                  modalContent={() => (
                    <ExportPanel
                      title={<Trans>Export checksheet</Trans>}
                      description={
                        <Trans>
                          The checksheet will be exported with the same settings
                          as displayed on screen.
                        </Trans>
                      }
                      canExportPdf={true}
                      getExportUrl={getExportUrl}
                    />
                  )}
                  iconType="icon-export"
                >
                  <Trans>Export</Trans>
                </ButtonShowModal>
                <Link
                  to={`/plot-sets/${plotSet.name}/plot`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn"
                >
                  <span>
                    <Trans>View plot set</Trans>
                  </span>
                  <Icon type="icon-plot" />
                </Link>
                <ButtonShowModal
                  name="assign-batches"
                  iconType="icon-clipboard-arrow-left"
                  modalContent={(modalProps) => (
                    <AssignBatchesModal
                      {...modalProps}
                      checksheetInstanceId={checksheetInstance.id}
                      dataSources={dataSources}
                      canAddOtherBatches={
                        checksheetTemplate.allow_other_batches
                      }
                    />
                  )}
                >
                  <Trans>Assign batches</Trans>
                </ButtonShowModal>
                {reviewButtons(formik, 'above', dataSources)}
              </ActionBlock>
            </div>
          </div>
          {checksheetTemplate.description && (
            <p id="checksheet-template-description">
              {checksheetTemplate.description}
            </p>
          )}
          {formik.status}
          <div className="table-responsive">
            {/* Note: table is based on the "data sources" (route marches and
              data loggers) stored with the instance at the time of its creation,
              rather than the values stored with the template. This is so the
              instance won't get messed up if the template is later edited. */}
            <ChecksheetDataSourceTable
              dataSources={dataSources}
              timeZone={areas[0].time_zone.name}
            />
          </div>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th className="table-cell-checksheet-feature-name">
                    <Trans>Feature name</Trans>
                  </th>
                  <th className="table-cell-min">
                    <Trans>Normal</Trans>
                  </th>
                  <th className="table-cell-checksheet-comment">
                    <Trans>Comment</Trans>
                  </th>
                  <th>
                    <Trans>Stored plot</Trans>
                  </th>
                  <th>
                    <Trans>Observation points</Trans>
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Note: table is based on the "features" stored with the checksheet
                instance at the time of its creation, rather than iterating over the
                stored plots currently in the plot set. This is so the user-entered
                data on the checksheet instance won't get destroyed if the plot set
                or the template is edited. */}
                {checksheetInstance.features.map((feature, idx) => (
                  <tr key={feature.id}>
                    <td className="table-cell-checksheet-feature-name">
                      {feature.name}
                    </td>
                    <td className="table-cell-min-width">
                      <YNToggleField name={`features.${idx}.normal`} />
                      <FieldError name={`features.${idx}.normal`} />
                    </td>
                    <td className="table-cell-checksheet-comment">
                      {formik.values.features?.[idx].editing_comment ||
                      formik.values.status ===
                        ChecksheetInstance_STATUS.in_progress ||
                      /* DSI Analysts can still edit checksheet instances
                         while ready for review / under follow up */
                      (!canReviewCheckSheetInstances &&
                        (formik.values.status ===
                          ChecksheetInstance_STATUS.ready_for_review ||
                          formik.values.status ===
                            ChecksheetInstance_STATUS.under_followup)) ? (
                        <>
                          <Field
                            component="textarea"
                            name={`features.${idx}.comment`}
                            rows="1"
                          />
                          <FieldError name={`features.${idx}.comment`} />
                        </>
                      ) : (
                        feature.comment && (
                          <p>
                            {feature.comment}
                            {
                              /* DSI Senior Analysts can edit feature comments, but the have
                               to first click the Edit button next to them */
                              canReviewCheckSheetInstances &&
                              (formik.values.status ===
                                ChecksheetInstance_STATUS.ready_for_review ||
                                formik.values.status ===
                                  ChecksheetInstance_STATUS.under_followup) ? (
                                <Button
                                  className="edit-comment-button"
                                  iconType="icon-edit"
                                  title={t`Edit comment`}
                                  iconOnly
                                  onClick={() => {
                                    formik.setFieldValue(
                                      `features.${idx}.editing_comment`,
                                      true
                                    );
                                  }}
                                >
                                  Edit
                                </Button>
                              ) : null
                            }
                          </p>
                        )
                      )}
                      {formik.values.features?.[idx].editing_review_comment ? (
                        <>
                          <Field
                            component="textarea"
                            name={`features.${idx}.review_comment`}
                            rows="1"
                          />
                          <FieldError name={`features.${idx}.review_comment`} />
                        </>
                      ) : canReviewCheckSheetInstances &&
                        (formik.values.status ===
                          ChecksheetInstance_STATUS.ready_for_review ||
                          formik.values.status ===
                            ChecksheetInstance_STATUS.under_followup) &&
                        feature.review_comment_author === currentUserId &&
                        feature.review_comment ? (
                        <>
                          <p>
                            <strong>
                              <Trans>Reviewer</Trans>:
                            </strong>{' '}
                            {feature.review_comment}
                            <Button
                              className="edit-comment-button"
                              iconType="icon-edit"
                              title={t`Edit review comment`}
                              iconOnly
                              onClick={() => {
                                formik.setFieldValue(
                                  `features.${idx}.editing_review_comment`,
                                  true
                                );
                              }}
                            >
                              Edit
                            </Button>
                          </p>
                        </>
                      ) : (formik.values.status ===
                          ChecksheetInstance_STATUS.ready_for_review ||
                          formik.values.status ===
                            ChecksheetInstance_STATUS.reviewed ||
                          formik.values.status ===
                            ChecksheetInstance_STATUS.under_followup) &&
                        feature.review_comment ? (
                        <p>
                          <strong>
                            <Trans>Reviewer</Trans>:
                          </strong>{' '}
                          {feature.review_comment}
                        </p>
                      ) : (
                        <HasPermission check="can_review_checksheet_instances">
                          <ButtonShowModal
                            name="show-reviewer-comment-modal"
                            modalContent={(modalProps) => (
                              <ReviewerCommentModal
                                onSave={(review_comment: string) => {
                                  formik.setFieldValue(
                                    `features.${idx}.review_comment`,
                                    review_comment
                                  );
                                }}
                                {...modalProps}
                                {...{ checksheetInstance, featureIdx: idx }}
                              />
                            )}
                          >
                            <Trans>Add reviewer comment</Trans>
                          </ButtonShowModal>
                        </HasPermission>
                      )}
                    </td>

                    <td>
                      <Link
                        to={`/stored-plots/${feature.stored_plot.name}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {feature.stored_plot.name}
                      </Link>
                    </td>
                    <td>
                      <ChecksheetFeatureObservationPoints
                        feature={feature}
                        areaCodes={areas.map(({ code }) => code)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {checksheetTemplate.notes && (
            <p id="checksheet-template-notes">{checksheetTemplate.notes}</p>
          )}
          <ActionBlock className="text-right">
            {reviewButtons(formik, 'below', dataSources)}
          </ActionBlock>
        </PageStandard>
      )}
    </Formik>
  );
}
