import React from 'react';
import { connect } from 'react-redux';
import { withI18n, withI18nProps } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import NavItem from './navitem';
import NavCategory from './navcategory';
import Button from 'components/base/button/button';
import { clickOutsideMenu, expandNavMenu, collapseNavMenu } from 'ducks/ui';

import './navmenu.scss';
import dmsLogo from 'assets/images/dms-logo-white.svg';
import dsiLogo from 'assets/images/dsi-logo.svg';
import dsiLogoSmall from 'assets/images/dsi-logo-small.svg';
import { FullState } from 'main/reducers';
import { DMSRoutes } from 'main/routes';
import { Enum } from 'util/backendapi/models/api.interfaces';

interface OwnProps {
  navTransitionClassName: null | string;
}

interface StateProps {
  isNavCollapsed: boolean;
  isSubMenuOpen: boolean;
}

interface DispatchProps {
  clickOutsideMenu: () => void;
  expandNavMenu: () => void;
  collapseNavMenu: () => void;
}

type Props = OwnProps & StateProps & DispatchProps & withI18nProps;

/**
 * Our main navigation menu, as used in PageStandard. Most of its props
 * are relating to animating/coordinating its collapse & expand behavior,
 * with the page's main content block.
 *
 * @export
 * @class NavMenu
 * @extends {React.Component}
 */
class _NavMenu extends React.Component<Props> {
  static mapStateToProps = (state: FullState): StateProps => {
    return {
      isNavCollapsed: state.ui.isNavMenuCollapsed,
      isSubMenuOpen: Object.keys(state.ui.subMenu || {}).some(
        (key) => Boolean(state.ui.subMenu[key]) === false
      ),
    };
  };

  static mapDispatchToProps: DispatchProps = {
    clickOutsideMenu,
    expandNavMenu,
    collapseNavMenu,
  };

  handleClickOutside = () => {
    this.props.isSubMenuOpen && this.props.clickOutsideMenu();
  };

  render() {
    let navHeader;
    let navMobileToggle;
    let navFooter;
    if (this.props.isNavCollapsed) {
      navHeader = (
        <img
          src={dmsLogo}
          alt={this.props.i18n._(t`Dam Monitoring System`)}
          width="40"
        />
      );

      navMobileToggle = (
        <Button
          id="nav-toggle-control"
          iconType="icon-arrow-double-right"
          iconOnly={true}
          title={t`Expand sidebar`}
          onClick={this.props.expandNavMenu}
        >
          <Trans>Expand sidebar</Trans>
        </Button>
      );

      navFooter = (
        <React.Fragment>
          <span className="visuallyhidden">
            <Trans>Powered by</Trans>
          </span>
          <img
            src={dsiLogoSmall}
            width="34"
            alt={this.props.i18n._(t`Dam Safety Intelligence`)}
          />
        </React.Fragment>
      );
    } else {
      navHeader = (
        <React.Fragment>
          <img
            src={dmsLogo}
            alt={this.props.i18n._(t`Dam Monitoring System`)}
            width="70"
          />
        </React.Fragment>
      );

      navMobileToggle = (
        <Button
          id="nav-toggle-control"
          iconType="icon-arrow-double-left"
          onClick={this.props.collapseNavMenu}
        >
          <Trans>Collapse sidebar</Trans>
        </Button>
      );

      navFooter = (
        <React.Fragment>
          <span>
            <Trans>Powered by</Trans>
          </span>
          <img
            src={dsiLogo}
            width="122"
            alt={this.props.i18n._(t`Dam Safety Intelligence`)}
          />
        </React.Fragment>
      );
    }

    const navMenuClasses = classNames('nav-menu', {
      'sub-menu-open': this.props.isSubMenuOpen,
    });

    return (
      <div
        className={classNames(
          'navigation-block',
          this.props.navTransitionClassName
        )}
        id="navigation-block"
      >
        <header id="nav-header">{navHeader}</header>
        <div className="nav-menu-wrapper">
          <nav id="nav-menu" className={navMenuClasses}>
            <ul>
              <NavItem
                name="dashboard"
                route={DMSRoutes.DASHBOARD}
                iconType="icon-house"
              >
                <Trans>Dashboard</Trans>
              </NavItem>
              <NavCategory
                name="data-processing"
                header={<Trans>Data processing</Trans>}
                iconType="icon-new-reading"
                items={[
                  {
                    name: 'admin-file-upload-gate',
                    children: <Trans>File upload gate</Trans>,
                    route: DMSRoutes.FILE_GATE,
                  },
                  {
                    name: 'maintain-checksheet-templates',
                    children: <Trans>Checksheet templates</Trans>,
                    route: DMSRoutes.CHECKSHEET_TEMPLATE_LIST,
                  },
                  {
                    name: 'maintain-checksheet-instances',
                    children: <Trans>Checksheet instances</Trans>,
                    route: DMSRoutes.CHECKSHEET_INSTANCE_LIST,
                  },
                  {
                    name: 'batches',
                    children: <Trans>Batches</Trans>,
                    route: DMSRoutes.READINGS_BATCH_LIST,
                  },
                  {
                    name: 'inspection-reports',
                    children: <Trans>Inspection reports</Trans>,
                    route: DMSRoutes.INSPECTION_BATCH_LIST,
                  },
                  {
                    name: 'maintain-route-march',
                    children: <Trans>Route march</Trans>,
                    route: DMSRoutes.ROUTE_MARCH_LIST,
                  },
                  {
                    name: 'reprocessingrequests',
                    children: <Trans>Reprocessing requests</Trans>,
                    linkUrl: `/reading-processing-requests/?status=${[
                      Enum.ReadingProcessingRequest_STATUS.new,
                      Enum.ReadingProcessingRequest_STATUS.queued,
                      Enum.ReadingProcessingRequest_STATUS.processing,
                    ].join(',')}`,
                    route: DMSRoutes.READING_PROCESSING_REQUEST_LIST,
                  },
                  {
                    name: 'reading-retirement-requests',
                    children: <Trans>Retire adjusted readings</Trans>,
                    linkUrl: `/reading-retirement-requests/?status=${[
                      Enum.ReadingRetirementRequest_STATUS.new,
                      Enum.ReadingRetirementRequest_STATUS.queued,
                      Enum.ReadingRetirementRequest_STATUS.processing,
                    ].join(',')}`,
                    route: DMSRoutes.READING_RETIREMENT_REQUEST_LIST,
                  },
                ]}
              />
              <NavCategory
                name="view-data"
                header={<Trans>View data</Trans>}
                iconType="icon-plot"
                items={[
                  {
                    name: 'quickplot',
                    children: <Trans>Quick plot</Trans>,
                    route: DMSRoutes.QUICK_PLOT_LANDING,
                  },
                  {
                    name: 'scatterplot',
                    children: <Trans>Scatter plot</Trans>,
                    route: DMSRoutes.SCATTER_PLOT_LANDING,
                  },
                  {
                    name: 'cummulativeplot',
                    children: <Trans>Cumulative plot</Trans>,
                    route: DMSRoutes.CUMULATIVE_PLOT_LANDING,
                  },
                  {
                    name: 'storedplots',
                    children: <Trans>Stored plots</Trans>,
                    linkUrl: '/stored-plots',
                    route: DMSRoutes.STORED_PLOT_LIST,
                  },
                  {
                    name: 'plotsets',
                    children: <Trans>Plot sets</Trans>,
                    route: DMSRoutes.PLOT_SET_LIST,
                  },
                  {
                    name: 'quicklist',
                    children: <Trans>Quick list</Trans>,
                    route: DMSRoutes.QUICK_LIST_LIST,
                  },
                  {
                    name: 'storedlistlanding',
                    children: <Trans>Stored lists</Trans>,
                    route: DMSRoutes.STORED_LIST_LIST,
                  },
                  {
                    name: 'maintain-comments',
                    children: <Trans>Comments</Trans>,
                    route: DMSRoutes.COMMENT_LIST,
                  },
                  {
                    name: 'maintain-media',
                    children: <Trans>Media</Trans>,
                    route: DMSRoutes.MEDIA_LIST,
                  },
                  {
                    name: 'map',
                    children: <Trans>Map</Trans>,
                    route: DMSRoutes.MAP_GROUP,
                  },
                ]}
              />
              <NavCategory
                name="maintain-data"
                header={<Trans>Maintain data</Trans>}
                iconType="icon-spanner"
                items={[
                  {
                    name: 'maintain-area',
                    children: <Trans>Area</Trans>,
                    route: DMSRoutes.AREA_LIST,
                  },
                  {
                    name: 'maintain-site',
                    children: <Trans>Site</Trans>,
                    route: DMSRoutes.SITE_LIST,
                  },
                  {
                    name: 'maintain-observation-point',
                    children: <Trans>Observation point</Trans>,
                    route: DMSRoutes.OBSERVATION_POINT_LIST,
                  },
                  {
                    name: 'maintain-instrument-type',
                    children: <Trans>Instrument types</Trans>,
                    route: DMSRoutes.INSTRUMENT_TYPE_LIST,
                  },
                  {
                    name: 'list-data-loggers',
                    children: <Trans>List data loggers</Trans>,
                    route: DMSRoutes.DATA_LOGGER_LIST,
                    exact: true,
                  },
                  {
                    name: 'newdatalogger',
                    children: <Trans>New data logger</Trans>,
                    route: DMSRoutes.DATA_LOGGER_NEW,
                    exact: true,
                  },
                  {
                    name: 'maintain-performance-indicator',
                    children: <Trans>Performance Indicators</Trans>,
                    route: DMSRoutes.PERFORMANCE_INDICATOR_LIST,
                    exact: true,
                  },
                ]}
              />
              <NavCategory
                name="alarms"
                header={<Trans>Alarms</Trans>}
                iconType="icon-bell"
                items={[
                  {
                    name: 'alarm-reports',
                    children: <Trans>Alarm reports</Trans>,
                    route: DMSRoutes.ALARM_REPORT_LIST,
                  },
                  {
                    name: 'alarm-parameters',
                    children: <Trans>Alarm parameters</Trans>,
                    route: DMSRoutes.ALARM_PARAMETER_LANDING,
                  },
                  {
                    name: 'alarm-notifications',
                    children: <Trans>Alarm notifications</Trans>,
                    route: DMSRoutes.ALARM_NOTIFICATION_LIST,
                  },
                ]}
              />
              <NavCategory
                name="reports"
                header={<Trans>Reports</Trans>}
                iconType="icon-audit-log"
                items={[
                  {
                    name: 'maintain-saved-reports',
                    children: <Trans>Saved reports</Trans>,
                    route: DMSRoutes.SAVED_REPORT_LIST,
                  },
                  {
                    name: 'my-export-report',
                    children: <Trans>My exports</Trans>,
                    route: DMSRoutes.MY_EXPORTS_LIST,
                  },
                  {
                    name: 'alarm-parameter-report',
                    children: <Trans>Alarm parameter report</Trans>,
                    route: DMSRoutes.ALARM_PARAMETER_REPORT,
                  },
                  {
                    name: 'data-review-report',
                    children: <Trans>Data review report</Trans>,
                    route: DMSRoutes.DATA_REVIEW_REPORT,
                  },
                  {
                    name: 'static-data-report',
                    children: <Trans>Static data report</Trans>,
                    route: DMSRoutes.STATIC_DATA_REPORT,
                  },
                  {
                    name: 'logger-calibration-report',
                    children: <Trans>Logger calibration report</Trans>,
                    route: DMSRoutes.DATA_LOGGER_CHANNELS,
                    linkUrl:
                      '/data-logger-channels?columns=channel_number,change_datetime,observation_point__code,observation_point_formula__formula__code,observation_point__instrument_type__code,temperature_compensation_channel,pressure_compensation_channel,c1,c2,c3,c4,status',
                  },
                  {
                    name: 'route-march-schedule',
                    children: <Trans>Route march schedule</Trans>,
                    route: DMSRoutes.ROUTE_MARCH_SCHEDULE,
                  },
                  {
                    name: 'observation-point-formulas',
                    children: <Trans>Formula assignment report</Trans>,
                    route: DMSRoutes.OBSERVATION_POINT_FORMULA_LIST,
                  },
                  {
                    name: 'observation-point-formula-constants',
                    // TODO 84724 departure from ACs
                    children: <Trans>Formula constants report</Trans>,
                    route: DMSRoutes.OBSERVATION_POINT_FORMULA_CONSTANT_LIST,
                  },
                  {
                    name: 'alarm-comments-report',
                    children: <Trans>Alarm comments report</Trans>,
                    route: DMSRoutes.ALARM_COMMENTS_REPORT,
                  },
                  {
                    name: 'reading-comments-report',
                    children: <Trans>Reading comments report</Trans>,
                    route: DMSRoutes.READING_COMMENTS_REPORT,
                  },
                ]}
              />
              <NavCategory
                name="administration"
                header={<Trans>Administration</Trans>}
                iconType="icon-cog"
                items={[
                  {
                    name: 'maintain-users',
                    children: <Trans>Maintain users</Trans>,
                    linkUrl: `/users/?is_active=true`,
                    route: DMSRoutes.USER_LIST,
                  },
                  {
                    name: 'maintain-groups',
                    children: <Trans>Group</Trans>,
                    route: DMSRoutes.GROUP_LIST,
                  },
                  {
                    name: 'maintain-clients',
                    children: <Trans>Clients</Trans>,
                    route: DMSRoutes.CLIENT_LIST,
                  },
                  {
                    name: 'maintain-audit-log',
                    children: <Trans>Audit log</Trans>,
                    route: DMSRoutes.AUDIT_LOG_LIST,
                  },
                ]}
              />
            </ul>
          </nav>
        </div>
        {navMobileToggle}
        <footer id="nav-footer" className="powered-by">
          {navFooter}
        </footer>
      </div>
    );
  }
}

const NavMenu = withI18n()(
  connect<StateProps, DispatchProps, OwnProps & withI18nProps, FullState>(
    _NavMenu.mapStateToProps,
    _NavMenu.mapDispatchToProps
  )(onClickOutside(_NavMenu))
);

export default NavMenu;
