import sortBy from 'lodash/sortBy';
import { Model, Filter } from 'util/backendapi/models/api.interfaces';
import { Reducer } from 'redux';
import { reorderList } from 'util/misc';
import { StandardThunk } from 'main/store';
import {
  getApi,
  postApi,
  patchApi,
  getPaginated,
  deleteApi,
} from 'util/backendapi/fetch';
import { t } from '@lingui/macro';
import { PaginationResponseData } from 'util/backendapi/pagination';
import { getCurrentDatetime, formatDatetimeForBackendUrl } from 'util/dates';
import { errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  UNMOUNT_STORED_LIST_REPORT:
    'dms/storedList/detail/UNMOUNT_STORED_LIST_REPORT',
  FETCH_STORED_LIST_START: 'dms/storedList/detail/FETCH_STORED_LIST_START',
  FETCH_STORED_LIST_ERROR: 'dms/storedList/detail/FETCH_STORED_LIST_ERROR',
  FETCH_STORED_LIST_RESPONSE:
    'dms/storedList/detail/FETCH_STORED_LIST_RESPONSE',
  FETCH_STORED_LIST_ITEM_LIST_START:
    'dms/storedList/detail/FETCH_STORED_LIST_ITEM_LIST_START',
  FETCH_STORED_LIST_ITEM_LIST_RESPONSE:
    'dms/storedList/detail/FETCH_STORED_LIST_ITEM_LIST_RESPONSE',
  FETCH_STORED_LIST_ITEM_LIST_ERROR:
    'dms/storedList/detail/FETCH_STORED_LIST_ITEM_LIST_ERROR',
  FETCH_STORED_LIST_READINGS_START:
    'dms/storedList/detail/FETCH_STORED_LIST_READINGS_START',
  FETCH_STORED_LIST_READINGS_RESPONSE:
    'dms/storedList/detail/FETCH_STORED_LIST_READINGS_RESPONSE',
  FETCH_STORED_LIST_READINGS_ERROR:
    'dms/storedList/detail/FETCH_STORED_LIST_READINGS_ERROR',
  ADD_OBSERVATION_POINT_START:
    'dms/storedList/detail/ADD_OBSERVATION_POINT_START',
  ADD_OBSERVATION_POINT_RESPONSE:
    'dms/storedList/detail/ADD_OBSERVATION_POINT_RESPONSE',
  ADD_OBSERVATION_POINT_ERROR:
    'dms/storedList/detail/ADD_OBSERVATION_POINT_ERROR',
  UPDATE_OBS_POINT_POSITION_START:
    'dms/storedList/detail/UPDATE_OBS_POINT_POSITION_START',
  UPDATE_OBS_POINT_POSITION_RESPONSE:
    'dms/storedList/detail/UPDATE_OBS_POINT_POSITION_RESPONSE',
  UPDATE_OBS_POINT_POSITION_ERROR:
    'dms/storedList/detail/UPDATE_OBS_POINT_POSITION_ERROR',
  REMOVE_OBSPOINT_START: 'dms/storedlist/detail/REMOVE_OBSPOINT_START',
  REMOVE_OBSPOINT_RESPONSE: 'dms/storedlist/detail/REMOVE_OBSPOINT_RESPONSE',
  REMOVE_OBSPOINT_ERROR: 'dms/storedlist/detail/REMOVE_OBSPOINT_ERROR',
} as const;

export function unmountStoredListReport() {
  return {
    type: ActionTypes.UNMOUNT_STORED_LIST_REPORT,
  };
}

export function fetchStoredListItemListStart(storedListId: number) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_ITEM_LIST_START,
    payload: { storedListId },
  };
}

export function fetchStoredListItemListResponse(
  storedListItems: Model.StoredListItemDecorated[]
) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_ITEM_LIST_RESPONSE,
    payload: { storedListItems: storedListItems },
  };
}

export function fetchStoredListItemListError(errorMessage: string) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_ITEM_LIST_ERROR,
    errorMessage: errorMessage,
  };
}

export function fetchStoredListStart() {
  return {
    type: ActionTypes.FETCH_STORED_LIST_START,
  };
}

export function fetchStoredListResponse(
  storedList: Model.PolymorphicStoredList,
  timeZone: Model.TimeZone
) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_RESPONSE,
    payload: { storedList, timeZone },
  };
}

export function fetchStoredListError(errorMessage: string) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_ERROR,
    errorMessage: errorMessage,
  };
}

export type StoredListDetailAction =
  | ReturnType<typeof unmountStoredListReport>
  | ReturnType<typeof fetchStoredListStart>
  | ReturnType<typeof fetchStoredListError>
  | ReturnType<typeof fetchStoredListResponse>
  | ReturnType<typeof fetchStoredListItemListStart>
  | ReturnType<typeof fetchStoredListItemListResponse>
  | ReturnType<typeof fetchStoredListItemListError>
  | ReturnType<typeof fetchStoredListReadingsStart>
  | ReturnType<typeof fetchStoredListReadingsResponse>
  | ReturnType<typeof fetchStoredListReadingsError>
  | ReturnType<typeof addObservationPointStart>
  | ReturnType<typeof addObservationPointResponse>
  | ReturnType<typeof addObservationPointError>
  | ReturnType<typeof updateObsPointPositionStart>
  | ReturnType<typeof updateObsPointPositionResponse>
  | ReturnType<typeof updateObsPointPositionError>
  | ReturnType<typeof removeObsPointStart>
  | ReturnType<typeof removeObsPointResponse>
  | ReturnType<typeof removeObsPointError>;

export interface StoredListDetailState {
  loading: boolean;
  errorMessage: string | null;
  storedList: Model.PolymorphicStoredList | null;
  timeZone: Model.TimeZone | null;
  observationPointOutputs: {
    loading: boolean;
    errorMessage: string | null;
    storedListId: number | null;
    storedListItems: Model.StoredListItemDecorated[];
  };
  readings: {
    fetchKey: string | null;
    loading: boolean;
    errorMessage: string | null;
    pagination: PaginationResponseData | null;
    report: Model.PolymorphicStoredListReading[];
    generatedDatetime: string | null;
  };
}

export function storedListDetailInitialState(): StoredListDetailState {
  return {
    loading: true,
    errorMessage: null,
    storedList: null,
    timeZone: null,
    observationPointOutputs: {
      loading: true,
      errorMessage: null,
      storedListId: null,
      storedListItems: [],
    },
    readings: {
      fetchKey: null,
      loading: true,
      errorMessage: null,
      pagination: null,
      report: [],
      generatedDatetime: null,
    },
  };
}

export const storedListDetailReducer: Reducer<
  StoredListDetailState,
  StoredListDetailAction
> = function (
  state = storedListDetailInitialState(),
  action
): StoredListDetailState {
  switch (action.type) {
    case ActionTypes.UNMOUNT_STORED_LIST_REPORT:
      return storedListDetailInitialState();
    case ActionTypes.FETCH_STORED_LIST_ITEM_LIST_START:
      return {
        ...state,
        observationPointOutputs: {
          ...state.observationPointOutputs,
          errorMessage: null,
          loading: true,
          storedListId: action.payload.storedListId,
          storedListItems: [],
        },
      };
    case ActionTypes.FETCH_STORED_LIST_ITEM_LIST_RESPONSE:
      return {
        ...state,
        observationPointOutputs: {
          ...state.observationPointOutputs,
          errorMessage: null,
          loading: false,
          storedListItems: action.payload.storedListItems,
        },
        loading: false,
      };
    case ActionTypes.FETCH_STORED_LIST_ITEM_LIST_ERROR:
      return {
        ...state,
        observationPointOutputs: {
          ...state.observationPointOutputs,
          errorMessage: action.errorMessage,
          loading: false,
        },
      };
    case ActionTypes.FETCH_STORED_LIST_START:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ActionTypes.FETCH_STORED_LIST_RESPONSE:
      return {
        ...state,
        loading: false,
        storedList: action.payload.storedList,
        timeZone: action.payload.timeZone,
      };
    case ActionTypes.FETCH_STORED_LIST_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: action.errorMessage,
      };
    case ActionTypes.ADD_OBSERVATION_POINT_START:
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case ActionTypes.ADD_OBSERVATION_POINT_RESPONSE:
      return {
        ...state,
        loading: false,
        observationPointOutputs: {
          loading: false,
          errorMessage: null,
          storedListId: state.observationPointOutputs.storedListId,
          storedListItems: state.observationPointOutputs.storedListItems.concat(
            [action.payload]
          ),
        },
      };
    case ActionTypes.ADD_OBSERVATION_POINT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.UPDATE_OBS_POINT_POSITION_START:
      return {
        ...state,
      };
    case ActionTypes.UPDATE_OBS_POINT_POSITION_RESPONSE:
      return {
        ...state,
        observationPointOutputs: {
          ...state.observationPointOutputs,
          storedListItems: reorderList(
            state.observationPointOutputs.storedListItems,
            action.payload.existingPosition,
            action.payload.storedListItem
          ),
        },
      };
    case ActionTypes.UPDATE_OBS_POINT_POSITION_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case ActionTypes.FETCH_STORED_LIST_READINGS_START:
      return {
        ...state,
        readings: {
          ...state.readings,
          fetchKey: action.fetchKey,
          loading: true,
          errorMessage: null,
        },
      };
    case ActionTypes.FETCH_STORED_LIST_READINGS_RESPONSE:
      if (action.fetchKey !== state.readings.fetchKey) {
        return state;
      } else {
        return {
          ...state,
          readings: {
            ...state.readings,
            loading: false,
            report: action.payload.readings,
            pagination: action.payload.pagination,
            generatedDatetime: action.payload.generatedDatetime,
          },
        };
      }
    case ActionTypes.FETCH_STORED_LIST_READINGS_ERROR:
      if (action.fetchKey !== state.readings.fetchKey) {
        return state;
      } else {
        return {
          ...state,
          readings: {
            ...state.readings,
            loading: false,
            errorMessage: action.payload,
            generatedDatetime: null,
            pagination: null,
            report: [],
          },
        };
      }
    case ActionTypes.REMOVE_OBSPOINT_START:
      return {
        ...state,
        observationPointOutputs: {
          ...state.observationPointOutputs,
          loading: true,
        },
      };
    case ActionTypes.REMOVE_OBSPOINT_RESPONSE:
      return {
        ...state,
        observationPointOutputs: {
          ...state.observationPointOutputs,
          storedListItems: state.observationPointOutputs.storedListItems
            .filter((storedListItem) => storedListItem.id !== action.payload)
            .map((storedListItem, index) =>
              index + 1 === storedListItem.position
                ? storedListItem
                : { ...storedListItem, position: index + 1 }
            ),
          loading: false,
        },
      };
    case ActionTypes.REMOVE_OBSPOINT_ERROR:
      return {
        ...state,
        observationPointOutputs: {
          ...state.observationPointOutputs,
          errorMessage: action.errorMessage,
          loading: false,
        },
      };
    default:
      return state;
  }
};

export function fetchStoredList(storedListId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(fetchStoredListStart());
    try {
      const storedList = await getApi(`/stored-lists/${storedListId}/`);
      const timeZone = (await getApi(`/areas/${storedList.area}/`)).time_zone;
      dispatch(fetchStoredListResponse(storedList, timeZone));
    } catch (e) {
      return dispatch(fetchStoredListError(e.message));
    }
  };
}

export function fetchStoredListItemList(storedListId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(fetchStoredListItemListStart(storedListId));
    try {
      const storedListItems = sortBy(
        await getApi('/stored-list-items/', {
          stored_list: Number(storedListId),
        }),
        (stored_list_item) => stored_list_item.position
      );
      dispatch(fetchStoredListItemListResponse(storedListItems));
    } catch (e) {
      return dispatch(fetchStoredListItemListStart(e.message));
    }
  };
}

function addObservationPointStart() {
  return {
    type: ActionTypes.ADD_OBSERVATION_POINT_START,
  };
}
function addObservationPointError(errorMessage: string) {
  return {
    type: ActionTypes.ADD_OBSERVATION_POINT_ERROR,
    errorMessage,
  };
}
function addObservationPointResponse(payload: Model.StoredListItemDecorated) {
  return {
    type: ActionTypes.ADD_OBSERVATION_POINT_RESPONSE,
    payload,
  };
}

export function addObservationPoint(
  record: ForPostOrPut<Model.StoredListItem>
): StandardThunk {
  return async function (dispatch) {
    dispatch(addObservationPointStart());
    try {
      const newStoredList = await postApi('/stored-list-items/', record);
      dispatch(addObservationPointResponse(newStoredList));
    } catch (e) {
      dispatch(addObservationPointError(errorToString(e)));
      throw e;
    }
  };
}

export function updateObsPointPositionStart() {
  return {
    type: ActionTypes.UPDATE_OBS_POINT_POSITION_START,
  };
}

export function updateObsPointPositionResponse(
  existingPosition: number,
  storedListItem: Model.StoredListItemDecorated
) {
  return {
    type: ActionTypes.UPDATE_OBS_POINT_POSITION_RESPONSE,
    payload: { storedListItem: storedListItem, existingPosition },
  };
}

export function updateObsPointPositionError(errorMessage: string) {
  return {
    type: ActionTypes.UPDATE_OBS_POINT_POSITION_ERROR,
    errorMessage: errorMessage,
  };
}

export function updateObsPointPosition(
  obsPointId: number,
  srcPosition: number,
  destPosition: number
): StandardThunk {
  return async function (dispatch, _getState, { i18n }) {
    dispatch(updateObsPointPositionStart());
    try {
      const updatedObsPoint = await patchApi(
        `/stored-list-items/${obsPointId}/`,
        {
          position: destPosition,
        }
      );
      dispatch(updateObsPointPositionResponse(srcPosition, updatedObsPoint));
    } catch (e) {
      if (!e.error) {
        dispatch(
          updateObsPointPositionError(i18n._(t`Error updating positions`))
        );
      } else {
        dispatch(updateObsPointPositionError(e.error));
      }
    }
  };
}

function fetchStoredListReadingsStart(fetchKey: string) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_READINGS_START,
    fetchKey,
  };
}

function fetchStoredListReadingsResponse(
  fetchKey: string,
  readings: Model.PolymorphicStoredListReading[],
  pagination: PaginationResponseData | null
) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_READINGS_RESPONSE,
    fetchKey,
    payload: {
      readings,
      pagination,
      generatedDatetime: getCurrentDatetime(),
    },
  };
}

function fetchStoredListReadingsError(fetchKey: string, errorMessage: string) {
  return {
    type: ActionTypes.FETCH_STORED_LIST_READINGS_ERROR,
    fetchKey,
    payload: errorMessage,
  };
}

export function fetchStoredListReadings(
  storedListId: number,
  filters: Filter.StoredListsReadings
): StandardThunk {
  return async function (dispatch) {
    const fetchKey = JSON.stringify({ storedListId, filters });
    dispatch(fetchStoredListReadingsStart(fetchKey));
    try {
      const { pagination, data } = await getPaginated(
        `/stored-lists/${storedListId}/readings/`,
        {
          ...filters,
          previous_datetime: filters.previous_datetime
            ? formatDatetimeForBackendUrl(filters.previous_datetime)
            : undefined,
          current_datetime: filters.current_datetime
            ? formatDatetimeForBackendUrl(filters.current_datetime)
            : undefined,
        }
      );
      return dispatch(
        fetchStoredListReadingsResponse(fetchKey, data, pagination)
      );
    } catch (e) {
      return dispatch(fetchStoredListReadingsError(fetchKey, errorToString(e)));
    }
  };
}

function removeObsPointStart() {
  return {
    type: ActionTypes.REMOVE_OBSPOINT_START,
  };
}

function removeObsPointResponse(payload: number) {
  return {
    type: ActionTypes.REMOVE_OBSPOINT_RESPONSE,
    payload,
  };
}

function removeObsPointError(errorMessage: string) {
  return {
    type: ActionTypes.REMOVE_OBSPOINT_ERROR,
    errorMessage,
  };
}

export function removeObsPoint(id: number): StandardThunk {
  return async (dispatch) => {
    dispatch(removeObsPointStart());

    try {
      await deleteApi(`/stored-list-items/${id}/`);
      dispatch(removeObsPointResponse(id));
    } catch (e) {
      dispatch(removeObsPointError(e));
    }
  };
}
