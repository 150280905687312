import React, { useEffect, useState } from 'react';
import { extent } from 'd3-array';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import {
  XYPlot,
  LineSeries,
  WhiskerSeries,
  XAxis,
  YAxis,
  AxisUtils,
} from 'react-vis';
import { LabelSeries, CustomSVGSeries } from './react-vis-hacks';
import {
  getColorForIdx,
  getLabelForIdx,
  getMarkerForIdx,
  AXIS_LABEL_FONT_HEIGHT,
  Y_AXIS_TICK_PADDING,
  TICK_SIZE,
  SERIES_LABEL_PADDING,
  SSR_TICK_LABEL_CHAR_WIDTH,
} from './timeseriesplot';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import {
  formatDateForDisplay,
  convertDatetimeToDate,
  formatDatetimeForDisplay,
} from 'util/dates';
import './timeseriesplot.scss';
import {
  SurveyLevellingReading,
  SurveyLevellingSerie,
  selectSurveyLevellingPlotData,
} from 'ducks/plot/survey-levelling';
import { FullState } from 'main/reducers';
import { StoredSurveyLevellingPlotWithArea } from 'ducks/stored-plot/detail';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import Loading from 'components/base/loading/loading';
import useResizeObserver from 'hooks/use-resize-observer';
import { AlertWarning } from 'components/base/alert/alert';
import { scaleLinear } from 'd3-scale';
import {
  calculateSurveyAnnotationPlotLines,
  computeSurveyLevellingPlotDateRange,
} from './surveyLevellingPlotSelectors';
import { detectCliMode } from 'util/export';
import { TransEnum } from 'components/base/i18n/TransEnum';
import {
  StoredPlotAnnotationLabelPosition,
  StoredPlotAnnotation_STUB_POSITION,
} from 'util/backendapi/types/Enum';

const axisStyle = {
  line: {
    stroke: '#cfcfcf',
  },
};

// The height of the top and bottom margins. Just needs to make space for
// the axis labels.
const MARGIN_TOP_BOTTOM = AXIS_LABEL_FONT_HEIGHT;

// The width of the WhiskerSeries cross bar.
const CROSS_BAR_WIDTH = 15;

// Initial height/width of the plot (before it's dynamically resized to fill
// the available space.) Ignored if "initialHeight" and/or "initialWidth"
// props are passed.
const DEFAULT_PLOT_HEIGHT = 500;
const DEFAULT_PLOT_WIDTH = 800;

const ANNOTATION_STUB_LENGTH = 100;
const ANNOTATION_LINE_COLOR = '#6c6c6c';

interface Props {
  storedPlot: StoredSurveyLevellingPlotWithArea;
}

export function SurveyLevellingPlot(props: Props) {
  const { storedPlot } = props;
  const plotState = useSelector(
    (state: FullState) =>
      selectSurveyLevellingPlotData(state, storedPlot.id) ?? {
        errorMessage: '',
        isLoading: false,
        observationPoints: [],
        surveySeries: [],
      }
  );
  const { observationPoints, surveySeries } = plotState;

  const { minDatetime, maxDatetime } =
    computeSurveyLevellingPlotDateRange(storedPlot);
  const timeZone = storedPlot.area.time_zone.name;

  return (
    <>
      <div className="plot-item-detail">
        <h3 className="plot-item-header">
          {storedPlot.title} (
          {
            <TransEnum
              enum="StoredSurveyLevellingPlot_ERROR_BARS__LONG"
              value={storedPlot.error_bars}
            />
          }
          )
        </h3>
        <p data-testid="plot-date-range">
          {formatDatetimeForDisplay(minDatetime, timeZone)} -{' '}
          {formatDatetimeForDisplay(maxDatetime, timeZone)}
        </p>
      </div>
      {plotState.errorMessage && (
        <ErrorNotice>{plotState.errorMessage}</ErrorNotice>
      )}
      {plotState.isLoading ? (
        <Loading />
      ) : (
        observationPoints &&
        surveySeries && (
          <SurveyLevellingPlotView
            {...{ storedPlot, observationPoints, surveySeries }}
          />
        )
      )}
    </>
  );
}

interface ViewProps {
  storedPlot: StoredSurveyLevellingPlotWithArea;
  surveySeries: SurveyLevellingSerie[];
  observationPoints: Model.ObservationPointDecorated[];
  initialWidth?: number;
  initialHeight?: number;
}

export function SurveyLevellingPlotView(props: ViewProps) {
  const { observationPoints, storedPlot, surveySeries } = props;

  const markerPool = React.useMemo(() => {
    let markerIdx = 0;
    return surveySeries.map((serie) => {
      if (serie.showPlotMarkers) {
        return getMarkerForIdx(markerIdx++);
      } else {
        return '';
      }
    });
  }, [surveySeries]);

  const seriesWithErrorBars = React.useMemo(
    () => [
      // initial/previous
      storedPlot.error_bars ===
      Enum.StoredSurveyLevellingPlot_ERROR_BARS.INITIAL_AND_LATEST
        ? 0
        : surveySeries.length - 2,
      // latest
      surveySeries.length - 1,
    ],
    [storedPlot.error_bars, surveySeries.length]
  );

  const [ref, plotWidth, plotHeight] = useResizeObserver<HTMLDivElement>({
    defaultWidth: props.initialWidth ?? DEFAULT_PLOT_WIDTH,
    defaultHeight: props.initialHeight ?? DEFAULT_PLOT_HEIGHT,
  });

  const { estimatedYTickWidth, ...domainProps } = React.useMemo(() => {
    const xDomain = ((survey_points) => {
      // We want the widest range including x_range_min, and x_range_max settings
      // So just add these values to the survey points array and get the extent
      let range_points = survey_points.map((sp) => Number(sp.distance));
      range_points.push(
        Number(storedPlot.x_axis_range_min),
        Number(storedPlot.x_axis_range_max)
      );
      const range = extent(range_points);
      if (!storedPlot.downstream) {
        return [range[1], range[0]];
      }
      return range;
    })(storedPlot.survey_points) as [number, number];

    const [yMin, yMax] = extent(
      surveySeries.flatMap((serie, serieIdx): (number | null)[] => {
        if (seriesWithErrorBars.includes(serieIdx)) {
          // For a series that is drawn with error bars, include the tops and
          // bottoms of the error bars in our calculation of the Y domain.
          return serie.readings.flatMap((r) => {
            if (r.y !== null && r.yVariance !== null) {
              return [r.y, r.y + r.yVariance / 2, r.y - r.yVariance / 2];
            } else {
              return [r.y];
            }
          });
        } else {
          return serie.readings.map((r) => r.y);
        }
      }) as number[]
    ) as [number, number];
    const ySize = yMax - yMin;
    const yDomain =
      storedPlot.y_axis_range_min !== null &&
      storedPlot.y_axis_range_max !== null
        ? [
            Number(storedPlot.y_axis_range_min),
            Number(storedPlot.y_axis_range_max),
          ]
        : [yMin - ySize * 0.05, yMax + ySize * 0.05];

    // Also estimate the width needed to display the ticks, based on their labels
    // We only need to do this for one side, because both sides use the same
    // labels.
    const formatTick = scaleLinear()
      .domain(yDomain)
      .tickFormat(AxisUtils.getTicksTotalFromSize(plotHeight));
    const minValueString = formatTick(yDomain[0]);
    const maxValueString = formatTick(yDomain[1]);
    const estimatedYTickWidth =
      Y_AXIS_TICK_PADDING +
      Math.max(minValueString.length, maxValueString.length) *
        SSR_TICK_LABEL_CHAR_WIDTH;

    return {
      xDomain,
      yDomain,
      estimatedYTickWidth,
    };
  }, [
    plotHeight,
    seriesWithErrorBars,
    storedPlot.downstream,
    storedPlot.survey_points,
    storedPlot.x_axis_range_min,
    storedPlot.x_axis_range_max,
    storedPlot.y_axis_range_min,
    storedPlot.y_axis_range_max,
    surveySeries,
  ]);

  const annotations = React.useMemo(
    () =>
      calculateSurveyAnnotationPlotLines(
        domainProps,
        props.storedPlot.annotations
      ),
    [domainProps, props.storedPlot.annotations]
  );

  const [element, setElement] = useState<HTMLDivElement | null>();

  // Measure the width of the rendered Y axis ticks and labels. (We only need
  // to measure one, because both sides have the same labels)
  const [measuredYTickWidth, setMeasuredYTickWidth] = useState<number>();
  useEffect(() => {
    // In CLI we use JSDOM, which doesn't actually render anything, so can't
    // do any measuring.
    if (detectCliMode()) {
      return;
    }

    if (!element) {
      return;
    }

    // HACK: React-Vis does not expose a ref to the actual plotted <g> element
    // that represents the axis. So instead we'll have to find it using a
    // querySelector.
    const newWidth =
      element.querySelector('g.yAxis.left')?.getBoundingClientRect()?.width ??
      0;
    setMeasuredYTickWidth(newWidth);
  }, [element]);

  const margin = React.useMemo(() => {
    const yAxisWidth =
      AXIS_LABEL_FONT_HEIGHT + (measuredYTickWidth ?? estimatedYTickWidth);
    return {
      left: yAxisWidth,
      right: yAxisWidth,
      top: MARGIN_TOP_BOTTOM,
      bottom: MARGIN_TOP_BOTTOM,
    };
  }, [estimatedYTickWidth, measuredYTickWidth]);

  const backgroundWidth = plotWidth - margin.left - margin.right;
  const backgroundHeight = plotHeight - margin.top - margin.bottom;

  const getNull = React.useCallback((d: any) => d.y !== null, []);

  return (
    <>
      <div
        className="plot-area"
        ref={(r) => {
          // Pass the element to the `useResizeObserver()` hook
          ref(r);
          // Keep our own reference to the element also.
          setElement(r);
        }}
      >
        {!surveySeries.some((s) => s.readings.length > 0) && (
          <AlertWarning className="alert-condensed">
            <Trans>No data available in the selected range</Trans>
          </AlertWarning>
        )}
        {/* Need to wrap inside SVG otherwise it wont render correctly in SSR */}
        {storedPlot.background_image && (
          <svg
            x={0}
            y={0}
            width={plotWidth}
            height={plotHeight}
            style={{ position: 'absolute' }}
          >
            <foreignObject
              x={margin.left}
              y={margin.top}
              width={backgroundWidth}
              height={backgroundHeight}
            >
              <img
                src={storedPlot.background_image!}
                width={backgroundWidth}
                height={backgroundHeight}
                alt=""
              />
            </foreignObject>
          </svg>
        )}
        <XYPlot
          {...domainProps}
          height={plotHeight}
          width={plotWidth}
          margin={margin}
        >
          {/* Annotation lines */}
          {annotations?.horizontalLines && (
            <LineSeries
              key="annotation-lines-horizontal"
              className="plot-annotation-line"
              data={annotations.horizontalLines}
              stroke={ANNOTATION_LINE_COLOR}
              getNull={getNull}
            />
          )}
          {annotations?.verticalLines && (
            <LineSeries
              key="annotation-lines-vertical"
              className="plot-annotation-line"
              data={annotations.verticalLines}
              stroke={ANNOTATION_LINE_COLOR}
              getNull={getNull}
            />
          )}
          {annotations?.horizontalStubs && (
            <CustomSVGSeries<
              ArrayElements<
                Exclude<typeof annotations['horizontalStubs'], null>
              >
            >
              data={annotations.horizontalStubs}
              customComponent={({ stubPosition }) => {
                // CustomSVGSeries wraps each element in a <g> tag that's already
                // positioned at the xy coordinates of the item being plotted.
                // So all the xy coordinates in these tags are relative to that.
                return (
                  <line
                    className="plot-annotation-line"
                    y1="0"
                    y2="0"
                    x1="0"
                    x2={
                      stubPosition === 'left'
                        ? ANNOTATION_STUB_LENGTH
                        : -1 * ANNOTATION_STUB_LENGTH
                    }
                    stroke={ANNOTATION_LINE_COLOR}
                  />
                );
              }}
              {...domainProps}
            />
          )}
          {annotations?.horizontalLabels && (
            <CustomSVGSeries<
              ArrayElements<
                Exclude<typeof annotations['horizontalLabels'], null>
              >
            >
              key={`annotation-labels-horizontal`}
              data={annotations.horizontalLabels}
              customComponent={({ label, position, stubPosition }) => (
                <text
                  className="plot-label-inside annotation-label"
                  x="0"
                  y="0"
                  dominantBaseline={
                    position === StoredPlotAnnotationLabelPosition.ABOVE
                      ? 'auto'
                      : 'hanging'
                  }
                  dx={
                    stubPosition === StoredPlotAnnotation_STUB_POSITION.RIGHT
                      ? '-0.3em'
                      : '0.3em'
                  }
                  dy={
                    position === StoredPlotAnnotationLabelPosition.ABOVE
                      ? '-0.5em'
                      : '0.5em'
                  }
                  textAnchor={
                    stubPosition === StoredPlotAnnotation_STUB_POSITION.RIGHT
                      ? 'end'
                      : 'start'
                  }
                >
                  {label}
                </text>
              )}
            />
          )}
          {annotations?.verticalStubs && (
            <CustomSVGSeries<
              ArrayElements<Exclude<typeof annotations['verticalStubs'], null>>
            >
              data={annotations.verticalStubs}
              customComponent={({ stubPosition }) => {
                // CustomSVGSeries wraps each element in a <g> tag that's already
                // positioned at the xy coordinates of the item being plotted.
                // So all the xy coordinates in these tags are relative to that.
                return (
                  <line
                    className="plot-annotation-line"
                    x1="0"
                    x2="0"
                    y1="0"
                    y2={
                      stubPosition === StoredPlotAnnotation_STUB_POSITION.BOTTOM
                        ? -1 * ANNOTATION_STUB_LENGTH
                        : ANNOTATION_STUB_LENGTH
                    }
                    stroke={ANNOTATION_LINE_COLOR}
                  />
                );
              }}
              {...domainProps}
            />
          )}
          {annotations?.verticalLabels && (
            <CustomSVGSeries<
              ArrayElements<Exclude<typeof annotations['verticalLabels'], null>>
            >
              key={`annotation-labels-vertical`}
              data={annotations.verticalLabels}
              customComponent={({ label, position, stubPosition }) => (
                <text
                  className="plot-label-inside annotation-label"
                  x="-1"
                  y="0"
                  dy={
                    position === StoredPlotAnnotationLabelPosition.LEFT
                      ? '-0.5em'
                      : '0.5em'
                  }
                  dx={
                    stubPosition === StoredPlotAnnotation_STUB_POSITION.TOP
                      ? '-0.5em'
                      : '0.5em'
                  }
                  dominantBaseline={
                    position === StoredPlotAnnotationLabelPosition.LEFT
                      ? 'auto'
                      : 'hanging'
                  }
                  textAnchor={
                    stubPosition === StoredPlotAnnotation_STUB_POSITION.TOP
                      ? 'end'
                      : 'start'
                  }
                  transform="rotate(-90)"
                >
                  {label}
                </text>
              )}
            />
          )}
          {surveySeries.map((serie, idx) => (
            <LineSeries<SurveyLevellingReading>
              key={serie.surveyDatetime}
              data={serie.readings}
              animation={false}
              stroke={getColorForIdx(idx)}
              getNull={(r) => r.y !== null}
              className="plot-readings-line"
            />
          ))}
          {seriesWithErrorBars.map((serieIdx) => {
            const serie = surveySeries[serieIdx];
            if (!serie) {
              return null;
            }
            return (
              <WhiskerSeries
                key={serie.surveyDatetime}
                data={serie.readings.filter(
                  (p) => p.y !== null && p.yVariance !== null
                )}
                stroke={getColorForIdx(serieIdx)}
                className="plot-readings-line"
                crossBarWidth={CROSS_BAR_WIDTH}
              />
            );
          })}
          <XAxis
            orientation="bottom"
            className="xAxis plot-direction-labels"
            style={{
              ...axisStyle,
              textAnchor: 'middle',
            }}
            position="middle"
            tickSize={0}
            tickValues={domainProps.xDomain}
            tickFormat={(_distance, idx) => {
              return idx === 0 ? 'Left' : 'Right';
            }}
          />
          <XAxis
            orientation="top"
            className="xAxis"
            style={{
              ...axisStyle,
              textAnchor: 'middle',
            }}
            position="middle"
            tickSize={0}
            tickValues={storedPlot.survey_points.map((sp) => sp.distance)}
            tickFormat={(_distance, idx) => {
              if (!storedPlot.survey_points[idx].show_label) return '';
              return storedPlot.survey_points[idx].label;
            }}
          />
          <XAxis
            orientation="bottom"
            className="xAxis plot-direction-bottom"
            style={{
              ...axisStyle,
              textAnchor: 'middle',
            }}
            position="middle"
            tickValues={storedPlot.survey_points.map((sp) => sp.distance)}
            tickFormat={(distance) => {
              return distance;
            }}
            {...TICK_SIZE}
          />
          <YAxis
            orientation="left"
            className="yAxis left"
            style={axisStyle}
            tickPadding={Y_AXIS_TICK_PADDING}
            {...TICK_SIZE}
          />
          <YAxis
            orientation="right"
            className="yAxis right"
            style={axisStyle}
            tickPadding={Y_AXIS_TICK_PADDING}
            {...TICK_SIZE}
          />
          {/* The labels for the left and right edges of the plot (positioned via a separate
          element rather than CSS, as an IE11 workaround.) */}
          <YAxis
            title={
              observationPoints[0]?.instrument_type.default_vertical_plot_title
            }
            // Line up the label flush with the left side of the SVG.
            // (It should be position correctly due to the left-margin we
            // put on the whole plot.)
            left={0}
            marginLeft={0}
            orientation="left"
            position="end"
            hideLine
            hideTicks
          />
          <YAxis
            title={
              observationPoints[0]?.instrument_type.default_vertical_plot_title
            }
            // Position this label to the right of its associated axis.
            marginLeft={margin.left + margin.right}
            orientation="right"
            position="end"
            hideLine
            hideTicks
          />
          {/* Draw plot markers for serie with them enabled above the axes */}
          {surveySeries.map((serie, idx) => {
            if (!serie.showPlotMarkers || serie.readings.length <= 2)
              return null;

            const serieColor = getColorForIdx(idx);
            const serieMarker = getMarkerForIdx(idx, markerPool);

            const plotMarkerPoints = serie.readings.map((p) => ({
              x: p.x,
              y: p.y,
              label: serieMarker,
              style: {
                fill: serieColor,
              },
            }));
            return (
              <LabelSeries<typeof plotMarkerPoints[0]>
                className="plot-markers"
                key={`plot-markers-${serie.surveyDatetime}`}
                data={plotMarkerPoints}
                labelAnchorX="middle"
                labelAnchorY="central"
              />
            );
          })}
          {/* Letter labels (A, B, C, etc) at left and right-hand ends of the
              data series lines. */}
          {surveySeries.map(
            ({ readings }, idx) =>
              readings.length > 0 && (
                <LabelSeries<
                  SurveyLevellingReading & { label: string; xOffset: number }
                >
                  key={`label-${idx}`}
                  data={[
                    {
                      ...readings[0],
                      label: getLabelForIdx(idx),
                      xOffset:
                        SERIES_LABEL_PADDING * (storedPlot.downstream ? -1 : 1),
                    },
                    {
                      ...readings[readings.length - 1],
                      label: getLabelForIdx(idx),
                      xOffset:
                        SERIES_LABEL_PADDING * (storedPlot.downstream ? 1 : -1),
                    },
                  ]}
                  allowOffsetToBeReversed={false}
                  labelAnchorY="middle"
                  style={{
                    fill: getColorForIdx(idx),
                    stroke: getColorForIdx(idx),
                  }}
                />
              )
          )}
        </XYPlot>
      </div>
      <div className="plot-legend plot-legend-survey-levelling">
        <dl>
          {surveySeries.map((serie, idx) => {
            const serieColor = getColorForIdx(idx);
            const serieMarker = serie.showPlotMarkers
              ? getMarkerForIdx(idx, markerPool)
              : null;

            return (
              <div key={idx}>
                <dt>
                  <span style={{ color: serieColor }}>
                    {getLabelForIdx(idx)}
                  </span>
                  {serieMarker && (
                    <>
                      {' '}
                      <span
                        className="plot-marker-legend"
                        style={{ color: serieColor }}
                      >
                        {serieMarker}
                      </span>
                    </>
                  )}
                </dt>
                <dd>
                  {formatDateForDisplay(
                    convertDatetimeToDate(
                      serie.surveyDatetime,
                      storedPlot.area.time_zone.name
                    )
                  )}
                </dd>
              </div>
            );
          })}
        </dl>
      </div>
    </>
  );
}
