import React from 'react';
import { Trans } from '@lingui/macro';
import deepEqual from 'lodash/isEqual';
import fromPairs from 'lodash/fromPairs';

import { Model } from 'util/backendapi/models/api.interfaces';
import { Card, CardSection } from 'components/base/card/card';
import { ObsPointFormulaCard } from './formbody';
import { formatDatetimeForDisplay } from 'util/dates';
import Loading from 'components/base/loading/loading';
import Accordion from 'components/base/accordion/accordion';
import { FormulaSnapshotChanges } from './currentformula.types';
import { ObsPointDetailFormulaState } from 'ducks/obsPoint/detail/formula';

import './formulahistory.scss';

export type FormulaHistoryProps = ObsPointDetailFormulaState & {
  onToggle: (isExpanded: boolean) => void;
  formulas: Model.FormulaDecorated[];
};

/**
 * Displays the expandable formula history, beneath the currentformula section.
 */
export class FormulaHistory extends React.Component<FormulaHistoryProps> {
  render() {
    const observationPoint = this.props.historyForObservationPoint;
    // Hide the formula history viewer if there is no history.
    if (
      !this.props.isLoading &&
      this.props.formulaSnapshots &&
      this.props.formulaSnapshots.length === 0
    ) {
      return null;
    }

    const timeZone = observationPoint
      ? observationPoint.time_zone.name
      : undefined;
    const { formulaSnapshots } = this.props;

    return (
      <Accordion
        expand={this.props.isHistoryExpanded}
        onToggle={this.props.onToggle}
        item={{
          id: 'formula-history',
          title: <Trans>Formula snapshots over time</Trans>,
          content:
            !formulaSnapshots || this.props.isLoading ? (
              <Loading />
            ) : (
              // Display a card for each snapshot. (This list should already
              // be in reverse chronological order in the Redux store.)
              formulaSnapshots.map((snapshot, idx) => {
                // Skip the first snapshot. It represents the current state of the
                // formula, which is already displayed in the "current formula"
                // section.
                if (idx === 0) {
                  return null;
                }
                // Compare this snapshot to the one displayed above it on the
                // screen. (Which, chronologically, is the one that came after
                // it.)
                const followedBy = formulaSnapshots[idx - 1];
                const changed = calculateChanges(
                  snapshot,
                  followedBy,
                  this.props.formulas
                );

                return (
                  <Card
                    name={`formula-history-snapshot-${idx}`}
                    key={snapshot.start_datetime}
                    header={
                      <Trans>
                        {formatDatetimeForDisplay(
                          snapshot.start_datetime,
                          timeZone
                        )}
                        {' - '}
                        {formatDatetimeForDisplay(
                          followedBy.start_datetime,
                          timeZone
                        )}
                      </Trans>
                    }
                    iconType="icon-calendar"
                  >
                    <ObsPointFormulaCard
                      namePrefix={`snapshot-${idx}-`}
                      observationPointFormula={snapshot}
                      CardSectionComponent={CardSection}
                      isEditing={false}
                      isHistory={true}
                      formulas={this.props.formulas}
                      isLoading={this.props.isLoading}
                      observationPoint={observationPoint}
                      siteDecorated={this.props.siteDecorated}
                      relatedObservationPoints={
                        this.props.relatedObservationPoints
                      }
                      changed={changed}
                    />
                  </Card>
                );
              })
            ),
        }}
      />
    );
  }
}

/**
 * Compare one snapshot to another, and determine which fields have changed.
 *
 * @param snapshot
 * @param compareTo
 * @param formulas We want to generate a boolean for every formula input and
 * constant of the snapshot's formula, and the snapshot itself may not have
 * a matching key for each of them (if the observation point formula or the
 * site's time dependent fields were incomplete). So we need to use the
 * full formula object itself to get that list.
 */
function calculateChanges(
  snapshot: Model.ObservationPointFormulaSnapshot,
  compareTo: Model.ObservationPointFormulaSnapshot,
  formulas: Array<Model.FormulaDecorated>
): FormulaSnapshotChanges {
  if (
    snapshot.observation_point_formula.id !==
    compareTo.observation_point_formula.id
  ) {
    return {
      formula: true,
      formulaInputs: {},
      formulaConstants: {},
    };
  } else {
    const formula = formulas.find(
      (f) => f.id === snapshot.observation_point_formula.formula
    );
    if (!formula) {
      // Defensive coding: If the record has an invalid formula ID
      // (which should be impossible, due to foreign-key constraints),
      // mark nothing as changed.
      return {
        formula: false,
        formulaInputs: {},
        formulaConstants: {},
      };
    } else {
      return {
        formula: false,
        formulaInputs: fromPairs(
          formula.formula_inputs.map(({ var_name }) => [
            var_name,
            !deepEqual(
              snapshot.observation_point_formula_inputs[var_name],
              compareTo.observation_point_formula_inputs[var_name]
            ),
          ])
        ),
        formulaConstants: fromPairs(
          formula.formula_constants.map(({ var_name }) => [
            var_name,
            !deepEqual(
              snapshot.observation_point_formula_constant_values[var_name],
              compareTo.observation_point_formula_constant_values[var_name]
            ) ||
              !deepEqual(
                snapshot.site_time_dependent_fields[var_name],
                compareTo.site_time_dependent_fields[var_name]
              ),
          ])
        ),
      };
    }
  }
}
