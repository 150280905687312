import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStoredPlot, makeStoredPlotKey } from 'ducks/stored-plot/detail';
import { FullState } from 'main/reducers';

export function useStoredPlotState(plotName: string | null) {
  const dispatch = useDispatch();
  const plotState = useSelector((state: FullState) =>
    plotName ? state.storedPlot.detail[makeStoredPlotKey(plotName)] : null
  );

  useEffect(() => {
    if (plotName && !plotState) {
      dispatch(fetchStoredPlot(plotName));
    }
  }, [dispatch, plotName, plotState]);

  return plotState || { record: null, loading: false, errorMessage: '' };
}
