import React from 'react';
import { ReportFilter, FilterRenderingInfo } from '../../report-types';
import { parseQueryParamFromRouterProps } from 'util/routing';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import { ShowHideToggleField } from 'components/base/form/toggle-field/ToggleField';

/**
 * Configure a Show/Hide filter control for a report API table.
 *
 * @param name
 * @param label
 */
export function reportFilterShowHideMenu(
  name: string,
  label: React.ReactNode
): ReportFilter {
  function _parseFromFrontendUrl(
    routeProps: RouteChildrenProps | RouteComponentProps
  ) {
    return Boolean(parseQueryParamFromRouterProps(routeProps, name, false));
  }

  return {
    name,
    label,
    render() {
      return <ShowHideToggleField name={name} />;
    },
    renderSSR(info: FilterRenderingInfo) {
      return info.value;
    },
    getFormValFromUrlParam(routeProps) {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      return valueFromUrl;
    },
    getUrlParamFromFormVal(formValues) {
      const val = formValues[name];
      if (val === '' || val === undefined) {
        return { [name]: null };
      } else {
        return { [name]: val };
      }
    },
    getBackendFilterFromUrl(routeProps) {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      if (valueFromUrl === null) {
        return null;
      }

      const filterName = name;
      return { [filterName]: valueFromUrl };
    },
  };
}
