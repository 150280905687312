import React from 'react';
import { FormCardSection } from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { Field, FormikErrors } from 'formik';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { PolymorphicStoredPlotWithArea } from 'ducks/stored-plot/detail';

export interface BaseStoredPlotFormValues
  extends ForPostOrPut<Model.BaseStoredPlot> {
  save_as_name?: string;
  save_as_description?: string;
  save_as_area?: number;
}

interface Props {
  areaOptions: { value: number; label: string; timeZone: string }[];
  plotType: Enum.PlotType;
}

export function makeStoredPlotNameSectionInitialValues(
  storedPlot: PolymorphicStoredPlotWithArea
): BaseStoredPlotFormValues {
  return {
    name: storedPlot.name,
    plot_type: storedPlot.plot_type,
    title: storedPlot.title,
    description: storedPlot.description,
    area: storedPlot.area.id,
  };
}

export function validateStoredPlotNameSection(
  values: BaseStoredPlotFormValues
): FormikErrors<BaseStoredPlotFormValues> {
  const errors: FormikErrors<BaseStoredPlotFormValues> = {};
  if (values.name === '') {
    errors.name = (<Trans>Name is required.</Trans>) as any;
  }
  if (!values.area) {
    errors.area = (<Trans>Area is required.</Trans>) as any;
  }
  return errors;
}

export function StoredPlotNameSection(props: Props) {
  const { areaOptions, plotType } = props;

  return (
    <FormCardSection
      name="name"
      header={<Trans>Name</Trans>}
      fields={[
        {
          name: 'name',
          label: <Trans>Name</Trans>,
          content: (
            <>
              <Field type="text" name="name" />
              <FieldError name="name" />
            </>
          ),
        },
        {
          name: 'title',
          label: <Trans>Title</Trans>,
          content: (
            <>
              <Field type="text" name="title" />
              <FieldError name="title" />
            </>
          ),
        },
        {
          name: 'description',
          label: <Trans>Description</Trans>,
          content: (
            <>
              <Field name="description" component="textarea" />
              <FieldError name="description" />
            </>
          ),
        },
        {
          name: 'area',
          label: <Trans>Area</Trans>,
          content: (
            <>
              <SimpleSelectField
                name="area"
                isLoading={!areaOptions.length}
                options={areaOptions}
              />
              <FieldError name="area" />
            </>
          ),
        },
        {
          name: 'plot_type',
          label: <Trans>Plot type</Trans>,
          content: (
            <p className="non-editable-value">
              <TransEnum enum="PlotType" value={plotType} />
            </p>
          ),
        },
      ]}
    />
  );
}
