import React, { useMemo, useCallback } from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, FormikErrors, Form, Field } from 'formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import {
  useFormConfirmation,
  FormConfirmation,
} from 'components/base/confirmation/FormConfirmation';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi, postApi } from 'util/backendapi/fetch';
import { ButtonPrimary } from 'components/base/button/button';
import { formatDatetimeForDisplay } from 'util/dates';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import { DateInputState } from 'components/base/form/datefield/DateInput';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from 'components/modules/async-menu/ObsPointMenu';
import { NumberField } from 'components/base/form/number-field/NumberField';
import { toNullableDecimal } from 'util/validation';

type obsPointOption = SimpleSelectOption<number> & {
  timeZone: string;
};

type FormValues = Merge<
  Model.ReadingRetirementRequest_POST,
  {
    observationPointDetails: obsPointOption[];
    fromDatetimeDetails?: DateInputState;
    toDatetimeDetails?: DateInputState;
    greater_than_value: string;
    less_than_value: string;
  }
>;

function validate(values: FormValues): FormikErrors<FormValues> {
  const errors: FormikErrors<FormValues> = {};
  if (values.observation_points.length === 0) {
    errors.observation_points = (
      <Trans>Observation point is required.</Trans>
    ) as any;
  }
  if (
    !values.fromDatetimeDetails ||
    (!values.fromDatetimeDetails.day &&
      !values.fromDatetimeDetails.month &&
      !values.fromDatetimeDetails.year &&
      !values.fromDatetimeDetails.hour &&
      !values.fromDatetimeDetails.minute)
  ) {
    errors.from_datetime = (
      <Trans>From date and time is required</Trans>
    ) as any;
  }
  if (
    !values.toDatetimeDetails ||
    (!values.toDatetimeDetails.day &&
      !values.toDatetimeDetails.month &&
      !values.toDatetimeDetails.year &&
      !values.toDatetimeDetails.hour &&
      !values.toDatetimeDetails.minute)
  ) {
    errors.to_datetime = (<Trans>To date and time is required</Trans>) as any;
  }
  if (
    values.from_datetime &&
    values.to_datetime &&
    values.from_datetime > values.to_datetime
  ) {
    errors.from_datetime = (
      <Trans>From date and time cannot be later than To date and time</Trans>
    ) as any;
  }
  if (!values.reason) {
    errors.reason = (<Trans>Reason is required</Trans>) as any;
  }
  if (values.greater_than_value && values.less_than_value) {
    errors.less_than_value = (
      <Trans>
        Please provide only one of "Values greater than" or "Values less than",
        not both.
      </Trans>
    ) as any;
  }
  return errors;
}

interface Props {
  hideModal: () => void;
  onAfterSubmit: () => void;
}

export function CreateRetirementRequestModal(props: Props) {
  const isMounted = useIsMounted();
  const { hideModal, onAfterSubmit } = props;
  const [askForConfirmation, handleConfirmation] = useFormConfirmation();

  const initialValues: FormValues = useMemo(
    () => ({
      observation_points: [],
      observationPointDetails: [],
      from_datetime: '',
      to_datetime: '',
      reason: '',
      greater_than_value: '',
      less_than_value: '',
    }),
    []
  );
  const onSearchObsPoints = useCallback(async (searchText: string) => {
    const obsPoints = await getApi('/observation-points/', {
      code__icontains: searchText,
      fields: ['id', 'code', 'time_zone'],
      limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
    });
    return obsPoints.map((op) => ({
      value: op.id,
      label: op.code,
      timeZone: op.time_zone.name,
    }));
  }, []);
  const loadDefaultsObsPoints = useCallback(async (values: number[]) => {
    const obsPoints = await getApi('/observation-points/', {
      id__in: values,
      fields: ['id', 'code', 'time_zone'],
    });
    return obsPoints.map((op) => ({
      value: op.id,
      label: op.code,
      timeZone: op.time_zone.name,
    }));
  }, []);

  return (
    <ModalContent header={<Trans>Create retire readings job</Trans>}>
      <Formik<FormValues>
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, formik) => {
          const result = await askForConfirmation();
          if (!result) {
            formik.setSubmitting(false);
            return;
          }

          try {
            await postApi('/reading-retirement-requests/', {
              observation_points: values.observation_points,
              from_datetime: values.from_datetime,
              to_datetime: values.to_datetime,
              reason: values.reason,
              greater_than_value: toNullableDecimal(values.greater_than_value),
              less_than_value: toNullableDecimal(values.less_than_value),
            });
            if (!isMounted()) {
              return;
            }
            onAfterSubmit();
            hideModal();
          } catch (e) {
            if (!isMounted()) {
              return;
            }
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, getExpectedFields(initialValues));
          }
        }}
      >
        {(formik) => (
          <Form>
            <FormConfirmation
              onResolve={handleConfirmation}
              okBtnText={<Trans>Yes, retire readings</Trans>}
              content={() => {
                const timeZone =
                  formik.values.observationPointDetails[0]?.timeZone;
                const obsPoints = formik.values.observationPointDetails
                  .map((op) => op.label)
                  .join(', ');
                const from = formatDatetimeForDisplay(
                  formik.values.from_datetime,
                  timeZone
                );
                const to = formatDatetimeForDisplay(
                  formik.values.to_datetime,
                  timeZone
                );
                return (
                  <Trans>
                    Are you sure you want to retire all adjusted readings for{' '}
                    <strong>{obsPoints}</strong>
                    {formik.values.greater_than_value ? (
                      <>
                        {' '}
                        with a value greater than{' '}
                        <strong>{formik.values.greater_than_value}</strong> and
                      </>
                    ) : formik.values.less_than_value ? (
                      <>
                        {' '}
                        with a value less than{' '}
                        <strong>{formik.values.less_than_value}</strong> and
                      </>
                    ) : null}
                    between <strong>{from}</strong> and <strong>{to}</strong>{' '}
                    inclusive? This action is not reversible.
                  </Trans>
                );
              }}
            />
            {formik.status}
            <FormSection>
              <FormItem
                label={<Trans>Observation points</Trans>}
                fieldId="observation-points"
              >
                <AsyncSimpleSelectField<number, true, obsPointOption>
                  name="observation_points"
                  detailsName="observationPointDetails"
                  isMulti
                  placeholder={<Trans>Select an observation point...</Trans>}
                  autoFocus={true}
                  onSearch={onSearchObsPoints}
                  loadDefaults={loadDefaultsObsPoints}
                />
                <FieldError name="observation_points" />
              </FormItem>
              <FormItem label={<Trans>From date</Trans>} fieldId="from-date">
                <DatetimeField
                  name="from_datetime"
                  detailsName="fromDatetimeDetails"
                  id="from-date"
                  // TODO: If the timezone changes, it'll make this value change,
                  // which may be unexpected to the user. (This could happen if
                  // they fill in a time first, and then select an obs point outside
                  // of their local timezone; or if they change from one obs point
                  // to another in a different timezone)
                  //
                  // Maybe make this input tz-unaware to avoid that problem,
                  // and do the tz-conversion at form submission?
                  timeZone={formik.values.observationPointDetails[0]?.timeZone}
                  requireAllFields={true}
                />
                <FieldError name="from_datetime" />
              </FormItem>
              <FormItem label={<Trans>To date</Trans>} fieldId="to-date">
                <DatetimeField
                  name="to_datetime"
                  detailsName="toDatetimeDetails"
                  id="to-date"
                  timeZone={formik.values.observationPointDetails[0]?.timeZone}
                  requireAllFields={true}
                />
                <FieldError name="to_datetime" />
              </FormItem>
              <FormItem label={<Trans>Reason</Trans>} fieldId="reason">
                <Field component="textarea" name="reason" id="reason" />
                <FieldError name="reason" />
              </FormItem>
              <FormItem
                label={<Trans>Values greater than</Trans>}
                fieldId="greater_than_value"
              >
                <NumberField
                  name="greater_than_value"
                  id="greater_than_value"
                />
                <FieldError name="greater_than_value" />
              </FormItem>
              <FormItem
                label={<Trans>Values less than</Trans>}
                fieldId="less_than_value"
              >
                <NumberField name="less_than_value" id="less_than_value" />
                <FieldError name="less_than_value" />
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                type="submit"
                data-testid="retirement-request-submit-btn"
                disabled={formik.isSubmitting}
              >
                <Trans>Create</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
