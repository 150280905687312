import React from 'react';
import lodashMerge from 'lodash/merge';
import lodashSet from 'lodash/set';
import {
  PlotType,
  StoredPlotAnnotationAxis,
  StoredPlotAnnotationLabelPosition,
  StoredPlotAnnotation_STUB_POSITION,
  StoredPlotAnnotation_LINE_STYLE,
  StoredPlotAnnotationType,
} from 'util/backendapi/types/Enum';
import { StoredTimeSeriesPlotFormValues } from './StoredTimeSeriesPlotForm';
import { StoredScatterPlotFormValues } from './StoredScatterPlotForm';
import {
  StoredPlotItemAxis,
  StoredPlotItemReadingsSeries,
  StoredPlotItem_POST,
  BaseStoredPlot,
  StoredPlotNumericAnnotation,
  StoredPlotDatetimeAnnotation,
  StoredPlotAnnotation,
  StoredPlotItemHighlightPeriod,
} from 'util/backendapi/types/Model';
import { FormikErrors } from 'formik';
import {
  validateCustomScale,
  validateStartEndDatetime,
} from 'components/plots/validation';
import { Trans } from '@lingui/macro';
import { validateNumber } from 'util/validation';
import { ObsPointItemMenuOption } from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { Enum } from 'util/backendapi/models/api.interfaces';
import sortBy from 'lodash/sortBy';

/**
 * These are the values we receive from the "create a stored plot modal", when
 * creating a new stored plot.
 */
export type NewStoredPlotDefaults = Pick<BaseStoredPlot, 'name' | 'plot_type'>;

export type StoredScatterTimeSeriesFormValues =
  | StoredTimeSeriesPlotFormValues
  | StoredScatterPlotFormValues;

// To improve form performance by reducing the number of needed <FormChangeEffect>s,
// we use the same numeric values to represent position on Y axis *and* X axis
// labels/stubs. e.g. a form value of "1" for label position means "TOP" for a
// Y-axis label, and "LEFT" for an X-axis label.

type LabelPositionValue = 1 | 2;
type StubPositionValue = 3 | 4;

export type StoredPlotAnnotationFormValues = Merge<
  Omit<
    Merge<StoredPlotDatetimeAnnotation, StoredPlotNumericAnnotation>,
    'annotation_type'
  >,
  {
    position: LabelPositionValue;
    stub_position: StubPositionValue;
  }
>;

export type StoredPlotHighlightPeriodFormValues = Merge<
  StoredPlotItemHighlightPeriod,
  { end_datetime: string }
>;

export const ANNOTATION_LABEL_POSITION_OPTIONS: {
  [K in StoredPlotAnnotationAxis]: {
    value: LabelPositionValue;
    realValue: StoredPlotAnnotationLabelPosition;
    label: any;
  }[];
} = {
  [StoredPlotAnnotationAxis.X]: [
    {
      value: 1,
      realValue: StoredPlotAnnotationLabelPosition.LEFT,
      label: <Trans>Left</Trans>,
    },
    {
      value: 2,
      realValue: StoredPlotAnnotationLabelPosition.RIGHT,
      label: <Trans>Right</Trans>,
    },
  ],
  [StoredPlotAnnotationAxis.Y]: [
    {
      value: 1,
      realValue: StoredPlotAnnotationLabelPosition.ABOVE,
      label: <Trans>Above</Trans>,
    },
    {
      value: 2,
      realValue: StoredPlotAnnotationLabelPosition.BELOW,
      label: <Trans>Below</Trans>,
    },
  ],
};

/**
 * Given an annotation, get the form value that represents its label position.
 */
export function getAnnotationLabelPositionFormValue({
  axis,
  position,
}: Pick<StoredPlotAnnotation, 'axis' | 'position'>): LabelPositionValue {
  return (
    ANNOTATION_LABEL_POSITION_OPTIONS[axis].find(
      (opt) => opt.realValue === position
    )?.value ?? 1
  );
}

/**
 * Given an annotation form value, get its proper backend label position value.
 */
export function getAnnotationLabelPositionValue({
  axis,
  position,
}: Pick<
  StoredPlotAnnotationFormValues,
  'axis' | 'position'
>): StoredPlotAnnotation['position'] {
  return (
    ANNOTATION_LABEL_POSITION_OPTIONS[axis].find(
      (opt) => opt.value === position
    )?.realValue ?? ''
  );
}

export const ANNOTATION_STUB_POSITION_OPTIONS: {
  [K in StoredPlotAnnotationAxis]: {
    value: StubPositionValue;
    realValue: StoredPlotAnnotation_STUB_POSITION;
    label: any;
  }[];
} = {
  [StoredPlotAnnotationAxis.X]: [
    {
      value: 3,
      realValue: StoredPlotAnnotation_STUB_POSITION.TOP,
      label: <Trans>Top</Trans>,
    },
    {
      value: 4,
      realValue: StoredPlotAnnotation_STUB_POSITION.BOTTOM,
      label: <Trans>Bottom</Trans>,
    },
  ],
  [StoredPlotAnnotationAxis.Y]: [
    {
      value: 3,
      realValue: StoredPlotAnnotation_STUB_POSITION.LEFT,
      label: <Trans>Left</Trans>,
    },
    {
      value: 4,
      realValue: StoredPlotAnnotation_STUB_POSITION.RIGHT,
      label: <Trans>Right</Trans>,
    },
  ],
};

/**
 * Given an annotation, get its stub position form value
 */
export function getAnnotationStubPositionFormValue({
  axis,
  stub_position,
}: Pick<StoredPlotAnnotation, 'axis' | 'stub_position'>): StubPositionValue {
  return (
    ANNOTATION_STUB_POSITION_OPTIONS[axis].find(
      (opt) => opt.realValue === stub_position
    )?.value ?? 3
  );
}

/**
 * Given an annotation form value, get the proper backend value for its
 * stub position.
 */
export function getAnnotationStubPositionValue({
  axis,
  stub_position,
  line_style,
}: Pick<
  StoredPlotAnnotationFormValues,
  'axis' | 'stub_position' | 'line_style'
>): StoredPlotAnnotation['stub_position'] {
  if (line_style !== StoredPlotAnnotation_LINE_STYLE.STUB) {
    return null;
  }
  return (
    ANNOTATION_STUB_POSITION_OPTIONS[axis].find(
      (opt) => opt.value === stub_position
    )?.realValue ?? null
  );
}

export interface StoredPlotItemAxisFormValues extends StoredPlotItemAxis {
  mode: 'custom' | 'auto';
  minimum: string;
  maximum: string;
}

export interface StoredPlotItemSerieFormValues
  extends Optional<StoredPlotItemReadingsSeries, 'id'> {
  opItem: string; // obsPointId_itemNumber
  selectedOptions?: ObsPointItemMenuOption[];
  // Provides a unique key for each row in the series table, to minimize
  // unnecessary component re-creation after dragging and dropping.
  randomKey: string;
}

export type StoredPlotItemFormValues = Merge<
  StoredPlotItem_POST,
  {
    highlight_periods: StoredPlotHighlightPeriodFormValues[];
    annotations: StoredPlotAnnotationFormValues[];
    axes: StoredPlotItemAxisFormValues[];
    reading_series: Array<StoredPlotItemSerieFormValues>;
    show_highlight_periods: boolean;
  }
>;

/**
 * Validate the form subsection about a single plot ("item")
 * @param plotSectionValues
 */

export function validateAnnotations(
  plotType: PlotType,
  annotations: StoredPlotAnnotationFormValues[],
  errors: FormikErrors<any>
) {
  annotations.forEach((annotation, idx) => {
    if (!annotation.axis) {
      lodashSet(
        errors,
        ['annotations', idx, 'axis'],
        <Trans>Axis is required</Trans>
      );
    }

    const type = annotationType(plotType, annotation);

    if (type === StoredPlotAnnotationType.DATETIME) {
      if (!annotation.datetime_value) {
        lodashSet(
          errors,
          ['annotations', idx, 'datetime_value'],
          <Trans>Value is required</Trans>
        );
      }
      if (
        (plotType === Enum.PlotType.TIME_SERIES ||
          plotType === Enum.PlotType.SURVEY_LEVELLING) &&
        // The form actually gives us these as strings, but the types think
        // they're numbers
        (annotation.start_numeric as any) !== '' &&
        (annotation.end_numeric as any) !== '' &&
        Number(annotation.start_numeric) >= Number(annotation.end_numeric)
      ) {
        lodashSet(
          errors,
          ['annotations', idx, 'end_numeric'],
          <Trans>Annotation end must be greater than annotation start</Trans>
        );
      }
    } else if (
      type === StoredPlotAnnotationType.NUMERIC ||
      type === StoredPlotAnnotationType.SURVEY
    ) {
      if (annotation.numeric_value === '') {
        lodashSet(
          errors,
          ['annotations', idx, 'numeric_value'],
          <Trans>Value is required</Trans>
        );
      } else if (!validateNumber(annotation.numeric_value)) {
        lodashSet(
          errors,
          ['annotations', idx, 'numeric_value'],
          <Trans>Value must be a number</Trans>
        );
      }
      if (
        plotType === Enum.PlotType.TIME_SERIES &&
        annotation.start_datetime &&
        annotation.end_datetime &&
        annotation.start_datetime >= annotation.end_datetime
      ) {
        lodashSet(
          errors,
          ['annotations', idx, 'end_datetime'],
          <Trans>Annotation end must be later than annotation start</Trans>
        );
      }
    }

    // Annotation with line style "none" must have a label
    // (This setting is only available in some plot types)
    if (
      (plotType === Enum.PlotType.TIME_SERIES ||
        plotType === Enum.PlotType.SURVEY_LEVELLING) &&
      annotation.line_style === null &&
      !annotation.label
    ) {
      lodashSet(
        errors,
        ['annotations', idx, 'label'],
        <Trans>Label is required when annotation line style is 'None'.</Trans>
      );
    }
  });
}

export function validatePlotSection(
  plotType: PlotType,
  plotSectionValues: StoredPlotItemFormValues
): FormikErrors<StoredPlotItemFormValues> {
  let plotSectionErrors: FormikErrors<StoredPlotItemFormValues> = {};
  plotSectionValues.axes.forEach((yAxis, yAxisIdx) => {
    const scaleErrors = validateCustomScale(yAxis, `axes[${yAxisIdx}]`);

    if (Object.keys(scaleErrors).length > 0) {
      lodashMerge(plotSectionErrors, scaleErrors);
    }
  });

  validateAnnotations(
    plotType,
    plotSectionValues.annotations,
    plotSectionErrors
  );

  let periodValidationErrors = 0;

  plotSectionValues.highlight_periods.forEach(
    ({ start_datetime, end_datetime }, idx) => {
      const periodErrors = validateStartEndDatetime(
        start_datetime,
        end_datetime
      );
      if (Object.keys(periodErrors).length > 0) {
        periodValidationErrors++;
        lodashSet(plotSectionErrors, `highlight_periods[${idx}]`, periodErrors);
      }
    }
  );

  if (periodValidationErrors === 0) {
    const sortedHighlightPeriods = sortBy(
      plotSectionValues.highlight_periods.map((period, idx) => ({
        ...period,
        originalIndex: idx,
      })),
      'start_datetime'
    );

    // Check periods don't overlap
    for (let i = 0; i < sortedHighlightPeriods.length; i++) {
      if (i > 0) {
        const { start_datetime, originalIndex } = sortedHighlightPeriods[i];
        const lastPeriod = sortedHighlightPeriods[i - 1];

        if (
          start_datetime &&
          (start_datetime === lastPeriod.start_datetime ||
            (lastPeriod.end_datetime &&
              start_datetime < lastPeriod.end_datetime))
        ) {
          lodashSet(plotSectionErrors, `highlight_periods[${originalIndex}]`, {
            start_datetime: (
              <Trans>Time period cannot overlap with another period.</Trans>
            ),
          });
          break;
        }
      }
    }
  }

  plotSectionValues.reading_series.forEach((serieValues, seriesIdx) => {
    const serieErrors = validateReadingSeriesSection(serieValues);
    if (Object.keys(serieErrors).length > 0) {
      lodashSet(plotSectionErrors, `reading_series[${seriesIdx}]`, serieErrors);
    }
  });

  return plotSectionErrors;
}

/**
 * Validate the form subsection about a single reading series (observation point)
 * @param serieValues
 */
function validateReadingSeriesSection(
  serieValues: StoredPlotItemSerieFormValues
): FormikErrors<StoredPlotItemSerieFormValues> {
  const serieErrors: FormikErrors<StoredPlotItemSerieFormValues> = {};
  if (!serieValues.observation_point) {
    serieErrors.observation_point = (
      <Trans>Observation point is required</Trans>
    ) as any;
  }
  return serieErrors;
}

export function annotationType(
  plotType: PlotType,
  annotation: Pick<
    StoredPlotAnnotation | StoredPlotAnnotationFormValues,
    'axis'
  >
): StoredPlotAnnotationType {
  if (plotType === PlotType.SURVEY_LEVELLING)
    return StoredPlotAnnotationType.SURVEY;

  if (
    plotType === PlotType.TIME_SERIES &&
    annotation.axis === StoredPlotAnnotationAxis.X
  ) {
    return StoredPlotAnnotationType.DATETIME;
  }

  return StoredPlotAnnotationType.NUMERIC;
}

export function annotationFormValues(
  annotations: StoredPlotAnnotation[]
): StoredPlotAnnotationFormValues[] {
  return annotations.map((a) => {
    // Assigning to a typed local variable to get strict type checks...
    const r: StoredPlotAnnotationFormValues = {
      // Provide a default empty value for the type of value field that's
      // not currently selected. (This will avoid problems caused by having
      // a value of `undefined` if the user switches the annotation's axis)
      numeric_value: '',
      datetime_value: '',
      ...a,
      // Convert possible nulls on these into empty strings
      start_numeric: (a as StoredPlotDatetimeAnnotation).start_numeric || '',
      end_numeric: (a as StoredPlotDatetimeAnnotation).end_numeric || '',
      start_datetime: (a as StoredPlotNumericAnnotation).start_datetime || '',
      end_datetime: (a as StoredPlotNumericAnnotation).end_datetime || '',
      // To simplify the annotation form, we reuse the same form values for position
      // for both X and Y axis annotations. The values are rewritten into their correct
      // state in the submitHandler.
      position: getAnnotationLabelPositionFormValue(a),
      stub_position: getAnnotationStubPositionFormValue(a),
    };
    return r;
  });
}

export function formatAnnotationFormValuesForSubmit(
  plotType: Enum.PlotType,
  annotations: StoredPlotAnnotationFormValues[]
) {
  return annotations.map(
    ({
      numeric_value,
      start_datetime,
      end_datetime,
      datetime_value,
      start_numeric,
      end_numeric,
      ...annotFormValues
    }) => {
      const sharedAnnotation = {
        ...annotFormValues,
        // To simplify the annotation form, we reuse the same form values for position
        // for both X and Y axis annotations. The values are rewritten into their correct
        // state in the submitHandler.
        position: getAnnotationLabelPositionValue(annotFormValues),
        stub_position: getAnnotationStubPositionValue(annotFormValues),
      };

      const type = annotationType(plotType, annotFormValues);

      const scrubbedAnnotation: StoredPlotAnnotation =
        type === StoredPlotAnnotationType.DATETIME
          ? {
              ...sharedAnnotation,
              annotation_type: StoredPlotAnnotationType.DATETIME,
              datetime_value,
              start_numeric: start_numeric === '' ? null : start_numeric,
              end_numeric: end_numeric === '' ? null : end_numeric,
            }
          : type === StoredPlotAnnotationType.NUMERIC
          ? {
              ...sharedAnnotation,
              annotation_type: StoredPlotAnnotationType.NUMERIC,
              numeric_value,
              start_datetime: start_datetime || null,
              end_datetime: end_datetime || null,
            }
          : {
              ...sharedAnnotation,
              annotation_type: StoredPlotAnnotationType.SURVEY,
              numeric_value,
              start_numeric: start_numeric === '' ? null : start_numeric,
              end_numeric: end_numeric === '' ? null : end_numeric,
            };

      return scrubbedAnnotation;
    }
  );
}

export function makeEmptyStoredPlotItem(
  plotType: PlotType
): StoredPlotItemFormValues {
  return {
    name: '',
    description: '',
    interpolate: plotType === PlotType.SCATTER ? true : false,
    show_plot_markers: plotType === PlotType.SCATTER ? true : false,
    show_mark_connections: plotType === PlotType.SCATTER ? true : false,
    show_highlight_periods: false,
    highlight_periods: [],
    reading_series:
      plotType === PlotType.TIME_SERIES
        ? [
            {
              opItem: '',
              randomKey: String(Math.random()),
              observation_point: 0,
              item_number: 0,
              axis_side: 'left',
              show_plot_port_rl: false,
              show_plot_markers: false,
              show_analysis_comment_indicators: false,
              show_inspector_comment_indicators: false,
              show_media_indicators: false,
              show_alarm_parameters: false,
              show_legend_cap_rl: false,
              show_legend_port_rl: false,
              show_confidence_level: false,
            },
          ]
        : [
            {
              opItem: '',
              randomKey: String(Math.random()),
              observation_point: 0,
              item_number: 0,
              axis_side: 'left',
              show_plot_port_rl: false,
              show_plot_markers: false,
              show_analysis_comment_indicators: false,
              show_inspector_comment_indicators: false,
              show_media_indicators: false,
              show_alarm_parameters: false,
              show_legend_cap_rl: false,
              show_legend_port_rl: false,
              show_confidence_level: false,
            },
            {
              opItem: '',
              randomKey: String(Math.random()),
              observation_point: 0,
              item_number: 0,
              axis_side: 'bottom',
              show_plot_port_rl: false,
              show_plot_markers: false,
              show_analysis_comment_indicators: false,
              show_inspector_comment_indicators: false,
              show_media_indicators: false,
              show_alarm_parameters: false,
              show_legend_cap_rl: false,
              show_legend_port_rl: false,
              show_confidence_level: false,
            },
          ],
    annotations: [],
    axes:
      plotType === PlotType.TIME_SERIES
        ? [
            {
              maximum: '',
              minimum: '',
              side: 'left',
              mode: 'auto',
            },
          ]
        : [
            {
              maximum: '',
              minimum: '',
              side: 'left',
              mode: 'auto',
            },
            {
              maximum: '',
              minimum: '',
              side: 'bottom',
              mode: 'auto',
            },
          ],
  };
}
