import React from 'react';
import { Trans, t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import {
  Field,
  ErrorMessage,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from 'formik';
import { SitesMenuAutoLoading } from '../../../../components/modules/sitesmenu/sitesmenu';
import { SimpleSelectField } from '../../../../components/base/form/simpleselect/simpleselectfield';
import ActionBlock from '../../../../components/base/actionblock/actionblock';
import ErrorNotice from '../../../../components/base/form/errornotice/errornotice';
import { ButtonPrimary } from '../../../../components/base/button/button';
import ButtonHideModal from '../../../../components/base/modal/buttonhidemodal';
import ModalContent from '../../../../components/base/modal/modalcontent';
import { getSelectedAreaCode } from '../../detail/maintobspointselectors';
import { UppercaseTextField } from 'components/base/form/uppercase-text-field/UppercaseTextField';

const validate = (values: FormValues): FormErrors => {
  const errors: FormErrors = {};

  if (!values.code.trim()) {
    errors.code = (
      <Trans>Observation point is required.</Trans>
    ) as any as string;
  }

  if (!values.area) {
    errors.area = (<Trans>Area is required.</Trans>) as any as string;
  }

  if (!values.site) {
    errors.site = (<Trans>Site is required.</Trans>) as any as string;
  }

  if (!values.instrument_type) {
    errors.instrument_type = (
      <Trans>Instrument Type is required.</Trans>
    ) as any as string;
  }

  return errors;
};

export interface InstrumentTypeOption {
  value: number;
  label: string;
}

interface AreaOption {
  value: number;
  label: string;
  code: string;
}
export interface Props {
  areaOptions: AreaOption[];
  instrumentTypeOptions: InstrumentTypeOption[];
  onSubmit: (values: FormValues, formik: FormikHelpers<FormValues>) => void;
}

export interface FormValues {
  code: string;
  name: string;
  area: number;
  site: number;
  instrument_type: number;
}

type FormErrors = FormikErrors<FormValues>;

const MaintainGroupModalView = (props: Props) => {
  const initialValues: FormValues = {
    code: '',
    name: '',
    area: 0,
    site: 0,
    instrument_type: 0,
  };

  return (
    <ModalContent header={<Trans>Create an observation point</Trans>}>
      <Formik
        initialValues={initialValues}
        onSubmit={props.onSubmit}
        validate={validate}
      >
        {({ isSubmitting, values }) => (
          <Form>
            <fieldset>
              <div className="form-group">
                <label htmlFor="createobspointpanel-code">
                  <Trans>Observation point</Trans>
                </label>
                <UppercaseTextField
                  name="code"
                  id="createobspointpanel-code"
                  autoFocus={true}
                />
                <ErrorMessage name="code" component={ErrorNotice} />
              </div>

              <div className="form-group">
                <label htmlFor="createobspointpanel-name">
                  <Trans>Name</Trans>
                </label>
                <Field
                  name="name"
                  autoComplete="block-autoComplete"
                  id="createobspointpanel-name"
                />
                <ErrorMessage name="name" component={ErrorNotice} />
              </div>

              <div className="form-group">
                <label htmlFor="createobspointpanel-area">
                  <Trans>Area</Trans>
                </label>
                <I18n>
                  {({ i18n }) => (
                    <SimpleSelectField
                      name="area"
                      id="createobspointpanel-area"
                      placeholder={i18n._(t`Select an area...`)}
                      options={props.areaOptions}
                    />
                  )}
                </I18n>
                <ErrorMessage name="area" component={ErrorNotice} />
              </div>

              <div className="form-group">
                <label htmlFor="createobspointpanel-site">
                  <Trans>Site</Trans>
                </label>
                <I18n>
                  {({ i18n }) => (
                    <SitesMenuAutoLoading
                      name="site"
                      id="createobspointpanel-site"
                      placeholder={i18n._(t`Please select a site`)}
                      selectedAreaCode={getSelectedAreaCode(
                        values.area,
                        props.areaOptions
                      )}
                    />
                  )}
                </I18n>
                <ErrorMessage name="site" component={ErrorNotice} />
              </div>
              <div className="form-group">
                <label htmlFor="createobspointpanel-instrument_type">
                  <Trans>Instrument type</Trans>
                </label>
                <I18n>
                  {({ i18n }) => (
                    <SimpleSelectField
                      name="instrument_type"
                      id="obspoint-create-instrument_types"
                      placeholder={i18n._(t`Select an instrument type...`)}
                      options={props.instrumentTypeOptions}
                    />
                  )}
                </I18n>
                <ErrorMessage name="instrument_type" component={ErrorNotice} />
              </div>
            </fieldset>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                id="createobspointpanel-submit"
                type="submit"
                disabled={isSubmitting}
                iconType="icon-save"
              >
                <Trans>Create</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

export default MaintainGroupModalView;
