import React, { ReactNode } from 'react';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '../../base/button/button';
import ButtonHideModal from '../../base/modal/buttonhidemodal';
import { Icon } from '../../base/icon/icon';
import ModalContent from '../../base/modal/modalcontent';
import { ExportFormats, PageOrientations } from './exportpanelconstants';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import ActionBlock from 'components/base/actionblock/actionblock';

export interface ExportPanelProps {
  onSubmit: (evt: React.FormEvent<HTMLFormElement>) => void;
  onExportFormatChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  onPageOrientationChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  exportFormat: ExportFormats;
  pageOrientation: PageOrientations;
  isExporting: boolean;
  title?: ReactNode;
  description?: ReactNode;
  canExportPdf?: boolean;
  canExportPng?: boolean;
  canExportCsv?: boolean;
  canExportXlsx?: boolean;
  canExportDocx?: boolean;
  canSelectPageOrientation?: boolean;
  errorMessage: React.ReactNode | null;
}

class ExportPanelView extends React.Component<ExportPanelProps> {
  static defaultProps: Partial<ExportPanelProps> = {
    title: <Trans>Export report</Trans>,
    description: (
      <Trans>
        The report will be exported with the same settings as displayed on
        screen.
      </Trans>
    ),
  };

  render() {
    const exportPdfInput = this.props.canExportPdf ? (
      <div className="radio-group">
        <input
          id="export-plot-pdf"
          type="radio"
          value={ExportFormats.PDF}
          name="export-plot"
          onChange={this.props.onExportFormatChange}
          checked={this.props.exportFormat === ExportFormats.PDF ? true : false}
        />
        <label id="export-plot-pdf-label" htmlFor="export-plot-pdf">
          <Icon type="icon-pdf" />
          <Trans>PDF</Trans>
        </label>
      </div>
    ) : null;

    const exportPngInput = this.props.canExportPng ? (
      <div className="radio-group">
        <input
          id="export-plot-png"
          type="radio"
          value={ExportFormats.PNG}
          name="export-plot"
          onChange={this.props.onExportFormatChange}
          checked={this.props.exportFormat === ExportFormats.PNG ? true : false}
        />
        <label id="export-plot-png-label" htmlFor="export-plot-png">
          <Icon type="icon-png" />
          <Trans>PNG</Trans>
        </label>
      </div>
    ) : null;

    const exportCsvInput = this.props.canExportCsv ? (
      <div className="radio-group">
        <input
          id="export-plot-csv"
          type="radio"
          value={ExportFormats.CSV}
          name="export-plot"
          onChange={this.props.onExportFormatChange}
          checked={this.props.exportFormat === ExportFormats.CSV ? true : false}
        />
        <label id="export-plot-csv-label" htmlFor="export-plot-csv">
          <Icon type="icon-csv" />
          <Trans>CSV</Trans>
        </label>
      </div>
    ) : null;

    const exportXlsxInput = this.props.canExportXlsx ? (
      <div className="radio-group">
        <input
          id="export-plot-xlsx"
          type="radio"
          value={ExportFormats.XLSX}
          name="export-plot"
          onChange={this.props.onExportFormatChange}
          checked={
            this.props.exportFormat === ExportFormats.XLSX ? true : false
          }
        />
        <label id="export-plot-xlsx-label" htmlFor="export-plot-xlsx">
          <Icon type="icon-csv" />
          <Trans>XLSX</Trans>
        </label>
      </div>
    ) : null;

    const exportDocxInput = this.props.canExportDocx ? (
      <div className="radio-group">
        <input
          id="export-plot-docx"
          type="radio"
          value={ExportFormats.DOCX}
          name="export-plot"
          onChange={this.props.onExportFormatChange}
          checked={
            this.props.exportFormat === ExportFormats.DOCX ? true : false
          }
        />
        <label id="export-plot-docx-label" htmlFor="export-plot-docx">
          <Icon type="icon-csv" />
          <Trans>DOCX</Trans>
        </label>
      </div>
    ) : null;

    const pageOrientationOptions =
      this.props.canSelectPageOrientation &&
      this.props.exportFormat === ExportFormats.PDF ? (
        <fieldset className="radio-fieldset-toggle">
          <legend>
            <Trans>Page orientation</Trans>
          </legend>
          <div className="radio-group-wrapper">
            <div className="radio-group">
              <input
                id="page-orientation-portrait"
                type="radio"
                value="portrait"
                name="page-orientation"
                onChange={this.props.onPageOrientationChange}
                checked={
                  this.props.pageOrientation === 'portrait' ? true : false
                }
              />
              <label
                id="page-orientation-portrait-label"
                htmlFor="page-orientation-portrait"
              >
                <Trans>Portrait</Trans>
              </label>
            </div>
            <div className="radio-group">
              <input
                id="page-orientation-landscape"
                type="radio"
                value="landscape"
                name="page-orientation"
                onChange={this.props.onPageOrientationChange}
                checked={
                  this.props.pageOrientation === 'landscape' ? true : false
                }
              />
              <label
                id="page-orientation-landscape-label"
                htmlFor="page-orientation-landscape"
              >
                <Trans>Landscape</Trans>
              </label>
            </div>
          </div>
        </fieldset>
      ) : null;

    return (
      <ModalContent header={this.props.title}>
        <p>{this.props.description}</p>
        <form onSubmit={this.props.onSubmit}>
          <fieldset className="radio-fieldset-toggle">
            <legend>
              <Trans>Export format</Trans>
            </legend>
            <div className="radio-group-wrapper">
              {exportPdfInput}
              {exportPngInput}
              {exportCsvInput}
              {exportXlsxInput}
              {exportDocxInput}
            </div>
            {this.props.errorMessage && (
              <ErrorNotice>{this.props.errorMessage}</ErrorNotice>
            )}
          </fieldset>
          {pageOrientationOptions}
          <ActionBlock>
            <ButtonHideModal />
            <ButtonPrimary
              type="submit"
              name="export"
              id="export-plot-submit"
              iconType="icon-export"
              disabled={this.props.isExporting}
            >
              <Trans>Export</Trans>
            </ButtonPrimary>
          </ActionBlock>
        </form>
      </ModalContent>
    );
  }
}

export default ExportPanelView;
