import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { ObservationPoint } from 'util/backendapi/types/Model';
import { patchApi } from 'util/backendapi/fetch';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import { FormikHelpers, Formik, Form, FastField } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { ButtonPrimary } from 'components/base/button/button';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { useGetApi } from 'hooks/use-get-api';
import Loading from 'components/base/loading/loading';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { IntegerField } from 'components/base/form/integer-field/IntegerField';

type FormValues = ForPatch<
  Pick<
    ObservationPoint,
    | 'id'
    | 'device_alarm_threshold'
    | 'route_march_instruction'
    | 'emergency_route_march_instruction'
  >
>;

interface Props {
  observationPointId: number;
  onAfterSubmit: () => void;
}

export function EditObsPointRouteMarchInfoModal({
  observationPointId,
  onAfterSubmit,
}: Props) {
  const isMounted = useIsMounted();
  const [observationPointDetail] = useGetApi(
    observationPointId ? `/observation-points/${observationPointId}/` : null
  );

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      try {
        await patchApi(`/observation-points/${observationPointId}/`, {
          device_alarm_threshold:
            values.device_alarm_threshold !== ''
              ? values.device_alarm_threshold
              : null,
          route_march_instruction: values.route_march_instruction,
          emergency_route_march_instruction:
            values.emergency_route_march_instruction,
        });
        onAfterSubmit();
      } catch (e) {
        if (isMounted()) {
          formik.setSubmitting(false);
          showErrorsInFormik(formik, e, getExpectedFields(values));
        }
      }
    },
    [observationPointId, onAfterSubmit, isMounted]
  );

  const initialValues: FormValues | null = useMemo(() => {
    if (!observationPointDetail.data) {
      return null;
    }

    return {
      device_alarm_threshold:
        observationPointDetail.data.device_alarm_threshold === null
          ? ''
          : observationPointDetail.data.device_alarm_threshold,
      route_march_instruction:
        observationPointDetail.data.route_march_instruction,
      emergency_route_march_instruction:
        observationPointDetail.data.emergency_route_march_instruction,
    };
  }, [observationPointDetail.data]);

  return (
    <ModalContent header={<Trans>Edit observation point</Trans>}>
      {observationPointDetail.isLoading ? <Loading /> : null}
      {initialValues ? (
        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {(formik) => (
            <Form>
              {formik.status}
              <FormSection>
                <FormItem label={<Trans>Observation point</Trans>}>
                  <span>{observationPointDetail.data?.code}</span>
                </FormItem>
                <FormItem label={<Trans>Device alarm</Trans>}>
                  <div className="input-unit-wrapper">
                    <IntegerField
                      min={0}
                      name="device_alarm_threshold"
                      data-testid="device_alarm_threshold"
                      className="text-input-with-unit"
                    />
                    <span className="input-unit">%</span>
                  </div>
                  <FieldError name="device_alarm_threshold" />
                </FormItem>
                <FormItem label={<Trans>Route march instruction</Trans>}>
                  <FastField
                    data-testid="route_march_instruction"
                    component="textarea"
                    maxLength={500}
                    name="route_march_instruction"
                  />
                  <FieldError name="route_march_instruction" />
                </FormItem>
                <FormItem
                  label={<Trans>Emergency route march instruction</Trans>}
                >
                  <FastField
                    data-testid="emergency_route_march_instruction"
                    component="textarea"
                    maxLength={500}
                    name="emergency_route_march_instruction"
                  />
                  <FieldError name="emergency_route_march_instruction" />
                </FormItem>
              </FormSection>
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="edit-observation-point-submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      ) : null}
    </ModalContent>
  );
}
