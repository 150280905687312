import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { fetchAlarmReport } from 'ducks/alarm-report/detail';
import withInit, { InitProps } from 'components/hoc/withinit';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import { StandardDispatch } from 'main/store';
import AlarmReportDetailView, {
  AlarmReportDetailViewStateProps,
  AlarmReportDetailViewDispatchProps,
} from './alarmreportdetailview';
import { alarmReportWorkflowSteps } from './changestatus-modal/changestatusmodal';
import { selectHasPermission } from 'util/user';

type RouterProps = RouteComponentProps<{ alarmReportId: string }>;

const mapStateToProps = (
  state: FullState,
  ownProps: RouterProps
): AlarmReportDetailViewStateProps => {
  const hasCommentsButton = selectHasPermission(
    state,
    Enum.User_PERMISSION.can_view_alarm_report_report
  );

  const alarmReportId = +ownProps.match.params.alarmReportId;
  const alarmReport =
    state.alarmReports.detail.alarmReport?.id === alarmReportId
      ? state.alarmReports.detail.alarmReport
      : null;

  return {
    ...state.alarmReports.detail,
    alarmReport,
    hasCommentsButton,
    canChangeStatus: checkChangeStatusPermission(
      alarmReport?.status,
      state.user.permissions
    ),
  };
};

const mapDispatchToProps = (
  dispatch: StandardDispatch,
  ownProps: RouterProps
) => ({
  onInit: () =>
    dispatch(
      fetchAlarmReport(parseInt(ownProps.match.params.alarmReportId, 10))
    ),
  onViewPlot: (obsCode: string) => {
    ownProps.history.push(`/quickplot/${obsCode}`);
  },
  onViewPreviousAlarm: (obsId: number, obsCode: string) => {
    ownProps.history.push(`/alarm-reports/?observation_point=${obsId}`);
  },
});

const AlarmReportDetailScreen = withRouter(
  connect<
    AlarmReportDetailViewStateProps,
    AlarmReportDetailViewDispatchProps & InitProps,
    RouterProps,
    FullState
  >(
    mapStateToProps,
    mapDispatchToProps
  )(withInit(AlarmReportDetailView))
);
export default AlarmReportDetailScreen;

// Helper function to check if user has permission to move to next workflow step
function checkChangeStatusPermission(
  stepName: Enum.AlarmReport_STATUS | undefined,
  permissions: Enum.User_PERMISSION[]
): boolean {
  if (!stepName) {
    return false;
  }

  const currentStepOption = alarmReportWorkflowSteps.find(
    (option) => option.value === stepName
  );

  return currentStepOption!.nextSteps.some((stepName) => {
    const nextStepOption = alarmReportWorkflowSteps.find(
      (step) => step.value === stepName
    );
    return nextStepOption!.permission
      ? permissions.includes(nextStepOption!.permission)
      : true;
  });
}
