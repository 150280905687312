import React, { useMemo } from 'react';
import difference from 'lodash/difference';
import sortedUniq from 'lodash/sortedUniq';
import lodashSet from 'lodash/set';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { StoredSurveyLevellingPlotWithArea } from 'ducks/stored-plot/detail';
import {
  makeStoredPlotNameSectionInitialValues,
  StoredPlotNameSection,
  validateStoredPlotNameSection,
} from './sections/StoredPlotNameSection';
import {
  Formik,
  Form,
  FieldArray,
  FormikErrors,
  Field,
  FormikProps,
} from 'formik';
import { FormCard, FormCardSection } from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { StoredPlotSaveCancelButtons } from './StoredPlotSaveCancelButtons';
import ActionBlock from 'components/base/actionblock/actionblock';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import Button from 'components/base/button/button';
import {
  convertDatetimeToDate,
  formatDatetimeForDisplay,
  formatDateForDisplay,
} from 'util/dates';
import FormChangeEffect, {
  FormChangeEffectParams,
} from 'components/base/form/formchangeeffect/formchangeeffect';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchObsPointSurveyDatetimes,
  unmountStoredSurveyLevellingPlotForm,
} from 'ducks/stored-plot/survey-levelling-detail';
import { FullState } from 'main/reducers';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { ReportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import { isTruthy, validateNumber } from 'util/validation';
import { createSelector } from 'reselect';
import {
  NewStoredPlotDefaults,
  StoredPlotAnnotationFormValues,
  annotationFormValues,
  formatAnnotationFormValuesForSubmit,
  validateAnnotations,
} from './stored-plot-edit-utils';
import Loading from 'components/base/loading/loading';
import { ToggleField } from 'components/base/form/toggle-field/ToggleField';
import { ObsPointMenu } from 'components/modules/async-menu/ObsPointMenu';
import {
  StoredPlotSaveAsValues,
  makeStoredPlotSaveAsInitialValues,
  validateStoredPlotSaveAsValues,
  StoredPlotSaveAsSettings,
  getStoredPlotSaveAsSettings,
} from './StoredPlotSaveAsModal';
import { showStoredPlotErrorsInFormik } from './StoredPlotEdit';
import {
  SurveyTimePeriodOption,
  SurveyTimePeriodsField,
} from './SurveyTimePeriodsField';
import { FileField } from 'components/base/form/file-field/FileField';
import { parseFilenameFromUrl } from 'util/backendapi/file';
import { AnnotationsSubsection } from './sections/StoredPlotItemSection';
import { RadioFieldOption } from 'components/base/form/radio-field/RadioField';
import './StoredSurveyLevellingPlotForm.scss';
import { StoredSurveyLevellingPlotSurveyPoint } from 'util/backendapi/types/Model';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';

export type StoredSurveyLevellingPlotFormValues = StoredPlotSaveAsValues &
  Omit<
    Model.StoredSurveyLevellingPlot_POST,
    'annotations' | 'survey_points'
  > & {
    annotations: StoredPlotAnnotationFormValues[];
    survey_points: (StoredSurveyLevellingPlotSurveyPoint & {
      observation_point_detail?: SimpleSelectOption<number>[];
    })[];
  };

export interface StoredSurveyLevellingPlotFormProps {
  storedPlot: StoredSurveyLevellingPlotWithArea | null;
  newPlotDefaults: NewStoredPlotDefaults | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  onSubmit: (
    values: Model.StoredSurveyLevellingPlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<any>;
  onCancel: () => void;
}

function makeInitialValues(
  storedPlot: StoredSurveyLevellingPlotWithArea | null,
  newPlotDefaults: NewStoredPlotDefaults | null
): StoredSurveyLevellingPlotFormValues {
  if (!storedPlot) {
    // Create an empty one
    return {
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.SURVEY_LEVELLING,
      name: newPlotDefaults?.name ?? '',
      area: 0,
      downstream: true,
      error_bars: Enum.StoredSurveyLevellingPlot_ERROR_BARS.INITIAL_AND_LATEST,
      x_axis_range_min: '',
      x_axis_range_max: '',
      y_axis_range_min: '',
      y_axis_range_max: '',
      survey_points: [
        {
          observation_point: 0,
          distance: '',
          label: '',
          show_label: true,
        },
      ],
      time_periods: [],
      background_image: '',
      background_image_file: null,
      annotations: [],
    };
  }

  return {
    ...makeStoredPlotNameSectionInitialValues(storedPlot),
    ...makeStoredPlotSaveAsInitialValues(),
    plot_type: Enum.PlotType.SURVEY_LEVELLING,
    downstream: storedPlot.downstream,
    error_bars: storedPlot.error_bars,
    x_axis_range_min: storedPlot.x_axis_range_min ?? '',
    x_axis_range_max: storedPlot.x_axis_range_max ?? '',
    y_axis_range_min: storedPlot.y_axis_range_min ?? '',
    y_axis_range_max: storedPlot.y_axis_range_max ?? '',
    // The back end provides the points sorted by distance (ascending)
    survey_points: storedPlot.survey_points.map(
      ({ observation_point, distance, label, show_label }) => ({
        observation_point,
        distance,
        label,
        show_label,
      })
    ),
    time_periods: storedPlot.time_periods,
    background_image: parseFilenameFromUrl(storedPlot.background_image ?? ''),
    background_image_file: null,
    annotations: annotationFormValues(storedPlot.annotations),
  };
}

function validateAxisRange(
  values: StoredSurveyLevellingPlotFormValues,
  errors: FormikErrors<StoredSurveyLevellingPlotFormValues>,
  min_field: 'x_axis_range_min' | 'y_axis_range_min',
  max_field: 'x_axis_range_max' | 'y_axis_range_max'
) {
  const min_value = values[min_field];
  const max_value = values[max_field];

  const isMinSupplied = Boolean(min_value);
  const isMinValid = isMinSupplied && validateNumber(min_value);
  const isMaxSupplied = Boolean(max_value);
  const isMaxValid = isMaxSupplied && validateNumber(max_value);
  if (isMinValid && isMaxValid && +min_value! >= +max_value!) {
    errors[min_field] = (<Trans>Min must be less than Max</Trans>) as any;
  } else {
    if ((isMinSupplied && !isMinValid) || (isMaxSupplied && !isMinSupplied)) {
      errors[min_field] = (
        <Trans>
          Minimum and maximum must either be both a number or both empty.
        </Trans>
      ) as any;
    }
    if ((isMaxSupplied && !isMaxValid) || (isMinSupplied && !isMaxSupplied)) {
      errors[max_field] = (
        <Trans>
          Minimum and maximum must either be both a number or both empty.
        </Trans>
      ) as any;
    }
  }
}

function validateForm(
  values: StoredSurveyLevellingPlotFormValues
): FormikErrors<StoredSurveyLevellingPlotFormValues> {
  let errors: FormikErrors<StoredSurveyLevellingPlotFormValues> = {
    ...validateStoredPlotNameSection(values),
    ...validateStoredPlotSaveAsValues(values),
  };

  validateAnnotations(values.plot_type, values.annotations, errors);

  values.survey_points.forEach((surveyPoint, idx) => {
    if (!surveyPoint.observation_point) {
      lodashSet(
        errors,
        ['survey_points', idx, 'observation_point'],
        <Trans>Observation point is required.</Trans>
      );
    }
    if (surveyPoint.label === '') {
      lodashSet(
        errors,
        ['survey_points', idx, 'label'],
        <Trans>Label is required.</Trans>
      );
    }
    if (surveyPoint.distance === '') {
      lodashSet(
        errors,
        ['survey_points', idx, 'distance'],
        <Trans>Distance is required.</Trans>
      );
    }
    if (!validateNumber(surveyPoint.distance)) {
      lodashSet(
        errors,
        ['survey_points', idx, 'distance'],
        <Trans>Distance must be a number.</Trans>
      );
    }
  });

  validateAxisRange(values, errors, 'x_axis_range_min', 'x_axis_range_max');
  validateAxisRange(values, errors, 'y_axis_range_min', 'y_axis_range_max');

  return errors;
}

const surveyPointLabelToggleOptions: RadioFieldOption<boolean>[] = [
  { label: <Trans>Show label</Trans>, value: true },
  { label: <Trans>Hide label</Trans>, value: false },
];

export function StoredSurveyLevellingPlotForm(
  props: StoredSurveyLevellingPlotFormProps
) {
  const { storedPlot, areaOptions, newPlotDefaults } = props;

  const dispatch = useDispatch();
  const allSurveyDatetimes = useSelector(
    (state: FullState) => state.storedPlot.surveyLevellingDetail
  );

  React.useEffect(
    () => () => {
      // Clear redux storage of survey datetimes
      dispatch(unmountStoredSurveyLevellingPlotForm());
    },
    [dispatch]
  );

  React.useEffect(() => {
    // Fetch survey datetimes for initially selected obs points.
    if (storedPlot) {
      storedPlot.survey_points.forEach(({ observation_point }) =>
        dispatch(fetchObsPointSurveyDatetimes(observation_point))
      );
    }
  }, [dispatch, storedPlot]);

  // Fetch survey datetimes whenever new obs points are selected.
  // (Used as a callback to a FormChangeEffect)
  const handleChangedObsPoints = React.useCallback(
    (
      curFormik: FormikProps<StoredSurveyLevellingPlotFormValues>,
      prevFormik: FormChangeEffectParams<StoredSurveyLevellingPlotFormValues>
    ) => {
      if (prevFormik.values.survey_points === curFormik.values.survey_points) {
        return;
      }

      if (
        prevFormik.values.survey_points.length ===
        curFormik.values.survey_points.length
      ) {
        curFormik.values.survey_points.forEach((survey_point, idx) => {
          // If the user selected a different observation point update the label
          if (
            survey_point.observation_point !==
            prevFormik.values.survey_points[idx].observation_point
          ) {
            if (
              survey_point.observation_point_detail &&
              survey_point.observation_point_detail.length > 0
            ) {
              curFormik.setFieldValue(
                `survey_points[${idx}].label`,
                survey_point.observation_point_detail[0].label
              );
            }
          }
        });
      }

      const prevObsPoints = prevFormik.values.survey_points.map(
        (sp) => sp.observation_point
      );
      const curObsPoints = curFormik.values.survey_points.map(
        (sp) => sp.observation_point
      );

      const newObsPoints = difference(curObsPoints, prevObsPoints).filter(
        isTruthy
      );
      newObsPoints.forEach((op) => dispatch(fetchObsPointSurveyDatetimes(op)));
    },
    [dispatch]
  );

  // A memoized selector (using reselect.js) for collating the list of unique
  // reading datetimes into the checkbox options list of survey datetimes.
  const selectSurveyTimePeriodOptions = useMemo(
    () =>
      createSelector(
        (
          formValues: StoredSurveyLevellingPlotFormValues,
          _props: StoredSurveyLevellingPlotFormProps
        ) => formValues.survey_points,
        (
          formValues: StoredSurveyLevellingPlotFormValues,
          _props: StoredSurveyLevellingPlotFormProps
        ) => formValues.time_periods,
        (
          formValues: StoredSurveyLevellingPlotFormValues,
          props: StoredSurveyLevellingPlotFormProps
        ) =>
          props.areaOptions.find((a) => a.value === formValues.area)
            ?.timeZone ?? null,
        function (
          survey_points,
          time_periods,
          timeZone
        ): SurveyTimePeriodOption[] {
          return sortedUniq(
            survey_points
              .flatMap(({ observation_point }) => {
                const opState = allSurveyDatetimes[observation_point];
                return opState ? opState.readingDatetimes : [];
              })
              // Display the currently selected datetimes as well, so that they
              // will be present in the form while we wait for the initial data
              // load
              //
              // This is a little bit awkward, because it means that if you select
              // a time period, then deselect all the obs points that have it, the time period
              // remains displayed until you de-select it. Then it suddenly disappears.
              //
              // BUT it's the easiest way to make sure the initial selection of
              // time periods doesn't disappear while the obs points data is loading.
              // A more polished approach would require tracking an "isInitialLoading"
              // state.
              .concat(
                time_periods.map(({ survey_datetime }) => survey_datetime)
              )
              .sort()
          )
            .map((surveyDatetime) => ({
              surveyDatetime,
              surveyDate: convertDatetimeToDate(surveyDatetime, timeZone),
            }))
            .map((survey, idx, surveyList) => {
              // Show the full reading datetime if it's on the same date as
              // the adjacent reading datetime.
              // Otherwise, just show the date.
              const prevSurvey = surveyList[idx - 1];
              const nextSurvey = surveyList[idx + 1];
              const isDateUnique =
                !(prevSurvey && prevSurvey.surveyDate === survey.surveyDate) &&
                !(nextSurvey && nextSurvey.surveyDate === survey.surveyDate);
              return {
                surveyDateOption: {
                  value: survey.surveyDatetime,
                  label: isDateUnique
                    ? formatDateForDisplay(survey.surveyDate)
                    : formatDatetimeForDisplay(survey.surveyDatetime, timeZone),
                },
                showPlotMarkers: true,
              };
            });
        }
      ),
    [allSurveyDatetimes]
  );

  const isLoadingSurveyDatetimes = useMemo(
    () => Object.values(allSurveyDatetimes).some((d) => d.isLoading),
    [allSurveyDatetimes]
  );

  const initialValues = makeInitialValues(storedPlot, newPlotDefaults);

  return (
    <Formik<StoredSurveyLevellingPlotFormValues>
      validate={validateForm}
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        const valuesForBackend: Model.StoredSurveyLevellingPlot_POST = {
          ...values,
          survey_points: values.survey_points.map(
            ({ observation_point, distance, label, show_label }) => ({
              observation_point,
              distance,
              label,
              show_label,
            })
          ),
          annotations: formatAnnotationFormValuesForSubmit(
            values.plot_type,
            values.annotations
          ) as Model.StoredPlotSurveyAnnotation[],
        };
        try {
          await props.onSubmit.call(
            null,
            valuesForBackend,
            getStoredPlotSaveAsSettings(values)
          );
        } catch (e) {
          formik.setSubmitting(false);
          showStoredPlotErrorsInFormik(formik, e, values);
        }
      }}
    >
      {(formik) => {
        const surveyTimePeriodOptions = selectSurveyTimePeriodOptions(
          formik.values,
          props
        );
        return (
          <Form>
            {formik.status}
            <FormCard
              name="general"
              header={<Trans>General</Trans>}
              subHeader={
                <StoredPlotSaveCancelButtons {...props} formik={formik} />
              }
            >
              <StoredPlotNameSection
                areaOptions={areaOptions}
                plotType={Enum.PlotType.SURVEY_LEVELLING}
              />
              <FieldArray name="survey_points">
                {(formikArrayHelpers) => (
                  <FormCardSection
                    name="survey-points"
                    header={<Trans>Survey points</Trans>}
                    fields={[
                      ...formik.values.survey_points.map(
                        (_surveyPoint, idx, surveyPoints) => ({
                          name: `survey-point-${idx}`,
                          columns: [
                            {
                              name: `survey-point-${idx}-observation-point`,
                              label: <Trans>Observation point</Trans>,
                              content: (
                                <>
                                  <ObsPointMenu
                                    name={`survey_points[${idx}].observation_point`}
                                    detailsName={`survey_points[${idx}].observation_point_detail`}
                                  />
                                  <FieldError
                                    name={`survey_points[${idx}].observation_point`}
                                  />
                                </>
                              ),
                            },
                            {
                              name: `survey-point-${idx}-label`,
                              label: <Trans>Label</Trans>,
                              className: 'survey-point-label-column',
                              content: (
                                <>
                                  <Field
                                    className="survey-point-label-field"
                                    name={`survey_points[${idx}].label`}
                                  />
                                  <FieldError
                                    name={`survey_points[${idx}].label`}
                                  />
                                </>
                              ),
                            },
                            {
                              name: `survey-point-${idx}-show-label`,
                              className: 'survey-point-show-label-column',
                              content: (
                                <>
                                  <ToggleField
                                    className="survey-point-show-label-toggle"
                                    name={`survey_points[${idx}].show_label`}
                                    options={surveyPointLabelToggleOptions}
                                  />
                                </>
                              ),
                            },
                            {
                              name: `survey-point-${idx}-distance`,
                              className: 'survey-point-distance-column',
                              label: <Trans>Distance</Trans>,
                              content: (
                                <>
                                  <Field
                                    type="text"
                                    name={`survey_points[${idx}].distance`}
                                  />
                                  <FieldError
                                    name={`survey_points[${idx}].distance`}
                                  />
                                </>
                              ),
                            },
                            {
                              name: `survey-point-${idx}-delete`,
                              content: (
                                <>
                                  {surveyPoints.length > 1 && (
                                    <Button
                                      onClick={() =>
                                        formikArrayHelpers.remove(idx)
                                      }
                                    >
                                      <Trans>Delete</Trans>
                                    </Button>
                                  )}
                                </>
                              ),
                            },
                          ],
                        })
                      ),
                      <div key="observation-point-add" className="card-row">
                        <div className="form-group">
                          <Button
                            data-test-id="survey-levelling-plot-add-observation-point-button"
                            iconType="icon-plus"
                            onClick={() =>
                              formikArrayHelpers.push({
                                observation_point: 0,
                                distance: '',
                                label: '',
                                show_label: true,
                              })
                            }
                          >
                            <Trans>Add observation point</Trans>
                          </Button>
                        </div>
                      </div>,
                    ]}
                  ></FormCardSection>
                )}
              </FieldArray>
              <FormCardSection
                name="layers"
                header={<Trans>Layers</Trans>}
                fields={[
                  {
                    name: 'background_image',
                    label: <Trans>Background image</Trans>,
                    className: 'card-row-file-upload',
                    content: (
                      <>
                        <FileField
                          name="background_image"
                          fileExtensions={['.jpg', '.png', '.svg']}
                        />

                        <FieldError name="background_image" />
                      </>
                    ),
                  },
                ]}
              />
              <FormCardSection
                name="display"
                header={<Trans>Display options</Trans>}
                fields={[
                  {
                    name: 'error_bars',
                    label: <Trans>Error bars</Trans>,
                    content: (
                      <>
                        <SimpleSelectField
                          name="error_bars"
                          options={Object.values(
                            Enum.StoredSurveyLevellingPlot_ERROR_BARS
                          ).map(
                            ReportFilterMenu.ENUM_MENU(
                              'StoredSurveyLevellingPlot_ERROR_BARS'
                            )
                          )}
                        />
                        <FieldError name="error_bars" />
                      </>
                    ),
                  },
                  {
                    name: 'downstream',
                    label: (
                      <Trans>
                        Distance measured left to right when looking
                      </Trans>
                    ),
                    content: (
                      <>
                        <ToggleField
                          name="downstream"
                          options={[
                            {
                              label: <Trans>Downstream</Trans>,
                              value: true,
                            },
                            {
                              label: <Trans>Upstream</Trans>,
                              value: false,
                            },
                          ]}
                        />
                        <FieldError name="downstream" />
                      </>
                    ),
                  },
                ]}
              />
              <FormCardSection
                name="x-axis-range"
                header={<Trans>X-Axis Range</Trans>}
                fields={[
                  {
                    name: 'x-axis-range',
                    columns: [
                      {
                        name: 'x-axis-min',
                        label: <Trans>Min</Trans>,
                        content: (
                          <>
                            <Field name="x_axis_range_min" />
                            <FieldError name="x_axis_range_min" />
                          </>
                        ),
                      },
                      {
                        name: 'x-axis-max',
                        label: <Trans>Max</Trans>,
                        content: (
                          <>
                            <Field name="x_axis_range_max"></Field>
                            <FieldError name="x_axis_range_max" />
                          </>
                        ),
                      },
                    ],
                  },
                ]}
              />
              <FormCardSection
                name="y-axis-range"
                header={<Trans>Y-Axis Range</Trans>}
                fields={[
                  {
                    name: 'y-axis-range',
                    columns: [
                      {
                        name: 'y-axis-min',
                        label: <Trans>Min</Trans>,
                        content: (
                          <>
                            <Field name="y_axis_range_min" />
                            <FieldError name="y_axis_range_min" />
                          </>
                        ),
                      },
                      {
                        name: 'y-axis-max',
                        label: <Trans>Max</Trans>,
                        content: (
                          <>
                            <Field name="y_axis_range_max"></Field>
                            <FieldError name="y_axis_range_max" />
                          </>
                        ),
                      },
                    ],
                  },
                ]}
              />
              <AnnotationsSubsection
                plotIdx={0}
                plotType={Enum.PlotType.SURVEY_LEVELLING}
                plot={formik.values}
              />
              <FormCardSection
                name="time-period"
                header={<Trans>Time periods</Trans>}
                fields={[
                  {
                    name: 'surveys',
                    label: <Trans>Surveys</Trans>,
                    content: (
                      <>
                        {isLoadingSurveyDatetimes && <Loading />}
                        <FieldError name="time_periods" />
                        {!surveyTimePeriodOptions.length &&
                        !formik.values.survey_points.some(
                          (sp) => sp.observation_point
                        ) ? (
                          <Trans>
                            Surveys will be listed when an observation point has
                            been selected.
                          </Trans>
                        ) : (
                          <SurveyTimePeriodsField
                            name="time_periods"
                            surveyTimePeriods={surveyTimePeriodOptions}
                          />
                        )}
                      </>
                    ),
                  },
                ]}
              />
            </FormCard>
            <FormChangeEffect<StoredSurveyLevellingPlotFormValues>
              // Fetch new possible survey dates, whenever a new obs point
              // is selected.
              onChange={(prevFormik) =>
                handleChangedObsPoints(formik, prevFormik)
              }
            />
            <ActionBlock className="text-right">
              <StoredPlotSaveCancelButtons {...props} formik={formik} />
            </ActionBlock>
          </Form>
        );
      }}
    </Formik>
  );
}
