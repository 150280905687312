import React from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { AlertInfo, AlertDanger } from 'components/base/alert/alert';
import ModalContent from 'components/base/modal/modalcontent';
import { Formik, Form, FormikErrors } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { getApi } from 'util/backendapi/fetch';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { DateField } from 'components/base/form/datefield/datefield';
import {
  convertDateToDatetime,
  START_OF_DAY,
  END_OF_DAY,
  formatDateForStorage,
  formatDatetimeForDisplayAsDate,
} from 'util/dates';
import { Filter, Model } from 'util/backendapi/models/api.interfaces';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { DataReviewState } from 'ducks/dataReview';
import Loading from 'components/base/loading/loading';
import { useHideModal } from 'components/base/modal/use-hide-modal';
import {
  ExportPanelModalButton,
  GetExportUrlFunc,
} from 'components/modules/exportpanel/exportpanel';
import SimpleSelect from 'components/base/form/simpleselect/simpleselect';
import './DataReview.scss';
import moment from 'moment-timezone';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';

export type OwnProps = {
  setActiveAreaQueryParam: (activeArea: number) => void;
  setReviewQueryParams: (
    allAreas: number[],
    activeArea: number,
    start_datetime: string,
    end_datetime: string
  ) => void;
  getExportUrl: GetExportUrlFunc;
  allAreaOptions: { value: number; label: string }[];
  areaOptionsErrorMessage: string | null;
  activeArea: number | null;
  result: DataReviewState['result'];
  isLoading: DataReviewState['isLoading'];
  errorMessage: DataReviewState['errorMessage'];
  isExporting?: boolean;
  startDatetime: string;
  endDatetime: string;
};

const DataReviewReportsHeader = (props: {
  allAreaOptions: OwnProps['allAreaOptions'];
  setActiveAreaQueryParam: OwnProps['setActiveAreaQueryParam'];
  activeArea: OwnProps['activeArea'];
}) => {
  const { allAreaOptions, activeArea, setActiveAreaQueryParam } = props;
  const reportAreaIdx = allAreaOptions.findIndex(
    (option) => option.value === activeArea
  );

  return (
    <div className="data-review-header">
      <h2>{allAreaOptions[reportAreaIdx].label}</h2>
      <ActionBlock className="data-review-pager">
        <Button
          iconOnly
          iconType="icon-arrow-left"
          title={t`Previous area in report`}
          disabled={reportAreaIdx === 0}
          onClick={() =>
            setActiveAreaQueryParam(allAreaOptions[reportAreaIdx - 1].value)
          }
        />
        <SimpleSelect
          name="currentArea"
          options={allAreaOptions}
          onChange={(areaId: number | null) => setActiveAreaQueryParam(areaId!)}
          value={activeArea!}
        />
        <Button
          iconOnly
          iconType="icon-arrow-right"
          title={t`Next area in report`}
          disabled={reportAreaIdx === allAreaOptions.length - 1}
          onClick={() =>
            setActiveAreaQueryParam(allAreaOptions[reportAreaIdx + 1].value)
          }
        />
      </ActionBlock>
    </div>
  );
};

export function DataReviewReportView(props: OwnProps) {
  const {
    isLoading,
    errorMessage,
    allAreaOptions,
    areaOptionsErrorMessage,
    activeArea,
    result,
    setActiveAreaQueryParam,
    startDatetime,
    endDatetime,
  } = props;

  let reportContent = (
    <AlertInfo>
      <Trans>Please provide settings for the data review report.</Trans>
    </AlertInfo>
  );

  if (isLoading) {
    reportContent = <Loading />;
  } else if (
    !isLoading &&
    result &&
    allAreaOptions.length &&
    (activeArea || props.isExporting)
  ) {
    reportContent = (
      <>
        {props.isExporting ? null : (
          <DataReviewReportsHeader
            activeArea={activeArea}
            setActiveAreaQueryParam={setActiveAreaQueryParam}
            allAreaOptions={allAreaOptions}
          />
        )}
        <DataReviewResultsTable
          results={result}
          allAreaOptions={allAreaOptions}
          isExporting={props.isExporting}
          startDatetime={startDatetime}
          endDatetime={endDatetime}
        />
      </>
    );
  } else if (errorMessage || areaOptionsErrorMessage) {
    reportContent = <AlertDanger>{errorMessage}</AlertDanger>;
  }

  return (
    <PageStandard
      name="data-review-report"
      header={props.isExporting ? null : <Trans>Data Review Report</Trans>}
    >
      {props.isExporting ? null : (
        <ActionBlock className="text-right">
          <ButtonShowModal
            name="data-review-report-settings"
            modalContent={() => (
              <DataReviewReportModal
                setReviewQueryParams={props.setReviewQueryParams}
              />
            )}
            autoShow={!allAreaOptions.length}
          >
            <Trans>Settings</Trans>
          </ButtonShowModal>
          <ExportPanelModalButton
            canExportXlsx={true}
            canExportPdf={true}
            taskQueueFormats={[ExportFormats.PDF, ExportFormats.XLSX]}
            getExportUrl={props.getExportUrl}
            canSelectPageOrientation={true}
          />
        </ActionBlock>
      )}
      {reportContent}
    </PageStandard>
  );
}
const DataReviewResultsTable = (props: {
  results: Model.DataReviewReportInstance[] | null;
  isExporting?: boolean;
  allAreaOptions: OwnProps['allAreaOptions'];
  startDatetime: string;
  endDatetime: string;
}) => {
  if (props.results) {
    return (
      <>
        {props.results.map((result, i) => {
          return (
            <div className="area-results" key={i}>
              <div className="heading-container">
                {props.isExporting ? (
                  <h3 className="pdf-heading">
                    {/*During export the backend ensures the order of results and allAreaOptions will match (and that it
                    will contain only the areas of the results array) so this is a safe assumption to make*/}
                    <Trans>
                      Data Review Report: {props.allAreaOptions[i].label}
                    </Trans>
                  </h3>
                ) : (
                  // Don't show the date range in the PDF, because it's added to the PDF page footer by the backend
                  <span>
                    <Trans>
                      Data between{' '}
                      {formatDatetimeForDisplayAsDate(props.startDatetime)} and{' '}
                      {formatDatetimeForDisplayAsDate(props.endDatetime)}
                    </Trans>
                  </span>
                )}
              </div>
              <>
                <h3>
                  <Trans>Data processed</Trans>
                </h3>
                <div
                  className="filtered-table-container"
                  key={`data-processed-${i}`}
                >
                  {!result.data_processed.length ? (
                    <Trans>None</Trans>
                  ) : (
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Trans>Batch Number</Trans>
                            </th>
                            <th>
                              <Trans>Batch Name</Trans>
                            </th>
                            <th>
                              <Trans>Created Date</Trans>
                            </th>
                            <th>
                              <Trans>Created By</Trans>
                            </th>
                            <th>
                              <Trans>Readings</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.data_processed.map((dataProcessed, i) => (
                            <tr key={i}>
                              <td>{dataProcessed['Batch Number']}</td>
                              <td>{dataProcessed['Batch Name']}</td>
                              <td>{dataProcessed['Created Date']}</td>
                              <td>{dataProcessed['Created By']}</td>
                              <td>{dataProcessed.Readings}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
              <>
                <h3>
                  <Trans>Missed Route Marches</Trans>
                </h3>
                <div
                  className="filtered-table-container"
                  key={`missed-route-march-${i}`}
                >
                  {!result.missed_route_marches.length ? (
                    <Trans>None</Trans>
                  ) : (
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Trans>Route March</Trans>
                            </th>
                            <th>
                              <Trans>Frequency</Trans>
                            </th>
                            <th>
                              <Trans>Tolerance</Trans>
                            </th>
                            <th>
                              <Trans>Last Received</Trans>
                            </th>
                            <th>
                              <Trans>Due</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.missed_route_marches.map(
                            (missedRouteMarch, i) => (
                              <tr key={i}>
                                <td>{missedRouteMarch['Route March']}</td>
                                <td>{missedRouteMarch.Frequency}</td>
                                <td>{missedRouteMarch.Tolerance}</td>
                                <td>{missedRouteMarch['Last Received']}</td>
                                <td>{missedRouteMarch.Due}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
              <>
                <h3>
                  <Trans>Missed readings (NaN)</Trans>
                </h3>
                <div
                  className="filtered-table-container"
                  key={`missed-readings-${i}`}
                >
                  {!result.missed_readings.length ? (
                    <Trans>None</Trans>
                  ) : (
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Trans>Observation Point</Trans>
                            </th>
                            <th>
                              <Trans>Instrument Type</Trans>
                            </th>
                            <th>
                              <Trans>Batch</Trans>
                            </th>
                            <th>
                              <Trans>Reading Date</Trans>
                            </th>
                            <th>
                              <Trans>Inspector Comment</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.missed_readings.map((missedReading, i) => (
                            <tr key={i}>
                              <td>{missedReading['Observation Point']}</td>
                              <td>{missedReading['Instrument Type']}</td>
                              <td>{missedReading.Batch}</td>
                              <td>{missedReading['Reading Date']}</td>
                              <td>{missedReading['Inspector Comment']}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
              <>
                <h3>
                  <Trans>Logger missed readings (NaN) summary</Trans>
                </h3>
                <div
                  className="filtered-table-container"
                  key={`logger-missed-readings-${i}`}
                >
                  {!result.logger_missed_readings.length ? (
                    <Trans>None</Trans>
                  ) : (
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Trans>Observation Point</Trans>
                            </th>
                            <th>
                              <Trans>Instrument Type</Trans>
                            </th>
                            <th>
                              <Trans>NaN Readings</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.logger_missed_readings.map(
                            (loggerMissedReading, i) => (
                              <tr key={i}>
                                <td>
                                  {loggerMissedReading['Observation Point']}
                                </td>
                                <td>
                                  {loggerMissedReading['Instrument Type']}
                                </td>
                                <td>{loggerMissedReading['NaN Readings']}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
              <>
                <h3>
                  <Trans>Area Comments</Trans>
                </h3>
                <div
                  className="filtered-table-container"
                  key={`area-comments-${i}`}
                >
                  {!result.area_comments.length ? (
                    <Trans>None</Trans>
                  ) : (
                    <div className="table-responsive">
                      <table>
                        <thead>
                          <tr>
                            <th>
                              <Trans>Comment Date</Trans>
                            </th>
                            <th>
                              <Trans>Commenter</Trans>
                            </th>
                            <th>
                              <Trans>Comment</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {result.area_comments.map((areaComment, i) => (
                            <tr key={i}>
                              <td>{areaComment['Comment Date']}</td>
                              <td>{areaComment.Commenter}</td>
                              <td>{areaComment.Comment}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </>
            </div>
          );
        })}
      </>
    );
  }
  return <p>no results</p>;
};

interface FormValues extends Filter.DataReview {
  area_details: { value: number; label: string; timeZone: string }[];
}

export function DataReviewReportModal(props: {
  setReviewQueryParams: OwnProps['setReviewQueryParams'];
}) {
  const hideModal = useHideModal();

  const initialValues: FormValues = {
    area_ids: [],
    area_details: [],
    start_datetime: formatDateForStorage(
      moment().utc().subtract(1, 'month').startOf('month')
    ),
    end_datetime: formatDateForStorage(
      moment().utc().subtract(1, 'month').endOf('month')
    ),
  };
  return (
    <ModalContent header={<Trans>Data review report settings</Trans>}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<FormValues> = {};
          if (!values.start_datetime) {
            errors.start_datetime = (
              <Trans>Start date is required.</Trans>
            ) as any;
          }
          if (!values.end_datetime) {
            errors.end_datetime = (<Trans>End date is required.</Trans>) as any;
          }
          if (
            values.start_datetime &&
            values.end_datetime &&
            moment(values.end_datetime).diff(
              moment(values.start_datetime),
              'days'
            ) > 365
          ) {
            errors.end_datetime = (
              <Trans>
                Date range between start date and end date must be a maximum of
                1 year
              </Trans>
            ) as any;
          }
          return errors;
        }}
        onSubmit={async (
          { area_details, start_datetime, end_datetime },
          formik
        ) => {
          try {
            // Use all areas in area group if no area is explicitly set
            if (!area_details.length) {
              const allAreas = await getApi('/areas/');
              area_details = allAreas.map((area) => ({
                value: area.id,
                timeZone: area.time_zone.name,
                label: area.name,
              }));
            }
            const timeZone = area_details[0]?.timeZone;

            props.setReviewQueryParams(
              area_details.map((detail) => detail.value),
              area_details[0].value,
              convertDateToDatetime(start_datetime, timeZone, START_OF_DAY),
              convertDateToDatetime(end_datetime, timeZone, END_OF_DAY)
            );
            hideModal();
          } catch (e) {
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, getExpectedFields(initialValues));
          }
        }}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <FormSection>
              <FormItem label={<Trans>Area</Trans>}>
                <AsyncSimpleSelectField<number, true>
                  autoFocus={true}
                  name="area_ids"
                  detailsName="area_details"
                  isMulti={true}
                  onSearch={async (searchText) => {
                    const areas = await getApi('/areas/', {
                      code__icontains: searchText,
                    });
                    return areas.map((a) => ({
                      value: a.id,
                      label: `${a.code} - ${a.name}`,
                      timeZone: a.time_zone.name,
                    }));
                  }}
                  // Never comes pre-loaded with defaults, so nothing to do here.
                  loadDefaults={async () => []}
                  placeholder={'All areas'}
                />
                <FieldError name="area_ids" />
              </FormItem>
              <FormItem
                label={<Trans>Start date</Trans>}
                fieldId="data-review-startdate"
                className="form-group-panel-inline"
              >
                <DateField
                  id="data-review-startdate"
                  data-testid="startdate"
                  name="start_datetime"
                />
              </FormItem>
              <FormItem
                label={<Trans>End date</Trans>}
                fieldId="data-review-enddate"
                className="form-group-panel-inline"
              >
                <DateField
                  data-testid="enddate"
                  name="end_datetime"
                  id="data-review-enddate"
                />
              </FormItem>
              <FieldError name="start_datetime" />
              <FieldError name="end_datetime" />
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="data-review-report-submit"
                  type="submit"
                >
                  <Trans>Apply settings</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </FormSection>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
