import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import Loading from 'components/base/loading/loading';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { Enum } from 'util/backendapi/models/api.interfaces';
import {
  AlertWarning,
  AlertInfo,
  AlertDanger,
} from 'components/base/alert/alert';
import { formatDatetimeForDisplay } from 'util/dates';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { BackButton } from 'components/base/back-button/BackButton';
import orderBy from 'lodash/orderBy';
import { deleteApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { DataLoggerDecorated } from 'util/backendapi/types/Model';
import { useDispatch } from 'react-redux';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import { fetchDataLogger } from 'ducks/data-logger/detail';
import {
  DataLogger_TIME_DEPENDENT_FIELD_NAME,
  DataLogger_DAYLIGHT_SAVING_HANDLING,
} from 'util/backendapi/types/Enum';

interface Props
  extends RouteComponentProps<{
    dataLoggerNumber: string;
    fieldName: Enum.DataLogger_TIME_DEPENDENT_FIELD_NAME;
  }> {}

/**
 * Screen for displaying a data logger's time dependent field history.
 * TODO: This is mostly a copy-paste from `SiteTimeDependentFieldsScreen.tsx`
 *
 * @param props
 */
export function DataLoggerTdfScreen(props: Props) {
  const { dataLoggerNumber: rawDlNumber, fieldName } = props.match.params;
  const dataLoggerNumber = +rawDlNumber;

  const {
    dataLoggerNumber: dlInRedux,
    loading: isLoading,
    error,
    dataLogger,
  } = useShallowEqualSelector((state: FullState) => {
    const dataLoggerState = state.dataLogger.detail;
    if (dataLoggerState.dataLoggerNumber !== dataLoggerNumber) {
      return {
        dataLoggerNumber: null,
        loading: true,
        dataLogger: null,
        error: null,
      };
    }
    return {
      dataLoggerNumber: dataLoggerState.dataLoggerNumber,
      loading: dataLoggerState.loading,
      error: dataLoggerState.errorLoading,
      dataLogger: dataLoggerState.dataLogger,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (dlInRedux !== dataLoggerNumber) {
      dispatch(fetchDataLogger(dataLoggerNumber));
    }
  }, [dataLoggerNumber, dispatch, dlInRedux]);

  const handleDelete = useCallback(
    async (fieldEntryId: number) => {
      await deleteApi(`/data-logger-time-dependent-fields/${fieldEntryId}/`);
      dispatch(fetchDataLogger(dataLoggerNumber));
    },
    [dataLoggerNumber, dispatch]
  );

  return (
    <DataLoggerTdfScreenView
      dataLoggerNumber={dataLoggerNumber}
      fieldName={fieldName}
      dataLogger={
        dataLogger?.logger_number === +dataLoggerNumber ? dataLogger : null
      }
      isLoading={isLoading}
      errorMessage={error}
      onDelete={handleDelete}
    />
  );
}

export function DataLoggerTdfScreenView(props: {
  dataLoggerNumber: number;
  fieldName: Enum.DataLogger_TIME_DEPENDENT_FIELD_NAME;
  isLoading: boolean;
  errorMessage: any;
  dataLogger: DataLoggerDecorated | undefined | null;
  onDelete: (fieldEntryId: number) => Promise<any>;
}) {
  const {
    dataLogger,
    dataLoggerNumber,
    fieldName,
    isLoading,
    errorMessage,
    onDelete,
  } = props;

  // Whitelist the field name param
  const isValidFieldName = Object.values(
    Enum.DataLogger_TIME_DEPENDENT_FIELD_NAME
  ).includes(fieldName);

  const fieldEntries = isValidFieldName
    ? orderBy(
        dataLogger?.data_logger_time_dependent_fields?.[fieldName] ?? [],
        [(e) => e.start_datetime],
        ['desc']
      )
    : [];

  // A tiny React component to curry `<TransEnum>`, since we have to call it
  // several times with the same props.
  const MyFieldName = useCallback(
    () =>
      isValidFieldName ? (
        <TransEnum
          enum="DataLogger_TIME_DEPENDENT_FIELD_NAME"
          value={fieldName}
        />
      ) : (
        <>{fieldName}</>
      ),
    [fieldName, isValidFieldName]
  );

  return (
    <PageStandard
      name="data-logger-time-dependent-fields"
      header={
        <Trans>
          <MyFieldName /> history for logger {dataLoggerNumber}
        </Trans>
      }
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton
          title={t`Back to data logger`}
          defaultBackUrl={`/data-loggers/${dataLoggerNumber}`}
        />
      </div>

      {!isValidFieldName ? (
        <AlertDanger>
          <Trans>"{fieldName}" is not a valid field name.</Trans>
        </AlertDanger>
      ) : errorMessage ? (
        <AlertWarning>{errorToString(errorMessage)}</AlertWarning>
      ) : isLoading ? (
        <Loading />
      ) : !dataLogger ? (
        <AlertWarning>Data logger {dataLoggerNumber} not found.</AlertWarning>
      ) : fieldEntries.length === 0 ? (
        <AlertInfo>
          <Trans>
            Data logger {dataLoggerNumber} has no existing <MyFieldName />{' '}
            records.
          </Trans>
        </AlertInfo>
      ) : (
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                {/* "Field" column not currently needed because there's only 
                one TDF on data loggers. */}
                {/* <th>
                  <Trans>Field</Trans>
                </th> */}
                <th>
                  <Trans>Start Date</Trans>
                </th>
                <th>
                  <Trans>Value</Trans>
                </th>
                <HasPermission check="can_delete_data_logger_time_dependent_fields">
                  <th className="action-icons">
                    <Trans>Actions</Trans>
                  </th>
                </HasPermission>
              </tr>
            </thead>
            <tbody>
              {fieldEntries.map(
                ({ id: fieldEntryId, start_datetime, value }) => (
                  <tr key={fieldEntryId}>
                    {/* "Field" column not currently needed because there's only 
                one TDF on data loggers. */}
                    {/* <td>
                      <MyFieldName />
                    </td> */}
                    <td>
                      {formatDatetimeForDisplay(
                        start_datetime,
                        dataLogger.site.area.time_zone.name
                      )}
                    </td>
                    <td>
                      {fieldName ===
                      DataLogger_TIME_DEPENDENT_FIELD_NAME.daylight_saving_handling ? (
                        <TransEnum
                          enum="DataLogger_DAYLIGHT_SAVING_HANDLING"
                          value={value as DataLogger_DAYLIGHT_SAVING_HANDLING}
                        />
                      ) : (
                        value
                      )}
                    </td>
                    <HasPermission check="can_delete_data_logger_time_dependent_fields">
                      <td className="action-icons">
                        <ButtonShowConfirmation
                          name="delete-data-logger-time-dependent-field"
                          title={t`Delete`}
                          iconType="icon-circle-minus"
                          className="btn-link-panel"
                          content={
                            <Trans>
                              Are you sure you want to delete this{' '}
                              <strong>
                                <MyFieldName />
                              </strong>{' '}
                              record? This action is not reversible.
                            </Trans>
                          }
                          okBtnText={<Trans>Yes, delete</Trans>}
                          onConfirm={() => onDelete(fieldEntryId)}
                        />
                      </td>
                    </HasPermission>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </PageStandard>
  );
}
