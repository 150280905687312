import React from 'react';
import { detectExportMode } from 'util/export';
import { Trans } from '@lingui/macro';
import { getCurrentDatetime, formatDatetimeForDisplay } from 'util/dates';
import { useSelector } from 'react-redux';
import { selectLoggedInUserName } from 'ducks/login';

/**
 * A component for rendering the string we often display on report screens
 * (particularly during export), that reads:
 *
 *     Generated on 01/07/2019 12:50 by Aaron Wells
 *
 * TODO: With our new PDF rendering flow, the equivalent to this message
 * gets generated by the Python backend and inserted into the PDF.
 *
 * That will mean that we no longer need this component for any of the screens
 * that have the default "show only in export mode" behavior. Which pretty much
 * leaves only the one on the Quick List screen.
 */
interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  /**
   * The datetime to display.
   *   - If a valid ISO datetime string, will render it in user timezone
   *   - If not provided (or `undefined`), will use the time this component
   * instance was mounted.
   *   - If `null`, will print a placeholder to indicate that the report has
   * not yet been "generated"
   */
  when?: string | null;
  /**
   * Whether to show the message only during PDF export (default: false)
   */
  showInExportMode?: boolean;
}
export const GeneratedOnMessage: React.FunctionComponent<Props> = function (
  props
) {
  const name = useSelector(selectLoggedInUserName);
  const timeAtMount = React.useRef(getCurrentDatetime());
  let timeString: string;
  if (props.when === undefined) {
    timeString = formatDatetimeForDisplay(timeAtMount.current);
  } else if (props.when) {
    timeString = formatDatetimeForDisplay(props.when);
  } else {
    timeString = '...';
  }

  if ((detectExportMode() && props.showInExportMode) || !detectExportMode()) {
    const { when, showInExportMode, ...otherProps } = props;
    return (
      <p {...otherProps}>
        <Trans>
          Generated on {timeString} by {name}
        </Trans>
      </p>
    );
  } else {
    return null;
  }
};
GeneratedOnMessage.defaultProps = {
  showInExportMode: false,
};
