import React from 'react';
import { NumberField } from '../number-field/NumberField';

type Props = Merge<
  React.InputHTMLAttributes<HTMLInputElement>,
  {
    name: string;
    unit: string;
  }
>;

export function UnitOfMeasurementField(props: Props) {
  const { name, unit, ...otherProps } = props;
  return (
    <div className="input-unit-wrapper">
      <NumberField name={name} {...otherProps} />
      <span className="input-unit">{unit}</span>
    </div>
  );
}
