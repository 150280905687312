import React from 'react';
import { Trans, Plural, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import {
  END_OF_DAY,
  formatDateForDisplay,
  formatDatetimeForDisplay,
  START_OF_DAY,
} from 'util/dates';
import ActionBlock from 'components/base/actionblock/actionblock';
import { TransEnum } from 'components/base/i18n/TransEnum';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportStateProps } from 'hooks/use-report-state';
import { reportFilterDateNoMenu } from 'components/modules/report/filter/fields/FilterDateNoMenu';
import { getDisplayableFilters } from 'components/modules/report/report-utils';
import { useRouteComponentProps } from 'util/hooks';
import { DMSLink } from 'components/base/link/DMSLink';
import { HasPermission } from 'components/logic/has-permission/HasPermission';

export interface ReadingCommentsListViewProps {
  reportProps: ReportStateProps<Model.ReportsReadingComment>;
}

export const readingCommentsListFilters: ReportFilter[] = [
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterDateNoMenu(
    'start-comment-date',
    'created_datetime__gte',
    <Trans>Start comment date</Trans>,
    null,
    START_OF_DAY
  ),
  reportFilterDateNoMenu(
    'end-comment-date',
    'created_datetime__lte',
    <Trans>End comment date</Trans>,
    null,
    END_OF_DAY
  ),
  reportFilterMenu(
    // This name should match the back-end query param name
    'comment_filter',
    <Trans>Hide excluded words and phrases</Trans>,
    { isMulti: false, valueType: 'string' },
    ReportFilterMenu.PASS_MENU_THROUGH,
    {
      placeholder: <Trans>Yes</Trans>,
      options: [
        { label: <Trans>Yes</Trans>, value: 'true' },
        { label: <Trans>No</Trans>, value: 'false' },
      ],
    }
  ),
];

export const readingCommentsListColumns: ReportColumn<Model.ReportsReadingComment>[] =
  [
    {
      label: <Trans>Area</Trans>,
      name: 'area_code',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Observation point</Trans>,
      name: 'observation_point_code',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Comment date and time</Trans>,
      name: 'created_datetime',
      visibility: DEFAULT_SHOW,
      accessor: ({ created_datetime: value }) =>
        formatDatetimeForDisplay(value), // datetime is relative to user
    },
    {
      label: <Trans>Data type</Trans>,
      name: 'resourcetype',
      visibility: DEFAULT_HIDE,
      accessor: (row) => (
        <TransEnum enum="Comment_RESOURCE_TYPE" value={row.resourcetype} />
      ),
    },
    {
      label: <Trans>Comment type</Trans>,
      name: 'comment_type',
      visibility: DEFAULT_HIDE,
      accessor: (row) => (
        <TransEnum enum="Comment_TYPE" value={row.comment_type} />
      ),
    },
    {
      label: <Trans>Commenter</Trans>,
      name: 'commenter_name',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Comment</Trans>,
      name: 'content',
      backendFieldName: 'content',
      visibility: DEFAULT_SHOW,
      tdClassName: 'text-with-linebreaks',
    },
  ];

function ReadingCommentsReportSubHeader(props: ReadingCommentsListViewProps) {
  const { reportProps } = props;

  const routeProps = useRouteComponentProps();

  const filtersToRender = getDisplayableFilters(
    routeProps,
    null,
    readingCommentsListFilters,
    reportProps.reportInfo?.filters
  );

  const [commentStartDate] = filtersToRender.filter(
    (f) => f.filterName === 'start-comment-date' && f.hasValues
  );
  const [commentEndDate] = filtersToRender.filter(
    (f) => f.filterName === 'end-comment-date' && f.hasValues
  );

  return (
    <div className="ssr-active-filters-block">
      <Trans>
        Comments between{' '}
        {commentStartDate?.value
          ? formatDateForDisplay(commentStartDate.value)
          : '-'}{' '}
        and{' '}
        {commentEndDate?.value
          ? formatDateForDisplay(commentEndDate.value)
          : '-'}
      </Trans>
    </div>
  );
}

export function ReadingCommentsListView(props: ReadingCommentsListViewProps) {
  const { reportProps } = props;

  return (
    <PageStandard
      name="list-reading-comments"
      header={
        reportProps.isExportMode ? (
          <Trans>Reading Comments Report</Trans>
        ) : (
          <Trans>Reading Comments</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_view_reading_comments_report_exclusions">
          <ActionBlock className="text-right">
            <DMSLink to="/reading-comment-exclusions/" className="btn">
              <span>
                <Trans>View Exclusions List</Trans>
              </span>
            </DMSLink>
          </ActionBlock>
        </HasPermission>
        {reportProps.isExportMode ? (
          <ReadingCommentsReportSubHeader {...props} />
        ) : (
          <ReportFiltersBlock
            filtersFrontend={readingCommentsListFilters}
            filtersBackend={reportProps.reportInfo?.filters}
            isExportMode={reportProps.isExportMode}
            filtersToShow={[
              'area',
              'start-comment-date',
              'end-comment-date',
              'comment_filter',
            ]}
          />
        )}
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <SaveReportModalButtons
            columnsFrontend={readingCommentsListColumns}
            filtersFrontend={readingCommentsListFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Reading comments report`}
            reportUrl="/reports/reading-comments/"
            columns={readingCommentsListColumns}
            filters={readingCommentsListFilters}
          />
        </ActionBlock>
        <ReportTable
          columns={readingCommentsListColumns}
          filters={readingCommentsListFilters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 comment"
              other="Filtered to # comments"
            />
          )}
          msgNoMatches={<Trans>No comments match the selected filters.</Trans>}
        />
      </div>
    </PageStandard>
  );
}
