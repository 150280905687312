import { EntityTypes } from 'ducks/entities';
import { withInsertEntities } from 'ducks/entities';
import { Model } from 'util/backendapi/models/api.interfaces';
import { errorToString } from 'util/backendapi/error';
import { StandardThunk, DuckActions } from 'main/store';
import { getApi, patchApi } from 'util/backendapi/fetch';

export const ActionTypes = {
  FETCH_USER_PROFILE_START: 'dms/userprofile/FETCH_USER_PROFILE_START',
  FETCH_USER_PROFILE_RESPONSE: 'dms/userprofile/FETCH_USER_PROFILE_RESPONSE',
  FETCH_USER_PROFILE_ERROR: 'dms/userprofile/FETCH_USER_PROFILE_ERROR',
  UPDATE_USER_PROFILE_START: 'dms/userprofile/UPDATE_USER_PROFILE',
  UPDATE_USER_PROFILE_RESPONSE: 'dms/userprofile/UPDATE_USER_PROFILE_RESPONSE',
  UPDATE_USER_PROFILE_ERROR: 'dms/userprofile/UPDATE_USER_PROFILE_ERROR',
  UNMOUNT_USER_PROFILE_SCREEN: 'dms/userprofile/UNMOUNT_USER_PROFILE_SCREEN',
} as const;

export const ActionCreators = {
  FETCH_USER_PROFILE_START: (userId: number) => ({
    type: ActionTypes.FETCH_USER_PROFILE_START,
    userId,
  }),
  FETCH_USER_PROFILE_RESPONSE: (userId: number, user: Model.User) => ({
    type: ActionTypes.FETCH_USER_PROFILE_RESPONSE,
    userId,
    payload: { user },
  }),
  FETCH_USER_PROFILE_ERROR: (userId: number, errorMessage: string) => ({
    userId,
    type: ActionTypes.FETCH_USER_PROFILE_ERROR,
    error: true,
    payload: errorMessage,
  }),
  UPDATE_USER_PROFILE_START: () => ({
    type: ActionTypes.UPDATE_USER_PROFILE_START,
  }),
  UPDATE_USER_PROFILE_RESPONSE: (user: Model.User) =>
    // TODO: We need to put the user into entities, because that's where
    // the code that looks for the "current user" looks for it. We should
    // probably change that.
    withInsertEntities(
      {
        type: ActionTypes.UPDATE_USER_PROFILE_RESPONSE,
        payload: { user },
      },
      EntityTypes.USER,
      [user],
      false
    ),
  UPDATE_USER_PROFILE_ERROR: (errorMessage: string) => ({
    type: ActionTypes.UPDATE_USER_PROFILE_ERROR,
    error: true,
    payload: errorMessage,
  }),
  UNMOUNT_USER_PROFILE_SCREEN: () => ({
    type: ActionTypes.UNMOUNT_USER_PROFILE_SCREEN,
  }),
};
export type UserProfileAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface UserProfileState {
  user: Model.User | null;
  userId: number | null;
  isLoading: boolean;
  errorMessage: string | null;
}

export function userProfileInitialState(): UserProfileState {
  return {
    user: null,
    userId: null,
    isLoading: false,
    errorMessage: null,
  };
}

export default function userProfileReducer(
  state = userProfileInitialState(),
  action: UserProfileAction
): UserProfileState {
  switch (action.type) {
    case ActionTypes.FETCH_USER_PROFILE_START:
      return {
        ...state,
        isLoading: true,
        userId: action.userId,
      };
    case ActionTypes.FETCH_USER_PROFILE_RESPONSE:
      if (action.userId !== state.userId) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        user: action.payload.user,
      };
    case ActionTypes.FETCH_USER_PROFILE_ERROR:
      if (action.userId !== state.userId) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case ActionTypes.UPDATE_USER_PROFILE_RESPONSE:
      if (action.payload.user.id !== state.userId) {
        return state;
      }
      return {
        ...state,
        user: action.payload.user,
      };
    case ActionTypes.UNMOUNT_USER_PROFILE_SCREEN:
      return userProfileInitialState();
    default:
      return state;
  }
}

export const unmountUserProfileScreen =
  ActionCreators.UNMOUNT_USER_PROFILE_SCREEN;

/**
 * A redux-thunk action that fetches all the data about a user
 * that is needed to view the user profile screen.
 *
 * @export
 * @param {string} userId
 * @returns {function} A redux thunk function
 */
export function fetchUserProfile(userId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_USER_PROFILE_START(userId));

    try {
      const user = await getApi(`/users/${userId}/`);

      return dispatch(ActionCreators.FETCH_USER_PROFILE_RESPONSE(userId, user));
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_USER_PROFILE_ERROR(userId, errorToString(e))
      );
    }
  };
}

/**
 * Redux thunk action creator, to update a user profile on the backend.
 *
 * @export
 * @param {*} user The new values for the user.
 * @returns
 */
export function updateCurrentUserProfile(
  user: Model.CurrentUser_PATCH
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.UPDATE_USER_PROFILE_START());

    try {
      const updatedUser = await patchApi('/users/current/', user);

      return dispatch(ActionCreators.UPDATE_USER_PROFILE_RESPONSE(updatedUser));
    } catch (e) {
      dispatch(ActionCreators.UPDATE_USER_PROFILE_ERROR(errorToString(e)));
      throw e;
    }
  };
}
