import React, { useMemo } from 'react';
import { FormikProps, ErrorMessage, Field, FormikErrors } from 'formik';
import { Trans } from '@lingui/macro';
import { EditableCardSectionComponent } from 'components/base/form/editablecard/editablecard';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { SitesMenuAutoLoading } from 'components/modules/sitesmenu/sitesmenu';
import { DateField } from 'components/base/form/datefield/datefield';
import { formatDateForDisplay } from 'util/dates';
import { Model } from 'util/backendapi/models/api.interfaces';
import { AreaObsPointMenu } from './areaObsPointMenu/areaObsPointMenu';
import { IntervalField } from 'components/base/form/interval/intervalfield';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import {
  IntegerField,
  IntegerFieldValue,
} from 'components/base/form/integer-field/IntegerField';
import { validatePositiveInteger, isNotNull } from 'util/validation';
import {
  DataLogger_DAYLIGHT_SAVING_HANDLING,
  DataLogger_TIME_DEPENDENT_FIELD_NAME,
} from 'util/backendapi/types/Enum';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';

// New imports
import { DatetimeField } from 'components/base/form/datefield/datefield';
import Button from 'components/base/button/button';
import lodashSet from 'lodash/set';
import { getCurrentDatetime, formatDatetimeForDisplay } from 'util/dates';
import { DataLogger_POST } from 'util/backendapi/types/Model';
import { DMSLink } from 'components/base/link/DMSLink';
import { YesNoToggleField } from 'components/base/form/toggle-field/ToggleField';

export interface DataLoggerFormValues {
  logger_number: number | '';
  area: number | '';
  site: number | '';
  model: string;
  install_date: string;
  install_by: string;
  install_comments: string;
  null_reading_observation_point: number | '';
  missed_download_observation_point: number | '';
  maintain_observation_point_latest_readings: boolean;
  reading_frequency: string | null;
  reading_frequency_tolerance: string | null;
  download_frequency: string | null;
  download_frequency_tolerance: string | null;
  number_of_channels: IntegerFieldValue;
  data_logger_time_dependent_fields: {
    daylight_saving_handling: [
      {
        isEditing: boolean;
        value: DataLogger_DAYLIGHT_SAVING_HANDLING | null;
        start_datetime: string;
      }
    ];
  };
}

interface DataLoggerFormBodyProps {
  formik: FormikProps<DataLoggerFormValues>;
  CardSectionComponent: EditableCardSectionComponent;
  isEditing: boolean;
  areas: Array<Model.AreaDecorated>;
  dataLogger: Model.DataLoggerDecorated | null;
  nullReadingObsPoint: Model.ObservationPointDecorated | null;
  missedDownloadObsPoint: Model.ObservationPointDecorated | null;
}

export function formatDataLoggerForBackend(
  values: DataLoggerFormValues
): DataLogger_POST {
  const { data_logger_time_dependent_fields: timeDepFields, ...otherValues } =
    values;
  const daylightSavingHandling = timeDepFields?.daylight_saving_handling?.[0];
  return {
    ...otherValues,
    logger_number: +values.logger_number,
    site: +values.site,
    null_reading_observation_point: !values.null_reading_observation_point
      ? null
      : values.null_reading_observation_point,
    missed_download_observation_point: !values.missed_download_observation_point
      ? null
      : values.missed_download_observation_point,
    install_date: values.install_date ? values.install_date : null,
    number_of_channels: +values.number_of_channels,
    data_logger_time_dependent_fields: daylightSavingHandling.isEditing
      ? {
          [DataLogger_TIME_DEPENDENT_FIELD_NAME.daylight_saving_handling]: [
            {
              value: daylightSavingHandling.value!,
              start_datetime: daylightSavingHandling.start_datetime,
            },
          ],
        }
      : {},
  };
}

export const validateDataLoggerForm = (
  values: DataLoggerFormValues
): FormikErrors<DataLoggerFormValues> => {
  let errors = {} as FormikErrors<DataLoggerFormValues>;

  if (!values.logger_number && values.logger_number !== 0) {
    errors.logger_number = (<Trans>Logger ID is required.</Trans>) as any;
  }
  if (!values.site) {
    errors.site = (<Trans>Site is required.</Trans>) as any;
  }

  if (values.reading_frequency && !values.reading_frequency_tolerance) {
    errors.reading_frequency_tolerance = (
      <Trans>
        Reading frequency tolerance is required, when reading frequency has been
        supplied.
      </Trans>
    ) as any;
  }

  if (values.reading_frequency_tolerance && !values.reading_frequency) {
    errors.reading_frequency = (
      <Trans>
        Reading frequency is required, when reading frequency tolerance has been
        supplied.
      </Trans>
    ) as any;
  }

  if (values.missed_download_observation_point) {
    if (!values.download_frequency) {
      errors.download_frequency = (
        <Trans>Download frequency is required.</Trans>
      ) as any;
    }
    if (!values.download_frequency_tolerance) {
      errors.download_frequency_tolerance = (
        <Trans>Download frequency tolerance is required.</Trans>
      ) as any;
    }
  }

  if (!validatePositiveInteger(values.number_of_channels)) {
    errors.number_of_channels = (
      <Trans>Number of channels must be a positive integer number.</Trans>
    ) as any;
  }

  // TODO RM-89206 the AC's don't say whether this should be a required field?
  const dst =
    values.data_logger_time_dependent_fields.daylight_saving_handling[0];
  if (dst.isEditing) {
    if (!dst.value) {
      lodashSet(
        errors,
        'daylightSavingHandling.value',
        <Trans>This field is required</Trans>
      );
    }

    if (!dst.start_datetime) {
      lodashSet(
        errors,
        'daylightSavingHandling.start_datetime',
        <Trans>This field is required</Trans>
      );
    }
  }

  return errors;
};

export function DataLoggerFormBody(props: DataLoggerFormBodyProps) {
  const { formik, CardSectionComponent, isEditing, dataLogger, areas } = props;
  const { values } = formik;
  const nullReadingObsPointLabel = props.nullReadingObsPoint ? (
    props.nullReadingObsPoint.code
  ) : (
    <Trans>N/A</Trans>
  );
  const missedDownloadObsPointLabel = props.missedDownloadObsPoint ? (
    props.missedDownloadObsPoint.code
  ) : (
    <Trans>N/A</Trans>
  );
  const dstHandling =
    values.data_logger_time_dependent_fields.daylight_saving_handling[0];

  const areaMenuOptions = useMemo(
    function () {
      if (!areas || areas.length === 0) {
        return [];
      }

      return areas.map((a) => ({
        value: a.id,
        label: a.name,
      }));
    },
    [areas]
  );

  const selectedArea = useMemo(
    function () {
      return areas.find((area) => area.id === values.area);
    },
    [values.area, areas]
  );
  return (
    <>
      {formik.status}
      <CardSectionComponent
        name="general"
        header={<Trans>General</Trans>}
        fields={[
          {
            name: 'logger_number',
            label: <Trans>Logger ID</Trans>,
            content: isEditing ? (
              <>
                <Field type="text" name="logger_number" />
                <ErrorMessage name="logger_number" component={ErrorNotice} />
              </>
            ) : (
              dataLogger && dataLogger.logger_number
            ),
          },
          {
            name: 'area',
            label: <Trans>Area</Trans>,
            content: isEditing ? (
              <>
                <SimpleSelectField
                  name="area"
                  placeholder="Please select an area"
                  options={areaMenuOptions}
                />
                <ErrorMessage name="area" component={ErrorNotice} />
              </>
            ) : (
              props.dataLogger && props.dataLogger.site.area.name
            ),
          },
          {
            name: 'site',
            label: <Trans>Site</Trans>,
            content: isEditing ? (
              <>
                <SitesMenuAutoLoading
                  name="site"
                  selectedAreaCode={selectedArea?.code}
                  initialSiteCode={
                    props.dataLogger && props.dataLogger.site.code
                  }
                />
                <ErrorMessage name="site" component={ErrorNotice} />
              </>
            ) : (
              props.dataLogger && props.dataLogger.site.code
            ),
          },
          {
            name: 'model',
            label: <Trans>Model</Trans>,
            content: isEditing ? (
              <>
                <Field type="text" name="model" />
                <ErrorMessage name="model" component={ErrorNotice} />
              </>
            ) : (
              dataLogger && dataLogger.model
            ),
          },
          {
            name: 'null_reading_observation_point',
            label: <Trans>Null reading observation point</Trans>,
            content: isEditing ? (
              <>
                <AreaObsPointMenu
                  name="null_reading_observation_point"
                  initialSelectionLabel={nullReadingObsPointLabel}
                  selectedAreaId={formik.values.area}
                />
                <FieldError name="null_reading_observation_point" />
              </>
            ) : (
              nullReadingObsPointLabel
            ),
          },
          {
            name: 'missed_download_observation_point',
            label: <Trans>Missed download observation point</Trans>,
            content: isEditing ? (
              <>
                <AreaObsPointMenu
                  name="missed_download_observation_point"
                  initialSelectionLabel={missedDownloadObsPointLabel}
                  selectedAreaId={formik.values.area}
                />
                <FieldError name="missed_download_observation_point" />
              </>
            ) : (
              missedDownloadObsPointLabel
            ),
          },
          {
            name: 'maintain_observation_point_latest_readings',
            label: <Trans>Maintain observation point latest 10 readings</Trans>,
            content: isEditing ? (
              <>
                <YesNoToggleField name="maintain_observation_point_latest_readings" />
              </>
            ) : props.dataLogger &&
              props.dataLogger.maintain_observation_point_latest_readings ? (
              'Yes'
            ) : (
              'No'
            ),
          },
        ]}
      />
      <CardSectionComponent
        name="installation"
        header={<Trans>Installation</Trans>}
        fields={[
          {
            name: 'install_date',
            label: <Trans>Installation date</Trans>,
            content: isEditing ? (
              <>
                <DateField name="install_date" />
                <ErrorMessage name="install_date" component={ErrorNotice} />
              </>
            ) : (
              dataLogger && formatDateForDisplay(dataLogger.install_date as any)
            ),
          },
          {
            name: 'install_by',
            label: <Trans>Installed by</Trans>,
            content: isEditing ? (
              <>
                <Field name="install_by" type="text" />
                <ErrorMessage name="install_by" component={ErrorNotice} />
              </>
            ) : (
              dataLogger && dataLogger.install_by
            ),
          },
          {
            name: 'install_comments',
            label: <Trans>Installation comments</Trans>,
            content: isEditing ? (
              <>
                <Field name="install_comments" component="textarea" />
                <ErrorMessage name="install_comments" component={ErrorNotice} />
              </>
            ) : (
              <div className="text-with-linebreaks">
                {dataLogger && dataLogger.install_comments}
              </div>
            ),
          },
          {
            name: 'daylightSavingHandling',
            columns: [
              {
                name: 'daylightSavingHandlingValue',
                label: <Trans>Daylight Saving Time handling</Trans>,
                content: isEditing ? (
                  <>
                    <SimpleSelectField
                      isDisabled={!dstHandling.isEditing}
                      name="data_logger_time_dependent_fields.daylight_saving_handling.0.value"
                      options={menuItemsFromEnum(
                        'DataLogger_DAYLIGHT_SAVING_HANDLING'
                      )}
                    />
                    <FieldError name="data_logger_time_dependent_fields.daylight_saving_handling.0.value" />
                  </>
                ) : (
                  <span>
                    <TransEnum
                      enum="DataLogger_DAYLIGHT_SAVING_HANDLING"
                      value={dstHandling.value}
                    />
                  </span>
                ),
              },
              {
                name: 'daylightSavingHandlingDatetime',
                label: <Trans>Start</Trans>,
                content: isEditing ? (
                  <>
                    <DatetimeField
                      name="data_logger_time_dependent_fields.daylight_saving_handling.0.start_datetime"
                      disabled={!dstHandling.isEditing}
                      timeZone={selectedArea?.time_zone.name}
                    />
                    <FieldError name="data_logger_time_dependent_fields.daylight_saving_handling.0.start_datetime" />
                    {!dstHandling.isEditing && (
                      <Button
                        onClick={() => {
                          // Can I just set the whole `daylightSavingHandling`
                          // object in one go, or will that error out?
                          formik.setFieldValue(
                            'data_logger_time_dependent_fields.daylight_saving_handling.0.start_datetime',
                            getCurrentDatetime()
                          );
                          formik.setFieldValue(
                            'data_logger_time_dependent_fields.daylight_saving_handling.0.isEditing',
                            true
                          );
                        }}
                      >
                        <Trans>Edit</Trans>
                      </Button>
                    )}
                  </>
                ) : (
                  <span>
                    {formatDatetimeForDisplay(
                      dstHandling.start_datetime,
                      selectedArea?.time_zone.name
                    )}
                  </span>
                ),
              },
              isEditing || !dataLogger
                ? null
                : {
                    name: `daylight_saving_handling-view-history-link`,
                    content: (
                      <DMSLink
                        to={`/data-loggers/${dataLogger?.logger_number}/time-dependent-fields/daylight_saving_handling/`}
                      >
                        <Trans>View history</Trans>
                      </DMSLink>
                    ),
                  },
            ].filter(isNotNull),
          },
          {
            name: 'number_of_channels',
            label: <Trans>Number of channels</Trans>,
            content: isEditing ? (
              <>
                <IntegerField name="number_of_channels" />
                <FieldError name="number_of_channels" />
              </>
            ) : (
              dataLogger && dataLogger.number_of_channels
            ),
          },
        ]}
      />

      <CardSectionComponent
        name="frequency"
        header={<Trans>Frequency</Trans>}
        fields={[
          {
            name: 'reading_frequency',
            label: <Trans>Reading frequency</Trans>,
            formGroupClassName: 'form-group-interval-select',
            content: isEditing ? (
              <>
                <IntervalField
                  name="reading_frequency"
                  id="reading_frequency-input"
                  defaultUnit="hours"
                />
                <ErrorMessage
                  component={ErrorNotice}
                  name="reading_frequency"
                />
              </>
            ) : (
              <IntervalDisplay
                value={dataLogger && dataLogger.reading_frequency}
              />
            ),
          },
          {
            name: 'reading_frequency_tolerance',
            label: <Trans>Reading frequency tolerance</Trans>,
            formGroupClassName: 'form-group-interval-select',
            content: isEditing ? (
              <>
                <IntervalField
                  name="reading_frequency_tolerance"
                  id="reading_frequency_tolerance-input"
                  defaultUnit="hours"
                />
                <ErrorMessage
                  component={ErrorNotice}
                  name="reading_frequency_tolerance"
                />
              </>
            ) : (
              <IntervalDisplay
                value={dataLogger && dataLogger.reading_frequency_tolerance}
              />
            ),
          },
          {
            name: 'download_frequency',
            label: <Trans>Download frequency</Trans>,
            formGroupClassName: 'form-group-interval-select',
            content: isEditing ? (
              <>
                <IntervalField
                  name="download_frequency"
                  id="download_frequency-input"
                  defaultUnit="hours"
                />
                <ErrorMessage
                  component={ErrorNotice}
                  name="download_frequency"
                />
              </>
            ) : (
              <IntervalDisplay
                value={dataLogger && dataLogger.download_frequency}
              />
            ),
          },
          {
            name: 'download_frequency_tolerance',
            label: <Trans>Download frequency tolerance</Trans>,
            formGroupClassName: 'form-group-interval-select',
            content: isEditing ? (
              <>
                <IntervalField
                  name="download_frequency_tolerance"
                  id="download_frequency_tolerance-input"
                  defaultUnit="hours"
                />
                <ErrorMessage
                  component={ErrorNotice}
                  name="download_frequency_tolerance"
                />
              </>
            ) : (
              <IntervalDisplay
                value={dataLogger && dataLogger.download_frequency_tolerance}
              />
            ),
          },
        ]}
      />
    </>
  );
}
