import React from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import Button from '../button/button';
import { PageStandardContext } from '../../modules/pagestandard/pagestandard';
import './modal.scss';

interface ModalContentProps {
  header?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  onClose?: () => void;
}
const ModalContent: React.FunctionComponent<ModalContentProps> =
  function ModalContent(props) {
    return (
      <PageStandardContext.Consumer>
        {(context) => (
          <div
            className={classNames('modal-inner', props.className)}
            id={`${context.pageName}-modal-${context.activeModalName}`}
          >
            <div className="modal-header">
              {props.header && <h2>{props.header}</h2>}
              <Button
                id="modal-close"
                className="close"
                onClick={props.onClose || context.hideModal}
                iconType="icon-cross"
                iconOnly={true}
              >
                <Trans>Close</Trans>
              </Button>
            </div>
            {props.children}
          </div>
        )}
      </PageStandardContext.Consumer>
    );
  };

export default ModalContent;
