import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Media_CONTENT_TYPE } from 'util/backendapi/types/Enum';
import { Media_POST } from 'util/backendapi/types/Model';
import { patchApiFormData, getApi } from 'util/backendapi/fetch';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import { FormikHelpers, FormikErrors, Formik, Form, Field } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { SiteMultiMenu } from 'components/modules/async-menu/SiteMenu';
import { ObsPointMultiMenu } from 'components/modules/async-menu/ObsPointMenu';
import { AreaMultiMenu } from 'components/modules/async-menu/AreaMenu';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { useGetApi } from 'hooks/use-get-api';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import './EditMediaModal.scss';
import {
  MultiFileFormValue,
  MultiFileField,
} from 'components/base/form/multi-file-field/MultiFileField';
import { isTruthy } from 'util/validation';
import {
  MediaUploadProgressModal,
  MediaToUpload,
} from './MediaUploadProgressModal';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

type MultiItemFormValues = Merge<
  Media_POST,
  {
    id: null;
    attached_files: MultiFileFormValue;
    content_type: Media_CONTENT_TYPE | null;
    non_unique_file_names?: string[];
  }
>;

type SingleItemFormValues = Media_POST & { id: number };

type FormValues = MultiItemFormValues | SingleItemFormValues;

interface Props {
  mediaId?: number;
  onAfterSubmit: () => void;
}

export function EditMediaModal({ mediaId, onAfterSubmit }: Props) {
  const isMounted = useIsMounted();

  const [toUpload, setToUpload] = useState<MediaToUpload[]>();

  const validate = useCallback(
    (values: FormValues): FormikErrors<FormValues> => {
      const errors: FormikErrors<FormValues> = {};
      if (values.id === null && values.attached_files.length === 0) {
        errors.file_name = (<Trans>A file is required.</Trans>) as any;
      }
      if (!values.content_type) {
        errors.content_type = (<Trans>Content type is required.</Trans>) as any;
      }

      if (
        values.areas.length === 0 &&
        values.sites.length === 0 &&
        values.observation_points.length === 0
      ) {
        errors.areas = (
          <Trans>
            At least one of area, site or observation point is required.
          </Trans>
        ) as any;
      }

      return errors;
    },
    []
  );

  const contentTypeOptions: SimpleSelectOption<Media_CONTENT_TYPE>[] =
    React.useMemo(
      () =>
        menuItemsFromEnum(
          'Media_CONTENT_TYPE',

          Object.values(Media_CONTENT_TYPE).filter(
            // "Report export" content is for PDFs generated by the system.
            (v) => v !== Media_CONTENT_TYPE.REPORT_EXPORT
          )
        ),
      []
    );

  const [mediaDetail] = useGetApi(mediaId ? `/media/${mediaId}/` : null);

  const checkFileNamesUnique = useCallback(
    async (formik: FormikHelpers<FormValues>, files: MultiFileFormValue) => {
      const fileNames = files.map((f) => f.file?.name).filter(isTruthy);
      if (fileNames.length === 0) {
        formik.setFieldValue('non_unique_file_names', undefined);
        return;
      }
      const matchingMedia = await getApi('/reports/media/', {
        file_name__in: fileNames,
        columns: ['file_name'],
      });
      if (matchingMedia.length > 0) {
        formik.setFieldValue(
          'non_unique_file_names',
          sortBy(uniq(matchingMedia.map((f) => f.file_name)))
        );
      } else {
        formik.setFieldValue('non_unique_file_names', undefined);
      }
    },
    []
  );

  const selectAllAreas = async (formik: FormikHelpers<any>) => {
    const areas = await getApi('/areas/');
    const areaIds = areas.map((area) => area.id);
    formik.setFieldValue('areas', areaIds);
  };

  const handleSubmit = useCallback(
    async (values: FormValues, formik: FormikHelpers<FormValues>) => {
      const formData = new FormData();

      if (values.id === null) {
        // Go to uploader
        setToUpload(
          values.attached_files.map(
            (f): MediaToUpload => ({
              areas: values.areas,
              content_type: values.content_type!,
              description: values.description,
              observation_points: values.observation_points,
              sites: values.sites,
              attached_file: f.file!,
            })
          )
        );
        return;
      }

      formData.append(
        'values',
        JSON.stringify({
          content_type: values.content_type,
          observation_points: values.observation_points,
          sites: values.sites,
          areas: values.areas,
          description: values.description,
        })
      );

      try {
        if (mediaId) {
          await patchApiFormData(`/media/${mediaId}/`, formData);
        }
        onAfterSubmit();
      } catch (err) {
        if (isMounted()) {
          formik.setSubmitting(false);
          showErrorsInFormik(
            formik,
            err,
            getExpectedFields(values),
            ErrorNotice
          );
        }
      }
    },
    [mediaId, onAfterSubmit, isMounted]
  );

  const initialValues: FormValues | null = useMemo((): FormValues | null => {
    if (mediaId) {
      if (!mediaDetail.data) {
        return null;
      }

      return {
        id: mediaId,
        file_name: mediaDetail.data.file_name,
        description: mediaDetail.data.description,
        content_type: mediaDetail.data.content_type,
        observation_points: mediaDetail.data.observation_points,
        sites: mediaDetail.data.sites,
        areas: mediaDetail.data.areas,
      };
    }

    return {
      id: null,
      attached_files: [],
      file_name: '',
      description: '',
      content_type: null,
      observation_points: [],
      sites: [],
      areas: [],
    };
  }, [mediaId, mediaDetail.data]);

  if (toUpload) {
    return (
      <MediaUploadProgressModal
        itemsToUpload={toUpload}
        onClose={onAfterSubmit}
      />
    );
  }

  return (
    <ModalContent
      header={mediaId ? <Trans>Edit media</Trans> : <Trans>Upload media</Trans>}
    >
      {mediaDetail.isLoading ? <Loading /> : null}
      {initialValues ? (
        <Formik<FormValues>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {(formik) => (
            <Form>
              <FormSection>
                {formik.values.id !== null ? (
                  <FormItem label={<Trans>File</Trans>}>
                    <span>{mediaDetail.data?.file_name}</span>
                  </FormItem>
                ) : (
                  <>
                    <FormItem
                      label={<Trans>Select files</Trans>}
                      fieldId="upload-media-file"
                    >
                      <MultiFileField name="attached_files" />
                      <FieldError name="file_name" />
                    </FormItem>
                    {formik.values.non_unique_file_names && (
                      <AlertWarning
                        className="alert-condensed alert-file-input"
                        additionalInformation={
                          <Trans>
                            You can continue to upload these files, but they may
                            already exist.
                          </Trans>
                        }
                      >
                        <Trans>
                          Media items with these names already exist in the DMS:
                          {formik.values.non_unique_file_names
                            .map((n) => `'${n}'`)
                            .join(', ')}
                        </Trans>
                      </AlertWarning>
                    )}
                    <FormChangeEffect<MultiItemFormValues>
                      onChange={({ values: prevValues }) => {
                        if (
                          formik.values.id === null &&
                          formik.values.attached_files !==
                            prevValues.attached_files
                        ) {
                          checkFileNamesUnique(
                            formik,
                            formik.values.attached_files
                          );
                        }
                      }}
                    />
                  </>
                )}
                <FormItem
                  label={<Trans>Description</Trans>}
                  fieldId="upload-media-description"
                >
                  <Field
                    component="textarea"
                    name="description"
                    id="upload-media-description"
                  />
                  <FieldError name="description" />
                </FormItem>
                <FormItem
                  label={<Trans>Content type</Trans>}
                  fieldId="upload-content-type"
                >
                  <SimpleSelectField
                    name="content_type"
                    id="upload-content-type"
                    options={contentTypeOptions}
                    placeholder={<Trans>Select a type of content</Trans>}
                  />
                  <FieldError name="content_type" />
                </FormItem>
                <FormItem
                  label={<Trans>Observation points</Trans>}
                  fieldId="upload-media-observation-points"
                >
                  <ObsPointMultiMenu name="observation_points" />
                  <FieldError name="observation_points" />
                </FormItem>
                <FormItem
                  label={<Trans>Sites</Trans>}
                  fieldId="upload-media-sites"
                >
                  <SiteMultiMenu name="sites" />
                  <FieldError name="sites" />
                </FormItem>
                <FormItem
                  label={<Trans>Areas</Trans>}
                  fieldId="upload-media-areas"
                  className="upload-media-areas"
                >
                  <Button
                    className="upload-media-areas-select-all"
                    onClick={() => selectAllAreas(formik)}
                  >
                    <Trans>Select all</Trans>
                  </Button>
                  <AreaMultiMenu name="areas" shortLabel={true} />
                  <FieldError name="areas" />
                </FormItem>
              </FormSection>
              {formik.status}
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="upload-media-submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType={mediaId ? 'icon-save' : 'icon-upload'}
                >
                  {mediaId ? <Trans>Save</Trans> : <Trans>Upload</Trans>}
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      ) : null}
    </ModalContent>
  );
}
