import React from 'react';
import * as ace from 'brace';
import 'brace/mode/text';
import 'brace/theme/monokai';
import './richeditor.css';

interface Props {
  fileContent: string;
  parserMessages: Array<{
    message: string;
    line_index: number;
    word_index: number;
    severity: string;
  }> | null;
  registerGetValue: (callback: () => string) => void;
}

export default class RichEditor extends React.Component<Props> {
  aceEditor: ace.Editor | null = null;
  textareaRef = React.createRef<HTMLTextAreaElement>();
  divRef = React.createRef<HTMLDivElement>();

  render() {
    return (
      // Using a textarea to make fallback easier
      // ... but I have to wrap it in a div, because Ace will destroy the original
      // textarea, and if it's the root DOM element of this React component, then
      // react gets confused when it tries to unmount this component.
      <div className="richeditor" ref={this.divRef}>
        <textarea
          autoComplete="off"
          spellCheck="false"
          wrap="off"
          defaultValue={this.props.fileContent}
          ref={this.textareaRef}
        />
      </div>
    );
  }

  componentDidMount() {
    // @ts-ignore
    this.aceEditor = ace.edit(this.textareaRef.current, {
      mode: 'ace/mode/text',
      theme: 'ace/theme/monokai',
      scrollPastEnd: false,
      firstLineNumber: 1,
      useWorker: false,
    });
    // @ts-ignore
    this.divRef.current.editor = this.aceEditor;
    this.aceEditor.setShowInvisibles(true);
    this.aceEditor.setBehavioursEnabled(false);
    this.aceEditor.setShowPrintMargin(false);
    this.aceEditor.setHighlightSelectedWord(false);
    this.aceEditor.setShowFoldWidgets(false);
    this.aceEditor.setWrapBehavioursEnabled(false);
    this.aceEditor.setOption('tooltipFollowsMouse', false);

    // HACK: Disabling Ace's "update annotations", to prevent it removing the
    // error markers in the gutter when you move the cursor to that line.
    // TODO: In the longer term, it may be better to handle this with an event
    // handler. See https://groups.google.com/forum/#!topic/ace-discuss/_SsC09KnzeQ
    this.aceEditor
      .getSession()
      // @ts-ignore
      .off('change', this.aceEditor.renderer.$gutterLayer.$updateAnnotations);

    // Silence an Ace warning message about the upcoming deprecation of auto-scrolling.
    // @see https://github.com/ajaxorg/ace/commit/a71c17b46f2088d7584b3e9d09d2c83787bc1954#diff-9939b765a7512c3749c8275012b3ac68R720
    //
    // (What does this actually do? $blockScrolling seems to be an internal Ace
    // variable, a counter that indicates how many commands are currently running
    // which should block auto-scrolling until they finish. By setting it to the
    // special JS value "Infinity", we ensure it can never reach 0, so
    // auto-scrolling will never happen. Then the client app (us) is safe to do
    // scrolling manually, and will be ready to upgrade to the auto-scrolling
    // feature's removal.)
    //
    // @note This message went away in mainline Ace several releases ago, but
    // the webpack-compatible "brace" project is a few releases behind. :(
    this.aceEditor.$blockScrolling = Infinity;

    this.props.registerGetValue(() => this.aceEditor?.getValue() ?? '');

    this.annotateParserMessages();
  }

  componentDidUpdate() {
    this.annotateParserMessages();
  }

  componentWillUnmount() {
    if (this.aceEditor) {
      this.aceEditor.destroy();
      this.aceEditor = null;
    }
  }

  /**
   * Marks the lines in the textarea that have parser messages (errors)
   *
   * @memberof RichTextarea
   */
  annotateParserMessages = () => {
    // No parser messages; nothing to do.
    if (!this.props.parserMessages || this.props.parserMessages.length === 0) {
      return;
    }

    if (this.aceEditor && this.props.parserMessages.length > 0) {
      // Put an error marker next to the bad lines
      this.aceEditor.getSession().setAnnotations(
        this.props.parserMessages.map((msg) => ({
          row: msg.line_index,
          text: msg.message,
          type: 'error', //rawmsg.severity,
          column: 1,
        }))
      );

      // Move the cursor to the first error
      const currentPosition = this.aceEditor.getCursorPosition();
      if (currentPosition.row === 0 && currentPosition.column === 0) {
        const firstMsg = this.props.parserMessages[0];

        // @ts-ignore
        this.aceEditor.scrollToLine(firstMsg.line_index + 1, true, false);
        this.aceEditor.gotoLine(firstMsg.line_index + 1, 0, false);

        if (firstMsg.word_index > 0) {
          for (let i = 0; i <= firstMsg.word_index; i++) {
            this.aceEditor.getSelection().moveCursorLongWordRight();
          }
        }
        this.aceEditor.getSelection().selectWord();
      }
    }
  };
}
