import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';
import Loading from 'components/base/loading/loading';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, t } from '@lingui/macro';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { Enum } from 'util/backendapi/models/api.interfaces';
import {
  AlertWarning,
  AlertInfo,
  AlertDanger,
} from 'components/base/alert/alert';
import { formatDatetimeForDisplay } from 'util/dates';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { BackButton } from 'components/base/back-button/BackButton';
import orderBy from 'lodash/orderBy';
import { useGetApi } from 'hooks/use-get-api';
import { deleteApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { SiteDecorated } from 'util/backendapi/types/Model';

interface Props
  extends RouteComponentProps<{
    siteCode: string;
    fieldName: Enum.Site_TIME_DEPENDENT_FIELD_NAME;
  }> {}

export function SiteTimeDependentFieldsScreen(props: Props) {
  const { siteCode, fieldName } = props.match.params;

  const [{ isLoading, error, data }, , , reloadSite] = useGetApi('/sites/', {
    code: siteCode,
  });

  const site = data ? (data.length === 0 ? null : data[0]) : undefined;

  return (
    <SiteTimeDependentFieldsScreenView
      siteCode={siteCode}
      fieldName={fieldName}
      site={site}
      isLoading={isLoading || !data || (data && site?.code !== siteCode)}
      errorMessage={error}
      reloadSite={reloadSite}
    />
  );
}

export function SiteTimeDependentFieldsScreenView(props: {
  siteCode: string;
  fieldName: Enum.Site_TIME_DEPENDENT_FIELD_NAME;
  isLoading: boolean;
  errorMessage: any;
  site: SiteDecorated | undefined | null;
  reloadSite: () => void;
}) {
  const { site, siteCode, fieldName, isLoading, errorMessage, reloadSite } =
    props;

  // Whitelist the field name param
  const isValidFieldName = Object.values(
    Enum.Site_TIME_DEPENDENT_FIELD_NAME
  ).includes(fieldName);

  const fieldEntries = isValidFieldName
    ? orderBy(
        site?.site_time_dependent_fields[fieldName] ?? [],
        [(e) => e.start_datetime],
        ['desc']
      )
    : [];

  // A tiny React component to curry `<TransEnum>`, since we have to call it
  // several times with the same props.
  const MyFieldName = useCallback(
    () =>
      isValidFieldName ? (
        <TransEnum enum="Site_TIME_DEPENDENT_FIELD_NAME" value={fieldName} />
      ) : (
        <>{fieldName}</>
      ),
    [fieldName, isValidFieldName]
  );

  return (
    <PageStandard
      name="site-time-dependent-fields"
      header={
        <Trans>
          <MyFieldName /> for {siteCode}
        </Trans>
      }
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton
          title={t`Back to site`}
          defaultBackUrl={`/sites/${siteCode}`}
        />
      </div>

      {!isValidFieldName ? (
        <AlertDanger>
          <Trans>"{fieldName}" is not a valid field name.</Trans>
        </AlertDanger>
      ) : errorMessage ? (
        <AlertWarning>{errorToString(errorMessage)}</AlertWarning>
      ) : site === null ? (
        <AlertWarning>Site {siteCode} not found.</AlertWarning>
      ) : isLoading || !site ? (
        <Loading />
      ) : fieldEntries.length === 0 ? (
        <AlertInfo>
          <Trans>
            Site {siteCode} has no existing <MyFieldName /> records.
          </Trans>
        </AlertInfo>
      ) : (
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>
                  <Trans>Field</Trans>
                </th>
                <th>
                  <Trans>Start Date</Trans>
                </th>
                <th>
                  <Trans>Value</Trans>
                </th>
                <th className="action-icons">
                  <Trans>Actions</Trans>
                </th>
              </tr>
            </thead>
            <tbody>
              {fieldEntries.map(
                ({ id: fieldEntryId, start_datetime, value }) => (
                  <tr key={fieldEntryId}>
                    <td>
                      <MyFieldName />
                    </td>
                    <td>
                      {formatDatetimeForDisplay(
                        start_datetime,
                        site.area.time_zone.name
                      )}
                    </td>
                    <td>{value}</td>
                    <td className="action-icons">
                      <HasPermission check="can_delete_site_time_dependent_fields">
                        <ButtonShowConfirmation
                          name="delete-site-time-dependent-field"
                          title={t`Delete`}
                          iconType="icon-circle-minus"
                          className="btn-link-panel"
                          content={
                            <Trans>
                              Are you sure you want to delete this{' '}
                              <strong>
                                <MyFieldName />
                              </strong>{' '}
                              record? This action is not reversible.
                            </Trans>
                          }
                          okBtnText={<Trans>Yes, delete</Trans>}
                          onConfirm={async () => {
                            await deleteApi(
                              `/site-time-dependent-fields/${fieldEntryId}/`
                            );
                            reloadSite();
                          }}
                        />
                      </HasPermission>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      )}
    </PageStandard>
  );
}
