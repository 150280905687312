import React from 'react';
import classNames from 'classnames';
import {
  RadioFieldProps,
  RadioField,
  RadioFieldOption,
} from '../radio-field/RadioField';
import { FormSectionProps, FormSection } from '../FormItem';
import { Trans } from '@lingui/macro';

export type ToggleFieldProps<T = any> = Pick<
  RadioFieldProps,
  'name' | 'disabled'
> & {
  options: RadioFieldOption<T>[];
  // Applies styling for an "inline" toggle, which puts the toggle's label
  // closer to the toggle controls. Useful for displaying a list of related
  // toggles.
  inline?: boolean;
} & Omit<FormSectionProps, 'children'>;

/**
 * A "toggle" control, that allows the user to choose between a few states.
 * (Implemented as a set of radio buttons, styled with special CSS.)
 *
 * Takes an `options` prop in the same format as `<SimpleSelect>`'s options,
 * and consumes/returns the `value` attribute of the selected object.
 *
 * @param props
 */
export function ToggleField(props: ToggleFieldProps) {
  const { inline, name, disabled, options, className, ...fieldSetProps } =
    props;

  return (
    <FormSection
      className={classNames(
        { 'radio-fieldset-toggle': true, 'radio-fieldset-inline': inline },
        className
      )}
      {...fieldSetProps}
    >
      <RadioField name={name} disabled={disabled} options={options} />
    </FormSection>
  );
}

// We'll also define some repeatedly used toggles.
type ToggleFieldSubtypeProps = Omit<ToggleFieldProps, 'options'>;

/**
 * Yes/No toggle
 * @returns boolean
 */
export const yesNoToggleOptions: RadioFieldOption<boolean>[] = [
  { label: <Trans>Yes</Trans>, value: true },
  { label: <Trans>No</Trans>, value: false },
];

export const YesNoToggleField = (props: ToggleFieldSubtypeProps) => (
  <ToggleField {...props} options={yesNoToggleOptions} />
);

export const yesNoStringToggleOptions: RadioFieldOption<string>[] = [
  { label: <Trans>Yes</Trans>, value: 'true' },
  { label: <Trans>No</Trans>, value: 'false' },
];

export const YesNoStringToggleField = (props: ToggleFieldSubtypeProps) => (
  <ToggleField {...props} options={yesNoStringToggleOptions} />
);

/**
 * Y/N toggle
 * @returns boolean
 */
export const yNToggleOptions: RadioFieldOption<boolean>[] = [
  {
    label: (
      <Trans>
        Y<span className="visuallyhidden">es</span>
      </Trans>
    ),
    value: true,
  },
  {
    label: (
      <Trans>
        N<span className="visuallyhidden">o</span>
      </Trans>
    ),
    value: false,
  },
];

export const YNToggleField = (props: ToggleFieldSubtypeProps) => (
  <ToggleField {...props} options={yNToggleOptions} />
);

/**
 * L/R toggle
 * @returns 'left' | 'right'
 */
const leftRightToggleOptions: RadioFieldOption<'left' | 'right'>[] = [
  {
    label: <Trans>L</Trans>,
    value: 'left',
  },
  {
    label: <Trans>R</Trans>,
    value: 'right',
  },
];
export const LeftRightToggleField = (props: ToggleFieldSubtypeProps) => (
  <ToggleField {...props} options={leftRightToggleOptions} />
);

/**
 * Show/Hide toggle
 * @returns boolean
 */
export const showHideToggleOptions: RadioFieldOption<boolean>[] = [
  { label: <Trans>Show</Trans>, value: true },
  { label: <Trans>Hide</Trans>, value: false },
];
export const ShowHideToggleField = (props: ToggleFieldSubtypeProps) => (
  <ToggleField {...props} options={showHideToggleOptions} />
);
