import React from 'react';
import { Trans } from '@lingui/macro';
import ObsPointGeneralSection from './sections/general';
import ObsPointInstallationSection from './sections/installation';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import { AlertDanger, AlertWarning } from 'components/base/alert/alert';
import { ObsPointCurrentFormulaSection } from './sections/currentformula/currentformula';
import { ObsPointFormSection } from 'ducks/obsPoint/detail/main';
import { ObsPointViewProps, SectionProps } from './maintobspoint.types';
import { DMSLink } from 'components/base/link/DMSLink';
import { fetchAreaCodeActiveCivilFeatures } from 'ducks/area/detail';

class SetupObservationPoint extends React.Component<ObsPointViewProps> {
  getPropsForSection = (sectionName: ObsPointFormSection): SectionProps => ({
    isDisabled: (this.props.editingSection &&
      this.props.editingSection !== sectionName) as boolean,
    isEditing: this.props.editingSection === sectionName,
    isLoading: this.props.isLoading,
    isSubmitting:
      this.props.editingSection === sectionName &&
      this.props.editingSectionIsSubmitting,
    startEditing: () => this.props.startEditing(sectionName),
    stopEditing: () => this.props.stopEditing(sectionName),
    onSubmit: (values) => this.props.onSubmit(sectionName, values),
    observationPoint: this.props.observationPoint,
    hasEditPermission: this.props.hasCreateObservationPointPermission,
  });

  renderBackLink() {
    return (
      <DMSLink to={`/observation-point`} className="back-link">
        <Trans>Back to Maintain Observation Points</Trans>
      </DMSLink>
    );
  }
  renderIncompleteMessage() {
    return (
      <AlertWarning
        additionalInformation={
          <p>
            <Trans>It can still be saved and you can complete it later.</Trans>
          </p>
        }
      >
        <Trans>This observation point record is not yet complete.</Trans>
      </AlertWarning>
    );
  }

  render() {
    const { observationPointCode, isLoading, errorMessage, menuOptions } =
      this.props;

    return (
      <PageStandard
        name="create-observation-point"
        header={<Trans>Create an Observation Point</Trans>}
        subHeader={observationPointCode}
        key={`maintobspoint-${observationPointCode}`}
      >
        {isLoading && <Loading />}
        {errorMessage && <AlertDanger>{errorMessage}</AlertDanger>}

        {this.renderBackLink()}
        {this.renderIncompleteMessage()}

        <ObsPointGeneralSection
          {...this.getPropsForSection('general')}
          observationPointCode={observationPointCode}
          menuOptions={menuOptions}
          onFetchAreaCodeCivilFeatures={fetchAreaCodeActiveCivilFeatures}
        />
        <ObsPointInstallationSection
          {...this.getPropsForSection('installation')}
          menuOptions={this.props.menuOptions}
        />

        <ObsPointCurrentFormulaSection
          {...this.getPropsForSection('formula')}
          formulaOptions={this.props.menuOptions.formula}
        />

        {this.renderIncompleteMessage()}
        {this.renderBackLink()}
      </PageStandard>
    );
  }
}

export default SetupObservationPoint;
