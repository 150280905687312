import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportFilter,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, Plural, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { ReportStateProps } from 'hooks/use-report-state';
import ActionBlock from 'components/base/actionblock/actionblock';
import { EditPerformanceIndicatorModal } from './EditPerformanceIndicatorModal';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';

export const PerformanceIndicatorListFilters: ReportFilter[] = [];

export const performanceIndicatorListColumns: (props: {
  refreshList: () => void;
}) => ReportColumn<Model.ReportsPerformanceIndicator>[] = ({ refreshList }) => [
  {
    label: <Trans>Name</Trans>,
    name: 'name',
    visibility: DEFAULT_SHOW,
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id'],
    accessor: ({ id }) => (
      <>
        <ButtonShowModal
          className="btn-link-panel"
          name="edit-performance-indicator"
          modalContent={({ hideModal }) => (
            <EditPerformanceIndicatorModal
              performanceIndicatorId={id}
              onAfterSubmit={() => {
                hideModal();
                refreshList();
              }}
            />
          )}
          iconType="icon-edit"
          iconOnly={true}
          title={t`Edit`}
        >
          <Trans>Edit</Trans>
        </ButtonShowModal>
      </>
    ),
  },
];

export interface PerformanceIndicatorListViewProps {
  reportProps: ReportStateProps<Model.ReportsPerformanceIndicator>;
}

export const PerformanceIndicatorListView: React.FunctionComponent<PerformanceIndicatorListViewProps> =
  function (props) {
    const { reportProps } = props;
    const columns = performanceIndicatorListColumns(reportProps);
    const filters = PerformanceIndicatorListFilters;
    return (
      <PageStandard
        name="performanceIndicator-list"
        header={
          reportProps.isExportMode ? (
            <Trans>Performance indicator report</Trans>
          ) : (
            <Trans>Maintain Performance Indicators</Trans>
          )
        }
      >
        <div className="page-content-header-filters-actions">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="create-performance-indicator"
              shortcut="CREATE_NEW"
              iconType="icon-plus"
              modalContent={({ hideModal }) => (
                <EditPerformanceIndicatorModal
                  onAfterSubmit={() => {
                    hideModal();
                    reportProps.refreshList();
                  }}
                />
              )}
            >
              <Trans>Create performance indicator</Trans>
            </ButtonShowModal>
          </ActionBlock>

          <ReportFiltersBlock
            filtersFrontend={filters}
            filtersBackend={
              reportProps.reportInfo && reportProps.reportInfo.filters
            }
            isExportMode={reportProps.isExportMode}
          />
        </div>
        <div className="filtered-table-wrapper">
          <ReportTable
            columns={columns}
            errorMessage={reportProps.errorMessage}
            filters={filters}
            isLoading={reportProps.isLoading}
            msgFilterCount={(count) => (
              <Plural
                value={count}
                one="Filtered to 1 performance indicator"
                other="Filtered to # performance indicators"
              />
            )}
            msgNoMatches={
              <Trans>
                No performance indicators match the selected filters.
              </Trans>
            }
            pagination={reportProps.pagination}
            records={reportProps.records}
            reportInfo={reportProps.reportInfo}
          />
        </div>
      </PageStandard>
    );
  };
