import React, { useCallback } from 'react';
import {
  UserEditView,
  UserEditViewProps,
  UserEditFormValues,
} from './UserEditView';
import { useHistory, useLocation, RouteComponentProps } from 'react-router';
import { useGetApi } from 'hooks/use-get-api';
import { postApi, patchApi } from 'util/backendapi/fetch';
import {
  User_POST,
  User_PATCH,
  UserAreaGroupOfUser,
} from 'util/backendapi/types/Model';
import { useIsMounted } from 'util/hooks';
import { parseNumberParamFromRouterProps } from 'util/routing';
import { errorToString } from 'util/backendapi/error';

interface UserEditScreenProps
  extends RouteComponentProps<{ userId?: string }> {}

export const UserEditScreen = (props: UserEditScreenProps) => {
  const location = useLocation<{ name?: string }>();
  const history = useHistory();
  const isMounted = useIsMounted();

  const userId = parseNumberParamFromRouterProps(props, 'userId', 0);

  const initialEditingState = Boolean(
    new URLSearchParams(location.search).get('edit')
  );
  const backUrl = new URLSearchParams(location.search).get('backUrl');

  const [userDetail, , , reloadUserData] = useGetApi(
    userId ? `/users/${userId}/` : null
  );

  const handleSubmit: UserEditViewProps['onSubmit'] = useCallback(
    async (values: UserEditFormValues) => {
      const user_area_groups: UserAreaGroupOfUser[] =
        values.user_area_groups.map((group) => ({
          area_group: group.area_group!,
          roles: [...group.roles],
        }));

      // If default user area group isn't in the list set it to null
      const default_user_area_group = values.user_area_groups.find(
        (uag) => uag.id === values.default_user_area_group
      )
        ? values.default_user_area_group || null
        : null;

      if (values.id) {
        const user: User_PATCH = {
          email: values.email,
          profile: {
            name: values.name,
            preferred_name: values.preferred_name,
            mobile: values.mobile,
            default_user_area_group: default_user_area_group,
          },
          user_area_groups: user_area_groups,
          system_roles: values.system_roles,
          is_active: values.is_active,
          user_admin_settings: {
            batches_require_confirmation:
              values.user_admin_settings.batches_require_confirmation,
          },
        };

        await patchApi(`/users/${values.id}/`, user);
        if (isMounted()) {
          reloadUserData();
        }
      } else {
        const newUser: User_POST = {
          username: values.username,
          email: values.email,
          profile: {
            name: values.name,
            preferred_name: values.preferred_name,
            mobile: values.mobile,
            default_user_area_group: default_user_area_group,
          },
          user_area_groups: user_area_groups,
          system_roles: values.system_roles,
          is_active: values.is_active,
          user_admin_settings: {
            batches_require_confirmation:
              values.user_admin_settings.batches_require_confirmation,
          },
        };

        await postApi('/users/', newUser);

        if (isMounted()) {
          history.push('/users/');
        }
      }
    },
    [history, isMounted, reloadUserData]
  );

  const handleCancel: UserEditViewProps['onCancel'] = useCallback(() => {
    if (backUrl) {
      history.push(backUrl);
    } else {
      history.push(`/users/`);
    }
  }, [history, backUrl]);

  const [areaGroupsDetail] = useGetApi('/area-groups/');
  const [areaGroupRolesDetail] = useGetApi('/roles/');

  // NOTE: Currently any role can be used as a system role. We may revisit this later
  const [systemRolesDetail] = useGetApi('/roles/', { all: true });

  const isLoading =
    areaGroupsDetail.isLoading ||
    areaGroupRolesDetail.isLoading ||
    systemRolesDetail.isLoading ||
    (userId && userDetail.isLoading) ||
    false;
  const errorMessage: string | null =
    errorToString(areaGroupsDetail.error) ||
    errorToString(areaGroupRolesDetail.error) ||
    errorToString(systemRolesDetail.error) ||
    (userId && errorToString(userDetail.error)) ||
    null;

  const user = userDetail.data;

  const areaGroups = areaGroupsDetail.data ?? [];
  const areaGroupRoles = areaGroupRolesDetail.data ?? [];

  const systemRoles = systemRolesDetail.data ?? [];

  const isNewUser = userId ? false : true;

  return (
    <UserEditView
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      user={user}
      isLoading={isLoading}
      isNewUser={isNewUser}
      initialEditingState={initialEditingState || isNewUser}
      errorMessage={errorMessage}
      areaGroups={areaGroups}
      areaGroupRoles={areaGroupRoles}
      systemRoles={systemRoles}
    />
  );
};
