import React from 'react';
import { connect } from 'react-redux';
import { matchPath, RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Icon, IconType } from 'components/base/icon/icon';
import { expandNavCategory, collapseNavCategory } from 'ducks/ui';
import NavItem from './navitem';
import { FullState } from 'main/reducers';
import Button from 'components/base/button/button';
import { t } from '@lingui/macro';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { selectHasPermission } from 'util/user';

interface OwnProps {
  name: string;
  header: React.ReactNode;
  iconType: IconType;
  items: Array<{
    name: string;
    children: React.ReactNode;
    route: {
      path: string;
      permission?: null | Enum.User_PERMISSION;
    };
    linkUrl?: string;
    matchUrl?: string | string[];
    exact?: boolean;
    strict?: boolean;
  }>;
}

interface StateProps {
  isCollapsed: boolean;
  isNavMenuCollapsed: boolean;
  permissionCheckedItems: OwnProps['items'];
}

interface DispatchProps {
  collapseNavCategory: (name: string) => void;
  expandNavCategory: (name: string) => void;
}

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;
/**
 * A collapsible navigation menu category. It can be manually expanded
 * or collapsed, and it automatically opens if the user navigates to any
 * of its listed items. When this happens, it is considered "active",
 * and cannot be manually collapsed until the user navigates away none of its
 * items match the current page.
 *
 * When the parent menu is collapsed, category menu can be open as popup menu,
 * as a result, the behavior will changed for "active" category. Clicking the
 * "active" category is allowed in order to open/close the child menu.
 *
 * It expects to receive an array of objects representing navigation items, as
 * the "items" prop. (It renders these into <NavItem> components). It takes no
 * "children" prop.
 *
 * NOTE: This component expects no more than ONE navitem to be "active" at
 * any given time. This seems like a reasonable assumption, because it would
 * be strange for there to be two navigation links representing the same URL.
 * But if for some reason we need to have multiple active navigation links,
 * we'll need to revisit this.
 *
 * Also, this component was written to handle a single level of nested navitems.
 * If we need to do a multi-level tree, this will need to be revisited.
 *
 * @class _NavCategory
 * @extends {React.Component}
 */
class _NavCategory extends React.Component<Props, any> {
  static mapStateToProps = (
    state: FullState,
    ownProps: OwnProps & RouteComponentProps
  ): StateProps => {
    const collapsedState: boolean = (state.ui.subMenu || ({} as any))[
      `is-${ownProps.name}-collapsed`
    ];
    const isNavMenuCollapsed = state.ui.isNavMenuCollapsed;
    return {
      // Nav categories are collapsed by default. So if there is no redux
      // state for the category yet, then treat it as collapsed.
      isCollapsed: collapsedState === undefined ? true : collapsedState,
      isNavMenuCollapsed,
      permissionCheckedItems: ownProps.items.filter((item) =>
        item.route.permission
          ? selectHasPermission(state, item.route.permission)
          : true
      ),
    };
  };

  static mapDispatchToProps: DispatchProps = {
    collapseNavCategory,
    expandNavCategory,
  };

  render() {
    // if there is no items left after permissions check
    // we simply hide the whole menu
    if (!this.props.permissionCheckedItems.length) {
      return null;
    }

    // Find if any of our nav items represents the current page. And if so,
    // remember its index.
    let activeItemIndex = this.props.permissionCheckedItems.findIndex(
      (item) => {
        const { matchUrl, linkUrl, route, exact, strict } = item;
        // If matchUrl is not provided, match against linkUrl
        const path = matchUrl || route.path || linkUrl;

        return Boolean(
          matchPath(this.props.location.pathname, { path, exact, strict })
        );
      }
    );
    const isActive = activeItemIndex !== -1;

    let onClick;

    if (isActive && !this.props.isNavMenuCollapsed) {
      onClick = {};
    } else if (this.props.isCollapsed) {
      onClick = { onClick: this.expand };
    } else {
      onClick = { onClick: this.collapse };
    }

    return (
      <li
        id={`nav-category-${this.props.name}`}
        className={classNames({
          'nav-category': true,
          'nav-category-active': isActive,
          'nav-category-collapsed': this.props.isCollapsed,
        })}
      >
        <span className="nav-category-header" {...onClick}>
          {this.props.iconType ? (
            <Icon type={this.props.iconType} className="nav-category-icon" />
          ) : null}
          <span className="nav-text">{this.props.header}</span>
          <Icon
            className="nav-category-control-icon"
            type={
              this.props.isCollapsed && !isActive
                ? 'icon-arrow-down'
                : 'icon-arrow-up'
            }
          />
        </span>
        <div className="nav-category-items-wrapper">
          <ul
            className={classNames({
              'nav-category-items': true,
            })}
          >
            {
              // do not display subheader when the main menu is not collapse, so screen reader does not read the header twice
            }
            {this.props.isNavMenuCollapsed ? (
              <li className="nav-category-header-secondary">
                <span>{this.props.header}</span>
                <Button
                  title={t`Close`}
                  iconOnly
                  iconType="icon-cross"
                  className="close"
                  onClick={this.collapse}
                />
              </li>
            ) : null}
            {this.props.permissionCheckedItems.map((item, index) => {
              // We don't need to pass the URL-matching props through to the NavItem.
              const { matchUrl, exact, strict, ...otherNavItemProps } = item;
              return (
                <NavItem
                  key={item.name}
                  _isActivePage={index === activeItemIndex}
                  onClick={this.props.isNavMenuCollapsed ? this.collapse : null}
                  {...otherNavItemProps}
                />
              );
            })}
          </ul>
        </div>
      </li>
    );
  }

  collapse = () => {
    if (!this.props.isCollapsed) {
      this.props.collapseNavCategory(this.props.name);
    }
  };

  expand = () => {
    if (this.props.isCollapsed) {
      this.props.expandNavCategory(this.props.name);
    }
  };
}

const NavCategory = withRouter(
  connect<StateProps, DispatchProps, OwnProps & RouteComponentProps, FullState>(
    _NavCategory.mapStateToProps,
    _NavCategory.mapDispatchToProps
  )(_NavCategory)
);
(NavCategory as any).WrappedComponent = _NavCategory;
export default NavCategory;
