import React from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import { PlotSettingsReadingSeries } from './timeseriesplot.types';
import { Trans } from '@lingui/macro';

interface Props {
  minimal?: boolean;
  series: {
    observationPoint: Model.ObservationPointDecorated;
    settings: PlotSettingsReadingSeries;
  };
}
export const SeriesLegendText = ({ minimal, series }: Props) => {
  const { show_legend_cap_rl, show_legend_port_rl, item_number } =
    series.settings;
  const { cap_rl, port_rl, instrument_type } = series.observationPoint;
  return (
    <>
      {series.observationPoint.code}
      {minimal ? null : (
        <>
          {' '}
          {series.observationPoint.name}{' '}
          {
            instrument_type.items.find((i) => i.item_number === item_number)
              ?.description
          }
        </>
      )}
      {/* Separator between obs point name and cap/port rl info */}
      {show_legend_cap_rl || show_legend_port_rl ? '. ' : ''}
      {show_legend_cap_rl && (
        <>
          {' '}
          <Trans>Cap RL: {renderRL(cap_rl)}</Trans>
        </>
      )}
      {show_legend_port_rl && (
        <>
          {' '}
          <Trans>Port RL: {renderRL(port_rl)}</Trans>
        </>
      )}
    </>
  );
};
SeriesLegendText.defaultProps = {
  minimal: false,
};

function renderRL(value: string | number | null) {
  const noValue = !value && value !== 0;
  if (noValue) {
    return <Trans>(none)</Trans>;
  } else {
    return <Trans>{value}m</Trans>;
  }
}
