import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { formatDatetimeForDisplay } from 'util/dates';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { getUserDisplayName } from 'util/user';
import { sortBy } from 'lodash';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { useReportState, ReportStateProps } from 'hooks/use-report-state';
import { RouteComponentProps } from 'react-router';
import { retirementRequestDuck } from 'ducks/readings/retirement-request/list';
import { FullState } from 'main/reducers';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { CreateRetirementRequestModal } from './CreateRetirementRequestModal';
import { reportFilterAreas } from 'components/modules/report/filter/fields/reportFilterAreas';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';
import { reportFilterDatetime } from 'components/modules/report/filter/fields/FilterDate';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';

export const retirementRequestColumns: ReportColumn<Model.ReportsReadingRetirementRequest>[] =
  [
    {
      label: <Trans>Requested date</Trans>,
      name: 'date-requested',
      backendFieldName: 'created_datetime',
      visibility: DEFAULT_SHOW,
      additionalFields: ['observation_points__site__area__time_zone__name'],
      hideFieldsFromCSV: ['observation_points__site__area__time_zone__name'],
      accessor: ({
        created_datetime,
        observation_points__site__area__time_zone__name: [timeZone],
      }) => formatDatetimeForDisplay(created_datetime, timeZone),
    },
    {
      label: <Trans>Status</Trans>,
      name: 'status',
      backendFieldName: 'status',
      visibility: DEFAULT_SHOW,
      accessor: ({ status }) => (
        <TransEnum enum="ReadingRetirementRequest_STATUS" value={status} />
      ),
    },
    {
      label: <Trans>From date</Trans>,
      name: 'from-date',
      backendFieldName: 'from_datetime',
      visibility: DEFAULT_SHOW,
      additionalFields: ['observation_points__site__area__time_zone__name'],
      hideFieldsFromCSV: ['observation_points__site__area__time_zone__name'],
      accessor: ({
        from_datetime,
        observation_points__site__area__time_zone__name: [timeZone],
      }) => formatDatetimeForDisplay(from_datetime, timeZone),
    },
    {
      label: <Trans>To date</Trans>,
      name: 'to-date',
      backendFieldName: 'to_datetime',
      visibility: DEFAULT_SHOW,
      additionalFields: ['observation_points__site__area__time_zone__name'],
      hideFieldsFromCSV: ['observation_points__site__area__time_zone__name'],
      accessor: ({
        to_datetime,
        observation_points__site__area__time_zone__name: [timeZone],
      }) => formatDatetimeForDisplay(to_datetime, timeZone),
    },
    {
      label: <Trans>User</Trans>,
      name: 'user',
      backendFieldName: 'user',
      visibility: DEFAULT_SHOW,
      accessor: ({ user }) => getUserDisplayName(user),
    },
    {
      label: <Trans>Observation points</Trans>,
      name: 'observation-points',
      backendFieldName: 'observation_points__code',
      visibility: DEFAULT_SHOW,
      accessor: ({ observation_points__code: obsPointCodes }) =>
        sortBy(obsPointCodes).join(', '),
    },
    {
      label: <Trans>Reason</Trans>,
      name: 'reason',
      backendFieldName: 'reason',
      visibility: DEFAULT_SHOW,
      tdClassName: 'text-with-linebreaks',
    },
    {
      label: <Trans>Greater than</Trans>,
      name: 'greater_than_value',
      backendFieldName: 'greater_than_value',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Less than</Trans>,
      name: 'less_than_value',
      backendFieldName: 'less_than_value',
      visibility: DEFAULT_SHOW,
    },
  ];

export const retirementRequestFilters: ReportFilter[] = [
  reportFilterMenu(
    'status',
    <Trans>Status</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('ReadingRetirementRequest_STATUS'),
    { placeholder: <Trans>All statuses</Trans>, autoFocus: true }
  ),
  reportFilterObservationPoints('observation_points__id'),
  reportFilterAreas('observation_points__site__area'),
  reportFilterUsers('user', <Trans>User</Trans>),
  reportFilterDatetime('created_datetime', <Trans>Requested date</Trans>),
  reportFilterDatetime('from_datetime', <Trans>From date</Trans>),
  reportFilterDatetime('to_datetime', <Trans>To date</Trans>),
];

export const RetirementRequestListScreen = (props: RouteComponentProps) => {
  const reportState = useReportState(
    props,
    retirementRequestColumns,
    retirementRequestFilters,
    retirementRequestDuck,
    (state: FullState) => state.retirementRequest
  );
  return <RetirementRequestListView reportProps={reportState} />;
};

export function RetirementRequestListView(props: {
  reportProps: ReportStateProps<Model.ReportsReadingRetirementRequest>;
}) {
  const { reportProps } = props;
  return (
    <PageStandard
      name="retirement-requests-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Retire adjusted readings report</Trans>
        ) : (
          <Trans>Retire Adjusted Readings</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_retire_readings">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="retire-readings-button"
              data-testid="retire-readings-button"
              shortcut="CREATE_NEW"
              modalContent={(modalProps) => (
                <CreateRetirementRequestModal
                  {...modalProps}
                  onAfterSubmit={reportProps.refreshList}
                />
              )}
            >
              <Trans>Create retire job</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>
        <ReportFiltersBlock
          filtersFrontend={retirementRequestFilters}
          filtersBackend={reportProps.reportInfo?.filters}
          isExportMode={reportProps.isExportMode}
          filtersToShow={[
            'status',
            'observation_points__id',
            'observation_points__site__area',
            'user',
          ]}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={retirementRequestColumns}
            filtersFrontend={retirementRequestFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Retire adjusted readings report`}
            reportUrl="/reports/reading-retirement-requests/"
            columns={retirementRequestColumns}
            filters={retirementRequestFilters}
          />
          <AdvancedFiltersModalButton
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={retirementRequestFilters}
            sections={[
              {
                name: 'dates_section',
                filters: ['created_datetime', 'from_datetime', 'to_datetime'],
              },
            ]}
          />
        </ActionBlock>
        <ReportTable<Model.ReportsReadingRetirementRequest>
          columns={retirementRequestColumns}
          filters={retirementRequestFilters}
          {...reportProps}
        />
      </div>
    </PageStandard>
  );
}
