import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import deepEqual from 'lodash/isEqual';
import AsyncSelect, {
  AsyncProps as AsyncSelectProps,
} from 'react-select/async';
import { t } from '@lingui/macro';
import { I18n } from '@lingui/react';
import classNames from 'classnames';
import SimpleSelect, { SimpleSelectOption } from '../simpleselect/simpleselect';
import {
  GroupBase,
  OnChangeValue as SelectedOptionsType,
  Options as OptionsType,
} from 'react-select';

const DEBOUNCE_DELAY_MS = process.env.NODE_ENV === 'test' ? 0 : 450;

type SimpleValueType<E, IsMulti extends boolean> =
  | (IsMulti extends true ? E[] : E)
  | null
  | undefined;

function isArray<T>(
  selection: SelectedOptionsType<T, any>
): selection is OptionsType<T> {
  return Array.isArray(selection);
}

function coerceToArray<T>(
  value: T | T[] | readonly T[] | null | undefined
): T[] {
  if (!value) {
    return [];
  } else if (Array.isArray(value)) {
    return value;
  } else {
    return [value as T];
  }
}

export type AsyncSimpleSelectProps<
  E,
  IsMulti extends boolean,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
> = Merge<
  Omit<AsyncSelectProps<OPT, IsMulti, GroupBase<OPT>>, 'loadOptions'>,
  {
    isMulti?: IsMulti;
    placeholder?: React.ReactNode;
    name: string;
    value?: SimpleValueType<E, IsMulti>;
    /**
     * A callback function that will be invoked when the user types a new search
     * term into the menu. It should return menu options that match the search term.
     *
     * Usually it should use a backend filter that does a case-insensitive partial
     * match of the displayed field, e.g. `name__icontains`
     */
    onSearch: OnSearchFunc<E, OPT>;
    /**
     * A callback function to fetch the values & labels of the menu's initial
     * selection, if the user visits the page with a filter already in the URL.
     * This is needed because the initial values are only *values*, but to display
     * them in the menu we need *labels* as well.
     *
     * It should use a backend filter that does an *exact* match of the value
     * field, e.g. `id__contains`
     */
    loadDefaults: LoadDefaultsFunc<E, IsMulti, OPT>;
    /**
     * A callback function that will be invoked when the user changes the selection
     * in the menu.
     *
     * It will receive two parameters:
     *
     * @param value The selected value(s)
     * @param details The full selected object(s)
     */
    onChange: OnChangeFunc<E, IsMulti, OPT>;
  }
>;

interface State<IsMulti extends boolean, OPT> {
  isLoadingInitialOptions: boolean;
  selectedOptions: SelectedOptionsType<OPT, IsMulti> | null;
}

export type OnSearchFunc<
  E = string,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
> = (inputValue: string) => Promise<OPT[]>;

export type LoadDefaultsFunc<
  E = string,
  IsMulti extends boolean = false,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
> = (initialValue: IsMulti extends true ? E[] : E) => Promise<OPT[]>;

export type OnChangeFunc<
  E = string,
  IsMulti extends boolean = false,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
> = (selection: SimpleValueType<E, IsMulti>, details: OPT[]) => void;

/**
 * Search-as-you-type select component
 *
 * It acepts 3 *type variables*:
 * - E - Each option type (default is string)
 * - R - Returned value or values
 * - OPT - Option type
 *
 * @TODO there are some duplications with <SimpleSelect> component. Perhaps there can be some improvements needed to be done.
 */
export default class AsyncSimpleSelect<
  E = string,
  IsMulti extends boolean = false,
  OPT extends SimpleSelectOption<E> = SimpleSelectOption<E>
> extends Component<
  AsyncSimpleSelectProps<E, IsMulti, OPT>,
  State<IsMulti, OPT>
> {
  private debouncedSearch: any;
  private _isMounted: boolean;

  state: State<IsMulti, OPT> = {
    isLoadingInitialOptions: false,
    selectedOptions: null,
  };

  constructor(props: AsyncSimpleSelectProps<E, IsMulti, OPT>) {
    super(props);

    this.debouncedSearch = debounce(
      (searchTerm: string, callback: (options: OPT[]) => void) => {
        this.props
          .onSearch(searchTerm)
          .then((options) => callback(options))
          .catch(() => callback([]));
        // Lodash's debounce() only works with React-Select's `loadOptions`
        // prop if we use it by calling `callback()` ourselves, and *don't*
        // return a Promise. (Or use a package like debounce-promise)
        // See: https://github.com/JedWatson/react-select/issues/3075
        return null;
      },
      DEBOUNCE_DELAY_MS
    );

    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    // If there are initial selections (because the user visited the page
    // with a URL that already has filters), then use the loadDefaults() prop
    // to get the menu options for those values.
    if (
      this.props.value &&
      (!Array.isArray(this.props.value) || this.props.value.length > 0)
    ) {
      this.setState({ isLoadingInitialOptions: true });
      const response = await this.props
        .loadDefaults(this.props.value)
        .catch(() => [] as OPT[]);
      let defaultSelection: SelectedOptionsType<OPT, IsMulti>;
      if (this.props.isMulti) {
        defaultSelection = response as SelectedOptionsType<OPT, true> as any;
      } else {
        defaultSelection = (response?.[0] ?? null) as SelectedOptionsType<
          OPT,
          false
        > as any;
      }
      if (this._isMounted) {
        this.setState({
          selectedOptions: defaultSelection,
          isLoadingInitialOptions: false,
        });
        // Call the "handleChange", in case the parent component wants to
        // receive the full details of the selected objects.
        this.handleChange(defaultSelection);
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps: AsyncSimpleSelectProps<E, IsMulti, OPT>) {
    // Update selection when the "value" props changes.
    if (!deepEqual(this.props.value, prevProps.value)) {
      // React-Select takes full {value, label} objects to represent the
      // selection, and uses === to check whether the selection has changed.
      // So, we need to map the new values prop to the underlying selected
      // objects.
      const values = coerceToArray(this.props.value);
      const selectedOptions = coerceToArray(this.state.selectedOptions);
      let newSelections: typeof selectedOptions = [];

      let hasMissingSelection = false;
      values.forEach((v) => {
        const selection = selectedOptions.find((so) => so.value === v);
        if (!selection) {
          hasMissingSelection = true;
        } else {
          newSelections.push(selection);
        }
      });

      // Some of the selected values from the values prop are not present in
      // the current list of selections. So, we need to reload the selected
      // options (following the same procedure as after initial component mount)
      if (hasMissingSelection) {
        this.componentDidMount();
      } else if (!deepEqual(selectedOptions, newSelections)) {
        this.setState({
          selectedOptions: (this.props.isMulti
            ? newSelections
            : newSelections[0]) as SelectedOptionsType<OPT, IsMulti>,
        });
      }
    }
  }

  handleChange = (selection: SelectedOptionsType<OPT, IsMulti>) => {
    this.setState({ selectedOptions: selection });
    const { onChange } = this.props;

    if (this.props.isMulti) {
      if (isArray(selection)) {
        onChange(
          selection.map((item) => item.value) as SimpleValueType<E, IsMulti>,
          selection.map((sel) => sel)
        );
      } else {
        onChange([] as E[] as SimpleValueType<E, IsMulti>, []);
      }
    } else {
      if (selection) {
        onChange((selection as OPT).value as SimpleValueType<E, IsMulti>, [
          selection as OPT,
        ]);
      } else {
        // We used to pass `undefined` when there was nothing selected,
        // but that causes Formik to remove the field from its values, which
        // in turn causes error messages not to be displayed. So we now pass
        // `null` instead.
        onChange(null as SimpleValueType<E, IsMulti>, []);
      }
    }
  };

  render() {
    const { isLoadingInitialOptions } = this.state;

    if (isLoadingInitialOptions) {
      return (
        <SimpleSelect
          name={this.props.name}
          options={[]}
          value={''}
          onChange={() => {}}
          placeholder={this.props.placeholder}
          isLoading
          isDisabled
        />
      );
    }

    const { className, onChange, value, placeholder, ...otherProps } =
      this.props;

    return (
      <I18n>
        {({ i18n }) => (
          <AsyncSelect
            cacheOptions
            placeholder={placeholder as string}
            loadOptions={this.debouncedSearch}
            value={this.state.selectedOptions}
            defaultOptions={false}
            noOptionsMessage={(obj) =>
              obj.inputValue
                ? i18n._(t`No options.`)
                : i18n._(t`Type in any character to search`)
            }
            onChange={this.handleChange}
            className={classNames('react-select', className)}
            classNamePrefix="react-select"
            {...otherProps}
          />
        )}
      </I18n>
    );
  }
}
