import React from 'react';
import classNames from 'classnames';

export interface FormSectionProps
  extends React.HTMLAttributes<HTMLFieldSetElement> {
  label?: React.ReactNode;
  children: React.ReactNode;
}

/**
 * A simple presentational component for the standard rendering of a form
 * or from section with an optional label.
 *
 * (Its main purpose is to try to make it easier to write a form using
 * our standard design pattern, without having to use <FormCard> or manually
 * writing all the <div> and <label> elements.)
 *
 * @param props
 */
export const FormSection: React.FunctionComponent<FormSectionProps> = function (
  props
) {
  const { label, children, ...otherProps } = props;
  return (
    <fieldset {...otherProps}>
      {props.label && <legend>{props.label}</legend>}
      {props.children}
    </fieldset>
  );
};

interface FormItemProps extends React.HTMLAttributes<HTMLDivElement> {
  fieldId?: string;
  label: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}

/**
 * A simple presentational component for the standard rendering of form items.
 * (Its main purpose is to try to make it easier to write a form using
 * our standard design pattern, without having to use <FormCard> or manually
 * writing all the <div> and <label> elements.)
 *
 * @example
 * <FormItem
 *   label={<Trans>Report name</Trans>}
 *   fieldId="save-report-name"
 * >
 *   <Field
 *     id="save-report-name"
 *     name="report_name"
 *     type="text"
 *     disabled={formik.isSubmitting}
 *   />
 *   <ErrorMessage name="report_name" component={ErrorNotice} />
 * </FormItem>
 */
export const FormItem: React.FunctionComponent<FormItemProps> = function (
  props
) {
  const { fieldId, className, label, children, ...otherProps } = props;
  return (
    <div
      id={`form-group-${props.fieldId}`}
      className={classNames('form-group', props.className)}
      {...otherProps}
    >
      <label htmlFor={props.fieldId}>{props.label}</label>
      {props.children}
    </div>
  );
};

interface FormMultiItemProps extends FormSectionProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

/**
 * A simple presentational component for rendering a form item that contains
 * multiple input elements (such as a group of checkboxes or radio buttons)
 */
export const FormMultiItem: React.FunctionComponent<FormMultiItemProps> =
  function (props) {
    // Currently implemented identically to the FormSection component. Except
    // the label is mandatory.
    return <FormSection {...props} />;
  };
