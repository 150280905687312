import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import {
  RCPWithQueryParams,
  setQueryParams,
  setOneQueryParam,
  parseStringArrayFromQueryParam,
  parseNumberArrayFromQueryParam,
} from '../../../util/routing';
import { Filter, Model } from '../../../util/backendapi/models/api.interfaces';
import {
  PaginationQueryParams,
  Pagination,
} from '../../../util/backendapi/pagination';
import { FullState } from '../../../main/reducers';
import {
  StoredListLandingView,
  StoredListLandingViewProps,
} from './storedlistslandingview';
import {
  selectAll,
  EntityTypes,
  fetchEntityList,
} from '../../../ducks/entities';
import {
  fetchStoredLists,
  loadDefaultsForStoredListMenu,
  searchOnTypeForStoredListMenu,
  createStoredList,
  updateStoredList,
} from '../../../ducks/storedList/list';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { enhanceWithBackUrl } from 'components/base/link/DMSLink';

type QueryParams = Pick<
  Filter.StoredLists,
  'id__in' | 'area__in' | 'type__in'
> &
  PaginationQueryParams;
export type OwnProps = RCPWithQueryParams<QueryParams>;

type StateProps = Pick<
  StoredListLandingViewProps,
  | 'allAreas'
  | 'pagination'
  | 'filteredItems'
  | 'loading'
  | 'errorMessage'
  | 'filtersInUrl'
> & {
  fetchedFilters: Filter.StoredLists;
};

const mapDispatchToProps = {
  fetchEntityList,
  fetchStoredLists,
  updateStoredList,
  createStoredList,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

const selectFiltersFromUrl = createSelector(
  (props: OwnProps) => parseNumberArrayFromQueryParam(props, 'area__in'),
  (props: OwnProps) => parseNumberArrayFromQueryParam(props, 'id__in'),
  (props: OwnProps) => parseStringArrayFromQueryParam(props, 'type__in'),
  (props: OwnProps) => Pagination.parseFromRouterProps(props),
  function (
    area__in: number[],
    id__in: number[],
    type__in: string[],
    { offset, limit }: { offset: number; limit: number }
  ) {
    return {
      area__in,
      id__in,
      type__in,
      limit,
      offset,
    };
  }
);

class StoredListLandingScreenInner extends React.Component<Props> {
  static mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
    return {
      allAreas: selectAll(state, EntityTypes.AREA),
      fetchedFilters: state.storedList.list.filters,
      errorMessage: state.storedList.list.errorMessage,
      filteredItems: state.storedList.list.storedLists,
      loading: state.storedList.list.loading,
      pagination: Pagination.fromRequestedReceived(
        Pagination.parseFromRouterProps(ownProps),
        state.storedList.list.pagination
      ),
      filtersInUrl: selectFiltersFromUrl(ownProps),
    };
  }

  onClearFilters = () =>
    setQueryParams(this.props, {
      area__in: undefined,
      id__in: undefined,
      type__in: undefined,
    });

  onFilterChanged = (
    filterName: 'area__in' | 'id__in' | 'type__in',
    newValues: string[] | number[] | undefined | null
  ) => setOneQueryParam(this.props, filterName, newValues);

  handleSubmitCreate = async (storedList: Model.PolymorphicStoredList_POST) => {
    const newStoredListId = await this.props.createStoredList(storedList);
    // Navigate to "Maintain stored list" screen
    if (this._isMounted) {
      this.props.history.push(
        enhanceWithBackUrl(
          `/stored-lists/${newStoredListId}/edit/`,
          this.props.location
        )
      );
    }
  };

  handleSubmitUpdate = async (
    id: number,
    updatedStoredList: Model.PolymorphicStoredList_PATCH
  ) => {
    await this.props.updateStoredList(id, updatedStoredList);
    // Refresh the table
    this.props.fetchStoredLists(this.props.filtersInUrl);
  };

  render() {
    return (
      <StoredListLandingView
        allAreas={this.props.allAreas}
        errorMessage={this.props.errorMessage}
        filteredItems={this.props.filteredItems}
        loadDefaultListNames={loadDefaultsForStoredListMenu}
        loading={this.props.loading}
        onClearFilters={this.onClearFilters}
        onFilterChanged={this.onFilterChanged}
        onSearchForListName={searchOnTypeForStoredListMenu}
        pagination={this.props.pagination}
        filtersInUrl={this.props.filtersInUrl}
        onSubmitCreate={this.handleSubmitCreate}
        onSubmitUpdate={this.handleSubmitUpdate}
      />
    );
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.props.fetchEntityList(EntityTypes.AREA);
    this.props.fetchStoredLists(this.props.filtersInUrl);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.filtersInUrl !== prevProps.filtersInUrl) {
      this.props.fetchStoredLists(this.props.filtersInUrl);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
}

export const StoredListLandingScreen = withRouter(
  connect<StateProps, DispatchProps, OwnProps, FullState>(
    StoredListLandingScreenInner.mapStateToProps,
    mapDispatchToProps
  )(StoredListLandingScreenInner)
);
