import React, { useRef, useEffect } from 'react';
import SimpleSelect, {
  SimpleSelectOption,
} from 'components/base/form/simpleselect/simpleselect';
import { Trans, t } from '@lingui/macro';
import { Model } from 'util/backendapi/models/api.interfaces';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Button, { ButtonPrimary } from 'components/base/button/button';
import ActionBlock from 'components/base/actionblock/actionblock';
import { PolymorphicStoredPlotWithArea } from 'ducks/stored-plot/detail';
import './PlotSet.scss';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { StoredPlot } from 'components/plots/StoredPlot';
import { BackButton } from 'components/base/back-button/BackButton';
import { Form, Formik } from 'formik';
import ModalContent from 'components/base/modal/modalcontent';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ExportState } from './PlotSet';
import { QueuedExportModal } from 'components/modules/exportpanel/QueuedExportModal';
import { DateField } from 'components/base/form/datefield/datefield';
import { ToggleField } from 'components/base/form/toggle-field/ToggleField';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';

type PlotSetViewProps = {
  plotSet: null | Model.PlotSet;
  storedPlot: null | PolymorphicStoredPlotWithArea;
  currentIndex: number;
  storedPlotOptions: SimpleSelectOption<string>[];
  isLoading: boolean;
  errorMessage: string;
  userString: string;
  onNext: () => void;
  onPrevious: () => void;
  onSelectStorePlot: (name: string | null) => void;
  onExportSubmit: (values: ExportFormValues) => any;
  exportState: ExportState;
  resetExportState: () => void;
};

export interface ExportFormValues {
  endDate: string;
}

const OwnExportPanel = (
  props: {
    onExportSubmit: PlotSetViewProps['onExportSubmit'];
    resetExportState: PlotSetViewProps['resetExportState'];
  } & ExportState
) => {
  useEffect(() => {
    return () => {
      if (props.errorMessage || props.exportTaskId) {
        props.resetExportState();
      }
    };
  });
  if (props.errorMessage) {
    return <ErrorNotice>{props.errorMessage}</ErrorNotice>;
  } else if (!props.errorMessage && !props.exportTaskId) {
    return (
      <ModalContent header={<Trans>Export plot</Trans>}>
        <>
          <p>
            <Trans>
              The plot set will be exported with all stored plots within it.
            </Trans>
          </p>
          <Formik
            initialValues={{ endDate: '', exportFormat: 'PDF' }}
            onSubmit={props.onExportSubmit}
          >
            {() => (
              <Form>
                <fieldset className="radio-fieldset-toggle">
                  <legend>
                    <Trans>Export format</Trans>
                  </legend>
                  <div className="radio-group-wrapper">
                    <ToggleField
                      name="exportFormat"
                      options={[{ label: 'PDF', value: 'PDF' }]}
                    />
                    <FieldError name="exportFormat" />
                  </div>
                </fieldset>
                <fieldset>
                  <legend>Global plot set end date</legend>
                  <p>
                    <Trans>
                      When entered, the end date is applied to all stored plots
                      in the plot set and any existing end dates will be
                      overridden. Other time period settings in the stored plots
                      (start date / number of months) will also be applied.
                    </Trans>
                  </p>
                  <DateField name="endDate"></DateField>
                  <FieldError name="endDate" />
                </fieldset>
                <ActionBlock>
                  <ButtonHideModal />
                  <ButtonPrimary
                    type="submit"
                    name="export"
                    id="export-plot-submit"
                    iconType="icon-export"
                  >
                    <Trans>Export</Trans>
                  </ButtonPrimary>
                </ActionBlock>
              </Form>
            )}
          </Formik>
        </>
      </ModalContent>
    );
  } else if (props.exportTaskId) {
    return (
      <QueuedExportModal taskId={props.exportTaskId} fileTypeLabel="PDF" />
    );
  } else {
    return (
      <ErrorNotice>
        <Trans>An unexpected error occurred</Trans>
      </ErrorNotice>
    );
  }
};

export const PlotSetView = (props: PlotSetViewProps) => {
  const {
    plotSet,
    currentIndex,
    storedPlotOptions,
    isLoading,
    errorMessage,
    onNext,
    onPrevious,
    onSelectStorePlot,
    storedPlot,
  } = props;

  // A ref to an HTML element that individual stored plot types can use to
  // add additional controls to to the top of the screen next to "settings"
  // and "export"
  const buttonsPortal = useRef<HTMLElement>(null);

  return (
    <PageStandard
      name="plotset"
      header={<Trans>Plot Set</Trans>}
      subHeader={plotSet ? plotSet.name : ''}
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton defaultBackUrl="/plot-sets" />
        <div className="plot-set-header">
          <h2>{storedPlot ? storedPlot.title : ''}</h2>

          <ActionBlock className="plot-set-pager">
            <Button
              iconOnly
              iconType="icon-arrow-left"
              title={t`Previous plot in plot set`}
              onClick={onPrevious}
              disabled={currentIndex === 0}
            />
            <SimpleSelect
              name="stored-plots-select"
              options={storedPlotOptions}
              value={
                storedPlotOptions.length
                  ? storedPlotOptions[currentIndex].value
                  : ''
              }
              onChange={onSelectStorePlot}
            />
            <Button
              iconOnly
              iconType="icon-arrow-right"
              title={t`Next plot in plot set`}
              onClick={onNext}
              disabled={currentIndex === storedPlotOptions.length - 1}
            />
          </ActionBlock>
        </div>
      </div>
      <div className="page-content-header columns-fluid">
        <div className="plot-page-header-info">
          <h3 data-testid="stored-plot-description">
            {storedPlot ? storedPlot.description : null}
          </h3>
        </div>
        {plotSet ? (
          <ActionBlock className="text-right">
            <span ref={buttonsPortal} />
            <ButtonShowModal
              name="export"
              modalContent={() => (
                <OwnExportPanel
                  onExportSubmit={props.onExportSubmit}
                  errorMessage={props.exportState.errorMessage}
                  exportTaskId={props.exportState.exportTaskId}
                  resetExportState={props.resetExportState}
                />
              )}
              iconType="icon-export"
            >
              <Trans>Export</Trans>
            </ButtonShowModal>
          </ActionBlock>
        ) : null}
      </div>

      <StoredPlot
        isLoading={isLoading}
        errorMessage={errorMessage}
        storedPlot={storedPlot}
        buttonsPortal={buttonsPortal}
      />
    </PageStandard>
  );
};
