import React from 'react';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import { FormikHelpers } from 'formik';
import EditableCard from 'components/base/form/editablecard/editablecard';
import {
  DataLoggerFormValues,
  DataLoggerFormBody,
  validateDataLoggerForm,
} from '../dataloggerformbody';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import ActionBlock from 'components/base/actionblock/actionblock';
import { Link } from 'react-router-dom';
import { Icon } from 'components/base/icon/icon';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { BackButton } from 'components/base/back-button/BackButton';

export interface MaintainDataLoggerViewProps {
  hasEditPermission: boolean;
  loading: boolean | undefined;
  error: string | null;
  areas: Array<Model.AreaDecorated>;
  dataLogger: Model.DataLoggerDecorated | null;
  nullReadingObsPoint: Model.ObservationPointDecorated | null;
  missedDownloadObsPoint: Model.ObservationPointDecorated | null;
  updateDataLogger: (
    values: DataLoggerFormValues,
    actions: FormikHelpers<DataLoggerFormValues>
  ) => Promise<boolean>;
}

interface MyState {
  isEditing: boolean;
}

export class MaintainDataLoggerView extends React.Component<
  MaintainDataLoggerViewProps,
  MyState
> {
  constructor(props: MaintainDataLoggerViewProps) {
    super(props);
    this.state = {
      isEditing: false,
    };
  }

  render() {
    const pageProps = {
      name: 'maintain-data-logger',
      header: <Trans>Data logger</Trans>,
    };
    if (this.props.error) {
      return (
        <PageStandard {...pageProps}>
          <AlertDanger>{this.props.error}</AlertDanger>
        </PageStandard>
      );
    }
    if (this.props.loading || this.props.dataLogger === null) {
      return (
        <PageStandard {...pageProps}>
          <Loading />
        </PageStandard>
      );
    }

    // "Daylight Saving time handling" time-dependent field = DST Handling TDF
    // TODO RM-89206: This assumes the back end will return them with the latest
    // TDF as the first one in the array.
    const dstHandlingTdf =
      this.props.dataLogger.data_logger_time_dependent_fields
        ?.daylight_saving_handling?.[0];

    let isEditing = this.state.isEditing;

    return (
      <PageStandard
        {...pageProps}
        subHeader={this.props.dataLogger.logger_number}
        key={`maintdatalogger-${this.props.dataLogger.logger_number}`}
      >
        <div className="page-content-header-with-back-button-wrapper">
          <BackButton defaultBackUrl="/data-loggers" />
          <div className="page-content-header">
            <HasPermission check="can_view_data_logger_channel_report">
              <ActionBlock>
                <Link
                  to={`/data-logger-channels/?data_logger=${this.props.dataLogger.logger_number}`}
                  className="btn"
                >
                  <span>
                    <Trans>Channel allocation</Trans>
                  </span>
                  <Icon type="icon-exchange" />
                </Link>
              </ActionBlock>
            </HasPermission>
          </div>
        </div>
        <EditableCard<DataLoggerFormValues>
          validateOnBlur={false}
          hasEditPermission={this.props.hasEditPermission}
          shouldDisable={this.props.dataLogger === null}
          name="overview"
          header={
            isEditing ? (
              <Trans>Change logger</Trans>
            ) : (
              <Trans>Logger overview</Trans>
            )
          }
          startEditing={() => this.setState({ isEditing: true })}
          stopEditing={() => this.setState({ isEditing: false })}
          isEditMode={this.state.isEditing}
          initialValues={{
            logger_number: this.props.dataLogger.logger_number,
            area: this.props.dataLogger.site.area.id,
            site: this.props.dataLogger.site.id,
            model: this.props.dataLogger.model,
            install_date: this.props.dataLogger.install_date || '',
            install_by: this.props.dataLogger.install_by,
            install_comments: this.props.dataLogger.install_comments,
            null_reading_observation_point: this.props.nullReadingObsPoint
              ? this.props.nullReadingObsPoint.id
              : '',
            missed_download_observation_point: this.props.missedDownloadObsPoint
              ? this.props.missedDownloadObsPoint.id
              : '',
            maintain_observation_point_latest_readings:
              this.props.dataLogger.maintain_observation_point_latest_readings,
            reading_frequency: this.props.dataLogger.reading_frequency,
            reading_frequency_tolerance:
              this.props.dataLogger.reading_frequency_tolerance,
            download_frequency: this.props.dataLogger.download_frequency,
            download_frequency_tolerance:
              this.props.dataLogger.download_frequency_tolerance,
            number_of_channels:
              this.props.dataLogger.number_of_channels.toString(),
            data_logger_time_dependent_fields: {
              daylight_saving_handling: [
                dstHandlingTdf
                  ? {
                      isEditing: false,
                      value:
                        dstHandlingTdf.value as Enum.DataLogger_DAYLIGHT_SAVING_HANDLING,
                      start_datetime: dstHandlingTdf.start_datetime,
                    }
                  : { isEditing: false, value: null, start_datetime: '' },
              ],
            },
          }}
          onSubmit={async (values, actions) => {
            let success = await this.props.updateDataLogger(values, actions);
            if (success) {
              this.setState({ isEditing: false });
            }
          }}
          validate={validateDataLoggerForm}
          render={(renderProps) => (
            <DataLoggerFormBody
              {...renderProps}
              isEditing={isEditing}
              areas={this.props.areas}
              dataLogger={this.props.dataLogger}
              nullReadingObsPoint={this.props.nullReadingObsPoint}
              missedDownloadObsPoint={this.props.missedDownloadObsPoint}
            />
          )}
        />
      </PageStandard>
    );
  }
}
