import React from 'react';
import { t, Trans, Plural } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Model } from 'util/backendapi/models/api.interfaces';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import ActionBlock from 'components/base/actionblock/actionblock';
import {
  ReportColumn,
  ReportFilter,
  DEFAULT_SHOW,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportStateProps } from 'hooks/use-report-state';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { getApi } from 'util/backendapi/fetch';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { DMSLink } from 'components/base/link/DMSLink';
import { Icon } from 'components/base/icon/icon';
import { parseStringArrayFromQueryParam } from 'util/routing';

export const userListColumns: ReportColumn<Model.ReportsUser>[] = [
  {
    label: <Trans>Username</Trans>,
    name: 'username',
    visibility: DEFAULT_SHOW,
    backendFieldName: 'username',
    additionalFields: ['id'],
    accessor: ({ id, username }) => (
      <DMSLink to={`/users/${id}`}>{username}</DMSLink>
    ),
  },
  {
    label: <Trans>Name</Trans>,
    name: 'profile__name',
    visibility: DEFAULT_SHOW,
    backendFieldName: 'profile__name',
  },
  {
    label: <Trans>Preferred name</Trans>,
    name: 'profile__preferred_name',
    visibility: DEFAULT_SHOW,
    backendFieldName: 'profile__preferred_name',
  },
  {
    label: <Trans>Client</Trans>,
    name: 'clients__name',
    visibility: DEFAULT_SHOW,
    backendFieldName: 'clients__name',
  },
  {
    label: <Trans>Status</Trans>,
    name: 'is_active',
    visibility: DEFAULT_SHOW,
    backendFieldName: 'is_active',
    accessor: ({ is_active }) => (is_active ? 'Active' : 'Retired'),
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id'],
    accessor: ({ id }) => (
      <HasPermission check="can_create_users">
        <DMSLink to={`/users/${id}`}>
          <Icon type="icon-view" title={t`View`} />
        </DMSLink>
        <DMSLink to={`/users/${id}?edit=1`}>
          <Icon type="icon-edit" title={t`Edit`} />
        </DMSLink>
      </HasPermission>
    ),
  },
];
function _userToMenuItem(user: Model.User): SimpleSelectOption<number> {
  return {
    value: user.id,
    label: `${user.profile.name} (${user.username})`,
  };
}

export const userListFilters = (
  props: {
    clients?: Model.Client[] | null;
  } = {}
): ReportFilter[] => [
  reportFilterAsyncMenu(
    'id',
    <Trans>User</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: (searchText) =>
        getApi('/users/', {
          profile__name__icontains: searchText,
        }).then((users) => users.map(_userToMenuItem)),
      loadDefaults: (ids: number[]) =>
        getApi('/users/', {
          id__in: ids,
        }).then((users) => users.map(_userToMenuItem)),
    },
    {
      autoFocus: true,
      placeholder: <Trans>All users</Trans>,
    }
  ),
  reportFilterMenu(
    'client',
    <Trans>Client</Trans>,
    { isMulti: true, valueType: 'number' },
    (option: Model.Client) => ({
      value: option.id,
      label: option.name,
    }),
    {
      placeholder: <Trans>All clients</Trans>,
      options: props.clients ?? [],
    }
  ),
  {
    ...reportFilterMenu(
      'is_active',
      <Trans>Status</Trans>,
      { isMulti: true, valueType: 'string' },
      ReportFilterMenu.PASS_MENU_THROUGH,
      {
        placeholder: <Trans>All statuses</Trans>,
        options: [
          {
            value: 'true',
            label: <Trans>Active</Trans>,
          },
          {
            value: 'false',
            label: <Trans>Retired</Trans>,
          },
        ],
      }
    ),
    getBackendFilterFromUrl(routeProps) {
      const valuesFromUrl = parseStringArrayFromQueryParam(
        routeProps,
        'is_active',
        null
      );
      // If only one option is selected then filter to that
      // otherwise it should show both active and inactive users
      if (valuesFromUrl && valuesFromUrl.length === 1) {
        return { is_active: valuesFromUrl[0] === 'true' };
      }

      return null;
    },
  },
];

export interface UserListViewProps {
  reportProps: ReportStateProps<Model.ReportsUser>;
  clients: Model.Client[] | null;
}

export const UserListView: React.FunctionComponent<UserListViewProps> = (
  props
) => {
  const columns = userListColumns;
  const filters = userListFilters(props);
  const { reportProps } = props;
  return (
    <PageStandard
      name="maintObsPointLanding"
      header={
        reportProps.isExportMode ? (
          <Trans>User report</Trans>
        ) : (
          <Trans>Maintain Users</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_users">
          <ActionBlock className="text-right">
            <DMSLink to={`/users/new`} className="btn" shortcut="CREATE_NEW">
              <span>
                <Trans>Create user</Trans>
              </span>
              <Icon type="icon-plus" />
            </DMSLink>
          </ActionBlock>
        </HasPermission>
        <ReportFiltersBlock
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          filtersFrontend={filters}
          filtersToShow={['id', 'client', 'is_active']}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ButtonPrint />
          <ExportReportButton
            fileNameBase={t`User report`}
            reportUrl="/reports/users/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>
        <ReportTable
          columns={columns}
          filters={filters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 user"
              other="Filtered to # users"
            />
          )}
          msgNoMatches={<Trans>No users match the selected filters.</Trans>}
        />
      </div>
    </PageStandard>
  );
};
