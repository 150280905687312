import React from 'react';
import { I18n } from '@lingui/react';
import { MessageDescriptor } from '@lingui/core';
import { Field } from 'formik';
import { ReportFilter, FilterRenderingInfo } from '../../report-types';
import { parseQueryParamFromRouterProps } from 'util/routing';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import { FieldError } from 'components/base/form/errornotice/errornotice';

/**
 * Configure a text filter control for a report API table.
 *
 * @param name
 * @param label
 * @param extraProps
 */
export function reportFilterText(
  name: string,
  label: React.ReactNode,
  extraProps?: {
    placeholder?: MessageDescriptor;
    validate?: (fieldValue: any) => React.ReactNode;
  }
): ReportFilter {
  function _parseFromFrontendUrl(
    routeProps: RouteChildrenProps | RouteComponentProps
  ) {
    return parseQueryParamFromRouterProps(routeProps, name, null);
  }

  return {
    name,
    label,
    render() {
      return (
        <I18n>
          {({ i18n }) => (
            <>
              <Field
                name={name}
                type="text"
                placeholder={
                  extraProps?.placeholder ? i18n._(extraProps.placeholder) : ''
                }
                validate={extraProps?.validate}
              />
              <FieldError name={name} />
            </>
          )}
        </I18n>
      );
    },
    renderSSR(info: FilterRenderingInfo) {
      return info.value;
    },
    getFormValFromUrlParam(routeProps) {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      return valueFromUrl;
    },
    getUrlParamFromFormVal(formValues) {
      const val = formValues[name];
      if (val === '' || val === undefined) {
        return { [name]: null };
      } else {
        return { [name]: val };
      }
    },
    getBackendFilterFromUrl(routeProps) {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      if (valueFromUrl === null) {
        return null;
      }

      const filterName = name;
      return { [filterName]: valueFromUrl };
    },
  };
}
