import React from 'react';
import lodashGet from 'lodash/get';
import { BulkCreateErrorDetail } from 'util/backendapi/types/Model';

export type BulkCreateErrorFilter = 'with_errors' | 'without_errors' | '';

type Props = {
  errorDetail: BulkCreateErrorDetail;
  errorFilter: BulkCreateErrorFilter;
};

export const BulkCreateErrorTable = (props: Props) => {
  const { errorDetail, errorFilter } = props;

  return (
    <div className="table-responsive">
      <table>
        <thead>
          <tr>
            {errorDetail.fields.map((fieldName, fieldIndex) => (
              <th key={`bulk-create-error-table-header-${fieldIndex}`}>
                {lodashGet(errorDetail.headers, fieldName, '')}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {errorDetail.content.map((row, rowIndex) => {
            const rowErrors = errorDetail.errors[rowIndex] ?? {};
            const rowHasErrors = rowErrors && Object.keys(rowErrors).length > 0;

            if (
              (errorFilter === 'with_errors' && !rowHasErrors) ||
              (errorFilter === 'without_errors' && rowHasErrors)
            ) {
              return null;
            }

            const rowKey = `bulk-create-error-table-row-${rowIndex}`;
            const rowClassName = rowHasErrors ? 'table-row-error' : undefined;
            return (
              <tr key={rowKey} className={rowClassName}>
                {errorDetail.fields.map((field, i) => {
                  let fieldErrors = rowErrors?.[field] ?? [];
                  const fieldKey = `bulk-create-error-table-field-${field}`;

                  // Display any non field errors against the first column
                  if (i === 0 && rowErrors.non_field_errors) {
                    fieldErrors = [
                      ...rowErrors.non_field_errors,
                      ...fieldErrors,
                    ];
                  }

                  if (fieldErrors.length > 0) {
                    const errorString = fieldErrors.join(', ');
                    return (
                      <td key={fieldKey}>
                        {row[field]}
                        <div className="table-cell-error">{errorString}</div>
                      </td>
                    );
                  }
                  return <td key={fieldKey}>{row[field]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
