import React from 'react';
import { FieldArray, FormikProps } from 'formik';
import { Trans } from '@lingui/macro';
import Button from 'components/base/button/button';
import { StoredSpatialCrossSectionPlotFormValues } from '../StoredSpatialCrossSectionPlotForm';
import { StoredSpatialPlanPlotFormValues } from '../StoredSpatialPlanPlotForm';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { StoredSpatialPlotObservationPointSection } from './StoredSpatialPlotObservationPointSection';
import { FormCardSection } from 'components/base/card/card';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

export type StoredSpatialPlotFormValues =
  | StoredSpatialCrossSectionPlotFormValues
  | StoredSpatialPlanPlotFormValues;

type Props = {
  formik: FormikProps<StoredSpatialPlotFormValues>;
  timeZone: string | undefined;
  requireObservationPoint: boolean;
};

export function StoredSpatialPlotObservationPointListSection(props: Props) {
  const { formik, timeZone, requireObservationPoint } = props;

  return (
    <>
      <FieldArray name="observation_points">
        {(formikArrayHelpers) => (
          <FormCardSection
            name={`stored-plot-spatial-observation-points`}
            className="nested stored-plot-spatial-fieldset"
            header={
              <>
                <Trans>Observation point display options</Trans>
                <Button
                  data-test-id="spatial-plot-add-spatial-plot-observation-point-button"
                  iconType="icon-plus"
                  className="annotation-delete-button"
                  onClick={() =>
                    formikArrayHelpers.push({
                      observation_point: 0,
                      label: '',
                      use_specific_datetime: false,
                      reading_datetime: null,
                      show_label: true,
                      show_in_data_table: true,
                      vertical_offset: '0',
                      horizontal_offset: '0',
                    })
                  }
                >
                  <Trans>Add observation point</Trans>
                </Button>
              </>
            }
            fields={[
              {
                name: 'observation-point-table',
                content: (
                  <div className="spatial-plot-observation-points">
                    {formik.values.observation_points.length > 0 && (
                      <DragDropContext
                        onDragEnd={(result: DropResult) => {
                          if (
                            result.destination &&
                            result.source.index !== result.destination.index
                          ) {
                            formikArrayHelpers.move(
                              result.source.index,
                              result.destination.index
                            );
                          }
                        }}
                      >
                        <table className="stored-plot-spatial-table table-responsive">
                          <thead>
                            <tr>
                              <th scope="col">
                                <Trans>Observation Point</Trans>
                              </th>
                              <th scope="col">
                                <Trans>Label</Trans>
                              </th>
                              <th scope="col">
                                <Trans>Display label on plot</Trans>
                              </th>
                              <th scope="col">
                                <Trans>Display obs pt in data table</Trans>
                              </th>
                              <th scope="col" className="reading-input">
                                <Trans>Reading</Trans>
                              </th>
                              <th scope="col">
                                <Trans>Reading Date</Trans>
                              </th>
                              <th scope="col">
                                <Trans>Vertical Offset</Trans>
                              </th>
                              <th scope="col">
                                <Trans>Horizontal Offset</Trans>
                              </th>
                              <th scope="col" className="action-icons">
                                <Trans>Actions</Trans>
                              </th>
                            </tr>
                          </thead>
                          <Droppable
                            droppableId={`droppable-observation-point-list-section`}
                          >
                            {(provided) => (
                              <tbody
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                              >
                                {formik.values.observation_points.map(
                                  (_observationPoint, idx) => (
                                    // A table of the obs points in the plot
                                    <StoredSpatialPlotObservationPointSection
                                      key={idx}
                                      formik={formik}
                                      timeZone={timeZone}
                                      obsPointIdx={idx}
                                      obsPointFieldPrefix={`observation_points[${idx}`}
                                      obsPointArrayHelpers={formikArrayHelpers}
                                      requireObservationPoint={
                                        requireObservationPoint
                                      }
                                      draggable={
                                        formik.values.observation_points
                                          .length > 1
                                      }
                                    />
                                  )
                                )}
                                {provided.placeholder}
                              </tbody>
                            )}
                          </Droppable>
                        </table>
                      </DragDropContext>
                    )}
                  </div>
                ),
              },
            ]}
          ></FormCardSection>
        )}
      </FieldArray>
      <FormChangeEffect<StoredSpatialPlotFormValues>
        onChange={({ values: prevValues }) => {
          if (
            formik.values.observation_points.length ===
            prevValues.observation_points.length
          ) {
            formik.values.observation_points.forEach(
              (observation_point, idx) => {
                // If the user selected a different observation point
                // change the label to the observation points name
                if (
                  observation_point.selectedOptions &&
                  (!prevValues.observation_points[idx] ||
                    observation_point.observation_point !==
                      prevValues.observation_points[idx].observation_point)
                ) {
                  const selectedOptionLabel =
                    observation_point.selectedOptions[0]?.label ?? '';
                  formik.setFieldValue(
                    `observation_points[${idx}].label`,
                    selectedOptionLabel
                  );
                }
              }
            );
          }
        }}
      />
    </>
  );
}
