import React from 'react';
import { formatDatetimeForDisplay } from '../../util/dates';
import RichEditor from '../../components/base/richeditor/richeditor';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { AlertDanger, AlertInfo } from '../../components/base/alert/alert';
import PageStandard from '../../components/modules/pagestandard/pagestandard';
import { ButtonPrimary } from '../../components/base/button/button';
import Loading from '../../components/base/loading/loading';
import ActionBlock from 'components/base/actionblock/actionblock';
import { Model } from 'util/backendapi/models/api.interfaces';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { getUserDisplayName } from 'util/user';

interface FileEditorViewProps {
  id: number;
  isLoading: boolean;
  canEditFileFormat: boolean;
  parserMessages: Model.ReadingsFileParseMessageDecorated[] | null;
  file: Model.ReadingsFileDecorated | null;
  fileContent: string | null;
  reasonForChangeOptions: SimpleSelectOption<number>[];
  handleSubmit: (reasonForChange: number, newFileContent: string) => void;
}

interface State {
  reasonForChange: number;
  fileContent: string;
}

export class FileEditorView extends React.Component<
  FileEditorViewProps,
  State
> {
  constructor(props: FileEditorViewProps) {
    super(props);
    this.state = {
      reasonForChange: props.reasonForChangeOptions[0].value,
      fileContent: props.fileContent || '',
    };
  }

  // TODO: This is the strange way I pass the function up from `RichEditor`,
  // so the parent component can imperatively retrieve the current editor
  // contents. This could probably be done better with a ref or contexts.
  getTextAreaContent: (() => string) | null = null;

  // TODO: This doesn't seem like exactly the right way to put this into
  // the react component lifecycle. But neither the standard "value" or
  // "defaultValue" settings works quite right for me, because I want to
  // display the textarea in a disabled "Loading" state until we get the
  // file contents, and then change it to a normal controlled component, with
  // the live data in the component's state.
  //
  // So for now, the quickest way to get there is to use componentDidUpdate
  // to reset state.fileContent when a non-null props.fileContent comes in.
  componentDidUpdate(prevProps: FileEditorViewProps) {
    if (prevProps.fileContent === null && this.props.fileContent !== null) {
      this.setState({ fileContent: this.props.fileContent });
    }
  }

  renderParserMessage(
    parserMessage: Model.ReadingsFileParseMessageDecorated
  ): React.ReactNode {
    const { message, line_index, word_index, severity } = parserMessage;
    let shortMessage = message;
    let hover = undefined;

    const stackTraceStart = message.indexOf(' Stack trace:\n');
    if (stackTraceStart > 0) {
      shortMessage = message.slice(0, stackTraceStart);
      // Replace literal '\n' (which is how we encode the newlines for JSON)
      // with an actual newline.
      hover = [
        'Stack trace:' +
          message
            .slice(stackTraceStart + ' Stack Trace:\\n'.length + 1, -2)
            .split("', '")
            .map((i) => i.replace(/\\n/g, '\n').replace(/\\'/g, "'")),
      ].join('\n');
    }
    return (
      <span title={hover}>
        <Trans>{`Line ${line_index + 1} word ${
          word_index + 1
        }: ${severity} "${shortMessage}"`}</Trans>
      </span>
    );
  }

  render() {
    let parserMessagesRendered;
    if (!this.props.parserMessages) {
      parserMessagesRendered = <Loading />;
    } else {
      switch (this.props.parserMessages.length) {
        case 0:
          parserMessagesRendered = <Trans>(None)</Trans>;
          break;
        case 1:
          parserMessagesRendered = this.renderParserMessage(
            this.props.parserMessages[0]
          );
          break;
        default:
          parserMessagesRendered = (
            <ul>
              {this.props.parserMessages.map((message, i) => (
                <li key={i}>{this.renderParserMessage(message)}</li>
              ))}
            </ul>
          );
      }
    }

    return (
      <PageStandard
        name="editreadingsfile"
        header={<Trans>Edit Raw Data</Trans>}
        subHeader={this.props.file && this.props.file.file_name}
      >
        {this.props.isLoading ? (
          <Loading />
        ) : (
          <form onSubmit={this.onFormSubmit}>
            <AlertDanger
              additionalInformation={
                <dl className="dl-inline">
                  <dt>
                    <Trans>Reason:</Trans>
                  </dt>
                  <dd>{parserMessagesRendered}</dd>
                  <dt>
                    <Trans>Uploaded:</Trans>
                  </dt>
                  <dd>
                    {formatDatetimeForDisplay(
                      this.props.file && this.props.file.upload_datetime
                    )}
                  </dd>
                  <dt>
                    <Trans>Uploaded by:</Trans>
                  </dt>
                  <dd>
                    {this.props.file &&
                      getUserDisplayName(this.props.file.user)}
                  </dd>
                  <dt>
                    <Trans>Batch:</Trans>
                  </dt>
                  <dd>
                    {this.props.file &&
                      this.props.file.readings_batch.batch_number}
                  </dd>
                </dl>
              }
            >
              <Trans>
                Validation failed for{' '}
                {this.props.file && this.props.file.file_name}
              </Trans>
            </AlertDanger>
            {this.props.canEditFileFormat ? (
              <>
                <div>
                  {this.props.fileContent === null ? (
                    <I18n>
                      {({ i18n }) => (
                        <div className="form-group">
                          <label htmlFor="fileContent">
                            <Trans>File content</Trans>
                          </label>
                          <textarea
                            disabled={true}
                            value={i18n._(t`Loading file contents...`)}
                            name="fileContent"
                          />
                        </div>
                      )}
                    </I18n>
                  ) : (
                    <RichEditor
                      fileContent={this.props.fileContent}
                      parserMessages={this.props.parserMessages}
                      // TODO: This is the strange way I pass the "get value" function
                      // up from RichEditor to the parent. It could probably be done
                      // better with refs and/or contexts.
                      registerGetValue={(fn: () => string) =>
                        (this.getTextAreaContent = fn)
                      }
                    />
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="reasonForChange">
                    <Trans>Reason for change</Trans>
                  </label>
                  <select
                    name="reasonForChange"
                    value={this.state.reasonForChange}
                    onChange={this.onInputChange}
                    required={true}
                  >
                    {this.props.reasonForChangeOptions.map((opt) => (
                      <option key={opt.value} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </div>
                <ActionBlock>
                  <ButtonPrimary
                    type="submit"
                    iconType="icon-save"
                    disabled={!this.props.fileContent}
                  >
                    <Trans>Save & revalidate</Trans>
                  </ButtonPrimary>
                </ActionBlock>
              </>
            ) : (
              <AlertInfo>
                <Trans>
                  This file format cannot be edited in the DMS. Please fix the
                  errors in your copy of the file and re-upload it.
                </Trans>
              </AlertInfo>
            )}
          </form>
        )}
      </PageStandard>
    );
  }

  onInputChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (
      event.target.name === 'reasonForChange' &&
      +event.target.value !== this.state.reasonForChange
    ) {
      this.setState({
        reasonForChange: +event.target.value,
      });
    }
  };

  onFormSubmit = (event: React.FormEvent) => {
    if (this.getTextAreaContent !== null) {
      this.props.handleSubmit(
        this.state.reasonForChange,
        this.getTextAreaContent()
      );
    }
    event.preventDefault();
  };
}
