import React from 'react';
import { Trans } from '@lingui/macro';
import ModalContent from 'components/base/modal/modalcontent';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrimary } from 'components/base/button/button';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { Form, Formik, FormikProps, ErrorMessage } from 'formik';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import {
  ObsPointItemMenu,
  ObsPointItemMenuOption,
  splitObsPointItemIdent,
} from '../obs-point-item-menu/ObsPointItemMenu';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import { useHistory } from 'react-router';
import { getObservationPointItemUrlCode } from 'components/plots/timeseriesplotselectors';

export interface SettingsFormValues {
  selection: string;
  selectedObsPoint: ObsPointItemMenuOption[];
}

export type Props = Pick<ModalContentProps, 'hideModal'>;

const validate = (values: SettingsFormValues) => {
  const errors: any = {};

  if (!values.selection) {
    errors.selection = <Trans>Please select an observation point</Trans>;
  }
  return errors;
};

const AlarmParametersSettingsView: React.FunctionComponent<Props> = ({
  hideModal,
}) => {
  const initialValues: SettingsFormValues = {
    selection: '',
    selectedObsPoint: [],
  };
  let history = useHistory();
  return (
    <ModalContent header={<Trans>Alarm parameter settings</Trans>}>
      <Formik
        initialValues={initialValues}
        validateOnMount
        validate={validate}
        onSubmit={(values) => {
          const itemNumber = splitObsPointItemIdent(
            values.selection
          )?.item_number;
          const obsPointCode =
            values.selectedObsPoint[0]?.observationPoint?.code;

          const itemUrlCode =
            itemNumber && obsPointCode
              ? getObservationPointItemUrlCode(obsPointCode, itemNumber)
              : '';
          const newUrl = `/alarm-parameters/${encodeURIComponent(itemUrlCode)}`;
          history.push(newUrl);
          hideModal();
        }}
      >
        {(formikBag: FormikProps<SettingsFormValues>) => (
          <Form>
            <fieldset>
              <legend>
                <Trans>Observation point</Trans>
              </legend>
              <ObsPointItemMenu
                name="selection"
                autoFocus={true}
                isMulti={false}
                detailsName="selectedObsPoint"
              />
              <ErrorMessage name="selection" component={ErrorNotice} />
            </fieldset>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                type="submit"
                iconType="icon-update"
                disabled={!formikBag.isValid}
              >
                <Trans>Apply settings</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

export default AlarmParametersSettingsView;
