import { getApi } from 'util/backendapi/fetch';
import { StandardThunk, DuckActions } from 'main/store';
import { Model } from 'util/backendapi/models/api.interfaces';

export const ActionTypes = {
  FETCH_AREA_OBS_POINT_OPTIONS_START:
    'dms/datalogger/FETCH_AREA_OBS_POINT_OPTIONS_START',
  FETCH_AREA_OBS_POINT_OPTIONS_RESPONSE:
    'dms/datalogger/FETCH_AREA_OBS_POINT_OPTIONS_RESPONSE',
  FETCH_AREA_OBS_POINT_OPTIONS_ERROR:
    'dms/datalogger/FETCH_AREA_OBS_POINT_OPTIONS_ERROR',
} as const;

export const ActionCreators = {
  FETCH_AREA_OBS_POINT_OPTIONS_START: (area: number) => ({
    type: ActionTypes.FETCH_AREA_OBS_POINT_OPTIONS_START,
    area,
  }),
  FETCH_AREA_OBS_POINT_OPTIONS_RESPONSE: (
    area: number,
    observationPoints: Model.ObservationPointDecorated[]
  ) => ({
    type: ActionTypes.FETCH_AREA_OBS_POINT_OPTIONS_RESPONSE,
    area,
    payload: observationPoints,
  }),
  FETCH_AREA_OBS_POINT_OPTIONS_ERROR: (area: number, errorMessage: string) => ({
    type: ActionTypes.FETCH_AREA_OBS_POINT_OPTIONS_ERROR,
    area,
    payload: errorMessage,
  }),
};

export type AreaObsPointMenuAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface AreaObsPointMenuState {
  errorMessage: null | string;
  isLoading: boolean;
  menuOptions: Pick<Model.ObservationPointDecorated, 'id' | 'code'>[];
  loadedAreaId: number | null;
}

function initialState(): AreaObsPointMenuState {
  return {
    errorMessage: null,
    isLoading: false,
    menuOptions: [],
    loadedAreaId: null,
  };
}

export function areaObsPointMenuReducer(
  state = initialState(),
  action: AreaObsPointMenuAction
): AreaObsPointMenuState {
  switch (action.type) {
    case ActionTypes.FETCH_AREA_OBS_POINT_OPTIONS_START:
      return {
        loadedAreaId: action.area,
        isLoading: true,
        menuOptions: [],
        errorMessage: null,
      };
    case ActionTypes.FETCH_AREA_OBS_POINT_OPTIONS_RESPONSE:
      if (action.area !== state.loadedAreaId) {
        return state;
      } else {
        return {
          ...state,
          isLoading: false,
          errorMessage: null,
          menuOptions: action.payload,
        };
      }
    case ActionTypes.FETCH_AREA_OBS_POINT_OPTIONS_ERROR:
      if (action.area !== state.loadedAreaId) {
        return state;
      } else {
        return {
          ...state,
          isLoading: false,
          errorMessage: action.payload,
          menuOptions: [],
        };
      }
    default:
      return state;
  }
}

export function fetchAreaObsPointOptions(area: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_AREA_OBS_POINT_OPTIONS_START(area));
    try {
      dispatch(
        ActionCreators.FETCH_AREA_OBS_POINT_OPTIONS_RESPONSE(
          area,
          await getApi('/observation-points/', {
            fields: ['id', 'code'],
            site__area__in: [area],
          })
        )
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_AREA_OBS_POINT_OPTIONS_ERROR(area, e.message)
      );
    }
  };
}
