import { Model } from 'util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions } from 'main/store';
import { postApi, getApi, patchApi, deleteApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  FETCH_ALARM_NOTIFICATION_START:
    'dms/alarmNotification/detail/FETCH_ALARM_NOTIFICATION_START',
  FETCH_ALARM_NOTIFICATION_RESPONSE:
    'dms/alarmNotification/detail/FETCH_ALARM_NOTIFICATION_RESPONSE',
  FETCH_ALARM_NOTIFICATION_ERROR:
    'dms/alarmNotification/detail/FETCH_ALARM_NOTIFICATION_ERROR',
} as const;

export const ActionCreators = {
  FETCH_ALARM_NOTIFICATION_START(id: number) {
    return {
      type: ActionTypes.FETCH_ALARM_NOTIFICATION_START,
      id,
    };
  },
  FETCH_ALARM_NOTIFICATION_RESPONSE(
    alarmNotification: Model.AlarmNotification
  ) {
    return {
      type: ActionTypes.FETCH_ALARM_NOTIFICATION_RESPONSE,
      payload: {
        alarmNotification,
      },
    };
  },
  FETCH_ALARM_NOTIFICATION_ERROR(id: number, errorMessage: string) {
    return {
      type: ActionTypes.FETCH_ALARM_NOTIFICATION_ERROR,
      id,
      errorMessage,
    };
  },
};

export type AlarmNotificationDetailActions = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface AlarmNotificationDetailState {
  loading: boolean;
  errorMessage: string;
  id: null | number;
  record: null | Model.AlarmNotification;
}

export function initialAlarmNotificationDetailState(): AlarmNotificationDetailState {
  return {
    loading: false,
    errorMessage: '',
    id: null,
    record: null,
  };
}

export function alarmNotificationDetailReducer(
  state = initialAlarmNotificationDetailState(),
  action: AlarmNotificationDetailActions
): AlarmNotificationDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_ALARM_NOTIFICATION_START:
      return {
        ...state,
        loading: true,
        errorMessage: '',
        record: null,
        id: action.id,
      };
    case ActionTypes.FETCH_ALARM_NOTIFICATION_RESPONSE:
      if (state.id === action.payload.alarmNotification.id) {
        return {
          ...state,
          loading: false,
          record: action.payload.alarmNotification,
        };
      } else {
        return state;
      }
    case ActionTypes.FETCH_ALARM_NOTIFICATION_ERROR:
      if (state.id === action.id) {
        return {
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

export function fetchAlarmNotification(id: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_ALARM_NOTIFICATION_START(id));
    try {
      const alarmNotification = await getApi(`/alarm-notifications/${id}/`);
      dispatch(
        ActionCreators.FETCH_ALARM_NOTIFICATION_RESPONSE(alarmNotification)
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_ALARM_NOTIFICATION_ERROR(id, errorToString(e))
      );
    }
  };
}

export function createAlarmNotification(
  values: WithoutID<Model.AlarmNotification>
): StandardThunk<Model.AlarmNotification> {
  return async () => {
    return await postApi('/alarm-notifications/', values);
  };
}

export function deleteAlarmNotification(id: number): StandardThunk {
  return async () => {
    return await deleteApi(`/alarm-notifications/${id}/`);
  };
}

export function updateAlarmNotification(
  id: number,
  values: PartialWithoutID<Model.AlarmNotification>
): StandardThunk<Model.AlarmNotification> {
  return async () => {
    return await patchApi(`/alarm-notifications/${id}/`, values);
  };
}
