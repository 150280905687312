import { RouteComponentProps, RouteChildrenProps } from 'react-router';
import uniq from 'lodash/uniq';
import {
  ReportFilter,
  ReportColumn,
  ALWAYS_SHOW,
  DEFAULT_SHOW,
  getColBackendField,
  FilterRenderingInfo,
} from './report-types';
import { Model, Filter } from 'util/backendapi/models/api.interfaces';
import { detectExportMode } from 'util/export';
import { Pagination } from 'util/backendapi/pagination';
import { parseStringArrayFromQueryParam } from 'util/routing';
import { ExportFormats } from '../exportpanel/exportpanelconstants';

/**
 * Matches up the frontend configuration for filters, with the backend metadata
 * about each filter. This function mostly exists to re-use some code between
 * ReportFiltersBlock and AdvancedFiltersModal, but it may also be useful for
 * other components that need to do things with Report API filters.
 *
 * @param filtersToShow
 * @param filtersFrontend
 * @param filtersBackend
 * @param routeProps
 */
export function getDisplayableFilters(
  routeProps: RouteComponentProps | RouteChildrenProps,
  filtersToShow: string[] | null,
  filtersFrontend: ReportFilter[],
  filtersBackend: Model.ReportFilterInfo[] | null = null
): FilterRenderingInfo[] {
  const filterNames =
    filtersToShow === null
      ? filtersFrontend.map((frontend) => frontend.name)
      : filtersToShow;

  return filterNames.map((filterName) => {
    const frontend = filtersFrontend.find(
      (filter) => filter.name === filterName
    );
    if (!frontend) {
      return {
        filterName,
        frontend: undefined,
        backend: undefined,
        hasValues: false,
        value: undefined,
      };
    }

    const backend =
      (filtersBackend &&
        filtersBackend.find((info) => info.name === filterName)) ||
      undefined;
    const curValue = frontend.getFormValFromUrlParam(routeProps);
    const hasValues = frontend.getBackendFilterFromUrl(routeProps) !== null;
    return { filterName, frontend, backend, hasValues, value: curValue };
  });
}

/**
 * Retrieve the reporting parameters from the frontend URL, and returns them in
 * the form used for saved reports.
 *
 * The returned "filters" field is in frontend URL format, so it should be
 * suitable to pass directly to `setQueryParams()`.
 *
 * @param routeProps
 * @param columnsFrontend
 * @param filtersFrontend
 * @param reportInfo
 */
export function getSavedReportFromFrontendParams(
  routeProps: RouteComponentProps | RouteChildrenProps,
  columnsFrontend: ReportColumn[],
  filtersFrontend: ReportFilter[],
  reportInfo: Model.ReportInfo | null
): Pick<Model.SavedReport, 'filters' | 'columns' | 'ordering'> {
  const frontendColNames = columnsFrontend.map((frontend) => frontend.name);

  // Sort order
  let ordering = parseStringArrayFromQueryParam(routeProps, 'ordering', null);

  if (ordering !== null) {
    ordering = ordering.filter((colName) => {
      const baseName = colName[0] === '-' ? colName.slice(1) : colName;
      return frontendColNames.includes(baseName);
    });
  }

  // Columns of data to request
  let columns = parseStringArrayFromQueryParam(routeProps, 'columns', null);
  if (columns !== null) {
    columns = columns.filter((colName) => frontendColNames.includes(colName));
  }

  // If the following `.reduce()` return value is === to this "noFilters" object,
  // then we know there are no active filters.
  const noFilters = {};
  const filters = getDisplayableFilters(
    routeProps,
    null,
    filtersFrontend,
    reportInfo ? reportInfo.filters : null
  ).reduce(
    (activeFilters, filter) =>
      filter.hasValues
        ? {
            ...activeFilters,
            // Convert the value back to frontend URL format.
            ...filter.frontend!.getUrlParamFromFormVal({
              [filter.filterName]: filter.value,
            }),
          }
        : activeFilters,
    noFilters
  ) as Record<string, any>;

  return {
    columns,
    ordering,
    filters: filters === noFilters ? null : filters,
  };
}

/**
 * Gets all the standard report API params from the frontend URL, and puts them
 * together (translating them if needed) to the right format to send them to
 * the backend report API.
 *
 * @param routeProps
 * @param filtersFrontend
 * @param filtersBackend
 * @param exportFormat
 */
export function getReportApiBackendParams<
  TReportItem,
  TReportFilter extends Filter.ReportsStandardFilters<any> = Filter.ReportsStandardFilters<TReportItem>
>(
  routeProps: RouteComponentProps | RouteChildrenProps,
  columnsFrontend: ReportColumn<TReportItem>[],
  filtersFrontend: ReportFilter[],
  exportFormat?: ExportFormats
): TReportFilter {
  // Sort order
  const ordering = parseStringArrayFromQueryParam(routeProps, 'ordering').map(
    (rawUrlColName) => {
      const isDescending = rawUrlColName[0] === '-';
      const urlColName = isDescending ? rawUrlColName.slice(1) : rawUrlColName;
      const col = columnsFrontend.find((colFE) => colFE.name === urlColName);
      if (col) {
        return `${isDescending ? '-' : ' '}${getColBackendField(col)}`;
      } else {
        return undefined;
      }
    }
  );

  // Pagination
  const { limit, offset } = detectExportMode()
    ? {
        limit: undefined,
        offset: undefined,
      }
    : Pagination.parseFromRouterProps(routeProps);

  // Columns of data to request
  const columnsSelected = parseStringArrayFromQueryParam(
    routeProps,
    'columns',
    null
  );

  const columns = uniq(
    columnsFrontend.reduce(function (acc, col): string[] {
      if (
        // Some columns should be hidden from PDF/CSV export.
        (exportFormat && col.hideFromExport) ||
        !(
          col.visibility === ALWAYS_SHOW ||
          (!columnsSelected && col.visibility === DEFAULT_SHOW) ||
          (columnsSelected && columnsSelected.includes(col.name))
        )
      ) {
        // Do not request this frontend column's backend fields.
        return acc;
      }

      // Find the backend fields this frontend column needs to request.
      let myFields = [getColBackendField(col)].filter(Boolean) as string[];
      if (col.additionalFields) {
        myFields.push(...col.additionalFields);
      }

      if (exportFormat === ExportFormats.CSV && col.hideFieldsFromCSV) {
        myFields = myFields.filter(
          (field) => !col.hideFieldsFromCSV?.includes(field as any)
        );
      }

      return [...acc, ...myFields];
    }, [] as string[])
  );

  // Filtering
  const filterParams = filtersFrontend.reduce((acc, frontend) => {
    // Unfortunately there is not a smooth 1:1 mapping on all filter types
    // between the frontend UI (and hence frontend URL format), and the backend
    // filter param. So we need to call this method on the filter's frontend
    // config, to convert from the frontend URL to the backend filter param.
    const filterVal = frontend.getBackendFilterFromUrl(
      routeProps,
      Boolean(exportFormat)
    );
    if (!filterVal) {
      return acc;
    } else {
      return { ...acc, ...filterVal };
    }
  }, {});

  return {
    limit,
    offset,
    columns,
    ordering,
    ...filterParams,
  } as TReportFilter;
}
