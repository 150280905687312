import React from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { useHideModal } from 'components/base/modal/use-hide-modal';
import { Formik, Form, FormikErrors } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { Trans } from '@lingui/macro';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrimary } from 'components/base/button/button';
import { getExpectedFields } from 'util/backendapi/error';
import { StaticDataReportViewProps } from './StaticDataReportView';
import { DateField } from 'components/base/form/datefield/datefield';
import { AreaMultiMenu } from 'components/modules/async-menu/AreaMenu';
import { ObsPointMultiMenu } from 'components/modules/async-menu/ObsPointMenu';
import { SiteMultiMenu } from 'components/modules/async-menu/SiteMenu';

export interface StaticDataReportModalSettings {
  areas: number[];
  sites: number[];
  observation_points: number[];
  start_date: string;
  end_date: string;
}

export function StaticDataReportModal(props: {
  settings: StaticDataReportModalSettings;
  setStaticDataReportQueryParams: StaticDataReportViewProps['setStaticDataReportQueryParams'];
}) {
  const hideModal = useHideModal();

  const { settings } = props;

  const initialValues: StaticDataReportModalSettings = {
    ...settings,
  };
  return (
    <ModalContent header={<Trans>Static data report settings</Trans>}>
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const errors: FormikErrors<StaticDataReportModalSettings> = {};

          if (!values.end_date) {
            errors.end_date = (<Trans>End date is required.</Trans>) as any;
          }

          if (
            values.areas.length === 0 &&
            values.sites.length === 0 &&
            values.observation_points.length === 0
          ) {
            errors.areas = (
              <Trans>
                At least one of area, site or observation point is required.
              </Trans>
            ) as any;
          }

          return errors;
        }}
        onSubmit={async (
          { areas, sites, observation_points, start_date, end_date },
          formik
        ) => {
          try {
            props.setStaticDataReportQueryParams(
              areas,
              sites,
              observation_points,
              start_date,
              end_date
            );
            hideModal();
          } catch (e) {
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, getExpectedFields(initialValues));
          }
        }}
      >
        {(formik) => (
          <Form>
            {formik.status}
            <FormSection>
              <FormItem label={<Trans>Area</Trans>}>
                <AreaMultiMenu name="areas" />
                <FieldError name="areas" />
              </FormItem>
              <FormItem label={<Trans>Site</Trans>}>
                <SiteMultiMenu name="sites" />
                <FieldError name="sites" />
              </FormItem>
              <FormItem label={<Trans>Observation point</Trans>}>
                <ObsPointMultiMenu name="observation_points" />
                <FieldError name="observation_points" />
              </FormItem>
              <FormItem
                label={<Trans>Start date</Trans>}
                fieldId="static-data-report-startdate"
                className="form-group-panel-inline"
              >
                <DateField
                  id="static-data-report-startdate"
                  name="start_date"
                />
              </FormItem>
              <FieldError name="start_date" />
              <FormItem
                label={<Trans>End date</Trans>}
                fieldId="static-data-report-enddate"
                className="form-group-panel-inline"
              >
                <DateField id="static-data-report-enddate" name="end_date" />
              </FormItem>
              <FieldError name="end_date" />
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  data-testid="data-review-report-submit"
                  type="submit"
                >
                  <Trans>Apply settings</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </FormSection>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
