import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { AsyncSimpleSelectFieldProps } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';

export function makeIdCodeMenuOptions(
  options: { id: number; code: string }[]
): SimpleSelectOption<number>[] {
  return options.map((opt) => ({
    value: opt.id,
    label: opt.code,
  }));
}

export function makeIdCodeAndNameMenuOptions(
  options: { id: number; code: string; name: string }[]
): SimpleSelectOption<number>[] {
  return options.map((opt) => ({
    value: opt.id,
    label: `${opt.code} - ${opt.name}`,
  }));
}

export type SingleMenuProps = Merge<
  Partial<AsyncSimpleSelectFieldProps<number, false>>,
  {
    name: string;
  }
>;

export type MultiMenuProps = Merge<
  Partial<AsyncSimpleSelectFieldProps<number, true>>,
  {
    name: string;
  }
>;
