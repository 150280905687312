import React, { useMemo, useCallback, useState } from 'react';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import { AlertWarning, AlertInfo } from 'components/base/alert/alert';
import { Model } from 'util/backendapi/models/api.interfaces';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { FormCardSection, CardSectionRow } from 'components/base/card/card';
import {
  FormikErrors,
  Field,
  FieldArray,
  FormikHelpers,
  FormikProps,
} from 'formik';
import { BackButton } from 'components/base/back-button/BackButton';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { CheckBoxesField } from 'components/base/form/checkboxes-field/CheckboxesField';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { AreaGroupDecorated, Role } from 'util/backendapi/types/Model';
import lodashSet from 'lodash/set';
import sortBy from 'lodash/sortBy';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { useIsMounted } from 'util/hooks';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import EditableCard, {
  EditableCardSectionComponent,
} from 'components/base/form/editablecard/editablecard';
import './UserEditView.scss';
import { YesNoRadioField } from 'components/base/form/yesnoradio/yesnoradiofield';

export type UserAreaGroupValues = {
  id: number | null;
  area_group: number | null;
  roles: number[];
};

export type UserEditFormValues = Merge<
  Pick<Model.User_POST, 'username' | 'email' | 'is_active'>,
  Pick<Model.Profile, 'name' | 'preferred_name' | 'mobile'>
> & {
  default_user_area_group: number;
  id: number | null;
  system_roles: number[];
  user_area_groups: UserAreaGroupValues[];
  user_admin_settings: {
    batches_require_confirmation: boolean;
  };
};

export interface UserEditViewProps {
  user: Model.User | null;
  isLoading: boolean;
  isNewUser: boolean;
  initialEditingState: boolean;
  errorMessage: string | null;
  areaGroups: AreaGroupDecorated[];
  areaGroupRoles: Role[];
  systemRoles: Role[];
  onSubmit: (values: UserEditFormValues) => Promise<void>;
  onCancel: () => void;
}

const makeInitialValues = (user: Model.User | null): UserEditFormValues => {
  if (user) {
    return {
      id: user.id,
      name: user.profile.name,
      preferred_name: user.profile.preferred_name,
      username: user.username,
      email: user.email,
      mobile: user.profile.mobile,
      default_user_area_group: user.profile.default_user_area_group || 0,
      user_area_groups: sortBy(user.user_area_groups, (userAreaGroup) =>
        userAreaGroup.area_group.name.toLowerCase()
      ).map((userAreaGroup) => ({
        id: userAreaGroup.id,
        area_group: userAreaGroup.area_group.id,
        roles: sortBy(userAreaGroup.roles, (role) =>
          role.name.toLowerCase()
        ).map((role) => role.id),
      })),
      system_roles: user.system_roles.map((role) => role.id),
      is_active: user.is_active,
      user_admin_settings: {
        batches_require_confirmation:
          user.user_admin_settings.batches_require_confirmation,
      },
    };
  }

  return {
    id: null,
    name: '',
    preferred_name: '',
    username: '',
    email: '',
    mobile: '',
    system_roles: [],
    default_user_area_group: 0,
    user_area_groups: [{ id: null, area_group: null, roles: [] }],
    is_active: true,
    user_admin_settings: {
      batches_require_confirmation: false,
    },
  };
};

const requiredFields: Array<keyof UserEditFormValues> = [
  'name',
  'preferred_name',
  'username',
  'email',
  'mobile',
];

const validateForm = (
  values: UserEditFormValues
): FormikErrors<UserEditFormValues> => {
  let errors: FormikErrors<UserEditFormValues> = {};

  requiredFields.forEach(function (fieldName) {
    if (!values[fieldName]) {
      errors[fieldName] = (<Trans>This field is required</Trans>) as any;
    }
  });

  if (values.user_area_groups.length > 0) {
    values.user_area_groups.forEach((group, idx) => {
      if (!group.area_group) {
        lodashSet(
          errors,
          `user_area_groups.${idx}.area_group`,
          (<Trans>Group is required</Trans>) as any
        );
      }
      if (group.roles.length === 0) {
        lodashSet(
          errors,
          `user_area_groups.${idx}.roles`,
          (<Trans>At least one role is required</Trans>) as any
        );
      }
    });
  }

  return errors;
};

const UserAreaGroupsSection = (props: {
  user: Model.User | null;
  isEditing: boolean;
  CardSectionComponent: EditableCardSectionComponent;
  usersGroups: UserAreaGroupValues[];
  areaGroups: AreaGroupDecorated[];
  roles: Role[];
}) => {
  const {
    user,
    isEditing,
    CardSectionComponent,
    usersGroups,
    areaGroups,
    roles,
  } = props;

  const areaGroupOptions: SimpleSelectOption<number>[] = useMemo(
    () =>
      sortBy(areaGroups, (areaGroup) => areaGroup.name.toLowerCase()).map(
        (areaGroup) => ({
          label: areaGroup.name,
          value: areaGroup.id,
        })
      ),
    [areaGroups]
  );
  const roleOptions: SimpleSelectOption<number>[] = useMemo(
    () =>
      sortBy(roles, (role) => role.name.toLowerCase()).map((role) => ({
        label: role.name,
        value: role.id,
      })),
    [roles]
  );

  const usersAreaGroups: Model.UserAreaGroupNested[] = useMemo(
    () =>
      sortBy(user?.user_area_groups ?? [], (userAreaGroup) =>
        userAreaGroup.area_group.name.toLowerCase()
      ),
    [user]
  );

  if (!isEditing && user) {
    return (
      <>
        <FieldArray name={`user_area_groups`}>
          {() => {
            const fields: CardSectionRow[] = usersAreaGroups.map(
              (userAreaGroup, idx) => {
                const userAreaGroupPrefix = `user_area_groups.${idx}`;
                return {
                  name: userAreaGroupPrefix,
                  label: userAreaGroup.area_group.name,
                  content: sortBy(userAreaGroup.roles, (role) =>
                    role.name.toLowerCase()
                  ).map((role) => <p key={`role-${role.id}`}>{role.name}</p>),
                };
              }
            );

            return (
              <CardSectionComponent
                name="groups-section"
                header={<Trans>Groups</Trans>}
                fields={fields}
              />
            );
          }}
        </FieldArray>
      </>
    );
  }

  return (
    <FieldArray name={`user_area_groups`}>
      {(userAreaGroupHelpers) => {
        const fields: CardSectionRow[] = usersGroups.map(
          (_userAreaGroup, idx) => {
            const userAreaGroupPrefix = `user_area_groups.${idx}`;
            return {
              name: userAreaGroupPrefix,
              content: (
                <>
                  <FormCardSection
                    className="user-area-groups-form-card-section"
                    name={`${userAreaGroupPrefix}.subsection`}
                    header={
                      <>
                        <Trans>Group {idx + 1}</Trans>
                        {usersGroups.length > 1 ? (
                          <Button
                            className="group-delete-button"
                            data-testid={`${userAreaGroupPrefix}.delete-button`}
                            onClick={() => {
                              userAreaGroupHelpers.remove(idx);
                            }}
                          >
                            <Trans>Delete</Trans>
                          </Button>
                        ) : null}
                      </>
                    }
                    fields={[
                      {
                        name: `${userAreaGroupPrefix}.area-group-label`,
                        label: <Trans>Group</Trans>,
                        content: (
                          <>
                            <SimpleSelectField
                              name={`${userAreaGroupPrefix}.area_group`}
                              options={areaGroupOptions}
                              placeholder={<Trans>Select a group</Trans>}
                            />
                            <FieldError
                              name={`${userAreaGroupPrefix}.area_group`}
                            />
                          </>
                        ),
                      },
                      {
                        name: `${userAreaGroupPrefix}.roles-label`,
                        label: <Trans>Roles</Trans>,
                        content: (
                          <>
                            <SimpleSelectField
                              name={`${userAreaGroupPrefix}.roles`}
                              options={roleOptions}
                              placeholder={
                                <Trans>Select one or more roles</Trans>
                              }
                              isMulti={true}
                            />
                            <FieldError name={`${userAreaGroupPrefix}.roles`} />
                          </>
                        ),
                      },
                    ]}
                  />
                </>
              ),
            };
          }
        );

        // Show the "Add group" button at the bottom.
        fields.push({
          name: `user-area-groups-add-group`,
          content: (
            <>
              <ButtonPrimary
                name="add-group-button"
                data-testid="add-group-button"
                iconType="icon-plus"
                onClick={() => {
                  userAreaGroupHelpers.push({
                    area_group: null,
                    roles: [],
                  });
                }}
              >
                <Trans>Add group</Trans>
              </ButtonPrimary>
            </>
          ),
        });

        return (
          <CardSectionComponent
            name="groups-section"
            header={<Trans>Groups</Trans>}
            fields={fields}
          />
        );
      }}
    </FieldArray>
  );
};

const NewUserSaveConfirmationButton = (
  formik: FormikProps<UserEditFormValues>
) => (
  <ButtonShowConfirmation
    name={`user-edit-submit-confirm-button`}
    data-testid={`user-edit-submit-confirm-button`}
    iconType="icon-save"
    disabled={formik.isSubmitting}
    destructive={false}
    onClick={async () => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length > 0) {
        // Cause the errors to be displayed (validateForm doesn't do this itself)
        // the errors will prevent the form actually submitting
        formik.submitForm();
        return false;
      }
      return true;
    }}
    onConfirm={async () => {
      formik.submitForm();
    }}
    content={
      <Trans>
        Are you sure you want to create this user account? An email will be sent
        to <strong>{formik.values.preferred_name}</strong>{' '}
        <strong>({formik.values.email})</strong> with instructions on how to
        create a password.
      </Trans>
    }
    okBtnText={<Trans>Yes, create account</Trans>}
  >
    <Trans>Save</Trans>
  </ButtonShowConfirmation>
);

export const UserEditView = (props: UserEditViewProps) => {
  const {
    user,
    isNewUser,
    isLoading,
    initialEditingState,
    errorMessage,
    areaGroups,
    areaGroupRoles,
    systemRoles,
    onSubmit,
    onCancel,
  } = props;

  const [isEditing, setIsEditing] = useState(initialEditingState);

  const isMounted = useIsMounted();

  const handleSubmit = useCallback(
    async (
      values: UserEditFormValues,
      formik: FormikHelpers<UserEditFormValues>
    ) => {
      try {
        await onSubmit(values);
        if (isMounted()) {
          setIsEditing(false);
        }
      } catch (e) {
        if (isMounted()) {
          showErrorsInFormik(formik, e, getExpectedFields(values));
          formik.setSubmitting(false);
        }
      }
    },
    [onSubmit, isMounted, setIsEditing]
  );

  const defaultUserAreaGroupField = React.useCallback(
    (
      label: React.ReactElement,
      name: string,
      userAreaGroupValues: UserAreaGroupValues[]
    ) => {
      const defaultOption: SimpleSelectOption<number> = {
        value: 0,
        label: <Trans>None</Trans>,
      };

      const currentUserAreaGroups = props.user?.user_area_groups ?? [];
      const filteredUserAreaGroups = currentUserAreaGroups.filter((uag) =>
        userAreaGroupValues.some((uagv) => uagv.id === uag.id)
      );

      const userAreaGroupOptions = [defaultOption].concat(
        sortBy(
          filteredUserAreaGroups.map((uag) => ({
            label: uag.area_group.name,
            value: uag.id,
          })),
          'label'
        )
      );

      const defaultUserAreaGroupValue =
        props.user?.profile.default_user_area_group ?? 0;
      const defaultUserAreaGroupName = userAreaGroupOptions.find(
        (option) => option.value === defaultUserAreaGroupValue
      )?.label;

      return {
        name,
        label,
        content: isEditing ? (
          <>
            <SimpleSelectField name={name} options={userAreaGroupOptions} />
            <FieldError name={name} />
          </>
        ) : (
          defaultUserAreaGroupName
        ),
      };
    },
    [isEditing, props.user]
  );

  const systemRoleOptions: SimpleSelectOption<number>[] = useMemo(
    () =>
      sortBy(systemRoles, (role) => role.name.toLowerCase()).map((role) => ({
        label: role.name,
        value: role.id,
      })),
    [systemRoles]
  );

  return (
    <PageStandard
      name="edit-user"
      header={<Trans>Maintain Users</Trans>}
      subHeader={
        isNewUser ? <Trans>Create a new user</Trans> : user?.profile.name
      }
    >
      <div className="page-content-header-with-back-button-wrapper">
        <BackButton defaultBackUrl="/users" />
        <div className="page-content-header">
          {isLoading ? (
            <Loading />
          ) : errorMessage ? (
            <AlertWarning>{errorMessage}</AlertWarning>
          ) : !user && !isNewUser ? (
            <AlertWarning>
              <Trans>User not found</Trans>
            </AlertWarning>
          ) : (
            <EditableCard<UserEditFormValues>
              name="general"
              header={
                <Trans>
                  {isNewUser ? (
                    <Trans>Create a new user</Trans>
                  ) : (
                    user?.profile.name
                  )}
                </Trans>
              }
              shouldDisable={false}
              isEditMode={isEditing}
              startEditing={() => setIsEditing(true)}
              stopEditing={isNewUser ? onCancel : () => setIsEditing(false)}
              onSubmit={handleSubmit}
              saveButton={isNewUser ? NewUserSaveConfirmationButton : undefined}
              initialValues={makeInitialValues(user)}
              validate={validateForm}
              render={({ CardSectionComponent, formik }) => {
                return (
                  <>
                    {formik.status}
                    <CardSectionComponent
                      name="edit-user-form-card"
                      header={<Trans>Details</Trans>}
                      fields={[
                        {
                          name: 'name',
                          label: <Trans>Name</Trans>,
                          content: isEditing ? (
                            <>
                              <Field type="text" name="name" />
                              <FieldError name="name" />
                            </>
                          ) : (
                            formik.values.name
                          ),
                        },
                        {
                          name: 'preferred_name',
                          label: <Trans>Preferred Name</Trans>,
                          content: isEditing ? (
                            <>
                              <Field type="text" name="preferred_name" />
                              <FieldError name="preferred_name" />
                            </>
                          ) : (
                            formik.values.preferred_name
                          ),
                        },
                        {
                          name: 'username',
                          label: <Trans>Username</Trans>,
                          content: isEditing ? (
                            isNewUser ? (
                              // Username is only an editable field when creating a new user
                              <>
                                <Field type="text" name="username" />
                                <FieldError name="username" />
                              </>
                            ) : (
                              <p className="non-editable-value">
                                {user?.username}
                              </p>
                            )
                          ) : (
                            formik.values.username
                          ),
                        },
                        {
                          name: 'email',
                          label: <Trans>Email</Trans>,
                          content: isEditing ? (
                            <>
                              <Field type="text" name="email" />
                              <FieldError name="email" />
                            </>
                          ) : (
                            formik.values.email
                          ),
                        },
                        {
                          name: 'mobile',
                          label: <Trans>Mobile</Trans>,
                          content: isEditing ? (
                            <>
                              <Field type="text" name="mobile" />
                              <FieldError name="mobile" />
                            </>
                          ) : (
                            formik.values.mobile
                          ),
                        },
                        {
                          name: 'is_active',
                          label: <Trans>Status</Trans>,
                          content: isEditing ? (
                            <>
                              <SimpleSelectField<boolean>
                                name="is_active"
                                options={[
                                  {
                                    label: <Trans>Active</Trans>,
                                    value: true,
                                  },
                                  {
                                    label: <Trans>Retired</Trans>,
                                    value: false,
                                  },
                                ]}
                              />
                              <FieldError name="is_active" />
                            </>
                          ) : formik.values.is_active ? (
                            <Trans>Active</Trans>
                          ) : (
                            <Trans>Retired</Trans>
                          ),
                        },
                      ]}
                    />
                    <CardSectionComponent
                      name="edit-user-details-form-card"
                      header={<Trans>Details</Trans>}
                      fields={[
                        defaultUserAreaGroupField(
                          <Trans>Default group</Trans>,
                          'default_user_area_group',
                          formik.values.user_area_groups
                        ),
                      ]}
                    />
                    <UserAreaGroupsSection
                      user={user}
                      isEditing={isEditing}
                      CardSectionComponent={CardSectionComponent}
                      usersGroups={formik.values.user_area_groups}
                      areaGroups={areaGroups}
                      roles={areaGroupRoles}
                    />
                    {isEditing ? (
                      <CardSectionComponent
                        name="system-roles"
                        header={<Trans>System roles</Trans>}
                        fields={[
                          {
                            name: 'system_role_description',
                            content: (
                              <AlertInfo>
                                System roles control access to DMS features that
                                are outside of the Area Group hierarchy (eg:
                                clients, checksheets, groups). If a system role
                                is assigned to this user, they will be able to
                                perform the corresponding actions in all of
                                their groups.
                              </AlertInfo>
                            ),
                          },
                          {
                            name: 'system_role_checkboxes',
                            content: (
                              <CheckBoxesField
                                name="system_roles"
                                options={systemRoleOptions}
                              />
                            ),
                          },
                        ]}
                      />
                    ) : user?.system_roles.length ? (
                      <CardSectionComponent
                        name="system-roles"
                        header={<Trans>System roles</Trans>}
                        fields={[
                          {
                            name: 'system_role_list',
                            content: sortBy(user.system_roles, (role) =>
                              role.name.toLowerCase()
                            ).map((role) => (
                              <p key={`role-${role.id}`}>{role.name}</p>
                            )),
                          },
                        ]}
                      />
                    ) : null}
                    <CardSectionComponent
                      name="batch-file-processing"
                      header={<Trans>Batch file processing</Trans>}
                      fields={[
                        {
                          name: 'batch-file-processing-field',
                          label: (
                            <Trans>
                              Batches from this user require confirmation field
                            </Trans>
                          ),
                          content: isEditing ? (
                            <>
                              <YesNoRadioField name="user_admin_settings.batches_require_confirmation" />
                              <FieldError name="user_admin_settings.batches_require_confirmation" />
                            </>
                          ) : formik.values.user_admin_settings
                              .batches_require_confirmation ? (
                            <Trans>Yes</Trans>
                          ) : (
                            <Trans>No</Trans>
                          ),
                        },
                      ]}
                    />
                  </>
                );
              }}
            />
          )}
        </div>
      </div>
    </PageStandard>
  );
};
