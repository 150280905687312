import React, { useMemo } from 'react';
import Button, { ButtonPrimary, ButtonSecondary } from '../button/button';
import { MessageDescriptor } from '@lingui/core';
import './InlineTextEdit.scss';
import { Field, Form, Formik } from 'formik';
import { FieldError } from '../form/errornotice/errornotice';
import { FormSection } from '../form/FormItem';
import { Trans } from '@lingui/macro';
import { showErrorsInFormik } from 'util/backendapi/error-formik';

type InlineTextEditFormValues = {
  content: string;
};

export function InlineTextEdit(props: {
  title: MessageDescriptor;
  content: string;
  isEditing: boolean;
  setIsEditing: (editing: boolean) => void;
  onSubmit: (content: string) => Promise<void>;
  onAfterSubmit?: (content: string) => void;
}) {
  const initialValues = useMemo(
    () => ({ content: props.content }),
    [props.content]
  );

  if (!props.isEditing) {
    return (
      <>
        <Button
          className="btn-link-panel inline-text-edit-button"
          iconOnly
          iconType="icon-edit"
          title={props.title}
          onClick={() => props.setIsEditing(true)}
        />
        <div className="inline-text-edit-content">{props.content}</div>
      </>
    );
  }

  return (
    <Formik<InlineTextEditFormValues>
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        try {
          await props.onSubmit(values.content);
          props.setIsEditing(false);
          if (props.onAfterSubmit) {
            props.onAfterSubmit(values.content);
          }
        } catch (e) {
          showErrorsInFormik(formik, e);
          formik.setSubmitting(false);
        }
      }}
    >
      {(formik) => (
        <Form>
          {formik.status}
          <FormSection>
            <Field component="textarea" name="content" />
            <FieldError name="content" />
            <div className="inline-text-edit-buttons">
              <ButtonSecondary onClick={() => props.setIsEditing(false)}>
                <Trans>Cancel</Trans>
              </ButtonSecondary>
              <ButtonPrimary type="submit">
                <Trans>Save</Trans>
              </ButtonPrimary>
            </div>
          </FormSection>
        </Form>
      )}
    </Formik>
  );
}
