import React from 'react';
import { Trans } from '@lingui/macro';

import ActionBlock from 'components/base/actionblock/actionblock';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ModalContent from 'components/base/modal/modalcontent';

import {
  DateFieldValue,
  DateField,
} from 'components/base/form/datefield/datefield';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { Formik, Form, Field, FormikErrors } from 'formik';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi } from 'util/backendapi/fetch';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { Model } from 'util/backendapi/models/api.interfaces';
import Loading from 'components/base/loading/loading';
import { convertDatetimeToDate } from 'util/dates';
import { ToggleField } from 'components/base/form/toggle-field/ToggleField';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from 'components/modules/async-menu/ObsPointMenu';

const OBS_POINT_DETAIL_FIELDS: Array<keyof Model.ObservationPointDecorated> = [
  'code',
  'earliest_reading',
  'latest_reading',
  'time_zone',
];
type MyObsPointDetails = Pick<
  Model.ObservationPointDecorated,
  'code' | 'earliest_reading' | 'latest_reading' | 'time_zone'
>;
type MyObsPointMenuItem = SimpleSelectOption<string> & {
  timeZone: string;
  earliestReadingDate: string | undefined;
  latestReadingDate: string | undefined;
};

export type QuickListReadingsMode = 'raw' | 'adjusted' | 'both';
const readingsModeOptions: SimpleSelectOption<QuickListReadingsMode>[] = [
  {
    label: <Trans>Adjusted</Trans>,
    value: 'adjusted',
  },
  {
    label: <Trans>Raw</Trans>,
    value: 'raw',
  },
  {
    label: <Trans>Both</Trans>,
    value: 'both',
  },
];

export interface QuickListSettingsFormValue {
  observationPoint: string;
  observationPointDetails: MyObsPointMenuItem[];
  startDate: DateFieldValue;
  endDate: DateFieldValue;
  numberOfReadings: number | '';
  readingsMode: QuickListReadingsMode;
}

export interface QuickListSettingsViewProps {
  initialValues: QuickListSettingsFormValue;
  isLoading: boolean;
  onSubmit: (values: QuickListSettingsFormValue) => void;
}

function validateForm(values: QuickListSettingsFormValue) {
  const errors: FormikErrors<QuickListSettingsFormValue> = {};
  if (
    values.numberOfReadings !== '' &&
    (!Number.isInteger(+values.numberOfReadings) ||
      +values.numberOfReadings < 1)
  ) {
    errors.numberOfReadings = (
      <Trans>Number of readings must be a positive integer</Trans>
    ) as any;
  }
  if (
    !values.numberOfReadings &&
    values.startDate &&
    values.endDate &&
    values.startDate > values.endDate
  ) {
    errors.startDate = (
      <Trans>Start date can not be later than end date</Trans>
    ) as any;
  }
  return errors;
}

function makeMenuOption(op: MyObsPointDetails) {
  return {
    value: op.code,
    label: op.code,
    timeZone: op.time_zone.name,
    earliestReadingDate: op.earliest_reading
      ? convertDatetimeToDate(
          op.earliest_reading.reading_datetime,
          op.time_zone.name
        )
      : undefined,
    latestReadingDate: op.latest_reading
      ? convertDatetimeToDate(
          op.latest_reading.reading_datetime,
          op.time_zone.name
        )
      : undefined,
  };
}

export const QuickListSettingsView: React.FunctionComponent<QuickListSettingsViewProps> =
  function (props) {
    return (
      <ModalContent
        className="quicklistsettings-wrapper"
        header={<Trans>List settings</Trans>}
      >
        {props.isLoading ? (
          <Loading />
        ) : (
          <Formik<QuickListSettingsFormValue>
            initialValues={props.initialValues}
            onSubmit={props.onSubmit}
            validate={validateForm}
          >
            {(formik) => (
              <Form>
                <FormSection label={<Trans>Observation point</Trans>}>
                  <AsyncSimpleSelectField<string, false, MyObsPointMenuItem>
                    id="list-plot-settings-observation-points"
                    autoFocus={true}
                    name="observationPoint"
                    isMulti={false}
                    detailsName="observationPointDetails"
                    loadDefaults={(initialCode) =>
                      initialCode
                        ? getApi('/observation-points/', {
                            code: initialCode,
                            fields: OBS_POINT_DETAIL_FIELDS,
                          }).then((ops) => ops.map(makeMenuOption))
                        : Promise.resolve([])
                    }
                    onSearch={(searchText) =>
                      getApi('/observation-points/', {
                        code__icontains: searchText,
                        fields: OBS_POINT_DETAIL_FIELDS,
                        limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
                      }).then((ops) => ops.map(makeMenuOption))
                    }
                  />
                  <FieldError name="opfo" />
                </FormSection>
                <FormSection label={<Trans>Time period</Trans>}>
                  <div className="form-group form-group-panel-inline">
                    <label htmlFor="quicklistsettings-startdate">
                      <Trans>Start date</Trans>
                    </label>
                    <DateField
                      id="quicklistsettings-startdate"
                      // Disable start/end dates when "Latest readings" selected
                      disabled={formik.values.numberOfReadings !== ''}
                      name="startDate"
                      placeholder={
                        formik.values.observationPointDetails[0] &&
                        formik.values.observationPointDetails[0]
                          .earliestReadingDate
                      }
                    />
                  </div>
                  <div className="form-group form-group-panel-inline">
                    <label htmlFor="quicklistsettings-enddate">
                      <Trans>End date</Trans>
                    </label>
                    <DateField
                      id="quicklistsettings-enddate"
                      // Disable start/end dates when "Latest readings" selected
                      disabled={formik.values.numberOfReadings !== ''}
                      name="endDate"
                      placeholder={
                        formik.values.observationPointDetails[0] &&
                        formik.values.observationPointDetails[0]
                          .latestReadingDate
                      }
                    />
                  </div>
                  <FieldError name="startDate" />
                  <FieldError name="endDate" />
                </FormSection>
                <fieldset>
                  <legend>
                    <Trans>Latest readings</Trans>
                  </legend>
                  <label htmlFor="quicklistsettings-numberOfReadings">
                    <Trans>Number of readings</Trans>
                  </label>
                  <div className="columns-fluid">
                    <Field
                      name="numberOfReadings"
                      id="quicklistsettings-numberOfReadings"
                      type="number"
                      min="1"
                    />
                    {formik.values.numberOfReadings && (
                      <Button
                        onClick={() =>
                          formik.setFieldValue('numberOfReadings', '')
                        }
                      >
                        <Trans>Clear</Trans>
                      </Button>
                    )}
                  </div>
                  <FieldError name="numberOfReadings" />
                </fieldset>
                <FormSection label={<Trans>Display options</Trans>}>
                  <FormItem
                    label={<Trans>Reading type</Trans>}
                    fieldId="readingsMode"
                  >
                    <div className="columns-fluid">
                      <ToggleField
                        name="readingsMode"
                        id="readingsMode"
                        options={readingsModeOptions}
                      />
                    </div>
                    <FieldError name="readingsMode" />
                  </FormItem>
                </FormSection>
                <ActionBlock>
                  <ButtonHideModal />
                  <ButtonPrimary
                    id="quicklistsettings-apply"
                    type="submit"
                    iconType="icon-update"
                  >
                    <Trans>Apply settings</Trans>
                  </ButtonPrimary>
                </ActionBlock>
              </Form>
            )}
          </Formik>
        )}
      </ModalContent>
    );
  };
