import React, { useCallback, useEffect } from 'react';
import {
  Switch,
  Route,
  RouteComponentProps,
  Redirect,
  matchPath,
} from 'react-router';
import { connect, ResolveThunks } from 'react-redux';
import MaintainObservationPointView from './maintobspointview';
import SetupObservationPointView from './setupobspointview';
import { selectMenuOptions } from './maintobspointselectors';
import { parseStringParamFromRouterProps } from 'util/routing';
import { fetchEntityList, EntityTypes } from 'ducks/entities';
import { FullState } from 'main/reducers';
import {
  closeSectionForEditing,
  fetchObservationPoint,
  openSectionForEditing,
  updateObservationPoint,
  unmountMaintainObservationPointScreen,
  ObsPointFormSection,
} from 'ducks/obsPoint/detail/main';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { ObsPointViewProps } from './maintobspoint.types';
import { selectHasPermission } from 'util/user';

type OwnProps = RouteComponentProps<{
  obsPointCode: string;
}>;

type StateProps = Omit<
  ObsPointViewProps,
  'startEditing' | 'stopEditing' | 'onSubmit' | 'observationPointCode'
>;

const mapDispatchToProps = {
  stopEditing: closeSectionForEditing,
  fetchObservationPoint,
  startEditing: openSectionForEditing,
  fetchEntityList,
  updateObservationPoint,
  unmountMaintainObservationPointScreen,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

function mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
  return {
    observationPoint: state.obsPoint.detail.main.observationPoint,
    isLoading: state.obsPoint.detail.main.isLoading,
    errorMessage: state.obsPoint.detail.main.errorMessage,
    editingSection: state.obsPoint.detail.main.editingSection,
    editingSectionIsSubmitting:
      state.obsPoint.detail.main.editingSectionIsSubmitting,
    menuOptions: selectMenuOptions(state),
    hasViewCommentPermission: selectHasPermission(
      state,
      Enum.User_PERMISSION.can_view_observation_point_comments
    ),
    hasCreateReadingPermission: selectHasPermission(
      state,
      Enum.User_PERMISSION.can_create_manual_readings
    ),
    hasCreateObservationPointPermission: selectHasPermission(
      state,
      Enum.User_PERMISSION.can_create_observation_points
    ),
  };
}

function InnerObsPointDetailScreen(props: Props) {
  const {
    observationPoint,
    updateObservationPoint,
    fetchObservationPoint,
    fetchEntityList,
    unmountMaintainObservationPointScreen,
    match,
    location,
  } = props;

  const observationPointCode = parseStringParamFromRouterProps(
    props,
    'obsPointCode'
  );

  /**
   * Generic "handle submit" function, for the parts of the form that only
   * update the observation point itself (via `/observation-points/`).
   *
   * "Specialized" sections that edit other entities, like aliases, formulas,
   * data logger, will need to use their own specialized submit functions,
   * and they'll need to make sure to call `fetchObservationPoint()` afterwards
   * if the obs point and other sections should be refreshed.
   */
  const handleSubmit = useCallback(
    async (
      section: ObsPointFormSection,
      values: ForPatch<Model.ObservationPoint>
    ) => {
      if (!observationPoint) {
        return;
      }
      await updateObservationPoint(section, observationPoint.id, values);
    },
    [observationPoint, updateObservationPoint]
  );

  useEffect(() => {
    if (
      observationPointCode &&
      (!observationPoint || observationPoint.code !== observationPointCode)
    ) {
      fetchObservationPoint(observationPointCode);
      fetchEntityList(EntityTypes.AREA);
      fetchEntityList(EntityTypes.FORMULA);
      fetchEntityList(EntityTypes.INSTRUMENT_TYPE);
      fetchEntityList(EntityTypes.OBSERVATION_POINT_CLASSIFICATION);
      fetchEntityList(EntityTypes.OBSERVATION_POINT_GRID_REFERENCE);
      fetchEntityList(EntityTypes.OBSERVATION_POINT_RELIABILITY);
      fetchEntityList(EntityTypes.OBSERVATION_POINT_TUBING_TYPE);
      fetchEntityList(EntityTypes.DATA_LOGGER);
    }
  }, [
    observationPoint,
    observationPointCode,
    fetchObservationPoint,
    fetchEntityList,
  ]);

  useEffect(() => {
    return () => {
      unmountMaintainObservationPointScreen();
    };
  }, [unmountMaintainObservationPointScreen]);

  const shouldRedirectToMaintScreen =
    observationPoint &&
    observationPoint.setup_complete === true &&
    matchPath(match.url, { path: '/observation-point-setup/:obsPointCode' });

  return (
    <Switch>
      {shouldRedirectToMaintScreen && (
        <Redirect
          to={`/observation-point/${match.params.obsPointCode}${location.search}`}
        />
      )}
      <Route
        path="/observation-point-setup"
        render={() => (
          <SetupObservationPointView
            {...props}
            onSubmit={handleSubmit}
            observationPointCode={observationPointCode}
          />
        )}
      />
      <Route
        render={() => (
          <MaintainObservationPointView
            {...props}
            onSubmit={handleSubmit}
            observationPointCode={observationPointCode}
          />
        )}
      />
    </Switch>
  );
}

export const ObsPointDetailScreen = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  mapStateToProps,
  mapDispatchToProps
)(InnerObsPointDetailScreen);
