import uniq from 'lodash/uniq';
import isEqual from 'lodash/isEqual';
import { useState, useRef, useEffect } from 'react';
import { useIsMounted, usePrevious } from 'util/hooks';
import { getApi } from 'util/backendapi/fetch';
import {
  convertDatetimeToDate,
  formatDateForDisplay,
  formatDatetimeForDisplay,
} from 'util/dates';

/**
 * A Hook to get all uniq reading datetimes from a list of Observation Point Ids.
 * Can be extracted out to be reuseable if needed.
 */
export function useSurveyDatetimes(obsIds: number[]): [string[], boolean] {
  const isMounted = useIsMounted();
  const [datetimes, setDatetimes] = useState<string[]>([]);
  const [isLoadingSurveyDatetimes, setIsLoadingSurveyDatetimes] =
    useState(false);

  const prevObsIds = usePrevious(obsIds);

  // caching ref to store all the fetched readings
  const $datetimesByObsPoint = useRef<Record<number, string[]>>({});

  // effect to fetch the readings of selected observation point
  // in order to get the datetime values
  useEffect(() => {
    async function fetchReadingDatetimes() {
      setIsLoadingSurveyDatetimes(true);

      try {
        for (const obsId of obsIds) {
          if (!$datetimesByObsPoint.current[obsId]) {
            const readings = await getApi('/readings/simple/', {
              effective: true,
              observation_point: obsId,
              limit: 1000,
            });

            // eslint-disable-next-line require-atomic-updates
            $datetimesByObsPoint.current[obsId] = uniq(
              readings.map((r) => r.reading_datetime)
            );
          }
        }

        setDatetimes(
          uniq(obsIds.flatMap((id) => $datetimesByObsPoint.current[id])).sort()
        );

        if (!isMounted()) {
          return;
        }
        setIsLoadingSurveyDatetimes(false);
      } catch (e) {
        if (!isMounted()) {
          return;
        }
        setIsLoadingSurveyDatetimes(false);
      }
    }

    if (!isEqual(obsIds, prevObsIds)) {
      fetchReadingDatetimes();
    }
  }, [isMounted, obsIds, prevObsIds]);

  return [datetimes, isLoadingSurveyDatetimes];
}

export function selectDatetimeOptions(
  datetimes: string[],
  timeZone: null | string
) {
  return datetimes
    .map((surveyDatetime) => ({
      surveyDatetime,
      surveyDate: convertDatetimeToDate(surveyDatetime, timeZone),
    }))
    .map((survey, idx, surveyList) => {
      // Show the full reading datetime if it's on the same date as
      // the adjacent reading datetime.
      // Otherwise, just show the date.
      const prevSurvey = surveyList[idx - 1];
      const nextSurvey = surveyList[idx + 1];
      const isDateUnique =
        !(prevSurvey && prevSurvey.surveyDate === survey.surveyDate) &&
        !(nextSurvey && nextSurvey.surveyDate === survey.surveyDate);
      return {
        value: survey.surveyDatetime,
        label: isDateUnique
          ? formatDateForDisplay(survey.surveyDate)
          : formatDatetimeForDisplay(survey.surveyDatetime, timeZone),
      };
    });
}
