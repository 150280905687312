import { AlarmReport_STATUS } from 'util/backendapi/types/Enum';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { transEnum } from 'util/i18n-utils';

export function makeStatusChangeComment(
  i18n: I18n,
  status: AlarmReport_STATUS,
  comment: string
): string {
  const statusString = i18n._(transEnum('AlarmReport_STATUS', status));
  return i18n._(t`Status changed to: ${statusString}.`) + '\n' + comment;
}
