import { ReduxStateProps } from './addobspointpanelview';
import { connect, ResolveThunks } from 'react-redux';
import {
  addRouteMarchObservationPoint,
  ActionTypes,
} from '../../../ducks/routemarchobspoints';
import {
  RouteMarchAddObservationPointPanelView,
  FormikValues,
} from './addobspointpanelview';
import React from 'react';
import { FormikHelpers } from 'formik';
import { Model } from '../../../util/backendapi/models/api.interfaces';
import { Trans } from '@lingui/react';

const mapDispatchToProps = {
  addRouteMarchObservationPoint,
} as const;
type ReduxDispatchProps = typeof mapDispatchToProps;

type Props = ResolveThunks<ReduxDispatchProps> &
  ReduxStateProps & { hideModal: () => any };

interface OwnProps {
  routeMarch: Model.RouteMarch | null;
  routeMarchObservationPoints: Model.RouteMarchObservationPointDecorated[];
}

class _RouteMarchAddObservationPointPanel extends React.Component<Props> {
  static mapStateToProps(state: any): ReduxStateProps {
    const r: ReduxStateProps = {
      ...state.routeMarchObservationPoint.observationPoints,
    };
    return r;
  }

  validate = (values: FormikValues) => {
    if (values.observationPoint === 0) {
      return {
        observationPoint: <Trans>Please select an observation point</Trans>,
      };
    }
  };

  submit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    actions.setSubmitting(true);
    let position = 1;
    if (this.props.routeMarchObservationPoints.length > 0) {
      position =
        this.props.routeMarchObservationPoints[
          this.props.routeMarchObservationPoints.length - 1
        ].position + 1;
    }
    const result: any = await this.props.addRouteMarchObservationPoint(
      this.props.routeMarch.id,
      position,
      values.observationPoint
    );
    if (
      result.type === ActionTypes.ADD_ROUTE_MARCH_OBSERVATION_POINT_RESPONSE
    ) {
      actions.setSubmitting(false);
      this.props.hideModal();
    }
  };

  render() {
    return (
      <RouteMarchAddObservationPointPanelView
        {...this.props}
        onSubmit={this.submit}
        onValidate={this.validate}
      />
    );
  }
}

const RouteMarchAddObservationPointPanel = connect<
  ReduxStateProps,
  ReduxDispatchProps,
  OwnProps
>(
  _RouteMarchAddObservationPointPanel.mapStateToProps,
  mapDispatchToProps
)(_RouteMarchAddObservationPointPanel);

export default RouteMarchAddObservationPointPanel;
