import React from 'react';
import { I18n } from '@lingui/react';
import { Trans, t } from '@lingui/macro';
import { AlertDanger } from '../../components/base/alert/alert';
import {
  ButtonPrimary,
  ButtonSecondary,
} from '../../components/base/button/button';
import { Icon } from 'components/base/icon/icon';
import './loginview.scss';
import dsiLogo from 'assets/images/dsi-logo.svg';
import featureImageSmall from 'assets/images/log-in-feature-small.png';
import { Formik, Form, Field } from 'formik';
import { FormItem } from 'components/base/form/FormItem';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

type LoginViewProps = {
  isLoggedIn: boolean;
  isFetching?: boolean;
  redirectOnSuccess: string;
  showForgotPassword: boolean;
} & LoginFormProps &
  ForgotPasswordFormProps;

type LoginFormProps = {
  submitLogin: (username: string, password: string) => void;
  statusMsg: string | null;
  setShowForgotPasswordState: (showPasswordState: boolean) => void;
};

interface ForgotPasswordFormProps {
  setShowForgotPasswordState: (showPasswordState: boolean) => void;
  handlePasswordReset: (emailAddress: string) => void;
}

interface LoginFormValues {
  username: string;
  password: string;
}

interface PasswordResetFormValues {
  email_address: string;
}

export const LoginForm: React.FunctionComponent<
  LoginFormProps & {
    i18n: any;
  }
> = function (props) {
  return (
    <div className="login-form-wrapper">
      <div className="login-form">
        <h2>Log in</h2>
        <Formik<LoginFormValues>
          initialValues={{ username: '', password: '' }}
          onSubmit={(values, formik) => {
            props.submitLogin(values.username, values.password);
            formik.setSubmitting(false);
          }}
        >
          {(formik) => (
            <Form>
              <FormItem
                id="username"
                label={
                  <Icon type="icon-user" title={props.i18n._(t`Username`)} />
                }
              >
                <Field
                  name="username"
                  type="text"
                  placeholder={props.i18n._('Username')}
                  disabled={formik.isSubmitting}
                  required={true}
                />
              </FormItem>
              <FormItem
                id="password"
                label={
                  <Icon type="icon-lock" title={props.i18n._(t`Password`)} />
                }
              >
                <Field
                  name="password"
                  type="password"
                  placeholder={props.i18n._('Password')}
                  disabled={formik.isSubmitting}
                  required={true}
                />
              </FormItem>
              {props.statusMsg ? (
                <AlertDanger>{props.statusMsg}</AlertDanger>
              ) : null}
              <ButtonPrimary
                type="submit"
                iconType="icon-log-in"
                disabled={formik.isSubmitting}
              >
                <Trans>Log in</Trans>
              </ButtonPrimary>
              <Link
                to="#"
                onClick={() => props.setShowForgotPasswordState(true)}
                className="forgot-password"
              >
                <span>
                  <Trans>Forgot password?</Trans>
                </span>
              </Link>
            </Form>
          )}
        </Formik>
      </div>
      <div className="login-feature-image">
        <img
          src={featureImageSmall}
          alt={props.i18n._(t`Mangahao Dam number 1`)}
        />
      </div>
    </div>
  );
};

export const ForgotPasswordForm: React.FunctionComponent<ForgotPasswordFormProps> =
  function (props) {
    return (
      <div className="login-form-wrapper">
        <div className="forgot-password-form">
          <h2>Reset password</h2>{' '}
          <Formik<PasswordResetFormValues>
            initialValues={{ email_address: '' }}
            onSubmit={async (values) => {
              try {
                await props.handlePasswordReset(values.email_address);
              } finally {
                props.setShowForgotPasswordState(false);
              }
            }}
          >
            {(formik) => (
              <Form>
                <span>
                  <Trans>
                    Please enter your email address and instructions on how to
                    reset your password will be emailed to you.
                  </Trans>
                </span>
                {formik.status}
                <FormItem id="email_address" label={<Trans>Email</Trans>}>
                  <Field name="email_address" type="email" required={true} />
                </FormItem>
                <ButtonPrimary type="submit" disabled={formik.isSubmitting}>
                  <Trans>Send reset instructions</Trans>
                </ButtonPrimary>
                <ButtonSecondary
                  onClick={() => props.setShowForgotPasswordState(false)}
                >
                  <Trans>Cancel</Trans>
                </ButtonSecondary>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  };

export const LoginView: React.FunctionComponent<LoginViewProps> = function (
  props
) {
  if (props.isLoggedIn) {
    return <Redirect push to={props.redirectOnSuccess} />;
  }
  return (
    <div className="page login-page">
      <I18n>
        {({ i18n }) => (
          <>
            <div className="login-page-main">
              <h1>
                <Trans>Dam Monitoring System</Trans>
              </h1>
              {props.showForgotPassword ? (
                <ForgotPasswordForm
                  setShowForgotPasswordState={props.setShowForgotPasswordState}
                  handlePasswordReset={props.handlePasswordReset}
                />
              ) : (
                <LoginForm
                  submitLogin={props.submitLogin}
                  i18n={i18n}
                  setShowForgotPasswordState={props.setShowForgotPasswordState}
                  statusMsg={props.statusMsg}
                />
              )}
            </div>
            <footer>
              <div className="powered-by">
                <span>
                  <Trans>Powered by</Trans>
                </span>
                <img src={dsiLogo} alt={i18n._(t`Dam Safety Intelligence`)} />
              </div>
            </footer>
          </>
        )}
      </I18n>
    </div>
  );
};
