import React from 'react';
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  FormikErrors,
  FormikProps,
  FormikHelpers,
} from 'formik';
import set from 'lodash/set';
import sortBy from 'lodash/sortBy';
import ActionBlock from '../../components/base/actionblock/actionblock';
import ErrorNotice from '../../components/base/form/errornotice/errornotice';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import { SimpleSelectField } from '../../components/base/form/simpleselect/simpleselectfield';
import { Enum, Model } from '../../util/backendapi/models/api.interfaces';
import { Trans } from '@lingui/macro';
import { I18n } from '@lingui/react';
import Button, { ButtonPrimary } from '../../components/base/button/button';
import ModalContent from '../../components/base/modal/modalcontent';
import Loading from 'components/base/loading/loading';
import { validateNumber } from 'util/validation';
import { rangeInclusive } from 'util/misc';
import { CheckboxToggleField } from 'components/base/form/checkbox-toggle-field/CheckboxToggleField';
import './editreadingview.scss';

export interface EditReadingFormValues {
  reading_datetime: string;
  reading_comment: string;
  raw_reading_entries: (Model.RawReadingEntry & { isNaN: boolean })[];
  reason_for_change: string;
}
type MyFormikErrors = FormikErrors<EditReadingFormValues>;
type MyFormikProps = FormikProps<EditReadingFormValues>;
type MyFormikHelpers = FormikHelpers<EditReadingFormValues>;

export interface EditReadingViewProps {
  loading: boolean;
  reasonForChangeOptions: { value: string; label: string }[];
  reading: Model.Reading;
  numReadingPositions: number;
  onSubmit: (values: EditReadingFormValues, formik: MyFormikHelpers) => void;
  onCancel: () => void;
}

interface State {}

const validate = (values: EditReadingFormValues) => {
  const errors: MyFormikErrors = {};

  values.raw_reading_entries.forEach((readingEntry, idx) => {
    if (
      readingEntry.value === null ||
      (!readingEntry.isNaN && !validateNumber(readingEntry.value))
    ) {
      set(
        errors,
        ['raw_reading_entries', idx, 'value'],
        <Trans>Value must be a number.</Trans>
      );
    }
  });

  const hasNaNReading = values.raw_reading_entries.some(
    (readingEntry) => readingEntry.isNaN
  );

  if (hasNaNReading && values.reading_comment === '') {
    errors.reading_comment = (<Trans>A comment is required.</Trans>) as any;
  }

  // NOTES: reading.reading_datetime has value = false when the date is invalid
  if (values.reading_datetime === '') {
    errors.reading_datetime = (
      <Trans>Reading date time is required.</Trans>
    ) as any;
  }

  if (!values.reason_for_change) {
    errors.reason_for_change = (
      <Trans>Reason for change is required.</Trans>
    ) as any;
  }

  return errors;
};

class EditReadingView extends React.Component<EditReadingViewProps, State> {
  constructor(props: EditReadingViewProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading, numReadingPositions, reading } = this.props;

    let body: React.ReactNode;

    if (loading) {
      body = <Loading />;
    } else {
      const entries = sortBy(reading.raw_reading_entries, 'position');

      let rawReadingEntries = entries.map((rawReadingEntry) => {
        return {
          ...rawReadingEntry,
          isNaN: false,
        };
      });

      // If `readingPositions` is empty (eg. no formula applied for the reading_date),
      // we default to 1 reading position
      const expectedRawReadingCount = numReadingPositions || 1;

      // Add a raw reading entry for any missing raw reading entries
      if (rawReadingEntries.length < expectedRawReadingCount) {
        rangeInclusive(1, expectedRawReadingCount).forEach((position) => {
          if (
            !rawReadingEntries.some(
              (rawReadingEntry) => rawReadingEntry.position === position
            )
          ) {
            rawReadingEntries.push({
              position,
              value: '',
              isNaN: false,
            });
          }
        });
      }

      // Set isNaN for on entries with NaN reading errors
      reading.errors.forEach((readingError) => {
        if (readingError.error_number === Enum.ReadingError.NOT_A_NUMBER) {
          const matchResult = readingError.message.match(/position '(\d+)'/);
          if (matchResult) {
            const position = +matchResult[1];

            let readingEntry = rawReadingEntries.find(
              (rawReadingEntry) => rawReadingEntry.position === position
            );
            if (readingEntry) {
              readingEntry.isNaN = true;
            }
          }
        }
      });

      rawReadingEntries = sortBy(rawReadingEntries, 'position');

      const initialValues: EditReadingFormValues = {
        reading_datetime: reading.reading_datetime,
        reading_comment:
          reading.inspector_comment !== null
            ? reading.inspector_comment.content
            : '',
        raw_reading_entries: rawReadingEntries,
        reason_for_change: '',
      };

      body = (
        <Formik<EditReadingFormValues>
          initialValues={initialValues!}
          onSubmit={this.props.onSubmit}
          validate={validate}
        >
          {(formikBag: MyFormikProps) => (
            <Form>
              {formikBag.status}
              <fieldset>
                <div className="form-group">
                  <label htmlFor="observation-point-code">
                    <Trans>Observation point</Trans>
                  </label>
                  <div>{reading.observation_point.code}</div>
                </div>
                <div className="form-group">
                  <label htmlFor="raw-reading-datetime">
                    <Trans>Reading date and time</Trans>
                  </label>
                  <DatetimeField
                    name="reading_datetime"
                    id="raw-reading-datetime"
                    timeZone={reading.time_zone.name}
                  />
                  <ErrorMessage
                    name="reading_datetime"
                    component={ErrorNotice}
                  />
                </div>
                {
                  // Display the entries sorted by position number.
                  formikBag.values.raw_reading_entries.map(
                    ({ position }, idx) => {
                      const isNaN =
                        formikBag.values.raw_reading_entries[idx].isNaN;

                      return (
                        <div
                          className="form-group edit-raw-reading"
                          key={position}
                        >
                          <label htmlFor="raw-reading-value">
                            <Trans>Raw reading {position}</Trans>
                          </label>
                          {isNaN ? (
                            <input
                              type="text"
                              className="raw-reading-value"
                              id={`raw-reading-value-disabled-${idx}`}
                              disabled={true}
                            />
                          ) : (
                            <Field
                              className="raw-reading-value"
                              name={`raw_reading_entries[${idx}].value`}
                              id={`raw-reading-value-${idx}`}
                            />
                          )}
                          <CheckboxToggleField
                            className="nan-reading-checkbox"
                            label={<Trans>NaN</Trans>}
                            name={`raw_reading_entries[${idx}].isNaN`}
                          />
                          <ErrorMessage
                            name={`raw_reading_entries[${idx}].value`}
                            component={ErrorNotice}
                          />
                        </div>
                      );
                    }
                  )
                }
                <div className="form-group">
                  <label htmlFor="reading-comment">
                    <Trans>Inspector comment</Trans>
                  </label>
                  <Field
                    name="reading_comment"
                    id="reading-comment"
                    type="textarea"
                  />
                  <ErrorMessage
                    name="reading_comment"
                    component={ErrorNotice}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="reason-for-change">
                    <Trans>Reason for change</Trans>
                  </label>
                  <I18n>
                    {({ i18n }) => {
                      const reasonForChangeOptionsTranslated =
                        this.props.reasonForChangeOptions.map((opt: any) => ({
                          ...opt,
                          label: i18n._(opt.label),
                        }));

                      return (
                        <SimpleSelectField
                          name="reason_for_change"
                          id="reason-for-change"
                          options={reasonForChangeOptionsTranslated}
                          placeholder={<Trans>Please select a reason</Trans>}
                        />
                      );
                    }}
                  </I18n>
                  <ErrorMessage
                    name="reason_for_change"
                    component={ErrorNotice}
                  />
                </div>
              </fieldset>
              <ActionBlock>
                <Button onClick={this.props.onCancel}>
                  <Trans>Cancel</Trans>
                </Button>
                <ButtonPrimary
                  id="maintgroup-change"
                  type="submit"
                  disabled={formikBag.isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Save & reprocess</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      );
    }

    return (
      <ModalContent header={<Trans>Edit reading</Trans>}>{body}</ModalContent>
    );
  }
}

export default EditReadingView;
