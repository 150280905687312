import { match, RouteComponentProps } from 'react-router';
import * as H from 'history';
import lodashGet from 'lodash/get';

/**
 * Because React-Router focuses entirely on the "path" part of the URL, and not
 * the "query" part, their RouteComponentProps type has no type checks for the
 * URL's expected query parameters.
 *
 * RCPWithQueryParams extends RouteComponentProps to also type-check the query
 * params.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface RCPWithQueryParams<QueryParams = {}, PathParams = {}>
  extends RouteComponentProps<PathParams> {}

// The portion of the React-Route route component/child props that we need
// for dealing with path params. (Making this into a separate type makes our
// functions able to accept either a RouteComponentProps or a RouteChildrenProps,
// which inconveniently are not identical)
interface RoutePropsPathParams<PathParams> {
  match: Pick<match<PathParams>, 'params'> | null;
}

// The portion of the React-Router route component/child props that we need
// for dealing with query params.
type RoutePropsQueryParams<QueryParams> = Pick<
  RCPWithQueryParams<QueryParams>,
  'location'
>;

/**
 * The types of data we can accept in `setQueryParams()`
 */
export type QueryParamValue =
  | string
  | string[]
  | number
  | number[]
  | false
  | null
  | undefined;
export type QueryParams = {
  [K in string]?: QueryParamValue;
};

// A type to deal with incomplete type parameters. Use the key of T if T is
// something that has any keys, otherwise just take a string. This allows
// the code to correctly highlight incorrectly named path params if you
// use RouteComponentProps with path params; but to accept any string if
// you don't (in which case it defaults to {})
type KeyIfAvailable<T> = StringKeyOf<T> extends never ? string : StringKeyOf<T>;

/**
 * A helper function to safely coerce a numeric value from a React-Router
 * parameterized path.
 *
 * TODO: This would be more gracefully handled with a higher-order-component,
 * that provides the numeric value of the path param as a prop to the wrapped
 * component directly.
 *
 * @export
 * @param props The "router props" provided by React Router
 * @param paramName The name of the param field in the path
 * @param notFoundValue A value to return if the field is not found or is invalid
 */
export function parseNumberParamFromRouterProps<
  PathParams extends match['params'] = {},
  NFV = 0
>(
  props: RoutePropsPathParams<PathParams>,
  paramName: KeyIfAvailable<PathParams>,
  notFoundValue: NFV = 0 as any as NFV
): number | NFV {
  let rawValue = lodashGet(props, `match.params[${paramName}]`, null);
  if (rawValue === null) {
    return notFoundValue;
  }

  let cleanedValue = +rawValue;
  if (Number.isSafeInteger(cleanedValue) && cleanedValue > 0) {
    return cleanedValue;
  } else {
    return notFoundValue;
  }
}

/**
 * A helper function to safely coerce a string value from a React-Router
 * parameterized path.
 *
 * TODO: This would be more gracefully handled with a higher-order component,
 * that provides the numeric value of the path param as a prop to the wrapped
 * component directly.
 *
 * @export
 * @param props The "router props" provided by React Router
 * @param paramName The name of the param field in the path
 * @param notFoundValue A value to return if the field is not found or is invalid
 */
export function parseStringParamFromRouterProps<
  PathParams extends match['params'] = {},
  NFV = ''
>(
  props: RoutePropsPathParams<PathParams>,
  paramName: KeyIfAvailable<PathParams>,
  notFoundValue: NFV = '' as any as NFV
): string | NFV {
  // @ts-ignore
  if (props.match && props.match.params && props.match.params[paramName]) {
    return props.match.params[paramName as keyof PathParams] as any;
  } else {
    return notFoundValue;
  }
}

/**
 * Parse a query parameter as a string
 *
 * @param props The "router props" provided by React Router
 * @param name The name of the query param
 * @param notFoundValue A value to return if the field is not found or is invalid
 */
export function parseQueryParamFromRouterProps<
  TQueryParams extends QueryParams = {},
  NFV = ''
>(
  props: RoutePropsQueryParams<TQueryParams>,
  name: KeyIfAvailable<TQueryParams>,
  notFoundValue: NFV = '' as unknown as NFV
): string | NFV {
  const query = new URLSearchParams(props.location.search);
  const value = query.get(name as string);
  if (value === null) {
    return notFoundValue;
  } else {
    return value;
  }
}

/**
 * Parse a query parameter as number
 *
 * @param props The "router props" provided by React Router
 * @param name The name of the query param
 * @param notFoundValue A value to return if the field is not found or is invalid
 */
export function parseNumberQueryParamFromRouterProps<
  TQueryParams extends QueryParams = {},
  NFV = 0
>(
  props: RoutePropsQueryParams<TQueryParams>,
  name: KeyIfAvailable<TQueryParams>,
  notFoundValue: NFV = 0 as unknown as NFV
): number | NFV {
  const value = parseInt(parseQueryParamFromRouterProps(props, name) || '', 10);
  return Number.isSafeInteger(value) ? value : notFoundValue;
}

/**
 * Parse a query parameter as boolean
 *
 * @param props The "router props" provided by React Router
 * @param name The name of the query param
 * @param notFoundValue A value to return if the field is not found or is invalid
 */
export function parseBooleanQueryParamFromRouterProps<
  TQueryParams extends QueryParams = {}
>(
  props: RoutePropsQueryParams<TQueryParams>,
  name: KeyIfAvailable<TQueryParams>,
  notFoundValue: boolean = false
): boolean {
  const value = parseQueryParamFromRouterProps(props, name);
  return value === 'true' ? true : value === 'false' ? false : notFoundValue;
}

/**
 * Parse an array of strings from a query string containing comma-separated
 * values.
 *
 * @param props
 * @param name
 */
export function parseStringArrayFromQueryParam<
  TQueryParams extends QueryParams = {},
  NFV = string[]
>(
  props: RoutePropsQueryParams<TQueryParams>,
  name: KeyIfAvailable<TQueryParams>,
  notFoundValue: NFV = [] as any as NFV
): string[] | NFV {
  const base = parseQueryParamFromRouterProps(props, name, null);
  if (base === null) {
    return notFoundValue;
  } else {
    return base.split(',').filter((x) => x !== '');
  }
}

/**
 * Parse an array of strings from a query string containing comma-separated
 * values.
 *
 * @param props
 * @param name
 */
export function parseNumberArrayFromQueryParam<
  TQueryParam extends QueryParams = {},
  NFV = number[]
>(
  props: RoutePropsQueryParams<TQueryParam>,
  name: KeyIfAvailable<TQueryParam>,
  notFoundValue: NFV = [] as any as NFV
): number[] | NFV {
  const stringResults = parseStringArrayFromQueryParam(props, name, null);
  if (stringResults === null) {
    return notFoundValue;
  }
  return stringResults.map((x) => +x).filter((x) => !Number.isNaN(x));
}

/**
 * There is no entity called "observation point formula output", but we often
 * need to deal with it as a sort of "virtual entity". For example when plotting
 * an observation point's adjusted readings, the unique identifier for the data
 * series we're plotting is the observation point + one of the formula output ids
 * of the formula of its observation point formula.
 *
 * We represent this virtual entity in the system as a (observation point code,
 * formula output id) tuple, separated by a hyphen: `BENOW02-3`
 *
 * However, some observation points have a hyphen in their code, so we can't
 * simply use String.prototype.split(). This method uses a regex, to split it
 * up based on the *last* hyphen in the string.
 *
 * For back-compatibility, if it can't parse out a separate observation point
 * code and formula output id, it will assume the entire string is an
 * observation point code and there is no formula output id.
 *
 * @deprecated We're phasing out the "obs point & formula output" idea and
 * replacing it with "obs point & instrument type item"
 * @param identifierStr
 */
export function parseObsFormulaOutputString(
  identifierStr: string
): { observationPointCode: string; formulaOutputId: number | null }[] {
  if (!identifierStr) {
    return [];
  }

  return identifierStr.split(',').map((str) => {
    const matches = OPFO_REGEX.exec(str) || [];
    return {
      observationPointCode: matches[1],
      formulaOutputId: matches[2] ? Number.parseInt(matches[2]) : null,
    };
  });
}
const OPFO_REGEX = /^(.*?)(?:-([0-9]*))?$/;

/**
 * Takes an object containing (front end) query params to add/remove from the
 * URL, and makes a new query string with them.
 *
 * @param currentQueryString
 * @param paramsToSet An object mapping the params to set/update. The keys
 * should be the names of the params, and the values should be the desired
 * new values. There are some special values:
 * - If the value is an empty array, `false`, `null`, or `undefined`, it will
 * remove the query param from the URL.
 * - If the value is an array, it will set the query param to a comma-separated
 * list of the values in the array.
 */
export function mergeQueryParams<TQueryParams extends QueryParams = {}>(
  currentQueryString: string,
  paramsToSet: Record<KeyIfAvailable<TQueryParams>, QueryParamValue>
) {
  const newParams = new URLSearchParams(currentQueryString);

  Object.entries(paramsToSet).forEach(([paramName, paramValue]) => {
    let newValue = paramValue;

    if (Array.isArray(newValue)) {
      if (newValue.length === 0) {
        newValue = null;
      } else {
        newValue = (newValue as Array<any>).map((x) => String(x)).join(',');
      }
    }

    if (newValue === null || newValue === false || newValue === undefined) {
      newParams.delete(paramName);
    } else {
      newParams.set(paramName, String(newValue));
    }
  });
  return newParams;
}

/**
 * Update multiple query params in the URL.
 *
 * This actually merges the new values of the query params into the existing
 * URL query params (if any), and tells React Router to update the URL if it
 * is now different.
 *
 * It can also optionally clear the pagination params.
 *
 * @param routerProps
 * @param paramsToSet An object mapping the params to set/update. The keys
 * should be the names of the params, and the values should be the desired
 * new values. There are some special values:
 * - If the value is an empty array, `false`, `null`, or `undefined`, it will
 * remove the query param from the URL.
 * - If the value is an array, it will set the query param to a comma-separated
 * list of the values in the array.
 * @param clearTheseOnChange Additional query params that should be cleared
 * if the URL is now different. (By default, the pagination params "limit" and
 * "offset")
 */
export function setQueryParams<
  TQueryParams extends QueryParams = {},
  RP extends Pick<
    RCPWithQueryParams<TQueryParams>,
    'location' | 'history'
  > = Pick<RCPWithQueryParams<TQueryParams>, 'location' | 'history'>
>(
  routerProps: RP,
  paramsToSet: Record<KeyIfAvailable<TQueryParams>, QueryParamValue>,
  // By default, reset pagination whenever the URL changes.
  clearTheseOnChange: string[] | null = ['offset', 'limit']
): void {
  const currentQueryString = routerProps.location.search;

  const newParams = mergeQueryParams(currentQueryString, paramsToSet);

  // only change the URL if new  is different
  if (String(newParams) !== currentQueryString.replace('?', '')) {
    if (clearTheseOnChange) {
      clearTheseOnChange.forEach((deleteParam) =>
        newParams.delete(deleteParam)
      );
    }
    routerProps.history.push({
      ...routerProps.location,
      search: String(newParams),
    });
  }
}

/**
 * Convenience wrapper around `setQueryParams()`, for the more common case
 * where you just want to set one param.
 *
 * @param routerProps
 * @param paramName
 * @param paramValue
 * @param clearTheseOnChange
 */
export function setOneQueryParam<
  TQueryParams extends QueryParams = {},
  RP extends Pick<
    RCPWithQueryParams<TQueryParams>,
    'location' | 'history'
  > = Pick<RCPWithQueryParams<TQueryParams>, 'location' | 'history'>
>(
  routerProps: RP,
  paramName: KeyIfAvailable<TQueryParams>,
  paramValue: QueryParamValue,
  clearTheseOnChange?: string[] | null
): void {
  setQueryParams<TQueryParams>(
    routerProps,
    { [paramName as KeyIfAvailable<TQueryParams>]: paramValue } as any,
    clearTheseOnChange
  );
}

/**
 * Get the current query string
 *
 * @param location History.Location
 * @param options.include list of params to include
 * @param options.exclude list of params to exclude
 */
export function currentQueryString(
  location: H.Location,
  options?: { include?: string[]; exclude?: string[] }
): string {
  const params = new URLSearchParams(location.search);

  const finalParams = new URLSearchParams();

  const inclusions = options?.include ?? [];
  const exclusions = options?.exclude ?? [];

  params.forEach((val, key) => {
    const includeThisKey = inclusions.length
      ? inclusions.includes(key)
      : !exclusions.includes(key);

    if (includeThisKey) {
      finalParams.set(key, val);
    }
  });

  const qs = finalParams.toString();
  return qs ? `?${qs}` : '';
}
