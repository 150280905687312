import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const instrumentTypeListDuck = makeReportingDuck(
  'instrumentTypes',
  (filters) => getPaginated('/reports/instrument-types/', filters),
  () => getApi('/reports/instrument-types/info/')
);

export type InstrumentTypeListAction = ReportingDuckActions<
  typeof instrumentTypeListDuck
>;
