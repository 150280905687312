import React from 'react';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { LinkProps } from 'react-router-dom';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { DMSLink } from 'components/base/link/DMSLink';

interface Props extends LinkProps {
  permission:
    | keyof typeof Enum.User_PERMISSION
    | Array<keyof typeof Enum.User_PERMISSION>;
  children: React.ReactNode;
}

/**
 * Display a Link if user has sufficient permission.
 * Render as static text otherwise.
 */
export function LinkIfHas(props: Props) {
  const { permission, children, ...linkProps } = props;
  return (
    <HasPermission check={permission} noPermissionMsg={children}>
      <DMSLink {...linkProps}>{children}</DMSLink>
    </HasPermission>
  );
}
