import { useEffect, useRef, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { FormikContextType, connect } from 'formik';

const DEFAULT_DELAY_MS = 2000;

interface OwnProps<T> {
  onChange: (values: T) => void;
  delay?: number;
}

interface InnerProps<T> extends OwnProps<T> {
  formik: FormikContextType<T>;
}

/**
 * NOTE: The `onChange` prop should not be an inline function, because we need
 * to memoize the debounced version of it, and that's impossible if we receive
 * a different function instance on each render.
 *
 * TODO: Mostly copied from `FormChangeEffectDebounced.tsx` in the mobile app.
 * Except we hadn't upgraded to Formik 2 on the frontend app yet when this was
 * written, so it doesn't use the hooks.
 *
 * @param props
 */
function InnerFormChangeEffectDebounced<T>(props: InnerProps<T>) {
  const { onChange, delay, formik } = props;
  const { values } = formik;
  const prevValues = useRef<T | null>(null);
  // A ref used in dev to detect if the onChange callback has changed.
  const callbackChangeRef = useRef<OwnProps<T>['onChange'] | null>(onChange);

  const onChangeDebounced = useMemo(() => {
    if (
      process.env.NODE_ENV === 'development' &&
      callbackChangeRef.current &&
      callbackChangeRef.current !== onChange
    ) {
      // eslint-disable-next-line no-console
      console.warn(
        'The "onChange" prop passed to <FormChangeEffectDebounced> has changed to a different function instance.' +
          ' This is usually unintentional and will prevent the debouncing from working correctly.'
      );
      // Clear the ref, so we only print this message once.
      callbackChangeRef.current = null;
    }
    return debounce(
      (formValues: T) => onChange(formValues),
      delay ?? DEFAULT_DELAY_MS
    );
  }, [delay, onChange]);

  // Flush the debounce when the component unmounts.
  useEffect(() => () => onChangeDebounced.flush(), [onChangeDebounced]);

  useEffect(() => {
    // Do not trigger onChange if values are not different
    if (prevValues.current && !isEqual(values, prevValues.current)) {
      onChangeDebounced(values);
    }

    prevValues.current = values;
  }, [values, onChangeDebounced]);

  return null;
}

export const FormChangeEffectDebounced = connect(
  InnerFormChangeEffectDebounced
) as <T>(props: OwnProps<T>) => React.ReactElement | null;
