import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Trans, t } from '@lingui/macro';
import sortBy from 'lodash/sortBy';
import lodashSet from 'lodash/set';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import ActionBlock from 'components/base/actionblock/actionblock';
import { AlertDanger } from 'components/base/alert/alert';
import { ButtonPrimary } from 'components/base/button/button';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { FormItem, FormSection } from 'components/base/form/FormItem';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { enhanceWithBackUrl } from 'components/base/link/DMSLink';
import Loading from 'components/base/loading/loading';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ModalContent from 'components/base/modal/modalcontent';
import { EntityTypes, selectAll } from 'ducks/entities';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { FullState } from 'main/reducers';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { postApi, getApi } from 'util/backendapi/fetch';
import {
  ObservationPointDecorated,
  ReadingsBatch_POST,
  User,
  FormulaDecorated,
  ObservationPointFormulaInput,
  ObservationPointFormula,
  ObservationPointFormulaDecorated,
} from 'util/backendapi/types/Model';
import {
  formatDatetimeForStorage,
  getCurrentDatetime,
  formatDatetimeForDisplay,
} from 'util/dates';
import { validateNumber, isTruthy } from 'util/validation';
import { I18n } from '@lingui/react';
import { I18n as I18nProp } from '@lingui/core';
import { useSelector } from 'react-redux';
import { selectLoggedInUser } from 'ducks/login';
import { getUserDisplayName } from 'util/user';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { errorToString } from 'util/backendapi/error';
import { useIsMounted } from 'util/hooks';
import { makeObsPointItemMenuOption } from 'components/modules/obs-point-item-menu/ObsPointItemMenu';

enum READING_TYPE {
  raw_reading = 'raw_reading',
  test_calculation = 'test_calculation',
}

interface Props {
  observationPoint: ObservationPointDecorated;
}
export function CreateReadingModal(props: Props) {
  const isMounted = useIsMounted();
  const { observationPoint } = props;
  const currentUser = useSelector((state: FullState) =>
    selectLoggedInUser(state)
  );

  const now = useMemo(() => new Date().toISOString(), []);

  const { isLoading, errorMessage, currentOpf } = useSelector(
    (state: FullState) => {
      const observationPointState = state.obsPoint.detail.formula;
      const {
        isLoading,
        errorMessage,
        historyForObservationPoint,
        formulaSnapshots,
      } = observationPointState;

      return {
        isLoading,
        errorMessage,
        currentOpf:
          !isLoading &&
          !errorMessage &&
          formulaSnapshots &&
          historyForObservationPoint?.id === observationPoint.id
            ? orderBy(formulaSnapshots, (fs) => fs.start_datetime, 'desc').find(
                (snapshot) =>
                  formatDatetimeForStorage(snapshot.start_datetime) <= now
              )
            : undefined,
      };
    }
  );
  const formulas = useSelector((state: FullState) => {
    const formulas = selectAll(state, EntityTypes.FORMULA);
    if (formulas.allIds.length === 0) {
      return undefined;
    } else {
      return formulas.byId;
    }
  });

  const [isLoadingDependents, setIsLoadingDependents] = useState(false);
  const [errorLoadingDependents, setErrorLoadingDependents] = useState('');
  const [observationPoints, setObservationPoints] =
    useState<ObservationPointDecorated[]>();
  const [observationPointFormulas, setObservationpointFormulas] =
    useState<ObsPointFormulaDetails[]>();

  useEffect(() => {
    (async function () {
      if (!observationPoint || !currentOpf || !formulas) {
        return;
      }

      const observationPointFormulas: ObsPointFormulaDetails[] = [
        {
          isAssignedToDataLogger: false,
          observation_point_formula: currentOpf.observation_point_formula,
          observation_point:
            currentOpf.observation_point_formula.observation_point,
          formula: currentOpf.observation_point_formula.formula,
          observation_point_formula_inputs: Object.values(
            currentOpf.observation_point_formula_inputs
          ),
        },
      ];

      const observationPoints = [observationPoint];

      const formula = formulas[currentOpf.observation_point_formula.formula];
      let obsPointsToFetch: number[] = uniq(
        formula.formula_inputs
          .filter((fi) => fi.type === Enum.FormulaInput_TYPE.comp_reading)
          .map(
            (fi) =>
              currentOpf!.observation_point_formula_inputs[fi.var_name]
                ?.compensation_observation_point
          )
          .filter(isTruthy)
      );

      if (obsPointsToFetch.length === 0) {
        // Find out if it's assigned to a data logger
        const dataLoggerChannels = await getApi('/data-logger-channels/', {
          observation_point: observationPoint.id,
          active_at_datetime: now,
        });
        setObservationPoints(observationPoints);
        setObservationpointFormulas(
          observationPointFormulas.map((opf) => ({
            ...opf,
            isAssignedToDataLogger: dataLoggerChannels.length > 0,
          }))
        );
        return;
      }

      setIsLoadingDependents(true);

      try {
        while (obsPointsToFetch.length > 0) {
          const [newObsPoints, newOpfs] = await Promise.all([
            getApi('/observation-points/', { id__in: obsPointsToFetch }),
            getApi('/observation-point-formulas/', {
              observation_point__in: obsPointsToFetch,
              active_now: true,
            }).then((opfs) =>
              // The results from `/observation-point-formulas/` can include
              // more than one observation point formula input for a single
              // formula input. We want only the current one (that is, the one
              // with the latest start date that is before "now")
              opfs.map((opf) => ({
                ...opf,
                observation_point_formula_inputs: uniqBy(
                  orderBy(
                    opf.observation_point_formula_inputs.filter(
                      (opfi) =>
                        formatDatetimeForStorage(opfi.start_datetime) <= now
                    ),
                    (opfi) => opfi.start_datetime,
                    'desc'
                  ),
                  (opfi) => opfi.formula_input
                ),
              }))
            ),
          ]);
          if (!isMounted()) {
            return;
          }

          obsPointsToFetch = uniq(
            newOpfs.flatMap((opf) =>
              opf.observation_point_formula_inputs
                .map((opfi) => opfi.compensation_observation_point)
                .filter(isTruthy)
            )
          );
          if (
            obsPointsToFetch.some((newOpId) =>
              observationPoints.some((op) => op.id === newOpId)
            )
          ) {
            throw new Error(
              'The current formula for this observation point appears to have a circular dependency.'
            );
          }
          observationPoints.push(...newObsPoints);
          observationPointFormulas.push(
            ...newOpfs.map((opf) => ({
              isAssignedToDataLogger: false,
              observation_point_formula: opf,
              observation_point: opf.observation_point.id,
              formula: opf.formula.id,
              observation_point_formula_inputs:
                opf.observation_point_formula_inputs,
            }))
          );
        }

        // Check for missing data, to prevent the frontend from crashing completely.
        observationPointFormulas.forEach((opf) => {
          const observationPoint = observationPoints.find(
            (op) => op.id === opf.observation_point
          );
          if (!observationPoint) {
            throw new Error(
              `Could not find observation point [${opf.observation_point}] for observation point formula [${opf.observation_point_formula.id}]`
            );
          }
          const formula = formulas[opf.formula];
          if (!formula) {
            throw new Error(
              `Could not find formula [${
                opf.formula
              }] for observation point formula [${formatDatetimeForDisplay(
                opf.observation_point_formula.start_datetime,
                observationPoint.time_zone.name
              )}] on observation point [${observationPoint.code}]`
            );
          }
        });

        // Check whether any of them are associated with a data logger.
        const dataLoggerChannels = await getApi('/data-logger-channels/', {
          observation_point__in: observationPoints.map((op) => op.id),
          active_at_datetime: now,
        });
        if (!isMounted()) {
          return;
        }

        setIsLoadingDependents(false);
        setObservationPoints(observationPoints);
        setObservationpointFormulas(
          observationPointFormulas.map((opf) => ({
            ...opf,
            isAssignedToDataLogger: dataLoggerChannels.some(
              (dlc) => dlc.observation_point?.id === opf.observation_point
            ),
          }))
        );
      } catch (e) {
        setIsLoadingDependents(false);
        setErrorLoadingDependents(errorToString(e));
      }
    })();
  }, [currentOpf, formulas, isMounted, now, observationPoint]);

  const history = useHistory();
  const location = useLocation();
  const handleSubmit = useCallback(
    async (batch: ReadingsBatch_POST) => {
      const result = await postApi('/readings-batches/', batch);
      history.push(
        enhanceWithBackUrl(`/check-readings/${result.batch_number}`, location)
      );
    },
    [history, location]
  );

  return (
    <I18n>
      {({ i18n }) => (
        <CreateReadingModalView
          timeZone={observationPoint.time_zone.name}
          isLoading={isLoading || isLoadingDependents}
          errorMessage={errorMessage || errorLoadingDependents}
          onSubmit={handleSubmit}
          formulas={formulas}
          observationPoints={observationPoints}
          observationPointFormulas={observationPointFormulas}
          currentUser={currentUser}
          i18n={i18n}
        />
      )}
    </I18n>
  );
}

interface FormValues {
  reading_type: READING_TYPE;
  reading_datetime: string;
  readings: {
    observationPointFormulaId: number;
    inspector_comment: string;
    raw_reading_entries: {
      formulaInputId: number;
      type: Enum.FormulaInput_TYPE;
      value: string;
      position: number | null;
    }[];
  }[];
}

/**
 * The information we need about each observation point formula, to render
 * its inputs in the form.
 */
interface ObsPointFormulaDetails {
  observation_point_formula:
    | ObservationPointFormula
    | ObservationPointFormulaDecorated;
  observation_point: number;
  formula: number;
  observation_point_formula_inputs: ObservationPointFormulaInput[];
  isAssignedToDataLogger: boolean;
}

interface ViewProps {
  timeZone: string;
  observationPointFormulas: ObsPointFormulaDetails[] | undefined;
  observationPoints: ObservationPointDecorated[] | undefined;
  formulas: Record<number, FormulaDecorated | undefined> | undefined;
  isLoading: boolean;
  errorMessage: string | null;
  onSubmit: (values: ReadingsBatch_POST) => Promise<any>;
  currentUser: User | null;
  i18n: I18nProp;
}
export function CreateReadingModalView(props: ViewProps) {
  const [now] = useState(getCurrentDatetime());

  const {
    observationPointFormulas,
    formulas,
    isLoading,
    errorMessage,
    onSubmit,
    currentUser,
    observationPoints,
    i18n,
  } = props;
  const header = <Trans>Create a manual reading</Trans>;
  if (errorMessage && !isLoading) {
    return (
      <ModalContent header={header}>
        <AlertDanger>{errorMessage}</AlertDanger>
      </ModalContent>
    );
  } else if (
    isLoading ||
    !formulas ||
    !observationPointFormulas ||
    !observationPoints
  ) {
    return (
      <ModalContent header={header}>
        <Loading />
      </ModalContent>
    );
  }

  const initialValues: FormValues = {
    // Can't create manual readings for data logger obs points, so default it
    // to "test calculation" in that case.
    reading_type: observationPointFormulas.some(
      (opf) => opf.isAssignedToDataLogger
    )
      ? READING_TYPE.test_calculation
      : READING_TYPE.raw_reading,
    reading_datetime: now,
    readings: observationPointFormulas.map((opf) => {
      const formula = formulas[opf.formula]!;
      return {
        observationPointFormulaId: opf.observation_point_formula.id,
        inspector_comment: i18n._(t`Manual reading`),
        raw_reading_entries: sortBy(
          formula.formula_inputs,
          (fi) => fi.position
        ).map((formulaInput) => ({
          formulaInputId: formulaInput.id,
          type: formulaInput.type,
          value: '',
          position: formulaInput.position,
        })),
      };
    }),
  };
  return (
    <ModalContent header={header}>
      <ul>
        <li>
          <Trans>
            When <strong>'Raw reading'</strong> is selected, a batch will be
            created with a single reading and it can be processed as normal.
            This option is not available for data loggers.
          </Trans>
        </li>
        <li>
          <Trans>
            When <strong>'Test calculation'</strong> is selected, a batch will
            be created with a single reading, but it cannot be saved. This is
            used for testing formulae and their inputs.
          </Trans>
        </li>
      </ul>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          const batch: ReadingsBatch_POST = {
            observer_name: currentUser ? getUserDisplayName(currentUser) : '',
            is_test: values.reading_type === READING_TYPE.test_calculation,
            readings: values.readings.map((r) => {
              const opfi = observationPointFormulas.find(
                (opfi) =>
                  opfi.observation_point_formula.id ===
                  r.observationPointFormulaId
              )!;

              return {
                observation_point: opfi.observation_point,
                inspector_comment: r.inspector_comment,
                reading_datetime: values.reading_datetime,
                raw_reading_entries: r.raw_reading_entries
                  .filter((re) =>
                    [
                      Enum.FormulaInput_TYPE.chainable_reading,
                      Enum.FormulaInput_TYPE.raw_reading,
                    ].includes(re.type)
                  )
                  .map((re) => ({
                    value: +re.value,
                    position: re.position!,
                  })),
              };
            }),
          };
          try {
            await onSubmit(batch);
          } catch (e) {
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, []);
          }
        }}
        validate={(values) => {
          const errors: FormikErrors<typeof values> = {};
          if (!values.reading_datetime) {
            errors.reading_datetime = (
              <Trans>Reading date and time is required.</Trans>
            ) as any;
          }

          // Check for data logger obs points. Can not create manual
          // readings for those.
          if (values.reading_type === READING_TYPE.raw_reading) {
            observationPointFormulas.some((opf) => {
              if (!opf.isAssignedToDataLogger) {
                return false;
              } else {
                const obsPoint = observationPoints.find(
                  (op) => op.id === opf.observation_point
                );
                errors.reading_type = (
                  <Trans>
                    Cannot create a manual reading for observation point{' '}
                    {obsPoint?.code} as it is assigned to a data logger.
                  </Trans>
                ) as any;
              }
              return true;
            });
          }

          values.readings.forEach((r, readingIdx) => {
            if (!r.inspector_comment) {
              lodashSet(
                errors,
                ['readings', readingIdx, 'inspector_comment'],
                <Trans>Inspector comment is required.</Trans>
              );
            }
            r.raw_reading_entries.forEach((readingEntry, valueIdx) => {
              if (
                [
                  Enum.FormulaInput_TYPE.chainable_reading,
                  Enum.FormulaInput_TYPE.raw_reading,
                ].includes(readingEntry.type) &&
                !validateNumber(readingEntry.value)
              ) {
                lodashSet(
                  errors,
                  [
                    'readings',
                    readingIdx,
                    'raw_reading_entries',
                    valueIdx,
                    'value',
                  ],
                  <Trans>Value must be a number.</Trans>
                );
              }
            });
          });
          return errors;
        }}
      >
        {(formik) => (
          <Form>
            <FormSection>
              {formik.status}
              <FormItem
                label={<Trans>Reading type</Trans>}
                fieldId="reading_type"
              >
                <SimpleSelectField
                  id="reading_type"
                  name="reading_type"
                  options={[
                    {
                      value: READING_TYPE.raw_reading,
                      label: <Trans>Raw reading</Trans>,
                    },
                    {
                      value: READING_TYPE.test_calculation,
                      label: <Trans>Test calculation</Trans>,
                    },
                  ]}
                />
                <FieldError name="reading_type" />
              </FormItem>
              <FormItem
                label={<Trans>Reading date and time</Trans>}
                fieldId="reading_datetime"
              >
                <DatetimeField
                  name="reading_datetime"
                  id="reading_datetime"
                  timeZone={props.timeZone}
                />
                <FieldError name="reading_datetime" />
              </FormItem>
            </FormSection>
            {formik.values.readings.map((reading, readingIdx) => {
              const opf = observationPointFormulas.find(
                (opf) =>
                  opf.observation_point_formula.id ===
                  reading.observationPointFormulaId
              )!;
              const formula = formulas[opf.formula]!;
              const obsPoint = observationPoints.find(
                (op) => op.id === opf.observation_point
              );
              return (
                <FormSection
                  key={opf.observation_point_formula.id}
                  label={`${obsPoint?.code} (${formula.code})`}
                >
                  {reading.raw_reading_entries.map(
                    (readingEntry, entryIndex) => {
                      const formulaInput = formula.formula_inputs.find(
                        (fi) => fi.id === readingEntry.formulaInputId
                      )!;
                      // TODO: Special handling for summation & chained inputs?
                      switch (formulaInput.type) {
                        case Enum.FormulaInput_TYPE.comp_reading: {
                          const opfi =
                            opf.observation_point_formula_inputs.find(
                              (opfi) => opfi.formula_input === formulaInput.id
                            );
                          const compObsPointItem =
                            opfi &&
                            opfi.compensation_observation_point &&
                            opfi.compensation_item_number &&
                            makeObsPointItemMenuOption(observationPoints, {
                              observation_point:
                                opfi.compensation_observation_point,
                              item_number: opfi.compensation_item_number,
                            });
                          return (
                            <FormItem
                              key={formulaInput.id}
                              label={formulaInput.description}
                            >
                              {compObsPointItem ? (
                                compObsPointItem.label
                              ) : (
                                <Trans>[Not set]</Trans>
                              )}
                            </FormItem>
                          );
                        }
                        default: {
                          const formName = `readings[${readingIdx}].raw_reading_entries[${entryIndex}].value`;
                          return (
                            <FormItem
                              key={formulaInput.id}
                              label={
                                <Trans>
                                  Reading item {formulaInput.position} (
                                  {formulaInput.description})
                                </Trans>
                              }
                              fieldId={`raw_reading_entries-${readingIdx}`}
                            >
                              <Field
                                id={`raw_reading_entries-${readingIdx}`}
                                name={formName}
                                type="text"
                              />
                              <FieldError name={formName} />
                            </FormItem>
                          );
                        }
                      }
                    }
                  )}
                  <FormItem
                    label={<Trans>Inspector comment</Trans>}
                    fieldId={`readings[${readingIdx}].inspector_comment`}
                  >
                    <Field
                      id={`readings[${readingIdx}].inspector_comment`}
                      name={`readings[${readingIdx}].inspector_comment`}
                      component="textarea"
                    />
                    <FieldError
                      name={`readings[${readingIdx}].inspector_comment`}
                    />
                  </FormItem>
                </FormSection>
              );
            })}
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                data-testid="submit-create-reading"
                type="submit"
                disabled={formik.isSubmitting}
                iconType="icon-save"
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
