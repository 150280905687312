import { t } from '@lingui/macro';
import { StandardThunk } from 'main/store';
import { Model } from 'util/backendapi/models/api.interfaces';
import { SiteDetailFormValues } from 'screens/site/detail/SiteDetailView';
import { DRFError } from 'util/backendapi/error';
import { getApi, patchApi, postApi } from 'util/backendapi/fetch';

export const ActionTypes = {
  FETCH_SITE_START: 'dms/site/detail/FETCH_SITE_START',
  FETCH_SITE_RESPONSE: 'dms/site/detail/FETCH_SITE_RESPONSE',
  FETCH_SITE_ERROR: 'dms/site/detail/FETCH_SITE_ERROR',
  UPDATE_SITE_START: 'dms/site/detail/UPDATE_SITE_START',
  UPDATE_SITE_RESPONSE: 'dms/site/detail/UPDATE_SITE_RESPONSE',
  UPDATE_SITE_ERROR: 'dms/site/detail/UPDATE_SITE_ERROR',
  CREATE_SITE_START: 'dms/site/detail/CREATE_SITE_START',
  CREATE_SITE_RESPONSE: 'dms/site/detail/CREATE_SITE_RESPONSE',
  CREATE_SITE_ERROR: 'dms/site/detail/CREATE_SITE_ERROR',
  UNMOUNT_SITE_SCREEN: 'dms/site/detail/UNMOUNT_SITE_SCREEN',
} as const;

const ActionCreators = {
  UNMOUNT_SITE_SCREEN: function () {
    return {
      type: ActionTypes.UNMOUNT_SITE_SCREEN,
    };
  },
  FETCH_SITE_START: function () {
    return { type: ActionTypes.FETCH_SITE_START };
  },
  FETCH_SITE_ERROR: function (errorMessage: string) {
    return {
      type: ActionTypes.FETCH_SITE_ERROR,
      error: true,
      payload: errorMessage,
    };
  },
  FETCH_SITE_RESPONSE: function (site: Model.SiteDecorated) {
    return {
      type: ActionTypes.FETCH_SITE_RESPONSE,
      payload: site,
    };
  },
  UPDATE_SITE_START: function () {
    return {
      type: ActionTypes.UPDATE_SITE_START,
    };
  },
  UPDATE_SITE_ERROR: function (errors: DRFError<Model.SiteDecorated> | Error) {
    return {
      type: ActionTypes.UPDATE_SITE_ERROR,
      error: true,
      payload: errors,
    };
  },
  UPDATE_SITE_RESPONSE: function (site: Model.SiteDecorated) {
    return {
      type: ActionTypes.UPDATE_SITE_RESPONSE,
      payload: site,
    };
  },
  CREATE_SITE_START: function () {
    return {
      type: ActionTypes.CREATE_SITE_START,
    };
  },
  CREATE_SITE_ERROR: function (errors: DRFError<Model.SiteDecorated> | Error) {
    return {
      type: ActionTypes.CREATE_SITE_ERROR,
      error: true,
      payload: errors,
    };
  },
  CREATE_SITE_RESPONSE: function (site: Model.SiteDecorated) {
    return {
      type: ActionTypes.CREATE_SITE_RESPONSE,
      payload: site,
    };
  },
};

export type SiteDetailAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export type SiteDetailState = Readonly<{
  siteCode: string;
  site: Model.SiteDecorated | null;
  isLoading: boolean;
  isSubmitting: boolean;
  fetchErrorMessage: string | null;
}>;

export const initialSiteDetailState: SiteDetailState = {
  siteCode: '',
  site: null,
  isLoading: false,
  isSubmitting: false,
  fetchErrorMessage: null,
};

export default function siteDetailReducer(
  state = initialSiteDetailState,
  action: SiteDetailAction
): SiteDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_SITE_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_SITE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        site: action.payload,
      };
    case ActionTypes.FETCH_SITE_ERROR:
      return {
        ...state,
        isLoading: false,
        fetchErrorMessage: action.payload,
      };
    case ActionTypes.UPDATE_SITE_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.UPDATE_SITE_RESPONSE:
      return {
        ...state,
        isSubmitting: false,
        site: action.payload,
      };
    case ActionTypes.UPDATE_SITE_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };
    case ActionTypes.CREATE_SITE_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.CREATE_SITE_RESPONSE:
    case ActionTypes.CREATE_SITE_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };
    case ActionTypes.UNMOUNT_SITE_SCREEN:
      return initialSiteDetailState;
    default:
      return state;
  }
}

export const unmountMaintainSiteScreen = ActionCreators.UNMOUNT_SITE_SCREEN;

export function fetchSite(siteCode: string): StandardThunk {
  return async function (dispatch, getState, { i18n }) {
    dispatch(ActionCreators.FETCH_SITE_START());

    try {
      const sites = await getApi('/sites/', {
        code: siteCode,
      });

      if (sites.length < 1) {
        return dispatch(
          ActionCreators.FETCH_SITE_ERROR(
            i18n._(t`Site "${siteCode}" not found.`)
          )
        );
      }

      return dispatch(ActionCreators.FETCH_SITE_RESPONSE(sites[0]));
    } catch (e) {
      return dispatch(ActionCreators.FETCH_SITE_ERROR(e.toString()));
    }
  };
}

export function updateSite(
  id: number,
  values: SiteDetailFormValues
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.UPDATE_SITE_START());

    try {
      const site = await patchApi(`/sites/${id}/`, values);
      return dispatch(ActionCreators.UPDATE_SITE_RESPONSE(site));
    } catch (e) {
      dispatch(ActionCreators.UPDATE_SITE_ERROR(e));
      throw e;
    }
  };
}

export function createSite(values: SiteDetailFormValues): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.UPDATE_SITE_START());

    try {
      const site = await postApi(`/sites/`, values);
      return dispatch(ActionCreators.UPDATE_SITE_RESPONSE(site));
    } catch (e) {
      dispatch(ActionCreators.UPDATE_SITE_ERROR(e));
      throw e;
    }
  };
}
