import isEqualWith from 'lodash/isEqualWith';
import { Filter, Model } from '../../util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions } from '../../main/store';
import {
  getPaginated,
  getApi,
  postApi,
  patchApi,
} from '../../util/backendapi/fetch';
import { PaginationResponseData } from '../../util/backendapi/pagination';
import { Reducer } from 'redux';
import {
  LoadDefaultsFunc,
  OnSearchFunc,
} from 'components/base/form/asyncsimpleselect/asyncsimpleselect';
import { BackendApiError } from 'util/backendapi/error';

export const ActionTypes = {
  CREATE_STORED_LIST_START: 'dms/storedList/list/CREATE_STORED_LIST_START',
  CREATE_STORED_LIST_RESPONSE:
    'dms/storedList/list/CREATE_STORED_LIST_RESPONSE',
  CREATE_STORED_LIST_ERROR: 'dms/storedList/list/CREATE_STORED_LIST_ERROR',
  FETCH_STORED_LIST_MANY_START: 'dms/storedlist/FETCH_STORED_LIST_MANY_START',
  FETCH_STORED_LIST_MANY_ERROR: 'dms/storedlist/FETCH_STORED_LIST_MANY_ERROR',
  FETCH_STORED_LIST_MANY_RESPONSE:
    'dms/storedlist/FETCH_STORED_LIST_MANY_RESPONSE',
  UPDATE_STORED_LIST_START: 'dms/storedList/list/UPDATE_STORED_LIST_START',
  UPDATE_STORED_LIST_RESPONSE:
    'dms/storedList/list/UPDATE_STORED_LIST_RESPONSE',
  UPDATE_STORED_LIST_ERROR: 'dms/storedList/list/UPDATE_STORED_LIST_ERROR',
} as const;

export const ActionCreators = {
  CREATE_STORED_LIST_START() {
    return {
      type: ActionTypes.CREATE_STORED_LIST_START,
    };
  },
  CREATE_STORED_LIST_RESPONSE() {
    return {
      type: ActionTypes.CREATE_STORED_LIST_RESPONSE,
    };
  },
  CREATE_STORED_LIST_ERROR(
    error: BackendApiError<Model.PolymorphicStoredList_POST>
  ) {
    return {
      type: ActionTypes.CREATE_STORED_LIST_ERROR,
      error: true,
      payload: error,
    };
  },
  FETCH_STORED_LIST_MANY_START(filters: Filter.StoredLists) {
    return {
      type: ActionTypes.FETCH_STORED_LIST_MANY_START,
      filters,
    };
  },
  FETCH_STORED_LIST_MANY_ERROR(
    filters: Filter.StoredLists,
    errorMessage: string
  ) {
    return {
      type: ActionTypes.FETCH_STORED_LIST_MANY_ERROR,
      filters,
      errorMessage,
    };
  },
  FETCH_STORED_LIST_MANY_RESPONSE(
    filters: Filter.StoredLists,
    storedLists: Model.PolymorphicStoredList[],
    pagination: PaginationResponseData | null
  ) {
    return {
      type: ActionTypes.FETCH_STORED_LIST_MANY_RESPONSE,
      filters,
      payload: {
        storedLists,
        pagination,
      },
    };
  },
  UPDATE_STORED_LIST_START: () => ({
    type: ActionTypes.UPDATE_STORED_LIST_START,
  }),
  UPDATE_STORED_LIST_RESPONSE: () => ({
    type: ActionTypes.UPDATE_STORED_LIST_RESPONSE,
  }),
  UPDATE_STORED_LIST_ERROR: (
    error: BackendApiError<Model.PolymorphicStoredList_PATCH>
  ) => ({
    type: ActionTypes.UPDATE_STORED_LIST_ERROR,
    error: true,
    payload: error,
  }),
} as const;

export type StoredListListAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface StoredListListState {
  errorMessage: string | null;
  filters: Filter.StoredLists;
  loading: boolean;
  pagination: PaginationResponseData | null;
  storedLists: Model.PolymorphicStoredList[];
}

export function storedListListInitialState(): StoredListListState {
  return {
    errorMessage: null,
    filters: {},
    loading: true,
    pagination: null,
    storedLists: [],
  };
}

export const storedListListReducer: Reducer<
  StoredListListState,
  StoredListListAction
> = function (
  state = storedListListInitialState(),
  action
): StoredListListState {
  switch (action.type) {
    case ActionTypes.FETCH_STORED_LIST_MANY_START:
      if (
        isEqualWith(state.filters, action.filters, (_valueA, _valueB, key) =>
          // If this callback returns "undefined", lodash handles the comparison
          // as it would with "isEqual()". So this is just a way of saying,
          // "compare all the filter fields except limit and offset"
          key === 'limit' || key === 'offset' ? true : undefined
        )
      ) {
        // If only the pagination filters are changing, keep all the data
        // for the current page during the load time, so the pagination
        // controls will behave more consistently.
        return {
          ...state,
          loading: true,
          errorMessage: null,
          filters: action.filters,
        };
      } else {
        // If other filters have changed, reset everything.
        return {
          ...storedListListInitialState(),
          filters: action.filters,
        };
      }
    case ActionTypes.FETCH_STORED_LIST_MANY_RESPONSE:
      if (state.filters !== action.filters) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          storedLists: action.payload.storedLists,
          pagination: action.payload.pagination,
        };
      }
    case ActionTypes.FETCH_STORED_LIST_MANY_ERROR:
      if (state.filters !== action.filters) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          errorMessage: action.errorMessage,
        };
      }
    default:
      return state;
  }
};

export function fetchStoredLists(filters: Filter.StoredLists): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_STORED_LIST_MANY_START(filters));
    try {
      const { data: storedLists, pagination } = await getPaginated(
        '/stored-lists/',
        filters
      );

      dispatch(
        ActionCreators.FETCH_STORED_LIST_MANY_RESPONSE(
          filters,
          storedLists.slice(0, 50),
          pagination
        )
      );
    } catch (e) {
      dispatch(ActionCreators.FETCH_STORED_LIST_MANY_ERROR(filters, e.message));
    }
  };
}

/**
 * For use as the "loadDefaults" callback of AsyncSimpleSelect
 * @param initialValue
 */
export const loadDefaultsForStoredListMenu: LoadDefaultsFunc<number, true> =
  async function (initialValue: number[]) {
    if (initialValue.length > 0) {
      return makeStoredListOptions(
        await getApi('/stored-lists/', {
          id__in: initialValue,
        })
      );
    } else {
      return [];
    }
  };

/**
 * For use as the "onSearch" callback of AsyncSimpleSelect
 * @param inputValue
 */
export const searchOnTypeForStoredListMenu: OnSearchFunc<number> =
  async function (inputValue: string) {
    if (!inputValue) {
      return [];
    }
    return makeStoredListOptions(
      await getApi('/stored-lists/', {
        name__icontains: inputValue,
      })
    );
  };

function makeStoredListOptions(storedLists: Model.PolymorphicStoredList[]) {
  return storedLists.map((s) => ({ value: s.id, label: s.name }));
}

export function createStoredList(
  storedList: Model.PolymorphicStoredList_POST
): StandardThunk<number> {
  return async function (dispatch) {
    dispatch(ActionCreators.CREATE_STORED_LIST_START());
    try {
      const newStoredList = await postApi('/stored-lists/', storedList);
      dispatch(ActionCreators.CREATE_STORED_LIST_RESPONSE());
      return newStoredList.id;
    } catch (e) {
      dispatch(ActionCreators.CREATE_STORED_LIST_ERROR(e));
      throw e;
    }
  };
}

export function updateStoredList(
  id: number,
  storedList: Model.PolymorphicStoredList_PATCH
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.UPDATE_STORED_LIST_START());
    try {
      await patchApi(`/stored-lists/${id}/`, storedList);
      dispatch(ActionCreators.UPDATE_STORED_LIST_RESPONSE());
    } catch (e) {
      dispatch(ActionCreators.UPDATE_STORED_LIST_ERROR(e));
      throw e;
    }
  };
}
