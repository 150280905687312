import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const obsPointListDuck = makeReportingDuck(
  'observationPoints',
  (filters) => getPaginated('/reports/observation-points/', filters),
  () => getApi('/reports/observation-points/info/')
);

export type ObsPointListAction = ReportingDuckActions<typeof obsPointListDuck>;
