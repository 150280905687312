import React from 'react';
import {
  connect,
  FormikTouched,
  FormikErrors,
  FormikContextType,
} from 'formik';

/**
 * A helper component for Formik forms that need to do things like having
 * one field automatically update when another field's value changes.
 *
 * This is a re-implementation of the "formik-effect" package, which doesn't
 * work in React 16.3+. The concept, though, is very simple. It just uses
 * the Formik "connect" HOC, to receive the current formik state as props.
 * Then it uses the standard React lifecycle method "componentDidUpdate()"
 * to fire off an onChange() method when the props change.
 *
 * Like formik-effect, it takes a single prop, "onChange()". When the formik
 * state changes, this function gets called with an object representing the
 * previous state. (So, very similar to the way "componentDidUpdate(prevProps, prevState)" works).
 *
 * The formik "state" is a subset of the full formik "props":
 *   { values, errors, touched, isSubmitting }
 *
 * @example
 *
 * <Formik>
 * {(formikProps) => (
 *   <main>
 *     <Field name="field1" />
 *     <Field name="field2" />
 *     <FormChangeEffect onChange={(prevFormikState) => {
 *         // You can compare `prevFormikState` to the current `formikProps`
 *         if (prevFormikState.values.field1 !== formikProps.values.field1) {
 *           // The value of field1 change!
 *           formikProps.setFieldValue(
 *             'field2',
 *             getNewDefaultForField2(formikProps.values.field1);
 *           );
 *         }
 *       }}
 *     />
 *   </main>
 * )}
 * </Formik>
 *
 * @class FormChangeEffect
 * @extends {React.Component}
 */

export type FormChangeEffectParams<FormValues> = {
  values: FormValues;
  touched: FormikTouched<FormValues>;
  errors: FormikErrors<FormValues>;
  isSubmitting: boolean;
};

interface OwnProps<FormValues> {
  onChange: (prevProps: FormChangeEffectParams<FormValues>) => void;
}
interface Props<FormValues> extends OwnProps<FormValues> {
  formik: FormikContextType<FormValues>;
}

class InnerFormChangeEffect<FormValues> extends React.Component<
  Props<FormValues>
> {
  render() {
    return null;
  }

  componentDidUpdate(prevProps: Props<FormValues>) {
    // Bail out if the formik props haven't changed.
    if (prevProps.formik === this.props.formik) {
      return;
    }

    // Bail out if a shallow comparison of the formik state props shows no change
    if (
      prevProps.formik.values === this.props.formik.values &&
      prevProps.formik.touched === this.props.formik.touched &&
      prevProps.formik.errors === this.props.formik.errors &&
      prevProps.formik.isSubmitting === this.props.formik.isSubmitting
    ) {
      return;
    }

    this.props.onChange({
      values: prevProps.formik.values,
      touched: prevProps.formik.touched,
      errors: prevProps.formik.errors,
      isSubmitting: prevProps.formik.isSubmitting,
    });
  }
}

const FormChangeEffect = connect(InnerFormChangeEffect) as <FormValues = any>(
  props: OwnProps<FormValues>
) => React.ReactElement | null;

export default FormChangeEffect;
