import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import { BatchesListView } from './batcheslistview';
import { fetchBatches } from '../../../ducks/dashboard';
import { FullState } from 'main/reducers';
import { Enum, Filter, Model } from 'util/backendapi/models/api.interfaces';
import { setDashletCollapsed } from 'ducks/ui';
import moment from 'moment-timezone';
import { formatDatetimeForBackendUrl } from 'util/dates';
import { createWebsocket } from 'util/websocket';

type DispatchProps = typeof _BatchesList.mapDispatchToProps;
type StateProps = {
  batches: Model.ListReadingsBatch[] | null;
  isExpanded: boolean;
  currentUserId: number | null;
};
type OwnProps = { batchesForUser: boolean };
type Props = StateProps & ResolveThunks<DispatchProps> & OwnProps;

class _BatchesList extends React.Component<Props> {
  static FETCH_MINIMUM_DELAY_MS = 500;
  static FETCH_VARIABLE_DELAY_MS = 1500;

  static WEBSOCKET_RECONNECT_DELAY_MS = 5000;

  private timeout: any;
  private _isMounted: boolean;
  private websocket: WebSocket | null;

  constructor(props: Props) {
    super(props);
    this.timeout = false;
    this._isMounted = false;
    this.websocket = null;
  }

  static mapDispatchToProps = { fetchBatches, setDashletCollapsed };
  static mapStateToProps = (state: FullState): StateProps => {
    return {
      batches: state.dashboard.batches,
      isExpanded: !state.ui.isDashletCollapsed.batchesAwaiting,
      currentUserId: state.user.loggedInAs,
    };
  };

  /**
   * Schedules the next fetch of file info.
   * (Defined as an arrow function in order to make sure "this" is bound
   * to the correct object instance)
   */

  scheduleNextFetch = (_expanded: boolean) => {
    // If a fetch is already scheduled then do nothing
    if (this.timeout) return;

    // Before setting another timeout, make sure the component did not unmount.
    if (this._isMounted) {
      const fetch_delay =
        _BatchesList.FETCH_MINIMUM_DELAY_MS +
        _BatchesList.FETCH_VARIABLE_DELAY_MS * Math.random();
      this.timeout = setTimeout(this.fetchData, fetch_delay);
    }
  };

  connectWebsocket = () => {
    if (!this._isMounted) return;

    // Authenticate with backend using oath token
    this.websocket = createWebsocket('/ws/dashboard-notifications/');

    this.websocket.onmessage = (_e) => {
      //console.log('Websocket message', JSON.parse(_e.data))
      this.scheduleNextFetch(this.props.isExpanded);
    };

    this.websocket.onclose = (_e) => {
      //console.log('Websocket socket closed');
      setTimeout(
        this.connectWebsocket,
        _BatchesList.WEBSOCKET_RECONNECT_DELAY_MS
      );
    };

    // this.websocket.onopen = (_e) => {
    //   console.log('Websocket socket connected');
    // };
  };

  fetchData = async () => {
    this.timeout = false;
    const filters: Filter.ReadingsBatch = {
      status__in: [Enum.ReadingsBatch_STATUS.unprocessed],
      route_march_inspection: false,
    };

    if (this.props.batchesForUser) {
      if (!this.props.currentUserId) {
        this.scheduleNextFetch(this.props.isExpanded);
        return;
      }
      filters.created_by__in = [this.props.currentUserId];
      filters.created__gte = formatDatetimeForBackendUrl(
        moment().startOf('day')
      );
    }

    await this.props.fetchBatches(filters);
  };

  onAccordionToggled = (expanded: boolean) => {
    this.props.setDashletCollapsed('batchesAwaiting', !expanded);
    this.scheduleNextFetch(expanded);
  };

  componentDidMount() {
    this._isMounted = true;

    this.connectWebsocket();

    this.fetchData();
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.websocket) {
      this.websocket.close();
      this.websocket = null;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    return (
      <BatchesListView
        batches={this.props.batches}
        batchesForUser={this.props.batchesForUser}
        isExpanded={this.props.isExpanded}
        onToggle={this.onAccordionToggled}
      />
    );
  }
}

const BatchesList = connect<StateProps, DispatchProps, OwnProps, FullState>(
  _BatchesList.mapStateToProps,
  _BatchesList.mapDispatchToProps
)(_BatchesList);

export default BatchesList;
