import React from 'react';
import useResizeObserver from 'hooks/use-resize-observer';
import { useMemo } from 'react';
import {
  HorizontalGridLines,
  LabelSeries,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from 'react-vis';
import {
  getColorForIdx,
  getLabelForIdx,
  getMarkerForIdx,
  GRID_LINES_STYLE,
  LR_MARGIN,
  LR_MARGIN_WITH_TICKS,
  SERIES_LABEL_PADDING,
  TICK_LINE_AXIS_STYLE,
} from './timeseriesplot';
import {
  CumulativePlotReadings,
  ObservationPointDecorated,
} from 'util/backendapi/types/Model';
import { flatMap, min, range, sortBy } from 'lodash';
import moment from 'moment';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';

interface CumulativePlotProps {
  observationPoint: ObservationPointDecorated;
  itemNumber: number;
  cumulativePlotReadings: CumulativePlotReadings;
  timePeriods: number[];
  width?: number;
  height?: number;
}

const Y_AXIS_DOMAIN_SCALE_FACTOR = 1.05;

const axisStyle = {
  line: {
    stroke: '#cfcfcf',
  },
};

const tickSize = {
  tickSizeOuter: 0,
  tickSizeInner: 5,
};

export function CumulativePlot(props: CumulativePlotProps) {
  const { cumulativePlotReadings, observationPoint, timePeriods } = props;

  const [plotWrapperRef, plotWrapperWidth, plotWrapperHeight] =
    useResizeObserver<HTMLDivElement>({
      defaultWidth: 500,
      defaultHeight: 500,
    });

  const plotSize = useMemo(() => {
    const size: {
      height: number;
      width: number;
    } = {
      height: plotWrapperHeight,
      width: plotWrapperWidth,
    };

    if (props.width) {
      size.width = props.width;
    }

    if (props.height) {
      size.height = props.height;
    }
    return size;
  }, [plotWrapperHeight, plotWrapperWidth, props.width, props.height]);

  const dataSeries = useMemo(() => {
    return sortBy(Object.keys(cumulativePlotReadings.readings)).map((year) => {
      const readings = cumulativePlotReadings.readings[year].map((d, i) => ({
        x: d.doy,
        y: d.value,
      }));
      return { year: +year, readings: readings };
    });
  }, [cumulativePlotReadings]);

  const { xDomain, yDomain } = useMemo(() => {
    const hasLeapYear = dataSeries.some((d) => moment([d.year]).isLeapYear());
    const xDomain = [0, hasLeapYear ? 366 : 365];
    const yDomain = [
      0,
      Math.max(
        ...flatMap(
          dataSeries.map((series) =>
            series.readings.map((d) => d.y * Y_AXIS_DOMAIN_SCALE_FACTOR)
          )
        )
      ),
    ];
    return { xDomain, yDomain };
  }, [dataSeries]);

  const xAxisTitle = i18n._(t`Days since 1st January`);
  const yAxisTitle =
    observationPoint.instrument_type.cumulative_plot_y_axis_title ||
    observationPoint.instrument_type.default_vertical_plot_title;

  const startYear = useMemo(() => min(timePeriods)!, [timePeriods]);

  const { monthTicks, monthLabels } = useMemo(() => {
    const monthNames = range(12).map((i) =>
      moment(new Date(startYear, i, 1)).format('MMM')
    );
    const monthDays = range(12).map((i) =>
      moment(new Date(startYear, i, 1)).daysInMonth()
    );

    const monthTicks: number[] = [];
    const monthLabels: Record<number, string> = {};
    for (let i = 0; i < 12; i++) {
      if (i > 1) {
        monthTicks[i] = monthDays[i - 1] + monthTicks[i - 1];
      } else if (i === 1) {
        monthTicks[i] = monthDays[0];
      } else {
        monthTicks[i] = 0;
      }
      monthLabels[monthTicks[i]] = monthNames[i];
    }
    return { monthTicks, monthLabels };
  }, [startYear]);

  return (
    <>
      <div className="plot-area" ref={plotWrapperRef}>
        <XYPlot
          xDomain={xDomain}
          yDomain={yDomain}
          margin={{ left: LR_MARGIN_WITH_TICKS, right: LR_MARGIN }}
          {...plotSize}
        >
          <HorizontalGridLines style={GRID_LINES_STYLE} />
          <VerticalGridLines style={GRID_LINES_STYLE} tickValues={monthTicks} />
          <XAxis
            orientation="top"
            className="xAxis"
            position="middle"
            hideTicks
            style={TICK_LINE_AXIS_STYLE}
          />
          <YAxis
            orientation="right"
            className="yAxis"
            hideTicks
            style={TICK_LINE_AXIS_STYLE}
          />
          <XAxis
            title={xAxisTitle}
            top={plotSize.height}
            orientation="bottom"
            position="middle"
            hideLine
            hideTicks
          />
          <YAxis
            title={yAxisTitle}
            left={0}
            marginLeft={0}
            orientation="left"
            position="middle"
            hideLine
            hideTicks
          />
          <XAxis
            style={axisStyle}
            {...tickSize}
            className="xAxis"
            tickFormat={(i) => monthLabels[i]}
            tickValues={monthTicks}
          />
          {/* Cumulative plot lines. */}
          {dataSeries.map((series, idx) => (
            <LineSeries
              key={`line-${series.year}`}
              animation={false}
              className="plot-readings-line"
              style={{ stroke: getColorForIdx(idx) }}
              data={series.readings}
            />
          ))}
          {/* Letter labels (A, B, C, etc) at end of each data series line. */}
          {dataSeries.map((series, idx) => {
            const lastReading = series.readings[series.readings.length - 1];
            const letterPlotPoint = {
              x: lastReading.x,
              y: lastReading.y,
              label: getLabelForIdx(idx),
              xOffset: SERIES_LABEL_PADDING,
            };
            return (
              <LabelSeries<{
                x: number;
                y: number;
                label: string;
                xOffset: number;
              }>
                key={`label-${idx}`}
                data={[letterPlotPoint]}
                allowOffsetToBeReversed={false}
                labelAnchorY="middle"
                style={{
                  fill: getColorForIdx(idx),
                  stroke: getColorForIdx(idx),
                }}
                xDomain={xDomain}
                yDomain={yDomain}
              />
            );
          })}
          <YAxis style={axisStyle} {...tickSize} className="yAxis" />
        </XYPlot>
      </div>
      <div className="plot-legend plot-legend-cumulative">
        <ol>
          {dataSeries.map((series, idx) => (
            <div key={idx}>
              <li>
                <span style={{ color: getColorForIdx(idx) }}>
                  {getLabelForIdx(idx)}
                </span>{' '}
                {getMarkerForIdx(idx) && (
                  <>
                    <span
                      className="plot-marker-legend"
                      style={{ color: getColorForIdx(idx) }}
                    ></span>{' '}
                  </>
                )}
                {observationPoint.code} {series.year}
              </li>
            </div>
          ))}
        </ol>
      </div>
    </>
  );
}
