import React from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, Plural, t } from '@lingui/macro';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { reportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { Icon } from 'components/base/icon/icon';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ReportStateProps } from 'hooks/use-report-state';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { DMSLink } from 'components/base/link/DMSLink';

export interface AreaListViewProps {
  reportProps: ReportStateProps<Model.ReportsArea>;
  deleteArea: (id: number) => Promise<any>;
}

export const areaListColumns = (extraProps?: {
  refreshList: () => void;
  deleteArea: (id: number) => Promise<void>;
}): ReportColumn<Model.ReportsArea>[] => {
  return [
    {
      label: <Trans>Area</Trans>,
      name: 'code',
      visibility: DEFAULT_SHOW,
      accessor: ({ code }) => <DMSLink to={`/area/${code}`}>{code}</DMSLink>,
    },
    {
      label: <Trans>Name</Trans>,
      name: 'name',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Client</Trans>,
      name: 'client',
      backendFieldName: 'client__full_name',
      visibility: DEFAULT_SHOW,
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['code', 'id'],
      accessor: ({ code, id }) => (
        <>
          <DMSLink to={`/area/${code}`}>
            <Icon type="icon-view" title={t`View`} />
          </DMSLink>
          <HasPermission check="can_create_areas">
            <DMSLink to={`/area/${code}?edit=1`}>
              <Icon type="icon-edit" title={t`Edit`} />
            </DMSLink>
          </HasPermission>
          <HasPermission check="can_delete_areas">
            <ButtonShowConfirmation
              name="delete-area"
              title={t`Delete`}
              iconType="icon-circle-minus"
              className="btn-link-panel"
              content={
                <Trans>
                  Are you sure you want to delete <strong>{code}</strong>? This
                  action is not reversible.
                </Trans>
              }
              okBtnText={<Trans>Yes, delete</Trans>}
              onConfirm={async function () {
                await extraProps!.deleteArea(id);
                extraProps!.refreshList();
              }}
            />
          </HasPermission>
        </>
      ),
    },
  ];
};

export const areaListFilters: ReportFilter[] = [
  reportFilterMenu(
    'code',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'string' },
    (option: Model.ReportsArea) => ({
      value: option.code,
      label: `${option.code} - ${option.name}`,
    }),
    {
      placeholder: <Trans>Select an area</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterMenu(
    'client',
    <Trans>Client</Trans>,
    { isMulti: true, valueType: 'number' },
    (option: Model.Client) => ({
      value: option.id,
      label: `${option.full_name}`,
    }),
    {
      placeholder: <Trans>Select a client</Trans>,
    }
  ),
];

export const AreaListView = (props: AreaListViewProps) => {
  const { reportProps } = props;
  const columns = areaListColumns({
    refreshList: reportProps.refreshList,
    deleteArea: props.deleteArea,
  });
  return (
    <PageStandard
      name="maint-area-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Areas report</Trans>
        ) : (
          <Trans>Maintain Areas</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_areas">
          <ActionBlock className="text-right">
            <DMSLink to={`/area/new`} className="btn" shortcut="CREATE_NEW">
              <span>
                <Trans>Create area</Trans>
              </span>
              <Icon type="icon-plus" />
            </DMSLink>
          </ActionBlock>
        </HasPermission>

        <ReportFiltersBlock
          filtersBackend={reportProps.reportInfo?.filters}
          filtersFrontend={areaListFilters}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={areaListFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Area report`}
            reportUrl="/reports/areas/"
            columns={columns}
            filters={areaListFilters}
          />
        </ActionBlock>
        <ReportTable
          columns={columns}
          filters={areaListFilters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 area"
              other="Filtered to # areas"
            />
          )}
          msgNoMatches={<Trans>No areas match the selected filters.</Trans>}
        />
      </div>
    </PageStandard>
  );
};
