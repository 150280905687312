import React, { useState } from 'react';
import sortBy from 'lodash/sortBy';
import PanelContent from 'components/base/panel/panelcontent';
import { Model } from 'util/backendapi/models/api.interfaces';
import { t, Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button, { ButtonPrimary } from 'components/base/button/button';
import { AlertDanger, AlertInfo } from 'components/base/alert/alert';
import {
  Formik,
  FormikHelpers,
  FormikErrors,
  Form,
  ErrorMessage,
} from 'formik';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import './ClientUsersPanel.scss';
import Loading from 'components/base/loading/loading';
import { errorToString } from 'util/backendapi/error';

interface MyFormValues {
  users: number;
}

export interface ClientUsersPanelProps {
  client: null | Model.Client;
  isLoading: boolean;
  canEdit: boolean;
  clientUsers: Array<Model.User>;
  availableUsers: Array<Model.User>;
  onSubmitAdd: (userId: number) => void;
  onRemoveUser: (userId: number) => void;
}

export function ClientUsersPanelView(props: ClientUsersPanelProps) {
  const header = <Trans>{props.client?.name} members</Trans>;

  const clientUsersSorted = React.useMemo(
    () => sortBy(props.clientUsers, ['profile.name', 'username']),
    [props.clientUsers]
  );

  const [removeUserErrorMessage, setRemoveUserErrorMessage] =
    useState<string>('');

  const { onRemoveUser } = props;

  // Menu items for the "add a user" menu.
  const usersMenuItems = React.useMemo(() => {
    // Don't bother with this if they lack edit permission.
    if (!props.canEdit) {
      return [];
    }

    const sortedAvailableUsers = sortBy(props.availableUsers, [
      'profile.name',
      'username',
    ]);
    return sortedAvailableUsers.map((user) => ({
      value: user.id,
      label: `${user.profile.name} (${user.username})`,
    }));
  }, [props.availableUsers, props.canEdit]);

  // Callback to handle validating the "add user" form
  const validateAdd = React.useCallback(
    (values: MyFormValues): FormikErrors<MyFormValues> => {
      if (!values.users) {
        return {
          users: (<Trans>This field is required</Trans>) as any,
        };
      } else {
        return {};
      }
    },
    []
  );

  // Callback to handle submitting the "add user" form
  const handleSubmitAdd = React.useCallback(
    (values: MyFormValues, actions: FormikHelpers<MyFormValues>): void => {
      try {
        props.onSubmitAdd.call(null, values.users as number);
      } catch (e) {
        showErrorsInFormik(actions, e, ['users']);
      } finally {
        actions.setSubmitting(false);
        setShowNewMemberForm(false);
      }
    },
    [props.onSubmitAdd]
  );

  const handleRemoveUser = React.useCallback(
    async (userId: number) => {
      try {
        setRemoveUserErrorMessage('');
        await onRemoveUser(userId);
      } catch (e) {
        setRemoveUserErrorMessage(errorToString(e));
      }
    },
    [onRemoveUser, setRemoveUserErrorMessage]
  );

  const [showNewMemberForm, setShowNewMemberForm] = useState(false);

  return (
    <PanelContent header={header}>
      {props.canEdit && !showNewMemberForm && (
        <ActionBlock>
          <Button
            id={`client-panel-add-person-button`}
            iconType="icon-plus"
            className="btn-tab"
            onClick={() => setShowNewMemberForm(true)}
          >
            <Trans>Add a person</Trans>
          </Button>
        </ActionBlock>
      )}
      {props.canEdit && showNewMemberForm && (
        <div className="panel-form-wrapper">
          <div className="panel-form-body">
            <Formik
              initialValues={{ users: 0 }}
              validate={validateAdd}
              onSubmit={handleSubmitAdd}
            >
              {(formik) => (
                <Form>
                  <FormSection
                    label={
                      <Trans>
                        Associate a person with {props.client?.name}
                      </Trans>
                    }
                  >
                    {formik.status}
                    <FormItem
                      label={<Trans>Person</Trans>}
                      fieldId="client-users-menu"
                    >
                      <SimpleSelectField
                        name="users"
                        id="client-users-menu"
                        options={usersMenuItems}
                        isDisabled={formik.isSubmitting}
                      />
                      <ErrorMessage name="users" component={ErrorNotice} />
                    </FormItem>
                    <UserAreaGroupsTable
                      user={props.availableUsers.find(
                        (user) => user.id === formik.values.users
                      )}
                      tableCaption={
                        <Trans>Selected person’s group and roles</Trans>
                      }
                    />
                  </FormSection>
                  <ActionBlock>
                    <Button
                      onClick={() => setShowNewMemberForm(false)}
                      disabled={formik.isSubmitting}
                    >
                      <Trans>Cancel</Trans>
                    </Button>
                    <ButtonPrimary
                      id="client-panel-add-person-submit"
                      type="submit"
                      disabled={formik.isSubmitting}
                      iconType="icon-save"
                    >
                      <Trans>Add person</Trans>
                    </ButtonPrimary>
                  </ActionBlock>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
      {props.isLoading ? (
        <Loading />
      ) : props.clientUsers.length === 0 ? (
        <AlertInfo>
          <Trans>This client has no members</Trans>
        </AlertInfo>
      ) : (
        <>
          {removeUserErrorMessage !== '' && (
            <AlertDanger>{removeUserErrorMessage}</AlertDanger>
          )}
          <ul className="panel-member-list">
            {clientUsersSorted.map((user) => (
              <li key={user.id}>
                <div className="columns-fluid">
                  <strong>
                    {user.profile.name} ({user.username})
                  </strong>
                  <div className="action-icons text-right">
                    {props.canEdit ? (
                      <Button
                        iconOnly={true}
                        iconType="icon-circle-minus"
                        name="remove-user"
                        className="btn-link-panel"
                        title={t`Remove user`}
                        onClick={() => handleRemoveUser(user.id)}
                      >
                        <Trans>Remove user</Trans>
                      </Button>
                    ) : null}
                  </div>
                </div>
                <UserAreaGroupsTable user={user} />
              </li>
            ))}
          </ul>
        </>
      )}
    </PanelContent>
  );
}

interface UserAreaGroupsTableProps {
  user: Model.User | undefined;
  tableCaption?: React.ReactNode;
}
const UserAreaGroupsTable: React.FunctionComponent<UserAreaGroupsTableProps> =
  React.memo(function (props) {
    if (!props.user) {
      return null;
    }
    return props.user.user_area_groups.length === 0 ? (
      <p>
        <Trans>Not a member of any groups</Trans>
      </p>
    ) : (
      <table className="member-table">
        {props.tableCaption && <caption>{props.tableCaption}</caption>}
        <thead className="visuallyhidden">
          <tr>
            <th>
              <Trans>Group</Trans>
            </th>
            <th>
              <Trans>Role</Trans>
            </th>
          </tr>
        </thead>
        <tbody>
          {sortBy(
            props.user.user_area_groups,
            (userAreaGroup) => userAreaGroup.area_group.name
          ).map((userAreaGroup: Model.UserAreaGroupNested) => (
            <tr key={userAreaGroup.area_group.id}>
              <th scope="row">{userAreaGroup.area_group.name}</th>
              <td>
                <ul>
                  {sortBy(userAreaGroup.roles, (role) => role.name).map(
                    (role) => (
                      <li key={role.id}>{role.name}</li>
                    )
                  )}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  });
