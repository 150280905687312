import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportFilter,
  ReportViewProps,
  ReportColumn_CANSELECT,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import { DMSLink } from 'components/base/link/DMSLink';
import { alarmReportColumns } from 'screens/alarmreports/AlarmReportListScreen';
import { Icon } from 'components/base/icon/icon';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import {
  START_OF_DAY,
  END_OF_DAY,
  formatDateForDisplay,
  formatDatetimeForDisplay,
} from 'util/dates';
import { useReportState } from 'hooks/use-report-state';
import { alarmCommentsReportDuck } from 'ducks/alarm-report/list';
import { RouteComponentProps } from 'react-router';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { useRouteComponentProps } from 'util/hooks';
import { reportFilterDateNoMenu } from 'components/modules/report/filter/fields/FilterDateNoMenu';
import { NO_TIMEZONE } from 'components/modules/report/filter/fields/FilterDate';
import { MomentInput } from 'moment-timezone';

export const alarmCommentsReportFilters: ReportFilter[] = [
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    {
      placeholder: <Trans>All areas</Trans>,
      autoFocus: true,
    }
  ),
  reportFilterDateNoMenu(
    'start-reading-date',
    'adjusted_reading_entry__reading__reading_datetime__gte',
    <Trans>Start reading date</Trans>,
    NO_TIMEZONE,
    START_OF_DAY
  ),
  reportFilterDateNoMenu(
    'end-reading-date',
    'adjusted_reading_entry__reading__reading_datetime__lte',
    <Trans>End reading date</Trans>,
    NO_TIMEZONE,
    END_OF_DAY
  ),
];

/**
 * Most of the columns on this report are exactly the same as columns in the
 * standard alarm reports report. This function just plucks them out from the
 * column definitions for that report.
 *
 * @param colName
 */
function reuseAlarmReportsReportColumn(
  colName: string,
  visibility: ReportColumn_CANSELECT = DEFAULT_SHOW
): ReportColumn<Model.ReportsAlarmComment> {
  return {
    ...alarmReportColumns.find(({ name }) => name === colName)!,
    visibility,
  };
}

function formatWrappedDatetimeForDisplay(
  datetime: MomentInput | null,
  timezone?: null | string
) {
  const formattedDatetime = formatDatetimeForDisplay(datetime, timezone);

  // Replace the first space between the date and time with an HTML link break
  const spaceIndex = formattedDatetime.indexOf(' ');
  if (spaceIndex !== -1) {
    const dateString = formattedDatetime.substr(0, spaceIndex);
    const timeString = formattedDatetime.substr(spaceIndex + 1);

    return (
      <>
        {dateString}
        <br />
        {timeString}
      </>
    );
  }

  return formattedDatetime;
}

export const alarmCommentsReportColumns: ReportColumn<Model.ReportsAlarmComment>[] =
  [
    {
      label: <Trans>Observation point</Trans>,
      name: 'observation-point',
      visibility: DEFAULT_SHOW,
      backendFieldName: 'observation_point__code',
      additionalFields: ['observation_point'],
      accessor: ({ observation_point: id, observation_point__code: code }) => (
        <DMSLink to={`/alarm-reports?observation_point=${id}`}>{code}</DMSLink>
      ),
    },
    // Reading date and time
    {
      ...reuseAlarmReportsReportColumn('reading-datetime'),
      accessor: ({
        adjusted_reading_entry__reading__reading_datetime: reading_datetime,
        area__time_zone__name: time_zone,
      }) =>
        reading_datetime &&
        formatWrappedDatetimeForDisplay(reading_datetime, time_zone),
    },
    // Alarm report date and time

    {
      ...reuseAlarmReportsReportColumn('created-datetime'),
      accessor: ({ created_datetime, area__time_zone__name: time_zone }) =>
        created_datetime &&
        formatWrappedDatetimeForDisplay(created_datetime, time_zone),
    },
    // Alarm number
    reuseAlarmReportsReportColumn('alarm-number'),
    // Alarm level
    reuseAlarmReportsReportColumn('level'),
    // Alarm parameter
    reuseAlarmReportsReportColumn('threshold'),
    // Adjusted reading
    reuseAlarmReportsReportColumn('adjusted-reading-value'),
    // Category
    reuseAlarmReportsReportColumn('category'),
    {
      label: <Trans>Comments</Trans>,
      name: 'comments',
      visibility: DEFAULT_SHOW,
      backendFieldName: 'comments',
      tdClassName: 'text-with-linebreaks',
    },
    // Alarm status
    reuseAlarmReportsReportColumn('status', DEFAULT_HIDE),
    {
      ...ACTION_COLUMN,
      additionalFields: ['id'],
      accessor: ({ id }) => (
        <DMSLink to={`/alarm-reports/${id}`}>
          <Icon type="icon-view" title={t`View alarm report`} />
        </DMSLink>
      ),
    },
  ];

export function AlarmCommentsReportScreen(props: RouteComponentProps) {
  const reportState = useReportState(
    props,
    alarmCommentsReportColumns,
    alarmCommentsReportFilters,
    alarmCommentsReportDuck,
    (state) => state.alarmReports.alarmCommentsReport
  );

  return <AlarmCommentsReportView reportProps={reportState} />;
}

export function AlarmCommentsReportView(props: {
  reportProps: ReportViewProps<Model.ReportsAlarmComment>;
}) {
  const { reportProps } = props;
  const filters = alarmCommentsReportFilters;
  const columns = alarmCommentsReportColumns;
  const routeProps = useRouteComponentProps();
  let pdfExportFilterMessage = null;
  if (reportProps.isExportMode) {
    const curFilters = new URLSearchParams(routeProps.location.search);
    const startDate = curFilters.get('start-reading-date');
    const endDate = curFilters.get('end-reading-date');
    pdfExportFilterMessage = (
      <div className="ssr-active-filters-block">
        <Trans>
          Data between {startDate ? formatDateForDisplay(startDate) : '-'} and{' '}
          {endDate ? formatDateForDisplay(endDate) : '-'}
        </Trans>
      </div>
    );
  }
  return (
    <PageStandard
      name="alarm-comments-report"
      header={<Trans>Alarm Report Summary</Trans>}
    >
      {pdfExportFilterMessage ? (
        pdfExportFilterMessage
      ) : (
        <div className="page-content-header-filters-actions">
          <ReportFiltersBlock
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={filters}
            isExportMode={reportProps.isExportMode}
          />
        </div>
      )}
      <div className="filtered-table-wrapper">
        <ActionBlock className="text-right">
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ButtonPrint />
          <ExportReportButton
            fileNameBase={t`Alarm comments`}
            reportUrl="/reports/alarm-comments/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>
        <ReportTable<Model.ReportsAlarmComment>
          {...reportProps}
          columns={columns}
          filters={filters}
        />
      </div>
    </PageStandard>
  );
}
