import React from 'react';
import { FormikHelpers } from 'formik';
import { DRFError, formatErrorsForFormik } from './error';
import { AlertDanger } from 'components/base/alert/alert';

/**
 * A helper function to just take that DRFError | Error, and plop it directly
 * into Formik via `setErrors()` and `setStatus()`. I extracted this into a
 * function because this tended to be exactly the same every place we used
 * `formatErrorsForFormik()`.
 *
 * This function expects that your form field names match up with the backend
 * field names, and that in your form body you display `{formik.status}`
 * somewhere.
 *
 * @param formik
 * @param error
 * @param expectedFields
 * @param ErrorComponent
 */
export function showErrorsInFormik<FormValues = Record<string, any>>(
  formik: FormikHelpers<FormValues>,
  error: unknown | string | Error | DRFError<FormValues>,
  expectedFields?: string[],
  ErrorComponent: React.ComponentType = AlertDanger
): void {
  const { fieldErrors, otherErrors } = formatErrorsForFormik(
    error,
    expectedFields
  );
  formik.setErrors(fieldErrors);
  if (otherErrors.length) {
    formik.setStatus(
      otherErrors.map((errorMsg, idx) => (
        <ErrorComponent key={idx}>{errorMsg}</ErrorComponent>
      ))
    );
  }
}
