import React, { useMemo } from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, Plural, t } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { Model } from 'util/backendapi/models/api.interfaces';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  ACTION_COLUMN,
  ReportColumn,
  ReportFilter,
} from 'components/modules/report/report-types';
import { Icon } from 'components/base/icon/icon';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ReportStateProps } from 'hooks/use-report-state';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { mediaListColumns } from './MediaListView';

export interface MyExportsListViewProps {
  reportProps: ReportStateProps<Model.ReportsMedia>;
  deleteMedia: (id: number) => Promise<any>;
}

export const myExportsListColumns: (props: {
  refreshList: () => void;
  deleteMedia: (id: number) => Promise<any>;
}) => ReportColumn<Model.ReportsMedia>[] = ({ refreshList, deleteMedia }) => {
  const myExportsColumns = [
    'file_name',
    'file_type',
    'file_size',
    'uploaded_by',
    'uploaded_datetime',
  ];
  return mediaListColumns({
    refreshList,
    deleteMedia,
  })
    .filter(({ name }) =>
      myExportsColumns.find((columName) => columName === name)
    )
    .concat([
      {
        ...ACTION_COLUMN,
        additionalFields: ['id', 'file', 'file_name'],
        accessor: ({ id, file, file_name }) => (
          <>
            <a href={file} target="_blank" rel="noopener noreferrer">
              <Icon type="icon-view" title={t`View`} />
            </a>
            <HasPermission check="can_delete_media">
              <ButtonShowConfirmation
                name="delete-media"
                title={t`Delete`}
                iconType="icon-circle-minus"
                className="btn-link-panel"
                content={
                  <Trans>
                    Are you sure you want to delete <strong>{file_name}</strong>
                    ? This action is not reversible.
                  </Trans>
                }
                okBtnText={<Trans>Yes, delete</Trans>}
                onConfirm={async function () {
                  await deleteMedia(id!);
                  refreshList();
                }}
              />
            </HasPermission>
          </>
        ),
      },
    ]);
};

export const myExportsListFilters: ReportFilter[] = [];

export function MyExportsListView(props: MyExportsListViewProps) {
  const { deleteMedia } = props;
  const { refreshList } = props.reportProps;

  const columns = useMemo(
    () =>
      myExportsListColumns({
        refreshList,
        deleteMedia,
      }),
    [refreshList, deleteMedia]
  );

  const filters = myExportsListFilters;
  const { reportProps } = props;
  return (
    <PageStandard
      name="my-exports-list"
      header={
        reportProps.isExportMode ? (
          <Trans>My exports report</Trans>
        ) : (
          <Trans>My Exports</Trans>
        )
      }
    >
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`My exports report`}
            reportUrl="/reports/my-exports/"
            columns={columns}
            filters={filters}
          />
        </ActionBlock>
        <ReportTable
          columns={columns}
          filters={filters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 export"
              other="Filtered to # exports"
            />
          )}
          msgNoMatches={<Trans>No exports match the selected filters.</Trans>}
        />
      </div>
    </PageStandard>
  );
}
