import React from 'react';
import { Trans } from '@lingui/macro';
import { Field, FormikProps } from 'formik';
import lodashGet from 'lodash/get';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { formatDatetimeForDisplay } from 'util/dates';
import Button from 'components/base/button/button';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import Loading from 'components/base/loading/loading';
import {
  CurrentFormulaProps,
  CurrentFormulaFormValues,
} from './currentformula';
import { EditableCardSectionComponent } from 'components/base/form/editablecard/editablecard';
import { FormulaSnapshotChanges } from './currentformula.types';
import {
  CardSectionFieldMultiColumn,
  CardSectionField,
  FormCardSection,
  CardSectionRow,
} from 'components/base/card/card';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { validateNumber, isTruthy, isNotNull } from 'util/validation';
import { getSafely, rangeInclusive } from 'util/misc';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import { IntervalField } from 'components/base/form/interval/intervalfield';
import {
  ObsPointItemMenu,
  makeObsPointItemMenuOption,
  getObsPointItemIdent,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import {
  RadioField,
  RadioFieldProps,
} from 'components/base/form/radio-field/RadioField';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import {
  isValueConstant,
  isReferenceConstant,
  isDecimalPlacesConstant,
  isComparisonOperatorConstant,
} from 'util/backendapi/models/formulaconstant';
import { sortBy } from 'lodash';
import { DMSLink } from 'components/base/link/DMSLink';
import './formbody.scss';

type MyFormikProps = FormikProps<CurrentFormulaFormValues>;

export interface ObsPointFormulaCardProps {
  isLoading: boolean;
  formulaOptions?: CurrentFormulaProps['formulaOptions'];
  formulas: Model.FormulaDecorated[];
  observationPoint: Model.ObservationPointDecorated | null;
  observationPointFormula: Model.ObservationPointFormulaSnapshot | null;
  siteDecorated: Model.SiteDecorated | null;
  relatedObservationPoints: Model.ObservationPointDecorated[] | null;
  CardSectionComponent: EditableCardSectionComponent;
  changed?: FormulaSnapshotChanges;
  isEditing: boolean;
  isHistory: boolean;
  formik?: MyFormikProps;
  shouldDisable?: boolean;
  handleChangeFormulaMenu?: (
    formik: MyFormikProps,
    selection: number | null
  ) => boolean;
  handleClickFormulaChangeButton?: (formik: MyFormikProps) => void;
  handleClickOpficChangeButton?: (
    formik: MyFormikProps,
    formikValueName: string
  ) => void;
  namePrefix: string;
  canViewFormulaConstantReport?: boolean;
  canViewFormulaAssignmentReport?: boolean;
  canViewSiteReport?: boolean;
}
/**
 * A tightly-coupled helper component to render the form body for the current
 * formula. Separated into its own component mainly so that it can have its
 * own "shouldComponentUpdate()" method, which makes checks based on props
 * *and* part of the formik state.
 */
export class ObsPointFormulaCard extends React.Component<ObsPointFormulaCardProps> {
  shouldComponentUpdate(nextProps: ObsPointFormulaCardProps) {
    const fieldsToShallowCompare: Array<keyof ObsPointFormulaCardProps> = [
      'formulas',
      'formulaOptions',
      'isLoading',
      'observationPoint',
      'siteDecorated',
      'isEditing',
      'shouldDisable',
      'changed',
    ];
    const formikFieldsToDeepCompare: Array<keyof MyFormikProps> = [
      'status',
      'values',
      'errors',
      'touched',
    ];

    return (
      fieldsToShallowCompare.some(
        (fieldName) => nextProps[fieldName] !== this.props[fieldName]
      ) ||
      formikFieldsToDeepCompare.some(
        (field) =>
          !isEqual(
            nextProps.formik && nextProps.formik[field],
            this.props.formik && this.props.formik[field]
          )
      )
    );
  }

  addCardPrefixToName = (name: string) => `${this.props.namePrefix}${name}`;

  render() {
    const {
      CardSectionComponent,
      formik,
      observationPointFormula: opf,
      observationPoint,
    } = this.props;

    const timeZone = this.props.observationPoint
      ? this.props.observationPoint.time_zone.name
      : undefined;

    const formulaId =
      this.props.isEditing && formik
        ? formik.values.formula
        : opf && opf.observation_point_formula.formula;
    const formula =
      Boolean(formulaId) && this.props.formulas.find((f) => f.id === formulaId);

    // If in edit mode, display information from the form.
    // If not in edit mode, display information from the props.
    const showNoFormulaMessage = !this.props.isEditing && !formula;
    const formulaHasNoInputs =
      !this.props.isLoading && formula && formula.formula_inputs.length === 0;
    const formulaHasNoConstants =
      !this.props.isLoading &&
      formula &&
      formula.formula_constants.length === 0;

    return (
      <>
        {formik && formik.status}
        <CardSectionComponent
          name={this.addCardPrefixToName('formula-overview')}
          className="formula-overview"
          header={<Trans>Formula</Trans>}
          fields={
            !this.props.isLoading &&
            !showNoFormulaMessage && [
              {
                name: this.addCardPrefixToName('formula'),
                className: classNames({
                  highlight: this.props.changed && this.props.changed.formula,
                }),
                // First row, first column: Formula name/description
                columns: [
                  {
                    name: this.addCardPrefixToName('formula-description'),
                    label: <Trans>Formula</Trans>,
                    content:
                      this.props.isEditing &&
                      formik &&
                      formik.values.isEditingFormula ? (
                        // can change the formula: show a menu
                        <>
                          {this.props.formulaOptions ? (
                            <SimpleSelectField<number>
                              data-testid="change-formula-menu"
                              options={this.props.formulaOptions}
                              name="formula"
                              isDisabled={this.props.shouldDisable}
                              onChange={(selection: number | null) =>
                                this.props.handleChangeFormulaMenu!(
                                  formik,
                                  selection
                                )
                              }
                            />
                          ) : (
                            <Loading />
                          )}
                          <FieldError name="formula" />
                        </>
                      ) : (
                        // cannot change the formula: show the current formula descr
                        <>
                          <span className="change-formula-value">
                            {formula && `${formula.code} - ${formula.name}`}
                          </span>

                          {this.props.isEditing && formik && (
                            <Button
                              name="isEditingFormula"
                              className="change-formula-button"
                              data-testid="change-formula-button"
                              disabled={this.props.shouldDisable}
                              onClick={() => {
                                this.props.handleClickFormulaChangeButton!(
                                  formik
                                );
                              }}
                            >
                              <Trans>Change formula</Trans>
                            </Button>
                          )}
                        </>
                      ),
                  },
                  // First row, second column: When the card is in "view" mode,
                  // display a link to the formula assignment report
                  observationPoint &&
                  this.props.canViewFormulaAssignmentReport &&
                  !this.props.isHistory &&
                  !this.props.isEditing
                    ? {
                        name: this.addCardPrefixToName('view-history-link'),
                        content: (
                          <DMSLink
                            to={`/formula-assignment-report/?observation_point=${observationPoint.id}&ordering=-start_datetime&now=false`}
                          >
                            <Trans>View history</Trans>
                          </DMSLink>
                        ),
                      }
                    : null,
                ].filter(isNotNull),
              },
              // Second row: formula start datetime
              {
                name: this.addCardPrefixToName('start_datetime'),
                className: classNames({
                  highlight: this.props.changed && this.props.changed.formula,
                }),
                columns:
                  this.props.isEditing &&
                  formik &&
                  formik.values.isEditingFormula
                    ? [
                        {
                          name: 'start_date',
                          label: <Trans>Formula start</Trans>,
                          content: (
                            <>
                              <DatetimeField
                                name="start_datetime"
                                data-testid={this.addCardPrefixToName(
                                  'start_datetime'
                                )}
                                timeZone={timeZone}
                                disabled={
                                  this.props.shouldDisable ||
                                  !formik.values.isEditingFormula
                                }
                              />
                              <FieldError name="start_datetime" />
                            </>
                          ),
                        },
                        {
                          name: 'copy_start_date',
                          content: (
                            <Button
                              name="copy-date"
                              className="input-unit"
                              onClick={() => {
                                if (!formula) {
                                  return;
                                }
                                formula.formula_inputs.forEach((fi) => {
                                  const startDateFieldName = `observation_point_formula_inputs.${fi.var_name}.start_datetime`;
                                  if (
                                    lodashGet(
                                      formik.values,
                                      startDateFieldName
                                    ) !== undefined
                                  ) {
                                    formik.setFieldValue(
                                      startDateFieldName,
                                      formik.values.start_datetime
                                    );
                                  }
                                });

                                formula.formula_constants.forEach((fc) => {
                                  if (!fc.site_time_dependent_field_name) {
                                    const startDateFieldName = `observation_point_formula_constant_values.${fc.var_name}.start_datetime`;
                                    if (
                                      lodashGet(
                                        formik.values,
                                        startDateFieldName
                                      ) !== undefined
                                    ) {
                                      formik.setFieldValue(
                                        startDateFieldName,
                                        formik.values.start_datetime
                                      );
                                    }
                                  }
                                });
                              }}
                            >
                              Copy Date
                            </Button>
                          ),
                        },
                      ]
                    : [
                        {
                          name: 'observation_point_view',
                          label: <Trans>Formula start</Trans>,
                          content: (
                            <p className="non-editable-value">
                              {formatDatetimeForDisplay(
                                (opf &&
                                  opf.observation_point_formula
                                    .start_datetime) ||
                                  '',
                                timeZone
                              )}
                            </p>
                          ),
                        },
                      ],
              },
            ]
          }
        >
          {this.props.isLoading ? (
            <Loading />
          ) : (
            showNoFormulaMessage && (
              <span className="section-message">
                <Trans>No formula associated with the observation point</Trans>
              </span>
            )
          )}
        </CardSectionComponent>
        {formula && formula.id && (
          <CardSectionComponent
            name={this.addCardPrefixToName('formula-inputs')}
            header={
              formula.code ? (
                <Trans>{formula.code} inputs</Trans>
              ) : (
                <Trans>Formula inputs</Trans>
              )
            }
            fields={sortBy(formula.formula_inputs, 'position').flatMap(
              (formulaInput) => {
                let renderRowFn: OpficFieldRenderFn<OpfInputFieldProps>;
                switch (formulaInput.type) {
                  case Enum.FormulaInput_TYPE.raw_reading:
                    renderRowFn = renderRawReadingInput;
                    break;
                  case Enum.FormulaInput_TYPE.comp_reading:
                    renderRowFn = renderCompensationInputWithTolerance;
                    break;
                  case Enum.FormulaInput_TYPE.summation:
                    renderRowFn = renderSummationInput;
                    break;
                  case Enum.FormulaInput_TYPE.chainable_reading:
                    renderRowFn = renderChainableInput;
                    break;
                  default:
                    // eslint-disable-next-line no-console
                    console.error('Unknown formula input type:', formulaInput);
                    renderRowFn = () => [];
                }
                return this.renderInputOrConstantRow(
                  'input',
                  formik,
                  formulaInput,
                  renderRowFn
                );
              }
            )}
          >
            {formulaHasNoInputs && (
              <Trans>No inputs for the selected formula</Trans>
            )}
          </CardSectionComponent>
        )}
        {formula && formula.id && (
          <CardSectionComponent
            name={this.addCardPrefixToName('formula-constants')}
            header={
              formula.code ? (
                <Trans>{formula.code} constants</Trans>
              ) : (
                <Trans>Formula constants</Trans>
              )
            }
            fields={sortBy(formula.formula_constants, 'position').flatMap(
              (formulaConstant) => {
                let renderRowFn: OpficFieldRenderFn<OpfConstantFieldProps>;
                if (isValueConstant(formulaConstant)) {
                  renderRowFn = renderValueConstant;
                } else if (isDecimalPlacesConstant(formulaConstant)) {
                  renderRowFn = renderDecimalPlacesConstant;
                } else if (isReferenceConstant(formulaConstant)) {
                  renderRowFn = renderReferenceConstant;
                } else if (isComparisonOperatorConstant(formulaConstant)) {
                  renderRowFn = renderComparisonOperatorConstant;
                } else {
                  // eslint-disable-next-line no-console
                  console.error(
                    'Unknown formula constant type:',
                    formulaConstant
                  );
                  renderRowFn = () => [];
                }
                return this.renderInputOrConstantRow(
                  'constant',
                  formik,
                  formulaConstant,
                  renderRowFn
                );
              }
            )}
          >
            {formulaHasNoConstants && (
              <Trans>No constants for the selected formula</Trans>
            )}
          </CardSectionComponent>
        )}
      </>
    );
  }

  /**
   * A function to render the fields for the OPFI's and OPFC's.
   * ("opfic" means "OPFI/OPFC", and "fic" means "FI/FC").
   *
   * Most of the actual rendering happens in the `renderFn()` callback, which
   * is different for each type of input and constant. This function just
   * implements the shared logic which is the same for all types.
   */
  renderInputOrConstantRow = <T extends OpficFieldProps>(
    inputOrConstant: 'input' | 'constant',
    formik: MyFormikProps | undefined,
    ficObj: Model.FormulaInput | Model.FormulaConstant,
    renderFn: OpficFieldRenderFn<T>
  ): CardSectionRow[] => {
    // Determine some names according to standard patterns, and retrieve
    // the observation point formula instance record for this input/constant
    // (if it has one)
    const var_name = ficObj.var_name;
    const { observationPointFormula: opf, shouldDisable: isCardDisabled } =
      this.props;
    // A screen-unique name prefix for components of this input/constant
    const rowNameBase = this.addCardPrefixToName(
      `formula-${inputOrConstant}-${var_name}`
    );
    // The path to the object in formik's form values, which holds any inputs
    // for this opfic.
    const formikNameBase = `observation_point_formula_${
      inputOrConstant === 'input' ? 'inputs' : 'constant_values'
    }.${var_name}`;
    // The opfi or opfcv (if any)
    const opficObj =
      (opf &&
        (inputOrConstant === 'input'
          ? opf.observation_point_formula_inputs
          : opf.observation_point_formula_constant_values)[var_name]) ||
      null;

    // Check whether this row is marked as one that changed (in case we're
    // displaying formula history)
    const shouldHighlightRow =
      this.props.changed &&
      (inputOrConstant === 'input'
        ? this.props.changed.formulaInputs[var_name]
        : this.props.changed.formulaConstants[var_name]);

    // Whether to display this field in "edit mode"
    const isCardEditMode = this.props.isEditing;
    // If we're in edit mode, whether this field is unlocked for editing
    const isEditingThisField =
      isCardEditMode &&
      formik &&
      lodashGet(formik.values, `${formikNameBase}.isEditing`, false);

    const fieldProps: OpficFieldProps = {
      ficObj,
      opficObj,
      formik,
      formikNameBase,
      className: classNames({
        highlight: shouldHighlightRow,
      }),
      rowNameBase: this.addCardPrefixToName(`${rowNameBase}`),
      isCardEditMode,
      isChangeButtonVisible: isCardEditMode && !isEditingThisField,
      isChangeButtonDisabled: isCardEditMode && isCardDisabled,
      isFieldDisabled:
        isCardEditMode && (isCardDisabled || !isEditingThisField),
      onClickChangeButton:
        formik &&
        (() =>
          this.props.handleClickOpficChangeButton!(formik, formikNameBase)),
      cardProps: this.props,
    };

    return renderFn(fieldProps as T);
  };
}

/**
 * All of the editable input and constant types have a "start date" field,
 * which is rendered after the value field. They also have an individual
 * "Edit" button to unlock editing that individual field (when the card
 * is in edit mode)
 *
 * This function renders both of those, side by side in the same card column.
 *
 * @param props
 */
function renderStartDatetimeAndEditButton(
  props: OpficFieldProps
): CardSectionField {
  const { isCardEditMode, formikNameBase, opficObj, rowNameBase, cardProps } =
    props;
  const timeZone = cardProps.observationPoint
    ? cardProps.observationPoint.time_zone.name
    : undefined;
  return {
    name: `${rowNameBase}-start_datetime`,
    label: <Trans>Start date</Trans>,
    content: isCardEditMode ? (
      <>
        <DatetimeField
          name={`${formikNameBase}.start_datetime`}
          data-testid={`${rowNameBase}.start_datetime`}
          timeZone={timeZone}
          disabled={props.isFieldDisabled}
        />
        <FieldError name={`${formikNameBase}.start_datetime`} />
        {props.isChangeButtonVisible && renderEditButton(props)}
      </>
    ) : (
      <p className="non-editable-value">
        {opficObj
          ? formatDatetimeForDisplay(opficObj.start_datetime, timeZone)
          : ''}
      </p>
    ),
  };
}

/**
 * Render start date field (no edit button).
 *
 * @param props
 */
function renderStartDatetime(props: OpficFieldProps): CardSectionField {
  return renderStartDatetimeAndEditButton({
    ...props,
    isChangeButtonVisible: false,
  });
}

/**
 * Conditionally render the edit button (no start date field)
 *
 * @param props
 */
function renderEditButton(props: OpficFieldProps): React.ReactNode {
  return (
    <Button
      name={`${props.formikNameBase}.isEditing`}
      disabled={props.isChangeButtonDisabled}
      onClick={props.onClickChangeButton}
    >
      <Trans>Edit</Trans>
    </Button>
  );
}

function renderTolerance(props: OpfInputFieldProps, label: React.ReactNode) {
  const {
    formikNameBase,
    isFieldDisabled,
    isCardEditMode,
    rowNameBase,
    opficObj: opfi,
  } = props;
  return {
    name: `${rowNameBase}-tolerance`,
    label,
    formGroupClassName: 'form-group-interval-select',
    content: isCardEditMode ? (
      // EDIT MODE
      <>
        <IntervalField
          disabled={isFieldDisabled}
          name={`${formikNameBase}.tolerance`}
        />{' '}
        <FieldError name={`${formikNameBase}.tolerance`} />
      </>
    ) : (
      // VIEW MODE
      <p className="non-editable-value">
        <IntervalDisplay value={opfi?.tolerance} />
      </p>
    ),
  };
}

function renderToleranceWithRadios(
  props: OpfInputFieldProps,
  label: React.ReactNode,
  firstRadioLabel: React.ReactNode,
  secondRadioLabel: React.ReactNode
) {
  const {
    formikNameBase,
    isCardEditMode,
    isFieldDisabled,
    rowNameBase,
    formik,
    opficObj: opfi,
    ficObj: formulaInput,
  } = props;

  const showToleranceMenu = getSafely(
    () =>
      props.isCardEditMode && formik
        ? formik.values.observation_point_formula_inputs[formulaInput.var_name]
            .hasSummationTolerance
        : Boolean(opfi!.tolerance),
    false
  );

  return {
    name: `${rowNameBase}-tolerance`,
    formGroupClassName: 'form-group-interval-select',
    columns: isCardEditMode
      ? // EDIT MODE
        [
          {
            name: `${rowNameBase}-tolerance-option`,
            label,
            content: (
              <>
                <RadioField
                  name={`${formikNameBase}.hasSummationTolerance`}
                  disabled={isFieldDisabled}
                  options={[
                    {
                      label: <Trans>{firstRadioLabel}</Trans>,
                      value: true,
                    },
                    {
                      label: <Trans>{secondRadioLabel}</Trans>,
                      value: false,
                    },
                  ]}
                />
                <FieldError name={`${formikNameBase}.hasSummationTolerance`} />
              </>
            ),
          },
          {
            name: `${rowNameBase}-tolerance-menu`,
            content: (
              <>
                <div className="form-group-radio-input">
                  <IntervalField
                    disabled={!showToleranceMenu}
                    name={`${formikNameBase}.tolerance`}
                  />{' '}
                  <FieldError name={`${formikNameBase}.tolerance`} />
                </div>
              </>
            ),
          },
        ]
      : // VIEW MODE
        [
          {
            name: `${rowNameBase}-tolerance-view`,
            label,
            content: opfi?.tolerance ? (
              <p className="non-editable-value">
                <IntervalDisplay value={opfi?.tolerance} />
              </p>
            ) : (
              <p className="non-editable-value">
                One reading per day for each summation component
              </p>
            ),
          },
        ],
  };
}

function renderFormulaConstantHistoryLink(
  rowNameBase: string,
  formulaConstant: Model.FormulaConstant,
  observationPoint: Model.ObservationPointDecorated | null,
  canViewFormulaConstantReport: boolean | undefined,
  isHistory: boolean,
  isCardEditMode: boolean
) {
  return observationPoint &&
    canViewFormulaConstantReport &&
    !isHistory &&
    !isCardEditMode
    ? {
        name: `${rowNameBase}-view-history-link`,
        content: (
          <DMSLink
            to={`/formula-constants/?formula_constant=${formulaConstant.id}&observation_point_formula__observation_point=${observationPoint.id}&ordering=-start-date`}
          >
            <Trans>View history</Trans>
          </DMSLink>
        ),
      }
    : null;
}

/**
 * For each type of input or constant, we have a particular "render function"
 * that renders the card row for it, including any input fields, read-only
 * fields, and the "change this field"
 *
 * All these functions receive a common set of "props". Not all props will be
 * applicable to all input/constant types. In particular, read-only types
 * (like reference constants) can ignore all the ones dealing with edit mode,
 * as well as the opficObj.
 */
type OpficFieldProps = {
  rowNameBase: string;
  className: string;
  formik?: MyFormikProps;
  formikNameBase: string;
  // Is the card in edit (form) mode, or read-only mode?
  isCardEditMode: boolean;
  // If in edit mode, should this field's inputs be disabled at this moment?
  isFieldDisabled: boolean;
  // If in edit mode, should we show the "change" button for this field?
  isChangeButtonVisible?: boolean;
  // If tolerance is null then hasSummationTolerance will be false
  hasSummationTolerance?: boolean;
  // If in edit mode, should the "change" button be disabled?
  isChangeButtonDisabled?: boolean;
  onClickChangeButton?: () => void;
  ficObj: Model.FormulaInput | Model.FormulaConstant;
  opficObj:
    | Model.ObservationPointFormulaInput
    | Model.ObservationPointFormulaConstantValue
    | null;
  cardProps: ObsPointFormulaCardProps;
};
interface OpfInputFieldProps extends OpficFieldProps {
  ficObj: Model.FormulaInput;
  opficObj: Model.ObservationPointFormulaInput | null;
}
interface OpfConstantFieldProps extends OpficFieldProps {
  ficObj: Model.FormulaConstant;
  opficObj: Model.ObservationPointFormulaConstantValue | null;
}

type OpficFieldRenderFn<T extends OpficFieldProps = OpficFieldProps> = (
  props: T
) => CardSectionRow[];

/**
 * A raw reading input.
 *
 * Nothing to configure. It just displays which field it's in.
 *
 * @param props
 */
const renderRawReadingInput = function (
  props: OpfInputFieldProps
): CardSectionField[] {
  const { ficObj: formulaInput, rowNameBase } = props;
  return [
    {
      name: `${rowNameBase}-position`,
      className: props.className,
      label: <Trans>Raw reading item {formulaInput.position}</Trans>,
      content: <p className="non-editable-value">{formulaInput.description}</p>,
    },
  ];
};

/**
 * A "Compensation" input, WITH a tolerance seletor.
 *
 * Displays a menu from which you pick another observation point's adjusted
 * readings, to use as an input to this formula. And an input to choose
 * the allowed tolerance interval for the compensation reading.
 */
const renderCompensationInputWithTolerance: OpficFieldRenderFn<OpfInputFieldProps> =
  function (props): CardSectionRow[] {
    const {
      formik,
      ficObj: formulaInput,
      opficObj: opfi,
      rowNameBase,
      formikNameBase,
      cardProps,
      className,
    } = props;

    if (props.isCardEditMode && formik) {
      return [
        {
          name: rowNameBase,
          className,
          columns: [
            // Column 1: everything but the edit button
            {
              name: `${rowNameBase}-edit-compensation`,
              content: (
                <FormCardSection
                  name={`${rowNameBase}-edit-compensation-input`}
                  header={
                    <Trans>
                      Compensation reading: {formulaInput.description}
                    </Trans>
                  }
                  fields={[
                    // Column 1, Row 1: select obs point
                    {
                      name: `${rowNameBase}-value`,
                      label: <Trans>Observation point</Trans>,
                      content: (
                        <>
                          {cardProps.observationPoint ? (
                            <ObsPointItemMenu
                              name={`${formikNameBase}.compensationFieldIds`}
                              isMulti={false}
                              isDisabled={props.isFieldDisabled}
                            />
                          ) : (
                            <Loading />
                          )}
                          <FieldError
                            name={`${formikNameBase}.compensationFieldIds`}
                          />
                          <FieldError
                            name={`${formikNameBase}.compensation_item_number`}
                          />
                          <FieldError
                            name={`${formikNameBase}.compensation_observation_point`}
                          />
                        </>
                      ),
                    },
                    // Column 1, row 2: Tolerance
                    {
                      ...renderTolerance(
                        props,
                        <Trans>Reading time difference tolerance</Trans>
                      ),
                    },
                    // Column 1, row 3: Start datetime
                    renderStartDatetime(props),
                  ]}
                />
              ),
            },
            // Column 2 is just the edit button (if it's visible)
            props.isChangeButtonVisible && {
              name: `${rowNameBase}-edit-button`,
              content: renderEditButton(props),
              className: 'card-section-nested-edit-button',
            },
          ].filter(isTruthy),
        },
      ];
    } else {
      return [
        // Row 1: Compensation observation point & start datetime
        {
          name: rowNameBase,
          className: props.className,
          columns: [
            // Row 1, First column: Compensation observation point
            {
              name: `${rowNameBase}-value`,
              label: formulaInput.description,
              content: (
                <span>
                  {opfi?.compensation_observation_point &&
                  opfi?.compensation_item_number &&
                  cardProps.relatedObservationPoints
                    ? makeObsPointItemMenuOption(
                        cardProps.relatedObservationPoints,
                        {
                          observation_point:
                            opfi.compensation_observation_point,
                          item_number: opfi.compensation_item_number,
                        }
                      ).label
                    : null}
                </span>
              ),
            },
            // Row 1, second column: start datetime
            renderStartDatetime(props),
          ],
        },
        // Row 2: Tolerance
        {
          ...renderTolerance(
            props,
            <Trans>Reading time difference tolerance</Trans>
          ),
          className,
        },
      ];
    }
  };

/**
 * A "chainable" input
 *
 * In edit mode, it displays two related controls:
 *
 * 1. A switcher to let you choose whether to feed normal raw reading entries
 *    into this input, or whether to use the adjusted readings from another
 *    observation point
 * 2. If you choose to use adjusted readings from another observation point,
 *    a menu to let you choose which observation point.
 * 3. (And of course, the standard "start datetime" field)
 *
 * In non-edit mode, it doesn't display the picker. It just shows you the details
 * about the input method you've chosen.
 *
 * @param props
 */
const renderChainableInput: OpficFieldRenderFn<OpfInputFieldProps> = function (
  props
): CardSectionFieldMultiColumn[] {
  const {
    ficObj: formulaInput,
    opficObj: opfi,
    rowNameBase,
    formikNameBase,
    formik,
    cardProps,
  } = props;
  const isChainMode = getSafely(
    () =>
      props.isCardEditMode && formik
        ? formik.values.observation_point_formula_inputs[formulaInput.var_name]
            .isChainMode
        : opfi!.dependency_observation_points.length > 0,
    false
  );

  if (props.isCardEditMode && formik) {
    // In edit mode the chainable input settings are presented as rows in a sub section
    return [
      {
        name: props.rowNameBase,
        className: props.className,
        columns: [
          {
            // Column 1: the chainable input details subsection
            name: `${rowNameBase}-edit-chainable`,
            content: (
              <FormCardSection
                name={`${rowNameBase}-edit-chainable-input`}
                header={
                  <Trans>
                    Raw reading item {formulaInput.position}:{' '}
                    {formulaInput.description}
                  </Trans>
                }
                fields={[
                  // Column 1, Row 1: select chain source
                  {
                    name: `${rowNameBase}-chain-source-type`,
                    label: <Trans>Reading source</Trans>,
                    content: (
                      <>
                        <RadioField
                          disabled={props.isFieldDisabled}
                          name={`${formikNameBase}.isChainMode`}
                          options={CHAIN_INPUT_SOURCE_OPTIONS}
                        />
                        <FieldError name={`${formikNameBase}.isChainMode`} />
                        <FormChangeEffect<CurrentFormulaFormValues>
                          onChange={({ values: prevValues }) => {
                            // Clear the observation point selection, when exiting
                            // chain mode.
                            if (!isChainMode) {
                              const prevIsChainMode = getSafely(
                                () =>
                                  prevValues.observation_point_formula_inputs[
                                    formulaInput.var_name
                                  ].isChainMode,
                                false
                              );
                              if (prevIsChainMode) {
                                formik.setFieldValue(
                                  `${formikNameBase}.dependency_observation_points`,
                                  []
                                );
                              }
                            }
                          }}
                        />
                      </>
                    ),
                  },
                  // Column 1, Row 2: Obs point menu (if in chain mode)
                  isChainMode && {
                    name: `${rowNameBase}-chain-source`,
                    label: <Trans>Observation point</Trans>,
                    content: (
                      <>
                        <ObsPointItemMenu
                          isDisabled={props.isFieldDisabled}
                          name={`${formikNameBase}.dependency_observation_points[0]`}
                          isMulti={false}
                          filterOption={(opt) =>
                            !(
                              opt.observationPoint &&
                              cardProps.observationPoint &&
                              opt.observationPoint.id ===
                                cardProps.observationPoint.id
                            )
                          }
                        />
                        <FieldError
                          name={`${formikNameBase}.dependency_observation_points`}
                        />
                      </>
                    ),
                  },
                  // Column 1, Row 3: Start datetime
                  renderStartDatetime(props),
                ].filter(isTruthy)}
              />
            ),
          },
          // Column 2 is just the edit button (if it's visible)
          props.isChangeButtonVisible && {
            name: `${rowNameBase}-edit-button`,
            content: renderEditButton(props),
            className: 'card-section-nested-edit-button',
          },
        ].filter(isTruthy),
      },
    ];
  } else {
    return [
      {
        name: rowNameBase,
        className: props.className,
        columns: [
          // Column 1: Chain source
          {
            name: `${rowNameBase}-chain-source`,
            ...(isChainMode
              ? {
                  // Column 1a: Chain mode
                  label: (
                    <Trans>Raw reading item {formulaInput.position}</Trans>
                  ),
                  content: (
                    <>
                      {formulaInput.description}:{' '}
                      <p>
                        {cardProps.relatedObservationPoints &&
                          opfi &&
                          (opfi.dependency_observation_points.length ? (
                            makeObsPointItemMenuOption(
                              cardProps.relatedObservationPoints!,
                              opfi.dependency_observation_points[0]
                            ).label
                          ) : (
                            <Trans>[None]</Trans>
                          ))}
                      </p>
                    </>
                  ),
                }
              : // Column 1b: Raw reading mode
                // Rendered the same as a non-chainable raw reading input
                pick(renderRawReadingInput(props)[0], 'label', 'content')),
          },
          // Column 2: start datetime
          renderStartDatetime(props),
        ],
      },
    ];
  }
};

const CHAIN_INPUT_SOURCE_OPTIONS: RadioFieldProps['options'] = [
  {
    value: false,
    label: <Trans>Raw reading from input file</Trans>,
  },
  {
    value: true,
    label: <Trans>Adjusted reading from another observation point</Trans>,
  },
];

/**
 * A "Summation" input
 *
 * Displays a multi-select menu to let you choose multiple observation points
 * whose adjusted readings will be summed to create this observation point's
 * raw readings.
 *
 * Also displays a "tolerance" interval, for how close together all the dependent
 * observation points' readings are expected to be clustered.
 *
 * @param props
 */
const renderSummationInput: OpficFieldRenderFn<OpfInputFieldProps> = function (
  props
): CardSectionRow[] {
  const {
    ficObj: formulaInput,
    opficObj: opfi,
    rowNameBase,
    formikNameBase,
    cardProps,
  } = props;

  // The summation input is presented as three separate card rows
  return [
    {
      // Row 1: Observation points menu & "edit" button
      name: `${rowNameBase}-summation-input`,
      className: props.className,
      columns: [
        {
          // Row 1, Column 1: Observation points menu
          name: `${rowNameBase}-summation-observation-points`,
          label: formulaInput.description,
          content: props.isCardEditMode ? (
            // EDIT MODE summation observation points
            <>
              <ObsPointItemMenu
                isDisabled={props.isFieldDisabled}
                name={`${formikNameBase}.dependency_observation_points`}
                isMulti={true}
                filterOption={(opt) =>
                  !(
                    opt.observationPoint &&
                    cardProps.observationPoint &&
                    opt.observationPoint.id === cardProps.observationPoint.id
                  )
                }
              />
              <FieldError
                name={`${formikNameBase}.dependency_observation_points`}
              />
            </>
          ) : (
            // VIEW MODE summation observation points
            cardProps.relatedObservationPoints &&
            opfi && (
              <ul>
                {opfi.dependency_observation_points.map((pair) => (
                  <li
                    key={getObsPointItemIdent(
                      pair.observation_point,
                      pair.item_number
                    )}
                  >
                    {
                      makeObsPointItemMenuOption(
                        cardProps.relatedObservationPoints!,
                        pair
                      ).label
                    }
                  </li>
                ))}
              </ul>
            )
          ),
        }, // Row 1 Column 2: The "edit" button (if visible)
        props.isChangeButtonVisible && {
          name: `${rowNameBase}-edit-button`,
          content: renderEditButton(props),
          className: 'card-section-nested-edit-button',
        },
      ].filter(isTruthy),
    },
    // Row 2: Summation tolerance
    {
      ...renderToleranceWithRadios(
        props,
        <Trans>Summation tolerance</Trans>,
        <Trans>Reading time difference tolerance</Trans>,
        <Trans>One reading per day for each summation component</Trans>
      ),
      className: props.className,
    },
    // Row 3: Start datetime
    // This function takes care of both EDIT and VIEW modes. :-)
    {
      ...renderStartDatetime(props),
      className: props.className,
    },
  ];
};

/**
 * "Value" constant.
 *
 * You enter a numeric value, and it's used as a constant for the formula.
 *
 * @param props
 */
const renderValueConstant: OpficFieldRenderFn<OpfConstantFieldProps> =
  function (props): CardSectionFieldMultiColumn[] {
    const {
      ficObj: formulaConstant,
      opficObj: opfcv,
      rowNameBase,
      formikNameBase,
      isCardEditMode,
      cardProps: { observationPoint, isHistory, canViewFormulaConstantReport },
    } = props;
    return [
      {
        name: rowNameBase,
        className: props.className,
        columns: [
          // First column: Value
          {
            name: `${rowNameBase}-value`,
            label: formulaConstant.description,
            content: props.isCardEditMode ? (
              <>
                <Field
                  name={`${formikNameBase}.value`}
                  type="text"
                  disabled={props.isFieldDisabled}
                />
                <FieldError name={`${formikNameBase}.value`} />
              </>
            ) : (
              <span>
                {opfcv && validateNumber(opfcv.value) ? (
                  opfcv.value
                ) : (
                  <Trans>[not set]</Trans>
                )}
              </span>
            ),
          },
          // Second column: start datetime & edit button
          renderStartDatetimeAndEditButton(props),
          // Third column: When the card is in "view" mode, display a link
          // to the formula constant report
          renderFormulaConstantHistoryLink(
            rowNameBase,
            formulaConstant,
            observationPoint,
            canViewFormulaConstantReport,
            isHistory,
            isCardEditMode
          ),
        ].filter(isNotNull),
      },
    ];
  };

/**
 * "Decimal places" constant
 *
 * Displays a menu with options 0 to 9, to specify how many decimal places
 * adjusted readings should be rounded to.
 *
 * @param props
 */
const renderDecimalPlacesConstant: OpficFieldRenderFn<OpfConstantFieldProps> =
  function (props): CardSectionFieldMultiColumn[] {
    const {
      ficObj: formulaConstant,
      opficObj: opfcv,
      rowNameBase,
      formikNameBase,
      isCardEditMode,
      cardProps: { observationPoint, isHistory, canViewFormulaConstantReport },
    } = props;

    const isValueSet = opfcv && validateNumber(opfcv.value);
    return [
      {
        name: rowNameBase,
        className: props.className,
        columns: [
          // First column: Decimal points
          {
            name: `${rowNameBase}-value`,
            label: formulaConstant.description,
            content: props.isCardEditMode ? (
              <>
                <SimpleSelectField
                  isDisabled={props.isFieldDisabled}
                  name={`${formikNameBase}.value`}
                  options={DECIMAL_POINT_OPTIONS}
                />
                <FieldError name={`${formikNameBase}.value`} />
              </>
            ) : (
              <span>
                {isValueSet ? opfcv!.value : <Trans>[not set]</Trans>}
              </span>
            ),
          },
          // Second column: start datetime & edit button
          renderStartDatetimeAndEditButton(props),
          // Third column: When the card is in "view" mode, display a link
          // to the formula constant report
          renderFormulaConstantHistoryLink(
            rowNameBase,
            formulaConstant,
            observationPoint,
            canViewFormulaConstantReport,
            isHistory,
            isCardEditMode
          ),
        ].filter(isNotNull),
      },
    ];
  };

/**
 * "Comparison operator constant
 *
 * Displays a menu with options to select a comparison operator.
 *
 * @param props
 */

const COMPARISON_OPERATOR_OPTIONS = [
  'Equal to',
  'Not equal',
  'Greater than',
  'Greater than or equal to',
  'Less than',
  'Less than or equal to',
].map((label, i) => ({ value: `${i}`, label }));

const renderComparisonOperatorConstant: OpficFieldRenderFn<OpfConstantFieldProps> =
  function (props): CardSectionFieldMultiColumn[] {
    const {
      ficObj: formulaConstant,
      opficObj: opfcv,
      rowNameBase,
      formikNameBase,
      isCardEditMode,
      cardProps: { observationPoint, isHistory, canViewFormulaConstantReport },
    } = props;

    const isValueSet =
      opfcv &&
      validateNumber(opfcv.value) &&
      +opfcv.value >= 0 &&
      +opfcv.value < COMPARISON_OPERATOR_OPTIONS.length;

    return [
      {
        name: rowNameBase,
        className: props.className,
        columns: [
          // First column: Comparison operator
          {
            name: `${rowNameBase}-value`,
            label: formulaConstant.description,
            content: props.isCardEditMode ? (
              <>
                <SimpleSelectField
                  isDisabled={props.isFieldDisabled}
                  name={`${formikNameBase}.value`}
                  options={COMPARISON_OPERATOR_OPTIONS}
                />
                <FieldError name={`${formikNameBase}.value`} />
              </>
            ) : (
              <span>
                {isValueSet ? (
                  COMPARISON_OPERATOR_OPTIONS[+opfcv!.value]?.label
                ) : (
                  <Trans>[not set]</Trans>
                )}
              </span>
            ),
          },
          // Second column: start datetime & edit button
          renderStartDatetimeAndEditButton(props),
          // Third column: When the card is in "view" mode, display a link
          // to the formula constant report
          // TODO: Special handling of the comparison operator on the report?
          renderFormulaConstantHistoryLink(
            rowNameBase,
            formulaConstant,
            observationPoint,
            canViewFormulaConstantReport,
            isHistory,
            isCardEditMode
          ),
        ].filter(isNotNull),
      },
    ];
  };

// Allow to specify 0 to 9 decimal points
const DECIMAL_POINT_OPTIONS = rangeInclusive(0, 9).map((i) => ({
  value: `${i}`,
  label: `${i}`,
}));

/**
 * "Reference" constant
 *
 * Uses the value of one of the time-dependent fields from the observation
 * point's site.
 *
 * The specific site field is hard-coded into the formula constant's record,
 * and the value is part of the site not the observation point, so there is
 * nothing editable here. We just display what the field's value was at
 * the relevant time.
 *
 * @param props
 */
const renderReferenceConstant: OpficFieldRenderFn<OpfConstantFieldProps> =
  function (props): CardSectionFieldMultiColumn[] {
    const {
      ficObj: formulaConstant,
      rowNameBase,
      cardProps,
      isCardEditMode,
    } = props;
    const { observationPointFormula: opf, siteDecorated: site } = cardProps;
    const stfName =
      formulaConstant.site_time_dependent_field_name as Enum.Site_TIME_DEPENDENT_FIELD_NAME;
    const referenceConstantEntry = getSafely(
      () =>
        // Use the value from the opf snapshot if present (i.e. if we're
        // viewing a historical snapshot))
        (opf && opf.site_time_dependent_fields[stfName]) ||
        // Use the current value from the site otherwise (i.e. if we're
        // in edit mode and have just changed formulas)
        site!.site_time_dependent_fields[stfName]![0],
      null
    );

    const timeZone = cardProps.observationPoint
      ? cardProps.observationPoint.time_zone.name
      : undefined;

    return [
      {
        name: rowNameBase,
        className: props.className,
        columns: [
          // First column: Value of the reference field
          {
            name: `${rowNameBase}-value`,
            label: formulaConstant.description,
            content: (
              <p className="non-editable-value">
                {referenceConstantEntry ? (
                  referenceConstantEntry.value
                ) : (
                  <Trans>[not found in site]</Trans>
                )}
              </p>
            ),
          },
          // Second column: When the reference field's value started
          // (NOT editable, so no "change" button)
          {
            name: `${rowNameBase}-start_datetime`,
            label: <Trans>Start date</Trans>,
            content: (
              <p className="non-editable-value">
                {referenceConstantEntry &&
                  formatDatetimeForDisplay(
                    referenceConstantEntry.start_datetime,
                    timeZone
                  )}
              </p>
            ),
          },
          // Third column: When the card is in "view" mode, display a link to
          // the site time dependent field's history
          site &&
          cardProps.canViewSiteReport &&
          !cardProps.isHistory &&
          !isCardEditMode
            ? {
                name: `${rowNameBase}-view-history-link`,
                content: (
                  <DMSLink
                    to={`/sites/${site.code}/time-dependent-fields/${stfName}`}
                  >
                    <Trans>View history</Trans>
                  </DMSLink>
                ),
              }
            : null,
        ].filter(isNotNull),
      },
    ];
  };
