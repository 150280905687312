import React, { createRef } from 'react';
import { connect, ResolveThunks } from 'react-redux';
import PageStandardView, { PageStandardViewProps } from './pagestandardview';
import { selectLoggedInUserName, doLogout } from '../../../ducks/login';
import { FullState } from '../../../main/reducers';

export interface PageStandardContextState {
  showPanel: (whichPanel: React.Component, panelName: string) => void;
  hidePanel: () => void;
  showModal: (whichModal: React.Component, modalName: string) => void;
  hideModal: () => void;
  activePanel: React.Component | null;
  activePanelName: string | null;
  activeModal: React.Component | null;
  activeModalName: string | null;
  pageName: string | null;
  panelRef: React.RefObject<HTMLDivElement> | null;
  popupRef: React.RefObject<HTMLDivElement> | null;
}

export const PageStandardContext =
  React.createContext<PageStandardContextState>({
    showModal: function () {},
    hideModal: function () {},
    showPanel: function () {},
    hidePanel: function () {},
    activePanel: null,
    activePanelName: null,
    activeModal: null,
    activeModalName: null,
    pageName: null,
    panelRef: null,
    popupRef: null,
  });

export interface PageStandardProps {
  name: string;
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  children: React.ReactNode;
}

export type PropsFromDispatch = ResolveThunks<{
  doLogout: typeof doLogout;
}>;

interface PropsFromState {
  userGroupsCount: number;
  preferredName: string;
  isNavMenuCollapsed: boolean;
}

type ComponentProps = PageStandardProps & PropsFromDispatch & PropsFromState;
type ComponentState = PageStandardContextState;

/**
 * A "standard" page layout. Has navigation, header, and (TODO) panel area.
 *
 * (Some pages will be "non-standard" because they have no navigation.)
 *
 * @export
 * @class PageLayout
 * @extends {React.Component}
 */
class _PageStandard extends React.Component<ComponentProps, ComponentState> {
  static mapStateToProps(state: FullState): PropsFromState {
    const preferredName = selectLoggedInUserName(state);

    return {
      userGroupsCount: state.user.areaGroups ? state.user.areaGroups.length : 0,
      preferredName,
      isNavMenuCollapsed: state.ui.isNavMenuCollapsed,
    };
  }

  static mapDispatchToProps = {
    doLogout,
  };

  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      activePanel: null,
      activePanelName: null,
      activeModal: null,
      activeModalName: null,
      showPanel: this.showPanel,
      hidePanel: this.hidePanel,
      showModal: this.showModal,
      hideModal: this.hideModal,
      pageName: this.props.name,
      panelRef: null,
      popupRef: null,
    };
  }

  passPanelRef = (panelRef: React.RefObject<HTMLDivElement>) => {
    this.setState({ panelRef });
  };

  passPopupRef = (popupRef: React.RefObject<HTMLDivElement>) => {
    this.setState({ popupRef });
  };

  // Force `popupRef` to be a new ref object, in order to trigger a re-render
  // plot popups after the nav bar expand/contract CSS transition is over
  forceUpdatePopupRef = () => {
    const newRef =
      createRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement | null>;
    newRef.current = this.state.popupRef?.current ?? null;
    this.setState({ popupRef: newRef });
  };

  render() {
    return (
      // Using `this.state` as the context value, in order to avoid unintentional
      // re-renders. See: https://reactjs.org/docs/context.html#caveats
      <PageStandardContext.Provider value={this.state}>
        <PageStandardView
          name={this.props.name}
          header={this.props.header}
          subHeader={this.props.subHeader}
          userGroupsCount={this.props.userGroupsCount}
          userPreferredName={this.props.preferredName}
          handleClickLogout={this.handleClickLogout}
          isNavMenuCollapsed={this.props.isNavMenuCollapsed}
          passPanelRef={this.passPanelRef}
          passPopupRef={this.passPopupRef}
          forceUpdatePopupRef={this.forceUpdatePopupRef}
        >
          {this.props.children}
        </PageStandardView>
      </PageStandardContext.Provider>
    );
  }

  /**
   * The callback for the buttons that open/close the info panel.
   * (Using an arrow function in order to bind "this")
   * @param {string} whichPanel The name of the panel to toggle. (Must match one
   * of the names in the `panels` prop.)
   */
  showPanel: PageStandardContextState['showPanel'] = (
    whichPanel,
    panelName
  ) => {
    // If the panel is already open, clicking its button will close it.
    if (this.state.activePanel !== whichPanel) {
      this.setState({ activePanel: whichPanel, activePanelName: panelName });
    }
  };

  hidePanel: PageStandardContextState['hidePanel'] = () => {
    if (this.state.activePanel !== null) {
      this.setState({ activePanel: null, activePanelName: null });
    }
  };

  showModal: PageStandardContextState['showModal'] = (
    whichModal,
    modalName
  ) => {
    if (this.state.activeModal !== whichModal) {
      this.setState({ activeModal: whichModal, activeModalName: modalName });
    }
  };

  hideModal: PageStandardContextState['hideModal'] = () => {
    if (this.state.activeModal !== null) {
      this.setState({ activeModal: null, activeModalName: null });
    }
  };

  handleClickLogout: PageStandardViewProps['handleClickLogout'] = (event) => {
    this.props.doLogout();
    event.preventDefault();
  };
}

const PageStandard = connect(
  _PageStandard.mapStateToProps,
  _PageStandard.mapDispatchToProps
)(_PageStandard);
export default PageStandard;
