import React from 'react';
import classNames from 'classnames';
import { Icon, IconType } from 'components/base/icon/icon';
import './card.scss';

type CardProps = Merge<
  // Extra props are passed through to the underlying div element.
  Partial<React.HTMLAttributes<HTMLDivElement>>,
  {
    name: string;
    header?: React.ReactNode;
    iconType?: IconType;
    subHeader?: React.ReactNode;
    footer?: React.ReactNode;
  }
>;

/**
 * A "card" section of a page. See also <FormCard> for a form split up into
 * cards, and <EditableCard> for a card that can switch between "read-only"
 * and "edit" form.
 *
 * @export
 * @class Card
 * @extends {React.Component}
 */
export class Card extends React.Component<CardProps> {
  render() {
    const {
      name,
      header,
      iconType,
      subHeader,
      className,
      children,
      footer,
      ...otherProps
    } = this.props;

    const id = `card-${this.props.name}`;
    return (
      <div id={id} className={classNames('card', className)} {...otherProps}>
        {(this.props.header || this.props.subHeader) && (
          <div id={`${id}-header`} className="card-header">
            {this.props.header && (
              <h2>
                {this.props.iconType ? (
                  <Icon type={this.props.iconType} />
                ) : null}
                {this.props.header}
              </h2>
            )}
            {this.props.subHeader && <div>{this.props.subHeader}</div>}
          </div>
        )}
        <div className="card-content">{this.props.children}</div>
        {this.props.footer && (
          <div className="card-footer">{this.props.footer}</div>
        )}
      </div>
    );
  }
}

export interface CardSectionField {
  name: string;
  className?: string;
  label?: React.ReactNode;
  content: React.ReactNode;
  formGroupClassName?: string;
}

export interface CardSectionFieldMultiColumn {
  name: string;
  className?: string;
  columns: CardSectionField[];
  formGroupClassName?: string;
}

export type CardSectionRow =
  | CardSectionField
  | CardSectionFieldMultiColumn
  | React.ReactElement;

export type CardSectionProps<T extends HTMLElement = HTMLElement> = Merge<
  // Extra props are passed through to the underlying HTML element.
  Partial<React.HTMLAttributes<T>>,
  {
    name: string;
    header?: React.ReactNode;
    fields?: CardSectionRow[] | null | false | undefined;
  }
>;

function selectCardSectionOtherProps(props: CardSectionProps) {
  const { name, header, fields, className, ...otherProps } = props;
  return otherProps;
}

function isMultiColumnField(
  field: CardSectionField | CardSectionFieldMultiColumn
): field is CardSectionFieldMultiColumn {
  return 'columns' in field;
}

function selectCardSectionRowDetails(field: CardSectionRow) {
  let fieldColumns: Array<CardSectionField> | false = false;
  let isMultiColumn: boolean;
  let rowName: string;
  let rowClassName: string | undefined = undefined;
  if (React.isValidElement(field)) {
    // "escape hatch", just render whatever they pass in, with no structure.
    return null;
  } else {
    rowName = field.name;
    rowClassName = field.className;
    if (isMultiColumnField(field)) {
      // A field with multiple columns;
      isMultiColumn = true;
      fieldColumns = field.columns;
    } else {
      // One object, representing a field with just one column.
      fieldColumns = [field] as CardSectionField[];
      isMultiColumn = false;
    }
  }
  return {
    fieldColumns,
    isMultiColumn,
    rowName,
    rowClassName,
  };
}

/**
 * A section within a "card".
 *
 * @export
 * @class CardSection
 * @extends {React.Component}
 */
export class CardSection extends React.Component<
  CardSectionProps<HTMLDivElement>
> {
  render() {
    const otherProps = selectCardSectionOtherProps(this.props);
    const id = `card-section-${this.props.name}`;

    return (
      <div
        id={id}
        className={classNames(
          'card-form',
          'card-section',
          this.props.className
        )}
        {...otherProps}
      >
        {this.props.header && <h3>{this.props.header}</h3>}
        {this.props.children}
        {this.props.fields && (
          <dl>{this.props.fields.map((field) => this.renderRow(field))}</dl>
        )}
      </div>
    );
  }

  renderRow = (field: CardSectionRow) => {
    const rowDetails = selectCardSectionRowDetails(field);
    if (rowDetails === null) {
      return field;
    }
    const { fieldColumns, isMultiColumn, rowName, rowClassName } = rowDetails;
    return (
      <div key={rowName} className={classNames('card-row', rowClassName)}>
        {fieldColumns.map(
          ({ name, label, content, className: fieldClassName }, idx) => (
            <div
              key={name}
              className={classNames(
                `card-row-col-${idx + 1}`,
                isMultiColumn ? fieldClassName : null
              )}
            >
              {label && (
                <dt id={`card-section-field-label-${name}`}>{label}</dt>
              )}
              <dd id={`card-section-field-content-${name}`}>{content}</dd>
            </div>
          )
        )}
      </div>
    );
  };
}

/**
 * A card in "edit" mode (aka an "active" card.)
 *
 * @export
 * @class FormCard
 * @extends {React.Component}
 */
export class FormCard extends React.Component<CardProps> {
  render() {
    const { className, children, ...otherProps } = this.props;
    return (
      <Card className={classNames('card-active', className)} {...otherProps}>
        {this.props.children}
      </Card>
    );
  }
}

/**
 * A section of a card in "edit" mode.
 *
 * This component is a copy-paste of CardSection, but the differences between
 * them were just big enough that I couldn't think of way to achieve any
 * direct code re-use between them.
 *
 * @export
 * @class FormCardSection
 * @extends {React.Component}
 */
export class FormCardSection extends React.Component<
  CardSectionProps<HTMLFieldSetElement>
> {
  render() {
    const otherProps = selectCardSectionOtherProps(this.props);
    const id = `card-section-${this.props.name}`;

    return (
      <fieldset
        id={id}
        className={classNames(
          'card-form',
          'card-section',
          this.props.className
        )}
        {...otherProps}
      >
        {this.props.header && <legend>{this.props.header}</legend>}
        {this.props.children}
        {this.props.fields &&
          this.props.fields.map((field) => this.renderRow(field))}
      </fieldset>
    );
  }

  renderRow = (field: CardSectionRow) => {
    const rowDetails = selectCardSectionRowDetails(field);
    if (rowDetails === null) {
      return field;
    }
    const { fieldColumns, isMultiColumn, rowName, rowClassName } = rowDetails;
    return (
      <div key={rowName} className={classNames('card-row', rowClassName)}>
        {fieldColumns.map(
          (
            {
              name,
              label,
              content,
              formGroupClassName,
              className: fieldClassName,
            },
            idx
          ) => (
            <div
              key={name}
              id={`card-section-field-${name}`}
              className={classNames(
                `card-row-col-${idx + 1}`,
                isMultiColumn ? fieldClassName : null
              )}
            >
              <div className={classNames('form-group', formGroupClassName)}>
                {label && (
                  <label htmlFor={name} id={`card-section-field-label-${name}`}>
                    {label}
                  </label>
                )}
                <div className="form-group-content">{content}</div>
              </div>
            </div>
          )
        )}
      </div>
    );
  };
}
