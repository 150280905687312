import { mockFormula } from './formula';
import { Model, Enum } from './api.interfaces';
import { pickFromEnum } from 'util/validation';
import { FormulaConstant_DECIMAL_PLACES } from '../types/Enum';
import { rangeInclusive } from 'util/misc';

export function isReferenceConstant(
  fc: Model.FormulaConstant | Model.FormulaConstantDecorated
): boolean {
  return Boolean(fc.site_time_dependent_field_name);
}

export function isDecimalPlacesConstant(
  fc: Model.FormulaConstant | Model.FormulaConstantDecorated
): boolean {
  return (
    !isReferenceConstant(fc) &&
    fc.var_name === Enum.FormulaConstant_DECIMAL_PLACES
  );
}

export function isValueConstant(
  fc: Model.FormulaConstant | Model.FormulaConstantDecorated
): boolean {
  return (
    !isReferenceConstant(fc) &&
    !isDecimalPlacesConstant(fc) &&
    !isComparisonOperatorConstant(fc)
  );
}

export function isComparisonOperatorConstant(
  fc: Model.FormulaConstant | Model.FormulaConstantDecorated
): boolean {
  return fc.var_name === Enum.FormulaConstant_COMPARISON_OPERATOR;
}

export function mockFormulaConstant(seed: number): Model.FormulaConstant {
  const base = {
    id: seed,
    formula: seed,
    position: seed,
  };
  switch (seed % 3) {
    // Value constant
    case 1:
      return {
        ...base,
        description: `Value constant #${seed}`,
        var_name: `value_constant_${seed}`,
        site_time_dependent_field_name: '',
        default_value: seed % 2 ? (1.1 * seed).toFixed(2) : null,
      };
    // Reference constant
    case 2: {
      const refField = pickFromEnum(Enum.Site_TIME_DEPENDENT_FIELD_NAME, seed);
      return {
        ...base,
        description: `Ref constant #${seed} (${refField})`,
        var_name: `site_${refField}_${seed}`,
        site_time_dependent_field_name: refField,
        default_value: null,
      };
    }
    // Decimal places constant
    case 0:
    default:
      return {
        ...base,
        description: `Decimal places`,
        var_name: FormulaConstant_DECIMAL_PLACES,
        site_time_dependent_field_name: '',
        default_value: String(rangeInclusive(1, 9)[seed % 9]),
      };
  }
}

export function mockFormulaConstantDecorated(
  seed: number
): Model.FormulaConstantDecorated {
  const fc = mockFormulaConstant(seed);
  return {
    ...fc,
    formula: mockFormula(seed),
  };
}
