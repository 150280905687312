import React, { CSSProperties, useMemo, useCallback, useState } from 'react';
import { scaleLinear } from 'd3-scale';
import { t, Trans } from '@lingui/macro';
import { withI18n, withI18nProps } from '@lingui/react';
import throttle from 'lodash/throttle';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import classNames from 'classnames';
import {
  HorizontalGridLines,
  LineSeries,
  WhiskerSeries,
  VerticalGridLines,
  VerticalRectSeries,
  XAxis,
  YAxis,
  AbstractSeriesProps,
  Highlight,
  XYPlot,
} from 'react-vis';
import { LabelSeries, CustomSVGSeries } from './react-vis-hacks';
import CrosshairWithReticle from './crosshairwithreticle';
import './timeseriesplot.scss';

import {
  selectMouseoverPoints,
  calculatePortRLPlotLines,
  calculateAnnotationPlotLines,
  makeTimeSeriesPlotSelectors,
  getTicksTotalFromSize,
  TSPlotDomainProps,
  calculateUnreliablePeriodsForSerie,
} from './timeseriesplotselectors';
import AxisUtils from 'react-vis/dist/utils/axis-utils';
import { formatDatetimeForStorage } from 'util/dates';
import {
  PlotReadingsSeries,
  TSPlotAlarmParamBox,
  TSPlotAlarmParamLabel,
  TSPlotAlarmParamOutlinePoint,
  TSPlotReading,
  IconPoint,
  TSPlotMouseoverPoint,
  TSPlotAlarmParamSeries,
  YAxisSide,
  Y_AXIS_SIDES,
  TSPlotYAxisScales,
  PlotSettingsAxis,
  TSPlotOnZoomFn,
} from './timeseriesplot.types';
import { BucketInfo } from './popups/BucketInfo';
import { ReadingInfo } from './popups/ReadingInfo';
import { SeriesLegendText } from './SeriesLegendText';
import { StoredPlotAnnotation } from 'util/backendapi/types/Model';
import useResizeObserver from 'hooks/use-resize-observer';
import { detectExportMode } from 'util/export';
import { Popup } from './popups/Popup';
import { BordersPadded } from './BordersPadded';
import {
  StoredPlotAnnotationLabelPosition,
  StoredPlotAnnotation_STUB_POSITION,
} from 'util/backendapi/types/Enum';
import { isTruthy } from 'util/validation';

export const GRID_LINES_STYLE = {
  stroke: '#cfcfcf',
  strokeDasharray: '3',
};

export const TICK_LINE_AXIS_STYLE = {
  line: { stroke: '#cfcfcf' },
  text: { display: 'none' },
};
const TICK_LABEL_AXIS_STYLE = {
  line: { display: 'none' },
  text: { stroke: 'none' },
};
const FULL_AXIS_STYLE = {
  line: TICK_LINE_AXIS_STYLE,
  text: TICK_LABEL_AXIS_STYLE,
};

const ANNOTATION_LINE_COLOR = '#6c6c6c';

// Estimation of the width of a character in a Y axis tick label
export const SSR_TICK_LABEL_CHAR_WIDTH = 6;

// ReactVis props that control the size of the actual "tick" lines.
export const TICK_SIZE = {
  tickSizeOuter: 0,
  tickSizeInner: 5,
};

// The width of the WhiskerSeries cross bar.
const CROSS_BAR_WIDTH = 15;

// The expected height of the text in an axis label, plus some padding.
export const AXIS_LABEL_FONT_HEIGHT = 25;

// Horizontal distance (in px) between the end of a series line, and the center
// of its letter label (A, B, C...)
export const SERIES_LABEL_PADDING = 15;

// The distance (in px) between the tick lines and the tick labels
// The main purpose of this is to make space for the end-of-series letter labels
// (A, B, C...) to prevent them from overlapping the tick labels.
export const Y_AXIS_TICK_PADDING = 16;

// When there are two right axes, the outer one doesn't need as much tick padding,
// because it doesn't need to make space for the end-of-series letter labels.
// So we shrink the padding by this much.
const OUTER_Y_AXIS_TICK_PADDING_REDUCTION = 6;

// The minimum size (in px) for the margin on the left or right side of the plot.
// If a side of the plot has no Y axis drawn along it (no obs points assigned
// to that side) the plot will be padded by this much. This is mainly to make
// space for the end-of-series letter labels (A, B, C...) and enough space for
// an X-axis year label (e.g. "2025") if it winds up aligned with the rightmost
// tick.
export const LR_MARGIN = 25;
export const LR_MARGIN_WITH_TICKS = 80;

// length (in px) of a Port RL line
const PORT_RL_LINE_LENGTH = 100;

// Y offset (in px) between the x,y coordinates of a reading with an indicator,
// and the bottom of the indicator icon for it.
// Negative = up, Positive = down
const INDICATOR_Y_OFFSET = -5;
const INDICATOR_HEIGHT = 10;
const INDICATOR_Y_PADDING = 2;

export const COLORS_POOL: string[] = [
  '#136e38',
  '#107db3',
  '#9c6302',
  '#771a1a',
  '#088185',
  '#6300c0',
  '#518300',
  '#122c6f',
  '#b21c6a',
  '#202020',
];

export const MARKERS_POOL = [
  '\ue968', // triangle
  '\ue966', // circle
  '\ue964', // diamon
  '\ue962', // square
  '\ue960', // rhombus
  '\ue95e', // plus
  '\ue95d', // asterisk
  '\ue95c', // x
  '\ue967', // triangle-solid
  '\ue965', // circle-solid
  '\ue963', // diamond-solid
  '\ue961', // square-solid
  '\ue95f', // rhombus-solid
];

export const LABELS_POOL = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export const getColorForIdx = (
  idx: number,
  colorsPool: string[] = COLORS_POOL
) => {
  return colorsPool[idx % colorsPool.length];
};

export const getMarkerForIdx = (
  idx: number,
  markersPool: string[] = MARKERS_POOL
) => {
  return markersPool[idx % markersPool.length];
};

export const getLabelForIdx = (
  idx: number,
  labelsPool: string = LABELS_POOL
) => {
  return labelsPool[idx % labelsPool.length] || '';
};

export interface TimeSeriesPlotProps {
  paddedMinDatetime: string | null;
  paddedMaxDatetime: string | null;
  timeZone?: string | null;
  yAxes: PlotSettingsAxis<YAxisSide>[];
  readingsSeries: PlotReadingsSeries<YAxisSide>[];
  alarmParamSeries?: TSPlotAlarmParamSeries[];
  annotations?: StoredPlotAnnotation[];
  colorsPool?: string[];
  labelsPool?: string;
  markersPool?: string[];
  onZoom?: TSPlotOnZoomFn;
  // these props are used for customize the appearance
  // of the plot (mainly used by SSR atm)
  horizontalGridlinesTotal?: number;
  gridLinesStyle?: CSSProperties;
  yTicksTotal?: number;
  margin?: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
  };
}

export type TimeSeriesPlotOwnProps = TimeSeriesPlotProps &
  withI18nProps & {
    width?: number | null;
    height?: number | null;
  };

const InnerTimeSeriesPlot: React.FunctionComponent<TimeSeriesPlotOwnProps> =
  function (props) {
    const {
      calculateAlarmPlots,
      calculateSeriesLabels,
      selectSeriesByAxis,
      calculateXTicks,
      calculateAxisDomains,
    } = useMemo(() => makeTimeSeriesPlotSelectors(), []);

    const [isBrushing, setIsBrushing] = useState<boolean>(false);
    const [hoverPoint, setHoverPoint] = useState<null | TSPlotMouseoverPoint>(
      null
    );
    const [selectedPoint, setSelectedPoint] =
      useState<null | TSPlotMouseoverPoint>(null);

    const [isHoverable, setIsHoverable] = useState(true);

    const [plotWrapperRef, plotWrapperWidth, plotWrapperHeight] =
      useResizeObserver<HTMLDivElement>({
        defaultHeight: 500,
        defaultWidth: 500,
      });
    // The size (in pixels) of the plot overall.
    const plotSize = useMemo(() => {
      const size: {
        height: number;
        width: number;
      } = {
        height: plotWrapperHeight,
        width: plotWrapperWidth,
      };

      if (props.width) {
        size.width = props.width;
      }

      if (props.height) {
        size.height = props.height;
      }
      return size;
    }, [plotWrapperHeight, plotWrapperWidth, props.width, props.height]);

    // Computed values to indicate how many ticks should be displayed for yAxis
    // for best visibility of the axis value. (ie. labels not overlapping when height is small)
    // If not specified in the props, it is computed base on plotHeight
    const yTicksTotal = useMemo(() => {
      return props.yTicksTotal || getTicksTotalFromSize(plotSize.height);
    }, [props.yTicksTotal, plotSize.height]);

    // Number of horizontal gridLines
    // usually the same value with yTicksTotal
    const horizontalGridlinesTotal = useMemo(() => {
      return props.horizontalGridlinesTotal || yTicksTotal;
    }, [props.horizontalGridlinesTotal, yTicksTotal]);

    // Figure out min/max values being plotted on y and x axes.
    // (D3 calls this a "domain")
    const axisDomains = calculateAxisDomains(props);

    // Some plotted elements aren't specific to one data series. But they still
    // need a domain. Use the domain props for the left axis, unless there are
    // are no left-hand series, in which case use the right-hand domain props.
    const defaultDomainProps = useMemo<TSPlotDomainProps | null>(() => {
      if (axisDomains) {
        const xDomain = axisDomains.bottom;
        const yDomain =
          axisDomains.left || axisDomains.right || axisDomains.right2;
        if (xDomain && yDomain) {
          return { xDomain, yDomain };
        }
      }
      return null;
    }, [axisDomains]);

    // For convenience (because we need them in a few different places) make
    // functions to convert from one of the Y axes' domains, to the domain
    // of the "default" Y axis.
    const yScaleFromDefaultTo: TSPlotYAxisScales = useMemo(
      () =>
        Object.fromEntries(
          Y_AXIS_SIDES.map((side) => {
            if (
              !defaultDomainProps ||
              !axisDomains ||
              !axisDomains[side] ||
              defaultDomainProps.yDomain === axisDomains[side]
            ) {
              // Default no-args scale, just passes values through with no change.
              return [side, scaleLinear()];
            } else {
              return [
                side,
                scaleLinear()
                  .domain(defaultDomainProps.yDomain!)
                  .range(axisDomains[side]!),
              ];
            }
          })
        ),
      [defaultDomainProps, axisDomains]
    );

    // Combine all data point from all readings series to one big array in order to
    // render it as a LineSeries with strokeWidth = 0.
    // it helps with snapping the point from different series using LineSeries.onNearestXY
    const allDataPoints = useMemo(
      () =>
        selectMouseoverPoints(
          defaultDomainProps,
          props.readingsSeries,
          yScaleFromDefaultTo,
          props.colorsPool,
          // in quickplot, props.markersPool will be undefined, we don't want to use the default MARKERS_POOL
          props.markersPool || []
        ),
      [
        defaultDomainProps,
        props.readingsSeries,
        props.colorsPool,
        props.markersPool,
        yScaleFromDefaultTo,
      ]
    );

    const plotMarkerPoints: {
      x: Date;
      y: number | null;
      label: string;
      style: {
        fill: string;
      };
    }[] = useMemo(() => {
      return allDataPoints
        .filter((point) => Boolean(point.marker))
        .map((p) => ({
          x: p.x,
          y: p.y,
          label: p.marker,
          style: {
            fill: p.seriesColor,
          },
        }));
    }, [allDataPoints]);

    const { unreliableReadingsBySeries, errorBarsBySeries } = useMemo(
      () => ({
        unreliableReadingsBySeries: props.readingsSeries.map((serie) =>
          calculateUnreliablePeriodsForSerie(serie)
        ),
        errorBarsBySeries: props.readingsSeries.map((serie) => {
          if (!serie.settings.show_confidence_level) {
            return null;
          }

          const varianceReadings = serie.readings?.filter(
            (r) => r.yVariance !== undefined
          );
          if (!(varianceReadings && varianceReadings.length > 0)) {
            return null;
          }

          return varianceReadings;
        }),
      }),
      [props.readingsSeries]
    );

    const onNearestXY = useMemo(
      // Throttle the crosshair to update no more than 12 times per second.
      // This doesn't make much of a difference when there are few data points,
      // but it helps prevent the browser from freezing when there are thousands.
      // TODO: Patch React-Vis to memoize more of its "onXY" calculations?
      // Currently it generates a new d3 voronoi on each call to the onmouseover.
      () =>
        throttle((hoverPoint: TSPlotMouseoverPoint | null) => {
          setHoverPoint(hoverPoint);
        }, 1000 / 12),
      []
    );

    const onClosePopup = useCallback(() => {
      setSelectedPoint(null);
      setTimeout(() => {
        setIsHoverable(true);
      }, 100);
    }, []);

    // Create tick formatter functions (mainly to scale all the Y axes to use
    // the same grid lines)
    const tickFormatters = useMemo(() => {
      const tickFormatters = Object.fromEntries(
        Y_AXIS_SIDES.map((side) => {
          if (!axisDomains || !defaultDomainProps || !axisDomains[side]) {
            return [side, undefined];
          }

          const thisDomain = axisDomains[side]!;
          const height = plotSize.height;

          // A d3 function to generate a nice human-readable string from a number,
          // using information about the domain and the number of ticks in the plot to
          // decide how many decimals to round to.
          const formatTick = scaleLinear()
            .domain(thisDomain)
            .tickFormat(AxisUtils.getTicksTotalFromSize(height));

          // The final formatter we use, converts from default y axis scale to
          // right axis scale, then has D3 generate a nice label for the value.
          const applyScaleAndFormatTick =
            axisDomains[side] === defaultDomainProps.yDomain
              ? // Don't need a scaler/formatter for the "default" axis
                undefined
              : (valueInDefaultScale: number) =>
                  formatTick(yScaleFromDefaultTo[side](valueInDefaultScale));

          return [side, applyScaleAndFormatTick];
        })
      );
      return tickFormatters;
    }, [axisDomains, defaultDomainProps, plotSize.height, yScaleFromDefaultTo]);

    /**
     * A callback function for our `<Highlight>` component. Called when
     * the user has finished dragging a highlight area on the prop.
     */
    const handleBrushEnd = useCallback(
      (area) => {
        setIsBrushing(false);
        if (!(area && axisDomains && props.onZoom)) {
          return;
        }

        // Notify our `onZoom` handler of the new scale parameters
        // (converted into the format used in our URLs)
        const minDatetime = formatDatetimeForStorage(area.left);
        const maxDatetime = formatDatetimeForStorage(area.right);
        const yAxes: PlotSettingsAxis<YAxisSide>[] = Y_AXIS_SIDES.filter(
          // Don't bother zooming an axis that isn't currently in use!
          (a) => Boolean(axisDomains[a])
        ).map((side) => ({
          side,
          minimum: String(yScaleFromDefaultTo[side](area.bottom)),
          maximum: String(yScaleFromDefaultTo[side](area.top)),
        }));

        props.onZoom.call(null, {
          minDatetime,
          maxDatetime,
          yAxes,
        });
      },
      [axisDomains, props.onZoom, yScaleFromDefaultTo]
    );
    const getNull = useCallback((d: any) => d.y !== null, []);

    const { alarmsForPlotting, alarmThresholdLabels } =
      calculateAlarmPlots(props);

    const portRLPlotLines = useMemo(
      () =>
        calculatePortRLPlotLines(
          props.readingsSeries,
          axisDomains,
          defaultDomainProps,
          yScaleFromDefaultTo,
          props.labelsPool,
          props.colorsPool
        ),
      [
        axisDomains,
        defaultDomainProps,
        props.colorsPool,
        props.labelsPool,
        props.readingsSeries,
        yScaleFromDefaultTo,
      ]
    );

    const annotations = useMemo(
      () => calculateAnnotationPlotLines(defaultDomainProps, props.annotations),
      [defaultDomainProps, props.annotations]
    );

    // Determine labels (if any) for the right and left axis
    const seriesByAxis = selectSeriesByAxis(props);
    const yAxisLabels = mapValues(seriesByAxis, (thisSideSeries) => {
      const verticalTitles = uniq(
        thisSideSeries.map(
          (series) =>
            series.observationPoint.instrument_type.default_vertical_plot_title
        )
      );

      return verticalTitles.length === 1 ? verticalTitles[0] : null;
    });

    // When there is only one right axis, we label it "R" in the legend.
    // When there are two right axes, we label them "R1" and "R2".
    const legendYAxisNames = useMemo(
      () => ({
        left: false,
        right:
          axisDomains && axisDomains.right2
            ? props.i18n._(t`R1 axis`)
            : props.i18n._(t`R axis`),
        right2:
          axisDomains && axisDomains.right
            ? props.i18n._(t`R2 axis`)
            : props.i18n._(t`R axis`),
      }),
      [axisDomains, props.i18n]
    );

    const hasLeftAxis = props.readingsSeries.some(
      (s) => s.settings.axis_side === 'left'
    );
    const hasRightAxis = props.readingsSeries.some(
      (s) => s.settings.axis_side === 'right'
    );
    const hasRight2Axis = props.readingsSeries.some(
      (s) => s.settings.axis_side === 'right2'
    );
    const marginProps = useMemo(() => {
      return {
        left: hasLeftAxis ? LR_MARGIN_WITH_TICKS : LR_MARGIN,
        right:
          hasRightAxis && hasRight2Axis
            ? LR_MARGIN_WITH_TICKS * 2
            : hasRightAxis || hasRight2Axis
            ? LR_MARGIN_WITH_TICKS
            : LR_MARGIN,
        // TODO: top margin of 0 needed to make zoom-highlight line up
        // correctly, due to bug in `<Highlight>` component.
        top: 0,
        ...props.margin,
      };
    }, [hasLeftAxis, hasRight2Axis, hasRightAxis, props.margin]);

    const numSeries = props.readingsSeries.length;
    const numSeriesLoaded = props.readingsSeries.filter(
      (s) => !s.loading
    ).length;

    if (!axisDomains) {
      // TODO: Show something when there is no data?
      return <h1>no data!</h1>;
    }

    // This component assumes the data has already been sorted chronologically.
    const { xTicks, xGridLines, xTickFormat } = calculateXTicks(props);

    return (
      <>
        <div
          className="plot-area"
          ref={plotWrapperRef}
          style={{
            height: plotSize.height,
          }}
        >
          <XYPlot<AbstractSeriesProps<TSPlotReading>>
            margin={marginProps}
            xType="time"
            {...defaultDomainProps}
            {...plotSize}
          >
            {/**
             * NOTE: The order of components here, determines the order they're
             * layered in during rendering. So, components listed further down
             * will cover up components higher up.
             */}
            {/* The background shading for the alarm thresholds boxes (rendered
              early so it's below the grid lines.) */}
            {alarmsForPlotting.map((alarmType) => (
              <VerticalRectSeries<TSPlotAlarmParamBox>
                key={`${alarmType.type} ${alarmType.level} background`}
                className={`plot-alarm-background ${alarmType.alarmLevelClassName} ${alarmType.alarmTypeClassName}`}
                data={alarmType.shadedAlarmAreas}
                color={null}
                {...defaultDomainProps}
              />
            ))}
            {errorBarsBySeries.map((errorBars, idx) => {
              return (
                errorBars && (
                  <WhiskerSeries
                    key={props.readingsSeries[idx].observationPoint.id}
                    data={errorBars}
                    stroke={getColorForIdx(idx, props.colorsPool)}
                    className="plot-readings-line"
                    crossBarWidth={CROSS_BAR_WIDTH}
                  />
                )
              );
            })}
            <HorizontalGridLines
              style={props.gridLinesStyle || GRID_LINES_STYLE}
              tickTotal={horizontalGridlinesTotal}
            />
            <VerticalGridLines
              style={props.gridLinesStyle || GRID_LINES_STYLE}
              tickValues={xGridLines}
            />
            {/* Border line along the top of the graph. */}
            <XAxis
              orientation="top"
              className="xAxis"
              position="middle"
              hideTicks
              style={TICK_LINE_AXIS_STYLE}
              title={
                numSeriesLoaded < numSeries
                  ? `loading ${numSeriesLoaded} / ${numSeries} ...`
                  : undefined
              }
            />
            {/* Border line and tick marks along the bottom of the graph */}
            <XAxis
              orientation="bottom"
              className="xAxis"
              style={TICK_LINE_AXIS_STYLE}
              tickValues={xTicks}
              {...TICK_SIZE}
            />
            {/* Border line and tick marks along the left edge of the plot */}
            {hasLeftAxis ? (
              <YAxis
                orientation="left"
                tickPadding={Y_AXIS_TICK_PADDING}
                className="yAxis left"
                style={TICK_LINE_AXIS_STYLE}
                tickTotal={yTicksTotal}
                {...TICK_SIZE}
              />
            ) : (
              <YAxis
                orientation="left"
                className="yAxis"
                hideTicks
                style={TICK_LINE_AXIS_STYLE}
              />
            )}
            {/* Border line and tick marks along the right edge of the plot */}
            {hasRightAxis ? (
              <YAxis
                orientation="right"
                tickPadding={Y_AXIS_TICK_PADDING}
                className="yAxis right"
                style={TICK_LINE_AXIS_STYLE}
                tickTotal={yTicksTotal}
                tickFormat={tickFormatters.right}
                {...TICK_SIZE}
              />
            ) : hasRight2Axis ? (
              <YAxis
                orientation="right"
                tickPadding={Y_AXIS_TICK_PADDING}
                className="yAxis right2"
                style={TICK_LINE_AXIS_STYLE}
                tickTotal={yTicksTotal}
                tickFormat={tickFormatters.right2}
                {...TICK_SIZE}
              />
            ) : (
              <YAxis
                orientation="right"
                className="yAxis"
                hideTicks
                style={TICK_LINE_AXIS_STYLE}
              />
            )}
            {/* The lines marking the actual alarm parameter thresholds. Plotted
              later than the background shading, so it can be ABOVE the grid
              lines. */}
            {alarmsForPlotting.map((alarmType) => (
              <LineSeries<TSPlotAlarmParamOutlinePoint>
                key={`${alarmType.type} ${alarmType.level} lines`}
                className={`plot-alarm-line ${alarmType.alarmLevelClassName} ${alarmType.alarmTypeClassName}`}
                data={alarmType.alarmThresholdLines}
                getNull={getNull}
                color={null}
                {...defaultDomainProps}
              />
            ))}
            {/* The labels for the alarm thresholds. */}
            <LabelSeries<TSPlotAlarmParamLabel>
              className="plot-label-inside"
              data={alarmThresholdLabels}
              labelAnchorX="start"
              labelAnchorY="auto"
              {...defaultDomainProps}
            />
            {/* Port RL lines & labels */}
            <CustomSVGSeries<{
              x: Date;
              y: number;
              port_rl: number;
              color: string;
              label: string;
            }>
              data={portRLPlotLines}
              customComponent={(serie) => (
                // CustomSVGSeries wraps each element in a <g> tag that's already
                // positioned at the xy coordinates of the item being plotted.
                // So all the xy coordinates in these tags are relative to that.
                <>
                  <line
                    className="plot-port-rl-line"
                    // A line from the left edge of the plot...
                    x1="0"
                    // and 100 pixels long
                    x2={PORT_RL_LINE_LENGTH}
                    // Vertically lined up exactly at the port RL's value
                    y1="0"
                    y2="0"
                    stroke={serie.color}
                    strokeWidth={2}
                  />
                  <text
                    className="plot-port-rl-label"
                    x="1"
                    y="0"
                    // Vertically align the text above the line
                    // TODO: A bug in WebKit causes it to render "text-after-edge"
                    // as middle-aligned. So to make this work in wkhtmltopdf
                    // we'll instead just shift these labels up by 1/2 em.
                    //dominantBaseline="text-after-edge"
                    dy="-0.5em"
                    // Horizontally align the text at the left of the line.
                    textAnchor="start"
                    style={{ fill: serie.color }}
                    stroke={serie.color}
                    fill={serie.color}
                    color={serie.color}
                  >
                    <Trans>Port {serie.label}</Trans>
                  </text>
                </>
              )}
              {...defaultDomainProps}
            />
            {/* Annotation lines */}
            {annotations?.horizontalLines && (
              <LineSeries
                key="annotation-lines-horizontal"
                className="plot-annotation-line"
                data={annotations.horizontalLines}
                stroke={ANNOTATION_LINE_COLOR}
                getNull={getNull}
              />
            )}
            {annotations?.verticalLines && (
              <LineSeries
                key="annotation-lines-vertical"
                className="plot-annotation-line"
                data={annotations.verticalLines}
                stroke={ANNOTATION_LINE_COLOR}
                getNull={getNull}
              />
            )}
            {annotations?.horizontalStubs && (
              <CustomSVGSeries<
                ArrayElements<
                  Exclude<typeof annotations['horizontalStubs'], null>
                >
              >
                data={annotations.horizontalStubs}
                customComponent={({ stubPosition }) => {
                  // CustomSVGSeries wraps each element in a <g> tag that's already
                  // positioned at the xy coordinates of the item being plotted.
                  // So all the xy coordinates in these tags are relative to that.
                  return (
                    <line
                      className="plot-annotation-line"
                      y1="0"
                      y2="0"
                      x1="0"
                      x2={
                        stubPosition === 'left'
                          ? PORT_RL_LINE_LENGTH
                          : -1 * PORT_RL_LINE_LENGTH
                      }
                      stroke={ANNOTATION_LINE_COLOR}
                    />
                  );
                }}
                {...defaultDomainProps}
              />
            )}
            {annotations?.horizontalLabels && (
              <CustomSVGSeries<
                ArrayElements<
                  Exclude<typeof annotations['horizontalLabels'], null>
                >
              >
                key={`annotation-labels-horizontal`}
                data={annotations.horizontalLabels}
                customComponent={({ label, position, stubPosition }) => (
                  <text
                    className="plot-label-inside annotation-label"
                    x="0"
                    y="0"
                    dominantBaseline={
                      position === StoredPlotAnnotationLabelPosition.ABOVE
                        ? 'auto'
                        : 'hanging'
                    }
                    dx={
                      stubPosition === StoredPlotAnnotation_STUB_POSITION.RIGHT
                        ? '-0.3em'
                        : '0.3em'
                    }
                    dy={
                      position === StoredPlotAnnotationLabelPosition.ABOVE
                        ? '-0.5em'
                        : '0.5em'
                    }
                    textAnchor={
                      stubPosition === StoredPlotAnnotation_STUB_POSITION.RIGHT
                        ? 'end'
                        : 'start'
                    }
                  >
                    {label}
                  </text>
                )}
              />
            )}

            {annotations?.verticalStubs && (
              <CustomSVGSeries<
                ArrayElements<
                  Exclude<typeof annotations['verticalStubs'], null>
                >
              >
                data={annotations.verticalStubs}
                customComponent={({ stubPosition }) => {
                  // CustomSVGSeries wraps each element in a <g> tag that's already
                  // positioned at the xy coordinates of the item being plotted.
                  // So all the xy coordinates in these tags are relative to that.
                  return (
                    <line
                      className="plot-annotation-line"
                      x1="0"
                      x2="0"
                      y1="0"
                      y2={
                        stubPosition ===
                        StoredPlotAnnotation_STUB_POSITION.BOTTOM
                          ? -1 * PORT_RL_LINE_LENGTH
                          : PORT_RL_LINE_LENGTH
                      }
                      stroke={ANNOTATION_LINE_COLOR}
                    />
                  );
                }}
                {...defaultDomainProps}
              />
            )}
            {annotations?.verticalLabels && (
              <CustomSVGSeries<
                ArrayElements<
                  Exclude<typeof annotations['verticalLabels'], null>
                >
              >
                key={`annotation-labels-vertical`}
                data={annotations.verticalLabels}
                customComponent={({ label, position, stubPosition }) => (
                  <text
                    className="plot-label-inside annotation-label"
                    x="-1"
                    y="0"
                    dy={
                      position === StoredPlotAnnotationLabelPosition.LEFT
                        ? '-0.5em'
                        : '0.5em'
                    }
                    dx={
                      stubPosition === StoredPlotAnnotation_STUB_POSITION.TOP
                        ? '-0.5em'
                        : '0.5em'
                    }
                    dominantBaseline={
                      position === StoredPlotAnnotationLabelPosition.LEFT
                        ? 'auto'
                        : 'hanging'
                    }
                    textAnchor={
                      stubPosition === StoredPlotAnnotation_STUB_POSITION.TOP
                        ? 'end'
                        : 'start'
                    }
                    transform="rotate(-90)"
                  >
                    {label}
                  </text>
                )}
              />
            )}
            {/* The actual lines representing the values of the data series. */}
            {props.readingsSeries.flatMap(
              (
                { loading, readings, settings: { axis_side: y_axis_side } },
                serieIdx
              ) => {
                if (!readings) {
                  return [];
                }

                const unreliableReadings = unreliableReadingsBySeries[serieIdx];

                return [
                  // This line is for all the readings, reliable and unreliable.
                  <LineSeries<TSPlotReading>
                    key={`line-${serieIdx}`}
                    animation={false} // Disabled due to issues when exporting to PDF
                    data={readings}
                    className="plot-readings-line"
                    stroke={getColorForIdx(serieIdx, props.colorsPool)}
                    getNull={getNull}
                    xDomain={axisDomains['bottom']}
                    yDomain={axisDomains[y_axis_side]!}
                    strokeStyle={loading ? 'dashed' : 'solid'}
                  />,
                  // Draw a dashed line for the unreliable readings. (Actually,
                  // a white line to hide the reliable readings line, and then
                  // a dashed line on top of that.)
                  ...(unreliableReadings?.length > 0
                    ? [
                        <LineSeries
                          key={`unreliable-bg-${serieIdx}`}
                          animation={false} // Disabled due to issues when exporting to PDF
                          data={unreliableReadings}
                          className="plot-readings-line"
                          stroke="white"
                          strokeWidth={3}
                          getNull={getNull}
                          xDomain={axisDomains['bottom']}
                          yDomain={axisDomains[y_axis_side]!}
                        />,
                        <LineSeries
                          key={`unreliable-fg-${serieIdx}`}
                          animation={false} // Disabled due to issues when exporting to PDF
                          data={unreliableReadings}
                          className="plot-readings-line"
                          stroke={getColorForIdx(serieIdx, props.colorsPool)}
                          getNull={getNull}
                          xDomain={axisDomains['bottom']}
                          yDomain={axisDomains[y_axis_side]!}
                          style={{ strokeDasharray: '4px 4px' }}
                        />,
                      ]
                    : []),
                ];
              }
            )}
            {/* Comments & media indicators */}
            {props.readingsSeries.map(({ readings, settings }, readingIdx) => {
              if (
                !readings ||
                (!settings.show_analysis_comment_indicators &&
                  !settings.show_inspector_comment_indicators &&
                  !settings.show_media_indicators)
              ) {
                return null;
              }
              const {
                axis_side: y_axis_side,
                show_media_indicators: showMedia,
                show_analysis_comment_indicators: showAnalysisComments,
                show_inspector_comment_indicators: showInspectorComments,
              } = settings;

              // A function that indicates whether to show a comment indicator
              // above a particular reading.
              const hasCommentIndicatorFn: (
                r: TSPlotReading
              ) => boolean | undefined =
                showAnalysisComments && showInspectorComments
                  ? (r) =>
                      r.hasAnalysisCommentIndicator ||
                      r.hasInspectorCommentIndicator
                  : showAnalysisComments
                  ? (r) => r.hasAnalysisCommentIndicator
                  : showInspectorComments
                  ? (r) => r.hasInspectorCommentIndicator
                  : () => false;

              return (
                <LabelSeries<IconPoint>
                  key={`comments-and-media-indicators-${readingIdx}`}
                  style={{
                    fontFamily: 'icons',
                    fill: getColorForIdx(readingIdx, props.colorsPool),
                    stroke: 'none',
                  }}
                  labelAnchorX="middle"
                  labelAnchorY="auto"
                  dy="-0.5em"
                  xDomain={axisDomains['bottom']}
                  yDomain={axisDomains[y_axis_side]!}
                  data={readings
                    .flatMap((reading): IconPoint[] => {
                      const icons: IconPoint[] = [];
                      const showCommentIcon = hasCommentIndicatorFn(reading);
                      const showMediaIcon =
                        showMedia && reading.hasMediaIndicator;
                      if (showCommentIcon) {
                        icons.push(
                          // To put a white outline around the comment icons, print
                          // another copy of the icon in the series' color.
                          {
                            ...reading,
                            // Comment icon (see `_icons.scss`)
                            label: '\uE920',
                            yOffset: INDICATOR_Y_OFFSET,
                            className: 'plot-comment-indicator-background',
                            style: {
                              stroke: 'white',
                              strokeWidth: '3px',
                              fill: 'white',
                              fontWeight: 900,
                              strokeLinecap: 'square',
                              strokeLinejoin: 'round',
                            },
                          },
                          {
                            ...reading,
                            // Comment icon (see `_icons.scss`)
                            label: '\uE920',
                            yOffset: INDICATOR_Y_OFFSET,
                          }
                        );
                      }
                      if (showMediaIcon) {
                        // If there is also a comment indicator showing, place the media indicator below it
                        const yOffset = showCommentIcon
                          ? INDICATOR_Y_OFFSET -
                            (INDICATOR_HEIGHT + INDICATOR_Y_PADDING)
                          : INDICATOR_Y_OFFSET;

                        icons.push(
                          // To put a white outline around the media icons, print
                          // another copy of the icon in the series' color.
                          {
                            ...reading,
                            // Media icon (see `_icons.scss`)
                            label: '\uE942',
                            yOffset,
                            className: 'plot-media-indicator-background',
                            style: {
                              stroke: 'white',
                              strokeWidth: '3px',
                              fill: 'white',
                              fontWeight: 900,
                              strokeLinecap: 'square',
                              strokeLinejoin: 'round',
                            },
                          },
                          {
                            ...reading,
                            // Media icon (see `_icons.scss`)
                            label: '\uE942',
                            yOffset,
                          }
                        );
                      }
                      return icons;
                    })
                    .filter(isTruthy)}
                />
              );
            })}
            {/* ELEMENTS BELOW <Borders> CAN BE OUTSIDE THE MAIN GRAPH AREA

              The `<Borders />` component lays opaque rectangles onto the SVG,
              in order to hide elements that overlap outside of the main
              graph area (like a data series that goes below the bottom of the
              graph). Additionally, data that overlaps outside the whole SVG,
              gets covered up by CSS rules.
          */}
            <BordersPadded padding={2} />
            {/* The tick labels along the bottom edge of the graph */}
            <XAxis
              orientation="bottom"
              className="xAxis"
              tickFormat={xTickFormat}
              style={TICK_LABEL_AXIS_STYLE}
              tickValues={xTicks}
              {...TICK_SIZE}
            />
            {/* The axis label for the bottom of the graph. (Placed in a separate element
          so that we can position it below the graph. This should be done with CSS,
          but IE11 doesn't support CSS transforms on SVG elements.) */}
            <XAxis
              title={props.i18n._(t`Time`)}
              top={plotSize.height}
              orientation="bottom"
              position="middle"
              hideLine
              hideTicks
            />
            {/* The tick labels for the left Y axis */}
            {hasLeftAxis && (
              <YAxis
                orientation="left"
                tickPadding={Y_AXIS_TICK_PADDING}
                className="yAxis left"
                style={TICK_LABEL_AXIS_STYLE}
                tickTotal={yTicksTotal}
                {...TICK_SIZE}
              />
            )}
            {/* The tick labels for the right Y axis */}
            {hasRightAxis && (
              <YAxis
                orientation="right"
                tickPadding={Y_AXIS_TICK_PADDING}
                className="yAxis right"
                tickFormat={tickFormatters.right}
                style={TICK_LABEL_AXIS_STYLE}
                tickTotal={yTicksTotal}
                {...TICK_SIZE}
              />
            )}
            {/* The right2 axis.
             */}
            {hasRight2Axis &&
              (hasRightAxis ? (
                // If there are two right-side Y axes, then right2 is fully outside
                // of the graph, so we need to draw the full axis here, with ticks
                // AND labels.
                <YAxis
                  orientation="right"
                  tickPadding={
                    Y_AXIS_TICK_PADDING - OUTER_Y_AXIS_TICK_PADDING_REDUCTION
                  }
                  className="yAxis right2"
                  tickFormat={tickFormatters.right2}
                  style={FULL_AXIS_STYLE}
                  tickTotal={yTicksTotal}
                  {...TICK_SIZE}
                  // Position this axis to the right of the other two axes.
                  marginLeft={marginProps.left + LR_MARGIN_WITH_TICKS}
                />
              ) : (
                // If right2 is the only right-side Y axis, then its ticks have
                // already been drawn inside the graph, and we only need to
                // draw the tick labels here.
                <YAxis
                  orientation="right"
                  tickPadding={Y_AXIS_TICK_PADDING}
                  className="yAxis right2"
                  tickFormat={tickFormatters.right2}
                  style={TICK_LABEL_AXIS_STYLE}
                  tickTotal={yTicksTotal}
                  {...TICK_SIZE}
                />
              ))}
            {/* The axis labels for the left and right edges of the plot (positioned via a separate
          element rather than CSS, as an IE11 workaround.) */}
            {Boolean(yAxisLabels.left) && (
              <YAxis
                title={yAxisLabels.left!}
                // Line up the label flush with the left side of the SVG.
                // (It should be position correctly due to the left-margin we
                // put on the whole plot.)
                left={0}
                marginLeft={0}
                orientation="left"
                position="middle"
                hideLine
                hideTicks
              />
            )}
            {Boolean(yAxisLabels.right) && (
              <YAxis
                title={yAxisLabels.right!}
                // Position this label to the right of its associated axis.
                marginLeft={marginProps.left + LR_MARGIN_WITH_TICKS}
                orientation="right"
                position="middle"
                hideLine
                hideTicks
              />
            )}
            {Boolean(yAxisLabels.right2) && (
              <YAxis
                title={yAxisLabels.right2!}
                marginLeft={
                  // Position this label to the right of both right axes.
                  marginProps.left + marginProps.right
                }
                orientation="right"
                position="middle"
                hideLine
                hideTicks
              />
            )}
            {/* Letter labels (A, B, C, etc) at left and right-hand ends of the
              data series lines. */}
            {props.readingsSeries.map(
              ({ settings: { axis_side: y_axis_side } }, idx) => {
                const letters = calculateSeriesLabels(props)[idx];
                if (!letters?.length) {
                  return null;
                }
                const letterPlotPoints = letters.map(({ x, y, side }) => ({
                  x,
                  y,
                  label: getLabelForIdx(idx, props.labelsPool),
                  xOffset: SERIES_LABEL_PADDING * (side === 'left' ? -1 : 1),
                }));
                return (
                  <LabelSeries<{
                    x: Date;
                    y: number;
                    label: string;
                    xOffset: number;
                  }>
                    key={`label-${idx}`}
                    data={letterPlotPoints}
                    allowOffsetToBeReversed={false}
                    labelAnchorY="middle"
                    style={{
                      fill: getColorForIdx(idx, props.colorsPool),
                      stroke: getColorForIdx(idx, props.colorsPool),
                    }}
                    xDomain={axisDomains['bottom']}
                    yDomain={axisDomains[y_axis_side]!}
                  />
                );
              }
            )}
            {/* Mouseover handler, to find the nearest point to the mouse, out
              of all the data series. */}
            {!isBrushing &&
              isHoverable &&
              !detectExportMode() &&
              allDataPoints.length > 0 && (
                <LineSeries<TSPlotMouseoverPoint>
                  animation={false}
                  data={allDataPoints}
                  className="plot-readings-invisible-line"
                  strokeWidth={0}
                  getNull={getNull}
                  onNearestXY={onNearestXY}
                />
              )}
            {plotMarkerPoints.length > 0 ? (
              <LabelSeries<typeof plotMarkerPoints[0]>
                className="plot-markers"
                labelAnchorX="middle"
                labelAnchorY="central"
                data={plotMarkerPoints}
              />
            ) : null}
            {/* Vertical "hover" line */}
            {!isBrushing && hoverPoint && (
              <CrosshairWithReticle
                showVertical={true}
                showHorizontal={false}
                values={[hoverPoint]}
                onReticleClick={(point, position) => {
                  setSelectedPoint({
                    ...point,
                    position,
                  });
                  setIsHoverable(false);
                }}
                {...defaultDomainProps}
              />
            )}
            {/* Data point detail popup */}
            {selectedPoint && (
              <Popup point={selectedPoint}>
                {selectedPoint.bucket ? (
                  <BucketInfo
                    point={selectedPoint}
                    timeZone={props.timeZone}
                    onClose={onClosePopup}
                    onZoomIn={(startDatetime, endDatetime) => {
                      if (props.onZoom) {
                        props.onZoom.call(null, {
                          minDatetime: startDatetime,
                          maxDatetime: endDatetime,
                          yAxes: [],
                        });
                      }
                    }}
                  />
                ) : (
                  <ReadingInfo
                    point={selectedPoint}
                    timeZone={props.timeZone}
                    onClose={onClosePopup}
                  />
                )}
              </Popup>
            )}
            {/* Drag-and-drop zoom handler */}
            {!selectedPoint && props.onZoom && (
              <Highlight<Date, number>
                onBrushStart={() => {
                  setIsBrushing(true);
                  setHoverPoint(null);
                }}
                onBrushEnd={handleBrushEnd}
                className={'plot-zoom'}
              />
            )}
          </XYPlot>
        </div>
        <div className="plot-legend plot-legend-timeseries">
          <ol>
            {props.readingsSeries.map((serie, idx) => (
              <div key={idx} className={classNames({ loading: serie.loading })}>
                <li>
                  <span
                    style={{ color: getColorForIdx(idx, props.colorsPool) }}
                  >
                    {getLabelForIdx(idx, props.labelsPool)}
                  </span>{' '}
                  {getMarkerForIdx(idx, props.markersPool || []) && (
                    <>
                      <span
                        className="plot-marker-legend"
                        style={{ color: getColorForIdx(idx, props.colorsPool) }}
                      >
                        {getMarkerForIdx(idx, props.markersPool || [])}
                      </span>{' '}
                    </>
                  )}
                  {legendYAxisNames[serie.settings.axis_side]
                    ? `(${legendYAxisNames[serie.settings.axis_side]}) - `
                    : null}
                  <SeriesLegendText series={serie} />
                  {serie.errorMessage && (
                    <span className="error">{serie.errorMessage}</span>
                  )}
                </li>
              </div>
            ))}
          </ol>
        </div>
      </>
    );
  };

export const TimeSeriesPlot = withI18n()(InnerTimeSeriesPlot);
