import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataLoggerList,
  unmountDataLoggerList,
} from 'ducks/data-logger/list';
import { DataLoggersListView } from './DataLoggersListView';
import { Pagination } from 'util/backendapi/pagination';
import { FullState } from 'main/reducers';

/**
 * TODO: Turn this into a reports framework screen.
 */
export function DataLoggersListScreen(_props: {}) {
  const dispatch = useDispatch();
  const state = useSelector((state: FullState) => state.dataLogger.list);

  const limit = Pagination.LIST_PAGE_SIZE_DEFAULT;
  const [offset, setOffset] = React.useState(0);

  React.useEffect(() => {
    dispatch(fetchDataLoggerList(limit, offset));
  }, [dispatch, limit, offset]);

  React.useEffect(
    () => () => {
      dispatch(unmountDataLoggerList());
    },
    [dispatch]
  );

  const handlePageChanged = React.useCallback(
    (pageNumber = 0) => setOffset(pageNumber * limit),
    [limit]
  );

  return (
    <DataLoggersListView
      dataLoggers={state.dataLoggers}
      loading={state.loading}
      error={state.error}
      pagination={Pagination.fromRequestedReceived(
        { limit, offset },
        state.pagination
      )}
      onPageChanged={handlePageChanged}
    />
  );
}
