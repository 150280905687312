import React from 'react';
import PageStandard from '../../components/modules/pagestandard/pagestandard';
import { Trans } from '@lingui/macro';
import { formatDatetimeForDisplay } from 'util/dates';
import Loading from 'components/base/loading/loading';
import { AlertWarning, AlertDanger } from 'components/base/alert/alert';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ScatterPlotSettingsModal } from './settings/ScatterPlotSettingsModal';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { Model } from 'util/backendapi/models/api.interfaces';
import { SavePlotModal } from 'screens/quickplot/SavePlotModal';
import { PlotType } from 'util/backendapi/types/Enum';
import {
  ScatterPlot,
  ScatterPlotReadingSeries,
  ScatterPlotProps,
} from 'components/plots/ScatterPlot';
import { ScatterPlotSettings } from 'components/plots/timeseriesplot.types';
import { ExportPanel } from 'components/modules/exportpanel/exportpanel';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import Button from 'components/base/button/button';

export type Props = {
  loading: boolean;
  error: string | null;
  area: Model.AreaDecorated | null;
  x: ScatterPlotReadingSeries | null;
  y: ScatterPlotReadingSeries | null;
  userDisplayName: string;
  xObservationPointCode: string;
  yObservationPointCode: string;
  startDateTime: string;
  endDateTime: string;
  timeZone: string;
  interpolate: boolean;
  plotmarks: boolean;
  markconnections: boolean;
  axes: ScatterPlotSettings['axes'];
  resolvedSettings: ScatterPlotSettings | null;
  highlightPeriods: ScatterPlotSettings['highlight_periods'];
  onZoom: ScatterPlotProps['onZoom'];
  onResetZoom?: () => void;
};

export class ScatterPlotView extends React.Component<Props, {}> {
  render() {
    const {
      loading,
      error,
      xObservationPointCode,
      yObservationPointCode,
      x,
      y,
      interpolate,
      plotmarks,
      markconnections,
      axes,
      startDateTime,
      endDateTime,
      timeZone,
      highlightPeriods,
    } = this.props;

    const isZoomed = Boolean(this.props.onResetZoom);

    const pageProps = {
      name: 'scatterplot',
      header: <Trans>Scatter Plot</Trans>,
      subHeader:
        // NOTE: Y axis name is printed before X axis name
        xObservationPointCode && yObservationPointCode && (
          <Trans>
            {yObservationPointCode}
            {' & '}
            {xObservationPointCode}
          </Trans>
        ),
    };

    const displayedDateRange = !isZoomed && (
      <div className="plot-page-header-info">
        <h2 data-testid="plot-date-range">
          {formatDatetimeForDisplay(startDateTime, timeZone)} -{' '}
          {formatDatetimeForDisplay(endDateTime, timeZone)}
        </h2>
      </div>
    );

    const plotHeader = (
      <div className="page-content-header columns-fluid">
        {displayedDateRange}
        <ActionBlock className="text-right">
          {this.props.onResetZoom && (
            <Button onClick={this.props.onResetZoom}>
              <Trans>Reset</Trans>
            </Button>
          )}
          <HasPermission check="can_create_stored_plots">
            <ButtonShowModal
              name="save-plot"
              iconType="icon-save"
              modalContent={() => <SavePlotModal plotType={PlotType.SCATTER} />}
            >
              <Trans>Save</Trans>
            </ButtonShowModal>
          </HasPermission>
          <ButtonShowModal
            name="settings"
            iconType="icon-cog"
            key={`${xObservationPointCode}-${yObservationPointCode}`}
            autoShow={!xObservationPointCode || !yObservationPointCode}
            shortcut="GOTO_SETTINGS"
            modalContent={({ hideModal }: { hideModal: any }) => (
              <ScatterPlotSettingsModal hideModal={hideModal} />
            )}
          >
            <Trans>Settings</Trans>
          </ButtonShowModal>
          <ButtonShowModal
            name="export"
            disabled={!this.props.resolvedSettings}
            modalContent={() => (
              <ExportPanel
                title={<Trans>Export scatter plot</Trans>}
                description={
                  <Trans>
                    The plot will be exported with the same settings as
                    displayed on screen.
                  </Trans>
                }
                canExportPdf={true}
                getExportUrl={() => ({
                  path: '/export/pdf/scatter-plots/',
                  queryParams: new URLSearchParams({
                    settings: JSON.stringify(this.props.resolvedSettings),
                  }),
                })}
              />
            )}
            iconType="icon-export"
          >
            <Trans>Export</Trans>
          </ButtonShowModal>
        </ActionBlock>
      </div>
    );

    if (!xObservationPointCode || !yObservationPointCode) {
      return <PageStandard {...pageProps}>{plotHeader}</PageStandard>;
    }

    return (
      <PageStandard {...pageProps}>
        {plotHeader}
        {loading ? <Loading /> : null}
        {error ? <AlertDanger>{error}</AlertDanger> : null}

        {!loading && !error && (!x || !y) && (
          <AlertWarning>
            <Trans>No readings found.</Trans>
          </AlertWarning>
        )}
        {!loading && !error && x && y ? (
          <ScatterPlot
            showMarkConnections={markconnections}
            showPlotMarks={plotmarks}
            interpolate={interpolate}
            x={x}
            y={y}
            xObservationPointCode={xObservationPointCode}
            yObservationPointCode={yObservationPointCode}
            highlightPeriods={highlightPeriods}
            axes={axes}
            onZoom={this.props.onZoom}
            timeZone={timeZone}
          />
        ) : null}
      </PageStandard>
    );
  }
}
