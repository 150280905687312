import React from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';

/**
 * A Formik field for a boolean checkbox that looks like button.
 */
export interface CheckboxToggleFieldProps {
  name: string;
  label: React.ReactNode;
  disabled?: boolean;
  className?: string;
}

const InnerCheckboxToggleField: React.FunctionComponent<
  FieldProps & CheckboxToggleFieldProps
> = (props) => {
  const { field, form, label } = props;

  const handleChange = React.useCallback(
    () => form.setFieldValue(field.name, !field.value),
    [field.name, field.value, form]
  );

  const fieldId = `checkbox-${field.name}`;

  return (
    <div className={classNames('checkbox-toggle-wrapper', props.className)}>
      <Field
        type="checkbox"
        name={field.name}
        id={fieldId}
        checked={field.value}
        value={field.value}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <label htmlFor={fieldId}>{label}</label>
    </div>
  );
};

export const CheckboxToggleField = (props: CheckboxToggleFieldProps) => (
  <Field component={InnerCheckboxToggleField} {...props} />
);
