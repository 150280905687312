import React from 'react';
import { Trans } from '@lingui/macro';
import { formatDatetimeForDisplay } from 'util/dates';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import {
  ReportColumn,
  ReportFilter,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { ReprocessingRequestActionsCell } from './ActionsCell';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';
import { reportFilterAreas } from 'components/modules/report/filter/fields/reportFilterAreas';

export interface ReprocessingRequestColumnProps {
  hasCancelRequestPermission: boolean;
  hasExecuteRequestPermission: boolean;
  onCancelRequest: (id: number) => Promise<void>;
  onConfirmRequest: (id: number) => Promise<void>;
  refreshList: () => void;
}

export function reprocessingRequestsColumns(
  extraProps?: ReprocessingRequestColumnProps
): ReportColumn<Model.ReportsReadingProcessingRequest>[] {
  return [
    {
      label: <Trans>Requested date</Trans>,
      name: 'created_datetime',
      additionalFields: ['area__time_zone__name'],
      hideFieldsFromCSV: ['area__time_zone__name'],
      visibility: DEFAULT_SHOW,
      accessor: (row) =>
        formatDatetimeForDisplay(
          row.created_datetime,
          row.area__time_zone__name
        ),
    },
    {
      label: <Trans>Status</Trans>,
      name: 'status',
      visibility: DEFAULT_SHOW,
      accessor: (row) => (
        <TransEnum enum="ReadingProcessingRequest_STATUS" value={row.status} />
      ),
    },
    {
      label: <Trans>User</Trans>,
      name: 'user__profile__name',
      additionalFields: ['user__username'],
      visibility: DEFAULT_SHOW,
      accessor: (row) => `${row.user__profile__name} (${row.user__username})`,
    },
    {
      label: <Trans>Area</Trans>,
      name: 'area__code',
      additionalFields: ['area__name'],
      visibility: DEFAULT_SHOW,
      accessor: (row) => `${row.area__code} - ${row.area__name}`,
    },
    {
      label: <Trans>Reprocess from date</Trans>,
      name: 'from_datetime',
      additionalFields: ['area__time_zone__name'],
      hideFieldsFromCSV: ['area__time_zone__name'],
      visibility: DEFAULT_SHOW,
      accessor: (row) =>
        formatDatetimeForDisplay(row.from_datetime, row.area__time_zone__name),
    },
    {
      label: <Trans>Reprocess to date</Trans>,
      name: 'to_datetime',
      additionalFields: ['area__time_zone__name'],
      hideFieldsFromCSV: ['area__time_zone__name'],
      visibility: DEFAULT_HIDE,
      accessor: (row) =>
        formatDatetimeForDisplay(row.to_datetime, row.area__time_zone__name),
    },
    {
      label: <Trans>Observation points</Trans>,
      name: 'observation_points__code',
      visibility: DEFAULT_SHOW,
      accessor: ({ observation_points__code: obsPointCodes }) =>
        obsPointCodes.join(', '),
    },
    {
      label: <Trans>Reason for change</Trans>,
      name: 'reason',
      visibility: DEFAULT_SHOW,
      accessor: (row) => (
        <TransEnum enum="ReadingProcessingRequest_REASON" value={row.reason} />
      ),
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['id', 'status'],
      accessor: ({ id, status }) =>
        extraProps && (
          <ReprocessingRequestActionsCell
            key={`reprocessing-request-actions-cell-${id}`}
            row={{ id, status }}
            hasCancelRequestPermission={extraProps.hasCancelRequestPermission}
            hasExecuteRequestPermission={extraProps.hasExecuteRequestPermission}
            onCancelRequest={extraProps.onCancelRequest}
            onConfirmRequest={extraProps.onConfirmRequest}
            refreshList={extraProps.refreshList}
          />
        ),
    },
  ];
}

export const reprocessingRequestsFilters: ReportFilter[] = [
  reportFilterMenu(
    'status',
    <Trans>Status</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('ReadingProcessingRequest_STATUS'),
    {
      placeholder: <Trans>All statuses</Trans>,
      options: Object.values(Enum.ReadingProcessingRequest_STATUS),
    }
  ),
  reportFilterAreas('area'),
  reportFilterUsers('user', <Trans>Requested by</Trans>),
];
