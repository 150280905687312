import React from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { SimpleSelectOption } from '../simpleselect/simpleselect';
import uniqueId from 'lodash/uniqueId';

export type RadioFieldOption<T = any> = SimpleSelectOption<T>;

/**
 * A Formik field for a set of radio buttons. It acts a lot like a SimpleSelectField:
 * it takes a list of options with `value` and `label`, and it returns the
 * `value` of the ticked radio button.
 */
export interface RadioFieldProps {
  name: string;
  disabled?: boolean;
  className?: string;
  options: ReadonlyArray<RadioFieldOption>;
}

const InnerRadioField: React.FunctionComponent<FieldProps & RadioFieldProps> = (
  props
) => {
  return (
    <div className={classNames('radio-group-wrapper', props.className)}>
      {props.options.map((opt, i) => (
        <SingleRadio {...props} idx={i} key={i} option={opt} />
      ))}
    </div>
  );
};

const SingleRadio: React.FunctionComponent<
  FieldProps &
    RadioFieldProps & {
      idx: number;
      option: RadioFieldOption;
    }
> = (props) => {
  const { field, form, option } = props;

  const handleChange = React.useCallback(
    () => form.setFieldValue(field.name, option.value),
    [field.name, form, option.value]
  );

  const fieldId = React.useMemo(
    () => `radio-${field.name}-${option.value}-${uniqueId()}`,
    [field.name, option.value]
  );

  return (
    <div className="radio-group">
      <Field
        type="radio"
        name={field.name}
        id={fieldId}
        checked={field.value === option.value}
        // NOTE: The "value" attribute here is not actually read back by our
        // code. Rather, the form value is set in the `onChange` callback.
        value={`radio-${field.name}-${option.value}`}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <label htmlFor={fieldId}>{option.label}</label>
    </div>
  );
};

export const RadioField = (props: RadioFieldProps) => (
  <Field component={InnerRadioField} {...props} />
);
