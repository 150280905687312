import React from 'react';
import {
  PlotType,
  StoredPlotAnnotationAxis,
  StoredPlotAnnotationType,
  StoredPlotAnnotation_LINE_STYLE,
} from 'util/backendapi/types/Enum';
import { FormikProps, FieldArray, Field, FieldArrayRenderProps } from 'formik';
import {
  FormCard,
  FormCardSection,
  CardSectionField,
  CardSectionRow,
} from 'components/base/card/card';
import { Trans } from '@lingui/macro';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import {
  YesNoToggleField,
  ToggleField,
  ShowHideToggleField,
} from 'components/base/form/toggle-field/ToggleField';
import Button from 'components/base/button/button';
import {
  StoredScatterPlotSeriesSection,
  StoredTimeSeriesPlotSeriesSection,
} from './StoredPlotSeriesSection';
import { getCustomScaleFieldsForAxis } from './get-custom-scale-fields';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import {
  StoredPlotItemFormValues,
  StoredScatterTimeSeriesFormValues,
  StoredPlotItemSerieFormValues,
  makeEmptyStoredPlotItem,
  ANNOTATION_LABEL_POSITION_OPTIONS,
  ANNOTATION_STUB_POSITION_OPTIONS,
  annotationType,
} from '../stored-plot-edit-utils';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { NumberField } from 'components/base/form/number-field/NumberField';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { StoredSurveyLevellingPlotFormValues } from '../StoredSurveyLevellingPlotForm';

interface StoredPlotItemSectionProps<
  T extends StoredScatterTimeSeriesFormValues
> {
  plot: StoredPlotItemFormValues;
  plotType: PlotType;
  plotIdx: number;
  formik: FormikProps<T>;
  timeZone: string | undefined;
}

/**
 * The form for a single plot (aka "item") in the full stored plot set
 * @param props
 */
export function StoredPlotItemSection<
  T extends StoredScatterTimeSeriesFormValues
>(props: StoredPlotItemSectionProps<T>) {
  const { plotIdx, plot, formik, plotType } = props;
  // All the fields for this item will be prefixed with this
  const itemFieldPrefix = `items[${plotIdx}]`;

  return (
    <FieldArray name={`${itemFieldPrefix}.reading_series`}>
      {(readingSeriesArrayHelpers) => (
        <FormCard
          name={`plot-${plotIdx + 1}`}
          header={<Trans>Plot {plotIdx + 1}</Trans>}
          subHeader={
            <DeleteStoredPlotItemButton
              {...props}
              itemFieldPrefix={itemFieldPrefix}
            />
          }
          className="stored-plot-plot-card"
          data-testid="stored-plot-item"
          footer={
            plotType === PlotType.SCATTER &&
            plot.reading_series.map((serie, seriesIdx) => (
              // A subcard for each Readings Series in the plot
              <StoredScatterPlotSeriesSection
                key={seriesIdx}
                plot={plot}
                serie={serie}
                axis={plot.axes[seriesIdx]}
                seriesFieldPrefix={`${itemFieldPrefix}.reading_series.${seriesIdx}`}
                axisFieldPrefix={`${itemFieldPrefix}.axes.${seriesIdx}`}
                seriesIdx={seriesIdx}
                formik={formik}
              />
            ))
          }
        >
          <FormCardSection
            name={`plot-${plotIdx + 1}-name`}
            header={<Trans>Name</Trans>}
            fields={[
              {
                name: `${itemFieldPrefix}.name`,
                label: <Trans>Title</Trans>,
                content: (
                  <>
                    <Field type="text" name={`${itemFieldPrefix}.name`} />
                    <FieldError name={`${itemFieldPrefix}.name`} />
                  </>
                ),
              },
              {
                name: `${itemFieldPrefix}.description`,
                label: <Trans>Description</Trans>,
                content: (
                  <>
                    <Field
                      type="text"
                      name={`${itemFieldPrefix}.description`}
                      component="textarea"
                    />
                    <FieldError name={`${itemFieldPrefix}.description`} />
                  </>
                ),
              },
            ]}
          />

          {/*
           * For stored TimeSeries plot, the custom scales for left and right Y_Axis is render
           * in the plot item level.
           * While in stored Scatter plot, the custom scales form controls are render within the series section.
           */}
          {props.plotType === PlotType.TIME_SERIES ? (
            <>
              <FormCardSection
                name={`plot-${plotIdx + 1}-y-axis-scale`}
                header={<Trans>Y axis scale</Trans>}
                fields={plot.axes.flatMap((yAxis, yAxisIdx) => {
                  const yAxisFieldPrefix = `${itemFieldPrefix}.axes[${yAxisIdx}]`;
                  return getCustomScaleFieldsForAxis(
                    yAxis,
                    yAxisFieldPrefix,
                    yAxis.side === 'left' ? (
                      <Trans>Left axis</Trans>
                    ) : yAxis.side === 'right' ? (
                      <Trans>Right axis 1</Trans>
                    ) : (
                      <Trans>Right axis 2</Trans>
                    )
                  );
                })}
              />
              <AnnotationsSubsection
                plotIdx={plotIdx}
                plotType={plotType}
                plot={plot}
                itemFieldPrefix={itemFieldPrefix}
              />
              {/* Only display the "alarm parameters" option when there is exactly
                  one observation point in the plot. */}
              {plot.reading_series.length <= 1 && (
                <FormCardSection
                  name={`plot-${plotIdx + 1}-alarm-parameters`}
                  header={<Trans>Alarm parameters</Trans>}
                  fields={[
                    {
                      name: 'show_alarm_parameters',
                      label: <Trans>Alarm parameters</Trans>,
                      content: (
                        <>
                          <ShowHideToggleField
                            name={`${itemFieldPrefix}.reading_series.0.show_alarm_parameters`}
                          />
                          <FieldError
                            name={`${itemFieldPrefix}.reading_series.0.show_alarm_parameters`}
                          />
                        </>
                      ),
                    },
                  ]}
                />
              )}
              {/* effect to clear the value when an axis switches from Custom --> Auto */}
              <FormChangeEffect<StoredScatterTimeSeriesFormValues>
                onChange={({ values: prevValues }) => {
                  // TODO: copy-pasted from `QuickPlotSettingsView`
                  const prevPlot = prevValues.items[plotIdx];
                  if (
                    prevPlot &&
                    plot.axes.length &&
                    prevPlot.axes.length === plot.axes.length
                  ) {
                    plot.axes.forEach((yAxis, yAxisIdx) => {
                      if (
                        yAxis.side === prevPlot.axes[yAxisIdx].side &&
                        yAxis.mode === 'auto' &&
                        prevPlot.axes[yAxisIdx].mode === 'custom'
                      ) {
                        const yAxisFieldPrefix = `${itemFieldPrefix}.axes[${yAxisIdx}]`;
                        formik.setFieldValue(`${yAxisFieldPrefix}.minimum`, '');
                        formik.setFieldTouched(
                          `${yAxisFieldPrefix}.minimum`,
                          false
                        );
                        formik.setFieldValue(`${yAxisFieldPrefix}.maximum`, '');
                        formik.setFieldTouched(
                          `${yAxisFieldPrefix}.maximum`,
                          false
                        );
                      }
                    });
                  }

                  // Also disable "show alarm parameters" when a second series is added
                  if (
                    prevPlot?.reading_series.length === 1 &&
                    plot?.reading_series.length > 1
                  ) {
                    formik.setFieldValue(
                      `${itemFieldPrefix}.reading_series.0.show_alarm_parameters`,
                      false
                    );
                    formik.setFieldTouched(
                      `${itemFieldPrefix}.reading_series.0.show_alarm_parameters`,
                      false
                    );
                  }
                }}
              />
            </>
          ) : null}

          {props.plotType === PlotType.SCATTER ? (
            <FieldArray name={`${itemFieldPrefix}.highlight_periods`}>
              {(highlightPeriodsArrayHelpers) => (
                <>
                  <FormCardSection
                    name={itemFieldPrefix}
                    header={<Trans>Display options</Trans>}
                    fields={[
                      {
                        name: `${itemFieldPrefix}.interpolate`,
                        label: <Trans>Interpolate</Trans>,
                        content: (
                          <>
                            <YesNoToggleField
                              name={`${itemFieldPrefix}.interpolate`}
                            />
                            <FieldError
                              name={`${itemFieldPrefix}.interpolate`}
                            />
                          </>
                        ),
                      },
                      {
                        name: `${itemFieldPrefix}.show_plot_markers`,
                        label: <Trans>Show plot markers</Trans>,
                        content: (
                          <>
                            <YesNoToggleField
                              name={`${itemFieldPrefix}.show_plot_markers`}
                            />
                            <FieldError
                              name={`${itemFieldPrefix}.show_plot_markers`}
                            />
                          </>
                        ),
                      },
                      {
                        name: `${itemFieldPrefix}.show_mark_connections`,
                        label: <Trans>Show mark connections</Trans>,
                        content: (
                          <>
                            <YesNoToggleField
                              name={`${itemFieldPrefix}.show_mark_connections`}
                            />
                            <FieldError
                              name={`${itemFieldPrefix}.show_mark_connections`}
                            />
                          </>
                        ),
                      },
                      {
                        name: `${itemFieldPrefix}.show_highlight_periods`,
                        label: <Trans>Colour time periods</Trans>,
                        content: (
                          <>
                            <YesNoToggleField
                              name={`${itemFieldPrefix}.show_highlight_periods`}
                            />
                            <FieldError
                              name={`${itemFieldPrefix}.show_highlight_periods`}
                            />
                            <HighlightPeriodsChangeEffect
                              {...props}
                              itemFieldPrefix={itemFieldPrefix}
                              arrayHelpers={highlightPeriodsArrayHelpers}
                            />
                          </>
                        ),
                      },
                      ...makeHighlightPeriodsSubsection({
                        ...props,
                        itemFieldPrefix,
                        arrayHelpers: highlightPeriodsArrayHelpers,
                      }),
                    ]}
                  />
                  <AnnotationsSubsection
                    plotIdx={plotIdx}
                    plotType={plotType}
                    plot={plot}
                    itemFieldPrefix={itemFieldPrefix}
                  />
                </>
              )}
            </FieldArray>
          ) : null}
          {plotType === PlotType.TIME_SERIES && (
            <FormCardSection
              name={`stored-plot-time-series-obs-points-${plotIdx}`}
              className="nested"
              header={
                <>
                  <Trans>Observation point display options</Trans>
                  <Button
                    className="annotation-delete-button"
                    iconType="icon-plus"
                    onClick={() => {
                      // Formik `arrayHelpers`'s methods are not typed at all,
                      // so explicitly typing the new item I set up.
                      const newSeries: StoredPlotItemSerieFormValues =
                        makeEmptyStoredPlotItem(plotType).reading_series[0];
                      readingSeriesArrayHelpers.push(newSeries);
                    }}
                  >
                    <Trans>Add observation point</Trans>
                  </Button>
                </>
              }
              fields={[
                {
                  name: 'obs-points-table',
                  className: 'table-responsive',
                  content: (
                    <DragDropContext
                      onDragEnd={(result: DropResult) => {
                        if (
                          result.destination &&
                          result.source.index !== result.destination.index
                        ) {
                          readingSeriesArrayHelpers.move(
                            result.source.index,
                            result.destination.index
                          );
                        }
                      }}
                    >
                      <table className="stored-plot-series-table">
                        <thead>
                          <tr>
                            <th scope="col">
                              <Trans>Observation point</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Y axis</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Plot markers</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Port RL</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Analysis comments</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Inspector comments</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Associated media</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Cap RL legend</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Port RL legend</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Confidence level</Trans>
                            </th>
                            <th scope="col" className="action-icons">
                              <Trans>Actions</Trans>
                            </th>
                          </tr>
                        </thead>
                        <Droppable droppableId={`storedplot-series-${plotIdx}`}>
                          {(provided) => (
                            <tbody
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {plot.reading_series.map((serie, seriesIdx) => (
                                // A table of the obs points in the plot
                                <StoredTimeSeriesPlotSeriesSection
                                  key={serie.randomKey}
                                  plot={plot}
                                  serie={serie}
                                  seriesFieldPrefix={`${itemFieldPrefix}.reading_series.${seriesIdx}`}
                                  seriesIdx={seriesIdx}
                                  formik={formik}
                                  readingSeriesArrayHelpers={
                                    readingSeriesArrayHelpers
                                  }
                                />
                              ))}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  ),
                },
              ]}
            ></FormCardSection>
          )}
        </FormCard>
      )}
    </FieldArray>
  );
}

/**
 * A FormChangeEffect to update the form in response to highlighted time periods
 * being enabled and disabled.
 */
function HighlightPeriodsChangeEffect<
  T extends StoredScatterTimeSeriesFormValues
>({
  plotIdx,
  plot,
  formik,
  itemFieldPrefix,
  arrayHelpers,
}: StoredPlotItemSectionProps<T> & {
  arrayHelpers: FieldArrayRenderProps;
  itemFieldPrefix: string;
}) {
  return (
    <FormChangeEffect<StoredScatterTimeSeriesFormValues>
      // Event handler to update the from when highlight periods are enabled
      // or disabled.
      onChange={({ values: prevValues }) => {
        const prevPlot = prevValues.items[plotIdx];

        if (prevPlot.show_highlight_periods === plot.show_highlight_periods) {
          return;
        }

        // turn off highlight periods
        if (prevPlot.show_highlight_periods && !plot.show_highlight_periods) {
          formik.setFieldValue(`${itemFieldPrefix}.highlight_periods`, []);
          return;
        }

        // turn on highlight periods
        if (!prevPlot.show_highlight_periods && plot.show_highlight_periods) {
          arrayHelpers.push({
            start_datetime: '',
            end_datetime: '',
          });
        }
      }}
    />
  );
}

/**
 * Generates the array of card section rows, one for each highlighted time period.
 * And also, the "Add time period" button.
 */
function makeHighlightPeriodsSubsection<
  T extends StoredScatterTimeSeriesFormValues
>({
  plot,
  arrayHelpers,
  itemFieldPrefix,
  timeZone,
}: StoredPlotItemSectionProps<T> & {
  arrayHelpers: FieldArrayRenderProps;
  itemFieldPrefix: string;
}): CardSectionField[] {
  if (!plot.show_highlight_periods) {
    return [];
  }

  const addPeriodText: CardSectionField = {
    name: `${itemFieldPrefix}.period-text`,
    className: 'card-row-text-offset',
    content: (
      <p>
        <Trans>
          Readings outside of the periods specified below will be displayed in
          the default colour
        </Trans>
      </p>
    ),
  };

  // The button to add another time period.
  // (and the FormChangeEffect to take care of resetting )
  const addPeriodButton: CardSectionField = {
    name: `${itemFieldPrefix}.add-highlight-period`,
    content: (
      <Button
        data-testid="colour-time-period-add-button"
        iconType="icon-plus"
        onClick={() =>
          arrayHelpers.push({
            start_datetime: '',
            end_datetime: '',
          })
        }
      >
        <Trans>Add time period</Trans>
      </Button>
    ),
  };

  // Make one nested FormCardSection for each highlight period.
  const highlightPeriods: CardSectionField[] = plot.highlight_periods.map(
    (_, idx) => {
      const periodPrefix = `${itemFieldPrefix}.highlight_periods.${idx}`;
      return {
        name: periodPrefix,
        content: (
          <FormCardSection
            header={<Trans>Time period {idx + 1}</Trans>}
            name={`${periodPrefix}.subsection`}
            fields={[
              {
                name: `${periodPrefix}.start_datetime`,
                label: <Trans>Start</Trans>,
                content: (
                  <>
                    <DatetimeField
                      timeZone={timeZone}
                      id={`${periodPrefix}.start_datetime`}
                      name={`${periodPrefix}.start_datetime`}
                    />
                    <FieldError name={`${periodPrefix}.start_datetime`} />
                  </>
                ),
              },
              {
                name: `${periodPrefix}.end_datetime`,
                label: <Trans>End</Trans>,
                content: (
                  <>
                    <DatetimeField
                      timeZone={timeZone}
                      id={`${periodPrefix}.end_datetime`}
                      name={`${periodPrefix}.end_datetime`}
                    />
                    <FieldError name={`${periodPrefix}.end_datetime`} />
                  </>
                ),
              },
            ]}
          />
        ),
      };
    }
  );
  highlightPeriods.push(addPeriodButton);
  highlightPeriods.unshift(addPeriodText);
  return highlightPeriods;
}

export function AnnotationsSubsection(props: {
  plot: StoredPlotItemFormValues | StoredSurveyLevellingPlotFormValues;
  plotIdx: number;
  plotType: PlotType;
  itemFieldPrefix?: string;
}) {
  const { itemFieldPrefix, plotIdx, plot } = props;

  const annotationField = itemFieldPrefix
    ? `${itemFieldPrefix}.annotations`
    : 'annotations';
  const annotationsTextName = itemFieldPrefix
    ? `${itemFieldPrefix}.annotations-text`
    : 'annotations-text';
  const addAnnotationName = itemFieldPrefix
    ? `${itemFieldPrefix}.add-annotation`
    : 'add-annotation';

  const annotationAxisOptions: SimpleSelectOption<Enum.StoredPlotAnnotationAxis>[] =
    React.useMemo(
      () =>
        menuItemsFromEnum(
          'StoredPlotAnnotationAxis',
          Object.values(StoredPlotAnnotationAxis)
        ),
      []
    );

  const lineStyleOptions = [
    {
      value: Enum.StoredPlotAnnotation_LINE_STYLE.FULL,
      label: <Trans>Full</Trans>,
    },
    {
      value: Enum.StoredPlotAnnotation_LINE_STYLE.STUB,
      label: <Trans>Stub</Trans>,
    },
    { value: null, label: <Trans>None</Trans> },
  ];

  return (
    <FieldArray name={annotationField}>
      {(annotationArrayHelpers) => {
        const fields: CardSectionRow[] = plot.annotations.map((annot, idx) => {
          const type = annotationType(props.plotType, annot);

          const annotPrefix = `${annotationField}.${idx}`;

          const axisName = `${annotPrefix}.axis`;
          const labelName = `${annotPrefix}.label`;
          const positionName = `${annotPrefix}.position`;
          const lineStyleName = `${annotPrefix}.line_style`;
          const stubPositionName = `${annotPrefix}.stub_position`;

          const hasNumericValue =
            type === StoredPlotAnnotationType.NUMERIC ||
            type === StoredPlotAnnotationType.SURVEY;
          const hasNumericRange =
            type === StoredPlotAnnotationType.DATETIME ||
            type === StoredPlotAnnotationType.SURVEY;

          const valueName = hasNumericValue
            ? `${annotPrefix}.numeric_value`
            : `${annotPrefix}.datetime_value`;
          const startName = hasNumericRange
            ? `${annotPrefix}.start_numeric`
            : `${annotPrefix}.start_datetime`;
          const endName = hasNumericRange
            ? `${annotPrefix}.end_numeric`
            : `${annotPrefix}.end_datetime`;

          const deleteButton = (
            <ButtonShowConfirmation
              name={`plot-${plotIdx + 1}-annotation-${idx + 1}-delete`}
              className="annotation-delete-button"
              onConfirm={async () => {
                annotationArrayHelpers.remove(idx);
              }}
              content={
                <Trans>
                  Are you sure you want to delete{' '}
                  <strong>Annotation {idx + 1}</strong> from this stored plot?
                  This action is not reversible.
                </Trans>
              }
              okBtnText={<Trans>Yes, delete</Trans>}
            >
              <Trans>Delete</Trans>
            </ButtonShowConfirmation>
          );
          return {
            name: annotPrefix,
            content: (
              <FormCardSection
                name={`${annotPrefix}.subsection`}
                header={
                  <>
                    <Trans>Annotation {idx + 1}</Trans> {deleteButton}
                  </>
                }
                fields={[
                  {
                    name: axisName,
                    label: <Trans>Axis</Trans>,
                    content: (
                      <>
                        <SimpleSelectField
                          name={axisName}
                          options={annotationAxisOptions}
                        />
                        <FieldError name={axisName} />
                      </>
                    ),
                  },
                  {
                    name: valueName,
                    label: <Trans>Value</Trans>,
                    content: (
                      <>
                        {!hasNumericValue ? (
                          <DatetimeField name={valueName} />
                        ) : (
                          <NumberField name={valueName} />
                        )}

                        <FieldError name={valueName} />
                      </>
                    ),
                  },
                  {
                    name: labelName,
                    label: <Trans>Label</Trans>,
                    content: (
                      <>
                        <Field name={labelName} type="text" maxLength={500} />
                        <FieldError name={labelName} />
                      </>
                    ),
                  },
                  {
                    name: positionName,
                    label: <Trans>Label position</Trans>,
                    content: (
                      <>
                        <ToggleField
                          name={positionName}
                          options={
                            ANNOTATION_LABEL_POSITION_OPTIONS[annot.axis]
                          }
                        />
                        <FieldError name={positionName} />
                      </>
                    ),
                  },

                  // Only show line style settings for these plot types
                  ...(props.plotType === Enum.PlotType.TIME_SERIES ||
                  props.plotType === Enum.PlotType.SURVEY_LEVELLING
                    ? [
                        {
                          name: lineStyleName,
                          label: <Trans>Line style</Trans>,
                          content: (
                            <>
                              <ToggleField
                                name={lineStyleName}
                                options={lineStyleOptions}
                              />
                              <FieldError name={lineStyleName} />
                            </>
                          ),
                        },
                      ]
                    : []),

                  // stub position and/or line start/end
                  ...(props.plotType === Enum.PlotType.TIME_SERIES ||
                  props.plotType === Enum.PlotType.SURVEY_LEVELLING
                    ? annot.line_style ===
                      Enum.StoredPlotAnnotation_LINE_STYLE.STUB
                      ? [
                          {
                            name: stubPositionName,
                            label: <Trans>Stub position</Trans>,
                            content: (
                              <>
                                <ToggleField
                                  name={stubPositionName}
                                  options={
                                    ANNOTATION_STUB_POSITION_OPTIONS[annot.axis]
                                  }
                                />
                                <FieldError name={stubPositionName} />
                              </>
                            ),
                          },
                        ]
                      : [
                          {
                            name: startName,
                            label: <Trans>Annotation start</Trans>,
                            content: (
                              <>
                                {hasNumericRange ? (
                                  <NumberField name={startName} />
                                ) : (
                                  <DatetimeField name={startName} />
                                )}
                                <FieldError name={startName} />
                              </>
                            ),
                          },
                          {
                            name: endName,
                            label: <Trans>Annotation end</Trans>,
                            content: (
                              <>
                                {hasNumericRange ? (
                                  <NumberField name={endName} />
                                ) : (
                                  <DatetimeField name={endName} />
                                )}
                                <FieldError name={endName} />
                              </>
                            ),
                          },
                        ]
                    : []),
                ]}
              />
            ),
          };
        });

        // If the plot has no annotations, show this message instead.
        if (fields.length === 0) {
          fields.push({
            name: annotationsTextName,
            content: (
              <p className="non-editable-value">
                <Trans>There are no annotations for this plot.</Trans>
              </p>
            ),
          });
        }

        // Show the "Add annotation" button at the bottom.
        fields.push({
          name: addAnnotationName,
          content: (
            <Button
              data-testid="add-annotation-button"
              iconType="icon-plus"
              onClick={() => {
                annotationArrayHelpers.push({
                  axis: StoredPlotAnnotationAxis.Y,
                  // Provide a default empty value for both value types.
                  // (This will avoid problems caused by having a value of
                  // `undefined` if the user switches the annotation's axis)
                  datetime_value: '',
                  numeric_value: '',
                  start_datetime: '',
                  end_datetime: '',
                  start_numeric: '',
                  end_numeric: '',
                  label: '',
                  position: ANNOTATION_LABEL_POSITION_OPTIONS['Y'][0].value,
                  line_style: StoredPlotAnnotation_LINE_STYLE.FULL,
                  stub_position: ANNOTATION_STUB_POSITION_OPTIONS['Y'][0].value,
                });
              }}
            >
              <Trans>Add annotation</Trans>
            </Button>
          ),
        });

        return (
          <FormCardSection
            name={`plot-${plotIdx + 1}-annotations`}
            header={<Trans>Annotations</Trans>}
            fields={fields}
          />
        );
      }}
    </FieldArray>
  );
}

function DeleteStoredPlotItemButton<
  T extends StoredScatterTimeSeriesFormValues
>(
  props: Merge<
    StoredPlotItemSectionProps<T>,
    {
      itemFieldPrefix: string;
    }
  >
) {
  const { itemFieldPrefix, plotType, plotIdx, formik } = props;
  return (
    <ActionBlock>
      <ButtonShowConfirmation
        name={`plot-${plotIdx + 1}-delete`}
        onConfirm={async () => {
          formik.setFieldValue(
            itemFieldPrefix,
            makeEmptyStoredPlotItem(plotType)
          );
        }}
        content={
          <Trans>
            Are you sure you want to delete <strong>Plot {plotIdx + 1}</strong>{' '}
            from this stored plot? This action is not reversible.
          </Trans>
        }
        okBtnText={<Trans>Yes, delete</Trans>}
      >
        <Trans>Delete</Trans>
      </ButtonShowConfirmation>
    </ActionBlock>
  );
}
