import React, { useEffect, useRef, useMemo } from 'react';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button, { ButtonPrimary } from 'components/base/button/button';
import {
  Field,
  ErrorMessage,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from 'formik';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import { AlarmParametersState } from 'ducks/alarmparameters';
import Loading from 'components/base/loading/loading';
import ModalContent from 'components/base/modal/modalcontent';
import {
  isCurrentAlarm as isCurrentAlarmCheck,
  isFutureAlarm as isFutureAlarmCheck,
} from 'util/alarmparameters';
import { formatDatetimeForDisplay, getCurrentDatetime } from 'util/dates';
import { FormItem } from 'components/base/form/FormItem';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { AlertInfo } from 'components/base/alert/alert';
import { IntervalField } from 'components/base/form/interval/intervalfield';
import {
  alarmComparisonTypeOptions,
  alarmLevelOptionsSelector,
  alarmTypeOptions,
} from './alarm-parameter-form-selectors';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import { validateNumber } from 'util/validation';
import {
  ObsPointItemMenu,
  ObsPointItem,
  getObsPointItemIdent,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { ToggleField } from 'components/base/form/toggle-field/ToggleField';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import moment from 'moment-timezone';
import { useGetApi } from 'hooks/use-get-api';

export type AlarmParameterFormValues = Omit<
  Model.AlarmParameter,
  'id' | 'last_changed'
> & {
  relativeObsPoint: null | ObsPointItem[];
  relative_observation_point_menu: string;
};

export type AlarmParameterFormProps = AlarmParametersState['editForm'] & {
  alarmParamId: null | number;
  observationPointId: number;
  itemNumber: number;
  timeZone: string;
  onUpdate: (id: number, values: Model.AlarmParameter_PATCH) => void;
  onCreate: (values: Model.AlarmParameter_POST) => void;
  onCancel: () => void;
  onUnmount: () => void;
};

const validate =
  (_isCurrentAlarm: boolean, isFutureAlarm: boolean, isNewAlarm: boolean) =>
  (values: AlarmParameterFormValues) => {
    let errors: FormikErrors<AlarmParameterFormValues> = {};

    const now = getCurrentDatetime();

    if (isNewAlarm) {
      if (!values.comparison_type) {
        errors.comparison_type = (
          <Trans>Comparision type is required.</Trans>
        ) as any;
      }

      if (!values.level) {
        errors.level = (<Trans>Alarm level is required.</Trans>) as any;
      }

      if (!values.type) {
        errors.type = (<Trans>Alarm type is required.</Trans>) as any;
      }
    }

    if (isFutureAlarm || isNewAlarm) {
      if (!values.threshold || !validateNumber(values.threshold)) {
        errors.threshold = (<Trans>A valid number is required.</Trans>) as any;
      }

      if (!values.start_datetime) {
        errors.start_datetime = (
          <Trans>Start datetime is required.</Trans>
        ) as any;
      } else if (now > values.start_datetime) {
        errors.start_datetime = (
          <Trans>Start datetime must be in the future.</Trans>
        ) as any;
      }

      if (
        values.end_datetime &&
        values.start_datetime &&
        values.start_datetime >= values.end_datetime
      ) {
        errors.end_datetime = (
          <Trans>End datetime must be later than Start datetime.</Trans>
        ) as any;
      }
    }

    if (values.end_datetime && now > values.end_datetime) {
      errors.end_datetime = (
        <Trans>End datetime must be in the future.</Trans>
      ) as any;
    }

    if (
      values.comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.relative
    ) {
      if (!values.relative_observation_point_menu) {
        errors.relative_observation_point_menu = (
          <Trans>Comparison observation point is required.</Trans>
        ) as any;
      }

      if (
        !values.relative_comparison_factor ||
        !validateNumber(values.relative_comparison_factor)
      ) {
        errors.relative_comparison_factor = (
          <Trans>A valid number is required.</Trans>
        ) as any;
      }
    }

    if (
      values.comparison_type === Enum.AlarmParameter_COMPARISON_TYPE.velocity
    ) {
      if (!values.direction_of_change) {
        errors.direction_of_change = (
          <Trans>Direction of change is required.</Trans>
        ) as any;
      }
      if (values.time_interval === null) {
        errors.time_interval = (
          <Trans>Time interval is required.</Trans>
        ) as any;
      } else if (
        moment.duration(values.time_interval).as('milliseconds') <= 0
      ) {
        errors.time_interval = (
          <Trans>Time interval must be greater than 0.</Trans>
        ) as any;
      }
    }
    return errors;
  };

export function AlarmParameterFormView(props: AlarmParameterFormProps) {
  const {
    isFetching,
    errorMessage,
    alarmParameter,
    alarmParamId,
    timeZone,
    observationPointId,
    itemNumber,
    onCreate,
    onUpdate,
    onUnmount,
    onCancel,
  } = props;
  const isMountedRef = useRef<boolean>(true);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
      onUnmount();
    };
  }, [onUnmount]);

  const messages: React.ReactNode[] = [];

  const isNewAlarm = !alarmParamId;
  const header = isNewAlarm ? (
    <Trans>New alarm parameter</Trans>
  ) : (
    <Trans>Edit alarm parameter</Trans>
  );

  const initialValues: AlarmParameterFormValues = useMemo(() => {
    if (alarmParameter) {
      return {
        ...alarmParameter,
        end_datetime: alarmParameter.end_datetime || null,
        relativeObsPoint: null,
        relative_comparison_factor:
          alarmParameter.relative_comparison_factor || '',
        relative_observation_point_menu:
          alarmParameter.relative_observation_point &&
          alarmParameter.relative_item_number
            ? getObsPointItemIdent(
                alarmParameter.relative_observation_point,
                alarmParameter.relative_item_number
              )
            : '',
      };
    } else {
      return {
        level: Enum.AlarmParameter_LEVEL.data_check,
        type: Enum.AlarmParameter_TYPE.maximum,
        comparison_type: Enum.AlarmParameter_COMPARISON_TYPE.absolute,
        start_datetime: getCurrentDatetime(),
        status: Enum.AlarmParameter_STATUS.enabled,
        observation_point: observationPointId,
        item_number: itemNumber,
        relative_item_number: null,
        relative_observation_point: null,
        relative_comparison_datetime_tolerance: null,
        relative_comparison_factor: '',
        threshold: '',
        end_datetime: null,
        relativeObsPoint: null,
        relative_observation_point_menu: '',
        item_description: '',
        direction_of_change: null,
        time_interval: null,
      };
    }
  }, [alarmParameter, itemNumber, observationPointId]);

  if (isFetching) {
    return (
      <ModalContent header={header}>
        <Loading />
      </ModalContent>
    );
  }

  if (errorMessage) {
    return (
      <ModalContent header={header}>
        <ErrorNotice>{errorMessage}</ErrorNotice>
      </ModalContent>
    );
  }

  if (!isNewAlarm && !alarmParameter) {
    return (
      <ModalContent header={header}>
        <ErrorNotice>
          <Trans>Alarm parameter not found.</Trans>
        </ErrorNotice>
      </ModalContent>
    );
  }

  const isCurrentAlarm = alarmParameter
    ? isCurrentAlarmCheck({
        start_datetime: alarmParameter.start_datetime!,
        end_datetime: alarmParameter.end_datetime || null,
      })
    : false;
  const isFutureAlarm = alarmParameter
    ? isFutureAlarmCheck({
        start_datetime: alarmParameter.start_datetime!,
        end_datetime: alarmParameter.end_datetime || null,
      })
    : false;
  const isAbsoluteAlarm = alarmParameter
    ? alarmParameter.comparison_type ===
      Enum.AlarmParameter_COMPARISON_TYPE.absolute
    : false;

  if (!isNewAlarm && alarmParameter) {
    if (isCurrentAlarm) {
      messages.push(
        <>
          <p>
            <Trans>
              Only the End date and time can be changed when the alarm parameter
              is active.
            </Trans>
          </p>
          <p>
            <Trans>To update an alarm parameter, please add a new one.</Trans>
          </p>
        </>
      );
    }

    if (isAbsoluteAlarm) {
      if (alarmParameter.level === Enum.AlarmParameter_LEVEL.data_check) {
        messages.push(
          <AlertInfo>
            <Trans>
              If you end the Data check parameter when there are active Alert or
              Design check alarm parameters, these will be ended too.
            </Trans>
          </AlertInfo>
        );
      }

      if (alarmParameter.level === Enum.AlarmParameter_LEVEL.design_check) {
        messages.push(
          <AlertInfo>
            <Trans>
              If you end the Design check parameter when there is an active
              Alert alarm parameter, this will be ended too.
            </Trans>
          </AlertInfo>
        );
      }
    }
  }

  return (
    <ModalContent header={header}>
      {messages.map((msg, idx) => (
        <div key={idx}>{msg}</div>
      ))}
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          values: AlarmParameterFormValues,
          formik: FormikHelpers<AlarmParameterFormValues>
        ) => {
          formik.setStatus(undefined);
          try {
            let valuesToSubmit: Model.AlarmParameter_POST;
            const {
              relative_observation_point_menu,
              relativeObsPoint,
              ...rest
            } = values;
            valuesToSubmit = rest;

            if (!valuesToSubmit.end_datetime) {
              valuesToSubmit.end_datetime = null;
            }

            // If editing existing alarm that is current, only send end_datetime as only it can be changed
            if (!isNewAlarm && isCurrentAlarm) {
              await onUpdate(alarmParamId!, {
                end_datetime: valuesToSubmit.end_datetime,
              });
              return;
            }

            const [obsPointId, itemNumber] =
              relative_observation_point_menu.split('_');
            valuesToSubmit.relative_observation_point = Number(obsPointId);
            valuesToSubmit.relative_item_number = Number(itemNumber);

            if (
              valuesToSubmit.comparison_type !==
              Enum.AlarmParameter_COMPARISON_TYPE.relative
            ) {
              delete valuesToSubmit.relative_item_number;
              delete valuesToSubmit.relative_comparison_factor;
              delete valuesToSubmit.relative_observation_point;
              delete valuesToSubmit.relative_comparison_datetime_tolerance;
            }

            if (
              valuesToSubmit.comparison_type !==
              Enum.AlarmParameter_COMPARISON_TYPE.velocity
            ) {
              delete valuesToSubmit.time_interval;
              delete valuesToSubmit.direction_of_change;
            }

            if (isNewAlarm) {
              await onCreate(valuesToSubmit);
            } else {
              await onUpdate(alarmParamId!, valuesToSubmit);
            }
          } catch (error) {
            if (!isMountedRef.current) {
              return;
            }

            formik.setSubmitting(false);
            showErrorsInFormik(formik, error, [
              'threshold',
              'start_datetime',
              'end_datetime',
              'relative_observation_point',
              'relative_item_number',
              'relative_comparison_factor',
              'relative_comparison_datetime_tolerance',
            ]);
          }
        }}
        validate={validate(isCurrentAlarm, isFutureAlarm, isNewAlarm)}
      >
        {(formikBag) => (
          <Form>
            <fieldset>
              {isCurrentAlarm || isFutureAlarm ? (
                <legend>
                  {alarmParameter && (
                    <>
                      <TransEnum
                        enum="AlarmParameter_LEVEL"
                        value={alarmParameter.level}
                      />
                      <span> - </span>
                      <TransEnum
                        enum="AlarmParameter_TYPE"
                        value={alarmParameter.type}
                      />
                    </>
                  )}
                </legend>
              ) : null}

              {isNewAlarm && (
                <>
                  <FormItem
                    fieldId="alarmparameterform-comparison_type"
                    label={<Trans>Comparison type</Trans>}
                  >
                    <SimpleSelectField
                      name="comparison_type"
                      id="alarmparameterform-comparison_type"
                      options={alarmComparisonTypeOptions}
                      isSearchable={false}
                    />
                    <FormChangeEffect
                      onChange={() => {
                        // Only absolute value alarms can have a "data check".
                        if (
                          formikBag.values.comparison_type !==
                            Enum.AlarmParameter_COMPARISON_TYPE.absolute &&
                          formikBag.values.level ===
                            Enum.AlarmParameter_LEVEL.data_check
                        ) {
                          formikBag.setFieldValue(
                            'level',
                            Enum.AlarmParameter_LEVEL.alert
                          );
                        }
                      }}
                    />
                    <ErrorMessage
                      name="comparison_type"
                      component={ErrorNotice}
                    />
                  </FormItem>
                  <FormItem
                    fieldId="alarmparameterform-level"
                    label={<Trans>Alarm level</Trans>}
                  >
                    <SimpleSelectField
                      name="level"
                      id="alarmparameterform-level"
                      options={alarmLevelOptionsSelector(
                        formikBag.values.comparison_type
                      )}
                      isSearchable={false}
                    />
                    <ErrorMessage name="level" component={ErrorNotice} />
                  </FormItem>
                  <FormItem
                    fieldId="alarmparameterform-type"
                    label={<Trans>Maximum/minimum</Trans>}
                  >
                    <SimpleSelectField
                      name="type"
                      id="alarmparameterform-type"
                      options={alarmTypeOptions}
                      isSearchable={false}
                    />
                    <ErrorMessage name="type" component={ErrorNotice} />
                  </FormItem>
                </>
              )}

              {formikBag.values.comparison_type ===
                Enum.AlarmParameter_COMPARISON_TYPE.velocity && (
                <FormItem
                  fieldId="alarm-parameter-time-interval"
                  label={<Trans>Time interval</Trans>}
                >
                  {isCurrentAlarm ? (
                    <span>
                      <IntervalDisplay value={alarmParameter?.time_interval} />
                    </span>
                  ) : (
                    <IntervalField
                      name="time_interval"
                      id="alarm-parameter-time-interval"
                    />
                  )}
                  <FieldError name="time_interval" />
                </FormItem>
              )}

              <FormItem
                fieldId="alarmparameterform-threshold"
                label={
                  formikBag.values.comparison_type ===
                  Enum.AlarmParameter_COMPARISON_TYPE.velocity ? (
                    <Trans>Change in reading (threshold)</Trans>
                  ) : (
                    <Trans>Alarm parameter</Trans>
                  )
                }
              >
                {isCurrentAlarm ? (
                  <span>{alarmParameter?.threshold}</span>
                ) : (
                  <>
                    <Field name="threshold" id="alarmparameterform-threshold" />
                  </>
                )}
                <ErrorMessage name="threshold" component={ErrorNotice} />
              </FormItem>

              {formikBag.values.comparison_type ===
                Enum.AlarmParameter_COMPARISON_TYPE.velocity && (
                <FormItem
                  fieldId="alarmparameter-direction-of-change"
                  label={<Trans>Direction of change</Trans>}
                >
                  {isCurrentAlarm ? (
                    <span>
                      {alarmParameter?.direction_of_change && (
                        <TransEnum
                          enum="AlarmParameter_DIRECTION_OF_CHANGE"
                          value={alarmParameter?.direction_of_change}
                        />
                      )}
                    </span>
                  ) : (
                    <ToggleField
                      id="alarmparameter-direction-of-change"
                      name="direction_of_change"
                      options={menuItemsFromEnum(
                        'AlarmParameter_DIRECTION_OF_CHANGE'
                      )}
                    />
                  )}
                  <FieldError name="direction_of_change" />
                </FormItem>
              )}

              <FormItem
                fieldId="alarmparameterform-start_datetime"
                label={<Trans>Start</Trans>}
              >
                {isCurrentAlarm ? (
                  <span>
                    {alarmParameter &&
                      formatDatetimeForDisplay(
                        alarmParameter.start_datetime,
                        timeZone
                      )}
                  </span>
                ) : (
                  <>
                    <DatetimeField
                      name="start_datetime"
                      id="alarmparameterform-start_datetime"
                      timeZone={timeZone}
                    />
                  </>
                )}
                <ErrorMessage name="start_datetime" component={ErrorNotice} />
              </FormItem>

              <FormItem
                fieldId="alarmparameterform-end_datetime"
                label={<Trans>End</Trans>}
              >
                <DatetimeField
                  name="end_datetime"
                  id="alarmparameterform-end_datetime"
                  timeZone={timeZone}
                  defaultValues={{ hour: '23', minute: '59' }}
                />
                <ErrorMessage name="end_datetime" component={ErrorNotice} />
              </FormItem>
            </fieldset>

            {formikBag.values.comparison_type ===
            Enum.AlarmParameter_COMPARISON_TYPE.relative ? (
              <RelativeAlarmSection
                isCurrentAlarm={isCurrentAlarm}
                values={formikBag.values}
                form={formikBag}
              />
            ) : null}

            {formikBag.status}
            <ActionBlock>
              <Button
                id={`alarmparameterform-cancel`}
                onClick={() => onCancel()}
              >
                <Trans>Cancel</Trans>
              </Button>
              <ButtonPrimary
                id="alarmparameterform-submit"
                type="submit"
                disabled={formikBag.isSubmitting}
                iconType="icon-save"
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}

const RelativeAlarmSection = ({
  isCurrentAlarm,
  values,
}: {
  isCurrentAlarm: boolean;
  values: AlarmParameterFormValues;
  form: FormikHelpers<AlarmParameterFormValues>;
}) => {
  const [{ data: currentAlarmRelativeObsPoint }] = useGetApi(
    isCurrentAlarm && values?.relative_observation_point
      ? `/observation-points/${values.relative_observation_point}/`
      : null
  );

  return (
    <fieldset>
      <legend>
        <Trans>Relative comparison</Trans>
      </legend>
      <FormItem
        fieldId="alarmparameterform-relative_observation_point"
        label={<Trans>Comparison observation point</Trans>}
      >
        {isCurrentAlarm ? (
          <span>
            {currentAlarmRelativeObsPoint && values.relative_item_number
              ? `${currentAlarmRelativeObsPoint.code} - ${values.relative_item_number}`
              : null}
          </span>
        ) : (
          <>
            <ObsPointItemMenu
              id="relative_observation_point_menu"
              name="relative_observation_point_menu"
              autoFocus={true}
              isMulti={false}
              detailsName={'relativeObsPoint'}
            />
            <ErrorMessage
              name="relative_observation_point_menu"
              component={ErrorNotice}
            />
          </>
        )}
        <ErrorMessage
          name="relative_observation_point"
          component={ErrorNotice}
        />
        <ErrorMessage name="relative_item_number" component={ErrorNotice} />
      </FormItem>
      <FormItem
        fieldId="alarmparameterform-relative_comparison_factor"
        label={<Trans>Comparison factor</Trans>}
      >
        {isCurrentAlarm ? (
          <span>{values.relative_comparison_factor}</span>
        ) : (
          <>
            <Field
              name="relative_comparison_factor"
              id="alarmparameterform-relative_comparison_factor"
            />
          </>
        )}
        <ErrorMessage
          name="relative_comparison_factor"
          component={ErrorNotice}
        />
      </FormItem>
      <FormItem
        fieldId="alarmparameterform-relative_comparison_datetime_tolerance"
        label={<Trans>Comparison time difference tolerance</Trans>}
        className="form-group-interval-select"
      >
        {isCurrentAlarm ? (
          <span>
            <IntervalDisplay
              value={values.relative_comparison_datetime_tolerance}
            />
          </span>
        ) : (
          <div className="form-group-content">
            <IntervalField
              name="relative_comparison_datetime_tolerance"
              id="alarmparameterform-relative_comparison_datetime_tolerance"
            />
          </div>
        )}
        <ErrorMessage
          name="relative_comparison_datetime_tolerance"
          component={ErrorNotice}
        />
      </FormItem>
    </fieldset>
  );
};
