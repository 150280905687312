import React from 'react';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi } from 'util/backendapi/fetch';
import {
  makeIdCodeMenuOptions,
  makeIdCodeAndNameMenuOptions,
  SingleMenuProps,
  MultiMenuProps,
} from './utils';

export function AreaSingleMenu(props: SingleMenuProps) {
  const { name, ...otherProps } = props;
  return (
    <AsyncSimpleSelectField<number, false>
      name={name}
      {...otherProps}
      isMulti={false}
      onSearch={async (searchText: string) =>
        makeIdCodeAndNameMenuOptions(
          await getApi('/areas/', {
            code__icontains: searchText,
          })
        )
      }
      loadDefaults={async (value: number) =>
        makeIdCodeAndNameMenuOptions(
          await getApi('/areas/', {
            id__in: [value],
          })
        )
      }
    />
  );
}

type AreaMultiMenuProps = MultiMenuProps & { shortLabel?: boolean };

export function AreaMultiMenu(props: AreaMultiMenuProps) {
  const { name, ...otherProps } = props;

  const makeMenuOptions = props.shortLabel
    ? makeIdCodeMenuOptions
    : makeIdCodeAndNameMenuOptions;

  return (
    <AsyncSimpleSelectField<number, true>
      name={name}
      {...otherProps}
      isMulti
      onSearch={async (searchText: string) =>
        makeMenuOptions(
          await getApi('/areas/', {
            code__icontains: searchText,
          })
        )
      }
      loadDefaults={async (values: number[]) =>
        makeMenuOptions(
          await getApi('/areas/', {
            id__in: values,
          })
        )
      }
    />
  );
}
