import { DuckActions } from 'main/store';

/**
 * Special actions that cause changes across the full state.
 *
 * Included here instead of in `reducers.ts` to avoid circular imports
 */

export const GlobalActionTypes = {
  CLEAR_STATE: 'dms/CLEAR_STATE',
  CLEAR_CACHED_DATA: 'dms/CLEAR_CACHED_DATA',
} as const;

export const GlobalActionCreators = {
  /**
   * Clear the Redux state. This will result in the user being redirected
   * to the login screen.
   *
   * @param message If provided, this message will be displayed on the
   * login screen.
   */
  CLEAR_STATE: () => ({
    type: GlobalActionTypes.CLEAR_STATE,
  }),

  /**
   * Clear all the Redux state except for the parts that we persist into
   * LocalStorage. This will keep the user logged in, but force all cached
   * data to be reloaded.
   */
  CLEAR_CACHED_DATA: () => ({ type: GlobalActionTypes.CLEAR_CACHED_DATA }),
} as const;

export type GlobalAction = DuckActions<
  typeof GlobalActionTypes,
  typeof GlobalActionCreators
>;
