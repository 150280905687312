import {
  BaseStoredSpatialPlot,
  SpatialPlotObservationPoint,
} from 'util/backendapi/types/Model';
import clamp from 'lodash/clamp';

// @see basestyles/_icons.scss
export const ICON_MARKER_DAM_LEVELLING = '\uE954';
export const ICON_MARKER_DEFORMATION = '\uE955';
export const ICON_MARKER_OBSERVATION_WELL = '\uE957';
export const ICON_MARKER_PIEZOMETER_TIP = '\uE958';
export const ICON_MARKER_WATER_LEVEL = '\uE959';
// Water level icon, but aligned so its bottom is directly on the font "baseline"
export const ICON_MARKER_WATER_LEVEL_BASELINE = '\uE95A';

// TODO: For exporting to PDF we'll have to reinstate the paperspace "left margin"
// and "bottom margin" fields, which represent the positioning of the plot image
// inside the printed A3 sheet of paper.
//
// To help keep that in mind, for now I'm using these hard-coded variables set
// to `0` to represent that.
const LEFT_IMAGE_MARGIN_MM = 0;
const BOTTOM_IMAGE_MARGIN_MM = 0;

type SpatialPlot = Pick<
  BaseStoredSpatialPlot,
  'base_paperspace_x' | 'base_paperspace_y' | 'scale'
>;

/**
 * Calculate the easting and northing of the lower-left corner of a
 * stored spatial plot.
 *
 * @param plot
 * @param referencePoint
 */
export function calculatePlanViewOrigin(
  plot: SpatialPlot,
  baseObsPoint: {
    nztm_northing: string | number;
    nztm_easting: string | number;
  }
): { left: number; bottom: number } {
  // The reference point's recorded paperspace position is relative
  // to the entire A3 sheet of paper. Subtract the image's position
  // in the paper, to get the reference point's location relative
  // to the image alone.
  const refMMfromLeft = +plot.base_paperspace_x! - LEFT_IMAGE_MARGIN_MM;
  const refMMfromBottom = +plot.base_paperspace_y! - BOTTOM_IMAGE_MARGIN_MM;

  // Convert the reference point's distance from the image's lower-left,
  // into meters of easting/northing in the map grid
  const refGridMetersFromLeft = (refMMfromLeft * +plot.scale) / 1000;
  const refGridMetersFromBottom = (refMMfromBottom * +plot.scale) / 1000;

  // Subtract the map grid meters of distance from the reference point's
  // geographical easting and northing, and you get the easting and
  // northing of the image's lower-left corner.
  return {
    left: +baseObsPoint.nztm_easting - refGridMetersFromLeft,
    bottom: +baseObsPoint.nztm_northing - refGridMetersFromBottom,
  };
}

export function calculateCrossSectionOrigin(
  plot: SpatialPlot,
  baseObsPoint: SpatialPlotObservationPoint & {
    port_rl: number;
  }
): { left: number; bottom: number } {
  const refMMfromLeft = +plot.base_paperspace_x! - LEFT_IMAGE_MARGIN_MM;
  const refMMfromBottom = +plot.base_paperspace_y! - BOTTOM_IMAGE_MARGIN_MM;

  // Convert the reference point's paperspace distance from the image's
  // lower-left, into a measure of "real world" meters
  const refScaledMetersFromLeft = (refMMfromLeft * +plot.scale) / 1000;
  const refScaledMetersFromBottom = (refMMfromBottom * +plot.scale) / 1000;

  // Now subtract the "real world" distance from the obs point's x, y
  // coordinates in the plot, and you get the x,y coordinates of the image's
  // lower left.
  return {
    // X axis values in the plot represent distance away from the base obs point.
    // So the base obs point itself is at 0.
    left: 0 - refScaledMetersFromLeft,
    // Y axis values represent differences in elevation, relative to the
    // base observation point's Port RL.
    bottom: baseObsPoint.port_rl - refScaledMetersFromBottom,
  };
}

export function calculateSpatialPlotDomains(
  storedPlot: {
    paperspace_width: string;
    paperspace_height: string;
    scale: number;
  },
  origin: { left: number; bottom: number }
) {
  const { left, bottom } = origin;
  const imageWidthMM = +storedPlot.paperspace_width;
  const imageHeightMM = +storedPlot.paperspace_height;
  const scale = storedPlot.scale;

  // Find out how many "real world" meters the image covers.
  // Multiply by the image's scale and then divide by 1000 to convert from MM to M.
  const imageWidthInScaledMeters = (imageWidthMM * scale) / 1000;
  const imageHeightInScaledMeters = (imageHeightMM * scale) / 1000;

  return [
    [left, left + imageWidthInScaledMeters] as [number, number],
    [bottom, bottom + imageHeightInScaledMeters] as [number, number],
  ];
}

/**
 * A "force" for D3 force-directed layout. It prevents nodes from being pushed
 * outside the boundaries of the plot area.
 */
export function forceStayInBounds() {
  let nodes: { x: number; y: number; vx?: number; vy?: number }[];
  let xDomain = [0, 1];
  let yDomain = [0, 1];
  let getWidth: (node: any) => number = (node) => node.width;
  let getHeight: (node: any) => number = (node) => node.height;
  function force() {
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      const halfWidth = getWidth(node) / 2;
      const halfHeight = getHeight(node) / 2;
      if (node.x < xDomain[0] + halfWidth || node.x > xDomain[1] - halfWidth) {
        node.vx = 0;
        node.x = clamp(node.x, xDomain[0], xDomain[1]);
      }
      if (
        node.y < yDomain[0] + halfHeight ||
        node.y > yDomain[1] - halfHeight
      ) {
        node.vy = 0;
        node.y = clamp(node.y, yDomain[0], yDomain[1]);
      }
    }
  }
  force.initialize = function (_nodes: any[]) {
    nodes = _nodes.filter((n) => !n.isIcon);
  };
  force.xDomain = function (d: [number, number]) {
    xDomain = d;
    return force;
  };
  force.yDomain = function (d: [number, number]) {
    yDomain = d;
    return force;
  };
  force.width = function (f: (n: any) => number) {
    getWidth = f;
    return force;
  };
  force.height = function (f: (n: any) => number) {
    getHeight = f;
    return force;
  };
  return force;
}
