import React from 'react';
import ExportPanelView, { ExportPanelProps } from './exportpanelview';
import {
  backendUrl,
  withAuthHeaders,
  postApi,
} from '../../../util/backendapi/fetch';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { RouteComponentProps, match } from 'react-router';
import { withRouter } from 'react-router-dom';
import {
  ExportFormats,
  ExportFormatMimeTypes,
  PageOrientations,
} from './exportpanelconstants';
import { Location } from 'history';
import { Trans, t } from '@lingui/macro';
import ButtonShowModal, {
  ButtonShowModalProps,
} from 'components/base/modal/buttonshowmodal';
import { getDatetimeForExportFilename } from 'util/dates';
import { withI18n, withI18nProps } from '@lingui/react';
import { I18n } from '@lingui/core';
import { QueuedExportModal } from './QueuedExportModal';

/**
 * The panel for exporting to a downloadable CSV/PDF/PNG.
 *
 * @param {GetExportUrlFunc?} getExportUrl Generates backend URL to get the export
 * from, given selected format and current route props.
 * @param {React.ReactNode} [title = "Export report"] Modal header
 * @param {React.ReactNode} [description = "The report will be exported with the same settings as displayed on screen"]
 * @param {bool} [canExportCsv=false]
 * @param {bool} [canExportPdf=false]
 * @param {bool} [canExportPng=false]
 */

export interface GetExportUrlFunc {
  (
    exportFormat: ExportFormats,
    routeProps: {
      queryParams: URLSearchParams;
      location: Location;
      match: match;
    },
    i18n: I18n,
    generatedDatetime: string,
    pageOrientation?: string
  ):
    | {
        path: string;
        queryParams: URLSearchParams;
        filename?: string;
      }
    | Promise<{
        path: string;
        queryParams: URLSearchParams;
        filename?: string;
      }>;
}

type OwnProps = {
  getExportUrl?: GetExportUrlFunc;
  // If the selected format is in the taskQueueFormat list, exports will be generated asynchronously via a background
  // task, and the user will see a modal that informs them of its progress.
  taskQueueFormats?: ExportFormats[];
  canSelectPageOrientation?: boolean;
} & Pick<
  ExportPanelProps,
  | 'title'
  | 'description'
  | 'canExportPdf'
  | 'canExportCsv'
  | 'canExportPng'
  | 'canExportXlsx'
  | 'canExportDocx'
>;
type Props = OwnProps & RouteComponentProps & withI18nProps;
interface State {
  isDownloading: boolean;
  exportTaskId: string | null;
  exportFormat: ExportFormats;
  errorMessage: React.ReactNode | null;
  pageOrientation: PageOrientations;
}

class ExportPanelInner extends React.Component<Props, State> {
  _isMounted = false;
  state: State = {
    isDownloading: false,
    exportTaskId: null,
    exportFormat: this.props.canExportPdf
      ? ExportFormats.PDF
      : this.props.canExportCsv
      ? ExportFormats.CSV
      : this.props.canExportXlsx
      ? ExportFormats.XLSX
      : this.props.canExportDocx
      ? ExportFormats.DOCX
      : ExportFormats.PNG,
    errorMessage: null,
    pageOrientation: PageOrientations.PORTRAIT,
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static defaultProps: Pick<Props, 'getExportUrl'> = {
    getExportUrl: (exportFormat, { queryParams, location }) => {
      const pathname =
        location.pathname.slice(location.pathname.length - 1) === '/'
          ? location.pathname
          : `${location.pathname}/`;
      if (exportFormat === ExportFormats.CSV) {
        // Default CSV export URL, e.g. /observation-points/export/csv/
        return {
          path: `${pathname}export/csv/`,
          queryParams,
        };
      } else {
        // Default PDF export URL, e.g. /export/pdf/stored-plots/
        return {
          path: `/export/${exportFormat}${pathname}`,
          queryParams,
        };
      }
    },
  };

  handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    this.setState({
      isDownloading: true,
      errorMessage: null,
    });

    const defaultQueryParams = new URLSearchParams(this.props.location.search);
    if (this.state.exportFormat === ExportFormats.CSV) {
      // TODO: @deprecated The CSV and PDF renderers originally relied on this
      // "utc_offset" param for generating certain dates in the user's timezone.
      // We now instead include a `User-Time-Zone` header on each backend API
      // request, and the backend should use that instead.
      //
      // But some CSV endpoints still use this.
      defaultQueryParams.set('utc_offset', String(moment().utcOffset()));
    }
    // Remove pagination params (to make it easier to generate the CSV export URLs)
    defaultQueryParams.delete('limit');
    defaultQueryParams.delete('offset');
    const generatedDatetime = getDatetimeForExportFilename();
    const urlData = {
      queryParams: defaultQueryParams,
      location: this.props.location,
      match: this.props.match,
    };

    const { path, queryParams, filename } = await this.props.getExportUrl!(
      this.state.exportFormat,
      urlData,
      this.props.i18n,
      generatedDatetime,
      this.state.pageOrientation
    );

    let queryString = queryParams.toString();
    if (queryString) {
      queryString = `?${queryString}`;
    }
    const exportURL = backendUrl(`${path}${queryString}`);

    const inTaskQueue = this.props.taskQueueFormats?.some(
      (format) => format === this.state.exportFormat
    );
    if (inTaskQueue) {
      try {
        const response = await postApi(
          `/tasks${path}${queryString}` as '/tasks/export/:exportURL/'
        );
        if (this._isMounted) {
          this.setState({ exportTaskId: response.id });
        }
      } catch (e) {
        if (this._isMounted) {
          this.setState({
            errorMessage: <Trans>Error: {e.message}</Trans>,
          });
        }
      }
    } else {
      try {
        const response = await fetch(
          exportURL,
          withAuthHeaders({
            headers: {
              Accept: ExportFormatMimeTypes[this.state.exportFormat],
            },
          })
        );
        if (response && response.ok) {
          let saveasFileName: string;
          if (filename) {
            saveasFileName = filename;
          } else {
            if (
              response.headers &&
              response.headers.get('Content-Disposition')
            ) {
              saveasFileName = response
                .headers!.get('Content-Disposition')!
                .split('"')[1];
            } else {
              // TODO: internationalization.
              saveasFileName = this.props.i18n._(
                t`DMS report - ${generatedDatetime}.${this.state.exportFormat.toLowerCase()}`
              );
            }
          }
          saveAs(await response.blob(), saveasFileName);
        } else {
          throw new Error(response.statusText);
        }
      } catch (e) {
        if (this._isMounted) {
          this.setState({
            errorMessage: <Trans>Error: {e.message}</Trans>,
          });
        }
      } finally {
        if (this._isMounted) {
          this.setState({
            isDownloading: false,
          });
        }
      }
    }
  };

  handleExportFormatChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      exportFormat: evt.target.value as ExportFormats,
    });
  };

  handlePageOrientationChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      pageOrientation: evt.target.value as PageOrientations,
    });
  };

  render() {
    const { exportTaskId } = this.state;
    if (exportTaskId) {
      return (
        <QueuedExportModal
          taskId={exportTaskId}
          fileTypeLabel={this.props.i18n._(this.state.exportFormat)}
        />
      );
    } else
      return (
        <ExportPanelView
          isExporting={this.state.isDownloading}
          onExportFormatChange={this.handleExportFormatChange}
          onPageOrientationChange={this.handlePageOrientationChange}
          onSubmit={this.handleSubmit}
          exportFormat={this.state.exportFormat}
          pageOrientation={this.state.pageOrientation}
          title={this.props.title}
          description={this.props.description}
          canExportPdf={this.props.canExportPdf}
          canExportPng={this.props.canExportPng}
          canExportCsv={this.props.canExportCsv}
          canExportXlsx={this.props.canExportXlsx}
          canExportDocx={this.props.canExportDocx}
          canSelectPageOrientation={this.props.canSelectPageOrientation}
          errorMessage={this.state.errorMessage}
        />
      );
  }
}

export const ExportPanel = withI18n()(withRouter(ExportPanelInner));

/**
 * Convenience component for "standard" export panels that don't need any special
 * customization.
 *
 * If you need to customize the export panel, you should use a ButtonShowModal
 * and ExportPanel directly.
 *
 * @param props
 */
export const ExportPanelModalButton: React.FunctionComponent<
  Pick<
    Props,
    | 'taskQueueFormats'
    | 'getExportUrl'
    | 'canExportPdf'
    | 'canExportCsv'
    | 'canExportPng'
    | 'canExportXlsx'
    | 'canExportDocx'
    | 'canSelectPageOrientation'
  > &
    Partial<ButtonShowModalProps>
> = (props) => {
  const {
    getExportUrl,
    canExportPdf,
    canExportCsv,
    canExportPng,
    canExportXlsx,
    canExportDocx,
    taskQueueFormats,
    canSelectPageOrientation,
    ...buttonProps
  } = props;
  return (
    <ButtonShowModal
      name="export"
      modalContent={() => (
        <ExportPanel
          getExportUrl={getExportUrl}
          canExportPdf={canExportPdf}
          canExportCsv={canExportCsv}
          canExportPng={canExportPng}
          canExportXlsx={canExportXlsx}
          canExportDocx={canExportDocx}
          taskQueueFormats={taskQueueFormats}
          canSelectPageOrientation={canSelectPageOrientation}
        />
      )}
      iconType="icon-export"
      shortcut="SHOW_EXPORT_MODAL"
      {...buttonProps}
    >
      <Trans>Export</Trans>
    </ButtonShowModal>
  );
};
