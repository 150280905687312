import uniq from 'lodash/uniq';
import { extent } from 'd3-array';
import {
  ObservationPointItemUrlCode,
  PlotReadingsSeries,
  ScatterPlotAxisSide,
  ScatterPlotSettings,
} from 'components/plots/timeseriesplot.types';
import { ReportsAlarmParameter } from 'util/backendapi/types/Model';
import { getApi } from 'util/backendapi/fetch';
import { formatDatetimeForBackendUrl } from 'util/dates';
import { Enum, Filter } from 'util/backendapi/models/api.interfaces';
import { isNotNull } from 'util/validation';
import orderBy from 'lodash/orderBy';
import { t } from '@lingui/macro';
import { i18n } from '@lingui/core';

export interface RelativeAlarmScatterPlotData {
  settings: ScatterPlotSettings;
  readingsSeries: PlotReadingsSeries<ScatterPlotAxisSide>[];
}

export async function fetchAlarmParameterScatterPlotData(
  relativeAlarmParameters: ReportsAlarmParameter[]
) {
  // Identify the unique pairs of observation points items in the alarm parameters
  const pairs: ObservationPointItemUrlCode[][] = uniq(
    relativeAlarmParameters.map(
      (param) =>
        `${param.observation_point__code}:${param.item_number}:${param.relative_observation_point__code}:${param.relative_item_number}`
    )
  ).map((pair) => {
    const [
      yObservationPointCode,
      yItemNumber,
      xObservationPointCode,
      xItemNumber,
    ] = pair.split(':');
    return [
      {
        observationPointCode: yObservationPointCode,
        itemNumber: Number(yItemNumber),
      },
      {
        observationPointCode: xObservationPointCode,
        itemNumber: Number(xItemNumber),
      },
    ];
  });

  let relativeAlarmPlotsData: RelativeAlarmScatterPlotData[] = [];

  const axes: ScatterPlotSettings['axes'] = [];

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i];

    const yObservationPointCode = pair[0].observationPointCode;
    const yItemNumber = pair[0].itemNumber;
    const xObservationPointCode = pair[1].observationPointCode;
    const xItemNumber = pair[1].itemNumber;

    const startDatetime = null;
    const endDatetime = null;

    const obsPoints = await getApi('/observation-points/', {
      code__in: [yObservationPointCode, xObservationPointCode],
    });

    // Put the observation points in the same order as the series.
    const observationPoints = [
      yObservationPointCode,
      xObservationPointCode,
    ].map((observationPointCode) => {
      const obsPoint = obsPoints.find((op) => op.code === observationPointCode);

      if (!obsPoint) {
        throw new Error(
          i18n._(t`Observation point "${observationPointCode}" not found.`)
        );
      }
      return obsPoint;
    });

    const pairAlarmParameters = relativeAlarmParameters.filter(
      (param) =>
        param.observation_point__code === yObservationPointCode &&
        param.item_number === yItemNumber &&
        param.relative_observation_point__code === xObservationPointCode &&
        param.relative_item_number === xItemNumber
    );

    let filteredAlarmParameters: ReportsAlarmParameter[] = [];

    // For each pair, only display the alarm parameters for the highest level found
    const alarmLevels = [
      Enum.AlarmParameter_LEVEL.alert,
      Enum.AlarmParameter_LEVEL.design_check,
      Enum.AlarmParameter_LEVEL.data_check,
    ];
    alarmLevels.some((level) => {
      filteredAlarmParameters = pairAlarmParameters.filter(
        (param) => param.level === level
      );
      return filteredAlarmParameters.length > 0;
    });

    const settings: ScatterPlotSettings = {
      plotType: Enum.PlotType.SCATTER,
      start_datetime: startDatetime,
      end_datetime: endDatetime,
      reading_series: pair.map((pair, i) => ({
        observation_point: observationPoints[i].id,
        item_number: pair.itemNumber,
        axis_side: i === 0 ? 'left' : ('bottom' as 'left' | 'bottom'),
        show_plot_port_rl: false,
        show_plot_markers: true,
        show_analysis_comment_indicators: false,
        show_inspector_comment_indicators: false,
        show_media_indicators: false,
        show_alarm_parameters: false,
        show_legend_cap_rl: false,
        show_legend_port_rl: false,
        show_confidence_level: false,
      })),
      observationPoints,
      relativeAlarmParameters: filteredAlarmParameters,
      axes,
      duration: null,
      showAnalysisComments: false,
      showInspectorComments: false,
      showMediaAll: false,
      interpolate: true,
      highlight_periods: [],
      show_plot_markers: true,
      show_mark_connections: true,
      storedPlot: null,
    };

    const [minDatetime, maxDatetime] = extent(
      observationPoints.flatMap((op) =>
        [
          op.earliest_reading && op.earliest_reading.reading_datetime,
          op.latest_reading && op.latest_reading.reading_datetime,
        ].filter(isNotNull)
      ),
      (reading_datetime) => new Date(reading_datetime)
    );

    const readingDateParams: Partial<Filter.ReadingsPlot> = {};

    if (minDatetime) {
      readingDateParams.reading_datetime_after =
        formatDatetimeForBackendUrl(minDatetime);
    }

    if (maxDatetime) {
      readingDateParams.reading_datetime_before =
        formatDatetimeForBackendUrl(maxDatetime);
    }

    const { y_readings, x_readings } = await getApi('/readings/scatter-plot/', {
      y_observation_point: observationPoints[0].id,
      y_item_number: yItemNumber,
      x_observation_point: observationPoints[1].id,
      x_item_number: xItemNumber,
      ...readingDateParams,
    });

    const readingsSeries = [y_readings, x_readings].map((readings, idx) => {
      const series: PlotReadingsSeries<ScatterPlotAxisSide> = {
        settings: settings.reading_series[idx],
        readings: orderBy(readings, [(r) => r.reading_datetime], ['asc']).map(
          (reading) => ({
            x: new Date(reading.reading_datetime),
            y: reading.value,
            reading_id: reading.reading_id,
          })
        ),
        loading: false,
        observationPoint: observationPoints[idx],
      };
      return series;
    });

    relativeAlarmPlotsData.push({ settings, readingsSeries });
  }

  return relativeAlarmPlotsData;
}
