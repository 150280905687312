import moment from 'moment-timezone';
import { MomentInput } from 'moment';
import { MessageDescriptor } from '@lingui/core';
import { transEnum } from './i18n-utils';
import sortBy from 'lodash/sortBy';
import { IntervalUnit, INTERVAL_UNITS } from './backendapi/types/Enum';

/**
 * This is a "magic" datetime value used by the backend to indicate an
 * "as early as possible" datetime on some records. :-P
 *
 * The backend calls it `MINIMUM_DATETIME`. It's sort of like `-Infinity` but for
 * datetimes.
 */
export const THE_BEGINNING_OF_TIME = '1000-01-01T00:00:00Z';

/**
 * Functions for dealing with dates.
 *
 * Dates *with* times ("datetimes"), are handled internally as ISO date strings
 * in the UTC timezone. Dates that need to be displayed in a specific timezone
 * (e.g. readings) have the timezone specified in a separate string in IANA
 * format (Pacific/Auckland), and the timezone must be provided to the formatting
 * function when the datetime is formatted for display.
 *
 * Dates *without* times are handled internally as strings in YYYY-MM-DD format.
 * A date string in this format generally should NOT be used with a timezone,
 * or compared to a datetime.
 *
 * Because we store datetimes in UTC, and timezones (where applicable) separately,
 * you usually only need to provide explicit timezones in these situations:
 *
 * 1. Displaying a reading datetime to the user: (UTC -> dam)
 * 2. Parsing user input into a reading datetime: (dam -> UTC)
 * 3. Truncating a reading datetime into a date: (UTC -> dam)
 * 4. "Expanding" a date into a reading datetime filter: (dam -> UTC)
 */

/**
 * Helper function to convert a UTC ISO datetime string to a human-readable
 * format, using moment.js.
 *
 * @param {string} format The format string to use
 * @param {string} datetime A datetime string in UTC time
 * @param {string} [timezone] Optional timezone in IANA tzdata format ("Pacific/Auckland")
 * @return {string} A datetime string in the specified timezone, or local time
 * if no timezone is provided.
 */
function _formatUsingMoment(
  format: string,
  datetime: MomentInput | null,
  timezone?: null | string
) {
  if (datetime === null) {
    return '';
  }
  const m = moment(datetime, moment.ISO_8601, true);
  if (timezone) {
    m.tz(timezone);
  }
  if (m.isValid()) {
    return m.format(format);
  } else {
    return '';
  }
}

/**
 * Helper function to convert a UTC ISO datetime string from one string format
 * to another.
 *
 * @param {string} format
 * @param {string} datetime A datetime string in UTC time
 * @return {string} A datetime string in UTC time
 */
function _formatUsingMomentUtc(format: string, datetime: MomentInput) {
  const m = moment.utc(datetime, moment.ISO_8601, true);
  if (m.isValid()) {
    return m.format(format);
  } else {
    return '';
  }
}

/**
 * Validate and convert a datetime string from an external source (typically
 * the backend API), into an ISO UTC datetime string as used internally in the
 * frontend.
 *
 * This function is meant to be used as a "whitelisting" step for datetimes
 * that are already probably well-formed and UTC. So, it takes no timezone
 * parameter.
 *
 * @export
 * @param {string} datetime
 * @return {string}
 */
export function formatDatetimeForStorage(datetime: MomentInput | null) {
  const m = moment.utc(datetime!, moment.ISO_8601, true);
  if (m.isValid()) {
    return m.toISOString();
  } else {
    return '';
  }
}

/**
 * A RegExp to check whether a given string is in internal storage format
 * (an ISO-formatted UTC datetime)
 */
const DATETIME_INTERNAL_REGEX = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?Z$/;
export function isInternalDatetimeString(datetime: unknown): boolean {
  if (typeof datetime === 'string') {
    return DATETIME_INTERNAL_REGEX.test(datetime);
  } else {
    return false;
  }
}

/**
 * Format a UTC ISO datetime string into the format the backend API expects for
 * datetime data.
 *
 * NOTE: Backend *URL* parameters take a different format!
 * @see formatDatetimeForBackendUrl
 * @export
 * @param {string} datetime
 * @return {string}
 */
export function formatDatetimeForBackendapi(datetime: MomentInput) {
  // Currently the backend api uses the same format (ISO UTC datetime string)
  // as the frontend's internal datetime format.
  return formatDatetimeForStorage(datetime);
}

/**
 * Format a UTC ISO datetime string into the format used by the backend for
 * URL parameters.
 *
 * @export
 * @param {string} datetime
 * @param {string} [timezone]
 * @return {string}
 */
export function formatDatetimeForBackendUrl(datetime: MomentInput) {
  return _formatUsingMomentUtc(DATETIME_BACKEND_FILTER_FORMAT, datetime);
}
// The backend is based on Django filters, which accept datetimes in this
// format rather than in ISO format. They do not accept a timezone specifier;
// the datetime is automatically parsed in the backend server's timezone.
// Our backend server is hard-coded to UTC, however, so this is not a problem.
const DATETIME_BACKEND_FILTER_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**
 * Format a UTC ISO datetime string into a short human-readable format.
 *
 * @param {string} datetime
 * @param {string} [timezone] The timezone to display the datetime in. Should
 * be in IANA tzdata format, e.g. "Pacific/Auckland", "Asia/Manila", etc.
 * If not provided, uses the browser's current timezone.
 * @return {string}
 */
export function formatDatetimeForDisplay(
  datetime: MomentInput | null,
  timezone?: null | string
) {
  return _formatUsingMoment(
    timezone
      ? DATETIME_DAM_TZ_DISPLAY_FORMAT
      : DATETIME_LOCAL_TZ_DISPLAY_FORMAT,
    datetime,
    timezone
  );
}
const DATETIME_LOCAL_TZ_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm';
const DATETIME_DAM_TZ_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm z';

export function formatDatetimeForDisplayAsDate(
  datetime: MomentInput,
  timezone?: string
) {
  return _formatUsingMoment(DATE_DISPLAY_FORMAT, datetime, timezone);
}

/**
 * Format a UTC ISO datetime string into a longer human-readable format.
 *
 * @export
 * @param {string} datetime
 * @param {string} [timezone] The timezone to display the datetime in. Should
 * be in IANA tzdata format, e.g. "Pacific/Auckland", "Asia/Manila", etc
 * If not provided, uses the browser's current timezone.
 * @return {string}
 */
export function formatDatetimeForDisplayPretty(
  datetime: MomentInput,
  timezone?: string
) {
  return _formatUsingMoment(
    timezone ? DATETIME_DAM_TZ_PRETTY_FORMAT : DATETIME_LOCAL_TZ_PRETTY_FORMAT,
    datetime,
    timezone
  );
}
const DATETIME_LOCAL_TZ_PRETTY_FORMAT = 'D MMM YYYY HH:mm';
const DATETIME_DAM_TZ_PRETTY_FORMAT = 'D MMM YYYY HH:mm z';

/**
 * Format a date (no time!) coming from the backend api, into the format
 * used internally by the frontend.
 *
 * TODO: Do we want to directly pass through "null" values?
 *
 * @export
 * @param {string} date
 * @returns {string} Returns a date string, or "" for an invalid input.
 */
export function formatDateForStorage(date: MomentInput) {
  return _formatUsingMomentUtc(DATE_INTERNAL_FORMAT, date);
}

const DATE_INTERNAL_REGEX = /^\d\d\d\d-\d\d-\d\d$/;
export function isInternalDateString(date: unknown): boolean {
  if (typeof date === 'string') {
    return DATE_INTERNAL_REGEX.test(date);
  } else {
    return false;
  }
}

/**
 * Format a date (no time!) from internal frontend format to the format required
 * for serialization and sending to the backend.
 *
 * @export
 * @param {string|null} date
 * @returns {string|null} Returns a date string, or null if date is null, or ""
 * for other invalid inputs.
 */
export function formatDateForBackendapi(date: MomentInput | null) {
  if (date === null) {
    return null;
  }

  return _formatUsingMomentUtc(DATE_BACKEND_FORMAT, date);
}
const DATE_BACKEND_FORMAT = 'YYYY-MM-DD';

/**
 * Format a date string in ISO format (with no time!) into a short,
 * human-readable format.
 *
 * @export
 * @param {string} date A date string in YYYY-MM-DD format.
 * @return {string}
 */
export function formatDateForDisplay(date: MomentInput) {
  return _formatUsingMomentUtc(DATE_DISPLAY_FORMAT, date);
}
const DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';

/**
 * Truncate a UTC ISO datetime string into an ISO date (without time) string.
 *
 * @export
 * @param datetime The datetime to truncate.
 * @param timezone The timezone to get the calendar date
 * for. (Every datetime represents two different calendar dates, on opposite
 * sides of the international date line.)
 * Pass `null` for the user's current timezone (aka "user time").
 * Defaults to UTC.
 * @returns A date in ISO format (YYYY-MM-DD)
 */
export function convertDatetimeToDate(
  datetime: MomentInput | null,
  timezone: string | null = 'Etc/UTC'
) {
  return _formatUsingMoment(
    DATE_INTERNAL_FORMAT,
    datetime,
    timezone || undefined
  );
}
const DATE_INTERNAL_FORMAT = 'YYYY-MM-DD';

/**
 * Pad an ISO date (without time) string into a full UTC ISO datetime string.
 * By default, it sets the time to midnight in the specified timezone.
 *
 * @see convertDateToDatetime
 * @export
 * @param date In YYYY-MM-DD format
 * @param timeZone The timezone to parse the date + timeOfDay in.
 * This is important when you are picking "start/end date" when plotting readings.
 * Pass `null` for the user's current timezone (aka "user time").
 * Defaults to UTC.
 * @param timeOfDay The time of day to append to the date, in HH:MM:SS format.
 * Defaults to midnight (in specified time zone)
 * @returns A full datetime string in UTC ISO format.
 */
export function convertDateToDatetime(
  date: MomentInput | null,
  timezone: null | string = 'Etc/UTC',
  timeOfDay: string = START_OF_DAY
) {
  let m;
  const dateWithTime = `${date}T${timeOfDay}`;
  const format = `${DATE_INTERNAL_FORMAT}THH:mm:ss`;
  if (timezone) {
    m = moment.tz(dateWithTime, format, timezone);
  } else {
    m = moment(dateWithTime, format);
  }
  if (m.isValid()) {
    return m.toISOString();
  } else {
    return '';
  }
}
export const START_OF_DAY = '00:00:00';
export const MID_DAY = '12:00:00';
export const END_OF_DAY = '23:59:59';

/**
 * Generate obvious fake dates in sequence, for use when generating mock data
 * during testing.
 *
 * e.g.: 2001-01-01 01:01:01, 2002-02-02 02:02:02, etc...
 *
 * TODO: This may make more sense in testutils.js, but for some reason it
 * causes code to crash when I import it from there. I suspect this has something
 * to do with node import resolution.
 *
 * @export
 * @param {number} seed A number to add to add to each field in the dates.
 * @param {string} [baseDatetime='2001-01-01T01:01:01Z'] The date to add the seed
 * number to.
 * @returns {string}
 */
export function makeMockDatetime(
  seed: number,
  baseDatetime: MomentInput = '2001-01-01T01:01:01Z'
) {
  return moment
    .utc(baseDatetime)
    .add({
      years: seed,
      months: seed,
      days: seed,
      hours: seed,
      minutes: seed,
      seconds: seed,
    })
    .toISOString();
}

/**
 * Create obvious fake dates in sequence, for use when generating mock data
 * during testing.
 *
 * e.g.: 2001-01-01, 2002-02-02, 2003-03-03, etc...
 *
 * TODO: This may make more sense in testutils.js, but for some reason it
 * causes code to crash when I import it from there. I suspect this has something
 * to do with node import resolution.
 *
 * @param {number} seed
 * @param {string} baseDate
 * @return {string}
 */
export function makeMockDate(
  seed: number,
  baseDate: MomentInput = '2001-01-01'
) {
  return moment
    .utc(baseDate)
    .add({ years: seed, months: seed, days: seed })
    .format('YYYY-MM-DD');
}

/**
 * A utility function to get a datetime string for the current time (rounded
 * to the start of the nearest minute, to match the UI for datetime inputs)
 */
export function getCurrentDatetime() {
  const now = moment();
  // round to the nearest minute
  now.seconds(0).milliseconds(0);

  // If UTC offset is specified in local storage, use that.
  if (localStorage.getItem('utcOffset')) {
    now.add(Number(localStorage.getItem('utcOffset')), 'minutes');
  }
  return formatDatetimeForStorage(now);
}

/**
 * Get the date and time formatted to insert into the filename of an exported
 * file, such as "Observation point report - 20190613 1615.csv"
 */
export function getDatetimeForExportFilename() {
  return _formatUsingMoment(DATETIME_EXPORT_FILENAME, getCurrentDatetime());
}
const DATETIME_EXPORT_FILENAME = 'YYYYMMDD HHmm';

/**
 * A utility function for formatting an interval string into something human-readable.
 *
 * If an exact-match unit is found, the duration will be formatted
 * with the relevant unit, otherwise the largest provided unit will be used for
 * conversion and formatting.
 *
 * @example
 * // Rather than using this directly, it's more convenient to just use the
 * // `<DatetimeInterval />` component, defined in `util/i18n.tsx`
 * const jsx = <DatetimeInterval value={'P7DT5H'} />;
 *
 * // If you need this as a `string` rather than a JSX component, however,
 * // you can pass the results of this function directly to `i18n._()`.
 * const str = i18n._(formatIntervalForDisplay('P7DT5H'));
 *
 * @param intervalString in a format that can be parsed by moment.duration(), i.e 'P7D'
 */
export function formatIntervalForDisplay(
  intervalString: null | undefined | string
): MessageDescriptor {
  if (!intervalString) {
    return { id: '' };
  }

  const { intervalNumber, intervalUnit } =
    parseIntervalFromString(intervalString);
  if (intervalNumber === null || intervalUnit == null) {
    return { id: '' };
  }

  return {
    id: transEnum('interval.count', intervalUnit),
    values: {
      count: intervalNumber,
    },
  };
}

export function formatIntervalForComputer(
  intervalNumber: number | null,
  intervalUnit: IntervalUnit | null
): string | null {
  if (intervalNumber === null || intervalUnit === null) {
    return null;
  } else {
    return moment.duration(intervalNumber, intervalUnit).toISOString();
  }
}

/**
 * A utility function for parsing an interval type and interval number, from
 * a duration in machine-readable format, e.g. "P7DT5H"
 *
 * @param intervalString
 * @param unitOptions
 */
export function parseIntervalFromString(
  intervalString: string | null,
  useThisUnit?: IntervalUnit
): { intervalNumber: number | null; intervalUnit: IntervalUnit | null } {
  if (!intervalString) {
    return { intervalNumber: null, intervalUnit: null };
  }
  const duration = moment.duration(intervalString);

  if (useThisUnit) {
    return {
      intervalUnit: useThisUnit,
      intervalNumber: duration.as(useThisUnit),
    };
  } else {
    // As a special case, return "0 minutes" for 0-length intervals.
    if (duration.as('milliseconds') === 0) {
      return {
        intervalNumber: 0,
        intervalUnit: 'minutes',
      };
    }
    // No unit specified, so we pick one.
    //
    // We pick the unit that generates the shortest (least number of digits)
    // representation of the interval. In case of tie, we use the larger unit
    // (i.e. the smaller number)
    const intervalAs = INTERVAL_UNITS.map((unit) => ({
      intervalUnit: unit,
      intervalNumber: duration.as(unit),
    }));
    return sortBy(
      intervalAs,
      ({ intervalNumber }) => intervalNumber.toString().length,
      ({ intervalNumber }) => intervalNumber
    )[0];
  }
}

export function makeMockIntervalString(
  seed: number,
  useThisUnit?: IntervalUnit
): string {
  const unit = useThisUnit || INTERVAL_UNITS[seed % INTERVAL_UNITS.length];
  return formatIntervalForComputer(seed, unit)!;
}

/**
 * Module-internal function for formatting a "time of day" (e.g. a Postgresql
 * "time" field). This is a time without a date, for example 8:17am. Typically
 * the back end represents these in HH:mm:ss format: 08:00:00, 23:59:59, etc.
 *
 * Unfortunately momentjs doesn't have a "time of day" data type, so we have
 * to make due using a combination of durations (aka intervals) and full
 * datetimes.
 *
 * Conveniently, moment.duration() can parse durations in ASP format, which
 * (for durations of less than a day) look the same as DRF's formatting for
 * timeofdays: 08:00:00.
 *
 * @param timeOfDay Time of day, represented as the duration since midnight
 * in any of the formats that `moment.duration()` accepts
 * @param format
 */
function _formatTimeofdayUsingMoment(
  timeOfDay: string | moment.DurationInputObject | null | undefined,
  format: string
): string {
  if (!timeOfDay) {
    return '';
  }
  const duration = moment.duration(timeOfDay);
  if (!duration.isValid()) {
    return '';
  }

  // Moment doesn't have a "time of day" data type, nor a formatter for
  // durations/intervals (other than ISO format). So the easiest way
  // to format a time of day, is to add it to a midnight datetime to
  // produce a datetime with the desired time of day, and then format
  // that.
  return moment.utc('1970-01-01T00:00:00Z').add(duration).format(format);
}

/**
 * Format a time of day (with NO DATE) into the format used by the back end.
 *
 * @param timeOfDay Represented as the duration since midnight, in any of
 * the formats that moment.duration() accepts.
 */
export function formatTimeofdayForBackendapi(
  timeOfDay: string | moment.DurationInputObject
): string {
  return _formatTimeofdayUsingMoment(timeOfDay, TIMEOFDAY_BACKEND_FORMAT);
}
const TIMEOFDAY_BACKEND_FORMAT = 'HH:mm:ss';

/**
 * Format a time of day (with NO DATE) into a human-readable format.
 *
 * @param timeOfDay Represented as the duration since midnight, in any of
 * the formats that moment.duration() accepts.
 */
export function formatTimeofdayForDisplay(
  timeOfDay: string | moment.DurationInputObject | null | undefined
): string {
  return _formatTimeofdayUsingMoment(timeOfDay, TIMEOFDAY_DISPLAY_FORMAT);
}
const TIMEOFDAY_DISPLAY_FORMAT = 'HH:mm';
