import React from 'react';
import classNames from 'classnames';
import { Trans, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import Loading from 'components/base/loading/loading';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { AlertDanger } from 'components/base/alert/alert';
import { ButtonDanger } from 'components/base/button/button';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ModalContent from 'components/base/modal/modalcontent';
import { Model } from '../../../util/backendapi/models/api.interfaces';
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
} from 'react-beautiful-dnd';
import { AddObsPointPanelView } from './addobspointpanelview';
import { errorToString } from 'util/backendapi/error';
import { BackButton } from 'components/base/back-button/BackButton';

export interface StoredListItems {
  loading: boolean;
  errorMessage: string | null;
  storedListId: number | null;
  storedListItems: Model.StoredListItemDecorated[];
}

export interface StateProps {
  loading: boolean;
  errorMessage: string | null;
  storedList: Model.PolymorphicStoredList | null;
  hasAddPermission: boolean;
  hasDeletePermission: boolean;
  observationPointOutputs: StoredListItems;
}
export type Props = StateProps & {
  onDragEnd: (result: DropResult) => void;
  onRemoveObsPoint: (id: number, hideModal: any) => void;
};

export class StoredListItemView extends React.Component<Props> {
  renderRemoveObsPointConfirmation(id: number, code: string, hideModal: any) {
    return (
      <ModalContent header={<Trans>Confirmation</Trans>}>
        <p>
          <Trans>
            Are you sure you want to remove <strong>{code}</strong> from this
            stored list? This action is not reversible.
          </Trans>
        </p>
        <ActionBlock className="text-right">
          <ButtonHideModal>
            <Trans>Cancel</Trans>
          </ButtonHideModal>
          <ButtonDanger
            onClick={() => this.props.onRemoveObsPoint(id, hideModal)}
          >
            <Trans>Yes, remove</Trans>
          </ButtonDanger>
        </ActionBlock>
      </ModalContent>
    );
  }
  render() {
    const {
      storedList,
      observationPointOutputs,
      hasAddPermission,
      hasDeletePermission,
    } = this.props;

    let loading = null;
    let errorMessage = null;
    let header = <Trans>Maintain Stored List</Trans>;
    let subHeader = null;
    if (this.props.loading || observationPointOutputs.loading) {
      loading = true;
    }
    if (this.props.errorMessage || observationPointOutputs.errorMessage) {
      if (this.props.errorMessage) {
        errorMessage = this.props.errorMessage;
      }
      if (observationPointOutputs.errorMessage) {
        errorMessage = this.props.errorMessage;
      }
    }

    let storedListOutputsTable = null;

    if (!loading) {
      if (storedList !== null) {
        subHeader = storedList.name;
      }

      storedListOutputsTable = (
        <DragDropContext onDragEnd={this.props.onDragEnd}>
          <div className="table-responsive">
            <table id="stored-list-item-table">
              <thead>
                <tr>
                  <th scope="col">
                    <Trans>Observation point</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Item description</Trans>
                  </th>
                  <th scope="col">
                    <Trans>Instrument type</Trans>
                  </th>
                  <th scope="col" className="action-icons">
                    <Trans>Actions</Trans>
                  </th>
                </tr>
              </thead>
              <Droppable droppableId="storedListItems-list">
                {(provided) => (
                  <tbody ref={provided.innerRef} {...provided.droppableProps}>
                    {observationPointOutputs.storedListItems.map(
                      (storedListItem, index) => {
                        const instrumentTypeItem =
                          storedListItem.observation_point.instrument_type.items.find(
                            (item) =>
                              item.item_number === storedListItem.item_number
                          );

                        return (
                          <Draggable
                            key={String(storedListItem.id)}
                            draggableId={String(storedListItem.id)}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              return (
                                <tr
                                  key={String(storedListItem.id)}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  className={classNames({
                                    draggable: true,
                                    dragging: snapshot.isDragging,
                                  })}
                                  data-storedlistitem-id={storedListItem.id}
                                >
                                  <td>
                                    {storedListItem.observation_point.code}
                                  </td>
                                  <td>{instrumentTypeItem?.description}</td>
                                  <td>{`${storedListItem.observation_point.instrument_type.code} - ${storedListItem.observation_point.instrument_type.name}`}</td>
                                  <td className="action-icons">
                                    {hasDeletePermission && (
                                      <ButtonShowModal
                                        name="remove-obspoint"
                                        iconOnly={true}
                                        iconType="icon-circle-minus"
                                        className="btn-link-panel"
                                        title={t`Remove`}
                                        modalContent={({ hideModal }) =>
                                          this.renderRemoveObsPointConfirmation(
                                            storedListItem.id,
                                            storedListItem.observation_point
                                              .code,
                                            hideModal
                                          )
                                        }
                                        modalProps={{
                                          className: 'modal modal-danger',
                                          overlayClassName: 'modal-centred',
                                        }}
                                      >
                                        <Trans>Remove</Trans>
                                      </ButtonShowModal>
                                    )}
                                  </td>
                                </tr>
                              );
                            }}
                          </Draggable>
                        );
                      }
                    )}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </table>
          </div>
        </DragDropContext>
      );
    }

    return (
      <PageStandard
        name="listRouteMarchObsPointOutputs"
        header={header}
        subHeader={subHeader}
      >
        <div className="page-content-header-with-back-button-wrapper">
          <BackButton defaultBackUrl="/stored-lists" />
          {loading && <Loading />}
          {hasAddPermission &&
            observationPointOutputs.storedListId &&
            storedList && (
              <div className="page-content-header">
                <ActionBlock className="text-right">
                  <ButtonShowModal
                    name="add-observation-point"
                    iconType="icon-plus"
                    modalContent={({ hideModal }) => (
                      <AddObsPointPanelView
                        hideModal={hideModal}
                        storedListId={
                          Number(observationPointOutputs.storedListId)!
                        }
                        storedListItems={
                          observationPointOutputs.storedListItems
                        }
                      />
                    )}
                  >
                    <Trans>Add observation point</Trans>
                  </ButtonShowModal>
                </ActionBlock>
              </div>
            )}
        </div>
        {!loading && storedListOutputsTable}
        {errorMessage && (
          <AlertDanger>{errorToString(errorMessage)}</AlertDanger>
        )}
      </PageStandard>
    );
  }
}
