import React from 'react';
import idleManager from 'react-idle-manager';
import { connect, ResolveThunks } from 'react-redux';
import { doLogout } from 'ducks/login';
import { FullState } from 'main/reducers';

// Timeout value in milliseconds.
const sessionTimeout = process.env.REACT_APP_SESSION_TIMEOUT;
const idleManagerOptions = {
  key: 'dms-frontend',
  idleAfter: Number(sessionTimeout) * 1000,
  timeoutAfter: 0,
};

type IdleManagerProps = {
  isTimedOut: boolean;
};
type DispatchProps = {
  doLogout: typeof doLogout;
};

type Props = IdleManagerProps & ResolveThunks<DispatchProps>;

/**
 * A component to log the user out if they're idle for too long. It needs to be
 * a class component in order to work with the `react-idle-manager` library.
 *
 * This component assumes it will only be mounted when the user is logged in.
 */
class IdleTimeoutManagerInner extends React.Component<Props> {
  static mapDispatchToProps: DispatchProps = {
    doLogout,
  };

  componentDidMount() {
    // It's unlikely that you'll already be idle as soon as this mounts, but
    // since idle state is shared across tabs, maybe it's possible?
    if (this.props.isTimedOut) {
      this.props.doLogout();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isTimedOut && this.props.isTimedOut) {
      this.props.doLogout();
    }
  }

  render() {
    // Logical component only.
    return null;
  }
}

export const IdleTimeoutManager = connect<
  undefined,
  DispatchProps,
  IdleManagerProps,
  FullState
>(
  undefined,
  IdleTimeoutManagerInner.mapDispatchToProps
)(
  idleManager(idleManagerOptions)(IdleTimeoutManagerInner)
) as any as React.ComponentType<{}> & {
  WrappedComponent: typeof IdleTimeoutManagerInner;
};

(IdleTimeoutManager as any).WrappedComponent = IdleTimeoutManagerInner;
