import React, { useMemo, useCallback } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter, Enum } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';
import {
  CommentsListView,
  commentsListColumns,
  commentsListFilters,
} from './CommentsListView';
import { commentListDuck } from 'ducks/comments/list/list';
import { useSelector, useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { selectLoggedInUser } from 'ducks/login';
import { NewCommentFormValues } from 'components/modules/comments-panel/new-comment-form/NewCommentFormView';
import {
  AreaComment_POST,
  SiteComment_POST,
  ObservationPointComment_POST,
} from 'util/backendapi/types/Model';
import { addComment } from 'ducks/comments/panel/list';

type OwnProps = RCPWithQueryParams<Filter.ReportsComments>;

export const CommentsListScreen = (ownProps: OwnProps) => {
  const dispatch = useDispatch();

  const columns = useMemo(() => {
    return commentsListColumns({
      deleteComment: EMPTY_FUNC,
      refreshList: EMPTY_FUNC,
      currentUsername: null,
      canEditAllComments: false,
      canEditOwnComments: false,
    });
  }, []);

  const reportProps = useReportState(
    ownProps,
    columns,
    commentsListFilters,
    commentListDuck,
    (state) => state.comments.list
  );
  const deleteComment = useCallback(
    async (id: number) => {
      await deleteApi(`/comments/${id}/`);
      reportProps.refreshList();
    },
    [reportProps]
  );

  const { refreshList } = reportProps;

  const createComment = useCallback(
    async (values: NewCommentFormValues, target?: number) => {
      if (!target) {
        return;
      }
      let comment:
        | AreaComment_POST
        | SiteComment_POST
        | ObservationPointComment_POST
        | undefined = undefined;
      switch (values.resourcetype) {
        case Enum.Comment_RESOURCE_TYPE.area:
          comment = {
            ...values,
            resourcetype: Enum.Comment_RESOURCE_TYPE.area,
            area: target,
          };
          break;

        case Enum.Comment_RESOURCE_TYPE.site:
          comment = {
            ...values,
            resourcetype: Enum.Comment_RESOURCE_TYPE.site,
            site: target,
          };
          break;
        case Enum.Comment_RESOURCE_TYPE.obsPoint:
          comment = {
            ...values,
            resourcetype: Enum.Comment_RESOURCE_TYPE.obsPoint,
            observation_point: target,
          };
          break;
      }
      if (!comment) {
        return;
      }
      await dispatch(addComment(comment));
      refreshList();
    },
    [dispatch, refreshList]
  );

  const currentUsername = useSelector(
    (state: FullState) => selectLoggedInUser(state)?.username ?? ''
  );

  const permissions = useSelector((state: FullState) => state.user.permissions);

  const props = {
    reportProps,
    createComment,
    deleteComment,
    currentUsername,
    permissions,
  };

  return <CommentsListView {...props} />;
};
