import deepEqual from 'lodash/isEqual';
import { RouteComponentProps } from 'react-router';
import { parseNumberQueryParamFromRouterProps } from '../routing';
import { Filter } from './models/api.interfaces';
import { createStructuredSelector, defaultMemoize } from 'reselect';

// The current pagination count limit on the back end.
// NOTE: The back end also sends this number to us as a response header,
// `Pagination-Count-Limit`. But because it's always the same value right now,
// it makes more sense to just use it as a constant on the front end.
export const PAGINATION_COUNT_LIMIT = 10000;

export const LIST_PAGE_SIZE_DEFAULT = 50;

/**
 * PaginationRequestData: Describes the range of records requested from the
 * backend.
 *
 * @param offset 0-based index of the first record requested
 * @param limit Maximum number of records to receive (may be less, e.g. if
 * there are 25 records and you request offset 20, limit 10, you will only
 * receive 5 records)
 */
export interface PaginationRequestData {
  readonly offset: number;
  readonly limit: number;
}

/**
 * PaginationResponseData: Describes the range of records received from the
 * backend, and the total number of records available.
 *
 * `start` and `end` are 0-based, (like `offset` in `PaginationRequestData`),
 * so the index of the last record will be `total-1`.
 *
 * There are two scenarios where you may receive no records, in which case
 * `start` and `end` will be `null`
 * - `total === 0`: No records matched your request
 * - `total > 0`: You requested an `offset` beyond the last record
 *
 * @param start 0-based index of the first received record, or `null` if response includes no records.
 * @param end 0-based index of the last received record, or `null` if response includes no records.
 * @param total Number of records available from the backend (used by frontend
 * to calculate how many "pages" are available))
 */
export interface PaginationResponseData {
  readonly start: number | null;
  readonly end: number | null;
  readonly total: number | null;
}

/**
 * PaginationMeta: Combined information about the pagination "page" requested
 * from the backend, and the records actually received from the backend.
 *
 * It's important to store these separately, so that the page can correctly
 * be displayed when you're waiting for a paginated request to return, or when
 * the the paginated response contains fewer records than requested.
 *
 * Best practice is something like this:
 *
 * - `pagination.requested` is equivalent to other request params; normally
 * it should be parsed directly from the current URL (or default values)
 * using `Pagination.parseFromRouterProps()`, and only needs to be stored in
 * Redux if you're storing the last request params for cancellation purposes.
 * - `pagination.received` should always be stored in Redux. It should be parsed
 * from the paginated response using `Pagination.fromBackendResponse()` and
 * should have the same lifetime in the store as the set of records received
 * along with it.
 *     - When switching pages, you usually want to keep the current records, and
 *         their `pagination.received`, in order to avoid too much UI jump caused by
 *         the whole table and pagination controls disappearing when the request starts
 *         and returning when the request finishes.
 *     - When you've received an error, or you're changing filters, or you've
 *         left and returned to a page, you usually want to delete the last
 *         set of records received. You should also delete `pagination.received`
 *         (set it to `null`) at the same time.
 *
 * Use the function `Pagination.fromRequestedReceived()` to combine the
 * `requested` and `received` objects into a single object, in your `mapStateToProps()`.
 * `Pagination.parseFromRouterProps()` and `Pagination.fromRequestedReceived()`
 * are both memoized so the objects returned by them should not break memoization
 * for child components.
 *
 * @param requested Describes the range of records requested from the backend
 * @param received Describes the range of records received from the backend,
 * and the total number of records available. Should be initially `null` when
 * no response received yet.
 */
export interface PaginationMeta {
  readonly requested: PaginationRequestData;
  readonly received: PaginationResponseData | null;
}

export type PaginationQueryParams = Filter.Pagination;

function currentPageIndex(pagination: PaginationMeta): number {
  if (pagination.requested.limit === 0) {
    return -1;
  }
  return Math.floor(pagination.requested.offset / pagination.requested.limit);
}

function pageCount(pagination: PaginationMeta): number | null {
  if (
    pagination.requested.limit === 0 ||
    !pagination.received ||
    pagination.received.total === null
  ) {
    return null;
  }

  return Math.ceil(pagination.received.total / pagination.requested.limit);
}

/**
 * Parse a `Content-Range` header into a Pagination object.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Range
 */
function fromContentRange(contentRange: string): null | PaginationResponseData {
  // NOTE: Unfortunately this explanation can't be in a jsdoc block because
  // it contains the literal `*/`, which would end the comment block. :-P
  //
  // The format of `Content-Range` is `items x-y/z`, or `items */z`
  // - x: index of first record in the response
  // - y: index of last record in the response (inclusive)
  // - z: total number of records available, or '*' if unknown
  //
  // `x` and `y` are 0-based indexes, which means the last record will have an
  // index of `z-1`
  //
  // If the response includes no records, it will have the form `items */z`,
  // with no `x` or `y` value. This can happen for two reasons:
  // - `z` is 0 : No records matched your request
  // - `z` > 0 : You requested an offset greater than `z-1`
  //
  // We represent `*/z` on the frontend with a `null` for `start` and `end`.
  //
  // We represent `x-y/*` with a `null` for `total`.
  const matchResult = contentRange.match(/^items (\*|(\d+)-(\d+))\/(\*|\d+)$/);

  if (matchResult) {
    const range = matchResult[1];
    const totalAsNumeric = parseInt(matchResult[4]);
    const total = Number.isNaN(totalAsNumeric) ? null : totalAsNumeric;

    if (range === '*') {
      return {
        start: null,
        end: null,
        total,
      };
    }

    let start = parseInt(matchResult[2]);
    let end = parseInt(matchResult[3]);

    if (
      Number.isNaN(start) ||
      Number.isNaN(end) ||
      end < start ||
      end >= totalAsNumeric
    ) {
      throw new Error(
        `Nonsensical pagination received from backend: ${contentRange}`
      );
    } else {
      return {
        start,
        end,
        total,
      };
    }
  }

  return null;
}

const fromOffsetTotalLimit = defaultMemoize(function (
  offset: number,
  total: number | null,
  limit: number = LIST_PAGE_SIZE_DEFAULT
): PaginationMeta {
  const requested = {
    offset,
    limit,
  };

  if (total === 0 || (total && offset >= total)) {
    return {
      requested,
      received: {
        start: null,
        end: null,
        total,
      },
    };
  }

  return {
    requested,
    received: {
      total,
      start: offset * limit,
      end: Math.min((offset + 1) * limit, total ?? Infinity) - 1,
    },
  };
});

/**
 * Just a memoized selector to make a PaginationMeta object from separate
 * "requested" and "received" objects, memoized using a deepEqual comparison.
 */
const fromRequestedReceived = defaultMemoize(
  (
    requested: PaginationRequestData,
    received: PaginationResponseData | null
  ) => ({
    requested,
    received,
  }),
  deepEqual
);

function fromBackendResponse(
  response: Response
): PaginationResponseData | null {
  const contentRangeHeader = response.headers.get('content-range');
  if (!contentRangeHeader) {
    return null;
  }
  return fromContentRange(contentRangeHeader);
}

/**
 * Parse the standard pagination query params (limit, offset) out of the React
 * Router props.
 *
 * @param routerProps
 * @param defaultPageSize
 */
const parseFromRouterProps = createStructuredSelector({
  limit: (
    routerProps: Pick<RouteComponentProps, 'location'>,
    defaultPageSize = LIST_PAGE_SIZE_DEFAULT
  ) =>
    parseNumberQueryParamFromRouterProps(routerProps, 'limit') ||
    defaultPageSize,
  offset: (routerProps: Pick<RouteComponentProps, 'location'>) =>
    parseNumberQueryParamFromRouterProps(routerProps, 'offset') || 0,
}) as (
  props: Pick<RouteComponentProps, 'location'>,
  defaultPageSize?: number
) => PaginationRequestData;

export const Pagination = {
  LIST_PAGE_SIZE_DEFAULT,
  currentPageIndex,
  pageCount,
  fromContentRange,
  fromBackendResponse,
  fromOffsetTotalLimit,
  fromRequestedReceived,
  parseFromRouterProps,
};
