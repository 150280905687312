import { deleteApi, getApi, getPaginated } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { Filter } from 'util/backendapi/models/api.interfaces';

export async function removeBatch(batchId: number): Promise<any> {
  return await deleteApi(`/readings-batches/${batchId}/`);
}

export const readingsBatchDuck = makeReportingDuck(
  'readingsBatch',
  (filters: Filter.ReportsReadingsBatches) =>
    getPaginated('/reports/readings-batches/', filters),
  () => getApi('/reports/readings-batches/info/')
);

export type ReadingsBatchListActions = ReportingDuckActions<
  typeof readingsBatchDuck
>;
