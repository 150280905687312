import React from 'react';
import {
  ChecksheetDataSourceBatch,
  ChecksheetDataSource,
} from 'util/backendapi/types/Model';
import { Trans } from '@lingui/macro';
import { formatDatetimeForDisplay } from 'util/dates';
import { Icon } from 'components/base/icon/icon';
import { Link } from 'react-router-dom';
import { DMSLink } from 'components/base/link/DMSLink';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { LinkIfHas } from 'components/logic/link-if-has-permission/LinkIfHas';

/**
 * Note: a checksheet instance makes a copy of its template's data sources
 * when the instance is created, so that the user-entered data in relation to
 * these won't be lost if the template is later edited.
 *
 * So this component should do its work entirely by looking at the
 * `ChecksheetDataSource` list from the _instance_, not from the _template_.
 * The only piece of information it needs from the template, is the time zone.
 */
interface ChecksheetDataSourceTableProps {
  dataSources: ChecksheetDataSource[];
  timeZone: string;
}

export function ChecksheetDataSourceTable(
  props: ChecksheetDataSourceTableProps
) {
  const { dataSources, timeZone } = props;

  return (
    <table>
      <thead>
        <tr>
          <th>
            <Trans>Route march</Trans>
          </th>
          <th>
            <Trans>Data logger</Trans>
          </th>
          <th>
            <Trans>Batch name</Trans>
          </th>
          <th>
            <Trans>Batch</Trans>
          </th>
          <th>
            <Trans>Observation date</Trans>
          </th>
          <th>
            <Trans>Route comment</Trans>
          </th>
          <th>
            <Trans>Rain last 4 days</Trans>
          </th>
          <th>
            <Trans>Errors</Trans>
          </th>
          <th>
            <Trans>Alarms</Trans>
          </th>
          <th>
            <Trans>Media</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {dataSources.map((dataSource) => {
          const batches: (ChecksheetDataSourceBatch | null)[] =
            dataSource.batches.length > 0 ? dataSource.batches : [null];

          return batches.map((batch) => (
            <tr key={dataSource.id}>
              <td>{dataSource.route_march?.code}</td>
              <td>{dataSource.data_logger?.logger_number}</td>
              <td>{batch?.name}</td>
              <td>
                {batch ? (
                  <DMSLink
                    to={`/check-readings/${batch.batch_number}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {batch.batch_number}
                  </DMSLink>
                ) : (
                  <em>
                    <Trans>No batch assigned</Trans>
                  </em>
                )}
              </td>
              <td>
                {batch?.latest_reading?.reading_datetime &&
                  formatDatetimeForDisplay(
                    batch.latest_reading.reading_datetime,
                    timeZone
                  )}
              </td>
              <td>
                {batch?.inspector_comments.length
                  ? batch.inspector_comments.map((comment) => (
                      <p key={`route-march-comment-${comment.id}`}>
                        {comment.content}
                      </p>
                    ))
                  : null}
              </td>
              <td>{batch?.rainfall}</td>
              <td>
                {batch ? (
                  batch?.reading_errors && batch.reading_errors > 0 ? (
                    <Link
                      to={`/readings-batches?batch_number=${batch.batch_number}`}
                    >
                      <Icon
                        type="icon-exclamation-solid"
                        className="checksheet-icon-error"
                      />{' '}
                      {batch.reading_errors}
                    </Link>
                  ) : (
                    0
                  )
                ) : null}
              </td>
              <td>
                {batch ? (
                  batch.status === Enum.ReadingsBatch_STATUS.qa_complete ? (
                    batch.alarms && batch.alarms > 0 ? (
                      <Link
                        to={`/alarm-reports/?readings_batch__batch_number=${batch.batch_number}`}
                      >
                        <Icon
                          type="icon-bell-solid"
                          className="checksheet-icon-alarm"
                        />{' '}
                        {batch.alarms}
                      </Link>
                    ) : (
                      0
                    )
                  ) : (
                    <Trans>N/A - unprocessed</Trans>
                  )
                ) : null}
              </td>
              <td>
                {batch &&
                  (batch.media === 0 ? (
                    0
                  ) : (
                    <LinkIfHas
                      permission={['can_view_media_report', 'can_view_media']}
                      to={`/media/?readings_batch=${batch.id}`}
                    >
                      <Icon
                        type="icon-multimedia"
                        className="checksheet-icon-media"
                      />{' '}
                      {batch.media}
                    </LinkIfHas>
                  ))}
              </td>
            </tr>
          ));
        })}
      </tbody>
    </table>
  );
}
