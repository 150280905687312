import React from 'react';
import {
  getStoredPlotSaveAsSettings,
  makeStoredPlotSaveAsInitialValues,
  StoredPlotSaveAsSettings,
  StoredPlotSaveAsValues,
  validateStoredPlotSaveAsValues,
} from './StoredPlotSaveAsModal';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { StoredCumulativePlotWithArea } from 'ducks/stored-plot/detail';
import { NewStoredPlotDefaults } from './stored-plot-edit-utils';
import {
  makeStoredPlotNameSectionInitialValues,
  StoredPlotNameSection,
  validateStoredPlotNameSection,
} from './sections/StoredPlotNameSection';
import {
  getObsPointItemIdent,
  ObsPointItemMenu,
  splitObsPointItemIdent,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { max, min, sortBy } from 'lodash';
import { Trans } from '@lingui/macro';
import { Form, Formik, FormikErrors } from 'formik';
import { showStoredPlotErrorsInFormik } from './StoredPlotEdit';
import { FormCard, FormCardSection } from 'components/base/card/card';
import { StoredPlotSaveCancelButtons } from './StoredPlotSaveCancelButtons';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { IntegerField } from 'components/base/form/integer-field/IntegerField';
import ActionBlock from 'components/base/actionblock/actionblock';
import {
  YearCheckboxesField,
  yearListFromRange,
} from 'components/base/form/year-checkboxes-field/YearCheckboxesField';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';

export type StoredCumulativePlotFormValues = StoredPlotSaveAsValues &
  Omit<Model.StoredCumulativePlot_POST, 'observation_point_items'> & {
    observation_point_item: string;
    start_year: string;
    end_year: string;
    year_options: SimpleSelectOption<number>[];
  };

export interface StoredCumulativePlotFormProps {
  storedPlot: StoredCumulativePlotWithArea | null;
  newPlotDefaults: NewStoredPlotDefaults | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  onSubmit: (
    values: Model.StoredCumulativePlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<any>;
  onCancel: () => void;
}

function makeInitialValues(
  storedPlot: StoredCumulativePlotWithArea | null,
  newPlotDefaults: NewStoredPlotDefaults | null
): StoredCumulativePlotFormValues {
  if (!storedPlot) {
    return {
      ...makeStoredPlotSaveAsInitialValues(),
      plot_type: Enum.PlotType.CUMULATIVE,
      name: newPlotDefaults?.name ?? '',
      area: 0,
      observation_point_item: '',
      start_year: '',
      end_year: '',
      time_periods: [],
      year_options: [],
    };
  }

  let observationPointItemIdent = '';

  const observationPointItems = storedPlot.observation_point_items;
  if (observationPointItems.length > 0) {
    observationPointItemIdent = getObsPointItemIdent(
      observationPointItems[0].observation_point,
      observationPointItems[0].item_number
    );
  }

  const startYear = String(min(storedPlot.time_periods) ?? '');
  const endYear = String(max(storedPlot.time_periods) ?? '');

  return {
    ...makeStoredPlotNameSectionInitialValues(storedPlot),
    ...makeStoredPlotSaveAsInitialValues(),
    plot_type: Enum.PlotType.CUMULATIVE,
    start_year: startYear,
    end_year: endYear,
    observation_point_item: observationPointItemIdent,
    time_periods: storedPlot.time_periods,
    year_options: yearListFromRange(startYear, endYear).map((year) => ({
      label: year,
      value: year,
    })),
  };
}

function validateForm(
  values: StoredCumulativePlotFormValues
): FormikErrors<StoredCumulativePlotFormValues> {
  let errors: FormikErrors<StoredCumulativePlotFormValues> = {
    ...validateStoredPlotNameSection(values),
    ...validateStoredPlotSaveAsValues(values),
  };

  if (!values.observation_point_item) {
    errors.observation_point_item = (
      <Trans>Please select an observation point.</Trans>
    ) as any;
  }

  if (!values.start_year || String(values.start_year).length !== 4) {
    errors.start_year = (<Trans>Must be a valid 4 digit year.</Trans>) as any;
  }

  if (!values.end_year || String(values.end_year).length !== 4) {
    errors.end_year = (<Trans>Must be a valid 4 digit year.</Trans>) as any;
  } else if (
    values.end_year &&
    values.start_year &&
    +values.start_year > +values.end_year
  ) {
    errors.end_year = (
      <Trans>Must be equal to or greater than start year.</Trans>
    ) as any;
  }

  if (values.time_periods.length === 0) {
    errors.time_periods = (
      <Trans>You must select at least one year.</Trans>
    ) as any;
  }

  return errors;
}

export function StoredCumulativePlotForm(props: StoredCumulativePlotFormProps) {
  const { storedPlot, areaOptions, newPlotDefaults } = props;

  const initialValues = makeInitialValues(storedPlot, newPlotDefaults);

  return (
    <Formik<StoredCumulativePlotFormValues>
      validate={validateForm}
      initialValues={initialValues}
      onSubmit={async (values, formik) => {
        const { observation_point, item_number } = splitObsPointItemIdent(
          values.observation_point_item
        )!;
        const observationPointItems = [{ observation_point, item_number }];

        const valuesForBackend: Model.StoredCumulativePlot_POST = {
          ...values,
          observation_point_items: observationPointItems,
          time_periods: sortBy(values.time_periods),
        };
        try {
          await props.onSubmit.call(
            null,
            valuesForBackend,
            getStoredPlotSaveAsSettings(values)
          );
        } catch (e) {
          formik.setSubmitting(false);
          showStoredPlotErrorsInFormik(formik, e, values);
        }
      }}
    >
      {(formik) => {
        return (
          <Form>
            {formik.status}
            <FormCard
              name="general"
              header={<Trans>General</Trans>}
              subHeader={
                <StoredPlotSaveCancelButtons {...props} formik={formik} />
              }
            >
              <StoredPlotNameSection
                areaOptions={areaOptions}
                plotType={Enum.PlotType.CUMULATIVE}
              />
              <FormCardSection
                name="time-period"
                header={<Trans>Time period</Trans>}
                fields={[
                  {
                    name: 'start-year',
                    label: <Trans>Start year</Trans>,
                    content: (
                      <>
                        <IntegerField name="start_year" maxLength={4} />
                        <FieldError name="start_year" />
                      </>
                    ),
                  },
                  {
                    name: 'end-year',
                    label: <Trans>End year</Trans>,
                    content: (
                      <>
                        <IntegerField name="end_year" maxLength={4} />
                        <FieldError name="end_year" />
                      </>
                    ),
                  },
                  {
                    name: 'time-periods',
                    label: <Trans>Included years</Trans>,
                    content: (
                      <>
                        <YearCheckboxesField
                          formik={formik}
                          name="time_periods"
                          startName="start_year"
                          endName="end_year"
                          yearOptionsName="year_options"
                        />
                        <FieldError name="time_periods" />
                      </>
                    ),
                  },
                ]}
              />
              <FormCardSection
                name="observation-points"
                header={<Trans>Observation points</Trans>}
                fields={[
                  {
                    name: 'observation-point-item',
                    label: <Trans>Observation point</Trans>,
                    content: (
                      <>
                        <ObsPointItemMenu
                          name="observation_point_item"
                          isMulti={false}
                        />
                        <FieldError name="observation_point_item" />
                      </>
                    ),
                  },
                ]}
              />
            </FormCard>
            <ActionBlock className="text-right">
              <StoredPlotSaveCancelButtons {...props} formik={formik} />
            </ActionBlock>
          </Form>
        );
      }}
    </Formik>
  );
}
