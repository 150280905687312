import React, { useMemo, useState, RefObject, useCallback } from 'react';
import { XYPlot, LineSeries, CustomSVGSeries } from 'react-vis';
import { LabelSeries } from 'components/plots/react-vis-hacks';
import CrosshairWithReticle from 'components/plots/crosshairwithreticle';
import { Trans } from '@lingui/macro';
import { scaleLinear, ScaleLinear } from 'd3-scale';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import {
  InstrumentType,
  SpatialPlotObservationPoint,
} from 'util/backendapi/types/Model';
import {
  calculatePlanViewOrigin,
  calculateSpatialPlotDomains,
  forceStayInBounds,
  ICON_MARKER_WATER_LEVEL,
  ICON_MARKER_OBSERVATION_WELL,
} from './spatial-plot-utils';
import { useSpatialPlotHover } from './useSpatialPlotHover';
import { useSpatialPlotResizeObserver } from './useSpatialPlotResizeObserver';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import { StoredSpatialPlanPlotWithArea } from 'ducks/stored-plot/detail';
import { selectSpatialPlotData } from 'ducks/plot/spatial';
import { createPortal } from 'react-dom';
import {
  SpatialPlotDeformationSurveyDataTable,
  SpatialPlotDataTable,
} from './SpatialPlotDataTable';
import { SpatialPlotObsPointPopup } from './SpatialPlotObsPointPopup';
import { Toggle } from 'components/base/form/toggle/Toggle';
import { showHideToggleOptions } from 'components/base/form/toggle-field/ToggleField';
import { Popup } from 'components/plots/popups/Popup';
import { forceSimulation, forceLink, SimulationLinkDatum } from 'd3-force';
import { seedPseudoRandNormalizedFloat } from 'util/misc';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { isTruthy } from 'util/validation';
import { forceEllipseCollide } from './forceEllipseCollide';
import { StoredSpatialPlanPlot_DATA_TABLE_STYLE } from 'util/backendapi/types/Enum';
import { ScaleRule } from './ScaleRule';
import {
  createReadingsLegendEntries,
  parseReadingsLegendSymbol,
  ReadingsLegend,
} from './ReadingsLegend';

// Because we draw the icons using fonts, they're actuall LabelSeries. But
// providing an IconSeries alias hopefully makes the code a little easier to read.
const IconSeries = LabelSeries;

const NO_PLOT_MARGIN = { top: 0, bottom: 0, left: 0, right: 0 };

const SPATIAL_PLAN_DEFAULT_MARKER_SYMBOL = ICON_MARKER_OBSERVATION_WELL;
const SPATIAL_PLAN_DEFAULT_MARKER_COLOR = 'yellow';

interface PlanViewPlotPoint extends SpatialPlotObservationPoint {
  code: string;
  fx?: number;
  fy?: number;
  height?: number;
  index: number;
  isIcon?: boolean;
  label: string | null;
  padding?: number;
  plotId?: string;
  width?: number;
  style?: React.CSSProperties;
  x: number;
  y: number;
  stackedObsPoints: (SpatialPlotObservationPoint & { label?: string | null })[];
}
interface PlanViewLabelPoint extends PlanViewPlotPoint {
  icon: PlanViewPlotPoint;
}

export function SpatialPlanPlot(props: {
  storedPlot: StoredSpatialPlanPlotWithArea;
  buttonsPortal?: RefObject<HTMLElement>;
}) {
  const { storedPlot } = props;

  const plotState = useShallowEqualSelector((state: FullState) =>
    selectSpatialPlotData(state, storedPlot.id)
  );

  if (plotState?.isLoading) {
    return <Loading />;
  } else if (plotState?.errorMessage) {
    return <AlertWarning>{plotState.errorMessage}</AlertWarning>;
  } else if (!plotState?.observationPoints || !storedPlot) {
    return null;
  }

  return (
    <SpatialPlanPlotView
      obsPoints={plotState.observationPoints as SpatialPlotObservationPoint[]}
      storedPlot={storedPlot}
      buttonsPortal={props.buttonsPortal}
    />
  );
}

export interface SpatialPlanPlotViewProps {
  storedPlot: StoredSpatialPlanPlotWithArea;
  obsPoints: SpatialPlotObservationPoint[];
  buttonsPortal?: RefObject<HTMLElement>;
  initialHeight?: number;
  dataTablePos?: {
    top: string;
    left: string;
  };
}

export function SpatialPlanPlotView(props: SpatialPlanPlotViewProps) {
  const { storedPlot, obsPoints } = props;

  const baseObsPoint = obsPoints.find(
    (op) => op.id === storedPlot.base_observation_point
  );
  if (
    baseObsPoint &&
    (baseObsPoint.nztm_easting === null || baseObsPoint.nztm_northing === null)
  ) {
    return (
      <AlertWarning>
        <Trans>
          Error: Base observation {baseObsPoint.code} does not have geographic
          location data.
        </Trans>
      </AlertWarning>
    );
  }

  return (
    <Inner
      {...props}
      baseObsPoint={baseObsPoint as InnerProps['baseObsPoint']}
    />
  );
}

type InnerProps = SpatialPlanPlotViewProps & {
  baseObsPoint:
    | (SpatialPlotObservationPoint & {
        nztm_easting: string;
        nztm_northing: string;
      })
    | undefined;
};

function Inner(props: InnerProps) {
  const {
    storedPlot,
    obsPoints,
    baseObsPoint,
    buttonsPortal,
    initialHeight,
    dataTablePos,
  } = props;

  const [xDomain, yDomain] = useMemo(() => {
    const origin = baseObsPoint
      ? calculatePlanViewOrigin(storedPlot, baseObsPoint)
      : null;
    return origin
      ? calculateSpatialPlotDomains(storedPlot, origin)
      : [null, null];
  }, [baseObsPoint, storedPlot]);

  const { ref, widthPx, heightPx } = useSpatialPlotResizeObserver(
    storedPlot,
    NO_PLOT_MARGIN,
    initialHeight
  );

  const instrumentTypes = useMemo(() => {
    let instrumentMap: Record<string, InstrumentType> = {};
    storedPlot.instrument_types.forEach((it) => {
      instrumentMap[it.code] = it;
    });
    return instrumentMap;
  }, [storedPlot.instrument_types]);

  const { obsPointIcons, obsPointLabels, labelIconLinks } = useMemo((): {
    obsPointIcons: PlanViewPlotPoint[];
    obsPointLabels: PlanViewLabelPoint[];
    labelIconLinks: Array<{ x: number | null; y: number | null }>;
  } => {
    if (!yDomain || !xDomain) {
      return {
        obsPointIcons: [],
        obsPointLabels: [],
        labelIconLinks: [],
      };
    }
    const obsPointsXY: PlanViewPlotPoint[] = obsPoints
      .filter(
        (op) =>
          op.nztm_easting !== null &&
          op.nztm_northing !== null &&
          +op.nztm_easting >= xDomain![0] &&
          +op.nztm_easting <= xDomain![1] &&
          +op.nztm_northing >= yDomain![0] &&
          +op.nztm_northing <= yDomain![1]
      )
      .map((op) => {
        const opConfigIdx = storedPlot.observation_points.findIndex(
          (opc) => opc.observation_point === op.id
        );
        const opConfig = storedPlot.observation_points[opConfigIdx];
        return {
          ...op,
          label: opConfig?.show_label ? opConfig?.label! : null,
          index: opConfigIdx,
          x: Number(op.nztm_easting) + Number(op.horizontal_offset),
          y: Number(op.nztm_northing) + Number(op.vertical_offset),
          stackedObsPoints: [],
        };
      });

    if (storedPlot.disperse_observation_point_labels) {
      const pxToM = (yDomain[1] - yDomain[0]) / heightPx;
      const iconHeight = 12 * pxToM;
      const labelHeight = 12 * pxToM;
      const labelCharWidth = labelHeight * 0.6;
      const padding = 10 * pxToM;
      // Length of the line linking the icon to the label
      const linkLength = (iconHeight + labelHeight + padding) / 2;

      // Note: D3 force layout will mutate the labels
      const obsPointLabels: PlanViewLabelPoint[] = [];
      const obsPointIcons: PlanViewPlotPoint[] = [];
      const links: SimulationLinkDatum<PlanViewPlotPoint>[] = [];

      for (let i = 0; i < obsPointsXY.length; i++) {
        const op = obsPointsXY[i];

        const { symbol, style } = parseReadingsLegendSymbol(
          instrumentTypes[op.instrument_type__code]?.readings_legend_symbol,
          SPATIAL_PLAN_DEFAULT_MARKER_SYMBOL,
          SPATIAL_PLAN_DEFAULT_MARKER_COLOR
        );

        const icon: PlanViewPlotPoint = {
          ...op,
          plotId: `${op.id}-icon`,
          label: symbol,
          style: style,
          fx: op.x,
          fy: op.y,
          isIcon: true,
          height: iconHeight,
          width: iconHeight,
          padding: 0,
          stackedObsPoints: [op],
        };
        obsPointIcons.push(icon);
        if (op.label) {
          obsPointLabels.push({
            ...op,
            y: op.y - linkLength,
            plotId: `${op.id}-label`,
            // A rectangle of width W and height H can be contained in an
            // ellipse of width W * sqrt(2) and height H * sqrt(2)
            height: labelHeight * Math.SQRT2,
            width: op.label.length * labelCharWidth * Math.SQRT2,
            isIcon: false,
            icon,
            padding: 0,
            stackedObsPoints: [op],
          });
          links.push({
            source: `${op.id}-icon`,
            target: `${op.id}-label`,
          });
        }
      }

      // Create our force simulation
      const simulation = forceSimulation<
        PlanViewPlotPoint,
        SimulationLinkDatum<PlanViewPlotPoint>
      >(obsPointLabels);

      // Prevent labels and icons from overlapping each other
      simulation.force('overlap', forceEllipseCollide().padding(padding));

      // Prevent labels from being pushed off of the plot.
      simulation.force(
        'stay in bounds',
        forceStayInBounds().xDomain(xDomain).yDomain(yDomain)
      );

      // Run it a few ticks without the icons, to allow overlapping labels to fly
      // off in the most convenient direction.
      simulation.stop();
      simulation.tick(5);

      // Add the icons.
      simulation.nodes(obsPointIcons.concat(obsPointLabels));

      // Try to keep labels near their icons
      const forceLinkInstance = forceLink<
        PlanViewPlotPoint,
        SimulationLinkDatum<PlanViewPlotPoint>
      >(links)
        .id((d) => d.plotId!)
        .distance(linkLength)
        .strength(0.001);

      simulation.force('label icon connection', forceLinkInstance);
      forceLinkInstance.initialize(
        links as any,
        seedPseudoRandNormalizedFloat(1)
      );

      simulation.tick(300);

      return {
        obsPointIcons,
        obsPointLabels,
        labelIconLinks: forceLinkInstance.links().flatMap((link) => [
          {
            x: (link.source as any).x,
            y: (link.source as any).y,
          },
          {
            x: (link.target as any).x,
            y: (link.target as any).y,
          },
          { x: null, y: null },
        ]),
      };
    } else {
      // If there are multiple observation points in the same location, stack
      // their labels.
      const groupedByLocation: PlanViewPlotPoint[] = Object.entries(
        groupBy(obsPointsXY, (op) => `${Math.floor(op.x)},${Math.floor(op.y)}`)
      ).map(([, obsPointsInThisLocation]) => {
        return {
          ...obsPointsInThisLocation[0],
          stackedObsPoints: sortBy(
            obsPointsInThisLocation,
            (op) => op.port_rl,
            (op) => op.index
          ),
        };
      });

      return {
        obsPointLabels: groupedByLocation
          .filter((point) => point.stackedObsPoints.some((op) => op.label))
          .map((p) => ({
            ...p,
            icon: p,
          })),
        obsPointIcons: groupedByLocation.map((p) => {
          const instrumentType =
            instrumentTypes[p.stackedObsPoints[0].instrument_type__code];
          const { symbol, style } = parseReadingsLegendSymbol(
            instrumentType?.readings_legend_symbol,
            SPATIAL_PLAN_DEFAULT_MARKER_SYMBOL,
            SPATIAL_PLAN_DEFAULT_MARKER_COLOR
          );
          return {
            ...p,
            label: symbol,
            style: style,
          };
        }),
        labelIconLinks: [],
      };
    }
  }, [
    heightPx,
    obsPoints,
    storedPlot.disperse_observation_point_labels,
    storedPlot.observation_points,
    xDomain,
    yDomain,
    instrumentTypes,
  ]);

  const [showDataTable, setShowDataTable] = useState<boolean>(
    storedPlot.show_data_table
  );

  const hover = useSpatialPlotHover<PlanViewPlotPoint>();

  const handleReticleClick = useCallback(
    (point: PlanViewPlotPoint) => {
      hover.setSelectedPoint.call(null, point);
      hover.setIsHoverable.call(null, false);
    },
    [hover.setSelectedPoint, hover.setIsHoverable]
  );

  const handleLabelClick = useCallback(
    (label: PlanViewLabelPoint) => {
      hover.onNearestXY.call(null, label.icon);
      hover.setSelectedPoint.call(null, label.icon);
      hover.setIsHoverable.call(null, false);
    },
    [hover.onNearestXY, hover.setIsHoverable, hover.setSelectedPoint]
  );

  if (!baseObsPoint) {
    // Because XYPlot doesn't render anything if it doesn't have any data to plot
    // render without it when there are no observation points
    return (
      <div className="non-cropping-plot-wrapper">
        <div className="plot-area" ref={ref}>
          <ManualSVGContainer
            {...props}
            innerWidth={widthPx}
            innerHeight={heightPx}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {buttonsPortal?.current &&
        createPortal(
          <Toggle
            inline={true}
            className="radio-toggle-data-table"
            options={showHideToggleOptions}
            name="show-data-table-radio"
            label={<Trans>Data table:</Trans>}
            defaultValue={showDataTable}
            onChange={setShowDataTable}
          />,
          buttonsPortal.current
        )}

      <div className="non-cropping-plot-wrapper">
        <div className="plot-area" ref={ref}>
          <XYPlot
            height={heightPx}
            width={widthPx}
            xDomain={xDomain}
            yDomain={yDomain}
            margin={NO_PLOT_MARGIN}
          >
            <ManualSVGContainer {...props} />
            {storedPlot.disperse_observation_point_labels && (
              <LineSeries data={labelIconLinks} getNull={(p) => p.x !== null} />
            )}
            <IconSeries
              data={obsPointIcons}
              labelAnchorX="middle"
              labelAnchorY="central"
              className="spatial-plan-obs-point-icon"
              onNearestXY={!hover.selectedPoint ? hover.onNearestXY : undefined}
            />
            {storedPlot.disperse_observation_point_labels ? (
              <LabelSeries
                data={obsPointLabels}
                className="spatial-plan-obs-point-text"
                labelAnchorX="middle"
                labelAnchorY="central"
                style={!hover.selectedPoint ? { cursor: 'pointer' } : undefined}
                onValueClick={
                  !hover.selectedPoint ? handleLabelClick : undefined
                }
                // TODO: It'd be good to also make it so that when you hover over
                // the label, it highlights the associated icon. But we'd have to
                // use `onValueMouseEnter()` and `onValueMouseLeave()` for that,
                // and those do not play well with our reticle.
              />
            ) : (
              <CustomSVGSeries
                data={obsPointLabels}
                customComponent={(point) => {
                  const labels = point.stackedObsPoints
                    .map((p) => p.label)
                    .filter(isTruthy);
                  return (
                    <text
                      dominantBaseline="text-before-edge"
                      className="spatial-plan-obs-point-text rv-xy-plot__series--label-text"
                      textAnchor="start"
                      style={
                        !hover.selectedPoint ? { cursor: 'pointer' } : undefined
                      }
                      onClick={
                        !hover.selectedPoint
                          ? () => handleLabelClick(point)
                          : undefined
                      }
                    >
                      {labels.map((label, idx) => (
                        <tspan key={idx} x={0} dy={idx === 0 ? 0 : '1.3em'}>
                          {label}
                        </tspan>
                      ))}
                    </text>
                  );
                }}
              />
            )}
            {hover.hoverPoint && (
              <CrosshairWithReticle<PlanViewPlotPoint>
                showVertical={false}
                showHorizontal={false}
                values={[hover.hoverPoint]}
                onReticleClick={handleReticleClick}
              />
            )}
            {hover.selectedPoint && (
              <Popup point={hover.selectedPoint}>
                <SpatialPlotObsPointPopup
                  onClose={hover.onClosePopup}
                  observationPoints={hover.selectedPoint.stackedObsPoints}
                />
              </Popup>
            )}
          </XYPlot>
        </div>
        {showDataTable && (
          <div
            style={{
              position: 'absolute',
              top: `${dataTablePos?.top ?? `20px`}`,
              left: `${dataTablePos?.left ?? `20px`}`,
            }}
          >
            {storedPlot.data_table_style ===
            StoredSpatialPlanPlot_DATA_TABLE_STYLE.DEFORMATION_SURVEY ? (
              <SpatialPlotDeformationSurveyDataTable
                storedPlot={storedPlot}
                obsPoints={obsPoints}
              />
            ) : (
              <SpatialPlotDataTable
                storedPlot={storedPlot}
                obsPoints={obsPoints}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

/**
 * A container function to render low-level SVG elements that can't be handled
 * very gracefully by a React-Vis series. (Although it would be possible to
 * do it hackily with a CustomSVGSeries that has a single data point at the
 * upper-left of the plot.)
 *
 * @param props
 */
function ManualSVGContainer(
  props: SpatialPlanPlotViewProps & {
    // React-Vis will automagically pass these props in via child prop
    // rewriting.
    innerWidth?: number;
    innerHeight?: number;
    xRange?: [number, number];
    yRange?: [number, number];
  }
) {
  const { storedPlot, innerWidth, innerHeight, xRange, yRange } = props;

  const { xScalePaperspace, yScalePaperspace } = useMemo(
    () => ({
      xScalePaperspace: xRange
        ? scaleLinear()
            .domain([0, +storedPlot.paperspace_width])
            .range(xRange)
        : null,
      yScalePaperspace: yRange
        ? scaleLinear()
            .domain([0, +storedPlot.paperspace_height])
            .range(yRange)
        : null,
    }),
    [storedPlot.paperspace_height, storedPlot.paperspace_width, xRange, yRange]
  );

  const readingsLegendEntries = useMemo(() => {
    const additionalEntries =
      storedPlot.lake_level_paperspace_x ||
      storedPlot.tailwater_level_paperspace_x
        ? [
            {
              title: 'Current Lake and Tailwater levels',
              symbol: ICON_MARKER_WATER_LEVEL,
              style: { fill: 'white' },
            },
          ]
        : undefined;

    return createReadingsLegendEntries(
      storedPlot.instrument_types,
      SPATIAL_PLAN_DEFAULT_MARKER_SYMBOL,
      SPATIAL_PLAN_DEFAULT_MARKER_COLOR,
      additionalEntries
    );
  }, [storedPlot]);

  return (
    <>
      {/* The plot's background images using normal <img /> tag */}
      {/* The SVG <image href /> tag will not render in SSR */}
      {/* Need to wrap inside SVG otherwise it wont render correctly in SSR */}
      <svg x={0} y={0} width={innerWidth} height={innerHeight}>
        <foreignObject x="0" y="0" width="100%" height="100%">
          <img src={storedPlot.base_layer_image} width="100%" alt="" />
        </foreignObject>
      </svg>
      {storedPlot.additional_layer_image && (
        <svg x={0} y={0} width={innerWidth} height={innerHeight}>
          <foreignObject x="0" y="0" width="100%" height="100%">
            <img src={storedPlot.additional_layer_image!} width="100%" alt="" />
          </foreignObject>
        </svg>
      )}
      {/* Insert the lake level and tailwater level icons */}
      <WaterLevelIcon
        label={<Trans>Lake</Trans>}
        obsPointId={storedPlot.lake_level_observation_point}
        paperspaceX={storedPlot.lake_level_paperspace_x}
        paperspaceY={storedPlot.lake_level_paperspace_y}
        allObsPoints={props.obsPoints}
        xScalePaperspace={xScalePaperspace}
        yScalePaperspace={yScalePaperspace}
      />
      <WaterLevelIcon
        label={<Trans>Tail</Trans>}
        obsPointId={storedPlot.tailwater_level_observation_point}
        paperspaceX={storedPlot.tailwater_level_paperspace_x}
        paperspaceY={storedPlot.tailwater_level_paperspace_y}
        allObsPoints={props.obsPoints}
        xScalePaperspace={xScalePaperspace}
        yScalePaperspace={yScalePaperspace}
      />
      <ScaleRule {...props} />
      <ReadingsLegend {...props} legendEntries={readingsLegendEntries} />
    </>
  );
}
// React-Vis requires this attribute to be present.
ManualSVGContainer.requiresSVG = true;

/**
 * Render a single "water level" icon (Lake or Tail)
 *
 * This places the water level icon centered on the paperspace coordinates,
 * and puts the label and the reading value on center-aligned text lines
 * below it.
 *
 * This cannot easily be done using conventional ReactVis "Series" components,
 * because we want to target a particular location in pixel coordinates,
 * rather than in "domain" coordinates.
 *
 * @param props
 */
function WaterLevelIcon(props: {
  label: React.ReactElement;
  obsPointId: number | null;
  paperspaceX: string | null;
  paperspaceY: string | null;
  xScalePaperspace: ScaleLinear<number, number> | null;
  yScalePaperspace: ScaleLinear<number, number> | null;
  allObsPoints: SpatialPlotObservationPoint[];
}) {
  const {
    allObsPoints,
    obsPointId,
    paperspaceX,
    paperspaceY,
    xScalePaperspace: xScale,
    yScalePaperspace: yScale,
    label,
  } = props;

  if (
    obsPointId === null ||
    paperspaceX === null ||
    paperspaceY === null ||
    xScale === null ||
    yScale === null
  ) {
    return null;
  }

  const waterLevel =
    allObsPoints.find((op) => op.id === obsPointId)?.reading
      ?.adjusted_reading_entries[0]?.value ?? '-';

  return (
    <g
      transform={`translate(${xScale(+paperspaceX)} ${yScale(+paperspaceY)})`}
      dominantBaseline="central"
      textAnchor="middle"
    >
      <text className="spatial-plan-lake-tail-icon">
        {ICON_MARKER_WATER_LEVEL}
      </text>
      <text className="spatial-plan-lake-tail-label" y="1.2em">
        {label}
      </text>
      <text className="spatial-plan-lake-tail-label" y="2.4em">
        {waterLevel}
      </text>
    </g>
  );
}
