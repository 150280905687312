import { DMSRoutes } from 'main/routes';
import { matchPath } from 'react-router-dom';

export function isValidDMSRoute(url: string) {
  if (!url || !url.startsWith('/')) {
    return false;
  }

  const pathname = url.split('?')[0];

  const allPaths: string[] = Object.keys(DMSRoutes).map(
    (key) => DMSRoutes[key].path
  );

  return allPaths.some((path) => {
    const match = matchPath(pathname, {
      path,
      exact: true,
      strict: false,
    });
    return Boolean(match);
  });
}
