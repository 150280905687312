import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { getApi, getPaginated } from 'util/backendapi/fetch';
import { Filter } from 'util/backendapi/models/api.interfaces';

export const inspectionReadingDuck = makeReportingDuck(
  'inspectionReading',
  (filters: Filter.ReportsInspectionReadings) =>
    getPaginated('/reports/inspection-readings/', filters),
  () => getApi('/reports/inspection-readings/info/')
);

export type ReadingsListActions = ReportingDuckActions<
  typeof inspectionReadingDuck
>;
