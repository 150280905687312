import { getApi, getPaginated } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { Filter } from 'util/backendapi/models/api.interfaces';

export const alarmParameterDuck = makeReportingDuck(
  'alarmParameter',
  (filters: Filter.ReportsAlarmParameters) =>
    getPaginated('/reports/alarm-parameters/', filters),
  () => getApi('/reports/alarm-parameters/info/')
);

export type AlarmParameterListActions = ReportingDuckActions<
  typeof alarmParameterDuck
>;
