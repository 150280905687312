import React, { useState, useEffect } from 'react';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, Form } from 'formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { useDispatch } from 'react-redux';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { updateChecksheetDataSources } from 'ducks/checksheet-instance/edit';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields, errorToString } from 'util/backendapi/error';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import moment from 'moment-timezone';
import { getApi } from 'util/backendapi/fetch';
import { formatDatetimeForDisplay } from 'util/dates';
import orderBy from 'lodash/orderBy';
import mapValues from 'lodash/mapValues';
import groupBy from 'lodash/groupBy';
import { FetcherState } from 'hooks/use-get-api';
import Loading from 'components/base/loading/loading';
import { AlertWarning } from 'components/base/alert/alert';
import { ChecksheetDataSource_PATCH } from 'util/backendapi/types/Model';
import { isTruthy } from 'util/validation';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { isEqual } from 'lodash';

interface DataLoggerSourceValues {
  id: number;
  batch: number | null;
}

type FormValues = {
  routeMarchDataSources: ChecksheetDataSource_PATCH[];
  dataLoggerDataSources: DataLoggerSourceValues[];
  otherBatches: number[] | null;
};

interface Props extends ModalContentProps {
  checksheetInstanceId: number;
  dataSources: Model.ChecksheetDataSource[];
  canAddOtherBatches: boolean;
}

export function makeChecksheetBatchOptions(
  batches: Model.ListReadingsBatch[]
): SimpleSelectOption<number>[] {
  return orderBy(batches, [(b) => b.created], ['desc']).map((b) => ({
    value: b.id,
    label: `${formatDatetimeForDisplay(b.created)} (${b.batch_number})`,
  }));
}

async function fetchMenuOptions(
  checksheetInstanceId: number,
  dataSources: Model.ChecksheetDataSource[]
) {
  const routeMarches = dataSources
    .map((ds) => ds.route_march?.id)
    .filter(isTruthy);
  const routeMarchBatches =
    routeMarches.length > 0
      ? [
          ...(await getApi('/readings-batches/', {
            route_march__in: routeMarches,
            created__gte: moment().subtract(1, 'year').toISOString(),
            checksheet_data_source__instance__isnull: true,
            ordering: ['route_march_code', '-created'],
          })),
          // Currently selected batches
          ...(await getApi('/readings-batches/', {
            route_march__in: routeMarches,
            checksheet_data_source__instance: checksheetInstanceId,
            ordering: ['route_march_code', '-created'],
          })),
        ]
      : [];
  const routeMarchBatchOptions: Record<number, SimpleSelectOption<number>[]> =
    mapValues(
      groupBy(
        routeMarchBatches.filter((rmb) => rmb.route_march),
        (rmb) => rmb.route_march!.id
      ),
      makeChecksheetBatchOptions
    );

  const dataLoggers = dataSources
    .map((ds) => ds.data_logger?.id)
    .filter(isTruthy);
  const dataLoggerBatchOptions = Object.fromEntries(
    await Promise.all(
      dataLoggers.map(
        async (
          dataLoggerId
        ): Promise<[number, SimpleSelectOption<number>[]]> => [
          dataLoggerId,
          makeChecksheetBatchOptions([
            ...(await getApi('/readings-batches/', {
              data_logger__in: [dataLoggerId],
              offset: 0,
              limit: 100,
              checksheet_data_source__instance__isnull: true,
              ordering: ['-created'],
            })),
            // Currently selected batches
            ...(await getApi('/readings-batches/', {
              data_logger__in: [dataLoggerId],
              checksheet_data_source__instance: checksheetInstanceId,
              ordering: ['-created'],
            })),
          ]),
        ]
      )
    )
  );

  return {
    routeMarchBatchOptions,
    dataLoggerBatchOptions,
  };
}

export function AssignBatchesModal(props: Props) {
  const dispatch = useDispatch();
  const { checksheetInstanceId, dataSources } = props;

  const routeMarches = dataSources.filter((ds) => ds.route_march);
  const dataLoggers = dataSources.filter((ds) => ds.data_logger);
  const existingOtherBatchesDataSource = dataSources.find(
    (ds) => ds.route_march === null && ds.data_logger === null
  );
  const initialValues: FormValues = {
    routeMarchDataSources: routeMarches.map(({ id, batches }) => ({
      id,
      batches: batches.map(({ id }) => id),
      instance: checksheetInstanceId,
    })),
    dataLoggerDataSources: dataLoggers.map(({ id, batches }) => ({
      id,
      batch: batches.length > 0 ? batches[0].id : null,
      instance: checksheetInstanceId,
    })),
    otherBatches: existingOtherBatchesDataSource
      ? existingOtherBatchesDataSource.batches.map(({ id }) => id)
      : null,
  };

  const [menuOptions, setMenuOptions] = useState<
    FetcherState<{
      routeMarchBatchOptions: Record<number, SimpleSelectOption<number>[]>;
      dataLoggerBatchOptions: Record<number, SimpleSelectOption<number>[]>;
    }>
  >({
    isError: false,
    isLoading: true,
    data: null,
  });
  useEffect(() => {
    setMenuOptions({
      isError: false,
      isLoading: true,
      data: null,
    });

    fetchMenuOptions(checksheetInstanceId, dataSources)
      .then((data) =>
        setMenuOptions({
          isError: false,
          isLoading: false,
          data,
        })
      )
      .catch((e) =>
        setMenuOptions({
          isError: true,
          isLoading: false,
          error: errorToString(e),
          data: null,
        })
      );
  }, [checksheetInstanceId, dataSources]);

  const header = <Trans>Assign batches</Trans>;

  if (menuOptions.isLoading || !menuOptions.data) {
    return (
      <ModalContent header={header}>
        <Loading />
      </ModalContent>
    );
  }
  if (menuOptions.isError) {
    return (
      <ModalContent header={header}>
        <AlertWarning>{menuOptions.error}</AlertWarning>
      </ModalContent>
    );
  }

  const { routeMarchBatchOptions, dataLoggerBatchOptions } = menuOptions.data;

  return (
    <ModalContent header={header}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, formik) => {
          try {
            // Convert data logger source batch to an array with one or
            // zero elements and recombine with route march data sources
            const dataSources = values.routeMarchDataSources.concat(
              values.dataLoggerDataSources.map(
                (ds): ChecksheetDataSource_PATCH => {
                  return {
                    id: ds.id,
                    batches: ds.batch !== null ? [ds.batch] : [],
                    instance: checksheetInstanceId,
                  };
                }
              )
            );
            const dataSourcesToUpdate = dataSources.filter((ds) => {
              if (
                existingOtherBatchesDataSource &&
                ds.id === existingOtherBatchesDataSource.id
              ) {
                return false;
              }
              return props.dataSources.some((eds) => {
                const existingDs = {
                  id: eds.id,
                  batches: eds.batches.map(({ id }) => id),
                };
                const newDs = {
                  id: ds.id,
                  batches: ds.batches,
                };

                return !isEqual(existingDs, newDs);
              });
            });

            if (values.otherBatches) {
              let otherBatchesDataSource: ChecksheetDataSource_PATCH = {
                batches: values.otherBatches,
                instance: checksheetInstanceId,
              };

              if (existingOtherBatchesDataSource) {
                otherBatchesDataSource.id = existingOtherBatchesDataSource.id;
              }
              dataSourcesToUpdate?.push(otherBatchesDataSource);
            }
            await dispatch(
              updateChecksheetDataSources(
                checksheetInstanceId,
                dataSourcesToUpdate
              )
            );
            props.hideModal();
          } catch (e) {
            showErrorsInFormik(formik, e, getExpectedFields(values));
            formik.setSubmitting(false);
          }
        }}
      >
        {(formik) => {
          return (
            <Form>
              {formik.status}
              {routeMarches.length > 0 ? (
                <FormSection label={<Trans>Route marches</Trans>}>
                  {routeMarches.map((routeMarchDataSource, idx) => {
                    const name = `routeMarchDataSources.${idx}.batches`;
                    const fieldId = `route-march-${routeMarchDataSource.route_march?.id}`;
                    return (
                      <FormItem
                        key={name}
                        fieldId={fieldId}
                        label={routeMarchDataSource.route_march?.code}
                      >
                        <SimpleSelectField<number, true>
                          placeholder={<Trans>Select a batch</Trans>}
                          id={fieldId}
                          name={name}
                          isMulti={true}
                          options={
                            routeMarchBatchOptions[
                              routeMarchDataSource.route_march!.id
                            ]
                          }
                        />
                        <FieldError name={name} />
                      </FormItem>
                    );
                  })}
                </FormSection>
              ) : null}
              {dataLoggers.length > 0 ? (
                <FormSection label={<Trans>Data loggers</Trans>}>
                  {dataLoggers.map((dataLoggerDataSource, idx) => {
                    const name = `dataLoggerDataSources.${idx}.batch`;
                    const fieldId = `data-logger-${dataLoggerDataSource.data_logger?.id}`;
                    return (
                      <FormItem
                        key={name}
                        fieldId={fieldId}
                        label={dataLoggerDataSource.data_logger?.logger_number}
                      >
                        <SimpleSelectField<number, false>
                          id={fieldId}
                          placeholder={<Trans>Select a batch</Trans>}
                          name={name}
                          isMulti={false}
                          options={
                            dataLoggerBatchOptions[
                              dataLoggerDataSource.data_logger!.id
                            ]
                          }
                        />
                        <FieldError name={name} />
                      </FormItem>
                    );
                  })}
                </FormSection>
              ) : null}
              {props.canAddOtherBatches ? (
                <FormSection label={<Trans>Other batches</Trans>}>
                  <AsyncSimpleSelectField<number, true>
                    name="otherBatches"
                    isMulti={true}
                    onSearch={async (searchText: string) =>
                      makeChecksheetBatchOptions(
                        await getApi('/readings-batches/', {
                          offset: 0,
                          limit: 100,
                          ordering: ['-created'],
                          batch_number__startswith: searchText,
                        })
                      )
                    }
                    loadDefaults={async (values: number[]) =>
                      makeChecksheetBatchOptions(
                        await getApi('/readings-batches/', {
                          offset: 0,
                          limit: 100,
                          ordering: ['-created'],
                          id__in: values,
                        })
                      )
                    }
                  />
                </FormSection>
              ) : null}
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          );
        }}
      </Formik>
    </ModalContent>
  );
}
