import React, { useEffect, useMemo } from 'react';
import sortBy from 'lodash/sortBy';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, t } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button, { ButtonPrimary } from 'components/base/button/button';
import {
  Field,
  ErrorMessage,
  Form,
  Formik,
  FormikErrors,
  FormikHelpers,
} from 'formik';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { ClientDetailState } from 'ducks/client/detail';
import Loading from 'components/base/loading/loading';
import ModalContent from 'components/base/modal/modalcontent';
import { FormItem } from 'components/base/form/FormItem';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { useIsMounted } from 'util/hooks';
import { I18n } from '@lingui/react';
import { getExpectedFields } from 'util/backendapi/error';

export type ClientEditFormValues = ForPostOrPut<Omit<Model.Client, 'users'>>;

export type ClientEditFormViewProps = Merge<
  ClientDetailState,
  {
    allUsers: Model.User[];
    onSubmit: (id: null | number, values: ClientEditFormValues) => void;
    onCancel: () => void;
    onUnmount: () => void;
  }
>;

const validate = (values: ClientEditFormValues) => {
  let errors: FormikErrors<ClientEditFormValues> = {};
  if (!values.name) {
    errors.name = (<Trans>Name is required.</Trans>) as any;
  }
  return errors;
};

export const ClientEditFormView = ({
  loading,
  client,
  clientId,
  allUsers,
  onSubmit,
  onUnmount,
  onCancel,
}: ClientEditFormViewProps) => {
  const isMounted = useIsMounted();

  useEffect(() => {
    return () => {
      onUnmount();
    };
  }, [onUnmount]);

  const usersMenuItems = useMemo(() => {
    const sortedAvailableUsers = sortBy(allUsers, ['profile.name', 'username']);
    return sortedAvailableUsers.map((user) => ({
      value: user.id,
      label: `${user.profile.name} (${user.username})`,
    }));
  }, [allUsers]);

  const header = clientId ? (
    <Trans>Edit client</Trans>
  ) : (
    <Trans>Create client</Trans>
  );

  if (loading) {
    return (
      <ModalContent header={header}>
        <Loading />
      </ModalContent>
    );
  }

  const formInitialValues: ClientEditFormValues = client
    ? {
        full_name: client.full_name || '',
        name: client.name || '',
        office_phone: client.office_phone || '',
        postal_address: client.postal_address || '',
        primary_contact: client.primary_contact || 0,
        dms_contact: client.dms_contact || 0,
        secondary_dms_contacts: client.secondary_dms_contacts || [],
      }
    : {
        full_name: '',
        name: '',
        office_phone: '',
        postal_address: '',
        primary_contact: 0,
        dms_contact: 0,
        secondary_dms_contacts: [],
      };

  return (
    <ModalContent header={header}>
      <Formik
        initialValues={formInitialValues}
        onSubmit={async (
          values: ClientEditFormValues,
          formik: FormikHelpers<ClientEditFormValues>
        ) => {
          formik.setStatus(undefined);

          const valuesToSubmit = { ...values };

          if (!valuesToSubmit.primary_contact) {
            delete valuesToSubmit.primary_contact;
          }

          if (!valuesToSubmit.dms_contact) {
            delete valuesToSubmit.dms_contact;
          }

          try {
            await onSubmit(clientId, valuesToSubmit);
          } catch (error) {
            if (!isMounted()) {
              return;
            }
            formik.setSubmitting(false);
            showErrorsInFormik(formik, error, getExpectedFields(values));
          }
        }}
        validate={validate}
      >
        {(formikBag) => (
          <I18n>
            {({ i18n }) => (
              <Form>
                <fieldset>
                  <FormItem
                    fieldId="clienteditform-name"
                    label={<Trans>Name</Trans>}
                  >
                    <>
                      <Field
                        name="name"
                        id="clienteditform-name"
                        placeholder={i18n._(t`Short name`)}
                      />
                      <ErrorMessage name="name" component={ErrorNotice} />
                    </>
                  </FormItem>
                  <FormItem
                    fieldId="clienteditform-full_name"
                    label={<Trans>Full name</Trans>}
                  >
                    <>
                      <Field
                        name="full_name"
                        id="clienteditform-full_name"
                        placeholder={i18n._(t`Full name`)}
                      />
                      <ErrorMessage name="full_name" component={ErrorNotice} />
                    </>
                  </FormItem>
                  <FormItem
                    fieldId="clienteditform-primary_contact"
                    label={<Trans>Primary contact person</Trans>}
                  >
                    <>
                      <SimpleSelectField
                        name="primary_contact"
                        id="clienteditform-primary_contact"
                        options={usersMenuItems}
                        isDisabled={formikBag.isSubmitting}
                        placeholder={i18n._(t`Select a person`)}
                      />
                      <ErrorMessage
                        name="primary_contact"
                        component={ErrorNotice}
                      />
                    </>
                  </FormItem>
                  <FormItem
                    fieldId="clienteditform-dms_contact"
                    label={<Trans>Primary DSI analyst</Trans>}
                  >
                    <>
                      <SimpleSelectField
                        name="dms_contact"
                        id="clienteditform-dms_contact"
                        options={usersMenuItems}
                        isDisabled={formikBag.isSubmitting}
                        placeholder={i18n._(t`Select a person`)}
                      />
                      <ErrorMessage
                        name="dms_contact"
                        component={ErrorNotice}
                      />
                    </>
                  </FormItem>
                  <FormItem
                    fieldId="clienteditform-secondary_dms_contacts"
                    label={<Trans>Secondary DSI contacts</Trans>}
                  >
                    <>
                      <SimpleSelectField
                        name="secondary_dms_contacts"
                        id="clienteditform-secondary_dms_contacts"
                        options={usersMenuItems}
                        isMulti={true}
                        isDisabled={formikBag.isSubmitting}
                        placeholder={i18n._(t`Select one or more people`)}
                      />
                      <ErrorMessage
                        name="secondary_dms_contacts"
                        component={ErrorNotice}
                      />
                    </>
                  </FormItem>
                  <FormItem
                    fieldId="clienteditform-postal_address"
                    label={<Trans>Postal address</Trans>}
                  >
                    <>
                      <Field
                        name="postal_address"
                        component="textarea"
                        id="clienteditform-postal_address"
                        placeholder={i18n._(t`Office postal address`)}
                      />
                      <ErrorMessage
                        name="postal_address"
                        component={ErrorNotice}
                      />
                    </>
                  </FormItem>
                  <FormItem
                    fieldId="clienteditform-office_phone"
                    label={<Trans>Office phone</Trans>}
                  >
                    <>
                      <Field
                        name="office_phone"
                        id="clienteditform-office_phone"
                        placeholder={i18n._(t`Office phone number`)}
                      />
                      <ErrorMessage
                        name="office_phone"
                        component={ErrorNotice}
                      />
                    </>
                  </FormItem>
                </fieldset>
                <ActionBlock>
                  <Button
                    id={`clienteditform-cancel`}
                    onClick={() => onCancel()}
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <ButtonPrimary
                    id="clienteditform-submit"
                    type="submit"
                    disabled={formikBag.isSubmitting}
                    iconType="icon-save"
                  >
                    <Trans>Save</Trans>
                  </ButtonPrimary>
                </ActionBlock>
                {formikBag.status}
              </Form>
            )}
          </I18n>
        )}
      </Formik>
    </ModalContent>
  );
};
