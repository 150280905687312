import React, { useMemo, useCallback } from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans } from '@lingui/macro';
import { Formik, Form, FormikErrors, Field, FieldArray } from 'formik';
import lodashSet from 'lodash/set';
import {
  DashboardComponent,
  DashboardComponent_POST,
} from 'util/backendapi/types/Model';
import { FormCard, FormCardSection } from 'components/base/card/card';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import Button, { ButtonPrimary } from 'components/base/button/button';
import ActionBlock from 'components/base/actionblock/actionblock';
import { useParams, useHistory } from 'react-router';
import { useGetApi } from 'hooks/use-get-api';
import { AlertWarning } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import { postApi, patchApi, deleteApi } from 'util/backendapi/fetch';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields, errorToString } from 'util/backendapi/error';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';

export function EditBookmarksDashletScreen() {
  const { dashletId } = useParams<{ dashletId: string }>();
  const history = useHistory();

  const [{ isError, isLoading, data: dashlet, error }] = useGetApi(
    dashletId ? `/dashboard-components/${+dashletId}/` : null
  );

  const handleAfterSubmit = useCallback(() => history.push('/'), [history]);

  if (dashletId) {
    return (
      <EditBookmarkDashletView
        isError={isError}
        isLoading={isLoading}
        dashlet={dashlet}
        error={error}
        dashletId={Number(dashletId)}
        onAfterSubmit={handleAfterSubmit}
      />
    );
  }
  return (
    <EditBookmarkDashletView
      isError={false}
      isLoading={false}
      dashlet={null}
      dashletId={null}
      onAfterSubmit={handleAfterSubmit}
    />
  );
}

type FormValues = DashboardComponent_POST;

function validate(values: FormValues): FormikErrors<FormValues> {
  const errors: FormikErrors<FormValues> = {};
  if (!values.title) {
    errors.title = (<Trans>Component heading is required.</Trans>) as any;
  }
  for (let i = 0; i < values.links.length; i++) {
    if (!values.links[i].label) {
      lodashSet(
        errors,
        ['links', i, 'label'],
        <Trans>Label is required.</Trans>
      );
    }
    if (!values.links[i].url) {
      lodashSet(errors, ['links', i, 'url'], <Trans>URL is required.</Trans>);

      // Very simple validation that the string is an absolute URL.
      // We rely on the back end to validate this more thoroughly.
    } else if (!/^https?:\/\/.+/i.test(values.links[i].url)) {
      lodashSet(
        errors,
        ['links', i, 'url'],
        <Trans>Must be an absolute URL.</Trans>
      );
    }
  }
  return errors;
}

function CancelSaveButtons(props: { onCancel: () => void }) {
  return (
    <ActionBlock>
      <Button
        data-testid="bookmark-dashlet-cancel-button"
        onClick={props.onCancel}
      >
        <Trans>Cancel</Trans>
      </Button>
      <ButtonPrimary
        data-testid="bookmark-dashlet-submit-button"
        type="submit"
        iconType="icon-save"
      >
        <Trans>Save</Trans>
      </ButtonPrimary>
    </ActionBlock>
  );
}

interface ViewProps {
  isError: boolean;
  isLoading: boolean;
  error?: any;
  dashlet: DashboardComponent | null;
  dashletId: number | null;
  onAfterSubmit: () => void;
}

export function EditBookmarkDashletView(props: ViewProps) {
  const { dashlet, dashletId, isLoading, isError, error, onAfterSubmit } =
    props;
  const isNew = dashletId === null;
  const initialValues: FormValues = useMemo(
    () =>
      dashlet ?? {
        title: '',
        // Show three empty link placeholders to start with
        links: [1, 2, 3].map(() => ({
          label: '',
          url: '',
        })),
      },
    [dashlet]
  );
  const pageHeader = isNew ? (
    <Trans>Create a Dashboard Component</Trans>
  ) : (
    <Trans>Edit Dashboard Component</Trans>
  );
  const cardHeader = isNew ? (
    <Trans>New dashboard component</Trans>
  ) : (
    <Trans>Edit dashboard component</Trans>
  );

  return (
    <PageStandard name="dashlet" header={pageHeader}>
      {isError ? (
        <AlertWarning>{errorToString(error)}</AlertWarning>
      ) : isLoading || (!isNew && !dashlet) ? (
        <Loading />
      ) : (
        <Formik<FormValues>
          initialValues={initialValues}
          validate={validate}
          onSubmit={async (values, formik) => {
            try {
              if (isNew) {
                await postApi('/dashboard-components/', values);
              } else {
                await patchApi(`/dashboard-components/${dashletId}/`, values);
              }
              onAfterSubmit();
            } catch (e) {
              formik.setSubmitting(false);
              showErrorsInFormik(formik, e, getExpectedFields(values));
            }
          }}
        >
          {(formik) => (
            <Form>
              {formik.status}
              <ActionBlock className="text-right">
                <CancelSaveButtons onCancel={onAfterSubmit} />
              </ActionBlock>
              <FormCard
                name="bookmarks-dashlet-form"
                header={cardHeader}
                subHeader={
                  isNew ? undefined : (
                    <ButtonShowConfirmation
                      name="delete-bookmark-dashlet-button"
                      destructive={true}
                      content={
                        <Trans>
                          Are you sure you want to delete the{' '}
                          <strong>{dashlet?.title}</strong> component from your
                          dashboard? This action is not reversible.
                        </Trans>
                      }
                      okBtnText={<Trans>Yes, delete</Trans>}
                      onConfirm={async () => {
                        await deleteApi(`/dashboard-components/${dashletId}/`);
                        onAfterSubmit();
                      }}
                    >
                      <Trans>Delete</Trans>
                    </ButtonShowConfirmation>
                  )
                }
              >
                <FormCardSection
                  name="name"
                  header={<Trans>Name</Trans>}
                  fields={[
                    {
                      name: 'title',
                      label: <Trans>Component heading</Trans>,
                      content: (
                        <>
                          <Field type="text" name="title" />
                          <FieldError name="title" />
                        </>
                      ),
                    },
                  ]}
                />
                <FieldArray name="links">
                  {(formikArrayHelpers) => (
                    <FormCardSection
                      name="links"
                      header={<Trans>Links</Trans>}
                      fields={formik.values.links
                        .map((_value, idx) => ({
                          name: `links-${idx}`,
                          columns: [
                            {
                              name: `links-${idx}-label`,
                              label: <Trans>Label</Trans>,
                              content: (
                                <>
                                  <Field
                                    type="text"
                                    name={`links[${idx}].label`}
                                  />
                                  <FieldError name={`links[${idx}].label`} />
                                </>
                              ),
                            },
                            {
                              name: `links-${idx}-url`,
                              label: <Trans>URL</Trans>,
                              content: (
                                <>
                                  <Field
                                    type="text"
                                    name={`links[${idx}].url`}
                                  />
                                  <FieldError name={`links[${idx}].url`} />
                                  {formik.values.links.length > 1 && (
                                    <Button
                                      onClick={() =>
                                        formikArrayHelpers.remove(idx)
                                      }
                                    >
                                      <Trans>Delete</Trans>
                                    </Button>
                                  )}
                                </>
                              ),
                            },
                          ],
                        }))
                        .concat(
                          (
                            <div key="add-link-button" className="card-row">
                              <div className="form-group">
                                <Button
                                  onClick={() =>
                                    formikArrayHelpers.push({
                                      label: '',
                                      url: '',
                                    })
                                  }
                                  iconType="icon-plus"
                                >
                                  <Trans>Add link</Trans>
                                </Button>
                              </div>
                            </div>
                          ) as any
                        )}
                    />
                  )}
                </FieldArray>
              </FormCard>
              <ActionBlock className="text-right">
                <CancelSaveButtons onCancel={onAfterSubmit} />
              </ActionBlock>
            </Form>
          )}
        </Formik>
      )}
    </PageStandard>
  );
}
