import { DuckActions } from 'main/store';

export interface UiState {
  lang: string;
  isDashletCollapsed: {
    batchesAwaiting: boolean;
    uncancelledAlarms: boolean;
    checksheetsToReview: boolean;
    checksheetsInProgress: boolean;
  };
  isCustomDashletCollapsed: Record<number, boolean>;
  isNavMenuCollapsed: boolean;
  subMenu: Record<string, boolean>;
}

export const ActionTypes = {
  EXPAND_NAV_MENU: 'dms/ui/EXPAND_NAV_MENU',
  COLLAPSE_NAV_MENU: 'dms/ui/COLLAPSE_NAV_MENU',
  CHANGE_LANGUAGE: 'dms/ui/CHANGE_LANGUAGE',
  EXPAND_NAV_CATEGORY: 'dms/ui/EXPAND_NAV_CATEGORY',
  COLLAPSE_NAV_CATEGORY: 'dms/ui/COLLAPSE_NAV_CATEGORY',
  CLICK_OUTSIDE_MENU: 'dms/ui/CLICK_OUTSIDE_MENU',
  SET_DASHLET_COLLAPSED: 'dms/ui/SET_DASHLET_COLLAPSED',
  SET_CUSTOM_DASHLET_COLLAPSED: 'dms/ui/SET_CUSTOM_DASHLET_COLLAPSED',
} as const;

export const ActionCreators = {
  EXPAND_NAV_MENU: () => ({ type: ActionTypes.EXPAND_NAV_MENU }),
  COLLAPSE_NAV_MENU: () => ({ type: ActionTypes.COLLAPSE_NAV_MENU }),
  CHANGE_LANGUAGE: (lang: string) => ({
    type: ActionTypes.CHANGE_LANGUAGE,
    payload: lang,
  }),
  EXPAND_NAV_CATEGORY: (name: string) => ({
    type: ActionTypes.EXPAND_NAV_CATEGORY,
    payload: name,
  }),
  COLLAPSE_NAV_CATEGORY: (name: string) => ({
    type: ActionTypes.COLLAPSE_NAV_CATEGORY,
    payload: name,
  }),
  CLICK_OUTSIDE_MENU: () => ({ type: ActionTypes.CLICK_OUTSIDE_MENU }),
  SET_DASHLET_COLLAPSED: (
    dashlet: keyof UiState['isDashletCollapsed'],
    isCollapsed: boolean
  ) => ({
    type: ActionTypes.SET_DASHLET_COLLAPSED,
    payload: {
      dashlet,
      isCollapsed,
    },
  }),
  SET_CUSTOM_DASHLET_COLLAPSED: (dashletId: number, isCollapsed: boolean) => ({
    type: ActionTypes.SET_CUSTOM_DASHLET_COLLAPSED,
    payload: {
      dashletId,
      isCollapsed,
    },
  }),
} as const;

export const expandNavMenu = ActionCreators.EXPAND_NAV_MENU;
export const collapseNavMenu = ActionCreators.COLLAPSE_NAV_MENU;
export const expandNavCategory = ActionCreators.EXPAND_NAV_CATEGORY;
export const collapseNavCategory = ActionCreators.COLLAPSE_NAV_CATEGORY;
export const changeLanguage = ActionCreators.CHANGE_LANGUAGE;
export const clickOutsideMenu = ActionCreators.CLICK_OUTSIDE_MENU;
export const setDashletCollapsed = ActionCreators.SET_DASHLET_COLLAPSED;
export const setCustomDashletCollapsed =
  ActionCreators.SET_CUSTOM_DASHLET_COLLAPSED;

export type UiAction = DuckActions<typeof ActionTypes, typeof ActionCreators>;

export function uiInitialState(): UiState {
  return {
    lang: 'en',
    isNavMenuCollapsed: false,
    subMenu: {},
    isDashletCollapsed: {
      batchesAwaiting: true,
      uncancelledAlarms: true,
      checksheetsToReview: true,
      checksheetsInProgress: true,
    },
    isCustomDashletCollapsed: {},
  };
}

export default function uiReducer(
  state = uiInitialState(),
  action: UiAction
): UiState {
  switch (action.type) {
    case ActionTypes.CHANGE_LANGUAGE:
      return { ...state, lang: action.payload };
    case ActionTypes.EXPAND_NAV_MENU:
      return { ...state, isNavMenuCollapsed: false };
    case ActionTypes.COLLAPSE_NAV_MENU:
      return {
        ...state,
        isNavMenuCollapsed: true,
        subMenu: {},
      };
    case ActionTypes.EXPAND_NAV_CATEGORY: {
      const { isNavMenuCollapsed } = state;

      // if main nav menu is collapsed,
      // we allow to open only one sub-menu at a time
      if (isNavMenuCollapsed) {
        return {
          ...state,
          subMenu: {
            [`is-${action.payload}-collapsed`]: false,
          },
        };
      } else {
        return {
          ...state,
          subMenu: {
            ...state.subMenu,
            [`is-${action.payload}-collapsed`]: false,
          },
        };
      }
    }
    case ActionTypes.COLLAPSE_NAV_CATEGORY:
      return {
        ...state,
        subMenu: {
          ...state.subMenu,
          [`is-${action.payload}-collapsed`]: true,
        },
      };

    case ActionTypes.CLICK_OUTSIDE_MENU:
      if (state.isNavMenuCollapsed) {
        return {
          ...state,
          subMenu: {},
        };
      }

      return state;

    case ActionTypes.SET_DASHLET_COLLAPSED:
      return {
        ...state,
        isDashletCollapsed: {
          ...state.isDashletCollapsed,
          [action.payload.dashlet]: action.payload.isCollapsed,
        },
      };

    case ActionTypes.SET_CUSTOM_DASHLET_COLLAPSED:
      return {
        ...state,
        isCustomDashletCollapsed: {
          ...state.isCustomDashletCollapsed,
          [action.payload.dashletId]: action.payload.isCollapsed,
        },
      };

    default:
      return state;
  }
}
