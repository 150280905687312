import { useState, useReducer, useEffect, useCallback } from 'react';
import { getApi } from 'util/backendapi/fetch';
import { Endpoint } from 'util/backendapi/models/api.interfaces';

export interface FetcherState<T> {
  isError: boolean;
  isLoading: boolean;
  error?: any;
  data: null | T;
}
const dataFetchReducer = <T>(state: FetcherState<T>, action: any) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        data: null,
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.error,
      };
    default:
      throw new Error();
  }
};

export const useGetApi = <T extends keyof Endpoint.GET>(
  initialUrl: null | T,
  initialParams?: Endpoint.GET[T]['params']
): [
  FetcherState<Endpoint.GET[T]['result']>,
  (url: T) => void,
  (params: Endpoint.GET[T]['params']) => void,
  () => void
] => {
  const [url, setUrl] = useState(initialUrl);
  const [params, setParams] = useState(initialParams);
  const [revision, setRevision] = useState(0);

  const reloadData = useCallback(() => {
    setRevision(revision + 1);
  }, [revision, setRevision]);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    data: null,
  });

  useEffect(() => {
    let didCancel = false;

    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });

      try {
        const result = await getApi(url!, params);

        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: result });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_FAILURE', error });
        }
      }
    };

    if (url) {
      fetchData();
    }

    return () => {
      didCancel = true;
    };
  }, [url, params, revision]);

  return [state, setUrl, setParams, reloadData];
};
