import React from 'react';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { Trans, Plural, t } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import {
  ReportFilter,
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { reportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import ButtonShowPanel from 'components/base/panel/buttonshowpanel';
import { ClientAreasPanel } from './areas/ClientAreasPanel';
import { ClientUsersPanel } from './users/ClientUsersPanel';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import ClientEditForm from './detail/ClientEditForm';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { LinkIfHas } from 'components/logic/link-if-has-permission/LinkIfHas';
import { ReportStateProps } from 'hooks/use-report-state';

export interface ClientListViewProps {
  reportProps: ReportStateProps<Model.ReportsClient>;
  deleteClient: (clientId: number) => Promise<void>;
}

export const clientListFilters: ReportFilter[] = [
  reportFilterMenu(
    'id',
    <Trans>Full name</Trans>,
    { isMulti: true, valueType: 'number' },
    (option: { id: number; full_name: string }) => ({
      value: option.id,
      label: option.full_name,
    }),
    {
      placeholder: <Trans>Find client</Trans>,
      autoFocus: true,
    }
  ),
];

export const clientListColumns = (extraProps?: {
  refreshList: () => void;
  deleteClient: (clientId: number) => Promise<void>;
}): ReportColumn<Model.ReportsClient>[] => [
  {
    label: <Trans>Name</Trans>,
    name: 'name',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Full name</Trans>,
    name: 'full_name',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Primary contact person</Trans>,
    name: 'primary_contact',
    backendFieldName: 'primary_contact__username',
    visibility: DEFAULT_SHOW,
    additionalFields: ['primary_contact__id', 'primary_contact__profile__name'],
    accessor: ({
      primary_contact__username: username,
      primary_contact__id: userId,
      primary_contact__profile__name: name,
    }) =>
      userId && (
        <LinkIfHas
          permission="can_view_other_user_profiles"
          to={`/user/${userId}`}
        >
          {name || username}
        </LinkIfHas>
      ),
  },
  {
    label: <Trans>Postal address</Trans>,
    name: 'postal_address',
    visibility: DEFAULT_SHOW,
    accessor: (row) => (
      <div className="text-with-linebreaks">{row.postal_address}</div>
    ),
  },
  {
    label: <Trans>Office phone</Trans>,
    name: 'office_phone',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Primary DSI analyst</Trans>,
    name: 'dms_contact',
    backendFieldName: 'dms_contact__username',
    visibility: DEFAULT_SHOW,
    additionalFields: ['dms_contact__id', 'dms_contact__profile__name'],
    accessor: ({
      dms_contact__username: username,
      dms_contact__id: userId,
      dms_contact__profile__name: name,
    }) =>
      userId && (
        <LinkIfHas
          permission="can_view_other_user_profiles"
          to={`/user/${userId}`}
        >
          {name || username}
        </LinkIfHas>
      ),
  },
  {
    label: <Trans>Secondary DSI contacts</Trans>,
    name: 'secondary_dms_contacts',
    backendFieldName: 'secondary_dms_contacts',
    visibility: DEFAULT_SHOW,
    accessor: ({ secondary_dms_contacts }) =>
      secondary_dms_contacts.map(({ id, username, profile: { name } }, idx) => (
        <React.Fragment key={id}>
          <LinkIfHas
            permission="can_view_other_user_profiles"
            to={`/user/${id}`}
          >
            {name || username}
          </LinkIfHas>
          {idx < secondary_dms_contacts.length - 1 && ', '}
        </React.Fragment>
      )),
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id', 'full_name', 'name', 'users', 'comment_count'],
    accessor: (
      row: Pick<
        Model.ReportsClient,
        'id' | 'full_name' | 'name' | 'users' | 'comment_count'
      >
    ) => (
      <>
        <ButtonShowPanel
          name={`client-areas-${row.id}`}
          iconOnly={true}
          iconType="icon-area"
          title={t`Areas`}
          className="btn-link-panel"
          panelContent={() => <ClientAreasPanel client={row} />}
        >
          <Trans>Areas</Trans>
        </ButtonShowPanel>
        <ButtonShowPanel
          name={`client-users-${row.id}`}
          iconOnly={true}
          iconType="icon-members"
          title={t`Members`}
          className="btn-link-panel"
          panelContent={() => <ClientUsersPanel clientId={row.id} />}
        >
          <Trans>Members</Trans>
        </ButtonShowPanel>
        <HasPermission check="can_create_clients">
          <ButtonShowModal
            className="btn-link-panel"
            name="edit-client"
            modalContent={({ hideModal }) => {
              return (
                <ClientEditForm
                  clientId={row.id}
                  onAfterSave={() => {
                    extraProps!.refreshList();
                  }}
                  hideModal={hideModal}
                />
              );
            }}
            iconType="icon-edit"
            iconOnly={true}
            title={t`Edit`}
          >
            <Trans>Edit</Trans>
          </ButtonShowModal>
        </HasPermission>
        <HasPermission check="can_delete_clients">
          <ButtonShowConfirmation
            name="delete-client"
            title={t`Delete`}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            content={
              <Trans>
                Are you sure you want to delete <strong>{row.name}</strong>?
                This action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async function () {
              await extraProps!.deleteClient(row.id);
            }}
          />
        </HasPermission>
        <HasPermission check="can_create_client_comments">
          <>
            <ButtonShowCommentsPanel
              type={Enum.Comment_RESOURCE_TYPE.client}
              description={
                <h4 className="panel-subheader">{row.full_name || row.name}</h4>
              }
              metadata={{
                client: row.id,
              }}
              buttonProps={{
                iconOnly: true,
                className: 'btn-link-panel',
                badge: row.comment_count,
              }}
            />
          </>
        </HasPermission>
      </>
    ),
  },
];

type Props = ClientListViewProps;
export const ClientListView: React.FunctionComponent<Props> = (props) => {
  const { reportProps } = props;
  return (
    <PageStandard
      name="clients-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Clients report</Trans>
        ) : (
          <Trans>Maintain Clients</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <HasPermission check="can_create_clients">
          <ActionBlock className="text-right">
            <ButtonShowModal
              name="create-client"
              iconType="icon-plus"
              shortcut="CREATE_NEW"
              modalContent={({ hideModal }) => {
                return (
                  <ClientEditForm
                    clientId={null}
                    onAfterSave={() => {
                      reportProps.refreshList();
                    }}
                    hideModal={hideModal}
                  />
                );
              }}
            >
              <Trans>Create client</Trans>
            </ButtonShowModal>
          </ActionBlock>
        </HasPermission>

        <ReportFiltersBlock
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          filtersFrontend={clientListFilters}
          isExportMode={reportProps.isExportMode}
        />
      </div>

      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <SaveReportModalButtons
            columnsFrontend={clientListColumns()}
            filtersFrontend={clientListFilters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Client report`}
            reportUrl="/reports/clients/"
            columns={clientListColumns()}
            filters={clientListFilters}
          />
        </ActionBlock>
        <ReportTable
          columns={clientListColumns({
            refreshList: reportProps.refreshList,
            deleteClient: props.deleteClient,
          })}
          filters={clientListFilters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 client"
              other="Filtered to # clients"
            />
          )}
          msgNoMatches={<Trans>No clients match the selected filters.</Trans>}
        />
      </div>
    </PageStandard>
  );
};
