import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const alarmNotificationListDuck = makeReportingDuck(
  'alarmNotification',
  (filters) => getPaginated('/reports/alarm-notifications/', filters),
  () => getApi('/reports/alarm-notifications/info/')
);

export type AlarmNotificationListActions = ReportingDuckActions<
  typeof alarmNotificationListDuck
>;
