import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Formik, Form, Field } from 'formik';
import ModalContent from 'components/base/modal/modalcontent';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import {
  fetchEntityList,
  EntityTypes,
  selectAllInOrderedArray,
} from 'ducks/entities';
import { FullState } from 'main/reducers';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { useShallowEqualSelector } from 'util/hooks';
import { createStoredPlot } from 'ducks/stored-plot/detail';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { withRouter, RouteComponentProps } from 'react-router';
import { getExpectedFields } from 'util/backendapi/error';
import { SingleCumulativePlotState } from 'ducks/plot/cumulative';
import {
  ScatterPlotSettings,
  TimeSeriesPlotSettings,
} from 'components/plots/timeseriesplot.types';

const InnerSavePlotModal = (
  props: {
    plotType:
      | Enum.PlotType.TIME_SERIES
      | Enum.PlotType.SCATTER
      | Enum.PlotType.CUMULATIVE;
  } & RouteComponentProps
) => {
  const { plotType } = props;

  const dispatch = useDispatch();

  const { resolvedSettings, areaOptions } = useShallowEqualSelector(
    (state: FullState) => ({
      resolvedSettings:
        plotType === Enum.PlotType.TIME_SERIES ||
        plotType === Enum.PlotType.SCATTER
          ? state.plot.scatterTimeSeries.plots[
              plotType === Enum.PlotType.SCATTER
                ? 'quickScatterPlot'
                : 'quickPlot'
            ].resolved
          : plotType === Enum.PlotType.CUMULATIVE
          ? state.plot.cumulative[0] ?? null
          : null,
      areaOptions: selectAllInOrderedArray(state, EntityTypes.AREA).map(
        (area) => ({
          value: area.id,
          label: `${area.code} - ${area.name}`,
        })
      ),
    })
  );

  useEffect(() => {
    dispatch(fetchEntityList(EntityTypes.AREA));
  }, [dispatch]);

  const onSubmit = useCallback(
    async (values: SavePlotFormValues) => {
      if (!resolvedSettings) {
        return;
      }

      if (plotType === Enum.PlotType.CUMULATIVE) {
        const cumulativePlotSettings =
          resolvedSettings as SingleCumulativePlotState;

        const newStoredPlot: Model.StoredCumulativePlot_POST = {
          plot_type: Enum.PlotType.CUMULATIVE,
          name: values.name,
          description: values.description,
          area: values.area!,
          observation_point_items:
            cumulativePlotSettings.observationPointItems!,
          time_periods: cumulativePlotSettings.timePeriods!,
        };
        await dispatch(createStoredPlot(newStoredPlot));
      } else if (plotType === Enum.PlotType.TIME_SERIES) {
        const timeSeriesPlotSettings =
          resolvedSettings as TimeSeriesPlotSettings;

        const newStoredPlot: Model.StoredTimeSeriesPlot_POST = {
          plot_type: Enum.PlotType.TIME_SERIES,
          name: values.name,
          description: values.description,
          area: values.area!,
          start_datetime: timeSeriesPlotSettings.start_datetime || null,
          end_datetime: timeSeriesPlotSettings.end_datetime || null,
          duration: timeSeriesPlotSettings.duration,
          layout: Enum.StoredPlot_LAYOUT.L100,
          plots_per_page: 1,
          items: [
            {
              reading_series: timeSeriesPlotSettings.reading_series,
              highlight_periods: [],
              annotations: [],
              axes: ['left' as const, 'right' as const]
                // We only need y axis config for axes that actually have
                // a series assigned to them.
                .filter((side) =>
                  timeSeriesPlotSettings.reading_series.some(
                    (rs) => rs.axis_side === side
                  )
                )
                .map(
                  (side) =>
                    timeSeriesPlotSettings.axes.find(
                      (ya) => ya.side === side
                    ) || {
                      side,
                      minimum: null,
                      maximum: null,
                    }
                ),
            },
          ],
        };
        await dispatch(createStoredPlot(newStoredPlot));
      } else if (plotType === Enum.PlotType.SCATTER) {
        const scatterPlotSettings = resolvedSettings as ScatterPlotSettings;
        if (
          !scatterPlotSettings.reading_series ||
          scatterPlotSettings.reading_series.length !== 2
        ) {
          return;
        }

        const newStoredPlot: Model.StoredScatterPlot_POST = {
          plot_type: Enum.PlotType.SCATTER,
          name: values.name,
          description: values.description,
          area: values.area!,
          start_datetime: scatterPlotSettings.start_datetime || null,
          end_datetime: scatterPlotSettings.end_datetime || null,
          duration: scatterPlotSettings.duration,
          plots_per_page: 1,
          items: [
            {
              reading_series: scatterPlotSettings.reading_series,
              highlight_periods: [],
              annotations: [],
              axes: [
                { minimum: null, maximum: null, side: 'left' as const },
                { minimum: null, maximum: null, side: 'bottom' as const },
              ],
              interpolate: scatterPlotSettings.interpolate,
              show_plot_markers: scatterPlotSettings.show_plot_markers,
              show_mark_connections: scatterPlotSettings.show_mark_connections,
            },
          ],
        };

        await dispatch(createStoredPlot(newStoredPlot));
      }

      props.history.push(`/stored-plots/${values.name}/`);
    },
    [dispatch, props.history, resolvedSettings, plotType]
  );

  const defaultArea =
    resolvedSettings &&
    resolvedSettings.observationPoints &&
    resolvedSettings.observationPoints.length > 0
      ? resolvedSettings.observationPoints[0].area.id
      : null;

  return (
    <SavePlotModalView
      areaOptions={areaOptions}
      defaultArea={defaultArea}
      onSubmit={onSubmit}
    />
  );
};

export const SavePlotModal = withRouter(InnerSavePlotModal);

export interface SavePlotFormValues {
  name: string;
  description: string;
  area: null | number;
}

const validate = (values: SavePlotFormValues) => {
  const errors: any = {};
  if (!values.name) {
    errors.name = <Trans>Name is required.</Trans>;
  }
  if (!values.area) {
    errors.area = <Trans>Area is required.</Trans>;
  }

  return errors;
};

interface ViewProps {
  areaOptions: SimpleSelectOption<number>[];
  defaultArea: number | null;
  onSubmit: (values: SavePlotFormValues) => Promise<any>;
}

export const SavePlotModalView = ({
  onSubmit,
  areaOptions,
  defaultArea,
}: ViewProps) => {
  const initialValues = {
    name: '',
    description: '',
    area: defaultArea,
  };

  return (
    <ModalContent header={<Trans>Save plot</Trans>}>
      <p>
        <Trans>
          The plot will be saved with the same settings as displayed on the
          screen.
        </Trans>
      </p>

      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, formik) => {
          try {
            await onSubmit(values);
          } catch (e) {
            showErrorsInFormik(
              formik,
              e,
              getExpectedFields(values),
              ErrorNotice
            );
          }
        }}
      >
        {(formik) => (
          <Form>
            <FormSection label={<Trans>Stored plot</Trans>}>
              <FormItem label={<Trans>Name</Trans>} fieldId="save-plot-name">
                <Field id="save-plot-name" name="name" type="text" />
                <FieldError name="name" />
              </FormItem>
              <FormItem
                label={<Trans>Description</Trans>}
                fieldId="save-plot-description"
              >
                <Field
                  id="save-plot-description"
                  name="description"
                  component="textarea"
                />
                <FieldError name="description" />
              </FormItem>
              <FormItem label={<Trans>Area</Trans>}>
                <SimpleSelectField
                  id="save-plot-area"
                  name="area"
                  isLoading={!areaOptions.length}
                  options={areaOptions}
                />
                <FieldError name="area" />
              </FormItem>
            </FormSection>
            <ActionBlock>
              <ButtonHideModal id="save-plot-cancel" />
              <ButtonPrimary
                id="save-plot-submit"
                type="submit"
                iconType="icon-save"
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
            {formik.status}
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
