import sortBy from 'lodash/sortBy';
import { t, Trans, Plural } from '@lingui/macro';
import React, { FunctionComponent, useState } from 'react';
import { Link } from 'react-router-dom';
import lodashGet from 'lodash/get';
import ButtonShowPanel from 'components/base/panel/buttonshowpanel';
import { AlarmCountByReadingId } from 'ducks/checkreadings';
import { ButtonPrimary, ButtonSecondary } from 'components/base/button/button';
import { Icon } from 'components/base/icon/icon';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { PaginationMeta } from 'util/backendapi/pagination';
import { formatDatetimeForDisplay } from 'util/dates';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import EditReadingModal from '../editreading/editreading';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { EntityTypes, EntityList } from 'ducks/entities';
import Loading from 'components/base/loading/loading';
import { AlertDanger, AlertInfo } from 'components/base/alert/alert';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import './checkreadings.scss';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import {
  FilterBlock,
  FilterControl,
} from 'components/modules/report/filter/FilterBlock';
import SimpleSelect, {
  SimpleSelectOption,
} from 'components/base/form/simpleselect/simpleselect';
import { getSafely } from 'util/misc';
import {
  ReportColumn,
  ALWAYS_SHOW,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';
import { BatchSummary } from 'components/modules/batchsummarybox/BatchSummary';
import { BackButton } from 'components/base/back-button/BackButton';
import { DMSLink } from 'components/base/link/DMSLink';
import { CopyBatchModal } from './CopyBatchModal';
import { isNotNull } from 'util/validation';
import { PreviewMediaButton } from 'screens/media/preview/PreviewMediaModal';

export type CheckReadingErrorsFilterSetting =
  | ''
  | 'with_error'
  | 'without_error';
const filterOptions: SimpleSelectOption<CheckReadingErrorsFilterSetting>[] = [
  { value: '', label: <Trans>All</Trans> },
  { value: 'with_error', label: <Trans>Errors only</Trans> },
  { value: 'without_error', label: <Trans>Non-errors only</Trans> },
];

export interface CheckReadingsViewProps {
  isLoading: boolean;
  isRecalculatingReadings: boolean;
  errorMessage: string;
  readingsBatch: null | Model.ListReadingsBatch;
  readingsFile: null | Model.ReadingsFileDecorated;
  pagination: PaginationMeta;
  readingErrorsFilter: CheckReadingErrorsFilterSetting | null;
  alarmsCountByReadingId: AlarmCountByReadingId | null;
  readings: Model.Reading[];
  allFormulaOutputs: EntityList<EntityTypes.FORMULA_OUTPUT>;
  checksheetInstanceId: number | null;
  onConfirmAndCreateAlarms: () => void;
  onRecalculateAdjustedReadings: (batchId: number) => void;
  onReadingErrorsFilterChanged: (
    value: CheckReadingErrorsFilterSetting | null
  ) => void;
  refreshList: () => void;
  refreshBatchData: () => void;
}

export function checkReadingsColumns(
  canEditReadings: boolean,
  showThumbnails: boolean,
  props: Pick<
    CheckReadingsViewProps,
    | 'allFormulaOutputs'
    | 'alarmsCountByReadingId'
    | 'refreshList'
    | 'readingsBatch'
  >
): ReportColumn<Model.Reading>[] {
  const { allFormulaOutputs, alarmsCountByReadingId, refreshList } = props;

  return [
    {
      label: <Trans>Observation point</Trans>,
      name: 'observation-point',
      visibility: ALWAYS_SHOW,
      accessor: (reading: Model.Reading) => reading.observation_point.code,
    },
    {
      label: <Trans>Date and time</Trans>,
      name: 'date-and-time',
      visibility: ALWAYS_SHOW,
      accessor: (reading: Model.Reading) =>
        formatDatetimeForDisplay(
          reading.reading_datetime,
          reading.time_zone.name
        ),
    },
    {
      label: <Trans>Raw reading</Trans>,
      name: 'raw-reading',
      visibility: ALWAYS_SHOW,
      className: 'text-right',
      tdClassName: 'raw-reading-value',
      accessor: (reading: Model.Reading) => (
        <RawReadingEntryList entries={reading.raw_reading_entries} />
      ),
    },
    {
      label: <Trans>Adjusted reading</Trans>,
      name: 'adjusted-reading',
      visibility: ALWAYS_SHOW,
      className: 'text-right',
      tdClassName: 'table-cell-error-multi',
      accessor: (reading: Model.Reading) =>
        reading.errors.length === 0 ? (
          <AdjustedReadingEntryList
            entries={reading.adjusted_reading_entries}
            allFormulaOutputs={allFormulaOutputs}
          />
        ) : (
          reading.errors.map((error) => (
            <span key={error.id}>
              <Trans>Error</Trans> {error.error_number}: {error.message}
            </span>
          ))
        ),
    },
    {
      label: <Trans>Alarms</Trans>,
      name: 'alarms',
      visibility: ALWAYS_SHOW,
      accessor: (reading: Model.Reading) =>
        !alarmsCountByReadingId ? (
          <Icon type="icon-processing" title={t`Loading`} />
        ) : getSafely(() => alarmsCountByReadingId[reading.id].count > 0) ? (
          <Trans>Yes</Trans>
        ) : (
          <Trans>No</Trans>
        ),
    },
    {
      label: <Trans>Inspector comment</Trans>,
      name: 'inspector-comment',
      visibility: ALWAYS_SHOW,
      tdClassName: 'text-with-linebreaks',
      accessor: (reading: Model.Reading) => reading.inspector_comment?.content,
    },
    showThumbnails
      ? {
          label: <Trans>Media</Trans>,
          name: 'media',
          className: 'preview-media',
          visibility: ALWAYS_SHOW,
          accessor: (reading: Model.Reading) =>
            reading.media
              ?.map((m) => (
                <PreviewMediaButton
                  key={`media-button-${m.id}`}
                  media={m}
                  observationPointCode={reading.observation_point.code}
                  timeZone={reading.time_zone.name}
                  onAfterSubmit={refreshList}
                />
              ))
              .filter(isNotNull),
        }
      : null,
    {
      ...ACTION_COLUMN,
      accessor: (reading: Model.Reading) => (
        <>
          {canEditReadings && (
            <ButtonShowModal
              className="btn-link-panel"
              name="edit-reading"
              modalContent={({ hideModal }) => (
                <EditReadingModal
                  reading={reading}
                  hideModal={hideModal}
                  onAfterSave={refreshList}
                />
              )}
              iconType="icon-edit"
              iconOnly={true}
              title={t`Edit`}
            />
          )}

          <DMSLink to={`/quickplot/${reading.observation_point.code}`}>
            <Icon type="icon-plot" title={t`View plot`} />
          </DMSLink>

          <HasPermission check="can_create_reading_comments">
            <ButtonShowCommentsPanel
              type={Enum.Comment_RESOURCE_TYPE.reading}
              description={
                <h4 className="panel-subheader">
                  <span>{reading.observation_point.code}</span>{' '}
                  <span>
                    {formatDatetimeForDisplay(
                      reading.reading_datetime,
                      reading.time_zone.name
                    )}
                  </span>
                </h4>
              }
              metadata={{
                reading: reading.id,
                observation_point: reading.observation_point.id,
                site: reading.observation_point.site,
                area: reading.area,
              }}
              buttonProps={{
                iconOnly: true,
                className: 'btn-link-panel',
                badge: reading.analysis_comments_count,
              }}
              commentReportParams={`resourcetype=${Enum.Comment_RESOURCE_TYPE.reading}&observation_point=${reading.observation_point.id}`}
            />
          </HasPermission>
          {reading.media && reading.media.length > 0 && (
            <HasPermission check={['can_view_media_report', 'can_view_media']}>
              <DMSLink to={`/media/?reading=${reading.id}`}>
                <Icon type="icon-multimedia" title={t`View media`} />
              </DMSLink>
            </HasPermission>
          )}
        </>
      ),
    },
  ].filter(isNotNull);
}

export const CheckReadingsView: FunctionComponent<CheckReadingsViewProps> =
  function (props) {
    const {
      isLoading,
      isRecalculatingReadings,
      errorMessage,
      readingsBatch,
      readingsFile,
      pagination,
      readingErrorsFilter,
      onConfirmAndCreateAlarms: confirmReadingsHandler,
      onRecalculateAdjustedReadings,
      onReadingErrorsFilterChanged,
      checksheetInstanceId,
    } = props;

    const [showThumbnails, setShowThumbnails] = useState<boolean>(false);

    const pageWrapper = (children: React.ReactNode) => (
      <PageStandard
        name="check-readings"
        header={<Trans>Check Readings</Trans>}
        subHeader={
          readingsBatch &&
          (readingsBatch?.is_test ? (
            <Trans>Test calculation</Trans>
          ) : (
            readingsBatch?.batch_number +
            (readingsBatch?.name ? ' (' + readingsBatch?.name + ')' : '')
          ))
        }
      >
        {children}
      </PageStandard>
    );

    if (!readingsBatch) {
      if (errorMessage) {
        return pageWrapper(<AlertDanger>{errorMessage}</AlertDanger>);
      } else {
        return pageWrapper(<Loading />);
      }
    }

    const isBatchUnprocessed =
      readingsBatch.status === Enum.ReadingsBatch_STATUS.unprocessed;

    const columns = checkReadingsColumns(
      isBatchUnprocessed,
      showThumbnails,
      props
    );

    return pageWrapper(
      <>
        <div className="page-content-header-with-back-button-wrapper">
          <BackButton defaultBackUrl="/readings-batches" />

          <div className="page-content-header-filters-actions">
            <ActionBlock className="text-right">
              <ButtonSecondary
                onClick={() => {
                  setShowThumbnails(!showThumbnails);
                }}
                iconType="icon-png"
              >
                {!showThumbnails ? (
                  <Trans>Show Thumbnails</Trans>
                ) : (
                  <Trans>Hide Thumbnails</Trans>
                )}
              </ButtonSecondary>
              {isBatchUnprocessed && (
                <ButtonSecondary
                  disabled={isRecalculatingReadings}
                  onClick={() => {
                    onRecalculateAdjustedReadings(readingsBatch.id);
                  }}
                  iconType="icon-update"
                >
                  Recalculate batch
                </ButtonSecondary>
              )}
              {checksheetInstanceId ? (
                <HasPermission check="can_view_checksheet_instances">
                  <Link
                    className="btn"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={`/checksheet-instances/${checksheetInstanceId}`}
                  >
                    <span>
                      <Trans>View checksheet</Trans>
                    </span>
                    <Icon type="icon-view" />
                  </Link>
                </HasPermission>
              ) : null}
              {readingsBatch.status ===
                Enum.ReadingsBatch_STATUS.qa_complete && (
                <HasPermission check="can_copy_readings_batches">
                  <ButtonShowModal
                    name="copy-readings-modal"
                    modalContent={() => (
                      <CopyBatchModal readingsBatch={readingsBatch} />
                    )}
                  >
                    <Trans>Copy batch</Trans>
                  </ButtonShowModal>
                </HasPermission>
              )}

              <ButtonShowPanel
                name="batchSummary"
                autoShow={true}
                panelContent={() => (
                  <BatchSummary
                    readingsBatch={readingsBatch}
                    readingsFile={readingsFile}
                  />
                )}
              >
                <Trans>Batch info</Trans>
              </ButtonShowPanel>
            </ActionBlock>
            <FilterBlock>
              <FilterControl
                hasValues={readingErrorsFilter !== null}
                label={<Trans>Reading errors</Trans>}
              >
                <SimpleSelect<CheckReadingErrorsFilterSetting>
                  name="reading_errors"
                  isClearable={false}
                  placeholder={<Trans>All readings</Trans>}
                  options={filterOptions}
                  value={readingErrorsFilter || ''}
                  onChange={onReadingErrorsFilterChanged}
                />
              </FilterControl>
            </FilterBlock>
          </div>
        </div>
        {isRecalculatingReadings ? (
          <AlertInfo>
            <Trans>Calculating adjusted readings...</Trans>
          </AlertInfo>
        ) : (
          <div className="filtered-table-wrapper">
            <ReportTable
              records={props.readings}
              columns={columns}
              getTrProps={(reading) =>
                reading.errors.length !== 0
                  ? { className: 'table-row-error' }
                  : {}
              }
              errorMessage={errorMessage}
              isLoading={isLoading}
              msgNoMatches={<Trans>No readings are available.</Trans>}
              hasActiveFilters={readingErrorsFilter !== null}
              onClearFilters={() => onReadingErrorsFilterChanged('')}
              msgFilterCount={(count) => (
                <Plural
                  value={count}
                  one="Filtered to 1 reading"
                  other="Filtered to # readings"
                />
              )}
              pagination={pagination}
            />
          </div>
        )}
        {isBatchUnprocessed && !readingsBatch.is_test && (
          <ActionBlock className="text-right">
            <ButtonPrimary
              onClick={confirmReadingsHandler}
              iconType="icon-tick"
              disabled={isRecalculatingReadings}
            >
              <Trans>Confirm and create alarms</Trans>
            </ButtonPrimary>
          </ActionBlock>
        )}
      </>
    );
  };

const RawReadingEntryList: FunctionComponent<{
  entries: Model.RawReadingEntry[];
}> = function (props) {
  if (props.entries.length > 1) {
    return (
      <dl>
        {sortBy(props.entries, 'position').map((entry) => (
          <React.Fragment key={entry.position}>
            <dt>{entry.position}</dt>
            <dd>{entry.value}</dd>
          </React.Fragment>
        ))}
      </dl>
    );
  } else if (props.entries.length === 1) {
    return <>{props.entries[0].value}</>;
  } else {
    return <>''</>;
  }
};

// TODO: Base this on instrument item types rather than formula outputs
const AdjustedReadingEntryList: FunctionComponent<{
  entries: Model.AdjustedReadingEntry[];
  allFormulaOutputs: EntityList<EntityTypes.FORMULA_OUTPUT>;
}> = function (props) {
  if (props.entries.length > 1) {
    return (
      <ul>
        {sortBy(props.entries, (entry) =>
          getSafely(
            () =>
              props.allFormulaOutputs.byId[entry.formula_output].item_number,
            entry.formula_output
          )
        ).map((entry: Model.AdjustedReadingEntry) => (
          <li
            title={`${lodashGet(
              props.allFormulaOutputs,
              `byId[${entry.formula_output}].formula.code`
            )}: ${lodashGet(
              props.allFormulaOutputs,
              `byId[${entry.formula_output}].description`
            )}`}
            key={entry.formula_output}
          >
            {entry.value}
          </li>
        ))}
      </ul>
    );
  } else if (props.entries.length === 1) {
    return <>{props.entries[0].value}</>;
  } else {
    return <>''</>;
  }
};
