import React, { useCallback, useMemo } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import {
  PlotSetListView,
  PlotSetListColumns,
  PlotSetListFilters,
} from './PlotSetListView';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import { plotSetListDuck } from 'ducks/plotset/list';
import { FullState } from 'main/reducers';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';

type OwnProps = RCPWithQueryParams<Filter.ReportsPlotSets>;

export const PlotSetListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return PlotSetListColumns({
      refreshList: EMPTY_FUNC,
      deletePlotSet: EMPTY_FUNC,
    });
  }, []);

  const deletePlotSet = useCallback(async (id: number) => {
    return deleteApi(`/plot-sets/${id}/`);
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      PlotSetListFilters(),
      plotSetListDuck,
      (state: FullState) => state.plotSet.list
    ),
    deletePlotSet,
  };

  return <PlotSetListView {...props} />;
};
