import React, { useState, useEffect } from 'react';
import { Model } from 'util/backendapi/models/api.interfaces';
import ModalContent from 'components/base/modal/modalcontent';
import { Trans, t } from '@lingui/macro';
import { Icon } from 'components/base/icon/icon';
import { postApiFormData } from 'util/backendapi/fetch';
import { setWithoutMutating, printAsMegabytes } from 'util/misc';
import { errorToString } from 'util/backendapi/error';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrimary } from 'components/base/button/button';
import { useIsMounted } from 'util/hooks';

export type MediaToUpload = Omit<Model.Media_POST, 'id' | 'file_name'> & {
  attached_file: File;
};

interface UploadStatus {
  file: { name: string; size: number };
  status: 'new' | 'uploading' | 'done' | 'error';
  error?: string;
}

interface Props {
  itemsToUpload: MediaToUpload[];
  onClose: () => void;
}

export function MediaUploadProgressModal(props: Props) {
  const isMounted = useIsMounted();

  const { itemsToUpload, onClose } = props;
  const [uploadQueue, setUploadQueue] = useState<UploadStatus[]>(
    itemsToUpload.map((file) => ({
      file: { name: file.attached_file.name, size: file.attached_file.size },
      status: 'new',
    }))
  );

  // Perform the upload, and record the status as it goes.
  // NOTE: Closing the modal is NOT supposed to cancel the upload
  useEffect(() => {
    (async function () {
      for (let i = 0; i < itemsToUpload.length; i++) {
        const file = itemsToUpload[i];
        try {
          const formData = new FormData();
          formData.append('file', file.attached_file, file.attached_file.name);
          formData.append(
            'values',
            JSON.stringify({
              content_type: file.content_type,
              observation_points: file.observation_points,
              sites: file.sites,
              areas: file.areas,
              description: file.description,
            })
          );
          if (isMounted()) {
            setUploadQueue((oldUploadQueue) =>
              setWithoutMutating(oldUploadQueue, [i, 'status'], 'uploading')
            );
          }
          await postApiFormData('/media/', formData);
          if (isMounted()) {
            setUploadQueue((oldUploadQueue) =>
              setWithoutMutating(oldUploadQueue, [i, 'status'], 'done')
            );
          }
        } catch (e) {
          if (isMounted()) {
            setUploadQueue((oldUploadQueue) =>
              setWithoutMutating(oldUploadQueue, i, {
                ...oldUploadQueue[i],
                status: 'error',
                error: errorToString(e),
              })
            );
          }
        }
      }
    })();
  }, [isMounted, itemsToUpload]);

  return (
    <MediaUploadProgressModalView uploadQueue={uploadQueue} onClose={onClose} />
  );
}

export function MediaUploadProgressModalView(props: {
  uploadQueue: UploadStatus[];
  onClose: () => void;
}) {
  const { uploadQueue, onClose } = props;

  const isFinished = uploadQueue.every(
    ({ status }) => status === 'done' || status === 'error'
  );

  return (
    <ModalContent
      header={
        isFinished ? (
          <Trans>Upload complete</Trans>
        ) : (
          <Trans>Upload in progress</Trans>
        )
      }
    >
      <ul className="panel-member-list">
        {uploadQueue.map(({ file: { name, size }, status, error }, idx) => (
          <li key={idx} className="columns-fluid">
            <div>
              {`${name} (${printAsMegabytes(size)})`}
              {status === 'error' && error && (
                <>
                  <br />
                  <span className="error">{error}</span>
                </>
              )}
            </div>
            {status === 'new' ? null : (
              <div className="text-right">
                {status === 'uploading' ? (
                  <Icon type="icon-processing" title={t`Uploading`} />
                ) : status === 'done' ? (
                  <Icon type="icon-tick-solid" title={t`Success`} />
                ) : (
                  status === 'error' && (
                    <Icon type="icon-exclamation-solid" title={`Failed`} />
                  )
                )}
              </div>
            )}
          </li>
        ))}
      </ul>
      <ActionBlock>
        <ButtonPrimary onClick={onClose}>
          <Trans>Close</Trans>
        </ButtonPrimary>
      </ActionBlock>
    </ModalContent>
  );
}
