import React from 'react';
import {
  fetchObsPntDateRange,
  ReadingDatesByObsPointId,
  clearObsPointDateRangeCache,
} from 'ducks/readingsdaterangefilter';
import { defaultMemoize } from 'reselect';
import { convertDatetimeToDate } from 'util/dates';
import { extent } from 'd3-array';
import isEqual from 'lodash/isEqual';
import { connect, ResolveThunks } from 'react-redux';
import {
  DateRangeFilter,
  DateRangeFilterProps,
} from 'components/modules/daterangefilter/daterangefilter';
import { FullState } from 'main/reducers';
import { getSafely } from 'util/misc';
import { isTruthy } from 'util/validation';

type OwnProps = Pick<DateRangeFilterProps, 'isDisabled' | 'render'> & {
  /** The IDS of the selected observation points */
  observationPointIds: number[];
  /** Timezone to use when converting dates to datetimes (optional: defaults to
   * timezone of first observation point.
   */
  timeZone?: string | null;
  /**
   * Specify this if you want to have different logic to select the first and last reading date
   * If not specify, @defaultFirstAndLastReadingDateSelector will be used
   * */
  firstAndLastReadingDateSelector?: (
    observationPointIds: number[],
    timeZone: string | null,
    firstLastReadingsDatetimeByObs: ReadingDatesByObsPointId
  ) => { firstReadingDate: string | null; lastReadingDate: string | null };
};

interface StateProps {
  firstReadingDate: string;
  lastReadingDate: string;
  errorMessage: string;
  isLoading: boolean;
}

const mapDispatchToProps = {
  fetchObsPntDateRange,
  clearObsPointDateRangeCache,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

/**
 * A container component that wraps DateRangeFilter, and automatically fetches
 * the first/last readings dates for the selected set of observation points.
 */
class InnerReadingsDateRangeFilter extends React.Component<Props> {
  static mapStateToProps(state: FullState, ownProps: OwnProps) {
    const myState = state.readingsDateRangeFilter;

    const firstAndLastReadingDateSelector =
      ownProps.firstAndLastReadingDateSelector
        ? ownProps.firstAndLastReadingDateSelector
        : defaultFirstAndLastReadingDateSelector;

    const { firstReadingDate, lastReadingDate } =
      firstAndLastReadingDateSelector(
        ownProps.observationPointIds,
        // Use timezone from props if provided, otherwise use timezone of first
        // observation point
        ownProps.timeZone ||
          getSafely(
            () =>
              state.readingsDateRangeFilter.firstLastReadingsDatetimeByObs[
                ownProps.observationPointIds[0]
              ].timeZone
          ),
        state.readingsDateRangeFilter.firstLastReadingsDatetimeByObs
      );

    return {
      firstReadingDate: firstReadingDate || '',
      lastReadingDate: lastReadingDate || '',
      errorMessage: myState.errorMessage,
      isLoading: Boolean(
        myState.fetchingStartAndEndDatetimeFor &&
          ownProps.observationPointIds.some((op) =>
            myState.fetchingStartAndEndDatetimeFor!.includes(op)
          )
      ),
    };
  }

  render() {
    return (
      <DateRangeFilter
        isDisabled={this.props.isDisabled}
        render={this.props.render}
        suggestedStartDate={this.props.firstReadingDate}
        suggestedEndDate={this.props.lastReadingDate}
        isLoading={this.props.isLoading}
      />
    );
  }

  componentDidMount() {
    this.props.fetchObsPntDateRange(this.props.observationPointIds);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      !isEqual(
        prevProps.observationPointIds.sort(),
        this.props.observationPointIds.sort()
      )
    ) {
      this.props.fetchObsPntDateRange(this.props.observationPointIds);
    }
  }

  componentWillUnmount() {
    this.props.clearObsPointDateRangeCache();
  }
}

export const ReadingsDateRangeFilter = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  InnerReadingsDateRangeFilter.mapStateToProps,
  mapDispatchToProps
)(InnerReadingsDateRangeFilter);

/**
 * There can be multiple observation points selected, each one of them will have diffrent
 * firstReadingDatetime & lastReadingDatetime stored inside redux.
 *
 * This selector will derive the firstReadingDatetime & lastReadingDatetime for the
 * currently selected ObservationPoints. Basically trying to find the earliest and latest date
 * among those dates that we have in the state.
 */
export const defaultFirstAndLastReadingDateSelector = defaultMemoize<
  Exclude<Props['firstAndLastReadingDateSelector'], undefined>
>((observationPointIds, timeZone, firstLastReadingsDatetimeByObs) => {
  if (!observationPointIds || !observationPointIds.length) {
    return {
      firstReadingDate: null,
      lastReadingDate: null,
    };
  }

  const allReadingDatetimes = observationPointIds
    .map((opId) => firstLastReadingsDatetimeByObs[opId])
    .filter(isTruthy)
    .flatMap((op) =>
      [op.earliest_reading_datetime, op.latest_reading_datetime].filter(
        isTruthy
      )
    );

  if (!allReadingDatetimes.length) {
    return {
      firstReadingDate: null,
      lastReadingDate: null,
    };
  }

  const [firstReadingDatetime, lastReadingDatetime] =
    extent(allReadingDatetimes);

  return {
    firstReadingDate: convertDatetimeToDate(
      firstReadingDatetime,
      timeZone || undefined
    ),
    lastReadingDate: convertDatetimeToDate(
      lastReadingDatetime,
      timeZone || undefined
    ),
  };
});
