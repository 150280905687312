import React, { RefObject } from 'react';
import classNames from 'classnames';
import {
  PageStandardContext,
  PageStandardContextState,
} from '../../modules/pagestandard/pagestandard';
import Button, { ButtonProps } from 'components/base/button/button';
import ReactDOM from 'react-dom';

export interface PanelContentProps {
  hidePanel: PageStandardContextState['hidePanel'];
  pageName: string;
  panelName: string;
}

interface Props extends ButtonProps {
  name: string;
  autoShow?: boolean;
  panelContent: (props: PanelContentProps) => any;
  panelRef?: RefObject<any>;
}

/**
 * A button for toggling the info panel. It relies on a React Context provided
 * by the PageStandard component.
 *
 * @export
 * @class ButtonShowPanel
 * @extends {React.Component}
 * @prop {string} name A name for the button. Should match up to the name of
 * one of the panels in the "panels" prop passed to the PageStandard.
 */
export default class ButtonShowPanel extends React.PureComponent<Props> {
  /**
   * Use the context provided by the closest parent <PageStandardContext>
   * element (which should be in the <PageStandard> component)
   * @see PageStandard
   */
  static contextType = PageStandardContext;

  componentDidMount() {
    if (this.props.autoShow) {
      this.context.showPanel(this, this.props.name);
    }
  }

  render() {
    const {
      name,
      children,
      className,
      panelContent,
      panelRef,
      iconType,
      autoShow,
      ...otherProps
    } = this.props;
    const { pageName, activePanel } = this.context;

    // Use this to change the style of the button when its panel is open.
    const isActivePanel = this === activePanel;

    return (
      <>
        <Button
          id={`${pageName}-info-button-${name}`}
          iconType={iconType ? iconType : 'icon-circle-info'}
          onClick={this.onClick}
          className={classNames(className, {
            'btn-panel-active': isActivePanel,
          })}
          {...otherProps}
        >
          {children}
        </Button>
        {isActivePanel &&
          ReactDOM.createPortal(
            this.props.panelContent({
              hidePanel: this.context.hidePanel,
              pageName: this.context.pageName,
              panelName: this.props.name,
            }),
            panelRef && panelRef.current
              ? panelRef.current
              : this.context.panelRef.current
          )}
      </>
    );
  }

  onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    if (this === this.context.activePanel) {
      this.context.hidePanel();
    } else {
      this.context.showPanel(this, this.props.name);
    }
  };
}
