/**
 * validation.js: A place to hold miscellaneous helper functions used in
 * validating & whitelisting data from external sources or user input.
 */

/**
 * A helper function for the input fields that we need to cast into nullable
 * integers before sending them to the backend. Depending on where the input
 * came from, the value might reasonable be a literal null, null represented
 * by an empty string, an actual number, or a number as a string.
 *
 * @param {*} rawValue
 * @return {null|number}
 * @throws Exception
 */
export function toNullableInteger(rawValue: any): null | number {
  if (rawValue === null || rawValue === '') {
    return null;
  }

  let x;
  if (typeof rawValue === 'number') {
    x = rawValue;
  } else {
    x = Number.parseInt(rawValue);
  }
  if (!Number.isInteger(x)) {
    throw new Error(`Value is not a nullable integer: ${String(rawValue)}`);
  }
  return x;
}

export function toNullableDecimal(rawValue: any): null | number {
  if (typeof rawValue === 'number' || rawValue === null) {
    return rawValue;
  }

  if (rawValue === '') {
    return null;
  }

  return +rawValue;
}

export function pickFromEnum<ENUM>(
  pEnum: ENUM,
  seed: number
): ENUM[keyof ENUM] {
  const enumValues = Object.values(pEnum);
  return enumValues[seed % enumValues.length];
}

/**
 * Like pickFromEnum(), except that the field is optional, so it will sometimes
 * return null, or '', etc
 *
 * @param pEnum
 * @param seed
 * @param nullValue The value to return instead of one of the enum fields.
 */
export function pickFromEnumOptional<ENUM, T>(
  pEnum: ENUM,
  seed: number,
  nullValue: T
): ENUM[keyof ENUM] | T {
  const enumValues = Object.values(pEnum);
  const idx = seed % (enumValues.length + 1);
  return idx === 0 ? nullValue : enumValues[idx - 1];
}

type FormValues = {
  [key: string]: any;
};

export function validateIsFilled(
  formValues: FormValues,
  fieldNameToValidate: string,
  emptyValue: any
) {
  if (formValues[fieldNameToValidate]) {
    return formValues[fieldNameToValidate] !== emptyValue;
  } else {
    return false;
  }
}

export function validateIsNotGreaterThanMaxLength(
  formValues: FormValues,
  fieldNameToValidate: string,
  maxLength: number
) {
  return formValues[fieldNameToValidate].length <= maxLength;
}

export function validateCode(code: string) {
  return code && code.match(/^[A-Z0-9-]+$/);
}

/**
 * Validates whether an input is a number or a string that can be simply
 * converted to a number.
 *
 * @param input
 */
export function validateNumber(input: unknown): boolean {
  if (typeof input === 'number') {
    return !Number.isNaN(input);
  } else if (typeof input === 'string') {
    return input !== '' && !Number.isNaN(Number(input));
  } else {
    return false;
  }
}

/**
 * Validates whether an input is a number (or numeric string) that represents
 * an integer that is greater than, or equal to, zero.
 *
 * @param input
 */
export function validateNonNegativeInteger(input: unknown): boolean {
  if (!validateNumber(input)) {
    return false;
  }
  const asNumber = Number(input);
  if (asNumber < 0 || !Number.isInteger(asNumber)) {
    return false;
  }
  return true;
}

/**
 * Validates whether an input is a number (or numeric string) that represents
 * a positive integer. This is useful for validating database ID numbers and
 * reading entry positions.
 *
 * @param input
 */
export function validatePositiveInteger(input: unknown): boolean {
  if (!validateNumber(input)) {
    return false;
  }
  const asNumber = Number(input);
  if (asNumber < 1 || !Number.isInteger(asNumber)) {
    return false;
  }
  return true;
}

/**
 * Just a little user-defined typeguard to make Array.prototype.filter work
 * better.
 *
 * @private
 * @param x
 */
export function isNotNull<T>(x: T | null): x is T {
  return x !== null;
}

/**
 * Another user-defined typeguard to make Array.prototype.filter work better.
 *
 * @param x
 */
export function isTruthy<T>(
  x: T
): x is Exclude<T, undefined | false | '' | 0 | null> {
  return Boolean(x);
}
