import { Highlight } from 'react-vis';

/**
 * Like the React-Vis <Highlight> component, but it locks the aspect ratio
 * of the selected area to a square.
 */
export class SquareHighlight extends Highlight {
  _getDrawArea(xLoc: number, yLoc: number) {
    const { startLocX, startLocY } = this.state as {
      startLocX: number;
      startLocY: number;
    };

    const sideLength = Math.max(
      Math.abs(startLocX - xLoc),
      Math.abs(startLocY - yLoc)
    );

    // SVG coordinates, so (0,0) is in the upper left.
    return {
      left: startLocX < xLoc ? startLocX : startLocX - sideLength,
      right: startLocX > xLoc ? startLocX : startLocX + sideLength,
      top: startLocY < yLoc ? startLocY : startLocY - sideLength,
      bottom: startLocY > yLoc ? startLocY : startLocY + sideLength,
    };
  }

  static displayName = 'SquareHighlight';

  static defaultProps = {
    color: 'rgb(77, 182, 172)',
    className: '',
    enableX: true,
    enableY: true,
    opacity: 0.3,
  };
}
