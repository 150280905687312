import React, { useMemo, useCallback } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import {
  MyExportsListView,
  myExportsListColumns,
  myExportsListFilters,
} from './MyExportsListView';
import { useReportState } from 'hooks/use-report-state';
import { myExportsListDuck } from 'ducks/my-exports/list';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';

type OwnProps = RCPWithQueryParams<Filter.ReportsPlotSets>;

export const MyExportsListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return myExportsListColumns({
      refreshList: EMPTY_FUNC,
      deleteMedia: EMPTY_FUNC,
    });
  }, []);

  const deleteMedia = useCallback(async (id: number) => {
    return deleteApi(`/media/${id}/`);
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      myExportsListFilters,
      myExportsListDuck,
      (state) => state.myExports.list
    ),
    deleteMedia,
  };

  return <MyExportsListView {...props} />;
};
