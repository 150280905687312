import useResizeObserver from 'hooks/use-resize-observer';

export function useSpatialPlotResizeObserver(
  storedPlot: {
    paperspace_width: string;
    paperspace_height: string;
  },
  margins: {
    top: number;
    bottom: number;
    left: number;
    right: number;
  },
  defaultPlotHeightPx: number = 500
) {
  //
  // Scale the plot to fit the available space in the window
  //
  const imageAspectRatio =
    +storedPlot.paperspace_width / +storedPlot.paperspace_height;

  const leftRightMargins = margins.left + margins.right;
  const topBottomMargins = margins.top + margins.bottom;

  const [ref, widthPx] = useResizeObserver<HTMLDivElement>({
    defaultHeight: defaultPlotHeightPx,
    defaultWidth:
      (defaultPlotHeightPx - topBottomMargins) * imageAspectRatio +
      leftRightMargins,
  });

  const heightPx =
    (widthPx - leftRightMargins) / imageAspectRatio + topBottomMargins;

  return {
    ref,
    widthPx,
    heightPx,
  };
}
