import React from 'react';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import { FilterBlock, FilterControl } from './FilterBlock';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Route } from 'react-router';
import { Formik } from 'formik';
import FormChangeEffect from 'components/base/form/formchangeeffect/formchangeeffect';
import { setQueryParams, QueryParams } from 'util/routing';
import { ReportFilter } from '../report-types';
import { getDisplayableFilters } from '../report-utils';
import './FilterBlock.scss';
import { Trans } from '@lingui/macro';
import { assign } from 'lodash';

interface Props {
  filtersBackend?: Model.ReportFilterInfo[] | null;
  filtersFrontend: ReportFilter[];
  isExportMode: boolean;
  filtersToShow?: string[] | null;
  onFiltersChange?: (newQueryParams: QueryParams) => QueryParams;
}

/**
 * A smart component for displaying a block of filters for a Report API table.
 *
 * It does these things:
 * - Renders the requested filter controls
 * - Populates their values based on the current frontend URL's parameters
 * - Updates the current frontend URL's parameters when the filters are changed
 * by a user
 *
 * @param props.filtersFrontend Frontend information about the filters defined
 * for this screen. (This can include more filters than the ones we'll actually
 * display, because this list is generally shared with a ReportFilterModal
 * instance and others.)
 * @param props.filtersBackend Metadata about the filters from the backend
 * reports API. (Note this is not sufficient data to actually render the filters,
 * which is why we need additional "filtersFrontend" configuration.)
 * @param props.filtersToShow A list of names of the filters to actually display
 */
export const ReportFiltersBlock: React.FunctionComponent<Props> = function (
  props
) {
  return (
    <Route
      render={(routeProps) => {
        const filtersToRender = getDisplayableFilters(
          routeProps,
          // in SSR mode, we show all filters that are defined and has values
          props.isExportMode ? null : props.filtersToShow || null,
          props.filtersFrontend,
          props.filtersBackend
        );

        const initialValues = fromPairs(
          filtersToRender.map(({ filterName, value }) => [filterName, value])
        );

        if (props.isExportMode) {
          const filterWithValues = filtersToRender.filter(
            (filter) => filter.hasValues && Boolean(filter.frontend)
          );
          return filterWithValues.length ? (
            <div className="ssr-active-filters-block">
              <p>
                <Trans>Filtered by:</Trans>
              </p>
              <ul>
                {filterWithValues.map((filter, idx) => (
                  <li key={idx} className="ssr-active-filter">
                    <strong>{filter.frontend!.label}:</strong>{' '}
                    {filter.frontend!.renderSSR(filter)}
                  </li>
                ))}
              </ul>
            </div>
          ) : null;
        }

        return (
          <FilterBlock>
            <Formik
              enableReinitialize={true}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
              onSubmit={() => {
                /* not expected to happen... */
              }}
            >
              {(formik) => (
                <>
                  {filtersToRender.map(
                    ({ filterName, frontend, backend, hasValues }) => {
                      if (!frontend) {
                        return `[${filterName}] not defined on frontend`;
                      }
                      return (
                        <FilterControl
                          key={filterName}
                          label={frontend.label}
                          hasValues={hasValues}
                        >
                          {frontend && frontend.render(backend!)}
                        </FilterControl>
                      );
                    }
                  )}
                  <FormChangeEffect
                    onChange={async (prevValues) => {
                      const unchanged = isEqual(
                        prevValues.values,
                        formik.values
                      );

                      if (unchanged) {
                        return;
                      }

                      // When we let validation run automatically by Formik, multiple effect will be fired
                      // First - when the values changed
                      //    at this point, validation not run yet, `formik.errors` will not reflect the real validation result)
                      // Second - when the validation finished, and errors object is set
                      //    at this point, values will appeared to be unchanged
                      //
                      // That will cause issues because the values & errors can get out of sync within one Effect

                      // We have to disabled Formik automatic validation via validateOnChange={false} & validateOnBlur={false}
                      // so that we can get the validation result synchronously here
                      //
                      const errors = await formik.validateForm();

                      // On updating the form, update all the filters in the URL.
                      // But only if there are not currently any validation errors.
                      if (Object.keys(errors).length === 0) {
                        let queryParams: QueryParams = assign(
                          {},
                          ...filtersToRender.map((f) =>
                            f.frontend?.getUrlParamFromFormVal(formik.values)
                          )
                        );
                        if (props.onFiltersChange) {
                          queryParams = props.onFiltersChange(queryParams);
                        }
                        setQueryParams(routeProps, queryParams);
                      }
                    }}
                  />
                </>
              )}
            </Formik>
          </FilterBlock>
        );
      }}
    />
  );
};
