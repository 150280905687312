import React from 'react';
import lodashSet from 'lodash/set';
import { validatePositiveInteger, validateNumber } from 'util/validation';
import { Trans } from '@lingui/macro';
import { ScaleConfigFormValue } from './settingsform.types';

/**
 * Shared validation logic for `number of months` field in: `QuickPlotSettingsView`, `ScatterPlotSettingsView`
 * and `StoredPlotEditView`. It depends also on the values of `startDate` & `endDate`, but these field names
 * are not gurarantee to be the same across different forms. That's why we have *sort of* complicated typings here.
 *
 * @param value: the form values
 * @param durationField: name of the duration field
 * @param startDatetimeField: name of the startDate field
 * @param endDatetimeField: name of the endDate field
 *
 * @return: an object contains the error for any of the specifed fields (if validation failed)
 */
export function validateDuration<
  D extends string,
  S extends string,
  E extends string
>(
  values: { [key in D | S | E]?: any },
  durationField: D,
  startDatetimeField: S,
  endDatetimeField: E
) {
  const errors: any = {};

  if (
    values[durationField] !== '' &&
    !validatePositiveInteger(values[durationField])
  ) {
    errors[durationField] = (
      <Trans>Number of months must be a positive integer.</Trans>
    ) as any;
  } else if (
    values[durationField] !== '' &&
    values[startDatetimeField] &&
    values[endDatetimeField]
  ) {
    errors[durationField] = (
      <Trans>
        The number of months cannot be entered with both a start and an end
        date.
      </Trans>
    ) as any;
  } else if (
    values[startDatetimeField] &&
    values[endDatetimeField] &&
    values[startDatetimeField] > values[endDatetimeField]
  ) {
    errors[startDatetimeField] = (
      <Trans>Start date can not be later than end date</Trans>
    ) as any;
  }

  return errors;
}

export function validateCustomScale(
  scale: ScaleConfigFormValue,
  fieldPrefix: string
) {
  const errors: any = {};

  if (scale.mode === 'custom') {
    if (!validateNumber(scale.minimum)) {
      lodashSet(
        errors,
        `${fieldPrefix}.minimum`,
        <Trans>Both min and max scale values are required</Trans>
      );
    }
    if (!validateNumber(scale.maximum)) {
      lodashSet(
        errors,
        `${fieldPrefix}.maximum`,
        <Trans>Both min and max scale values are required</Trans>
      );
    }
    if (
      validateNumber(scale.minimum) &&
      validateNumber(scale.maximum) &&
      +scale.minimum >= +scale.maximum
    ) {
      lodashSet(
        errors,
        `${fieldPrefix}.minimum`,
        <Trans>Min must be less than Max</Trans>
      );
    }
  }

  return errors;
}

export function validateStartEndDatetime(
  start_datetime: string,
  end_datetime: string,
  endDatetimeRequired: boolean = false
) {
  const errors: any = {};

  if (!start_datetime) {
    lodashSet(
      errors,
      'start_datetime',
      <Trans>Start datetime is required</Trans>
    );
  }

  if (!end_datetime && endDatetimeRequired) {
    lodashSet(errors, 'end_datetime', <Trans>End datetime is required</Trans>);
  }

  if (start_datetime && end_datetime && start_datetime >= end_datetime) {
    lodashSet(
      errors,
      'end_datetime',
      <Trans>End datetime must be later than start datetime</Trans>
    );
  }

  return errors;
}
