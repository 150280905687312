/**
 * THIS FILE IS AUTOMATICALLY GENERATED
 *
 * The full list of permissions in use is in `permission_constants.py` on the
 * backend.
 *
 * All permissions in use are prefixed with the Django app label "users." when
 * sent from the backend. If you log in as a super-user you will see other
 * permissions (like "manage_data.add_data_logger"), but these are not actually
 * used by the backend.
 *
 * To update this file, run: `make dev-frontend-update-permissions`
 */
export enum User_PERMISSION {
  'can_acknowledge_alarm_report' = 'users.can_acknowledge_alarm_report',
  'can_assign_file_uploader_role' = 'users.can_assign_file_uploader_role',
  'can_bulk_change_alarm_report_status' = 'users.can_bulk_change_alarm_report_status',
  'can_bulk_create_alarm_parameters' = 'users.can_bulk_create_alarm_parameters',
  'can_bulk_create_observation_points' = 'users.can_bulk_create_observation_points',
  'can_bulk_create_sites' = 'users.can_bulk_create_sites',
  'can_cancel_alarm_report' = 'users.can_cancel_alarm_report',
  'can_cancel_reading_reprocessing_requests' = 'users.can_cancel_reading_reprocessing_requests',
  'can_copy_readings_batches' = 'users.can_copy_readings_batches',
  'can_create_alarm_notifications' = 'users.can_create_alarm_notifications',
  'can_create_alarm_parameter_comments' = 'users.can_create_alarm_parameter_comments',
  'can_create_alarm_parameters' = 'users.can_create_alarm_parameters',
  'can_create_alarm_report_comments' = 'users.can_create_alarm_report_comments',
  'can_create_area_comments' = 'users.can_create_area_comments',
  'can_create_areas' = 'users.can_create_areas',
  'can_create_checksheet_instances' = 'users.can_create_checksheet_instances',
  'can_create_checksheet_templates' = 'users.can_create_checksheet_templates',
  'can_create_client_comments' = 'users.can_create_client_comments',
  'can_create_clients' = 'users.can_create_clients',
  'can_create_dashboard_component' = 'users.can_create_dashboard_component',
  'can_create_data_loggers' = 'users.can_create_data_loggers',
  'can_create_groups' = 'users.can_create_groups',
  'can_create_instrument_types' = 'users.can_create_instrument_types',
  'can_create_manual_readings' = 'users.can_create_manual_readings',
  'can_create_media' = 'users.can_create_media',
  'can_create_media_comments' = 'users.can_create_media_comments',
  'can_create_observation_point_aliases' = 'users.can_create_observation_point_aliases',
  'can_create_observation_point_comments' = 'users.can_create_observation_point_comments',
  'can_create_observation_point_formulas' = 'users.can_create_observation_point_formulas',
  'can_create_observation_points' = 'users.can_create_observation_points',
  'can_create_plot_sets' = 'users.can_create_plot_sets',
  'can_create_reading_comments' = 'users.can_create_reading_comments',
  'can_create_reading_reprocessing_requests' = 'users.can_create_reading_reprocessing_requests',
  'can_create_readings_batches' = 'users.can_create_readings_batches',
  'can_create_route_march_comments' = 'users.can_create_route_march_comments',
  'can_create_route_marches' = 'users.can_create_route_marches',
  'can_create_site_comments' = 'users.can_create_site_comments',
  'can_create_sites' = 'users.can_create_sites',
  'can_create_stored_lists' = 'users.can_create_stored_lists',
  'can_create_stored_plots' = 'users.can_create_stored_plots',
  'can_create_user_area_groups' = 'users.can_create_user_area_groups',
  'can_create_users' = 'users.can_create_users',
  'can_delete_alarm_notifications' = 'users.can_delete_alarm_notifications',
  'can_delete_alarm_parameters' = 'users.can_delete_alarm_parameters',
  'can_delete_areas' = 'users.can_delete_areas',
  'can_delete_checksheet_instances' = 'users.can_delete_checksheet_instances',
  'can_delete_clients' = 'users.can_delete_clients',
  'can_delete_comments' = 'users.can_delete_comments',
  'can_delete_data_logger_time_dependent_fields' = 'users.can_delete_data_logger_time_dependent_fields',
  'can_delete_groups' = 'users.can_delete_groups',
  'can_delete_inspection_report_batch' = 'users.can_delete_inspection_report_batch',
  'can_delete_media' = 'users.can_delete_media',
  'can_delete_observation_point_aliases' = 'users.can_delete_observation_point_aliases',
  'can_delete_observation_point_formula_constant_values' = 'users.can_delete_observation_point_formula_constant_values',
  'can_delete_observation_point_formulas' = 'users.can_delete_observation_point_formulas',
  'can_delete_observation_point_time_dependent_fields' = 'users.can_delete_observation_point_time_dependent_fields',
  'can_delete_observation_points' = 'users.can_delete_observation_points',
  'can_delete_plot_sets' = 'users.can_delete_plot_sets',
  'can_delete_readings_batch' = 'users.can_delete_readings_batch',
  'can_delete_site_time_dependent_fields' = 'users.can_delete_site_time_dependent_fields',
  'can_delete_sites' = 'users.can_delete_sites',
  'can_delete_stored_list_items' = 'users.can_delete_stored_list_items',
  'can_delete_stored_lists' = 'users.can_delete_stored_lists',
  'can_delete_stored_plots' = 'users.can_delete_stored_plots',
  'can_delete_user_area_groups' = 'users.can_delete_user_area_groups',
  'can_disable_and_enable_alarm_notifications' = 'users.can_disable_and_enable_alarm_notifications',
  'can_disable_and_enable_alarm_parameters' = 'users.can_disable_and_enable_alarm_parameters',
  'can_edit_all_comments' = 'users.can_edit_all_comments',
  'can_edit_inspection_report' = 'users.can_edit_inspection_report',
  'can_edit_media' = 'users.can_edit_media',
  'can_edit_own_comments' = 'users.can_edit_own_comments',
  'can_edit_reading_comments_report_exclusions' = 'users.can_edit_reading_comments_report_exclusions',
  'can_execute_reading_reprocessing_requests' = 'users.can_execute_reading_reprocessing_requests',
  'can_export_lists' = 'users.can_export_lists',
  'can_initiate_data_check_alarm_report_status' = 'users.can_initiate_data_check_alarm_report_status',
  'can_list_all_users' = 'users.can_list_all_users',
  'can_list_groups' = 'users.can_list_groups',
  'can_list_roles' = 'users.can_list_roles',
  'can_list_user_area_groups' = 'users.can_list_user_area_groups',
  'can_maintain_performance_indicators' = 'users.can_maintain_performance_indicators',
  'can_process_readings' = 'users.can_process_readings',
  'can_retire_readings' = 'users.can_retire_readings',
  'can_review_checksheet_instances' = 'users.can_review_checksheet_instances',
  'can_save_reports' = 'users.can_save_reports',
  'can_upload_raw_readings' = 'users.can_upload_raw_readings',
  'can_view_alarm_comments_report' = 'users.can_view_alarm_comments_report',
  'can_view_alarm_notification_report' = 'users.can_view_alarm_notification_report',
  'can_view_alarm_notifications' = 'users.can_view_alarm_notifications',
  'can_view_alarm_parameter_report' = 'users.can_view_alarm_parameter_report',
  'can_view_alarm_report_report' = 'users.can_view_alarm_report_report',
  'can_view_all_areas' = 'users.can_view_all_areas',
  'can_view_area_report' = 'users.can_view_area_report',
  'can_view_audit_log_report' = 'users.can_view_audit_log_report',
  'can_view_checksheet_instance_report' = 'users.can_view_checksheet_instance_report',
  'can_view_checksheet_instances' = 'users.can_view_checksheet_instances',
  'can_view_checksheet_template_report' = 'users.can_view_checksheet_template_report',
  'can_view_checksheet_templates' = 'users.can_view_checksheet_templates',
  'can_view_client_report' = 'users.can_view_client_report',
  'can_view_comment_report' = 'users.can_view_comment_report',
  'can_view_data_logger_channel_report' = 'users.can_view_data_logger_channel_report',
  'can_view_data_loggers' = 'users.can_view_data_loggers',
  'can_view_data_review_report' = 'users.can_view_data_review_report',
  'can_view_dms_analyst_dashboard' = 'users.can_view_dms_analyst_dashboard',
  'can_view_inspection_reports' = 'users.can_view_inspection_reports',
  'can_view_instrument_type_report' = 'users.can_view_instrument_type_report',
  'can_view_map' = 'users.can_view_map',
  'can_view_media' = 'users.can_view_media',
  'can_view_media_report' = 'users.can_view_media_report',
  'can_view_my_exports_report' = 'users.can_view_my_exports_report',
  'can_view_observation_point_aliases' = 'users.can_view_observation_point_aliases',
  'can_view_observation_point_comments' = 'users.can_view_observation_point_comments',
  'can_view_observation_point_formula_constant_report' = 'users.can_view_observation_point_formula_constant_report',
  'can_view_observation_point_formula_report' = 'users.can_view_observation_point_formula_report',
  'can_view_observation_points' = 'users.can_view_observation_points',
  'can_view_other_user_profiles' = 'users.can_view_other_user_profiles',
  'can_view_quick_lists' = 'users.can_view_quick_lists',
  'can_view_quick_plots' = 'users.can_view_quick_plots',
  'can_view_reading_comments_report' = 'users.can_view_reading_comments_report',
  'can_view_reading_comments_report_exclusions' = 'users.can_view_reading_comments_report_exclusions',
  'can_view_reading_reprocessing_requests' = 'users.can_view_reading_reprocessing_requests',
  'can_view_readings' = 'users.can_view_readings',
  'can_view_readings_batch_report' = 'users.can_view_readings_batch_report',
  'can_view_route_march_observation_points' = 'users.can_view_route_march_observation_points',
  'can_view_route_march_schedule_report' = 'users.can_view_route_march_schedule_report',
  'can_view_route_marches' = 'users.can_view_route_marches',
  'can_view_site_report' = 'users.can_view_site_report',
  'can_view_static_data_report' = 'users.can_view_static_data_report',
  'can_view_stored_list_items' = 'users.can_view_stored_list_items',
  'can_view_stored_lists' = 'users.can_view_stored_lists',
  'can_view_stored_plot_report' = 'users.can_view_stored_plot_report',
  'can_view_uncancelled_alarm_reports' = 'users.can_view_uncancelled_alarm_reports',
  'can_view_uploaded_batches_dashboard' = 'users.can_view_uploaded_batches_dashboard',
  'can_view_user_report' = 'users.can_view_user_report',
}
