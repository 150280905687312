import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import {
  RouteMarchListObservationPointsViewProps,
  RouteMarchListObservationPointsView,
} from './listobspointsview';
import {
  fetchObservationPoints,
  fetchRouteMarchObservationPoints,
  fetchRouteMarch,
  updateRouteMarchObservationPointPosition,
  removeRouteMarchObservationPoint,
  ActionTypes,
} from '../../../ducks/routemarchobspoints';
import { RouteComponentProps } from 'react-router';
import { parseStringParamFromRouterProps } from '../../../util/routing';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { FullState } from 'main/reducers';
import { selectHasPermission } from 'util/user';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { hasSameEntries } from 'util/misc';

const mapDispatchToProps = {
  fetchObservationPoints,
  fetchRouteMarch,
  fetchRouteMarchObservationPoints,
  updateRouteMarchObservationPointPosition,
  removeRouteMarchObservationPoint,
};

type ReduxDispatchProps = typeof mapDispatchToProps;

type ReduxStateProps = Omit<
  RouteMarchListObservationPointsViewProps,
  'onRemoveObsPointClick' | 'refreshList'
>;

type Props = ResolveThunks<ReduxDispatchProps> & ReduxStateProps;

class _RouteMarchListObservationPointScreen extends React.Component<Props> {
  static mapStateToProps(
    state: FullState,
    ownProps: RouteComponentProps
  ): ReduxStateProps {
    const routeMarchCode = parseStringParamFromRouterProps(
      ownProps,
      'routeMarchCode'
    );

    return {
      ...state.routeMarchObservationPoint,
      routeMarchCode,
      history: ownProps.history,
      hasCreateCommentPermission: selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_route_march_comments
      ),
      hasCreateRouteMarchesPermission: selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_route_marches
      ),
    };
  }

  componentDidMount() {
    const { routeMarchCode } = this.props;
    this.props.fetchRouteMarch(routeMarchCode);
    this.props.fetchRouteMarchObservationPoints(routeMarchCode);
  }

  componentDidUpdate() {
    const { routeMarchCode } = this.props;

    if (
      this.props.routeMarch.routeMarchData &&
      !hasSameEntries(
        this.props.routeMarch.routeMarchData.areas,
        this.props.observationPoints.areaIds
      )
    ) {
      this.props.fetchObservationPoints(
        this.props.routeMarch.routeMarchData.areas
      );
    }
    // Check if route march code in url changed
    else if (
      this.props.routeMarch.routeMarchData &&
      routeMarchCode !== this.props.routeMarch.routeMarchData.code
    ) {
      this.props.fetchRouteMarch(routeMarchCode);
      this.props.fetchRouteMarchObservationPoints(routeMarchCode);
    }
  }

  removeObsPointClick = async (
    routeMarchObservationPointId: number,
    hideModal: () => void
  ) => {
    const result: any = await this.props.removeRouteMarchObservationPoint(
      routeMarchObservationPointId
    );
    if (result.type === ActionTypes.REMOVE_ROUTE_MARCH_POSITION_RESPONSE) {
      hideModal();
    }
  };

  refreshList = async () => {
    this.props.fetchRouteMarchObservationPoints(this.props.routeMarchCode);
  };

  onDragEnd = (result: DropResult) => {
    if (
      result.destination &&
      result.source.index !== result.destination.index
    ) {
      const routeMarchObservationPoint =
        this.props.routeMarchObservationPoints[result.source.index];
      const newPosition =
        this.props.routeMarchObservationPoints[result.destination.index]
          .position;
      this.props.updateRouteMarchObservationPointPosition(
        routeMarchObservationPoint,
        newPosition
      );
    }
  };

  render() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <RouteMarchListObservationPointsView
          {...this.props}
          onRemoveObsPointClick={this.removeObsPointClick}
          refreshList={this.refreshList}
        />
      </DragDropContext>
    );
  }
}

const RouteMarchListObservationPointScreen = connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RouteComponentProps,
  FullState
>(
  _RouteMarchListObservationPointScreen.mapStateToProps,
  mapDispatchToProps
)(_RouteMarchListObservationPointScreen);

export default RouteMarchListObservationPointScreen;
