import React, { useMemo, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Formik, Form, FormikErrors } from 'formik';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import { Trans } from '@lingui/macro';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import { YesNoToggleField } from 'components/base/form/toggle-field/ToggleField';
import { AlarmNotification } from 'util/backendapi/types/Model';
import ModalContent from 'components/base/modal/modalcontent';
import { getApi } from 'util/backendapi/fetch';
import { alarmNotificationListDuck } from 'ducks/alarmNotification/list';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import {
  OnSearchFunc,
  LoadDefaultsFunc,
} from 'components/base/form/asyncsimpleselect/asyncsimpleselect';
import { makeObsPointsMenuOptions } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { Model } from 'util/backendapi/models/api.interfaces';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchAlarmNotification,
  updateAlarmNotification,
  createAlarmNotification,
} from 'ducks/alarmNotification/detail';
import { FullState } from 'main/reducers';
import Loading from 'components/base/loading/loading';
import { useIsMounted } from 'util/hooks';
import { useGetApi } from 'hooks/use-get-api';
import { getExpectedFields } from 'util/backendapi/error';
import { ReportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from 'components/modules/async-menu/ObsPointMenu';

interface AlarmNotificationFormProps {
  hideModal: () => void;
  alarmNotificationId?: number;
}

export const AlarmNotificationForm = (props: AlarmNotificationFormProps) => {
  const dispatch = useDispatch();
  const isMounted = useIsMounted();
  const detailState = useSelector(
    (state: FullState) => state.alarmNotification.detail
  );

  const [users] = useGetApi('/users/');
  const [areas] = useGetApi('/areas/');
  const [dataLoggers] = useGetApi('/data-loggers/');

  const userOptions = useMemo(() => {
    return (users.data || []).map(ReportFilterMenu.USER_MENU);
  }, [users.data]);

  const areaOptions = useMemo(() => {
    return (areas.data || []).map(ReportFilterMenu.CODE_AND_NAME_MENU);
  }, [areas.data]);

  const dataLoggerOptions = useMemo(() => {
    return (dataLoggers.data || []).map(
      (dataLogger: Model.DataLoggerDecorated) => ({
        value: dataLogger.id,
        label: dataLogger.logger_number,
      })
    );
  }, [dataLoggers.data]);

  useEffect(() => {
    if (props.alarmNotificationId) {
      dispatch(fetchAlarmNotification(props.alarmNotificationId));
    }
  }, [dispatch, props.alarmNotificationId]);

  const onSearchObsPoints = useCallback(async (searchText: string) => {
    return makeObsPointsMenuOptions(
      await getApi('/observation-points/', {
        code__icontains: searchText,
        fields: ['id', 'code'],
        limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
      })
    );
  }, []);

  const loadDefaultsObsPoints = useCallback(async (value: number[]) => {
    return makeObsPointsMenuOptions(
      await getApi('/observation-points/', {
        id__in: value,
        fields: ['id', 'code'],
      })
    );
  }, []);

  const handleSubmit = useCallback(
    async (values: AlarmNotificationFormValues, id?: null | number) => {
      if (id) {
        await dispatch(
          updateAlarmNotification(
            id,
            values as PartialWithoutID<Model.AlarmNotification>
          )
        );
      } else {
        await dispatch(
          createAlarmNotification(values as WithoutID<AlarmNotification>)
        );
      }

      if (!isMounted()) {
        return;
      }
      dispatch(alarmNotificationListDuck.refreshReportList());
    },
    [dispatch, isMounted]
  );

  return (
    <AlarmNotificationFormView
      {...props}
      isLoading={detailState.loading || users.isLoading || areas.isLoading}
      alarmNotification={detailState.record}
      areaOptions={areaOptions}
      userOptions={userOptions}
      dataLoggerOptions={dataLoggerOptions}
      onSearchObsPoints={onSearchObsPoints}
      loadDefaultsObsPoints={loadDefaultsObsPoints}
      onSubmit={handleSubmit}
    />
  );
};

export interface AlarmNotificationFormViewProps
  extends AlarmNotificationFormProps {
  isLoading: boolean;
  alarmNotification: null | AlarmNotification;
  areaOptions: SimpleSelectOption<number>[];
  userOptions: SimpleSelectOption<number>[];
  dataLoggerOptions: SimpleSelectOption<number>[];
  onSearchObsPoints: OnSearchFunc<any>;
  loadDefaultsObsPoints: LoadDefaultsFunc<any, any>;
  onSubmit: (
    values: AlarmNotificationFormValues,
    id?: null | number
  ) => Promise<any>;
}

type AlarmNotificationFormValues = Merge<
  WithoutID<Model.AlarmNotification>,
  {
    user?: null | number;
  }
>;

const validate = (values: AlarmNotificationFormValues) => {
  let errors: FormikErrors<AlarmNotificationFormValues> = {};

  if (!values.user) {
    errors.user = (<Trans>Please select a person</Trans>) as any;
  }

  const exclusiveTypeCount = [
    values.areas,
    values.data_loggers,
    values.observation_points,
  ].filter((type) => !isEmpty(type)).length;

  if (exclusiveTypeCount === 0) {
    errors.areas = (
      <Trans>Please select either Area, Data logger or Observation point</Trans>
    ) as any;
  }

  if (exclusiveTypeCount > 1) {
    errors.areas = (
      <Trans>
        You must select only one of either Area, Data logger or Observation
        point
      </Trans>
    ) as any;
  }

  return errors;
};

export const AlarmNotificationFormView = (
  props: AlarmNotificationFormViewProps
) => {
  const initialValues: AlarmNotificationFormValues = useMemo(() => {
    if (props.alarmNotification) {
      return props.alarmNotification;
    }

    return {
      user: null,
      observation_points: [],
      areas: [],
      data_loggers: [],
      include_design_check: true,
      include_alert: true,
      include_data_check: true,
      send_email: true,
      send_sms: true,
      status: 'enabled',
    };
  }, [props.alarmNotification]);

  return (
    <ModalContent
      header={
        props.alarmNotificationId ? (
          <Trans>Edit alarm notification</Trans>
        ) : (
          <Trans>Create an alarm notification</Trans>
        )
      }
    >
      {props.isLoading ? (
        <Loading />
      ) : (
        <Formik<AlarmNotificationFormValues>
          initialValues={initialValues}
          validate={validate}
          onSubmit={async (values, formik) => {
            try {
              await props.onSubmit(values, props.alarmNotificationId);
              props.hideModal();
            } catch (err) {
              formik.setSubmitting(false);
              showErrorsInFormik(
                formik,
                err,
                getExpectedFields(values),
                ErrorNotice
              );
            }
          }}
        >
          {(formik) => (
            <Form>
              <FormSection>
                <FormItem label={<Trans>Person</Trans>} fieldId="user">
                  <SimpleSelectField
                    name="user"
                    id="alarm-notification-user"
                    options={props.userOptions}
                    placeholder={<Trans>Select a person ...</Trans>}
                    autoFocus
                  />
                  <FieldError name="user" />
                </FormItem>
                <FormItem label={<Trans>Area</Trans>} fieldId="areas">
                  <SimpleSelectField
                    name="areas"
                    id="alarm-notification-area"
                    options={props.areaOptions}
                    isMulti
                    isDisabled={
                      !isEmpty(formik.values.data_loggers) ||
                      !isEmpty(formik.values.observation_points)
                    }
                    placeholder={<Trans>Select an area ...</Trans>}
                  />
                  <FieldError name="areas" />
                </FormItem>
                <FormItem
                  label={<Trans>Data logger</Trans>}
                  fieldId="data_loggers"
                >
                  <SimpleSelectField
                    name="data_loggers"
                    id="alarm-notification-data-logger"
                    options={props.dataLoggerOptions}
                    isMulti
                    isDisabled={
                      !isEmpty(formik.values.areas) ||
                      !isEmpty(formik.values.observation_points)
                    }
                    placeholder={<Trans>Select a data logger ...</Trans>}
                  />
                  <FieldError name="data_loggers" />
                </FormItem>
                <FormItem
                  label={<Trans>Observation point</Trans>}
                  fieldId="observation_points"
                >
                  <AsyncSimpleSelectField
                    name="observation_points"
                    isDisabled={
                      !isEmpty(formik.values.areas) ||
                      !isEmpty(formik.values.data_loggers)
                    }
                    isMulti
                    onSearch={props.onSearchObsPoints}
                    loadDefaults={props.loadDefaultsObsPoints}
                    placeholder={<Trans>Select an observation point ...</Trans>}
                  />
                  <FieldError name="observation_points" />
                </FormItem>
              </FormSection>
              <FormSection label={<Trans>Alarm level</Trans>}>
                <YesNoToggleField
                  name="include_design_check"
                  label={<Trans>Design check</Trans>}
                />
                <YesNoToggleField
                  name="include_alert"
                  label={<Trans>Alert</Trans>}
                />
                <YesNoToggleField
                  name="include_data_check"
                  label={<Trans>Data check</Trans>}
                />
              </FormSection>
              <FormSection label={<Trans>Notification method</Trans>}>
                <YesNoToggleField
                  name="send_email"
                  label={<Trans>Email</Trans>}
                />
                <YesNoToggleField name="send_sms" label={<Trans>SMS</Trans>} />
              </FormSection>
              <ActionBlock>
                <ButtonHideModal id="edit-alarm-notification-cancel" />
                <ButtonPrimary
                  id="edit-alarm-notification-submit"
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  {props.alarmNotificationId ? (
                    <Trans>Save</Trans>
                  ) : (
                    <Trans>Create</Trans>
                  )}
                </ButtonPrimary>
              </ActionBlock>
              {formik.status}
            </Form>
          )}
        </Formik>
      )}
    </ModalContent>
  );
};
