import React, { useMemo, useCallback } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';
import { areaListDuck } from 'ducks/area/list';
import { areaListFilters, areaListColumns, AreaListView } from './AreaListView';

type OwnProps = RCPWithQueryParams<Filter.ReportsSites>;

export const AreaListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return areaListColumns({
      refreshList: EMPTY_FUNC,
      deleteArea: EMPTY_FUNC,
    });
  }, []);

  const deleteArea = useCallback(async (id: number) => {
    return deleteApi(`/areas/${id}/`);
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      areaListFilters,
      areaListDuck,
      (state) => state.area.list
    ),
    deleteArea,
  };

  return <AreaListView {...props} />;
};
