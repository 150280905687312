import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import { Model } from 'util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions } from 'main/store';
import { postApi, patchApi, getApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { formatDatetimeForStorage } from 'util/dates';
import { isTruthy } from 'util/validation';

export const ActionTypes = {
  EXPAND_FORMULA_HISTORY: 'dms/obsPoint/detail/formula/EXPAND_FORMULA_HISTORY',
  COLLAPSE_FORMULA_HISTORY:
    'dms/obsPoint/detail/formula/COLLAPSE_FORMULA_HISTORY',
  FETCH_FORMULA_HISTORY_START:
    'dms/obsPoint/detail/formula/FETCH_FORMULA_HISTORY_START',
  FETCH_FORMULA_HISTORY_RESPONSE:
    'dms/obsPoint/detail/formula/FETCH_FORMULA_HISTORY_RESPONSE',
  FETCH_FORMULA_HISTORY_ERROR:
    'dms/obsPoint/detail/formula/FETCH_FORMULA_HISTORY_ERROR',
  CHANGE_OPF_FORMULA: 'dms/obsPoint/detail/formula/CHANGE_OPF_FORMULA',
  CHANGE_OPF_FORMULA_ERROR:
    'dms/obsPoint/detail/formula/CHANGE_OPF_FORMULA_ERROR',
  CHANGE_OPF_FORMULA_RESPONSE:
    'dms/obsPoint/detail/formula/CHANGE_OPF_FORMULA_RESPONSE',
  MODIFY_OPF_DETAILS: 'dms/obsPoint/detail/formula/MODIFY_OPF_DETAILS',
  MODIFY_OPF_DETAILS_ERROR:
    'dms/obsPoint/detail/formula/MODIFY_OPF_DETAILS_ERROR',
  MODIFY_OPF_DETAILS_RESPONSE:
    'dms/obsPoint/detail/formula/MODIFY_OPF_DETAILS_RESPONSE',
} as const;

export const ActionCreators = {
  EXPAND_FORMULA_HISTORY: () => ({
    type: ActionTypes.EXPAND_FORMULA_HISTORY,
  }),
  COLLAPSE_FORMULA_HISTORY: () => ({
    type: ActionTypes.COLLAPSE_FORMULA_HISTORY,
  }),
  FETCH_FORMULA_HISTORY_START: (
    observationPoint: Model.ObservationPointDecorated
  ) => ({
    type: ActionTypes.FETCH_FORMULA_HISTORY_START,
    payload: observationPoint,
  }),
  FETCH_FORMULA_HISTORY_RESPONSE: (
    observationPoint: Model.ObservationPointDecorated,
    formulaSnapshots: Model.ObservationPointFormulaSnapshot[],
    siteDecorated: Model.SiteDecorated,
    relatedObservationPoints: Model.ObservationPointDecorated[]
  ) => ({
    type: ActionTypes.FETCH_FORMULA_HISTORY_RESPONSE,
    payload: {
      observationPoint,
      formulaSnapshots,
      siteDecorated,
      relatedObservationPoints,
    },
  }),
  FETCH_FORMULA_HISTORY_ERROR: (
    observationPoint: Model.ObservationPointDecorated,
    errorMessage: string
  ) => ({
    type: ActionTypes.FETCH_FORMULA_HISTORY_ERROR,
    payload: {
      observationPoint,
      errorMessage,
    },
  }),
  CHANGE_OPF_FORMULA: () => ({
    type: ActionTypes.CHANGE_OPF_FORMULA,
    meta: {
      section: 'formula' as 'formula',
    },
  }),
  CHANGE_OPF_FORMULA_ERROR: (errorMessage: string) => ({
    type: ActionTypes.CHANGE_OPF_FORMULA_ERROR,
    meta: {
      section: 'formula' as 'formula',
    },
    error: true,
    payload: errorMessage,
  }),
  CHANGE_OPF_FORMULA_RESPONSE: () => ({
    type: ActionTypes.CHANGE_OPF_FORMULA_RESPONSE,
    meta: {
      section: 'formula' as 'formula',
    },
  }),
  MODIFY_OPF_DETAILS: () => ({
    type: ActionTypes.MODIFY_OPF_DETAILS,
    meta: {
      section: 'formula' as 'formula',
    },
  }),
  MODIFY_OPF_DETAILS_ERROR: (errorMessage: string) => ({
    type: ActionTypes.MODIFY_OPF_DETAILS_ERROR,
    meta: {
      section: 'formula' as 'formula',
    },
    error: true,
    payload: errorMessage,
  }),
  MODIFY_OPF_DETAILS_RESPONSE: () => ({
    type: ActionTypes.MODIFY_OPF_DETAILS_RESPONSE,
    meta: {
      section: 'formula' as 'formula',
    },
  }),
};

export type ObsPointDetailFormulaAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export const expandFormulaHistory = ActionCreators.EXPAND_FORMULA_HISTORY;
export const collapseFormulaHistory = ActionCreators.COLLAPSE_FORMULA_HISTORY;

export interface ObsPointDetailFormulaState {
  errorMessage: string | null;
  formulaSnapshots: Model.ObservationPointFormulaSnapshot[] | null;
  isHistoryExpanded: boolean;
  isLoading: boolean;
  // We keep a reference to the observation point that we were fetching the
  // history for, so that we can tell when we need to re-fetch history.
  historyForObservationPoint: Model.ObservationPointDecorated | null;
  // We need a decorated copy of the site to get time dependent field values.
  siteDecorated: Model.SiteDecorated | null;
  relatedObservationPoints: Model.ObservationPointDecorated[] | null;
}

function initialState(): ObsPointDetailFormulaState {
  return {
    errorMessage: null,
    formulaSnapshots: null,
    isHistoryExpanded: false,
    historyForObservationPoint: null,
    isLoading: false,
    siteDecorated: null,
    relatedObservationPoints: null,
  };
}

export function obsPointDetailFormulaReducer(
  state = initialState(),
  action: ObsPointDetailFormulaAction
): ObsPointDetailFormulaState {
  switch (action.type) {
    case ActionTypes.FETCH_FORMULA_HISTORY_START:
      return {
        ...state,
        isLoading: true,
        historyForObservationPoint: action.payload,
        errorMessage: null,
        formulaSnapshots: null,
        siteDecorated: null,
      };
    case ActionTypes.FETCH_FORMULA_HISTORY_ERROR:
      if (
        action.payload.observationPoint === state.historyForObservationPoint
      ) {
        return {
          ...state,
          isLoading: false,
          errorMessage: action.payload.errorMessage,
        };
      } else {
        return state;
      }
    case ActionTypes.FETCH_FORMULA_HISTORY_RESPONSE:
      if (
        action.payload.observationPoint === state.historyForObservationPoint
      ) {
        return {
          ...state,
          isLoading: false,
          ...action.payload,
        };
      } else {
        return state;
      }
    case ActionTypes.EXPAND_FORMULA_HISTORY:
      return {
        ...state,
        isHistoryExpanded: true,
      };
    case ActionTypes.COLLAPSE_FORMULA_HISTORY:
      return {
        ...state,
        isHistoryExpanded: false,
      };
    default:
      return state;
  }
}

export function fetchFormulaHistory(
  observationPoint: Model.ObservationPointDecorated
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_FORMULA_HISTORY_START(observationPoint));
    try {
      const [formulaSnapshots, siteDecorated] = await Promise.all([
        getApi(`/observation-points/${observationPoint.id}/formula-snapshots/`),
        getApi(`/sites/${observationPoint.site.id}/`),
      ]);

      // For display purposes, fetch details about all the related observation
      // points used in compensation inputs, summation inputs, etc.
      const idsOfRelatedObsPoints = uniq(
        formulaSnapshots.flatMap((snapshot) =>
          Object.values(snapshot.observation_point_formula_inputs).flatMap(
            (opfi) =>
              [opfi.compensation_observation_point].concat(
                opfi.dependency_observation_points.map(
                  (dop) => dop.observation_point
                )
              )
          )
        )
      ).filter(isTruthy);

      const relatedObsPoints =
        idsOfRelatedObsPoints.length === 0
          ? []
          : await getApi('/observation-points/', {
              id__in: idsOfRelatedObsPoints,
            });

      return dispatch(
        ActionCreators.FETCH_FORMULA_HISTORY_RESPONSE(
          observationPoint,
          // We need the snapshots in reverse chronological order.
          sortBy(formulaSnapshots, 'start_datetime').reverse(),
          siteDecorated,
          relatedObsPoints
        )
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_FORMULA_HISTORY_ERROR(
          observationPoint,
          errorToString(e)
        )
      );
    }
  };
}

/**
 * A thunk for when a user changes the formula for an observation point.
 * This requires us to create new observation point formula (OPF),
 * observation point formula input (OPFI) and observation point formula
 * constant value (OPFCV) records.
 *
 * @param {*} observationPointId
 * @param {*} formulaId
 * @param {*} obsPointFormulaStartDatetime
 * @param {*} obsPointFormulaInputs
 * @param {*} obsPointFormulaConstantValues
 */
export function changeSelectedFormula(
  observationPointFormula: ForPostOrPut<Model.ObservationPointFormula>,
  obsPointFormulaInputs: {
    [var_name in string]: ForPostOrPut<
      Omit<Model.ObservationPointFormulaInput, 'observation_point_formula'>
    >;
  },
  obsPointFormulaConstantValues: {
    [var_name in string]: ForPostOrPut<
      Omit<
        Model.ObservationPointFormulaConstantValue,
        'observation_point_formula'
      >
    >;
  }
): StandardThunk {
  return async function (dispatch) {
    try {
      dispatch(ActionCreators.CHANGE_OPF_FORMULA());

      // First, create the new OPF record, because we'll need to add its ID
      // to the new OPFCV and OPFI records.
      const createdOpf = await postApi(
        '/observation-point-formulas/',
        observationPointFormula
      );

      // TODO: Handling nested errors...
      await Promise.all([
        // Create new observation point formula inputs
        Promise.all(
          Object.values(obsPointFormulaInputs).map((opfi) =>
            postApi('/observation-point-formula-inputs/', {
              ...opfi,
              observation_point_formula: createdOpf.id,
            })
          )
        ),

        // Create new observation point formula constants
        Promise.all(
          Object.values(obsPointFormulaConstantValues).map((opfc) =>
            postApi('/observation-point-formula-constant-values/', {
              ...opfc,
              observation_point_formula: createdOpf.id,
            })
          )
        ),
      ]);

      dispatch(ActionCreators.CHANGE_OPF_FORMULA_RESPONSE());
    } catch (e) {
      dispatch(ActionCreators.CHANGE_OPF_FORMULA_ERROR(errorToString(e)));
      // TODO: Nested errors from the opfi and opfcv requests...
      throw e;
    }
  };
}

/**
 * Modify an observation point formula's inputs and constants.
 * This is a different operation from changing the observation point's active
 * formula, because it will sometimes just update the OPFI's and OPFCV's, and
 * sometimes create new ones.
 *
 * @param {*} newOPFIs
 * @param {*} newOPFCVs
 * @param {*} oldOPFIs
 * @param {*} oldOPFCVs
 */
export function modifyObsPointFormulaDetails(
  newOPFIs: {
    [var_name in string]: ForPostOrPut<Model.ObservationPointFormulaInput>;
  },
  newOPFCVs: {
    [var_name in string]: ForPostOrPut<Model.ObservationPointFormulaConstantValue>;
  },
  oldOPF: Model.ObservationPointFormulaSnapshot
): StandardThunk {
  return async function (dispatch) {
    try {
      dispatch(ActionCreators.MODIFY_OPF_DETAILS());

      // TODO: Handling nested errors
      await Promise.all([
        // Create/Update OPFIs
        Promise.all(
          Object.entries(newOPFIs).map(async ([var_name, newOpfi]) => {
            const origOpfi = oldOPF.observation_point_formula_inputs[var_name];
            if (
              !origOpfi ||
              newOpfi.start_datetime !==
                formatDatetimeForStorage(origOpfi.start_datetime)
            ) {
              // If they changed the start datetime, make a new record.
              return postApi('/observation-point-formula-inputs/', newOpfi);
            } else {
              // If they did not change the start datetime, just update the
              // opfi.
              const {
                formula_input,
                observation_point_formula,
                start_datetime,
                ...editableFields
              } = newOpfi;
              return patchApi(
                `/observation-point-formula-inputs/${origOpfi.id}/`,
                editableFields
              );
            }
          })
        ),

        // Create/Update OPFCVs
        Promise.all(
          Object.entries(newOPFCVs).map(async ([var_name, newOpfcv]) => {
            const origOpfcv =
              oldOPF.observation_point_formula_constant_values[var_name];
            if (
              !origOpfcv ||
              newOpfcv.start_datetime !==
                formatDatetimeForStorage(origOpfcv.start_datetime)
            ) {
              // If they changed the start datetime, make a new record.
              return postApi(
                '/observation-point-formula-constant-values/',
                newOpfcv
              );
            } else {
              // If they did not change the start datetime, just update the
              // opfcv
              const {
                formula_constant,
                observation_point_formula,
                start_datetime,
                ...editableFields
              } = newOpfcv;
              return patchApi(
                `/observation-point-formula-constant-values/${origOpfcv.id}/`,
                editableFields
              );
            }
          })
        ),
      ]);

      dispatch(ActionCreators.MODIFY_OPF_DETAILS_RESPONSE());
    } catch (e) {
      dispatch(ActionCreators.MODIFY_OPF_DETAILS_ERROR(errorToString(e)));
      throw e;
    }
  };
}
