import React from 'react';
import { EditMediaModal } from './EditMediaModal';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { Trans, Plural, t } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { Model } from 'util/backendapi/models/api.interfaces';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import {
  ACTION_COLUMN,
  DEFAULT_SHOW,
  DEFAULT_HIDE,
  ReportColumn,
  ReportFilter,
} from 'components/modules/report/report-types';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import { formatDatetimeForDisplay } from 'util/dates';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { Icon } from 'components/base/icon/icon';
import { reportFilterObservationPoints } from 'components/modules/report/filter/fields/reportFilterObservationPoints';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { ReportStateProps } from 'hooks/use-report-state';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { reportFilterSites } from 'components/modules/report/filter/fields/reportFilterSites';
import { reportFilterDatetime } from 'components/modules/report/filter/fields/FilterDate';
import { ButtonShowCommentsPanel } from 'components/modules/comments-panel/ButtonShowCommentsPanel';
import { Comment_RESOURCE_TYPE } from 'util/backendapi/types/Enum';
import { getApi } from 'util/backendapi/fetch';
import { reportFilterAsyncMenu } from 'components/modules/report/filter/fields/FilterAsyncMenu';
import { printAsMegabytes } from 'util/misc';

export interface MediaListViewProps {
  reportProps: ReportStateProps<Model.ReportsMedia>;
  deleteMedia: (id: number) => Promise<any>;
}

export const mediaListColumns: (props: {
  refreshList: () => void;
  deleteMedia: (id: number) => Promise<any>;
}) => ReportColumn<Model.ReportsMedia>[] = ({ refreshList, deleteMedia }) => [
  {
    label: <Trans>File name</Trans>,
    name: 'file_name',
    className: 'table-cell-wrap',
    visibility: DEFAULT_SHOW,
    additionalFields: ['file'],
    accessor: ({ file, file_name }) => (
      <a href={file} rel="noopener noreferrer">
        {file_name}
      </a>
    ),
  },
  {
    label: <Trans>File type</Trans>,
    name: 'file_type',
    visibility: DEFAULT_SHOW,
    accessor: ({ file_type }) =>
      file_type && <TransEnum enum="Media_FILE_TYPE" value={file_type} />,
  },
  {
    label: <Trans>Content type</Trans>,
    name: 'content_type',
    visibility: DEFAULT_SHOW,
    accessor: ({ content_type }) =>
      content_type && (
        <TransEnum enum="Media_CONTENT_TYPE" value={content_type} />
      ),
  },
  {
    label: <Trans>File size</Trans>,
    name: 'file_size',
    visibility: DEFAULT_SHOW,
    accessor: ({ file_size }) => file_size && printAsMegabytes(file_size),
  },
  {
    label: <Trans>Uploaded by</Trans>,
    name: 'uploaded_by',
    backendFieldName: 'uploaded_by__profile__name',
    additionalFields: ['uploaded_by__username'],
    visibility: DEFAULT_SHOW,
    accessor: ({ uploaded_by__profile__name, uploaded_by__username }) =>
      `${uploaded_by__profile__name} (${uploaded_by__username})`,
  },
  {
    label: <Trans>Uploaded datetime</Trans>,
    name: 'uploaded_datetime',
    visibility: DEFAULT_SHOW,
    accessor: ({ uploaded_datetime }) =>
      formatDatetimeForDisplay(uploaded_datetime),
  },
  {
    label: <Trans>Observation points</Trans>,
    name: 'observation_points',
    visibility: DEFAULT_SHOW,
  },
  {
    label: <Trans>Areas</Trans>,
    name: 'areas',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Sites</Trans>,
    name: 'sites',
    visibility: DEFAULT_HIDE,
  },
  {
    label: <Trans>Description</Trans>,
    name: 'description',
    visibility: DEFAULT_HIDE,
  },
  {
    ...ACTION_COLUMN,
    additionalFields: ['id', 'file', 'file_name', 'comment_count'],
    accessor: ({ id, file, file_name, comment_count }) => (
      <>
        <a href={file} target="_blank" rel="noopener noreferrer">
          <Icon type="icon-view" title={t`View`} />
        </a>
        <HasPermission check="can_edit_media">
          <ButtonShowModal
            className="btn-link-panel"
            name="edit-media"
            modalContent={({ hideModal }) => (
              <EditMediaModal
                mediaId={id}
                onAfterSubmit={() => {
                  hideModal();
                  refreshList();
                }}
              />
            )}
            iconType="icon-edit"
            iconOnly={true}
            title={t`Edit`}
          >
            <Trans>Edit</Trans>
          </ButtonShowModal>
        </HasPermission>
        <HasPermission check="can_delete_media">
          <ButtonShowConfirmation
            name="delete-media"
            title={t`Delete`}
            iconType="icon-circle-minus"
            className="btn-link-panel"
            content={
              <Trans>
                Are you sure you want to delete <strong>{file_name}</strong>?
                This action is not reversible.
              </Trans>
            }
            okBtnText={<Trans>Yes, delete</Trans>}
            onConfirm={async function () {
              await deleteMedia(id!);
              refreshList();
            }}
          />
        </HasPermission>
        <HasPermission check="can_create_media_comments">
          <ButtonShowCommentsPanel
            type={Comment_RESOURCE_TYPE.media}
            description={
              <h4 className="panel-subheader">
                <span>{file_name}</span>
              </h4>
            }
            metadata={{
              media: id!,
            }}
            onCommentAdded={() => {
              refreshList();
            }}
            buttonProps={{
              iconOnly: true,
              className: 'btn-link-panel',
              badge: comment_count,
            }}
          />
        </HasPermission>
      </>
    ),
  },
];

export const mediaListFilters: ReportFilter[] = [
  reportFilterMenu(
    'content_type',
    <Trans>Content type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('Media_CONTENT_TYPE'),
    {
      placeholder: <Trans>All content types</Trans>,
    }
  ),
  reportFilterObservationPoints('observation_point', {
    placeholder: <Trans>All observation points</Trans>,
  }),
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All areas</Trans> }
  ),
  reportFilterMenu(
    'uploaded_by',
    <Trans>Uploaded by</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.USER_MENU,
    { placeholder: <Trans>All DMS users</Trans> }
  ),
  reportFilterSites('site', { placeholder: <Trans>All sites</Trans> }),
  reportFilterMenu(
    'file_type',
    <Trans>File type</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.ENUM_MENU('Media_FILE_TYPE'),
    { placeholder: <Trans>All file types</Trans> }
  ),
  reportFilterMenu(
    'file_format',
    <Trans>File format</Trans>,
    { isMulti: true, valueType: 'string' },
    ReportFilterMenu.STRING_OPTION_LIST_MENU,
    { placeholder: <Trans>All file formats</Trans> }
  ),
  reportFilterDatetime('uploaded_datetime', <Trans>Uploaded date</Trans>),
  // HACK: This filter isn't intended to have its menu displayed. It's only used via the URL.
  reportFilterAsyncMenu('reading', <Trans>Reading</Trans>, {
    isMulti: true,
    valueType: 'number',
    // onSearch not needed, since we won't be displaying this one.
    onSearch: async () => [],
    // loadDefaults _is_ needed, so we have something to show during PDF rendering.
    loadDefaults: async (values) => {
      const readings = await getApi('/readings/', { id__in: values });
      return readings.map((reading) => ({
        value: reading.id,
        label: `${reading.observation_point.code} ${formatDatetimeForDisplay(
          reading.reading_datetime,
          reading.time_zone.name
        )}`,
      }));
    },
  }),
  // HACK: This filter isn't intended to have its menu displayed. It's only used via the URL.
  reportFilterAsyncMenu('readings_batch', <Trans>Batch</Trans>, {
    isMulti: true,
    valueType: 'number',
    // onSearch not needed, since we won't be displaying this one.
    onSearch: async () => [],
    // loadDefaults _is_ needed, so we have something to show during PDF rendering.
    loadDefaults: async (values) => {
      const batches = await getApi('/readings-batches/', { id__in: values });
      return batches.map((batch) => ({
        value: batch.id,
        label: batch.batch_number,
      }));
    },
  }),
];

export function MediaListView(props: MediaListViewProps) {
  const columns = mediaListColumns({
    refreshList: props.reportProps.refreshList,
    deleteMedia: props.deleteMedia,
  });
  const filters = mediaListFilters;
  const { reportProps } = props;
  return (
    <PageStandard
      name="media-list"
      header={
        reportProps.isExportMode ? (
          <Trans>Media report</Trans>
        ) : (
          <Trans>Maintain Media</Trans>
        )
      }
    >
      <div className="page-content-header-filters-actions">
        <ActionBlock className="text-right">
          <HasPermission check="can_create_media">
            <ButtonShowModal
              name="upload-media"
              iconType="icon-upload"
              shortcut="CREATE_NEW"
              modalContent={({ hideModal }) => (
                <EditMediaModal
                  onAfterSubmit={() => {
                    hideModal();
                    props.reportProps.refreshList();
                  }}
                />
              )}
            >
              <Trans>Upload media</Trans>
            </ButtonShowModal>
          </HasPermission>
        </ActionBlock>

        <ReportFiltersBlock
          filtersBackend={
            reportProps.reportInfo && reportProps.reportInfo.filters
          }
          filtersFrontend={filters}
          filtersToShow={[
            'content_type',
            'observation_point',
            'area',
            'uploaded_by',
          ]}
          isExportMode={reportProps.isExportMode}
        />
      </div>
      <div className="filtered-table-wrapper">
        <ActionBlock className="filtered-table-options">
          <ButtonPrint />
          <SaveReportModalButtons
            columnsFrontend={columns}
            filtersFrontend={filters}
            reportInfo={reportProps.reportInfo}
          />
          <ExportReportButton
            fileNameBase={t`Media report`}
            reportUrl="/reports/media/"
            columns={columns}
            filters={filters}
          />
          <AdvancedFiltersModalButton
            filtersBackend={reportProps.reportInfo?.filters}
            filtersFrontend={filters}
            sections={[
              {
                name: 'menu_section',
                filters: ['observation_point', 'site', 'area', 'uploaded_by'],
              },
              {
                name: 'types_section',
                filters: ['content_type', 'file_type', 'file_format'],
              },
              {
                name: 'dates_section',
                filters: ['uploaded_datetime'],
              },
            ]}
          />
        </ActionBlock>
        <ReportTable
          columns={columns}
          filters={filters}
          reportInfo={reportProps.reportInfo}
          errorMessage={reportProps.errorMessage}
          isLoading={reportProps.isLoading}
          pagination={reportProps.pagination}
          records={reportProps.records}
          msgFilterCount={(count) => (
            <Plural
              value={count}
              one="Filtered to 1 media"
              other="Filtered to # media"
            />
          )}
          msgNoMatches={<Trans>No media matches the selected filters.</Trans>}
        />
      </div>
    </PageStandard>
  );
}
