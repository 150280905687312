import React, { useMemo, useCallback } from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import {
  maintObsPointListFilters,
  maintObsPointListColumns,
  ObsPointListView,
} from './ObsPointListView';
import { useReportState } from 'hooks/use-report-state';
import { EMPTY_FUNC } from 'util/misc';
import { deleteApi } from 'util/backendapi/fetch';
import { obsPointListDuck } from 'ducks/obsPoint/list/list';

type OwnProps = RCPWithQueryParams<Filter.ReportsObservationPoints>;

export const ObsPointListScreen = (ownProps: OwnProps) => {
  const columns = useMemo(() => {
    return maintObsPointListColumns({
      refreshList: EMPTY_FUNC,
      deleteObsPoint: EMPTY_FUNC,
    });
  }, []);

  const deleteObsPoint = useCallback(async (id: number) => {
    return deleteApi(`/observation-points/${id}/`);
  }, []);

  const props = {
    reportProps: useReportState(
      ownProps,
      columns,
      maintObsPointListFilters(),
      obsPointListDuck,
      (state) => state.obsPoint.list
    ),
    deleteObsPoint,
  };

  return <ObsPointListView {...props} />;
};
