import React from 'react';
import ButtonShowModal, { ModalContentProps } from '../modal/buttonshowmodal';
import { Trans } from '@lingui/macro';
import ModalContent from '../modal/modalcontent';
import ActionBlock from '../actionblock/actionblock';
import ButtonHideModal from '../modal/buttonhidemodal';
import { ButtonDanger, ButtonProps, ButtonPrimary } from '../button/button';
import { useIsMounted } from 'util/hooks';
import { MessageDescriptor } from '@lingui/core';
import { IconType } from '../icon/icon';
import { errorToString } from 'util/backendapi/error';
import ErrorNotice from '../form/errornotice/errornotice';
import classNames from 'classnames';

interface Props extends ButtonProps {
  name: string;
  className?: string;
  title?: MessageDescriptor;
  iconType?: IconType;
  onClick?: () => Promise<boolean>;
  onConfirm: () => Promise<any>;
  content: React.ReactNode;
  cancelBtnText?: React.ReactNode;
  okBtnText?: React.ReactNode;
  children?: React.ReactNode;
  destructive: boolean;
}

export function ButtonShowConfirmation(props: Props) {
  const { name, className, onClick, iconType, title, children, destructive } =
    props;
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  return (
    <ButtonShowModal
      name={name}
      iconOnly={!children}
      iconType={iconType}
      className={className}
      title={title}
      disabled={isSubmitting}
      onClick={onClick}
      modalProps={{
        className: classNames('modal', { 'modal-danger': destructive }),
        overlayClassName: 'modal-centred',
      }}
      modalContent={(modalContentProps) => (
        <ConfirmationModalContent
          {...modalContentProps}
          {...props}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      )}
    >
      {children}
    </ButtonShowModal>
  );
}
ButtonShowConfirmation.defaultProps = {
  // default to be a confirmation for a destructive action
  destructive: true,
};

/**
 * Using a separate component for the modal content, mainly so we can make
 * sure that the error message gets cleared when the modal is closed.
 * @param props
 */
function ConfirmationModalContent(
  props: Props &
    ModalContentProps & {
      isSubmitting: boolean;
      setIsSubmitting: (isSubmitting: boolean) => void;
    }
) {
  const {
    content,
    onConfirm,
    cancelBtnText,
    okBtnText,
    hideModal,
    isSubmitting,
    setIsSubmitting,
    destructive,
  } = props;
  const isMounted = useIsMounted();
  const [errorMessage, setErrorMessage] = React.useState('');
  const PrimaryButtonComponent = destructive ? ButtonDanger : ButtonPrimary;

  return (
    <ModalContent header={<Trans>Confirmation</Trans>}>
      <div>{content}</div>
      {errorMessage ? <ErrorNotice>{errorMessage}</ErrorNotice> : null}
      <ActionBlock className="text-right">
        <ButtonHideModal disabled={isSubmitting}>
          {cancelBtnText ? cancelBtnText : <Trans>Cancel</Trans>}
        </ButtonHideModal>
        <PrimaryButtonComponent
          disabled={isSubmitting}
          onClick={async () => {
            setIsSubmitting(true);
            setErrorMessage('');
            try {
              await onConfirm();
              if (isMounted()) {
                setIsSubmitting(false);
                hideModal();
              }
            } catch (e) {
              setIsSubmitting(false);
              setErrorMessage(errorToString(e as any));
            }
          }}
        >
          {okBtnText ? okBtnText : <Trans>Yes</Trans>}
        </PrimaryButtonComponent>
      </ActionBlock>
    </ModalContent>
  );
}
