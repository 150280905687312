import React from 'react';
import { Field, FieldProps } from 'formik';
import classNames from 'classnames';
import { SimpleSelectOption } from '../simpleselect/simpleselect';

export type CheckboxesFieldOption<T = any> = SimpleSelectOption<T>;

/**
 * A Formik field for a set of checkboxes. It acts a lot like a multi-select
 * SimpleSelectField: it takes a list of options with `value` and `label`, and
 * it returns an array with the `value` of the ticked checkboxes.
 */
export interface CheckboxesFieldProps {
  name: string;
  disabled?: boolean;
  className?: string;
  options: ReadonlyArray<CheckboxesFieldOption>;
}

const InnerCheckboxesField: React.FunctionComponent<
  FieldProps & CheckboxesFieldProps
> = (props) => {
  return (
    <div className={classNames('check-group-wrapper', props.className)}>
      {props.options.map((opt, i) => (
        <SingleCheckbox {...props} idx={i} key={i} option={opt} />
      ))}
    </div>
  );
};

const SingleCheckbox: React.FunctionComponent<
  FieldProps &
    CheckboxesFieldProps & {
      idx: number;
      option: CheckboxesFieldOption;
    }
> = (props) => {
  const { field, form, option } = props;
  const isSelected =
    Array.isArray(field.value) && field.value.includes(option.value);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newIsSelected = Boolean(e.target && e.target.checked);
      if (newIsSelected === isSelected) {
        return;
      }

      if (newIsSelected) {
        // Newly selected, add it to the array
        form.setFieldValue.call(null, field.name, [
          ...field.value,
          option.value,
        ]);
      } else {
        // Deselected, remove it from the array
        form.setFieldValue.call(
          null,
          field.name,
          field.value.filter((v: any) => v !== option.value)
        );
      }
    },
    [field.name, field.value, form.setFieldValue, isSelected, option.value]
  );

  const fieldId = `radio-${field.name}-${option.value}`;

  return (
    <div className="check-group">
      <Field
        type="checkbox"
        name={field.name}
        id={fieldId}
        checked={isSelected}
        // NOTE: The "value" attribute here is not actually read back by our
        // code. Rather, the form value is set in the `onChange` callback.
        value={`check-${field.name}-${option.value}`}
        onChange={handleChange}
        disabled={props.disabled}
      />
      <label htmlFor={fieldId}>{option.label}</label>
    </div>
  );
};

export const CheckBoxesField = (props: CheckboxesFieldProps) => (
  <Field component={InnerCheckboxesField} {...props} />
);
