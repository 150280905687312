import React, { useCallback } from 'react';
import { usePlotState } from 'hooks/use-plot-state';
import { getTimeZoneFromPlotMetadata } from 'components/plots/timeseriesplotselectors';
import { StoredPlotItem } from 'util/backendapi/types/Model';
import { PlotType } from 'util/backendapi/types/Enum';
import { TimeSeriesPlot } from 'components/plots/timeseriesplot';
import Loading from 'components/base/loading/loading';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { TSPlotZoomParams, buildStoredPlotKey } from './timeseriesplot.types';
import { useDispatch } from 'react-redux';
import { setStoredPlotZoom } from 'ducks/plot/scatter-time-series';

interface StoredTimeSeriesPlotItemProps {
  storedPlotId: number;
  colorsPool: string[];
  labelsPool: string;
  markersPool: string[];
  plotItem: StoredPlotItem;
  margin?: {
    left?: number;
    right: number;
  };
}

const EMPTY_ARRAY: any[] = [];

export const StoredTimeSeriesPlotItem = (
  props: StoredTimeSeriesPlotItemProps
) => {
  const dispatch = useDispatch();
  const handleZoom = useCallback(
    (zoom: TSPlotZoomParams) =>
      dispatch(setStoredPlotZoom(props.storedPlotId, props.plotItem.id, zoom)),
    [dispatch, props.plotItem.id, props.storedPlotId]
  );

  const plotState = usePlotState(
    buildStoredPlotKey(props.storedPlotId, props.plotItem.id),
    PlotType.TIME_SERIES
  );

  const timeZone = getTimeZoneFromPlotMetadata(plotState.resolvedSettings);

  return (
    <div className="plot-item">
      <div className="plot-item-detail">
        {props.plotItem.name ? (
          <h3 className="plot-item-header">{props.plotItem.name}</h3>
        ) : null}
        {props.plotItem.description ? (
          <p>{props.plotItem.description}</p>
        ) : null}
      </div>
      {plotState.errorMessage && (
        <ErrorNotice>{plotState.errorMessage}</ErrorNotice>
      )}
      {plotState.isLoading ? (
        <Loading />
      ) : (
        <TimeSeriesPlot
          annotations={props.plotItem.annotations}
          colorsPool={props.colorsPool}
          labelsPool={props.labelsPool}
          markersPool={props.markersPool}
          paddedMinDatetime={plotState.paddedMinDatetime}
          paddedMaxDatetime={plotState.paddedMaxDatetime}
          readingsSeries={plotState.readingsSeries}
          alarmParamSeries={plotState.alarmParamSeries}
          timeZone={timeZone}
          yAxes={plotState.yAxes ?? EMPTY_ARRAY}
          onZoom={handleZoom}
          margin={props.margin}
        />
      )}
    </div>
  );
};
