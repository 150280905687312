export enum ExportFormats {
  CSV = 'csv',
  PDF = 'pdf',
  PNG = 'png',
  XLSX = 'xlsx',
  DOCX = 'docx',
}

export enum PageOrientations {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export const ExportFormatMimeTypes = {
  [ExportFormats.CSV]: 'text/csv',
  [ExportFormats.PDF]: 'application/pdf',
  [ExportFormats.PNG]: 'image/png',
  [ExportFormats.XLSX]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [ExportFormats.DOCX]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
} as const;
