import React from 'react';
import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { AlertDanger, AlertWarning } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import {
  CumulativePlotSettings,
  CumulativePlotSettingsModal,
} from './CumulativePlotSettingsModal';
import {
  CumulativePlotReadings,
  ObservationPointDecorated,
} from 'util/backendapi/types/Model';
import { CumulativePlot } from 'components/plots/CumulativePlot';
import { MessageDescriptor } from '@lingui/core';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { SavePlotModal } from 'screens/quickplot/SavePlotModal';
import { PlotType } from 'util/backendapi/types/Enum';
import { ExportPanel } from 'components/modules/exportpanel/exportpanel';
import { min, max } from 'lodash';

export interface CumulativePlotViewProps {
  observationPoint: ObservationPointDecorated | null;
  cumulativePlotReadings: CumulativePlotReadings | null;
  isLoading: boolean;
  errorMessage: string | MessageDescriptor | null;
  itemNumber: number;
  timePeriods: number[];
}

export function CumulativePlotView(props: CumulativePlotViewProps) {
  const {
    observationPoint,
    cumulativePlotReadings,
    isLoading,
    errorMessage,
    itemNumber,
    timePeriods,
  } = props;

  const pageProps = {
    name: 'cumulativeplot',
    header: <Trans>Cumulative Plot</Trans>,
    subHeader: observationPoint?.code,
  };

  if (isLoading) {
    return (
      <PageStandard {...pageProps}>
        <Loading />
      </PageStandard>
    );
  }

  const startYear = min(timePeriods);
  const endYear = max(timePeriods);

  const displayedYearRange =
    startYear && endYear ? (
      <div className="plot-page-header-info">
        <h2 data-testid="plot-date-range">
          {startYear} <Trans>to</Trans> {endYear}
        </h2>
      </div>
    ) : null;

  const cumulativePlotSettings: CumulativePlotSettings | null = observationPoint
    ? {
        observation_point: observationPoint?.id,
        item_number: itemNumber,
        time_periods: timePeriods,
      }
    : null;

  const plotHeader = (
    <div className="page-content-header columns-fluid">
      {displayedYearRange}
      <ActionBlock className="text-right">
        <HasPermission check="can_create_stored_plots">
          <ButtonShowModal
            name="save-plot"
            iconType="icon-save"
            disabled={!cumulativePlotSettings}
            modalContent={() => (
              <SavePlotModal plotType={PlotType.CUMULATIVE} />
            )}
          >
            <Trans>Save</Trans>
          </ButtonShowModal>
        </HasPermission>
        <ButtonShowModal
          name="settings"
          iconType="icon-cog"
          shortcut="GOTO_SETTINGS"
          autoShow={!observationPoint && !itemNumber}
          modalContent={({ hideModal }: { hideModal: any }) => (
            <CumulativePlotSettingsModal
              hideModal={hideModal}
              cumulativePlotSettings={cumulativePlotSettings}
            />
          )}
        >
          <Trans>Settings</Trans>
        </ButtonShowModal>
        <ButtonShowModal
          name="export"
          disabled={!cumulativePlotSettings}
          modalContent={() => (
            <ExportPanel
              title={<Trans>Export cumulative plot</Trans>}
              description={
                <Trans>
                  The plot will be exported with the same settings as displayed
                  on screen.
                </Trans>
              }
              canExportPdf={true}
              getExportUrl={() => ({
                path: '/export/pdf/cumulative-plots/',
                queryParams: new URLSearchParams({
                  settings: JSON.stringify(cumulativePlotSettings),
                }),
              })}
            />
          )}
          iconType="icon-export"
        >
          <Trans>Export</Trans>
        </ButtonShowModal>
      </ActionBlock>
    </div>
  );

  if (errorMessage) {
    return (
      <PageStandard {...pageProps}>
        {plotHeader}
        <AlertDanger>{errorMessage}</AlertDanger>
      </PageStandard>
    );
  }

  if (
    cumulativePlotReadings !== null &&
    Object.keys(cumulativePlotReadings.readings).length === 0
  ) {
    return (
      <PageStandard {...pageProps}>
        {plotHeader}
        <AlertWarning>No data</AlertWarning>
      </PageStandard>
    );
  }

  return (
    <PageStandard {...pageProps}>
      {plotHeader}

      {observationPoint && cumulativePlotReadings ? (
        <CumulativePlot
          observationPoint={observationPoint}
          itemNumber={itemNumber}
          cumulativePlotReadings={cumulativePlotReadings}
          timePeriods={timePeriods}
        />
      ) : null}
    </PageStandard>
  );
}
