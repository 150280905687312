import {
  StoredPlotSurveyAnnotation,
  StoredSurveyLevellingPlot,
} from 'util/backendapi/types/Model';
import { StoredSurveyLevellingPlotWithArea } from 'ducks/stored-plot/detail';
import { extent } from 'd3-array';
import orderBy from 'lodash/orderBy';
import {
  StoredPlotAnnotationAxis,
  StoredPlotAnnotationLabelPosition,
  StoredPlotAnnotation_LINE_STYLE,
  StoredPlotAnnotation_STUB_POSITION,
} from 'util/backendapi/types/Enum';
import { clampNumeric } from './timeseriesplotselectors';

export function computeSurveyLevellingPlotDateRange(
  storedPlot: StoredSurveyLevellingPlot | StoredSurveyLevellingPlotWithArea
) {
  const plottedDateRange = extent(
    storedPlot.time_periods.map(({ survey_datetime }) => survey_datetime)
  );
  return {
    minDatetime: plottedDateRange[0] ?? null,
    maxDatetime: plottedDateRange[1] ?? null,
  };
}

interface SurveyLevellingDomainProps {
  xDomain: number[];
  yDomain: number[];
}

export function calculateSurveyAnnotationPlotLines(
  domainProps: SurveyLevellingDomainProps,
  annotations: StoredPlotSurveyAnnotation[]
) {
  if (!annotations.length) {
    return null;
  }

  const [xMin, xMax] = domainProps.xDomain;
  const [yMin, yMax] = domainProps.yDomain;

  const yAnnotations = annotations.filter(
    (annot) => annot.axis === StoredPlotAnnotationAxis.Y
  );

  const xAnnotations = annotations.filter(
    (annot) => annot.axis === StoredPlotAnnotationAxis.X
  );

  // Sort them in descending order by value so that ones lower in the plot
  // will layer on top of ones higher in the plot.
  const sortedHorizontalAnnotations = orderBy(
    yAnnotations,
    (item) => Number(item.numeric_value),
    'desc'
  )
    .map(({ numeric_value, start_numeric, end_numeric, ...annot }) => ({
      ...annot,
      numericValue: Number(numeric_value),
      startNumeric: clampNumeric(
        start_numeric === null ? xMin : Number(start_numeric),
        { min: xMin }
      ),
      endNumeric: clampNumeric(
        end_numeric === null ? xMax : Number(end_numeric),
        { max: xMax }
      ),
    }))
    .filter(
      // Don't render annotations that fall outside the plot.
      ({ numericValue, startNumeric, endNumeric }) =>
        !(
          numericValue < yMin ||
          numericValue > yMax ||
          startNumeric > xMax ||
          endNumeric < xMin
        )
    );

  const sortedVerticalAnnotations = orderBy(
    xAnnotations,
    (item) => Number(item.numeric_value),
    'desc'
  )
    .map(({ numeric_value, start_numeric, end_numeric, ...annot }) => ({
      ...annot,
      numericValue: Number(numeric_value),
      startNumeric: clampNumeric(
        start_numeric === null ? yMin : Number(start_numeric),
        { min: yMin }
      ),
      endNumeric: clampNumeric(
        end_numeric === null ? yMax : Number(end_numeric),
        { max: yMax }
      ),
    }))
    .filter(
      // Don't render annotations that fall outside the plot.
      ({ numericValue, startNumeric, endNumeric }) =>
        !(
          numericValue < xMin ||
          numericValue > xMax ||
          startNumeric > yMax ||
          endNumeric < yMin
        )
    );

  const horizontalLines = sortedHorizontalAnnotations
    .filter(
      (annot) => annot.line_style === StoredPlotAnnotation_LINE_STYLE.FULL
    )
    .flatMap((annot) => [
      // A horizontal line with the annotation's value as its vertical position,
      { x: annot.startNumeric, y: annot.numericValue },
      { x: annot.endNumeric, y: annot.numericValue },
      // A null to prevent a line from being drawn attaching this line to the next
      // annotation line. (Since we render them all as one `<LineSeries>` component,
      // which in turn renders to a single SVG `<path>` element)
      { x: null, y: null },
    ]);

  const horizontalLabels = sortedHorizontalAnnotations
    .filter((annot) => annot.label)
    .map((annot) => ({
      x:
        annot.stub_position === StoredPlotAnnotation_STUB_POSITION.RIGHT
          ? xMax
          : annot.startNumeric,
      y: annot.numericValue,
      label: annot.label,
      position: annot.position || StoredPlotAnnotationLabelPosition.ABOVE,
      stubPosition: annot.stub_position,
    }));

  const horizontalStubs = sortedHorizontalAnnotations
    .filter(
      (annot) => annot.line_style === StoredPlotAnnotation_LINE_STYLE.STUB
    )
    .map((annot) => ({
      x:
        annot.stub_position === StoredPlotAnnotation_STUB_POSITION.LEFT
          ? xMin
          : xMax,
      y: annot.numericValue,
      stubPosition: annot.stub_position,
    }));

  const verticalLines = sortedVerticalAnnotations
    .filter(
      (annot) => annot.line_style === StoredPlotAnnotation_LINE_STYLE.FULL
    )
    .flatMap((annot) => [
      { x: annot.numericValue, y: annot.startNumeric },
      { x: annot.numericValue, y: annot.endNumeric },
      { x: null, y: null },
    ]);

  const verticalLabels = sortedVerticalAnnotations
    .filter((annot) => annot.label)
    .map((annot) => ({
      x: annot.numericValue,
      y:
        annot.stub_position === StoredPlotAnnotation_STUB_POSITION.TOP
          ? yMax
          : annot.startNumeric,
      label: annot.label,
      position: annot.position || StoredPlotAnnotationLabelPosition.LEFT,
      stubPosition: annot.stub_position,
    }));

  const verticalStubs = sortedVerticalAnnotations
    .filter(
      (annot) => annot.line_style === StoredPlotAnnotation_LINE_STYLE.STUB
    )
    .map((annot) => ({
      x: annot.numericValue,
      y:
        annot.stub_position === StoredPlotAnnotation_STUB_POSITION.TOP
          ? yMax
          : yMin,
      stubPosition: annot.stub_position,
    }));

  return {
    horizontalLines: horizontalLines.length > 0 ? horizontalLines : null,
    horizontalLabels: horizontalLabels.length > 0 ? horizontalLabels : null,
    horizontalStubs: horizontalStubs.length > 0 ? horizontalStubs : null,
    verticalLines: verticalLines.length > 0 ? verticalLines : null,
    verticalLabels: verticalLabels.length > 0 ? verticalLabels : null,
    verticalStubs: verticalStubs.length > 0 ? verticalStubs : null,
  };
}
