import React, { useCallback } from 'react';
import ButtonShowModal, {
  ModalContentProps,
} from 'components/base/modal/buttonshowmodal';
import { Trans } from '@lingui/macro';
import ModalContent from 'components/base/modal/modalcontent';
import {
  Formik,
  Field,
  ErrorMessage,
  FormikErrors,
  FormikHelpers,
  Form,
} from 'formik';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { FullState } from 'main/reducers';
import { Enum, Model, Filter } from 'util/backendapi/models/api.interfaces';
import { connect, ResolveThunks } from 'react-redux';
import { RouteChildrenProps, Route, withRouter } from 'react-router';
import { ReportColumn, ReportFilter } from '../report-types';
import { getSavedReportFromFrontendParams } from '../report-utils';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { createSavedReport, updateSavedReport } from 'ducks/savedReport/detail';
import { FormItem } from 'components/base/form/FormItem';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import {
  RCPWithQueryParams,
  parseNumberQueryParamFromRouterProps,
} from 'util/routing';
import { useGetApi } from 'hooks/use-get-api';

interface FormValues {
  name: string;
}

type InnerProps = {
  columnsFrontend: ReportColumn[];
  filtersFrontend: ReportFilter[];
  reportInfo: Model.ReportInfo;
  loggedInAs: number | null;
  createSavedReport: (report: Model.SavedReport_POST) => void;
  updateSavedReport: (
    savedReportId: number,
    report: Model.SavedReport_PATCH
  ) => void;
  onAfterSave?: () => void;
} & RouteChildrenProps &
  ModalContentProps;

/**
 * A component to display the "Save report" modal and form.
 */
export class SaveReportModal extends React.Component<InnerProps> {
  _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  validate = (values: FormValues): FormikErrors<FormValues> | undefined => {
    if (values.name) {
      return undefined;
    } else {
      return { name: (<Trans>Report name is required.</Trans>) as any };
    }
  };

  handleSubmit = async (
    values: FormValues,
    formik: FormikHelpers<FormValues>
  ): Promise<void> => {
    if (!this.props.loggedInAs) {
      return;
    }
    formik.setStatus(undefined);

    const { ordering, columns, filters } = getSavedReportFromFrontendParams(
      this.props,
      this.props.columnsFrontend,
      this.props.filtersFrontend,
      this.props.reportInfo
    );

    try {
      await this.props.createSavedReport({
        name: values.name,
        user: this.props.loggedInAs,
        report: this.props.reportInfo.report_type,
        columns,
        filters,
        ordering,
      });
      if (!this._isMounted) {
        return;
      }
      if (this.props.onAfterSave) {
        this.props.onAfterSave();
      }
      this.props.hideModal();
      return;
    } catch (error) {
      if (!this._isMounted) {
        return;
      }
      formik.setSubmitting(false);
      showErrorsInFormik(formik, error, getExpectedFields(values));
      return;
    }
  };

  render() {
    const initialValues: FormValues = { name: '' };
    return (
      <ModalContent header={<Trans>Save report</Trans>}>
        <p>
          <Trans>
            The report will be saved with the same settings as displayed on
            screen.
          </Trans>
        </p>
        <Formik
          initialValues={initialValues}
          validate={this.validate}
          onSubmit={this.handleSubmit}
          validateOnBlur={false}
        >
          {(formik) => (
            <Form>
              {formik.status}
              <FormItem
                label={<Trans>Report name</Trans>}
                fieldId="save-report-name"
              >
                <Field
                  id="save-report-name"
                  name="name"
                  type="text"
                  autoFocus={true}
                  disabled={formik.isSubmitting}
                />
                <ErrorMessage name="name" component={ErrorNotice} />
              </FormItem>
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  type="submit"
                  iconType="icon-save"
                  disabled={formik.isSubmitting}
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
            </Form>
          )}
        </Formik>
      </ModalContent>
    );
  }
}

interface OwnProps {
  columnsFrontend: InnerProps['columnsFrontend'];
  filtersFrontend: InnerProps['filtersFrontend'];
  reportInfo?: InnerProps['reportInfo'] | null;
  onAfterSave?: InnerProps['onAfterSave'];
}
interface StateProps {
  loggedInAs: number | null;
  hasAddSavedReportPermission: boolean;
}

function mapStateToProps(state: FullState): StateProps {
  return {
    loggedInAs: state.user.loggedInAs,
    hasAddSavedReportPermission: state.user.permissions.includes(
      Enum.User_PERMISSION.can_save_reports
    ),
  };
}

const mapDispatchToProps = {
  createSavedReport,
  updateSavedReport,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

/**
 * A connected component to display the button for the "save report" modal.
 * It hides if it finds you don't have sufficient permissions.
 * @param props
 */
function InnerSaveReportModalButtons(
  props: Props & RCPWithQueryParams<Filter.ReportsStandardFilters>
) {
  const savedReportId = parseNumberQueryParamFromRouterProps(
    props,
    'saved_report_id'
  );

  const handleUpdate = useCallback(async () => {
    if (!savedReportId) return;

    const { ordering, columns, filters } = getSavedReportFromFrontendParams(
      props,
      props.columnsFrontend,
      props.filtersFrontend,
      props.reportInfo!
    );

    props.updateSavedReport(+savedReportId, {
      columns,
      filters,
      ordering,
    });
  }, [props, savedReportId]);

  const [{ data: savedReport }] = useGetApi(
    savedReportId ? `/saved-reports/${savedReportId}/` : null
  );

  if (!props.hasAddSavedReportPermission) return null;

  return (
    <>
      {savedReportId && savedReport ? (
        <ButtonShowConfirmation
          name="update-saved-report"
          iconType="icon-save"
          onConfirm={handleUpdate}
          destructive={false}
          content={
            <>
              <Trans>
                Are you sure you want to update{' '}
                <strong>{savedReport.name}</strong>?
              </Trans>{' '}
              <Trans>This action is not reversible.</Trans>
            </>
          }
          okBtnText={<Trans>Yes, update</Trans>}
        >
          <Trans>Update saved report</Trans>
        </ButtonShowConfirmation>
      ) : null}
      <ButtonShowModal
        name="save-report"
        iconType="icon-save"
        disabled={!props.reportInfo}
        modalContent={(modalContentProps) => (
          <Route
            render={(routeProps) => (
              <SaveReportModal
                {...props}
                reportInfo={props.reportInfo!}
                {...modalContentProps}
                {...routeProps}
              />
            )}
          />
        )}
      >
        <Trans>{savedReportId ? 'Save report as...' : 'Save report'}</Trans>
      </ButtonShowModal>
    </>
  );
}

export const SaveReportModalButtons = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InnerSaveReportModalButtons));
