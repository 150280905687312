import React, { useCallback, useEffect, useMemo } from 'react';
import Button, { ButtonProps } from '../button/button';
import { t } from '@lingui/macro';
import { setOneQueryParam } from 'util/routing';
import { useLocation, useHistory } from 'react-router-dom';
import { isValidDMSRoute } from 'util/is-valid-dms-route';
import './BackButton.scss';

export function BackButton(
  props: {
    defaultBackUrl: string;
  } & ButtonProps
) {
  const location = useLocation<{ from: string }>();
  const history = useHistory();
  const { defaultBackUrl, ...rest } = props;

  const backUrl = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('backUrl');
  }, [location]);

  useEffect(() => {
    if (backUrl && !isValidDMSRoute(backUrl)) {
      setOneQueryParam({ location, history }, 'backUrl', null);
    }
  }, [backUrl, location, history]);

  const handleClick = useCallback(() => {
    if (backUrl && isValidDMSRoute(backUrl)) {
      history.push(backUrl);
    } else {
      history.push(defaultBackUrl);
    }
  }, [history, backUrl, defaultBackUrl]);

  return (
    <Button
      onClick={handleClick}
      iconType="icon-arrow-left"
      iconOnly
      title={t`Back to list`}
      className="back-button"
      shortcut="GO_BACK"
      {...rest}
    />
  );
}
