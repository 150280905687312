import { t } from '@lingui/macro';
import deepEqual from 'lodash/isEqual';
import { formatDatetimeForBackendUrl, getCurrentDatetime } from 'util/dates';
import {
  getPaginated,
  getApi,
  fetchPaginationMetadata,
} from 'util/backendapi/fetch';
import { StandardThunk, DuckActions } from 'main/store';
import { Model, Filter, Enum } from 'util/backendapi/models/api.interfaces';
import { PaginationResponseData } from 'util/backendapi/pagination';
import { DRFError, errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  FETCH_QUICKLIST_READINGS_START:
    'dms/quicklist/FETCH_QUICKLIST_READINGS_START',
  FETCH_QUICKLIST_READINGS_RESPONSE:
    'dms/quicklist/FETCH_QUICKLIST_READINGS_RESPONSE',
  FETCH_QUICKLIST_READINGS_ERROR:
    'dms/quicklist/FETCH_QUICKLIST_READINGS_ERROR',
  FETCH_QUICKLIST_OBSPOINT_START:
    'dms/quicklist/FETCH_QUICKLIST_OBSPOINT_START',
  FETCH_QUICKLIST_OBSPOINT_RESPONSE:
    'dms/quicklist/FETCH_QUICKLIST_OBSPOINT_RESPONSE',
  FETCH_QUICKLIST_OBSPOINT_ERROR:
    'dms/quicklist/FETCH_QUICKLIST_OBSPOINT_ERROR',
  UNMOUNT_QUICKLIST: 'dms/quicklist/UNMOUNT_QUICKLIST',
} as const;

export interface QuickListRequestDetails {
  observationPointCode: string;
  limit: number | null;
  offset: number | null;
  numLatestReadings: number | null;
  startDatetime: string | null;
  endDatetime: string | null;
}

const ActionCreators = {
  FETCH_QUICKLIST_READINGS_START: (request: QuickListRequestDetails) => ({
    type: ActionTypes.FETCH_QUICKLIST_READINGS_START,
    request,
  }),
  FETCH_QUICKLIST_READINGS_RESPONSE: (
    request: QuickListRequestDetails,
    readings: Model.Reading[],
    paginationReceived: PaginationResponseData | null,
    basePaginationOffset: number
  ) => ({
    type: ActionTypes.FETCH_QUICKLIST_READINGS_RESPONSE,
    request,
    payload: {
      readings,
      paginationReceived,
      basePaginationOffset,
    },
  }),
  FETCH_QUICKLIST_READINGS_ERROR: (
    request: QuickListRequestDetails,
    error: DRFError
  ) => ({
    type: ActionTypes.FETCH_QUICKLIST_READINGS_ERROR,
    request,
    error: true,
    payload: errorToString(error),
  }),
  FETCH_QUICKLIST_OBSPOINT_START: (observationPointCode: string) => ({
    type: ActionTypes.FETCH_QUICKLIST_OBSPOINT_START,
    observationPointCode,
  }),
  FETCH_QUICKLIST_OBSPOINT_RESPONSE: (
    observationPointCode: string,
    observationPoint: Model.ObservationPointDecorated,
    gaps: Model.ReadingGapComment[]
  ) => ({
    type: ActionTypes.FETCH_QUICKLIST_OBSPOINT_RESPONSE,
    observationPointCode,
    payload: {
      gaps,
      observationPoint,
    },
  }),
  FETCH_QUICKLIST_OBSPOINT_ERROR: (
    observationPointCode: string,
    errorMessage: string
  ) => ({
    type: ActionTypes.FETCH_QUICKLIST_OBSPOINT_ERROR,
    error: true,
    payload: errorMessage,
    observationPointCode,
  }),
  UNMOUNT_QUICKLIST: () => ({ type: ActionTypes.UNMOUNT_QUICKLIST }),
} as const;

export type QuickListAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface QuickListState {
  observationPointCode: string | null;
  request: QuickListRequestDetails | null;
  pagination: PaginationResponseData | null;
  readingsErrorMessage: string | null;
  isLoadingReadings: boolean;
  obsPointErrorMessage: string | null;
  isLoadingObsPoint: boolean;
  observationPoint: Model.ObservationPointDecorated | null;
  readings: Model.Reading[] | null;
  gaps: Model.ReadingGapComment[] | null;
  basePaginationOffset: number | null;
  generatedOnDatetime: string | null;
}

function quickListInitialState(): QuickListState {
  return {
    observationPointCode: null,
    request: null,
    pagination: null,
    readingsErrorMessage: null,
    isLoadingReadings: false,
    obsPointErrorMessage: null,
    isLoadingObsPoint: false,
    observationPoint: null,
    readings: null,
    gaps: [],
    basePaginationOffset: null,
    generatedOnDatetime: null,
  };
}

export function quickListReducer(
  state = quickListInitialState(),
  action: QuickListAction
): QuickListState {
  switch (action.type) {
    case ActionTypes.FETCH_QUICKLIST_READINGS_START:
      if (action.request.observationPointCode !== state.observationPointCode) {
        return state;
      } else {
        return {
          ...state,
          request: action.request,
          isLoadingReadings: true,
          readingsErrorMessage: null,
        };
      }
    case ActionTypes.FETCH_QUICKLIST_READINGS_RESPONSE: {
      if (!deepEqual(action.request, state.request)) {
        return state;
      } else {
        return {
          ...state,
          isLoadingReadings: false,
          pagination: action.payload.paginationReceived,
          readings: action.payload.readings,
          basePaginationOffset: action.payload.basePaginationOffset,
          generatedOnDatetime: getCurrentDatetime(),
        };
      }
    }
    case ActionTypes.FETCH_QUICKLIST_READINGS_ERROR: {
      if (!deepEqual(action.request, state.request)) {
        return state;
      } else {
        return {
          ...state,
          isLoadingReadings: false,
          readingsErrorMessage: action.payload,
        };
      }
    }
    case ActionTypes.FETCH_QUICKLIST_OBSPOINT_START: {
      return {
        ...state,
        observationPointCode: action.observationPointCode,
        isLoadingObsPoint: true,
        obsPointErrorMessage: null,
        observationPoint: null,
      };
    }
    case ActionTypes.FETCH_QUICKLIST_OBSPOINT_RESPONSE: {
      if (state.observationPointCode !== action.observationPointCode) {
        return state;
      } else {
        return {
          ...state,
          isLoadingObsPoint: false,
          obsPointErrorMessage: null,
          gaps: action.payload.gaps,
          observationPoint: action.payload.observationPoint,
        };
      }
    }
    case ActionTypes.FETCH_QUICKLIST_OBSPOINT_ERROR: {
      if (state.observationPointCode !== action.observationPointCode) {
        return state;
      } else {
        return {
          ...state,
          isLoadingObsPoint: false,
          obsPointErrorMessage: action.payload,
        };
      }
    }
    case ActionTypes.UNMOUNT_QUICKLIST:
      return quickListInitialState();
    default:
      return state;
  }
}

export const unmountQuickListPage = ActionCreators.UNMOUNT_QUICKLIST;

export function fetchQuickListReadings(
  observationPointId: number,
  request: QuickListRequestDetails
): StandardThunk {
  return async function (dispatch, getState, { i18n }) {
    const { limit, offset, numLatestReadings, startDatetime, endDatetime } =
      request;

    dispatch(ActionCreators.FETCH_QUICKLIST_READINGS_START(request));

    try {
      // TODO: Update /readings/ endpoint so that we can filter by adjusted
      // reading entry position (to support observation points with multiple
      // formulas)
      const baseFilters: Filter.Readings = {
        observation_point: observationPointId,
        effective: true,
      };

      let readings: Model.Reading[];
      let pagination: PaginationResponseData | null;
      let basePaginationOffset: number = 0;

      if (numLatestReadings) {
        const metadata = await fetchPaginationMetadata(
          '/readings/',
          baseFilters
        );
        const totalReadingsAvailable = metadata.total;
        if (!totalReadingsAvailable) {
          // No records! Don't bother making a second request.
          readings = [];
          pagination = metadata;
        } else {
          basePaginationOffset = totalReadingsAvailable - numLatestReadings;
          if (basePaginationOffset < 0) {
            basePaginationOffset = 0;
          }

          const actualLimit = limit === null ? numLatestReadings : limit;
          const relativeOffset = offset === null ? 0 : offset;

          const result = await getPaginated('/readings/', {
            ...baseFilters,
            limit: actualLimit,
            offset: relativeOffset + basePaginationOffset,
          });
          readings = result.data;
          pagination = {
            total:
              totalReadingsAvailable > numLatestReadings
                ? numLatestReadings
                : totalReadingsAvailable,
            start: relativeOffset,
            end: relativeOffset + readings.length,
          };
        }
      } else {
        const filters: Filter.Readings = {
          ...baseFilters,
        };
        if (limit !== null) {
          filters.limit = limit;
        }
        if (offset !== null) {
          filters.offset = offset;
        }
        if (startDatetime) {
          filters.reading_datetime_after =
            formatDatetimeForBackendUrl(startDatetime);
        }
        if (endDatetime) {
          filters.reading_datetime_before =
            formatDatetimeForBackendUrl(endDatetime);
        }
        const result = await getPaginated('/readings/', filters);

        readings = result.data;
        pagination = result.pagination;
      }

      dispatch(
        ActionCreators.FETCH_QUICKLIST_READINGS_RESPONSE(
          request,
          readings,
          pagination,
          basePaginationOffset
        )
      );
    } catch (e) {
      dispatch(ActionCreators.FETCH_QUICKLIST_READINGS_ERROR(request, e));
    }
  };
}

export function fetchQuickListObsPoint(
  observationPointCode: string
): StandardThunk {
  return async function (dispatch, _getState, { i18n }) {
    dispatch(
      ActionCreators.FETCH_QUICKLIST_OBSPOINT_START(observationPointCode)
    );

    try {
      const observationPoints = await getApi(`/observation-points/`, {
        code: observationPointCode,
      });
      if (observationPoints.length !== 1) {
        return dispatch(
          ActionCreators.FETCH_QUICKLIST_OBSPOINT_ERROR(
            observationPointCode,
            i18n._(
              t`Could not find observation point "${observationPointCode}"`
            )
          )
        );
      }
      const observationPoint = observationPoints[0];

      const gaps = (await getApi(`/comments/`, {
        observation_point__in: [observationPoint.id],
        resourcetype__in: [Enum.Comment_RESOURCE_TYPE.readingGap],
      })) as Model.ReadingGapComment[];

      dispatch(
        ActionCreators.FETCH_QUICKLIST_OBSPOINT_RESPONSE(
          observationPointCode,
          observationPoint,
          gaps
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_QUICKLIST_OBSPOINT_ERROR(
          observationPointCode,
          errorToString(e)
        )
      );
    }
  };
}
