import React from 'react';
import { Trans } from '@lingui/macro';
import PanelContent from 'components/base/panel/panelcontent';
import { AlertDanger } from 'components/base/alert/alert';
import Loading from 'components/base/loading/loading';
import { Model } from 'util/backendapi/models/api.interfaces';

export type ReadingsCountPanelProps = {
  isLoading: boolean;
  readingsCountData: Model.ReadingProcessingRequestCount[];
  error: string | null;
};

export const ReadingsCountPanelView = (props: ReadingsCountPanelProps) => (
  <PanelContent header={<Trans>Observation points</Trans>}>
    {props.isLoading && <Loading />}

    {!props.isLoading && props.error ? (
      <AlertDanger>
        <Trans>{props.error}</Trans>
      </AlertDanger>
    ) : null}

    <table className="table-stripe-reverse">
      <thead>
        <tr>
          <th>
            <Trans>Observation point</Trans>
          </th>
          <th className="text-right">
            <Trans>Affected readings</Trans>
          </th>
        </tr>
      </thead>
      <tbody>
        {props.readingsCountData.map((count) => (
          <tr key={`item-${count.code}`}>
            <td>{count.code}</td>
            <td className="text-right">{count.count}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </PanelContent>
);
