import React from 'react';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi } from 'util/backendapi/fetch';
import {
  MultiMenuProps,
  SingleMenuProps,
  makeIdCodeMenuOptions,
} from '../async-menu/utils';
import { LIST_PAGE_SIZE_DEFAULT } from 'util/backendapi/pagination';

export const OBSERVATION_POINT_AUTOCOMPLETE_LIMIT = LIST_PAGE_SIZE_DEFAULT;

export function ObsPointMenu(props: SingleMenuProps) {
  const { name, ...otherProps } = props;
  return (
    <AsyncSimpleSelectField<number, false>
      name={name}
      {...otherProps}
      isMulti={false}
      onSearch={async (searchText: string) =>
        makeIdCodeMenuOptions(
          await getApi('/observation-points/', {
            code__icontains: searchText,
            fields: ['id', 'code'],
            limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
          })
        )
      }
      loadDefaults={async (value: number) =>
        makeIdCodeMenuOptions(
          await getApi('/observation-points/', {
            id__in: [value],
            fields: ['id', 'code'],
          })
        )
      }
    />
  );
}

export function ObsPointMultiMenu(props: MultiMenuProps) {
  const { name, ...otherProps } = props;
  return (
    <AsyncSimpleSelectField<number, true>
      name={name}
      {...otherProps}
      isMulti
      onSearch={async (searchText: string) =>
        makeIdCodeMenuOptions(
          await getApi('/observation-points/', {
            code__icontains: searchText,
            fields: ['id', 'code'],
            limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
          })
        )
      }
      loadDefaults={async (values: number[]) =>
        makeIdCodeMenuOptions(
          await getApi('/observation-points/', {
            id__in: values,
            fields: ['id', 'code'],
          })
        )
      }
    />
  );
}
