import React, { useState } from 'react';
import { Trans, Plural, t } from '@lingui/macro';
import { createSelector } from 'reselect';
import SimpleSelect, {
  SimpleSelectOption,
} from '../../components/base/form/simpleselect/simpleselect';
import { InitProps } from '../../components/hoc/withinit';
import PageStandard from '../../components/modules/pagestandard/pagestandard';
import { RouteMarchTableState } from '../../ducks/routemarch';
import { Model, Enum } from '../../util/backendapi/models/api.interfaces';
import { PaginationMeta } from '../../util/backendapi/pagination';
import { Icon } from '../../components/base/icon/icon';
import { ReportTable } from '../../components/modules/report/table/ReportTable';
import ButtonShowModal from '../../components/base/modal/buttonshowmodal';
import { RouteMarchPanel } from './routemarchpanel';
import { EntityTypes, EntityList } from '../../ducks/entities';
import {
  FilterBlock,
  FilterControl,
} from 'components/modules/report/filter/FilterBlock';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import ActionBlock from 'components/base/actionblock/actionblock';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import { DMSLink } from 'components/base/link/DMSLink';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import Button from 'components/base/button/button';
import { backendUrl, withAuthHeaders } from 'util/backendapi/fetch';
import { saveAs } from 'file-saver';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
} from 'components/modules/report/report-types';

export interface StateProps extends RouteMarchTableState {
  allAreas: EntityList<EntityTypes.AREA>;
  pagination: PaginationMeta;
  selectedAreaIds: string[];
  selectedRouteMarchCodes: string[];
  selectedTypes: string[];
  selectedStatuses: string[];
  filteredRouteMarches: Model.RouteMarch[] | null;
}
export type DispatchProps = {
  onAreaFilterChanged(newAreaId?: string[]): void;
  onRouteMarchCodeFilterChanged(newCode?: string[]): void;
  onTypeFilterChanged(newType?: string[]): void;
  onStatusFilterChanged(newStatus?: string[]): void;
  onClearFilters: () => void;
} & InitProps<StateProps>;
export type RouteMarchListProps = StateProps & DispatchProps;

export function routeMarchColumns(
  allAreas: EntityList<EntityTypes.AREA>
): ReportColumn<Model.RouteMarch>[] {
  return [
    {
      label: <Trans>Route march</Trans>,
      name: 'code',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Area</Trans>,
      name: 'area',
      visibility: DEFAULT_SHOW,
      accessor: ({ areas }) =>
        allAreas.allIds.length !== 0
          ? areas
              .map((area) => allAreas.byId[area])
              .map((area) => area.code)
              .join(', ')
          : null,
    },
    {
      label: <Trans>Description</Trans>,
      name: 'description',
      visibility: DEFAULT_SHOW,
      accessor: ({ description }) => description,
    },
    {
      label: <Trans>Menu label</Trans>,
      name: 'menu_label',
      visibility: DEFAULT_SHOW,
      accessor: ({ menu_label }) => menu_label,
    },
    {
      label: <Trans>Reading frequency</Trans>,
      name: 'reading_frequency',
      visibility: DEFAULT_SHOW,
      accessor: ({ reading_frequency }) => (
        <IntervalDisplay value={reading_frequency} />
      ),
    },
    {
      label: <Trans>Frequency tolerance</Trans>,
      name: 'reading_frequency_tolerance',
      visibility: DEFAULT_SHOW,
      accessor: ({ reading_frequency_tolerance }) => (
        <IntervalDisplay value={reading_frequency_tolerance} />
      ),
    },
    {
      label: <Trans>Type</Trans>,
      name: 'type',
      visibility: DEFAULT_SHOW,
      accessor: ({ type }) => <TransEnum enum="RouteMarch_TYPE" value={type} />,
    },
    {
      label: <Trans>Status</Trans>,
      name: 'status',
      visibility: DEFAULT_SHOW,
      accessor: ({ status }) => (
        <TransEnum enum="RouteMarch_STATUS" value={status} />
      ),
    },
    {
      ...ACTION_COLUMN,
      accessor: (routeMarch) => (
        <>
          <DMSLink to={`/route-march-observation-points/${routeMarch.code}`}>
            <Icon type="icon-view" title={t`View observation points`} />
          </DMSLink>
          <HasPermission check="can_create_route_marches">
            <ButtonShowModal
              name="changeRouteMarch"
              iconType="icon-edit"
              iconOnly={true}
              title={t`Edit`}
              className="btn-link-panel"
              modalContent={({ hideModal }: any) => (
                <RouteMarchPanel
                  routeMarch={routeMarch}
                  hideModal={hideModal}
                />
              )}
            >
              <Trans>Edit</Trans>
            </ButtonShowModal>
          </HasPermission>
          <HasPermission check="can_view_route_march_observation_points">
            <DownloadRouteMarchButton routeMarchCode={routeMarch.code} />
          </HasPermission>
        </>
      ),
    },
  ];
}

export class RouteMarchListView extends React.Component<RouteMarchListProps> {
  selectAreaOptions = createSelector(
    (props: RouteMarchListProps) => props.allAreas,
    function (allAreas) {
      return allAreas.allIds.map((areaId) => {
        const area = allAreas.byId[areaId];
        return {
          value: String(areaId),
          label: `${area.code} - ${area.name}`,
        };
      });
    }
  );

  selectRouteMarchOptions = createSelector(
    (props: RouteMarchListProps) => props.routeMarches,
    function (routeMarches) {
      if (!routeMarches) {
        return [];
      } else {
        return routeMarches.map((routeMarch) => ({
          value: routeMarch.code,
          label: routeMarch.code,
        }));
      }
    }
  );

  typeOptions: SimpleSelectOption<string>[] = menuItemsFromEnum(
    'RouteMarch_TYPE',
    Object.values(Enum.RouteMarch_TYPE)
  );

  statusOptions: SimpleSelectOption<string>[] = menuItemsFromEnum(
    'RouteMarch_STATUS',
    Object.values(Enum.RouteMarch_STATUS)
  );

  render() {
    const columns = routeMarchColumns(this.props.allAreas);

    const hasCodeFilter = this.props.selectedRouteMarchCodes.length > 0;
    const hasAreaFilter = this.props.selectedAreaIds.length > 0;
    const hasTypeFilter = this.props.selectedTypes.length > 0;
    const hasStatusFilter = this.props.selectedStatuses.length > 0;
    const addRouteMarchButton = (
      <ActionBlock className="text-right">
        <HasPermission check="can_create_route_marches">
          <ButtonShowModal
            name="addRouteMarch"
            iconType="icon-plus"
            shortcut="CREATE_NEW"
            title={t`Create route march`}
            modalContent={({ hideModal }: any) => (
              <RouteMarchPanel routeMarch={null} hideModal={hideModal} />
            )}
          >
            <Trans>Create route march</Trans>
          </ButtonShowModal>
        </HasPermission>
      </ActionBlock>
    );

    return (
      <PageStandard
        name="listroutemarches"
        header={<Trans>Maintain Route Marches</Trans>}
      >
        <div className="page-content-header-filters-actions">
          {!this.props.loading ? addRouteMarchButton : null}
          <FilterBlock>
            <FilterControl
              label={<Trans>Route march</Trans>}
              hasValues={hasCodeFilter}
            >
              <SimpleSelect
                name="routemarch-list-code"
                autoFocus={true}
                placeholder={<Trans>All route marches</Trans>}
                isLoading={this.props.loading}
                value={this.props.selectedRouteMarchCodes}
                isMulti={true}
                onChange={this.props.onRouteMarchCodeFilterChanged}
                options={this.selectRouteMarchOptions(this.props)}
              />
            </FilterControl>
            <FilterControl
              label={<Trans>Area</Trans>}
              hasValues={hasAreaFilter}
            >
              <SimpleSelect
                name="routemarch-list-area"
                placeholder={<Trans>All areas</Trans>}
                isLoading={this.props.allAreas.allIds.length === 0}
                value={this.props.selectedAreaIds}
                isMulti={true}
                onChange={this.props.onAreaFilterChanged}
                options={this.selectAreaOptions(this.props)}
              />
            </FilterControl>
            <FilterControl
              label={<Trans>Type</Trans>}
              hasValues={hasTypeFilter}
            >
              <SimpleSelect
                name="routemarch-list-type"
                placeholder={<Trans>All types</Trans>}
                options={this.typeOptions}
                value={this.props.selectedTypes}
                isMulti={true}
                onChange={this.props.onTypeFilterChanged}
              />
            </FilterControl>
            <FilterControl
              label={<Trans>Status</Trans>}
              hasValues={hasStatusFilter}
            >
              <SimpleSelect
                name="routemarch-list-status"
                placeholder={<Trans>All statuses</Trans>}
                options={this.statusOptions}
                value={this.props.selectedStatuses}
                isMulti={true}
                onChange={this.props.onStatusFilterChanged}
              />
            </FilterControl>
          </FilterBlock>
        </div>
        <div className="filtered-table-wrapper">
          <ReportTable
            columns={columns}
            errorMessage={this.props.errorMessage}
            hasActiveFilters={
              hasCodeFilter || hasAreaFilter || hasTypeFilter || hasStatusFilter
            }
            isLoading={this.props.loading}
            msgFilterCount={(count) => (
              <Plural
                value={count}
                one="Filtered to 1 route march"
                other="Filtered to # route marches"
              />
            )}
            msgNoMatches={
              <Trans>No route marches match the selected filters.</Trans>
            }
            pagination={this.props.pagination}
            onClearFilters={this.props.onClearFilters}
            records={this.props.filteredRouteMarches}
          />
        </div>
      </PageStandard>
    );
  }
}

function DownloadRouteMarchButton(props: { routeMarchCode: string }) {
  const { routeMarchCode } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  return (
    <Button
      iconOnly={true}
      title={t`Download route march`}
      iconType="icon-download"
      className="btn-link-panel"
      disabled={isDownloading}
      onClick={async () => {
        try {
          setIsDownloading(true);
          const response = await fetch(
            backendUrl(
              `/route-march-observation-points/txt/?route_march__code=${encodeURIComponent(
                routeMarchCode
              )}`
            ),
            withAuthHeaders()
          );
          await saveAs(
            new Blob([await response.json()]),
            `${routeMarchCode}.rmt`.toLowerCase()
          );
        } finally {
          setIsDownloading(false);
        }
      }}
    />
  );
}
