import { useMemo } from 'react';
import { StoredTimeSeriesPlotWithArea } from 'ducks/stored-plot/detail';
import {
  LR_MARGIN,
  LR_MARGIN_WITH_TICKS,
} from 'components/plots/timeseriesplot';

/**
 * A hook to calculate a reasonable margin for individual plot items inside a stored plot,
 * the values will then be passed to individual item to override their inner margin calculation,
 * so that all the plots within the StoredPlot will have the axis aligned.
 *
 * Currently, we used a simple approach to define fixed margin values based on the
 * presence of left, right1, righ2 axis. The chosen numbers must work for most of the cases (SSR included),
 * and need to accommondate enough space to display two right axes, with maximum values < 1,000,000
 *
 * A more complex approach would involve each plot to propagate up its own measurement (in runtime) to a
 * top level component, where it will be decided which values is the most sufficient for all plot items.
 * But this will require more engineering effort, which also means more "special things" to maintain.
 */
export function useStoredPlotMargin(storedPlot: StoredTimeSeriesPlotWithArea): {
  left: number;
  right: number;
} {
  const hasLeftAxis = storedPlot.items.some((item) =>
    item.axes.find((axis) => axis.side === 'left')
  );
  const hasRight1Axis = storedPlot.items.some((item) =>
    item.axes.find((axis) => axis.side === 'right')
  );
  const hasRight2Axis = storedPlot.items.some((item) =>
    item.axes.find((axis) => axis.side === 'right2')
  );

  const margin = useMemo(() => {
    let left: number = LR_MARGIN;
    let right: number = LR_MARGIN;
    if (hasLeftAxis) {
      left = LR_MARGIN_WITH_TICKS;
    }

    if (hasRight1Axis && hasRight2Axis) {
      right = LR_MARGIN_WITH_TICKS * 2;
    } else if (hasRight1Axis || hasRight2Axis) {
      right = LR_MARGIN_WITH_TICKS;
    }

    return {
      left,
      right,
    };
  }, [hasLeftAxis, hasRight1Axis, hasRight2Axis]);

  return margin;
}
