import React from 'react';
import { TranslatedEnumNames, TranslatedEnumVals } from 'util/i18n-utils';
import { SimpleSelectOption } from '../form/simpleselect/simpleselect';
import { TransEnum } from './TransEnum';
import { TRANSLATED_ENUMS } from 'util/backendapi/types/Enum';

/**
 * Helper function to generate an array of React-Select menu items for a backend
 * enum.
 *
 * @example
 *
 * // A menu composed of all the enum's values, in their "Default" order
 * const menuItems = menuItemsFromEnum('Comment_TYPE');
 *
 * // A menu with just some of the enum's values
 * const otherMenuItems = menuItemsFromEnum('Comment_RESOURCE_TYPE', [
 *  Enum.Comment_RESOURCE_TYPE.ObservationPointComment,
 *  Enum.Comment_RESOURCE_TYPE.SiteComment,
 *  Enum.Comment_RESOURCE_TYPE.AreaComment,
 * ]);

 * @param enumName
 * @param enumValues
 */
export function menuItemsFromEnum<EnumName extends TranslatedEnumNames>(
  enumName: EnumName,
  enumValues = TRANSLATED_ENUMS[enumName] as TranslatedEnumVals<EnumName>[]
): SimpleSelectOption<TranslatedEnumVals<EnumName>>[] {
  return enumValues.map((val) => ({
    value: val,
    label: <TransEnum enum={enumName} value={val} />,
  }));
}
