import { connect } from 'react-redux';
import { createOrUpdateRouteMarch } from '../../ducks/routemarch';
import {
  fetchEntityList,
  EntityTypes,
  selectAllInOrderedArray,
} from '../../ducks/entities';
import {
  RouteMarchPanelView,
  FormikValues,
  DispatchProps,
  StateProps,
  OwnProps,
} from './routemarchpanelview';
import { FullState } from '../../main/reducers';
import { createSelector } from 'reselect';
import { withRouter, RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';
import withInit, { InitProps } from '../../components/hoc/withinit';
import { enhanceWithBackUrl } from 'components/base/link/DMSLink';

export interface AreaOption {
  value: number;
  code: string;
  label: string;
}

const selectAreaOptions = createSelector(
  (state: FullState) => selectAllInOrderedArray(state, EntityTypes.AREA),
  (itemList) => {
    return itemList
      ? itemList.map(({ id, code, name }) => ({
          value: id,
          label: `${code} - ${name}`,
          code,
        }))
      : null;
  }
);

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: FullState) => ({
  ...state.routeMarch,
  areaOptions: selectAreaOptions(state),
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, any>,
  ownProps: RouteComponentProps
) => ({
  onInit: () => {
    dispatch(fetchEntityList);
  },
  onSubmit: async (values: FormikValues) => {
    return dispatch(createOrUpdateRouteMarch(values));
  },
  onAfterSubmit: (routeMarchObservationPointCode: string) => {
    ownProps.history.push(
      enhanceWithBackUrl(
        `/route-march-observation-points/${routeMarchObservationPointCode}`,
        ownProps.location
      )
    );
  },
});

export const RouteMarchPanel = withRouter(
  connect<StateProps, DispatchProps & InitProps, OwnProps, FullState>(
    mapStateToProps,
    mapDispatchToProps
  )(withInit(RouteMarchPanelView))
);
