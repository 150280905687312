import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const siteListDuck = makeReportingDuck(
  'sites',
  (filters) => getPaginated('/reports/sites/', filters),
  () => getApi('/reports/sites/info/')
);

export type SiteListAction = ReportingDuckActions<typeof siteListDuck>;
