import React, { useCallback, useMemo } from 'react';
import {
  AllowedFileExtensions,
  ALLOWED_FILE_EXTENSIONS,
} from '../file-field/FileField';
import { Field, FieldProps, ErrorMessage } from 'formik';
import Button from 'components/base/button/button';
import { Trans, t } from '@lingui/macro';
import ErrorNotice from '../errornotice/errornotice';
import { printAsMegabytes } from 'util/misc';

// Sets a Formik value that is an array of these.
interface FileFormValue {
  file?: File;
  name: string;
  size: number;
}

interface Props {
  name: string;
  fileExtensions?: AllowedFileExtensions[];
  maxFiles?: number;
}

export type MultiFileFormValue = FileFormValue[];

function InnerMultiFileField(
  props: FieldProps & {
    fileExtensions: AllowedFileExtensions[];
    maxFiles: number;
  }
) {
  const {
    field: { name: fieldName, value: fieldValue },
    form: formik,
    fileExtensions,
    maxFiles,
  } = props;
  const values = useMemo(
    () => (fieldValue ?? []) as FileFormValue[],
    [fieldValue]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const input = e.target;
      const fileList = input.files;

      if (fileList) {
        formik.setFieldValue(
          fieldName,
          values
            .concat(
              Array.from(fileList).map((file) => ({
                name: file.name,
                file,
                size: file.size,
              }))
            )
            .slice(0, maxFiles),
          true
        );
        formik.setFieldTouched(fieldName);
        // Reset the file selector.
        input.value = '';
      }
    },
    [fieldName, formik, maxFiles, values]
  );

  const clickHandlers = useMemo(
    () =>
      values.map((_v, idx) => () => {
        formik.setFieldValue(
          fieldName,
          values.filter((_v, vIdx) => vIdx !== idx)
        );
        formik.setFieldTouched(fieldName);
      }),
    [fieldName, formik, values]
  );

  return (
    <>
      {values.length > 0 && (
        <ul className="panel-member-list">
          {values.map(({ name: fileName, size }, idx) => (
            <li key={idx} className="columns-fluid">
              <div>
                {`${fileName} (${printAsMegabytes(size)}) `}
                <ErrorMessage name={fieldName}>
                  {(errors) => {
                    if (errors[idx]) {
                      return (
                        <>
                          <br />
                          <ErrorNotice>{errors[idx]}</ErrorNotice>
                        </>
                      );
                    } else {
                      return null;
                    }
                  }}
                </ErrorMessage>
              </div>
              <div className="action-icons text-right">
                <Button
                  iconOnly={true}
                  iconType="icon-circle-minus"
                  title={t`Delete`}
                  onClick={clickHandlers[idx]}
                  className="btn-link-panel"
                />
              </div>
            </li>
          ))}
        </ul>
      )}
      <div>
        <input
          type="file"
          multiple={true}
          accept={fileExtensions.join(',')}
          onChange={handleChange}
          disabled={values.length >= maxFiles}
        />
      </div>
    </>
  );
}

export function MultiFileField(props: Props) {
  const { name: fieldName, maxFiles } = props;

  const fileExtensions = props.fileExtensions ?? ALLOWED_FILE_EXTENSIONS;

  /**
   * This component is treated as one Formik field, but it actually sets an
   * array of error messages, so it can target the error message to the specific
   * field with the problem.
   */
  const handleValidate = useCallback(
    (values: FileFormValue[]) => {
      const errors = values.map(({ name: newFileName }) => {
        if (
          fileExtensions.length &&
          newFileName &&
          !fileExtensions.some((ext) => newFileName.toLowerCase().endsWith(ext))
        ) {
          return (
            <Trans>
              File extension must be one of: {fileExtensions.join(' ')}
            </Trans>
          );
        } else {
          return null;
        }
      });
      if (errors.every((e) => e === null)) {
        return undefined;
      }
      return errors;
    },
    [fileExtensions]
  );

  return (
    <Field
      name={fieldName}
      validate={handleValidate}
      component={InnerMultiFileField}
      fileExtensions={fileExtensions}
      maxFiles={maxFiles ?? 10}
    />
  );
}
