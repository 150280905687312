import React, { useMemo, useCallback, useState, useEffect } from 'react';
import difference from 'lodash/difference';
import { useGetApi } from 'hooks/use-get-api';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import { selectAll, EntityTypes, fetchEntityList } from 'ducks/entities';
import { selectHasPermission } from 'util/user';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { ClientUsersPanelView } from './ClientUsersPanelView';
import { useDispatch } from 'react-redux';
import { patchApi } from 'util/backendapi/fetch';

export function ClientUsersPanel(props: { clientId: number }) {
  const [clientFetch] = useGetApi(`/clients/${props.clientId}/`);
  const [client, setClient] = useState<Model.Client | null>(null);
  const dispatch = useDispatch();
  const { allUsers, canEdit } = useShallowEqualSelector((state: FullState) => {
    return {
      allUsers: selectAll(state, EntityTypes.USER),
      canEdit: selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_clients
      ),
    };
  });

  useEffect(() => {
    if (clientFetch.data) {
      setClient(clientFetch.data);
    }
  }, [clientFetch]);

  useEffect(() => {
    // only fetch the list of users if not already fetch
    // usually, there is already one user to start with (the logged in user)
    if (allUsers.allIds.length <= 1) {
      dispatch(fetchEntityList(EntityTypes.USER));
    }
  }, [dispatch, allUsers]);

  const { availableUsers, clientUsers } = useMemo((): {
    availableUsers: Model.User[];
    clientUsers: Model.User[];
  } => {
    const clientUserIds = client?.users ?? [];

    return {
      availableUsers: canEdit
        ? difference(allUsers.allIds, clientUserIds).map(
            (userId) => allUsers.byId[userId]
          )
        : [],
      clientUsers: clientUserIds
        .map((userId) => allUsers.byId[userId])
        .filter((user: Model.User | undefined): user is Model.User =>
          Boolean(user)
        ),
    };
  }, [canEdit, client, allUsers]);

  const handleAddUser = useCallback(
    async (userId: number) => {
      if (client) {
        const updatedClient = await patchApi(`/clients/${client.id}/`, {
          users: client.users.concat(userId),
        });

        setClient(updatedClient);
      }
    },
    [client]
  );

  const handleRemoveUser = useCallback(
    async (userId: number) => {
      if (client && client.users.some((id) => id === userId)) {
        const updatedClientUsers = client.users.filter((id) => id !== userId);
        const updatedClient = await patchApi(`/clients/${client.id}/`, {
          users: updatedClientUsers,
        });
        setClient(updatedClient);
      }
    },
    [client]
  );

  return (
    <ClientUsersPanelView
      isLoading={clientFetch.isLoading || allUsers.allIds.length <= 1}
      client={client}
      availableUsers={availableUsers}
      clientUsers={clientUsers}
      canEdit={canEdit}
      onSubmitAdd={handleAddUser}
      onRemoveUser={handleRemoveUser}
    />
  );
}
