import React from 'react';
import { setupI18n, I18n as I18nObj } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { connect } from 'react-redux';

// TODO: Right now the translation catalogs are a bit of a mess...
// They're sort of a build product, and you don't actually need them, in dev.
// But they are mandatory for prod, so we need to import them.
//
// The clean solution is to do a conditional import, but those are always
// asynchronous, and it would require some tricky re-working of our `<App>`
// component to make that work.
//
// Possibly a better solution would be to use a Babel Macro to include the
// "import" statement only when building for prod. But this is also a bit
// tricky.
import enCatalog from '../assets/locales/en';
import { FullState } from './reducers';
// import { changeLanguage } from '../ducks/ui';

export function makeI18n(): I18nObj {
  return setupI18n({
    language: 'en',
    catalogs: { en: enCatalog },
  });
}

interface OwnProps {
  i18n: I18nObj;
  children?: React.ReactNode;
}
interface StateProps {
  language: string;
}

type Props = OwnProps & StateProps;
class I18nManagerInner extends React.Component<Props> {
  static mapStateToProps(state: FullState): StateProps {
    return { language: state.ui.lang };
  }

  render() {
    return (
      <I18nProvider i18n={this.props.i18n} language={this.props.language}>
        <React.Fragment key={this.props.language}>
          {this.props.children}
        </React.Fragment>
      </I18nProvider>
    );
  }

  // NOTE: Commenting out this language-switcher for now, because there are
  // bugs that prevent the `en-x-intl` from actually working. But I'm leaving
  // the commented code here in this file as an illustration about how to
  // go about doing this functionality in future, should we need to.
  //
  // componentDidMount() {
  //   /**
  //    * For development & testing purposes, add a function to the window
  //    * called "dmsToggleLanguage()", that will "internationalize" all the
  //    * lang strings, by putting crazy UTF-8 decorations on them.
  //    */
  //   window.dmsToggleLanguage = async () => {
  //     if (this.props.i18n.language === 'en') {
  //       // eslint-disable-next-line no-console
  //       console.log(`Switching to 'en-x-intl'`);
  //       try {
  //         // Asynchronous dynamic import of en-x-intl catalog.
  //         const catalog = (await import('../assets/locales/en-x-intl')).default;
  //         this.props.i18n.load({ 'en-x-intl': catalog });
  //         this.props.dispatch(changeLanguage('en-x-intl'));
  //       } catch (e) {
  //         // eslint-disable-next-line no-console
  //         console.log(
  //           `No en-x-intl dictionary is available. Try running "npm run translate".`
  //         );
  //       }
  //     } else {
  //       // eslint-disable-next-line no-console
  //       console.log(`Switching to 'en'`);
  //       this.props.dispatch(changeLanguage('en'));
  //     }
  //   };
  // }

  // componentWillUnmount() {
  //   delete window.dmsToggleLanguage;
  // }
}

export const I18nManager = connect<StateProps, {}, OwnProps, FullState>(
  I18nManagerInner.mapStateToProps
)(I18nManagerInner);
