import { Model } from 'util/backendapi/models/api.interfaces';
import { getApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { DuckActions, StandardThunk } from 'main/store';

export const ActionTypes = {
  FETCH_DATA_REVIEW_REPORT_START:
    'dms/dataReview/FETCH_DATA_REVIEW_REPORT_START',
  FETCH_DATA_REVIEW_REPORT_RESPONSE:
    'dms/dataReview/FETCH_DATA_REVIEW_REPORT_RESOINSE',
  FETCH_DATA_REVIEW_REPORT_ERROR:
    'dms/dataReview/FETCH_DATA_REVIEW_REPORT_ERROR',
} as const;

export const ActionCreators = {
  FETCH_DATA_REVIEW_REPORT_START: () => ({
    type: ActionTypes.FETCH_DATA_REVIEW_REPORT_START,
  }),
  FETCH_DATA_REVIEW_REPORT_RESPONSE: (
    reportData: Model.DataReviewReportInstance[]
  ) => ({
    type: ActionTypes.FETCH_DATA_REVIEW_REPORT_RESPONSE,
    payload: reportData,
  }),
  FETCH_DATA_REVIEW_REPORT_ERROR: (errorMessage: string) => ({
    type: ActionTypes.FETCH_DATA_REVIEW_REPORT_ERROR,
    error: true,
    payload: errorMessage,
  }),
};
export type DataReviewReportAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface DataReviewState {
  result: Model.DataReviewReportInstance[] | null;
  isLoading: boolean;
  errorMessage: string | null;
}

export function dataReviewReportInitialState(): DataReviewState {
  return {
    result: null,
    isLoading: false,
    errorMessage: null,
  };
}

export function dataReviewReportReducer(
  state = dataReviewReportInitialState(),
  action: DataReviewReportAction
): DataReviewState {
  switch (action.type) {
    case ActionTypes.FETCH_DATA_REVIEW_REPORT_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_DATA_REVIEW_REPORT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        result: action.payload,
      };
    case ActionTypes.FETCH_DATA_REVIEW_REPORT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
}

export function fetchDataReviewReport(
  areaToQuery: number,
  start_datetime: string,
  end_datetime: string
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_DATA_REVIEW_REPORT_START());

    try {
      const response = await getApi('/reports/data-review/', {
        area_ids: `${areaToQuery}`,
        start_datetime: start_datetime,
        end_datetime: end_datetime,
      });
      return dispatch(
        ActionCreators.FETCH_DATA_REVIEW_REPORT_RESPONSE(response)
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_DATA_REVIEW_REPORT_ERROR(errorToString(e))
      );
    }
  };
}
