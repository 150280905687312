import { selectOneById, EntityTypes, withInsertEntities } from './entities';
import { StandardThunk, DuckActions, SyncThunk } from '../main/store';
import { getApi, backendUrl, postApi } from '../util/backendapi/fetch';
import { Enum, Model } from '../util/backendapi/models/api.interfaces';
import { t } from '@lingui/macro';
import { FullState } from '../main/reducers';
import { errorToString } from 'util/backendapi/error';
import {
  OAuthLoginResponse,
  OAuthRefreshResponse,
} from 'util/backendapi/types/Model';
import { formatDatetimeForStorage } from 'util/dates';
import { GlobalAction, GlobalActionCreators } from './global';
import { GroupAction, ActionTypes as GroupActionTypes } from './group';

export const ActionTypes = {
  DO_LOGIN_START: 'dms/actions/DO_LOGIN_START',
  DO_LOGIN_RESPONSE: 'dms/actions/DO_LOGIN_RESPONSE',
  DO_LOGIN_ERROR: 'dms/actions/DO_LOGIN_ERROR',
  DO_LOGOUT_START: 'dms/actions/DO_LOGOUT_START',
  DO_LOGOUT_RESPONSE: 'dms/actions/DO_LOGOUT_RESPONSE',
  DO_LOGOUT_ERROR: 'dms/actions/DO_LOGOUT_ERROR',
  UNMOUNT_LOGIN_PAGE: 'dms/actions/UNMOUNTED_LOGIN_PAGE',
  REFRESH_CURRENT_USER_DATA_START:
    'dms/actions/REFRESH_CURRENT_USER_DATA_START',
  REFRESH_CURRENT_USER_DATA_RESPONSE:
    'dms/actions/REFRESH_CURRENT_USER_DATA_RESPONSE',
  REFRESH_CURRENT_USER_DATA_ERROR:
    'dms/actions/REFRESH_CURRENT_USER_DATA_ERROR',
  REFRESH_AUTH_TOKENS: 'dms/actions/FETCH_OAUTH_TOKENS',
  REFRESH_AUTH_TOKENS_RESPONSE: 'dms/actions/FETCH_OAUTH_TOKENS_RESPONSE',
  REFRESH_AUTH_TOKENS_ERROR: 'dms/actions/FETCH_OAUTH_TOKENS_ERROR',
  SET_DEFAULT_ACTIVE_AREA_GROUP: 'dms/actions/SET_DEFAULT_ACTIVE_AREA_GROUP',
  SWITCH_ACTIVE_AREA_GROUP: 'dms/actions/SWITCH_ACTIVE_AREA_GROUP',
  SET_AUTH_TOKENS: 'dms/actions/SET_AUTH_TOKENS',
} as const;

export const ActionCreators = {
  UNMOUNT_LOGIN_PAGE() {
    return {
      type: ActionTypes.UNMOUNT_LOGIN_PAGE,
    };
  },

  REFRESH_CURRENT_USER_DATA_START() {
    return {
      type: ActionTypes.REFRESH_CURRENT_USER_DATA_START,
    };
  },

  REFRESH_CURRENT_USER_DATA_RESPONSE(user: Model.CurrentUser) {
    const baseAction = {
      type: ActionTypes.REFRESH_CURRENT_USER_DATA_RESPONSE,
      payload: user,
    };
    return withInsertEntities(baseAction, EntityTypes.USER, [user], false);
  },

  REFRESH_CURRENT_USER_DATA_ERROR(errorMessage: string) {
    return {
      type: ActionTypes.REFRESH_CURRENT_USER_DATA_ERROR,
      error: true,
      payload: errorMessage,
    };
  },

  DO_LOGIN_START() {
    return {
      type: ActionTypes.DO_LOGIN_START,
    };
  },

  DO_LOGIN_ERROR(errorMessage: string) {
    return {
      type: ActionTypes.DO_LOGIN_ERROR,
      error: true,
      payload: errorMessage,
    };
  },

  DO_LOGIN_RESPONSE(response: Model.OAuthLoginResponse) {
    return {
      type: ActionTypes.DO_LOGIN_RESPONSE,
      payload: extractOauthTokenData(response),
    };
  },

  DO_LOGOUT_START() {
    return { type: ActionTypes.DO_LOGOUT_START };
  },

  DO_LOGOUT_ERROR(errorMessage: string) {
    return {
      type: ActionTypes.DO_LOGOUT_ERROR,
      error: true,
      payload: errorMessage,
    };
  },

  DO_LOGOUT_RESPONSE() {
    return {
      type: ActionTypes.DO_LOGOUT_RESPONSE,
    };
  },

  REFRESH_AUTH_TOKENS() {
    return {
      type: ActionTypes.REFRESH_AUTH_TOKENS,
    };
  },

  REFRESH_AUTH_TOKENS_RESPONSE(response: Model.OAuthRefreshResponse) {
    return {
      type: ActionTypes.REFRESH_AUTH_TOKENS_RESPONSE,
      payload: extractOauthTokenData(response),
    };
  },

  REFRESH_AUTH_TOKENS_ERROR(errorMessage: string) {
    return {
      type: ActionTypes.REFRESH_AUTH_TOKENS_ERROR,
      error: true,
      payload: errorMessage,
    };
  },

  SWITCH_ACTIVE_AREA_GROUP(areaGroupId: number | null) {
    return {
      type: ActionTypes.SWITCH_ACTIVE_AREA_GROUP,
      payload: areaGroupId,
    };
  },

  SET_DEFAULT_ACTIVE_AREA_GROUP(areaGroupId: number | null) {
    return {
      type: ActionTypes.SET_DEFAULT_ACTIVE_AREA_GROUP,
      payload: areaGroupId,
    };
  },

  SET_AUTH_TOKENS(tokens: AuthState) {
    return {
      type: ActionTypes.SET_AUTH_TOKENS,
      payload: tokens,
    };
  },
};

export type LoginAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export const setAuthTokens = ActionCreators.SET_AUTH_TOKENS;
export const unmountLoginPage = ActionCreators.UNMOUNT_LOGIN_PAGE;

export function switchActiveAreaGroup(areaGroupId: number | null): SyncThunk {
  return function (dispatch) {
    dispatch(ActionCreators.SWITCH_ACTIVE_AREA_GROUP(areaGroupId));
    dispatch(GlobalActionCreators.CLEAR_CACHED_DATA());
  };
}

/**
 * State relating to the current user's authentication and permissions.
 * NOTE: This data is persistent into LocalStorage or SessionStorage, across
 * page loads. Everything else in the Redux store is cleared when you leave
 * or refresh the page.
 */
export interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  tokenExpiryTime: string | null;
}

function makeInitialAuthState(): AuthState {
  return {
    accessToken: null,
    refreshToken: null,
    tokenExpiryTime: null,
  };
}

export function authReducer(
  state = makeInitialAuthState(),
  action: LoginAction
): AuthState {
  switch (action.type) {
    case ActionTypes.DO_LOGIN_RESPONSE:
    case ActionTypes.REFRESH_AUTH_TOKENS_RESPONSE:
    case ActionTypes.SET_AUTH_TOKENS: {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        tokenExpiryTime: action.payload.tokenExpiryTime,
      };
    }
  }
  return state;
}

export interface UserState {
  loading: boolean;
  loggedInAs: number | null;
  hasPerformedLogin: boolean;
  permissions: Enum.User_PERMISSION[];
  roles: Model.Role[];
  areaGroups: Model.AreaGroupDecorated[];
  activeAreaGroupId: number | null;
}

/**
 * Using a function to generate this, instead of a constant, to avoid
 * accidental mutation of the "permissions" array.
 *
 * @returns {object}
 */
function makeUserInitialState(): UserState {
  return {
    loading: false,
    loggedInAs: null,
    hasPerformedLogin: false,
    permissions: [],
    roles: [],
    areaGroups: [],
    activeAreaGroupId: null,
  };
}

// Manages state relating to the logged in user
export function userReducer(
  state = makeUserInitialState(),
  action: LoginAction | GroupAction
): UserState {
  switch (action.type) {
    case ActionTypes.REFRESH_CURRENT_USER_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.DO_LOGIN_RESPONSE:
      return {
        ...state,
        hasPerformedLogin: true,
      };
    case ActionTypes.REFRESH_CURRENT_USER_DATA_RESPONSE: {
      const areaGroups = action.payload.user_area_groups.map(
        (uag) => uag.area_group
      );
      let newActiveAreaGroupId: number | null = state.activeAreaGroupId;
      if (
        (state.activeAreaGroupId === null ||
          !areaGroups.find((ag) => ag.id === state.activeAreaGroupId)) &&
        areaGroups.length > 0
      ) {
        newActiveAreaGroupId = areaGroups[0].id;
      }
      return {
        ...state,
        loading: false,
        loggedInAs: action.payload.id,
        permissions: action.payload.permissions.sort(),
        areaGroups,
        activeAreaGroupId: newActiveAreaGroupId,
      };
    }
    case ActionTypes.REFRESH_CURRENT_USER_DATA_ERROR:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.SWITCH_ACTIVE_AREA_GROUP:
      return {
        ...state,
        activeAreaGroupId: action.payload,
      };
    case ActionTypes.SET_DEFAULT_ACTIVE_AREA_GROUP:
      return {
        ...state,
        activeAreaGroupId: action.payload,
      };
    case GroupActionTypes.DELETE_GROUP_RESPONSE:
      // When the user deletes a group that they are a member of, clear the group
      // from user state so it won't show up in their "switch group" options list.
      // NOTE: If they delete their *current* group, the `deleteGroup()` thunk will
      // take care of that by clearing `activeAreaGroupId` and dispatching
      // `refreshCurrentUserData()`.
      if (state.areaGroups.some((g) => g.id === action.groupId)) {
        return {
          ...state,
          areaGroups: state.areaGroups.filter((g) => g.id !== action.groupId),
        };
      } else {
        return state;
      }
    default:
      return state;
  }
}

export interface LoginPageState {
  isFetching: boolean;
  statusMsg: string;
}
const loginPageInitialState: LoginPageState = {
  isFetching: false,
  statusMsg: '',
};
export function loginPageReducer(
  state: LoginPageState = loginPageInitialState,
  action: LoginAction | GlobalAction
): LoginPageState {
  switch (action.type) {
    case ActionTypes.DO_LOGIN_START:
      return { ...state, isFetching: true };
    case ActionTypes.DO_LOGIN_RESPONSE:
      return {
        ...state,
        isFetching: false,
        statusMsg: '',
      };
    case ActionTypes.DO_LOGIN_ERROR:
    case ActionTypes.REFRESH_AUTH_TOKENS_ERROR:
      return {
        ...state,
        isFetching: false,
        statusMsg: action.payload,
      };
    case ActionTypes.UNMOUNT_LOGIN_PAGE:
      return {
        ...loginPageInitialState,
      };
    default:
      return state;
  }
}

function extractOauthTokenData(
  response: OAuthLoginResponse | OAuthRefreshResponse
): AuthState {
  const { access_token, refresh_token, expires_in } = response;
  return {
    accessToken: access_token,
    refreshToken: refresh_token,
    tokenExpiryTime: formatDatetimeForStorage(
      new Date(Date.now() + expires_in * 1000)
    ),
  };
}

/**
 * This thunk is dispatched if the user's LocalStorage already has auth tokens
 * in it when the page first loads. It hits the "/users/current/" endpoint,
 * which serves to verify that the user's auth tokens are, in fact, still valid,
 * and it also gets the information about the user that we need for every screen.
 * @param isLoggedIn
 * @param accessToken
 * @param refreshToken
 */
export function refreshCurrentUserData(): StandardThunk {
  return async function (dispatch, getState) {
    dispatch(ActionCreators.REFRESH_CURRENT_USER_DATA_START());
    try {
      const activeAreaGroupId = getState().user.activeAreaGroupId;
      if (!activeAreaGroupId) {
        // Awkwardly, if the user has just logged in and we know nothing about
        // them, we need to do an initial fetch of /users/current/ just to find
        // out what groups they're in, so that we can pick a "default" group
        // and use that on the second request, to get info about them in that group.
        const currentUserNoGroup = await getApi('/users/current/');
        const { user_area_groups: userAreaGroups } = currentUserNoGroup;
        if (userAreaGroups.length === 0) {
          // The user belongs to no groups. Just leave them with their current
          // (null) active area group ID, and use the groupless fetch result
          // of current user for their current data.
          return dispatch(
            ActionCreators.REFRESH_CURRENT_USER_DATA_RESPONSE(
              currentUserNoGroup
            )
          );
        } else {
          const defaultUserAreaGroupId =
            currentUserNoGroup.profile.default_user_area_group;
          let defaultAreaGroupId = null;

          if (defaultUserAreaGroupId) {
            // If a valid default group is specified use that
            defaultAreaGroupId =
              userAreaGroups.find((uag) => uag.id === defaultUserAreaGroupId)
                ?.area_group.id ?? null;
          }
          if (!defaultAreaGroupId) {
            // otherwise use their "first" group as their default
            defaultAreaGroupId = userAreaGroups[0].area_group.id;
          }

          // Do another fetch logging in with the the default area group
          dispatch(
            ActionCreators.SET_DEFAULT_ACTIVE_AREA_GROUP(defaultAreaGroupId)
          );
        }
      }

      const currentUser = await getApi('/users/current/');

      return dispatch(
        ActionCreators.REFRESH_CURRENT_USER_DATA_RESPONSE(currentUser)
      );
    } catch (e) {
      dispatch(GlobalActionCreators.CLEAR_STATE());
      return dispatch(
        ActionCreators.REFRESH_CURRENT_USER_DATA_ERROR(errorToString(e))
      );
    }
  };
}

/**
 * Authenticate the user with a username and password. If successful,
 * also fetch data about the now logged-in user.
 *
 * @export
 * @param {string} username
 * @param {string} password Password, in plaintext.
 * @returns {function} A redux thunk action
 */
export function doLogin(username: string, password: string): StandardThunk {
  return async function (dispatch, _getState, { i18n }) {
    dispatch(ActionCreators.DO_LOGIN_START());

    try {
      const authBody: Model.OAuthLoginRequest = {
        // Get an OAUTH token from a username and password string.
        grant_type: 'password',
        // Note: These OAUTH client values are not actually secret, because this
        // is a JS application. These values are both readable from the browser.
        client_id: String(process.env.REACT_APP_OAUTH_CLIENT_ID),
        username: username,
        password: password,
      };

      // This endpoint isn't one of our standard REST/JSON endpoints, so we
      // can't use the normal `postApi()` to access it.
      const authResponse = await fetch(backendUrl('/oauth/token/'), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        // TODO: Workaround for MS Edge bug, where fetch/Request cannot accept
        // a UrlSearchParams object as a request body.
        body: new URLSearchParams(authBody as any).toString(),
      });

      // TODO: Check the response in more detail to see if that's actually it.
      if (!authResponse.ok) {
        return dispatch(
          ActionCreators.DO_LOGIN_ERROR(i18n._(t`Incorrect login details.`))
        );
      }

      const authJson = (await authResponse.json()) as Model.OAuthLoginResponse;

      return dispatch(ActionCreators.DO_LOGIN_RESPONSE(authJson));
    } catch (error) {
      return dispatch(ActionCreators.DO_LOGIN_ERROR(errorToString(error)));
    }
  };
}

/**
 * Perform a logout.
 *
 * @export
 * @returns {function} A redux-thunk action
 */
export function doLogout(): StandardThunk<any> {
  return async function (dispatch, getState, { i18n }) {
    const refreshToken = getState().auth.refreshToken;

    dispatch(ActionCreators.DO_LOGOUT_START());
    dispatch(GlobalActionCreators.CLEAR_STATE());
    if (refreshToken) {
      const authBody: Model.OAuthLogoutRequest = {
        client_id: String(process.env.REACT_APP_OAUTH_CLIENT_ID),
        token: refreshToken,
        token_type_hint: 'refresh_token',
      };

      let authResponse;
      try {
        // Tell the backend to revoke our current access token.
        authResponse = await fetch(backendUrl('/oauth/revoke_token/'), {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          // TODO: Workaround for MS Edge bug, where fetch/Request cannot accept
          // a UrlSearchParams object as a request body.
          body: new URLSearchParams(authBody as any).toString(),
        });
      } catch (error) {
        return dispatch(ActionCreators.DO_LOGOUT_ERROR(errorToString(error)));
      }

      if (!(authResponse && authResponse.ok)) {
        return dispatch(
          ActionCreators.DO_LOGOUT_ERROR(
            errorToString(
              i18n._(
                t`Error during logout: ${authResponse.status} ${authResponse.statusText}`
              )
            )
          )
        );
      }
    }

    return dispatch(ActionCreators.DO_LOGOUT_RESPONSE());
  };
}

/**
 * Attempts to refresh the access token.
 * To improve security, the accessToken is not long lived, and can expire while
 * a user's session is active. Use the refresh flow to seamlessly request a new
 * access token and update the store.
 *
 * @export
 * @param {string} currentRefreshToken The current refresh token
 * @returns
 */
export function refreshAuthTokens(): StandardThunk<boolean> {
  return async function (dispatch, getState, { i18n }) {
    dispatch(ActionCreators.REFRESH_AUTH_TOKENS());

    const currentRefreshToken = getState().auth.refreshToken;
    try {
      if (!currentRefreshToken) {
        throw new Error(i18n._(t`Credentials expired`));
      }

      const authBody: Model.OAuthRefreshRequest = {
        grant_type: 'refresh_token',
        refresh_token: currentRefreshToken,
        client_id: String(process.env.REACT_APP_OAUTH_CLIENT_ID),
      };
      const auth_details = await postApi(
        '/oauth/token/',
        new URLSearchParams(authBody as any).toString(),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        }
      );

      dispatch(ActionCreators.REFRESH_AUTH_TOKENS_RESPONSE(auth_details));
      return true;
    } catch (e) {
      // If token refresh fails, log the user out.
      dispatch(GlobalActionCreators.CLEAR_STATE());
      dispatch(
        ActionCreators.REFRESH_AUTH_TOKENS_ERROR(
          i18n._(t`Error refreshing credentials: ${errorToString(e)}`)
        )
      );
      return false;
    }
  };
}

export function selectIsLoggedIn(state: FullState) {
  return Boolean(state.auth.accessToken && state.user.loggedInAs);
}

/**
 * A redux selector for retrieving the logged-in user.
 *
 * @param state The Redux state
 */
export function selectLoggedInUser(state: FullState) {
  const userId = state.user.loggedInAs;
  if (userId === null) {
    return null;
  }
  return selectOneById(state, EntityTypes.USER, userId);
}

/**
 * Get the user's display name, in a standardized way.
 *
 * @param state
 */
export function selectLoggedInUserName(state: FullState): string {
  const user = selectLoggedInUser(state);
  if (user === null) {
    return '-';
  }
  return user.profile.preferred_name || user.username || '';
}
