import React from 'react';
import {
  StoredListReportProps,
  StoredListReportView,
} from './storedlistreportview';
import {
  RCPWithQueryParams,
  parseNumberParamFromRouterProps,
  parseQueryParamFromRouterProps,
  setQueryParams,
} from 'util/routing';
import { PaginationQueryParams, Pagination } from 'util/backendapi/pagination';
import { FullState } from 'main/reducers';
import {
  fetchStoredList,
  fetchStoredListItemList,
  fetchStoredListReadings,
  unmountStoredListReport,
} from 'ducks/storedList/detail';
import { ResolveThunks, connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Filter } from 'util/backendapi/models/api.interfaces';

type OwnProps = RCPWithQueryParams<
  PaginationQueryParams,
  {
    storedListId: string;
    // Required for summary report
    previous_datetime?: string;
    current_datetime?: string;
  }
>;
type StateProps = Pick<
  StoredListReportProps,
  | 'errorMessage'
  | 'generatedDatetime'
  | 'loading'
  | 'pagination'
  | 'readings'
  | 'storedList'
  | 'storedListItems'
  | 'timeZone'
> & {
  key: string;
  storedListId: number | null;
  filters: PaginationQueryParams;
};

const mapDispatchToProps = {
  fetchStoredList,
  fetchStoredListItemList: fetchStoredListItemList,
  fetchStoredListReadings,
  unmountStoredListReport,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;

const selectFilters = createStructuredSelector<
  OwnProps,
  Filter.StoredListsReadings
>({
  limit: (props: OwnProps) => Pagination.parseFromRouterProps(props).limit,
  offset: (props: OwnProps) => Pagination.parseFromRouterProps(props).offset,
  previous_datetime: (props: OwnProps) =>
    parseQueryParamFromRouterProps(props, 'previous_datetime'),
  current_datetime: (props: OwnProps) =>
    parseQueryParamFromRouterProps(props, 'current_datetime'),
});

class InnerStoredListReportScreen extends React.Component<Props> {
  static mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
    const storedListId = parseNumberParamFromRouterProps(
      ownProps,
      'storedListId',
      null
    );
    return {
      key: String(storedListId),
      errorMessage:
        state.storedList.detail.errorMessage ||
        state.storedList.detail.observationPointOutputs.errorMessage ||
        state.storedList.detail.readings.errorMessage,
      filters: selectFilters(ownProps),
      generatedDatetime: state.storedList.detail.readings.generatedDatetime,
      loading:
        state.storedList.detail.loading ||
        state.storedList.detail.observationPointOutputs.loading ||
        state.storedList.detail.readings.loading,
      pagination: Pagination.fromRequestedReceived(
        Pagination.parseFromRouterProps(ownProps),
        state.storedList.detail.readings.pagination
      ),
      readings: state.storedList.detail.readings.report,
      storedList: state.storedList.detail.storedList,
      storedListId,
      storedListItems:
        state.storedList.detail.observationPointOutputs.storedListItems,
      timeZone:
        state.storedList.detail.timeZone &&
        state.storedList.detail.timeZone.name,
    };
  }

  handleSubmitSettings = (
    values: Partial<Filter.StoredListsReadings>
  ): void => {
    setQueryParams(this.props, values);
  };

  render() {
    return (
      <StoredListReportView
        errorMessage={this.props.errorMessage}
        generatedDatetime={this.props.generatedDatetime}
        loading={this.props.loading}
        readings={this.props.readings}
        pagination={this.props.pagination}
        filters={this.props.filters}
        onSubmitSettings={this.handleSubmitSettings}
        storedList={this.props.storedList}
        storedListItems={this.props.storedListItems}
        timeZone={this.props.timeZone}
      />
    );
  }

  componentDidMount() {
    if (this.props.storedListId) {
      this.props.fetchStoredList(this.props.storedListId);
      this.props.fetchStoredListItemList(this.props.storedListId);
      this.props.fetchStoredListReadings(
        this.props.storedListId,
        this.props.filters
      );
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.storedListId && prevProps.filters !== this.props.filters) {
      this.props.fetchStoredListReadings(
        this.props.storedListId,
        this.props.filters
      );
    }
  }

  componentWillUnmount() {
    this.props.unmountStoredListReport();
  }
}

export const StoredListReportScreen = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  FullState
>(
  InnerStoredListReportScreen.mapStateToProps,
  mapDispatchToProps
)(InnerStoredListReportScreen);
