import { Middleware } from 'redux';
import { StandardDispatch } from 'main/store';
import { FullState } from 'main/reducers';

/**
 * A module-scope variable to pass the access token from the store into
 * withAuthHeaders(), without requiring withAuthHeaders to receive "getState()"
 * from every function that calls it.
 *
 * This feels a little hacky, but it does decouple the code more. The cleanest
 * way to do this would probably be a redux middleware, but I had difficulty
 * getting that to work (particularly on application startup).
 *
 * (We could also read the tokens in from LocalStorage on every fetch, but this
 * could hurt performance in certain situations.)
 */
export const AUTH_GLOBALS = {
  dmsAccessToken: null as string | null,
  activeAreaGroupId: null as number | null,
};

/**
 * Apply this middleware to the redux store, to keep AUTH_GLOBALS in sync
 * with the redux store.
 */
export const authGlobalsMiddleware: Middleware<StandardDispatch, FullState> =
  ({ getState }) =>
  (next) =>
  (action) => {
    const result = next(action);
    AUTH_GLOBALS.dmsAccessToken = getState().auth.accessToken;
    AUTH_GLOBALS.activeAreaGroupId = getState().user.activeAreaGroupId;
    return result;
  };
