import React from 'react';
import { Trans } from '@lingui/macro';
import { Field, Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import { FormItem } from 'components/base/form/FormItem';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ActionBlock from 'components/base/actionblock/actionblock';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { ButtonPrimary } from 'components/base/button/button';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ModalContent from 'components/base/modal/modalcontent';
import { Enum, Model } from 'util/backendapi/models/api.interfaces';
import { WorkflowOption } from './changestatusmodal';
import { Comment } from 'components/modules/comments-panel/comment/comment';
import Loading from 'components/base/loading/loading';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';

export const validateChangeStatusForm = (
  values: ChangeStatusFormValues
): FormErrors => {
  const errors: FormErrors = {};

  if (!values.status) {
    errors.status = (<Trans>Status is required.</Trans>) as any;
  }

  if (!values.comment.trim()) {
    errors.comment = (<Trans>Comment is required.</Trans>) as any;
  }

  if (!values.category) {
    errors.category = (<Trans>Category is required.</Trans>) as any;
  }

  return errors;
};

export interface Props {
  initialCategory: Enum.AlarmReport_CATEGORY | null;
  comments: Model.ReportsComment[];
  isLoadingComments: boolean;
  nextStepsOptions: WorkflowOption[];
  onSubmit: (
    values: ChangeStatusFormValues,
    formik: FormikHelpers<ChangeStatusFormValues>
  ) => void;
}

export interface ChangeStatusFormValues {
  status: Enum.AlarmReport_STATUS | undefined;
  category: Enum.AlarmReport_CATEGORY | null;
  comment: string;
}

type FormErrors = FormikErrors<ChangeStatusFormValues>;

export const ChangeStatusModalView = (props: Props) => {
  return (
    <ModalContent header={<Trans>Change status</Trans>}>
      <Formik<ChangeStatusFormValues>
        initialValues={{
          status: undefined,
          comment: '',
          category: props.initialCategory,
        }}
        onSubmit={props.onSubmit}
        validate={validateChangeStatusForm}
      >
        {(formik) => (
          <>
            <Form>
              <ChangeStatusForm nextStepsOptions={props.nextStepsOptions} />
              <ActionBlock>
                <ButtonHideModal />
                <ButtonPrimary
                  id="changestatusmodal-submit"
                  type="submit"
                  disabled={formik.isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              </ActionBlock>
              {formik.status}
            </Form>
            {props.isLoadingComments ? (
              <Loading />
            ) : (
              <ol className="comment-list">
                {props.comments.map((comment) => (
                  <li key={comment.id}>
                    <Comment {...comment} />
                  </li>
                ))}
              </ol>
            )}
          </>
        )}
      </Formik>
    </ModalContent>
  );
};

interface ChangeStatusFormProps {
  nextStepsOptions: WorkflowOption[];
}
export function ChangeStatusForm(props: ChangeStatusFormProps) {
  return (
    <fieldset>
      <legend>
        <Trans>Alarm report status</Trans>
      </legend>
      <FormItem
        fieldId="changestatusmodal-status"
        label={<Trans>Status</Trans>}
      >
        <SimpleSelectField
          id="changestatusmodal-status"
          name="status"
          options={props.nextStepsOptions}
        />
        <FieldError name="status" />
      </FormItem>
      <FormItem
        fieldId="changestatusmodal-category"
        label={<Trans>Category</Trans>}
      >
        <SimpleSelectField
          id="changestatusmodal-category"
          name="category"
          placeholder={<Trans>Select a category</Trans>}
          options={menuItemsFromEnum(
            'AlarmReport_CATEGORY',
            Object.values(Enum.AlarmReport_CATEGORY)
          )}
        />
        <FieldError name="category" />
      </FormItem>
      <FormItem
        fieldId="changestatusmodal-comment"
        label={<Trans>Comment</Trans>}
      >
        <Field
          name="comment"
          id="changestatusmodal-comment"
          component="textarea"
        />
        <FieldError name="comment" />
      </FormItem>
    </fieldset>
  );
}
