import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ReportFilter,
  ReportViewProps,
  DEFAULT_HIDE,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, Plural, t, NumberFormat } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { reportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import { formatDatetimeForDisplay } from 'util/dates';
import { IntervalDisplay } from 'components/base/i18n/IntervalDisplay';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { DMSLink } from 'components/base/link/DMSLink';
import { TransEnum } from 'components/base/i18n/TransEnum';
import { AdvancedFiltersModalButton } from 'components/modules/report/filter/AdvancedFiltersModal';
import { reportFilterDatetime } from 'components/modules/report/filter/fields/FilterDate';
import { reportFilterUsers } from 'components/modules/report/filter/fields/reportFilterUsers';

export const RouteMarchScheduleFilters = (): ReportFilter[] => [
  reportFilterMenu(
    'route_march__areas__client__name',
    <Trans>Client</Trans>,
    { isMulti: true, valueType: 'string' },
    (option: Model.Client) => ({
      value: option.name,
      label: option.name,
    }),
    {
      autoFocus: true,
      placeholder: <Trans>Select a client...</Trans>,
    }
  ),
  reportFilterMenu(
    'route_march__areas__code',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'string' },
    (option: Model.Area) => ({
      value: option.code,
      label: `${option.code} - ${option.name}`,
    }),
    {
      placeholder: <Trans>Select an area...</Trans>,
    }
  ),
  reportFilterMenu(
    'route_march__code',
    <Trans>Route march</Trans>,
    { isMulti: true, valueType: 'string' },
    (option: Model.RouteMarch) => ({
      value: option.code,
      label: option.code,
    }),
    {
      placeholder: <Trans>Select a route march...</Trans>,
    }
  ),
  reportFilterUsers('created_by', <Trans>Uploaded by</Trans>),
  reportFilterDatetime('created', <Trans>Date received</Trans>, null),
];

export const RouteMarchScheduleColumns: ReportColumn<Model.ReportsRouteMarchSchedule>[] =
  [
    {
      label: <Trans>Client</Trans>,
      name: 'route_march__areas__client__name',
      visibility: DEFAULT_SHOW,
      accessor: ({ route_march__areas__client__name }) =>
        route_march__areas__client__name?.join(', '),
    },
    {
      label: <Trans>Area</Trans>,
      name: 'route_march__areas__code',
      visibility: DEFAULT_SHOW,
      accessor: ({ route_march__areas__code }) =>
        route_march__areas__code?.join(', '),
    },
    {
      label: <Trans>Route march</Trans>,
      name: 'route_march__code',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Reading frequency</Trans>,
      name: 'route_march__reading_frequency',
      visibility: DEFAULT_SHOW,
      accessor: ({ route_march__reading_frequency: value }) => (
        <IntervalDisplay value={value} />
      ),
    },
    {
      label: <Trans>Frequency tolerance</Trans>,
      name: 'route_march__reading_frequency_tolerance',
      visibility: DEFAULT_SHOW,
      accessor: ({ route_march__reading_frequency_tolerance: value }) => (
        <IntervalDisplay value={value} />
      ),
    },
    {
      label: <Trans>Date and time received</Trans>,
      name: 'created',
      visibility: DEFAULT_SHOW,
      accessor: ({ created: value }) => formatDatetimeForDisplay(value),
    },
    {
      label: <Trans>Inspector</Trans>,
      name: 'observer_name',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Batch number</Trans>,
      name: 'batch_number',
      visibility: DEFAULT_HIDE,
      accessor: ({ batch_number }) => {
        return (
          <HasPermission
            check="can_view_readings"
            noPermissionMsg={batch_number}
          >
            <DMSLink to={`/check-readings/${batch_number}`}>
              {batch_number}
            </DMSLink>
          </HasPermission>
        );
      },
    },
    {
      label: <Trans>Batch name</Trans>,
      name: 'batch-name',
      backendFieldName: 'name',
      visibility: DEFAULT_HIDE,
    },
    {
      label: <Trans>Batch status</Trans>,
      name: 'batch-status',
      backendFieldName: 'status',
      visibility: DEFAULT_HIDE,
      accessor: ({ status }) => (
        <TransEnum enum={'ReadingsBatch_STATUS'} value={status} />
      ),
    },
    {
      label: <Trans>Readings</Trans>,
      name: 'readings',
      backendFieldName: 'readings__count',
      visibility: DEFAULT_HIDE,
      accessor: ({ readings__count }) => (
        <NumberFormat value={readings__count} />
      ),
    },
    {
      label: <Trans>Errors</Trans>,
      name: 'errors',
      backendFieldName: 'readings__reading_errors__exists',
      visibility: DEFAULT_HIDE,
      accessor: ({ readings__reading_errors__exists }) =>
        readings__reading_errors__exists ? (
          <Trans>Yes</Trans>
        ) : (
          <Trans>No</Trans>
        ),
    },
  ];

export interface RouteMarchScheduleViewProps {
  reportProps: ReportViewProps<Model.ReportsRouteMarchSchedule>;
}

export const RouteMarchScheduleView: React.FunctionComponent<RouteMarchScheduleViewProps> =
  function (props) {
    const { reportProps } = props;
    const columns = RouteMarchScheduleColumns;
    const filters = RouteMarchScheduleFilters();
    return (
      <PageStandard
        name="route-march-schedule"
        header={
          reportProps.isExportMode ? (
            <Trans>Route March Schedule report</Trans>
          ) : (
            <Trans>Route March Schedule</Trans>
          )
        }
      >
        <div className="page-content-header-filters-actions">
          <ReportFiltersBlock
            filtersFrontend={filters}
            filtersToShow={[
              'route_march__areas__client__name',
              'route_march__areas__code',
              'route_march__code',
            ]}
            filtersBackend={
              reportProps.reportInfo && reportProps.reportInfo.filters
            }
            isExportMode={reportProps.isExportMode}
          />
        </div>

        <div className="filtered-table-wrapper">
          <ActionBlock className="filtered-table-options">
            <ButtonPrint />
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
            />
            <ExportReportButton
              fileNameBase={t`Route march schedule`}
              reportUrl="/reports/route-march-schedule/"
              columns={columns}
              filters={filters}
            />
            <AdvancedFiltersModalButton
              filtersBackend={reportProps.reportInfo?.filters}
              filtersFrontend={filters}
              sections={[
                {
                  name: 'batch_section',
                  filters: [
                    'route_march__areas__client__name',
                    'route_march__areas__code',
                    'route_march__code',
                    'created_by',
                  ],
                },
                {
                  name: 'date_section',
                  filters: ['created'],
                },
              ]}
            />
          </ActionBlock>

          <ReportTable
            columns={columns}
            errorMessage={reportProps.errorMessage}
            filters={filters}
            isLoading={reportProps.isLoading}
            msgFilterCount={(count) => (
              <Plural
                value={count}
                one="Filtered to 1 route march"
                other="Filtered to # route marches"
              />
            )}
            msgNoMatches={
              <Trans>No route marches match the selected filters.</Trans>
            }
            pagination={reportProps.pagination}
            records={reportProps.records}
            reportInfo={reportProps.reportInfo}
          />
        </div>
      </PageStandard>
    );
  };
