import React, { useEffect, useState } from 'react';
import { ToggleFieldProps } from '../toggle-field/ToggleField';
import classNames from 'classnames';
import { FormSection } from '../FormItem';

type Props<T = any> = Merge<
  ToggleFieldProps<T>,
  {
    defaultValue: T;
    onChange: (newValue: T) => void;
  }
>;

/**
 * Like a ToggleField, but for use outside of Formik.
 *
 * TODO: Could probably de-dupe this code into RadioField
 * and ToggleField?
 *
 * @param props
 */
export function Toggle<T = any>(props: Props<T>) {
  const {
    inline,
    name,
    disabled,
    options,
    className,
    defaultValue,
    onChange,
    ...fieldSetProps
  } = props;

  const [value, setValue] = useState<T>(defaultValue);

  useEffect(() => {
    onChange(value);
  }, [onChange, value]);

  return (
    <FormSection
      className={classNames('radio-fieldset-toggle', className, {
        'radio-fieldset-inline': inline,
      })}
      {...fieldSetProps}
    >
      <div
        className={classNames('radio-group-wrapper', {
          'form-group': !inline,
        })}
      >
        {options.map((opt) => {
          const id = `${name}-${opt.value}`;
          return (
            <div className="radio-group" key={id}>
              <input
                id={id}
                data-testid={id}
                name="showDataTable"
                type="radio"
                checked={value === opt.value}
                onChange={() => setValue(opt.value)}
                // This "value" attribute is not used directly, but is required
                // for the HTML to behave.
                value={id}
              />
              <label htmlFor={id}>{opt.label}</label>
            </div>
          );
        })}
      </div>
    </FormSection>
  );
}
