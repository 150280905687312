import { getApi } from 'util/backendapi/fetch';
import { Model } from 'util/backendapi/models/api.interfaces';
import { StandardThunk } from 'main/store';

export const ActionTypes = {
  FETCH_READINGS_COUNT_START:
    'dms/readings/processing-request/detail/FETCH_READINGS_COUNT_START',
  FETCH_READINGS_COUNT_ERROR:
    'dms/readings/processing-request/detail/FETCH_READINGS_COUNT_ERROR',
  FETCH_READINGS_COUNT_RESPONSE:
    'dms/readings/processing-request/detail/FETCH_READINGS_COUNT_RESPONSE',
} as const;

export const ActionCreators = {
  fetchReadingsCountStart: () => ({
    type: ActionTypes.FETCH_READINGS_COUNT_START,
  }),
  fetchReadingsCountResponse: (
    payload: Model.ReadingProcessingRequestCount[]
  ) => ({
    type: ActionTypes.FETCH_READINGS_COUNT_RESPONSE,
    payload,
  }),
  fetchReadingsCountError: (errorMessage: string) => ({
    type: ActionTypes.FETCH_READINGS_COUNT_ERROR,
    payload: errorMessage,
  }),
};

export type ReprocessingRequestDetailAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export interface ReprocessingRequestDetailState {
  isLoading: boolean;
  error: string;
  readingsCountData: Model.ReadingProcessingRequestCount[];
}

export function ReprocessingRequestsDetailInitialState(): ReprocessingRequestDetailState {
  return {
    error: '',
    isLoading: false,
    readingsCountData: [],
  };
}

export const ReprocessingRequestDetailReducer = function (
  state = ReprocessingRequestsDetailInitialState(),
  action: ReprocessingRequestDetailAction
): ReprocessingRequestDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_READINGS_COUNT_START:
      return {
        ...state,
        readingsCountData: [],
        isLoading: true,
      };
    case ActionTypes.FETCH_READINGS_COUNT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        readingsCountData: action.payload,
      };
    case ActionTypes.FETCH_READINGS_COUNT_ERROR:
      return {
        ...state,
        isLoading: false,
        readingsCountData: [],
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export function fetchReadingsCount(id: number): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.fetchReadingsCountStart());

    try {
      const payload = await getApi(
        `/reading-processing-requests/${id}/readings-count/`
      );
      dispatch(ActionCreators.fetchReadingsCountResponse(payload));
    } catch (e) {
      const errorMessage = e.errorMessage || e.message || 'Error';
      dispatch(ActionCreators.fetchReadingsCountError(errorMessage));
    }
  };
}
