import React from 'react';
import { FormikProps } from 'formik';
import FormChangeEffect from '../formchangeeffect/formchangeeffect';
import { CheckBoxesField } from '../checkboxes-field/CheckboxesField';
import { sortBy, uniq } from 'lodash';
import { SimpleSelectOption } from '../simpleselect/simpleselect';

const MAX_YEAR_CHECKBOX_COUNT = 100;

export type YearRangeFieldProps<T> = {
  formik: FormikProps<T>;
  name: Extract<keyof T, string>;
  startName: Extract<keyof T, string>;
  endName: Extract<keyof T, string>;
  yearOptionsName: Extract<keyof T, string>;
};

function yearRangeIsValid(startYear: string, endYear: string) {
  if (
    startYear.length === 4 &&
    endYear.length === 4 &&
    Number.isInteger(+startYear) &&
    Number.isInteger(+endYear) &&
    +endYear >= +startYear
  ) {
    return true;
  }

  return false;
}

export function yearListFromRange(startYear: string, endYear: string) {
  if (!yearRangeIsValid(startYear, endYear)) return [];

  let yearRange: number[] = [];

  let year = +startYear;
  while (year <= +endYear && yearRange.length < MAX_YEAR_CHECKBOX_COUNT) {
    yearRange.push(year);
    year++;
  }
  return yearRange;
}

export function YearCheckboxesField<T>(props: YearRangeFieldProps<T>) {
  const { formik, name, startName, endName, yearOptionsName } = props;

  return (
    <>
      <FormChangeEffect<T>
        onChange={(prevProps) => {
          if (
            prevProps.values[startName] !== formik.values[startName] ||
            prevProps.values[endName] !== formik.values[endName]
          ) {
            const startYear = formik.values[startName] as any;
            const endYear = formik.values[endName] as any;

            if (yearRangeIsValid(startYear, endYear)) {
              const years = yearListFromRange(startYear, endYear);

              // Update the current selected years to prune those outside range
              const previousYearSelection =
                [...(formik.values[name] as any)] ?? [];
              let updatedYearSelection = [...previousYearSelection];
              while (
                updatedYearSelection.length > 0 &&
                updatedYearSelection[0] < +startYear
              ) {
                updatedYearSelection.shift();
              }
              while (
                updatedYearSelection.length > 0 &&
                updatedYearSelection[updatedYearSelection.length - 1] > +endYear
              ) {
                updatedYearSelection.pop();
              }

              // Automatically select years that were not previously options to select
              let previousYearOptionsMap: Record<number, boolean> = {};
              const previousYearOptions =
                (prevProps.values[yearOptionsName] as any) ?? [];
              previousYearOptions.forEach(
                (option: SimpleSelectOption<number>) =>
                  (previousYearOptionsMap[option.value] = true)
              );

              years.forEach((year) => {
                if (!previousYearOptionsMap[year]) {
                  updatedYearSelection.push(year);
                }
              });

              updatedYearSelection = sortBy(uniq(updatedYearSelection));
              formik.setFieldValue(name, updatedYearSelection);

              // Update the year checkbox options
              const updatedYearOptions = years.map((year) => ({
                label: year,
                value: year,
              }));
              formik.setFieldValue(yearOptionsName, updatedYearOptions);
            }
          }
        }}
      />
      <CheckBoxesField
        name={name}
        options={formik.values[yearOptionsName] as any}
      />
    </>
  );
}
