import { getApi } from 'util/backendapi/fetch';
import { DuckActions, StandardThunk } from 'main/store';
import { Model } from 'util/backendapi/models/api.interfaces';

export const ActionTypes = {
  FETCH_ALARM_REPORT_START: 'dms/alarm-reports/FETCH_ALARM_REPORT_START',
  FETCH_ALARM_REPORT_RESPONSE: 'dms/alarm-reports/FETCH_ALARM_REPORT_RESPONSE',
  FETCH_ALARM_REPORT_ERROR: 'dms/alarm-reports/FETCH_ALARM_REPORT_ERROR',
} as const;

export const ActionCreators = {
  FETCH_ALARM_REPORT_START(id: number) {
    return {
      type: ActionTypes.FETCH_ALARM_REPORT_START,
      payload: { id },
    };
  },
  FETCH_ALARM_REPORT_RESPONSE(
    id: number,
    alarmReport: Model.AlarmReportDecorated,
    inspectorComment: Model.ReadingInspectorComment | null
  ) {
    return {
      type: ActionTypes.FETCH_ALARM_REPORT_RESPONSE,
      payload: { id, alarmReport, inspectorComment },
    };
  },
  FETCH_ALARM_REPORT_ERROR(id: number, e: any) {
    return {
      type: ActionTypes.FETCH_ALARM_REPORT_ERROR,
      payload: { id, message: e.message },
    };
  },
};

export type AlarmReportsAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export function alarmReportsInitialState(): AlarmReportsState {
  return {
    alarmReportId: null,
    alarmReport: null,
    loading: true,
    error: '',
    inspectorComment: null,
  };
}

export interface AlarmReportsState {
  alarmReportId: number | null;
  alarmReport: Model.AlarmReportDecorated | null;
  loading: boolean;
  error: string;
  inspectorComment: Model.ReadingInspectorComment | null;
}

export function alarmReportsReducer(
  state = alarmReportsInitialState(),
  action: AlarmReportsAction
): AlarmReportsState {
  switch (action.type) {
    case ActionTypes.FETCH_ALARM_REPORT_START:
      return {
        ...alarmReportsInitialState(),
        alarmReportId: action.payload.id,
      };
    case ActionTypes.FETCH_ALARM_REPORT_RESPONSE:
      if (action.payload.id !== state.alarmReportId) {
        return state;
      }
      return {
        ...state,
        loading: false,
        alarmReport: action.payload.alarmReport,
        inspectorComment: action.payload.inspectorComment,
      };
    case ActionTypes.FETCH_ALARM_REPORT_ERROR:
      if (action.payload.id !== state.alarmReportId) {
        return state;
      }
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        inspectorComment: null,
      };

    default:
      return state;
  }
}

export function fetchAlarmReport(id: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_ALARM_REPORT_START(id));
    try {
      const alarmReport = await getApi(`/alarm-reports/${id}/`);

      // currently base reading doesn't include inspector comments
      // that's why we need to additionally get it from related reading entry
      const reading = await getApi(`/readings/${alarmReport.reading.id}/`);

      dispatch(
        ActionCreators.FETCH_ALARM_REPORT_RESPONSE(
          id,
          alarmReport,
          reading.inspector_comment
        )
      );
    } catch (e) {
      dispatch(ActionCreators.FETCH_ALARM_REPORT_ERROR(id, e));
    }
  };
}
