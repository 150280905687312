import React from 'react';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import { FastField, FormikErrors, FormikHelpers, Field } from 'formik';
import { Trans } from '@lingui/macro';
import {
  formatDateForDisplay,
  convertDatetimeToDate,
  getCurrentDatetime,
  formatDatetimeForDisplay,
} from 'util/dates';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { DatetimeField } from 'components/base/form/datefield/datefield';
import Button from 'components/base/button/button';
import {
  DateField,
  DateFieldValue,
} from 'components/base/form/datefield/datefield';
import { MaintObsPointAllMenus, SectionProps } from '../maintobspoint.types';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { validateNumber, isNotNull } from 'util/validation';
import { DMSLink } from 'components/base/link/DMSLink';
import Loading from 'components/base/loading/loading';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';
import { AlertInfo } from 'components/base/alert/alert';

interface Props extends SectionProps {
  menuOptions: Pick<MaintObsPointAllMenus, 'tubing_type'>;
}

// formik returns empty number values as empty strings
export interface FormValues {
  install_depth: { value: string; start_datetime: string } | null;
  filter_top: string;
  filter_bottom: string;
  internal_diameter: string;
  install_date: DateFieldValue;
  base_date: DateFieldValue;
  probed_depth: string;
  probed_date: DateFieldValue;
  tubing_type: number;
  isEditingInstallDepth: boolean;
}

export default class ObsPointInstallationSection extends React.Component<Props> {
  shouldDisable = () =>
    this.props.isDisabled || this.props.isLoading || this.props.isSubmitting;

  validate(values: FormValues): FormikErrors<FormValues> {
    let errors: FormikErrors<FormValues> = {};
    const numberFields = [
      'filter_top',
      'filter_bottom',
      'probed_depth',
      'internal_diameter',
    ] as const;

    numberFields.forEach(function (fieldName) {
      const val = values[fieldName];
      if (val !== '' && !validateNumber(val)) {
        errors[fieldName] = (<Trans>This value must be a number</Trans>) as any;
      }
    });

    // If a numeric value was provided for internal_diameter, make sure it's
    // non-negative.
    if (
      values.internal_diameter !== '' &&
      !errors.internal_diameter &&
      Number(values.internal_diameter) < 0
    ) {
      errors['internal_diameter'] = (
        <Trans>This value must be zero or greater</Trans>
      ) as any;
    }

    const dateFields = ['install_date', 'base_date', 'probed_date'] as const;

    dateFields.forEach(function (fieldName) {
      if (values[fieldName] === false) {
        errors[fieldName] = (
          <Trans>This value must be a valid date</Trans>
        ) as any;
      }
    });

    if (values.isEditingInstallDepth) {
      if (!values.install_depth?.value) {
        lodashSet(
          errors,
          'install_depth.value',
          (<Trans>This field is required</Trans>) as any
        );
      }
      if (!validateNumber(values.install_depth?.value)) {
        lodashSet(
          errors,
          'install_depth.value',
          (<Trans>This value must be zero or greater</Trans>) as any
        );
      }

      if (!values.install_depth?.start_datetime) {
        lodashSet(
          errors,
          'install_depth.start_datetime',
          (<Trans>This field is required</Trans>) as any
        );
      }
    }

    return errors;
  }

  handleSubmit = async (
    values: FormValues,
    formik: FormikHelpers<FormValues>
  ) => {
    try {
      let valuesToSubmit: any = {
        ...values,
        base_date: values.base_date || null,
        filter_bottom:
          values.filter_bottom === '' ? null : values.filter_bottom,
        filter_top: values.filter_top === '' ? null : values.filter_top,
        install_date: values.install_date || null,
        internal_diameter:
          values.internal_diameter === '' ? null : +values.internal_diameter,
        probed_date: values.probed_date || null,
        probed_depth: values.probed_depth === '' ? null : values.probed_depth,
        tubing_type: values.tubing_type ? values.tubing_type : null,
      };
      delete valuesToSubmit.install_depth;
      delete valuesToSubmit.isEditingInstallDepth;

      if (values.isEditingInstallDepth && values.install_depth) {
        valuesToSubmit.observation_point_time_dependent_fields = {};
        valuesToSubmit.observation_point_time_dependent_fields[
          Enum.ObservationPoint_TIME_DEPENDENT_FIELD_NAME.install_depth
        ] = [
          {
            value: values.install_depth.value,
            start_datetime: values.install_depth.start_datetime,
          },
        ];
      }
      await this.props.onSubmit(valuesToSubmit);

      if (values.isEditingInstallDepth) {
        formik.setFieldValue('isEditingInstallDepth', false);
      }
    } catch (e) {
      showErrorsInFormik(formik, e, getExpectedFields(values));
      formik.setSubmitting(false);
    }
  };

  render() {
    const { observationPoint: op } = this.props;
    if (!op) {
      return <Loading />;
    }

    const installDepthTimeDependentFields: Model.ObservationPointTimeDependentField[] =
      op.observation_point_time_dependent_fields.install_depth;

    const initialValues: FormValues = {
      install_date: op.install_date || '',
      base_date: op.base_date || '',
      isEditingInstallDepth: false,
      install_depth: installDepthTimeDependentFields.length
        ? {
            value: installDepthTimeDependentFields[0].value,
            start_datetime: installDepthTimeDependentFields[0].start_datetime,
          }
        : { value: '', start_datetime: '' },
      internal_diameter:
        op.internal_diameter === null ? '' : op.internal_diameter.toString(),
      filter_top: op.filter_top === null ? '' : op.filter_top,
      filter_bottom: op.filter_bottom === null ? '' : op.filter_bottom,
      tubing_type: op.tubing_type ? op.tubing_type.id : 0,
      probed_date: op.probed_date || '',
      probed_depth: op.probed_depth === null ? '' : op.probed_depth,
    };

    return (
      <EditableCard<FormValues>
        name="installation"
        header={<Trans>Installation</Trans>}
        shouldDisable={this.shouldDisable()}
        hasEditPermission={this.props.hasEditPermission}
        isEditMode={this.props.isEditing}
        startEditing={this.props.startEditing}
        stopEditing={this.props.stopEditing}
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        render={({ formik, CardSectionComponent }) => {
          const isReadonly = op.setup_complete;
          return (
            <>
              {formik.status}
              <CardSectionComponent
                name="installation-dates"
                header={<Trans>Installation dates</Trans>}
                fields={[
                  this.dateField(
                    <Trans>Install date</Trans>,
                    'install_date',
                    isReadonly
                  ),
                  {
                    name: 'firstReadingDatetime',
                    label: <Trans>First read</Trans>,
                    content: (
                      <p className="non-editable-value">
                        {op.earliest_reading
                          ? formatDateForDisplay(
                              convertDatetimeToDate(
                                op.earliest_reading.reading_datetime,
                                op.time_zone.name
                              )
                            )
                          : '-'}
                      </p>
                    ),
                  },
                  this.dateField(<Trans>Base date</Trans>, 'base_date'),
                ]}
              />
              <CardSectionComponent
                name="depths"
                header={<Trans>Depths</Trans>}
                fields={[
                  <AlertInfo
                    className="alert-condensed"
                    key="install-depth-instructions"
                    additionalInformation={
                      this.props.observationPoint ? (
                        <DMSLink
                          to={`/sites/${this.props.observationPoint.site.code}`}
                        >
                          Go to {this.props.observationPoint.site.code}{' '}
                          maintenance screen.
                        </DMSLink>
                      ) : null
                    }
                  >
                    <Trans>
                      When Install Depth is changed the Cap RL of the site must
                      also be changed by the same amount to keep the Port RL
                      from changing.
                    </Trans>
                  </AlertInfo>,
                  {
                    name: 'install_depth',
                    columns: [
                      {
                        name: 'install_depth_value',
                        label: <Trans>Install depth</Trans>,
                        content: this.props.isEditing ? (
                          <div className="input-unit-wrapper">
                            <Field
                              data-testid={`installation-install_depth`}
                              disabled={!formik.values.isEditingInstallDepth}
                              type="text"
                              name="install_depth.value"
                              className="text-input-with-unit"
                            />
                            <span className="input-unit">
                              <Trans>m</Trans>
                            </span>
                            <FieldError name="install_depth.value" />
                          </div>
                        ) : formik.values.install_depth ? (
                          <>
                            <span>{formik.values.install_depth.value}</span>
                            <span className="input-unit">
                              <Trans>m</Trans>
                            </span>
                          </>
                        ) : null,
                      },
                      {
                        name: 'install_depth_datetime',
                        label: <Trans>Start</Trans>,
                        content: this.props.isEditing ? (
                          <>
                            {formik.values.isEditingInstallDepth ? (
                              <>
                                <DatetimeField name="install_depth.start_datetime" />
                                <FieldError name="install_depth.start_datetime" />
                              </>
                            ) : (
                              <>
                                <DatetimeField
                                  name="install_depth.start_datetime"
                                  disabled={true}
                                />
                                <Button
                                  onClick={() => {
                                    const currentDateTime =
                                      getCurrentDatetime();

                                    let installDepthValue = lodashGet(
                                      formik.values,
                                      'install_depth.value'
                                    );

                                    formik.setFieldValue(
                                      'install_depth',
                                      {
                                        start_datetime: currentDateTime,
                                        value: installDepthValue,
                                      },
                                      false
                                    );

                                    formik.setFieldValue(
                                      'isEditingInstallDepth',
                                      true
                                    );
                                  }}
                                >
                                  <Trans>Edit</Trans>
                                </Button>
                              </>
                            )}
                          </>
                        ) : formik.values.install_depth ? (
                          <span>
                            {formatDatetimeForDisplay(
                              formik.values.install_depth.start_datetime,
                              op.time_zone.name
                            )}
                          </span>
                        ) : null,
                      },
                      this.props.isEditing
                        ? null
                        : {
                            name: 'installDepthHistoryLink',
                            content: (
                              <DMSLink
                                to={`/observation-point/${op.code}/time-dependent-fields/install_depth`}
                              >
                                <Trans>View history</Trans>
                              </DMSLink>
                            ),
                          },
                    ].filter(isNotNull),
                  },
                  this.unitOfMeasurementField(
                    <Trans>Filter top RL (m)</Trans>,
                    'filter_top',
                    <Trans>m</Trans>
                  ),
                  this.unitOfMeasurementField(
                    <Trans>Filter bottom RL (m)</Trans>,
                    'filter_bottom',
                    <Trans>m</Trans>
                  ),
                  {
                    name: 'port_rl',
                    label: <Trans>Port RL (m)</Trans>,
                    content: (
                      <p className="non-editable-value">
                        {op.port_rl !== null ? `${op.port_rl} m` : ''}
                      </p>
                    ),
                  },
                ]}
              />
              <CardSectionComponent
                name="tubing"
                header={<Trans>Tubing</Trans>}
                fields={[
                  {
                    label: <Trans>Tubing type</Trans>,
                    name: 'tubing_type',
                    content: this.props.isEditing ? (
                      <>
                        <SimpleSelectField
                          name="tubing_type"
                          data-testid="installation-tubing_type"
                          isDisabled={this.shouldDisable()}
                          options={this.props.menuOptions.tubing_type}
                        />
                        <FieldError name="tubing_type" />
                      </>
                    ) : (
                      op &&
                      op.tubing_type &&
                      `${op.tubing_type.code} - ${op.tubing_type.name}`
                    ),
                  },
                  this.unitOfMeasurementField(
                    <Trans>Internal diameter</Trans>,
                    'internal_diameter',
                    <Trans>mm</Trans>
                  ),
                ]}
              />
              <CardSectionComponent
                name="probing"
                header={<Trans>Probing</Trans>}
                fields={[
                  this.dateField(<Trans>Probed date</Trans>, 'probed_date'),
                  this.unitOfMeasurementField(
                    <Trans>Probed depth</Trans>,
                    'probed_depth',
                    <Trans>m</Trans>
                  ),
                ]}
              />
            </>
          );
        }}
      />
    );
  }

  /**
   * Helper function for a field that displays a date
   *
   * @memberof MaintainObservationPointView
   */
  dateField = (
    label: React.ReactNode,
    name: keyof Model.ObservationPoint & keyof FormValues,
    isReadonly?: boolean | null
  ) => {
    let content;
    if (this.props.isEditing && !isReadonly) {
      content = (
        <>
          <DateField name={name} disabled={this.shouldDisable()} />
          <FieldError name={name} />
        </>
      );
    } else {
      content =
        this.props.observationPoint &&
        this.props.observationPoint[name] &&
        formatDateForDisplay(this.props.observationPoint[name] as string);
    }

    return {
      name,
      label,
      content,
    };
  };

  /**
   * Helper function for a field that displays a number with a unit of
   * measurement.
   *
   * @memberof ObsPointGeneralSection
   */
  unitOfMeasurementField = (
    label: React.ReactNode,
    name: keyof Model.ObservationPoint & keyof FormValues,
    unitOfMeasurement: React.ReactNode,
    fieldProps = {}
  ) => {
    return {
      name,
      label,
      content: this.props.isEditing ? (
        <div className="input-unit-wrapper">
          <FastField
            data-testid={`installation-${name}`}
            disabled={this.shouldDisable()}
            type="text"
            name={name}
            className="text-input-with-unit"
            {...fieldProps}
          />
          <span className="input-unit">{unitOfMeasurement}</span>
          <FieldError name={name} />
        </div>
      ) : (
        <p>
          {this.props.observationPoint && this.props.observationPoint[name]}{' '}
          {unitOfMeasurement}
        </p>
      ),
    };
  };
}
