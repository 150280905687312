import React from 'react';
import { reportFilterMenu, ReportFilterMenu } from './FilterMenu';
import { Trans } from '@lingui/macro';
import { SimpleSelectFieldProps } from 'components/base/form/simpleselect/simpleselectfield';

/**
 * Generates a report filter for a (synchronous) "areas" menu.
 * This assumes that the list of areas is being provided via reportinfo.
 *
 * @param name
 * @param extraProps
 */
export function reportFilterAreas(
  name: string,
  extraProps: Partial<SimpleSelectFieldProps<any>> = {}
) {
  return reportFilterMenu(
    name,
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>All areas</Trans>, ...extraProps }
  );
}
