import React, { useMemo, useCallback } from 'react';
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import uniqBy from 'lodash/uniqBy';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import {
  Formik,
  FormikErrors,
  Form,
  FormikProps,
  FieldArray,
  Field,
} from 'formik';
import {
  validateStoredPlotNameSection,
  StoredPlotNameSection,
} from './sections/StoredPlotNameSection';
import { FormCard, FormCardSection } from 'components/base/card/card';
import { Trans, t } from '@lingui/macro';
import { StoredPlotSaveCancelButtons } from './StoredPlotSaveCancelButtons';
import { StoredSpatialLayersSection } from './sections/StoredSpatialLayersSection';
import { StoredSpatialDimensionsSection } from './sections/StoredSpatialDimensionsSection';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { UnitOfMeasurementField } from 'components/base/form/unitofmeasurement-field/UnitOfMeasurementField';
import ActionBlock from 'components/base/actionblock/actionblock';
import Button from 'components/base/button/button';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { isTruthy, isNotNull } from 'util/validation';
import { ReportFilterMenu } from 'components/modules/report/filter/fields/FilterMenu';
import {
  ShowHideToggleField,
  yesNoToggleOptions,
} from 'components/base/form/toggle-field/ToggleField';
import { CheckBoxesField } from 'components/base/form/checkboxes-field/CheckboxesField';
import Loading from 'components/base/loading/loading';
import { getApi } from 'util/backendapi/fetch';
import { NumberField } from 'components/base/form/number-field/NumberField';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import {
  useWanderForm,
  WanderPlotFormContext,
  useWanderPlotFormContext,
} from './hooks/use-spatial-wander-form';
import {
  useSurveyDatetimes,
  selectDatetimeOptions,
} from './hooks/use-survey-datetimes';
import { StoredSpatialWanderPlotWithArea } from 'ducks/stored-plot/detail';
import { NewStoredPlotDefaults } from './stored-plot-edit-utils';
import {
  StoredPlotSaveAsValues,
  validateStoredPlotSaveAsValues,
  StoredPlotSaveAsSettings,
  getStoredPlotSaveAsSettings,
} from './StoredPlotSaveAsModal';
import { showStoredPlotErrorsInFormik } from './StoredPlotEdit';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import classNames from 'classnames';

function validateForm(
  values: StoredSpatialWanderPlotFormValues
): FormikErrors<StoredSpatialWanderPlotFormValues> {
  let errors: FormikErrors<StoredSpatialWanderPlotFormValues> = {
    ...validateStoredPlotNameSection(values),
    ...validateStoredPlotSaveAsValues(values),
  };

  values.survey_points.forEach((surveyPoint, idx) => {
    if (!surveyPoint.site) {
      lodashSet(
        errors,
        ['survey_points', idx, 'site'],
        <Trans>Site is required.</Trans>
      );
    }
    if (!surveyPoint.label) {
      lodashSet(
        errors,
        ['survey_points', idx, 'label'],
        <Trans>Label is required.</Trans>
      );
    }
    if (!surveyPoint.northing_observation_point) {
      lodashSet(
        errors,
        ['survey_points', idx, 'northing_observation_point'],
        <Trans>Northing observation point is required.</Trans>
      );
    }
    if (!surveyPoint.easting_observation_point) {
      lodashSet(
        errors,
        ['survey_points', idx, 'easting_observation_point'],
        <Trans>Easting observation point is required.</Trans>
      );
    }
  });

  if (values.base_observation_point) {
    const validBaseObsPoint = values.survey_points.some(
      (sp) =>
        sp.northing_observation_point === values.base_observation_point ||
        sp.easting_observation_point === values.base_observation_point
    );
    if (!validBaseObsPoint) {
      errors.base_observation_point = (
        <Trans>Invalid base observation point selected.</Trans>
      ) as any;
    }
  }

  if (!values.movement_legend_paperspace_x) {
    errors.movement_legend_paperspace_x = (
      <Trans>
        Movement vector legend X axis paperspace location is required.
      </Trans>
    ) as any;
  }

  if (!values.movement_legend_paperspace_y) {
    errors.movement_legend_paperspace_y = (
      <Trans>
        Movement vector legend Y axis paperspace location is required.
      </Trans>
    ) as any;
  }

  if (values.show_data_table) {
    if (!values.data_table_paperspace_x) {
      errors.data_table_paperspace_x = (
        <Trans>Data table X axis paperspace location is required.</Trans>
      ) as any;
    }

    if (!values.data_table_paperspace_y) {
      errors.data_table_paperspace_y = (
        <Trans>Data table Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  if (values.show_readings_legend) {
    if (!values.readings_legend_x) {
      errors.readings_legend_x = (
        <Trans>Readings legend X axis paperspace location is required.</Trans>
      ) as any;
    }
    if (!values.readings_legend_y) {
      errors.readings_legend_y = (
        <Trans>Readings legend Y axis paperspace location is required.</Trans>
      ) as any;
    }
  }

  const requiredFieldLabels = {
    base_layer_image: <Trans>Base image</Trans>,
    scale: <Trans>Scale</Trans>,
    wander_scale: <Trans>Wander scale</Trans>,
    paperspace_width: <Trans>Paperspace width (X axis)</Trans>,
    paperspace_height: <Trans>Paperspace height (Y axis)</Trans>,
    base_observation_point: <Trans>Base observation point</Trans>,
    base_paperspace_x: <Trans>Base obs pt X axis paperspace location</Trans>,
    base_paperspace_y: <Trans>Base obs pt Y axis paperspace location</Trans>,
  } as const;

  Object.entries(requiredFieldLabels).forEach(function ([fieldName, label]) {
    if (!values[fieldName]) {
      errors[fieldName] = (<Trans>{label} is required.</Trans>) as any;
    }
  });

  return errors;
}

export type StoredSpatialWanderPlotFormValues = StoredPlotSaveAsValues &
  Merge<
    Model.StoredSpatialWanderPlot_POST,
    {
      scale: string;
      show_readings_legend: boolean;
    }
  >;

export interface StoredSpatialWanderPlotFormProps {
  storedPlot: StoredSpatialWanderPlotWithArea | null;
  newPlotDefaults: NewStoredPlotDefaults | null;
  areaOptions: { value: number; label: string; timeZone: string }[];
  onSubmit: (
    values: Model.StoredSpatialWanderPlot_POST,
    saveAs: StoredPlotSaveAsSettings | null
  ) => Promise<Model.StoredSpatialWanderPlot>;
  onCancel: () => void;
}

export function StoredSpatialWanderPlotForm(
  props: StoredSpatialWanderPlotFormProps
) {
  const { storedPlot, areaOptions, newPlotDefaults } = props;
  const wanderPlotBag = useWanderForm(storedPlot, newPlotDefaults);
  return (
    <WanderPlotFormContext.Provider value={wanderPlotBag}>
      <Formik<StoredSpatialWanderPlotFormValues>
        validate={validateForm}
        initialValues={wanderPlotBag.initialValues}
        onSubmit={async (values, formik) => {
          const valuesForBackend: Model.StoredSpatialWanderPlot_POST = {
            ...(values as StoredSpatialWanderPlotFormValues),
            scale: +values.scale,
            data_table_paperspace_x: values.show_data_table
              ? values.data_table_paperspace_x
              : null,
            data_table_paperspace_y: values.show_data_table
              ? values.data_table_paperspace_y
              : null,
            readings_legend_x: values.show_readings_legend
              ? values.readings_legend_x
              : null,
            readings_legend_y: values.show_readings_legend
              ? values.readings_legend_y
              : null,
          };

          try {
            await props.onSubmit(
              valuesForBackend,
              getStoredPlotSaveAsSettings(values)
            );
          } catch (e) {
            formik.setSubmitting(false);
            showStoredPlotErrorsInFormik(formik, e, values);
          }
        }}
      >
        {(formik) => {
          return (
            <Form>
              {formik.status}
              <FormCard
                name="general"
                header={<Trans>General</Trans>}
                subHeader={
                  <StoredPlotSaveCancelButtons {...props} formik={formik} />
                }
                className="stored-plot-plot-card"
              >
                <StoredPlotNameSection
                  areaOptions={areaOptions}
                  plotType={Enum.PlotType.SPATIAL_WANDER}
                />
                <StoredSpatialLayersSection />
                <WanderSiteListSection formik={formik} />
                <StoredSpatialDimensionsSection />
                <WanderBaseObsPointSection formik={formik} />
                <FormCardSection
                  name="display-options"
                  header={<Trans>Display options</Trans>}
                  fields={[
                    {
                      name: 'error_ellipses',
                      label: <Trans>Error ellipses</Trans>,
                      content: (
                        <>
                          <SimpleSelectField
                            name="error_ellipses"
                            options={Object.values(
                              Enum.StoredSpatialWanderPlot_ERROR_ELLIPSE
                            ).map(
                              ReportFilterMenu.ENUM_MENU(
                                'StoredSpatialWanderPlot_ERROR_ELLIPSE'
                              )
                            )}
                          />
                          <FieldError name="error_ellipses" />
                        </>
                      ),
                    },
                    {
                      name: 'wander_scale',
                      label: <Trans>Wander scale</Trans>,
                      content: (
                        <>
                          <NumberField name="wander_scale" />
                          <FieldError name="wander_scale" />
                        </>
                      ),
                    },
                    {
                      name: 'movement_legend_paperspace_x',
                      label: (
                        <Trans>
                          Movement vector legend X axis paperspace location
                        </Trans>
                      ),
                      content: (
                        <>
                          <UnitOfMeasurementField
                            name="movement_legend_paperspace_x"
                            unit="mm"
                          />
                          <FieldError name="movement_legend_paperspace_x" />
                        </>
                      ),
                    },
                    {
                      name: 'movement_legend_paperspace_y',
                      label: (
                        <Trans>
                          Movement vector legend Y axis paperspace location
                        </Trans>
                      ),
                      content: (
                        <>
                          <UnitOfMeasurementField
                            name="movement_legend_paperspace_y"
                            unit="mm"
                          />
                          <FieldError name="movement_legend_paperspace_y" />
                        </>
                      ),
                    },
                    {
                      name: 'show_data_table',
                      label: <Trans>Data table</Trans>,
                      content: (
                        <>
                          <ShowHideToggleField name="show_data_table" />
                          <FieldError name="show_data_table" />
                          {!formik.values.show_data_table && (
                            <>
                              <FieldError name="data_table_paperspace_x" />
                              <FieldError name="data_table_paperspace_y" />
                            </>
                          )}
                        </>
                      ),
                    },
                    formik.values.show_data_table
                      ? {
                          name: 'data_table_paperspace_x',
                          label: (
                            <Trans>Data table X axis paperspace location</Trans>
                          ),
                          content: (
                            <>
                              <UnitOfMeasurementField
                                name="data_table_paperspace_x"
                                unit="mm"
                              />
                              <FieldError name="data_table_paperspace_x" />
                            </>
                          ),
                        }
                      : null,
                    formik.values.show_data_table
                      ? {
                          name: 'data_table_paperspace_y',
                          label: (
                            <Trans>Data table Y axis paperspace location</Trans>
                          ),
                          content: (
                            <>
                              <UnitOfMeasurementField
                                name="data_table_paperspace_y"
                                unit="mm"
                              />
                              <FieldError name="data_table_paperspace_y" />
                            </>
                          ),
                        }
                      : null,
                    {
                      name: 'show_readings_legend',
                      label: <Trans>Readings legend</Trans>,
                      content: (
                        <ShowHideToggleField name="show_readings_legend" />
                      ),
                    },
                    formik.values.show_readings_legend
                      ? {
                          name: 'readings_legend_x',
                          label: (
                            <Trans>
                              Readings legend X axis paperspace location
                            </Trans>
                          ),
                          content: (
                            <>
                              <UnitOfMeasurementField
                                name="readings_legend_x"
                                unit="mm"
                              />
                              <FieldError name="readings_legend_x" />
                            </>
                          ),
                        }
                      : null,
                    formik.values.show_readings_legend
                      ? {
                          name: 'readings_legend_y',
                          label: (
                            <Trans>
                              Readings legend Y axis paperspace location
                            </Trans>
                          ),
                          content: (
                            <>
                              <UnitOfMeasurementField
                                name="readings_legend_y"
                                unit="mm"
                              />
                              <FieldError name="readings_legend_y" />
                            </>
                          ),
                        }
                      : null,
                  ].filter(isNotNull)}
                />
                <WanderTimePeriodsSection
                  formik={formik}
                  areaOptions={areaOptions}
                />
              </FormCard>
              <ActionBlock className="text-right">
                <StoredPlotSaveCancelButtons {...props} formik={formik} />
              </ActionBlock>
            </Form>
          );
        }}
      </Formik>
    </WanderPlotFormContext.Provider>
  );
}

function WanderSiteListSection({
  formik,
}: {
  formik: FormikProps<StoredSpatialWanderPlotFormValues>;
}) {
  return (
    <FieldArray name="survey_points">
      {(formikArrayHelpers) => (
        <FormCardSection
          name={`stored-plot-spatial-wander-site-points`}
          className="nested stored-plot-spatial-fieldset"
          header={
            <>
              <Trans>Site display options</Trans>
              <Button
                data-test-id="spatial-plot-add-site-button"
                iconType="icon-plus"
                className="annotation-delete-button"
                onClick={() => {
                  formikArrayHelpers.push({
                    site: 0,
                    label: '',
                    northing_observation_point: 0,
                    easting_observation_point: 0,
                  });
                }}
              >
                <Trans>Add site</Trans>
              </Button>
            </>
          }
          fields={[
            {
              name: 'site-table',
              content: (
                <div className="spatial-plot-observation-points">
                  {formik.values.survey_points.length > 0 && (
                    <DragDropContext
                      onDragEnd={(result: DropResult) => {
                        if (
                          result.destination &&
                          result.source.index !== result.destination.index
                        ) {
                          formikArrayHelpers.move(
                            result.source.index,
                            result.destination.index
                          );
                        }
                      }}
                    >
                      <table className="stored-plot-spatial-table table-responsive">
                        <thead>
                          <tr>
                            <th scope="col">
                              <Trans>Site</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Label</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Display label on plot</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Display obs pt in data table</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Northing obs pt</Trans>
                            </th>
                            <th scope="col">
                              <Trans>Easting obs pt</Trans>
                            </th>
                            <th scope="col" className="action-icons">
                              <Trans>Actions</Trans>
                            </th>
                          </tr>
                        </thead>
                        <Droppable
                          droppableId={`droppable-wander-site-section`}
                        >
                          {(provided) => (
                            <tbody
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              {formik.values.survey_points.map(
                                (_surveyPoint, idx) => (
                                  <WanderSiteSection
                                    key={idx}
                                    formik={formik}
                                    surveyPointIdx={idx}
                                    surveyPointFieldPrefix={`survey_points[${idx}]`}
                                    onDelete={() =>
                                      formikArrayHelpers.remove(idx)
                                    }
                                    draggable={
                                      formik.values.survey_points.length > 1
                                    }
                                  />
                                )
                              )}
                              {provided.placeholder}
                            </tbody>
                          )}
                        </Droppable>
                      </table>
                    </DragDropContext>
                  )}
                </div>
              ),
            },
          ]}
        ></FormCardSection>
      )}
    </FieldArray>
  );
}

function WanderSiteSection({
  surveyPointIdx,
  surveyPointFieldPrefix,
  formik,
  onDelete,
  draggable,
}: {
  formik: FormikProps<StoredSpatialWanderPlotFormValues>;
  surveyPointIdx: number;
  surveyPointFieldPrefix: string;
  onDelete: () => void;
  draggable: boolean;
}) {
  const { values, setFieldValue } = formik;

  const getSelectedSiteId = useCallback(
    (values: StoredSpatialWanderPlotFormValues) => {
      return lodashGet(values, `${surveyPointFieldPrefix}.site`, 0);
    },
    [surveyPointFieldPrefix]
  );

  const selectedSiteId = getSelectedSiteId(values);

  const { loadObservationPoints, getSiteObsPoints } =
    useWanderPlotFormContext();

  const loadObservationPointsAndFillDefault = useCallback(
    async (siteId: number) => {
      const obsPointOptions = await loadObservationPoints(siteId);

      const northingObsPoint = obsPointOptions.find((obs) =>
        (obs.label as string).endsWith('N')
      );
      const eastingObsPoint = obsPointOptions.find((obs) =>
        (obs.label as string).endsWith('E')
      );

      setFieldValue(
        `${surveyPointFieldPrefix}.northing_observation_point`,
        northingObsPoint?.value ?? 0
      );

      setFieldValue(
        `${surveyPointFieldPrefix}.easting_observation_point`,
        eastingObsPoint?.value ?? 0
      );
    },
    [loadObservationPoints, surveyPointFieldPrefix, setFieldValue]
  );

  return (
    <Draggable
      draggableId={`draggable-${surveyPointIdx}`}
      index={surveyPointIdx}
      isDragDisabled={!draggable}
    >
      {(provided, snapshot) => (
        <tr
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          className={classNames({
            draggable: draggable,
            dragging: snapshot.isDragging,
          })}
        >
          <td>
            <AsyncSimpleSelectField<number>
              name={`${surveyPointFieldPrefix}.site`}
              isMulti={false}
              placeholder={'Type in to search'}
              onSearch={(inputValue: string) =>
                getApi('/sites-list/', { code__icontains: inputValue }).then(
                  (sites) => {
                    const allOtherSelectedSites = values.survey_points
                      .filter((sp, idx) => idx !== surveyPointIdx)
                      .map((sp) => sp.site);
                    return sites
                      .filter(
                        (site) => !allOtherSelectedSites.includes(site.id)
                      )
                      .map(({ id, code }) => ({
                        value: id,
                        label: code,
                      }));
                  }
                )
              }
              loadDefaults={(initialValue: number) =>
                getApi('/sites-list/', { id__in: [initialValue] }).then(
                  (sites) =>
                    sites.map(({ id, code }) => ({
                      value: id,
                      label: code,
                    }))
                )
              }
              isClearable={false}
              onChange={(value, detail) => {
                if (value !== selectedSiteId) {
                  formik.setFieldValue(
                    `${surveyPointFieldPrefix}.label`,
                    detail[0]?.label
                  );
                  loadObservationPointsAndFillDefault(value as any);
                }
              }}
            />
            <FieldError name={`${surveyPointFieldPrefix}.site`} />
          </td>
          <td>
            <Field
              type="text"
              maxLength={50}
              name={`${surveyPointFieldPrefix}.label`}
            />
            <FieldError name={`${surveyPointFieldPrefix}.label`} />
          </td>
          <td>
            <SimpleSelectField
              className="wander-plot-table-select-input"
              menuPortalTarget={document.body}
              name={`${surveyPointFieldPrefix}.show_label`}
              options={yesNoToggleOptions}
            />
            <FieldError name={`${surveyPointFieldPrefix}.show_label`} />
          </td>
          <td>
            <SimpleSelectField
              className="wander-plot-table-select-input"
              menuPortalTarget={document.body}
              name={`${surveyPointFieldPrefix}.show_in_data_table`}
              options={yesNoToggleOptions}
            />
            <FieldError name={`${surveyPointFieldPrefix}.show_in_data_table`} />
          </td>
          <td>
            <SimpleSelectField<number>
              id={`${surveyPointFieldPrefix}-northing`}
              name={`${surveyPointFieldPrefix}.northing_observation_point`}
              className="wander-plot-table-select-input"
              options={getSiteObsPoints(selectedSiteId)}
              isDisabled={!selectedSiteId}
            />
            <FieldError
              name={`${surveyPointFieldPrefix}.northing_observation_point`}
            />
          </td>
          <td>
            <SimpleSelectField<number>
              id={`${surveyPointFieldPrefix}-easting`}
              name={`${surveyPointFieldPrefix}.easting_observation_point`}
              className="wander-plot-table-select-input"
              options={getSiteObsPoints(selectedSiteId)}
              isDisabled={!selectedSiteId}
            />
            <FieldError
              name={`${surveyPointFieldPrefix}.easting_observation_point`}
            />
          </td>
          <td className="action-icons">
            {formik.values.survey_points.length > 1 && (
              <ButtonShowConfirmation
                iconOnly={true}
                iconType="icon-circle-minus"
                className="btn-link-panel"
                title={t`Delete`}
                name={`${surveyPointFieldPrefix}-delete`}
                onConfirm={async () => onDelete()}
                content={
                  <>
                    <Trans>
                      Are you sure you want to delete this site from this plot?
                    </Trans>{' '}
                    <Trans>This action is not reversible.</Trans>
                  </>
                }
                okBtnText={<Trans>Yes, delete</Trans>}
              />
            )}
          </td>
        </tr>
      )}
    </Draggable>
  );
}

function WanderBaseObsPointSection({
  formik,
}: {
  formik: FormikProps<StoredSpatialWanderPlotFormValues>;
}) {
  const { allObsPoints } = useWanderPlotFormContext();

  const selectedObsPoints = useMemo(() => {
    const selectedIds = formik.values.survey_points
      .flatMap((sp) => [
        sp.northing_observation_point,
        sp.easting_observation_point,
      ])
      .filter(isTruthy);

    return uniqBy(
      selectedIds
        .map((id) => allObsPoints.find((obs) => obs.value === id))
        .filter(isTruthy),
      (item) => item.value
    );
  }, [formik.values, allObsPoints]);

  return (
    <FormCardSection
      name="reference-point"
      header={<Trans>Reference point</Trans>}
      fields={[
        {
          name: 'base_observation_point',
          label: <Trans>Base observation point</Trans>,
          content: (
            <>
              <SimpleSelectField<number>
                name="base_observation_point"
                options={selectedObsPoints}
              />
              <FieldError name="base_observation_point" />
            </>
          ),
        },
        {
          name: 'base_x_axis_paperspace_location',
          label: <Trans>Base obs pt X axis paperspace location</Trans>,
          content: (
            <>
              <UnitOfMeasurementField name="base_paperspace_x" unit="mm" />
              <FieldError name="base_paperspace_x" />
            </>
          ),
        },
        {
          name: 'base_paperspace_y',
          label: <Trans>Base obs pt Y axis paperspace location</Trans>,
          content: (
            <>
              <UnitOfMeasurementField name="base_paperspace_y" unit="mm" />
              <FieldError name="base_paperspace_y" />
            </>
          ),
        },
      ]}
    />
  );
}

function WanderTimePeriodsSection({
  formik,
  areaOptions,
}: {
  formik: FormikProps<StoredSpatialWanderPlotFormValues>;
  areaOptions: { value: number; label: string; timeZone: string }[];
}) {
  const timeZone = useMemo(() => {
    const area = areaOptions.find((area) => area.value === formik.values.area);
    return area?.timeZone ?? null;
  }, [formik.values.area, areaOptions]);

  const selectedObsPointIds = useMemo(() => {
    return formik.values.survey_points
      .flatMap((sp) => [
        sp.northing_observation_point,
        sp.easting_observation_point,
      ])
      .filter(isTruthy);
  }, [formik.values]);

  const [datetimes, isLoadingSurveyDatetimes] =
    useSurveyDatetimes(selectedObsPointIds);

  const surveyDatetimeOptions = useMemo(() => {
    return selectDatetimeOptions(datetimes, timeZone);
  }, [datetimes, timeZone]);

  return (
    <FormCardSection
      name="time-period"
      header={<Trans>Time periods</Trans>}
      fields={[
        {
          name: 'surveys',
          label: <Trans>Surveys</Trans>,
          content: (
            <>
              {isLoadingSurveyDatetimes && <Loading />}
              <FieldError name="time_periods" />
              {!surveyDatetimeOptions.length &&
              !formik.values.survey_points.some(
                (sp) =>
                  sp.northing_observation_point || sp.easting_observation_point
              ) ? (
                <Trans>
                  Surveys will be listed when an observation point has been
                  selected.
                </Trans>
              ) : (
                <CheckBoxesField
                  name="time_periods"
                  options={surveyDatetimeOptions}
                />
              )}
            </>
          ),
        },
      ]}
    />
  );
}
