import React from 'react';
import { Trans } from '@lingui/macro';
import Button from 'components/base/button/button';
import { PageStandardContext } from 'components/modules/pagestandard/pagestandard';

interface Props {
  header?: React.ReactNode;
  children: React.ReactNode;
}

export default function PanelContent(props: Props) {
  return (
    <PageStandardContext.Consumer>
      {(context) => (
        <section
          id={`${context.pageName}-panel-${context.activePanelName}`}
          className="panel"
        >
          <div className="panel-header">
            {props.header && <h3>{props.header}</h3>}
            <Button
              id="panel-close"
              className="close"
              iconType="icon-cross"
              iconOnly={true}
              onClick={context.hidePanel}
            >
              <Trans>Close</Trans>
            </Button>
          </div>
          <div className="panel-content">{props.children}</div>
        </section>
      )}
    </PageStandardContext.Consumer>
  );
}
