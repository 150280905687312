import {
  getApi,
  getPaginated,
  PaginatedResponse,
  postApi,
  deleteApi,
} from 'util/backendapi/fetch';
import { Enum, Model, Filter } from 'util/backendapi/models/api.interfaces';
import { PaginationResponseData } from 'util/backendapi/pagination';
import { StandardThunk } from 'main/store';
import { errorToString } from 'util/backendapi/error';
import { PolymorphicComment } from 'util/backendapi/types/Model';

export const ActionTypes = {
  FETCH_COMMENTS_LIST_START: 'dms/comments/list/FETCH_COMMENTS_LIST_START',
  FETCH_COMMENTS_LIST_ERROR: 'dms/comments/list/FETCH_COMMENTS_LIST_ERROR',
  FETCH_COMMENTS_LIST_RESPONSE:
    'dms/comments/list/FETCH_COMMENTS_LIST_RESPONSE',
  FETCH_COMMENTS_INFO_START: 'dms/comments/list/FETCH_COMMENTS_INFO_START',
  FETCH_COMMENTS_INFO_ERROR: 'dms/comments/list/FETCH_COMMENTS_INFO_ERROR',
  FETCH_COMMENTS_INFO_RESPONSE:
    'dms/comments/list/FETCH_COMMENTS_INFO_RESPONSE',
  ADD_COMMENT_START: 'dms/comments/ADD_COMMENT_START',
  ADD_COMMENT_ERROR: 'dms/comments/ADD_COMMENT_ERROR',
  ADD_COMMENT_RESPONSE: 'dms/comments/list/ADD_COMMENT_RESPONSE',
  DELETE_COMMENT_START: 'dms/comments/list/DELETE_COMMENT_START',
  DELETE_COMMENT_RESPONSE: 'dms/comments/list/DELETE_COMMENT_RESPONSE',
  DELETE_COMMENT_ERROR: ' dms/comments/list/DELETE_COMMENT_ERROR',
} as const;

export const ActionCreators = {
  fetchCommentsListStart: (commentType?: Enum.Comment_RESOURCE_TYPE) => ({
    type: ActionTypes.FETCH_COMMENTS_LIST_START,
    commentType,
  }),
  fetchCommentsListResponse: (
    payload: PaginatedResponse<Model.ReportsComment>,
    commentType?: Enum.Comment_RESOURCE_TYPE
  ) => ({
    type: ActionTypes.FETCH_COMMENTS_LIST_RESPONSE,
    payload,
    commentType,
  }),
  fetchCommentsListError: (
    errorMessage: string,
    commentType?: Enum.Comment_RESOURCE_TYPE
  ) => ({
    type: ActionTypes.FETCH_COMMENTS_LIST_ERROR,
    payload: errorMessage,
    commentType,
  }),

  fetchCommentsInfoStart: () => ({
    type: ActionTypes.FETCH_COMMENTS_INFO_START,
  }),
  fetchCommentsInfoResponse: (payload: Model.ReportsCommentsInfo) => ({
    type: ActionTypes.FETCH_COMMENTS_INFO_RESPONSE,
    payload,
  }),
  fetchCommentsInfoError: (errorMessage: string) => ({
    type: ActionTypes.FETCH_COMMENTS_INFO_ERROR,
    payload: errorMessage,
  }),
  addCommentStart: () => ({
    type: ActionTypes.ADD_COMMENT_START,
  }),
  addCommentResponse: (payload: Model.PolymorphicComment) => ({
    type: ActionTypes.ADD_COMMENT_RESPONSE,
    payload,
  }),
  addCommentError: (errorMessage: string) => ({
    type: ActionTypes.ADD_COMMENT_ERROR,
    payload: errorMessage,
  }),
  deleteCommentStart: () => ({
    type: ActionTypes.DELETE_COMMENT_START,
  }),
  deleteCommentResponse: (commentId: number) => ({
    type: ActionTypes.DELETE_COMMENT_RESPONSE,
    payload: { commentId },
  }),
  deleteCommentError: (errorMessage: string) => ({
    type: ActionTypes.DELETE_COMMENT_ERROR,
    payload: errorMessage,
  }),
};

export type CommentsPanelListAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export interface CommentsPanelListState {
  isLoading: boolean;
  error: string | null;
  items: Model.ReportsComment[];
  pagination: PaginationResponseData | null;
  reportInfo: Model.ReportsCommentsInfo;
  commentType: Enum.Comment_RESOURCE_TYPE | null | undefined;
  deleteError: string | null;
}

export function commentsPanelListInitialState(): CommentsPanelListState {
  return {
    error: null,
    reportInfo: {
      filters: [],
      columns: [],
      default_sorting: [],
      report_type: Enum.ReportInfo_REPORT.comment_report,
    },
    isLoading: false,
    pagination: null,
    items: [],
    commentType: null,
    deleteError: null,
  };
}

export const commentsPanelListReducer = function (
  state = commentsPanelListInitialState(),
  action: CommentsPanelListAction
): CommentsPanelListState {
  switch (action.type) {
    case ActionTypes.FETCH_COMMENTS_LIST_START:
      return {
        ...state,
        isLoading: true,
        commentType: action.commentType,
      };
    case ActionTypes.FETCH_COMMENTS_LIST_RESPONSE:
      if (state.commentType !== action.commentType) {
        return state;
      }
      return {
        ...state,
        isLoading: false,
        items: action.payload.data,
        pagination: action.payload.pagination,
        commentType: action.commentType,
      };
    case ActionTypes.FETCH_COMMENTS_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        items: [],
        error: action.payload,
        commentType: action.commentType,
      };
    case ActionTypes.FETCH_COMMENTS_INFO_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_COMMENTS_INFO_RESPONSE:
      return {
        ...state,
        isLoading: false,
        reportInfo: action.payload,
      };
    case ActionTypes.FETCH_COMMENTS_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.ADD_COMMENT_START:
      return state;
    case ActionTypes.ADD_COMMENT_RESPONSE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.ADD_COMMENT_ERROR:
      return state;
    case ActionTypes.DELETE_COMMENT_START:
      return {
        ...state,
        deleteError: null,
      };
    case ActionTypes.DELETE_COMMENT_RESPONSE:
      return {
        ...state,
        items: state.items.filter(
          (item) => item.id !== action.payload.commentId
        ),
      };
    case ActionTypes.DELETE_COMMENT_ERROR:
      return {
        ...state,
        deleteError: action.payload,
      };
    default: {
      return state;
    }
  }
};

export function fetchCommentsList(
  params: Filter.ReportsComments,
  commentType?: Enum.Comment_RESOURCE_TYPE
): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.fetchCommentsListStart(commentType));

    try {
      const payload = await getPaginated('/reports/comments/', params);
      dispatch(ActionCreators.fetchCommentsListResponse(payload, commentType));
    } catch (e) {
      const errorMessage = e.errorMessage || e.message || 'Error';
      dispatch(
        ActionCreators.fetchCommentsListError(errorMessage, commentType)
      );
    }
  };
}

export function fetchCommentsReportInfo(): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.fetchCommentsInfoStart());

    try {
      const reportInfo = await getApi('/reports/comments/info/');
      dispatch(ActionCreators.fetchCommentsInfoResponse(reportInfo));
    } catch (e) {
      const errorMessage = e.errorMessage || e.message || 'Error';
      dispatch(ActionCreators.fetchCommentsInfoError(errorMessage));
    }
  };
}

export function addComment(
  payload: Model.PolymorphicComment_POST
): StandardThunk<PolymorphicComment> {
  return async function (dispatch) {
    dispatch(ActionCreators.addCommentStart());

    try {
      const comment = await postApi('/comments/', payload);
      dispatch(ActionCreators.addCommentResponse(comment));
      return comment;
    } catch (e) {
      dispatch(ActionCreators.addCommentError(errorToString(e)));

      // for formik to handle form errors
      throw e;
    }
  };
}

export function deleteComment(commentId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.deleteCommentStart());

    try {
      await deleteApi(`/comments/${commentId}/`);
      dispatch(ActionCreators.deleteCommentResponse(commentId));
    } catch (e) {
      dispatch(ActionCreators.deleteCommentError(errorToString(e)));
      throw e;
    }
  };
}
