import React from 'react';
import { Trans } from '@lingui/macro';
import { createSelector } from 'reselect';
import { connect as redux_connect, ResolveThunks } from 'react-redux';
import { connect as formik_connect, FormikContextType } from 'formik';
import { FullState } from 'main/reducers';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import {
  fetchAreaObsPointOptions,
  AreaObsPointMenuState,
} from 'ducks/data-logger/area-obs-point-menu';

interface OwnProps {
  name: string;
  initialSelectionLabel: React.ReactNode;
  selectedAreaId: number | '';
}

interface FormikProps {
  formik: FormikContextType<any>;
}

interface StateProps {
  isLoading: boolean;
  loadedAreaId: number | null;
  menuOptions: SimpleSelectOption<number | ''>[];
  errorMessage: string | null;
}

const mapDispatchToProps = {
  fetchObsPointMenuOptions: fetchAreaObsPointOptions,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps> & FormikProps;

interface State {
  isInitialLoading: boolean;
}

/**
 * A Formik component that displays a menu to select an observatino point,
 * filtered by an area ID.
 *
 * It takes a prop that tells it which area is selected, and it takes care
 * of fetching the appropriate sites for itself, as well as updating its
 * underlying Formik value as needed.
 *
 * TODO: This is largely copy-pasted from SitesMenuAutoLoading
 */
export class InnerAreaObsPointMenu extends React.Component<Props, State> {
  static mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
    const myState = state.dataLogger.areaObsPointMenu;
    return {
      errorMessage: myState.errorMessage,
      loadedAreaId: myState.loadedAreaId,
      isLoading: myState.isLoading,
      menuOptions: makeMenuOptions(myState, ownProps),
    };
  }

  constructor(props: Props) {
    super(props);

    // Special handling if the component needs to load its menu content at
    // the outset.
    this.state = {
      isInitialLoading:
        Boolean(props.selectedAreaId) &&
        (props.selectedAreaId !== props.loadedAreaId ||
          (props.selectedAreaId === props.loadedAreaId && props.isLoading)),
    };
  }

  render() {
    const {
      name,
      selectedAreaId,
      isLoading,
      initialSelectionLabel,
      loadedAreaId,
      menuOptions,
      fetchObsPointMenuOptions,
      formik,
      ...otherProps
    } = this.props;

    let placeholderProps = {};
    if (this.props.errorMessage) {
      placeholderProps = {
        isDisabled: true,
        placeholder: <Trans>Error: {this.props.errorMessage}</Trans>,
      };
    } else if (!this.props.selectedAreaId) {
      placeholderProps = {
        isDisabled: true,
      };
    } else if (this.state.isInitialLoading) {
      placeholderProps = {
        isDisabled: true,
        placeholder: this.props.initialSelectionLabel,
      };
    } else if (this.props.isLoading) {
      placeholderProps = {
        isDisabled: true,
        placeholder: <Trans>Loading...</Trans>,
      };
    }

    return (
      <SimpleSelectField<number | ''>
        {...otherProps}
        name={this.props.name}
        isLoading={this.state.isInitialLoading || this.props.isLoading}
        options={this.props.menuOptions}
        isMulti={false}
        {...placeholderProps}
      />
    );
  }

  componentDidMount() {
    if (
      this.props.selectedAreaId &&
      this.props.selectedAreaId !== this.props.loadedAreaId
    ) {
      this.props.fetchObsPointMenuOptions(this.props.selectedAreaId);
    }
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    // Detect whether the initial load has finished. This will happen if
    // we were in "initial loading" state, but now the redux data says
    // we're done loading, and the area we've loaded matches the selected
    // area.
    if (
      state.isInitialLoading &&
      props.selectedAreaId === props.loadedAreaId &&
      !props.isLoading
    ) {
      return {
        isInitialLoading: false,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps: Props) {
    // They've changed the area selection
    if (prevProps.selectedAreaId !== this.props.selectedAreaId) {
      // Clear our menu selection
      if (this.props.formik.values[this.props.name] !== '') {
        this.props.formik.setFieldValue(this.props.name, '');
      }

      if (
        this.props.selectedAreaId &&
        this.props.selectedAreaId !== this.props.loadedAreaId
      ) {
        this.props.fetchObsPointMenuOptions(this.props.selectedAreaId);
      }

      // Clear the initial loading flag, if it's still set.
      if (this.state.isInitialLoading) {
        this.setState({ isInitialLoading: false });
      }
    }
  }
}

export const AreaObsPointMenu = formik_connect<OwnProps>(
  redux_connect<StateProps, DispatchProps, OwnProps, FullState>(
    InnerAreaObsPointMenu.mapStateToProps,
    mapDispatchToProps
  )(InnerAreaObsPointMenu)
);

const isCorrectOptionsLoaded = createSelector(
  (_state: AreaObsPointMenuState, props: OwnProps) => props.selectedAreaId,
  (state: AreaObsPointMenuState) => state.loadedAreaId,
  (state: AreaObsPointMenuState) => !(state.isLoading || state.errorMessage),
  function (selectedArea, loadedArea, isLoaded) {
    return Boolean(selectedArea && isLoaded && selectedArea === loadedArea);
  }
);

/**
 * A memoized selector function to assemble the site data into the form
 * needed for the menu.
 */
const makeMenuOptions = createSelector(
  isCorrectOptionsLoaded,
  (state: AreaObsPointMenuState) => state.menuOptions,
  function (isLoaded, menuOptions) {
    if (!isLoaded) {
      return [];
    }

    return [
      {
        value: '' as '',
        label: <Trans>N/A</Trans>,
      },
      ...menuOptions.map((item) => ({
        value: item.id,
        label: item.code,
      })),
    ];
  }
);
