import { Model } from 'util/backendapi/models/api.interfaces';
import { getApi, patchApi, postApi, deleteApi } from 'util/backendapi/fetch';
import { errorToString } from 'util/backendapi/error';
import { DuckActions, StandardThunk } from 'main/store';
import { switchActiveAreaGroup } from './login';

export const ActionTypes = {
  FETCH_GROUPS_START: 'dms/groups/FETCH_GROUPS_START',
  FETCH_GROUPS_RESPONSE: 'dms/groups/FETCH_GROUPS_RESPONSE',
  FETCH_GROUPS_ERROR: 'dms/groups/FETCH_GROUPS_ERROR',
  CREATE_GROUP_START: 'dms/groups/CREATE_GROUP_START',
  CREATE_GROUP_RESPONSE: 'dms/groups/CREATE_GROUP_RESPONSE',
  CREATE_GROUP_ERROR: 'dms/groups/CREATE_GROUP_ERROR',
  DELETE_GROUP_RESPONSE: 'dms/groups/DELETE_GROUP_RESPONSE',
  UPDATE_GROUP_START: 'dms/groups/UPDATE_GROUP_START',
  UPDATE_GROUP_RESPONSE: 'dms/groups/UPDATE_GROUP_RESPONSE',
  UPDATE_GROUP_ERROR: 'dms/groups/UPDATE_GROUP_ERROR',
  FETCH_GROUP_USERS_START: 'dms/groups/FETCH_GROUP_USERS_START',
  FETCH_GROUP_USERS_RESPONSE: 'dms/groups/FETCH_GROUP_USERS_RESPONSE',
  FETCH_GROUP_USERS_ERROR: 'dms/groups/FETCH_GROUP_USERS_ERROR',
  FETCH_GROUP_USERS_UNMOUNT: 'dms/groups/FETCH_GROUP_USERS_UNMOUNT',
  SUBMIT_USER_ROLES_GROUP_START: 'dms/groups/SUBMIT_USER_ROLES_GROUP_START',
  SUBMIT_USER_ROLES_GROUP_RESPONSE:
    'dms/groups/SUBMIT_USER_ROLES_GROUP_RESPONSE',
  SUBMIT_USER_ROLES_GROUP_ERROR: 'dms/groups/SUBMIT_USER_ROLES_GROUP_ERROR',
  REMOVE_USER_FROM_GROUP_START: 'dms/groups/REMOVE_USER_FROM_GROUP_START',
  REMOVE_USER_FROM_GROUP_RESPONSE: 'dms/groups/REMOVE_USER_FROM_GROUP_RESPONSE',
  REMOVE_USER_FROM_GROUP_ERROR: 'dms/groups/REMOVE_USER_FROM_GROUP_ERROR',
} as const;

export const ActionCreators = {
  FETCH_GROUPS_START: () => ({ type: ActionTypes.FETCH_GROUPS_START }),
  FETCH_GROUPS_RESPONSE: (
    groups: Model.AreaGroupDecorated[],
    roles: Model.Role[],
    allUsers: Model.User[]
  ) => ({
    type: ActionTypes.FETCH_GROUPS_RESPONSE,
    payload: { groups, roles, allUsers },
  }),
  FETCH_GROUPS_ERROR: (errorMessage: string) => ({
    type: ActionTypes.FETCH_GROUPS_ERROR,
    error: true,
    payload: errorMessage,
  }),
  CREATE_GROUP_START: () => ({ type: ActionTypes.CREATE_GROUP_START }),
  CREATE_GROUP_RESPONSE: (group: Model.AreaGroupDecorated) => ({
    type: ActionTypes.CREATE_GROUP_RESPONSE,
    payload: group,
  }),
  CREATE_GROUP_ERROR: (errorMessage: string) => ({
    type: ActionTypes.CREATE_GROUP_ERROR,
    error: true,
    payload: errorMessage,
  }),
  DELETE_GROUP_RESPONSE: (groupId: number) => ({
    type: ActionTypes.DELETE_GROUP_RESPONSE,
    groupId,
  }),
  UPDATE_GROUP_START: () => ({ type: ActionTypes.UPDATE_GROUP_START }),
  UPDATE_GROUP_RESPONSE: (group: Model.AreaGroupDecorated) => ({
    type: ActionTypes.UPDATE_GROUP_RESPONSE,
    payload: group,
  }),
  UPDATE_GROUP_ERROR: (errorMessage: string) => ({
    type: ActionTypes.UPDATE_GROUP_ERROR,
    error: true,
    payload: errorMessage,
  }),
  FETCH_GROUP_USERS_START: (groupId: number) => ({
    type: ActionTypes.FETCH_GROUP_USERS_START,
    groupId,
  }),
  FETCH_GROUP_USERS_RESPONSE: (
    groupId: number,
    groupUsers: Model.UserAreaGroupDecorated[]
  ) => ({
    type: ActionTypes.FETCH_GROUP_USERS_RESPONSE,
    groupId,
    payload: groupUsers,
  }),
  FETCH_GROUP_USERS_ERROR: (groupId: number, errorMessage: string) => ({
    type: ActionTypes.FETCH_GROUP_USERS_ERROR,
    groupId,
    error: true,
    payload: errorMessage,
  }),
  FETCH_GROUP_USERS_UNMOUNT: (groupId: number) => ({
    type: ActionTypes.FETCH_GROUP_USERS_UNMOUNT,
    groupId,
  }),
  SUBMIT_USER_ROLES_GROUP_START: () => ({
    type: ActionTypes.SUBMIT_USER_ROLES_GROUP_START,
  }),
  SUBMIT_USER_ROLES_GROUP_RESPONSE: () => ({
    type: ActionTypes.SUBMIT_USER_ROLES_GROUP_RESPONSE,
  }),
  SUBMIT_USER_ROLES_GROUP_ERROR: (errorMessage: string) => ({
    type: ActionTypes.SUBMIT_USER_ROLES_GROUP_ERROR,
    error: true,
    payload: errorMessage,
  }),
  REMOVE_USER_FROM_GROUP_START: () => ({
    type: ActionTypes.REMOVE_USER_FROM_GROUP_START,
  }),
  REMOVE_USER_FROM_GROUP_RESPONSE: () => ({
    type: ActionTypes.REMOVE_USER_FROM_GROUP_RESPONSE,
  }),
  REMOVE_USER_FROM_GROUP_ERROR: (errorMessage: string) => ({
    type: ActionTypes.REMOVE_USER_FROM_GROUP_ERROR,
    error: true,
    payload: errorMessage,
  }),
} as const;

export type GroupAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface GroupState {
  error: boolean;
  errorMessage: string;
  isLoading: boolean;
  groups: null | Model.AreaGroupDecorated[];
  isSubmitting: boolean;
  allUsers: Model.User[] | null;
  roles: Model.Role[] | null;
  groupUsers: {
    groupId: number | null;
    isLoading: boolean;
    groupUsers: Model.UserAreaGroupDecorated[] | null;
    error: boolean;
    errorMessage: string;
    isSubmitting: boolean;
    submitError: string | null;
    isRemoving: boolean;
  };
}

export function groupInitialState(): GroupState {
  return {
    error: false,
    errorMessage: '',
    isLoading: false,
    groups: null,
    isSubmitting: false,
    allUsers: null,
    roles: null,
    groupUsers: {
      groupId: null,
      isLoading: false,
      groupUsers: null,
      error: false,
      errorMessage: '',
      isSubmitting: false,
      submitError: null,
      isRemoving: false,
    },
  };
}

export function groupReducer(
  state: GroupState = groupInitialState(),
  action: GroupAction
): GroupState {
  switch (action.type) {
    case ActionTypes.FETCH_GROUPS_START:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_GROUPS_RESPONSE:
      return {
        ...state,
        isLoading: false,
        groups: action.payload.groups,
        allUsers: action.payload.allUsers,
        roles: action.payload.roles,
        groupUsers: {
          ...state.groupUsers,
          isLoading: false,
        },
      };
    case ActionTypes.FETCH_GROUPS_ERROR:
      return {
        ...state,
        isLoading: false,
        error: true,
        errorMessage: action.payload,
      };
    case ActionTypes.CREATE_GROUP_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.CREATE_GROUP_RESPONSE:
      return {
        ...state,
        isSubmitting: false,
        groups: [...(state.groups || []), action.payload],
      };
    case ActionTypes.CREATE_GROUP_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };
    case ActionTypes.DELETE_GROUP_RESPONSE:
      return {
        ...state,
        groups: state.groups?.filter((g) => g.id !== action.groupId) ?? null,
      };
    case ActionTypes.UPDATE_GROUP_START:
      return {
        ...state,
        isSubmitting: true,
      };
    case ActionTypes.UPDATE_GROUP_RESPONSE: {
      const groups = state.groups || [];
      const updatedGroup = action.payload;

      const updatedGroups = groups.map(function (group) {
        if (group.id === updatedGroup.id) {
          return updatedGroup;
        }
        return group;
      });

      return {
        ...state,
        isSubmitting: false,
        groups: updatedGroups,
      };
    }
    case ActionTypes.UPDATE_GROUP_ERROR:
      return {
        ...state,
        isSubmitting: false,
      };
    case ActionTypes.FETCH_GROUP_USERS_START:
      return {
        ...state,
        groupUsers: {
          ...groupInitialState().groupUsers,
          isLoading: true,
          groupId: action.groupId,
        },
      };
    case ActionTypes.FETCH_GROUP_USERS_RESPONSE:
      if (state.groupUsers.groupId !== action.groupId) {
        return state;
      }
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isLoading: false,
          groupUsers: action.payload,
        },
      };
    case ActionTypes.FETCH_GROUP_USERS_ERROR:
      if (state.groupUsers.groupId !== action.groupId) {
        return state;
      }
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isLoading: false,
          errorMessage: action.payload,
        },
      };
    case ActionTypes.SUBMIT_USER_ROLES_GROUP_START:
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isSubmitting: true,
        },
      };
    case ActionTypes.SUBMIT_USER_ROLES_GROUP_RESPONSE:
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isSubmitting: false,
        },
      };
    case ActionTypes.SUBMIT_USER_ROLES_GROUP_ERROR:
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isSubmitting: false,
          submitError: action.payload,
        },
      };
    case ActionTypes.REMOVE_USER_FROM_GROUP_START:
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isRemoving: true,
        },
      };
    case ActionTypes.REMOVE_USER_FROM_GROUP_RESPONSE:
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isRemoving: false,
        },
      };
    case ActionTypes.REMOVE_USER_FROM_GROUP_ERROR:
      return {
        ...state,
        groupUsers: {
          ...state.groupUsers,
          isRemoving: false,
          error: true,
        },
      };
    case ActionTypes.FETCH_GROUP_USERS_UNMOUNT:
      if (state.groupUsers.groupId !== action.groupId) {
        return state;
      }
      return {
        ...state,
        groupUsers: {
          ...groupInitialState().groupUsers,
        },
      };
    default:
      return {
        ...state,
      };
  }
}

export function fetchAllGroups(): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.FETCH_GROUPS_START());

    try {
      const [groups, roles, allUsers] = await Promise.all([
        getApi('/area-groups/'),
        getApi('/roles/'),
        getApi('/users/', { is_active: true }),
      ]);

      dispatch(ActionCreators.FETCH_GROUPS_RESPONSE(groups, roles, allUsers));
    } catch (error) {
      dispatch(ActionCreators.FETCH_GROUPS_ERROR(errorToString(error)));
    }
  };
}

export function fetchGroupUsers(groupId: number): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.FETCH_GROUP_USERS_START(groupId));

    try {
      const groupUsers = await getApi(
        `/area-groups/${groupId}/user-area-groups/`
      );
      dispatch(ActionCreators.FETCH_GROUP_USERS_RESPONSE(groupId, groupUsers));
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_GROUP_USERS_ERROR(groupId, errorToString(e))
      );
    }
  };
}

/**
 * Add a user to a group, or change their roles in a group.
 *
 * TODO: Split this into two methods? :-P
 *
 * @param {number[]} roles
 * @param {?number} userGroupId If provided, patch this user-group membership
 * @param {?number} userId If userGroupId is not provided, this is the user
 * to add to a group.
 * @param {?number} groupId If userGroupId is not provided, this is the group
 * to add the user to.
 */
export function changeUserRolesOnGroup(
  roles: number[],
  userGroupId?: number,
  userId?: number,
  groupId?: number
): StandardThunk {
  return async (dispatch) => {
    dispatch({
      type: ActionTypes.SUBMIT_USER_ROLES_GROUP_START,
    });

    try {
      if (userGroupId) {
        await patchApi(`/user-area-groups/${userGroupId}/`, { roles });
      } else {
        await postApi('/user-area-groups/', {
          roles,
          user: userId!,
          area_group: groupId!,
        });
      }

      // TODO: If we've changed group membership for the logged-in user, we should also
      // refresh the current user data. (Currently, this is expected to be taken
      // care of separately, by the component that dispatches this thunk.)
      dispatch(ActionCreators.SUBMIT_USER_ROLES_GROUP_RESPONSE());
    } catch (e) {
      dispatch(ActionCreators.SUBMIT_USER_ROLES_GROUP_ERROR(errorToString(e)));
    }
  };
}

export function removeUserFromGroup(userGroupId: number): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.REMOVE_USER_FROM_GROUP_START());

    try {
      await deleteApi(`/user-area-groups/${userGroupId}/`);

      // TODO: If we've changed group membership for the logged-in user, we should also
      // refresh the current user data. (Currently, this is expected to be taken
      // care of separately, by the component that dispatches this thunk.)
      dispatch(ActionCreators.REMOVE_USER_FROM_GROUP_RESPONSE());
    } catch (e) {
      dispatch(ActionCreators.REMOVE_USER_FROM_GROUP_ERROR(errorToString(e)));
    }
  };
}

export const unmountMaintainGroupUsersPanel =
  ActionCreators.FETCH_GROUP_USERS_UNMOUNT;

export function createGroup(
  payload: ForPostOrPut<Model.AreaGroup>
): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.CREATE_GROUP_START());

    try {
      const response = await postApi('/area-groups/', payload);

      dispatch(ActionCreators.CREATE_GROUP_RESPONSE(response));

      return response;
    } catch (e) {
      dispatch(ActionCreators.CREATE_GROUP_ERROR(errorToString(e)));

      // so formik can handle it
      throw e;
    }
  };
}

export function updateGroup(
  id: number,
  payload: ForPatch<Model.AreaGroup>
): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.UPDATE_GROUP_START());

    try {
      const response = await patchApi(`/area-groups/${id}/`, payload);

      dispatch(ActionCreators.UPDATE_GROUP_RESPONSE(response));

      return response;
    } catch (e) {
      dispatch(ActionCreators.UPDATE_GROUP_ERROR(errorToString(e)));

      throw e;
    }
  };
}

export function deleteGroup(id: number): StandardThunk {
  return async function (dispatch, getState) {
    await deleteApi(`/area-groups/${id}/`);

    if (getState().user.activeAreaGroupId === id) {
      // If the user deleted their current group, force a refresh, which will
      // switch them to their default remaining grup.
      //
      // (There will be no need to dispatch `DELETE_GROUP_RESPONSE`, because all
      // the cached groups data will have been cleared from Redux.)
      dispatch(switchActiveAreaGroup(null));
    } else {
      // Remove the deleted group from the cached list of groups.
      dispatch(ActionCreators.DELETE_GROUP_RESPONSE(id));
    }
  };
}
