import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';
import { getPaginated, getApi } from 'util/backendapi/fetch';

export const observationPointFormulaConstantDuck = makeReportingDuck(
  'observationPointFormulaConstant',
  (filters) =>
    getPaginated('/reports/observation-point-formula-constants/', filters),
  () => getApi('/reports/observation-point-formula-constants/info/')
);

export type ObservationPointFormulaConstantAction = ReportingDuckActions<
  typeof observationPointFormulaConstantDuck
>;
