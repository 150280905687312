import React from 'react';
import { HeadProvider, Title } from 'react-head';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import makeStore from './store';
import { I18nManager, makeI18n } from './i18n-setup';
import { SessionManager } from '../components/logic/session-manager/SessionManager';
import { routes } from './routes';
import { ErrorBoundary } from 'components/logic/error-boundary/ErrorBoundary';
import { Trans } from '@lingui/macro';

class App extends React.Component {
  i18n = makeI18n();
  store = makeStore(this.i18n);

  componentDidMount() {
    // Clear the document title provided in index.html so it won't conflict
    // with the one dynamically provided by React-Head
    // See https://github.com/tizmagik/react-head/issues/83#issuecomment-486316644
    if (document && document.querySelector) {
      const templateTitle = document.querySelector('head title');
      if (
        templateTitle &&
        templateTitle.parentNode &&
        templateTitle.parentNode.removeChild
      ) {
        templateTitle.parentNode.removeChild(templateTitle);
      }
    }
  }

  render() {
    return (
      <ErrorBoundary>
        <ReduxProvider store={this.store}>
          <I18nManager i18n={this.i18n}>
            <HeadProvider>
              {/* Default document title */}
              <Title>
                <Trans>Dam Monitoring System</Trans>
              </Title>
              <SessionManager>
                <BrowserRouter>{routes}</BrowserRouter>
              </SessionManager>
            </HeadProvider>
          </I18nManager>
        </ReduxProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
