import React, { useMemo } from 'react';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { useSelector } from 'react-redux';
import { FullState } from 'main/reducers';
import { Trans } from '@lingui/macro';
import {
  GoogleMap,
  useLoadScript,
  MarkerClusterer,
} from '@react-google-maps/api';
import useResizeObserver from 'hooks/use-resize-observer';
import Loading from 'components/base/loading/loading';
import { Model } from 'util/backendapi/models/api.interfaces';
import {
  GOOGLE_MAPS_API_KEY,
  GOOGLE_MAP_OPTIONS,
  hasLatLong,
  MAP_CLUSTERER_OPTIONS,
} from '../map-utils';
import { useGetApi } from 'hooks/use-get-api';
import { errorToString } from 'util/backendapi/error';
import { AlertInfo, AlertDanger } from 'components/base/alert/alert';
import { AreaMarker } from './AreaMarker';
import LatLonSpherical from 'geodesy/latlon-spherical';

// Limit the map's initial zoom-in to include an area about this size
const MIN_INITIAL_MAP_ZOOM_KM = 2;

// How many lines to include in the hovertext for a cluster marker
const MAX_CLUSTER_HOVERTEXT_LINES = 20;

interface Props {}

/**
 * Displays all the areas in the user's current group
 *
 * @param _props
 */
export function GroupMapScreen(_props: Props) {
  const currentGroup =
    useSelector((state: FullState) =>
      state.user.areaGroups.find((g) => g.id === state.user.activeAreaGroupId)
    ) ?? null;

  const [{ data: areas, isLoading, isError, error }] =
    useGetApi('/area-coordinates/');

  return (
    <GroupMapView {...{ currentGroup, areas, isLoading, isError, error }} />
  );
}
GroupMapScreen.WrappedComponent = GroupMapView;

interface ViewProps {
  currentGroup: Model.AreaGroupDecorated | null;
  areas: Model.AreaCoordinates[] | null;
  isLoading: boolean;
  isError: boolean;
  error: any;
}

/**
 * The "View" part of the area group screen
 * @param props
 */
function GroupMapView(props: ViewProps) {
  const { currentGroup } = props;

  const googleMapsScript = useLoadScript({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  // We're only interested in the mappable areas...
  const areas = React.useMemo(
    () => (props.areas ? props.areas.filter(hasLatLong) : []),
    [props.areas]
  );

  const [ref, width, height] = useResizeObserver<HTMLDivElement>({
    defaultWidth: 800,
    defaultHeight: 650,
  });

  const bounds = useMemo(() => {
    if (!googleMapsScript.isLoaded) {
      return null;
    }
    const bounds = new google.maps.LatLngBounds();
    areas.forEach((a) => bounds.extend(a.wgs84_coordinates));

    // Set a minimum size for our initial map area, by making sure the bounds
    // includes some locations that are 1km away from the center of the map.
    const centerPoint = bounds.getCenter();
    const centerCoords = new LatLonSpherical(
      centerPoint.lat(),
      centerPoint.lng()
    );
    const paddingMeters = (MIN_INITIAL_MAP_ZOOM_KM * 1000) / 2;
    [0, 90, 180, 270].forEach((bearing) => {
      const { lat, lon } = centerCoords.destinationPoint(
        paddingMeters,
        bearing
      );
      bounds.extend({ lat, lng: lon });
    });

    return bounds;
  }, [areas, googleMapsScript.isLoaded]);

  const hasError = Boolean(props.isError || googleMapsScript.loadError);

  const error = props.error || googleMapsScript.loadError;

  return (
    <PageStandard
      name="asset-map-screen"
      header={<Trans>Group</Trans>}
      subHeader={currentGroup ? currentGroup.name : <Trans>[no group]</Trans>}
    >
      <div className="map-page-wrapper">
        <div ref={ref} className="map-wrapper">
          {hasError ? (
            <AlertDanger>{errorToString(error)}</AlertDanger>
          ) : props.isLoading || !googleMapsScript.isLoaded || !bounds ? (
            <Loading />
          ) : areas.length === 0 ? (
            <AlertInfo>
              <Trans>No areas found to display on map.</Trans>
            </AlertInfo>
          ) : (
            <GoogleMap
              id="group-map"
              mapContainerStyle={{
                height,
                width,
              }}
              options={GOOGLE_MAP_OPTIONS}
              onLoad={(map) => {
                map.fitBounds(bounds);
              }}
            >
              <MarkerClusterer
                options={MAP_CLUSTERER_OPTIONS}
                calculator={(markers) => {
                  let titles = markers
                    .slice(0, MAX_CLUSTER_HOVERTEXT_LINES)
                    .map((m) => m.getTitle())
                    .join('\n');
                  if (markers.length > MAX_CLUSTER_HOVERTEXT_LINES) {
                    titles += '\n...';
                  }

                  return {
                    // Label displayed on the marker
                    text: String(markers.length),
                    // Hover text
                    title: titles,
                    // array index of the marker style to use (we only have one)
                    index: 0,
                  };
                }}
              >
                {(clusterer) =>
                  areas.map((a) => (
                    <AreaMarker key={a.id} area={a} clusterer={clusterer} />
                  ))
                }
              </MarkerClusterer>
            </GoogleMap>
          )}
        </div>
      </div>
    </PageStandard>
  );
}
