import React from 'react';
import Button from 'components/base/button/button';
import { TSPlotMouseoverPoint } from '../timeseriesplot.types';
import { Trans } from '@lingui/macro';
import { formatDateForDisplay, convertDatetimeToDate } from 'util/dates';

interface Props {
  point: TSPlotMouseoverPoint;
  timeZone?: string | null;
  onClose: () => void;
  onZoomIn: (start_datetime: string, end_datetime: string) => void;
}

export const BucketInfo: React.FunctionComponent<Props> = (props) => {
  const { point, onClose, onZoomIn, timeZone: plotTimeZone } = props;

  if (!point.bucket) {
    return null;
  }

  return (
    <div className="plot-popup-timeseries">
      <div className="plot-popup-timeseries-info">
        <p>
          <strong>{point.obsPointCode}</strong>
        </p>
        <Button
          iconOnly
          onClick={onClose}
          shortcut="ESC"
          iconType="icon-cross"
          className="close"
        >
          <Trans>Close</Trans>
        </Button>
        <p>
          <Trans>{point.bucket.full_readings_count} readings</Trans>
          <br />
          {/* TODO: apply timezone here */}
          {formatDateForDisplay(
            convertDatetimeToDate(point.bucket.start_datetime, plotTimeZone)
          )}
          <span> - </span>
          <br />
          {formatDateForDisplay(
            convertDatetimeToDate(point.bucket.end_datetime, plotTimeZone)
          )}
          <br />
          <strong>
            {point.bucket.min_value}
            <span> - </span>
            {point.bucket.max_value}
            <span>{point.unit}</span>
          </strong>
        </p>
      </div>
      <div
        className="plot-popup-timeseries-action"
        style={{ backgroundColor: point.seriesColor }}
      >
        <Button
          iconOnly
          iconType="icon-magnifing-glass-plus"
          className="btn-link-panel"
          onClick={() =>
            onZoomIn(point.bucket!.start_datetime, point.bucket!.end_datetime)
          }
        />
      </div>
    </div>
  );
};
