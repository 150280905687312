import { t } from '@lingui/macro';
import { getApi, patchApi } from 'util/backendapi/fetch';
import { Model } from 'util/backendapi/models/api.interfaces';
import { postApi } from 'util/backendapi/fetch';
import { StandardThunk, DuckActions } from 'main/store';
import {
  ActionTypes as AliasActionTypes,
  ObsPointDetailAliasAction,
} from './alias';
import {
  ActionTypes as FormulaActionTypes,
  ObsPointDetailFormulaAction,
} from './formula';
import { errorToString } from 'util/backendapi/error';

export type ObsPointFormSection =
  | 'general'
  | 'performanceIndicators'
  | 'alias'
  | 'dataLogger'
  | 'formula'
  | 'installation'
  | 'readings';

export const ActionTypes = {
  OPEN_EDIT_SECTION: 'dms/obsPoint/detail/main/OPEN_EDIT_SECTION',
  CLOSE_EDIT_SECTION: 'dms/obsPoint/detail/main/CLOSE_EDIT_SECTION',
  FETCH_OBSERVATION_POINT_START:
    'dms/obsPoint/detail/main/FETCH_OBSERVATION_POINT_START',
  FETCH_OBSERVATION_POINT_RESPONSE:
    'dms/obsPoint/detail/main/FETCH_OBSERVATION_POINT_RESPONSE',
  FETCH_OBSERVATION_POINT_ERROR:
    'dms/obsPoint/detail/main/FETCH_OBSERVATION_POINT_ERROR',
  UPDATE_OBSERVATION_POINT: 'dms/obsPoint/detail/main/UPDATE_OBSERVATION_POINT',
  UPDATE_OBSERVATION_POINT_RESPONSE:
    'dms/obsPoint/detail/main/UPDATE_OBSERVATION_POINT_RESPONSE',
  UPDATE_OBSERVATION_POINT_ERROR:
    'dms/obsPoint/detail/main/UPDATE_OBSERVATION_POINT_ERROR',
  UNMOUNT_MAINT_OBS_POINT_SCREEN:
    'dms/obsPoint/detail/main/UNMOUNT_VIEW_OBSERVATION_POINT',
  CREATE_OBSERVATION_POINT_START:
    'dms/obsPoint/detail/main/CREATE_OBSERVATION_POINT_START',
  CREATE_OBSERVATION_POINT_ERROR:
    'dms/obsPoint/detail/main/CREATE_OBSERVATION_POINT_ERROR',
  CREATE_OBSERVATION_POINT_RESPONSE:
    'dms/obsPoint/detail/main/CREATE_OBSERVATION_POINT_RESPONSE',
} as const;

export const ActionCreators = {
  OPEN_EDIT_SECTION: (section: ObsPointFormSection) => ({
    type: ActionTypes.OPEN_EDIT_SECTION,
    payload: section,
  }),
  CLOSE_EDIT_SECTION: (section: ObsPointFormSection) => ({
    type: ActionTypes.CLOSE_EDIT_SECTION,
    payload: section,
  }),
  UNMOUNT_MAINT_OBS_POINT_SCREEN: () => ({
    type: ActionTypes.UNMOUNT_MAINT_OBS_POINT_SCREEN,
  }),
  FETCH_OBSERVATION_POINT_START: () => ({
    type: ActionTypes.FETCH_OBSERVATION_POINT_START,
  }),
  FETCH_OBSERVATION_POINT_RESPONSE: (
    observationPoint: Model.ObservationPointView
  ) => ({
    type: ActionTypes.FETCH_OBSERVATION_POINT_RESPONSE,
    payload: observationPoint,
  }),
  FETCH_OBSERVATION_POINT_ERROR: (errorMessage: string) => ({
    type: ActionTypes.FETCH_OBSERVATION_POINT_ERROR,
    error: true,
    payload: errorMessage,
  }),
  UPDATE_OBSERVATION_POINT: (section: ObsPointFormSection) => ({
    type: ActionTypes.UPDATE_OBSERVATION_POINT,
    meta: { section },
  }),
  UPDATE_OBSERVATION_POINT_RESPONSE: (
    section: ObsPointFormSection,
    response: Model.ObservationPointView
  ) => ({
    type: ActionTypes.UPDATE_OBSERVATION_POINT_RESPONSE,
    meta: { section },
    payload: response,
  }),
  UPDATE_OBSERVATION_POINT_ERROR: (
    section: ObsPointFormSection,
    errorMessage: string
  ) => ({
    type: ActionTypes.UPDATE_OBSERVATION_POINT_ERROR,
    error: true,
    payload: errorMessage,
    meta: { section },
  }),
  CREATE_OBSERVATION_POINT_START: () => ({
    type: ActionTypes.CREATE_OBSERVATION_POINT_START,
  }),
  CREATE_OBSERVATION_POINT_ERROR: (errorMessage: string) => ({
    type: ActionTypes.CREATE_OBSERVATION_POINT_ERROR,
    error: true,
    payload: errorMessage,
  }),
  CREATE_OBSERVATION_POINT_RESPONSE: (
    observationPoint: Model.ObservationPointView
  ) => ({
    type: ActionTypes.CREATE_OBSERVATION_POINT_RESPONSE,
    payload: observationPoint,
  }),
};

export type ObsPointDetailAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;
export const openSectionForEditing = ActionCreators.OPEN_EDIT_SECTION;
export const closeSectionForEditing = ActionCreators.CLOSE_EDIT_SECTION;
export const unmountMaintainObservationPointScreen =
  ActionCreators.UNMOUNT_MAINT_OBS_POINT_SCREEN;

export interface ObsPointDetailState {
  editingSection: ObsPointFormSection | null;
  editingSectionIsSubmitting: boolean;
  errorMessage: string | null;
  isLoading: boolean;
  observationPointCode: string;
  observationPoint: Model.ObservationPointView | null;
}

export function obsPointDetailInitialState(): ObsPointDetailState {
  return {
    editingSection: null,
    editingSectionIsSubmitting: false,
    errorMessage: null,
    isLoading: true,
    observationPointCode: '',
    observationPoint: null,
  };
}

export default function obsPointDetailReducer(
  state = obsPointDetailInitialState(),
  action:
    | ObsPointDetailAction
    | ObsPointDetailAliasAction
    | ObsPointDetailFormulaAction
): ObsPointDetailState {
  switch (action.type) {
    case ActionTypes.OPEN_EDIT_SECTION:
      return {
        ...state,
        editingSection: action.payload,
        editingSectionIsSubmitting: false,
      };
    case ActionTypes.CLOSE_EDIT_SECTION:
      if (state.editingSection === action.payload) {
        return {
          ...state,
          editingSection: null,
          editingSectionIsSubmitting: false,
        };
      } else {
        return state;
      }

    case ActionTypes.FETCH_OBSERVATION_POINT_START:
      return { ...state, isLoading: true };
    case ActionTypes.FETCH_OBSERVATION_POINT_RESPONSE:
      return {
        ...state,
        isLoading: false,
        observationPoint: action.payload,
      };
    case ActionTypes.FETCH_OBSERVATION_POINT_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case ActionTypes.CREATE_OBSERVATION_POINT_START:
      return { ...state, isLoading: true };
    case ActionTypes.CREATE_OBSERVATION_POINT_RESPONSE:
      return {
        ...state,
        isLoading: false,
      };
    case ActionTypes.CREATE_OBSERVATION_POINT_ERROR:
      return { ...state, isLoading: false };

    case ActionTypes.UPDATE_OBSERVATION_POINT:
    case AliasActionTypes.UPDATE_ALIASES_START:
    case FormulaActionTypes.CHANGE_OPF_FORMULA:
    case FormulaActionTypes.MODIFY_OPF_DETAILS:
      if (action.meta && action.meta.section === state.editingSection) {
        return {
          ...state,
          editingSectionIsSubmitting: true,
        };
      } else {
        return state;
      }
    case ActionTypes.UPDATE_OBSERVATION_POINT_ERROR:
    case AliasActionTypes.UPDATE_ALIASES_ERROR:
    case FormulaActionTypes.CHANGE_OPF_FORMULA_ERROR:
    case FormulaActionTypes.MODIFY_OPF_DETAILS_ERROR:
      if (action.meta && action.meta.section === state.editingSection) {
        return {
          ...state,
          editingSectionIsSubmitting: false,
        };
      } else {
        return state;
      }
    case AliasActionTypes.UPDATE_ALIASES_RESPONSE:
      if (state.editingSection === 'alias' && action.noErrors) {
        return {
          ...state,
          editingSectionIsSubmitting: false,
          editingSection: null,
        };
      } else {
        return state;
      }
    case ActionTypes.UPDATE_OBSERVATION_POINT_RESPONSE:
      if (action.meta && action.meta.section === state.editingSection) {
        return {
          ...state,
          editingSectionIsSubmitting: false,
          editingSection: null,
          // When updating the observation point itself, we can just use the
          // updated observation point we get from the backend response.
          observationPoint: action.payload,
        };
      } else {
        return state;
      }
    case FormulaActionTypes.CHANGE_OPF_FORMULA_RESPONSE:
    case FormulaActionTypes.MODIFY_OPF_DETAILS_RESPONSE:
      if (state.editingSection === 'formula') {
        return {
          ...state,
          editingSectionIsSubmitting: false,
          editingSection: null,
        };
      } else {
        return state;
      }
    case ActionTypes.UNMOUNT_MAINT_OBS_POINT_SCREEN:
      return obsPointDetailInitialState();
    default:
      return state;
  }
}

/**
 * A redux-thunk action that fetches the data about an observation point
 * that is needed to view the observation point's maintenance screen.
 *
 * Note that some sections of the observation point maintenance screen
 * (alias, formula, data logger channel) have their own separate ducks which
 * fetch their own details.
 *
 * @export
 * @param {string} observationPointCode
 * @returns {function} A redux thunk function
 */
export function fetchObservationPoint(
  observationPointCode: string
): StandardThunk {
  return async function (dispatch, _getState, { i18n }) {
    dispatch(ActionCreators.FETCH_OBSERVATION_POINT_START());
    try {
      // We need data about the observation point
      const observationPoint = (
        await getApi('/observation-points/', {
          code: observationPointCode,
        })
      )[0];

      if (!observationPoint) {
        return dispatch(
          ActionCreators.FETCH_OBSERVATION_POINT_ERROR(
            i18n._(t`Observation point "${observationPointCode}" not found.`)
          )
        );
      }

      return dispatch(
        ActionCreators.FETCH_OBSERVATION_POINT_RESPONSE(observationPoint)
      );
    } catch (e) {
      return dispatch(ActionCreators.FETCH_OBSERVATION_POINT_ERROR(e.message));
    }
  };
}

export function createObservationPoint(
  record: Model.ObservationPoint_POST
): StandardThunk {
  return async (dispatch) => {
    dispatch(ActionCreators.CREATE_OBSERVATION_POINT_START());

    try {
      return dispatch(
        ActionCreators.CREATE_OBSERVATION_POINT_RESPONSE(
          await postApi('/observation-points/', record)
        )
      );
    } catch (e) {
      dispatch(ActionCreators.CREATE_OBSERVATION_POINT_ERROR(errorToString(e)));

      // so formik can handle it
      throw e;
    }
  };
}

/**
 * Redux thunk action creator, to update an observation point in the backend.
 *
 * @export
 * @param {*} observationPoint The new values in the observation point. (Can
 * be a subset of the fields of the observation point)
 * @param {*} prevObservationPoint The previous values in the observation point.
 * We'll compare the new values against these, and only send the changed values
 * to the backend.
 * @returns
 */
export function updateObservationPoint(
  section: ObsPointFormSection,
  observationPointId: number,
  observationPoint: Model.ObservationPoint_PATCH
): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.UPDATE_OBSERVATION_POINT(section));

    try {
      const response = await patchApi(
        `/observation-points/${observationPointId}/`,
        observationPoint
      );
      return dispatch(
        ActionCreators.UPDATE_OBSERVATION_POINT_RESPONSE(section, response)
      );
    } catch (e) {
      dispatch(
        ActionCreators.UPDATE_OBSERVATION_POINT_ERROR(section, errorToString(e))
      );
      throw e;
    }
  };
}
