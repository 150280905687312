import { quadtree, QuadtreeInternalNode, QuadtreeLeaf } from 'd3-quadtree';
import { seedPseudoRandNormalizedFloat } from '../../../util/misc';

/*
Copyright 2017, Jukka Purma
Copyright 2022, Catalyst LTD
All rights reserved.

Redistribution and use in source and binary forms, with or without modification,
are permitted provided that the following conditions are met:

* Redistributions of source code must retain the above copyright notice, this
  list of conditions and the following disclaimer.

* Redistributions in binary form must reproduce the above copyright notice,
  this list of conditions and the following disclaimer in the documentation
  and/or other materials provided with the distribution.

* Neither the name of the author nor the names of contributors may be used to
  endorse or promote products derived from this software without specific prior
  written permission.

THIS SOFTWARE IS PROVIDED BY THE COPYRIGHT HOLDERS AND CONTRIBUTORS "AS IS" AND
ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE ARE
DISCLAIMED. IN NO EVENT SHALL THE COPYRIGHT OWNER OR CONTRIBUTORS BE LIABLE FOR
ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES
(INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES;
LOSS OF USE, DATA, OR PROFITS; OR BUSINESS INTERRUPTION) HOWEVER CAUSED AND ON
ANY THEORY OF LIABILITY, WHETHER IN CONTRACT, STRICT LIABILITY, OR TORT
(INCLUDING NEGLIGENCE OR OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THIS
SOFTWARE, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
 */

/**
 * Used internally in the Ellipse collision force for force layout
 */
interface CollideEllipseItem {
  index: number;
  x: number;
  vx: number;
  y: number;
  vy: number;
  height: number;
  width: number;
  isIcon: boolean;
  label: string;
  padding: number;
}

type CollideEllipseNode =
  | QuadtreeInternalNode<CollideEllipseItem>
  | QuadtreeLeaf<CollideEllipseItem>;
type InternalCollisionNode =
  | (QuadtreeInternalNode<CollideEllipseItem> & {
      hw?: number;
      hh?: number;
      data: undefined;
    })
  | (QuadtreeLeaf<CollideEllipseItem> & { hw?: number; hh?: number });

function _getX(d: CollideEllipseItem): number {
  return d.x + d.vx;
}

function _getY(d: CollideEllipseItem): number {
  return d.y + d.vy;
}

/**
 * A "force" for D3 force-directed layout. It acts like the core "forceCollide"
 * force, except that it calculates items as ellipses rather than circles.
 *
 * @see https://github.com/jpurma/d3-ellipse-force
 * @see https://bl.ocks.org/jpurma/6dd2081cf25a5d2dfcdcab1a4868f237
 * @see ... and the quadtree stuff is based on how `forceCollide` in d3-force does it.
 */
export function forceEllipseCollide() {
  let nodes: CollideEllipseItem[];
  let iterations = 2;
  let strength = 1;
  let padding = 0;
  const prng = seedPseudoRandNormalizedFloat(1);

  function jiggle() {
    return (prng() - 0.5) * 1e-6;
  }

  function force() {
    const n = nodes.length;
    let node: CollideEllipseItem;
    let xMax: number;
    let xMin: number;
    let yMax: number;
    let yMin: number;
    let myW: number;
    let myH: number;
    let myWH: number;
    let myW2: number;
    let myH2: number;
    let myX: number;
    let myY: number;

    for (let k = 0; k < iterations; ++k) {
      const tree = quadtree<CollideEllipseItem>(nodes, _getX, _getY);
      for (let i = 0; i < n; ++i) {
        node = nodes[i];
        myW = node.width / 2 + node.padding;
        myH = node.height / 2 + node.padding;
        myW2 = myW * myW;
        myH2 = myH * myH;
        myWH = myW * myH;
        myX = node.x + node.vx;
        myY = node.y + node.vy;
        xMax = myX + myW;
        xMin = myX - myW;
        yMax = myY + myH;
        yMin = myY - myH;
        tree.visit(apply);
      }
    }

    function apply(
      pQuad: CollideEllipseNode,
      x0: number,
      y0: number,
      x1: number,
      y1: number
    ) {
      const quad = pQuad as InternalCollisionNode;
      if (!quad.data) {
        const noVisitChildren =
          x0 > xMax || x1 < xMin || y0 > yMax || y1 < yMin;
        return noVisitChildren;
      }
      const data = quad.data;
      // Don't reposition icons. Just labels.
      if (!(data.isIcon && node.isIcon) && data.index > node.index) {
        const otherW = data.width / 2 + data.padding;
        const otherH = data.height / 2 + data.padding;
        const otherX = data.x + data.vx;
        const otherY = data.y + data.vy;

        let distX = myX - otherX;
        let distY = myY - otherY;

        // Before we go on to more complicated maths, a quick check to see whether
        // their furthest points are too far apart.
        if (Math.abs(distX) > myW + otherW && Math.abs(distY) > myH + otherH) {
          return;
        }

        if (distX === 0) {
          distX = jiggle();
        }
        if (distY === 0) {
          distY = jiggle();
        }

        // ellipse is defined as  x^2   y^2
        //                        --- + --- = 1
        //                        w^2   h^2
        // here x,y are points on ellipse's arc.
        // we have a line going between center points of two ellipses and we want to know
        // the point where it crosses the ellipse's arc. Because we know the line, we
        // know that y = g * x, where
        let g = distY / distX;
        // now the only unknown in ellipse above is x, and thus we can find it by
        // moving pieces around (pen and paper work). equation becomes:
        //             w * h
        // x = ---------------------
        //     sqrt(h^2 + g^2 * w^2)

        let g2 = g * g;
        let mySizeX = myWH / Math.sqrt(myH2 + g2 * myW2);
        let mySizeY = g * mySizeX;

        // the length of the little bit from the center of ellipse to its margin.
        // For circle it would be 'r', but for ellipse it varies.
        let myRadius = Math.sqrt(mySizeX * mySizeX + mySizeY * mySizeY);

        // And same for the other ellipse:
        let otherSizeX =
          (otherW * otherH) / Math.sqrt(otherH * otherH + g2 * otherW * otherW);
        let otherSizeY = g * otherSizeX;
        let otherRadius = Math.sqrt(
          otherSizeX * otherSizeX + otherSizeY * otherSizeY
        );

        // now we can calculate the gap or overlap between two ellipses, and force ratio on
        // how strongly they should push as average of their force_ratios
        let dist = Math.sqrt(distX * distX + distY * distY);
        let gap = dist - otherRadius - myRadius;

        // If the gap between them is less than 0, they're overlapping.
        if (gap < 0) {
          let xComponent = distX / dist;
          let yComponent = distY / dist;

          let impulse: number;
          if (node.isIcon) {
            impulse = 0;
          } else if (data.isIcon) {
            impulse = 1;
          } else {
            impulse = 0.5;
          }

          node.vx += xComponent * strength * impulse;
          node.vy += yComponent * strength * impulse;

          impulse = 1 - impulse;
          data.vx -= xComponent * strength * impulse;
          data.vy -= yComponent * strength * impulse;
          if (!node.isIcon && !node.padding) {
            node.padding = padding;
          }
          if (!data.isIcon && !data.padding) {
            data.padding = padding;
          }
        }
      }
    }
  }

  force.initialize = function (_nodes: any[]) {
    nodes = _nodes;
  };

  force.iterations = function (i: any) {
    if (i !== undefined) {
      iterations = +i;
      return force;
    } else {
      return iterations;
    }
  };

  force.padding = function (i: number) {
    padding = i;
    return force;
  };

  return force;
}
