import React, { useEffect, useCallback } from 'react';
import { usePlotState } from 'hooks/use-plot-state';
import { ScatterPlotView } from './ScatterPlotView';
import { useDispatch } from 'react-redux';
import {
  parseQueryParamFromRouterProps,
  parseBooleanQueryParamFromRouterProps,
  parseNumberQueryParamFromRouterProps,
  setQueryParams,
} from 'util/routing';
import { createStructuredSelector, createSelector } from 'reselect';
import {
  ScatterPlotSettingsFromUrl,
  resolveScatterPlotSettingsFromUrl,
  unmountPlotPage,
} from 'ducks/plot/scatter-time-series';
import { RouteComponentProps } from 'react-router-dom';
import { PlotType } from 'util/backendapi/types/Enum';
import {
  parseScalesConfigForPlot,
  encodeScalesConfigForUrl,
} from 'screens/quickplot/quickplotselectors';
import {
  PlotSettingsAxis,
  ScatterPlotAxisSide,
} from 'components/plots/timeseriesplot.types';
import { HighlightArea } from 'react-vis';
import { convertHighlightAreaToAxes } from 'components/plots/scatterplotselectors';

interface UrlMatchProps {
  op_x?: string;
  op_y?: string;
  item_x?: string;
  item_y?: string;
}
type OwnProps = RouteComponentProps<UrlMatchProps>;

export const getScatterPlotSettingsFromUrl = createStructuredSelector<
  OwnProps,
  ScatterPlotSettingsFromUrl
>({
  xObservationPointCode: (props) => props.match.params.op_x || '',
  yObservationPointCode: (props) => props.match.params.op_y || '',
  xItemNumber: (props) => +(props.match.params.item_x || 0),
  yItemNumber: (props) => +(props.match.params.item_y || 0),
  startDatetime: (props) =>
    parseQueryParamFromRouterProps(props, 'startDatetime', null),
  endDatetime: (props) =>
    parseQueryParamFromRouterProps(props, 'endDatetime', null),
  numberOfMonths: (props) =>
    parseNumberQueryParamFromRouterProps(props, 'numberOfMonths', null),
  axes: createSelector(
    (props: RouteComponentProps<UrlMatchProps>) =>
      parseQueryParamFromRouterProps(props, 'axes', ''),
    function (axes) {
      return parseScalesConfigForPlot(
        axes
      ) as PlotSettingsAxis<ScatterPlotAxisSide>[];
    }
  ),
  zoomedFrom: (props) =>
    parseQueryParamFromRouterProps(props, 'zoomedFrom', null),
  interpolate: (props) =>
    parseBooleanQueryParamFromRouterProps(props, 'interpolate', true),
  showPlotMarks: (props) =>
    parseBooleanQueryParamFromRouterProps(props, 'plotmarks', true),
  showMarkConnections: (props) =>
    parseBooleanQueryParamFromRouterProps(props, 'markconnections', true),
});

export const ScatterPlotScreen = (props: OwnProps) => {
  const dispatch = useDispatch();

  const settingsFromUrl = getScatterPlotSettingsFromUrl(props);
  const isZoomed = Boolean(settingsFromUrl.zoomedFrom);

  // Update the "resolved settings" in the Redux store, if the settings in
  // the URL have changed.
  useEffect(() => {
    dispatch(resolveScatterPlotSettingsFromUrl(settingsFromUrl));
  }, [dispatch, settingsFromUrl]);

  const plotState = usePlotState('quickScatterPlot', PlotType.SCATTER);

  const handleZoom = useCallback(
    (area: HighlightArea | null) => {
      const axes = convertHighlightAreaToAxes(area);
      if (!axes) {
        return;
      }
      setQueryParams(
        { history: props.history, location: props.location },
        {
          axes: encodeScalesConfigForUrl(axes),
          zoomedFrom:
            settingsFromUrl.zoomedFrom ??
            encodeScalesConfigForUrl(settingsFromUrl.axes) ??
            '_',
        }
      );
    },
    [
      props.history,
      props.location,
      settingsFromUrl.axes,
      settingsFromUrl.zoomedFrom,
    ]
  );

  const handleResetZoom = useCallback(() => {
    const zoomedFrom = settingsFromUrl.zoomedFrom;
    if (zoomedFrom === null) {
      return;
    }
    setQueryParams(
      { history: props.history, location: props.location },
      {
        axes: zoomedFrom,
        zoomedFrom: null,
      }
    );
  }, [props.history, props.location, settingsFromUrl.zoomedFrom]);

  // Clean up plot state when unmounting
  useEffect(
    () => () => {
      dispatch(unmountPlotPage());
    },
    [dispatch]
  );

  return (
    <ScatterPlotView
      loading={plotState.isLoading}
      error={plotState.errorMessage}
      area={null}
      startDateTime={plotState.minDatetime || ''}
      endDateTime={plotState.maxDatetime || ''}
      timeZone={
        plotState && plotState.resolvedSettings
          ? plotState.resolvedSettings.observationPoints[1].time_zone.name
          : ''
      }
      x={
        plotState.readingsSeries[1]?.readings
          ? {
              observationPoint: plotState.readingsSeries[1].observationPoint,
              readings: plotState.readingsSeries[1].readings,
              settings: plotState.readingsSeries[1].settings,
            }
          : null
      }
      y={
        plotState.readingsSeries[0]?.readings
          ? {
              observationPoint: plotState.readingsSeries[0].observationPoint,
              readings: plotState.readingsSeries[0].readings,
              settings: plotState.readingsSeries[0].settings,
            }
          : null
      }
      userDisplayName={plotState.userString}
      xObservationPointCode={settingsFromUrl.xObservationPointCode}
      yObservationPointCode={settingsFromUrl.yObservationPointCode}
      interpolate={Boolean(
        plotState.resolvedSettings && plotState.resolvedSettings.interpolate
      )}
      plotmarks={Boolean(
        plotState.resolvedSettings &&
          plotState.resolvedSettings.show_plot_markers
      )}
      markconnections={Boolean(
        plotState.resolvedSettings &&
          plotState.resolvedSettings.show_mark_connections
      )}
      axes={plotState.resolvedSettings ? plotState.resolvedSettings.axes : []}
      resolvedSettings={plotState.resolvedSettings}
      highlightPeriods={[]}
      onZoom={handleZoom}
      onResetZoom={isZoomed ? handleResetZoom : undefined}
    />
  );
};
