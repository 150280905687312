import React from 'react';
import Button from 'components/base/button/button';
import { t } from '@lingui/macro';
import { getApi } from 'util/backendapi/fetch';
import { downloadFile } from 'util/backendapi/download';

interface Props {
  fileId: number | null;
  batchId: number;
}

export function DownloadBatchButton(props: Props) {
  const { fileId, batchId } = props;
  return (
    <Button
      iconOnly={true}
      title={t`Download`}
      iconType="icon-download"
      className="btn-link-panel"
      onClick={async () => {
        if (fileId) {
          const file = await getApi(`/readings-files/${fileId}/`);
          saveAs(file.content, file.file_name);
        } else {
          downloadFile(`/readings-batches/${batchId}/export-mrf/`);
        }
      }}
    />
  );
}
