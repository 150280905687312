import React, { memo } from 'react';
import { SpatialPlotObservationPoint } from 'util/backendapi/types/Model';
import { useGetApi } from 'hooks/use-get-api';
import { isTruthy } from 'util/validation';
import { Icon } from 'components/base/icon/icon';
import Button from 'components/base/button/button';
import { Trans, t } from '@lingui/macro';
import { formatDatetimeForDisplay } from 'util/dates';
import { getObservationPointItemUrlCode } from 'components/plots/timeseriesplotselectors';
import Loading from 'components/base/loading/loading';
import { AlertDanger } from 'components/base/alert/alert';
import { errorToString } from 'util/backendapi/error';
import { DMSLink } from 'components/base/link/DMSLink';

interface Props {
  observationPoints: Omit<
    SpatialPlotObservationPoint,
    'vertical_offset' | 'horizontal_offset'
  >[];
  onClose: () => void;
}

/**
 * A popup window to display information about an observation point when it
 * is clicked on in the spatial plot.
 */
export const SpatialPlotObsPointPopup = memo((props: Props) => {
  const { observationPoints, onClose } = props;
  const [{ isError, isLoading, error, data }] = useGetApi(
    '/observation-points/',
    {
      id__in: observationPoints.map((op) => op.id),
    }
  );

  return (
    <div className="plot-popup-spatial">
      {observationPoints.map((observationPoint, idx) => {
        const observationPointDetails = data?.find(
          (op) => op.id === observationPoint.id
        );
        const title = [observationPoint.code, observationPointDetails?.name]
          .filter(isTruthy)
          .join(' - ');
        return (
          <>
            {idx > 0 && <hr />}
            <div className="popup-header" key={observationPoint.id}>
              <h3>{title}</h3>
              <DMSLink to={`/observation-point/${observationPoint.code}`}>
                <Icon type="icon-view" title={t`View`} />
              </DMSLink>
              <Button
                iconOnly
                onClick={onClose}
                shortcut="ESC"
                iconType="icon-cross"
                className="close"
              >
                <Trans>Close</Trans>
              </Button>
            </div>
            <div className="popup-content">
              <p>
                <strong>
                  <Trans>Latitude</Trans>
                </strong>{' '}
                {observationPointDetails?.wgs84_coordinates?.lat}
              </p>
              <p>
                <strong>
                  <Trans>Longitude</Trans>
                </strong>{' '}
                {observationPointDetails?.wgs84_coordinates?.lng}
              </p>
              <p>
                <strong>
                  <Trans>Port RL</Trans>
                </strong>{' '}
                {observationPoint.port_rl}
              </p>
              {observationPointDetails &&
                observationPointDetails.instrument_type.items.map(
                  (item, itemIdx) => {
                    const readingDatetime =
                      observationPoint.reading?.reading_datetime;
                    const readingValue =
                      observationPoint.reading?.adjusted_reading_entries[
                        itemIdx
                      ]?.value;

                    return (
                      <div key={item.item_number}>
                        <h4>{item.description}</h4>
                        <p>
                          <strong>{readingValue ?? '-'}</strong>{' '}
                          <span>
                            {readingDatetime
                              ? formatDatetimeForDisplay(
                                  readingDatetime,
                                  observationPointDetails.time_zone.name
                                )
                              : '-'}
                          </span>
                        </p>
                        <div className="action-icons">
                          <DMSLink
                            to={`/quickplot/${getObservationPointItemUrlCode(
                              observationPointDetails.code,
                              item.item_number
                            )}`}
                          >
                            <Icon type="icon-plot" title={t`Quick plot`} />
                          </DMSLink>{' '}
                          <DMSLink
                            to={`/quicklist/${observationPointDetails.code}`}
                          >
                            <Icon type="icon-list" title={t`Quick list`} />
                          </DMSLink>
                        </div>
                      </div>
                    );
                  }
                )}
              {isLoading && <Loading />}
              {isError && <AlertDanger>{errorToString(error)}</AlertDanger>}
            </div>
          </>
        );
      })}
    </div>
  );
});
