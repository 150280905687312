import orderBy from 'lodash/orderBy';
import deepEqual from 'lodash/isEqual';
import { Model } from 'util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions } from 'main/store';
import { getApi, patchApi, postApi, deleteApi } from 'util/backendapi/fetch';
import {
  errorToString,
  BackendApiError,
  isDRFError,
} from 'util/backendapi/error';
import { isTruthy } from 'util/validation';

export const ActionTypes = {
  FETCH_ALIASES_START: 'dms/obsPoint/detail/alias/FETCH_ALIASES_START',
  FETCH_ALIASES_RESPONSE: 'dms/obsPoint/detail/alias/FETCH_ALIASES_RESPONSE',
  FETCH_ALIASES_ERROR: 'dms/obsPoint/detail/alias/FETCH_ALIASES_ERROR',
  UPDATE_ALIASES_START: 'dms/obsPoint/detail/alias/UPDATE_ALIASES_START',
  UPDATE_ALIASES_RESPONSE: 'dms/obsPoint/detail/alias/UPDATE_ALIASES_RESPONSE',
  UPDATE_ALIASES_ERROR: 'dms/obsPoint/detail/alias/UPDATE_ALIASES_ERROR',
  DELETE_ALIAS_START: 'dms/obsPoint/detail/alias/DELETE_ALIAS_START',
  DELETE_ALIAS_RESPONSE: 'dms/obsPoint/detail/alias/DELETE_ALIAS_RESPONSE',
  DELETE_ALIAS_ERROR: 'dms/obsPoint/detail/alias/DELETE_ALIAS_ERROR',
} as const;

export const ActionCreators = {
  FETCH_ALIASES_START: (obsPoint: number) => ({
    type: ActionTypes.FETCH_ALIASES_START,
    obsPoint,
  }),
  FETCH_ALIASES_RESPONSE: (
    obsPoint: number,
    aliases: Model.ObservationPointAlias[]
  ) => ({
    type: ActionTypes.FETCH_ALIASES_RESPONSE,
    obsPoint,
    payload: aliases,
  }),
  FETCH_ALIASES_ERROR: (obsPoint: number, error: string) => ({
    type: ActionTypes.FETCH_ALIASES_ERROR,
    obsPoint,
    payload: error,
  }),
  UPDATE_ALIASES_START: (obsPoint: number) => ({
    type: ActionTypes.UPDATE_ALIASES_START,
    obsPoint,
    meta: {
      section: 'alias' as 'alias',
    },
  }),
  UPDATE_ALIASES_RESPONSE: (
    obsPoint: number,
    aliases: Model.ObservationPointAlias[],
    noErrors: boolean
  ) => ({
    type: ActionTypes.UPDATE_ALIASES_RESPONSE,
    obsPoint,
    payload: aliases,
    noErrors,
    meta: {
      section: 'alias' as 'alias',
    },
  }),
  UPDATE_ALIASES_ERROR: (
    obsPoint: number,
    error: string | AliasUpdateResponse[]
  ) => ({
    type: ActionTypes.UPDATE_ALIASES_ERROR,
    obsPoint,
    payload: error,
    meta: {
      section: 'alias' as 'alias',
    },
  }),
  DELETE_ALIAS_START: (obsPoint: number) => ({
    type: ActionTypes.DELETE_ALIAS_START,
    obsPoint,
  }),
  DELETE_ALIAS_RESPONSE: (obsPoint: number, alias: number) => ({
    type: ActionTypes.DELETE_ALIAS_RESPONSE,
    obsPoint,
    alias,
  }),
  DELETE_ALIAS_ERROR: (obsPoint: number, error: string) => ({
    type: ActionTypes.DELETE_ALIAS_ERROR,
    obsPoint,
    payload: error,
  }),
} as const;

export type ObsPointDetailAliasAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export interface ObsPointDetailAliasState {
  aliases: Model.ObservationPointAlias[];
  isLoading: boolean;
  loadedObsPoint: number | null;
  error: string | null;
}

const initialState: Readonly<ObsPointDetailAliasState> = {
  aliases: [],
  isLoading: false,
  loadedObsPoint: null,
  error: null,
};

export function obsPointDetailAliasReducer(
  state = initialState,
  action: ObsPointDetailAliasAction
): ObsPointDetailAliasState {
  switch (action.type) {
    case ActionTypes.FETCH_ALIASES_START:
      return {
        ...initialState,
        loadedObsPoint: action.obsPoint,
        isLoading: true,
      };
    case ActionTypes.FETCH_ALIASES_RESPONSE:
      if (state.loadedObsPoint !== action.obsPoint) {
        return state;
      } else {
        return {
          ...state,
          isLoading: false,
          aliases: sortAliases(action.payload),
          error: null,
        };
      }
    case ActionTypes.FETCH_ALIASES_ERROR:
      if (state.loadedObsPoint !== action.obsPoint) {
        return state;
      } else {
        return {
          ...state,
          isLoading: false,
          aliases: [],
          error: action.payload,
        };
      }
    case ActionTypes.DELETE_ALIAS_RESPONSE:
      if (state.loadedObsPoint !== action.obsPoint) {
        return state;
      } else {
        // Remove the deleted alias from our existing data.
        return {
          ...state,
          aliases: state.aliases.filter((a) => a.id !== action.alias),
        };
      }
    case ActionTypes.UPDATE_ALIASES_RESPONSE:
      if (state.loadedObsPoint !== action.obsPoint) {
        return state;
      } else if (action.payload.length === 0) {
        return state;
      } else {
        // Merge the update aliases into our existing data.
        let newAliases = [...state.aliases];
        action.payload.forEach((newAlias) => {
          const oldIdx = newAliases.findIndex((a) => a.id === newAlias.id);
          if (oldIdx === -1) {
            // New alias; add it to the list
            newAliases.push(newAlias);
          } else {
            // Update alias; overwrite its entry in the list
            newAliases[oldIdx] = newAlias;
          }
        });
        return {
          ...state,
          aliases: sortAliases(newAliases),
        };
      }
    default:
      return state;
  }
}

function sortAliases(aliases: Model.ObservationPointAlias[]) {
  return orderBy(aliases, (a) => a.reading_position);
}

export function fetchObsPointDetailAliases(obsPoint: number): StandardThunk {
  return async function (dispatch) {
    try {
      dispatch(ActionCreators.FETCH_ALIASES_START(obsPoint));
      const aliases = await getApi('/observation-point-aliases/', {
        observation_point: obsPoint,
      });
      return dispatch(
        ActionCreators.FETCH_ALIASES_RESPONSE(obsPoint, sortAliases(aliases))
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_ALIASES_ERROR(obsPoint, errorToString(e))
      );
    }
  };
}

export function deleteObsPointAlias(
  obsPoint: number,
  alias: number
): StandardThunk {
  return async function (dispatch) {
    try {
      dispatch(ActionCreators.DELETE_ALIAS_START(obsPoint));
      await deleteApi(`/observation-point-aliases/${alias}/`);
      return dispatch(ActionCreators.DELETE_ALIAS_RESPONSE(obsPoint, alias));
    } catch (e) {
      dispatch(ActionCreators.DELETE_ALIAS_ERROR(obsPoint, errorToString(e)));
      throw e;
    }
  };
}

interface AliasUpdateResponse {
  isError: boolean;
  data:
    | null
    | Model.ObservationPointAlias
    | BackendApiError<Model.ObservationPointAlias>;
}

export function updateObsPointAliases(
  obsPoint: number,
  aliases: Array<{
    id?: number;
    observation_point: number;
    alias: string;
    reading_position: number;
  }>
): StandardThunk {
  return async function (dispatch, getState) {
    try {
      dispatch(ActionCreators.UPDATE_ALIASES_START(obsPoint));
      const results = await Promise.all(
        aliases.map(async (alias): Promise<AliasUpdateResponse> => {
          try {
            // Check to see whether this is a new alias or an existing one
            if (alias.id) {
              // Check to see whether they actually have made any changes.
              const currentValue =
                getState().obsPoint.detail.alias.aliases.find(
                  (a) => a.id === alias.id
                );
              if (
                currentValue &&
                deepEqual(alias, {
                  ...currentValue,
                  observation_point: currentValue.observation_point.id,
                })
              ) {
                // No change, no need to update.
                return {
                  isError: false,
                  data: null,
                };
              }

              // PATCH change to existing record
              const data = await patchApi(
                `/observation-point-aliases/${alias.id}/`,
                alias
              );
              return {
                isError: false,
                data,
              };
            } else {
              // POST completely new record
              const data = await postApi('/observation-point-aliases/', alias);
              return {
                isError: false,
                data,
              };
            }
          } catch (e) {
            // Normally, if any of the promises sent to `Promise.all()` reject,
            // `Promise.all()` will resolve with *only* the first rejection.
            // But we need data about all the rejections (and successes) so
            // we can update the UI appropriately. So we catch any exception
            // and return it as a resolved value.
            return {
              isError: true,
              data: e,
            };
          }
        })
      );

      const successes = results
        .filter((res) => !res.isError && res.data)
        .map((res) => res.data) as Model.ObservationPointAlias[];

      const noErrors = results.every((res) => !res.isError);
      // Update the successfully updated/created aliases in the Redux store.
      dispatch(
        ActionCreators.UPDATE_ALIASES_RESPONSE(obsPoint, successes, noErrors)
      );

      if (noErrors) {
        return successes;
      } else {
        // There were some errors. We'll merge those into one big combined
        // `DRFError` object and throw it, so that the form can catch it and
        // display it in Formik.
        const mergedError = {
          aliases: results.map((res) => {
            if (!res.isError) {
              // Put in a `null` placeholder for the aliases that don't have
              // an error. This makes the array indexes match up with the
              // form rows correctly.
              return null;
            }
            const rawError = res.data!;
            if (!isDRFError(rawError)) {
              // Put non-DRF errors as errors on the "reading_position" field,
              // so they can be displayed in the form next to the raw that caused
              // the problem.
              return {
                reading_position: [errorToString(rawError)],
              };
            } else {
              const { detail, non_field_errors, ...fieldErrors } = rawError;
              return {
                ...fieldErrors,
                // Put non-field errors as errors on the "reading_position" field,
                // so they can be displayed in the form next to the raw that caused
                // the problem.
                reading_position: ([] as any[])
                  .concat(
                    fieldErrors.reading_position,
                    detail,
                    non_field_errors
                  )
                  .filter(isTruthy),
              };
            }
          }),
        };
        throw mergedError;
      }
    } catch (e) {
      dispatch(ActionCreators.UPDATE_ALIASES_ERROR(obsPoint, errorToString(e)));
      throw e;
    }
  };
}
