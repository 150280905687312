import { defaultMemoize } from 'reselect';
import { menuItemsFromEnum } from 'components/base/i18n/menuItemsFromEnum';
import { Enum } from 'util/backendapi/models/api.interfaces';

export const alarmComparisonTypeOptions = menuItemsFromEnum(
  'AlarmParameter_COMPARISON_TYPE',
  [
    Enum.AlarmParameter_COMPARISON_TYPE.absolute,
    Enum.AlarmParameter_COMPARISON_TYPE.relative,
    Enum.AlarmParameter_COMPARISON_TYPE.velocity,
  ]
);

export const alarmLevelOptions = menuItemsFromEnum('AlarmParameter_LEVEL', [
  Enum.AlarmParameter_LEVEL.data_check,
  Enum.AlarmParameter_LEVEL.alert,
  Enum.AlarmParameter_LEVEL.design_check,
]);

export const alarmLevelOptionsSelector = defaultMemoize(
  (comparison_type?: Enum.AlarmParameter_COMPARISON_TYPE) => {
    if (comparison_type !== Enum.AlarmParameter_COMPARISON_TYPE.absolute) {
      return alarmLevelOptions.filter(
        (item) => item.value !== Enum.AlarmParameter_LEVEL.data_check
      );
    }

    return alarmLevelOptions;
  }
);

export const alarmTypeOptions = menuItemsFromEnum('AlarmParameter_TYPE', [
  Enum.AlarmParameter_TYPE.maximum,
  Enum.AlarmParameter_TYPE.minimum,
]);
