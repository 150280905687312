import React from 'react';
import { Model } from '../../../../../util/backendapi/models/api.interfaces';
import Accordion from 'components/base/accordion/accordion';
import { Trans } from '@lingui/macro';
import { Card } from 'components/base/card/card';
import { formatDatetimeForDisplay } from 'util/dates';
import { DataLoggerChannelCard } from './dataloggerchannel';

interface Props {
  dataLoggerChannelHistory: Model.DataLoggerChannelDecorated[] | null;
  timeZone: string | undefined;
  noActiveDataLogger: boolean;
}

export function DataLoggerChannelHistory(props: Props) {
  const { dataLoggerChannelHistory, timeZone, noActiveDataLogger } = props;

  // Hide the data logger history viewer if there is no history.
  if (
    !dataLoggerChannelHistory ||
    !timeZone ||
    (noActiveDataLogger && dataLoggerChannelHistory.length < 1) ||
    (!noActiveDataLogger && dataLoggerChannelHistory.length < 2)
  ) {
    return null;
  }
  return (
    <Accordion
      expand={false}
      item={{
        id: 'data-logger-channel-history',
        title: <Trans>Data logger snapshots over time</Trans>,
        content: dataLoggerChannelHistory.map((snapshot, idx) => {
          // Skip the first snapshot. It represents the current data logger.
          // Unless the current data logger is retired, in which case we show
          // it here in the history.
          if (idx === 0 && !noActiveDataLogger) {
            return null;
          }

          const endDatetime = dataLoggerChannelHistory[idx - 1]?.start_datetime;

          return (
            <Card
              name={`data-logger-channel-history-snapshot-${idx}`}
              key={snapshot.id}
              header={
                <Trans>
                  {formatDatetimeForDisplay(snapshot.start_datetime, timeZone)}
                  {' - '}
                  {formatDatetimeForDisplay(endDatetime, timeZone)}
                </Trans>
              }
              iconType="icon-calendar"
            >
              <DataLoggerChannelCard
                timeZone={timeZone}
                dataLoggerChannel={snapshot}
                relatedObservationPointFormula={null}
              />
            </Card>
          );
        }),
      }}
    />
  );
}
