import React, { ComponentType } from 'react';
import Select, {
  components,
  Props as ReactSelectProps,
  OptionProps,
  OnChangeValue as ValueType,
} from 'react-select';
import orderBy from 'lodash/orderBy';
import { Trans } from '@lingui/macro';
import { SimpleSelectOption } from '../../../base/form/simpleselect/simpleselect';
import { Icon } from 'components/base/icon/icon';
import { createSelector } from 'reselect';
import { setOneQueryParam, RCPWithQueryParams } from 'util/routing';
import { ReportColumnRenderInfo, ALWAYS_SHOW } from '../report-types';
import './ReportColumnsMenu.scss';

export interface ReportColumnsMenuItem {
  value: string;
  label: React.ReactNode;
}

export type ReportColumnsMenuProps<TReportItem extends {} = any> = Merge<
  ReactSelectProps<SimpleSelectOption, true>,
  {
    columns: Pick<
      ReportColumnRenderInfo<TReportItem>,
      'frontend' | 'backend'
    >[];
    activeColumns: string[];
  } & RCPWithQueryParams<{ columns: string[] }>
>;

export class ReportColumnsMenu<
  TReportItem extends {} = any
> extends React.Component<ReportColumnsMenuProps<TReportItem>> {
  makeColumnOptions = createSelector(
    (props: ReportColumnsMenuProps<TReportItem>) => props.columns,
    function (columnDefinitions) {
      return columnDefinitions
        .filter((column) => column.frontend.visibility !== ALWAYS_SHOW)
        .map((column) => ({
          value: column.frontend.name,
          label: column.frontend.label,
        }));
    }
  );

  handleChange = (selection: ValueType<ReportColumnsMenuItem, true>) => {
    const selectedColumnNames = Array.isArray(selection)
      ? orderBy(selection, ({ value: colName }) =>
          this.props.columns.findIndex((col) => col.frontend.name === colName)
        ).map((s) => s.value)
      : [];
    setOneQueryParam(
      this.props,
      'columns',
      selectedColumnNames.length > 0 ? selectedColumnNames : '',
      null
    );
  };

  render() {
    const columnOptions = this.makeColumnOptions(this.props);
    const currentSelection: typeof columnOptions = columnOptions.filter((opt) =>
      this.props.activeColumns.includes(opt.value)
    );

    return (
      <Select
        onChange={this.handleChange}
        // These classes need to be in this order
        className="column-select react-select"
        classNamePrefix="react-select"
        placeholder={(<Trans>Columns</Trans>) as any}
        components={{ Option: columnOptionComponent }}
        options={columnOptions}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={false}
        controlShouldRenderValue={false}
        isSearchable={false}
        value={currentSelection}
        {...this.props}
        isMulti
      />
    );
  }
}

/**
 * A little functional component for rendering one of the column options
 * from the menu/popup
 *
 * @param props
 */
const columnOptionComponent: ComponentType<
  OptionProps<ReportColumnsMenuItem, true>
> = (props) => (
  <components.Option {...props}>
    <span className="react-select-inner-wrap">
      {props.isSelected ? <Icon type="icon-tick" /> : null}
      {props.label}
    </span>
  </components.Option>
);
