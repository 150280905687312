import React from 'react';
import lodashSet from 'lodash/set';
import { Trans } from '@lingui/macro';
import { SectionProps } from '../../maintobspoint.types';
import { FormikErrors, Field, FieldArray, FormikHelpers } from 'formik';
import EditableCard from 'components/base/form/editablecard/editablecard';
import { Model } from 'util/backendapi/models/api.interfaces';
import Button from 'components/base/button/button';
import { AlertDanger } from 'components/base/alert/alert';
import { validatePositiveInteger } from 'util/validation';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { CardSectionRow } from 'components/base/card/card';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { errorToString, getExpectedFields } from 'util/backendapi/error';
import { showErrorsInFormik } from 'util/backendapi/error-formik';

export interface ObsPointAliasSectionForm {
  aliases: Array<{ id?: number; alias: string; reading_position: number | '' }>;
}
export type ObsPointAliasSectionProps = {
  hasEditAliasPermission: boolean;
  observationPointCode: string;
  observationPointAliases: Model.ObservationPointAlias[];
  onSubmit: (
    aliases: Array<{ id?: number; reading_position: number; alias: string }>
  ) => Promise<void>;
  onDelete: (aliasId: number) => Promise<void>;
} & Pick<
  SectionProps,
  | 'startEditing'
  | 'stopEditing'
  | 'isEditing'
  | 'isDisabled'
  | 'isLoading'
  | 'isSubmitting'
>;

function validate(values: ObsPointAliasSectionForm) {
  const errors: FormikErrors<ObsPointAliasSectionForm> = {};
  values.aliases.forEach((aliasVal, idx) => {
    if (aliasVal.reading_position === '') {
      lodashSet(
        errors,
        `aliases[${idx}].reading_position`,
        <Trans>Reading position number is required</Trans>
      );
    } else if (!validatePositiveInteger(aliasVal.reading_position)) {
      lodashSet(
        errors,
        `aliases[${idx}].reading_position`,
        <Trans>Reading position must be a positive integer</Trans>
      );
    } else if (
      values.aliases
        .slice(0, idx)
        .map((a) => a.reading_position)
        .includes(aliasVal.reading_position)
    ) {
      lodashSet(
        errors,
        `aliases[${idx}].reading_position`,
        <Trans>
          This reading position is already in use by another alias for this
          observation point
        </Trans>
      );
    }

    // Alias code must be globally unique; must rely on the backend to
    // check that.
    if (aliasVal.alias === '') {
      lodashSet(
        errors,
        `aliases[${idx}].alias`,
        <Trans>Alias is required</Trans>
      );
    }
  });
  return errors;
}

export const ObsPointAliasSectionView: React.FunctionComponent<ObsPointAliasSectionProps> =
  function (props) {
    const shouldDisable =
      props.isDisabled || props.isLoading || props.isSubmitting;

    const initialValues: ObsPointAliasSectionForm = {
      aliases: props.observationPointAliases.map((alias) => ({
        id: alias.id,
        alias: alias.alias,
        reading_position: alias.reading_position,
      })),
    };

    const handleSubmit = React.useCallback(
      async (
        values: ObsPointAliasSectionForm,
        formik: FormikHelpers<ObsPointAliasSectionForm>
      ) => {
        try {
          await props.onSubmit.call(
            null,
            values.aliases.map((a) => ({
              ...a,
              reading_position: Number(a.reading_position),
            }))
          );
        } catch (errors) {
          formik.setSubmitting(false);
          showErrorsInFormik(formik, errors, getExpectedFields(values));
        }
      },
      [props.onSubmit]
    );

    return (
      <EditableCard<ObsPointAliasSectionForm>
        // When the card is in edit mode, do not reset the form when the
        // Redux values change (because the Redux values will change when you
        // delete an existing alias, but resetting the form at that point
        // would lose form state.)
        enableReinitialize={!props.isEditing}
        hasEditPermission={props.hasEditAliasPermission}
        header={<Trans>Observation point aliases</Trans>}
        initialValues={initialValues}
        isEditMode={props.isEditing}
        name="alias"
        onSubmit={handleSubmit}
        shouldDisable={shouldDisable}
        startEditing={props.startEditing}
        stopEditing={props.stopEditing}
        validate={validate}
        render={({ formik, CardSectionComponent }) => (
          // Using a Formik FieldArray component to help manage the dynamically
          // added & deleted form rows.
          <FieldArray name="aliases">
            {(formikArrayHelpers) => {
              let cardFields: CardSectionRow[] = [];

              if (!props.isEditing) {
                // Here's how each alias is rendered in view mode
                cardFields = formik.values.aliases.map((aliasVal, idx) => ({
                  name: 'observation-point-alias-view-' + idx,
                  label: (
                    <Trans>Reading position {aliasVal.reading_position}</Trans>
                  ),
                  content: aliasVal.alias,
                }));
              } else {
                // Here's how each alias is rendered in edit mode
                cardFields = formik.values.aliases.map((aliasVal, idx) => {
                  const rowName = 'observation-point-alias-' + idx;

                  // Shared props for the two versions of the "delete" button.
                  const deleteButtonProps = {
                    disabled: shouldDisable,
                    name: `${rowName}-delete`,
                  } as const;

                  // There are two versions of the "delete" button, depending
                  // on whether a row represents an existing alias, or a new
                  // alias that they haven't saved yet.
                  let deleteButton: JSX.Element;
                  if (aliasVal.id) {
                    // Existing alias: check permissions and show a confirmation before
                    // deleting, because it will delete actual records.
                    const originalAlias = props.observationPointAliases.find(
                      (a) => a.id === aliasVal.id
                    );

                    deleteButton = (
                      <HasPermission check="can_delete_observation_point_aliases">
                        <ButtonShowConfirmation
                          {...deleteButtonProps}
                          onConfirm={async () => {
                            try {
                              await props.onDelete(aliasVal.id!);
                              formikArrayHelpers.remove(idx);
                            } catch (e) {
                              formik.setStatus(
                                <AlertDanger>{errorToString(e)}</AlertDanger>
                              );
                            }
                          }}
                          okBtnText={<Trans>Yes, delete</Trans>}
                          content={
                            <Trans>
                              Are you sure you want to delete the{' '}
                              <strong>
                                {originalAlias
                                  ? originalAlias.alias
                                  : aliasVal.alias}
                              </strong>{' '}
                              observation point alias? Raw readings with this
                              code will no longer be associated with{' '}
                              <strong>{props.observationPointCode}</strong>.
                              This action is not reversible.
                            </Trans>
                          }
                        >
                          <Trans>Delete</Trans>
                        </ButtonShowConfirmation>
                      </HasPermission>
                    );
                  } else {
                    // Not-yet-saved new alias: No records to actually delete;
                    // just remove it from the form
                    deleteButton = (
                      <Button
                        {...deleteButtonProps}
                        onClick={() => formikArrayHelpers.remove(idx)}
                      >
                        <Trans>Delete</Trans>
                      </Button>
                    );
                  }

                  return {
                    name: rowName,
                    columns: [
                      {
                        name: rowName + '-position',
                        label: <Trans>Reading position</Trans>,
                        content: (
                          <>
                            <Field
                              name={`aliases[${idx}].reading_position`}
                              type="number"
                              min="1"
                              step="1"
                            />
                            <FieldError
                              name={`aliases[${idx}].reading_position`}
                            />
                          </>
                        ),
                      },
                      {
                        name: rowName + '-alias',
                        label: <Trans>Alias</Trans>,
                        content: (
                          <>
                            <Field
                              name={`aliases[${idx}].alias`}
                              maxLength="150"
                            />
                            <FieldError name={`aliases[${idx}].alias`} />
                            {deleteButton}
                          </>
                        ),
                      },
                    ],
                  };
                });
                cardFields.push(
                  <div key="alias-add" className="card-row">
                    <Button
                      name="alias-add"
                      iconType="icon-plus"
                      disabled={shouldDisable}
                      onClick={() =>
                        formikArrayHelpers.push({
                          reading_position: '',
                          alias: '',
                        })
                      }
                    >
                      <Trans>Add alias</Trans>
                    </Button>
                  </div>
                );
              }
              return (
                <CardSectionComponent
                  name="aliases"
                  header={<Trans>Aliases</Trans>}
                  fields={cardFields}
                >
                  {!props.isEditing &&
                    !props.isLoading &&
                    formik.values.aliases.length === 0 && (
                      <p className="non-editable-value">
                        <Trans>
                          There are no aliases for this observation point
                        </Trans>
                      </p>
                    )}
                  {formik.status}
                </CardSectionComponent>
              );
            }}
          </FieldArray>
        )}
      />
    );
  };
