import React, { useState, useEffect, useCallback } from 'react';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import {
  changeUserRolesOnGroup,
  fetchGroupUsers,
  removeUserFromGroup,
  unmountMaintainGroupUsersPanel,
} from 'ducks/group';
import { MaintainGroupUsersPanelView } from './maintgroupuserspanelview';
import { refreshCurrentUserData } from 'ducks/login';
import { useIsMounted, useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';

interface Props {
  groupId: number;
  groupName: string;
}

export function MaintainGroupUsersPanel(props: Props) {
  const [addPersonFormOpen, setAddPersonFormOpen] = useState(false);
  const [editPersonFormOpen, setEditPersonFormOpen] = useState(false);
  const [editingUserGroupId, setEditingUserGroupId] = useState<number | null>(
    null
  );
  const isMounted = useIsMounted();
  const dispatch = useDispatch();

  const state = useShallowEqualSelector((state: FullState) => {
    const loggedInAs = state.user.loggedInAs;
    const allUsers = state.groups.allUsers;
    const roles = state.groups.roles;
    if (state.groups.groupUsers.groupId === props.groupId) {
      return {
        loggedInAs,
        allUsers,
        roles,
        isLoading: state.groups.groupUsers.isLoading,
        groupUsers: state.groups.groupUsers.groupUsers,
        errorMessage: state.groups.groupUsers.errorMessage,
        isSubmitting: state.groups.groupUsers.isSubmitting,
        submitError: state.groups.groupUsers.submitError,
      };
    } else {
      return {
        loggedInAs,
        allUsers,
        roles,
        isLoading: true,
        groupUsers: null,
        errorMessage: null,
        isSubmitting: false,
        submitError: null,
      };
    }
  });

  useEffect(() => {
    if (!state.groupUsers) {
      dispatch(fetchGroupUsers(props.groupId));
    }
  }, [dispatch, props.groupId, state.groupUsers]);

  useEffect(() => {
    return () => {
      dispatch(unmountMaintainGroupUsersPanel(props.groupId));
    };
  }, [dispatch, props.groupId]);

  const addPersonClick = useCallback(() => {
    setAddPersonFormOpen(!addPersonFormOpen);
    setEditPersonFormOpen(false);
    setEditingUserGroupId(null);
  }, [addPersonFormOpen]);

  const editPersonClick = useCallback(
    (userGroupId = null) => {
      setAddPersonFormOpen(false);
      setEditPersonFormOpen(!editPersonFormOpen);
      setEditingUserGroupId(editPersonFormOpen ? null : userGroupId);
    },
    [editPersonFormOpen]
  );

  const submitAddOrEditUser = useCallback(
    async ({ roles, userGroupId, userId, groupId }, formik) => {
      formik.setSubmitting(true);
      await dispatch(
        changeUserRolesOnGroup(roles, userGroupId, userId, groupId)
      );

      if (isMounted() && !state.isSubmitting && !state.submitError) {
        setAddPersonFormOpen(false);
        setEditPersonFormOpen(false);
        setEditingUserGroupId(null);
        formik.setSubmitting(false);
        dispatch(fetchGroupUsers(groupId));
        if (userId === state.loggedInAs) {
          dispatch(refreshCurrentUserData());
        }
      } else if (state.submitError) {
        formik.setStatus(<Trans>An error has occurred</Trans>);
      }
    },
    [
      dispatch,
      isMounted,
      state.isSubmitting,
      state.loggedInAs,
      state.submitError,
    ]
  );

  const removePersonClick = useCallback(
    async (groupId, userGroupId, userId, hideModal) => {
      await dispatch(removeUserFromGroup(userGroupId));
      if (isMounted()) {
        dispatch(fetchGroupUsers(groupId));
        if (state.loggedInAs === userId) {
          dispatch(refreshCurrentUserData());
        }
        hideModal();
      }
    },
    [dispatch, isMounted, state.loggedInAs]
  );

  return (
    <MaintainGroupUsersPanelView
      {...props}
      {...state}
      addPersonFormOpen={addPersonFormOpen}
      editPersonFormOpen={editPersonFormOpen}
      onSubmitAddPerson={submitAddOrEditUser}
      onAddPersonClick={addPersonClick}
      onEditPersonClick={editPersonClick}
      editingUserGroupId={editingUserGroupId}
      onRemovePersonClick={removePersonClick}
    />
  );
}
