import { getApi, patchApi, postApi } from '../util/backendapi/fetch';
import { formatDatetimeForBackendapi } from '../util/dates';
import { t } from '@lingui/macro';
import { Model, Filter, Enum } from '../util/backendapi/models/api.interfaces';
import { StandardThunk, DuckActions } from 'main/store';
import {
  ActionCreators as CommentsListActionCreators,
  ActionTypes as CommentsListActionTypes,
} from './comments/panel/list';

export const ActionTypes = {
  FETCH_ALARM_FOR_REPORTING_START:
    'dms/quickplot/FETCH_ALARM_FOR_REPORTING_START',
  FETCH_ALARM_FOR_REPORTING_ERROR:
    'dms/quickplot/FETCH_ALARM_FOR_REPORTING_ERROR',
  FETCH_ALARM_FOR_REPORTING_RESPONSE:
    'dms/quickplot/FETCH_ALARM_FOR_REPORTING_RESPONSE',
  FETCH_ALARM_FOR_EDITING_START: 'dms/quickplot/FETCH_ALARM_FOR_EDITING_START',
  FETCH_ALARM_FOR_EDITING_ERROR: 'dms/quickplot/FETCH_ALARM_FOR_EDITING_ERROR',
  FETCH_ALARM_FOR_EDITING_RESPONSE:
    'dms/quickplot/FETCH_ALARM_FOR_EDITING_RESPONSE',
  CLEAR_ALARM_PARAMETER_FORM: 'dms/quickplot/CLEAR_ALARM_PARAMETER_FORM',
} as const;

export interface AlarmParametersState {
  alarms: null | Model.ReportsAlarmParameter[];
  editForm: {
    isFetching: boolean;
    errorMessage: string;
    alarmParameter: null | Model.AlarmParameter;
  };
  observationPointId: null | number;
  instrumentTypeItem: null | Model.InstrumentTypeItem;
  isLoading: boolean;
  errorMessage: string;
}

export const ActionCreators = {
  FETCH_ALARM_FOR_REPORTING_START: function (observationPointId: number) {
    return {
      type: ActionTypes.FETCH_ALARM_FOR_REPORTING_START,
      observationPointId,
    };
  },
  FETCH_ALARM_FOR_REPORTING_RESPONSE: function (
    observationPointId: number,
    payload: {
      instrumentTypeItem: null | Model.InstrumentTypeItem;
      alarms: Model.ReportsAlarmParameter[];
    }
  ) {
    return {
      type: ActionTypes.FETCH_ALARM_FOR_REPORTING_RESPONSE,
      observationPointId,
      payload,
    };
  },
  FETCH_ALARM_FOR_REPORTING_ERROR: function (
    observationPointId: number,
    errorMsg: string
  ) {
    return {
      type: ActionTypes.FETCH_ALARM_FOR_REPORTING_ERROR,
      error: true,
      observationPointId,
      payload: errorMsg,
    };
  },
  FETCH_ALARM_FOR_EDITING_START: function (id: number) {
    return {
      type: ActionTypes.FETCH_ALARM_FOR_EDITING_START,
      id,
    };
  },
  FETCH_ALARM_FOR_EDITING_ERROR: function (id: number, message: string) {
    return {
      type: ActionTypes.FETCH_ALARM_FOR_EDITING_ERROR,
      id,
      message,
    };
  },
  FETCH_ALARM_FOR_EDITING_RESPONSE: function (payload: Model.AlarmParameter) {
    return {
      type: ActionTypes.FETCH_ALARM_FOR_EDITING_RESPONSE,
      payload,
    };
  },
  CLEAR_ALARM_PARAMETER_FORM: function () {
    return {
      type: ActionTypes.CLEAR_ALARM_PARAMETER_FORM,
    };
  },
};

export type AlarmParametersAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

export function alarmParametersInitialState(): AlarmParametersState {
  return {
    alarms: null,
    editForm: {
      isFetching: false,
      alarmParameter: null,
      errorMessage: '',
    },
    observationPointId: null,
    instrumentTypeItem: null,
    isLoading: false,
    errorMessage: '',
  };
}

export function alarmParametersReducer(
  state = alarmParametersInitialState(),
  action:
    | AlarmParametersAction
    | ReturnType<typeof CommentsListActionCreators['addCommentResponse']>
): AlarmParametersState {
  switch (action.type) {
    case ActionTypes.FETCH_ALARM_FOR_REPORTING_START:
      return {
        ...state,
        isLoading: true,
        observationPointId: action.observationPointId,
        instrumentTypeItem: null,
        errorMessage: '',
      };

    case ActionTypes.FETCH_ALARM_FOR_REPORTING_RESPONSE:
      if (state.observationPointId === action.observationPointId) {
        return {
          ...state,
          isLoading: false,
          alarms: action.payload.alarms,
          instrumentTypeItem: action.payload.instrumentTypeItem,
          errorMessage: '',
        };
      } else {
        return state;
      }

    case ActionTypes.FETCH_ALARM_FOR_REPORTING_ERROR:
      if (state.observationPointId === action.observationPointId) {
        return {
          ...state,
          isLoading: false,
          alarms: null,
          errorMessage: action.payload,
        };
      } else {
        return state;
      }

    case ActionTypes.FETCH_ALARM_FOR_EDITING_START:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          isFetching: true,
          errorMessage: '',
        },
      };
    case ActionTypes.FETCH_ALARM_FOR_EDITING_ERROR:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          isFetching: false,
          errorMessage: action.message,
        },
      };
    case ActionTypes.FETCH_ALARM_FOR_EDITING_RESPONSE:
      return {
        ...state,
        editForm: {
          ...state.editForm,
          isFetching: false,
          alarmParameter: action.payload,
        },
      };

    case ActionTypes.CLEAR_ALARM_PARAMETER_FORM:
      return {
        ...state,
        editForm: alarmParametersInitialState().editForm,
      };

    // increase comment count when a comment is added with type = AlarmParamterComment
    case CommentsListActionTypes.ADD_COMMENT_RESPONSE:
      if (
        action.payload.resourcetype ===
        Enum.Comment_RESOURCE_TYPE.alarmParameter
      ) {
        const idxForUpdateCommentCount = (state.alarms || []).findIndex(
          (alarm) =>
            alarm.id ===
            (action.payload as Model.AlarmParameterComment).alarm_parameter
        );

        if (idxForUpdateCommentCount > -1) {
          return {
            ...state,
            alarms: state.alarms!.map((alarm, idx) =>
              idx === idxForUpdateCommentCount
                ? { ...alarm, comment_count: alarm.comment_count + 1 }
                : alarm
            ),
          };
        } else {
          return state;
        }
      } else {
        return state;
      }

    default:
      return state;
  }
}

export function fetchAlarmsForReporting(
  observationPointId: number,
  itemNumber: number,
  startDatetime: string,
  endDatetime: string
): StandardThunk {
  return async function (dispatch, getState, { i18n }) {
    dispatch(
      ActionCreators.FETCH_ALARM_FOR_REPORTING_START(observationPointId)
    );

    try {
      const obsPointJson: Model.ObservationPointDecorated = await getApi(
        `/observation-points/${observationPointId}/`
      );

      const instrumentTypeItem = obsPointJson.instrument_type.items.find(
        (item) => item.item_number === itemNumber
      );

      const filters: Filter.ReportsAlarmParameters = {
        observation_point: observationPointId,
        item_number: itemNumber,
        columns: [
          'id',
          'level',
          'type',
          'threshold',
          'start_datetime',
          'end_datetime',
          'comparison_type',
          'comment_count',
          'relative_comparison_factor',
          'relative_comparison_datetime_tolerance',
          'status',
          'last_changed',
          'observation_point__id',
          'observation_point__code',
          'observation_point__site',
          'observation_point__site__area',
          'item_number',
          'relative_observation_point__code',
          'relative_item_number',
          'time_interval',
          'direction_of_change',
        ],
      };

      if (startDatetime) {
        filters.start_datetime = formatDatetimeForBackendapi(startDatetime);
      }

      if (endDatetime) {
        filters.end_datetime = formatDatetimeForBackendapi(endDatetime);
      }

      const alarmsJson = await getApi('/reports/alarm-parameters/', filters);

      return dispatch(
        ActionCreators.FETCH_ALARM_FOR_REPORTING_RESPONSE(observationPointId, {
          instrumentTypeItem: instrumentTypeItem ? instrumentTypeItem : null,
          alarms: alarmsJson,
        })
      );
    } catch (e) {
      return dispatch(
        ActionCreators.FETCH_ALARM_FOR_REPORTING_ERROR(
          observationPointId,
          i18n._(t`Error fetching alarm parameters`)
        )
      );
    }
  };
}

export function fetchAlarmForEditing(id: number): StandardThunk {
  return async function (dispatch, _getState, { i18n }) {
    try {
      dispatch(ActionCreators.FETCH_ALARM_FOR_EDITING_START(id));

      const alarmParameter = await getApi(`/alarm-parameters/${id}/`);

      return dispatch(
        ActionCreators.FETCH_ALARM_FOR_EDITING_RESPONSE(alarmParameter)
      );
    } catch (err) {
      return dispatch(
        ActionCreators.FETCH_ALARM_FOR_EDITING_ERROR(
          id,
          i18n._(t`Error fetching alarm for editing`)
        )
      );
    }
  };
}

export function updateAlarmParameter(
  id: number,
  payload: Model.AlarmParameter_PATCH
): StandardThunk {
  return async function () {
    return await patchApi(`/alarm-parameters/${id}/`, payload);
  };
}

export function createAlarmParameter(
  payload: Model.AlarmParameter_POST
): StandardThunk {
  return async function () {
    return await postApi('/alarm-parameters/', payload);
  };
}
