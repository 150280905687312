import { useEffect, useRef } from 'react';
import { createIDBMutex } from '@protontech/mutex-browser';
import { LOCALSTORAGE_NAMESPACE, StandardDispatch } from 'main/store';
import { refreshAuthTokens } from 'ducks/login';
import { GlobalActionCreators } from 'ducks/global';

const MUTEX_NAME = 'refresh_lock';
const JITTER_SECONDS = 10;

interface Props {
  // Whether or not to add random "jitter" to the refresh interval. Usually
  // we want to add some jitter, to try to avoid all open tabs contending for
  // the refresh lock simultaneously. But for testing purposes its useful
  // to make everything deterministic by disabling jitter.
  jitter: boolean;
  dispatch: StandardDispatch;
  tokenExpiry: number | null;
}

/**
 */
export function TokenRefreshManager(props: Props) {
  const { dispatch, tokenExpiry, jitter } = props;

  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (tokenExpiry) {
      const now = Date.now();

      if (tokenExpiry <= now) {
        dispatch(GlobalActionCreators.CLEAR_STATE());
      } else {
        // Add +/- 10 seconds of random "jitter" to the scheduled
        // refresh period, to try to avoid multiple tabs contending for the
        // refresh lock simultaneously. This helps avoid potential performance
        // problems caused by waiting tabs needing to "busy wait" for the lock.
        const jitterSeconds = jitter
          ? Math.random() * (2 * JITTER_SECONDS) - JITTER_SECONDS
          : 0;

        const fullRefreshPeriod =
          (Number(process.env.REACT_APP_REFRESH_PERIOD_SECONDS ?? 600) +
            jitterSeconds) *
          1000;

        // If the token would expire before the normal refresh window is up,
        // then try to schedule an earlier refresh, 30 seconds before token
        // expiry.
        const earlyRefresh = tokenExpiry - now - (30 + jitterSeconds) * 1000;

        const refreshDelay = Math.max(
          Math.min(fullRefreshPeriod, earlyRefresh),
          0
        );

        timerRef.current = setTimeout(() => {
          // Find out the timer and auth state at the time we scheduled this timer.
          // When the timer executes, we'll check these to see if they're still
          // the latest, and that will tell us if the timer should cancel itself.
          const prevAuthState = window.localStorage.getItem(
            `${LOCALSTORAGE_NAMESPACE}_auth`
          );
          const prevTimer = timerRef.current;

          // We use a mutex to prevent multiple tabs sending the refresh request simultaneously.
          const mutex = createIDBMutex();

          mutex
            .lock(MUTEX_NAME)
            .then(
              /**
               * In concurrent programming terms, this function is the "critical section"
               * of our refresh timer. Only one tab at a time will be able to
               * execute the code inside this function.
               */
              function criticalSection() {
                const curAuthState = window.localStorage.getItem(
                  `${LOCALSTORAGE_NAMESPACE}_auth`
                );
                const currentTimer = timerRef.current;
                if (
                  prevAuthState === curAuthState &&
                  prevTimer === currentTimer
                ) {
                  // We got here first! Fire off the refresh.
                  return dispatch(refreshAuthTokens());
                } else {
                  // Someone else got here before us. No longer any need to refresh.
                  return;
                }
              }
            )
            .finally(() => {
              mutex.unlock(MUTEX_NAME);
              timerRef.current = undefined;
            });
        }, refreshDelay);
      }
    }

    return () => {
      // Clear any ongoing timers whenever the auth token changes (as reflected
      // by having a new tokenExpiry)
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [dispatch, jitter, tokenExpiry]);

  // Logical component; renders no content
  return null;
}
