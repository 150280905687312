import React from 'react';
import { Trans } from '@lingui/macro';
import { ReportFilterMenu, reportFilterMenu } from './FilterMenu';

export function reportFilterBoolean(name: string, label: React.ReactNode) {
  return reportFilterMenu(
    name,
    label,
    { isMulti: false, valueType: 'string' },
    ReportFilterMenu.PASS_MENU_THROUGH,
    {
      placeholder: <Trans>Both yes and no</Trans>,
      options: [
        {
          label: <Trans>Yes</Trans>,
          value: 'true',
        },
        {
          label: <Trans>No</Trans>,
          value: 'false',
        },
        {
          label: <Trans>Both yes and no</Trans>,
          value: '',
        },
      ],
    }
  );
}
