import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import {
  getApi,
  postApi,
  patchApi,
  postApiFormData,
  patchApiFormData,
} from 'util/backendapi/fetch';
import { StandardThunk, DuckActions } from 'main/store';
import { errorToString } from 'util/backendapi/error';

export const ActionTypes = {
  FETCH_STORED_PLOT_START: 'dms/storedPlot/detail/FETCH_STORED_PLOT_START',
  FETCH_STORED_PLOT_RESPONSE:
    'dms/storedPlot/detail/FETCH_STORED_PLOT_RESPONSE',
  FETCH_STORED_PLOT_ERROR: 'dms/storedPlot/detail/FETCH_STORED_PLOT_ERROR',
  UNMOUNT: 'dms/storedPlot/detail/UNMOUNT',
} as const;

export const ActionCreators = {
  FETCH_STORED_PLOT_START(plotName: string) {
    return {
      type: ActionTypes.FETCH_STORED_PLOT_START,
      plotName,
    };
  },
  FETCH_STORED_PLOT_RESPONSE(
    storedPlot: Model.PolymorphicStoredPlot,
    area: Model.AreaDecorated
  ) {
    return {
      type: ActionTypes.FETCH_STORED_PLOT_RESPONSE,
      plotName: storedPlot && storedPlot.name,
      payload:
        storedPlot && area
          ? {
              ...storedPlot,
              area,
            }
          : null,
    };
  },
  FETCH_STORED_PLOT_ERROR(plotName: string, errorMessage: string) {
    return {
      type: ActionTypes.FETCH_STORED_PLOT_ERROR,
      plotName,
      errorMessage,
    };
  },
  UNMOUNT() {
    return {
      type: ActionTypes.UNMOUNT,
    };
  },
};

export type StoredPlotDetailAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

type WithArea<T> = Merge<T, { area: Model.AreaDecorated }>;

export type StoredTimeSeriesPlotWithArea = WithArea<Model.StoredTimeSeriesPlot>;
export type StoredScatterPlotWithArea = WithArea<Model.StoredScatterPlot>;
export type StoredCumulativePlotWithArea = WithArea<Model.StoredCumulativePlot>;

export type StoredSpatialPlanPlotWithArea =
  WithArea<Model.StoredSpatialPlanPlot>;
export type StoredSpatialCrossSectionPlotWithArea =
  WithArea<Model.StoredSpatialCrossSectionPlot>;
export type StoredSpatialWanderPlotWithArea =
  WithArea<Model.StoredSpatialWanderPlot>;
export type StoredSurveyLevellingPlotWithArea =
  WithArea<Model.StoredSurveyLevellingPlot>;
export type PolymorphicStoredPlotWithArea =
  | StoredTimeSeriesPlotWithArea
  | StoredScatterPlotWithArea
  | StoredSpatialPlanPlotWithArea
  | StoredSpatialCrossSectionPlotWithArea
  | StoredSpatialWanderPlotWithArea
  | StoredSpatialCrossSectionPlotWithArea
  | StoredSurveyLevellingPlotWithArea
  | StoredCumulativePlotWithArea;

export type PolymorphicStoredSpatialPlotWithArea =
  | StoredSpatialPlanPlotWithArea
  | StoredSpatialCrossSectionPlotWithArea
  | StoredSpatialWanderPlotWithArea;

export interface SingleStoredPlotState {
  loading: boolean;
  errorMessage: string;
  plotName: string | null;
  record: null | PolymorphicStoredPlotWithArea;
}

export type StoredPlotDetailState = {
  [K in string]?: SingleStoredPlotState;
};

export function makeStoredPlotKey(plotName: string) {
  return `stored-plot:${plotName}`;
}

function initialStoredPlotsDetailState(): StoredPlotDetailState {
  return {};
}

export function storedPlotDetailReducer(
  state = initialStoredPlotsDetailState(),
  action: StoredPlotDetailAction
): StoredPlotDetailState {
  switch (action.type) {
    case ActionTypes.FETCH_STORED_PLOT_START:
      return {
        ...state,
        [makeStoredPlotKey(action.plotName)]: {
          loading: true,
          errorMessage: '',
          record: null,
          plotName: action.plotName,
        },
      };
    case ActionTypes.FETCH_STORED_PLOT_RESPONSE: {
      const plotKey = makeStoredPlotKey(action.plotName);
      const plotState = state[plotKey];
      if (plotState) {
        return {
          ...state,
          [plotKey]: {
            ...plotState,
            loading: false,
            record: action.payload,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.FETCH_STORED_PLOT_ERROR: {
      const plotKey = makeStoredPlotKey(action.plotName);
      const plotState = state[plotKey];
      if (plotState) {
        return {
          ...state,
          [plotKey]: {
            ...plotState,
            loading: false,
            errorMessage: action.errorMessage,
          },
        };
      } else {
        return state;
      }
    }
    case ActionTypes.UNMOUNT:
      return initialStoredPlotsDetailState();
    default:
      return state;
  }
}

export const unmountStoredPlotDetails = ActionCreators.UNMOUNT;

export function fetchStoredPlot(plotName: string): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.FETCH_STORED_PLOT_START(plotName));
    try {
      const [storedPlot] = await getApi('/stored-plots/', {
        name: plotName,
      });
      if (
        storedPlot.plot_type === Enum.PlotType.SPATIAL_WANDER ||
        storedPlot.plot_type === Enum.PlotType.SURVEY_LEVELLING
      ) {
        // The back end does not return the time periods in chronological order.
        storedPlot.time_periods.sort();
      }
      const area = await getApi(`/areas/${storedPlot.area}/`);
      dispatch(ActionCreators.FETCH_STORED_PLOT_RESPONSE(storedPlot, area));
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_STORED_PLOT_ERROR(plotName, errorToString(e))
      );
    }
  };
}

/**
 * To support uploading images, Spatial Plots are submitted
 * to the backend as multipart/form-data.
 */

function storedSpatialPlotFormData(
  values: Model.PolymorphicStoredSpatialPlot_POST,
  storedPlotId?: number
) {
  const formData = new FormData();

  if (storedPlotId) {
    formData.append('stored_plot_id', String(storedPlotId));
  }

  formData.append('plot_type', values.plot_type);

  const nonJsonValues: Array<keyof Model.PolymorphicStoredSpatialPlot_POST> = [
    'plot_type',
    'base_layer_image',
    'base_layer_image_file',
    'additional_layer_image',
    'additional_layer_image_file',
  ];

  let jsonValues = { ...values };
  Object.keys(values).forEach((key) => {
    if (nonJsonValues.indexOf(key) !== -1) {
      delete jsonValues[key];
    }
  });

  if (storedPlotId && values.additional_layer_image === '') {
    // Setting the filename to null causes it to be deleted
    jsonValues['additional_layer_image'] = null;
  }

  formData.append('values', JSON.stringify(jsonValues));

  if (values.base_layer_image && values.base_layer_image_file) {
    formData.append(
      'base_layer_image',
      values.base_layer_image_file,
      values.base_layer_image
    );
  }

  if (values.additional_layer_image && values.additional_layer_image_file) {
    formData.append(
      'additional_layer_image',
      values.additional_layer_image_file,
      values.additional_layer_image
    );
  }

  return formData;
}

function storedSurveyLevellingPlotFormData(
  values: Model.StoredSurveyLevellingPlot_POST,
  storedPlotId?: number
) {
  const formData = new FormData();

  if (storedPlotId) {
    formData.append('stored_plot_id', String(storedPlotId));
  }

  formData.append('plot_type', values.plot_type);

  const nonJsonValues: Array<keyof Model.StoredSurveyLevellingPlot_POST> = [
    'plot_type',
    'background_image',
    'background_image_file',
  ];

  let jsonValues = { ...values };
  Object.keys(values).forEach((key) => {
    if (nonJsonValues.indexOf(key) !== -1) {
      delete jsonValues[key];
    }
  });

  if (storedPlotId && values.background_image === '') {
    // Setting the filename to null causes it to be deleted
    jsonValues['background_image'] = null;
  }

  formData.append('values', JSON.stringify(jsonValues));

  if (values.background_image && values.background_image_file) {
    formData.append(
      'background_image',
      values.background_image_file,
      values.background_image
    );
  }

  return formData;
}

export function createStoredSpatialPlot(
  values: Model.PolymorphicStoredSpatialPlot_POST
): StandardThunk {
  return async () => {
    const formData = storedSpatialPlotFormData(values);
    return await postApiFormData('/spatial-stored-plots/', formData);
  };
}

export function updateStoredSpatialPlot(
  storedPlotId: number,
  values: Model.PolymorphicStoredSpatialPlot_POST
): StandardThunk {
  return async () => {
    const formData = storedSpatialPlotFormData(values, storedPlotId);
    return await patchApiFormData(
      `/spatial-stored-plots/${storedPlotId}/`,
      formData
    );
  };
}

export function createStoredSurveyLevellingPlot(
  values: Model.StoredSurveyLevellingPlot_POST
): StandardThunk {
  return async () => {
    const formData = storedSurveyLevellingPlotFormData(values);
    return await postApiFormData('/stored-plots/', formData);
  };
}

export function updateStoredSurveyLevellingPlot(
  storedPlotId: number,
  values: Model.StoredSurveyLevellingPlot_POST
): StandardThunk {
  return async () => {
    const formData = storedSurveyLevellingPlotFormData(values, storedPlotId);
    return await patchApiFormData(`/stored-plots/${storedPlotId}/`, formData);
  };
}

export function createStoredPlot(
  values: Model.PolymorphicStoredPlot_POST
): StandardThunk {
  return async () => {
    return postApi('/stored-plots/', values);
  };
}

export function updateStoredPlot(
  storedPlotId: number,
  values: Model.PolymorphicStoredPlot_POST
): StandardThunk {
  return async () => {
    return patchApi(`/stored-plots/${storedPlotId}/`, values);
  };
}
