import React from 'react';
import { ChecksheetFeature } from 'util/backendapi/types/Model';
import { Icon } from 'components/base/icon/icon';

interface ChecksheetFeatureObservationPointsProps {
  feature: ChecksheetFeature;
  areaCodes: string[];
}

export function ChecksheetFeatureObservationPoints(
  props: ChecksheetFeatureObservationPointsProps
) {
  const { feature, areaCodes } = props;

  return (
    <>
      {feature.observation_points.map((op, idx) => {
        let code = op.code;
        for (const areaCode of areaCodes) {
          // The WTK area does not consistently prefix observation point codes with WTK so it is excluded
          if (areaCode === 'WTK') continue;

          if (code.startsWith(areaCode)) {
            code = op.code.substring(areaCode.length);
            break;
          }
        }
        return (
          <span key={idx}>
            {op.reading_errors ? (
              <>
                <Icon
                  type="icon-exclamation-solid"
                  className="checksheet-icon-error"
                />{' '}
              </>
            ) : op.alarms ? (
              <>
                <Icon
                  type="icon-bell-solid"
                  className="checksheet-icon-alarm"
                />{' '}
              </>
            ) : null}
            {code}
            {idx !== feature.observation_points.length - 1 ? ', ' : null}
          </span>
        );
      })}
    </>
  );
}
