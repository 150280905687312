import {
  createStore,
  applyMiddleware,
  compose,
  Middleware,
  AnyAction,
} from 'redux';
import thunkMiddleware, { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { rootReducer, FullState, getDefaultInitialState } from './reducers';
import { save, load, LoadOptions, RLSOptions } from 'redux-localstorage-simple';
import { I18n } from '@lingui/core';
import { detectCliMode } from 'util/export';
import { authGlobalsMiddleware } from 'util/backendapi/auth-globals';

/**
 * The shape of the "extra argument" we pass to the thunk middleware.
 * This argument will then be passed as a third parameter to ever thunk
 * instance, so that they receive (dispatch, getState, ourExtraArg)
 */
export type ExtraThunkArg = {
  i18n: I18n;
};

/**
 * The type of our store's dispatch method.
 */
export type StandardDispatch = ThunkDispatch<
  FullState,
  ExtraThunkArg,
  AnyAction
>;

/**
 * The type of a thunk action.
 *
 * @example
 *
 * function doAThunk(): StandardThunk {
 *   // TS will automatically infer and apply the types for
 *   // dispatch, getState, and the third extra arg.
 *   return async function(dispatch, getState, {i18n}) {
 *     // do some thunky stuff.
 *   }
 * }
 */
export type StandardThunk<
  TReturnType = any,
  TFullState = FullState
> = ThunkAction<Promise<TReturnType>, TFullState, ExtraThunkArg, AnyAction>;

/**
 * The type of the rare non-asynchronous thunk. Did you know thunks don't have
 * to be asynchronous?
 */
export type SyncThunk<TReturnType = any, TFullState = FullState> = ThunkAction<
  TReturnType,
  TFullState,
  ExtraThunkArg,
  AnyAction
>;

export type StandardStore = ReturnType<typeof makeStore>;

/**
 * Given a Redux "duck" (module) that defines an ActionTypes const object with
 * its action types, and a ActionCreators const with its action creators
 * (and using the same keys as the ActionTypes), generates a TypeScript type
 * of all the Actions it can generate.
 *
 * Also, by taking TActionTypes and TActionCreators as its params, it validates
 * that TActionCreators does, in fact, provide an action creator for every
 * action type, and that each of them uses the correct `type` value in its
 * returned action object.
 *
 * @see src/ducks/storedList/list.ts for an example of a compatible duck.
 * @example
 *
 * export const ActionTypes = {
 *   DO_THING_START: 'dms/example/DO_THING_START',
 *   DO_THING_RESPONSE: 'dms/example/DO_THING_RESPONSE',
 *   DO_THING_ERROR: 'dms/example/DO_THING_ERROR'
 * } as const;
 *
 * export const ActionCreators = {
 *   DO_THING_START: () => ({type: ActionTypes.DO_THING_START}),
 *   DO_THING_RESPONSE: (values: number[]) => (
 *     {
 *       type: ActionTypes.DO_THING_RESPONSE,
 *       payload: values
 *     }
 *   ),
 *   DO_THING_ERROR: (e: string) => (
 *     {
 *       type: ActionTypes.DO_THING_ERROR,
 *       error: e
 *     }
 *   ),
 * } as const;
 *
 * export const ExampleAction = DuckAction<typeof ActionTypes, typeof ActionCreators>;
 */
export type DuckActions<
  TActionTypes extends { [key: string]: string },
  TActionCreators extends {
    [K in keyof TActionTypes]: (...params: any) => { type: TActionTypes[K] };
  }
> = ReturnType<TActionCreators[keyof TActionCreators]>;

/**
 * These are the sections of the redux state that we need to persist to when
 * you refresh the page (or otherwise flush the state without logging out)
 * in order to create a smooth user experience.
 */
export const LOCALSTORAGE_NAMESPACE = 'dms';
export const STATE_TO_PERSIST_ON_REFRESH: (keyof FullState)[] = [
  'auth',
  'user.activeAreaGroupId' as 'user',
  'ui',
];
export const STATE_TO_PERSIST_ON_LOGOUT: (keyof FullState)[] = ['ui'];

export default function makeStore(
  i18n: I18n,
  initialState?: FullState,
  othermiddleware: Middleware[] = []
) {
  const composeEnhancers =
    process.env.NODE_ENV === 'development' && !detectCliMode()
      ? ((window as any)
          .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose
      : compose;

  const localStorageSettings: LoadOptions & RLSOptions = {
    states: STATE_TO_PERSIST_ON_REFRESH as string[],
    namespace: LOCALSTORAGE_NAMESPACE,
  };

  const store = createStore<FullState, AnyAction, {}, {}>(
    rootReducer,
    initialState
      ? initialState
      : (load({
          ...localStorageSettings,
          preloadedState: getDefaultInitialState(),
          // Disable the warning that it gives when it first tries to load
          // the state on a new page where the state is currently empty.
          disableWarnings: true,
        }) as FullState),
    composeEnhancers(
      applyMiddleware(
        // Inject the current i18n instance, so that it will be available inside
        // the bodies of thunks.
        // (Note, we're passing it as a field of an object, rather than passing
        // it directly, so that we have room to pass further things if needed.)
        thunkMiddleware.withExtraArgument({ i18n }),
        save(localStorageSettings),
        authGlobalsMiddleware,
        ...othermiddleware
      )
    )
  );

  return store;
}
