import { Model } from 'util/backendapi/models/api.interfaces';
import { DuckActions, StandardThunk } from 'main/store';
import { errorToString } from 'util/backendapi/error';
import { getApi, patchApi, postApi } from 'util/backendapi/fetch';

export interface ChecksheetInstanceEditState {
  checksheetInstanceId: number | null;
  isLoading: boolean;
  errorMessage: string | null;
  data: null | {
    checksheetInstance: Model.ChecksheetInstance;
    checksheetTemplate: Model.ChecksheetTemplate;
    plotSet: Model.PlotSet;
  };
}

function initialChecksheetInstanceEditState(): ChecksheetInstanceEditState {
  return {
    checksheetInstanceId: null,
    isLoading: false,
    errorMessage: null,
    data: null,
  };
}

export const ActionTypes = {
  FETCH_CHECKSHEET_INSTANCE_START:
    'dms/checksheet-instance/edit/FETCH_CHECKSHEET_INSTANCE_START',
  FETCH_CHECKSHEET_INSTANCE_RESPONSE:
    'dms/checksheet-instance/edit/FETCH_CHECKSHEET_INSTANCE_RESPONSE',
  FETCH_CHECKSHEET_INSTANCE_ERROR:
    'dms/checksheet-instance/edit/FETCH_CHECKSHEET_INSTANCE_ERROR',
  UPDATE_CHECKSHEET_INSTANCE_START:
    'dms/checksheet-instance/edit/UPDATE_CHECKSHEET_INSTANCE_START',
  UPDATE_CHECKSHEET_INSTANCE_RESPONSE:
    'dms/checksheet-instance/edit/UPDATE_CHECKSHEET_INSTANCE_RESPONSE',
  UPDATE_CHECKSHEET_INSTANCE_SIMPLIFIED_RESPONSE:
    'dms/checksheet-instance/edit/UPDATE_CHECKSHEET_INSTANCE_SIMPLIFIED_RESPONSE',
  UPDATE_CHECKSHEET_INSTANCE_ERROR:
    'dms/checksheet-instance/edit/UPDATE_CHECKSHEET_INSTANCE_ERROR',
  UPDATE_DATA_SOURCES_START:
    'dms/checksheet-instance/edit/UPDATE_DATA_SOURCES_START',
  UPDATE_DATA_SOURCES_RESPONSE:
    'dms/checksheet-instance/edit/UPDATE_DATA_SOURCES_RESPONSE',
  UPDATE_DATA_SOURCES_ERROR:
    'dms/checksheet-instance/edit/UPDATE_DATA_SOURCES_ERROR',
} as const;

export const ActionCreators = {
  FETCH_CHECKSHEET_INSTANCE_START(checksheetInstanceId: number) {
    return {
      type: ActionTypes.FETCH_CHECKSHEET_INSTANCE_START,
      checksheetInstanceId,
    };
  },
  FETCH_CHECKSHEET_INSTANCE_RESPONSE(
    checksheetInstanceId: number,
    checksheetInstance: Model.ChecksheetInstance,
    checksheetTemplate: Model.ChecksheetTemplate,
    plotSet: Model.PlotSet
  ) {
    return {
      type: ActionTypes.FETCH_CHECKSHEET_INSTANCE_RESPONSE,
      checksheetInstanceId,
      payload: {
        checksheetInstance,
        checksheetTemplate,
        plotSet,
      },
    };
  },
  FETCH_CHECKSHEET_INSTANCE_ERROR(
    checksheetInstanceId: number,
    errorMessage: string
  ) {
    return {
      type: ActionTypes.FETCH_CHECKSHEET_INSTANCE_ERROR,
      error: true,
      payload: errorMessage,
      checksheetInstanceId,
    };
  },
  UPDATE_DATA_SOURCES_START(
    checksheetInstanceId: number,
    dataSource: Model.ChecksheetDataSource_PATCH[]
  ) {
    return {
      type: ActionTypes.UPDATE_DATA_SOURCES_START,
      checksheetInstanceId,
      dataSource,
    };
  },
  UPDATE_DATA_SOURCES_RESPONSE(
    checksheetInstanceId: number,
    dataSources: Model.ChecksheetDataSource[]
  ) {
    return {
      type: ActionTypes.UPDATE_DATA_SOURCES_RESPONSE,
      checksheetInstanceId,
      payload: {
        dataSources,
      },
    };
  },
  UPDATE_DATA_SOURCES_ERROR(
    checksheetInstanceId: number,
    errorMessage: string
  ) {
    return {
      type: ActionTypes.UPDATE_DATA_SOURCES_ERROR,
      error: true,
      payload: errorMessage,
      checksheetInstanceId,
    };
  },
  UPDATE_CHECKSHEET_INSTANCE_START(checksheetInstanceId: number) {
    return {
      type: ActionTypes.UPDATE_CHECKSHEET_INSTANCE_START,
      checksheetInstanceId,
    };
  },
  UPDATE_CHECKSHEET_INSTANCE_RESPONSE(
    checksheetInstanceId: number,
    checksheetInstance: Model.ChecksheetInstance
  ) {
    return {
      type: ActionTypes.UPDATE_CHECKSHEET_INSTANCE_RESPONSE,
      checksheetInstanceId,
      payload: checksheetInstance,
    };
  },
  UPDATE_CHECKSHEET_INSTANCE_SIMPLIFIED_RESPONSE(
    checksheetInstanceId: number,
    checksheetInstance: Model.SimpleChecksheetInstance
  ) {
    return {
      type: ActionTypes.UPDATE_CHECKSHEET_INSTANCE_SIMPLIFIED_RESPONSE,
      checksheetInstanceId,
      payload: checksheetInstance,
    };
  },
  UPDATE_CHECKSHEET_INSTANCE_ERROR(
    checksheetInstanceId: number,
    errorMessage: string
  ) {
    return {
      type: ActionTypes.UPDATE_CHECKSHEET_INSTANCE_ERROR,
      checksheetInstanceId,
      error: true,
      payload: errorMessage,
    };
  },
};

export type ChecksheetInstanceEditAction = DuckActions<
  typeof ActionTypes,
  typeof ActionCreators
>;

// NOTE: doesn't currently support changes to data_sources
function createUpdatedChecksheetInstance(
  oldChecksheetInstance: Model.ChecksheetInstance,
  updatedSimpleChecksheetInstance: Model.SimpleChecksheetInstance
) {
  const amendedFeatures = oldChecksheetInstance.features.map((feature) => {
    const updatedFeature = updatedSimpleChecksheetInstance.features.find(
      (f) => f.id === feature.id
    );
    return {
      ...feature,
      normal: updatedFeature!.normal,
      comment: updatedFeature!.comment,
      review_comment: updatedFeature!.review_comment,
    };
  });

  return {
    ...oldChecksheetInstance,
    status: updatedSimpleChecksheetInstance.status,
    features: amendedFeatures,
  };
}

export function checksheetInstanceEditReducer(
  state = initialChecksheetInstanceEditState(),
  action: ChecksheetInstanceEditAction
): ChecksheetInstanceEditState {
  // This is the only action that doesn't require to check that it's the
  // same checksheetInstanceId as currently in the state.
  if (action.type === ActionTypes.FETCH_CHECKSHEET_INSTANCE_START) {
    return {
      ...initialChecksheetInstanceEditState(),
      checksheetInstanceId: action.checksheetInstanceId,
      isLoading: true,
    };
  }

  // For all other actions, if they refer to a different checksheet instance
  // than the one we're currently looking at, ignore them.
  if (action.checksheetInstanceId !== state.checksheetInstanceId) {
    return state;
  }

  switch (action.type) {
    case ActionTypes.FETCH_CHECKSHEET_INSTANCE_ERROR:
      return {
        ...state,
        isLoading: false,
        data: null,
        errorMessage: action.payload,
      };
    case ActionTypes.FETCH_CHECKSHEET_INSTANCE_RESPONSE:
      return {
        ...state,
        isLoading: false,
        data: action.payload,
      };
    case ActionTypes.UPDATE_CHECKSHEET_INSTANCE_RESPONSE:
      return {
        ...state,
        data:
          state.data === null
            ? null
            : { ...state.data, checksheetInstance: action.payload },
      };
    case ActionTypes.UPDATE_CHECKSHEET_INSTANCE_SIMPLIFIED_RESPONSE:
      return {
        ...state,
        data:
          state.data === null
            ? null
            : {
                ...state.data,
                checksheetInstance: createUpdatedChecksheetInstance(
                  state.data.checksheetInstance,
                  action.payload
                ),
              },
      };
    case ActionTypes.UPDATE_DATA_SOURCES_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload,
      };
    case ActionTypes.UPDATE_DATA_SOURCES_RESPONSE:
      if (state.data) {
        const dataSources = [...state.data.checksheetInstance.data_sources];
        action.payload.dataSources.forEach((newDs) => {
          const updatedDataSourceIndex = dataSources.findIndex(
            (ds) => ds.id === newDs.id
          );
          if (updatedDataSourceIndex !== -1) {
            dataSources[updatedDataSourceIndex] = newDs;
          } else {
            dataSources.push(newDs);
          }
        });
        return {
          ...state,
          isLoading: false,
          data: {
            ...state.data,
            checksheetInstance: {
              ...state.data.checksheetInstance,
              data_sources: dataSources,
            },
          },
        };
      } else {
        return {
          ...state,
          isLoading: false,
          data: null,
        };
      }
    default:
      return state;
  }
}

export function fetchChecksheetInstance(
  checksheetInstanceId: number
): StandardThunk {
  return async function (dispatch) {
    dispatch(
      ActionCreators.FETCH_CHECKSHEET_INSTANCE_START(checksheetInstanceId)
    );
    try {
      const checksheetInstance = await getApi(
        `/checksheet-instances/${checksheetInstanceId}/`
      );

      const checksheetTemplate = await getApi(
        `/checksheet-templates/${checksheetInstance.template}/`
      );

      const plotSet = await getApi(
        `/plot-sets/${checksheetTemplate.plot_set}/`
      );

      dispatch(
        ActionCreators.FETCH_CHECKSHEET_INSTANCE_RESPONSE(
          checksheetInstanceId,
          checksheetInstance,
          checksheetTemplate,
          plotSet
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.FETCH_CHECKSHEET_INSTANCE_ERROR(
          checksheetInstanceId,
          errorToString(e)
        )
      );
    }
  };
}

export function updateChecksheetInstance(
  checksheetInstanceId: number,
  checksheetInstance: Model.ChecksheetInstance_PATCH
): StandardThunk {
  return async function (dispatch) {
    dispatch(
      ActionCreators.UPDATE_CHECKSHEET_INSTANCE_START(checksheetInstanceId)
    );
    try {
      const updatedChecksheetInstance = await patchApi(
        `/checksheet-instances/${checksheetInstanceId}/`,
        checksheetInstance
      );

      dispatch(
        ActionCreators.UPDATE_CHECKSHEET_INSTANCE_RESPONSE(
          checksheetInstanceId,
          updatedChecksheetInstance
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.UPDATE_CHECKSHEET_INSTANCE_ERROR(
          checksheetInstanceId,
          errorToString(e)
        )
      );
      // Because this action is dispatched by a form submission, throw the
      // error onwards so it can be handled and displayed by Formik.
      throw e;
    }
  };
}

/*
  Update checksheet instance with a simplified serializer response for better performance.

  Changes to the checksheet instance in the response are amended to the existing checksheet instance in state.

  Only certain changes are currently supported:
   * status
   * feature changes to:
     - normal
     - comment
     - review_comment
*/
export function updateChecksheetInstanceSimplified(
  checksheetInstanceId: number,
  checksheetInstance: Model.ChecksheetInstance_PATCH
): StandardThunk {
  return async function (dispatch) {
    dispatch(
      ActionCreators.UPDATE_CHECKSHEET_INSTANCE_START(checksheetInstanceId)
    );
    try {
      const simpleChecksheetInstance = await patchApi(
        `/checksheet-instances-simple/${checksheetInstanceId}/`,
        checksheetInstance
      );

      dispatch(
        ActionCreators.UPDATE_CHECKSHEET_INSTANCE_SIMPLIFIED_RESPONSE(
          checksheetInstanceId,
          simpleChecksheetInstance
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.UPDATE_CHECKSHEET_INSTANCE_ERROR(
          checksheetInstanceId,
          errorToString(e)
        )
      );
      // Because this action is dispatched by a form submission, throw the
      // error onwards so it can be handled and displayed by Formik.
      throw e;
    }
  };
}

export function updateChecksheetDataSources(
  checksheetInstanceId: number,
  checksheetDataSources: Model.ChecksheetDataSource_PATCH[]
): StandardThunk {
  return async function (dispatch) {
    dispatch(
      ActionCreators.UPDATE_DATA_SOURCES_START(
        checksheetInstanceId,
        checksheetDataSources
      )
    );
    try {
      const updatedAndNewDataSources: Model.ChecksheetDataSource[] =
        await Promise.all(
          checksheetDataSources.map(async (checksheetDataSource) => {
            if (checksheetDataSource.id) {
              return await patchApi(
                `/checksheet-data-sources/${checksheetDataSource.id}/`,
                checksheetDataSource
              );
            } else {
              return await postApi(
                '/checksheet-data-sources/',
                checksheetDataSource
              );
            }
          })
        );
      dispatch(
        ActionCreators.UPDATE_DATA_SOURCES_RESPONSE(
          checksheetInstanceId,
          updatedAndNewDataSources
        )
      );
    } catch (e) {
      dispatch(
        ActionCreators.UPDATE_DATA_SOURCES_ERROR(
          checksheetInstanceId,
          errorToString(e)
        )
      );
      throw e;
    }
  };
}
