import React from 'react';
import sortBy from 'lodash/sortBy';
import { ModalContentProps } from 'components/base/modal/buttonshowmodal';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useShallowEqualSelector } from 'util/hooks';
import { FullState } from 'main/reducers';
import {
  ScatterPlotSettingsView,
  ScatterPlotSettingsViewProps,
} from './ScatterPlotSettingsView';
import { getTimeZoneFromPlotMetadata } from 'components/plots/timeseriesplotselectors';
import { getApi } from 'util/backendapi/fetch';
import { convertDateToDatetime, START_OF_DAY, END_OF_DAY } from 'util/dates';
import {
  splitObsPointItemIdent,
  makeObsPointItemMenuOption,
} from 'components/modules/obs-point-item-menu/ObsPointItemMenu';
import { encodeScalesConfigForUrl } from 'screens/quickplot/quickplotselectors';
import { ScatterPlotSettingsFormValue } from 'components/plots/settingsform.types';
import { ScatterPlotSettings } from 'components/plots/timeseriesplot.types';
import { OBSERVATION_POINT_AUTOCOMPLETE_LIMIT } from 'components/modules/async-menu/ObsPointMenu';

interface OwnProps {
  hideModal: ModalContentProps['hideModal'];
}
interface UrlMatchProps {
  op_x?: string;
  op_y?: string;
  item_x?: string;
  item_y?: string;
}
type RouterProps = RouteComponentProps<UrlMatchProps>;

type Props = OwnProps & RouterProps;

/**
 * Function to fetch the initial menu selections in the "observation points"
 * menu, based on the obs point item strings (from the URL)
 *
 * @param initialValues
 */
const loadDefaultObsPointItems: ScatterPlotSettingsViewProps['loadDefaultObsPointItems'] =
  async (initialValue) => {
    const pair = splitObsPointItemIdent(initialValue);
    if (!pair) {
      return [];
    }

    const op = await getApi(
      `/observation-points/${pair.observation_point}/`
    ).catch(() => null);

    return [makeObsPointItemMenuOption(op ? [op] : [], pair)];
  };

/**
 * Function to asynchronously search for observation points matching the
 * user's input, in the observation points menu.
 *
 * @param inputString
 */
const onSearchObsPointItems: ScatterPlotSettingsViewProps['onSearchObsPointItems'] =
  async (inputString) => {
    const obsPoints = sortBy(
      await getApi('/observation-points/', {
        code__icontains: inputString,
        fields: ['id', 'code', 'time_zone', 'instrument_type'],
        limit: OBSERVATION_POINT_AUTOCOMPLETE_LIMIT,
      }),
      (op) => op.code
    );
    return obsPoints.flatMap((op) =>
      sortBy(op.instrument_type.items, (item) => item.item_number).map(
        ({ item_number }) =>
          makeObsPointItemMenuOption(obsPoints, {
            observation_point: op.id,
            item_number,
          })
      )
    );
  };

const formValuesToUrl = (
  values: ScatterPlotSettingsFormValue,
  timeZone: string | null
) => {
  const {
    selectionX,
    selectionXDetails,
    xAxisScale,
    selectionY,
    selectionYDetails,
    yAxisScale,
    startDate,
    endDate,
    interpolate,
    plotmarks,
    markconnections,
  } = values;

  if (
    !selectionX ||
    !selectionY ||
    !selectionXDetails.length ||
    !selectionYDetails.length
  ) {
    return '';
  }

  const params = new URLSearchParams();
  let qs = '';

  if (startDate) {
    params.set(
      'startDatetime',
      convertDateToDatetime(startDate, timeZone, START_OF_DAY)
    );
  }

  if (endDate) {
    params.set(
      'endDatetime',
      convertDateToDatetime(endDate, timeZone, END_OF_DAY)
    );
  }

  const axes = encodeScalesConfigForUrl(
    [yAxisScale, xAxisScale].filter((scale) => scale.mode !== 'auto')
  );

  if (axes) {
    params.set('axes', axes);
  }
  if (values.numberOfMonths) {
    params.set('numberOfMonths', String(values.numberOfMonths));
  }

  if (!interpolate) {
    params.set('interpolate', 'false');
  }

  if (!plotmarks) {
    params.set('plotmarks', 'false');
  }

  if (!markconnections) {
    params.set('markconnections', 'false');
  }

  if (params.toString()) {
    qs = '?' + params.toString();
  }

  const codeX = selectionXDetails[0].observationPoint!.code;
  const itemX = splitObsPointItemIdent(selectionX)!.item_number;
  const codeY = selectionYDetails[0].observationPoint!.code;
  const itemY = splitObsPointItemIdent(selectionY)!.item_number;

  return `${encodeURIComponent(codeY)}/${itemY}/${encodeURIComponent(
    codeX
  )}/${itemX}${qs}`;
};

function ScatterPlotSettingsModalInner(props: Props) {
  const { resolvedSettings, isLoading } = useShallowEqualSelector(function (
    state: FullState
  ) {
    return {
      resolvedSettings: state.plot.scatterTimeSeries.plots.quickScatterPlot
        .resolved as ScatterPlotSettings,
      isLoading: state.plot.scatterTimeSeries.plots.quickScatterPlot.isLoading,
    };
  });

  /**
   * When the user submits the settings form, we add the new settings to the URL,
   * which in turn will cause the plot to update.
   */
  const handleSubmit = React.useCallback(
    (values: ScatterPlotSettingsFormValue) => {
      const timeZone = getTimeZoneFromPlotMetadata(resolvedSettings);
      const urlFromSubmitted = formValuesToUrl(values, timeZone);

      props.history.push(`/scatterplot/${urlFromSubmitted}`);

      props.hideModal.call(null);
    },
    [props.history, props.hideModal, resolvedSettings]
  );

  return (
    <ScatterPlotSettingsView
      resolvedSettings={resolvedSettings}
      isLoading={isLoading}
      onSubmit={handleSubmit}
      loadDefaultObsPointItems={loadDefaultObsPointItems}
      onSearchObsPointItems={onSearchObsPointItems}
    />
  );
}

export const ScatterPlotSettingsModal = withRouter(
  ScatterPlotSettingsModalInner
) as React.ComponentClass<OwnProps> & {
  WrappedComponent: typeof ScatterPlotSettingsModalInner;
};

ScatterPlotSettingsModal.WrappedComponent = ScatterPlotSettingsModalInner;
