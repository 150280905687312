import React from 'react';
import { Trans } from '@lingui/macro';
import ActionBlock from 'components/base/actionblock/actionblock';
import { ButtonPrimary } from 'components/base/button/button';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { SimpleSelectField } from 'components/base/form/simpleselect/simpleselectfield';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ModalContent from 'components/base/modal/modalcontent';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { components, MultiValueGenericProps } from 'react-select';
import { Model } from 'util/backendapi/models/api.interfaces';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';

export type GroupFormValues = Optional<Model.AreaGroup, 'id'>;

const validate = (values: GroupFormValues) => {
  const errors: any = {};

  if (!values.name.trim()) {
    errors.name = <Trans>Name is required.</Trans>;
  }

  if (!values.areas || !values.areas.length) {
    errors.areas = <Trans>Areas is required.</Trans>;
  }

  return errors;
};

const MultiValueLabelCustom = (props: MultiValueGenericProps<AreaOption>) => {
  return (
    <div title={props.data.label}>
      <components.MultiValueLabel {...props}>
        {props.data.code}
      </components.MultiValueLabel>
    </div>
  );
};

export interface AreaOption {
  value: number;
  code: string;
  label: string;
}

export interface Props {
  group: Optional<Model.AreaGroupDecorated, 'id'>;
  areaOptions: AreaOption[];
  onSubmit: (values: any, formikProps: any) => void;
}

const MaintainGroupModalView = (props: Props) => {
  const initialValues: GroupFormValues = {
    ...props.group,
    areas: props.group.areas.map((area: Model.Area) => area.id),
  };
  return (
    <ModalContent
      header={
        props.group.id ? (
          <Trans>Change group</Trans>
        ) : (
          <Trans>Create a group</Trans>
        )
      }
    >
      <Formik
        initialValues={initialValues}
        onSubmit={props.onSubmit}
        validate={validate}
      >
        {({ isSubmitting }) => (
          <Form>
            <fieldset>
              <legend>
                <Trans>Group details</Trans>
              </legend>

              <div className="form-group">
                <label htmlFor="maintgroup-name">
                  <Trans>Group name</Trans>
                </label>
                <Field name="name" id="maintgroup-name" />
                <ErrorMessage name="name" component={ErrorNotice} />
              </div>

              <div className="form-group">
                <label htmlFor="maintgroup-areas">
                  <Trans>Areas</Trans>
                </label>
                <SimpleSelectField
                  name="areas"
                  id="maintgroup-areas"
                  placeholder="Choose areas"
                  components={{
                    MultiValueLabel: MultiValueLabelCustom as (
                      props: MultiValueGenericProps<SimpleSelectOption<number>>
                    ) => JSX.Element,
                  }}
                  options={props.areaOptions}
                  isMulti
                />
                <ErrorMessage name="areas" component={ErrorNotice} />
              </div>
            </fieldset>
            <ActionBlock>
              <ButtonHideModal />
              {props.group.id ? (
                <ButtonPrimary
                  id="maintgroup-change"
                  type="submit"
                  disabled={isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Save</Trans>
                </ButtonPrimary>
              ) : (
                <ButtonPrimary
                  id="maintgroup-create"
                  type="submit"
                  disabled={isSubmitting}
                  iconType="icon-save"
                >
                  <Trans>Create group</Trans>
                </ButtonPrimary>
              )}
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};

export default MaintainGroupModalView;
