import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LOCALSTORAGE_NAMESPACE } from 'main/store';
import { setAuthTokens, AuthState } from 'ducks/login';
import { GlobalActionCreators } from 'ducks/global';

/**
 * A logical component that watches for Storage events that indicate that another
 * tab has refreshed our auth token or logged out. When this happens, it puts
 * the new auth token values into the Redux store.
 */
export function TokenSyncManager() {
  // Set a listener to update our tokens to keep them in sync with localstorage,
  // in case another tab refreshes the auth tokens.
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (e: StorageEvent) => {
      const myKey = `${LOCALSTORAGE_NAMESPACE}_auth`;

      // A change in an unrelated key
      if (e.key !== null && e.key !== myKey) {
        return;
      }

      // LocalStorage has been cleared, or our LS entry has been deleted
      if (e.key === null || e.newValue === null) {
        dispatch(GlobalActionCreators.CLEAR_STATE());
        return;
      }

      try {
        // redux-localstorage-simple saves the auth state encoded as JSON
        const auth: AuthState = JSON.parse(e.newValue);

        // Make sure the new state doesn't have NULL auth tokens
        if (auth.accessToken && auth.refreshToken && auth.tokenExpiryTime) {
          dispatch(setAuthTokens(auth));
          return;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('Could not sync value from local storage', e.newValue);
      }

      // The LS entry didn't contain parseable JSON, or it had null auth tokens.
      dispatch(GlobalActionCreators.CLEAR_STATE());
    };

    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    };
  }, [dispatch]);

  // Logical component; renders no content
  return null;
}
