import React from 'react';
import { connect, ResolveThunks } from 'react-redux';
import {
  fetchEntityList,
  EntityTypes,
  selectAllInOrderedArray,
} from 'ducks/entities';
import { createSelector } from 'reselect';
import {
  SiteDetailView,
  SiteDetailViewProps,
  SiteDetailFormValues,
} from 'screens/site/detail/SiteDetailView';
import {
  fetchSite,
  updateSite,
  createSite,
  unmountMaintainSiteScreen,
} from '../../../ducks/site/detail';
import {
  parseStringParamFromRouterProps,
  RCPWithQueryParams,
  parseNumberQueryParamFromRouterProps,
  setOneQueryParam,
  currentQueryString,
} from 'util/routing';
import { FullState } from 'main/reducers';
import { selectHasPermission } from 'util/user';
import { User_PERMISSION } from 'util/backendapi/types/Enum';
import { FormikHelpers } from 'formik';

const selectAreaOptions = createSelector(
  (state: FullState) => selectAllInOrderedArray(state, EntityTypes.AREA),
  (itemList) => {
    return itemList
      ? itemList.map(({ id, code, name }) => ({
          value: id,
          label: `${code} - ${name}`,
          code,
        }))
      : null;
  }
);

type OwnProps = RCPWithQueryParams<{ edit?: 0 | 1 }, { siteCode: string }>;
type StateProps = Pick<
  SiteDetailViewProps,
  | 'site'
  | 'areaOptions'
  | 'isLoading'
  | 'isSubmitting'
  | 'fetchErrorMessage'
  | 'isEditing'
  | 'hasEditPermission'
> & { key: string; siteCode: string };

const mapDispatchToProps = {
  fetchSite,
  updateSite,
  createSite,
  fetchEntityList,
  unmountMaintainSiteScreen,
};

type DispatchProps = typeof mapDispatchToProps;

type Props = OwnProps & StateProps & ResolveThunks<DispatchProps>;
class InnerSiteDetailScreen extends React.Component<Props> {
  _isMounted: boolean = false;

  static mapStateToProps(state: FullState, ownProps: OwnProps): StateProps {
    const siteCode = parseStringParamFromRouterProps(ownProps, 'siteCode');
    return {
      siteCode,
      key: `site-${siteCode}`,
      site: state.site.detail.site,
      areaOptions: selectAreaOptions(state),
      isEditing: Boolean(
        parseNumberQueryParamFromRouterProps(ownProps, 'edit', false)
      ),
      isLoading: state.site.detail.isLoading,
      isSubmitting: state.site.detail.isSubmitting,
      fetchErrorMessage: state.site.detail.fetchErrorMessage,
      hasEditPermission: selectHasPermission(
        state,
        User_PERMISSION.can_create_sites
      ),
    };
  }

  static mapDispatchToProps = mapDispatchToProps;

  handleStartEditing = () => setOneQueryParam(this.props, 'edit', 1);

  handleStopEditing = () => {
    if (this.props.site) {
      setOneQueryParam(this.props, 'edit', null);
    } else {
      this.props.history.push(`/sites/`);
    }
  };

  handleSubmit = async (
    values: SiteDetailFormValues,
    actions: FormikHelpers<SiteDetailFormValues>
  ) => {
    const siteCode = parseStringParamFromRouterProps(this.props, 'siteCode');
    const shouldChangeUrl = values.code !== siteCode;

    if (this.props.site) {
      await this.props.updateSite(this.props.site.id, values);
    } else {
      await this.props.createSite(values);
    }

    if (!this._isMounted) return false;

    actions.setSubmitting(false);

    if (shouldChangeUrl) {
      const qs = currentQueryString(this.props.location, { exclude: ['edit'] });
      this.props.history.replace(
        `/sites/${encodeURIComponent(values.code)}${qs}`
      );
    } else {
      setOneQueryParam(this.props, 'edit', null);
    }

    return true;
  };

  render() {
    return (
      <SiteDetailView
        isEditing={this.props.isEditing && this.props.hasEditPermission}
        isLoading={this.props.isLoading}
        isSubmitting={this.props.isSubmitting}
        fetchErrorMessage={this.props.fetchErrorMessage}
        onStartEditing={this.handleStartEditing}
        onStopEditing={this.handleStopEditing}
        onSubmit={this.handleSubmit}
        site={this.props.site}
        areaOptions={this.props.areaOptions || []}
        hasEditPermission={this.props.hasEditPermission}
      />
    );
  }

  componentDidMount() {
    if (this.props.siteCode && this.props.siteCode !== 'new') {
      this.props.fetchSite(this.props.siteCode);
    }
    this.props.fetchEntityList(EntityTypes.AREA);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.unmountMaintainSiteScreen();
  }
}

export const SiteDetailScreen = connect(
  InnerSiteDetailScreen.mapStateToProps,
  InnerSiteDetailScreen.mapDispatchToProps
)(InnerSiteDetailScreen);
