import React from 'react';
import ModalContent from '../../../base/modal/modalcontent';
import { Trans } from '@lingui/macro';
import { Formik, FormikProps, FormikErrors, Form, Field } from 'formik';
import { Model } from '../../../../util/backendapi/models/api.interfaces';
import ActionBlock from '../../../base/actionblock/actionblock';
import ButtonHideModal from '../../../base/modal/buttonhidemodal';
import { ButtonPrimary } from '../../../base/button/button';

export interface Props {
  activeAreaGroupId: number;
  areaGroups: Model.AreaGroupDecorated[];
  onSubmit: (areaGroupId: number) => void;
}

interface MyFormValues {
  areaGroupId: string;
}
type MyFormikProps = FormikProps<MyFormValues>;
type MyFormikErrors = FormikErrors<MyFormValues>;

export class SwitchGroupsModalView extends React.Component<Props> {
  handleSubmit = (values: MyFormValues): void => {
    this.props.onSubmit(Number(values.areaGroupId));
  };

  validate(values: MyFormValues): MyFormikErrors {
    let errors: MyFormikErrors = {};
    if (!values.areaGroupId) {
      errors.areaGroupId = (<Trans>Please select a group</Trans>) as any;
    }
    return errors;
  }

  render() {
    const initialValues: MyFormValues = {
      areaGroupId: String(this.props.activeAreaGroupId),
    };
    const activeAreaGroup = this.props.areaGroups.find(
      (ag) => ag.id === this.props.activeAreaGroupId
    );
    const activeAreaGroupName = activeAreaGroup ? (
      activeAreaGroup.name
    ) : (
      <Trans>[Area {this.props.activeAreaGroupId}]</Trans>
    );
    return (
      <ModalContent header={<Trans>Switch group</Trans>}>
        <Formik
          initialValues={initialValues}
          validate={this.validate}
          onSubmit={this.handleSubmit}
        >
          {(formik: MyFormikProps) => {
            return (
              <Form>
                <p>
                  <strong>
                    <Trans>Current group: {activeAreaGroupName}</Trans>
                  </strong>
                </p>
                <div className="radio-group-wrapper">
                  {this.props.areaGroups.map((area_group) => {
                    const isSelectedInForm =
                      String(area_group.id) === formik.values.areaGroupId;
                    return (
                      <div key={area_group.id} className="radio-group">
                        <Field
                          id={`radio-switch-group-${area_group.id}`}
                          type="radio"
                          name="areaGroupId"
                          value={String(area_group.id)}
                          checked={isSelectedInForm}
                        />
                        <label htmlFor={`radio-switch-group-${area_group.id}`}>
                          {area_group.name}
                        </label>
                      </div>
                    );
                  })}
                </div>
                <ActionBlock>
                  <ButtonHideModal />
                  <ButtonPrimary
                    id="switchgroup-submit"
                    type="submit"
                    iconType="icon-switch"
                  >
                    <Trans>Switch group</Trans>
                  </ButtonPrimary>
                </ActionBlock>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    );
  }
}
