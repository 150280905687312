import React from 'react';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { useReportState } from 'hooks/use-report-state';
import {
  InstrumentTypeListView,
  InstrumentTypeListColumns,
  InstrumentTypeListFilters,
} from './InstrumentTypeListView';
import { instrumentTypeListDuck } from 'ducks/instrument-type/list';

type OwnProps = RCPWithQueryParams<Filter.ReportsSites>;

export const InstrumentTypeListScreen = (ownProps: OwnProps) => {
  const props = {
    reportProps: useReportState(
      ownProps,
      InstrumentTypeListColumns,
      InstrumentTypeListFilters,
      instrumentTypeListDuck,
      (state) => state.instrumentType.list
    ),
  };

  return <InstrumentTypeListView {...props} />;
};
