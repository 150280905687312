import React from 'react';
import { usePlotState } from 'hooks/use-plot-state';
import { getTimeZoneFromPlotMetadata } from 'components/plots/timeseriesplotselectors';
import { StoredPlotItem } from 'util/backendapi/types/Model';
import { PlotType } from 'util/backendapi/types/Enum';
import { formatDatetimeForDisplay } from 'util/dates';
import Loading from 'components/base/loading/loading';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import {
  ScatterPlot,
  ScatterPlotReadingSeries,
} from 'components/plots/ScatterPlot';
import {
  buildStoredPlotKey,
  PlotSettingsAxis,
  ScatterPlotAxisSide,
} from './timeseriesplot.types';
import { HighlightArea } from 'react-vis';

interface StoredScatterPlotItemProps {
  storedPlotId: number;
  plotItem: StoredPlotItem;
  zoom?: PlotSettingsAxis<ScatterPlotAxisSide>[];
  onZoom: (area: HighlightArea | null) => void;
}

export const StoredScatterPlotItem = (props: StoredScatterPlotItemProps) => {
  const plotState = usePlotState(
    buildStoredPlotKey(props.storedPlotId, props.plotItem.id),
    PlotType.SCATTER
  );

  const isZoomed = Boolean(props.zoom);
  const timeZone = getTimeZoneFromPlotMetadata(plotState.resolvedSettings);
  const displayedDateRange = !isZoomed &&
    plotState.minDatetime &&
    plotState.maxDatetime &&
    timeZone && (
      <p data-testid="plot-date-range">
        {formatDatetimeForDisplay(plotState.minDatetime, timeZone)} -{' '}
        {formatDatetimeForDisplay(plotState.maxDatetime, timeZone)}
      </p>
    );

  return (
    <div className="plot-item plot-item-scatter">
      <div className="plot-item-detail">
        <h3 className="plot-item-header">{props.plotItem.name}</h3>
        <p>{props.plotItem.description}</p>
        {displayedDateRange}
      </div>
      {plotState.errorMessage ? (
        <ErrorNotice>{plotState.errorMessage}</ErrorNotice>
      ) : null}
      {plotState.isLoading ? (
        <Loading />
      ) : (
        <ScatterPlot
          timeZone={timeZone}
          annotations={props.plotItem.annotations}
          x={
            plotState.readingsSeries[1]?.readings
              ? (plotState.readingsSeries[1] as ScatterPlotReadingSeries)
              : null
          }
          y={
            plotState.readingsSeries?.[0]?.readings
              ? (plotState.readingsSeries[0] as ScatterPlotReadingSeries)
              : null
          }
          yObservationPointCode={
            plotState.readingsSeries[0]
              ? plotState.readingsSeries[0].observationPoint.code
              : ''
          }
          xObservationPointCode={
            plotState.readingsSeries[1]
              ? plotState.readingsSeries[1].observationPoint.code
              : ''
          }
          interpolate={Boolean(
            plotState.resolvedSettings && plotState.resolvedSettings.interpolate
          )}
          showPlotMarks={Boolean(
            plotState.resolvedSettings &&
              plotState.resolvedSettings.show_plot_markers
          )}
          showMarkConnections={Boolean(
            plotState.resolvedSettings &&
              plotState.resolvedSettings.show_mark_connections
          )}
          axes={props.zoom ?? plotState.resolvedSettings?.axes ?? []}
          highlightPeriods={
            plotState.resolvedSettings
              ? plotState.resolvedSettings.highlight_periods
              : []
          }
          onZoom={props.onZoom}
        />
      )}
    </div>
  );
};
