import React from 'react';
import lodashGet from 'lodash/get';
import { Trans, t } from '@lingui/macro';
import { Field, FieldProps, ErrorMessage } from 'formik';
import {
  IntervalField,
  IntervalFieldValue,
} from 'components/base/form/interval/intervalfield';
import {
  DateField,
  DateFieldValue,
} from 'components/base/form/datefield/datefield';
import { I18n } from '@lingui/react';
import ErrorNotice from 'components/base/form/errornotice/errornotice';
import { ReportFilter, FilterRenderingInfo } from '../../report-types';
import { parseQueryParamFromRouterProps } from 'util/routing';
import { RouteChildrenProps, RouteComponentProps } from 'react-router';
import {
  formatDatetimeForBackendUrl,
  convertDateToDatetime,
  START_OF_DAY,
  END_OF_DAY,
  formatIntervalForDisplay,
  formatDateForDisplay,
} from 'util/dates';

const dateFilterLabels = {
  is: t`is`,
  is_after: t`is after`,
  is_before: t`is before`,
  is_between: t`is between`,
  is_within_last: t`is within the last`,
  is_within_next: t`is within the next`,
  is_blank: t`is blank`,
} as const;

export type DateFilterType = keyof typeof dateFilterLabels;

/**
 * Configure a filter on a date field, for a reports API table
 *
 * @param name
 * @param label
 */
export function reportFilterDate(
  name: string,
  label: React.ReactNode,
  types?: DateFilterType[]
): ReportFilter {
  const defaultFormValue = { type: 'is', range: '' } as const;

  function _parseFromFrontendUrl(
    routeProps: RouteComponentProps | RouteChildrenProps
  ): ReportDateFilterFieldValue | null {
    const rawFromUrl = parseQueryParamFromRouterProps(
      routeProps,
      name,
      undefined
    );
    if (rawFromUrl === undefined) {
      return null;
    }

    if (rawFromUrl === 'is_blank') {
      return { type: 'is_blank', range: '' };
    }

    const match = rawFromUrl.match(/^([^.]*)\.(.*)$/);
    if (!match || match.length !== 3) {
      return null;
    }
    const [, type, value] = match as [any, DateFilterType, string];
    if (!(type in dateFilterLabels)) {
      return null;
    }

    switch (type) {
      case 'is':
      case 'is_after':
      case 'is_before':
        return { type, range: value };
      case 'is_within_last':
      case 'is_within_next':
        return { type, range: value };
      case 'is_between':
        return { type, range: value.split('.', 2) as [string, string] };
      default:
        return null;
    }
  }

  return {
    name,
    label,
    render() {
      return <ReportDateFilterField name={name} types={types} />;
    },
    renderSSR(info: FilterRenderingInfo) {
      return (
        <I18n>
          {({ i18n }) => {
            let display = '';
            const { type, range } = info.value;
            if (['is_within_last', 'is_within_next'].includes(type)) {
              display = i18n._(formatIntervalForDisplay(range));
            } else if (type === 'is_between') {
              const [start, end] = range as [string, string];
              display = `${formatDateForDisplay(start)}.${formatDateForDisplay(
                end
              )}`;
            } else if (['is_after', 'is_before', 'is'].includes(type)) {
              display = formatDateForDisplay(range);
            } else {
              display = range;
            }

            return (
              <span>
                {i18n._(dateFilterLabels[info.value.type as DateFilterType])}{' '}
                {display}
              </span>
            );
          }}
        </I18n>
      );
    },
    getFormValFromUrlParam(routeProps): ReportDateFilterFieldValue {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      if (!valueFromUrl) {
        return defaultFormValue;
      } else {
        return valueFromUrl;
      }
    },
    getUrlParamFromFormVal(formValues) {
      const formVal = formValues[name];
      if (!formVal) {
        return { [name]: null };
      }
      const type = formVal.type as DateFilterType;
      switch (type) {
        case 'is_blank':
          return { [name]: 'is_blank' };
        case 'is_between': {
          const [start, end] = formVal.range as [string, string];
          if (start === '' || end === '') {
            return { [name]: null };
          }
          return { [name]: `is_between.${start}.${end}` };
        }
        case 'is':
        case 'is_after':
        case 'is_before': {
          const range = formVal.range as DateFieldValue;
          if (!range) {
            return { [name]: null };
          }
          return { [name]: `${type}.${range}` };
        }
        case 'is_within_last':
        case 'is_within_next': {
          const range = formVal.range as IntervalFieldValue;
          if (!range) {
            return { [name]: null };
          }
          return { [name]: `${type}.${range}` };
        }
        default:
          return {};
      }
    },
    getBackendFilterFromUrl(routeProps) {
      const valueFromUrl = _parseFromFrontendUrl(routeProps);
      if (!valueFromUrl) {
        return null;
      }
      switch (valueFromUrl.type) {
        case 'is':
          return { [name]: valueFromUrl.range };
        case 'is_after':
          return { [`${name}__gte`]: valueFromUrl.range };
        case 'is_before':
          return { [`${name}__lte`]: valueFromUrl.range };
        case 'is_between':
          if (valueFromUrl.range.length !== 2) {
            return null;
          }
          return {
            [`${name}__gte`]: valueFromUrl.range[0],
            [`${name}__lte`]: valueFromUrl.range[1],
          };
        case 'is_within_last':
          return { [`${name}__withinlast`]: valueFromUrl.range };
        case 'is_within_next':
          return { [`${name}__withinnext`]: valueFromUrl.range };
        case 'is_blank':
          return { [`${name}__isnull`]: 'True' };
        default:
          return null;
      }
    },
  };
}

export const NO_TIMEZONE = 'NO TIMEZONE' as const;

/**
 * Configure a filter on a datetime field, for a report API table. (The UI
 * for this is the same as a date field, but the filter params sent to the
 * backend need to be datetimes without time zone, rather than just dates.)
 * @param name
 * @param label
 * @param timezone The timezone of the user's input. This will (normally) be
 * converted to UTC before being sent as a filter value to the back end.
 *
 * Default value of `null` indicates the user's current timezone.
 *
 * The special value `NO_TIMEZONE` indicates that we want to pass it unchanged
 * to the back end, because the back end will use it for a special multi-timezone
 * filter.
 */
export function reportFilterDatetime(
  name: string,
  label: React.ReactNode,
  timezone: string | typeof NO_TIMEZONE | null = null,
  types?: DateFilterType[]
): ReportFilter {
  // Apply no timezone conversion to the user's input, because the backend will
  // use it for a multi-timezone filter (called `DamTimeFilter` on the backend)
  if (timezone === NO_TIMEZONE) {
    // ... to avoid any timezone conversion, we pretend the datetime is already in UTC.
    timezone = 'Etc/UTC';
  }

  // The datetime filter is almost the same as the date filter (because the
  // frontend for it is based on dates.) But it needs additional logic
  // when setting up the backend filters, because those do need to be datetimes.
  const sharedWithDateFilter = reportFilterDate(name, label, types);
  return {
    ...sharedWithDateFilter,
    getBackendFilterFromUrl(routeProps) {
      // Convert from the frontend's dates (without datetime) to the required
      // datetime (without timezone) for the backend. The backend will
      // take care of picking the appropriate time zone for each row, so all
      // we do on the frontend is add the "Time of day".
      const filters = sharedWithDateFilter.getBackendFilterFromUrl(routeProps);
      if (filters) {
        // For an "is" filter, turn it into a range filter to encompass all
        // datetimes in the day.
        if (filters[`${name}`]) {
          filters[`${name}__gte`] = filters[`${name}`];
          filters[`${name}__lte`] = filters[`${name}`];
          delete filters[name];
        }
        // Add start of day as time-of-day for "greater than or equal" filter.
        if (filters[`${name}__gte`]) {
          filters[`${name}__gte`] = formatDatetimeForBackendUrl(
            convertDateToDatetime(
              filters[`${name}__gte`],
              timezone,
              START_OF_DAY
            )
          );
        }
        // Add end of day as time-of-day for "less than or equal" filter.
        if (filters[`${name}__lte`]) {
          filters[`${name}__lte`] = formatDatetimeForBackendUrl(
            convertDateToDatetime(filters[`${name}__lte`], timezone, END_OF_DAY)
          );
        }
      }
      return filters;
    },
  };
}

export type ReportDateFilterFieldValue =
  | {
      type: 'is' | 'is_after' | 'is_before';
      range: DateFieldValue;
    }
  | {
      type: 'is_between';
      range: [DateFieldValue, DateFieldValue];
    }
  | {
      type: 'is_within_last' | 'is_within_next';
      range: IntervalFieldValue;
    }
  | {
      type: 'is_blank';
      range: '';
    };

type InnerProps = {
  types?: DateFilterType[];
  handleFocus?: () => void;
} & FieldProps;

class InnerReportDateFilterField extends React.Component<InnerProps> {
  handleTypeChange: React.ChangeEventHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const prevType = lodashGet(this.props.field.value, 'type');
    const nextType = e.target.value as DateFilterType;
    if (prevType !== nextType) {
      const prevRange: ReportDateFilterFieldValue['range'] = lodashGet(
        this.props.field.value,
        'range'
      );
      let newRange: typeof prevRange | null = null;

      // Blank the filter range if the type changes to something incompatible.
      if (nextType === 'is_between') {
        newRange = ['', ''];
      } else if (
        nextType === 'is_blank' ||
        (['is', 'is_after', 'is_before'].includes(prevType) &&
          !['is', 'is_after', 'is_before'].includes(nextType)) ||
        (['is_within_last', 'is_within_next'].includes(prevType) &&
          !['is_within_last', 'is_within_next'].includes(nextType))
      ) {
        newRange = '';
      }

      this.props.form.setFieldValue(`${this.props.field.name}.type`, nextType);
      this.props.form.setFieldTouched(`${this.props.field.name}.type`);

      if (newRange !== null) {
        this.props.form.setFieldValue(
          `${this.props.field.name}.range`,
          newRange
        );

        this.props.form.setFieldTouched(
          `${this.props.field.name}.range`,
          // A bit of a hack, if we're switching from a single-value type to
          // a dual-value type, we need to pass an array to setFieldTouched
          // instead of the boolean it claims to accept, so that it will
          // put an array into the "touched" state tree and be able to track
          // separate touched state for `range[0]` and `range[1]`
          nextType === 'is_between' ? ([true, true] as any) : true
        );
      }
    }
  };

  render() {
    const typeFieldName = `${this.props.field.name}.type`;
    const rangeFieldName = `${this.props.field.name}.range`;

    const selectedType = lodashGet(this.props.field.value, 'type', 'is');

    let rangeField: React.ReactNode = null;
    let singleFieldErrorNotice: React.ReactNode = (
      <ErrorMessage name={rangeFieldName} component={ErrorNotice} />
    );
    switch (selectedType) {
      case 'is':
      case 'is_after':
      case 'is_before':
        rangeField = (
          <div>
            <DateField name={rangeFieldName} onFocus={this.props.handleFocus} />
            {singleFieldErrorNotice}
          </div>
        );
        break;
      case 'is_between':
        rangeField = (
          <Trans>
            <div>
              <DateField name={`${rangeFieldName}[0]`} />
              <ErrorMessage
                name={`${rangeFieldName}[0]`}
                component={ErrorNotice}
              />
            </div>
            <span className="date-between-and"> and </span>
            <div>
              <DateField name={`${rangeFieldName}[1]`} />
              <ErrorMessage
                name={`${rangeFieldName}[1]`}
                component={ErrorNotice}
              />
            </div>
          </Trans>
        );
        singleFieldErrorNotice = null;
        break;
      case 'is_within_last':
      case 'is_within_next':
        rangeField = (
          <div className="form-date-interval">
            <IntervalField name={rangeFieldName} />
            {singleFieldErrorNotice}
          </div>
        );
        break;
      case 'is_blank':
      default:
        // Use a hidden field to clear the "details" Formik value.
        rangeField = (
          <div>
            <Field name={rangeFieldName} type="hidden" value="" />
            {singleFieldErrorNotice}
          </div>
        );
    }

    return (
      <I18n>
        {({ i18n }) => (
          <div className="form-date-range">
            <select
              name={typeFieldName}
              onFocus={this.props.handleFocus}
              onChange={this.handleTypeChange}
              value={lodashGet(this.props.field.value, 'type')}
            >
              {Object.entries(dateFilterLabels)
                .filter(
                  ([typeName]) =>
                    !this.props.types ||
                    this.props.types.includes(typeName as DateFilterType)
                )
                .map(([value, label]) => (
                  <option key={value} value={value}>
                    {i18n._(label)}
                  </option>
                ))}
            </select>
            {rangeField}
          </div>
        )}
      </I18n>
    );
  }
}

export interface ReportDateFilterFieldProps {
  name: string;
  types?: DateFilterType[];
  handleFocus?: () => void;
}

export function ReportDateFilterField(props: ReportDateFilterFieldProps) {
  return (
    <Field
      name={props.name}
      types={props.types}
      handleFocus={props.handleFocus}
      component={InnerReportDateFilterField}
      validate={(value: ReportDateFilterFieldValue) => {
        if (
          value.type === 'is_between' &&
          value.range[0] &&
          value.range[1] &&
          value.range[0] > value.range[1]
        ) {
          return <Trans>Start date must be earlier than end date</Trans>;
        }
        return undefined;
      }}
    />
  );
}
