import React from 'react';
import { Field, FieldProps } from 'formik';
import SimpleSelect, { SimpleSelectProps } from './simpleselect';

const EMPTY_ARRAY: [] = [];

type BaseValue<E, IsMulti extends boolean = false> = IsMulti extends true
  ? E[]
  : E | null;

export type SimpleSelectFieldProps<E, IsMulti extends boolean = false> = Omit<
  SimpleSelectProps<E, IsMulti>,
  'onChange' | 'value'
> & {
  onChange?: (selection: BaseValue<E, IsMulti>) => boolean | void;
};

/**
 * A custom component designed to be used with Formik's <Input> comonent,
 * as a drop-in replacement for a standard HTML5 <select>, but using
 * React-Select.
 *
 * This is very similar to <SimpleSeclect>, it uses <SimpleSelect> under the hood,
 * but is made to be use as a <Field> within <Formik>
 *
 * @example See `simpleselectfield.stories.js`
 * @export
 * @class SimpleSelectField
 * @extends {React.Component}
 */
class SimpleSelectInner<
  E,
  IsMulti extends boolean = false
> extends React.PureComponent<FieldProps & SimpleSelectFieldProps<E, IsMulti>> {
  // Since we're giving Formik a proper component to render, rather than
  // an HTML tag, we need to hook in onChange, onBlur, etc, ourselves.
  // selection: string | string[]
  handleChange = (selection: BaseValue<E, IsMulti>) => {
    if (this.props.onChange && this.props.onChange(selection) === false) {
      return;
    }

    this.props.form.setFieldValue(this.props.field.name, selection);
    this.props.form.setFieldTouched(this.props.field.name, true);
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      return;
    }

    this.props.form.setFieldTouched(this.props.field.name);
  };

  render() {
    const {
      options,
      field,
      form,
      classNamePrefix,
      onChange,
      name,
      ...otherProps
    } = this.props;
    return (
      <SimpleSelect<E, IsMulti>
        options={options || EMPTY_ARRAY}
        name={field.name}
        value={field.value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        classNamePrefix="react-select"
        isClearable={false}
        {...otherProps}
      />
    );
  }
}

export function SimpleSelectField<
  EachOptionValue = string,
  IsMulti extends boolean = false
>(props: SimpleSelectFieldProps<EachOptionValue, IsMulti>) {
  return <Field component={SimpleSelectInner} {...props} />;
}
SimpleSelectField.WrappedComponent = SimpleSelectInner;
