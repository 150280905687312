import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Formik, Form, FormikErrors } from 'formik';
import ModalContent from 'components/base/modal/modalcontent';
import { FormSection, FormItem } from 'components/base/form/FormItem';
import ErrorNotice, {
  FieldError,
} from 'components/base/form/errornotice/errornotice';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import { ButtonPrimary } from 'components/base/button/button';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { Model } from 'util/backendapi/models/api.interfaces';
import { getExpectedFields } from 'util/backendapi/error';
import { addStoredPlots } from 'ducks/plotset/detail';
import { AsyncSimpleSelectField } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { getApi } from 'util/backendapi/fetch';

export const AddStoredPlotModal = (props: {
  plotSet: Model.PlotSet;
  onAfterSubmit: () => void;
}) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async (values: AddStoredPlotFormValues) => {
      await dispatch(addStoredPlots(props.plotSet, values.storedPlotIds));
      props.onAfterSubmit();
    },
    [dispatch, props]
  );

  return (
    <AddStoredPlotModalView
      excludedPlots={props.plotSet.stored_plots}
      onSubmit={onSubmit}
    />
  );
};

export interface AddStoredPlotFormValues {
  storedPlotIds: number[];
}

const validate = (values: AddStoredPlotFormValues) => {
  const errors: FormikErrors<AddStoredPlotFormValues> = {};
  if (!values.storedPlotIds || values.storedPlotIds.length < 1) {
    errors.storedPlotIds = (
      <Trans>A stored plot must be selected</Trans>
    ) as any;
  }
  return errors;
};

interface AddStoredPlotModalViewProps {
  excludedPlots: number[];
  onSubmit: (values: AddStoredPlotFormValues) => Promise<any>;
}

export const AddStoredPlotModalView = ({
  excludedPlots,
  onSubmit,
}: AddStoredPlotModalViewProps) => {
  const initialValues = {
    storedPlotIds: [],
  };

  return (
    <ModalContent header={<Trans>Add stored plots</Trans>}>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={async (values, formik) => {
          try {
            await onSubmit(values);
          } catch (e) {
            showErrorsInFormik(
              formik,
              e,
              getExpectedFields(values),
              ErrorNotice
            );
          }
        }}
      >
        {(formik) => (
          <Form>
            <FormSection>
              <FormItem label={<Trans>Stored plots</Trans>}>
                <AsyncSimpleSelectField<number, true>
                  id="add-stored-plot-select"
                  name="storedPlotIds"
                  isMulti
                  autoFocus={true}
                  onSearch={async (inputValue) => {
                    const results = await getApi(`/reports/stored-plots/`, {
                      name__icontains: inputValue,
                      limit: 50,
                      columns: ['id', 'name'],
                    });

                    return (
                      results
                        // Filter out plots already in the plot set.
                        .filter((item) => !excludedPlots.includes(item.id))
                        .map((item) => ({
                          value: item.id,
                          label: item.name,
                        }))
                    );
                  }}
                  // Never any initial values, so we don't need a load defaults function.
                  loadDefaults={async () => []}
                />
              </FormItem>
              <FieldError name="storedPlotIds" />
            </FormSection>
            <ActionBlock>
              <ButtonHideModal id="add-stored-plot-cancel" />
              <ButtonPrimary
                id="add-stored-plot-submit"
                type="submit"
                iconType="icon-save"
              >
                <Trans>Save</Trans>
              </ButtonPrimary>
            </ActionBlock>
            {formik.status}
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
};
