import { connect } from 'react-redux';
import withInit, { InitProps } from 'components/hoc/withinit';
import { FullState } from 'main/reducers';
import {
  fetchAlarmForEditing,
  updateAlarmParameter,
  AlarmParametersState,
  createAlarmParameter,
  ActionCreators,
} from 'ducks/alarmparameters';
import {
  AlarmParameterFormView,
  AlarmParameterFormProps,
} from './alarm-parameter-form-view';
import { StandardDispatch } from 'main/store';
import { Model } from 'util/backendapi/models/api.interfaces';

type StateProps = AlarmParametersState['editForm'];

type DispatchProps = Pick<
  AlarmParameterFormProps,
  'onUpdate' | 'onCreate' | 'onCancel' | 'onUnmount'
>;

interface OwnProps {
  alarmParamId: null | number;
  observationPointId: number;
  itemNumber: number;
  timeZone: string;
  hideModal: () => any;
  onAfterSave: () => any;
}

export const mapStateToProps = (state: FullState): StateProps => {
  return state.alarmParameters.detail.editForm;
};

export const mapDispatchToProps = (
  dispatch: StandardDispatch,
  ownProps: OwnProps
) => ({
  onInit: () => {
    if (ownProps.alarmParamId) {
      dispatch(fetchAlarmForEditing(ownProps.alarmParamId));
    }
  },
  onUpdate: async (id: number, values: Model.AlarmParameter_PATCH) => {
    await dispatch(updateAlarmParameter(id, values));
    ownProps.hideModal();
    ownProps.onAfterSave();
  },
  onCreate: async (values: Model.AlarmParameter_POST) => {
    await dispatch(createAlarmParameter(values));
    ownProps.hideModal();
    ownProps.onAfterSave();
  },
  onCancel: () => {
    ownProps.hideModal();
  },
  onUnmount: () => {
    dispatch(ActionCreators.CLEAR_ALARM_PARAMETER_FORM());
  },
});

export const AlarmParameterForm = connect<
  StateProps,
  DispatchProps & InitProps<StateProps & DispatchProps>,
  OwnProps,
  FullState
>(
  mapStateToProps,
  mapDispatchToProps
)(withInit(AlarmParameterFormView));
