import React from 'react';
import {
  ReportColumn,
  DEFAULT_SHOW,
  ACTION_COLUMN,
  ReportFilter,
} from 'components/modules/report/report-types';
import { Model } from 'util/backendapi/models/api.interfaces';
import { Trans, Plural, t } from '@lingui/macro';
import {
  reportFilterMenu,
  ReportFilterMenu,
} from 'components/modules/report/filter/fields/FilterMenu';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { ReportTable } from 'components/modules/report/table/ReportTable';
import { ReportFiltersBlock } from 'components/modules/report/filter/ReportFiltersBlock';
import ActionBlock from 'components/base/actionblock/actionblock';
import { SaveReportModalButtons } from 'components/modules/report/actions/SaveReportModal';
import { ExportReportButton } from 'components/modules/report/actions/ExportReportButton';
import { ButtonPrint } from 'components/base/print/ButtonPrint';
import { Icon } from 'components/base/icon/icon';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { reportFilterSites } from 'components/modules/report/filter/fields/reportFilterSites';
import { ReportStateProps } from 'hooks/use-report-state';
import { ButtonShowConfirmation } from 'components/base/confirmation/ButtonShowConfirmation';
import { DMSLink } from 'components/base/link/DMSLink';
import ButtonShowModal from 'components/base/modal/buttonshowmodal';
import { SiteBulkCreateModal } from './SiteBulkCreateModal';

export const siteListFilters = (): ReportFilter[] => [
  reportFilterSites('id', {
    placeholder: <Trans>All sites</Trans>,
    autoFocus: true,
  }),
  reportFilterMenu(
    'area',
    <Trans>Area</Trans>,
    { isMulti: true, valueType: 'number' },
    ReportFilterMenu.CODE_AND_NAME_MENU,
    { placeholder: <Trans>Select an area</Trans> }
  ),
];

export const siteListColumns = (extraProps?: {
  refreshList: () => void;
  deleteSite: (obsPointId: number) => Promise<void>;
}): ReportColumn<Model.ReportsSite>[] => {
  return [
    {
      label: <Trans>Site</Trans>,
      name: 'code',
      visibility: DEFAULT_SHOW,
      accessor: ({ code }) => <DMSLink to={`/sites/${code}`}>{code}</DMSLink>,
    },
    {
      label: <Trans>Area</Trans>,
      name: 'area',
      backendFieldName: 'area__code',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Location</Trans>,
      name: 'location',
      visibility: DEFAULT_SHOW,
    },
    {
      label: <Trans>Cap RL</Trans>,
      name: 'cap_rl',
      visibility: DEFAULT_SHOW,
      accessor: (row) => (row.cap_rl ? <Trans>{row.cap_rl} m</Trans> : null),
    },
    {
      label: <Trans>As built azimuth</Trans>,
      name: 'asbuilt_azimuth',
      backendFieldName: 'asbuilt_azimuth',
      visibility: DEFAULT_SHOW,
      accessor: ({ asbuilt_azimuth }) =>
        asbuilt_azimuth ? <Trans>{asbuilt_azimuth} deg</Trans> : null,
    },
    {
      label: <Trans>As built angle</Trans>,
      name: 'asbuilt_angle',
      backendFieldName: 'asbuilt_angle',
      visibility: DEFAULT_SHOW,
      accessor: ({ asbuilt_angle }) =>
        asbuilt_angle !== null ? <Trans>{asbuilt_angle} deg</Trans> : null,
    },
    {
      label: <Trans>As built length</Trans>,
      name: 'asbuilt_length',
      backendFieldName: 'asbuilt_length',
      visibility: DEFAULT_SHOW,
      accessor: ({ asbuilt_length }) =>
        asbuilt_length ? <Trans>{asbuilt_length} m</Trans> : null,
    },
    {
      ...ACTION_COLUMN,
      additionalFields: ['id', 'code'],
      accessor: ({ id, code }) => (
        <>
          <DMSLink to={`/sites/${code}`}>
            <Icon type="icon-view" title={t`View`} />
          </DMSLink>
          <HasPermission check="can_create_sites">
            <DMSLink to={`/sites/${code}?edit=1`}>
              <Icon type="icon-edit" title={t`Edit`} />
            </DMSLink>
          </HasPermission>
          <HasPermission check="can_delete_sites">
            <ButtonShowConfirmation
              name="delete-site"
              title={t`Delete`}
              iconType="icon-circle-minus"
              className="btn-link-panel"
              content={
                <Trans>
                  Are you sure you want to delete <strong>{code}</strong>? This
                  action is not reversible.
                </Trans>
              }
              okBtnText={<Trans>Yes, delete</Trans>}
              onConfirm={async function () {
                await extraProps!.deleteSite(id);
                extraProps!.refreshList();
              }}
            />
          </HasPermission>
        </>
      ),
    },
  ];
};

export interface SiteListViewProps {
  reportProps: ReportStateProps<Model.ReportsSite>;
  deleteSite: (id: number) => Promise<any>;
}

export const SiteListView: React.FunctionComponent<SiteListViewProps> =
  function (props) {
    const { reportProps } = props;
    const columns = siteListColumns({
      refreshList: reportProps.refreshList,
      deleteSite: props.deleteSite,
    });
    const filters = siteListFilters();
    return (
      <PageStandard
        name="sites-list"
        header={
          reportProps.isExportMode ? (
            <Trans>Site report</Trans>
          ) : (
            <Trans>Maintain Sites</Trans>
          )
        }
      >
        <div className="page-content-header-filters-actions">
          <ActionBlock className="text-right">
            <HasPermission check="can_bulk_create_sites">
              <ButtonShowModal
                name="bulk-create-sites"
                modalContent={({ hideModal }) => (
                  <SiteBulkCreateModal
                    onAfterSubmit={() => {
                      hideModal();
                    }}
                  />
                )}
                iconType="icon-plus"
                title={t`Bulk create sites`}
              >
                <Trans>Bulk create sites</Trans>
              </ButtonShowModal>
            </HasPermission>

            <HasPermission check="can_create_sites">
              <DMSLink to={`/sites/new`} className="btn" shortcut="CREATE_NEW">
                <span>
                  <Trans>Create site</Trans>
                </span>
                <Icon type="icon-plus" />
              </DMSLink>
            </HasPermission>
          </ActionBlock>

          <ReportFiltersBlock
            filtersFrontend={filters}
            filtersBackend={reportProps.reportInfo?.filters}
            isExportMode={reportProps.isExportMode}
          />
        </div>
        <div className="filtered-table-wrapper">
          <ActionBlock className="filtered-table-options">
            <ButtonPrint />
            <SaveReportModalButtons
              columnsFrontend={columns}
              filtersFrontend={filters}
              reportInfo={reportProps.reportInfo}
            />
            <ExportReportButton
              fileNameBase={t`Sites report`}
              reportUrl="/reports/sites/"
              columns={columns}
              filters={filters}
            />
          </ActionBlock>

          <ReportTable
            columns={columns}
            errorMessage={reportProps.errorMessage}
            filters={filters}
            isLoading={reportProps.isLoading}
            msgFilterCount={(count) => (
              <Plural
                value={count}
                one="Filtered to 1 site"
                other="Filtered to # sites"
              />
            )}
            msgNoMatches={<Trans>No sites match the selected filters.</Trans>}
            pagination={reportProps.pagination}
            records={reportProps.records}
            reportInfo={reportProps.reportInfo}
          />
        </div>
      </PageStandard>
    );
  };
