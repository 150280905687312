import React, { useMemo, useRef } from 'react';
import { LinkProps, Link, useLocation, matchPath } from 'react-router-dom';
import * as H from 'history';
import { isValidDMSRoute } from 'util/is-valid-dms-route';
import { ApplicationShortcut, DMSHotKey } from 'main/DMSHotKey';

/**
 * A wrapper of React Router <Link>, which will automatically append
 * the query param `&backUrl=currentUrl` to the link if it matchs any
 * of `backablePaths` (usually the page with a <BackButton /> component inside)
 */
export function DMSLink(
  props: Merge<LinkProps, { shortcut?: ApplicationShortcut }>
) {
  const { to, shortcut, ...rest } = props;
  const location = useLocation();
  const anchorRef = useRef<HTMLAnchorElement | null>(null);

  const enhancedTo = useMemo(() => {
    return enhanceWithBackUrl(to as any, location);
  }, [location, to]);

  return (
    <>
      <Link
        to={enhancedTo}
        {...rest}
        ref={(node) => (anchorRef.current = node)}
      />

      {shortcut ? (
        <DMSHotKey
          shortcut={shortcut}
          onPress={() => anchorRef.current!.click()}
        />
      ) : null}
    </>
  );
}

// these are places with the <BackButton />
const backablePaths = [
  '/alarm-parameters/:obsPointCode',
  '/alarm-reports/:alarmReportId',
  '/area/:areaCode',
  '/check-readings/:batchNumber',
  '/checksheet-instances/:checksheetInstanceId',
  '/data-loggers/:dataLoggerNumber',
  '/instrument-types/:new',
  '/instrument-types/:instrumentTypeCode',
  '/observation-point/:obsPointCode/time-dependent-fields/:fieldName',
  '/observation-point/:obsPointCode',
  '/plot-sets/:plotSetName',
  '/plot-sets/:plotSetName/plot',
  '/reading-comment-exclusions/',
  '/route-march-observation-points/:routeMarchCode',
  '/sites/:siteCode/time-dependent-fields/:fieldName',
  '/sites/:siteCode/',
  '/stored-lists/:storedListId',
  '/stored-lists/:storedListId/edit',
  '/stored-plots/:id',
  '/stored-plots/:id/edit',
  '/stored-plots-new/:plotType',
];

export const isBackable = (url: string) => {
  if (!url || !url.startsWith('/')) {
    return false;
  }

  const pathname = url.split('?')[0];

  return backablePaths.some((path) => {
    const match = matchPath(pathname, {
      path,
      exact: true,
      strict: false,
    });

    return Boolean(match);
  });
};

export function enhanceWithBackUrl(to: string, location: H.Location): string;
export function enhanceWithBackUrl(
  to: LinkProps['to'],
  location: H.Location
): LinkProps['to'] {
  const currentUrl = `${location.pathname}${location.search}`;

  if (typeof to === 'string') {
    if (isBackable(to)) {
      const connector = to.indexOf('?') > -1 ? '&' : '?';
      return `${to}${connector}backUrl=${encodeURIComponent(currentUrl)}`;
    } else {
      return to;
    }
  }

  if (typeof to === 'function') {
    const computedTo = to(location);
    return enhanceWithBackUrl(computedTo as any, location);
  }

  const search = to.search || '?';

  if (isBackable(to.pathname || '')) {
    return {
      ...to,
      search: `${search}&backUrl=${encodeURIComponent(currentUrl)}`,
    };
  }

  return to;
}

/**
 * There can base case that the backURL contains another backURL inside
 * This util will extract the last backURL out of it
 *
 * @param location
 */
export function getLastBackUrl(location: { search: string }): string | null {
  const searchParams = new URLSearchParams(location.search);

  const backUrl = searchParams.get('backUrl');

  if (!backUrl) {
    return '';
  }

  if (!isValidDMSRoute(backUrl)) {
    return '';
  }

  if (backUrl.indexOf('backUrl') > -1) {
    return getLastBackUrl({
      search: new URL(backUrl, 'http://dms.any').search,
    });
  }

  return backUrl;
}
