import React, { useCallback } from 'react';
import { Field, FieldProps } from 'formik';
import { Trans } from '@lingui/macro';
import Button from 'components/base/button/button';

// Defining types for the file formats used in our application, to help prevent typos.
// This list is copied from the back end.
const DOCUMENT_FILE_EXTENSIONS = [
  '.doc',
  '.docx',
  '.pdf',
  '.odt',
  '.txt',
  '.xlsx',
  '.csv',
] as const;
const IMAGE_FILE_EXTENSIONS = [
  '.jpg',
  '.jpeg',
  '.png',
  '.tiff',
  '.tif',
  '.gif',
  '.svg',
] as const;
const VIDEO_FILE_EXTENSIONS = [
  '.mpeg',
  '.mov',
  '.avi',
  '.mpg',
  '.mp4',
] as const;
const AUDIO_FILE_EXTENSIONS = ['.mp3', '.wav'] as const;
export const ALLOWED_FILE_EXTENSIONS = [
  ...DOCUMENT_FILE_EXTENSIONS,
  ...IMAGE_FILE_EXTENSIONS,
  ...VIDEO_FILE_EXTENSIONS,
  ...AUDIO_FILE_EXTENSIONS,
] as const;
export type AllowedFileExtensions = ArrayElements<
  typeof ALLOWED_FILE_EXTENSIONS
>;

/**
 * A Formik field for a a file input.
 * It stores the selected filename as `name` and the File object as `${name}_file` in formik.values.
 */
type FileFieldProps = {
  name: string;
  // the field name that hold the selected file
  // if not specified, it defaults to `${name}_file`
  fileFieldName?: string;
  fileExtensions?: AllowedFileExtensions[];
  disabled?: boolean;
};

function FileFieldInner(props: FileFieldProps & FieldProps) {
  const {
    form: formik,
    field: { name },
    fileFieldName,
    fileExtensions: propsFileExtensions,
    disabled,
  } = props;
  const fileField = fileFieldName ?? `${name}_file`;
  const fileExtensions = propsFileExtensions ?? ALLOWED_FILE_EXTENSIONS;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const file = input.files?.[0];

    Promise.all([
      formik.setFieldValue(name, file?.name ?? ''),
      formik.setFieldValue(fileField, file ?? null),
    ]).then(() => formik.setFieldTouched(name));
  };

  if (formik.values[name]) {
    return (
      <>
        {formik.values[name]}{' '}
        <Button
          onClick={() => {
            Promise.all([
              formik.setFieldValue(name, ''),
              formik.setFieldValue(fileField, null),
            ]).then(() => formik.setFieldTouched(name));
          }}
          disabled={disabled}
        >
          <Trans>Delete</Trans>
        </Button>
      </>
    );
  }
  return (
    <input
      name={name}
      type="file"
      accept={fileExtensions?.join(',')}
      onChange={handleChange}
    />
  );
}

export function FileField(props: FileFieldProps) {
  const { fileExtensions } = props;
  const validate = useCallback(
    (fileName: string) => {
      if (
        fileExtensions &&
        fileName &&
        !fileExtensions.some((ext) => fileName.toLowerCase().endsWith(ext))
      ) {
        return (
          <Trans>
            File extension must be one of: {fileExtensions.join(' ')}
          </Trans>
        );
      }
    },
    [fileExtensions]
  );
  return <Field {...props} component={FileFieldInner} validate={validate} />;
}
