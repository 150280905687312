import React from 'react';
import {
  SavedReportsView,
  SavedReportsColumns,
  SavedReportsFilters,
} from './SavedReportsView';
import { RCPWithQueryParams } from 'util/routing';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { savedReportsListDuck } from 'ducks/savedReport/list';
import { useReportState } from 'hooks/use-report-state';

type OwnProps = RCPWithQueryParams<Filter.ReportsSavedReports>;

export const SavedReportsScreen = (ownProps: OwnProps) => {
  const props = useReportState(
    ownProps,
    SavedReportsColumns(),
    SavedReportsFilters(),
    savedReportsListDuck,
    (state) => state.savedReports
  );

  return <SavedReportsView reportProps={props} />;
};
