import difference from 'lodash/difference';
import { Model } from 'util/backendapi/models/api.interfaces';
import { DRFError, errorToString } from 'util/backendapi/error';
import { StandardThunk } from 'main/store';
import { getApi, patchApi } from 'util/backendapi/fetch';

export const ActionTypes = {
  FETCH_CLIENT_AREAS_START: 'dms/client/areas/FETCH_CLIENT_AREAS_START',
  FETCH_CLIENT_AREAS_RESPONSE: 'dms/client/areas/FETCH_CLIENT_AREAS_RESPONSE',
  FETCH_CLIENT_AREAS_ERROR: 'dms/client/areas/FETCH_CLIENT_AREAS_ERROR',
  UPDATE_CLIENT_AREAS_START: 'dms/client/areas/UPDATE_CLIENT_AREAS_START',
  UPDATE_CLIENT_AREAS_RESPONSE: 'dms/client/areas/UPDATE_CLIENT_AREAS_RESPONSE',
  UPDATE_CLIENT_AREAS_ERROR: 'dms/client/areas/UPDATE_CLIENT_AREAS_ERROR',
} as const;

export const ActionCreators = {
  fetchClientAreasStart: (clientId: number) => ({
    type: ActionTypes.FETCH_CLIENT_AREAS_START,
    clientId,
  }),
  fetchClientAreasResponse: (
    clientId: number,
    clientAreas: Model.AreaDecorated[],
    areasWithNoClient: Model.AreaDecorated[]
  ) => ({
    type: ActionTypes.FETCH_CLIENT_AREAS_RESPONSE,
    clientId,
    payload: {
      clientAreas,
      areasWithNoClient,
    },
  }),
  fetchClientAreasError: (clientId: number, error: DRFError | Error) => ({
    type: ActionTypes.FETCH_CLIENT_AREAS_ERROR,
    clientId,
    error: true,
    payload: errorToString(error),
  }),
  updateClientAreasStart: (
    clientId: number,
    prevAreas: number[],
    newAreas: number[]
  ) => ({
    type: ActionTypes.UPDATE_CLIENT_AREAS_START,
    clientId,
    payload: {
      prevAreas,
      newAreas,
    },
  }),
  updateClientAreasResponse: (clientId: number) => ({
    type: ActionTypes.UPDATE_CLIENT_AREAS_RESPONSE,
    clientId,
  }),
  updateClientAreasError: (clientId: number, error: DRFError | Error) => ({
    type: ActionTypes.UPDATE_CLIENT_AREAS_ERROR,
    clientId,
    error: true,
    payload: errorToString(error),
  }),
} as const;

export type ClientAreasAction = ReturnType<
  typeof ActionCreators[keyof typeof ActionCreators]
>;

export interface ClientAreasState {
  loading: boolean;
  loadedClientId: number | null;
  error: string | null;
  clientAreas: Model.AreaDecorated[] | null;
  areasWithNoClient: Model.AreaDecorated[] | null;
}

export function initialClientAreasState(): ClientAreasState {
  return {
    loading: false,
    loadedClientId: null,
    error: null,
    clientAreas: null,
    areasWithNoClient: null,
  };
}

export function clientAreasReducer(
  state: ClientAreasState = initialClientAreasState(),
  action: ClientAreasAction
): ClientAreasState {
  switch (action.type) {
    case ActionTypes.FETCH_CLIENT_AREAS_START:
      return {
        ...initialClientAreasState(),
        loading: true,
        loadedClientId: action.clientId,
      };
    case ActionTypes.FETCH_CLIENT_AREAS_RESPONSE:
      if (state.loadedClientId !== action.clientId) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          clientAreas: action.payload.clientAreas,
          areasWithNoClient: action.payload.areasWithNoClient,
        };
      }
    case ActionTypes.FETCH_CLIENT_AREAS_ERROR:
      if (state.loadedClientId !== action.clientId) {
        return state;
      } else {
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      }
    default:
      return state;
  }
}

export function fetchClientAreas(clientId: number): StandardThunk {
  return async function (dispatch) {
    dispatch(ActionCreators.fetchClientAreasStart(clientId));
    try {
      const [clientAreas, areasWithNoClient] = await Promise.all([
        getApi('/areas/', { client: clientId }),
        getApi('/areas/', { client__isnull: true }),
      ]);
      dispatch(
        ActionCreators.fetchClientAreasResponse(
          clientId,
          clientAreas,
          areasWithNoClient
        )
      );
    } catch (e) {
      dispatch(ActionCreators.fetchClientAreasError(clientId, e));
    }
  };
}

export function updateClientAreas(
  clientId: number,
  prevAreas: number[],
  newAreas: number[]
): StandardThunk {
  return async function (dispatch) {
    dispatch(
      ActionCreators.updateClientAreasStart(clientId, prevAreas, newAreas)
    );
    try {
      const areasToRemove = difference(prevAreas, newAreas);
      const areasToAdd = difference(newAreas, prevAreas);
      await Promise.all([
        ...areasToRemove.map((area) =>
          patchApi(`/areas/${area}/`, { client: null })
        ),
        ...areasToAdd.map((area) =>
          patchApi(`/areas/${area}/`, { client: clientId })
        ),
      ]);

      dispatch(ActionCreators.updateClientAreasResponse(clientId));
    } catch (e) {
      dispatch(ActionCreators.updateClientAreasError(clientId, e));
      throw e;
    }
  };
}
