import React from 'react';
import FileUploader from 'screens/dashboard/fileuploader/fileuploader';
import BatchesList from 'screens/dashboard/batcheslist/batcheslist';
import { Trans } from '@lingui/macro';
import PageStandard from 'components/modules/pagestandard/pagestandard';
import { HasPermission } from 'components/logic/has-permission/HasPermission';
import { UncancelledAlarmsDashlet } from './UncancelledAlarmsDashlet/UncancelledAlarmsDashlet';
import {
  ChecksheetsInProgressDashlet,
  ChecksheetsToReviewDashlet,
} from './ChecksheetsDashlet/ChecksheetsDashlet';
import { BookmarksDashboardSection } from './BookmarksDashlet/BookmarksDashlet';
import { Icon } from 'components/base/icon/icon';
import { DMSLink } from 'components/base/link/DMSLink';
import { selectHasPermission } from 'util/user';
import { useSelector } from 'react-redux';
import { Enum } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';

export function DashboardScreen() {
  const canViewDashboardComponents = useSelector(
    (state: FullState): boolean =>
      selectHasPermission(
        state,
        Enum.User_PERMISSION.can_view_dms_analyst_dashboard
      ) ||
      selectHasPermission(
        state,
        Enum.User_PERMISSION.can_create_dashboard_component
      ) ||
      selectHasPermission(
        state,
        Enum.User_PERMISSION.can_view_uploaded_batches_dashboard
      )
  );

  return (
    <PageStandard name="analystdash" header={<Trans>DMS Dashboard</Trans>}>
      {canViewDashboardComponents ? (
        <div className="sidebar-area-wrapper">
          <div className="sidebar-main">
            <HasPermission
              check="can_view_dms_analyst_dashboard"
              // There can be only one instance of BatchesList at a time.
              // So don't attempt to show the more limited "uploaded batches"
              // version of it unless we know the user is not viewing the
              // more expansive "awaiting processing" version in the DMS
              // analyst dashboard.
              noPermissionMsg={
                <HasPermission check="can_view_uploaded_batches_dashboard">
                  <BatchesList batchesForUser={true} />
                </HasPermission>
              }
            >
              <BatchesList batchesForUser={false} />
              <HasPermission check="can_view_checksheet_instance_report">
                <ChecksheetsToReviewDashlet />
              </HasPermission>
              <HasPermission check="can_view_checksheet_instance_report">
                <ChecksheetsInProgressDashlet />
              </HasPermission>
              <HasPermission check="can_view_alarm_report_report">
                <UncancelledAlarmsDashlet />
              </HasPermission>
            </HasPermission>
            <BookmarksDashboardSection />
          </div>
          <div className="sidebar-right">
            <FileUploader />
            <HasPermission check="can_create_dashboard_component">
              <DMSLink to="/dashboard-components/new" className="btn">
                <span>
                  <Trans>Create custom component</Trans>
                </span>
                <Icon type="icon-plus" />
              </DMSLink>
            </HasPermission>
          </div>
        </div>
      ) : null}
    </PageStandard>
  );
}
