import { getPaginated, getApi } from 'util/backendapi/fetch';
import {
  makeReportingDuck,
  ReportingDuckActions,
} from 'ducks/make-reporting-duck';

export const mediaListDuck = makeReportingDuck(
  'media',
  (filters) => getPaginated('/reports/media/', filters),
  () => getApi('/reports/media/info/')
);

export type MediaListAction = ReportingDuckActions<typeof mediaListDuck>;
