import React from 'react';
import { Trans } from '@lingui/macro';
import { Form, Formik, Field, FormikErrors, FormikProps } from 'formik';
import { ButtonPrimary } from 'components/base/button/button';
import ActionBlock from 'components/base/actionblock/actionblock';
import ButtonHideModal from 'components/base/modal/buttonhidemodal';
import ModalContent from 'components/base/modal/modalcontent';
import { FormItem } from 'components/base/form/FormItem';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { showErrorsInFormik } from 'util/backendapi/error-formik';
import { getExpectedFields } from 'util/backendapi/error';

interface CopyRouteMarchValues {
  code: string;
  description: string;
}

type CopyRouteMarchModalProps = {
  hideModal: () => void;
  onSubmit: (values: CopyRouteMarchValues) => Promise<void>;
};

export function validateCopyRouteMarchValues(
  values: CopyRouteMarchValues
): FormikErrors<CopyRouteMarchValues> {
  let errors: FormikErrors<CopyRouteMarchValues> = {};

  if (values.code === '') {
    errors.code = (<Trans>New route march code is required.</Trans>) as any;
  }

  if (values.description === '') {
    errors.description = (<Trans>New description is required.</Trans>) as any;
  }

  return errors;
}

export function CopyRouteMarchPanel(props: CopyRouteMarchModalProps) {
  return (
    <ModalContent header={<Trans>Copy route march</Trans>}>
      <Formik<CopyRouteMarchValues>
        initialValues={{ code: '', description: '' }}
        validate={validateCopyRouteMarchValues}
        onSubmit={async (values, formik) => {
          try {
            await props.onSubmit(values);
          } catch (e) {
            formik.setSubmitting(false);
            showErrorsInFormik(formik, e, getExpectedFields(values));
          }
        }}
      >
        {(formik: FormikProps<CopyRouteMarchValues>) => (
          <Form>
            {formik.status}
            <FormItem label={<Trans>New route march code</Trans>}>
              <Field type="text" name="code" autoFocus />
              <FieldError name="code" />
            </FormItem>
            <FormItem label={<Trans>New description</Trans>}>
              <Field type="text" name="description" />
              <FieldError name="description" />
            </FormItem>
            <p>
              <Trans>
                A new route march will be created with the same settings as this
                route march
              </Trans>
            </p>
            <ActionBlock>
              <ButtonHideModal />
              <ButtonPrimary
                id="copy-route-march-submit"
                type="submit"
                iconType="icon-save"
                disabled={formik.isSubmitting}
              >
                Copy
              </ButtonPrimary>
            </ActionBlock>
          </Form>
        )}
      </Formik>
    </ModalContent>
  );
}
