import {
  createSelector,
  createStructuredSelector,
  defaultMemoize,
} from 'reselect';
import sortBy from 'lodash/sortBy';
import {
  selectAllInOrderedArray,
  EntityTypes,
  EntityModels,
} from 'ducks/entities';
import { SimpleSelectOption } from 'components/base/form/simpleselect/simpleselect';
import { Model, Enum } from 'util/backendapi/models/api.interfaces';
import { FullState } from 'main/reducers';
import { MaintObsPointAllMenus } from './maintobspoint.types';

/**
 * A selector factory of sorts, for creating selectors to convert from Redux
 * store entities into the structure we need for our menu options.
 *
 * @param {string} entityType
 * @param {function} mapFn A function that converts the entity object into a menu option
 * object (which must have at least a "value" and a "label" field, both strings)
 * @return {function}
 */
function makeOptionsSelector<
  TEntityType extends EntityTypes,
  ValueType = number,
  ReturnType = SimpleSelectOption<ValueType>
>(
  entityType: TEntityType,
  isNullable = false,
  mapFn: (item: EntityModels[TEntityType]) => ReturnType = (item: any) =>
    ({
      value: item.id,
      label: `${item.code} - ${item.name}`,
    } as any),
  filterFn?: (item: EntityModels[TEntityType]) => boolean
) {
  return createSelector(
    (state: FullState) => selectAllInOrderedArray(state, entityType),
    function (itemList): ReturnType[] {
      const filteredItemList = filterFn ? itemList.filter(filterFn) : itemList;
      const menuOptions = sortBy(filteredItemList.map(mapFn), 'label');
      if (isNullable) {
        // NOTE: use 0 as the 'null' value as is React produces a warning
        // if an input is set to null
        return [{ label: '-', value: 0 as any } as any, ...menuOptions];
      } else {
        return menuOptions;
      }
    }
  );
}

/**
 * A combined memoized selector, that returns an object where each key is
 */
export const selectMenuOptions = createStructuredSelector<
  FullState,
  MaintObsPointAllMenus
>({
  classification: makeOptionsSelector(
    EntityTypes.OBSERVATION_POINT_CLASSIFICATION
  ),
  formula: makeOptionsSelector(
    EntityTypes.FORMULA,
    undefined,
    undefined,
    // Don't show the "Obsolete" formula in the formulas menu.
    (formula) => formula.code !== Enum.Formula_code_OBSOLETE
  ),
  grid_reference: makeOptionsSelector(
    EntityTypes.OBSERVATION_POINT_GRID_REFERENCE,
    true
  ),
  instrument_type: makeOptionsSelector(EntityTypes.INSTRUMENT_TYPE),
  reliability: makeOptionsSelector(EntityTypes.OBSERVATION_POINT_RELIABILITY),
  tubing_type: makeOptionsSelector(
    EntityTypes.OBSERVATION_POINT_TUBING_TYPE,
    true
  ),
  // The area options are non-standard, because:
  // - we need to retain the area's CODE (so that we can use that later to request
  // an update to the site menu.)
  area__code: makeOptionsSelector<
    EntityTypes.AREA,
    number,
    SimpleSelectOption<string> & { timeZone: string }
  >(EntityTypes.AREA, false, (area: Model.AreaDecorated) => ({
    value: area.code,
    label: `${area.code} - ${area.name}`,
    timeZone: area.time_zone.name,
  })),
  // The datalogger options are also different, because:
  // - The label needs to be the "logger_number" field rather than the "name" field
  data_logger: makeOptionsSelector(
    EntityTypes.DATA_LOGGER,
    false,
    (dataLogger) => ({
      value: dataLogger.id,
      label: String(dataLogger.logger_number),
    })
  ),
});

/**
 * Memoized selector to get the code of the currently selected menu, out of
 * the list of area menu options.
 *
 * @param {*} formikValues
 * @param {*} props
 * @returns {string|null}
 */
export const getSelectedAreaCode = defaultMemoize(function (
  selectedAreaId: number | string | undefined,
  areaMenuOptions: Array<SimpleSelectOption<number> & { code: string }>
) {
  if (!areaMenuOptions || !selectedAreaId) {
    return null;
  }
  const selectedArea = areaMenuOptions.find(
    (area) => String(area.value) === String(selectedAreaId)
  );
  if (!selectedArea) {
    return null;
  } else {
    return selectedArea.code;
  }
});
