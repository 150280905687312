import React from 'react';
import { ReportFilter } from '../../report-types';
import { reportFilterAsyncMenu } from './FilterAsyncMenu';
import { Trans } from '@lingui/macro';
import { getApi } from 'util/backendapi/fetch';
import { AsyncSimpleSelectFieldProps } from 'components/base/form/asyncsimpleselect/AsyncSimpleSelectField';
import { makeIdCodeMenuOptions } from 'components/modules/async-menu/utils';

/**
 * Generates a report filter for an async "sites" menu.
 * @param name
 */

export function reportFilterSites(
  name: string,
  extraProps: Partial<AsyncSimpleSelectFieldProps<any, any, any>> = {}
): ReportFilter {
  return reportFilterAsyncMenu(
    name,
    <Trans>Site</Trans>,
    {
      isMulti: true,
      valueType: 'number',
      onSearch: async (searchText: string) =>
        makeIdCodeMenuOptions(
          await getApi('/sites-list/', { code__icontains: searchText })
        ),
      loadDefaults: async (value: number[]) =>
        makeIdCodeMenuOptions(
          await getApi('/sites-list/', {
            id__in: value,
          })
        ),
    },
    {
      placeholder: <Trans>All sites</Trans>,
      ...extraProps,
    }
  );
}
