import React from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { Icon, IconType } from '../../../base/icon/icon';
import { DMSLink } from 'components/base/link/DMSLink';
import { Enum } from 'util/backendapi/models/api.interfaces';

interface Props {
  name: string;
  route: {
    path: string;
    permission?: null | Enum.User_PERMISSION;
  };
  linkUrl?: string;
  exact?: boolean;
  strict?: boolean;
  _isActivePage?: boolean;
  iconType?: IconType;
  children?: React.ReactNode;
  onClick?: (() => void) | null;
}

/**
 * A single "item" in the navigation menu, generally representing a page. It
 * changes its styling to indicate that it is "active", if it matches the
 * URL of the current page.
 *
 * Some items live on the top level of the menu by themselves; other items
 * live underneath a NavCategory. They both render the same, but their behavior
 * needs to be a little different:
 *
 * - A top-level nav item needs to check for itself whether or not it is active.
 * - A child-level nav item lets its parent nav category check whether or not
 * it is active.
 *
 * This difference in behavior is signaled by the "_isActivePage" prop, which
 * starts with an underscore to indicate that it is "internal" and should only
 * be injected by a parent NavCategory. If `this.props._isActivePage` holds
 * a value of undefined, the component can assume it's top level; otherwise,
 * it assumes it is a child.
 *
 * @export
 * @class NavItem
 * @extends {React.Component}
 */
export default class NavItem extends React.Component<Props> {
  render() {
    const { _isActivePage, route, exact, strict } = this.props;
    // A top-level nav item needs to check whether its Route matches by itself.
    if (_isActivePage === undefined) {
      return (
        <Route path={route.path} exact={exact} strict={strict}>
          {(routeProps) => {
            return this.reallyRender(Boolean(routeProps.match));
          }}
        </Route>
      );
    } else {
      // A child-level nav item has its Route checked by its parent
      // NavCategory, which will communicate whether it's active or not
      // via a new prop, "isActivePage"
      return this.reallyRender(_isActivePage);
    }
  }

  reallyRender = (isActivePage: boolean) => {
    const { linkUrl, route, name, children, onClick, iconType } = this.props;

    const icon = iconType ? (
      <Icon type={iconType} className="nav-item-icon" />
    ) : null;

    return (
      <li
        className={classNames({
          'nav-item': true,
          'nav-current-page': isActivePage,
        })}
        onClick={onClick ? onClick : () => {}}
      >
        <DMSLink to={linkUrl || route.path} id={`nav-item-${name}`}>
          {icon}
          <span className="nav-text">{children}</span>
        </DMSLink>
      </li>
    );
  };
}
