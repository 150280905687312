import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { getApi } from 'util/backendapi/fetch';
import { ChangePasswordView } from './changePasswordView';

type OwnProps = RouteComponentProps<{ userId: string; token: string }>;

export const ChangePasswordScreen: React.FunctionComponent<OwnProps> = (
  props
) => {
  const [validToken, setTokenValidity] = useState<boolean | null>(null);

  const userId = +props.match.params.userId!;
  const token = props.match.params.token!;
  const handleCancel = () => {
    props.history.push('/login');
  };

  useEffect(() => {
    (async () => {
      try {
        // Validate before loading the form that the token is valid for the user. This is a nicety
        // only as the actual request to update the password of course validates the token as well.
        await getApi(`/users/password-reset/${userId}/${token}/`);
        setTokenValidity(true);
      } catch (e) {
        setTokenValidity(false);
      }
    })();
  }, [userId, token]);

  return (
    <ChangePasswordView
      validToken={validToken}
      userId={userId}
      token={token}
      handleCancel={handleCancel}
    />
  );
};
