/**
 * Parse filename from a Django file URL.
 */
export function parseFilenameFromUrl(file_url: string) {
  if (file_url.indexOf('/') === -1) return file_url;
  const url = new URL(file_url);
  const path = url.pathname.split('/');
  const filename = decodeURIComponent(path[path.length - 1]);
  return filename;
}
