import React from 'react';
import { CrosshairProps } from 'react-vis';
import './crosshairwithreticle.scss';
import { getReactVisScales } from './react-vis-hacks';
import classNames from 'classnames';

interface Props<T = any> extends CrosshairProps {
  className?: string;
  marginLeft?: number;
  innerHeight?: number;
  onReticleClick: (value: T, position: { left: number; top: number }) => void;
  showVertical: boolean;
  showHorizontal: boolean;
}

/**
 * Extending the react-vis "Crosshair" component to match our specific graphing
 * needs, by adding a reticle where the crosshair's vertical line crosses
 * the timeseries line.
 *
 * The Crosshair component itself does not allow for this, because it provides
 * no way for the optional child component to know the absolute Y position
 * of the data point.
 *
 * This same graphic (a vertical line, with a circle where it crosses the
 * time series) could also me done with a MarkSeries for the circle and a
 * LineSeries or VerticalGridLines for the vertical line. But those render
 * using SVGs, whereas the Crosshair renders with CSS, and for frequently
 * re-rendered animations, CSS seems to be a lot more performant!
 *
 * This is mostly a copy-paste from the sadly not very extensible
 * Crosshair.render() function.
 *
 * @see react-vis/Crosshair.render()
 */
export default function CrosshairWithReticle<T = any>(props: Props<T>) {
  const {
    className,
    values,
    marginTop,
    marginLeft,
    innerHeight,
    innerWidth,
    onReticleClick,
    showVertical,
    showHorizontal,
  } = props;
  const { scaleX, scaleY } = getReactVisScales(props);

  const value = (values || []).find((v) => Boolean(v));
  if (!value) {
    return null;
  }
  const innerLeft = scaleX(value);
  const innerTop = scaleY(value);

  const left = marginLeft! + innerLeft;
  const top = marginTop!;

  return (
    <>
      {showHorizontal && (
        <div
          className={classNames('rv-crosshair', className)}
          style={{ left: `${marginLeft}px`, top: `${marginTop! + innerTop}px` }}
        >
          <div
            className="rv-crosshair__line crosshair-line"
            style={{ width: `${innerWidth}px`, height: '1px' }}
          />
        </div>
      )}
      <div
        className={classNames('rv-crosshair', className)}
        style={{ left: `${left}px`, top: `${top}px` }}
      >
        {showVertical && (
          <div
            className="rv-crosshair__line crosshair-line"
            style={{
              height: `${innerHeight}px`,
            }}
          />
        )}

        <div
          className="reticle"
          style={{
            top: `${innerTop}px`,
          }}
          onClick={() => onReticleClick(value, { left, top: top + innerTop })}
        >
          <span
            className="reticle-inner"
            style={{
              color: value.seriesColor,
            }}
          />
        </div>
      </div>
    </>
  );
}
