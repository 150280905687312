import React, { useEffect } from 'react';
import { CumulativePlotView } from './CumulativePlotView';
import { createStructuredSelector } from 'reselect';
import { RouteComponentProps } from 'react-router-dom';
import {
  fetchCumulativePlotData,
  selectCumulativePlotData,
  unmountCumulativePlot,
} from 'ducks/plot/cumulative';
import { FullState } from 'main/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { sortBy } from 'lodash';

interface CumulativePlotSettingsFromUrl {
  observationPointCode: string;
  itemNumber: number;
  timePeriods: number[];
}

interface UrlMatchProps {
  op?: string;
  item?: string;
  years?: string;
}

type OwnProps = RouteComponentProps<UrlMatchProps>;

export const getCumulativePlotSettingsFromUrl = createStructuredSelector<
  OwnProps,
  CumulativePlotSettingsFromUrl
>({
  observationPointCode: (props) => props.match.params.op || '',
  itemNumber: (props) => +(props.match.params.item || 0),
  timePeriods: (props) =>
    props.match.params.years
      ? sortBy(props.match.params.years.split(',').map((year) => +year))
      : [],
});

export function CumulativePlotScreen(props: OwnProps) {
  const settingsFromUrl = getCumulativePlotSettingsFromUrl(props);

  const { observationPointCode, itemNumber, timePeriods } = settingsFromUrl;

  const dispatch = useDispatch();

  const plotState = useSelector((state: FullState) =>
    selectCumulativePlotData(state, 0)
  );

  useEffect(
    () => () => {
      dispatch(unmountCumulativePlot());
    },
    [dispatch]
  );

  useEffect(() => {
    if (!observationPointCode || !itemNumber || timePeriods.length === 0) {
      return;
    }
    dispatch(
      fetchCumulativePlotData(observationPointCode, itemNumber, timePeriods)
    );
  }, [dispatch, observationPointCode, itemNumber, timePeriods]);

  const errorMessage = plotState?.errorMessage ?? null;
  const isLoading = plotState?.isLoading ?? false;

  const observationPoint = plotState?.observationPoints?.[0] ?? null;
  const plotReadings = plotState?.plotReadings ?? null;

  return (
    <CumulativePlotView
      observationPoint={observationPoint}
      cumulativePlotReadings={plotReadings}
      isLoading={isLoading}
      errorMessage={errorMessage}
      itemNumber={itemNumber}
      timePeriods={timePeriods}
    />
  );
}
