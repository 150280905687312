import React from 'react';
import { I18n } from '@lingui/react';
import { Icon, IconType } from '../icon/icon';
import './button.scss';
import { MessageDescriptor } from '@lingui/core';
import { DMSHotKey, ApplicationShortcut } from 'main/DMSHotKey';

export type ButtonProps = Merge<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  {
    iconType?: IconType;
    iconOnly?: boolean;
    title?: MessageDescriptor;
    badge?: React.ReactNode;
    // Note: shortcut only work when there is an `onClick` handler
    shortcut?: ApplicationShortcut;
  }
>;

/**
 * Renders a single button.
 *
 * This component is based on the React "Single Element Pattern", so all
 * additional props passed to it will be passed through to the underlying
 * HTML element that it renders.
 *
 * see: https://github.com/diegohaz/singel
 *
 * TODO: links styled as buttons, <input type="submit"> buttons
 *
 * @export
 * @class Button
 * @prop {*} children The children of the component will be displayed as the body
 * of the button. Or, if the button is using "icononly", the children will be
 * used as the hidden accessibility label for the button.
 * @prop {string} ?iconType The name of the icon type to display in the button.
 * @prop {bool} [iconOnly=false] If true, display only the icon. (The children
 * are still required, and will be added as a hidden accessibility label.)
 * @extends {React.PureComponent}
 */
export default class Button extends React.PureComponent<ButtonProps> {
  static defaultProps = {
    type: 'button',
    iconOnly: false,
  };

  render() {
    const { children, iconType, iconOnly, badge, shortcut, ...otherProps } =
      this.props;

    return (
      <I18n>
        {({ i18n }) => {
          let title = this.props.title ? i18n._(this.props.title) : '';
          return (
            <>
              {shortcut && otherProps.onClick ? (
                <DMSHotKey
                  shortcut={shortcut}
                  onPress={otherProps.onClick as any}
                />
              ) : null}
              <button {...otherProps} title={title}>
                {this.props.children ? (
                  <span className={iconOnly ? 'visuallyhidden' : undefined}>
                    {this.props.children}
                  </span>
                ) : null}
                {this.props.iconType ? (
                  <Icon type={this.props.iconType} />
                ) : null}
                {this.props.badge ? (
                  <span className="button-badge">{this.props.badge}</span>
                ) : null}
              </button>
            </>
          );
        }}
      </I18n>
    );
  }
}

/**
 * A module-internal helper function to reduce the duplicated code between
 * ButtonPrimary and ButtonSecondary.
 *
 * @param {*} buttonClass
 * @param {*} props
 * @returns
 */
function styledButton(buttonClass: string, props: ButtonProps) {
  const { className, ...otherProps } = props;
  let classes = buttonClass;
  if (className) {
    classes = `${classes} ${className}`;
  }
  return <Button className={classes} {...otherProps} />;
}

/**
 * A "primary" button, such as the "submit" button in a form.
 * (Automatically adds type="submit" to the underlying <button> tag, so that
 * the button will submit any enclosing form.)
 *
 * @export
 * @param {*} props
 * @returns
 */
export function ButtonPrimary(props: ButtonProps) {
  return styledButton('btn-primary', props);
}

/**
 * A "secondary" button, such as the "reset" button in a form.
 *
 * @export
 * @param {*} props
 * @returns
 */
export function ButtonSecondary(props: ButtonProps) {
  return styledButton('btn-secondary', props);
}

/**
 * A "danger" button, such as the "Yes, remove" confirmation button when removing a user from a group.
 *
 * @export
 * @param {*} props
 * @returns
 */
export function ButtonDanger(props: ButtonProps) {
  return styledButton('btn-danger', props);
}
