import React from 'react';
import { formatIntervalForDisplay } from 'util/dates';
import { Trans } from '@lingui/react';

/**
 * Helper function for displaying a date interval without having to wrap it
 * in an `<I18n>` tag.
 *
 * See the function `formatIntervalForDisplay()` for more details on where
 * the necessary lang strings are located.
 *
 * @example
 * // Displays "1 day"
 * const jsx = <IntervalDisplay value='P1D' />
 */
export const IntervalDisplay: React.FunctionComponent<{
  value: string | null | undefined;
}> = function ({ value }) {
  const msgDesc = formatIntervalForDisplay(value);
  if (!msgDesc) {
    return null;
  } else {
    return <Trans id={msgDesc.id} values={msgDesc.values} />;
  }
};
