import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { FullState } from 'main/reducers';
import { StaticDataReportView } from './StaticDataReportView';
import { RouteComponentProps } from 'react-router';
import {
  setOneQueryParam,
  parseNumberArrayFromQueryParam,
  setQueryParams,
  parseQueryParamFromRouterProps,
  parseNumberQueryParamFromRouterProps,
} from 'util/routing';
import { getApi } from 'util/backendapi/fetch';
import { usePrevious } from 'util/hooks';
import { isEqual } from 'lodash';
import { GetExportUrlFunc } from 'components/modules/exportpanel/exportpanel';
import { ExportFormats } from 'components/modules/exportpanel/exportpanelconstants';
import { fetchStaticDataReport } from 'ducks/static-data-report';
import { Filter } from 'util/backendapi/models/api.interfaces';
import { convertDateToDatetime, END_OF_DAY, START_OF_DAY } from 'util/dates';

export type OwnProps = RouteComponentProps;

interface ObservationPointDataState {
  options: { value: number; label: string; timeZone: string }[];
  errorMessage: string | null;
  isLoading: boolean;
}

export const StaticDataReportScreen: React.FunctionComponent<OwnProps> = (
  props
) => {
  const { isLoading, errorMessage, result } = useSelector(
    (state: FullState) => state.staticDataReport,
    shallowEqual
  );

  const dispatch = useDispatch();

  const areas = parseNumberArrayFromQueryParam(props, 'areas', []);
  const previousAreas = usePrevious(areas);

  const sites = parseNumberArrayFromQueryParam(props, 'sites', []);
  const previousSites = usePrevious(sites);

  const observationPoints = parseNumberArrayFromQueryParam(
    props,
    'observationPoints',
    []
  );
  const previousObservationPoints = usePrevious(observationPoints);

  const activeObservationPointId = parseNumberQueryParamFromRouterProps(
    props,
    'activeObservationPointId',
    null
  );
  const startDate = parseQueryParamFromRouterProps(props, 'start_date');
  const endDate = parseQueryParamFromRouterProps(props, 'end_date');

  const observationPointDataInitialState: ObservationPointDataState = {
    options: [],
    isLoading: false,
    errorMessage: null,
  };

  const [observationPointData, setObservationPointData] =
    useState<ObservationPointDataState>(observationPointDataInitialState);

  useEffect(() => {
    (async () => {
      if (
        (!isEqual(previousAreas, areas) ||
          !isEqual(previousSites, sites) ||
          !isEqual(previousObservationPoints, observationPoints)) &&
        (areas.length > 0 || sites.length > 0 || observationPoints.length > 0)
      ) {
        setObservationPointData({
          options: [],
          errorMessage: null,
          isLoading: true,
        });
        try {
          let observationPointFilters: Filter.ReportsObservationPoints = {
            columns: ['id', 'code', 'site__area__time_zone__name'],
          };

          if (areas.length > 0) {
            observationPointFilters['site__area__in'] = areas;
          }
          if (sites.length > 0) {
            observationPointFilters['site__in'] = sites;
          }
          if (observationPoints.length > 0) {
            observationPointFilters['id__in'] = observationPoints;
          }

          const response = await getApi(
            '/reports/observation-points/',
            observationPointFilters
          );
          const options = response.map((observationPoint) => ({
            value: observationPoint.id,
            label: observationPoint.code,
            timeZone: observationPoint.site__area__time_zone__name,
          }));
          setObservationPointData({
            options: options,
            errorMessage: null,
            isLoading: false,
          });
        } catch (e) {
          setObservationPointData({
            options: [],
            errorMessage: e.message,
            isLoading: false,
          });
        }
      }
    })();
  }, [
    areas,
    previousAreas,
    sites,
    previousSites,
    observationPoints,
    previousObservationPoints,
  ]);

  useEffect(() => {
    (() => {
      if (
        observationPointData &&
        observationPointData.options.length > 0 &&
        endDate
      ) {
        const observationPointId =
          activeObservationPointId || observationPointData.options[0].value;

        const timeZone = observationPointData.options[0].timeZone;

        const startDatetime = startDate
          ? convertDateToDatetime(startDate, timeZone, START_OF_DAY)
          : null;
        const endDatetime = convertDateToDatetime(
          endDate,
          timeZone,
          END_OF_DAY
        );
        dispatch(
          fetchStaticDataReport(observationPointId, startDatetime, endDatetime)
        );
      }
    })();
  }, [
    observationPointData,
    activeObservationPointId,
    startDate,
    endDate,
    dispatch,
  ]);

  const setActiveObservationPointIdQueryParam = (
    activeObservationPointId: number
  ) => {
    setOneQueryParam(
      props,
      'activeObservationPointId',
      activeObservationPointId
    );
  };

  const setStaticDataReportQueryParams = (
    areas: number[],
    sites: number[],
    observationPoints: number[],
    start_date: string,
    end_date: string
  ) => {
    setQueryParams(props, {
      areas,
      sites,
      observationPoints,
      start_date,
      end_date,
      activeObservationPointId: null,
    });
  };

  const getExportUrl: GetExportUrlFunc = useCallback(
    (
      exportFormat,
      { queryParams },
      _i18n,
      _generatedDatetime,
      pageOrientation
    ) => {
      // Because the user can potentially select observation points
      // in different time zones, use the first time zone to calculate
      // the report period
      const timeZone = observationPointData.options[0]?.timeZone ?? null;

      const startDatetime = convertDateToDatetime(
        startDate,
        timeZone,
        START_OF_DAY
      );
      const endDatetime = convertDateToDatetime(endDate, timeZone, END_OF_DAY);

      queryParams.append('area_ids', areas.join(','));
      queryParams.append('site_ids', sites.join(','));
      queryParams.append('observation_point_ids', observationPoints.join(','));
      queryParams.append('start_datetime', startDatetime);
      queryParams.append('end_datetime', endDatetime);

      if (exportFormat === ExportFormats.XLSX) {
        return {
          path: `/export/${exportFormat}/static-data-report/`,
          queryParams: queryParams,
        };
      } else {
        queryParams.append('page_orientation', pageOrientation!);
        return {
          path: `/export/${exportFormat}/static-data-report/`,
          queryParams: queryParams,
        };
      }
    },
    [areas, sites, observationPoints, observationPointData, startDate, endDate]
  );

  const observationPointId =
    activeObservationPointId || observationPointData.options[0]?.value || null;
  return (
    <StaticDataReportView
      isLoading={isLoading || observationPointData.isLoading}
      errorMessage={errorMessage || observationPointData.errorMessage}
      settings={{
        areas,
        sites,
        observation_points: observationPoints,
        start_date: startDate,
        end_date: endDate,
      }}
      allObservationPointOptions={observationPointData.options}
      activeObservationPointId={observationPointId}
      result={result}
      setActiveObservationPointIdQueryParam={
        setActiveObservationPointIdQueryParam
      }
      setStaticDataReportQueryParams={setStaticDataReportQueryParams}
      getExportUrl={getExportUrl}
    />
  );
};
