import React from 'react';
import { CommentsPanel, CommentsPanelProps } from './CommentsPanel';
import ButtonShowPanel from 'components/base/panel/buttonshowpanel';
import { t, Trans } from '@lingui/macro';
import { ButtonProps } from 'components/base/button/button';

export const ButtonShowCommentsPanel = (
  props: CommentsPanelProps & {
    panelRef?: React.RefObject<HTMLElement>;
    buttonProps?: ButtonProps;
  }
) => {
  const { buttonProps, panelRef, ...commentsPanelProps } = props;
  return (
    <ButtonShowPanel
      iconType="icon-comment"
      name="show-comments-panel"
      title={t`Comments`}
      panelContent={() => <CommentsPanel {...commentsPanelProps} />}
      panelRef={props.panelRef}
      {...buttonProps}
    >
      <Trans>Comments</Trans>
    </ButtonShowPanel>
  );
};
