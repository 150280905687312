import React from 'react';
import { ToggleField } from 'components/base/form/toggle-field/ToggleField';
import { FieldError } from 'components/base/form/errornotice/errornotice';
import { Trans } from '@lingui/macro';
import { Field } from 'formik';
import { isTruthy } from 'util/validation';
import {
  CardSectionField,
  CardSectionFieldMultiColumn,
} from 'components/base/card/card';
import { StoredPlotItemAxisFormValues } from '../stored-plot-edit-utils';

export const getCustomScaleFieldsForAxis = (
  axis: StoredPlotItemAxisFormValues,
  axisFieldPrefix: string,
  label: React.ReactNode
): Array<CardSectionField | CardSectionFieldMultiColumn> => {
  return [
    {
      name: `${axisFieldPrefix}.mode`,
      label,
      content: (
        <>
          <ToggleField
            name={`${axisFieldPrefix}.mode`}
            options={[
              {
                label: <Trans>Auto</Trans>,
                value: 'auto',
              },
              {
                label: <Trans>Custom</Trans>,
                value: 'custom',
              },
            ]}
          />
          <FieldError name={`${axisFieldPrefix}.mode`} />
          <FieldError name={`${axisFieldPrefix}.side`} />
          {axis.mode === 'auto' && (
            <>
              <FieldError name={`${axisFieldPrefix}.minimum`} />
              <FieldError name={`${axisFieldPrefix}.maximum`} />
            </>
          )}
        </>
      ),
    },
    axis.mode === 'custom'
      ? {
          name: `${axisFieldPrefix}.minmax`,
          columns: [
            {
              name: `${axisFieldPrefix}.minimum`,
              label: <Trans>Min</Trans>,
              content: (
                <>
                  <Field
                    name={`${axisFieldPrefix}.minimum`}
                    id={`${axisFieldPrefix}.minimum`}
                    data-testid="minimum"
                  />
                  <FieldError name={`${axisFieldPrefix}.minimum`} />
                </>
              ),
            },
            {
              name: `${axisFieldPrefix}.maximum`,
              label: <Trans>Max</Trans>,
              content: (
                <>
                  <Field
                    name={`${axisFieldPrefix}.maximum`}
                    id={`${axisFieldPrefix}.maximum`}
                    data-testid="maximum"
                  />
                  <FieldError name={`${axisFieldPrefix}.maximum`} />
                </>
              ),
            },
          ],
        }
      : null,
  ].filter(isTruthy);
};
