import moment from 'moment-timezone';
import { timeInterval } from 'd3-time';

/**
 * Factory function to make timezone-aware d3 "timeInterval" instances
 * for different units of measurement. A timeInterval is a utility for
 * dealing with times separated by a particular interval, such as
 * "every day", "every 12 hours", "every 2 month", etc.
 *
 * @see https://github.com/d3/d3-time#timeInterval
 *
 * @param tz Time zone
 * @param interval "year" or "month" or "day", etc
 */
export function tzInterval(
  tz: string,
  interval: 'year' | 'month' | 'week' | 'day' | 'hour' | 'minute'
) {
  return timeInterval(
    /**
     * The `floor` function takes a single date as an argument and rounds it down
     * to the nearest interval boundary.
     */
    function floor(date) {
      date.setTime(moment.tz(date, tz).startOf(interval).valueOf());
    },
    /**
     * The `offset` function takes a date and an integer step as arguments and
     * advances the specified date by the specified number of boundaries; the step
     * may be positive, negative, or zero.
     */
    function offset(date, step) {
      date.setTime(moment.tz(date, tz).add(step, interval).valueOf());
    },
    /**
     * The optional `count` function takes a start date and an end date, already
     * floored to the current interval, and returns the number of boundaries
     * between the start (exclusive) and the end (inclusive).
     * @param start
     * @param end
     */
    function count(start, end) {
      return moment.tz(start, tz).diff(moment.tz(end, tz), interval, true);
    },
    /**
     * The optional `field` function takes a date, already floored to the current
     * interval, and returns the field value of the specified date, corresponding
     * to the number of boundaries between this date (exclusive) and the latest
     * previous parent boundary. For example, for the `d3.timeDay` interval, this
     * returns the number of days since the start of the month.
     */
    function field(date: Date) {
      return moment.tz(date, tz).get(interval);
    }
  );
}
